// @ts-nocheck
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { PropsWithChildren, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from 'main/components/ErrorBoundary/ErrorBoundary';
import { RedirectByProfile } from 'main/features/Auth/components/RedirectByProfile';
import RouteContext from 'main/contexts/RouteContext';
import {
  RotasMain,
  RotasReter,
  RotasPrevidencia,
  RotasVida,
  RotasFerramentas,
  RotasPortabilidade,
  RotasConsultaCliente,
  RotasPrestamista,
  RotasSinistro,
  RotasStatusVendas,
  RotasRelatorios,
  RotasAtendimento,
  RotasExtranet,
  RotasRegistrarOcorrenciasAsc,
  RotasInadimplencia,
  RotasDPS,
  RotasContratosPrestamista,
  RotasInadinplenciaVidaPu,
  RotasPainelResgate,
  RotasPainelPortabilidade,
  RotasPrestamistaDeclinio,
  RotasPainelPrestamista,
  RotasProspeccao,
  RotasAdministracao,
  RotasPropostaVida,
} from 'config/rotas';
import breadcrumbs from 'config/breadcrumbs';
import Loader from 'main/components/Loader';

const Routes: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <Router>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <RouteContext.Provider value={{ breadcrumbs }}>
          <Suspense fallback={<Loader />}>
            <Route exact path="/">
              <RedirectByProfile />
            </Route>
            <RotasMain />
            <RotasConsultaCliente />
            <RotasStatusVendas />
            <RotasPortabilidade />
            <RotasPainelPortabilidade />
            <RotasPrevidencia />
            <RotasPrestamista />
            <RotasVida />
            <RotasRelatorios />
            <RotasFerramentas />
            <RotasInadimplencia />
            <RotasDPS />
            <RotasSinistro />
            <RotasReter />
            <RotasAtendimento />
            <RotasRegistrarOcorrenciasAsc />
            <RotasExtranet />
            <RotasContratosPrestamista />
            <RotasInadinplenciaVidaPu />
            <RotasPrestamistaDeclinio />
            <RotasPainelResgate />
            <RotasPainelPrestamista />
            <RotasProspeccao />
            <RotasAdministracao />
            <RotasPropostaVida />
          </Suspense>
          {children}
        </RouteContext.Provider>
      </ErrorBoundary>
    </Router>
  );
};

export default Routes;
