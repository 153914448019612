// @ts-nocheck
import { Text } from '@cvp/design-system/react';

import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from './styles';

const SaldoTotalCertificado = () => {
  const { featureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  return (
    <S.ContainerResumoSaldo>
      <Text variant="body02-sm" color="text">
        {CONSTS.TEXTOS_SALDO_TOTAL_CERTIFICADO.LABEL}
      </Text>
      <Text variant="body02-sm" color="text">
        <strong>
          {tryGetMonetaryValueOrDefault(
            featureData?.dadosListarFundosParaResgate?.saldo?.saldoTotal,
          )}
        </strong>
      </Text>
    </S.ContainerResumoSaldo>
  );
};

export default SaldoTotalCertificado;
