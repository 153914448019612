// @ts-nocheck
import { formatarData, ultimoDiaDoMes } from 'main/utils';
import * as Masks from 'main/utils/money';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as UTILS from 'previdencia/features/Aporte/utils/aporteUtils';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as RESPONSE_TYPES from 'previdencia/features/Aporte/types/AporteResponse';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';

export const converterResponseParaListaDadosFundos = (
  listaFundos: RESPONSE_TYPES.IDadosFundosAporte[] | undefined,
): RESPONSE_TYPES.IDadosFundosAporte[] => {
  const fundos: RESPONSE_TYPES.IDadosFundosAporte[] = [];

  listaFundos
    ?.sort(
      (ini, fim) => Number(fim.rentUlt12Meses) - Number(ini.rentUlt12Meses),
    )
    .forEach((e, index) => {
      fundos[index] = {
        reservaId: e.reservaId,
        fundoId: e.fundoId,
        descRiscoFundo: e.descRiscoFundo,
        descricaoFundo: e.descricaoFundo,
        saldo: Masks.formatarValorPadraoBrasileiro(e.saldo),
        valorMinimo: e.valorMinimo,
        vlrProporcionalContribuicao: Masks.formatarValorPadraoBrasileiro(
          e.vlrProporcionalContribuicao,
        ),
        descPerfilFundo: e.descPerfilFundo,
        rentUlt12Meses: `${Masks.formatarValorPadraoDigitoBrasileiro(
          e.rentUlt12Meses,
          2,
          true,
        )}%`,
        percetualProporcionalSaldo: `${Masks.formatarValorPadraoDigitoBrasileiro(
          e.percetualProporcionalSaldo,
          2,
          true,
        )}%`,
        valorContribuicao: Masks.formatarValorPadraoBrasileiro(
          e.valorContribuicao,
        ),
        selecionado: e.selecionado,
        qntMaxFundoPermitida: e.qntMaxFundoPermitida,
      };
    });

  return fundos;
};

export const converterResponseParaListaDadosBancariosAporte = (
  data: RESPONSE_TYPES.IDadosBancariosResponse | undefined,
): RESPONSE_TYPES.IDadosBancariosAporte[] => {
  const dados: RESPONSE_TYPES.IDadosBancariosAporte[] = [];

  data?.dados?.forEach((e, index) => {
    dados[index] = {
      tipoPagamentoId: e.tipoPagamentoId,
      descricaoPagamento: e.descricaoPagamento,
      canalId: e.canalId,
      dataPagamento: e.dataPagamento,
      metodoPagamento: e.metodoPagamento,
      tipoContaBanco: e.tipoContaBanco,
      numeroBanco: e.numeroBanco,
      nomeBanco: e.nomeBanco,
      numeroAgencia: e.numeroAgencia,
      digitoAgencia: e.digitoAgencia,
      conta: `${UTILS.retornarContaCaixaSemOperacao(e.numeroConta)}-${
        e.digitoConta
      }`,
      numeroConta: e.numeroConta,
      digitoConta: e.digitoConta,
      operacao: UTILS.retornarOperacaoContaCaixa(e.numeroConta),
      banco: `[${e.numeroBanco}] ${e.nomeBanco}`,
      agencia: e.numeroAgencia,
    };
  });

  return dados;
};

export const modificaSelecionado = (
  fundo: RESPONSE_TYPES.IDadosFundosAporte,
  fundoId: string,
  reservaId: string,
  maximoFundosPermitidos: string,
): RESPONSE_TYPES.IDadosFundosAporte => {
  if (fundo.fundoId + fundo.reservaId !== fundoId + reservaId) {
    return { ...fundo };
  }

  if (fundo.selecionado) {
    return {
      ...fundo,
      selecionado: !fundo.selecionado,
      valorContribuicao: CONSTS.VALOR_CONTRIBUICAO,
      qntMaxFundoPermitida: maximoFundosPermitidos,
    };
  }

  return {
    ...fundo,
    selecionado: !fundo.selecionado,
  };
};

export const modificaValorSelecionado = (
  fundo: RESPONSE_TYPES.IDadosFundosAporte,
  fundoId: string,
  reservaId: string,
  valorAtual: string,
  maximoFundosPermitidos: string,
): RESPONSE_TYPES.IDadosFundosAporte => {
  if (fundo.fundoId + fundo.reservaId !== fundoId + reservaId) {
    return { ...fundo };
  }

  if (fundo.selecionado) {
    return {
      ...fundo,
      selecionado: !fundo.selecionado,
      valorContribuicao: valorAtual,
      qntMaxFundoPermitida: maximoFundosPermitidos,
    };
  }

  return {
    ...fundo,
    selecionado: !fundo.selecionado,
  };
};

export const recuperarFundosRequest = (
  fundosDistribuicao: RESPONSE_TYPES.IDadosFundosAporte[],
) => {
  const fundosSelecionados: APORTE_TYPES.FundoAporte[] = [];

  fundosDistribuicao.forEach(item => {
    if (item.valorContribuicao !== CONSTS.VALOR_CONTRIBUICAO) {
      fundosSelecionados?.push({
        ReservaId: item.reservaId,
        FundoId: item.fundoId,
        ValorContribuicao: item.valorContribuicao,
      });
    }
  });

  return fundosSelecionados;
};

export const recuperarFundosComprovante = (
  fundosDistribuicao: RESPONSE_TYPES.IDadosFundosAporte[],
) => {
  const fundosSelecionados: RESPONSE_TYPES.IDadosFundosAporte[] = [];

  fundosDistribuicao.forEach(item => {
    if (item.valorContribuicao !== CONSTS.VALOR_CONTRIBUICAO) {
      fundosSelecionados?.push({
        ...item,
      });
    }
  });

  return fundosSelecionados;
};

export const dadosGerarAporte = (
  numCertificado: string,
  aporteFormaPagamento: APORTE_TYPES.IAporteFormaPagamento,
  aporteDadosBancarios: RESPONSE_TYPES.IDadosBancariosAporte,
) => {
  let dadosPagamento: Partial<APORTE_TYPES.IGerarDadosAporte>[] = [];

  dadosPagamento = [
    {
      numeroCerfificado: numCertificado,
      formaPagamento: UTILS.tipoPagamento(aporteFormaPagamento.formaPagamento),
      valorAporte: Masks.formatarValorPadraoBrasileiro(
        Number(aporteFormaPagamento.valorContribuicao) / 100,
      ),
    },
  ];

  if (aporteDadosBancarios.conta !== undefined) {
    dadosPagamento = [
      {
        numeroCerfificado: numCertificado,
        formaPagamento: UTILS.tipoPagamento(
          aporteFormaPagamento.formaPagamento,
        ),
        valorAporte: Masks.formatarValorPadraoBrasileiro(
          Number(aporteFormaPagamento.valorContribuicao) / 100,
        ),
        banco: aporteDadosBancarios.numeroBanco,
        operacao: aporteDadosBancarios.operacao,
        agencia: tryGetValueOrDefault([aporteDadosBancarios.agencia], '0,00'),
        contaBancaria: aporteDadosBancarios.conta,
        digitoConta: tryGetValueOrDefault(
          [aporteDadosBancarios.digitoConta],
          '0,00',
        ),
        dataPgamento:
          aporteDadosBancarios.conta.length > 0
            ? formatarData(
                tryGetValueOrDefault([aporteFormaPagamento.dataDebito], '0,00'),
              )
            : '-',
      },
    ];
  }

  return dadosPagamento;
};

export function requisicaoEfetuarAporte({
  cpfCnpj,
  numCertificado,
  aporteFormaPagamento,
  aporteDadosBancarios,
  fundosDistribuicao,
  pepPositivo,
  aporteOrigemRecursos,
}: APORTE_TYPES.IRequisicaoEfetuarAporteParams): APORTE_TYPES.RequestEfetuarAporte {
  return {
    CpfCnpj: cpfCnpj,
    NumeroCertificado: numCertificado,
    MultiFundo: UTILS.ehMultiFundo(fundosDistribuicao[0].qntMaxFundoPermitida),
    FaturaImpressa: false,
    Pagamento: {
      DescricaoPagamento: aporteFormaPagamento.formaPagamento,
      MetodoPagamento: UTILS.metodoPagamento(
        aporteFormaPagamento.formaPagamento,
      ),
      DataPagamento:
        aporteFormaPagamento.dataDebito !== ''
          ? aporteFormaPagamento.dataDebito.toString()
          : ultimoDiaDoMes(new Date()).toDateString(),
      CanalId: tryGetValueOrDefault([aporteDadosBancarios.canalId], ''),
      NomeBanco: CONSTS.enumNomeBancos.CAIXA_ECONOMICA,
      NumeroBanco: CONSTS.enumNumeroBancos.CAIXA_ECONOMICA,
      NumeroAgencia: aporteDadosBancarios.agencia,
      DigitoAgencia: CONSTS.DIGITO_AGENCIA_DEFAULT,
      NumeroConta:
        aporteDadosBancarios.operacao + aporteDadosBancarios.numeroConta,
      DigitoConta: aporteDadosBancarios.digitoConta,
      TipoContaBanco: aporteDadosBancarios.tipoContaBanco,
    },
    Fundos: recuperarFundosRequest(fundosDistribuicao),
    Origem: CONSTS.enumTipoFundo.DEFAULT,
    IndPep: tryGetValueOrDefault([pepPositivo], false),
    invoicePrintInd: true,
    DescricaoOrigem: tryGetValueOrDefault(
      [aporteOrigemRecursos.descricaoOrigemPagamento],
      '',
    ),
    TipoPep:
      pepPositivo === true
        ? CONSTS.enumTipoClientePep.TITULAR
        : CONSTS.enumTipoClientePep.NENUM,
  };
}
