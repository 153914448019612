// @ts-nocheck
import { IResponseConsultaDps } from '../interface/IResponseDps';
import { STATUS_DPS } from '../constants/texts';

export const mockResponseConsultaDps: IResponseConsultaDps[] = [
  {
    cpfCnpj: '46952821864',
    envioDpsAte: '2024-09-10',
    valorAcumulo: '200000.00',
    statusEnvioDPS: STATUS_DPS.EmAnalise,
    nomeCliente: 'Felipe Alves',
    dataNascimento: '2024-10-09',
    sexo: 'M',
    email: 'nomecliente@gmail.com',
    telefone: '11992765523',
    numeroProposta: '43746090005691',
  },
  {
    cpfCnpj: '98765432100',
    envioDpsAte: '2024-08-30',
    valorAcumulo: '100000.00',
    statusEnvioDPS: STATUS_DPS.Aceito,
    nomeCliente: 'Ana Caroline',
    dataNascimento: '2024-10-09',
    sexo: 'M',
    email: 'nomecliente@gmail.com',
    telefone: '11992765523',
    numeroProposta: '43746090005691',
  },
  {
    cpfCnpj: '32165498700',
    envioDpsAte: '2024-07-25',
    valorAcumulo: '150000.00',
    statusEnvioDPS: STATUS_DPS.PendenteDeEnvio,
    nomeCliente: 'Marcos Vinicius',
    dataNascimento: '2024-10-09',
    sexo: 'M',
    email: 'nomecliente@gmail.com',
    telefone: '11992765523',
    numeroProposta: '43746090005691',
  },
  {
    cpfCnpj: '32165498700',
    envioDpsAte: '2024-07-25',
    valorAcumulo: '150000.00',
    statusEnvioDPS: STATUS_DPS.Recusado,
    nomeCliente: 'Jaqueline Moreira Santana',
    dataNascimento: '2024-10-09',
    sexo: 'M',
    email: 'nomecliente@gmail.com',
    telefone: '11992765523',
    numeroProposta: '43746090005691',
  },
  {
    cpfCnpj: '46952821864',
    envioDpsAte: '2024-07-25',
    valorAcumulo: '150000.00',
    statusEnvioDPS: STATUS_DPS.NaoNecessario,
    nomeCliente: 'Millena Silva Santos',
    dataNascimento: '2024-10-09',
    sexo: 'M',
    email: 'nomecliente@gmail.com',
    telefone: '11992765523',
    numeroProposta: '43746090005691',
  },
  {
    cpfCnpj: '32165498700',
    envioDpsAte: '2024-07-25',
    valorAcumulo: '150000.00',
    statusEnvioDPS: STATUS_DPS.PendenteDePreenchimento,
    nomeCliente: 'Bianca Moura',
    dataNascimento: '2024-10-09',
    sexo: 'M',
    email: 'nomecliente@gmail.com',
    telefone: '11992765523',
    numeroProposta: '43746090005691',
  },
];
