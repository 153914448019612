// @ts-nocheck
import { obterEndpointValidarAssinaturaTransferencia } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseValidarAssinatura } from '../types/ValidarAssinatura.types';

export const validarAssinaturaTransferencia = async (
  CodigoEmpresa: string,
  Certificado: string,
): Promise<boolean | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseValidarAssinatura>>(
    obterEndpointValidarAssinaturaTransferencia(),
    {
      CodigoEmpresa,
      Certificado,
    },
  );
  if (data?.dados?.sucesso === false)
    throw new Error(
      data?.dados?.mensagens && data?.dados?.mensagens[0]?.descricao,
    );
  return data.dados.entidade?.permiteAssinatura;
};
