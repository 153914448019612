// @ts-nocheck
import React from 'react';
import { Button } from '@cvp/design-system/react';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { Display } from 'main/features/Auth/components';
import {
  IAporteContextData,
  IButtonsEtapasAporteProps,
} from 'previdencia/features/Aporte/types/Aporte';

const ButtonsEtapasAporte: React.FC<IButtonsEtapasAporteProps> = ({
  disabled,
  proximaEtapa,
  buttonLabel,
  loading,
}) => {
  const { featureData } = usePrevidenciaContext<IAporteContextData>();

  return (
    <Display justify="space-evenly">
      <Button variant="outlined" onClick={featureData?.etapaAnterior}>
        Cancelar
      </Button>
      <Button
        variant="primary"
        disabled={disabled}
        loading={loading}
        onClick={proximaEtapa}
      >
        {buttonLabel}
      </Button>
    </Display>
  );
};

export default ButtonsEtapasAporte;
