// @ts-nocheck
import styled from 'styled-components/macro';
import { Grid } from '@cvp/design-system/react';

export const Container = styled(Grid)(({ theme: { color } }) => ({
  border: `1px solid ${color.line.primary}`,
  background: `${color.brandPrimary.medium}0d`,
  borderRadius: 8,
  margin: '20px 0',
  padding: '10px 0',
}));
