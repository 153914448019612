// @ts-nocheck
import { IResponseConsultaDps } from '../interface/IResponseDps';

export const PESQUISA_CPF = (
  <>
    Faça a pesquisa pelo
    <strong>
      {' '}
      CPF do(a) titular do contrato de seguro / plano de previdência
    </strong>{' '}
    e obtenha as informações dos sinistros relacionados a ele:
  </>
);

export const TITULO_NENHUM_RESULTADO = 'ATENÇÃO!';

export const NENHUM_RESULTADO = (
  <>
    Verifique se os dados foram inseridos corretamente. <br /> Caso não
    apresente informações para o CPF consultado, realize a abertura de
    ocorrência com assunto Andamento de Sinistro vinculado ao produto
    contratado.
  </>
);

export const PESQUISA_CPF_LABEL = 'CPF do(a) titular do produto';

export const STATUS_DPS = {
  PendenteDeEnvio: 'Pendente de envio',
  PendenteDePreenchimento: 'Pendente de preenchimento',
  Aceito: 'Aceito',
  Recusado: 'Recusado',
  EmAnalise: 'Em análise',
  NaoNecessario: 'Não necessário',
};

export const TEXT_MODAL = {
  TITULO:
    'Por favor, verifique se os dados do cliente citado abaixo estão corretos, se necessário atualize.',
  MENSAGEM_ERRO_DPS: 'Falha ao enviar a DPS.',
  MENSAGEM_SUCESSO_DPS: 'DPS enviada com sucesso.',
  MENSAGEM_ERRO_FORMULARIO: 'Formulário inválido.',
};

export const DADOS_INICIAL: IResponseConsultaDps = {
  cpfCnpj: '',
  email: '',
  dataNascimento: '',
  telefone: '',
  sexo: '',
  statusEnvioDPS: '',
};

export const SEXO = [
  { value: 'F', text: 'Feminino' },
  { value: 'M', text: 'Masculino' },
];

export const MENSAGEM_TABELA_DADOS = 'Não há dados para exibir.';

export const MENSAGEM_CAMPO_OBRIGATORIO = 'Campo é obrigatório';
