// @ts-nocheck
import React from 'react';
import { Tag } from '@cvp/design-system/react';

type statusColorsProp = {
  [key: string]: string;
};
const statusColors: statusColorsProp = {
  ativo: 'primary',
  cancelado: 'inactive',
  emitido: 'warning',
};

const TagStatusApolice: React.FunctionComponent<{ status: string }> = ({
  status,
}) => {
  const statusLowerCase = status?.toLocaleLowerCase() || '';
  return (
    <Tag variant={statusColors[statusLowerCase]} value={statusLowerCase} />
  );
};

export default TagStatusApolice;
