// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { levelRisk } from 'previdencia/utils/riskStatus';
import * as FACTORY from 'previdencia/features/Aporte/factories/aporteFactory';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const ComprovanteAporteTabelaFundos: React.FC<
  APORTE_TYPES.IComprovanteAporteTabelaFundosProps
> = ({ fundosDistribuicao }) => {
  const fundosComprovante = FACTORY.recuperarFundosComprovante(
    fundosDistribuicao.get().value,
  );

  const resultFundosAporte = fundosComprovante.map(
    ({ descPerfilFundo, valorContribuicao, ...rest }) => ({
      perfil: <S.RiskBarAporteStyleAporte risk={levelRisk(descPerfilFundo)} />,
      valor: formatarValorPadraoBrasileiro(valorContribuicao),
      ...rest,
    }),
  );

  return (
    <>
      <Text variant="body02-lg">Dados do(s) Fundo(s)</Text>
      <Table
        noHeader
        responsive
        striped
        data={tryGetValueOrDefault([resultFundosAporte], [])}
        columns={CONSTS.COLUNAS_TABELA_FUNDOS_APORTE_CONFIRMACAO}
        noDataComponent="Não há dados para exibir."
      />
    </>
  );
};
export default ComprovanteAporteTabelaFundos;
