// @ts-nocheck
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
import { ICertificadoBeneficioPrevidencia } from 'main/types/CardPrevidencia/ICertificadoBeneficioPrevidencia';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { Beneficios } from 'previdencia/types/Beneficiarios';

const validarValores = (valor: string) => {
  if (valor) {
    return formatarValorPadraoBrasileiro(valor);
  }
  return 'R$ 0,00';
};

const validarCobertura = (coberturaMapeada: any, certificado: any) => {
  if (coberturaMapeada) {
    return {
      cobertura: certificado.rendaDescricao,
      prazo: coberturaMapeada?.termoDesejado
        ? coberturaMapeada?.termoDesejado
        : 0,
      valorContribuicao: validarValores(certificado?.valorEsperado),
      valorBeneficio: validarValores(certificado?.beneficioValor),
      situacao: certificado?.situacao,
    };
  }
  return {
    cobertura: certificado?.rendaDescricao,
    prazo: 0,
    valorContribuicao: validarValores(certificado?.valorEsperado),
    valorBeneficio: validarValores(certificado?.beneficioValor),
    situacao: certificado?.situacao,
  };
};

export const coberturasObject = (
  coberturas: Beneficios[] | undefined,
  certificados: ICertificadoBeneficioPrevidencia[],
) => {
  const coberturasObjectArray = [];

  for (const certificado of certificados) {
    const coberturaMapeada = coberturas?.find(
      cobertura => cobertura?.coberturaId === certificado.beneficioId,
    );
    coberturasObjectArray.push(validarCobertura(coberturaMapeada, certificado));
  }

  return coberturasObjectArray;
};
