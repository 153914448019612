// @ts-nocheck
export const ACEITAR_SMS = {
  SIM: 'S',
  NAO: 'N',
};

export const TIPO_EMAIL = {
  PESSOAL: 'PS',
  TRABALHO: 'TR',
};

export const LOCAL_TELEFONE = {
  CELULAR: 'O',
  COMERCIAL: 'T',
};

export const TELEFONE_PRINCIPAL = {
  SIM: 'S',
  NAO: 'N',
};

export const EXTENSAO_NUMERO = {
  PADRAO: '0',
};

export const OUTROS_PRODUTOS_CAIXA =
  ' O cliente permite que seus dados pessoais possam ser utilizados para ofertar outros produtos da Caixa Vida e Previdência e empresas coligadas.';

export const PARCEIROS_CAIXA =
  ' O cliente permite que seus dados possam ser compartilhados com parceiros de negócio para recebimento de ofertas de produtos.';

export const MENSGENS_ALERTA = {
  OBSERVACAO:
    'Observação: Caso Seja realizada marcação nas opções acima referentes à LGPD (Gestão de Consentimento), ao clicar em CONFIRMAR, os dados não são efetivados automaticamente, sendo necessário aguardar prazo de no mínimo 30 minutos.',
  VERIFIQUE_DADOS:
    'Verifique se os dados estão corretos e clique em CONFIRMAR.',
};
