// @ts-nocheck
export enum EtapaTransferenciaInterna {
  PLANOS = 'planosTransferenciaInterna',
  CONTRIBUICAO = 'contribuicaoRendaMensal',
  FUNDO_INVESTIMENTO = 'fundoInvestimentoDestino',
  CUIDADO_GARANTIDO = 'inclusaoCuidadoGarantido',
  FORMA_PAGAMENTO = 'formaPagamento',
  AQUISICAO = 'confirmacaoAquisicao',
}

export const enum DefaultValue {
  CODIGO_EMPRESA = '500',
  VALOR_CONTRIBUICAO_REQUEST = '0',
  PRAZO_PENSAO = '3',
  TIPO_COBERTURA_PENSAO = 'pensao',
  TIPO_COBERTURA_PECULIO = 'peculio',
  SUBTITULO_COBERTURA_PENSAO = 'Pensão por prazo certo',
  SUBTITULO_COBERTURA_PECULIO = 'Pecúlio',
  COLUNA_COBERTURA_PENSAO = 'Pensão',
  COLUNA_COBERTURA_PECULIO = 'Pecúlio por Morte',
  COLUNA_PRAZO_PECULIO = 'De uma única vez',
  TIPO_ASSINATURA = 'T_ADS',
  TIPO_ENVIO = 'SMS',
  CODIGO_ORIGEM = '50',
  CODIGO_BANCO = '104',
  PERMITIR_INCLUSAO_NOVA_COBERTURA = 'S',
  NAO_PERMITIR_INCLUSAO_NOVA_COBERTURA = 'N',
}

export enum ModalInfoPredefinitionSize {
  DEFAULT = 440,
  XS = 500,
  MD = 650,
  LG = 850,
}
