// @ts-nocheck
import { Card, Display, Text, Button } from '@cvp/design-system/react';
import { useContext, useState } from 'react';
import { TransferenciaOrigemDestino } from '../components/TransferenciaOrigemDestino/TransferenciaOrigemDestino';
import { InputFileFormik } from 'main/components/form/InputFile/InputFileFormik';
import { DescriptionFormatsFilesAllowed } from 'main/components/form/InputFile';
import ModalAssinaturaManual from '../components/Modais/ModalAssinaturaManual';
import { useHistory } from 'react-router';
import { useAssinatura } from '../hooks/useAssinatura';
import {
  converterBase64,
  downloadBase64File,
} from 'main/utils/converterBase64';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { useSolicitarAberturaProtocoloFop223 } from 'extranet/hooks/useSolicitarAberturaProtocoloFop223';
import { AppContext } from 'main/contexts/AppContext';
import { ButtonLink } from '../components/styles';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import RenderConditional from 'main/components/RenderConditional';

export const AssinaturaManual = () => {
  const { cliente } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const { solicitarCriacaoProtocoloFop223, loadingDadosEnvioAnexo } =
    useSolicitarAberturaProtocoloFop223();
  const history = useHistory();
  const [file, setFileList] = useState<File>();
  const [error, setError] = useState(false);
  const { loadingAssinatura, loadingDadosAssinatura, fetchData } =
    useAssinatura({ assinaturaManual: true });

  const handleDownload = async () => {
    const responseAssinatura = await fetchData();
    const blob = converterBase64(responseAssinatura?.entidade?.arquivoBase64);
    downloadBase64File({ blob, fileName: 'arquivo.pdf' });
  };

  const MontaPayloadCriarProtocolo = async () => {
    setShowModal(false);
    const formData = new FormData();

    formData.append('ComprovanteRenda', file as Blob);
    formData.append('cpf', cliente?.cpfCnpj);
    formData.append('NumeroCertificado', cliente?.numCertificado);
    formData.append('CodigoCallback', '0');

    const resultProtocolo = await solicitarCriacaoProtocoloFop223(formData);

    if (resultProtocolo?.entidade?.numeroProtocolo) {
      history.push(
        '/cliente/produtos/previdencia/transferencia-alteracao-taxa/comprovante',
      );
    } else setError(true);
  };

  if (
    checkIfSomeItemsAreTrue([
      loadingAssinatura,
      loadingDadosAssinatura,
      loadingDadosEnvioAnexo,
    ])
  )
    return <CardSkeleton />;
  return (
    <Display type="block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content>
          <TransferenciaOrigemDestino />

          <Display alignItems="center" style={{ marginTop: 20 }}>
            <div>
              <Text variant="caption">
                <strong>Upload de proposta</strong>
              </Text>
              <DescriptionFormatsFilesAllowed fileSize="3" />
              <Text color="primary" variant="caption">
                Não tem o arquivo?{' '}
                <ButtonLink variant="text" onClick={handleDownload}>
                  Clique aqui
                </ButtonLink>{' '}
                para baixar
              </Text>
              <br />

              <InputFileFormik
                onChange={event => {
                  if (event?.currentTarget?.files) {
                    setFileList(event?.currentTarget?.files[0]);
                  }
                }}
              />
            </div>
          </Display>
          <RenderConditional condition={error}>
            <br />
            <Text color="error" variant="caption">
              Não foi possível concluir a assinatura. Caso o erro persista,
              realize o cancelamento da solicitação através do histórico de
              solicitações e tente novamente.
            </Text>
            <br />
          </RenderConditional>
          <Display alignItems="flex-end" justify="flex-end">
            <Button variant="outlined" onClick={() => history.goBack()}>
              Voltar
            </Button>
            <Button variant="primary" onClick={() => setShowModal(!showModal)}>
              Avançar
            </Button>
          </Display>
        </Card.Content>

        <ModalAssinaturaManual
          show={showModal}
          onClose={() => setShowModal(false)}
          nextStep={MontaPayloadCriarProtocolo}
        />
      </Card>
    </Display>
  );
};
