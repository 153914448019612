// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import { retornarTipoRenda } from 'previdencia/features/SimulacaoRenda/utils/simulacaoRenda';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import { TextoInformativoTipoPagamentoProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import { TipoRenda } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import * as S from 'previdencia/features/SimulacaoRenda/components/TextoInformativoTipoPagamento/styles';

const TextoInformativoTipoPagamento: React.FC<
  TextoInformativoTipoPagamentoProps
> = ({ dados }) => {
  const tipoPagamento = dados?.nomTipoPagamento?.toLowerCase();

  const textoPorTipoPagamento: TipoRenda[] = [
    {
      codigo: EnumTipoRendaCodigo.Temporaria,
      tipo: 'temporária',
      descricao: (
        <>
          <Text variant="body02-sm" margin>
            Você terá <strong>renda {tipoPagamento}.</strong>
          </Text>

          <Text variant="body02-sm">
            Você receberá uma renda mensal pelo prazo que escolheu.
          </Text>
          <Text variant="body02-sm" margin>
            Esse benefício termina no caso de seu falecimento ou caso o prazo
            acabe.
          </Text>

          <Text variant="body02-sm">
            <strong>Exemplo:</strong>
          </Text>
          <Text variant="body02-sm">
            Carla quer transformar sua reserva em renda mensal, pra receber tudo
            em 10 anos. Ela escolheu não deixar sua renda para seus
            beneficiários. Portanto, caso Carla venha a falecer, seus
            beneficiários não terão acesso a essa renda.
          </Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.PrazoCerto,
      tipo: 'prazo certo',
      descricao: (
        <>
          <Text variant="body02-sm" margin>
            Você terá a <strong>renda {tipoPagamento}.</strong>
          </Text>

          <Text variant="body02-sm">
            Você receberá uma renda mensal pelo prazo que escolheu.
          </Text>
          <Text variant="body02-sm" margin>
            Caso venha a falecer durante esse período, seus beneficiários
            receberão a sua renda até que o prazo termine.
          </Text>

          <Text variant="body02-sm">
            <strong>Exemplo:</strong>
          </Text>
          <Text variant="body02-sm">
            Carla quer transformar sua reserva em renda mensal, pra receber tudo
            em 10 anos. Ela tem duas filhas, e faleceu depois de ter recebido
            essa renda por 4 anos. Logo, suas filhas receberão essa renda por 6
            anos.
          </Text>
          <Text variant="body02-sm">
            E se Carla tivesse falecido após os 10 anos que definiu? Suas filhas
            não teriam mais acesso a essa renda, pois o prazo já teria passado.
          </Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.Vitalicia,
      tipo: 'vitalícia',
      descricao: (
        <>
          <Text variant="body02-sm" margin>
            Você terá <strong>renda {tipoPagamento}.</strong>
          </Text>

          <Text variant="body02-sm">
            Você receberá uma renda mensal enquanto viver.
          </Text>
          <Text variant="body02-sm" margin>
            Esse benefício só termina no caso de seu falecimento.
          </Text>

          <Text variant="body02-sm">
            <strong>Exemplo:</strong>
          </Text>
          <Text variant="body02-sm">
            Carla quer transformar sua reserva em renda mensal, para receber
            enquanto viver. Ela escolheu não deixar sua renda para seus
            beneficiários. Portanto, caso Carla venha a falecer, seus
            beneficiários não terão acesso a essa renda.
          </Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
      tipo: 'vitalícia com prazo mínimo garantido',
      descricao: (
        <>
          <Text variant="body02-sm" margin>
            Você terá <strong>renda {tipoPagamento}.</strong>
          </Text>

          <Text variant="body02-sm">
            Você receberá uma renda mensal enquanto viver.
          </Text>
          <Text variant="body02-sm" margin>
            Caso venha a falecer, seus beneficiários receberão a sua renda por
            um prazo. Esse prazo começa a contar assim que transformar a sua
            reserva em renda.
          </Text>

          <Text variant="body02-sm">
            <strong>Exemplo:</strong>
          </Text>
          <Text variant="body02-sm">
            Carla transformou sua reserva em renda e escolheu 10 anos como prazo
            para suas duas filhas receberem sua renda no caso de seu
            falecimento. Ela recebeu essa rendar por 3 anos, e veio a falecer.
            Logo, suas filhas passarão a receber sua renda por 7 anos.
          </Text>
          <Text variant="body02-sm">
            E se Carla tivesse falecido após os 10 anos que definiu? Suas filhas
            não teriam mais acesso a essa renda, pois o prazo já teria passado.
          </Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
      tipo: 'vitalícia reversível ao beneficiário',
      descricao: (
        <>
          <Text variant="body02-sm" margin>
            Você terá <strong>renda {tipoPagamento}.</strong>
          </Text>

          <Text variant="body02-sm">
            Você receberá uma renda mensal enquanto viver.
          </Text>
          <Text variant="body02-sm" margin>
            Caso venha a falecer, apenas um beneficiário receberá a sua renda
            enquanto ele viver. Essa renda pode ser integral ou parcial.
          </Text>

          <Text variant="body02-sm">
            <strong>Exemplo:</strong>
          </Text>
          <Text variant="body02-sm">
            Carla tem duas filhas, e decidiu que quer transformar sua reserva em
            renda. Ela escolheu sua filha mais nova como beneficiária, e que ela
            receberia metade da sua renda. Logo, no caso do seu falecimento, sua
            filha mais nova será a única a ter 50% da sua renda.
          </Text>
        </>
      ),
    },
  ];

  const retornoTipoRenda = retornarTipoRenda(
    textoPorTipoPagamento,
    dados?.codTipoPagamento,
  );

  return <S.Container>{retornoTipoRenda?.descricao}</S.Container>;
};

export default TextoInformativoTipoPagamento;
