// @ts-nocheck
import { obterEndpointTiposRendas } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseTiposRendas } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';

export const obterTiposRendas = async (
  Cpf: string,
  NumeroCertificado: string,
): Promise<ResponseTiposRendas | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseTiposRendas>>(
    obterEndpointTiposRendas(),
    {
      Cpf,
      NumeroCertificado,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
