// @ts-nocheck
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import {
  IClientePrevidencia,
  IDadosClientePrev,
} from 'previdencia/types/IClientePrevidencia';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

export const obterResumoPlanoOrigemSelecionadoFactory = (
  cliente: IClientePrevidencia<IDadosClientePrev>,
): TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IObterResumoPlanoOrigemSelecionadoReturnFactory[] => {
  const listaDetalhamentoCertificado = cliente?.dados?.cerficadosDetalhado?.map(
    certificadoDetalhado => ({
      cobertura: <b>{certificadoDetalhado?.rendaDescricao}</b>,
      valorContribuicao: tryGetMonetaryValueOrDefault(
        certificadoDetalhado?.valorEsperado,
      ),
      valorBeneficio: tryGetMonetaryValueOrDefault(
        certificadoDetalhado?.beneficioValor,
      ),
      status: certificadoDetalhado?.situacao,
      saldo: tryGetMonetaryValueOrDefault(certificadoDetalhado?.valorEsperado),
    }),
  );

  return tryGetValueOrDefault([listaDetalhamentoCertificado], []);
};
