// @ts-nocheck
import styled from 'styled-components/macro';
import { Display as DisplayDS } from '@cvp/design-system/react';

export const ContainerData = styled(DisplayDS)`
  display: flex;
  flex-direction: column;

  h6,
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
`;
