// @ts-nocheck
import { useState, useCallback } from 'react';
import { usePecoCancelarTransferencia } from './usePecoCancelarTransferencia';

export const useCancelarTransferencia = (idRequisicao: string) => {
  const [codigoMotivoSelecionado, setCodigoMotivoSelecionado] = useState('');

  const {
    CancelarSolicitacao,
    loadingCancelarTransferencia,
    mensagem,
    sucesso,
  } = usePecoCancelarTransferencia(idRequisicao, codigoMotivoSelecionado);

  const handleSelecionarMotivo = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setCodigoMotivoSelecionado(event.target.value);
    },
    [],
  );

  const handleCancelar = useCallback(async () => {
    if (!codigoMotivoSelecionado) return;
    await CancelarSolicitacao();
  }, [CancelarSolicitacao, codigoMotivoSelecionado]);

  return {
    codigoMotivo: codigoMotivoSelecionado,
    setCodigoMotivo: setCodigoMotivoSelecionado,
    handleSelecionarMotivo,
    handleCancelar,
    loadingCancelarTransferencia,
    mensagem,
    sucesso,
  };
};
