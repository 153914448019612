// @ts-nocheck
import React from 'react';
import { Text, Display } from '@cvp/design-system/react';
import { ApoliceDadosBancariosImpressao } from 'seguros/components/TabelasApoliceImpressao/components/ApoliceDadosBancariosImpressao';
import { TabelaApoliceCoberturasImpressao } from 'seguros/components/TabelasApoliceImpressao/components/TabelaApoliceCoberturasImpressao';
import { TabelaApoliceCarenciasImpressao } from 'seguros/components/TabelasApoliceImpressao/components/TabelaApoliceCarenciasImpressao';
import { TabelaApoliceSorteiosImpressao } from 'seguros/components/TabelasApoliceImpressao/components/TabelaApoliceSorteiosImpressao';
import { ITabelasApoliceImpressaoProps } from 'seguros/types/IResponseCertificadosResumo';
import { TEXTOS_DADOS_APOLICE_VIDA } from 'seguros/constants/ApoliceAssistencias';
import * as S from 'vida/features/DadosApolice/pages/styles';

const TabelasApoliceImpressao: React.FC<ITabelasApoliceImpressaoProps> = ({
  dadosCertificadoVida,
  dadosCobertura,
  dadosCarencia,
  dadosSorteios,
}) => {
  return (
    <>
      <Display className="show-print">
        <Text color="primary" margin>
          Dados da Apólice
        </Text>
        <ApoliceDadosBancariosImpressao data={dadosCertificadoVida} />
        <TabelaApoliceCoberturasImpressao data={dadosCobertura} />
        <TabelaApoliceCarenciasImpressao data={dadosCarencia} />
        <TabelaApoliceSorteiosImpressao data={dadosSorteios} />

        <S.ContainerImportantInfoImpressao>
          <Text variant="body02-md" margin>
            {TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE.TITULO}
          </Text>
          <Text variant="body-medium3" margin>
            {TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE.REEMBOLSO}
          </Text>
          <Text variant="body-medium3" margin>
            {TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE.DETALHES_COBERTURAS}
          </Text>
          <Text variant="body-medium3">
            {TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE.IOF}
          </Text>
          <Text variant="caption-02">
            {TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE.DETALHES_IOF}
          </Text>
        </S.ContainerImportantInfoImpressao>
      </Display>
    </>
  );
};

export default TabelasApoliceImpressao;
