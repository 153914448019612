// @ts-nocheck
import React, { useState } from 'react';
import Icon from 'main/components/Icon';
import Modal from '../Modal/Modal';

type TooltipWrapperProps = {
  title?: string;
  left?: number;
  top?: number;
  iconName?: string;
  width?: string;
  children?: React.ReactNode;
};

export const TooltipModalWrapper: React.FC<TooltipWrapperProps> = ({
  children,
  title = '',
  left = 91,
  top = -8,
  iconName = 'warning',
  width = 'inherit',
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <div
        style={{ position: 'relative', width }}
        onClick={() => setOpenModal(!openModal)}
        aria-hidden="true"
      >
        {title}
        <div
          style={{
            position: 'absolute',
            left: `${left}%`,
            top: `${top}px`,
            zIndex: '1000',
          }}
        >
          <Icon name={iconName} />
        </div>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        {children}
      </Modal>
    </>
  );
};
