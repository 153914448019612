// @ts-nocheck
import { obterEndpointHistoricoSimulacaoRendaPrevidencia } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseDetalhesHistoricoSimulacaoRenda } from '../types/detalhesHistoricoSimulacaoRenda';

export const consultarDetalhesHistoricoSimulacaoRenda = async (
  certificado: string,
  seqSimulacao: string,
): Promise<ResponseDetalhesHistoricoSimulacaoRenda | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponseDetalhesHistoricoSimulacaoRenda>
  >(obterEndpointHistoricoSimulacaoRendaPrevidencia(), {
    certificado,
    seqSimulacao,
  });

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
