// @ts-nocheck
import styled from 'styled-components/macro';
import { Select } from '@cvp/design-system/react';

export const SelectComponent = styled(Select)<{ isDisabled?: boolean }>(
  ({ theme: { color }, isDisabled }) => ({
    div: {
      background: isDisabled ? '#DBDCDE' : color.neutral['08'],
    },
  }),
);
