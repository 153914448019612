// @ts-nocheck
import styled from 'styled-components/macro';

export const Container = styled.div(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Content = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  padding: 20,
  border: `1px solid ${theme.color.brandPrimary.light}`,
  borderRadius: '8px',
  flexDirection: 'column',
  gap: '16px',

  [theme.breakpoint.md()]: {
    flexDirection: 'row',
  },
}));

export const TextClientName = styled.div(() => ({
  maxWidth: '100%',
  overflow: 'hidden',
  fontSize: '12px',
  whitespace: 'nowrap',
}));

export const WrapperStatusT = styled.div(({ theme: { color } }) => ({
  '& p:first-child': {
    fontWeight: 'normal',
  },
}));
