// @ts-nocheck
import { Card, Grid, Text, Radio, Disclaimer } from '@cvp/design-system/react';

import For from 'main/components/For';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { DEFAULT_NO_DATA_TABLE } from 'main/constants/messages';
import useTabelasAliquotas from 'previdencia/features/AlteracaoRegimeTributario/hooks/useTabelasAliquotas';
import * as CONSTS from 'previdencia/features/AlteracaoRegimeTributario/constants/constants';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributario';
import * as S from './styles';

const TabelasAliquotas = ({
  opcoesTributacaoIrrfDisponiveis,
  loadingDadosAliquota,
  opcaoRegimeTributario,
  dadosTabela,
  selecionarOpcaoRegimeTributario,
}: REGIME_TRIBUTARIO_TYPES.ITabelasAliquotasProps) => {
  const { exibirDadosTabelaAliquota } = useTabelasAliquotas({
    dadosTabela,
    loadingDadosAliquota,
    opcaoRegimeTributario,
  });

  return (
    <>
      <For each={tryGetValueOrDefault([opcoesTributacaoIrrfDisponiveis], [])}>
        {tipoAliquota => (
          <>
            <RenderConditional condition={loadingDadosAliquota}>
              <Grid.Item xs={1} xl={1 / 2}>
                <SkeletonLoading lines={4} />
              </Grid.Item>
            </RenderConditional>

            <RenderConditional
              condition={
                exibirDadosTabelaAliquota(tipoAliquota).isTabelasAliquota
              }
            >
              <Grid.Item xs={1} xl={1 / 2}>
                <Card>
                  <Card.Content padding={[0, 0, 5, 0]}>
                    <S.ContainerTituloAliquota role="radiogroup">
                      <Radio
                        name="tipoAliquota"
                        onChange={() => {
                          selecionarOpcaoRegimeTributario(tipoAliquota);
                        }}
                        value={tipoAliquota}
                        checked={
                          exibirDadosTabelaAliquota(tipoAliquota)
                            .isOpcaoSelecionada
                        }
                        aria-label={`Selecionar alíquota ${tipoAliquota}`}
                      />

                      <Text variant="body01-md" color="white" role="heading">
                        {
                          exibirDadosTabelaAliquota(tipoAliquota)
                            .tituloOpcaoAliquota
                        }
                      </Text>
                    </S.ContainerTituloAliquota>

                    <S.Table
                      noHeader
                      responsive
                      highlightOnHover
                      striped
                      data={
                        exibirDadosTabelaAliquota(tipoAliquota)
                          .dadosTabelaAliquota
                      }
                      columns={CONSTS.COLUNAS_ALIQUOTA}
                      noDataComponent={DEFAULT_NO_DATA_TABLE}
                      aria-label={`Dados da alíquota ${tipoAliquota}`}
                      conditionalRowStyles={CONSTS.conditionalRowStylesAliquota}
                    />
                  </Card.Content>
                </Card>
              </Grid.Item>
            </RenderConditional>
          </>
        )}
      </For>

      <RenderConditional
        condition={exibirDadosTabelaAliquota().isErroDadosAliquotas}
      >
        <Grid.Item xs={1}>
          <Disclaimer variant="error" role="alert">
            <Disclaimer.Content text={CONSTS.NO_DATA_ALIQUOTA} />
          </Disclaimer>
        </Grid.Item>
      </RenderConditional>
    </>
  );
};

export default TabelasAliquotas;
