// @ts-nocheck
export const REAJUSTE_ANUAL = {
  VALORES_PLANO:
    'Todo ano os valores que o cliente paga são reajustados automaticamente para manter seu dinheiro valorizado, assim ele não perde compra. Conheça abaixo os dois tipos de atualizações que podem ser feitas, de acordo com o tipo de plano.',
  QUANDO:
    'No aniversário do seu plano, ou seja, na mesma data que você fez sua contratação.',
  O_QUE_ATUALIZA:
    'O valor pago para a reserva. Caso possua Cuidado Extra, será atualizado o valor da indenização e, consequentemente, o quanto você paga por ela.',
  COMO_ATUALIZA:
    'Segundo variação do IGP-M ou IPCA, de acordo com o regulamento do seu plano, para acompanhar a inflação.',
  QUANDO_CUIDADO_EXTRA:
    'Na data do seu aniversário, se o seu plano foi contratado antes de 2012. No aniversário do plano, se for depois de 2012.',
  COMO_ATUALIZA_CUIDADO_EXTRA:
    ' De acordo com sua idade (tabela de reequadramento por faixa etária).',
  ALTERACAO_VALOR_CONTRIBUICAO:
    'Você fez alguma alteração no valor da sua contribuição? Se sim, o reajuste vai considerar o período a partir da data da alteração até o aniversário do plano.',
  COMO_FUNCIONA:
    'Você decidiu mudar o valor da sua contribuição em junho e em dezembro seu plano vai completar mais um ano. Logo, o reajuste vai considerar apenas o período de junho a dezembro, e não os 12 meses.',
};

export const REAJUSTE_REENQUADRAMENTO = {
  O_QUE_ATUALIZA:
    'O valor que você paga para a reserva. Se você tem Cuidado Extra, será atualizado o valor da indenização e, consequentemente, o quanto você paga por ela.',
  COMO_ATUALIZA:
    'Segundo variação do IGP-M ou IPCA, de acordo com o regulamento do seu plano, para acompanhar a inflação.',
  QUANDO_CUIDADO_EXTRA:
    'Na data do seu aniversário, se o seu plano foi contratado antes de 2012. No aniversário do plano, se for depois de 2012.',
  COMO_ATUALIZA_CUIDADO_EXTRA:
    'De acordo com sua idade (tabela de reequadramento por faixa etária).',
};

export const columnsReserva = [
  {
    name: 'Tipo de atualização',
    selector: 'desTipoAtualizacao',
    minWidth: '320px',
  },
  {
    name: 'Data',
    selector: 'dthRecal',
  },
  {
    name: 'Valor anterior',
    selector: 'vlrAnterior',
  },
  {
    name: 'Atualização*',
    selector: 'pctAtualizacaoCodIndice',
  },
  {
    name: 'Valor final',
    selector: 'vlrFinal',
  },
];

export const columnsCuidadoExtra = [
  {
    name: 'Tipo de atualização',
    selector: 'desTipoAtualizacao',
    minWidth: '360px',
  },
  {
    name: 'Data',
    selector: 'dthRecal',
  },
  {
    name: 'Cuidado Extra',
    selector: 'desPagamento',
    minWidth: '250px',
  },
  {
    name: 'Valor anterior',
    selector: 'vlrAnterior',
  },
  {
    name: 'Atualização*',
    selector: 'pctAtualizacaoCodIndice',
  },
  {
    name: 'Valor final',
    selector: 'vlrFinal',
  },
];

export const INFORMACOES_REAJUSTE = {
  INFO_TITULO:
    'Todo ano os valores que você paga são reajustados automaticamente para manter seu dinheiro valorizado, assim ele não perde seu poder de compra.',
  INFO_TITULO_COMPLEMENTAR:
    'Conheça abaixo os tipos de atualizações que podem ser feitas, de acordo com o tipo de plano.',
  ANIVERSARIO_PLANO:
    'No aniversário do seu plano, ou seja, na mesma data que você fez sua contratação.',
  VALOR_RESERVA:
    'O valor que você paga para a reserva. Se você tem Cuidado Extra, será atualizado o valor da indenização e, consequentemente, o quando você paga por ela.',
  VARIACAO_IGPM:
    'Segundo variação do IGP-M ou IPCA, de acordo com o regulamento do seu plano, para acompanhar a inflação.',
  NO_ANIVERSARIO:
    'Na data do seu aniversário, se o seu plano foi contratado antes de 2012. No aniversário do plano, se for depois de 2012.',
  CUIDADO_EXTRA:
    'Se o Cuidado Extra do seu plano é cobrado uma vez por ano ou por semestre e o reenquadramento acontecer após o pagamento já efetuado, a diferença do valor será cobrada na sua próxima contribuição.',
  CUIDADO_EXTRA_EXEMPLO:
    'Por exemplo: se você paga R$ 360 e, após o reenquadramento, o valor subir para R$ 380, esses R$ 20 de diferença serão cobrados na sua próxima contribuição.',
  VARIACAO_INDICE:
    '*Caso a variação do índice seja negativa, os valores de contribuição e indenização são mantidos, o que é muito bom para você. Isso quer dizer que seu dinheiro está valendo mais.',
  UTILIZA_IGPM:
    'A Caixa Vida e Previdência utiliza para reajuste do plano o índice IGP-M ou IPCA com defasagem de 2 meses, com base na Resolução CNSP n°11 de 1996, como praticado pela maioria do mercado segurador.',
};
