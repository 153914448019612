// @ts-nocheck
import React from 'react';
import { Grid, Text, Accordion } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { ordenarMatriz } from 'reter/features/retencao/utils/ordenarMatriz';
import { AccordionFaqProps } from 'reter/features/retencao/types/AccordionFaq';
import * as S from 'reter/features/retencao/components/AccordionFaq/styles';

const AccordionFaq: React.FC<AccordionFaqProps> = ({ filteredSearch }) => {
  return (
    <Grid>
      <Grid.Item xs="1">
        <Accordion open>
          {ordenarMatriz(filteredSearch).map(pergunta => (
            <Accordion.Item key={pergunta.id} title={pergunta.titulo}>
              {ordenarMatriz(pergunta.textos).map(texto => (
                <>
                  <RenderConditional
                    condition={!texto.eLista}
                    component={
                      <Text key={texto.id} variant="body02-sm" margin>
                        {texto.conteudos[0]}
                      </Text>
                    }
                  />

                  <RenderConditional
                    condition={texto.eLista}
                    component={
                      <S.Ul key={texto.id}>
                        {texto.conteudos.map(conteudo => (
                          <S.Li key={conteudo}>{conteudo}</S.Li>
                        ))}
                      </S.Ul>
                    }
                  />
                </>
              ))}
            </Accordion.Item>
          ))}
        </Accordion>
      </Grid.Item>
    </Grid>
  );
};

export default AccordionFaq;
