// @ts-nocheck
import { FC } from 'react';
import { Card, Display, Grid } from '@cvp/design-system/react';
import For from 'main/components/For';
import { IResponseObterListaFopsAtivos } from 'main/features/Administracao/types/IFops';
import ItemFop from 'painelAdministracao/components/ItemFop/ItemFop';
import { ListaArquivo } from 'painelAdministracao/components/ListaArquivo/ListaArquivo';
import { IListaFopsAtivosProps } from 'painelAdministracao/types/ListagemTypes';

export const ListaFopsAtivos: FC<IListaFopsAtivosProps> = ({ fopsAtivos }) => {
  return (
    <For each={fopsAtivos}>
      {(fop: IResponseObterListaFopsAtivos, index: number) => {
        return (
          <Grid key={index}>
            <Grid.Item xs={1}>
              <Display type="display-block">
                <Card>
                  <Card.Content padding={[4, 4, 4]}>
                    <ItemFop fop={fop} />
                    <ListaArquivo fop={fop} />
                  </Card.Content>
                </Card>
              </Display>
            </Grid.Item>
          </Grid>
        );
      }}
    </For>
  );
};
