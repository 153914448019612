// @ts-nocheck
import * as RESPONSE_TYPES from 'contratosPrestamista/features/reenvioDpsEletronica/types/ConsultaDpsResponse';
import { tryGetValueOrDefault } from 'main/utils/conditional';

/**
 * Seleciona o número da proposta com base no código identificador fornecido.
 * @param propostasVinculadas - Array de objetos contendo as propostas vinculadas.
 * @param codIdentificador - Código identificador a ser pesquisado.
 * @returns O número da proposta correspondente ao código identificador, ou uma string vazia se não for encontrado.
 */
export const selecionarProposta = (
  propostasVinculadas: RESPONSE_TYPES.IResponseRecuperarListaDpsPropostasVinculadas[],
  codIdentificador: string,
) => {
  const numeroProposta = propostasVinculadas.find(
    proposta =>
      proposta.numeroProposta ===
      codIdentificador.split('_')[codIdentificador.split('_').length - 1],
  )?.numeroProposta;

  return tryGetValueOrDefault([numeroProposta], '');
};
