// @ts-nocheck
export enum DefaultValue {
  VALOR_MINIMO_TELEFONE = 10,
  VALOR_MINIMO_CEP = 8,
  QTD_MAX_CARACTERES_TEXTAREA_REGISTRAR_OCORRENCIA = 1000,
  QTD_MAX_CARACTERES_TEXTAREA_REGISTRAR_COMPLEMENTO = 500,
  QTD_MIN_CARACTERES_TEXTAREA = 500,
  TAMANHO_ARQUIVO_UPLOAD = 7,
}

export enum FieldType {
  NUMBER = 'number',
  TEXT = 'text',
  DATE = 'date',
  SELECT = 'select',
}

export enum PayloadFixo {
  CANAL = 'Web - CAIXA',
  CANAL_ORIGEM = 'Canal Institucional',
  CANAL_ORIGEM_SAC = 'SAC',
  ATENDIMENTO_SEGUNDO_NIVEL = 'true',
  ORIGEM_CONTATO = 'Apoio',
  TIPO_ORIGEM_PROTOCOLO = 'CLIENTE',
  NUMERO_TELEFONE_FUNCIONARIO_INTERLOCUTOR = '',
  MENSAGEM_CONCLUSAO = '',
  ORIGEM = 'Institucional',
  STATUS_CONTRATO_ATIVO = 'A',
}
