// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IPayloadReservasDestino,
  IReservasDestino,
  IResponseDefinirReservaDestino,
} from 'previdencia/types/IDefinirReservasDestino';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const useDefinirReservasDestino = (
  reservasDestino?: IReservasDestino[],
) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const { featureData } = usePrevidenciaContext<{ numTransferencia: string }>();
  const { loading, response, fetchData } = usePeco<
    IPayloadReservasDestino,
    IResponseDefinirReservaDestino
  >({
    api: {
      operationPath: PECOS.DefinirReservasDestino,
    },
    payload: {
      numTransferencia: tryGetValueOrDefault(
        [featureData?.numTransferencia],
        '',
      ),
      cpf: cpfCnpj,
      codContaOrigem: numCertificado,
      reservasDestino,
    },
  });

  return {
    loading,
    response: response?.entidade,
    fetchData,
  };
};
