// @ts-nocheck
import styled from 'styled-components/macro';

export const ContainerInformacoesRenda = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: '30px',
  marginBottom: '20px',
  marginRight: '10px',
});

export const RendaLiquida = styled.div({
  borderRight: '1px solid silver',
  paddingRight: '40px',
  marginRight: '40px',
  alignItems: 'center',
});

export const AccordionStyled = styled.div({
  width: '100%',
});

export const Resultado = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: '8px',
});

export const ResultadoItem = styled.div`
  flex: 1;
`;

export const Tabela = styled.div({
  borderCollapse: 'collapse',
  width: '100%',
  marginTop: '20px',
  'th, td': {
    border: '1px solid gray',
    padding: '8px',
    textAlign: 'center',
  },
  th: {
    backgroundColor: '#f2f2f2',
  },
});
