// @ts-nocheck
import { useContext } from 'react';
import { IAuthContextData } from 'main/features/Auth/interfaces';
import { AuthContext } from 'main/features/Auth/contexts/AuthContext';

export const useAuth = (): IAuthContextData => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth deve ser usado com o AuthProvider');
  }
  return context;
};
