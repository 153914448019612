// @ts-nocheck
import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { useAuth } from 'main/features/Auth/hooks';
import * as BeneficioProtecaoApi from 'previdencia/features/AlterarValorContribuicao/services/alterarValorContribuicaoBeneficioProtecao.api';
import { DadosResponseAlterarValorContribuicaoBeneficioProtecao } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoBeneficioProtecao';
import { DadosBeneficioProtecao } from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';
import { EnumCanalOrigem } from 'previdencia/types/DefaultValues';

const useAlterarValorContribuicaoBeneficioProtecao = () => {
  const { toastError } = useToast();
  const { user } = useAuth();
  const [
    isLoadingAlterarValorContribuicaoBeneficioProtecao,
    setIsLoadingAlterarValorContribuicaoBeneficioProtecao,
  ] = useState(false);
  const [
    resultAlterarValorContribuicaoBeneficioProtecao,
    setResultAlterarValorContribuicaoBeneficioProtecao,
  ] = useState<
    DadosResponseAlterarValorContribuicaoBeneficioProtecao | undefined
  >();
  const { cliente } = useContext(AppContext);

  const alterarValorContribuicaoBeneficioProtecao = async (
    dados: DadosBeneficioProtecao,
    novoValorContribuicao: number,
  ): Promise<void> => {
    try {
      setIsLoadingAlterarValorContribuicaoBeneficioProtecao(true);

      const { codPlan, codBeneficio, codContrib } = dados;

      const result =
        await BeneficioProtecaoApi.alterarValorContribuicaoBeneficioProtecao({
          codigoCertificado: cliente?.numCertificado,
          canalOrigem: EnumCanalOrigem.portalEconimiario,
          codigoBeneficio: codBeneficio,
          codigoPlano: codPlan,
          tipoContribuicao: codContrib,
          valorContribuicao: novoValorContribuicao,
          matriculaEconomiario: user.nomeAcesso,
        });

      if (!result) throw new Error();

      setResultAlterarValorContribuicaoBeneficioProtecao(result);
    } catch (error: any) {
      toastError(error.message);
    } finally {
      setIsLoadingAlterarValorContribuicaoBeneficioProtecao(false);
    }
  };

  return {
    isLoadingAlterarValorContribuicaoBeneficioProtecao,
    resultAlterarValorContribuicaoBeneficioProtecao,
    alterarValorContribuicaoBeneficioProtecao,
  };
};

export default useAlterarValorContribuicaoBeneficioProtecao;
