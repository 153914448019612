// @ts-nocheck
import { useContext } from 'react';

import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IPayloadRevalidarTransferencia,
  IResponseRevalidarTransferencia,
} from 'previdencia/types/IRevalidarTransferencia';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { TDataCard } from 'previdencia/types/ITransferirEntreFundos';

export const useRevalidarTransferencia = () => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  const { featureData } = usePrevidenciaContext<{
    numTransferencia: string;
    certificadoDestinoSelecionado: TDataCard;
    manterBeneficio: boolean;
    confirmaAlerta: boolean;
  }>();
  const { loading, response, fetchData } = usePeco<
    IPayloadRevalidarTransferencia,
    IResponseRevalidarTransferencia
  >({
    api: {
      operationPath: PECOS.RevalidarTransferencia,
    },
    payload: {
      cpf: cpfCnpj,
      numTransferencia: tryGetValueOrDefault(
        [featureData?.numTransferencia],
        '',
      ),
      codContaDestino: tryGetValueOrDefault(
        [featureData?.certificadoDestinoSelecionado.codConta],
        '',
      ),
      indicadorMigraRisco: getTernaryResult(
        !!featureData?.manterBeneficio,
        'S',
        'N',
      ),
      indicadorConfirmaAlerta: getTernaryResult(
        !!featureData?.confirmaAlerta,
        'S',
        'N',
      ),
    },
  });

  return {
    loading,
    response: response?.entidade,
    fetchData,
  };
};
