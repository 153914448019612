// @ts-nocheck
import React from 'react';
import { Tag } from '@cvp/design-system/react';

type statusColorsProp = {
  [key: string]: string;
};
const statusColors: statusColorsProp = {
  ATIVO: 'primary',
  EMITIDO: 'inactive',
  CANCELADO: 'warning',
  ENCERRADOPORFIMVIGENCIA: 'warning,',
  NAOACEITO: 'error',
};

const TagStatusSeguro: React.FunctionComponent<{ status: string }> = ({
  status,
}) => {
  return (
    <Tag variant={statusColors[status.replace(/\s/g, '')]} value={status} />
  );
};

export default TagStatusSeguro;
