// @ts-nocheck
import React from 'react';
import { CertificadoEmissao } from 'seguros/components/CertificadoEmissao';
import { useSegundaViaCertificadosSegurosPdf } from 'seguros/hooks/useSegundaViaCertificadosSegurosPdf';
import { getBlobUrl } from 'main/utils/blob';

const Certificado: React.FC = (): React.ReactElement => {
  const { dadosSegundaViaCertificado, loadingSegundaViaCertificadoo, error } =
    useSegundaViaCertificadosSegurosPdf();

  const gerarPdf = () => {
    getBlobUrl(dadosSegundaViaCertificado?.entidade?.conteudoBase64);
  };

  return (
    <CertificadoEmissao
      error={error}
      gerarPdf={gerarPdf}
      loading={loadingSegundaViaCertificadoo}
      existsPdf={!!dadosSegundaViaCertificado?.entidade}
      messageService={dadosSegundaViaCertificado?.mensagens?.[0]?.descricao}
    />
  );
};

export default Certificado;
