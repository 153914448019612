// @ts-nocheck
import React from 'react';
import { Display, Text, Button } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const ModalInformeDadosOrigem: React.FC<
  PROPS_TYPES.ModalInformeDadosOrigemProps
> = ({ dadosOrigem }) => {
  const { goDadosPlano } = usePrevNavigation();

  const mensagemInforme = tryGetValueOrDefault(
    [dadosOrigem?.mensagens?.[0]?.descricao],
    '-',
  );
  return (
    <TRANSF_VGBL_STYLES.ModalTransferenciaInterna
      size={ENUM_TYPES.ModalInfoPredefinitionSize.MD}
      show
      showCloseButton={false}
      data-testid="modalInformeDadosOrigem"
    >
      <TRANSF_VGBL_STYLES.Text margin color="secondary" fontWeight="bold">
        <Icon name="warning" /> Importante!
      </TRANSF_VGBL_STYLES.Text>
      <Text variant="body02-sm" margin>
        {mensagemInforme}
      </Text>
      <Display justify="center">
        <Button variant="secondary" onClick={goDadosPlano}>
          Certo, entendi!
        </Button>
      </Display>
    </TRANSF_VGBL_STYLES.ModalTransferenciaInterna>
  );
};

export default ModalInformeDadosOrigem;
