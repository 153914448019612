// @ts-nocheck
import { PECOS } from 'previdencia/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { IPayloadValidar, IResponseValidar } from '../types/IFundos';

const useValidarTransferencia = (
  numeroSolicitacao?: string,
  codigoAgencia?: string,
) => {
  const { response, loading, fetchData } = usePeco<
    IPayloadValidar,
    IResponseValidar
  >({
    api: {
      operationPath: PECOS.ValidarMultiFundoPj,
    },
    autoFetch: true,
    payload: {
      numeroSolicitacao,
      codigoAgencia,
    },
  });

  return { response, loading, fetchData };
};

export default useValidarTransferencia;
