// @ts-nocheck
export const quitacaoAnualDate = (): string[] => {
  const anos = [];
  const atual = new Date();
  const anoPassado = atual.getFullYear() - 1;

  for (let i = 0; i < 5; i += 1) {
    const ano = anoPassado - i;
    anos.push(ano.toString());
  }

  return anos;
};

export const anoValido = (ano?: string): boolean => !!ano?.match(/^\d{4}$/i);

export const formataDataQuitacao = (data?: string) => data?.replace(/-/gi, '/');
