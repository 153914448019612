// @ts-nocheck
import RenderConditional from 'main/components/RenderConditional';
import {
  Card,
  Text,
  Display,
  Grid,
  Select,
  Button,
} from '@cvp/design-system/react';
import RelatorioInadimplenciaAgencia from 'painelInadimplencia/pages/RelatorioAgencia';
import RelatorioInadimplenciaHR from 'painelInadimplencia/pages/RelatorioHierarquia';
import { ChangeEvent } from 'react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { required } from 'main/features/Validation/validations';
import {
  PeriodicidadePagamentoFilter,
  TiposVisao,
  SEGMENTO,
} from 'painelInadimplencia/constants/constants';
import Tag from 'main/components/PEComponents/Tag';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import useRelatorioInadimplencia from 'painelInadimplencia/hooks/useRelatorioInadimplencia';
import { IDadosInadimplenciaPayload as payloadRequest } from 'painelInadimplencia/types/ConsultarInadimplenciaRequest';
import useSegmento from 'painelInadimplencia/hooks/useSegmento';

const RelatorioInadimplencia = () => {
  const { validarSegmentoRota } = useSegmento();

  const {
    tipoVisao,
    unidade,
    setPayloadConsulta,
    payloadConsulta,
    filterFormaPagamento,
    loading,
    handleConsulta,
    dadosInadimplencia,
    handleBackStep,
    clearFilters,
    isDisabled,
  } = useRelatorioInadimplencia();

  return (
    <>
      <Display type="display-block">
        <Card>
          <Card.Content>
            <Display type="flex">
              <Text variant="headline-04" color="primary">
                <RenderConditional
                  condition={validarSegmentoRota(SEGMENTO.VIDA)}
                >
                  Painel de Inadimplência Vida
                </RenderConditional>

                <RenderConditional
                  condition={validarSegmentoRota(SEGMENTO.PREV)}
                >
                  Ausência de Contribuição de Previdência
                </RenderConditional>
              </Text>
            </Display>
            <Grid>
              <Grid.Item justify="space-between">
                <Text variant="body02-sm" color="primary">
                  Tipo Visão : <Tag value={tipoVisao} />
                </Text>
              </Grid.Item>
              <Grid.Item justify="space-between">
                <Text variant="body02-sm" color="primary">
                  Unidade : <Tag value={unidade} variant="secondary" />
                </Text>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>
      <Display type="display-block">
        <Card>
          <Card.Content>
            <Grid>
              <RenderConditional condition={tipoVisao === TiposVisao.AG}>
                <Grid.Item lg={1 / 4} xs={1}>
                  <Select
                    dettach
                    validationRules={[required()]}
                    label="Periodicidade de Pagamento"
                    placeholder="Escolha a opção"
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      const selectedValue = event.target.value;
                      setPayloadConsulta((prevState: payloadRequest) => ({
                        ...prevState,
                        PeriodicidadePagamento: selectedValue,
                      }));
                    }}
                  >
                    {PeriodicidadePagamentoFilter.map(item => (
                      <Select.Item
                        key={item.key}
                        text={item.label}
                        value={item.value}
                        selected={
                          item.value === payloadConsulta?.PeriodicidadePagamento
                        }
                      />
                    ))}
                  </Select>
                </Grid.Item>
                <Grid.Item lg={1 / 4} xs={1}>
                  <Select
                    dettach
                    validationRules={[required()]}
                    label="Forma de Pagamento"
                    placeholder="Escolha a opção"
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      const selectedValue = event.target.value;
                      setPayloadConsulta((prevState: any) => ({
                        ...prevState,
                        FormaPagamento: selectedValue,
                      }));
                    }}
                  >
                    {filterFormaPagamento().map(item => (
                      <Select.Item
                        key={item.key}
                        text={item.value}
                        value={item.value}
                        selected={
                          item.value === payloadConsulta?.FormaPagamento
                        }
                      />
                    ))}
                  </Select>
                </Grid.Item>
              </RenderConditional>
            </Grid>
            <Display>
              <Button
                disabled={checkIfSomeItemsAreTrue([loading, isDisabled])}
                type="submit"
                variant="outlined"
                onClick={() => handleBackStep()}
              >
                Voltar
              </Button>
              <RenderConditional
                condition={tipoVisao === TiposVisao.AG}
                component={
                  <Button
                    data-testid="limpar-filtros"
                    variant="outlined"
                    onClick={clearFilters}
                  >
                    Limpar filtros
                  </Button>
                }
              />
            </Display>

            <RenderConditional condition={loading}>
              <SkeletonLoading lines={8} />
            </RenderConditional>

            <RenderConditional condition={!loading}>
              <RenderConditional
                condition={
                  dadosInadimplencia?.entidade?.tipoHierarquia !== TiposVisao.AG
                }
              >
                <RelatorioInadimplenciaHR
                  setpayloadUnidade={setPayloadConsulta}
                  handleConsultar={handleConsulta}
                  loading={loading}
                  data={dadosInadimplencia?.entidade}
                />
              </RenderConditional>
              <RenderConditional
                condition={
                  dadosInadimplencia?.entidade?.tipoHierarquia === TiposVisao.AG
                }
              >
                <RelatorioInadimplenciaAgencia
                  handleConsultar={handleConsulta}
                  loading={loading}
                  data={dadosInadimplencia?.entidade}
                />
              </RenderConditional>
            </RenderConditional>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};

export default RelatorioInadimplencia;
