// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Divider, Text, Button } from '@cvp/design-system/react';
import { Display } from 'main/features/Auth/components';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { converterBase64 } from 'main/utils/converterBase64';
import { IResponseAcaoCoberturas } from '../../types/AcaoCoberturas.types';
import ListaInformacoesComprovante from '../ListaInformacoesComprovante';
import TextoAviso from '../TextoAviso';
import useObterComprovanteAtivacaoSuspensao from '../../hooks/useObterComprovanteAtivacaoSuspensao';
import {
  parametrosEnvioEmailFactory,
  tipoEmailFactory,
} from '../../factories/paramentroEnvioEmailFactory';
import { CoberturaContratada } from '../../types/CoberturasContratadas.types';
import { textoAviso } from '../../utils/texts';
import { EnumStatusAtivacaosuspensao } from '../../types/EnumsStatusAtivacaoSuspensao.types';

type ComprovanteAcaoContribuicaoProps = {
  dadosComprovante: IResponseAcaoCoberturas | undefined;
  dadosPlano: CoberturaContratada | undefined;
  codRequisicao: string;
  primeiraEtapa: () => void;
};

const ComprovanteAcaoContribuicao: React.FC<
  ComprovanteAcaoContribuicaoProps
> = ({ dadosComprovante, dadosPlano, primeiraEtapa, codRequisicao }) => {
  const suspensaoRealizada =
    dadosComprovante?.dadosBeneficio?.status ===
    EnumStatusAtivacaosuspensao.Suspenso;
  const [manualDownload, setManualDownload] = useState<boolean>(true);
  const idRequisicaoRequest = dadosComprovante?.idManutencaoCerificado || '';
  const [loading, setLoading] = useState(false);
  const payloadComprovante = {
    codigoRequisicao: codRequisicao,
    idRequisicao: idRequisicaoRequest,
  };
  const {
    response: responseComprovante,
    fetchData: obterComprovanteAtivacaoSuspensao,
  } = useObterComprovanteAtivacaoSuspensao(payloadComprovante);
  const data = responseComprovante?.entidade;
  useEffect(() => {
    if (data) {
      const blob = converterBase64(data.comprovante);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
    setLoading(false);
    setManualDownload(true);
  }, [data]);

  useEffect(() => {
    setLoading(true);
    setManualDownload(false);
    obterComprovanteAtivacaoSuspensao();
  }, [dadosComprovante?.idManutencaoCerificado]);

  const objetoEmail = {
    tipoEmail: tipoEmailFactory(dadosComprovante),
    parametrosEnvio: parametrosEnvioEmailFactory(dadosComprovante, dadosPlano),
  };
  return (
    <>
      <Text variant="body02-sm" color="text" margin>
        {textoAviso(suspensaoRealizada, dadosPlano?.tipo || '')}
      </Text>
      <ListaInformacoesComprovante
        dadosComprovante={dadosComprovante}
        status={dadosComprovante?.dadosBeneficio?.status || ''}
      />

      <TextoAviso
        tipo={dadosComprovante?.dadosBeneficio?.tipoBeneficio}
        suspensao={suspensaoRealizada}
        cancelamento
      />
      <Divider />
      <Text variant="body01-sm" margin>
        Imprima a confirmação em 3 vias, solicite a assinatura do titular e
        envie uma delas à Caixa Vida e Previdência, pelo malote laranja.
      </Text>
      <Display className="hide-print">
        <Button
          variant="outlined"
          onClick={() => {
            window.location.reload();
            primeiraEtapa();
          }}
          style={{ marginRight: 20 }}
        >
          Voltar
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            obterComprovanteAtivacaoSuspensao();
            setManualDownload(!manualDownload);
          }}
          loading={loading}
          disabled={!manualDownload}
        >
          Imprimir
        </Button>
        <EmailSenderModal objetoEmail={objetoEmail} />
      </Display>
    </>
  );
};

export default ComprovanteAcaoContribuicao;
