// @ts-nocheck
import jwt, { JwtPayload } from 'jwt-decode';
import { IAuthState, IUser } from 'main/features/Auth/interfaces';
import {
  getLocalItem,
  removeLocalItem,
  setLocalItem,
} from 'main/utils/storage';
import {
  obterAgenciaDigital,
  obterChaveArmazenamentoMetadadosUsuario,
  obterChaveArmazenamentoNomeAcesso,
  obterChaveArmazenamentoPermissoesUsuario,
  obterChaveArmazenamentoSessionId,
  obterChaveArmazenamentoTokenExpiresIn,
} from '../../../../config/storage_keys';

type JwtInfo = {
  Company: string;
  role: string;
  DigitalAgency: string;
} & JwtPayload;

const storageKeyPermissoesUsuario = obterChaveArmazenamentoPermissoesUsuario();
const storageKeyNomeAcesso = obterChaveArmazenamentoNomeAcesso();
const storageKeyTokenExpires = obterChaveArmazenamentoTokenExpiresIn();
const storageKeyMetadadosUsuario = obterChaveArmazenamentoMetadadosUsuario();
const storageKeySessionId = obterChaveArmazenamentoSessionId();
const storageKeyDigitalAgency = obterAgenciaDigital();

const getAuthData = (): IAuthState => {
  const expiresIn = getLocalItem<string>(storageKeyTokenExpires) ?? '';
  const user = getLocalItem<IUser>(storageKeyMetadadosUsuario) ?? ({} as IUser);
  const sessionId = getLocalItem<string>(storageKeySessionId) ?? '';
  const digitalAgency = getLocalItem<boolean>(storageKeyDigitalAgency) ?? false;

  return { user, tokenInfo: { expiresIn }, sessionId, digitalAgency };
};

const getExpiresIn = (token: string): Date => {
  let tokenexpiration = new Date();
  try {
    const jwtInfo = jwt<JwtInfo>(token ?? '');
    if (jwtInfo.DigitalAgency !== 'False') {
      setLocalItem(storageKeyDigitalAgency, true);
    }
    if (jwtInfo && jwtInfo.exp) {
      tokenexpiration = new Date(jwtInfo.exp * 1000);
    }
  } catch (err) {
    tokenexpiration.setSeconds(new Date().getSeconds() + 3600);
  }

  return tokenexpiration;
};

export const saveUserSessionInfo = (user?: IUser): void => {
  if (user) {
    setLocalItem(
      storageKeyTokenExpires,
      getExpiresIn(user?.marcadorControle).toString(),
    );
    setLocalItem(storageKeyMetadadosUsuario, user);
    setLocalItem(
      storageKeySessionId,
      jwt<JwtPayload>(user.marcadorControle).jti,
    );
  }
};

export const destroySession = (): void => {
  sessionStorage.clear();
  localStorage.clear();
};

export const getDataFromKey = (pgppk: string, keyRsa: string): string => {
  let dataret = '';
  for (let g = 0; g < keyRsa.length; g += 4) {
    dataret += pgppk.substr(Number(keyRsa.substr(g, 4)), 1);
  }
  return dataret;
};

export const setUserName = (nomeAcesso: string): void =>
  setLocalItem(storageKeyNomeAcesso, nomeAcesso);

export const getUserName = (): string =>
  getLocalItem<string>(storageKeyNomeAcesso) ?? '';

export const isMarcadorControleValid = (): boolean => {
  const dateStored = getLocalItem<string>(storageKeyTokenExpires);

  if (!dateStored) return false;

  const expireIn = Date.parse(dateStored);

  return expireIn > Date.now();
};

export const setPermissionsInSession = (permissions: string[]) => {
  setLocalItem(storageKeyPermissoesUsuario, permissions);
};

export const getPermissionsInSession = () => {
  return getLocalItem<string[]>(storageKeyPermissoesUsuario) ?? [];
};

export const removePermissions = () => {
  removeLocalItem(storageKeyPermissoesUsuario);
};
export default getAuthData;
