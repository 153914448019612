// @ts-nocheck
import React, { useState } from 'react';
import { Display } from '@cvp/design-system/react';
import { PERGUNTAS_FREQUENTES } from 'reter/features/retencao/constants/constants';
import AccordionFaqFiltro from 'reter/features/retencao/components/AccordionFaqFiltro';
import AccordionFaq from 'reter/features/retencao/components/AccordionFaq/index';
import { IFilteredSearch } from 'reter/features/retencao/types/IFilteredSearch';

const FaqRetencao: React.FC = () => {
  const [search, setSearch] = useState<string>('');

  const filteredSearch: IFilteredSearch[] =
    search.length > 0
      ? PERGUNTAS_FREQUENTES.filter(pergunta =>
          pergunta.titulo.toLocaleLowerCase().includes(search),
        )
      : PERGUNTAS_FREQUENTES;

  return (
    <Display type="display-block">
      <AccordionFaqFiltro
        search={search}
        setSearch={setSearch}
        filteredSearch={filteredSearch}
      />
      <AccordionFaq filteredSearch={filteredSearch} />
    </Display>
  );
};

export default FaqRetencao;
