// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import React from 'react';
import { ResponseHistoricoSolicitacoes } from '../types/HistoricoSolicitacoes';

export const filtrarPorTipoSolicitacao = (
  solicitacoes: ResponseHistoricoSolicitacoes[] | undefined,
  tipoSolicitacao: string,
): ResponseHistoricoSolicitacoes[] | undefined => {
  if (tipoSolicitacao !== '') {
    return solicitacoes?.filter(e => {
      return e.tipoRequisicao === tipoSolicitacao;
    });
  }
  return solicitacoes;
};

export const resgatarPeriodoUmAno = () => {
  const dataAtual = new Date();
  const ano = dataAtual.getFullYear() - 1;
  const mes = dataAtual.getMonth() + 1;
  const dia = dataAtual.getUTCDate() + 1;

  if (mes < 10 && dia > 9) {
    return `${ano}-0${mes}-${dia}`;
  }
  if (mes < 10 && dia < 10) {
    return `${ano}-0${mes}-0${dia}`;
  }
  if (mes > 9 && dia < 10) {
    return `${ano}-${mes}-0${dia}`;
  }

  return `${ano}-${mes}-${dia}`;
};

export const verificarBotaoClicado = (
  idRequisicaoRequest: string,
  idRequisicao: string,
  fetching: boolean,
): boolean => {
  return idRequisicaoRequest === idRequisicao && fetching;
};

export const verificarBotaoDesabilitado = (
  idRequisicaoRequest: string,
  idRequisicao: string,
  fetching: boolean,
): boolean => {
  return idRequisicaoRequest !== idRequisicao && fetching;
};

export const getCustomButtonByCondition = (
  conditions: boolean[],
  customButtons: React.ReactElement[],
): React.ReactElement | null => {
  return conditions.every(Boolean) ? (
    <>
      {customButtons.map(button => (
        <>{button}</>
      ))}
    </>
  ) : null;
};
