// @ts-nocheck
import React from 'react';
import { Tag } from '@cvp/design-system/react';
import { tipoRendaSimuladaMapper } from 'previdencia/utils/previdenciaMappers';

type statusRendaProps = { [key: string]: string };

const statusRendaColors: statusRendaProps = {
  A: 'primary',
};

const TagStatusRenda: React.FC<{ status: string }> = ({ status }) => {
  return (
    <Tag
      variant={statusRendaColors[status]}
      value={tipoRendaSimuladaMapper(status)}
    />
  );
};

export default TagStatusRenda;
