// @ts-nocheck
import { api, IApiResponse } from 'main/services';
import { obterEndpointSolicitarAssinaturaBeneficioProtecao } from 'previdencia/config/endpoints';
import {
  SolicitarAssinaturaBeneficioProtecaoApiProps,
  SolicitarAssinaturaBeneficioProtecaoResponse,
} from '../types/SolicitarAssinaturaBeneficioProtecao';

export const solicitarAssinaturaBeneficioProtecao = async (
  request: SolicitarAssinaturaBeneficioProtecaoApiProps,
) => {
  const { data } = await api.post<
    IApiResponse<SolicitarAssinaturaBeneficioProtecaoResponse>
  >(obterEndpointSolicitarAssinaturaBeneficioProtecao(), request);

  if (data?.dados.sucesso === false)
    throw new Error('Não foi possível solicitar a assinatura');

  return data.dados.entidade;
};
