// @ts-nocheck
import styled from 'styled-components/macro';

import DataTable from 'main/components/Table';
import RiskBar from 'previdencia/components/RiskBar';

export const RiskBarFundos = styled(RiskBar)(() => ({
  minWidth: '100px',
  minHeight: '60px',
}));

export const ContainerResumo = styled.div({
  marginBottom: '15px',
});

export const Table = styled(DataTable)`
  .rdt_table div {
    border-radius: '10px';
  }
`;

export const TabelaDistribuicaoFundos = styled(DataTable)(({ theme }) => ({
  '.rdt_TableHeadRow': {
    textAlign: 'center',
  },
}));

export const ContainerResumoCertificadoAssinatura = styled.div({
  textAlign: 'center',
});

export const ComprovanteSection = styled.div(({ theme: { color } }) => ({
  border: '1px dotted #000',
  padding: '20px',
  width: '500px',
  marginTop: 20,
}));
