// @ts-nocheck
import { TooltipModalWrapper } from 'main/components/Wrappers/TooltipModalWrapper';
import { TEXTO_TOOLTIP_RESERVA } from 'previdencia/constants/texts';
import { CellTypeTabelaFundos } from 'previdencia/types/Tabelas';

export const COLUNAS_TABELA_FUNDOS_PJ = [
  {
    selector: 'check',
    wrap: true,
    width: '50px',
    name: '',
  },
  {
    name: 'Fundo',
    selector: 'descricaoFundo',
    minWidth: '380px',
    cell: ({ descricaoFundo }: CellTypeTabelaFundos) => (
      <p title={descricaoFundo}>{descricaoFundo}</p>
    ),
  },
  {
    name: 'Perfil do Risco',
    selector: 'perfil',
    minWidth: '180px',
    maxWidth: '200px',
  },
  {
    name: 'Rentabilidade (últimos 12 meses)',
    selector: 'rentabilidade',
    minWidth: '100px',
    maxWidth: '170px',
  },
  {
    name: 'Saldo',
    selector: 'valorSaldo',
    minWidth: '150px',
  },
];

export const COLUNAS_TABELA_REDISTRIBUIR_FUNDOS_PJ = [
  {
    selector: 'check',
    width: '50px',
    wrap: true,
    name: '',
  },
  {
    name: 'Fundo',
    selector: 'descricaoFundo',
    cell: ({ descricaoFundo }: CellTypeTabelaFundos) => (
      <p title={descricaoFundo}>{descricaoFundo}</p>
    ),
    minWidth: '35vw',
  },
  {
    selector: 'valor_contribuicao',
    minWidth: '180px',
    name: 'Valor Contribuição',
  },
];

export const COLUNAS_TABELA_RESUMO_FUNDOS_PJ = [
  {
    selector: 'descricaoFundo',
    name: 'Fundo',
    minWidth: '550px',
    cell: ({ descricaoFundo }: CellTypeTabelaFundos) => (
      <p title={descricaoFundo}>{descricaoFundo}</p>
    ),
  },
  {
    selector: 'descricaoPerfilFundo',
    name: 'Perfil do Risco',
    minWidth: '180px',
  },
  {
    name: 'Valor da Retirada',
    minWidth: '150px',
    selector: 'valorRetirada',
  },
];

export const COLUNAS_TABELA_EXPANSIVEL_MULTIFUNDOS_PJ = [
  {
    name: (
      <TooltipModalWrapper left={100} top={0} title="Reserva">
        {TEXTO_TOOLTIP_RESERVA}
      </TooltipModalWrapper>
    ),
    width: '120px',
    selector: 'descricaoReserva',
  },
  {
    name: 'Saldo',
    width: '150px',
    selector: 'saldo',
  },
  {
    selector: 'tipo_transferencia',
    name: 'Tipo Transferência',
    maxWidth: '250px',
  },
  {
    name: 'Valor R$',
    selector: 'valor_input',
    maxWidth: '250px',
  },
];

export const COLUNAS_DESTINO_EXPANSIVEL_MULTIFUNDOS_PJ = [
  {
    name: (
      <TooltipModalWrapper left={100} top={0} title="Reserva">
        {TEXTO_TOOLTIP_RESERVA}
      </TooltipModalWrapper>
    ),
    width: '120px',
    selector: 'descricaoReserva',
  },
  {
    selector: 'saldo',
    name: 'Saldo',
    width: '150px',
  },
  {
    name: 'Valor a transferir R$',
    maxWidth: '250px',
    selector: 'valor_input',
  },
];
