// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { useContext } from 'react';
import { ResponseAlteracaoDiaVencimento } from '../types/AlteracaoDiaVencimento';
import { IRequestAtualizarDiaVencimento } from '../types/IRequestAtualizarDiaVencimento';

const useAtualizarDataVencimento = (diaVencimento: string) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IRequestAtualizarDiaVencimento,
    ResponseAlteracaoDiaVencimento
  >({
    api: { operationPath: PECOS.AtualizarDataVencimento },
    autoFetch: false,
    payload: {
      numeroCertificado: numCertificado,
      cpfCnpj,
      dataVencimento: diaVencimento,
    },
  });

  return { loading, response, fetchData };
};

export default useAtualizarDataVencimento;
