// @ts-nocheck
import React from 'react';
import withValidation from 'main/features/Validation/hocs/withValidation';
import ValidableProps from 'main/features/Validation/types/ValidableProps';
import InputMask from 'main/types/InputMask';
import masks from 'main/utils/masks';
import * as S from './styles';

interface InputProps extends ValidableProps<string> {
  inputMask?: InputMask;
  label?: string;
  error?: boolean;
  placeholder?: string;
  type?: string;
  maxLength?: string;
  disabled?: boolean;
  legend?: string;
  hidden?: boolean;
  handleKeyPress?: (e: {
    charCode: number;
    preventDefault: () => void;
  }) => void;
}

const Input: React.FC<InputProps> = ({
  link,
  disabled,
  inputMask: { mask, unmask } = masks.none,
  legend,
  hidden,
  handleKeyPress,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    link.set({
      value: unmask(inputValue),
      isValid: true,
      errorMsg: '',
    });
  };
  const hasError = !link.get().isValid;
  return (
    <S.Container>
      <S.Input
        {...props}
        value={mask(link.get().value)}
        disabled={disabled}
        hidden={hidden}
        onChange={handleChange}
        error={hasError}
        errorMessage={link.get().errorMsg}
        onKeyPress={handleKeyPress}
      />
      {!hidden && legend && <S.Legend isValid={!hasError}> {legend} </S.Legend>}
    </S.Container>
  );
};

Input.defaultProps = {
  inputMask: masks.none,
  label: '',
  error: false,
  placeholder: '',
  type: 'text',
  maxLength: '',
  disabled: false,
  legend: '',
  hidden: false,
};

export default withValidation<InputProps, string>(Input);
