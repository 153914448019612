// @ts-nocheck
import { useState } from 'react';

/**
 * Hook para gerenciar o foco de inputs dentro de uma tabela(React-datatable-component).
 *
 * @returns {{
 *   selectedTableInputFocus: string; // Estado atual do foco do input
 *   resetarInputFocus: () => void;   // Função para resetar o foco do input
 *   atribuirParametroInputFocus: (value: string) => void; // Função para atribuir um valor ao foco do input
 * }}
 */
const useTableInputFocus = () => {
  const [selectedTableInputFocus, setSelectedTableInputFocus] =
    useState<string>('');

  const resetarInputFocus = (): void => {
    setSelectedTableInputFocus('');
  };

  const atribuirParametroInputFocus = (value: string): void => {
    setSelectedTableInputFocus(value);
  };

  return {
    selectedTableInputFocus,
    resetarInputFocus,
    atribuirParametroInputFocus,
  };
};

export default useTableInputFocus;
