// @ts-nocheck
import { ISolicitarAssinaturaRequest } from '../types/ISolicitarAssinaturaRequest';

export const construirPayloadAssinaturaRequest = (
  payload: ISolicitarAssinaturaRequest,
) => ({
  ...payload,
  codigoEmpresa: '500',
  tipoEnvio: 'SMS',
  tipoAssinatura: 'TRANSFUND',
});
