// @ts-nocheck
import { IMenuSubItem } from 'main/components/Menu/Sidebar/types';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';

export const useFeatureAuthorizer = () => {
  const verifyRoles = (rolesToCheck: string[], userRolesToCheck: string[]) => {
    if (checkIfAllItemsAreTrue([!!rolesToCheck, rolesToCheck?.length > 0])) {
      return tryGetValueOrDefault([userRolesToCheck], [])
        .map(role => rolesToCheck?.includes(role))
        .some(atLeastOneItsTrue => atLeastOneItsTrue);
    }
    return false;
  };

  const verifyPermissions = (
    permissionsToCheck: string[] = [],
    userPermissionsToCheck: string[] = [],
  ) => {
    if (permissionsToCheck?.length === 0) return true;
    return verifyRoles(permissionsToCheck, userPermissionsToCheck);
  };

  const verifySubitens = (
    items: IMenuSubItem[] = [],
    userRolesToCheck: string[] = [],
    userPermissionsToCheck: string[] = [],
  ) => {
    const permissoesCheck: boolean[] = [];
    items.forEach(rolesToCheck => {
      return permissoesCheck.push(
        checkIfAllItemsAreTrue([
          verifyRoles(rolesToCheck.roles ?? [], userRolesToCheck),
          verifyPermissions(rolesToCheck.permissions, userPermissionsToCheck),
        ]),
      );
    });
    return checkIfSomeItemsAreTrue(permissoesCheck);
  };

  return {
    verifyRoles,
    verifyPermissions,
    verifySubitens,
  };
};
