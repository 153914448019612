// @ts-nocheck
import { useContext } from 'react';

import { Display, Text } from '@cvp/design-system/react';
import * as S from '../styles';
import Icon from 'main/components/Icon';
import { AppContext } from 'main/contexts/AppContext';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { TTransferenciaOrigemDestinoProps } from './types';

const TransferenciaOrigemDestino = (
  props: TTransferenciaOrigemDestinoProps,
) => {
  const { cliente } = useContext(AppContext);
  return (
    <>
      <Text variant="headline-05" color="primary" margin>
        Transferência com alteração de taxa
      </Text>
      <S.SectionTitle>
        <strong>Emissão do novo certificado</strong>
      </S.SectionTitle>
      <Display alignItems="center" style={{ marginTop: 20 }}>
        <div>
          <Text variant="caption" margin>
            <strong> Certificado Origem</strong>
          </Text>
          <br />
          <Text variant="caption" margin align="center">
            {tryGetValueOrDefault([cliente?.numCertificado], '-')}
          </Text>
        </div>
        <S.IconTransfer>
          <Icon name="arrowRight" />
        </S.IconTransfer>
        <div>
          <Text variant="caption" margin>
            <strong> Certificado Destino</strong>
          </Text>
          <br />
          <Text variant="caption" margin align="center">
            {getTernaryResult(!!props?.erro, '-', 'Em processamento')}
          </Text>
        </div>
      </Display>
      <br />
    </>
  );
};

export { TransferenciaOrigemDestino };
