// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import { TextoInformativoSimulacaoRendaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import * as S from 'previdencia/features/SimulacaoRenda/components/TextoInformativoSimulacaoRenda/styles';

const TextoInformativoSimulacaoRenda: React.FC<
  TextoInformativoSimulacaoRendaProps
> = ({ codigoTipoPagamento }) => {
  const exibirMsgGozoBeneficio = (): boolean => {
    return (
      codigoTipoPagamento ===
        EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido ||
      codigoTipoPagamento ===
        EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario
    );
  };

  return (
    <S.WrapperWarning>
      <Text variant="body02-lg" margin>
        IMPORTANTE
      </Text>

      <RenderConditional condition={exibirMsgGozoBeneficio()}>
        <Text variant="body02-sm" margin>
          Caso já esteja recebendo a renda (gozo de benefício), o cliente não
          poderá alterar o beneficiário, mesmo que este venha a falecer.
        </Text>
      </RenderConditional>

      <Text variant="body02-sm" margin>
        Os cálculos e as hipóteses aqui apresentados são apenas estimativas, não
        se constituindo em garantia ou obrigação da Caixa Vida e Previdência. Os
        valores estão sujeitos a alteração. A taxa de rentabilidade considerada
        para a estimativa de renda futura é de 6% ao ano.
      </Text>
      <Text variant="body02-sm" margin>
        A renda/benefício está sujeita a tributação de Imposto de Renda,
        conforme a legislação fiscal vigente.
      </Text>
      <Text variant="body02-sm">Esta simulação ficará disponível por 24h.</Text>
    </S.WrapperWarning>
  );
};

export default TextoInformativoSimulacaoRenda;
