// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import * as REQUEST_TYPES from 'contratosPrestamista/features/reenvioDpsEletronica/types/ConsultaDpsRequest';
import * as RESPONSE_TYPES from 'contratosPrestamista/features/reenvioDpsEletronica/types/ConsultaDpsResponse';

export const usePecoRecuperarListaDps = () => {
  const {
    loading: loadingListaDps,
    fetchData: fetchDataListaDps,
    response: dadosListaDps,
  } = usePeco<
    REQUEST_TYPES.IRequestRecuperarListaDps,
    RESPONSE_TYPES.IResponseRecuperarListaDps
  >({
    api: {
      operationPath: PECOS_PRESTAMISTA.RecuperarListaDps,
    },
    autoFetch: true,
  });

  return {
    loadingListaDps,
    fetchDataListaDps,
    dadosListaDps: tryGetValueOrDefault(
      [dadosListaDps?.entidade?.solicitacoesDps],
      [],
    ),
  };
};
