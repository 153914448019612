// @ts-nocheck
import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { IRequestSolicitarReenvioLinkPorEmailDps } from '../types/IRequestSolicitarReenvioLinkPorEmailDps';
import { IResponseSolicitarReenvioLinkPorEmailDps } from '../types/IResponseSolicitarReenvioLinkPorEmailDps';
import {
  IRequestConsultarStatusDps,
  IResponseConsultarStatusDps,
} from '../types/IRequestConsultarStatusDps';

export const usePecoReenviarLinkDps = () => {
  const { loading: loadingReenviarLink, fetchData: fetchDataReenviarLink } =
    usePeco<
      IRequestSolicitarReenvioLinkPorEmailDps,
      IResponseSolicitarReenvioLinkPorEmailDps
    >({
      api: {
        operationPath: PECOS_PRESTAMISTA.SolicitarReenvioLinkPorEmailDps,
      },
    });

  const {
    loading: loadingConsultarStatusDps,
    fetchData: fetchConsultarStatusDps,
  } = usePeco<IRequestConsultarStatusDps, IResponseConsultarStatusDps>({
    api: {
      operationPath: PECOS_PRESTAMISTA.ConsultarStatusDps,
    },
  });

  return {
    loadingReenviarLink,
    fetchDataReenviarLink,
    loadingConsultarStatusDps,
    fetchConsultarStatusDps,
  };
};
