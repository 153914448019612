// @ts-nocheck
import { DetalhesPortabilidade } from '../types/DetalhesPortabilidade';

export const extractEmailFromResponse = (
  dados?: DetalhesPortabilidade,
): string => {
  return (
    (dados?.cliente.emails ?? []).find(email => email.principal === true)
      ?.enderecoEmail ?? ''
  );
};

export const extractPhoneNumberFromReponse = (
  dadosPortabilidade?: DetalhesPortabilidade,
) =>
  (dadosPortabilidade?.cliente.telefones ?? []).find(
    telefone => telefone.principal === true,
  )?.numeroTelefone ?? '';
