// @ts-nocheck
import {
  PayloadContribuicoes,
  ResponseContribuicoes,
} from '../types/ResponseContribuicoes';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { obterProximoMes } from '../utils/contribuicoes';

const useObterContribuicoes = (Cpf: string, numeroCertificado: string) => {
  const { loading, response } = usePeco<
    PayloadContribuicoes,
    ResponseContribuicoes[] | undefined
  >({
    api: { operationPath: PECOS.ObterPagamento },
    payload: {
      numeroCertificado,
      Cpf,
      DataVencimentoInicial: new Date('1900-01-01').toISOString(),
      DataVencimentoFinal: obterProximoMes(),
    },

    autoFetch: true,
    cache: true,
    cacheKey: `${numeroCertificado}`,
  });

  const data = response?.entidade;

  return {
    data,
    loading,
  };
};

export default useObterContribuicoes;
