// @ts-nocheck
import styled from 'styled-components/macro';
import {
  Display as DisplayDS,
  Text as TextDS,
  Grid as GridDS,
} from '@cvp/design-system/react';

export const ContainerData = styled(DisplayDS)`
  display: flex;
  flex-direction: column;

  h6,
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const TitlePage = styled(TextDS)`
  margin-bottom: 40px;
`;

export const GridContainerForm = styled(GridDS)<{
  marginTop?: string | number;
}>(({ theme: { color }, marginTop }) => ({
  marginTop,

  '& input::placeholder': {
    color: color.neutral['04'],
  },

  '& input': {
    height: '40px',
  },
}));

export const ContainerCheckOption = styled(TextDS)`
  text-align: left;

  label {
    margin-right: 5px;
  }
`;
