// @ts-nocheck
import { Display, Grid, Text, Button } from '@cvp/design-system/react';

import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as CONSTS from 'previdencia/features/AlteracaoRegimeTributario/constants/constants';

const MensagemSucessoAlteracao = () => {
  const { goDadosPlano } = usePrevNavigation();

  return (
    <Grid.Item xs={1}>
      <Display>
        <Text variant="body02-md" color="text" margin role="status">
          {CONSTS.TEXTOS_REGIME_TRIBUTARIO_MSG.SUCESSO}
        </Text>
      </Display>
      <Display>
        <Button
          variant="outlined"
          onClick={goDadosPlano}
          role="button"
          aria-label="Voltar"
        >
          Voltar
        </Button>
      </Display>
    </Grid.Item>
  );
};

export default MensagemSucessoAlteracao;
