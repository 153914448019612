// @ts-nocheck
import React, { useEffect } from 'react';
import { Card, Button, Grid, Display, Text } from '@cvp/design-system/react';
import Input from 'main/components/form/Input';
import masks from 'main/utils/masks';
import { validDateRange } from 'main/features/Validation/validations';
import useFieldLink from 'main/hooks/useFieldLink';

import { validate } from 'main/features/Validation/utils/validateRules';
import Calendar from 'main/components/form/Calendar';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import { useHistory } from 'react-router';
import { useToast } from 'main/hooks/useToast';
import { validaCpfCnpj } from 'main/utils/cpf_cnpj';
import { useQueryClient } from 'react-query';
import {
  KEY_STORAGE_DATA_STATUS_PROPOSTA_AGENCIA,
  KEY_STORAGE_DATA_STATUS_PROPOSTA_CPF,
} from '../config/reactQuery';

const ConsultaStatusProposta: React.FC = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { toastError } = useToast();
  const [cpfLink] = useFieldLink<string>('');
  const [dateLink, validateDateLink] = useFieldLink<CalendarData>({
    initialDate: null,
    finalDate: null,
  });

  const redirect = () => {
    history.push('/prestamista/proposta/status/consulta/resultado', {
      cpf: cpfLink.get().value,
      dataInicial: dateLink.get().value.initialDate,
      dataFinal: dateLink.get().value.finalDate,
    });
  };

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const cpfCnpj = cpfLink.get().value;
    const invalidCpfCnpj = validaCpfCnpj(cpfCnpj);

    if (cpfCnpj && invalidCpfCnpj) {
      toastError('CPF/CNPJ inválido!');
      return;
    }

    const dataValida = validate([validateDateLink]);

    if (dataValida) redirect();
  };

  const resetReactQueryQueries = () => {
    queryClient.invalidateQueries(KEY_STORAGE_DATA_STATUS_PROPOSTA_CPF);
    queryClient.invalidateQueries(KEY_STORAGE_DATA_STATUS_PROPOSTA_AGENCIA);
  };

  useEffect(() => {
    resetReactQueryQueries();
  }, []);

  return (
    <Card style={{ minHeight: 'auto' }}>
      <Card.Content data-testid="tabelaListaPropostas">
        <Display>
          <Text variant="caption-02" margin>
            Atenção! Campos com * são obrigatórios
          </Text>
        </Display>
        <form onSubmit={handleSubmit}>
          <Grid>
            <Grid.Item xs={1 / 3}>
              <Calendar
                link={dateLink}
                requiredFinalDate
                requiredInitialDate
                validationRules={[validDateRange()]}
                range
                placeholder="Selecione a data"
                data-testid="data-venda"
              />
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item xs={1 / 3}>
              <Input
                label="CPF/CNPJ"
                link={cpfLink}
                inputMask={masks.cpfCnpj}
              />
            </Grid.Item>
          </Grid>
          <Display>
            <Button type="submit" variant="primary">
              Consultar
            </Button>
          </Display>
        </form>
      </Card.Content>
    </Card>
  );
};

export default ConsultaStatusProposta;
