// @ts-nocheck
import Accordion from 'main/components/PEComponents/Accordion/Accordion';
import { Skeleton } from 'main/components/Table';
import { COLUNAS_TABELA_COBERTURAS } from 'prestamista/features/dadosSeguro/constants/Coverturas';
import React from 'react';

export const CoberturasSkeleton: React.FC = () => {
  return (
    <Accordion open>
      <Accordion.Item
        title="Coberturas"
        key="Coberturas"
        data-testid="skeleton-coberturas"
      >
        <Skeleton colunas={COLUNAS_TABELA_COBERTURAS} />
      </Accordion.Item>
    </Accordion>
  );
};
