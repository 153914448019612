// @ts-nocheck
import RenderConditional from 'main/components/RenderConditional';
import React from 'react';
import { PRINCIPAL_USERS } from '../config/userProfiles';
import { useAuth } from '../hooks';
import { useFeatureAuthorizer } from '../hooks/useFeatureAuthorizer';

const FeatureAuthorizer: React.FC<{
  requiredPermissions?: string[];
  requiredRoles?: string[];
  children?: React.ReactNode;
}> = ({
  requiredPermissions = [],
  children,
  requiredRoles = PRINCIPAL_USERS,
}) => {
  const { userPermissions, userRoles } = useAuth();
  const { verifyPermissions, verifyRoles } = useFeatureAuthorizer();

  return (
    <RenderConditional
      condition={
        verifyPermissions(requiredPermissions, userPermissions) &&
        verifyRoles(requiredRoles, userRoles)
      }
    >
      {children}
    </RenderConditional>
  );
};

export default FeatureAuthorizer;
