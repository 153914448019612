// @ts-nocheck
import React from 'react';
import { Button } from '@cvp/design-system/react';
import { useObterStatusDPS } from '../hooks/useObterStatusDPS';
import { ILinkDPSButtonProps } from '../interface/IResponseDps';

const LinkDPSButton: React.FC<ILinkDPSButtonProps> = ({
  row,
  handleSelecionarItem,
}) => {
  const isDisabled = !useObterStatusDPS(row.statusEnvioDPS);

  return (
    <Button
      disabled={isDisabled}
      onClick={() => handleSelecionarItem(row)}
      small
    >
      Reenviar Link
    </Button>
  );
};

export default LinkDPSButton;
