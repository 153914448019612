// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IPayloadAssinatura,
  IRequestAssinatura,
  IResponseAssinatura,
} from 'previdencia/types/ITransferirEntreFundos';
import { DefaultParamRequest } from 'previdencia/types/enum';
import { useAuth } from 'main/features/Auth/hooks';

export const useTransfenciaFundos = (
  dadosAssinatura: Partial<IRequestAssinatura>,
  numeroRequisicao?: string,
) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const { user } = useAuth();

  const { loading, response, fetchData } = usePeco<
    Partial<IPayloadAssinatura>,
    IResponseAssinatura
  >({
    api: {
      operationPath: PECOS.TransferenciaFundosPrevidencia,
    },
    payload: {
      cpf: cpfCnpj,
      codigoEmpresa: DefaultParamRequest.CODIGO_EMPRESA,
      codigoCertificado: numCertificado,
      numeroDocumento: numeroRequisicao,
      nomeEconomiario: user.nomeUsuario,
      matriculaEconomiario: user.nomeAcesso,
      ...dadosAssinatura,
    },
  });

  return {
    loading,
    response: response?.entidade,
    fetchData,
  };
};
