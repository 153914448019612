// @ts-nocheck
import styled from 'styled-components/macro';

export const ContainerLinhaExpansivel = styled.div({
  '.rdt_TableRow': {
    minHeight: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.rdt_TableCol': {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  },
  '.rdt_TableHeadRow': {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const ContainerSelectTipoTransferencia = styled.div({
  minWidth: '200px',
});

export const InputContainer = styled.div<{ show: boolean }>(({ show }) => ({
  marginTop: show ? '14px' : '0',
}));

export const LoadingContainer = styled.div({
  padding: '14px',
});
