// @ts-nocheck
import { obterEndpointAlterarDadosBancariosPVR } from 'reter/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { ResponsePosPVR } from 'reter/features/retencao/types/enum';

export const obterAtualizacaoDadosBancarios = async (
  params: any,
): Promise<string | undefined> => {
  const { data } = await api.post<IApiResponse<string>>(
    obterEndpointAlterarDadosBancariosPVR(),
    {
      codBancoFavorecido: params.codigoInstituicao,
      nomeBancoFavorecido: params.nomeInstituicao,
      codAgenciaFavorecido: params.agencia,
      dgAgenciaFavorecido: params.digitoAgencia,
      numContaFavorecido:
        tryGetValueOrDefault([params.operacao], ResponsePosPVR.DEFAULT_CONTA) +
        params.conta,
      dgContaFavorecido: params.digito,
      numResgate: params.numResgate,
      UserName: params.UserName,
      codEmpresa: ResponsePosPVR.COD_EMPRESA,
      codAgencia: ResponsePosPVR.COD_AGENCIA,
      justificativa: ResponsePosPVR.JUSTIFICATIVA,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw mensagemErro;

  return data?.dados?.mensagens?.[0].descricao;
};
