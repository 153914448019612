// @ts-nocheck
import { Modal } from '@cvp/design-system/react';
import React from 'react';
import { TextAlign } from '../styles';

type ModalDpsEletronicaProps = {
  open: boolean;
  handleClose: () => void;
};
const ModalDpsEletronica: React.FC<ModalDpsEletronicaProps> = ({
  open,
  handleClose,
}) => {
  return (
    <Modal show={open} onClose={handleClose}>
      <TextAlign variant="body03-sm" style={{ marginBottom: 25 }}>
        Prezado(a) Economiário,
      </TextAlign>
      <TextAlign variant="body03-sm">
        Temos novidade: <strong>DPS Eletrônica!</strong>
      </TextAlign>
      <TextAlign variant="body03-sm">
        A partir do dia <strong>12/01/24</strong>, a Declaração Pessoal de Saúde
        (DPS) para os produtos <strong>Prestamista</strong> será 100% digital,
        após a identificação da necessidade do envio do formulário.
      </TextAlign>
      <TextAlign variant="body03-sm" style={{ marginTop: 25 }}>
        Mais <strong>agilidade</strong> e mais <strong>praticidade.</strong>
      </TextAlign>
      <TextAlign variant="body03-sm" style={{ marginTop: 25 }}>
        Bons negócios!
      </TextAlign>
    </Modal>
  );
};

export default ModalDpsEletronica;
