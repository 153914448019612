// @ts-nocheck
import React from 'react';

import RenderConditional from 'main/components/RenderConditional';
import { removeEmptySpaceAndForceUpperCase } from 'main/utils/string';
import {
  checkIfAllItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  StatusRequisicaoComprovante,
  StatusPermitirCancelamento,
  CodigoTipoRequisicao,
} from 'previdencia/features/HistoricoSolicitacoes/types/enum';
import {
  TBotaoAcaoProps,
  TBotoesPorStatus,
} from 'previdencia/features/HistoricoSolicitacoes/types/BotaoAcoes';
import { ButtonCancelar } from 'previdencia/features/HistoricoSolicitacoes/components/TabelaMovimentacoes/ButtonCancelar';
import { ButtonBaixar } from 'previdencia/features/HistoricoSolicitacoes/components/TabelaMovimentacoes/ButtonBaixar';
import { ButtonReassinar } from 'previdencia/features/HistoricoSolicitacoes/components/TabelaMovimentacoes/ButtonReassinar';
import { FLAGS_FOPS } from 'previdencia/constants/constants';

const ButtonAcoes = (props: TBotaoAcaoProps): React.ReactElement | null => {
  const {
    onClose,
    idRequisicao,
    permiteCancelamento,
    tipoRequisicao,
    statusRequisicao,
    isFetching,
    idRequisicaoRequest,
    setIdRequisicaoRequest,
    setCodRequisicao,
  } = props;

  const tiposRequisicaoOmitir = [
    CodigoTipoRequisicao.ALTERACAO_ENDERECO_TEL_EMAIL,
  ];

  const shouldOmitButtons = tiposRequisicaoOmitir.includes(
    tipoRequisicao as CodigoTipoRequisicao,
  );

  const shouldRenderCancelar =
    permiteCancelamento === StatusPermitirCancelamento.SIM;

  const botoesPorStatus: TBotoesPorStatus = getTernaryResult(
    shouldOmitButtons,
    {} as TBotoesPorStatus,
    {
      [StatusRequisicaoComprovante.CONCLUIDA]: (
        <ButtonBaixar
          idRequisicao={idRequisicao}
          tipoRequisicao={tipoRequisicao}
          isFetching={isFetching}
          idRequisicaoRequest={idRequisicaoRequest}
          setIdRequisicaoRequest={setIdRequisicaoRequest}
          setCodRequisicao={setCodRequisicao}
        />
      ),
      [StatusRequisicaoComprovante.EM_PAGAMENTO]: (
        <ButtonBaixar
          idRequisicao={idRequisicao}
          tipoRequisicao={tipoRequisicao}
          isFetching={isFetching}
          idRequisicaoRequest={idRequisicaoRequest}
          setIdRequisicaoRequest={setIdRequisicaoRequest}
          setCodRequisicao={setCodRequisicao}
        />
      ),
      [StatusRequisicaoComprovante.SOLICITADA]: (
        <ButtonBaixar
          idRequisicao={idRequisicao}
          tipoRequisicao={tipoRequisicao}
          isFetching={isFetching}
          idRequisicaoRequest={idRequisicaoRequest}
          setIdRequisicaoRequest={setIdRequisicaoRequest}
          setCodRequisicao={setCodRequisicao}
        />
      ),
      [StatusRequisicaoComprovante.AGUARDANDO_ASSINATURA]: (
        <ButtonReassinar
          idRequisicao={idRequisicao}
          tipoRequisicao={tipoRequisicao}
        />
      ),
    },
  );

  const statusNormalizado = removeEmptySpaceAndForceUpperCase(statusRequisicao);

  const botaoDoStatus = tryGetValueOrDefault(
    [botoesPorStatus[statusNormalizado]],
    null,
  );

  return (
    <>
      {botaoDoStatus}

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !shouldOmitButtons,
          shouldRenderCancelar,
        ])}
      >
        <ButtonCancelar
          idRequisicao={idRequisicao}
          onClose={() => {
            if (tipoRequisicao.includes(FLAGS_FOPS.FOP223)) {
              onClose?.(idRequisicao);
            }
          }}
        />
      </RenderConditional>
    </>
  );
};

export default ButtonAcoes;
