// @ts-nocheck
import { PECOS } from 'previdencia/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import {
  IPayloadConsultaFundo,
  IReservasDestino,
} from '../types/IContribuicaoRegular';
import { IResponseConsultaFundo } from 'previdencia/types/MultifundosPJ';

const useConsultarContribuicaoRegular = (
  numeroTransferencia?: string,
  reservasDestino?: IReservasDestino[],
) => {
  const { response, loading, fetchData } = usePeco<
    IPayloadConsultaFundo,
    IResponseConsultaFundo
  >({
    api: {
      operationPath: PECOS.ConsultarContribuicaoRegular,
    },
    autoFetch: false,
    payload: {
      numeroTransferencia,
      reservasDestino,
    },
  });

  return { response: response?.entidade, loading, fetchData };
};

export default useConsultarContribuicaoRegular;
