// @ts-nocheck
import { EnumStatusCardPrevidencia } from 'consultaCliente/types/ICardsApresentacao';
import styled from 'styled-components/macro';
import { Text as Texto } from '@cvp/design-system/react';

export const Text = styled.div<{ statusLength: number; status: string }>(
  ({ theme: { color, font, line }, statusLength, status }) => ({
    maxWidth: statusLength === 8 ? '52%' : '85%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    overflow: 'hidden',
    color:
      status === EnumStatusCardPrevidencia.Ativo ||
      status === EnumStatusCardPrevidencia.Suspenso
        ? color.line.primary
        : color.neutral['04'],
    fontSize: font.size.md,
    fontWeight: font.weight.md,
    lineHeight: line.height.lg,
  }),
);

export const TextClientName = styled(Texto).attrs({
  variant: 'body03-md',
})(() => ({
  paddingLeft: '2rem',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
}));

export const SpanContainer = styled.span(() => ({
  paddingTop: '-2px',
  marginTop: '-3px !important',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
}));

export const Container = styled.div`
  span {
    margin: 4px 2px;
  }
  button {
    margin: 4px 2px;
  }
  hr {
    margin: 4px;
  }
`;

export const ContainerMargin = styled.div`
  margin: 4px 2px;
  div {
    margin: 4px 0px;
  }
`;

export const IconContainer = styled.div`
  svg {
    height: 20px;
  }
`;
