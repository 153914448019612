// @ts-nocheck
import { TextField } from '@cvp/design-system/react';

import masks from 'main/utils/masks';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const ColunaValorTransferenciaDistribuicaoEntreFundos = ({
  row,
  selectedTableInputFocus,
  atribuirParametroInputFocus,
  handleValorTransferencia,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunaValorTransferenciaDistribuicaoEntreFundosProps) => {
  return (
    <TextField
      onFocus={() => {
        atribuirParametroInputFocus(row.codFundo);
      }}
      autoFocus={row.codFundo === selectedTableInputFocus}
      name={row.codFundo}
      placeholder="R$ 0,00"
      disabled={checkIfSomeItemsAreTrue([!row.selecionado])}
      value={masks.currencyInput.mask(row.valorTransferencia)}
      onChange={({
        target: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        handleValorTransferencia(
          row.codFundo,
          (Number(masks.currencyInput.unmask(value)) / 100).toFixed(2),
        );
      }}
    />
  );
};

export default ColunaValorTransferenciaDistribuicaoEntreFundos;
