// @ts-nocheck
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute, {
  IPrivateRoute,
} from 'main/components/Route/PrivateRoute';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';

import { ListagemFundos } from '../features/fundos/pages/ListagemFundos';

import Fop062 from '../features/fops/pages/Fop062';
import Fop063 from '../features/fops/pages/Fop063';
import FopPrestamista from '../features/fops/pages/FopPrestamista';
import FopPrevidencia from '../features/fops/pages/FopPrevidencia';
import FopVida from '../features/fops/pages/FopVida';
import Simuladores from '../features/fops/pages/Simuladores';
import CardProtocoloFop from 'extranet/components/fop223/CardProtocoloFop';
import SolicitacaoTransferenciaFop223 from 'extranet/components/fop223/SolicitacaoTransferenciaFop223';
import Fop223ContextProvider from 'extranet/features/fops/context/Fop223Context';

const routes: IPrivateRoute[] = [
  {
    path: '/extranet/fundos-investimentos',
    exact: true,
    component: ListagemFundos,
    key: 'fundos-investimentos',
    authenticated: true,
    breadcrumb: 'Fundos de Investimentos',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
    ],
  },
  {
    path: '/extranet/fop-prestamista',
    exact: true,
    component: FopPrestamista,
    key: 'fop-prestamista',
    authenticated: true,
    breadcrumb: 'Formulário Prestamista',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
  {
    path: '/extranet/fop-previdencia',
    exact: true,
    component: FopPrevidencia,
    key: 'fop-previdencia',
    authenticated: true,
    breadcrumb: 'Formulário Previdência',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
  {
    path: '/extranet/fop-vida',
    exact: true,
    component: FopVida,
    key: 'fop-vida',
    authenticated: true,
    breadcrumb: 'Formulário Vida',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
  {
    path: '/extranet/fop-062',
    exact: true,
    component: Fop062,
    key: 'fop-solicitacao-prev-empresarial',
    authenticated: true,
    breadcrumb: 'Formulário 062',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
  {
    path: '/extranet/fop-063',
    exact: true,
    component: Fop063,
    key: 'fop-contrato-prev-empresarial',
    authenticated: true,
    breadcrumb: 'Formulário 063',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
  {
    path: '/extranet/simuladores',
    exact: true,
    component: Simuladores,
    key: 'simuladores',
    authenticated: true,
    breadcrumb: 'Simuladores',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
  {
    path: '/extranet/fop-223',
    exact: true,
    component: SolicitacaoTransferenciaFop223,
    key: 'Fop 223',
    authenticated: true,
    breadcrumb: 'FOP 223',
    requiredRoles: PRINCIPAL_USERS,
  },
  {
    path: '/extranet/fop-223/dados-protocolo',
    exact: true,
    component: CardProtocoloFop,
    key: 'Fop 223',
    authenticated: true,
    breadcrumb: 'FOP 223',
    requiredRoles: PRINCIPAL_USERS,
  },
];

export const extranetBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/extranet': null,
};

const RotasPortabilidade: React.FC = () => (
  <Switch>
    <Fop223ContextProvider>
      <Route exact path="/extranet">
        <Redirect to="/" />;
      </Route>
      {routes.map(route => (
        <PrivateRoute
          key={route.key}
          path={route.path}
          exact={route.exact}
          component={route.component}
          authenticated={route.authenticated}
          requiredRoles={route.requiredRoles}
        />
      ))}
    </Fop223ContextProvider>
  </Switch>
);

export default RotasPortabilidade;
