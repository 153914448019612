// @ts-nocheck
import { useState } from 'react';
import { Card, Display, Text, Button } from '@cvp/design-system/react';
import * as S from '../components/styles';
import InfoBox from '../components/InfoBoxBeneficioProtecao';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaAlteracaoTaxaContext } from '../types/ITransferenciaAlteracaoTaxaContext';
import { IDadosCobertura } from '../types/TransferenciaConsultar';
import For from 'main/components/For';
import { useHistory } from 'react-router';
import ModalConfirmacaoTransferencia from '../components/Modais/ModalConfirmacaoTransferencia';
import { usePecoTransferirProduto } from '../hooks/usePecoTransferirProduto';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';

export const BeneficiosDeProtecao = () => {
  const history = useHistory();
  const { featureData } =
    usePrevidenciaContext<ITransferenciaAlteracaoTaxaContext>();
  const { transferirProduto, loading } = usePecoTransferirProduto();
  const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);

  const handleConfirmarTransferencia = async () => {
    setShowModalConfirmacao(!showModalConfirmacao);
    const result = await transferirProduto();
    if (result && result.length) {
      history.push(
        '/cliente/produtos/previdencia/transferencia-alteracao-taxa/novo-certificado',
      );
    }
  };

  if (loading) return <CardSkeleton />;
  return (
    <Display type="block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content>
          <Text variant="headline-05" color="primary" margin>
            <strong>Benefícios de proteção</strong>
          </Text>
          <S.AlertContainer>
            Os benefícios de proteção que não são mais comercializados serão
            cancelados ao final desta transação.
          </S.AlertContainer>
          <For
            each={tryGetValueOrDefault(
              [featureData?.coberturas],
              [] as IDadosCobertura[],
            )}
          >
            {item => (
              <>
                <S.SectionTitle>
                  <Display justify="space-between">
                    <div>
                      <Text variant="caption">{item.cobertura}</Text>
                    </div>
                    <Text variant="caption" color="secondary">
                      {item.status}
                    </Text>
                  </Display>
                </S.SectionTitle>
                <InfoBox
                  valorContribuicao={item.valorContribuicao}
                  valorBeneficio={item.valorBeneficio}
                />
              </>
            )}
          </For>
        </Card.Content>
      </Card>
      <Display alignItems="flex-end" justify="flex-end">
        <Button variant="outlined" onClick={() => history.goBack()}>
          Voltar
        </Button>
        <Button
          variant="primary"
          onClick={() => setShowModalConfirmacao(!showModalConfirmacao)}
        >
          Avançar
        </Button>
      </Display>

      <ModalConfirmacaoTransferencia
        onClose={() => setShowModalConfirmacao(!showModalConfirmacao)}
        show={showModalConfirmacao}
        nextStep={() => handleConfirmarTransferencia()}
      />
    </Display>
  );
};
