// @ts-nocheck
/**
 * validarPrimeiraStr
 *
 * Função que valida a primeira posição do valorRecebido, se a primeira posição for um número ele coloca o prefixo, se for uma string retorna o próprio valorRecebido.
 *
 * @param {string} valorRecebido - Valor recebido no qual irá ser validado, pode ser undefined se o valor vier de uma API
 * @param {string} prefix - Por padrão vazio, parâmetro opcional que será incluído no início do valorRecebido
 * @returns {string} - Pode ser retornado apenas o valorRecebido, quanto o valorRecebido com o prefixo, se o valorRecebido for undefined ele retornará vazio
 * @author Ramon Gomes
 * @version 1.0.0
 */
export const validarPrimeiraStr = (valorRecebido = '', prefix = ''): string => {
  const firstChar = Number(valorRecebido?.charAt(0));

  if (!Number.isNaN(firstChar)) {
    return prefix + valorRecebido;
  }

  return valorRecebido;
};
