// @ts-nocheck
import { Button, Card, Display, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import * as consts from 'seguros/constants/alterarDiaVencimentoSeguros';
import { ResultadoAlteracaoDiaVencimentoSegurosProps } from 'seguros/types/ResultadoAlteracaoDiaVencimentoSeguro';

export const ResultadoAlteracaoDiaVencimentoSeguros = ({
  sucesso,
  setRegistroAlterado,
}: ResultadoAlteracaoDiaVencimentoSegurosProps) => {
  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[6, 8, 6, 8]}>
          <RenderConditional condition={!sucesso}>
            <Text variant="headline-05" color="primary" margin>
              Alteração de Dia de Vencimento
            </Text>
            <Text variant="body02-sm" color="text" align="center" margin>
              Não foi possível alterar o dia de vencimento.
            </Text>
          </RenderConditional>

          <RenderConditional condition={sucesso}>
            <Text variant="headline-05" color="primary" margin>
              Alteração de Dia de Vencimento
            </Text>

            <Text
              variant="body02-sm"
              color="text"
              data-testid="mensagemSucesso"
            >
              {consts.MENSAGEM_ALTERACAO_COM_SUCESSO}
            </Text>
          </RenderConditional>

          <Display>
            <Button
              variant="outlined"
              onClick={() => {
                setRegistroAlterado(false);
              }}
            >
              Voltar
            </Button>
          </Display>
        </Card.Content>
      </Card>
    </Display>
  );
};
