// @ts-nocheck
import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'main/components/Route';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PageIndexPortabilidade from 'painelPortabilidade/pages/RelatoriolInadimplencia';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';

const routes: IPrivateRoute[] = [
  {
    path: '/hierarquia-portabilidades',
    component: PageIndexPortabilidade,
    key: 'portabilidade',
    breadcrumb: 'Portabilidade',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
  },
];

export const painelPortabilidadeBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
};

const RotasPainelPortabilidade = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);

export default RotasPainelPortabilidade;
