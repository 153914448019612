// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
export const obterEndpointListarPropostas = () =>
  '/PortalEconomiario/PECO_ListarPropostasAgencia';
export const obterEndpointDetalharProposta = () =>
  '/PortalEconomiario/PECO_DetalharPropostas';
export const obterEndpointUploadProposta = () =>
  '/PortalEconomiario/PECO_UploadFile';
export const obterEndpointListarCancelamentos = () =>
  '/PortalEconomiario/PECO_ListarPropostas';
export const obterEndpointDetalharCancelamento = () =>
  '/PortalEconomiario/PECO_DetalharPropostas';
export const obterEndpointEnviarToken = () =>
  '/PortalEconomiario/PECO_EnviarToken';
export const obterEndpointConfirmarToken = () =>
  '/PortalEconomiario/PECO_ConfirmarToken';

export const PECOS_PRESTAMISTA = {
  ListarStatusPropostasPrestamista: 'PECO_ListarStatusPropostasPrestamista',
  ObterValorImportanciaSegurada: 'PECO_ObterValorImportanciaSegurada',
  VerificarNecessidadeDps: 'PECO_VerificarNecessidadeDps',
  ConsultarSubscricaoPj: 'PECO_ConsultarSubscricaoDpsPj',
  ConsultarSubscricao: 'PECO_ConsultarSubscricaoDps',
  SolicitarDps: 'PECO_SolicitarDps',
  ListarStatusPropostaDps: 'PECO_ListarStatusPropostaDps',
  SolicitarReenvioLinkPorEmailDps: 'PECO_SolicitarReenvioLinkPorEmailDps',
  DetalharPropostas: 'PECO_DetalharPropostas',
  ConsultarStatusDps: 'PECO_ConsultarStatusDps',
  AtualizarPropostasDps: 'PECO_AtualizarPropostasDps',
  InserirPropostaDps: 'PECO_InserirPropostaDps',
  ConfirmarToken: 'PECO_ConfirmarToken',
  RecuperarListaDps: 'PECO_RecuperarListaDps',
};
