// @ts-nocheck
import { formatarData } from 'main/utils';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { ResponseAnoHistorico } from 'previdencia/features/ReajusteAnualPlano/types/ResponseAnosHistorico';

export const popularTabelaAnoHistorico = (
  data: ResponseAnoHistorico | undefined,
) => {
  const dadosTabelaReserva = data?.retornoAnoHistoricoProxy.anosHistorico
    .filter(item => {
      return item.nomCobertura === 'Renda';
    })
    .map(reserva => ({
      ...reserva,
      dthRecal: formatarData(reserva.dthRecal),
      vlrAnterior: formatarValorPadraoBrasileiro(reserva.vlrAnterior),
      pctAtualizacaoCodIndice: `${reserva.pctAtualizacao} % ${reserva.codIndice}`,
      vlrFinal: formatarValorPadraoBrasileiro(reserva.vlrFinal),
    }));

  const dadosTabelaCuidadoExtra = data?.retornoAnoHistoricoProxy.anosHistorico
    .filter(item => {
      return item.nomCobertura === 'Cuidado Extra';
    })
    .map(cuidadoExtra => ({
      ...cuidadoExtra,
      dthRecal: formatarData(cuidadoExtra.dthRecal),
      vlrAnterior: formatarValorPadraoBrasileiro(cuidadoExtra.vlrAnterior),
      pctAtualizacaoCodIndice: `${cuidadoExtra.pctAtualizacao} % ${cuidadoExtra.codIndice}`,
      vlrFinal: formatarValorPadraoBrasileiro(cuidadoExtra.vlrFinal),
    }));

  return {
    dadosTabelaCuidadoExtra,
    dadosTabelaReserva,
  };
};
