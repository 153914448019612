// @ts-nocheck
import React from 'react';
import { Display, Button, Modal, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import TextoErroUploadProposta from 'contratosPrestamista/components/TextoErroUploadProposta';
import { useHistory } from 'react-router';
import Icon from 'main/components/Icon';
import { IDpsEletronicaFeatureDataFluxoPF } from 'contratosPrestamista/features/dpseletronica/types/IDpsEletronicaFeatureDataFluxoPF';
import { useContratosPrestamistaContext } from 'contratosPrestamista/contexts/ContratosPrestamistaContext';
import { IModalStatusUploadPropostaProps } from '../types/IModalStatusUploadPropostaProps';
import EnvioDps from './EnvioDps';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

export const ModalStatusUploadProposta: React.FC<
  IModalStatusUploadPropostaProps
> = ({
  openModal,
  showErrorModal,
  showMessageRedirect,
  loadingRealizarUpload,
  dpsEnviada,
  anexoEnviado,
  listaEnvioDps,
  handleCloseModal,
  handleSubmit,
}) => {
  const history = useHistory();

  const { setFeatureData, featureData } =
    useContratosPrestamistaContext<IDpsEletronicaFeatureDataFluxoPF>();

  const iniciarFluxo = (): void => {
    setFeatureData({
      ...featureData,
      etapas: ['Identificação', 'Upload/DPS'],
      etapaAtual: 1,
      showStepBar: true,
    });

    history.push('/prestamista/dps/identificacao');
  };
  const finalizarFluxo = (): void => {
    if (showMessageRedirect) {
      iniciarFluxo();
      return;
    }

    handleCloseModal();
  };

  return (
    <Modal show={openModal} onClose={finalizarFluxo}>
      {loadingRealizarUpload ? (
        <Button variant="text" loading />
      ) : (
        <>
          <RenderConditional
            condition={checkIfAllItemsAreTrue([
              !showMessageRedirect,
              !showErrorModal,
            ])}
          >
            <Text variant="body02-md" margin>
              Todos os dados preenchidos estão corretos?
            </Text>
            <Text variant="body-medium3">
              Deseja confirmar o upload de proposta/DPS Eletrônica?
            </Text>
            <Display justify="center" style={{ marginTop: 20 }}>
              <Button
                variant="secondary"
                onClick={handleCloseModal}
                data-testid="cancelar"
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                type="button"
                data-testid="confirmar"
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </Display>
          </RenderConditional>
          <RenderConditional condition={showMessageRedirect}>
            <Text variant="body02-md" margin>
              Upload de proposta/DPS Eletrônica
            </Text>
            <div style={{ marginTop: 20 }}>
              <RenderConditional condition={anexoEnviado}>
                <Text
                  variant="caption-02"
                  margin
                  align="center"
                  color="success"
                >
                  <Icon name="successCheck" /> Upload realizado com sucesso!
                </Text>
              </RenderConditional>

              <RenderConditional condition={dpsEnviada}>
                <EnvioDps listaEnvioDps={listaEnvioDps} />
              </RenderConditional>

              <RenderConditional
                condition={checkIfAllItemsAreTrue([
                  !listaEnvioDps?.some(dado => !!dado.isDPSReqSuccessfully),
                  !dpsEnviada,
                ])}
              >
                <Text variant="caption-02" margin align="center" color="error">
                  <Icon name="close" /> Não foi possível enviar a DPS.
                </Text>
              </RenderConditional>
            </div>
            <Text variant="body02-md" style={{ marginTop: 20 }}>
              Qual ação deseja realizar?
            </Text>
            <Display justify="center" style={{ marginTop: 10 }}>
              <Button
                variant="secondary"
                onClick={iniciarFluxo}
                data-testid="cancelar"
              >
                Novo Upload
              </Button>
              <Button
                variant="primary"
                type="button"
                data-testid="confirmar"
                onClick={() =>
                  history.push('/prestamista/proposta/upload/consulta')
                }
              >
                Consultar Status Proposta
              </Button>
            </Display>
          </RenderConditional>
          <RenderConditional condition={showErrorModal}>
            <TextoErroUploadProposta />
          </RenderConditional>
        </>
      )}
    </Modal>
  );
};
