// @ts-nocheck
import React, { useState } from 'react';
import { Button, Checkbox } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';
import * as S from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Modal/ModalConsentimentoTransferencia/styles';

const ModalConsentimentoTransferencia: React.FC<
  PROPS_TYPES.ModalConsentimentoTransferenciaProps
> = ({ toggleModal, handleModal, avancarEtapa }) => {
  const [checkInfoAcaoTransferencia, setCheckInfoAcaoTransferencia] =
    useState<boolean>(false);
  return (
    <TRANSF_VGBL_STYLES.ModalTransferenciaInterna
      show={toggleModal}
      handleClose={handleModal}
      size={ENUM_TYPES.ModalInfoPredefinitionSize.LG}
      data-testid="modalConsentimentoTransferencia"
    >
      <TRANSF_VGBL_STYLES.Text
        margin
        color="secondary"
        fontWeight="bold"
        data-testid="modalConsentimentoTransferenciaGrauCriticidade"
      >
        <Icon name="warning" /> Atenção!
      </TRANSF_VGBL_STYLES.Text>
      <TRANSF_VGBL_STYLES.Text
        variant="body01-lg"
        margin
        textAlign="left"
        data-testid="modalConsentimentoTransferenciaTitulo"
      >
        {CONSTS.MODAL_INFO_ACAO_TRANSFERENCIA.TITULO}
      </TRANSF_VGBL_STYLES.Text>
      <TRANSF_VGBL_STYLES.Text
        variant="body02-sm"
        margin
        textAlign="left"
        data-testid="modalConsentimentoTransferenciaDescricao"
      >
        <S.ContainerCheckbox>
          <Checkbox
            onClick={() =>
              setCheckInfoAcaoTransferencia(!checkInfoAcaoTransferencia)
            }
            data-testid="modalConsentimentoTransferenciaCheckConsentimento"
          />
        </S.ContainerCheckbox>
        {CONSTS.MODAL_INFO_ACAO_TRANSFERENCIA.PRIMEIRA_DESCRICAO}
      </TRANSF_VGBL_STYLES.Text>
      <TRANSF_VGBL_STYLES.Text
        variant="body02-sm"
        margin
        textAlign="left"
        data-testid="modalConsentimentoTransferenciaDescricao"
      >
        {CONSTS.MODAL_INFO_ACAO_TRANSFERENCIA.SEGUNDA_DESCRICAO}
      </TRANSF_VGBL_STYLES.Text>
      <TRANSF_VGBL_STYLES.Display
        justify="center"
        data-testid="modalConsentimentoTransferenciaBotaoAvancar"
      >
        <Button
          variant="secondary"
          onClick={avancarEtapa}
          disabled={!checkInfoAcaoTransferencia}
          data-testid="prosseguirContribuicaoRendaMensal"
        >
          Certo, entendi!
        </Button>
      </TRANSF_VGBL_STYLES.Display>
    </TRANSF_VGBL_STYLES.ModalTransferenciaInterna>
  );
};

export default ModalConsentimentoTransferencia;
