// @ts-nocheck
import React, { PropsWithChildren } from 'react';
import Header from 'main/components/Header';
import { PageContainer } from 'main/styles/GlobalStyle';
import Footer from './AppFooter/Footer';
import { Wrapper } from '../Menu/Sidebar';

const AppPublicLayout: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <Wrapper>
    <Header showUserData={false} />
    <PageContainer>{children}</PageContainer>
    <Footer isPublic />
  </Wrapper>
);

export default AppPublicLayout;
