// @ts-nocheck
import React, {
  createContext,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';
import { removePermissions } from 'main/features/Auth/utils/auth';
import {
  ICliente,
  IClientePrestamista,
  IClienteVida,
} from 'main/types/ICliente';
import { getSessionItem, setSessionItem } from 'main/utils/storage';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { IMenu, IMenuItem } from 'main/components/Menu/Sidebar/types';
import { useConsultarContratosSF } from 'main/hooks/useConsultarContratosSF';

type ContextMenuType =
  | 'default'
  | 'previdencia'
  | 'vida'
  | 'seguros'
  | 'prestamista';

interface IAppContext {
  menu: IMenu;
  cliente: ICliente;
  clienteVida: IClienteVida;
  clientePrestamista: IClientePrestamista;
  nomeSocial: string;
  loadingNomeSocial: boolean;

  contextualMenu: ContextMenuType;
  setCliente: (data: ICliente) => void;
  setClienteVida: (data: IClienteVida) => void;
  setClientePrestamista: (data: IClientePrestamista) => void;
  setContextualMenu: (menu: ContextMenuType) => void;
  resetarCliente: () => void;
  restoreDefaultMenu: (cpfCnpj: string) => void;
  restoreDefaultMenuVida: (cpfCnpj: string) => void;
  restoreDefaultMenuPrestamista: (cpfCnpj: string) => void;
  setNomeSocial: (value: string) => void;
}

type AppContextProviderProps = {
  menus: IMenuItem[];
  contextualMenus?: IMenu[];
  children?: React.ReactNode;
};

export const AppContext = createContext<IAppContext>({} as IAppContext);

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  menus,
  contextualMenus,
  children,
}) => {
  const [contextualMenu, setContextualMenu] =
    useState<ContextMenuType>('default');
  const defaultMenu = {
    name: 'Economiário',
    type: 'economiario',
    items: menus,
  };

  const [appMenu, setAppMenu] = useState<IMenu>(defaultMenu);

  const [cliente, setCliente] = useState<ICliente>(() => {
    const result = getSessionItem<ICliente>('cliente');
    if (checkIfAllItemsAreTrue([!!result, !!result.cpfCnpj])) return result;

    return { cpfCnpj: '', numCertificado: '' } as ICliente;
  });

  const { obterContratosAtivos, nomeSocial, loadingNomeSocial, setNomeSocial } =
    useConsultarContratosSF();

  const [clienteVida, setClienteVida] = useState<IClienteVida>(() => {
    const result = getSessionItem<IClienteVida>('clienteVida');
    if (checkIfAllItemsAreTrue([!!result, !!result.cpfCnpj])) return result;

    return { cpfCnpj: '', numCertificado: '' } as IClienteVida;
  });

  const [clientePrestamista, setClientePrestamista] =
    useState<IClientePrestamista>(() => {
      const result = getSessionItem<IClientePrestamista>('clientePrestamista');
      if (checkIfAllItemsAreTrue([!!result, !!result.cpfCnpj])) return result;

      return { cpfCnpj: '', numeroCertificado: 0 } as IClientePrestamista;
    });

  const setClienteInContextAndSession = (data: ICliente) => {
    setSessionItem('cliente', data);
    setCliente(data);
  };

  const setClienteVidaInContextAndSession = (data: IClienteVida) => {
    setSessionItem('clienteVida', data);
    setClienteVida(data);
  };

  const setClientePrestamistaInContextAndSession = (
    data: IClientePrestamista,
  ) => {
    setSessionItem('clientePrestamista', data);
    setClientePrestamista(data);
  };

  const resetarCliente = () => {
    setClienteInContextAndSession({
      cpfCnpj: '',
      numCertificado: '',
    });
    removePermissions();
    setContextualMenu('default');
  };

  const restoreDefaultMenu = (cpfCnpj: string) => {
    setClienteInContextAndSession({
      cpfCnpj,
      numCertificado: '',
    });
    setContextualMenu('default');
  };

  const restoreDefaultMenuVida = (cpfCnpj: string) => {
    setClienteVidaInContextAndSession({
      cpfCnpj,
      numCertificado: '',
      numApolice: '',
      tipoCertificado: '',
    });
    setContextualMenu('default');
  };

  const restoreDefaultMenuPrestamista = (cpfCnpj: string) => {
    setClientePrestamistaInContextAndSession({
      cpfCnpj,
      numeroCertificado: 0,
      numeroApolice: 0,
      numeroContrato: '0',
    });
    setContextualMenu('default');
  };
  useLayoutEffect(() => {
    if (
      checkIfAllItemsAreTrue([contextualMenu !== 'default', !!cliente?.cpfCnpj])
    ) {
      setAppMenu(
        contextualMenus?.find(x => x.type === contextualMenu) ?? ({} as IMenu),
      );
    } else setAppMenu(defaultMenu);
  }, [
    contextualMenu,
    cliente?.cpfCnpj,
    clienteVida.cpfCnpj,
    clientePrestamista.cpfCnpj,
  ]);

  useEffect(() => {
    if (cliente.cpfCnpj) obterContratosAtivos(cliente.cpfCnpj);
  }, [cliente]);

  return (
    /* eslint-disable react/jsx-no-constructed-context-values */
    <AppContext.Provider
      value={{
        menu: appMenu,
        cliente,
        clienteVida,
        clientePrestamista,
        contextualMenu,
        nomeSocial,
        loadingNomeSocial,
        setCliente: setClienteInContextAndSession,
        setClienteVida: setClienteVidaInContextAndSession,
        setClientePrestamista: setClientePrestamistaInContextAndSession,
        setContextualMenu,
        resetarCliente,
        restoreDefaultMenu,
        restoreDefaultMenuVida,
        restoreDefaultMenuPrestamista,
        setNomeSocial,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
