// @ts-nocheck
import React, { useEffect } from 'react';
import {
  Display,
  Card,
  Grid,
  Button,
  TextField,
} from '@cvp/design-system/react';
import Table, { Skeleton } from 'main/components/Table';
import { MENSAGEM_TABELA_DADOS } from '../constants/texts';
import { colunasTabelaDps } from '../factories/colunasStatusDPSFactory';
import ModalDetalhes from '../components/ModalDetalhes';
import RenderConditional from 'main/components/RenderConditional';
import { useConsultarDps } from '../hooks/useConsultarDps';
import { useFiltrosDps } from '../hooks/useFiltroDps';
import { useModalDetalhesDps } from '../hooks/useModalDetalhesDPS';
import { useSolicitaDps } from '../hooks/useSolicitaDps';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { Container } from '../styles/styles';
import masks from 'main/utils/masks';
import { Formik, Field, Form, FieldProps } from 'formik';

const StatusDps: React.FC = () => {
  const {
    obterConsultaVida,
    loadingConsultaVida,
    fetchData: fetchDataConsultaVidaDps,
  } = useConsultarDps();

  const {
    dpsEletronicaData,
    setDpsEletronicaData,
    aplicarFiltros,
    atualizaConsulta,
  } = useFiltrosDps(obterConsultaVida);

  const {
    openModalDetalhes,
    setOpenModalDetalhes,
    itemSelecionado,
    handleSelecionarItem,
  } = useModalDetalhesDps();

  const { handleSubmitForm, isLoading } = useSolicitaDps(
    fetchDataConsultaVidaDps,
    setOpenModalDetalhes,
  );

  useEffect(() => {
    if (checkIfAllItemsAreTrue([!!obterConsultaVida?.length])) {
      setDpsEletronicaData(obterConsultaVida);
    }
  }, [obterConsultaVida, setDpsEletronicaData]);

  const handleFormikSubmit = (values: { busca: string }) => {
    aplicarFiltros(values.busca);
  };

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Formik initialValues={{ busca: '' }} onSubmit={handleFormikSubmit}>
            {({
              handleSubmit,
              handleReset,
              handleBlur,
              setFieldValue,
              values,
              errors,
            }) => (
              <Form>
                <Grid>
                  <Grid.Item xs={1 / 3}>
                    <Field name="busca">
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          label="CPF / CNPJ"
                          error={meta.touched && meta.error}
                          errorMessage={meta.touched && meta.error}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('busca', masks.cpfCnpj.unmask(value));
                          }}
                          value={masks.cpfCnpj.mask(values.busca)}
                          onBlur={handleBlur}
                        />
                      )}
                    </Field>
                  </Grid.Item>
                </Grid>
                <Display>
                  <Button
                    variant="primary"
                    disabled={Object.keys(errors).length > 0}
                    onClick={handleSubmit}
                  >
                    Consultar
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      handleReset();
                      atualizaConsulta();
                    }}
                  >
                    Limpar
                  </Button>
                </Display>
              </Form>
            )}
          </Formik>
          <RenderConditional
            condition={checkIfSomeItemsAreTrue([loadingConsultaVida])}
          >
            <Skeleton colunas={colunasTabelaDps(handleSelecionarItem)} />
          </RenderConditional>
          <RenderConditional
            condition={checkIfAllItemsAreTrue([!loadingConsultaVida])}
          >
            <Container>
              <Table
                responsive
                columns={colunasTabelaDps(handleSelecionarItem)}
                data={dpsEletronicaData}
                pagination
                paginationPerPage={10}
                paginationComponentOptions={{
                  rowsPerPageText: 'Itens por página',
                  rangeSeparatorText: 'de',
                }}
                noDataComponent={MENSAGEM_TABELA_DADOS}
              />
            </Container>
          </RenderConditional>
        </Card.Content>
      </Card>
      <ModalDetalhes
        open={openModalDetalhes}
        onClose={() => setOpenModalDetalhes(false)}
        handleSubmitForm={handleSubmitForm}
        dadosForm={itemSelecionado}
        loading={isLoading}
      />
    </Display>
  );
};

export default StatusDps;
