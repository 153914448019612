// @ts-nocheck
import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import {
  IVerificarExigenciaDpsRequest,
  IResponseVerificarExigenciaDps,
} from 'contratosPrestamista/features/dpseletronica/types';
import { useAuth } from 'main/features/Auth/hooks';
import { usePeco } from 'main/hooks/usePeco';
import { IDadosBasicoProposta } from 'contratosPrestamista/types/IDadosBasicoProposta';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { construirPayloadVerificarNecessidadeDps } from 'contratosPrestamista/factories/construirObjetoVerificarExigenciaDps';

export const useVerificarNecessidadeDps = () => {
  const { user } = useAuth();

  const {
    loading: loadingVerificarNecessidadeDps,
    response: responseVerificarNecessidadeDps,
    fetchData: fetchVerificarNecessidadeDps,
  } = usePeco<IVerificarExigenciaDpsRequest, IResponseVerificarExigenciaDps>({
    api: {
      operationPath: PECOS_PRESTAMISTA.VerificarNecessidadeDps,
    },
  });

  const verificarExigenciaDps = async (entrada: IDadosBasicoProposta) => {
    const result = await fetchVerificarNecessidadeDps(
      construirPayloadVerificarNecessidadeDps({
        ...entrada,
        capitalSegurado: entrada.capitalSegurado,
        numeroAgencia: user.agenciaVinculada,
        valorAcumulo: entrada.valorAcumulo,
        cpf: masks.numberOnly.mask(entrada.cpf),
        numeroProposta: entrada.numeroProposta,
      }),
    );

    return {
      necessitaDps: checkIfAllItemsAreTrue([!!result?.entidade?.necessitaDps]),
      lista: result?.entidade?.listaDpsExistentes,
    };
  };

  return {
    loadingVerificarNecessidadeDps,
    verificarExigenciaDps,
    responseVerificarNecessidadeDps,
  };
};
