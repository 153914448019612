// @ts-nocheck
import { api, IApiResponse } from 'main/services';
import { obterEndpointStatusSinistroVida } from 'sinistro/config/endpoints';
import { IStatusSinistroVida } from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { IFormVidaParams } from 'sinistro/features/statusSinistro/types/FormularioSinistroParams';

export const obterStatusSinistroVida = async (
  params: IFormVidaParams,
): Promise<IStatusSinistroVida> => {
  const resultado = await api.post<IApiResponse<any>>(
    obterEndpointStatusSinistroVida(),
    params,
  );

  const mensagemErro: string | undefined =
    resultado?.data?.dados?.mensagens?.[0].descricao;

  if (resultado?.data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return resultado?.data?.dados?.entidade;
};
