// @ts-nocheck
import styled from 'styled-components/macro';

export const CalendarContainer = styled.div(({ theme: { breakpoint } }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  gap: '20px',
  width: '100%',

  [breakpoint.md()]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const CalendarInputContainer = styled.div(({ theme: { color } }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  flexGrow: 1,

  '.calendar-day': {
    '&.react-datepicker__day--selected, &.react-datepicker__day--keyboard-selected':
      {
        backgroundColor: color.brandPrimary.light,
        color: color.neutral['08'],
      },
    '&.react-datepicker__day--disabled': {
      backgroundColor: 'transparent',
    },

    '&.react-datepicker__day--in-selecting-range': {
      color: color.neutral['08'],
    },
  },

  '> p': {
    marginBottom: '8px',
    fontSize: '16px',
    fontWeight: 400,
  },
}));

export const CalendarInput = styled.input<{ error?: boolean }>(
  ({ theme: { color }, error = false }) => ({
    width: '100%',
    height: '56px',
    fontSize: '16px',
    border: `1px solid ${color.neutral['04']}`,
    color: color.neutral['02'],
    borderRadius: '4px',
    padding: '0 12px',

    '&:focus': {
      borderColor: color.brandPrimary.light,
      color: color.brandPrimary.light,
      outline: 'none',
    },
    ...(error && {
      borderColor: color.feedback.error,
      color: color.feedback.error,
    }),
  }),
);
