// @ts-nocheck
import * as Yup from 'yup';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';

export const FormikDadosOcorrenciaSchema = (isFormFop: boolean) =>
  Yup.object().shape({
    ...CONSTS.DADOS_OCORRENCIA_CAMPOS_OBRIGATORIOS,
    ...(isFormFop && CONSTS.DADOS_OCORRENCIA_FORM_FOP_OBRIGATORIOS),
  });

/**
 * Remove o primeiro nome de um nome completo e retorna o sobrenome ou o restante do nome.
 *
 * @param {string} nomeCompleto - O nome completo que será processado.
 * @returns {string} - O sobrenome ou o restante do nome após a remoção do primeiro nome.
 */
export const removerPrimeiroNome = (nomeCompleto: string): string => {
  const nomeArray = nomeCompleto.split(' ');
  nomeArray.shift();
  return nomeArray.join(' ');
};

/**
 * Retorna o primeiro nome de um nome completo.
 *
 * @param {string} nomeCompleto - O nome completo que será processado.
 * @returns {string} - O primeiro nome do nome completo.
 */
export const manterPrimeiroNome = (nomeCompleto: string): string => {
  const nomeArray = nomeCompleto.split(' ');
  return nomeArray[0];
};

/**
 * Ordena uma lista de campos dinâmicos com base no valor da propriedade "ordem" de cada campo.
 *
 * @param {REGISTRO_OCORRENCIA_TYPES.IListaCamposDinamicos[]} listaCampos - A lista de campos dinâmicos que será ordenada.
 * @returns {REGISTRO_OCORRENCIA_TYPES.IListaCamposDinamicos[]} - A lista de campos dinâmicos ordenada.
 */
export const camposDinamicosOrdenados = (
  listaCampos: REGISTRO_OCORRENCIA_TYPES.IListaCamposDinamicos[],
): REGISTRO_OCORRENCIA_TYPES.IListaCamposDinamicos[] => {
  return listaCampos.sort(
    (campoA, campoB) =>
      tryGetValueOrDefault([campoB.ordem], 0) -
      tryGetValueOrDefault([campoA.ordem], 0),
  );
};

/**
 * Verifica se um determinado valor é uma representação válida de um endereço de email.
 *
 * @param {string} email - O valor que será verificado.
 * @returns {boolean} - True se o valor é uma representação válida de um endereço de email, caso contrário, False.
 */
export function isEmail(email: string): boolean {
  const validacaoRegex = /\S+@\S+\.\S+/;
  return validacaoRegex.test(email);
}

/**
 * Validação personalizada para campos dinâmicos do Formik.
 *
 * @param {REGISTRO_OCORRENCIA_TYPES.IDynamicFields} values - Um objeto contendo os valores dos campos dinâmicos.
 * @returns {REGISTRO_OCORRENCIA_TYPES.IDynamicFields} - Um objeto contendo os erros de validação para os campos dinâmicos.
 */
export const customFormikValidationDynamicFields = (
  values: REGISTRO_OCORRENCIA_TYPES.IDynamicFields,
): REGISTRO_OCORRENCIA_TYPES.IDynamicFields => {
  const errors = {} as REGISTRO_OCORRENCIA_TYPES.IDynamicFields;

  Object.values(values).forEach(field => {
    if (checkIfAllItemsAreTrue([field.obrigatorio, !field.valorCampo])) {
      errors[field.nomeCampo] =
        CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO;
    }
  });

  return errors;
};
