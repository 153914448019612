// @ts-nocheck
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { Cobertura } from 'previdencia/types/ICertificadoInformacoes';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

export const obterCoberturaValidaFactory = ({
  idTipoCertificado,
  tipoCertificado,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IObterCoberturaValidaFactory) => {
  return tipoCertificado?.coberturas?.coberturas?.map(cobertura => {
    if (
      checkIfAllItemsAreTrue([
        cobertura.risco === CONSTS.COBERTURA_VALIDA.SIM,
        cobertura.comercializado === CONSTS.COBERTURA_VALIDA.SIM,
      ])
    ) {
      return {
        ...cobertura,
        id: idTipoCertificado,
      };
    }

    return {} as Cobertura;
  });
};
