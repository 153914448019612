// @ts-nocheck
import React from 'react';
import { Display, Text } from '@cvp/design-system/react';
import { TArquivoProps } from 'painelAdministracao/types/ListagemTypes';

const ItemFop: React.FC<TArquivoProps> = ({ fop }) => {
  return (
    <Display justify="space-between" alignItems="center">
      <Text color="primary" variant="body02-sm" margin>
        {fop.nome}
      </Text>
    </Display>
  );
};

export default ItemFop;
