// @ts-nocheck
import { MENUS_ALTERNATIVOS } from 'config/menusAlternativos';
import { IMenu } from 'main/components/Menu/Sidebar/types';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
  VIDA_PERMISSIONS,
} from 'main/features/Auth/config/userProfiles';

export const menuVida: IMenu = {
  name: 'Seguros',
  type: 'seguros',
  roles: [
    ...PRINCIPAL_USERS,
    USER_PROFILES.ANALISTA_CONSULTA,
    USER_PROFILES.ANALISTA_SAIDA,
    USER_PROFILES.ANALISTA_ENTRADA,
    USER_PROFILES.ANALISTA_MANUTENCAO,
  ],
  items: [
    ...MENUS_ALTERNATIVOS,
    {
      alt: 'menu-dados-segurado',
      label: 'Dados do Segurado',
      icon: 'user',
      path: '/cliente/produtos/vida/dados-segurado',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
      ],
      permissions: [
        VIDA_PERMISSIONS.CERTIFICADO_PF,
        VIDA_PERMISSIONS.CERTIFICADO_PJ,
        VIDA_PERMISSIONS.BILHETE_PF,
      ],
    },
    {
      alt: 'menu-simulação-renda',
      label: 'Informações do Beneficiário',
      icon: 'homeFamily',
      path: '/cliente/produtos/vida/beneficiarios',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
      ],
      permissions: [
        VIDA_PERMISSIONS.CERTIFICADO_PF,
        VIDA_PERMISSIONS.BILHETE_PF,
      ],
    },
    {
      alt: 'menu-dados-apolice',
      label: 'Apólice',
      icon: 'documentFront',
      path: 'vida-dados-apolice',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
      ],
      permissions: [
        VIDA_PERMISSIONS.CERTIFICADO_PF,
        VIDA_PERMISSIONS.CERTIFICADO_PJ,
        VIDA_PERMISSIONS.BILHETE_PF,
      ],
      subItems: [
        {
          label: 'Dados da Apolice',
          path: '/cliente/produtos/vida/dados-apolice',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_ENTRADA,
          ],
          permissions: [
            VIDA_PERMISSIONS.CERTIFICADO_PF,
            VIDA_PERMISSIONS.CERTIFICADO_PJ,
            VIDA_PERMISSIONS.BILHETE_PF,
          ],
        },
        {
          label: 'Segunda Via de Apolice',
          path: '/cliente/produtos/vida/2a-via-certificado',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
          ],
          permissions: [
            VIDA_PERMISSIONS.CERTIFICADO_PF,
            VIDA_PERMISSIONS.BILHETE_PF,
          ],
        },
      ],
    },
    {
      alt: 'menu-simulação-renda',
      label: 'Alterações',
      icon: 'edit',
      path: 'dia-vencimento',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
      ],
      permissions: [
        VIDA_PERMISSIONS.CERTIFICADO_PF,
        VIDA_PERMISSIONS.CERTIFICADO_PJ,
      ],
      subItems: [
        {
          label: 'Alterar Dia Vencimento',
          path: '/cliente/produtos/vida/dia-vencimento',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
          ],
          permissions: [
            VIDA_PERMISSIONS.CERTIFICADO_PF,
            VIDA_PERMISSIONS.BILHETE_PF,
          ],
        },
        {
          label: 'Alterar Valor de Capital Segurado',
          path: '/cliente/produtos/vida/valor-segurado',
          permissions: [
            VIDA_PERMISSIONS.CERTIFICADO_PF,
            VIDA_PERMISSIONS.CERTIFICADO_PJ,
            VIDA_PERMISSIONS.BILHETE_PF,
          ],
          roles: [USER_PROFILES.ANALISTA_TI],
        },
      ],
    },
    {
      alt: 'menu-simulação-renda',
      label: 'Consultas',
      icon: 'documentPaper',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_ENTRADA,
      ],
      permissions: [
        VIDA_PERMISSIONS.CERTIFICADO_PF,
        VIDA_PERMISSIONS.CERTIFICADO_PJ,
        VIDA_PERMISSIONS.BILHETE_PF,
      ],
      subItems: [
        {
          label: 'Pagamentos',
          path: '/cliente/produtos/vida/pagamentos',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
          ],
          permissions: [
            VIDA_PERMISSIONS.CERTIFICADO_PF,
            VIDA_PERMISSIONS.CERTIFICADO_PJ,
            VIDA_PERMISSIONS.BILHETE_PF,
          ],
        },
        {
          label: 'Certificado On-Line',
          icon: 'financialWallet',
          path: '/cliente/produtos/vida/emissao-certificado',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_CONSULTA,
          ],
          permissions: [
            VIDA_PERMISSIONS.CERTIFICADO_PF,
            VIDA_PERMISSIONS.CERTIFICADO_PJ,
            VIDA_PERMISSIONS.BILHETE_PF,
          ],
        },
        {
          label: 'Declaração Anual de Débitos',
          path: '/cliente/produtos/vida/declaracao-anual-debitos',
          permissions: [
            VIDA_PERMISSIONS.CERTIFICADO_PF,
            VIDA_PERMISSIONS.CERTIFICADO_PJ,
            VIDA_PERMISSIONS.BILHETE_PF,
          ],
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
          ],
        },
        {
          label: 'Informações IRPF',
          path: '/cliente/produtos/vida/dados-irpf',
          permissions: [
            VIDA_PERMISSIONS.CERTIFICADO_PF,
            VIDA_PERMISSIONS.BILHETE_PF,
          ],
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_ENTRADA,
          ],
        },
        {
          label: 'Consulta de Sinistro',
          path: '/cliente/produtos/vida/consulta-sinistro',
          roles: [USER_PROFILES.ANALISTA_TI],
          permissions: [
            VIDA_PERMISSIONS.CERTIFICADO_PF,
            VIDA_PERMISSIONS.BILHETE_PF,
          ],
        },
      ],
    },
  ],
};
