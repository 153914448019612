// @ts-nocheck
import React from 'react';
import {
  Grid,
  Accordion,
  Card,
  Button,
  Display,
  TextField,
} from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import masks from 'main/utils/masks';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import FormDadosCliente from 'registroOcorrenciaASC/features/registrarOcorrencia/components/FormDadosCliente';
import { useRenderizarCardCliente } from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks';
import * as S from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CardDadosCliente/styles';
import * as OCORRENCIA_STYLES from 'registroOcorrenciaASC/styles/styles';
import * as OCORRENCIA_UTILS from 'registroOcorrenciaASC/utils/RegistroOcorrenciaASCUtils';

const CardDadosCliente = (): React.ReactElement => {
  const {
    formik,
    exibeFormDadosCliente,
    loadingDadosConsultaCliente,
    cpfOuCnpjValido,
  } = useRenderizarCardCliente();

  return (
    <OCORRENCIA_STYLES.CardContainer>
      <Card.Content>
        <Accordion open data-testid="accordionDadosCliente">
          <Accordion.Item title="Dados do cliente">
            <Grid>
              <Grid.Item xs={1} md={1 / 2}>
                <S.ContainerConsultarCliente>
                  <TextField
                    label="CPF/CNPJ"
                    data-testid="inputConsultaCpfCnpjCliente"
                    name="inputconsultaCpfCnpj"
                    value={masks.cpfCnpj.mask(
                      formik.values.inputconsultaCpfCnpj,
                    )}
                    onBlur={formik.handleBlur}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue(
                        'inputconsultaCpfCnpj',
                        masks.cpfCnpj.unmask(value),
                      );
                    }}
                    {...OCORRENCIA_UTILS.showErrorInput(
                      formik,
                      'inputconsultaCpfCnpj',
                    )}
                  />
                  <Display>
                    <Button
                      data-testid="btnConsultaCpfCnpjClienteConsultar"
                      variant="secondary"
                      onClick={formik.submitForm}
                      loading={loadingDadosConsultaCliente}
                      disabled={checkIfSomeItemsAreTrue([
                        !cpfOuCnpjValido,
                        loadingDadosConsultaCliente,
                      ])}
                    >
                      Consultar
                    </Button>
                  </Display>
                </S.ContainerConsultarCliente>
              </Grid.Item>
            </Grid>
            <RenderConditional condition={exibeFormDadosCliente}>
              <FormDadosCliente
                loadingDadosConsultaCliente={loadingDadosConsultaCliente}
              />
            </RenderConditional>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </OCORRENCIA_STYLES.CardContainer>
  );
};

export default CardDadosCliente;
