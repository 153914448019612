// @ts-nocheck
import { useState } from 'react';
import { ICardProdutoPrestamista } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoPrestamista';
import { ICardProdutoVida } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoVida';

import {
  obterCertificadoDetalhe,
  obterProdutosPrestamista,
  obterProdutosPrevidencia,
  obterProdutosVida,
} from 'consultaCliente/features/listaCardProduto/service/consultarProdutosApi';
import { UseCardsProdutoType } from 'consultaCliente/features/listaCardProduto/types/UseCardsProdutoType';
import { preencherClassificacaoDoPlano } from 'consultaCliente/features/listaCardProduto/utils/certificadoPrevidencia';
import { IDadosBasicosCertificadoPrevidencia } from 'main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';

const useCardsProduto = (): UseCardsProdutoType => {
  const [dadosCardsPrevidencia, setDadosCardsPrevidencia] = useState<
    IDadosBasicosCertificadoPrevidencia[] | undefined
  >();
  const [isLoadingPrevidencia, setIsLoadingPrevidencia] =
    useState<boolean>(false);

  const [dadosCardsVida, setDadosCardsVida] = useState<
    ICardProdutoVida[] | undefined
  >();
  const [isLoadingVida, setIsLoadingVida] = useState<boolean>(false);

  const [dadosCardsPrestamista, setDadosCardsPrestamista] = useState<
    ICardProdutoPrestamista[] | undefined
  >();
  const [isLoadingPrestamista, setIsLoadingPrestamista] =
    useState<boolean>(false);

  const fetchDadosCardsPrevidencia = async (codCliente: string) => {
    setIsLoadingPrevidencia(true);
    const resultadoDetalhesPromise = obterCertificadoDetalhe(codCliente);
    const listaCertificados = await obterProdutosPrevidencia(codCliente);
    setDadosCardsPrevidencia(listaCertificados);
    setIsLoadingPrevidencia(false);

    const detalhesCertificados = await resultadoDetalhesPromise;
    setDadosCardsPrevidencia(
      preencherClassificacaoDoPlano(listaCertificados, detalhesCertificados),
    );
  };

  const fetchDadosCardsVida = async (codCliente: string) => {
    setIsLoadingVida(true);
    const resultado = await obterProdutosVida(codCliente);
    setDadosCardsVida(resultado);
    setIsLoadingVida(false);
  };

  const fetchDadosCardsPrestamista = async (codCliente: string) => {
    setIsLoadingPrestamista(true);
    const resultado = await obterProdutosPrestamista(codCliente);
    setDadosCardsPrestamista(resultado);
    setIsLoadingPrestamista(false);
  };

  return {
    dadosCardsPrevidencia,
    isLoadingPrevidencia,
    fetchDadosCardsPrevidencia,
    dadosCardsVida,
    isLoadingVida,
    fetchDadosCardsVida,
    fetchDadosCardsPrestamista,
    dadosCardsPrestamista,
    isLoadingPrestamista,
  };
};

export default useCardsProduto;
