// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IResponseCancelarTransferencia,
  IRequestCancelarTransferencia,
} from '../types/CancelarTransferencia';

export const usePecoCancelarTransferencia = (
  idRequisicao: string,
  codigoMotivoCancelamento: string,
) => {
  const {
    response,
    loading: loadingCancelarTransferencia,
    fetchData,
  } = usePeco<IRequestCancelarTransferencia, IResponseCancelarTransferencia>({
    api: {
      operationPath: PECOS.CancelarTransferenciaVgblConjugado,
    },
    payload: {
      numeroSolicitacao: idRequisicao,
      codigoMotivo: codigoMotivoCancelamento,
    },
  });

  const sucesso = response?.sucessoBFF;
  const mensagem = response?.mensagens?.[0]?.descricao;

  return {
    ResponseCancelarTransferencia: response?.entidade,
    loadingCancelarTransferencia,
    CancelarSolicitacao: fetchData,
    sucesso,
    mensagem,
  };
};
