// @ts-nocheck
import { obterEndpointInformeIR } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseInformeIR } from '../types/InformeIR';

export const obterInformeIR = async (
  Cpf: string,
  AnoBase: string,
  TipoPessoa: string,
): Promise<ResponseInformeIR | undefined> => {
  if (!Cpf) return undefined;
  const { data } = await api.post<IApiResponse<ResponseInformeIR>>(
    obterEndpointInformeIR(),
    {
      Cpf,
      AnoBase,
      TipoPessoa,
      Email: '',
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
