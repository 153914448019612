// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { Card, Text } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import usePrevSummary from 'previdencia/hooks/usePrevSummary';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import RenderConditional from 'main/components/RenderConditional';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import { useHistory, useLocation } from 'react-router';
import ModalAviso from 'main/components/AssinaturaDocuSign/ModalAviso';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import useSolicitarAssinaturaBeneficioProtecao from 'previdencia/hooks/useSolicitarAssinaturaBeneficioProtecao';
import { DadosClienteModalTipoDocuSingProps } from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import { IMessageEvent } from 'main/types/IMessageEvent';
import TextoJuridicoAssinatura from 'previdencia/components/TextoJuridicoAssinatura';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import DadosBeneficio from 'previdencia/features/BeneficioDeProtecao/components/DadosBeneficio';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import {
  LocationType,
  Participante,
  PlanoSelecionado,
} from 'previdencia/features/BeneficioDeProtecao/types/BeneficioProtecaoTypes';
import FaixaRenda from 'previdencia/features/BeneficioDeProtecao/components/FaixaRenda/FaixaRenda';
import SelecionarCobertura from 'previdencia/features/BeneficioDeProtecao/components/SelecionarCobertura';
import AdquirirCobertura from 'previdencia/features/BeneficioDeProtecao/components/AdquirirCobertura';
import ConfirmarAquisicao from 'previdencia/features/BeneficioDeProtecao/components/ConfirmarAquisicao';
import { Display } from './styles';
import useCalcularBeneficioProtecao from 'previdencia/features/BeneficioDeProtecao/hooks/useCalcularBeneficioProtecao';
import useResgatarBeneficioProtecao from 'previdencia/features/BeneficioDeProtecao/hooks/useResgatarBeneficioProtecao';
import {
  calcularContribuicoesAtivas,
  validarAssinaturaDigital,
  validarReativacao,
  verificaAssinaturaDigital,
  validaProximaEtapa,
  validaAssinaturaPendente,
  validarDisponibilidade,
} from 'previdencia/features/BeneficioDeProtecao/utils/beneficioDeProtecao';
import Personalizar from 'previdencia/features/BeneficioDeProtecao/components/Personalizar/Personalizar';
import {
  construirPayloadIncluir,
  construirPayloadReativar,
  definirCodigoManutencao,
  definirPlano,
} from 'previdencia/features/BeneficioDeProtecao/factories/beneficioDeProtecaoFactory';
import MensagemErro from 'previdencia/features/BeneficioDeProtecao/components/MensagemErro';
import useIncluirBeneficioProtecao from 'previdencia/features/BeneficioDeProtecao/hooks/useIncluirBeneficioProtecao';
import useReativarBeneficioProtecao from 'previdencia/features/BeneficioDeProtecao/hooks/useReativarBeneficioProtecao';

const BeneficioDeProtecao: React.FC = () => {
  const { state } = useLocation<LocationType>();

  const history = useHistory();

  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  const [idRequisicao, setIdRequisicao] = useState('');

  const [etapaAtual, setEtapaAtual] = useState(0);
  const [flagPersonalizar, setFlagPersonalizar] = useState(false);
  const [exibirModalContatos, setExibirModalContatos] = useState(false);
  const [cobertura, setCobertura] = useState<number>();
  const [prazo, setPrazo] = useState<number | undefined>();
  const [planoSelecionado, setPlanoSelecionado] = useState<
    PlanoSelecionado | undefined
  >();
  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] = useState(false);
  const [openModalDocuSign, setOpenModalDocuSign] = useState<boolean>(false);
  const [openModalAviso, setOpenModalAviso] = useState<boolean>(false);
  const [rendaBruta] = useFieldLink('');
  const [mensagemErro, setMensagemErro] = useState<string | undefined>();
  const { informacaoPorCertificado, isLoading: loadingCertificado } =
    usePrevSummary();

  const {
    response: dadosBeneficiosRetorno,
    loading: loadingDadosBeneficios,
    erro: erroDadosBeneficio,
  } = useResgatarBeneficioProtecao();
  const dadosBeneficios = dadosBeneficiosRetorno?.entidade;
  const payloadCalcular = {
    salario: Number(rendaBruta.get().value) / 100,
    prazo,
  };
  const {
    response: retornoCalculo,
    loading: loadingCalculo,
    fetchData: obterCalcularBeneficio,
  } = useCalcularBeneficioProtecao(payloadCalcular);
  const responseCalculo = retornoCalculo?.entidade;
  const {
    data: reponseObterContatosDefault,
    isFetching: loadingObterContatos,
  } = useObterContatosDefaultPrevidencia();

  const {
    loading: loadingIncluirBeneficio,
    response: responseIncluirPeco,
    fetchData: obterIncluirBeneficio,
  } = useIncluirBeneficioProtecao(
    construirPayloadIncluir(informacaoPorCertificado, planoSelecionado),
  );
  const responseIncluirBeneficio = responseIncluirPeco?.entidade;
  const {
    loading: loadingReativarBeneficio,
    response: responseReativarPeco,
    fetchData: obterReativarBeneficio,
  } = useReativarBeneficioProtecao(
    construirPayloadReativar(informacaoPorCertificado, planoSelecionado),
  );

  const responseReativarBeneficio = responseReativarPeco?.entidade;
  const {
    isLoading: isLoadingSolicitarAssinatura,
    response: dadosSolicitarAssinatura,
    solicitarAssinaturaBeneficioProtecao,
  } = useSolicitarAssinaturaBeneficioProtecao();

  const { emailDefault, numerosTelefone, nome } =
    reponseObterContatosDefault ?? {};

  const disableButtonDocuSing = checkIfSomeItemsAreTrue([
    !emailDefault,
    !numerosTelefone,
  ]);

  const { subscribe, unsubscribe } = useMessageEvent();

  const permissaoAssinaturaDigital = validarAssinaturaDigital(dadosBeneficios);
  const reativacao = validarReativacao(dadosBeneficios, cobertura);

  const getParticipanteData = (): Participante => {
    return {
      produto: informacaoPorCertificado?.produto.descricaoProduto,
      certificado: informacaoPorCertificado?.certificadoNumero,
      valorTotal: calcularContribuicoesAtivas(
        informacaoPorCertificado?.certificadoBeneficios,
      ),
    };
  };

  const construirPayload = (): DadosClienteModalTipoDocuSingProps => {
    return {
      email: emailDefault,
      mobileNumber: numerosTelefone,
      cpfCnpj,
      nome,
    };
  };

  const proximaEtapa = () => {
    setEtapaAtual(etapaAtual + 1);
  };

  const etapaAnterior = () => {
    setEtapaAtual(etapaAtual - 1);
  };

  const handleCloseModalDocuSign = () => {
    setOpenModalDocuSign(false);
  };

  const handleSetCobertura = (value: number) => {
    setPrazo(undefined);
    setCobertura(value);
  };

  const handleSetPlano = (
    value: string,
    contribuicao: number | undefined,
    beneficio: number | undefined,
  ) => {
    setPlanoSelecionado(
      definirPlano(
        dadosBeneficios?.coberturas,
        responseCalculo?.valorContribuicaoAtual,
        value,
        cobertura,
        contribuicao,
        beneficio,
      ),
    );
  };

  const concluirFluxo = () => {
    if (reativacao) {
      obterReativarBeneficio();
      return;
    }

    obterIncluirBeneficio();
  };

  useEffect(() => {
    if (dadosSolicitarAssinatura?.urlAssinatura) {
      setOpenModalAviso(true);
    }
  }, [dadosSolicitarAssinatura]);

  useEffect(() => {
    if (validaProximaEtapa(loadingCalculo, responseCalculo, flagPersonalizar)) {
      proximaEtapa();
    }
  }, [responseCalculo, loadingCalculo]);

  useEffect(() => {
    subscribe(event => {
      const {
        data: { eventName },
      } = event as MessageEvent<IMessageEvent>;
      if (eventName === 'retornoAcaoConcluirAssinatura') {
        setExibirBotaoFecharModal(true);
      }
    });
    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(false));
    };
  }, []);

  useEffect(() => {
    setMensagemErro(
      validarDisponibilidade(dadosBeneficiosRetorno, erroDadosBeneficio),
    );
    setExibirModalContatos(permissaoAssinaturaDigital);
  }, [dadosBeneficios]);

  useEffect(() => {
    setIdRequisicao(validaAssinaturaPendente(state));
  }, [state?.from, state?.data?.idRequisicao]);

  useEffect(() => {
    if (idRequisicao !== '') {
      solicitarAssinaturaBeneficioProtecao(construirPayload(), idRequisicao);
    }
  }, [idRequisicao]);

  useEffect(() => {
    if (
      verificaAssinaturaDigital(
        reativacao,
        loadingIncluirBeneficio,
        responseIncluirBeneficio,
        loadingReativarBeneficio,
        responseReativarBeneficio,
      )
    ) {
      solicitarAssinaturaBeneficioProtecao(
        construirPayload(),
        String(
          definirCodigoManutencao(
            reativacao,
            responseReativarBeneficio?.codigoManutencao,
            responseIncluirBeneficio?.codigoManutencao,
          ),
        ),
      );
    }
  }, [responseIncluirBeneficio, loadingIncluirBeneficio]);

  const telas = [
    <DadosBeneficio
      proximaEtapa={proximaEtapa}
      dataParticipante={getParticipanteData()}
      dataBeneficios={dadosBeneficios}
    />,
    <FaixaRenda
      rendaBrutaLink={rendaBruta}
      proximaEtapa={proximaEtapa}
      etapaAnterior={etapaAnterior}
    />,
    <SelecionarCobertura
      faixaRenda={rendaBruta.get().value}
      beneficios={dadosBeneficios}
      coberturaSelecionada={cobertura}
      prazo={prazo}
      loading={loadingCalculo}
      setPrazo={setPrazo}
      setCobertura={handleSetCobertura}
      proximaEtapa={obterCalcularBeneficio}
      etapaAnterior={etapaAnterior}
    />,
    <AdquirirCobertura
      response={responseCalculo}
      beneficios={dadosBeneficios}
      cobertura={cobertura}
      setPersonalizar={setFlagPersonalizar}
      setPlano={handleSetPlano}
      proximaEtapa={proximaEtapa}
      etapaAnterior={etapaAnterior}
    />,
    <ConfirmarAquisicao
      prazo={prazo}
      planoSelecionado={planoSelecionado}
      loading={checkIfSomeItemsAreTrue([
        loadingIncluirBeneficio,
        loadingReativarBeneficio,
        isLoadingSolicitarAssinatura,
      ])}
      concluirFluxo={concluirFluxo}
      etapaAnterior={etapaAnterior}
    />,
  ];

  if (
    checkIfSomeItemsAreTrue([
      loadingCertificado,
      loadingDadosBeneficios,
      loadingObterContatos,
    ])
  ) {
    return <SkeletonLoading lines={6} />;
  }

  return (
    <Display type="inline-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO]}
      >
        <Card width="100%">
          <Card.Content width="100%" padding={[4, 4, 4]}>
            <Text variant="body01-lg" color="primary" margin>
              Incluir Benefício de Proteção
            </Text>
            <RenderConditional condition={!!mensagemErro}>
              <MensagemErro mensagem={mensagemErro} />
            </RenderConditional>
            <RenderConditional condition={!mensagemErro}>
              <RenderConditional condition={flagPersonalizar}>
                <Personalizar
                  response={responseCalculo}
                  cobertura={cobertura}
                  prazo={prazo}
                  valorSelecionadoLink={rendaBruta}
                  setPersonalizar={setFlagPersonalizar}
                  setPlano={handleSetPlano}
                  proximaEtapa={proximaEtapa}
                />
              </RenderConditional>
              <RenderConditional condition={!flagPersonalizar}>
                {telas[etapaAtual]}
              </RenderConditional>
            </RenderConditional>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          idRequisicao === '',
          permissaoAssinaturaDigital,
        ])}
      >
        <ModalAlertaContatos
          open={exibirModalContatos}
          onClose={() => setExibirModalContatos(false)}
          redirect={() =>
            history.push('/cliente/produtos/previdencia/dados-participante')
          }
          dados={{
            email: emailDefault,
            telefone: numerosTelefone,
          }}
        />
      </RenderConditional>

      <RenderConditional condition={permissaoAssinaturaDigital}>
        <ModalTokenDocuSign
          open={openModalDocuSign}
          urlTokenPage={tryGetValueOrDefault(
            [dadosSolicitarAssinatura?.urlAssinatura],
            '',
          )}
          handleClose={() => handleCloseModalDocuSign()}
          exibirBotaoFecharModal={exibirBotaoFecharModal}
        />
        <ModalAviso
          titulo="Inclusão de Benefício de Proteção"
          textoSolicitacao="Solicitação de inclusão de benefício de proteção recebida com sucesso."
          openModal={openModalAviso}
          setOpenModal={setOpenModalAviso}
          setModalAssinatura={setOpenModalDocuSign}
          disableButtonDocuSing={disableButtonDocuSing}
        >
          <TextoJuridicoAssinatura />
        </ModalAviso>
      </RenderConditional>
    </Display>
  );
};
export default BeneficioDeProtecao;
