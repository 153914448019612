// @ts-nocheck
import RenderConditional from 'main/components/RenderConditional';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import CardDadosInterlocutor from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CardDadosInterlocutor';
import CardDadosCliente from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CardDadosCliente';
import CardDadosOcorrencia from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CardDadosOcorrencia';
import CardDadosProtocolo from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CardDadosProtocolo';

const CardsRegistroOcorrencia = (): React.ReactElement => {
  const { aberturaOcorrencia } = useRegistrarOcorrenciaContext();

  return (
    <>
      <RenderConditional condition={!aberturaOcorrencia.ocorrenciaAberta}>
        <CardDadosInterlocutor />

        <CardDadosCliente />

        <CardDadosOcorrencia />
      </RenderConditional>

      <RenderConditional condition={aberturaOcorrencia.ocorrenciaAberta}>
        <CardDadosProtocolo />
      </RenderConditional>
    </>
  );
};

export default CardsRegistroOcorrencia;
