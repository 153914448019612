// @ts-nocheck
import React from 'react';
import { TextField } from '@cvp/design-system/react';
import ITextFieldDpsProps from '../interface/ITextFieldDpsProps';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';

export const TextFieldDps: React.FC<ITextFieldDpsProps> = ({
  label,
  name,
  placeholder,
  value,
  handleBlur,
  mask,
  unmask,
  setFieldValue,
  error,
  errorMessage,
}) => {
  const newValue = tryGetValueOrDefault([value], '');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unmaskedValue = getTernaryResult(
      !!unmask,
      unmask?.(e.target.value),
      e.target.value,
    );
    setFieldValue(name, unmaskedValue);
  };

  return (
    <TextField
      label={label}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      value={getTernaryResult(
        !!mask,
        tryGetValueOrDefault([mask?.(newValue)], newValue),
        newValue,
      )}
      name={name}
      error={error}
      errorMessage={errorMessage}
    />
  );
};
