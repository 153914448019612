// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  ResponseSolicitarAssinatura,
  IRequestSolicitarAsssinatura,
} from 'previdencia/features/AlteracaoBeneficiarios/types/SolicitarAssinaturaBeneficiario';
import { Assinante } from 'previdencia/features/AlteracaoBeneficiarios/types/Beneficiarios';

const useSolicitarAssinaturaBeneficiarios = (
  idRequisicao?: string,
  assinantes?: Assinante[],
) => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const { response, loading, fetchData } = usePeco<
    IRequestSolicitarAsssinatura,
    ResponseSolicitarAssinatura
  >({
    api: {
      operationPath: PECOS.SolicitarAssinaturaBeneficiariosPrevidencia,
    },
    autoFetch: false,
    payload: {
      NumeroCertificado: numCertificado,
      idRequisicao,
      assinantes,
    },
  });

  return { response, loading, fetchData };
};

export default useSolicitarAssinaturaBeneficiarios;
