// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS_PROPOSTA_VIDA } from 'propostasVida/config/endpoints';
import { IResponseConsultaDps } from '../interface/IResponseDps';
import { IRequestConsultaDps } from '../interface/ISolicitaDps';

import getAuthData from 'main/features/Auth/utils/auth';
import { mockResponseConsultaDps } from '../mock/mockResponseConsultaDps';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const useConsultarDps = () => {
  const { user } = getAuthData();

  const { loading, fetchData } = usePeco<
    IRequestConsultaDps,
    IResponseConsultaDps[]
  >({
    api: { operationPath: PECOS_PROPOSTA_VIDA.ConsultarVida },
    autoFetch: true,
    payload: {
      codUnidadeHierarquia: user.agenciaVinculada,
    },
    handleResponse: {
      throwToastErrorGI: false,
      throwToastErrorBFF: false,
    },
  });

  return {
    obterConsultaVida: tryGetValueOrDefault([], mockResponseConsultaDps),
    loadingConsultaVida: loading,
    fetchData,
  };
};
