// @ts-nocheck
import { obterEndpointSolicitarCancelamentoPrevidencia } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import {
  IResponseSolicitacaoCancelamento,
  SolicitacaoCancelamentoPayload,
} from '../types/IResponseCancelamento';

export const solicitaCancelamentoPrevidencia = async (
  payload: SolicitacaoCancelamentoPayload,
): Promise<IResponseSolicitacaoCancelamento | undefined> => {
  const { data } = await api.post<
    IApiResponse<IResponseSolicitacaoCancelamento | undefined>
  >(obterEndpointSolicitarCancelamentoPrevidencia(), { ...payload });

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
