// @ts-nocheck
import React from 'react';
import PrivateRoute, {
  IPrivateRoute,
} from 'main/components/Route/PrivateRoute';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { useHistory } from 'react-router';
import {
  DetalhaProspeccao,
  FormularioProspeccao,
  ListaProspeccoes,
  Cadastro,
} from '../../../main/features/prospeccao/features/consulta/pages';
import ProspeccaoContextProvider from '../../../main/features/prospeccao/contexts/ProspeccaoContext';
import ListaProspeccoesAnaliseMedica from '../../../main/features/prospeccao/features/analise/pages/ListaProspeccoesAnaliseMedica';
import DetalhesDefinirParecerMedico from '../../../main/features/prospeccao/features/analise/pages/DetalhesDefinirParecerMedico';
import DetalhesDefinirParecerTimeProduto from '../../../main/features/prospeccao/features/analise/pages/DetalhesDefinirParecerTimeProduto';
import DetalhesDefinirParecerTimeOperacao from '../../../main/features/prospeccao/features/analise/pages/DetalhesDefinirParecerTimeOperacao';
import ListaAnaliseTimeProduto from '../../../main/features/prospeccao/features/analise/pages/ListaAnaliseTimeProduto';
import ListaProspeccoesAvaliadas from '../../../main/features/prospeccao/features/analise/pages/ListaProspeccoesAvaliadas';

const routes: IPrivateRoute[] = [
  {
    path: '/prestamista/preanalise/cadastro',
    exact: true,
    component: Cadastro,
    key: 'prospeccao-cadastro',
    authenticated: true,
    breadcrumb: 'Cadastro',
    requiredRoles: PRINCIPAL_USERS,
  },
  {
    path: '/prestamista/preanalise/formulario',
    exact: true,
    component: FormularioProspeccao,
    key: 'prospeccao-formulario',
    authenticated: true,
    breadcrumb: 'Formulário',
    requiredRoles: PRINCIPAL_USERS,
  },
  {
    path: '/prestamista/preanalises',
    exact: true,
    component: ListaProspeccoes,
    key: 'lista-prospecao',
    authenticated: true,
    breadcrumb: 'Pré-Análises',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
  {
    path: '/prestamista/preanalises/:id/detalhes',
    exact: true,
    component: DetalhaProspeccao,
    key: 'detalhe-tarefas',
    authenticated: true,
    breadcrumb: 'Detalhes da Pré-Análise',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },

  {
    path: '/prestamista/preanalises/analise-medica',
    exact: true,
    component: ListaProspeccoesAnaliseMedica,
    key: 'prospeccao-analise',
    authenticated: true,
    breadcrumb: 'REGISTRO DO PARECER MÉDICO DE DPS ',
    requiredRoles: [
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.MEDICO,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/prestamista/preanalises/analise-medica/:id',
    exact: true,
    component: DetalhesDefinirParecerMedico,
    key: 'prospeccao-definir-parecer',
    authenticated: true,
    breadcrumb: 'DETALHES DO PARECER MÉDICO',
    requiredRoles: [
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.MEDICO,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/prestamista/preanalises/analise-produto',
    exact: true,
    component: ListaAnaliseTimeProduto,
    key: 'prospeccao-analise-parecer-medico',
    authenticated: true,
    breadcrumb: 'REGISTRO DO PARECER TÉCNICO',
    requiredRoles: [
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_PRODUTO,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/prestamista/preanalises/analise-produto/:id',
    exact: true,
    component: DetalhesDefinirParecerTimeProduto,
    key: 'prospeccao-definir-parecer-produto',
    authenticated: true,
    breadcrumb: 'DETALHES DO PARECER TÉCNICO',
    requiredRoles: [
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_PRODUTO,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/prestamista/preanalises/avaliadas',
    exact: true,
    component: ListaProspeccoesAvaliadas,
    key: 'prospeccao-analise-time-produto',
    authenticated: true,
    breadcrumb: 'Visualizar oportunidades avaliadas',
    requiredRoles: [
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/prestamista/preanalises/avaliadas/:id',
    exact: true,
    component: DetalhesDefinirParecerTimeOperacao,
    key: 'prospeccao-definir-parecer-operacao',
    authenticated: true,
    breadcrumb: 'DETALHES DAS ANÁLISES MÉDICA E TÉCNICA',
    requiredRoles: [
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
];

export const prospeccaoBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/prestamista/preanalise': null,
  '/prestamista/preanalises/:id': null,
};

const RotasProspecao: React.FC = () => {
  const history = useHistory();

  return (
    <ProspeccaoContextProvider
      getAnalistaProdutoRouteDetails={prospeccao => {
        history.push({
          pathname: `/prestamista/preanalises/analise-produto/${prospeccao.id}`,
          state: { cpfCnpj: prospeccao.cpfCnpj },
        });
      }}
      getDefaultRouteDetails={prospeccao => {
        history.push({
          pathname: `/prestamista/preanalises/${prospeccao.id}/detalhes`,
          state: { cpfCnpj: prospeccao.cpfCnpj },
        });
      }}
      getMedicoRouteDetails={prospeccao => {
        history.push({
          pathname: `/prestamista/preanalises/analise-medica/${prospeccao.id}`,
          state: { cpfCnpj: prospeccao.cpfCnpj },
        });
      }}
      getOperadorRouteDetails={prospeccao => {
        history.push({
          pathname: `/prestamista/preanalises/avaliadas/${prospeccao.id}`,
          state: { cpfCnpj: prospeccao.cpfCnpj },
        });
      }}
      getRouteCadastro={() =>
        history.push('/prestamista/preanalise/formulario')
      }
      getRouteListaPreAnalise={() => '/prestamista/preanalises'}
    >
      {routes.map(route => (
        <PrivateRoute
          key={route.key}
          path={route.path}
          exact={route.exact}
          component={route.component}
          authenticated={route.authenticated}
          requiredRoles={route.requiredRoles}
        />
      ))}
    </ProspeccaoContextProvider>
  );
};

export default RotasProspecao;
