// @ts-nocheck
import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { SolicitacaoAlteracaoContribuicaoRenda } from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';
import * as AlteracaoContribuicaoRendaApi from 'previdencia/features/AlterarValorContribuicao/services/solicitarAlteracaoValorContribuicaoRenda.api';
import { SolicitarAlteracaoContribuicaoRendaParams } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoBeneficioProtecao';

const useSolicitarAlteracaoValorContribuicaoRenda = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setArrayResponse] = useState<
    SolicitacaoAlteracaoContribuicaoRenda | undefined
  >();

  const { cliente } = useContext(AppContext);

  const obterSolicitacaoAlteracaoContribuicaoRenda = async (
    params: SolicitarAlteracaoContribuicaoRendaParams,
  ): Promise<void> => {
    try {
      setLoading(true);
      const dados =
        await AlteracaoContribuicaoRendaApi.obterSolicitacaoAlteracaoContribuicaoRenda(
          {
            ...params,
            cpf: cliente.cpfCnpj,
            numCertificado: cliente.numCertificado,
          },
        );

      setArrayResponse(dados);
    } catch (error: any) {
      toastError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    response,
    setArrayResponse,
    loading,
    obterSolicitacaoAlteracaoContribuicaoRenda,
  };
};

export default useSolicitarAlteracaoValorContribuicaoRenda;
