// @ts-nocheck
import { useLocation } from 'react-router-dom';

const useSegmento = () => {
  const location = useLocation();

  const posicaoSegmentoSelecionadoNaRota = 1;

  const validarSegmentoRota = (segmento: string) =>
    location.pathname.includes(segmento);

  const obterSegmentoRota = () =>
    location.pathname
      .split('/')
      [posicaoSegmentoSelecionadoNaRota].toUpperCase();

  return {
    validarSegmentoRota,
    obterSegmentoRota,
  };
};

export default useSegmento;
