// @ts-nocheck
import { ISubscricaoSocio } from 'contratosPrestamista/types';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import { Display, Button, Tooltip, Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import {
  obterMensagemNecessidadeDps,
  obterTextoBotaoValidarDados,
  obterVariacaoBotaoValidaDados,
} from '../utils';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';

type IGerarColunasTabelaSociosPJProps = {
  dadosSocios: ISubscricaoSocio[];
  handleOpenValidarSocio: (socio: ISubscricaoSocio) => void;
};
export const GerarColunasTabelaSociosPJ = ({
  dadosSocios,
  handleOpenValidarSocio,
}: IGerarColunasTabelaSociosPJProps) => [
  {
    name: 'CPF',
    selector: 'cpf',
    cell: (row: ISubscricaoSocio) => (
      <Tooltip
        text={obterMensagemNecessidadeDps(row.necessitaDps)}
        position="top"
        variant="secondary"
      >
        <Display alignItems="center">
          <Icon name="warning" /> {maskCpfCnpj(row.cpf)}
        </Display>
      </Tooltip>
    ),
  },
  {
    name: 'Capital Segurado Individual',
    selector: 'capitalSegurado',
    cell: (row: ISubscricaoSocio) => (
      <span>{formatarValorPadraoBrasileiro(row.capitalSegurado)}</span>
    ),
  },
  {
    name: 'Total de acúmulo de risco',
    selector: 'valorAcumulo',
    cell: (row: ISubscricaoSocio) => (
      <span>{formatarValorPadraoBrasileiro(row.valorAcumulo)}</span>
    ),
  },
  {
    name: 'Status DPS',
    selector: 'valorAcumulo',
    cell: (row: ISubscricaoSocio) => {
      const possuiDpsExistentes =
        tryGetValueOrDefault([row?.listaDpsExistentes], []).length > 0;
      return (
        <>
          <RenderConditional
            condition={checkIfAllItemsAreTrue([
              !row.necessitaDps,
              possuiDpsExistentes,
            ])}
          >
            <Text variant="caption-02">
              Não necessita DPS - DPS válida dentro de 90 dias
            </Text>
          </RenderConditional>
          <RenderConditional
            condition={checkIfAllItemsAreTrue([
              !row.necessitaDps,
              !possuiDpsExistentes,
            ])}
          >
            <Text variant="caption-02">Envio da DPS não necessário</Text>
          </RenderConditional>
          <RenderConditional condition={row.necessitaDps}>
            <Text variant="caption-02">Envio da DPS necessário</Text>
          </RenderConditional>
        </>
      );
    },
  },
  {
    name: 'Ação',
    selector: 'socios',
    cell: (row: ISubscricaoSocio) => {
      const handleOnClick = () => {
        const socio = dadosSocios.find(x => x.cpf === row.cpf);
        if (socio) {
          handleOpenValidarSocio(socio);
        }
      };
      return (
        <>
          <RenderConditional condition={row.necessitaDps}>
            <Button
              variant={obterVariacaoBotaoValidaDados(
                dadosSocios.find(x => x.cpf === row.cpf)?.validado,
              )}
              onClick={handleOnClick}
            >
              {obterTextoBotaoValidarDados(
                dadosSocios.find(x => x.cpf === row.cpf)?.validado,
              )}
            </Button>
          </RenderConditional>
          <RenderConditional condition={!row.necessitaDps}>
            <Button variant="outlined" onClick={handleOnClick}>
              Detalhes
            </Button>
          </RenderConditional>
        </>
      );
    },
  },
];
