// @ts-nocheck
import { FormikProps } from 'formik';
import * as Yup from 'yup';

import { tryGetValueOrDefault } from 'main/utils/conditional';
import { obterCodigoOperacaoENumeroConta } from 'previdencia/utils/previdencia';
import { ResponseDadosCertificadosPorCpf } from 'previdencia/types/DadosCertificado';
import * as UTILS from 'previdencia/features/AlteracaoFormaDadosPagamento/utils/alteracaoFormaDadosPagamento';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

export const converterDadosBasicoContaParaDadosPagamento = (
  formik: FormikProps<ALTERACAO_CONTA_TYPES.IFormikValuesAlteracaoContaDebito>,
  dadosBancoSelecionado:
    | ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias
    | undefined,
): ALTERACAO_CONTA_TYPES.DadosPagamento | undefined => {
  return {
    tipoPagamentoId: CONSTS.TIPO_PAGAMENTO_ID,
    tipoContaBanco: tryGetValueOrDefault(
      [dadosBancoSelecionado?.tipoContaBanco],
      UTILS.converterCodigoOperacaoParaTipoContaBanco(formik.values.operacao),
    ),
    numeroBanco: CONSTS.NUMERO_BANCO_CAIXA,
    numeroAgencia: tryGetValueOrDefault(
      [dadosBancoSelecionado?.numeroAgencia],
      formik.values.agencia,
    ),
    numeroConta: tryGetValueOrDefault(
      [dadosBancoSelecionado?.numeroConta.toString()],
      `${formik.values.operacao}${UTILS.formatarNumeroContaCorrente(
        formik.values.conta,
      )}`,
    ),
    digitoConta: tryGetValueOrDefault(
      [dadosBancoSelecionado?.digitoConta],
      formik.values.digitoBanco,
    ),
  };
};

export const buscarContaPorCanalId = (
  dados: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias[] | undefined,
  canalId: string,
): ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias[] | undefined => {
  const retorno: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias[] = [];

  const dado = dados?.find(e => e.idCanalRecuperado === canalId);

  if (dado) {
    retorno.push(dado);
  }

  return retorno;
};

export const converterDadosNovaContaParaDadosContasBancarias = (
  novaConta: ALTERACAO_CONTA_TYPES.DadosPagamento | undefined,
): ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias[] | undefined => {
  const retorno: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias[] = [];

  retorno.push({
    descricaoPagamento: '',
    digitoConta: tryGetValueOrDefault([novaConta?.digitoConta], ''),
    idCanalRecuperado: '',
    numeroAgencia: tryGetValueOrDefault([novaConta?.numeroAgencia], ''),
    numeroBanco: tryGetValueOrDefault([novaConta?.numeroBanco], ''),
    metodoPagamento: CONSTS.TIPO_CONTA.DEBITO_CONTA_SIGLA,
    numeroConta: UTILS.formatarNumeroContaCorrente(
      tryGetValueOrDefault([novaConta?.numeroConta], ''),
    ),
    tipoContaBanco: tryGetValueOrDefault([novaConta?.tipoContaBanco], ''),
    tipoPagamentoId: tryGetValueOrDefault([novaConta?.tipoPagamentoId], ''),
  });

  return retorno;
};

export const converterDadosCertificadoParaDadosContasBancarias = (
  dados: ResponseDadosCertificadosPorCpf | undefined,
): ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias[] | undefined => {
  const retorno: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias[] = [];
  retorno.push({
    descricaoPagamento: '',
    digitoConta: tryGetValueOrDefault([dados?.digitoConta], ''),
    idCanalRecuperado: '',
    numeroAgencia: tryGetValueOrDefault([dados?.numeroAgencia], ''),
    numeroBanco: tryGetValueOrDefault([dados?.numeroBanco], ''),
    metodoPagamento: tryGetValueOrDefault(
      [dados?.metodoPagamento],
      CONSTS.TIPO_CONTA.DEBITO_CONTA_SIGLA,
    ),
    numeroConta: UTILS.formatarNumeroContaCorrente(
      tryGetValueOrDefault([dados?.numeroConta], ''),
    ),
    tipoContaBanco: '',
    tipoPagamentoId: tryGetValueOrDefault([dados?.codigoTipoPagamento], ''),
  });

  return retorno;
};

export const buscarTipoContribuicaoPorTipoBeneficio = (
  dados:
    | ALTERACAO_CONTA_TYPES.ResponseRecuperarContribuicoesCertificado
    | undefined,
): string | undefined => {
  const dadosBuscado = dados?.beneficioContribuicaoCertificado.find(
    e => e.tipoBeneficio === CONSTS.TIPO_BENEFICIO_PR,
  );

  return dadosBuscado?.tipoContribuicao;
};

export const FORMIK_VALIDATION = Yup.object().shape({
  agencia: Yup.string()
    .required(CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO)
    .max(4, CONSTS.FORMIK_VALIDATION_MESSAGES.MAX_CHAR),
  conta: Yup.string().required(
    CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO,
  ),
  digitoBanco: Yup.string().required(
    CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO,
  ),
});

export const dadosBancariosFactory = ({
  dadosCertificado,
  cliente,
}: ALTERACAO_CONTA_TYPES.IDadosBancariosFactoryParams) => {
  return converterDadosCertificadoParaDadosContasBancarias(
    dadosCertificado,
  )?.map(item => ({
    certificadoAntigo: cliente?.numCertificado,
    formaPagamentoAntigo: UTILS.buscarMetodoPagamento(item.metodoPagamento),
    bancoAntigo: item.numeroBanco,
    agenciaAntiga: item.numeroAgencia,
    contaAntiga: `${
      obterCodigoOperacaoENumeroConta(item.numeroConta).numeroConta
    }-${item.digitoConta}`,
  }));
};

export const dadosContaFactory = ({
  cliente,
  novosDadosConta,
}: ALTERACAO_CONTA_TYPES.IDadosContaFactoryParams) =>
  novosDadosConta?.map(item => ({
    certificado: cliente?.numCertificado,
    metodoPagamento: UTILS.buscarMetodoPagamento(item.metodoPagamento),
    numeroBanco: item.numeroBanco,
    numeroAgencia: item.numeroAgencia,
    numeroConta: item.numeroConta,
    digitoConta: item.digitoConta,
  }));
