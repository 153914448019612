// @ts-nocheck
import DataTable from 'react-data-table-component';
import styled from 'styled-components/macro';
import {
  Card,
  Disclaimer as DisclaimerDS,
  Button,
} from '@cvp/design-system/react';
import { IDadosCobertura } from '../types/TransferenciaConsultar';

export const SectionTitle = styled.div(({ theme }) => ({
  backgroundColor: '#f2f2f2',
  color: '#000',
  padding: 15,
  margin: '15px 0px',
}));

export const SectionTitlePrimary = styled.div(({ theme: { color } }) => ({
  backgroundColor: color.brandPrimary.light,
  p: {
    color: color.neutral['08'],
  },
  padding: 15,
  margin: '15px 0px',
}));

export const CardContent = styled(Card.Content)({
  '> p': {
    margin: '4px 0',
  },
});

export const Table = styled(DataTable<IDadosCobertura>)(
  ({ theme: { color, font } }) => ({
    '.rdt_TableCol, .rdt_TableCell': {
      fontSize: font.size.sm,
      fontFamily: font.family.base,
    },
    '.rdt_TableCol': {
      fontWeight: font.weight.lg,
      backgroundColor: color.neutral['08'],
      color: color.brandPrimary.light,
      '&:hover': {
        color: color.brandPrimary.light,
      },
    },
  }),
);

export const ListBeneficios = styled.div({
  display: 'flex',
  justifyContent: 'center',
  ul: {
    textAlign: 'left',
  },
});

export const AlertContainer = styled.div(({ theme: { color } }) => ({
  backgroundColor: `#fbe5d6`,
  width: '80%',
  padding: '15px',
  margin: '10px 0',
}));

export const Disclaimer = styled(DisclaimerDS)({
  button: {
    opacity: 0,
  },
});

export const IconTransfer = styled.div(({ theme: { color } }) => ({
  marginTop: '5%',
  svg: {
    fill: color.brandSecondary.light,
  },
}));

export const ComprovanteSection = styled.div(({ theme: { color } }) => ({
  border: '1px dotted #000',
  padding: '20px',
  width: '500px',
  marginTop: 20,
}));

export const ButtonLink = styled(Button)({
  padding: 5,
  minWidth: 'initial',
  textDecoration: 'underline',
});
