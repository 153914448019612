// @ts-nocheck
import React from 'react';
import { formatarData } from 'main/utils';
import Table from 'main/components/Table/Table';
import Icon from 'main/components/Icon';
import { ResponseCoberturas } from 'previdencia/types/Beneficiarios';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  definirNomeIcone,
  verificarBeneficiarios,
} from '../../utils/operacoesBeneficiarios';
import { Beneficiario } from '../../types/Beneficiarios';
import { definirColunasTabelaAlteração } from '../../utils/definirColunas';
import { HERDEIROS_LEGAIS } from '../../constants/constants';

type TabelaAlteracaoBeneficiarioProps = {
  data: ResponseCoberturas | undefined;
  print?: boolean;
};

const TabelaAlteracaoBeneficiario: React.FC<
  TabelaAlteracaoBeneficiarioProps
> = ({ data, print }) => {
  const existeBeneficiarios = verificarBeneficiarios(data);

  function beneficiariosArray() {
    if (!existeBeneficiarios) {
      return [
        {
          beneficiario: HERDEIROS_LEGAIS,
          cpfCnpj: '',
          dataNascimento: '',
          grauParentesco: '',
          coberturas: [''],
        },
      ];
    }

    const dadosBeneficiarios: Beneficiario[] = [];

    data?.retorno.beneficios?.forEach(item => {
      if (item.beneficiarios?.length === 0) {
        dadosBeneficiarios.push({
          beneficiario: HERDEIROS_LEGAIS,
          identificador: '',
          dataNascimento: '',
          grauParentesco: '',
          tipoCobertura: '',
          coberturas: [
            <Icon
              name={definirNomeIcone(
                tryGetValueOrDefault([item.descricaoCobertura], ''),
              )}
            />,
          ],
        });
      }

      item.beneficiarios?.forEach(beneficiario => {
        const dados = dadosBeneficiarios.find(
          e => beneficiario.pessoaFisica?.pessoaFisicaId === e.identificador,
        );

        if (dados) {
          dados.coberturas?.push(
            <Icon
              name={definirNomeIcone(
                tryGetValueOrDefault([item.descricaoCobertura], ''),
              )}
            />,
          );
        }

        if (!dados) {
          dadosBeneficiarios.push({
            beneficiario: beneficiario.pessoaFisica.nome,
            identificador: beneficiario.pessoaFisica?.pessoaFisicaId,
            dataNascimento: formatarData(
              beneficiario.pessoaFisica.dataNascimento,
            ),
            grauParentesco: beneficiario.descricaoGrauParentesco,
            tipoCobertura: item.descricaoCobertura,
            coberturas: [
              <Icon
                name={definirNomeIcone(
                  tryGetValueOrDefault([item.descricaoCobertura], ''),
                )}
              />,
            ],
          });
        }
      });
    });

    return dadosBeneficiarios;
  }

  return (
    <Table
      data={tryGetValueOrDefault([beneficiariosArray()], [])}
      columns={definirColunasTabelaAlteração(print)}
      responsive
      noDataComponent="Não há dados para exibir."
      style={{
        marginBottom: !print ? 30 : 0,
        overflow: print ? 'hidden' : 'auto',
      }}
    />
  );
};

export default TabelaAlteracaoBeneficiario;
