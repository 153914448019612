// @ts-nocheck
import { IApiResponse, Mensagem } from 'main/services';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import {
  INotificationErrorResult,
  StateError,
} from 'main/types/HandleResponseApi/INotificationErrorConfig';
import { IUseHandleReponseApiConfig } from 'main/types/HandleResponseApi/IUseHandleReponseApiConfig';
import { compareArrays } from 'main/utils/array';
import { removeEmptySpace } from 'main/utils/string';
import { toastError } from './useToast';

const MENSAGEM_ERRO_GI = 'Ocorreu um erro no serviço de integração';
const REGEX_COD_666_GI = /<erro>Cod:666/;

/**
 * * @param IUseHandleReponseApiConfig
 * * @param author - Matheus Santana
 * * @description 2023-01-30 - hook responsável por tratar o response das APIs
 * * @description Antes de alterar, comunicar o autor
 * * @returns  return { handleResponse: {sucessoGI, sucessoBFF };
 * */

export function useHandleReponse({
  throwToastErrorGI = true,
  throwToastErrorBFF = true,
  defaultMessageError = true,
  notifierError = toastError,
}: IUseHandleReponseApiConfig) {
  const getMensagemErroBff = (mensagens?: Mensagem[] | undefined) =>
    mensagens && mensagens?.length > 0 ? mensagens[0].descricao : undefined;

  function extractResponse<T>(data: IApiResponse<T>): IHandleReponseResult<T> {
    const {
      sucesso: sucessoApiGateway,
      dados: entidadeApiGateway,
      mensagem,
    } = data;
    const {
      entidade: entidadeBff,
      sucesso: sucessoBff,
      mensagens,
    } = entidadeApiGateway;

    const contemCodigo666 = REGEX_COD_666_GI.test(removeEmptySpace(mensagem));

    const consultaSucessoApiGateway =
      !!sucessoApiGateway && !!entidadeApiGateway;

    return {
      sucessoGI: !contemCodigo666 || consultaSucessoApiGateway,
      sucessoBFF: !!sucessoBff,
      entidade: entidadeBff,
      mensagens,
    };
  }

  function notifyError<T>(
    data: IHandleReponseResult<T>,
  ): INotificationErrorResult {
    const { sucessoGI, sucessoBFF, mensagens } = data;
    const statesError = [
      {
        key: StateError.ERRO_GI,
        conditions: [false, false, true],
        throwNotification: () => notifierError(MENSAGEM_ERRO_GI),
        enable: throwToastErrorGI,
      },
      {
        key: StateError.ERRO_BFF,
        conditions: [true, false, true],
        throwNotification: () => notifierError(),
        enable: throwToastErrorBFF,
      },
      {
        key: StateError.ERRO_BFF_COM_MSG_ERRO_NEGOCIO,
        conditions: [true, false, false],
        throwNotification: () => notifierError(getMensagemErroBff(mensagens)),
        enable: throwToastErrorBFF,
      },
    ];

    const error = statesError.find(x =>
      compareArrays<boolean>(x.conditions, [
        sucessoGI,
        sucessoBFF,
        defaultMessageError,
      ]),
    );

    return {
      hasError: !!error,
      errorResult: error,
    };
  }

  function handleResponse<T>(data: IApiResponse<T>) {
    const result = extractResponse(data);

    const { hasError, errorResult } = notifyError(result);

    if (hasError && errorResult?.enable) errorResult.throwNotification();

    return result;
  }

  return {
    handleResponse,
  };
}
