// @ts-nocheck
import React, { useMemo, useState } from 'react';
import { IProduto } from 'main/features/prospeccao/types/Produto';
import useFieldLink from 'main/hooks/useFieldLink';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import ValidateResult from 'main/features/Validation/types/ValidateResult';
import { IDetalhesProspeccao } from 'main/features/prospeccao/types/IDetalhesProspeccao';
import { IProspeccao } from '../types/IProspeccao';
import { ModalProps } from '../features/questionario/types';

interface IProspeccaoContextProviderProps {
  getMedicoRouteDetails: (prospeccao: IProspeccao) => void;
  getAnalistaProdutoRouteDetails: (prospeccao: IProspeccao) => void;
  getOperadorRouteDetails: (prospeccao: IProspeccao) => void;
  getDefaultRouteDetails: (prospeccao: IProspeccao) => void;
  getRouteCadastro: () => void;
  getRouteListaPreAnalise: () => string;
  children?: React.ReactNode;
}

export interface IProspeccaoContext extends IProspeccaoContextProviderProps {
  produtoEscolhido: IProduto;
  perfil: string;
  formProspeccao: {
    isLastStep: boolean;
    [index: string]: string | number | boolean;
  };
  modal: ModalProps;
  bloqueioEnvio: boolean;
  arquivoDps: LinkedValue<FileList>;
  propeccao: IDetalhesProspeccao;
  setPropeccao: (propeccao: IDetalhesProspeccao) => void;
  handleSetProduto: (produto?: IProduto) => void;
  setPerfil: (perfil: string) => void;
  setLastStep: (value: boolean) => void;
  setField: (fieldId: string, value: unknown) => void;
  setModal: (params: ModalProps) => void;
  setBloqueioEnvio: (bloqueioEnvio: boolean) => void;
  validateDps: () => ValidateResult;
}

export const ProspeccaoContext = React.createContext<IProspeccaoContext>(
  {} as IProspeccaoContext,
);

const STORAGE_KEYS = {
  PRODUTO: 'prospeccao:produtoEscolhido',
};

const ProspeccaoContextProvider: React.FC<IProspeccaoContextProviderProps> = ({
  children,
  getAnalistaProdutoRouteDetails,
  getDefaultRouteDetails,
  getMedicoRouteDetails,
  getOperadorRouteDetails,
  getRouteCadastro,
  getRouteListaPreAnalise,
}) => {
  const [propeccao, setPropeccao] = useState<IDetalhesProspeccao>(
    {} as IDetalhesProspeccao,
  );
  const [arquivoDps, validateDps] = useFieldLink<FileList>({} as FileList);
  const [perfil, setPerfil] = useState<string>('');
  const [produtoEscolhido, setProdutoEscolhido] = useState<IProduto>(() =>
    JSON.parse(window.localStorage.getItem(STORAGE_KEYS.PRODUTO) ?? '{}'),
  );
  const [formProspeccao, setFormProspeccao] = useState({
    isLastStep: false,
  });

  const [modal, setModal] = useState<ModalProps>({
    open: false,
    mensagem: '',
  });

  const [bloqueioEnvio, setBloqueioEnvio] = useState<boolean>(false);

  const setLastStep = (value: boolean) =>
    setFormProspeccao(prevState => ({ ...prevState, isLastStep: value }));

  const setField = (fieldId: string, value: unknown) =>
    setFormProspeccao(prevState => ({
      ...prevState,
      [fieldId]: value,
    }));

  const setProdutoEscolhidoInLocalStorage = (produto: IProduto) => {
    window.localStorage.setItem(STORAGE_KEYS.PRODUTO, JSON.stringify(produto));
  };

  const handleSetProduto = (produto?: IProduto) => {
    if (produto) {
      setProdutoEscolhidoInLocalStorage(produto);
      setProdutoEscolhido(produto);
    }
  };

  const values = useMemo(
    () => ({
      handleSetProduto,
      setPerfil,
      setLastStep,
      setField,
      setModal,
      setBloqueioEnvio,
      validateDps,
      setPropeccao,
      getAnalistaProdutoRouteDetails,
      getDefaultRouteDetails,
      getMedicoRouteDetails,
      getOperadorRouteDetails,
      getRouteCadastro,
      getRouteListaPreAnalise,
      arquivoDps,
      perfil,
      produtoEscolhido,
      formProspeccao,
      propeccao,
      modal,
      bloqueioEnvio,
    }),
    [
      produtoEscolhido,
      perfil,
      formProspeccao,
      arquivoDps,
      propeccao,
      modal,
      bloqueioEnvio,
    ],
  );

  return (
    <ProspeccaoContext.Provider value={values}>
      {children}
    </ProspeccaoContext.Provider>
  );
};

export default ProspeccaoContextProvider;
