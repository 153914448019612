// @ts-nocheck
export const TOOLTIP_NOME_SOCIAL =
  'O nome exibido neste campo reflete a preferência de como o(a) cliente quer que o(a) chamemos: pelo nome civil, conforme seu documento ou pelo nome social, conforme sua livre escolha.';

export const TIPO_OPERACAO = {
  ALTERACAO: 'ALTERACAO',
  INCLUSAO: 'INCLUSAO',
  EXCLUSAO: 'EXCLUSAO',
};

export const USAR_NOME_SOCIAL = {
  SIM: 'S',
  NAO: 'N',
};

export const MSG_SERVICO = {
  MSG_INCLUSAO: 'Nome Social incluído com sucesso.',
  MSG_EXCLUSAO: 'Nome Social removido com sucesso.',
  MSG_ERRO_EXCLUSAO: 'Não foi possível remover o Nome Social',
  MSG_ERRO_INCLUSAO: ' Não foi possível incluir o Nome Social',
  MSG_ERRO_ALTERACAO: ' Não foi possível atualizar Nome Social',
  MSG_ATUALIZACAO: 'Nome Social atualizado com sucesso.',
};
