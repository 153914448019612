// @ts-nocheck
import { Button, Card, Divider, Grid, Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon/Icon';
import { Display } from 'main/features/Auth/components';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import React from 'react';
import * as S from 'sinistro/features/statusSinistro/pages/styles';
import { ResultadoSinistroProdutosProps } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';

const ResultadoSinistroProdutos: React.FC<ResultadoSinistroProdutosProps> = ({
  produtos,
  produtoSelecionado,
  setProdutoSelecionado,
}) => {
  return (
    <Grid>
      <Grid.Item xs={1}>
        <S.TextResultado variant="body01-sm" color="primary-dark">
          Produtos:
        </S.TextResultado>
      </Grid.Item>
      {produtos.map(produto => {
        const selecionado: boolean = checkIfAllItemsAreTrue([
          produtoSelecionado?.tipoProduto === produto.tipoProduto,
          produtoSelecionado?.certificado === produto.codigoCertificado,
          produtoSelecionado?.numeroAvisoSinistro ===
            produto.numeroAvisoSinistro,
        ]);
        return (
          <Grid.Item
            key={produto.codigoCertificado}
            xs={1}
            md={1 / 3}
            xl={1 / 4}
          >
            <Card>
              <S.ContainerCertificados>
                <Card.Content padding={[1, 2, 0]}>
                  <Display alignItems="center">
                    <S.CertificadosText>
                      {produto.descricaoProduto}
                    </S.CertificadosText>
                  </Display>
                </Card.Content>
                <Divider />
                <Card.Content padding={[1, 2, 0]}>
                  <Display alignItems="center">
                    <S.IconContainer>
                      <Icon name="documentPaper" />
                    </S.IconContainer>
                    <span>
                      <Text variant="caption-02">
                        <strong>Certificado</strong>
                      </Text>
                      <Text variant="body03-md">
                        {produto.codigoCertificado}
                      </Text>
                    </span>
                  </Display>
                  <Display alignItems="center">
                    <S.IconContainer>
                      <Icon name="user" />
                    </S.IconContainer>
                    <span>
                      <Text variant="caption-02">
                        <strong>Sinistro</strong>
                      </Text>
                      <Text variant="body03-md">
                        {produto.numeroAvisoSinistro}
                      </Text>
                    </span>
                  </Display>

                  <Display alignItems="center">
                    <S.IconContainer>
                      <Icon name="calendar" />
                    </S.IconContainer>
                    <span>
                      <Text variant="caption-02">
                        <strong>Data Sinistro</strong>
                      </Text>
                      <Text variant="body03-md">{produto.dataSinistro}</Text>
                    </span>
                  </Display>

                  <Display alignItems="center">
                    <S.IconContainer>
                      <Icon name="documentBack" />
                    </S.IconContainer>
                    <span>
                      <Text variant="caption-02">
                        <strong>Status</strong>
                      </Text>
                      <Text variant="body03-md">{produto.status}</Text>
                    </span>
                  </Display>
                </Card.Content>
                <Card.Content padding={[1, 4, 0]}>
                  <Display justify="space-between">
                    <Button
                      onClick={() =>
                        setProdutoSelecionado({
                          certificado: produto.codigoCertificado,
                          tipoProduto: produto.tipoProduto,
                          numeroAvisoSinistro: tryGetValueOrDefault(
                            [produto.numeroAvisoSinistro],
                            '',
                          ),
                        })
                      }
                      variant={selecionado ? 'secondary' : 'outlined'}
                      fullWidth
                      small
                    >
                      Detalhes do Sinistro
                    </Button>
                  </Display>
                </Card.Content>
              </S.ContainerCertificados>
            </Card>
          </Grid.Item>
        );
      })}
    </Grid>
  );
};

export default ResultadoSinistroProdutos;
