// @ts-nocheck
import styled from 'styled-components/macro';

export const Text = styled.div({
  maxWidth: '90%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  overflow: 'hidden',
});
