// @ts-nocheck
export const TITULO_IRPF = 'Informações Sobre IRPF no Seguro de Vida';
export const PARA_FINS = {
  INICIO:
    'Para fins de preenchimento e informações em declaração de IRPF, o pagamento do prêmio de seguro de vida (parcela de seguro) e as indenizações recebidas da Seguradora por',
  MORTE: 'morte',
  OU: 'ou',
  INVALIDEZ: 'invalidez',
  PERMANENTE: 'permanente',
  NAO_PRECISAM: 'não precisam',
  FIM: 'ser declaradas à Receita Federal, exceto se você já for obrigado a fazê-lo pelas normas e limites determinados pela Legislação de Imposto de Renda.',
};
export const PARA_AQUELES = {
  INICIO:
    'Para aqueles que se enquadram dentro dos limites e obrigatoriedade previstas pelas normas da Receita Federal para fins de Declaração de Imposto de Renda Pessoa Física - IRPF, os desembolsos feitos para pagamento das parcelas de seguros de vida deverão ser informados no campo',
  DESPESAS: '“despesas não tributáveis”',
  MEIO: 'e para os valores recebidos decorrentes de indenização de seguro de vida por',
  MORTE: 'morte',
  INVALIDEZ: 'invalidez',
  FIM: 'no campo “rendimentos isentos ou não tributáveis”. Para receber mais e verificar se você precisa cumprir a obrigatoriedade definida pela legislação do IRPF, acesse o site da Receita',
  SITE_DA_RECEITA: 'www.receita.fazenda.gov.br',
  SITE_DA_RECEITA_LINK: 'https://www.gov.br/receitafederal/pt-br',
};
