// @ts-nocheck
import { AxiosRequestConfig } from 'axios';
import { api } from 'main/services/api';
import { obterEndpointListarPortabilidadeEntrada } from 'portabilidade/config/endpoints';
import { obterEndpointCertificadosPrevidencia } from 'previdencia/config/endpoints';
import {
  obterEndpointAlterarDadosBancariosPVR,
  obterEndpointListaDadosDevolucaoPVR,
} from 'reter/config/endpoints';
import { obterTokenOperador } from 'previdencia/services/matrizAcesso.api';
import { obterEndpointStatusSinistroPrevidencia } from 'sinistro/config/endpoints';

const URLS_TOKEN_OPERADOR = [
  obterEndpointCertificadosPrevidencia(),
  obterEndpointListarPortabilidadeEntrada(),
  obterEndpointListaDadosDevolucaoPVR(),
  obterEndpointAlterarDadosBancariosPVR(),
  obterEndpointStatusSinistroPrevidencia(),
];

const mapInterceptor = async (request: AxiosRequestConfig) => {
  const match = URLS_TOKEN_OPERADOR.some(url =>
    url.includes(request.url ?? ''),
  );

  if (match) {
    const token = await obterTokenOperador();
    request.data = {
      ...request.data,
      xToken: token,
    };
  }

  return request;
};

export const registerTokenOperadorInteceptor = () =>
  api.interceptors.request.use(request => mapInterceptor(request));
