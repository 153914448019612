// @ts-nocheck
import { useContext, useEffect, useState } from 'react';

import { AppContext } from 'main/contexts/AppContext';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import {
  IObterContatosDefaultPrevidencia,
  useObterContatosDefaultPrevidencia,
} from 'main/hooks/useObterContatosDefaultPrevidencia';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import { IMessageEvent } from 'main/types/IMessageEvent';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import { useTransfenciaFundos } from 'previdencia/hooks/Transferencia/useTransferenciaFundosPrevidencia';
import { TIPO_ASSINATURA } from 'previdencia/constants/constants';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { getBlobUrl } from 'main/utils/blob';
import { useConfirmarTransferencia } from 'previdencia/hooks/Transferencia/useConfirmarTransferencia';
import { useLocation } from 'react-router';

const useAssinaturaTransferencia = () => {
  const { navigateTo } = usePrevNavigation();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const { featureData, setFeatureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();
  const [openModalAviso, setModalAviso] = useState<boolean>(false);
  const [openModalDocusign, setOpenModalDocusign] = useState<boolean>(false);
  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] =
    useState<boolean>(false);
  const [sucessoAssinatura, setSucessoAssinatura] = useState(false);

  const { subscribe, unsubscribe } = useMessageEvent();
  const { data: responseObterContatosDefault } =
    useObterContatosDefaultPrevidencia();

  const location =
    useLocation<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IParamsLocationData>();
  const { data: locationHistoricoSolicitacoes } = tryGetValueOrDefault(
    [location.state],
    {} as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IParamsLocationData,
  );
  const numeroRequisicao = tryGetValueOrDefault(
    [
      locationHistoricoSolicitacoes?.idRequisicao,
      featureData?.numTransferencia,
    ],
    '',
  );
  const { loading: loadingConfirmar, response: responseConfirmar } =
    useConfirmarTransferencia(numeroRequisicao);

  const {
    nome,
    emailDefault,
    numerosTelefone,
    numeroTelefoneSemDDD,
    numeroDDD,
  } = tryGetValueOrDefault<IObterContatosDefaultPrevidencia>(
    [responseObterContatosDefault],
    {} as IObterContatosDefaultPrevidencia,
  );
  const certificadoDestino =
    featureData?.certificadoDestinoSelecionado?.codConta;
  const transferenciaConfirmada =
    !!responseConfirmar?.entidade?.sucessoOperacao;
  const dadosCliente = {
    email: emailDefault,
    cpfCnpj,
    mobileNumber: numerosTelefone,
  };

  const obterPayloadAssinatura = () => {
    return {
      nome,
      codigoTelefone: numeroDDD,
      telefone: numeroTelefoneSemDDD,
      email: emailDefault,
      tipoEnvio: 'SMS',
      tipoAssinatura: TIPO_ASSINATURA.ENTRE_CERTIFICADOS,
    };
  };

  const handleCloseModalAviso = () => {
    setModalAviso(false);
  };

  const handleAbirModalAviso = () => {
    setModalAviso(true);
  };

  const { response, loading, fetchData } = useTransfenciaFundos(
    obterPayloadAssinatura(),
    numeroRequisicao,
  );
  const urlAssinatura = response?.urlAssinatura;

  const arquivoBase64 = response?.arquivoBase64;

  const handleTipoAssinatura = async (): Promise<void> => {
    fetchData({ tipoEnvio: 'SMS', assinaturaManual: false });
  };
  const handleAssinaturaManual = () => {
    navigateTo(
      `${CONSTS_TEXT.URL_TRANSFERENCIA.BASE}${CONSTS_TEXT.URL_TRANSFERENCIA.ASSINATURA_MANUAL}`,
    );
  };
  const handleRedirectComprovante = () => {
    navigateTo(
      `${CONSTS_TEXT.URL_TRANSFERENCIA.BASE}${CONSTS_TEXT.URL_TRANSFERENCIA.COMPROVANTE}`,
    );
  };

  const reiniciarTransferencia = () => {
    navigateTo(`${CONSTS_TEXT.URL_TRANSFERENCIA.BASE}`);
  };

  const downloadArquivo = async () => {
    fetchData({ assinaturaManual: true });
  };

  const handleCloseModal = (): void => {
    setOpenModalDocusign(false);
    if (sucessoAssinatura) {
      handleRedirectComprovante();
    }
  };

  const salvarEtapasContexto = (): void => {
    setFeatureData({
      ...featureData,
      step: CONSTS_TEXT.NUM_ETAPAS.EFETUAR_ASSINATURA,
    });
  };

  useEffect(salvarEtapasContexto, []);

  useEffect(() => {
    if (arquivoBase64) {
      getBlobUrl(arquivoBase64);
    }
  }, [arquivoBase64]);

  useEffect(() => {
    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;
      if (eventSuccess) setSucessoAssinatura(true);
      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setModalAviso(false);
        setExibirBotaoFecharModal(true);
      }
    });
    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(false));
    };
  }, []);

  useEffect(() => {
    if (urlAssinatura) {
      setModalAviso(false);
      setOpenModalDocusign(true);
    }
  }, [response]);

  return {
    loading,
    arquivoBase64,
    numCertificado,
    certificadoDestino,
    openModalAviso,
    dadosCliente,
    urlAssinatura,
    openModalDocusign,
    exibirBotaoFecharModal,
    emailDefault,
    numeroTelefoneSemDDD,
    numeroDDD,
    loadingConfirmar,
    transferenciaConfirmada,
    reiniciarTransferencia,
    downloadArquivo,
    handleTipoAssinatura,
    handleAssinaturaManual,
    handleCloseModalAviso,
    handleCloseModal,
    handleAbirModalAviso,
    handleRedirectComprovante,
  };
};

export default useAssinaturaTransferencia;
