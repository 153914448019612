// @ts-nocheck
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { TipoRenda } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import { ResponseParametrosRegimeTributario } from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { EPodeAlterarRegimeTributario } from 'previdencia/features/SimulacaoRenda/types/enum';

export const retornarTipoRenda = (
  dadosTipoRenda: TipoRenda[],
  cod: string | undefined,
): TipoRenda | undefined => {
  return dadosTipoRenda.find(e => e.codigo === cod) as TipoRenda;
};

export const verificarSePodeAlterarRegimeTributario = (
  responseRegimeTributario:
    | IHandleReponseResult<ResponseParametrosRegimeTributario>
    | undefined,
): boolean => {
  return checkIfAllItemsAreTrue([
    responseRegimeTributario?.entidade?.dados?.podeAlterarRegimeTributario !==
      undefined,
    responseRegimeTributario?.entidade?.dados?.podeAlterarRegimeTributario?.toLocaleUpperCase() !==
      EPodeAlterarRegimeTributario.NAO,
  ]);
};
