// @ts-nocheck
import styled from 'styled-components/macro';
import { Button } from '@cvp/design-system/react';

export const ButtonRecusarStyle = styled(Button)(({ theme }) => ({
  ':hover': {
    backgroundColor: theme.color.neutral['08'],
    color: theme.color.brandPrimary.medium,
  },
}));

export const ContainerButtons = styled.div(() => ({
  display: 'flex',
  paddingTop: '20px',
  gap: '40px',
  justifyContent: 'start',
}));

export const ContainerButtonsModal = styled.div(() => ({
  display: 'flex',
  paddingTop: '40px',
  justifyContent: 'space-around',
}));
