// @ts-nocheck
import { useState, useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { completarDadosConta } from 'main/utils/formatarNumeroContaCaixa';
import { PECOS } from 'previdencia/config/endpoints';
import * as REQUEST_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblRequest';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as PECO_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblUsePeco';
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';

const useFormaPagamento = (): PECO_TYPES.UseFormaPagamento => {
  const { cliente } = useContext(AppContext);

  const [formularioDadosBancarios, setFormularioDadosBancarios] =
    useState<VGBL_TYPES.FormCadastrarNovaContaInitialState | null>(null);

  const defaultPayload = {
    certificado: cliente?.numCertificado,
    codigoEmpresa: ENUM_TYPES.DefaultValue.CODIGO_EMPRESA,
  };

  const {
    response: listaDadosBancariosVgbl,
    loading: loadingListaDadosBancariosVgbl,
    fetchData: listarDadosBancariosVgbl,
  } = usePeco<
    REQUEST_TYPES.IDadosBancariosVgblPayload,
    RESPONSE_TYPES.IDadosBancariosVgblResponse
  >({
    api: { operationPath: PECOS.ListarDadosBancariosVgbl },
    autoFetch: true,
    payload: defaultPayload,
  });

  const { response: listaDiasVencimento, loading: loadingListaDiasVencimento } =
    usePeco<
      REQUEST_TYPES.IListaDiasVencimentoPayload,
      RESPONSE_TYPES.IListaDiasVencimentoResponse
    >({
      api: { operationPath: PECOS.ListarDiasUteisVgbl },
      autoFetch: true,
      payload: defaultPayload,
    });

  const listaDiasUteis = listaDiasVencimento?.entidade?.diasUteis;

  const {
    response: listaTiposOperacoesBancariasVgbl,
    loading: loadingListaTiposOperacoesBancariasVgbl,
  } = usePeco<
    REQUEST_TYPES.IListaTiposOperacoesBancariasVgblPayload,
    RESPONSE_TYPES.IDadosTiposOperacoesBancariasVgblResponse
  >({
    api: { operationPath: PECOS.ListarTiposOperacoesBancariasVgbl },
    autoFetch: true,
    payload: defaultPayload,
  });

  const listaTiposOperacoesBancarias =
    listaTiposOperacoesBancariasVgbl?.entidade?.dados;

  const {
    loading: loadingInserirDadosBancariosTransfVgbl,
    fetchData: inserirDadosBancarios,
  } = usePeco<
    REQUEST_TYPES.IInserirDadosBancariosTransfVgblPayload,
    RESPONSE_TYPES.IInserirDadosBancariosTransfVgblResponse
  >({
    api: { operationPath: PECOS.InserirDadosBancariosTransfVgbl },
    payload: {
      ...defaultPayload,
      agencia: completarDadosConta(formularioDadosBancarios?.agencia, 4),
      conta: completarDadosConta(formularioDadosBancarios?.conta, 12),
      diaVencimento: tryGetValueOrDefault(
        [formularioDadosBancarios?.dataDebito],
        0,
      ),
      digitoConta: tryGetValueOrDefault([formularioDadosBancarios?.digito], ''),
      tipoOperacao: tryGetValueOrDefault(
        [formularioDadosBancarios?.tipoOperacao],
        '',
      ),
    },
  });

  return {
    listaDadosBancariosVgbl,
    loadingListaDadosBancariosVgbl,
    listarDadosBancariosVgbl,
    listaDiasUteis,
    loadingListaDiasVencimento,
    listaTiposOperacoesBancarias,
    loadingListaTiposOperacoesBancariasVgbl,
    loadingInserirDadosBancariosTransfVgbl,
    inserirDadosBancarios,
    formularioDadosBancarios,
    setFormularioDadosBancarios,
  };
};

export default useFormaPagamento;
