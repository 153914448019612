// @ts-nocheck
import * as CONSTS from 'seguros/constants/DadosSegurado';
import { CommonTablePropsFactory } from 'seguros/types/DadosBeneficiarios';

export const commonTablePropsFactory = ({
  pagination,
  noHeader = true,
  responsive = true,
  striped = true,
  highlightOnHover = true,
  paginationPerPage = 10,
  noDataComponent = CONSTS.NAO_HA_DADOS_TABELA,
}: CommonTablePropsFactory) => ({
  noHeader,
  responsive,
  striped,
  highlightOnHover,
  pagination,
  paginationPerPage,
  noDataComponent,
  paginationComponentOptions: {
    rowsPerPageText: 'Items por página',
    rangeSeparatorText: 'de',
  },
});
