// @ts-nocheck
import masks from 'main/utils/masks';
import { removeEmptySpaceAndForceUpperCase } from 'main/utils/string';
import { IDetalhePortabilidadeEntrada } from '../types/IDetalhePortabilidadeEntrada';
import { IListaPortabilidadeEntrada } from '../types/IListaPortabilidadeEntrada';
import {
  IDadosPortabilidadeResposta,
  IResponsePortabilidadeEntrada,
} from '../types/IResponsePortabilidadeEntrada';

const PorcentagemConclusao: Record<string, string> = {
  SOLICITADA: '10',
  SOLICITACAO: '10',
  LIBERADA: '40',
  LIBERACAO: '40',
  AGUARDANDOAPURACAO: '50',
  APURACAO: '50',
  AGUARDANDOCONFIRMACAO: '60',
  CONFIRMACAO: '60',
  RECUSADA: '5',
  RECUSA: '5',
  DEVOLVIDA: '5',
  DEVOLUCAOPORTABILIDADE: '5',
  LIQUIDACAO: '100',
  LIQUIDADA: '100',
  EXCLUSAO: '5',
};

const converterPorcentagem = (status: string) => {
  const statusFormatado = removeEmptySpaceAndForceUpperCase(status)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return PorcentagemConclusao[statusFormatado];
};

export const converterRetornoParaListagem = (
  responsePortabilidadeEntrada?: IResponsePortabilidadeEntrada,
): IListaPortabilidadeEntrada[] => {
  if (
    responsePortabilidadeEntrada &&
    responsePortabilidadeEntrada?.dadosPortabilidadeResposta?.length > 0
  ) {
    return responsePortabilidadeEntrada?.dadosPortabilidadeResposta.map(
      item => {
        const [dadosCliente] = item.dadosCliente ?? [];
        const [dadosPortabilidade] = item.dadosPortabilidade ?? [];

        return {
          nomeCliente: dadosCliente?.nomeCliente,
          numeroCpf: masks.cpf.mask(dadosCliente?.cpf),
          agencia: dadosPortabilidade?.codigoAgenciaVenda,
          numeroPortabilidade: dadosPortabilidade?.numeroPortabilidade,
          indicador: dadosPortabilidade?.matriculaIndicadorPortabilidade,
          status: dadosPortabilidade?.status,
          valor: dadosPortabilidade?.valorPortabilidade,
          valorPortabilidadeFormatado: masks.currency.mask(
            dadosPortabilidade?.valorPortabilidade,
          ),
          statusConclusao: converterPorcentagem(dadosPortabilidade.status),
        };
      },
    );
  }

  return [] as IListaPortabilidadeEntrada[];
};

const formatarContaeDigito = (numeroConta: string, digitoConta: string) =>
  numeroConta && digitoConta ? `${numeroConta}${digitoConta}` : '';

export const converterParaDetalhes = (
  portabilidadeItem?: IDadosPortabilidadeResposta,
): IDetalhePortabilidadeEntrada | undefined => {
  if (
    portabilidadeItem &&
    portabilidadeItem?.dadosCliente?.length > 0 &&
    portabilidadeItem?.dadosPortabilidade?.length > 0
  ) {
    const [portabilidade] = portabilidadeItem?.dadosPortabilidade ?? [];
    const [cliente] = portabilidadeItem?.dadosCliente ?? [];
    const [dadosCedente] = portabilidade?.dadosCedente ?? [];
    const [dadosCessionaria] = portabilidade?.dadosCessionaria ?? [];
    const [dadosBanco] = portabilidade?.dadosBanco ?? [];

    return {
      numeroPortabilidade: portabilidade?.numeroPortabilidade,
      dataSolicitacao: portabilidade?.dataSolicitacao,
      statusPortabilidade: portabilidade?.status,
      nomeParticipante: cliente?.nomeCliente,
      cpfParticipante: masks.cpf.mask(cliente?.cpf),
      valorPortabilidade: masks.currency.mask(
        portabilidade?.valorPortabilidade,
      ),
      nomeSeguradoraCedente: dadosCedente.nomeCedente,
      cnpjFundoCedente: masks.cnpj.mask(dadosCedente?.cnpjFundoCedente),
      cnpjSeguradoraCedente: masks.cnpj.mask(dadosCedente?.cnpjCedente),
      nomePlanoCessioanario: dadosCessionaria?.nomeFundoCessionaria,
      cnpjFundoCessionario: masks.cnpj.mask(
        dadosCessionaria?.cnpjFundoCessionaria,
      ),
      regimeTributario: dadosCessionaria?.regimeTributario,
      tipoPlano: dadosCessionaria?.tipoPlano,
      numeroCertificado: cliente?.certificado,
      agencia: dadosBanco?.nomeBanco,
      banco: dadosBanco?.nomeBanco,
      contaComDigito: formatarContaeDigito(
        dadosBanco.numeroConta,
        dadosBanco.digitoConta,
      ),
      dataPedidoPortabilidadeSide: portabilidade?.dataPedidoPortabilidade,
    };
  }

  return undefined;
};
