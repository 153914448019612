// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import * as ValidarContaApi from 'main/features/ValidacaoContaCaixa/services/validarConta.api';
import { ResponseValidarConta } from 'main/features/ValidacaoContaCaixa/types/DadosContaBancaria';
import { IDadosBancariosContaCaixa } from 'main/features/ValidacaoContaCaixa/types/IDadosBancariosContaCaixa';

export const useValidarConta = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setResponse] = useState<ResponseValidarConta | undefined>();

  const obterValidacaoConta = async (
    dadosBancarios: IDadosBancariosContaCaixa,
  ): Promise<ResponseValidarConta | undefined> => {
    try {
      setLoading(true);
      const result = await ValidarContaApi.validarConta(dadosBancarios);

      setResponse(result);
      return result;
    } catch (requestError: any) {
      toastError(requestError);
    } finally {
      setLoading(false);
    }
    return undefined;
  };

  return {
    loading,
    response,
    obterValidacaoConta,
  };
};
