// @ts-nocheck
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Accordion, Card, Grid, Text, Button } from '@cvp/design-system/react';
import FabButton, { Action } from 'portabilidade/components/FabButton';
import { TextLabel } from 'main/styles/GlobalStyle';
import { TooltipWrapper } from 'main/components/Wrappers/TooltipWrapper';
import { useProspeccao } from 'main/features/prospeccao/hooks/useProspeccao';
import SkeletonLoading from 'main/components/SkeletonLoading';
import masks from 'main/utils/masks';
import Icon from 'main/components/Icon';
import { converterBase64 } from 'main/utils/converterBase64';
import RenderConditional from 'main/components/RenderConditional';
import { MAPPED_FIELDS } from '../constants/formProspeccao';
import { DownloadButton, AccordionItem } from './styles';
import HistoricoSituacaoProspeccao from './HistoricoSituacaoProspeccao';
import ModalUploadDps from './ModalUploadDps';

const DetalhesPreAnalise: React.FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const [openModalUpload, setOpenModalUpload] = useState<boolean>(false);
  const {
    state: { cpfCnpj },
  } = useLocation<{ cpfCnpj: string }>();
  const {
    obterDetalhesDaProspeccao,
    obterArquivoProspeccao,
    deveExibirBotaoReenvioDPS,
    prospeccao,
    loadingProspeccao,
    loadingDownload,
  } = useProspeccao();

  useEffect(() => {
    if (cpfCnpj && id) obterDetalhesDaProspeccao(id, cpfCnpj);
  }, [cpfCnpj, id]);

  if (loadingProspeccao) {
    return <SkeletonLoading lines={4} />;
  }

  const getMappedFieldValues = (field: string) => {
    return prospeccao?.respostasFormulario?.respostasFormulario?.find(
      x => x.Id === field,
    )?.Resposta;
  };

  const getDpsFileUrl = () => {
    if (
      prospeccao.respostasFormulario.arquivos &&
      prospeccao.respostasFormulario.arquivos?.length > 0
    ) {
      const [arquivo] = prospeccao.respostasFormulario.arquivos;
      return arquivo;
    }
    return null;
  };

  const baixarArquivoDps = async (idArquivo?: number) => {
    if (idArquivo) {
      const arquivo = await obterArquivoProspeccao(
        idArquivo,
        prospeccao.cpfCnpjCliente,
      );
      if (arquivo) {
        const blob = converterBase64(
          arquivo.conteudoArquivoEmBase64,
          arquivo.mimeType,
        );
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      }
    }
  };

  const parseCurrencyValue = (value?: string) => parseFloat(value ?? '0');

  return (
    <>
      <Card>
        <Card.Content>
          <Accordion open>
            <AccordionItem title="Dados da Pré-Análise">
              <Grid>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">CPF</TextLabel>
                    <Text variant="body02-sm" prospeccao-testid="cpfcnpj">
                      {masks.cpf.mask(prospeccao?.cpfCnpjCliente)}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Nome do cliente</TextLabel>
                    <Text variant="body02-sm" prospeccao-testid="nomeCliente">
                      {prospeccao?.nomeCliente}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Data de nascimento
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      prospeccao-testid="dataNascimento"
                    >
                      {getMappedFieldValues(MAPPED_FIELDS.dataNascimento)}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">E-mail</TextLabel>
                    <Text
                      variant="body02-sm"
                      prospeccao-testid="dataNascimento"
                    >
                      {prospeccao?.emailContato}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TooltipWrapper
                      left={45}
                      top={0}
                      tooltip="Valor contratado pelo segurado como limite máximo de indenização."
                    >
                      <TextLabel variant="body02-sm">
                        Importância segurada
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        proposta-testid="codigoAgenciaVenda"
                      >
                        {masks.currency.mask(
                          parseCurrencyValue(
                            getMappedFieldValues(
                              MAPPED_FIELDS.importanciaSegurada,
                            ),
                          ),
                        )}
                      </Text>
                    </TooltipWrapper>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TooltipWrapper
                      left={45}
                      top={0}
                      tooltip="Soma de todos Capitais Segurados contratados para um CPF, independente do produto."
                    >
                      <TextLabel variant="body02-sm">Acúmulo de IS</TextLabel>
                      <Text
                        variant="body02-sm"
                        proposta-testid="propostaInicioVigencia"
                      >
                        {masks.currency.mask(
                          parseCurrencyValue(
                            getMappedFieldValues(MAPPED_FIELDS.acumuloIs),
                          ).toFixed(),
                        )}
                      </Text>
                    </TooltipWrapper>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TooltipWrapper
                      left={45}
                      top={0}
                      tooltip="Soma entre o acúmulo de IS + o valor de IS da proposta atual."
                    >
                      <TextLabel variant="body02-sm">
                        Valor total de IS{' '}
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        proposta-testid="valorPremioLiquido"
                      >
                        {masks.currency.mask(
                          parseCurrencyValue(
                            getMappedFieldValues(MAPPED_FIELDS.valorProspeccao),
                          ),
                        )}
                      </Text>
                    </TooltipWrapper>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Prazo (em meses)</TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="valorPremioLiquido"
                    >
                      {getMappedFieldValues(MAPPED_FIELDS.prazo)}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Agência de venda</TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="valorImportanciaSegurada"
                    >
                      {prospeccao?.numeroAgenciaCadastro}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Celular</TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="descricaoPeriodicidadeCobranca"
                    >
                      {masks.phone.mask(
                        `${prospeccao?.numeroTelefoneCelularContato}`,
                      )}
                    </Text>
                  </div>
                </Grid.Item>

                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Telefone Comercial
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="descricaoPeriodicidadeCobranca"
                    >
                      {masks.phone.mask(
                        getMappedFieldValues(MAPPED_FIELDS.telefoneComercial),
                      )}
                    </Text>
                  </div>
                </Grid.Item>

                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Arquivo DPS</TextLabel>

                    <RenderConditional condition={!!getDpsFileUrl()}>
                      <DownloadButton
                        variant="text"
                        type="button"
                        onClick={() => baixarArquivoDps(getDpsFileUrl()?.Id)}
                      >
                        {loadingDownload && <Button variant="text" loading />}
                        {!loadingDownload && (
                          <>
                            Baixar &nbsp;
                            <Icon name="download" />
                          </>
                        )}
                      </DownloadButton>
                    </RenderConditional>
                    <RenderConditional
                      condition={!getDpsFileUrl()}
                      component={<div> - </div>}
                    />
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Status</TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="descricaoPeriodicidadeCobranca"
                    >
                      {
                        prospeccao?.situacoes.find(x => x.situacaoVigente)
                          ?.descricao
                      }
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 2}>
                  <div>
                    <TextLabel variant="body02-sm">Agendamento</TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="descricaoPeriodicidadeCobranca"
                    >
                      {getMappedFieldValues(MAPPED_FIELDS.agendamento)}
                    </Text>
                  </div>
                </Grid.Item>
              </Grid>
            </AccordionItem>
            <AccordionItem title="Histórico da Pré-Análise">
              <HistoricoSituacaoProspeccao dadosProspeccao={prospeccao} />
            </AccordionItem>
          </Accordion>
        </Card.Content>
      </Card>
      <RenderConditional condition={deveExibirBotaoReenvioDPS(prospeccao)}>
        <FabButton iconName="floatMenu">
          <Action
            text="Upload de DPS"
            onClick={() => setOpenModalUpload(!openModalUpload)}
          >
            <Icon name="uploadArrowIcon" />
          </Action>
        </FabButton>
      </RenderConditional>
      <RenderConditional condition={openModalUpload}>
        <ModalUploadDps
          open={openModalUpload}
          prospeccao={prospeccao}
          handleClose={() => setOpenModalUpload(!openModalUpload)}
        />
      </RenderConditional>
      {children}
    </>
  );
};

export default DetalhesPreAnalise;
