// @ts-nocheck
import { IResponseConsultarContratosSF } from 'main/types/IResponseConsultarContratosSF';
import { usePeco } from './usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useState } from 'react';
import { TIPO_SITUACAO } from 'main/constants/consultarContratosSF';

export const useConsultarContratosSF = () => {
  const [nomeSocial, setNomeSocial] = useState('');

  const { loading: loadingNomeSocial, fetchData } = usePeco<
    unknown,
    IResponseConsultarContratosSF[]
  >({
    api: {
      operationPath: 'PECO_RecuperarContratos_Sf',
    },
    handleResponse: {
      throwToastErrorGI: false,
      throwToastErrorBFF: false,
    },
  });

  const obterContratosAtivos = async (cpf: string) => {
    const dados = await fetchData({
      codCpfCnpj: cpf,
      situacao: TIPO_SITUACAO.ATIVO,
    });
    if (dados) {
      const [resultado] = tryGetValueOrDefault([dados.entidade], []);
      if (resultado) setNomeSocial(resultado.nomeSocial);
    }
  };

  return {
    loadingNomeSocial,
    nomeSocial,
    obterContratosAtivos,
    setNomeSocial,
  };
};
