// @ts-nocheck
import { Button, Skeleton as SkeletonDS } from '@cvp/design-system/react';
import styled from 'styled-components';

export const Skeleton = styled(SkeletonDS)`
  margin: 0;
`;
export const SkeletonMini = styled(Skeleton)`
  width: 50%;
`;
export const ButtonAnoConsulta = styled(Button)({
  marginRight: 15,
  marginBottom: 25,
});
