// @ts-nocheck
import { IMenuItem } from 'main/components/Menu/Sidebar/types';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';

export const menuAdministracao: IMenuItem = {
  label: 'Gestão de FOPs',
  alt: 'Gestão de FOPs',
  path: '/administracao',
  icon: 'settings',
  roles: [USER_PROFILES.ANALISTA_TI, USER_PROFILES.ANALISTA_POS_VENDA],
  subItems: [
    {
      label: 'Gestão de FOPs',
      path: '/administracao/gestao-fop',
      roles: [USER_PROFILES.ANALISTA_TI, USER_PROFILES.ANALISTA_POS_VENDA],
    },
  ],
};
