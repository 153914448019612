// @ts-nocheck
import {
  Accordion,
  Button,
  Card,
  Display,
  Grid,
  Text,
} from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import SemDadosRetornados from 'previdencia/features/AlteracaoBeneficiarios/components/SemDadosRetornados';
import TabelaAlteracaoBeneficiario from 'previdencia/features/AlteracaoBeneficiarios/components/TabelaAlteracaoBeneficiario';
import TextoAlteracaoBeneficiario from 'previdencia/features/AlteracaoBeneficiarios/components/TextoAlteracaoBeneficiario';
import InformeReserva from 'previdencia/features/AlteracaoBeneficiarios/components/InformeReserva';
import AreaReserva from 'previdencia/features/AlteracaoBeneficiarios/components/AreaReserva';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import TextoInformativoAssinatura from 'previdencia/features/AlteracaoBeneficiarios/components/TextoInformativo/TextoInformativoAssinatura';

import ImprimirComprovante from 'previdencia/features/AlteracaoBeneficiarios/components/ImprimirComprovante';
import TextoInformativo from 'previdencia/features/AlteracaoBeneficiarios/components/TextoInformativo';
import {
  Beneficios,
  ResponseCoberturas,
} from 'previdencia/types/Beneficiarios';
import { ResponsePermissaoAssinaturaBeneficiarios } from 'previdencia/features/AlteracaoBeneficiarios/types/PermissaoAssinaturaBeneficiarios';
import { mockID } from 'previdencia/features/AlteracaoBeneficiarios/types/enum';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import { Assinante } from 'previdencia/features/AlteracaoBeneficiarios/types/Beneficiarios';

type BeneficiarioCardProps = {
  imprimirComprovante: boolean;
  benefitsAndBeneficiaries: ResponseCoberturas | undefined;
  dataBeneficiarios: Beneficios[] | undefined;
  idRequisicao: string;
  permissaoAssinaturaDigital:
    | ResponsePermissaoAssinaturaBeneficiarios
    | undefined;
  loadingModificacaoBeneficiarios: boolean;
  loadingAssinatura: boolean;
  modificarBeneficiarios: () => void;
  hasDigitalSignaturePermission: boolean;
};

export const BeneficiarioCard = ({
  imprimirComprovante,
  benefitsAndBeneficiaries,
  dataBeneficiarios,
  permissaoAssinaturaDigital,
  loadingAssinatura,
  loadingModificacaoBeneficiarios,
  modificarBeneficiarios,
  hasDigitalSignaturePermission,
  idRequisicao,
}: BeneficiarioCardProps) => {
  const { goDadosPlano } = usePrevNavigation();

  const { data: reponseObterContatosDefault } =
    useObterContatosDefaultPrevidencia();

  const { emailDefault, numeroDDD, numeroTelefoneSemDDD } =
    reponseObterContatosDefault ?? {};

  const construirPayload = () => {
    const assinantes: Assinante[] = [];
    assinantes.push({
      emailAssinante: emailDefault,
      telefones: [
        {
          ddd: numeroDDD,
          telefoneAssinante: numeroTelefoneSemDDD,
        },
      ],
    });
    return assinantes;
  };
  return (
    <Card className="hide-print">
      <Card.Content padding={[4, 4, 4]}>
        <Grid>
          <Grid.Item xs={1}>
            <Text variant="headline-06" color="primary">
              Alteração de Beneficiários
            </Text>
          </Grid.Item>
          <RenderConditional condition={!imprimirComprovante}>
            <RenderConditional
              condition={benefitsAndBeneficiaries === undefined}
            >
              <Grid.Item xs={1}>
                <SemDadosRetornados />
              </Grid.Item>
            </RenderConditional>
            <RenderConditional
              condition={benefitsAndBeneficiaries !== undefined}
            >
              <Grid.Item xs={1}>
                <Text variant="body01-md" margin>
                  Veja como está a distribuição dos beneficiários entre as
                  coberturas encontradas:
                </Text>
                <TabelaAlteracaoBeneficiario data={benefitsAndBeneficiaries} />
              </Grid.Item>
              <Grid.Item xs={1}>
                <TextoAlteracaoBeneficiario />
              </Grid.Item>
              <Grid.Item xs={1}>
                <Accordion>
                  {dataBeneficiarios?.map(item => (
                    <Accordion.Item
                      key={item.empresaId}
                      title={
                        <InformeReserva
                          data={item}
                          saldoTotal={
                            benefitsAndBeneficiaries?.retorno.saldoTotal
                          }
                          count={tryGetValueOrDefault(
                            [item.beneficiarios?.length],
                            0,
                          )}
                        />
                      }
                    >
                      <AreaReserva
                        beneficiariosData={tryGetValueOrDefault(
                          [item?.beneficiarios],
                          [],
                        )}
                        planId={item.planoId}
                        benefitId={item.coberturaId}
                        permissaoAssinatura={!!permissaoAssinaturaDigital}
                        loadingAssinatura={loadingAssinatura}
                        loadingModificacaoBeneficiarios={
                          loadingModificacaoBeneficiarios
                        }
                        obterModificarBeneficiarios={modificarBeneficiarios}
                        construirPayload={construirPayload}
                      />
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Grid.Item>
              <Grid.Item xs={1}>
                <br />
                <RenderConditional condition={hasDigitalSignaturePermission}>
                  <TextoInformativoAssinatura />
                </RenderConditional>
                <RenderConditional condition={!hasDigitalSignaturePermission}>
                  <TextoInformativo />
                </RenderConditional>
                <Display>
                  <Button variant="outlined" onClick={goDadosPlano}>
                    Voltar
                  </Button>

                  <Button variant="secondary" onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </Display>
              </Grid.Item>
            </RenderConditional>
          </RenderConditional>
        </Grid>
        <RenderConditional condition={imprimirComprovante}>
          <ImprimirComprovante
            hasAssinatura={hasDigitalSignaturePermission}
            idRequisicao={tryGetValueOrDefault(
              [idRequisicao],
              mockID.ID_REQUISICAO,
            )}
          />
        </RenderConditional>
      </Card.Content>
    </Card>
  );
};
