// @ts-nocheck
import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'main/components/Route';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import { PRINCIPAL_USERS } from 'main/features/Auth/config/userProfiles';
import ListaRetencoes from 'reter/features/retencao/pages/ListaRetencoes/index';
import AlterarDadosBancarios from 'reter/features/retencao/pages/AlterarDadosBancarios/index';
import FaqRetencao from 'reter/features/retencao/pages/FaqRetencao/index';

const routes: IPrivateRoute[] = [
  {
    path: '/reter/recursos-pendentes-recebimento',
    exact: true,
    component: ListaRetencoes,
    key: 'lista-retencao',
    authenticated: true,
    breadcrumb: 'Recursos pendentes de devolução',
    requiredRoles: PRINCIPAL_USERS,
  },
  {
    path: '/reter/recursos-pendentes-recebimento/dados-bancario',
    exact: true,
    component: AlterarDadosBancarios,
    key: 'dados-bancario',
    authenticated: true,
    breadcrumb: 'Atualizar dados bancários',
    requiredRoles: PRINCIPAL_USERS,
  },
  {
    path: '/reter/recursos-pendentes-recebimento/faq-retencao',
    exact: true,
    component: FaqRetencao,
    key: 'faq-retencao',
    authenticated: true,
    breadcrumb: 'Faq',
    requiredRoles: PRINCIPAL_USERS,
  },
];

export const reterBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/reter': null,
  '/reter/recursos-pendentes-recebimento/:id': null,
};

const RotasReter = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);

export default RotasReter;
