// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Input from 'main/components/form/Input';
import RenderConditional from 'main/components/RenderConditional';
import { required } from 'main/features/Validation/validations';
import Select, { SelectItem } from 'main/components/form/Select';
import { Option } from 'previdencia/types/Forms';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import * as S from './styles';

interface ICancelamentoProps {
  motivoEstorno: Option[];
  motivoLink: LinkedValue<string>;
  outroMotivoLink: LinkedValue<string>;
}

const CancelamentoPrevidencia: React.FC<ICancelamentoProps> = ({
  motivoEstorno,
  motivoLink,
  outroMotivoLink,
}) => {
  const [isOutroMotivo, setIsOutroMotivo] = useState(false);

  useEffect(() => {
    setIsOutroMotivo(motivoLink.get().value === 'Outros');
  }, [motivoLink.get().value]);

  function isSelected(value: string) {
    return motivoLink.get().value === value;
  }

  return (
    <S.DetalhesPlano>
      <S.SelectRange>
        <S.WrapperSelect>
          <Select
            placeholder="Escolha a opção de cancelamento"
            validationRules={[required()]}
            link={motivoLink}
          >
            {motivoEstorno.map(item => (
              <SelectItem
                value={item.value}
                text={item.label}
                selected={isSelected(item.value)}
              />
            ))}
          </Select>
        </S.WrapperSelect>
        <RenderConditional condition={isOutroMotivo}>
          <Input
            type="text"
            link={outroMotivoLink}
            label="Por favor, nos informe o motivo de cancelamento"
            validationRules={[required()]}
            maxLength="100"
          />
        </RenderConditional>
      </S.SelectRange>
    </S.DetalhesPlano>
  );
};

export default CancelamentoPrevidencia;
