// @ts-nocheck
import masks from 'main/utils/masks';

export const percentageDecimal = {
  mask(value: number) {
    if (typeof value === 'string') {
      return masks.currencyInput.mask(value);
    }
    if (!Number.isNaN(value) && Number.isFinite(value)) {
      const finalValue = value
        ?.toFixed(2)
        .replace('.', ',')
        .replace(/\d(?=(\d{3})+,)/g, '$&.');
      return `${finalValue}`;
    }
    return '';
  },
  unmask(mask: string) {
    return mask?.replace(/\D/g, '');
  },
};

export const percentageDecimalInput = {
  mask(value: string | number) {
    let newValue = `${value}`;
    newValue = newValue.replace(',', '').split('.').join('');
    const valueParsed = parseFloat(newValue);
    return newValue === '' ? '' : percentageDecimal.mask(valueParsed / 100);
  },
  unmask(mask: string) {
    return mask?.replace(/\D/g, '');
  },
};
