// @ts-nocheck
import styled from 'styled-components/macro';

export const ContainerInputValorMonetario = styled.div(({ theme }) => ({
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },

  h6: {
    color: theme.color.neutral['03'],
    fontSize: '11px',
    width: '100%',
  },

  'h6[color=error]': {
    color: theme.color.feedback.error,
  },
}));
