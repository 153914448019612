// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Button, Display, Grid, Text } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import AdicionarBeneficiario from 'previdencia/features/AlteracaoBeneficiarios/components/AdicionarBeneficiario';
import ModalAlert from 'previdencia/features/AlteracaoBeneficiarios/components/ModalAlert';
import TabelaReserva from 'previdencia/features/AlteracaoBeneficiarios/components/TabelaReserva';
import { BeneficiorioData } from 'previdencia/features/AlteracaoBeneficiarios/types/Beneficiarios';
import { ModificarBeneficiario } from 'previdencia/features/AlteracaoBeneficiarios/types/ModificarBeneficiario';
import { AreaReservaProps } from 'previdencia/features/AlteracaoBeneficiarios/types/AlteracaoBeneficiariosProps';
import { TipoParentesco } from 'previdencia/features/AlteracaoBeneficiarios/types/TipoParentesco';
import {
  retornarBeneficiariosAdicionados,
  retornarBeneficiariosAlterados,
  somaPercentualDistribuicao,
} from 'previdencia/features/AlteracaoBeneficiarios/utils/operacoesBeneficiarios';
import * as S from 'previdencia/features/AlteracaoBeneficiarios/components/AreaReserva/styles';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import RenderConditional from 'main/components/RenderConditional';
import { TEXTO_ATENCAO } from 'previdencia/features/AlteracaoBeneficiarios/constants/constants';

const AreaReserva: React.FC<AreaReservaProps> = ({
  beneficiariosData,
  benefitId,
  planId,
  permissaoAssinatura,
  loadingModificacaoBeneficiarios,
  loadingAssinatura,
  obterModificarBeneficiarios,
  construirPayload,
}) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const [beneficiarios, setBeneficiarios] = useState<BeneficiorioData[]>([
    ...beneficiariosData,
  ]);
  const [modificarBeneficiarios, setModificarBeneficiarios] =
    useState<ModificarBeneficiario>({} as ModificarBeneficiario);
  const [exibirForm, setExibirForm] = useState(false);
  const [alertModal, setAlertModal] = useState(false);

  function handleSubmit() {
    const somaPercentual = somaPercentualDistribuicao(beneficiarios);

    if (somaPercentual === 100) {
      obterModificarBeneficiarios(
        modificarBeneficiarios,
        permissaoAssinatura,
        construirPayload(),
      );
    } else {
      setAlertModal(true);
    }
  }

  useEffect(() => {
    if (beneficiarios) {
      setModificarBeneficiarios({
        AdicionarBeneficiarios: retornarBeneficiariosAdicionados(beneficiarios),
        AlterarBeneficiarios: retornarBeneficiariosAlterados(beneficiarios),
        IdBeneficio: benefitId,
        IdPlano: planId,
        Cpf: cpfCnpj,
        NumeroCertificado: numCertificado,
      });
    }
  }, [beneficiarios]);

  return (
    <Grid>
      <Grid.Item xs={1}>
        <TabelaReserva
          beneficiarios={beneficiarios}
          atualizaBeneficiarios={setBeneficiarios}
          TipoParentesco={tryGetValueOrDefault([TipoParentesco], [])}
        />
        <FeatureAuthorizer
          requiredRoles={[
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
          ]}
          requiredPermissions={[
            PREV_PERMISSIONS.BENEFICIARIOS_CADASTRADOS_EDITAR,
          ]}
        >
          <Button variant="secondary" onClick={() => setExibirForm(true)}>
            Adicionar beneficiário
          </Button>
        </FeatureAuthorizer>
        <br /> <br />
        <RenderConditional condition={exibirForm}>
          <AdicionarBeneficiario
            onHiddenForm={() => setExibirForm(false)}
            onChangeAddBeneficiario={data =>
              setBeneficiarios(old => [...old, data])
            }
            beneficiariosAtuais={beneficiarios}
          />
        </RenderConditional>
      </Grid.Item>
      <Grid.Item xs={1}>
        <Text variant="body02-sm" margin>
          {TEXTO_ATENCAO.MORTE_TITULAR}{' '}
          <a
            href="https://www.jusbrasil.com.br/busca?q=Art.+792+do+C%C3%B3digo+Civil"
            rel="noopener noreferrer"
            target="_blank"
          >
            {TEXTO_ATENCAO.ARTIGO_CODIGO_CIVIL}
          </a>
        </Text>
        <Text variant="body02-sm" margin>
          {TEXTO_ATENCAO.SALVAR_ALTERACOES}
        </Text>
      </Grid.Item>

      <S.WrapperButtonAction>
        <Display justify="center">
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
            loading={checkIfSomeItemsAreTrue([
              loadingModificacaoBeneficiarios,
              loadingAssinatura,
            ])}
          >
            Confirmar alterações
          </Button>
        </Display>
      </S.WrapperButtonAction>
      <ModalAlert openModal={alertModal} onClose={() => setAlertModal(false)} />
    </Grid>
  );
};

export default AreaReserva;
