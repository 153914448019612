// @ts-nocheck
import { useState, useEffect } from 'react';
import useAtualizarDataAposentadoria from 'previdencia/features/AlteracaoPrazoDiferimento/hooks/useAtualizarDataAposentadoria';
import {
  calcularAnoAposentadoria,
  calcularIdadeSaida,
  calcularAnoVigencia,
} from 'previdencia/features/AlteracaoPrazoDiferimento/utils/calcularAnoAposentadoria';
import useObterCertificadosPrevidencia from 'consultaCliente/features/listaCardProduto/hooks/useObterCertificadosPrevidencia';
import { IDadosBasicosCertificadoPrevidencia } from 'main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';
import { formatarData } from 'main/utils';

type UseValidarIdadeSaidaPlanoRetorno = {
  messageError: string;
  messageSuccess: string;
  isLoading: boolean;
  loadingatuAlizarDataAposentadoria: boolean;
  fundo: IDadosBasicosCertificadoPrevidencia | undefined;
  vigenciaSaida: string;
  diaMesVigenciaSaida: string;
  idadeSaida?: number;
  handleConfirmarAlteracaoAno: () => void;
  handleConfirmarAlteracaoVigencia: () => void;
  validateIdadeSaidaPlano: () => void;
  handleSetIdadeSaida: (value: string) => void;
  handleSetVigenciaSaida: (value: string) => void;
  atualizarValores: () => void;
};

const useValidarIdadeSaidaPlano = (
  cpf: string,
  numCertificado: string,
): UseValidarIdadeSaidaPlanoRetorno => {
  const {
    data: multiFundoPrevidencia,
    isLoading,
    refetch: atualizarValores,
  } = useObterCertificadosPrevidencia(cpf);
  const [diaMesVigenciaSaida, setDiaMesVigenciaSaida] = useState('');
  const [vigenciaSaida, setVigenciaSaida] = useState('');
  const [messageError, setMessageError] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');

  const [idadeSaida, setIdadeSaida] = useState<number>();

  const {
    atualizarDataAposentadoria,
    loading: loadingatuAlizarDataAposentadoria,
    mensagemSucesso,
  } = useAtualizarDataAposentadoria();

  const fundo = multiFundoPrevidencia?.find(
    item => item.certificadoNumero === numCertificado,
  );

  const handleSetVigenciaSaida = (value: string) => {
    setVigenciaSaida(value);
  };

  const handleSetIdadeSaida = (value: string) => {
    setIdadeSaida(Number(value));
  };

  const validateIdadeSaidaPlano = () => {
    if (
      calcularAnoAposentadoria(
        fundo?.pessoa.pessoaFisica.nascimento,
        fundo?.aposentadoria,
      ) >= Number(idadeSaida)
    ) {
      setMessageError('A nova idade deverá ser maior que a idade antiga');
      return;
    }

    atualizarDataAposentadoria(
      cpf,
      numCertificado,
      vigenciaSaida
        ? new Date(
            `${diaMesVigenciaSaida}${vigenciaSaida}`.replace(
              /(\d+[/])(\d+[/])/,
              '$2$1',
            ),
          ).toISOString()
        : '',
    );

    handleSetVigenciaSaida('');
    handleSetIdadeSaida('');
  };

  const handleConfirmarAlteracaoAno = () => {
    if (vigenciaSaida.length === 4) {
      setIdadeSaida(
        calcularIdadeSaida(
          fundo?.pessoa.pessoaFisica.nascimento,
          `${diaMesVigenciaSaida}${vigenciaSaida}`,
        ),
      );
      setMessageError('');
    }
  };

  const handleConfirmarAlteracaoVigencia = () => {
    if (idadeSaida) {
      const ano = calcularAnoVigencia(
        fundo?.aposentadoria,
        Number(idadeSaida) -
          calcularAnoAposentadoria(
            fundo?.pessoa.pessoaFisica.nascimento,
            fundo?.aposentadoria,
          ),
      );
      handleSetVigenciaSaida(ano);
      setMessageError('');
    }
  };

  useEffect(() => {
    setDiaMesVigenciaSaida(
      formatarData(fundo?.aposentadoria).substring(0, 6) || '',
    );
  }, [fundo]);

  useEffect(() => {
    setMessageSuccess('');
    setMessageError('');
  }, [idadeSaida]);

  useEffect(() => {
    if (mensagemSucesso !== '') {
      setMessageSuccess(mensagemSucesso);
    }
  }, [mensagemSucesso]);

  return {
    fundo,
    messageError,
    messageSuccess,
    isLoading,
    loadingatuAlizarDataAposentadoria,
    vigenciaSaida,
    diaMesVigenciaSaida,
    idadeSaida,
    handleConfirmarAlteracaoAno,
    handleConfirmarAlteracaoVigencia,
    validateIdadeSaidaPlano,
    handleSetIdadeSaida,
    handleSetVigenciaSaida,
    atualizarValores,
  };
};

export default useValidarIdadeSaidaPlano;
