// @ts-nocheck
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Grid, Card, Display, Text, Button } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import useFundoInvestimentoDestino from 'previdencia/features/TransferenciaInternaEmissaoConjugado/hooks/useFundoInvestimentoDestino';
import TabelaFundosInvestimentoDestino from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/TabelaFundosInvestimentoDestino';
import ModalContribuicaoRegular from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Modal/ModalContribuicaoRegular';

const FundoInvestimentoDestino: React.FC = () => {
  const [modalContribuicaoRegular, setModalContribuicaoRegular] =
    useState<boolean>(false);

  const [continuarTransfVgbl, setContinuarTransfVgbl] =
    useState<boolean>(false);

  const {
    definirContribuicaoRendaMensal,
    salvarListaOfertasVgblConjugado,
    atualizarListaFundosInvestimento,
    dadosOfertasVgblConjugado,
    saldoTotalFundosAtuais,
  } = useTransferenciaInternaContext();

  const tabelaFundos = dadosOfertasVgblConjugado?.produtos?.[0]?.fundos;

  const {
    loadingQuantidadeMaximaFundos,
    quantidadeMaximaFundos,
    responseListaOfertasVgblConjugado,
    loadingListaOfertasVgblConjugado,
  } = useFundoInvestimentoDestino();

  const prosseguirContribuicaoRegular = (): void => {
    const fundos = tabelaFundos?.filter(fundo =>
      checkIfAllItemsAreTrue([
        !!fundo.selecionado,
        !!fundo.percentualDistribuicao,
      ]),
    );

    if (fundos?.length) {
      setModalContribuicaoRegular(true);
    }
  };

  const handleModalContribuicaoRegular = (): void => {
    setModalContribuicaoRegular(!modalContribuicaoRegular);
  };

  const handleFundoSelecionadoParaContribuicaoRegular = (
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    const fundos = tabelaFundos?.map(fundo => ({
      ...fundo,
      indicadorContribuicaoFundo: fundo.codigoFundo === e.target.value,
    }));

    atualizarListaFundosInvestimento(fundos);
  };

  const salvarListaOfertasVgblConjugadoNoContexto = (): void => {
    if (responseListaOfertasVgblConjugado) {
      salvarListaOfertasVgblConjugado(
        responseListaOfertasVgblConjugado?.entidade,
      );
    }
  };

  useEffect(salvarListaOfertasVgblConjugadoNoContexto, [
    responseListaOfertasVgblConjugado,
  ]);

  return (
    <>
      <Display type="display-block">
        <Card>
          <Card.Content padding={[6, 8, 6, 8]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary">
                  Escolha o(s) fundo(s) de investimento de destino
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <RenderConditional condition={loadingQuantidadeMaximaFundos}>
                  <SkeletonLoading lines={2} blocks={1} />
                </RenderConditional>
                <RenderConditional condition={!loadingQuantidadeMaximaFundos}>
                  <Text variant="body02-md">
                    Selecione na tabela abaixo até{' '}
                    <strong>
                      {tryGetValueOrDefault([quantidadeMaximaFundos], '-')}{' '}
                      fundos.
                    </strong>
                  </Text>
                  <Text variant="body02-md">
                    Valor total da reserva:{' '}
                    <strong>
                      {tryGetMonetaryValueOrDefault(
                        [saldoTotalFundosAtuais],
                        '-',
                      )}
                    </strong>
                    .
                  </Text>
                </RenderConditional>
              </Grid.Item>
              <Grid.Item xs={1}>
                <RenderConditional condition={loadingListaOfertasVgblConjugado}>
                  <SkeletonLoading lines={8} blocks={1} />
                </RenderConditional>
                <RenderConditional
                  condition={!loadingListaOfertasVgblConjugado}
                >
                  <TabelaFundosInvestimentoDestino
                    qtdFundosInvestimentoPermitidos={quantidadeMaximaFundos}
                    setContinuarTransfVgbl={setContinuarTransfVgbl}
                  />
                </RenderConditional>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Display>
                  <Button
                    variant="outlined"
                    onClick={definirContribuicaoRendaMensal}
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={prosseguirContribuicaoRegular}
                    disabled={!continuarTransfVgbl}
                    loading={checkIfSomeItemsAreTrue([
                      loadingQuantidadeMaximaFundos,
                      loadingListaOfertasVgblConjugado,
                    ])}
                  >
                    Continuar
                  </Button>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>

      <ModalContribuicaoRegular
        toggleModal={modalContribuicaoRegular}
        handleModal={handleModalContribuicaoRegular}
        handleFundoSelecionadoParaContribuicaoRegular={
          handleFundoSelecionadoParaContribuicaoRegular
        }
      />
    </>
  );
};

export default FundoInvestimentoDestino;
