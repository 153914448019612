// @ts-nocheck
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';

export const initialStateCheckbox: VGBL_TYPES.CheckboxCoberturaIdeal = {
  pensao: false,
  peculio: false,
};

export const initialModalState: VGBL_TYPES.ModalPlanosTransferenciaInterna = {
  modalAlertaContatos: false,
  modalConsentimentoTransferencia: false,
};

export const initialFormStateFactory: VGBL_TYPES.FormCadastrarNovaContaInitialState =
  {
    codigoInstituicao: ENUM_TYPES.DefaultValue.CODIGO_BANCO,
    agencia: '',
    conta: '',
    digito: '',
    dataDebito: 0,
    tipoOperacao: '',
  };

export const initialStateCoberturasCuidadoGarantido: VGBL_TYPES.CoberturasCuidadoGarantido =
  {
    pensao: {
      valorContribuicao: 0,
      prazo: 0,
      calculoIndenizacao: 0,
    },
    peculio: {
      valorContribuicao: 0,
      prazo: 0,
      calculoIndenizacao: 0,
    },
  };

export const initialModalAquisicaoState: VGBL_TYPES.ModalAquisicaoVgblConjugado =
  {
    modalTipoDocusign: false,
    modalDocusign: false,
    modalInformeCliente: true,
  };
