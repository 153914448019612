// @ts-nocheck
import { useAuth } from 'main/features/Auth/hooks';
import { STATUS_PORTABILIDADE } from '../constants';
import { DetalhesPortabilidade } from '../types/DetalhesPortabilidade';

export const useValidarRegrasPortabilidade = (
  portabilidade?: DetalhesPortabilidade,
) => {
  const { user } = useAuth();
  const dadosPortabilidade = portabilidade;

  const obterUltimoStatusPortabilidade = () => {
    return dadosPortabilidade?.historicoSituacaoRetencao[
      (dadosPortabilidade?.historicoSituacaoRetencao.length ?? 0) - 1
    ];
  };

  const portabilidadeNaoAceitaPeloCliente = () => {
    const codigoSituacao =
      obterUltimoStatusPortabilidade()?.codigoSituacaoRetencao;
    return codigoSituacao === STATUS_PORTABILIDADE.RETENCAO_NAO_ACEITA;
  };

  const portabilidadeJaEncontraSeRetida = () => {
    const codigoSituacao =
      obterUltimoStatusPortabilidade()?.codigoSituacaoRetencao;
    return codigoSituacao === STATUS_PORTABILIDADE.CANCELAMENTO_CONFIRMADO;
  };

  const portabilidadeEstaConcluida = () => {
    const codigoSituacao =
      obterUltimoStatusPortabilidade()?.codigoSituacaoRetencao;
    return codigoSituacao === STATUS_PORTABILIDADE.NAO_RETIDA;
  };

  const usuarioResponsavelEstaEmOutraAgencia = (): boolean => {
    const agenciaUsuarioResponsavel =
      dadosPortabilidade?.portabilidade?.usuarioResponsavel?.numeroAgencia ?? 0;
    const agenciaPortabilidade =
      dadosPortabilidade?.portabilidade.numeroAgenciaOrigem;
    return (
      agenciaUsuarioResponsavel > 0 &&
      agenciaUsuarioResponsavel !== agenciaPortabilidade
    );
  };

  const usuarioLogadoEResponsavelPelaPortabilidade = (): boolean => {
    const responsavel = dadosPortabilidade?.portabilidade.usuarioResponsavel;
    if (!responsavel) return false;

    const usuarioLogadoEhResponsavel =
      responsavel?.nomeAcesso === user.nomeAcesso;
    const usuarioDeOutraAgencia = usuarioResponsavelEstaEmOutraAgencia();
    return usuarioLogadoEhResponsavel && !usuarioDeOutraAgencia;
  };

  const portabilidadeTemResponsavel = (): boolean => {
    const possuiUsuarioResponsavel =
      dadosPortabilidade?.portabilidade.usuarioResponsavel;
    return !!possuiUsuarioResponsavel;
  };

  const portabilidadeEstaVencidaOuRetida = (): boolean => {
    if (!portabilidade) {
      return false;
    }

    const dataDeHoje = new Date().getTime();
    const dataVencimentoPortabilidade = new Date(
      portabilidade.portabilidade.dataVencimento,
    ).getTime();

    return (
      dataVencimentoPortabilidade < dataDeHoje ||
      portabilidadeJaEncontraSeRetida()
    );
  };

  const deveExibirLiberarAtendimento = (): boolean => {
    const responsavel = dadosPortabilidade?.portabilidade.usuarioResponsavel;
    return (
      (responsavel?.nomeAcesso === user.nomeAcesso ||
        usuarioResponsavelEstaEmOutraAgencia()) &&
      !portabilidadeEstaVencidaOuRetida() &&
      !portabilidadeNaoAceitaPeloCliente() &&
      !portabilidadeEstaConcluida()
    );
  };

  const deveExibirBotaoAtender = (): boolean =>
    !portabilidadeTemResponsavel() &&
    !portabilidadeEstaVencidaOuRetida() &&
    !portabilidadeNaoAceitaPeloCliente() &&
    !portabilidadeEstaConcluida();

  const deveExibirAcoesRetencao = (): boolean => {
    return (
      usuarioLogadoEResponsavelPelaPortabilidade() &&
      portabilidadeTemResponsavel() &&
      !portabilidadeNaoAceitaPeloCliente() &&
      !portabilidadeEstaVencidaOuRetida() &&
      !portabilidadeEstaConcluida()
    );
  };

  const deveExibirAcoesEdicaoCadastro = (): boolean => {
    return (
      usuarioLogadoEResponsavelPelaPortabilidade() &&
      !portabilidadeEstaVencidaOuRetida() &&
      !portabilidadeEstaConcluida() &&
      !portabilidadeNaoAceitaPeloCliente()
    );
  };

  const deveExibirNaoRetido = (): boolean => {
    const codigoSituacao =
      obterUltimoStatusPortabilidade()?.codigoSituacaoRetencao;

    return (
      usuarioLogadoEResponsavelPelaPortabilidade() &&
      codigoSituacao !== STATUS_PORTABILIDADE.CANCELAMENTO_CONFIRMADO &&
      codigoSituacao !== STATUS_PORTABILIDADE.NAO_RETIDA &&
      !portabilidadeNaoAceitaPeloCliente()
    );
  };

  return {
    obterUltimoStatusPortabilidade,
    deveExibirLiberarAtendimento,
    deveExibirAcoesRetencao,
    deveExibirBotaoAtender,
    portabilidadeTemResponsavel,
    deveExibirAcoesEdicaoCadastro,
    deveExibirNaoRetido,
  };
};
