// @ts-nocheck
import React from 'react';
import { Button } from '@cvp/design-system/react';

export const ButtonCancelar: React.FC<{
  idRequisicao: string;
  onClose?: (idRequisicao: string) => void;
}> = ({ idRequisicao, onClose }) => (
  <Button
    variant="secondary"
    onClick={() => onClose && onClose(idRequisicao)}
    style={{ margin: '5px 0', boxShadow: 'none' }}
  >
    Cancelar
  </Button>
);
