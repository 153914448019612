// @ts-nocheck
import { api, IApiResponse } from 'main/services';
import { obterEndpointAlterarValorContribuicaoBeneficioProtecao } from 'previdencia/config/endpoints';
import { DadosResponseAlterarValorContribuicaoBeneficioProtecao } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoBeneficioProtecao';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';

type AlterarValorContribuicaoBeneficioProtecaoApiProps = {
  codigoCertificado: string;
  codigoPlano: number;
  codigoBeneficio: number;
  tipoContribuicao: string;
  valorContribuicao: number;
  canalOrigem: string | DefaultValue;
  matriculaEconomiario: string;
};

export const alterarValorContribuicaoBeneficioProtecao = async ({
  codigoCertificado,
  canalOrigem,
  codigoBeneficio,
  codigoPlano,
  tipoContribuicao,
  valorContribuicao,
  matriculaEconomiario,
}: AlterarValorContribuicaoBeneficioProtecaoApiProps): Promise<
  DadosResponseAlterarValorContribuicaoBeneficioProtecao | undefined
> => {
  const { data } = await api.post<
    IApiResponse<DadosResponseAlterarValorContribuicaoBeneficioProtecao>
  >(obterEndpointAlterarValorContribuicaoBeneficioProtecao(), {
    codigoEmpresa: DefaultValue.CODIGO_EMPRESA,
    codigoCertificado,
    canalOrigem,
    codigoBeneficio,
    codigoPlano,
    tipoContribuicao,
    valorContribuicao,
    matriculaEconomiario,
  });

  if (!data.sucesso || !data.dados.sucesso) throw new Error();
  return data.dados.entidade;
};
