// @ts-nocheck
export const handleKeepFocusIn = (event: FocusEvent) => {
  event.preventDefault();
  if (event.target) {
    const modal = document.getElementById('modal_alerta');
    modal?.focus();
  }
};

export const fecharModal = (onClose: () => void) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  document.addEventListener('keydown', handleKeyDown);

  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
};

export const focoModal = (open: boolean) => {
  if (open) {
    document.addEventListener('focusin', handleKeepFocusIn);
  } else {
    document.removeEventListener('focusin', handleKeepFocusIn);
  }

  return () => {
    document.removeEventListener('focusin', handleKeepFocusIn);
  };
};
