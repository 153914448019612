// @ts-nocheck
import { useFormik } from 'formik';
import { useUploadAssinatura } from './useUploadAssinatura';
import { AppContext } from 'main/contexts/AppContext';
import { useContext, useEffect, useState } from 'react';
import useAssinaturaTransferencia from './useAssinaturaTransferencia';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const useAssinaturaManual = () => {
  const [arquivoAdicionado, setArquivoAdicionado] = useState(false);
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { handleRedirectComprovante } = useAssinaturaTransferencia();

  const initialStateFormik = { arquivo: undefined };

  const formik =
    useFormik<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IFormikAssinaturaManual>({
      enableReinitialize: true,
      initialValues: initialStateFormik,
      validateOnMount: true,
      onSubmit: () => undefined,
    });

  const { arquivo } = formik.values;
  const { response, loading, fetchData } = useUploadAssinatura();
  const isDisabled = !arquivoAdicionado;

  const handleDefinirArquivoUpload = (upload: File) => {
    formik.setValues({
      arquivo: upload,
    });
    setArquivoAdicionado(true);
  };

  const enviarArquivo = () => {
    const formData = new FormData();
    formData.append('Arquivo', arquivo as Blob);
    formData.append('cpf', cpfCnpj);
    formData.append('numeroCertificado', numCertificado);
    formData.append('codigoCallback', '10');
    fetchData(formData);
  };

  useEffect(() => {
    if (response?.numeroProtocolo) {
      handleRedirectComprovante();
    }
  }, [response]);

  return {
    responseUpload: response,
    loadingUpload: loading,
    isDisabled,
    enviarArquivo,
    fetchDataUpload: fetchData,
    handleDefinirArquivoUpload,
  };
};

export default useAssinaturaManual;
