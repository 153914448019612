// @ts-nocheck
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { basePath } from 'config/httpRequest';
import { reactQueryCacheDuration } from 'config/storage_keys';
import { useHandleReponse } from 'main/hooks/useHandleResponse';
import { useToast } from 'main/hooks/useToast';
import { api } from 'main/services';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { IPecoParams } from 'main/types/IPecoParams';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';

/**
 * * @param IPecoParams
 * * @link https://azuredevops.caixavidaeprevidencia.intranet/CVP/CVP.Frontends/_wiki/wikis/CVP.FrontEnds.wiki/253/React-Hook-usePeco
 * * @description 2023-01-30 - hook responsável por consumir as APIs
 * * @description Antes de alterar, comunicar o autor ou os arquitetos
 * * @returns  return {loading,    response,    fetchData,    error,  };
 * */

export function usePeco<T, U>(params: IPecoParams<T>) {
  const queryClient = useQueryClient();
  const { toastError } = useToast();
  const { handleResponse } = useHandleReponse({ ...params?.handleResponse });
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<IHandleReponseResult<U>>();
  const [responseBinary, setResponseBinary] = useState<unknown>();

  const [error, setError] = useState<unknown>(null);
  const pecoBasePath = params.api.pecoBasePath || basePath;
  const payload = params.payload || {};
  const withCache = params.cache || false;
  const autoFetch = params.autoFetch || false;
  const cacheTime = params.cacheTime || reactQueryCacheDuration();
  const requestConfig = params.requestConfig || {};

  const isFileResponseType = () => {
    const nonFileResponseTypes = ['json'];
    return (
      requestConfig?.responseType &&
      !nonFileResponseTypes.includes(requestConfig.responseType.toLowerCase())
    );
  };

  const executeRequest = async (
    dynamicPayload = {},
  ): Promise<IHandleReponseResult<U> | undefined> => {
    const finalUrl = `${pecoBasePath}${params.api.operationPath}`;
    const isDynamicPayloadFormData = dynamicPayload instanceof FormData;
    const isPayloadFormData = payload instanceof FormData;

    if (
      checkIfSomeItemsAreTrue([isDynamicPayloadFormData, isPayloadFormData])
    ) {
      const payloadData = isDynamicPayloadFormData ? dynamicPayload : payload;
      const { data } = await api.post(finalUrl, payloadData, {
        ...requestConfig,
      });
      if (isFileResponseType()) {
        return data;
      }

      return handleResponse(data);
    }

    const responseApi = async () => {
      const { data } = await api.post(finalUrl, {
        ...payload,
        ...dynamicPayload,
      });
      return data;
    };

    if (isFileResponseType()) {
      return responseApi();
    }

    return handleResponse(await responseApi());
  };

  const getReactQueryKey = () => {
    if (params?.cacheKey) return [params.api.operationPath, params.cacheKey];

    return [params.api.operationPath];
  };

  const fetchByReactQueryClient = async () =>
    queryClient.fetchQuery(getReactQueryKey(), {
      queryFn: executeRequest,
      staleTime: cacheTime,
    });

  const invalidateCache = async (queryKey?: string) =>
    queryClient.invalidateQueries(queryKey ?? getReactQueryKey());

  const fetchData = async (
    dynamicPayload = {},
  ): Promise<IHandleReponseResult<U> | undefined> => {
    try {
      setLoading(true);
      let result;

      if (withCache) {
        result = await fetchByReactQueryClient();
      } else {
        result = await executeRequest(dynamicPayload);
      }
      setResponse(result);
      return result;
    } catch (requestError: unknown) {
      const erro = requestError as Error;
      toastError(erro?.message);
      setError(requestError);
    } finally {
      setLoading(false);
    }
    return undefined;
  };

  const fetchDataBinary = async (
    dynamicPayload = {},
  ): Promise<unknown | undefined> => {
    try {
      setLoading(true);
      const result = await executeRequest(dynamicPayload);
      setResponseBinary(result as unknown);
      return result;
    } catch (requestError: unknown) {
      const erro = requestError as Error;
      toastError(erro?.message);
      setError(requestError);
    } finally {
      setLoading(false);
    }
    return undefined;
  };

  useEffect(() => {
    if (autoFetch && !isFileResponseType()) {
      fetchData();
    }
    if (autoFetch && isFileResponseType()) {
      fetchDataBinary();
    }
  }, []);
  return {
    loading,
    response,
    responseBinary,
    fetchData,
    fetchDataBinary,
    error,
    setResponse,
    invalidateCache,
  };
}
