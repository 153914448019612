// @ts-nocheck
import React, { useState } from 'react';
import { Display, Grid, Radio, Text } from '@cvp/design-system/react';
import { TextArea } from 'main/components/form';
import RenderConditional from 'main/components/RenderConditional';
import { removeEmptySpace } from 'main/utils/string';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import ButtonsEtapasAporte from 'previdencia/features/Aporte/components/ButtonsEtapasAporte';
import ModalNotificacao from 'previdencia/features/Aporte/components/ModalAporte';
import * as CONST from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const DeclaracaoOrigemRecursos: React.FC<
  APORTE_TYPES.IDeclaracaoOrigemRecursosProps
> = ({ aporteOrigemRecursos }) => {
  const { featureData } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  const [openModal, setOpenModal] = useState(false);
  const [outrosTipos, setOutrosTipos] = useState(false);
  const [isOrigemValida, setIsOrigemValida] = useState(false);
  const [descricaoOrigemRecursos, setDescricaoOrigemRecursos] = useState('');
  const quantidadeLinhasDescricao =
    descricaoOrigemRecursos !== ''
      ? descricaoOrigemRecursos.split(/\r?\n/).length
      : 0;

  const quantidadePalavrasDescricao =
    descricaoOrigemRecursos !== ''
      ? removeEmptySpace(descricaoOrigemRecursos).length
      : 0;

  const handleInformativoAporte = (id: string, descricao: string): void => {
    if (id === CONST.enumOrigemRecursos.NAO_DESEJA_INFORMAR) setOpenModal(true);

    aporteOrigemRecursos.set({
      value: { idOrigemPagamento: id, descricaoOrigemPagamento: descricao },
      isValid: true,
      errorMsg: '',
    });

    setOutrosTipos(false);
    setIsOrigemValida(true);
  };

  const handleInformarOutros = (): void => {
    aporteOrigemRecursos.set({
      value: {
        idOrigemPagamento: CONST.enumOrigemRecursos.INFORMAR_ORIGEM_MANUALMENTE,
        descricaoOrigemPagamento: tryGetValueOrDefault(
          [descricaoOrigemRecursos],
          '',
        ),
      },
      isValid: true,
      errorMsg: '',
    });

    setOutrosTipos(true);
    setIsOrigemValida(quantidadePalavrasDescricao >= 10);
  };

  const handlePassarInformacaoProximaEtapa = (): void => {
    if (outrosTipos) {
      aporteOrigemRecursos.set({
        value: {
          idOrigemPagamento:
            CONST.enumOrigemRecursos.INFORMAR_ORIGEM_MANUALMENTE,
          descricaoOrigemPagamento: tryGetValueOrDefault(
            [descricaoOrigemRecursos],
            '',
          ),
        },
        isValid: true,
        errorMsg: '',
      });
    }

    featureData?.proximaEtapa();
  };

  const handleSetarEValidarOutrasOrigens = (outros: string): void => {
    setDescricaoOrigemRecursos(outros);
    setIsOrigemValida(outros.trim().length >= 10);
  };

  return (
    <>
      <Text variant="body02-md" color="primary" margin>
        Declaração de Origem dos Recursos
      </Text>
      <Text variant="body01-sm" margin>
        {CONST.DECLARACAO_ORIGEM_DOS_RECURSOS}
      </Text>

      <Text variant="body02-md" color="primary">
        Qual a origem do recurso para esse aporte?
      </Text>

      <S.RadioContainerAporte>
        {CONST.ORIGEM_RECURSOS?.map(item => (
          <Display alignItems="center" key={item.id}>
            <Radio
              name="group"
              value={item.id}
              checked={
                item.id === aporteOrigemRecursos.get().value.idOrigemPagamento
              }
              onChange={() => handleInformativoAporte(item.id, item.descricao)}
            />
            {item.descricao}
          </Display>
        ))}

        <Display alignItems="center">
          <Radio
            name="group"
            value="06"
            checked={
              aporteOrigemRecursos.get().value.idOrigemPagamento ===
              CONST.enumOrigemRecursos.INFORMAR_ORIGEM_MANUALMENTE
            }
            onChange={handleInformarOutros}
          />
          Outros:{' '}
          <RenderConditional condition={!!outrosTipos}>
            {descricaoOrigemRecursos}
          </RenderConditional>
        </Display>
      </S.RadioContainerAporte>

      <RenderConditional condition={!!outrosTipos}>
        <Grid>
          <Grid.Item xs={1} lg={1 / 2}>
            <Text variant="body01-sm">
              Digite ou cole o texto no campo abaixo:
            </Text>
            <TextArea
              spellCheck
              rows={3}
              maxLength={50}
              value={descricaoOrigemRecursos}
              onChange={({
                target: { value },
              }: React.ChangeEvent<{ value: string }>) =>
                handleSetarEValidarOutrasOrigens(value)
              }
            />
            <Text variant="caption-02">
              <strong>Mínimo de 10 e Máximo de 50 caracteres</strong>
            </Text>
            <Display>
              <Text variant="body01-sm">
                Caracteres: {descricaoOrigemRecursos?.length}
              </Text>
              <Text variant="body01-sm">
                Palavras: {quantidadePalavrasDescricao}
              </Text>
              <Text variant="body01-sm">
                Linhas: {quantidadeLinhasDescricao}
              </Text>
            </Display>
          </Grid.Item>
        </Grid>
      </RenderConditional>

      <ButtonsEtapasAporte
        proximaEtapa={handlePassarInformacaoProximaEtapa}
        disabled={!isOrigemValida}
        buttonLabel="Avançar"
      />

      <ModalNotificacao
        tipo={aporteOrigemRecursos.get().value.idOrigemPagamento}
        titulo={
          aporteOrigemRecursos.get().value.idOrigemPagamento ===
          CONST.enumTipoMensagemAporte.ORIGEM_DOS_RECURSOS
            ? CONST.enumOrigemRecursos.HEADER_DEFAULT
            : ''
        }
        onClose={() => setOpenModal(false)}
        open={openModal}
      />
    </>
  );
};

export default DeclaracaoOrigemRecursos;
