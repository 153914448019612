// @ts-nocheck
import styled from 'styled-components/macro';

export const ContainerResumoSaldo = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '40%',
  background: theme.color.neutral['06'],
  padding: 12,
}));
