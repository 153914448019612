// @ts-nocheck
import React, { useState } from 'react';
import Icon from 'main/components/Icon';
import { Hidden } from '@cvp/design-system/react';
import { Content } from 'main/components/Menu/Horizontal/styles';
import ModalEnviarSegundaVia from '../ModalEnviarSegundaVia';
import * as S from './styles';

type BotaoGerarBoletoProps = {
  numeroCobranca: string;
  linhaDigitavel: string;
};

const BotaoGerarBoleto: React.FC<BotaoGerarBoletoProps> = ({
  numeroCobranca,
  linhaDigitavel,
}) => {
  const [exibirModal, setExibirModal] = useState(false);

  const gerarBoleto = () => {
    setExibirModal(!exibirModal);
  };

  return (
    <Content>
      <S.ButtonStyle type="button" onClick={() => gerarBoleto()}>
        <Hidden only={['xs', 'sm', 'md']}>
          <S.Label color="">Gerar Boleto</S.Label>
        </Hidden>
        <Icon name="documentBack" />
      </S.ButtonStyle>
      <ModalEnviarSegundaVia
        numeroCobranca={numeroCobranca}
        open={exibirModal}
        handleClose={() => setExibirModal(false)}
        linhaDigitavel={linhaDigitavel}
      />
    </Content>
  );
};

export default BotaoGerarBoleto;
