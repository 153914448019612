// @ts-nocheck
import styled from 'styled-components/macro';

export const ContainerTituloAliquota = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  background: theme.color.brandPrimary.gradient,
}));
