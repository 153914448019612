// @ts-nocheck
import { Grid, Display, Modal, Button, Text } from '@cvp/design-system/react';
import { ModalConfirmacaoProps } from 'seguros/types/ModalConfirmacaoSeguros';

export const ModalConfirmacaoSeguros: React.FC<ModalConfirmacaoProps> = ({
  open,
  handleClose,
  handleConfirm,
  diaAntigo,
  diaNovo,
  isLoading,
}: ModalConfirmacaoProps) => {
  return (
    <Modal show={open} onClose={handleClose}>
      <Grid>
        <Grid.Item xs={1}>
          <Display>
            <Text variant="body01-md" color="text">
              Dia de vencimento antigo:
            </Text>
            <Text
              variant="body01-md"
              color="text-light"
              data-testid="diaVencimentoAtual"
            >
              {diaAntigo}
            </Text>
          </Display>
          <Display>
            <Text variant="body01-md" color="text">
              Novo dia de vencimento:
            </Text>
            <Text
              variant="body01-md"
              color="text-light"
              margin
              data-testid="novoDiaVencimento"
            >
              {diaNovo}
            </Text>
          </Display>
          <br />
          <>
            <Display>
              <Button variant="outlined" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={handleConfirm}
                loading={isLoading}
                data-testid="confirmarAlteracao"
              >
                Confirmar
              </Button>
            </Display>
          </>
        </Grid.Item>
      </Grid>
    </Modal>
  );
};
