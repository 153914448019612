// @ts-nocheck
import { useContext, useState } from 'react';
import { Button, Card, Display, Text } from '@cvp/design-system/react';
import { DropdownItem } from 'main/components/Menu/Horizontal/styles';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { FlexContainer } from 'main/styles/GlobalStyle';
import EmailSenderModal from 'main/components/EmailSenderModal/index';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import ContribuicoesLista from '../components/ContribuicoesLista';
import FiltroStatus from '../components/FiltroStatus';
import useObterContribuicoes from '../hooks/useObterContribuicoes';
import {
  converterStatus,
  filtrarStatus,
} from '../factories/contribuicoesFactory';
import { STATUS_COBRANCA_CONVERTIDO } from '../constants/constants';
import RenderConditional from 'main/components/RenderConditional';

const Contribuicoes = (): React.ReactElement => {
  const [hidden, setHidden] = useState(true);
  const [status, setStatus] = useState('');
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const { data, loading } = useObterContribuicoes(cpfCnpj, numCertificado);
  const contribuicaoConvertido = converterStatus(data);

  const contribuicoes = filtrarStatus(contribuicaoConvertido, status);
  const { goDadosPlano } = usePrevNavigation();

  <RenderConditional condition={loading}>
    <SkeletonLoading blocks={2} />
  </RenderConditional>;

  const handleFilterStatus = (statusValor: string) => {
    setStatus(statusValor);
    setHidden(!hidden);
  };

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredPermissions={[
          PREV_PERMISSIONS.CONTRIBUICOES_PAGAS,
          PREV_PERMISSIONS.CONTRIBUICOES_EM_ABERTO,
          PREV_PERMISSIONS.CONTRIBUICAO_ADICIONAL,
        ]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <FlexContainer justifyContent="space-between" alignItems="flex-end">
              <Text variant="headline-05" color="primary">
                Contribuições
              </Text>
              <FiltroStatus
                hidden={hidden}
                onClick={() => setHidden(!hidden)}
                toggle={() => setHidden(!hidden)}
              >
                <DropdownItem
                  onClick={() =>
                    handleFilterStatus(STATUS_COBRANCA_CONVERTIDO.PAGA)
                  }
                >
                  Pagos
                </DropdownItem>

                <DropdownItem
                  onClick={() =>
                    handleFilterStatus(STATUS_COBRANCA_CONVERTIDO.PENDENTE)
                  }
                >
                  Pendentes
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    handleFilterStatus(STATUS_COBRANCA_CONVERTIDO.NAO_PAGO)
                  }
                >
                  Não Pagos
                </DropdownItem>
                <DropdownItem onClick={() => handleFilterStatus('')}>
                  Todos
                </DropdownItem>
              </FiltroStatus>
            </FlexContainer>

            <RenderConditional condition={loading}>
              <SkeletonLoading />
            </RenderConditional>

            <RenderConditional condition={!loading}>
              <ContribuicoesLista contribuicoesLista={contribuicoes} />
            </RenderConditional>

            <Display>
              <Button variant="outlined" onClick={goDadosPlano}>
                Voltar
              </Button>
              <EmailSenderModal
                objetoEmail={{
                  parametrosEnvio: {
                    cpfCnpj,
                    dataVencimentoInicial: new Date('1900-01-01').toISOString(),
                    DataVencimentoFinal: new Date().toISOString(),
                    numeroCertificado: numCertificado,
                  },
                  tipoEmail: 'TabelaContribuicoesPagas',
                }}
              />
            </Display>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default Contribuicoes;
