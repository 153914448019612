// @ts-nocheck
import React from 'react';
import Table from 'main/components/Table';

import { tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as S from './styles';
import { SelectTipoTransferencia } from './SelectTipoTransferencia';
import { InputValor } from './InputValor';
import { COLUNAS_TABELA_EXPANSIVEL_MULTIFUNDOS_PJ } from './constants/constants';
import { LinhaExpansivelProps } from './types/TabelaMultifundos.types';

export const LinhaExpansivel: React.FunctionComponent<LinhaExpansivelProps> = ({
  data,
  handleAlterar,
}) => {
  const table = data?.reservas.map(
    ({
      descricaoReserva,
      saldo,
      isParcial,
      valorTransferencia,
      tipoTransferencia,
    }) => ({
      saldo: tryGetMonetaryValueOrDefault(saldo),
      descricaoReserva,
      tipo_transferencia: (
        <SelectTipoTransferencia
          onSelect={(tipoTransferenciaSelect: string) =>
            handleAlterar({
              id: tryGetValueOrDefault([data?.codigoFundo], ''),
              reserva: descricaoReserva,
              tipoTransferencia: tipoTransferenciaSelect,
              saldo: Number(saldo),
            })
          }
        />
      ),
      valor_input: (
        <InputValor
          show={isParcial}
          valorTransferencia={valorTransferencia}
          tipoTransferencia={tipoTransferencia}
          valorMaximo={Number(saldo)}
          valorMinimo={data?.valorMinimo}
          onBlur={(value: number) => {
            handleAlterar({
              id: tryGetValueOrDefault([data?.codigoFundo], ''),
              reserva: descricaoReserva,
              tipoTransferencia,
              saldo: Number(saldo),
              valorTransferencia: value,
            });
          }}
        />
      ),
    }),
  );

  return (
    <S.ContainerLinhaExpansivel>
      <Table
        responsive
        data={tryGetValueOrDefault([table], [])}
        columns={tryGetValueOrDefault(
          [COLUNAS_TABELA_EXPANSIVEL_MULTIFUNDOS_PJ],
          [],
        )}
        noDataComponent="Não há dados para exibir."
      />
    </S.ContainerLinhaExpansivel>
  );
};
