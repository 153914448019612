// @ts-nocheck
export const useMessageEvent = () => {
  const DEFAULT_EVENT_NAME = 'message';

  const subscribe = (listener: (data: unknown) => void) => {
    window.addEventListener(DEFAULT_EVENT_NAME, listener);
  };
  const unsubscribe = (listener: (data: unknown) => void) => {
    window.removeEventListener(
      DEFAULT_EVENT_NAME ?? DEFAULT_EVENT_NAME,
      listener,
    );
  };

  return {
    subscribe,
    unsubscribe,
  };
};
