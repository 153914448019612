// @ts-nocheck
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import masks from 'main/utils/masks';
import { isCpf, isCnpj } from 'main/utils/cpf_cnpj';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import { usePecoConsultarCliente } from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks/usePecoRegistrarOcorrencia';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';
import * as RESPONSE_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrenciasResponse';

export const useRenderizarCardCliente = () => {
  const {
    salvarConsultaCpfCnpj,
    formDadosCliente,
    salvarDadosCliente,
    limparDadosCliente,
    limparOcorrenciaAberta,
  } = useRegistrarOcorrenciaContext();

  const { loadingDadosConsultaCliente, obterDadosConsultaCliente } =
    usePecoConsultarCliente();

  const [exibeFormDadosCliente, setExibeFormDadosCliente] =
    useState<boolean>(false);

  const formik: FormikProps<REGISTRO_OCORRENCIA_TYPES.IFormikValuesConsultaCpfCnpj> =
    useFormik<REGISTRO_OCORRENCIA_TYPES.IFormikValuesConsultaCpfCnpj>({
      initialValues: CONSTS.INITIAL_FORMIK_STATE_CONSULTA_CPFCNPJ,
      onSubmit: consultarCliente,
    });

  const cpfValido = isCpf(formik.values.inputconsultaCpfCnpj);

  const cnpjValido = isCnpj(formik.values.inputconsultaCpfCnpj);

  const cpfOuCnpjValido = checkIfSomeItemsAreTrue([cpfValido, cnpjValido]);

  const salvarDadosClienteContexto = (
    dadosCliente: RESPONSE_TYPES.IConsultarClienteResponse | undefined,
  ): void => {
    if (dadosCliente) {
      const telefoneSecundarioPfOuPj = cpfValido
        ? dadosCliente?.telefone
        : dadosCliente?.telefoneEmpresa;

      salvarDadosCliente({
        ...formDadosCliente,
        novoCliente: false,
        tipoCliente: dadosCliente.tipoCliente,
        razaoSocialOuNome: tryGetValueOrDefault([dadosCliente?.cliente], ''),
        emailCorporativoOuEmail: tryGetValueOrDefault(
          [dadosCliente?.email],
          '',
        ),
        cep: tryGetValueOrDefault([dadosCliente?.cep], ''),
        endereco: tryGetValueOrDefault([dadosCliente?.endereco], ''),
        bairro: tryGetValueOrDefault([dadosCliente?.bairro], ''),
        cidade: tryGetValueOrDefault([dadosCliente?.cidade], ''),
        uf: tryGetValueOrDefault([dadosCliente?.uf], ''),
        telefonePrincipal: tryGetValueOrDefault([dadosCliente?.celular], ''),
        telefoneSecundario: tryGetValueOrDefault(
          [telefoneSecundarioPfOuPj],
          '',
        ),
      });
    }
  };

  async function consultarCliente(): Promise<void> {
    if (cpfOuCnpjValido) {
      limparDadosCliente();

      const result = await obterDadosConsultaCliente({
        CpfCnpj: masks.cpfCnpj.unmask(formik.values.inputconsultaCpfCnpj),
      });

      const dadosCliente = result?.entidade;

      salvarDadosClienteContexto(dadosCliente);

      setExibeFormDadosCliente(true);
    }
  }

  const fecharConsultaCliente = (): void => {
    setExibeFormDadosCliente(false);
    limparOcorrenciaAberta();
  };

  const salvarCpfCnpjContexto = (): void => {
    if (cpfOuCnpjValido) {
      salvarConsultaCpfCnpj({
        cpf: cpfValido,
        cnpj: cnpjValido,
        valor: masks.cpfCnpj.unmask(formik.values.inputconsultaCpfCnpj),
      });
    }
  };

  useEffect(() => {
    fecharConsultaCliente();
    salvarCpfCnpjContexto();
  }, [formik.values.inputconsultaCpfCnpj]);

  return {
    formik,
    exibeFormDadosCliente,
    loadingDadosConsultaCliente,
    cpfOuCnpjValido,
  };
};
