// @ts-nocheck
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  Display,
  Grid,
  Text,
  TextField,
} from '@cvp/design-system/react';
import MiltonDefault from 'assets/img/ilustracoes/prev/milton_default.svg';
import { Field, FieldProps, Formik } from 'formik';
import { AppContext } from 'main/contexts/AppContext';
import { PREV_PERMISSIONS } from 'main/features/Auth/config/userProfiles';
import { useAuth } from 'main/features/Auth/hooks';
import { validaCpfCnpj } from 'main/utils/cpf_cnpj';
import masks from 'main/utils/masks';
import { TooltipWrapper } from 'main/components/Wrappers';

const BuscaCliente = () => {
  const history = useHistory();
  const { setCliente, resetarCliente } = useContext(AppContext);
  const { removeCustomPermission } = useAuth();

  const handleSearchSubmit = (value: string) => {
    const unmaskedValue = masks.numberOnly.unmask(value);
    setCliente({ cpfCnpj: unmaskedValue, numCertificado: '' });
    history.push(`/cliente/produtos`);
  };

  useEffect(() => {
    resetarCliente();
    removeCustomPermission(PREV_PERMISSIONS.POSICAO_CONSOLIDADA);
  }, []);

  return (
    <Grid justify="center" alignItems="center" style={{ height: '100%' }}>
      <Grid.Item xs="1">
        <Text variant="headline-03" color="secondary" margin align="center">
          Bem-vindo ao Serviços Online da Caixa Vida e Previdência!
        </Text>
        <Grid.Item xs={1}>
          <Display justify="space-around">
            <Display justify="center" alignItems="center">
              <img src={MiltonDefault} alt="Ilustração" />
              <div>
                <Text margin>Consulte seu cliente</Text>
                <Text color="text-light" variant="body02-sm">
                  Para efetuar a consulta de produtos, <br /> digite ao lado o
                  número do CPF ou CNPJ do cliente.
                </Text>
              </div>
            </Display>
            <Formik
              initialValues={{ search: '' }}
              onSubmit={values => handleSearchSubmit(values.search)}
            >
              {({
                handleSubmit,
                handleBlur,
                values,
                setFieldValue,
                errors,
              }) => (
                <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                  <Card>
                    <Card.Content align="center">
                      <Grid justify="center" alignItems="center">
                        <Grid.Item xs="1" justify="center" alignItems="center">
                          <Field name="search" validate={validaCpfCnpj}>
                            {({ field, meta }: FieldProps) => {
                              return (
                                <TooltipWrapper
                                  left={25}
                                  top={-2}
                                  tooltip="A busca por CNPJ, só permite visualizar funcionalidades para Pessoa Física."
                                >
                                  <TextField
                                    {...field}
                                    label="CPF / CNPJ"
                                    error={meta.touched && meta.error}
                                    errorMessage={meta.touched && meta.error}
                                    onChange={({
                                      target: { value },
                                    }: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(
                                        'search',
                                        masks.cpfCnpj.unmask(value),
                                      );
                                    }}
                                    value={masks.cpfCnpj.mask(values.search)}
                                    onBlur={handleBlur}
                                  />
                                </TooltipWrapper>
                              );
                            }}
                          </Field>
                        </Grid.Item>
                        <Grid.Item xs="1">
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={Object.keys(errors).length > 0}
                          >
                            Consultar
                          </Button>
                        </Grid.Item>
                      </Grid>
                    </Card.Content>
                  </Card>
                </form>
              )}
            </Formik>
          </Display>
        </Grid.Item>
      </Grid.Item>
    </Grid>
  );
};

export default BuscaCliente;
