// @ts-nocheck
import styled from 'styled-components/macro';
import {
  Display as DisplayDS,
  Text as TextDS,
  Divider as DividerDS,
} from '@cvp/design-system/react';
import SelectDS from 'main/components/form/Select';

export const Card = styled(DisplayDS)(({ theme: { color }, combinacao }) => ({
  border: `1px solid ${color.brandPrimary.light}`,
  borderRadius: '10px',
  position: 'relative',
  padding: '25px',
  paddingBottom: combinacao ? '50px' : 'unset',
  width: combinacao ? '100%' : '80%',
  marginBottom: combinacao ? '50px' : '0px',
  transition: '350ms all ease',

  '&:hover': {
    background: color.neutral['07'],
    transition: '350ms all ease',
  },
}));

export const BadgeTitle = styled(TextDS)(({ theme: { color } }) => ({
  background: color.brandPrimary.light,
  color: color.neutral['08'],
  borderRadius: '50px',
  fontWeight: 'bold',
  padding: '8px 12px',
  margin: '0px',
  letterSpacing: '1px',
  position: 'absolute',
  top: '-18px',
  left: '0px',
}));

export const Title = styled(TextDS)(() => ({
  margin: '12px 8px 0px 8px',
}));

export const Divider = styled(DividerDS)(({ theme: { color } }) => ({
  borderTop: `1px solid ${color.brandPrimary.light}`,
  backgroundColor: 'unset',
  width: '80%',
}));

export const SubTitle = styled(TextDS)(() => ({
  fontWeight: 'bold',
  width: '100%',
}));

export const ContainerInputs = styled(DisplayDS)(({ theme }) => ({
  margin: '0',
  flexWrap: 'nowrap',
}));

export const ContainerContribuicaoInput = styled(DisplayDS)(
  ({ theme: { color }, combinacao }) => ({
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: combinacao ? 'column' : 'row',

    '& input': {
      color: color.brandPrimary.light,
      height: '40px',
      width: combinacao ? '100%' : '90%',
      fontWeight: 'bold',
      marginBottom: '5px',
    },

    '& input::placeholder': {
      color: color.brandPrimary.light,
    },
  }),
);

export const BadgeValor = styled(DisplayDS)(
  ({ theme: { color }, combinacao }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: color.brandPrimary.light,

    borderRadius: '40px',
    padding: combinacao ? '12px 20px' : '20px 30px',
    margin: '0px',
    letterSpacing: '1px',
    position: 'absolute',
    top: combinacao ? '100%' : '50%',
    right: combinacao ? 'unset' : '-130px',
    bottom: 'unset',
    left: combinacao ? '50%' : 'unset',
    transform: combinacao ? 'translate(-50%, -50%)' : 'translate(0, -50%)',

    '& h6, & p': {
      color: color.neutral['08'],
      textAlign: 'center',
      margin: '0px',
    },
  }),
);

export const Select = styled(SelectDS)(() => ({
  '& input, & div:first-child': {
    height: '40px',
  },

  '& div': {
    display: 'flex',
    alignItems: 'center',
  },
}));
