// @ts-nocheck
export enum EnumTipoPeriodo {
  customizado = 'custom',
}

export enum SITUACAO {
  ATIVA = 'A',
  COBRANCA_SUSPENSA = 'U',
  BENEFICIO = 'B',
  SINISTRO = 'S',
  CANCELADA = 'C',
  ENCERRADA = 'E',
  DESLIGADA = 'D',
}
