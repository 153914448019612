// @ts-nocheck
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { ITituloCapitalizacao } from 'seguros/types/IResponseCertificadosResumo';

export const COLUNAS_TABELA_SORTEIOS = [
  {
    name: 'Nº de Sorteio',
    selector: 'numeroSorteio',
  },
  {
    name: 'Nº do Título',
    selector: 'numeroTitulo',
  },
  {
    name: 'Com seu número de sorteio, você concorre a',
    cell: ({ valorTitulo }: ITituloCapitalizacao) =>
      tryGetMonetaryValueOrDefault(valorTitulo),
    maxWidth: '200px',
    center: true,
  },
];

export const SEM_DADOS = 'Não há dados de sorteio a serem exibidos.';

export const DESC_SORTEIO = 'NÚMERO DE SORTEIO';
