// @ts-nocheck
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { api, IApiResponse } from 'main/services';
import {
  obterUrlAtualizarStatusProspeccao,
  obterUrlListarProspecoes,
  obterUrlUploadArquivoProspeccao,
  obterUrlDetalhesDaProspeccao,
  obterUrlDownloadArquivoProspeccao,
} from 'main/features/prospeccao/config/endpoints';
import { IAtualizacaoStatusRequest } from 'main/features/prospeccao/types/IAtualizacaoStatusRequest';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';
import { mapListProspeccao } from 'main/features/prospeccao/utils/mapListProspeccaoResult';
import { IArquivoDownload } from 'main/features/prospeccao/types/IDetalhesProspeccao';

export const atualizarStatusProspeccao = async (
  payload: IAtualizacaoStatusRequest,
) => {
  const result = await api.post<IApiResponse<unknown>>(
    obterUrlAtualizarStatusProspeccao(),
    payload,
  );
  return result.data;
};

const obterLista = async (perfil: string) => {
  const { data } = await api.post<IApiResponse<IProspeccao[]>>(
    obterUrlListarProspecoes(),
    {
      perfil,
    },
  );

  return mapListProspeccao(data);
};

export const obterListaParaEconomiario = (
  perfil = USER_PROFILES.ECONOMIARIO || USER_PROFILES.ANALISTA_POS_VENDA,
) =>
  obterLista(
    perfil ?? (USER_PROFILES.ECONOMIARIO || USER_PROFILES.ANALISTA_POS_VENDA),
  );

export const obterListaParaAnaliseMedica = (perfil = USER_PROFILES.MEDICO) =>
  obterLista(perfil ?? USER_PROFILES.MEDICO);

export const obterListaParaAnalistaProduto = (
  perfil = USER_PROFILES.ANALISTA_PRODUTO,
) => obterLista(perfil ?? USER_PROFILES.ANALISTA_PRODUTO);

export const obterListaParaOperacao = (perfil = USER_PROFILES.OPERADOR) =>
  obterLista(perfil ?? USER_PROFILES.OPERADOR);

export const realizarUploadArquivoProspeccao = async (
  files: FileList,
  idProspeccaoCliente: string,
): Promise<IApiResponse<unknown>> => {
  const data = new FormData();
  const file = files[0];
  data.append('Arquivo', file);
  data.append('idProspeccaoCliente', idProspeccaoCliente);
  data.append('DataHoraArquivo', new Date().toISOString());
  data.append('TipoArquivo', 'dps');

  const result = await api.post<IApiResponse<unknown>>(
    obterUrlUploadArquivoProspeccao(),
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return result.data;
};

export const obterDetalhesDaProspeccao = async (
  id: string,
  cpfCnpjCliente: string,
) => {
  const result = await api.post(obterUrlDetalhesDaProspeccao(), {
    id,
    cpfCnpjCliente,
  });

  return result.data;
};

export const obterArquivoEmBase64 = async (
  idArquivoProsposta: number,
  cpfCnpjCliente: string,
) => {
  const result = await api.post<IApiResponse<IArquivoDownload>>(
    obterUrlDownloadArquivoProspeccao(),
    {
      idArquivoProsposta,
      cpfCnpjCliente,
      tipoArquivo: 'dps',
    },
  );

  return result.data;
};
