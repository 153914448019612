// @ts-nocheck
import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import * as BeneficioProtecaoApi from 'previdencia/features/AlterarValorContribuicao/services/obterDadosBeneficioProtecao.api';
import { ResponseDadosBeneficioProtecao } from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';

const useObterDadosBeneficioProtecao = () => {
  const { toastError } = useToast();
  const [loadingDadosBeneficioProtecao, setLoading] = useState<boolean>(false);
  const [dadosBeneficioProtecao, setDadosBeneficioProtecao] =
    useState<ResponseDadosBeneficioProtecao>();
  const [mensagemBeneficioProtecao, setMensagemBeneficioProtecao] =
    useState<string>();

  const { cliente } = useContext(AppContext);

  const obterDadosBeneficioProtecao = async (): Promise<void> => {
    try {
      setLoading(true);
      const dados = await BeneficioProtecaoApi.obterDadosBeneficioProtecao(
        cliente?.numCertificado,
      );

      setDadosBeneficioProtecao(dados?.entidade);

      const mensagemRetornoAPI = dados?.mensagens?.[0]?.descricao?.split(':');

      setMensagemBeneficioProtecao(mensagemRetornoAPI[1]);
    } catch (error: any) {
      toastError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loadingDadosBeneficioProtecao,
    dadosBeneficioProtecao,
    mensagemBeneficioProtecao,
    setDadosBeneficioProtecao,
    obterDadosBeneficioProtecao,
  };
};

export default useObterDadosBeneficioProtecao;
