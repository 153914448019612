// @ts-nocheck
import { PECOS_AREA_LOGADA } from 'atendimento/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { IApiResponse } from 'main/services';

export const useDeletarClienteAreaLogada = () => {
  const { loading, response, fetchData } = usePeco<
    { numCpf: string },
    IApiResponse<unknown>
  >({
    api: { operationPath: PECOS_AREA_LOGADA.DeletarCadastro },
  });

  return {
    fetchData,
    loading,
    response,
  };
};
