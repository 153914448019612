// @ts-nocheck
import { DadosTipoRenda } from 'previdencia/features/AlterarValorContribuicao/types/FactoryTypes';

export const montarMensagemModalRangeValoresContribuicaoFactory = (
  dadosTipoRenda: DadosTipoRenda[],
  novoValorTipoBeneficio: string,
): DadosTipoRenda | undefined => {
  return dadosTipoRenda.find(
    dado => dado.tipoBeneficioProtecao === novoValorTipoBeneficio,
  );
};
