// @ts-nocheck
import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import { IImportanciaSegurada } from 'main/features/prospeccao/types/IImportanciaSegurada';
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { isCpf } from 'main/utils/cpf_cnpj';

export const usePecoIdentificacao = () => {
  const { loading: loadingImportanciaSegurada, fetchData } = usePeco<
    { cpfCnpjCliente: string },
    IImportanciaSegurada
  >({
    api: {
      operationPath: PECOS_PRESTAMISTA.ObterValorImportanciaSegurada,
    },
    handleResponse: {
      throwToastErrorBFF: false,
      throwToastErrorGI: false,
    },
  });

  const calcularAcumulo = async (
    cpfCnpj: string,
    valorCreditoInput: number,
  ) => {
    if (isCpf(cpfCnpj)) {
      const result = await fetchData({
        cpfCnpjCliente: cpfCnpj,
      });
      const valorContratos = tryGetValueOrDefault(
        [result?.entidade?.valorTotalContratos],
        0,
      );

      if (valorContratos > 0) return valorContratos + valorCreditoInput;
    }

    return valorCreditoInput;
  };

  return {
    calcularAcumulo,
    loadingImportanciaSegurada,
  };
};
