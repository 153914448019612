// @ts-nocheck
import styled from 'styled-components';
import { TCircleStatusProps } from './CircleStatus.types';

export const StatusCircle = styled.div<TCircleStatusProps>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #dbdcde;
  background-color: ${({ variant }) => {
    switch (variant) {
      case 'success':
        return 'green';
      case 'warning':
        return 'yellow';
      case 'error':
        return 'red';
      default:
        return 'gray';
    }
  }};
`;
