// @ts-nocheck
export enum DefaultValue {
  VALOR_MONETARIO_VAZIO = 'R$ 0,00',
  TIPO_BENEFICIO_PR = 'PR',
  TIPO_BENEFICIO_RI = 'RI',
  TIPO_BENEFICIO_ATIVO = 'Ativo',
  CODIGO_EMPRESA = '500',
  STATUS_FUNDO_ATIVO = 'Ativo',
  DESCRICAO_PAGAMENTO_PECULIO = 'pecúlio',
  CRITERIO_CUSTOMIZACAO_TIPO_C = 'C',
  PERIODICIDADE_MES = 'M',
  PERIODICIDADE_MES_EXTENSO = 'Mensal',
  PERIODICIDADE_ANO = 'A',
  PERIODICIDADE_ANO_EXTENSO = 'Anual',
  EXISTE_COBERTURA = 'S',
  PERMITIR_ALTERACAO_PECULIO = 'S',
  PERMITIR_ALTERACAO_PENSAO = 'S',
  NAO_PERMITIR_ALTERACAO_PECULIO = 'N',
  NAO_PERMITIR_ALTERACAO_PENSAO = 'N',
  URL_CAIXA = 'https://www.caixaseguradora.com.br',
  TIPO_BENEFICIO_PECULIO = 'peculio',
  TIPO_BENEFICIO_PENSAO = 'pensao',
  DESCRICAO_TIPO_BENEFICIO_PECULIO = 'Pecúlio por Morte',
  DESCRICAO_TIPO_BENEFICIO_PENSAO = 'Pensão por Prazo Certo',
  SALARIO = 1,
  TELEFONE_ACEITA_SMS = 'S',
  TIPO_FUNDO_EXISTENTE = 'existente',
  TIPO_FUNDO_NOVO = 'novo',
  QTD_MINIMA_FUNDOS_SELECIONADOS = 1,
  VALOR_MINIMO_REDISTRIBUICAO_MULTIPLOS_FUNDOS = 500,
  POSSUI_MENSAGEM = 'true',
  NAO_POSSUI_MENSAGEM = 'false',
  STATUS_ERRO_SOLICITACAO_CONTRIBUICAO = 'ERRO',
}
