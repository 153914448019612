// @ts-nocheck
import * as Yup from 'yup';
import { IResponseObterListaFopsAtivos } from 'main/features/Administracao/types/IFops';
import { TListaAgrupadaPorTipo } from 'painelAdministracao/types/ListagemTypes';
import { definirLabelTipo } from 'painelAdministracao/utils/utils';
import * as CONSTS from 'painelAdministracao/constants/constants';

export const agruparDadosPorTipo = (
  dados: IResponseObterListaFopsAtivos[],
): TListaAgrupadaPorTipo[] => {
  let armazenarGrupos: TListaAgrupadaPorTipo[] = [];
  dados.forEach(fop => {
    const itemTipo = armazenarGrupos?.find(item => item.tipo === fop.tipo);

    if (itemTipo) {
      const index = armazenarGrupos.findIndex(
        grupo => grupo.tipo === itemTipo.tipo,
      );
      const novosItens = [...armazenarGrupos[index].itens];
      novosItens.push(fop);
      const novoGrupo = { ...armazenarGrupos[index] };

      novoGrupo.itens = novosItens;

      const novoArmazenarGrupos = [...armazenarGrupos];

      novoArmazenarGrupos[index] = novoGrupo;
      armazenarGrupos = novoArmazenarGrupos;
    }
    if (!itemTipo) {
      const novoItem: TListaAgrupadaPorTipo = {
        tipo: fop.tipo,
        labelTipo: definirLabelTipo(fop.tipo),
        itens: [fop],
      };
      armazenarGrupos = [...armazenarGrupos, novoItem];
    }
  });
  return armazenarGrupos;
};

export const FORMIK_VALIDATION = Yup.object().shape({
  codigo: Yup.string().required(
    CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO,
  ),
  nome: Yup.string()
    .required(CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO)
    .min(3, 'Informe um nome válido'),
  versao: Yup.string().required(
    CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO,
  ),
  dataVersao: Yup.string().required(
    CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO,
  ),
  arquivo: Yup.string().required(
    CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO,
  ),
});
