// @ts-nocheck
export const obterUrlListarProdutos = (): string =>
  '/PortalEconomiario/PECO_ListarProdutosProspeccao';

export const obterUrlListarPorCpfCnpj = (): string =>
  '/PortalEconomiario/PECO_ListarProspeccoesPorCpfCnpj';

export const obterUrlRecuperarProspeccao = (): string =>
  '/PortalEconomiario/PECO_Recuperar';

export const obterUrlListarProspecoes = (): string =>
  '/PortalEconomiario/PECO_ListarProspeccoes';

export const obterUrlCadastrarProspeccao = (): string =>
  '/PortalEconomiario/PECO_CadastrarAtualizarProspeccao';

export const obterUrlRecuperarFormularioProspeccao = (): string =>
  '/PortalEconomiario/PECO_RecuperarFormularioProspeccao';

export const obterUrlImportanciaSegurada = (): string =>
  '/PortalEconomiario/PECO_ObterValorImportanciaSegurada';

export const obterUrlDetalhesDaProspeccao = (): string =>
  '/PortalEconomiario/PECO_ObterDadosProspeccao';

export const obterUrlUploadArquivoProspeccao = (): string =>
  '/PortalEconomiario/PECO_IncluirArquivoProspeccao';

export const obterUrlDownloadArquivoProspeccao = (): string =>
  '/PortalEconomiario/PECO_ObterArquivoProspeccao';

export const obterUrlAtualizarStatusProspeccao = (): string =>
  '/PortalEconomiario/PECO_AtualizarSituacaoProspeccao';
