// @ts-nocheck
import { Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import useURLQueryParam from 'main/hooks/useURLQueryParam';
import { useEffect, useState } from 'react';
import {
  ACAO_RETENCAO,
  CHAVE_ID_ACAO_RETENCAO_EXECUTADA,
  CHAVE_ID_DETALHE_PORTABILIDADE_ATUAL,
} from '../constants';
import { useDefinirAcaoRetencao } from '../hooks/useDefinirAcaoRetencao';

const FeedbackAssinaturaDocUsign = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const { getParam } = useURLQueryParam();
  const { defirnirAcaoRetencao } = useDefinirAcaoRetencao();
  const idRetencao =
    sessionStorage.getItem(CHAVE_ID_DETALHE_PORTABILIDADE_ATUAL) ?? '';

  const atualizarStatusPortabilidade = async () => {
    const retencaoExecutada =
      sessionStorage.getItem(CHAVE_ID_ACAO_RETENCAO_EXECUTADA) ?? '';
    const acoesQueAtualizamStatus = [
      ACAO_RETENCAO.SOLICITAR_PRORROGACAO_VIA_SMS.ID_ACAO,
      ACAO_RETENCAO.SOLICITAR_CANCELAMENTO_VIA_SMS.ID_ACAO,
    ];

    if (acoesQueAtualizamStatus.includes(retencaoExecutada)) {
      const idAcao = retencaoExecutada
        ? ACAO_RETENCAO.ASSINATURA_PRORROGACAO_SOLICITADA.ID_ACAO
        : ACAO_RETENCAO.ASSINATURA_CANCELAMENTO_SOLICITADA.ID_ACAO;
      await defirnirAcaoRetencao(idRetencao, idAcao);
    }
  };
  useEffect(() => {
    if (getParam('event') === 'signing_complete') {
      setSuccess(true);
      atualizarStatusPortabilidade();
    } else {
      defirnirAcaoRetencao(
        idRetencao,
        ACAO_RETENCAO.CANCELAR_PROCESSO_ASSINATURA.ID_ACAO,
      );
    }
    window.parent.postMessage('retornoAcaoConcluirAssinatura');
  }, []);

  return (
    <div>
      {success && (
        <>
          <Text color="success">
            <Icon name="successCheck" /> Assinatura eletrônica realizada com
            sucesso!
          </Text>
          <br />
        </>
      )}
      {!success && (
        <Text color="error">
          <Icon name="close" /> Ocorreu um erro ao realizar a operação, por
          favor realize o procedimento de assinatura novamente.
        </Text>
      )}
    </div>
  );
};

export default FeedbackAssinaturaDocUsign;
