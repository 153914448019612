// @ts-nocheck
import React from 'react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { PE } from 'main/components/PEComponents';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { ValoresContribuicaoProps } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoComponentProps';

const ValoresContribuicao: React.FC<ValoresContribuicaoProps> = ({
  resumoContribuicao,
  loadingValoresContribuicao,
}) => {
  return (
    <PE.Container padding={0} marginBottom={30}>
      <PE.Grid>
        <PE.Grid.Item xs={1}>
          <PE.Text variant="headline-05" color="primary" margin>
            Alteração do Valor de Contribuição
          </PE.Text>
          <PE.Text variant="body02-sm">
            <strong>
              Confira como está o valor de contribuição para Sobrevivência, em
              cada um dos fundos de investimento.
            </strong>
          </PE.Text>
          <PE.Divider />
        </PE.Grid.Item>
      </PE.Grid>

      <RenderConditional condition={loadingValoresContribuicao}>
        <SkeletonLoading blocks={1} />
      </RenderConditional>

      <RenderConditional condition={!loadingValoresContribuicao}>
        <PE.Grid>
          <PE.Grid.Item xs={1}>
            <PE.Text variant="body02-sm" marginBottom={10}>
              O valor total de contribuição atual é de:{' '}
              <strong>
                {tryGetMonetaryValueOrDefault(
                  resumoContribuicao.valorTotalContribuicoesAtivas,
                )}{' '}
              </strong>
              distribuídos da seguinte forma:
            </PE.Text>

            <PE.Text variant="body02-sm">
              Valor total de <strong>sobrevivência</strong>:{' '}
              <strong>
                {tryGetMonetaryValueOrDefault(
                  resumoContribuicao.valorTotalSobrevivenciasAtivas,
                )}
                .
              </strong>
            </PE.Text>

            <PE.Text variant="body02-sm">
              Valor total de <strong>proteção</strong>:{' '}
              <strong>
                {tryGetMonetaryValueOrDefault(
                  resumoContribuicao.valorTotalProtecoesAtivas,
                )}
                .
              </strong>
            </PE.Text>

            <RenderConditional
              condition={resumoContribuicao.listaProtecoesAtivas.length > 1}
            >
              {resumoContribuicao.listaProtecoesAtivas.map(protecao => (
                <PE.Text
                  variant="body02-sm"
                  paddingLeft="10"
                  key={protecao.incomeDescription}
                >
                  - Valor da contribuição de{' '}
                  <strong>{protecao.incomeDescription}</strong>:{' '}
                  <strong>
                    {tryGetMonetaryValueOrDefault(
                      protecao.initialContributionValue,
                    )}
                    .
                  </strong>
                </PE.Text>
              ))}
            </RenderConditional>
          </PE.Grid.Item>
        </PE.Grid>
      </RenderConditional>
    </PE.Container>
  );
};

export default ValoresContribuicao;
