// @ts-nocheck
import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseFundosPorCertificado } from '../types/TiposTransferenciaFundoInvestimento.type';
import * as FundosPorCertificadoApi from '../services/fundosPorCertificado.api';

const useObterFundosPorCertificado = () => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<
    ResponseFundosPorCertificado | undefined
  >();
  const buscarFundosPorCertificado = async () => {
    try {
      setLoading(true);
      const result = await FundosPorCertificadoApi.fundosPorCertificado(
        cpfCnpj,
        numCertificado,
      );

      setResponse(result);
    } catch (requestError: any) {
      toastError();
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    response,
    buscarFundosPorCertificado,
  };
};

export default useObterFundosPorCertificado;
