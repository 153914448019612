// @ts-nocheck
import { ApexOptions } from 'apexcharts';

import { formatarData, formatarDataMesAno } from 'main/utils';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as CONSTS from 'evolucaoPatrimonial/constants/constants';
import * as EVOLUCAO_PATRIMONIAL_TYPES from 'evolucaoPatrimonial/types/EvolucaoPatrimonial';

export const chartDataFactory = ({
  formik,
  dadosConsulta,
}: EVOLUCAO_PATRIMONIAL_TYPES.IChartDataFactoryParams): EVOLUCAO_PATRIMONIAL_TYPES.IChartDataFactoryRetorno => {
  let options;
  let series;
  let nameSeries;

  if (formik.values.tipoEvolucao === CONSTS.FILTRO_EVOLUCAO.DIARIA) {
    options = dadosConsulta?.evolucaoDiaria.map(item => item.dataReferencia);

    if (formik.values.tipoModalidade === CONSTS.FILTRO_MODALIDADE.PATRIMONIO) {
      series = dadosConsulta?.evolucaoDiaria.map(item =>
        Number(item.valorSaldoTotal),
      );
      nameSeries = CONSTS.FILTRO_MODALIDADE.LABEL_PATRIMONIO;
    } else {
      series = dadosConsulta?.evolucaoDiaria.map(item =>
        Number(item.valorRentabilidade),
      );
      nameSeries = CONSTS.FILTRO_MODALIDADE.LABEL_RENTABILIDADE;
    }
  }

  if (formik.values.tipoEvolucao === CONSTS.FILTRO_EVOLUCAO.MENSAL) {
    options = dadosConsulta?.evolucaoMensal.map(item => item.dataReferencia);

    if (formik.values.tipoModalidade === CONSTS.FILTRO_MODALIDADE.PATRIMONIO) {
      series = dadosConsulta?.evolucaoMensal.map(item =>
        Number(item.valorSaldoTotal),
      );
      nameSeries = CONSTS.FILTRO_MODALIDADE.LABEL_PATRIMONIO;
    } else {
      series = dadosConsulta?.evolucaoMensal.map(item =>
        Number(item.valorRentabilidade),
      );
      nameSeries = CONSTS.FILTRO_MODALIDADE.LABEL_RENTABILIDADE;
    }
  }

  return {
    options,
    series,
    nameSeries,
  };
};

export const seriesChartDataFactory = ({
  chartData,
}: EVOLUCAO_PATRIMONIAL_TYPES.ISeriesChartDataFactoryParams): ApexOptions['series'] => [
  {
    name: chartData.nameSeries,
    data: chartData.series as number[],
  },
];

export const optionsChartDataFactory = ({
  formik,
  chartData,
}: EVOLUCAO_PATRIMONIAL_TYPES.IOptionsChartDataFactoryParams): ApexOptions => ({
  colors: ['#005FC8'],
  noData: {
    text: CONSTS.MENSAGEM_NO_DATA,
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
  },
  chart: {
    toolbar: {
      tools: {
        download: false,
        pan: false,
        reset: true,
        zoom: false,
      },
    },
    height: 500,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
    width: 2,
  },
  yaxis: {
    labels: {
      formatter(value) {
        return tryGetMonetaryValueOrDefault(value);
      },
    },
  },
  xaxis: {
    categories: chartData.options,
    labels: {
      formatter(value) {
        if (formik.values.tipoEvolucao === CONSTS.FILTRO_EVOLUCAO.DIARIA) {
          return formatarData(value);
        }

        return formatarDataMesAno(value);
      },
    },
  },
});
