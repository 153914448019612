// @ts-nocheck
import styled from 'styled-components/macro';
import { Text } from '@cvp/design-system/react';

export const ObsDestacadaFundo = styled(Text)(({ theme }) => ({
  background: theme.color.feedback.error,
  borderRadius: '5px',
  padding: '5px',
  marginBottom: '5px',
}));
