// @ts-nocheck
import { Display, Radio } from '@cvp/design-system/react';

import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const SelecionarManterBeneficioProtecao = ({
  manterBeneficioProtecaoContexto,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ISelecionarManterBeneficioProtecaoProps) => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();
  return (
    <Display>
      <Display alignItems="center" style={{ margin: 0 }}>
        <Radio
          name="manterTransferencia"
          onClick={() => {
            manterBeneficioProtecaoContexto(CONSTS.MANTER_BENEFICIO.SIM);
          }}
          checked={featureData?.manterBeneficio === CONSTS.MANTER_BENEFICIO.SIM}
          value={CONSTS.MANTER_BENEFICIO.SIM}
        />
        Manter
      </Display>

      <Display alignItems="center" style={{ margin: 0 }}>
        <Radio
          name="manterTransferencia"
          onClick={() => {
            manterBeneficioProtecaoContexto(CONSTS.MANTER_BENEFICIO.NAO);
          }}
          checked={featureData?.manterBeneficio === CONSTS.MANTER_BENEFICIO.NAO}
          value={CONSTS.MANTER_BENEFICIO.NAO}
        />
        Cancelar
      </Display>
    </Display>
  );
};

export default SelecionarManterBeneficioProtecao;
