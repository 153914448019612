// @ts-nocheck
import { useState } from 'react';
import { FormikProps, useFormik } from 'formik';

import { tryGetValueOrDefault } from 'main/utils/conditional';
import { usePecoReenviarLinkDps } from 'contratosPrestamista/features/dpsEletronicaPostecipado/hooks/usePecoReenviarLinkDps';
import { formatarData, formatarDataAdicionandoSeparador } from 'main/utils';
import { INITIAL_STATE_MODAL_RETORNO_ACAO_DPS } from 'contratosPrestamista/features/dpsEletronicaPostecipado/constants/MapeamentoStatus';
import { InitialStateModalRetornoAcaoDps } from 'contratosPrestamista/features/dpsEletronicaPostecipado/types/InitialStateModalRetornoAcaoDps';
import { usePecoRecuperarListaDps } from 'contratosPrestamista/features/reenvioDpsEletronica/hooks/usePecoReenvioDpsEletronica';
import * as RESPONSE_TYPES from 'contratosPrestamista/features/reenvioDpsEletronica/types/ConsultaDpsResponse';
import * as FACTORY from 'contratosPrestamista/features/reenvioDpsEletronica/factories/ConsultarDpsFactory';
import * as UTILS from 'contratosPrestamista/features/reenvioDpsEletronica/utils/ConsultarDps';
import * as CONSTS from 'contratosPrestamista/features/reenvioDpsEletronica/constants/constants';
import * as CONSULTAR_DPS_TYPES from 'contratosPrestamista/features/reenvioDpsEletronica/types/ConsultarDps';

const useConsultarDps = () => {
  const serviceReenviarLinkDps = usePecoReenviarLinkDps();
  const serviceListaDps = usePecoRecuperarListaDps();

  const [modalAcaoDps, setModalAcaoDps] = useState(false);

  const [modalRetornoAcaoDps, setModalRetornoAcaoDps] =
    useState<InitialStateModalRetornoAcaoDps>(
      INITIAL_STATE_MODAL_RETORNO_ACAO_DPS,
    );

  const formik: FormikProps<CONSULTAR_DPS_TYPES.IFormikValuesAtualizacaoDadosCliente> =
    useFormik<CONSULTAR_DPS_TYPES.IFormikValuesAtualizacaoDadosCliente>({
      enableReinitialize: true,
      initialValues: CONSTS.INITIAL_FORMIK_STATE,
      validationSchema: FACTORY.FORMIK_VALIDATION,
      validateOnMount: true,
      onSubmit: () => undefined,
    });

  const handleModalAcaoDps = (
    item: RESPONSE_TYPES.IResponseRecuperarListaDpsSolicitacoesDps,
  ): void => {
    formik.setValues({
      ...formik.values,
      nome: item.dadoCadastralCliente.nome,
      cpf: item.dadoCadastralCliente.cpf,
      telefone: item.dadoCadastralCliente.telefone,
      email: item.dadoCadastralCliente.email,
      dataNascimento: formatarData(item.dadoCadastralCliente.dataNascimento),
      numeroProposta: UTILS.selecionarProposta(
        item.propostasVinculadas,
        item.codigoIdentificadorDps,
      ),
    });

    setModalAcaoDps(prev => !prev);
  };

  const colunas = CONSTS.COLUNAS({ handleModalAcaoDps }) as [];

  const handleSubmitReenviarLink = async () => {
    const retornoServiceReenviarLinkDps =
      await serviceReenviarLinkDps.fetchDataReenviarLink({
        cpf: formik.values.cpf,
        numeroProposta: formik.values.numeroProposta,
        email: formik.values.email,
        sexo: formik.values.sexo,
        telefone: formik.values.telefone,
        dataNascimento: formatarDataAdicionandoSeparador(
          formik.values.dataNascimento,
        ),
      });

    setModalAcaoDps(prevState => !prevState);

    setModalRetornoAcaoDps(prevState => ({
      ...prevState,
      isOpen: !prevState.isOpen,
      message: tryGetValueOrDefault(
        [retornoServiceReenviarLinkDps?.mensagens?.[0]?.descricao],
        '',
      ),
    }));
  };

  const handleSubmitRetornoAcaoDps = (): void => {
    setModalRetornoAcaoDps(INITIAL_STATE_MODAL_RETORNO_ACAO_DPS);
  };

  return {
    serviceListaDps,
    serviceReenviarLinkDps,
    colunas,
    modalAcaoDps,
    setModalAcaoDps,
    formik,
    handleSubmitReenviarLink,
    handleSubmitRetornoAcaoDps,
    modalRetornoAcaoDps,
  };
};

export default useConsultarDps;
