// @ts-nocheck
import { useEffect, useState } from 'react';

import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { usePecoSimularTransferenciaRiscoPrevidencia } from 'previdencia/hooks/Transferencia/usePecoSimularTransferenciaRiscoPrevidencia';
import * as UTILS from 'previdencia/features/TransferenciaEntreCertificados/utils/TransferenciaEntreCertificados';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const useBeneficioProtecao = (
  coberturasPorCertificado: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ICoberturasPorCertificado[],
) => {
  const { navigateTo } = usePrevNavigation();

  const { featureData, setFeatureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const { simularRisco, isLoadingSimulacaoRisco } =
    usePecoSimularTransferenciaRiscoPrevidencia(
      tryGetValueOrDefault([featureData?.numTransferencia], ''),
    );

  const [opcaoEscolhida, setOpcaoEscolhida] = useState(
    {} as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IOpcaoBeneficioProtecaoEscolhida,
  );
  const [simulacaoRiscoPorBeneficio, setSimulacaoRiscoPorBeneficio] = useState(
    {} as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ISimulacaoRiscoPorBeneficio,
  );

  const isDisableNextStep: boolean = checkIfSomeItemsAreTrue([
    isLoadingSimulacaoRisco,
    Object.keys(simulacaoRiscoPorBeneficio).length !==
      coberturasPorCertificado.length,
  ]);

  const handleOpcaoBeneficio = async (
    opcao: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IOpcaoBeneficioProtecao,
    tipoCobertura: string,
  ) => {
    setOpcaoEscolhida(prevState => ({
      ...prevState,
      [tipoCobertura]: {
        opcao,
        tipoCobertura,
      },
    }));

    const dadosSimulacaoRisco = await simularRisco({
      tipoSimulacao: opcao?.id,
    });

    const retornoBeneficioCalculado =
      dadosSimulacaoRisco?.entidade?.return?.lstBeneficiosCalculados.find(
        item => tipoCobertura.includes(UTILS.getFirstWord(item?.desBeneficio)),
      );

    const retornoBeneficioDestino =
      dadosSimulacaoRisco?.entidade?.return?.lstBeneficiosDestino.find(item =>
        tipoCobertura.includes(UTILS.getFirstWord(item?.desBeneficio)),
      );

    const simulacaoBeneficio = tryGetValueOrDefault(
      [retornoBeneficioDestino, retornoBeneficioCalculado],
      {} as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.TSimulacaoBeneficio,
    );

    setSimulacaoRiscoPorBeneficio(prevState => ({
      ...prevState,
      [tipoCobertura]: {
        ...simulacaoBeneficio,
      } as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.TSimulacaoBeneficio,
    }));
  };

  const redirecionarAssinatura = (): void => {
    navigateTo(
      `${CONSTS_TEXT.URL_TRANSFERENCIA.BASE}${CONSTS_TEXT.URL_TRANSFERENCIA.EFETUAR_ASSINATURA}`,
    );
  };

  const definirProximaTela = (): void => {
    setFeatureData({
      ...featureData,
      simulacaoRiscoPorBeneficio: tryGetValueOrDefault(
        [simulacaoRiscoPorBeneficio],
        {} as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ISimulacaoRiscoPorBeneficio,
      ),
    });

    redirecionarAssinatura();
  };

  const salvarEtapasContexto = (): void => {
    setFeatureData({
      ...featureData,
      step: CONSTS_TEXT.NUM_ETAPAS.BENEFICIO_PROTECAO,
    });
  };

  useEffect(salvarEtapasContexto, []);

  return {
    isLoadingSimulacaoRisco,
    isDisableNextStep,
    simulacaoRiscoPorBeneficio,
    opcaoEscolhida,
    handleOpcaoBeneficio,
    definirProximaTela,
  };
};

export default useBeneficioProtecao;
