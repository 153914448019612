// @ts-nocheck
export enum TipoPessoaEnum {
  fisica = 'F',
  juridica = 'J',
}

export enum TipoPortalEconomiario {
  fisica = 'N',
  juridica = 'S',
}
