// @ts-nocheck
import masks from 'main/utils/masks';
import { MAPPED_FIELDS } from 'main/features/prospeccao/constants/formProspeccao';
import { Opcao, Opcoes } from 'main/features/prospeccao/types/IFormProspeccao';
import * as Validators from './validators';

export const shouldToRenderAlfanumericoInput = (
  inputToRender: Opcao,
): boolean => {
  switch (inputToRender?.tipo) {
    case 'ddiPhone':
    case 'dddPhone':
    case 'texto':
    case 'upload':
    case 'lista':
    case 'label':
    case 'paragrafo':
      return false;
    default:
      return true;
  }
};

export const getGridLayout = (layout: string[]): number => {
  if (layout?.length > 0) {
    const [first, second] = layout;
    return Number(first) / Number(second);
  }
  return 1 / 3;
};

export const unmaskCurrency = (value?: string): number => {
  if (value) {
    const unmaskedValue = masks.currency.unmask(value);
    return parseFloat(unmaskedValue) / 100;
  }
  return 0;
};

export const validateField = (values: Opcoes[], value: string) => {
  const item = values.find(x => x.id === value);

  if (item?.obrigatorio && !item.resposta) return 'Campo obrigatório.';

  if (item?.tipo && Validators.validators[item.tipo]) {
    const unmaskedValue = masks[item.tipo].unmask(item.resposta ?? '');
    const inputIsValid = Validators.validators[item.tipo](unmaskedValue);
    return inputIsValid ? undefined : 'Campo inválido';
  }
  return undefined;
};

export const getFieldErrorMessage = (
  touched: boolean,
  error?: string,
): string => {
  if (touched && error) return error;
  return '';
};

export const getMask = (item: Opcoes) => {
  if (item.tipo && masks[item.tipo]) {
    return masks[item.tipo].mask(item.resposta);
  }
  if (item.atributosView && item.atributosView?.mask) {
    return masks[item.atributosView.mask].mask(item.resposta);
  }
  return item.resposta;
};

export const removeMask = (value?: string, item?: Opcoes): string => {
  if (value && item && item.tipo && masks[item.tipo]) {
    if (
      item.id === MAPPED_FIELDS.importanciaSegurada ||
      item.id === MAPPED_FIELDS.valorProspeccao
    ) {
      const unmasked = parseFloat(masks[item.tipo].unmask(value)) / 100;
      return unmasked.toString();
    }
    return masks[item.tipo].unmask(value);
  }
  return value?.toString() ?? '';
};
