// @ts-nocheck
export const MODALIDADES = {
  PGBL: {
    info: 'As contribuições podem ser deduzidas da base de cálculo do IR (até 12% da renda bruta anual) e a tributação no momento do resgate será sobre o saldo total.',
    cor: '#005FC814',
  },
  VGBL: {
    info: 'Não há possibilidade de utilizar as contribuições para dedução da base de cálculo do IR e a tributação no momento do resgate será apenas sobre os rendimentos',
    cor: '#005fc826',
  },
  FGB: {
    info: 'Plano Tradicional - As contribuições podem ser deduzidas da base de cálculo do IR (até 12% da renda bruta anual) e a tributação no momento do resgate será sobre o saldo total.',
    cor: '#005fc838',
  },
};
