// @ts-nocheck
import React from 'react';
import { Divider, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TableSemHeader } from 'seguros/components/TabelaApoliceCoberturas/styles';
import { TabelaApoliceCarenciasProps } from 'seguros/types/ApoliseCobertura';
import { ICarencia } from 'seguros/types/IResponseCertificadosResumo';
import * as CONSTS from 'seguros/constants/TabelaApoliceCarencias';
import * as S from 'seguros/components/TabelasApoliceImpressao/components/styles';

export const TabelaApoliceCarenciasImpressao: React.FC<
  TabelaApoliceCarenciasProps<ICarencia>
> = ({ data, columns }) => {
  return (
    <RenderConditional condition={!!data.length}>
      <Text variant="body02-md" color="primary" margin>
        Carências
      </Text>
      <S.WrapperTableContainer>
        <TableSemHeader
          noHeader
          responsive
          columns={tryGetValueOrDefault(
            [columns],
            CONSTS.COLUNAS_TABELA_CARENCIAS,
          )}
          data={data}
          noDataComponent={CONSTS.SEM_DADOS_CARENCIAS}
        />
        <Divider />
      </S.WrapperTableContainer>
    </RenderConditional>
  );
};
