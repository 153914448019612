// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';

import {
  IResponseCancelamentoResgate,
  IRequestCancelamentoResgate,
} from 'previdencia/features/CancelamentoSolicitacaoResgate/types/ResponseCancelamentoSolicitacaoResgate';

export const useCancelamentoSolicitacaoResgate = (numResgate?: string) => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IRequestCancelamentoResgate,
    IResponseCancelamentoResgate[]
  >({
    api: { operationPath: PECOS.CancelarResgate },
    autoFetch: false,
    payload: {
      cpfCnpj,
      numeroResgate: numResgate,
    },
  });

  return { loading, response, fetchData };
};

export default useCancelamentoSolicitacaoResgate;
