// @ts-nocheck
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { ICoberturas } from 'prestamista/features/dadosSeguro/types/ICoberturas';
import { ResponseObterDadosCertificadoPrestamista } from 'prestamista/types/ICertifucatiPrestamista';

export const dadosCoberturasFactory = (
  retornoCertificadoPrestamista:
    | ResponseObterDadosCertificadoPrestamista[]
    | undefined,
): ICoberturas[] => {
  const vazio = '-';
  if (
    !retornoCertificadoPrestamista ||
    retornoCertificadoPrestamista.length < 1
  )
    return [];

  const item = retornoCertificadoPrestamista[0];

  return [
    {
      descricao: tryGetValueOrDefault(
        [item?.premioEndosso?.descricaoCobertura],
        vazio,
      ),
      valor: tryGetMonetaryValueOrDefault(item?.premioEndosso?.valorIs, vazio),
    },
  ];
};
