// @ts-nocheck
import { Modal as ModalDS } from '@cvp/design-system/react';
import React from 'react';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({ open, onClose, children }) => {
  return (
    <ModalDS show={open} onClose={onClose}>
      {children}
    </ModalDS>
  );
};

export default Modal;
