// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { formatarDataHoraAmigavel } from 'main/utils';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import RenderConditional from 'main/components/RenderConditional';
import * as S from 'main/styles/GlobalStyle';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { IResponseAcaoCoberturas } from '../../types/AcaoCoberturas.types';
import { Container } from '../styles';
import { EnumStatusAtivacaosuspensao } from '../../types/EnumsStatusAtivacaoSuspensao.types';

type ListaComprovanteCuidadoExtraProps = {
  dadosComprovante: IResponseAcaoCoberturas | undefined;
  status: string;
};

const ListaComprovanteCuidadoExtra: React.FC<
  ListaComprovanteCuidadoExtraProps
> = ({ dadosComprovante, status }) => {
  return (
    <Container>
      <S.ContainerBranco>
        <Text variant="body02-sm">Cliente :</Text>
        <Text variant="body02-sm">
          {dadosComprovante?.nomePessoaCertificado}
        </Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">CPF :</Text>
        <Text variant="body02-sm">
          {formatarCpf(dadosComprovante?.cpfPessoaCertificado)}
        </Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Data :</Text>
        <Text variant="body02-sm">
          {formatarDataHoraAmigavel(new Date().toString())}
        </Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Certificado :</Text>
        <Text variant="body02-sm">{dadosComprovante?.contaId}</Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Tipo :</Text>
        <Text variant="body02-sm">
          {dadosComprovante?.dadosBeneficio?.descricaoBeneficio}
        </Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Valor da Contribuição :</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(dadosComprovante?.valorPagamento)}
        </Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Dia de Vencimento :</Text>
        <Text variant="body02-sm">{dadosComprovante?.diaPagamento}</Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Indenização :</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(
            dadosComprovante?.dadosBeneficio.valorBeneficioEsperado,
          )}
        </Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Status do Cuidado Extra :</Text>
        <Text variant="body02-sm">
          {status === EnumStatusAtivacaosuspensao.Ativo ? 'Ativo' : 'Cancelado'}
        </Text>
      </S.ContainerBranco>
      <RenderConditional
        condition={
          !!checkIfAllItemsAreTrue([
            !!dadosComprovante?.dadosBeneficio?.tipoCalculo,
            dadosComprovante?.dadosBeneficio?.tipoCalculo !== '',
          ])
        }
      >
        <S.ContainerCinza>
          <Text variant="body02-sm">
            Duração do Recebimento do Cuidado Extra :
          </Text>
          <Text variant="body02-sm">
            {dadosComprovante?.dadosBeneficio?.tipoCalculo}
          </Text>
        </S.ContainerCinza>
      </RenderConditional>
    </Container>
  );
};

export default ListaComprovanteCuidadoExtra;
