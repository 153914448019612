// @ts-nocheck
import * as Yup from 'yup';
import { cpf } from 'cpf-cnpj-validator';

import masks from 'main/utils/masks';

const MENSAGEM_EMAIL_INVALIDO = 'Informe um e-mail válido';
const MENSAGEM_CAMPO_OBRIGATORIO = 'Campo obrigatório';

export const EdicaoSocioValidationSchema = Yup.object().shape({
  nome: Yup.string()
    .required(MENSAGEM_CAMPO_OBRIGATORIO)
    .min(3, 'Informe um nome válido'),
  dataNascimento: Yup.string().required(MENSAGEM_CAMPO_OBRIGATORIO),
  telefone: Yup.string().required(MENSAGEM_CAMPO_OBRIGATORIO),
  email: Yup.string()
    .email(MENSAGEM_EMAIL_INVALIDO)
    .nullable()
    .test('email', 'E-mail inválido', function (value) {
      if (value === null) {
        this.createError({
          message: MENSAGEM_EMAIL_INVALIDO,
          path: 'email',
        });
        return false;
      }
      if (value === '' || value === undefined) {
        this.createError({
          message: MENSAGEM_CAMPO_OBRIGATORIO,
          path: 'email',
        });
        return false;
      }
      return true;
    }),
});

export const AdicionarSocioValidationSchema =
  EdicaoSocioValidationSchema.concat(
    Yup.object().shape({
      cpf: Yup.string()
        .required(MENSAGEM_CAMPO_OBRIGATORIO)
        .test('cpf', 'CPF inválido', value => {
          const valueUnmasked = masks.numberOnly.mask(value);
          if (valueUnmasked.length === 11) return cpf.isValid(valueUnmasked);
          return false;
        }),
    }),
  );
