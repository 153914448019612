// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';

const TextoInformativoDadosSolicitacao: React.FC = () => {
  return (
    <>
      <Text color="primary">Dados do Solicitação</Text>
      <Text variant="body01-sm" margin>
        O saldo de seu certificado permanecerá zerado até a efetivação da
        transferência, no prazo máximo de até 2 dias úteis, conforme legislação
        vigente. Durante esse período poderão ocorrer variações na cota e no
        valor transferido, a depender do tipo de fundo (renda fixa ou variável)
        e performance deste no mercado financeiro.
      </Text>
      <Text variant="body01-sm" margin>
        Caso seu certificado já possua uma parcela de cobrança futura gerada,
        quando do pagamento, esta será automaticamente direcionada para o novo
        fundo de investimento.
      </Text>
    </>
  );
};

export default TextoInformativoDadosSolicitacao;
