// @ts-nocheck
import RenderConditional from 'main/components/RenderConditional';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from './styles';

const ColunaObservacaoFundo = ({
  row,
}: SOLICITACAO_RESGATE_TYPES.IColunaObservacaoFundoProps) => {
  return (
    <RenderConditional condition={row.exibeMensagem}>
      <div>
        {row.mensagensFundo
          .filter(mensagemValida => !!mensagemValida.descricao)
          .map(mensagem => (
            <S.ObsDestacadaFundo
              id={mensagem.codigo}
              color="white"
              variant="body02-sm"
            >
              {mensagem.descricao}
            </S.ObsDestacadaFundo>
          ))}
      </div>
    </RenderConditional>
  );
};

export default ColunaObservacaoFundo;
