// @ts-nocheck
import ValidableState from '../types/ValidableState';
import ValidateResult from '../types/ValidateResult';
import ValidationFunction from '../types/ValidationFunction';

export function validateRules<T>(
  validationRules: Array<ValidationFunction<T>>,
  value: T,
): ValidableState {
  return validationRules.reduce<ValidableState>(
    (lastValidation, rule) => {
      if (!lastValidation.isValid) {
        return lastValidation;
      }
      return rule(value);
    },
    { isValid: true },
  );
}

export function validate(
  validationElements: Array<() => ValidateResult>,
): boolean {
  const result = validationElements.reduce<ValidateResult>(
    (lastValidation, validateElement) => {
      const currentValidation = validateElement && validateElement();

      if (!lastValidation.isValid) {
        return lastValidation;
      }

      return currentValidation;
    },
    { isValid: true },
  );

  if (!result.isValid && result.scrollIntoView) {
    result.scrollIntoView();
  }

  return result.isValid;
}
