// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { useContext } from 'react';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IPayloadCoberturasContratadas,
  IResponseStatusCoberturasContratadas,
} from '../types/CoberturasContratadas.types';
import { EnumCategoria } from '../types/EnumsStatusAtivacaoSuspensao.types';

const useObterCoberturasContratadas = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IPayloadCoberturasContratadas,
    IResponseStatusCoberturasContratadas
  >({
    api: { operationPath: PECOS.RecuperarContribuicoesCertificado },
    autoFetch: true,
    payload: {
      Cpf: cpfCnpj,
      NumeroCertificado: numCertificado,
      categoria: EnumCategoria.Coberturas,
      cpfCnpj,
    },
  });

  return { loading, response, fetchData };
};

export default useObterCoberturasContratadas;
