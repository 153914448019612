// @ts-nocheck
import { Display, Button, Text, Modal } from '@cvp/design-system/react';
import { IModalRetornoAcaoDpsProps } from 'contratosPrestamista/types/IModalRetornoAcaoDpsProps';

const ModalRetornoAcaoDps = ({
  openModal,
  handleClose,
  message,
}: IModalRetornoAcaoDpsProps) => {
  return (
    <Modal show={openModal} onClose={handleClose}>
      <Text variant="body02-sm" margin>
        {message}
      </Text>
      <Display justify="center">
        <Button variant="secondary" onClick={handleClose}>
          Ok
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalRetornoAcaoDps;
