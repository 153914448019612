// @ts-nocheck
import React from 'react';
import { Text, Modal, Button, Display } from '@cvp/design-system/react';
import For from 'main/components/For';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as S from './styles';
import useTransferenciaEntreCertificados from '../../hooks/useTransferenciaEntreCertificados';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { ModalAvisoCoberturasProps } from '../../types/TransferenciaEntreCertificados';

const ModalAvisoCoberturas: React.FC<ModalAvisoCoberturasProps> = ({
  openAvisoCoberturas,
  handleConfirmarCoberturas,
  handleCloseCoberturas,
}) => {
  const { coberturasRunOff } = useTransferenciaEntreCertificados();
  return (
    <Modal
      show={openAvisoCoberturas}
      onClose={handleConfirmarCoberturas}
      style={{ minWidth: 600, width: '100%' }}
    >
      <Text variant="headline-05" color="primary" margin>
        Atenção
      </Text>

      <Text color="text" margin>
        {CONSTS_TEXT.COBERTURAS_ANTIGAS.INICIO}
        <b>{CONSTS_TEXT.COBERTURAS_ANTIGAS.MEIO}</b>
        {CONSTS_TEXT.COBERTURAS_ANTIGAS.FIM}
      </Text>

      <Text variant="text-big-700" color="primary">
        {CONSTS_TEXT.COBERTURAS_NAO_COMERCIALIZADAS.TITULO}
      </Text>

      <Display justify="center" margin>
        <S.Ul>
          <For each={tryGetValueOrDefault([coberturasRunOff?.coberturas], [])}>
            {item => (
              <S.Li key={item.cobertura}>
                <Text
                  variant="text-big-400"
                  key={item.cobertura}
                  textAlign="left"
                >
                  {item.cobertura}
                </Text>
              </S.Li>
            )}
          </For>
        </S.Ul>
      </Display>

      <Display justify="space-evenly">
        <Button onClick={handleCloseCoberturas} variant="secondary">
          Não
        </Button>
        <Button onClick={handleConfirmarCoberturas} variant="primary">
          Sim
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalAvisoCoberturas;
