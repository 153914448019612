// @ts-nocheck
import { scrollScreen } from 'main/utils/screen';

export function scrollEndScreen() {
  scrollScreen(document.body.scrollHeight);
}

export function deveIrFimDaTela(value: boolean) {
  if (value) scrollEndScreen();
}
