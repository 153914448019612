// @ts-nocheck
import React, { createContext } from 'react';

interface IRouteContext {
  breadcrumbs: unknown;
}

const RouteContext = createContext<IRouteContext>({} as IRouteContext);

export default RouteContext;
