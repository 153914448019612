// @ts-nocheck
import * as CONTEXT_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblContext';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';

export const COLUNAS_TABELA_CUIDADO_EXTRA = [
  {
    name: 'Cobertura',
    selector: 'descricaoRisco',
    minWidth: '350px',
  },
  {
    name: 'Prazo (anos)',
    selector: 'temporariedade',
  },
  {
    name: 'Valor da Contribuição',
    selector: 'valorContribuicao',
  },
  {
    name: 'Valor do Benefício',
    selector: 'valorBeneficio',
    minWidth: '200px',
  },
  {
    name: 'Status',
    selector: 'status',
  },
];

export const COLUNAS_TABELA_FUNDOS_INVESTIMENTO_DESTINO = [
  {
    selector: 'check',
    wrap: true,
    center: true,
    width: '50px',
    name: '',
  },
  {
    name: 'Fundo',
    selector: 'descricaoFundo',
    center: true,
    minWidth: '140px',
  },
  {
    name: 'Perfil do Risco',
    selector: 'perfilRisco',
    center: true,
    minWidth: '140px',
  },
  {
    name: 'Aplicação ( % )',
    selector: 'aplicacao',
    center: true,
    minWidth: '140px',
  },
  {
    name: 'Valor da Reserva',
    selector: 'valorReserva',
    center: true,
    minWidth: '140px',
  },
];

export const COLUNAS_TABELA_FORMA_PAGAMENTO = [
  {
    name: '',
    selector: 'check',
    width: '100px',
    center: true,
  },
  {
    name: 'Conta',
    selector: 'conta',
    center: true,
  },
  {
    name: 'Agência',
    selector: 'agencia',
    center: true,
  },
  {
    name: 'Banco',
    selector: 'banco',
    center: true,
    minWidth: '250px',
  },
  {
    name: 'Dia de vencimento',
    selector: 'diaVencimento',
    center: true,
  },
];

export const COLUNAS_TABELA_AQUISICAO_COBERTURA = [
  {
    name: 'Cobertura',
    selector: 'cobertura',
  },
  {
    name: 'Prazo (anos)',
    selector: 'prazo',
  },
  {
    name: 'Valor da Contribuição',
    selector: 'valorContribuicao',
  },
  {
    name: 'Valor da Indenização',
    selector: 'valorIndenizacao',
  },
];

export const COLUNAS_TABELA_AQUISICAO_FUNDOS = [
  {
    name: 'Valor da Reserva',
    selector: 'valorReserva',
  },
  {
    name: 'Valor da Transferência',
    selector: 'valorTransferencia',
  },
  {
    name: 'Fundos',
    selector: 'fundos',
    minWidth: '50%',
  },
];

export const NAO_HA_DADOS_TABELA = 'Não há dados para serem exibidos.';

export const MODAL_INFO_ACAO_TRANSFERENCIA = {
  TITULO:
    'A Transferência se aplicará a planos individuais (PF) VGBL para planos VGBL conjugados.',
  PRIMEIRA_DESCRICAO: `A transferência de plano individual VGBL para plano VGBL conjugado implicará no cancelamento do certificado atual e não poderá ser desfeita. Reforçamos que a modalidade e o regime tributário continuarão como no plano de origem e caso o plano atual possua cobertura de invalidez, esta será cancelada sem a possibilidade de ser contratada posteriormente. O novo certificado não pode ser suspenso e possui como característica a comunicabilidade, o que em caso de inadimplência, garante que o valor do Cuidado Garantido seja descontado da reserva acumulada até o limite de 12 eventos.`,
  SEGUNDA_DESCRICAO:
    'Após as 12 comunicabilidades, o plano é encerrado e o valor é creditado na conta do cliente.',
};

export const VALOR_PORCENTAGEM = { MINIMO: 0, MAXIMO: 100 };

export const PRAZO_CUIDADO_GARANTIDO = [
  { id: 'prazoCuidadoGarantido1', prazo: '1', descricaoPrazo: '1 ano' },
  { id: 'prazoCuidadoGarantido2', prazo: '2', descricaoPrazo: '2 anos' },
  { id: 'prazoCuidadoGarantido3', prazo: '3', descricaoPrazo: '3 anos' },
  { id: 'prazoCuidadoGarantido4', prazo: '4', descricaoPrazo: '4 anos' },
  { id: 'prazoCuidadoGarantido5', prazo: '5', descricaoPrazo: '5 anos' },
  { id: 'prazoCuidadoGarantido6', prazo: '6', descricaoPrazo: '6 anos' },
  { id: 'prazoCuidadoGarantido7', prazo: '7', descricaoPrazo: '7 anos' },
  { id: 'prazoCuidadoGarantido8', prazo: '8', descricaoPrazo: '8 anos' },
  { id: 'prazoCuidadoGarantido9', prazo: '9', descricaoPrazo: '9 anos' },
  { id: 'prazoCuidadoGarantido10', prazo: '10', descricaoPrazo: '10 anos' },
];

export const DESCRICAO_TIPO_COBERTURA = {
  PENSAO: 'O valor da renda mensal será:',
  PECULIO: 'O valor da indenização será:',
};

export const TEXTOS_COBERTURAS = {
  TITULO: 'Incluir Cuidado Garantido',
  SUBTITULO: 'Selecione e simule a cobertura(s) que o Cliente deseja incluir:',
  TITULO_COBERTURA_PENSAO: 'Pensão por prazo certo',
  DESCRICAO_COBERTURA_PENSAO:
    'Caso o titular venha falecer, os seus beneficiários receberão uma renda mensal pelo prazo contratado.',
  TITULO_COBERTURA_PECULIO: 'Pecúlio',
  DESCRICAO_COBERTURA_PECULIO:
    'Em caso de falecimento do titular, os beneficiários receberão a indenização contratada de uma só vez.',
  AVISO_COBERTURA_OBRIGATORIA:
    '*Obrigatório selecionar pelo menos uma cobertura.',
  MODAL_DESCRICAO: `No certificado de destino, os beneficiários predefinidos serão herdeiros
    legais. Quando o certificado estiver ativo, o cliente conseguirá
    alterá-los na Área Logada ou no aplicativo Caixa Vida e Previdência.`,
};

export const TEXTOS_AQUISICAO_VGBL = {
  TITULO: 'Confirmar aquisição',
  CONSENTIMENTO: `O cliente está de acordo em transferir o saldo do seu
  certificado para um Plano VGBL CONJUGADO com benefícios de
  acumulação e cuidado garantido obrigatórios. Os pagamentos do
  novo plano não podem ser suspensos, pois em caso de
  inadimplência, o valor da contribuição do cuidado garantido
  será descontado da reserva já acumulada até o limite de 12
  eventos consecutivos. Além disso, está claro que o valor pago
  para o cuidado garantido não compõe a reserva acumulada e será
  reajustado anualmente, de acordo com o indexador do valor.`,
  AVISO_CONSENTIMENTO:
    '*É necessário sinalizar a ciência sobre as condições da aquisição.',
  TITULO_MODAL_DOCUSIGN: 'Transferência VGBL conjugado',
};

export const TEXTOS_FORMA_PAGAMENTO = {
  TITULO: 'Forma de pagamento',
  SUBTITULO: 'Débito em conta',
  DESCRICAO: 'Escolha uma das contas já cadastradas do cliente.',
  BOTAO_OCULTAR_FORMULARIO: 'Ocultar formulário',
  BOTAO_ADD_CONTA_CAIXA: 'Adicionar nova conta CAIXA',
};

export const TEXTOS_CADASTRAR_CONTA = {
  TITULO: 'Informe os dados da nova conta',
  MENSAGEM_ALERTA:
    'A conta de débito é obrigatória e esta deve ser de titularidade do participante do plano.',
  DADOS_CONTA_CAIXA: 'Banco 104 - Caixa Econômica Federal',
  OPERACAO: {
    LABEL: 'Operação',
    NAME: 'tipoOperacao',
    PLACEHOLDER: 'Selecione a operação',
    TEST_ID: 'selectOperacao',
  },
  AGENCIA: {
    LABEL: '*Agência',
    NAME: 'agencia',
    PLACEHOLDER: 'Digite a agência',
    TEST_ID: 'inputAgencia',
  },
  CONTA: {
    LABEL: '*Conta',
    NAME: 'conta',
    PLACEHOLDER: 'Digite a conta',
    TEST_ID: 'inputConta',
  },
  DIGITO: {
    LABEL: '*Dígito',
    NAME: 'digito',
    PLACEHOLDER: 'Digite o dígito',
    TEST_ID: 'inputDigitoConta',
  },
  DATA_DEBITO: {
    LABEL: 'Data do débito',
    NAME: 'dataDebito',
    PLACEHOLDER: 'Selecione a data do débito',
    TEST_ID: 'selectDataDebito',
  },
};

export const TEXTOS_CONTRIBUICAO_RENDA_MENSAL = {
  TITULO: 'Contribuição de Renda Mensal',
  DESCRICAO:
    'Defina a contribuição mensal que irá compor a reserva do novo plano do seu cliente.',
  INPUT: {
    SUBTITULO: 'Contribuição de Renda Mensal',
    PLACEHOLDER: 'R$ 0,00',
    LABEL: 'Valor:',
  },
};

export const TEXTOS_MODAL_CONTRIBUICAO_REGULAR = {
  TITULO: 'Contribuição Regular',
  DESCRICAO:
    'Selecione um dos fundos escolhidos anteriormente para efetuar a contribuição regular:',
};

export const VALOR_MOCK = {
  RENDA_BRUTA: 10000,
};

export const initialTransferenciaInternaDataState: CONTEXT_TYPES.TransferenciaInternaData =
  {
    etapa: ENUM_TYPES.EtapaTransferenciaInterna.PLANOS,
    dadosOrigem: {} as RESPONSE_TYPES.IDadosOrigemResponse,
    saldoTotalFundosAtuais: 0,
    dadosOfertasVgblConjugado:
      {} as RESPONSE_TYPES.IListaOfertasVgblConjugadoResponse,
    contribuicaoRendaMensal: '',
    coberturasCuidadoGarantido: {} as VGBL_TYPES.CoberturasCuidadoGarantido,
    contaCadastradaSelecionada: {} as VGBL_TYPES.ContaCadastradaSelecionada,
    numeroSolicitacao: '',
  };

export const VALIDATION_DEFAULT_MESSAGE_SCHEMA = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório.',
};

export const F5_BTN = 'F5';

export const MSG_ERRO_MAXIMO_APLICACAO = 'O valor máximo deve ser de 100%';
