// @ts-nocheck
import React from 'react';
import { Display, Text, Button, Modal } from '@cvp/design-system/react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IModalConfirmaCertificado } from 'previdencia/features/Aporte/types/Aporte';

const ConfirmaCertificado: React.FC<IModalConfirmaCertificado> = ({
  descricaoProduto,
  confirmarCertificado,
  cancelConfirmarCertificado,
  validaConfirmarCertificado,
}) => {
  return (
    <Modal show={confirmarCertificado} onClose={cancelConfirmarCertificado}>
      <Display>
        <Text variant="body01-md" style={{ margin: '30px' }}>
          Você confirma o aporte no certificado{' '}
          {tryGetValueOrDefault([descricaoProduto], '')}
        </Text>
      </Display>
      <Display justify="space-evenly">
        <Button variant="secondary" small onClick={cancelConfirmarCertificado}>
          Não, voltar
        </Button>
        <Button variant="secondary" small onClick={validaConfirmarCertificado}>
          Ok, confirmar
        </Button>
      </Display>
    </Modal>
  );
};

export default ConfirmaCertificado;
