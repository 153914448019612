// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import * as S from './styles';

const TextoInformativo: React.FC = () => {
  return (
    <S.ContainerTextoInformativo>
      <Text variant="body01-sm" color="text-light" margin>
        É obrigatório o envio da cópia do documento pessoal do titular do plano
        ou ficha de assinatura e autógrafo (FAA). <br />O envio ratifica que a
        alteração foi realizada com anuência do cliente.
      </Text>
      <Text variant="body01-sm" color="text-light" margin>
        Para finalizar o processo de alteração de beneficiário, siga as
        instruções abaixo: <br />
        Abrir ASC {'>'} Previdência {'>'} Alteração Beneficiário {'>'} Inclusão
        / Alteração <br />
        - Anexar o FOP devidamente assinado pelo cliente <br />- Anexar
        documento pessoal ou FAA atualizado do titular do plano.
      </Text>
      <Text variant="body01-sm" color="text-light" margin>
        Lembre-se, a alteração de beneficiário está disponível como autosserviço
        para os clientes via app ou área logada do site, sem necessidade de
        envio de documentos.
      </Text>
    </S.ContainerTextoInformativo>
  );
};

export default TextoInformativo;
