// @ts-nocheck
export enum EnumStatusAtivacaosuspensao {
  Ativo = 'AC',
  Suspenso = 'SU',
  Beneficio = 'B',
}

export enum EnumTipoBeneficios {
  PREV = 'PR',
  RISCO = 'RI',
}

export enum StatusCoberturas {
  CoberturaSuspensa = 'U',
  CoberturaAtiva = 'A',
}

export enum EnumCategoria {
  Coberturas = 'RE',
}

export enum EnumTipoRenda {
  Mensal = '7',
}
