// @ts-nocheck
import React from 'react';
import { Modal, Text } from '@cvp/design-system/react';
import { IModalErroProps } from '../../types/TransferenciaEntreCertificados';

const ModalErro: React.FC<IModalErroProps> = ({
  openModal,
  handleCloseModal,
  mensagem,
}) => {
  return (
    <Modal show={openModal} onClose={handleCloseModal}>
      <Text variant="headline-05" color="primary" margin>
        Atenção
      </Text>
      <Text color="text" margin>
        {mensagem}
      </Text>
    </Modal>
  );
};

export default ModalErro;
