// @ts-nocheck
import { useContext, useEffect, useState } from 'react';

import {
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { AppContext } from 'main/contexts/AppContext';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ALIQUOTA } from 'previdencia/constants/constants';
import { IListarFundosParaResgateAliquota } from 'previdencia/types/ConsultaListaFundosParaResgate';
import {
  usePecoConsultarTiposPagamento,
  usePecoListarMotivosResgate,
  usePecoRecuperarBancos,
} from 'previdencia/features/SolicitacaoResgate/hooks/usePecoSolicitacaoResgate';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as RESPONSE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateResponse';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useConfirmarValoresSimulacao = () => {
  const { navigateTo } = usePrevNavigation();

  const { cliente } = useContext(AppContext);

  const { featureData, setFeatureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const { aliquotaAtual, indicadorPermiteEditarAliquota } =
    tryGetValueOrDefault(
      [featureData?.dadosListarFundosParaResgate?.aliquota],
      {} as IListarFundosParaResgateAliquota,
    );

  const [open, setOpen] = useState(false);

  const [openModalAtencaoResgate, setOpenModalAtencaoResgate] = useState(false);

  const [opcaoRegimeTributario, setOpcaoRegimeTributario] = useState<string>(
    getTernaryResult(!indicadorPermiteEditarAliquota, aliquotaAtual, ''),
  );

  const [dadosResumoSelecionado, setDadosResumoSelecionado] = useState<
    SOLICITACAO_RESGATE_TYPES.IObterResumoAliquotaReturnFactory[]
  >([]);

  const [
    valoresDetalhadosPorTipoAliquota,
    setValoresDetalhadosPorTipoAliquota,
  ] = useState<string | undefined>('');

  const { loadingDadosRecuperarBancos, recuperarBancos } =
    usePecoRecuperarBancos();

  const { loadingDadosListarMotivosResgate, listarMotivosResgate } =
    usePecoListarMotivosResgate();

  const { loadingDadosConsultaTiposPagamento, consultarTiposPagamento } =
    usePecoConsultarTiposPagamento();

  const isLoadingEfetuarResgate = checkIfSomeItemsAreTrue([
    loadingDadosRecuperarBancos,
    loadingDadosListarMotivosResgate,
    loadingDadosConsultaTiposPagamento,
  ]);

  const resumoAliquotaProgressiva = FACTORY.obterResumoAliquotaFactory(
    featureData,
    ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
  );

  const resumoAliquotaRegressiva = FACTORY.obterResumoAliquotaFactory(
    featureData,
    ALIQUOTA.TIPO_REGIME_REGRESSIVO,
  );

  const detalhadoAliquotaProgressiva = FACTORY.obterDetalhadoAliquotaFactory(
    featureData,
    ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
  );

  const detalhadoAliquotaRegressiva = FACTORY.obterDetalhadoAliquotaFactory(
    featureData,
    ALIQUOTA.TIPO_REGIME_REGRESSIVO,
  );

  const dadosTabelaValoresDetalhadosProgressivo =
    FACTORY.obterDadosTabelaDetalheCalculoFactory(
      featureData,
      ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
    );

  const dadosTabelaValoresDetalhadosRegressivo =
    FACTORY.obterDadosTabelaDetalheCalculoFactory(
      featureData,
      ALIQUOTA.TIPO_REGIME_REGRESSIVO,
    );

  const dadosResumoAliquotaSelecionada: SOLICITACAO_RESGATE_TYPES.IDadosResumoAliquotaSelecionada =
    UTILS.obterResumoAliquotaSelecionada({
      indicadorPermiteEditarAliquota,
      aliquotaAtual,
      resumoAliquotaProgressiva,
      resumoAliquotaRegressiva,
      dadosResumoSelecionado,
      opcaoRegimeTributario,
    });

  const selecionarOpcaoRegimeTributario = (tipoAliquota: string): void => {
    setOpcaoRegimeTributario(tipoAliquota);

    setDadosResumoSelecionado(
      getTernaryResult(
        tipoAliquota === ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
        resumoAliquotaProgressiva,
        resumoAliquotaRegressiva,
      ),
    );
  };

  const obterDadosPorAliquota = (tipoAliquota?: string) => {
    const resumo = getTernaryResult(
      tipoAliquota === ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
      resumoAliquotaProgressiva,
      resumoAliquotaRegressiva,
    );

    const resumoEstendido = getTernaryResult(
      valoresDetalhadosPorTipoAliquota === ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
      detalhadoAliquotaProgressiva,
      detalhadoAliquotaRegressiva,
    );

    const tabelaHistoricoContribuicao = getTernaryResult(
      valoresDetalhadosPorTipoAliquota === ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
      dadosTabelaValoresDetalhadosProgressivo,
      dadosTabelaValoresDetalhadosRegressivo,
    );

    const isExibeTabelaAliquota = getTernaryResult(
      indicadorPermiteEditarAliquota,
      true,
      tipoAliquota === aliquotaAtual,
    );

    return {
      resumo,
      resumoEstendido,
      tabelaHistoricoContribuicao,
      isExibeTabelaAliquota,
    };
  };

  const isDesabilitadoBotaoRealizarResgate =
    UTILS.desabilitarBotaoRealizarResgate({
      indicadorPermiteEditarAliquota,
      dadosResumoSelecionado,
      opcaoRegimeTributario,
    });

  const objetoEmail: SOLICITACAO_RESGATE_TYPES.IObjetoEmailSolicitacaoResgate =
    {
      tipoEmail: tipoEmailConstants.SIMULACAO_RESGATE,
      parametrosEnvio: {
        numeroResgate: UTILS.obterNumeroResgateConsolidado(
          featureData?.dadosEtapaSelecaoAliquota,
          valoresDetalhadosPorTipoAliquota,
        ),
        numeroCertificado: cliente.numCertificado,
      },
    };

  const handleCloseModalValoresDetalhados = (tipoAliquota?: string): void => {
    setOpen(state => !state);
    setValoresDetalhadosPorTipoAliquota(tipoAliquota);
  };

  const handleCloseModalAtencaoResgate = (): void => {
    setOpenModalAtencaoResgate(state => !state);
  };

  const efetuarResgate = async (): Promise<void> => {
    const numeroResgateConsolidado = UTILS.obterNumeroResgateConsolidado(
      featureData?.dadosEtapaSelecaoAliquota,
      dadosResumoAliquotaSelecionada.tipoAliquota,
    );

    const dadosRecuperarBancos = await recuperarBancos();

    const listaBancosConsolidada: RESPONSE_TYPES.IRecuperarBancosResponse[] =
      FACTORY.obterBancosCorporativoXPrevFactory(
        dadosRecuperarBancos?.entidade,
      );

    const dadosListarMotivosResgate = await listarMotivosResgate();

    const dadosConsultaTiposPagamento = await consultarTiposPagamento({
      numeroResgate: numeroResgateConsolidado,
    });

    setFeatureData({
      ...featureData,
      dadosRecuperarBancos: listaBancosConsolidada,
      dadosListarMotivosResgate: dadosListarMotivosResgate?.entidade,
      dadosConsultaTiposPagamento: dadosConsultaTiposPagamento?.entidade,
      numeroResgateConsolidado,
    });

    navigateTo('efetuar-resgate');
  };

  const salvarResumoAliquotaContexto = (): void => {
    if (opcaoRegimeTributario) {
      setFeatureData({
        ...featureData,
        resumoAliquota: dadosResumoAliquotaSelecionada,
      });
    }
  };

  useEffect(salvarResumoAliquotaContexto, [opcaoRegimeTributario]);

  return {
    objetoEmail,
    opcaoRegimeTributario,
    openModalAtencaoResgate,
    open,
    isLoadingEfetuarResgate,
    isDesabilitadoBotaoRealizarResgate,
    obterDadosPorAliquota,
    selecionarOpcaoRegimeTributario,
    handleCloseModalValoresDetalhados,
    handleCloseModalAtencaoResgate,
    efetuarResgate,
  };
};

export default useConfirmarValoresSimulacao;
