// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import usePecoConsultarInadimplencia from 'painelInadimplencia/hooks/usePecoConsultarInadimplencia';
import { useAuth } from 'main/features/Auth/hooks';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import {
  FormaPagamentoFilterPREV,
  FormaPagamentoFilterVIDA,
  PREV,
} from 'painelInadimplencia/constants/constants';
import { IDadosInadimplenciaPayload as payloadRequest } from 'painelInadimplencia/types/ConsultarInadimplenciaRequest';
import useSegmento from 'painelInadimplencia/hooks/useSegmento';

const useRelatorioInadimplencia = () => {
  const { obterSegmentoRota } = useSegmento();
  const { user } = useAuth();
  const [tipoVisao, setTipoVisao] = useState<string>('');
  const [unidade, setUnidade] = useState<number>();
  const [step, setStep] = useState([user?.agenciaVinculada]);
  const [stepSegmento, setStepSegmento] = useState([obterSegmentoRota()]);
  const ultimoCodRef = useRef('');
  const ultimoSegmentoRef = useRef('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [payloadConsulta, setPayloadConsulta] = useState<payloadRequest>({
    Codigo: '',
    Segmento: obterSegmentoRota(),
    PeriodicidadePagamento: '',
    FormaPagamento: '',
  });

  const filterFormaPagamento = () => {
    if (payloadConsulta?.Segmento === PREV) return FormaPagamentoFilterPREV;

    return FormaPagamentoFilterVIDA;
  };

  const {
    dadosInadimplencia,
    loadingDadosInadimplencia: loading,
    fetchData,
  } = usePecoConsultarInadimplencia(payloadConsulta);

  const handleConsulta = (cod?: string) => {
    if (cod) {
      if (
        checkIfAllItemsAreTrue([
          !!ultimoCodRef.current,
          !!ultimoSegmentoRef.current,
        ])
      ) {
        setStep([...step, ultimoCodRef.current]);
        setStepSegmento([...stepSegmento, ultimoSegmentoRef.current]);
      }

      setIsDisabled(false);

      ultimoCodRef.current = cod;
      ultimoSegmentoRef.current = payloadConsulta?.Segmento;

      setPayloadConsulta({ ...payloadConsulta, Codigo: cod });
    } else {
      setPayloadConsulta({ ...payloadConsulta, Codigo: unidade });
    }
  };

  const clearFilters = () => {
    setPayloadConsulta((prevState: payloadRequest) => ({
      ...prevState,
      PeriodicidadePagamento: '',
      FormaPagamento: '',
    }));
  };

  const handleBackStep = () => {
    const ultimoCodigo = step[step.length - 1];
    const ultimoSegmento = stepSegmento[stepSegmento.length - 1];

    clearFilters();

    if (checkIfAllItemsAreTrue([step.length > 0, stepSegmento.length > 0])) {
      setStep(step.slice(0, -1));
      setStepSegmento(stepSegmento.slice(0, -1));
      ultimoCodRef.current = '';
      ultimoSegmentoRef.current = '';
      setPayloadConsulta({ Segmento: ultimoSegmento, Codigo: ultimoCodigo });

      if (
        checkIfAllItemsAreTrue([
          ultimoSegmento === obterSegmentoRota(),
          ultimoCodigo === user?.agenciaVinculada,
        ])
      ) {
        setIsDisabled(true);
      }
    } else {
      setStep([]);
      setStepSegmento([]);
      setPayloadConsulta({
        Segmento: obterSegmentoRota(),
        Codigo: user?.agenciaVinculada,
      });
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    if (dadosInadimplencia?.entidade?.tipoHierarquia)
      setTipoVisao(dadosInadimplencia?.entidade?.tipoHierarquia);
    if (dadosInadimplencia?.entidade?.unidadeInicial)
      setUnidade(dadosInadimplencia?.entidade?.unidadeInicial);
  }, [dadosInadimplencia]);

  useEffect(() => {
    fetchData();
  }, [payloadConsulta]);

  return {
    tipoVisao,
    unidade,
    setPayloadConsulta,
    payloadConsulta,
    filterFormaPagamento,
    loading,
    handleConsulta,
    isDisabled,
    handleBackStep,
    clearFilters,
    dadosInadimplencia,
  };
};

export default useRelatorioInadimplencia;
