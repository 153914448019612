// @ts-nocheck
import React from 'react';
import { Display, Text, TextField } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';

type InputGridItemProps = {
  name: string;
  icon?: string;
  placeholder?: string;
  label?: string;
  value: string;
  disabled?: boolean;
  error?: string;
  handleChange: (e: React.ChangeEvent<unknown>) => void;
};

const InputGridItemInline: React.FC<InputGridItemProps> = ({
  name,
  icon,
  placeholder,
  label,
  value,
  disabled,
  error,
  handleChange,
}: InputGridItemProps) => (
  <Display style={{ margin: 0 }}>
    <RenderConditional condition={!!icon}>
      <Icon name={icon} />
    </RenderConditional>
    <div>
      <RenderConditional condition={!!label}>
        <Text variant="caption-02" color="text-light">
          {label}
        </Text>
      </RenderConditional>

      <TextField
        name={name}
        data-testid={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        error={!!error}
        errorMessage={error}
        onChange={handleChange}
      />
    </div>
  </Display>
);

export default InputGridItemInline;
