// @ts-nocheck
import React from 'react';
import { Display, Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';

type InfoGridItemProps = {
  icon: string;
  label: string;
  value: string | React.ReactElement | null;
  width?: string;
};

export const InfoGridItemInline: React.FC<InfoGridItemProps> = ({
  icon,
  label,
  value,
  width,
}: InfoGridItemProps) => (
  <Display style={{ margin: 0, minWidth: width }}>
    <Icon name={icon} />
    <div>
      <Text variant="caption-02" color="text-light">
        {label}
      </Text>
      <Text
        variant="caption-02"
        color="text"
        title={value}
        data-testid={`info-grid-${label}`}
      >
        {value}
      </Text>
    </div>
  </Display>
);
