// @ts-nocheck
import { dateWithoutTime } from 'main/utils';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
export const statusAtualPermiteReversao = (dataLimiteReversao: string) => {
  return (
    dateWithoutTime(dataLimiteReversao) >=
    dateWithoutTime(new Date().toISOString())
  );
};
