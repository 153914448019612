// @ts-nocheck
export const colunas = [
  {
    name: 'Resumo',
    selector: 'resumo',
    sortable: false,
  },
  {
    name: 'Valor',
    selector: 'valor',
    sortable: false,
  },
];
