// @ts-nocheck
import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'main/components/Route';
import StatusSinistro from 'sinistro/features/statusSinistro/pages/StatusSinistro';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';

export const routes: IPrivateRoute[] = [
  {
    path: '/sinistro/status',
    component: StatusSinistro,
    key: 'status-sinistro',
    authenticated: true,
    breadcrumb: 'Status de Sinistro',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
];

export const sinistroBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/sinistro': null,
};
const RotasSinistro = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);

export default RotasSinistro;
