// @ts-nocheck
import styled from 'styled-components/macro';
import {
  IconButton as IconButtonBase,
  Disclaimer as DisclaimerBase,
  Card,
} from '@cvp/design-system/react';
import { Table } from 'main/components/Table';

export const TableWithColNoPadding = styled(Table)(
  ({ theme: { color, font } }) => ({
    '.rdt_TableCol': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
);

export const IconButton = styled(IconButtonBase)({
  margin: '3px 0',
});

export const ActionsButton = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

export const Content = styled.div({
  padding: '1rem 0 3rem 0',
});

export const Disclaimer = styled(DisclaimerBase)({
  padding: '12px 0 12px 16px',
  button: {
    opacity: 0,
  },
  svg: {
    width: '22px',
    height: '22px',
  },
});

export const CardContent = styled(Card)<{ hasError: boolean }>(
  ({ theme: { color }, hasError }) => ({
    border: hasError ? `1px solid ${color.feedback.error}` : '',
    padding: '36px 24px',
  }),
);

export const Text = styled.div<{ hasError: boolean }>(
  ({ theme: { color, font }, hasError }) => ({
    whiteSpace: 'nowrap',
    textTransform: 'lowercase',
    variant: 'body02-sm',
    color: hasError ? `1px solid ${color.feedback.error}` : color.neutral['02'],
    maxWidth: '80%',
  }),
);

export const TextWarning = styled.div(({ theme: { color, font } }) => ({
  fontSize: font.size.md,
  variant: 'body02-sm',
  color: color.feedback.error,
  padding: '7px',
}));
