// @ts-nocheck
import { obterEndpointEnderecoCEP } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { DadosCEP } from '../types/DadosParticipante';

export const obterDadosCEP = async (
  cpfCnpj: string,
  cep: string,
): Promise<DadosCEP | undefined> => {
  const { data } = await api.post<IApiResponse<DadosCEP | undefined>>(
    obterEndpointEnderecoCEP(),
    {
      cpfCnpj,
      cep,
    },
  );
  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
