// @ts-nocheck
import { IRelatorioConfig } from 'relatorios/features/powerbi/types/IRelatorioConfig';

export const RELATORIO_GEINF: IRelatorioConfig = {
  GROUP_ID: '8ad7a524-a128-466c-a812-dbe7b76a0adf',
  RELATORIO_ID: '51587981-06a1-46f2-9071-ed91de3b4a5f',
};

export const RELATORIO_CVP: IRelatorioConfig = {
  GROUP_ID: '4a8442b2-98a2-4193-ac15-2882cc3b980f',
  RELATORIO_ID: 'bb4cfd27-a7e4-445d-964b-f94be4246878',
};

export const RELATORIO_CANCELAMETO_PRESTAMISTA: IRelatorioConfig = {
  GROUP_ID: '1c32b870-d56e-4b0b-8486-44fb0f3fbc06',
  RELATORIO_ID: 'ca7ccad5-9b34-496f-b1a9-7778d791c3fd',
};
