// @ts-nocheck
import { PECOS_AREA_LOGADA } from 'atendimento/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { IApiResponse } from 'main/services';
import { IRequestAtualizarDadosClientes } from '../types/IDadosAtualizacaoCliente';

export const useAtualizarClienteAreaLogada = () => {
  const { loading, response, fetchData } = usePeco<
    IRequestAtualizarDadosClientes,
    IApiResponse<unknown>
  >({
    api: { operationPath: PECOS_AREA_LOGADA.AtualizarCadastro },
  });

  return {
    fetchData,
    loading,
    response,
  };
};
