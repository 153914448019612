// @ts-nocheck
import { textosAtivarSuspender } from '../constants/AtivarSuspenderContribuicaoConstants';
import { EnumTipoBeneficios } from '../types/EnumsStatusAtivacaoSuspensao.types';

export const tipoServicoLabel = (
  tipo: string,
  servicoAtivacao: boolean,
): string => {
  return tipo === EnumTipoBeneficios.RISCO
    ? (servicoAtivacao && 'ativação') || 'cancelamento'
    : (servicoAtivacao && 'ativação') || 'suspensão';
};

export const tipoServico = (tipo: string, servicoAtivacao: boolean): string => {
  return tipo === EnumTipoBeneficios.RISCO
    ? (servicoAtivacao && 'ativar') || 'cancelar'
    : (servicoAtivacao && 'ativar') || 'suspender';
};

export const checkLabel = (tipo: string, servicoAtivacao: boolean): string => {
  return tipo === EnumTipoBeneficios.RISCO
    ? `Cliente ciente e mesmo assim deseja ${tipoServico(
        tipo || '',
        servicoAtivacao,
      )} o Cuidado Extra`
    : `Cliente ciente das condições e deseja a ${tipoServico(
        tipo || '',
        servicoAtivacao,
      )} de sua contribuição.`;
};

export const textoAviso = (suspensao: boolean, tipo: string) => {
  if (suspensao === true && tipo !== EnumTipoBeneficios.RISCO) {
    return (
      <>
        {textosAtivarSuspender.suspensaoInforme}
        <br />
        {textosAtivarSuspender.confirmacaoSuspensao}
      </>
    );
  }

  if (tipo === EnumTipoBeneficios.RISCO) {
    return (
      <>
        {textosAtivarSuspender.cancelouCuidadoExtra}
        <br />
        {textosAtivarSuspender.confirmacaoCancelamento}
      </>
    );
  }

  return textosAtivarSuspender.ativacaoInforme;
};
