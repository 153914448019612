// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Text, Grid, Divider } from '@cvp/design-system/react';
import { currentDate, formatarData } from 'main/utils';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { HistoricoSolicitacoesTipo } from 'previdencia/features/HistoricoSolicitacoes/types/enum';
import InputsConsulta from 'previdencia/features/HistoricoSolicitacoes/components/InputsConsulta/index';
import TabelaMovimentacoes from 'previdencia/features/HistoricoSolicitacoes/components/TabelaMovimentacoes/index';
import useConsultarHistoricoSolicitacoes from 'previdencia/features/HistoricoSolicitacoes/hooks/useConsultarHistoricoSolicitacoes';
import { filtrarPorTipoSolicitacao } from 'previdencia/features/HistoricoSolicitacoes/utils/historicoSolicitacoes';
import {
  ResponseHistoricoSolicitacoes,
  ResponseTiposSolicitacoes,
} from 'previdencia/features/HistoricoSolicitacoes/types/HistoricoSolicitacoes';
import { AlterarPeriodoParams } from 'previdencia/features/HistoricoSolicitacoes/types/HistoricoSolicitacoesProps';
import VoltarDadosPlano from 'main/components/Button/voltarDadosPlano/VoltarDadosPlano';
import useConsultarTiposSolicitacoes from '../../hooks/useConsultarTiposSolicitacoes';
import { adicionaOpcaoTodos } from '../../factories/filtroTiposSolicitacoes';
import { addYears } from 'date-fns';

const DadosConsulta: React.FC = () => {
  const primeiraDataInicial = addYears(new Date(), -1).toISOString();
  const primeiraDataFinal = currentDate();
  const [dataInicial, setDataInicial] = useState(primeiraDataInicial);
  const [dataFinal, setDataFinal] = useState(primeiraDataFinal);
  const [filtragemTipo, setFiltragemTipo] = useState('');
  const {
    data,
    isLoading: loadingHistoricoSolicitacoes,
    refetch,
    isFetching: fetchingHistoricoSolicitacoes,
  } = useConsultarHistoricoSolicitacoes(dataInicial, dataFinal);
  const { data: dadosTiposSolicitacoes } = useConsultarTiposSolicitacoes();
  const [historicoSolicitacoes, setHistoricoSolicitacoes] = useState<
    ResponseHistoricoSolicitacoes[] | undefined
  >();
  const [todosTiposSolicitacoes, setTodosTiposSolicitacoes] = useState<
    ResponseTiposSolicitacoes[] | undefined
  >();
  useEffect(() => {
    if (dadosTiposSolicitacoes) {
      setTodosTiposSolicitacoes(adicionaOpcaoTodos(dadosTiposSolicitacoes));
    }
  }, [dadosTiposSolicitacoes]);

  useEffect(() => {
    if (dataInicial || dataFinal) {
      refetch();
    }
  }, [dataInicial, dataFinal, refetch]);

  useEffect(() => {
    if (!fetchingHistoricoSolicitacoes) {
      setHistoricoSolicitacoes(
        filtrarPorTipoSolicitacao(
          data,
          filtragemTipo === HistoricoSolicitacoesTipo.TIPO_SOLICITACAO_TODOS
            ? ''
            : filtragemTipo,
        ),
      );
    }
  }, [fetchingHistoricoSolicitacoes, filtragemTipo, data]);

  const alterarPeriodo = (params: AlterarPeriodoParams): void => {
    setFiltragemTipo(params.tipo);

    if (
      params.dataInicialModificada &&
      params.dataFinalModificada &&
      params.dataValida
    ) {
      setDataInicial(params.dataInicialModificada.toISOString());
      setDataFinal(params.dataFinalModificada.toISOString());
    }
  };

  const definirPeriodoDefaultHistorico = (params: any): void => {
    setDataInicial(params.dataInicialModificada?.toISOString());
    setDataFinal(params.dataFinalModificada?.toISOString());
  };

  if (loadingHistoricoSolicitacoes) {
    return <SkeletonLoading blocks={6} />;
  }

  return (
    <>
      <Grid.Item xs={1}>
        <Text variant="body02-sm" color="text">
          Você pode visualizar as solicitações feitas para esse certificado por
          tipo de serviço ou por período. Escolha abaixo uma das opções:
        </Text>
        <Divider />
        <InputsConsulta
          alterarPeriodo={alterarPeriodo}
          tiposSolicitacoes={todosTiposSolicitacoes}
          definirPeriodoDefaultHistorico={definirPeriodoDefaultHistorico}
        />
        <Divider />
      </Grid.Item>
      <Grid.Item xs={1}>
        <Text variant="body02-sm" color="text" margin>
          Exibindo movimentações do período de acumulação:{' '}
          <strong>{formatarData(dataInicial)}</strong> a{'  '}
          <strong>{formatarData(dataFinal)}</strong>
        </Text>
        <TabelaMovimentacoes
          dados={historicoSolicitacoes}
          loading={fetchingHistoricoSolicitacoes}
        />
      </Grid.Item>
      <Grid.Item xs={1}>
        <VoltarDadosPlano />
      </Grid.Item>
    </>
  );
};

export default DadosConsulta;
