// @ts-nocheck
import { Text, Modal, Display } from '@cvp/design-system/react';

import For from 'main/components/For';
import RenderConditional from 'main/components/RenderConditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import * as S from './styles';

const ModalValidarValoresTipoTransferencia = ({
  open,
  dadosPreenchimento,
  handleClose,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IModalValidarValoresTipoTransferenciaProps) => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const textos = CONSTS_TEXT.MODAL_VALIDAR_VALORES(
    featureData?.certificadoOrigem?.vlrMinPermancenciaFundo,
  );

  return (
    <Modal
      show={open}
      onClose={handleClose}
      style={{ minWidth: 600, width: '100%' }}
    >
      <Text variant="headline-05" color="primary" margin>
        {textos.TITULO}
      </Text>

      <RenderConditional
        condition={
          !!dadosPreenchimento?.listaFundosAcimaValorMaximoSaldo?.length
        }
      >
        <Text color="text" margin>
          {textos.TRANSFERENCIA_PARCIAL_SALDO_MINIMO}
        </Text>

        <Display justify="center">
          <S.Ul>
            <For each={dadosPreenchimento.listaFundosAcimaValorMaximoSaldo}>
              {item => (
                <S.Li key={item.codFundo}>
                  <Text
                    variant="text-big-400"
                    key={item}
                    color="primary"
                    textAlign="left"
                  >
                    {item.descFundo}
                  </Text>
                </S.Li>
              )}
            </For>
          </S.Ul>
        </Display>
      </RenderConditional>

      <RenderConditional
        condition={
          !!dadosPreenchimento?.listaFundosNaoRespeitamValorMinimo?.length
        }
      >
        <Text color="text" margin>
          {textos.VALOR_MINIMO_TRANSFERENCIA}
        </Text>

        <Display justify="center">
          <S.Ul>
            <For each={dadosPreenchimento.listaFundosNaoRespeitamValorMinimo}>
              {item => (
                <S.Li key={item.codFundo}>
                  <Text
                    color="primary"
                    key={item}
                    textAlign="left"
                    variant="text-big-400"
                  >
                    {item.descFundo}
                  </Text>
                </S.Li>
              )}
            </For>
          </S.Ul>
        </Display>
      </RenderConditional>
    </Modal>
  );
};

export default ModalValidarValoresTipoTransferencia;
