// @ts-nocheck
import styled from 'styled-components/macro';
import { Display } from '@cvp/design-system/react';

export const ContainerIconInfo = styled(Display)(() => ({
  alignItems: 'flex-start',
  marginLeft: '10px',

  '& > div': {
    display: 'block',
  },

  '& h6, & h5, & h4, & h3, & h2, & h1, & p': {
    margin: '0 3px 3px 3px',
  },
}));
