// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Display, Modal, Button, Text, Radio } from '@cvp/design-system/react';
import { TextArea } from 'main/components/form';

type ModalFinalizarAvaliacaoProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (justificativa: string, situacao: number) => Promise<void>;
  title?: string;
  loading: boolean;
  approveRejectIds: {
    approve: number;
    reject: number;
  };
};
export const ModalFinalizarAvaliacao: React.FC<
  ModalFinalizarAvaliacaoProps
> = ({
  open,
  handleClose,
  handleConfirm,
  title,
  loading,
  approveRejectIds,
}) => {
  const [situacao, setSituacao] = useState<number>(0);
  const [motivo, setMotivo] = useState<string>('');

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Modal show={open} onClose={() => handleClose()}>
      <Text variant="headline-04" margin>
        {title ?? ' Parecer médico:'}
      </Text>
      {loading && <Button variant="text" loading />}
      {!loading && (
        <>
          <Display justify="center" alignItems="center">
            <Text variant="body-medium3">Favorável:</Text>
            <Display justify="center">
              <Display alignItems="center">
                <Radio
                  name="situacao"
                  checked={situacao === approveRejectIds.approve}
                  onChange={() => setSituacao(approveRejectIds.approve)}
                />
                Sim
              </Display>
              <Display alignItems="center">
                <Radio
                  name="situacao"
                  onChange={() => setSituacao(approveRejectIds.reject)}
                  checked={situacao === approveRejectIds.reject}
                />
                Não
              </Display>
            </Display>
          </Display>

          <Text variant="body-medium3" align="left">
            Motivo:
          </Text>
          <TextArea
            spellCheck
            value={motivo}
            onChange={({
              target: { value },
            }: React.ChangeEvent<{ value: string }>) => setMotivo(value)}
          />

          <Display justify="center" style={{ marginTop: 20 }}>
            <Button
              variant="secondary"
              onClick={handleClose}
              data-testid="cancelar"
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={async () => handleConfirm(motivo, situacao)}
              data-testid="confirmar"
              disabled={!situacao}
            >
              Confirmar
            </Button>
          </Display>
        </>
      )}
    </Modal>
  );
};
