// @ts-nocheck
import { Table as TableDS } from 'main/components/Table';
import styled from 'styled-components';

export const TableSemHeader = styled(TableDS)`
  div.rdt_TableHead {
    display: none;
  }

  .rdt_TableRow button[aria-disabled='true'] {
    display: none;
  }
`;
