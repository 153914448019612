// @ts-nocheck
import React, { useContext, useEffect } from 'react';
import { Text, Button, Display } from '@cvp/design-system/react';
import { download } from 'main/utils/download';
import { AppContext } from 'main/contexts/AppContext';
import useObterComprovanteResgate from 'previdencia/hooks/useObterComprovanteResgate';
import { ImprimirComprovanteProps } from 'previdencia/features/AlteracaoBeneficiarios/types/AlteracaoBeneficiariosProps';
import { CodigoRequisicao } from 'previdencia/features/AlteracaoBeneficiarios/types/enum';
import EmailSenderModal from 'main/components/EmailSenderModal';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import * as CONSTS from 'previdencia/features/AlteracaoBeneficiarios/constants/constants';
import * as S from './styles';

const ImprimirComprovante: React.FC<ImprimirComprovanteProps> = ({
  idRequisicao,
  hasAssinatura,
}) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.COMPROVANTE_BENEFICIARIO,
    parametrosEnvio: {
      idRequisicao,
      numeroCertificado: numCertificado,
      codigoRequisicao: CodigoRequisicao.ALTERACAO_BENEFICIARIOS,
      cpfCnpj,
    },
  };

  const {
    data: comprovanteResgate,
    refetch,
    isFetching,
  } = useObterComprovanteResgate(
    cpfCnpj,
    numCertificado,
    idRequisicao,
    true,
    CodigoRequisicao.ALTERACAO_BENEFICIARIOS,
  );
  const gerarComprovante = () => {
    if (comprovanteResgate?.dados.entidade?.comprovante) {
      download(comprovanteResgate?.dados.entidade?.comprovante);
    }
  };
  const imprimir = () => {
    if (!comprovanteResgate?.dados.entidade?.comprovante) {
      refetch();
    }
    gerarComprovante();
  };
  useEffect(() => {
    gerarComprovante();
  }, [comprovanteResgate]);
  return (
    <S.Container>
      <Text variant="body01-lg" color="secondary">
        Alteração realizada com sucesso
      </Text>
      <RenderConditional condition={hasAssinatura}>
        <Text variant="body01-md" color="text-light">
          {CONSTS.TEXTO_IMPRIMIR_COMPROVANTE.PARAGRAFO_01}
        </Text>
        <Text variant="body01-md" color="text-light">
          {CONSTS.TEXTO_IMPRIMIR_COMPROVANTE.PARAGRAFO_02}
        </Text>
        <Text variant="body01-md" color="text-light" margin>
          {CONSTS.TEXTO_IMPRIMIR_COMPROVANTE.PARAGRAFO_03}
        </Text>
      </RenderConditional>
      <RenderConditional condition={!hasAssinatura}>
        <Text variant="body01-md" color="text-light" margin>
          {CONSTS.TEXTO_IMPRIMIR_COMPROVANTE.PARAGRAFO_04}
        </Text>
      </RenderConditional>
      <Display>
        <Button
          variant="primary"
          onClick={() => imprimir()}
          loading={isFetching}
        >
          Imprimir
        </Button>
        <EmailSenderModal objetoEmail={objetoEmail} />
      </Display>
    </S.Container>
  );
};

export default ImprimirComprovante;
