// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { useContext, useEffect } from 'react';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { download } from 'main/utils/download';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { toastError } from 'main/hooks/useToast';
import {
  IPayloadEmitirExtrato,
  IPayloadEmitirExtratoPeco,
  IResponseEmitirExtrato,
} from '../types/IEmitirExtrato.types';

const useEmitirExtrato = (request: IPayloadEmitirExtrato | undefined) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IPayloadEmitirExtratoPeco,
    IResponseEmitirExtrato
  >({
    api: { operationPath: PECOS.ObterExtratoPdf },
    autoFetch: false,
    payload: {
      cpfCnpj,
      numeroCertificado: numCertificado,
      fundo: '',
      ...request,
    },
  });

  useEffect(() => {
    if (checkIfAllItemsAreTrue([!!response, !!response?.entidade?.return])) {
      download(tryGetValueOrDefault([response?.entidade?.return], ''));
    }
    if (
      checkIfAllItemsAreTrue([
        !!response,
        !!response?.entidade?.msgErroExcessao,
      ])
    ) {
      const errorMsg = 'Ocorreu um erro ao emitir o extrato';
      toastError(errorMsg);
    }
  }, [response]);

  return { loading, response, fetchData };
};

export default useEmitirExtrato;
