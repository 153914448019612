// @ts-nocheck
import { TIPOS_TRANSFERENCIA } from 'extranet/features/fops/constants/constantsFop223';
import {
  getContribuicaoRegularRendaVariavel,
  getDadosContribuicaoRegularRendaFixa,
  getDadosTransferenciaRendaFixa,
  getDadosTransferenciaRendaVariavel,
} from './fopsDadosTransferencia';

export const fopOnChangeDadosTransferenciaRendaFixa = (
  value: string,
  index: number,
  setFieldValue: (field: string, value: string) => void,
) => {
  if (value === TIPOS_TRANSFERENCIA.PARCIAL) {
    setFieldValue(getDadosTransferenciaRendaFixa(index, 'parcial'), 'x');
    setFieldValue(getDadosTransferenciaRendaFixa(index, 'total'), '');
  }
  if (value === TIPOS_TRANSFERENCIA.TOTAL) {
    setFieldValue(getDadosTransferenciaRendaFixa(index, 'total'), 'x');
    setFieldValue(getDadosTransferenciaRendaFixa(index, 'parcial'), '');
  }
};

export const fopOnChangeDadosTransferenciaRendaVariavel = (
  value: string,
  index: number,
  setFieldValue: (field: string, value: string) => void,
) => {
  if (value === TIPOS_TRANSFERENCIA.PARCIAL) {
    setFieldValue(getDadosTransferenciaRendaVariavel(index, 'parcial'), 'x');
    setFieldValue(getDadosTransferenciaRendaVariavel(index, 'total'), '');
  }
  if (value === TIPOS_TRANSFERENCIA.TOTAL) {
    setFieldValue(getDadosTransferenciaRendaVariavel(index, 'total'), 'x');
    setFieldValue(getDadosTransferenciaRendaVariavel(index, 'parcial'), '');
  }
};

export const fopOnChangeContribuicaoRegularRendaFixa = (
  value: string,
  index: number,
  setFieldValue: (field: string, value: string) => void,
) => {
  setFieldValue(getDadosContribuicaoRegularRendaFixa(index, ''), value);
};

export const fopOnChangeContribuicaoRegularRendaVariavel = (
  value: string,
  index: number,
  setFieldValue: (field: string, value: string) => void,
) => {
  setFieldValue(getContribuicaoRegularRendaVariavel(index, ''), value);
};
