// @ts-nocheck
import styled from 'styled-components/macro';
import { Grid as GridDS } from '@cvp/design-system/react';

export const GridContainerForm = styled(GridDS)<{
  marginTop?: string | number;
}>(({ theme: { color }, marginTop }) => ({
  marginTop,

  '& input::placeholder': {
    color: color.neutral['04'],
  },

  '& input': {
    height: '40px',
  },
}));
