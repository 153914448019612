// @ts-nocheck
import { Grid, Button } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import masks from 'main/utils/masks';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import { useRenderizarFormCliente } from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';
import * as S from 'registroOcorrenciaASC/features/registrarOcorrencia/components/FormDadosCliente/styles';
import * as OCORRENCIA_UTILS from 'registroOcorrenciaASC/utils/RegistroOcorrenciaASCUtils';

const FormDadosCliente = ({
  loadingDadosConsultaCliente,
}: REGISTRO_OCORRENCIA_TYPES.FormDadosClienteParams): React.ReactElement => {
  const { cpfCnpjCliente } = useRegistrarOcorrenciaContext();

  const { formik, loadingDadosEnderecoPeloCep, obterEnderecoPeloCep } =
    useRenderizarFormCliente();

  return (
    <Grid>
      <Grid.Item xs={1}>
        <S.Text variant="body02-sm">
          {CONSTS.TEXTOS_FORM_DADOS_CLIENTE.VERIFICAR_DADOS}
        </S.Text>
      </Grid.Item>
      <RenderConditional condition={loadingDadosConsultaCliente}>
        <Grid.Item xs={1}>
          <SkeletonLoading blocks={1} />
        </Grid.Item>
      </RenderConditional>
      <RenderConditional condition={!loadingDadosConsultaCliente}>
        <S.GridItem xs={1} md={1 / 2}>
          <S.TextField
            label={cpfCnpjCliente.cnpj ? 'Razão Social*' : 'Nome*'}
            data-testid="inputNomeOuRazaoSocialCliente"
            name="inputRazaoSocialOuNome"
            value={formik.values.inputRazaoSocialOuNome}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'inputRazaoSocialOuNome',
            )}
          />
        </S.GridItem>
        <S.GridItem xs={1} md={1 / 2}>
          <S.TextField
            label={cpfCnpjCliente.cnpj ? 'E-mail Corporativo' : 'E-mail'}
            data-testid="inputEmailCorporativoOuEmailCliente"
            name="inputEmailCorporativoOuEmail"
            value={formik.values.inputEmailCorporativoOuEmail}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'inputEmailCorporativoOuEmail',
            )}
          />
        </S.GridItem>
        <S.GridItem xs={1} md={1 / 2}>
          <S.ContainerConsultarCep>
            <S.TextField
              label="CEP"
              data-testid="inputCepCliente"
              maxLength={9}
              name="inputCep"
              value={masks.cep.mask(formik.values.inputCep)}
              onBlur={formik.handleBlur}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue('inputCep', masks.cep.unmask(value));
              }}
              {...OCORRENCIA_UTILS.showErrorInput(formik, 'inputCep')}
            />
            <Button
              data-testid="btnInputCepClienteConsultar"
              variant="secondary"
              onClick={() => {
                obterEnderecoPeloCep({ CEP: formik.values.inputCep });
              }}
              loading={loadingDadosEnderecoPeloCep}
              disabled={checkIfSomeItemsAreTrue([
                formik.values.inputCep.length !==
                  ENUM_TYPES.DefaultValue.VALOR_MINIMO_CEP,
                loadingDadosEnderecoPeloCep,
              ])}
            >
              Consultar
            </Button>
          </S.ContainerConsultarCep>
        </S.GridItem>
        <RenderConditional condition={loadingDadosEnderecoPeloCep}>
          <S.GridItem xs={1}>
            <SkeletonLoading blocks={1} />
          </S.GridItem>
        </RenderConditional>
        <RenderConditional condition={!loadingDadosEnderecoPeloCep}>
          <S.GridItem xs={1}>
            <S.TextField
              label="Endereço"
              data-testid="inputEnderecoCliente"
              name="inputEndereco"
              value={formik.values.inputEndereco}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...OCORRENCIA_UTILS.showErrorInput(formik, 'inputEndereco')}
            />
          </S.GridItem>
          <S.GridItem xs={1} md={4 / 10}>
            <S.TextField
              label="Bairro"
              disabled
              data-testid="inputBairroCliente"
              name="inputBairro"
              value={formik.values.inputBairro}
            />
          </S.GridItem>
          <S.GridItem xs={1} md={5 / 10}>
            <S.TextField
              label="Cidade"
              disabled
              data-testid="inputCidadeCliente"
              name="inputCidade"
              value={formik.values.inputCidade}
            />
          </S.GridItem>
          <S.GridItem xs={1} md={1 / 10}>
            <S.TextField
              label="UF"
              disabled
              data-testid="inputUfCliente"
              name="inputUf"
              value={formik.values.inputUf}
            />
          </S.GridItem>
        </RenderConditional>
        <S.GridItem xs={1} md={2 / 5}>
          <S.TextField
            label="Telefone Principal*"
            maxLength={15}
            data-testid="inputTelefonePrincipalCliente"
            name="inputTelefonePrincipal"
            value={masks.commercialPhone.mask(
              formik.values.inputTelefonePrincipal,
            )}
            onBlur={formik.handleBlur}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                'inputTelefonePrincipal',
                masks.commercialPhone.unmask(value),
              );
            }}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'inputTelefonePrincipal',
            )}
          />
        </S.GridItem>
        <S.GridItem xs={1} md={2 / 5}>
          <S.TextField
            label="Telefone"
            maxLength={15}
            data-testid="inputTelefoneSecundarioCliente"
            name="inputTelefoneSecundario"
            value={masks.commercialPhone.mask(
              formik.values.inputTelefoneSecundario,
            )}
            onBlur={formik.handleBlur}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                'inputTelefoneSecundario',
                masks.commercialPhone.unmask(value),
              );
            }}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'inputTelefoneSecundario',
            )}
          />
        </S.GridItem>
        <RenderConditional condition={cpfCnpjCliente.cnpj}>
          <Grid.Item xs={1}>
            <S.Text variant="body02-sm">
              {CONSTS.TEXTOS_FORM_DADOS_CLIENTE.INFO_DADOS_REPRESENTANTE}
            </S.Text>
          </Grid.Item>
          <S.GridItem xs={1} md={1 / 2}>
            <S.TextField
              label="CPF*"
              data-testid="inputCpfRepresentante"
              name="inputCpfRepresentante"
              value={masks.cpf.mask(formik.values.inputCpfRepresentante)}
              onBlur={formik.handleBlur}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(
                  'inputCpfRepresentante',
                  masks.cpf.unmask(value),
                );
              }}
              {...OCORRENCIA_UTILS.showErrorInput(
                formik,
                'inputCpfRepresentante',
              )}
            />
          </S.GridItem>
          <S.GridItem xs={1} md={1 / 2}>
            <S.TextField
              label="Nome*"
              data-testid="inputNomeRepresentante"
              name="inputNomeRepresentante"
              value={formik.values.inputNomeRepresentante}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...OCORRENCIA_UTILS.showErrorInput(
                formik,
                'inputNomeRepresentante',
              )}
            />
          </S.GridItem>
          <S.GridItem xs={1} md={1 / 2}>
            <S.TextField
              label="E-mail*"
              data-testid="inputEmailRepresentante"
              name="inputEmailRepresentante"
              value={formik.values.inputEmailRepresentante}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              {...OCORRENCIA_UTILS.showErrorInput(
                formik,
                'inputEmailRepresentante',
              )}
            />
          </S.GridItem>
          <S.GridItem xs={1} md={1 / 2}>
            <S.TextField
              label="Telefone*"
              maxLength={15}
              data-testid="inputTelefoneRepresentante"
              name="inputTelefoneRepresentante"
              value={masks.commercialPhone.mask(
                formik.values.inputTelefoneRepresentante,
              )}
              onBlur={formik.handleBlur}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(
                  'inputTelefoneRepresentante',
                  masks.commercialPhone.unmask(value),
                );
              }}
              {...OCORRENCIA_UTILS.showErrorInput(
                formik,
                'inputTelefoneRepresentante',
              )}
            />
          </S.GridItem>
        </RenderConditional>
      </RenderConditional>
      <Grid.Item xs={1}>
        <S.Text variant="body02-sm">
          <strong>
            {CONSTS.TEXTOS_FORM_DADOS_CLIENTE.CAMPOS_OBRIGATORIOS}
          </strong>
        </S.Text>
      </Grid.Item>
    </Grid>
  );
};

export default FormDadosCliente;
