// @ts-nocheck
import { IRouteProps } from 'main/components/Route';

export const mapBreadCrumbsFromRoutes = (routes: IRouteProps[]) => {
  if (routes && routes.length > 0) {
    const breads = routes.map(item => ({
      [`${item.path}`]: item.breadcrumb,
    }));
    return Object.assign({}, ...breads);
  }
  return {};
};
