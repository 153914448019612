// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { DefaultParamRequest } from 'previdencia/types/enum';
import { getUserName } from 'main/features/Auth/utils/auth';
import { EnumCanalOrigem } from 'previdencia/types/DefaultValues';
import {
  IPayloadReativarBeneficio,
  IPayloadReativarBeneficioPeco,
  IResponseIncluirReativarBeneficioProtecao,
} from '../types/ReativarIncluirBeneficioProtecaoTypes';

const useReativarBeneficioProtecao = (
  payloadReativar: IPayloadReativarBeneficio,
) => {
  const username = getUserName();

  const {
    cliente: { numCertificado },
  } = useContext(AppContext);
  const { response, loading, fetchData } = usePeco<
    IPayloadReativarBeneficioPeco,
    IResponseIncluirReativarBeneficioProtecao
  >({
    api: {
      operationPath: PECOS.ReativarBeneficioProtecao,
    },
    autoFetch: false,
    payload: {
      ...payloadReativar,
      codigoEmpresa: DefaultParamRequest.CODIGO_EMPRESA,
      codigoCertificado: numCertificado,
      matriculaEconomiario: username,
      canalOrigem: EnumCanalOrigem.portalEconimiario,
    },
  });

  return { response, loading, fetchData };
};

export default useReativarBeneficioProtecao;
