// @ts-nocheck
import * as Yup from 'yup';

const MENSAGEM_EMAIL_INVALIDO = 'Informe um e-mail válido';
const MENSAGEM_CAMPO_OBRIGATORIO = 'Campo obrigatório';

export const NomeSocialValidationSchema = Yup.object().shape({
  utilizarNomeSocial: Yup.boolean(),
  nomeSocial: Yup.string().when('utilizarNomeSocial', {
    is: true,
    then: Yup.string()
      .required(MENSAGEM_CAMPO_OBRIGATORIO)
      .min(3, 'Informe um nome válido'),
  }),
});
