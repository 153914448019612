// @ts-nocheck
import styled from 'styled-components/macro';

export const Container = styled.div<{ containerWidth: string }>(
  ({ theme: { breakpoint }, containerWidth }) => ({
    width: '100%',
    '&&': {
      [breakpoint.xl()]: {
        width: containerWidth,
      },
    },
  }),
);
