// @ts-nocheck
import styled from 'styled-components/macro';
import { Button } from '@cvp/design-system/react';
import { PE } from 'main/components/PEComponents';

export const ContainerInput = styled(PE.Display)(() => ({
  display: 'block',
  marginLeft: '26px',
}));

export const ContainerInputChild = styled.div(({ theme }) => ({
  display: 'block',
  gap: '50px',
  alignItems: 'center',
  padding: '15px 0',

  [theme.breakpoint.md()]: {
    display: 'flex',
  },
}));

export const Botao = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: '10px',

  [theme.breakpoint.md()]: {
    marginBottom: '10px',
    width: '45px',
  },
}));
