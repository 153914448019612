// @ts-nocheck
import React from 'react';
import * as S from './styles';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { TInfoBoxProps } from './types';

const InfoBox: React.FC<TInfoBoxProps> = ({
  valorBeneficio,
  valorContribuicao,
}) => (
  <S.Container>
    <S.Row>
      <S.Label>Contribuição</S.Label>
      <S.Value>
        Valor: {tryGetMonetaryValueOrDefault([valorContribuicao], 0)}
      </S.Value>
    </S.Row>
    <S.Divider />
    <S.Row>
      <S.Label>Importância segurada</S.Label>
      <S.Value>
        Valor: {tryGetMonetaryValueOrDefault([valorBeneficio], 0)}
      </S.Value>
    </S.Row>
  </S.Container>
);

export default InfoBox;
