// @ts-nocheck
import { Text } from '@cvp/design-system/react';
import { Link } from 'react-router-dom';
import { obterLinkAtendimentoOnline } from 'previdencia/config/endpoints';

export const TEXTOS = {
  CANAIS_ATENDIMENTO: (
    <Text variant="body02-sm" color="primary">
      <b>
        Canais de atendimento
        <br aria-hidden="true" /> ao Economiário
      </b>
    </Text>
  ),
  CENTRAL_SERVICO: (
    <b>
      Central de Serviço Exclusiva de
      <br aria-hidden="true" /> Atendimento ao Empregado CAIXA
    </b>
  ),
  WEBCHAT: (
    <>
      Especialista disponível para dar solução <br aria-hidden="true" />
      em tempo real a Rede Caixa
    </>
  ),
  WEBCHAT_PRIVADO: (
    <>
      {' '}
      (
      <Link aria-label="Ir para webchat" to="/atendimento/webchat">
        Webchat
      </Link>
      ).
    </>
  ),
  WEBCHAT_PUBLICO: (
    <>
      {' '}
      (
      <a
        aria-label="Ir para webchat de Seguro Vida"
        href={obterLinkAtendimentoOnline('573Do0000000Al6')}
        target="blank"
      >
        Seguro Vida,{' '}
      </a>
      <br aria-hidden="true" />
      <a
        aria-label="Ir para webchat de Seguro Prestamista"
        href={obterLinkAtendimentoOnline('573Do0000000Aib')}
        target="blank"
      >
        Seguro Prestamista,{' '}
      </a>
      <a
        aria-label="Ir para webchat de Previdência"
        href={obterLinkAtendimentoOnline('573Do0000000Aj0')}
        target="blank"
      >
        Previdência
      </a>
      )
    </>
  ),

  ATENDIMENTO_WEBCHAT: 'Atendimento Online Webchat',
  APOIO_REDE: 'Apoio à Rede 0800 702 4022',
  MESA_APOIO: 'A Mesa de Apoio a Rede (MARE)',
  ATENDIMENTO_GESTORES: (
    <>
      Canal direto de atendimento aos Gestores de Unidade
      <br /> para resolução de casos críticos (11) 2110-3450
    </>
  ),
};
