// @ts-nocheck
import { Text, Grid } from '@cvp/design-system/react';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import masks from 'main/utils/masks';
import { usePrestamistaContext } from 'prestamista/contexts/PrestamistaContextProvider';
import * as UTILS from 'prestamista/features/dadosSegurado/utils/DadosSegurado';
import * as CONSTS from 'prestamista/features/dadosSegurado/constants/constants';
import * as DADOS_SEGURADO_TYPES from 'prestamista/features/dadosSegurado/types/DadosSegurado';
import * as S from 'prestamista/features/dadosSegurado/components/TabelaDadosSegurado/styles';
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';

const TabelaDadosSegurado = () => {
  const { featureData } =
    usePrestamistaContext<DADOS_SEGURADO_TYPES.IDadosSeguradoContextData>();
  const { nomeSocial } = useContext(AppContext);

  const dadosCertificadoPrestamista = featureData?.dadosCertificadoPrestamista;

  const listaTelefones = dadosCertificadoPrestamista?.segurados?.[0]?.telefones;
  const nomeSegurado =
    dadosCertificadoPrestamista?.segurados?.[0]?.nomeRazaoSocial;

  const email = dadosCertificadoPrestamista?.segurados?.[0]?.emails?.[0];

  if (checkIfSomeItemsAreTrue([!!featureData?.loadingDadosSegurado])) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <>
      <Grid>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 2}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelCepDadosSegurado"
            >
              Nome Civil
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="cepDadosSegurado"
            >
              {nomeSegurado}
            </Text>
          </S.ContainerData>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 2}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelCepDadosSegurado"
            >
              Nome Social
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="cepDadosSegurado"
            >
              {tryGetValueOrDefault([nomeSocial], '-')}
            </Text>
          </S.ContainerData>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelCepDadosSegurado"
            >
              CEP
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="cepDadosSegurado"
            >
              {tryGetValueOrDefault(
                [
                  masks.cep.mask(
                    dadosCertificadoPrestamista?.codigoCepSegurado,
                  ),
                ],
                '-',
              )}
            </Text>
          </S.ContainerData>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelEnderecoDadosSegurado"
            >
              Endereço
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="enderecoDadosSegurado"
            >
              {tryGetValueOrDefault(
                [dadosCertificadoPrestamista?.nomeLogradouroSegurado],
                '-',
              )}
            </Text>
          </S.ContainerData>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelNumeroEnderecoDadosSegurado"
            >
              Número
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="numeroEnderecoDadosSegurado"
            >
              {tryGetValueOrDefault(
                [dadosCertificadoPrestamista?.numeroLogradouroSegurado],
                '-',
              )}
            </Text>
          </S.ContainerData>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelComplementoEnderecoDadosSegurado"
            >
              Complemento
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="complementoEnderecoDadosSegurado"
            >
              {tryGetValueOrDefault(
                [
                  dadosCertificadoPrestamista?.descricaoComplementoEnderecoSegurado,
                ],
                '-',
              )}
            </Text>
          </S.ContainerData>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelBairroDadosSegurado"
            >
              Bairro
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="bairroDadosSegurado"
            >
              {tryGetValueOrDefault(
                [dadosCertificadoPrestamista?.nomeBairroSegurado],
                '-',
              )}
            </Text>
          </S.ContainerData>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelCidadeDadosSegurado"
            >
              Cidade
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="cidadeDadosSegurado"
            >
              {tryGetValueOrDefault(
                [dadosCertificadoPrestamista?.nomeCidadeSegurado],
                '-',
              )}
            </Text>
          </S.ContainerData>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelUfDadosSegurado"
            >
              UF
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="ufDadosSegurado"
            >
              {tryGetValueOrDefault(
                [dadosCertificadoPrestamista?.codigoUfSegurado],
                '-',
              )}
            </Text>
          </S.ContainerData>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelEmailDadosSegurado"
            >
              E-mail
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="emailDadosSegurado"
            >
              {tryGetValueOrDefault([email?.uri], '-')}
            </Text>
          </S.ContainerData>
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelTelefoneCelularDadosSegurado"
            >
              Telefone Celular
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="telefoneCelularDadosSegurado"
            >
              (
              {tryGetValueOrDefault(
                [
                  UTILS.obterDadosTelefonePorTipo(
                    CONSTS.TIPO_TELEFONE.CELULAR.ID,
                    listaTelefones,
                  )?.numeroDdd?.toString(),
                ],
                '-',
              )}
              ){' '}
              {tryGetValueOrDefault(
                [
                  masks.phoneWithoutDDD.mask(
                    UTILS.obterDadosTelefonePorTipo(
                      CONSTS.TIPO_TELEFONE.CELULAR.ID,
                      listaTelefones,
                    )?.numeroTelefone?.toString(),
                  ),
                ],
                '',
              )}
            </Text>
          </S.ContainerData>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <S.ContainerData>
            <Text
              variant="caption-02"
              color="text-light"
              data-testid="labelTelefoneResidencialDadosSegurado"
            >
              Telefone Residencial
            </Text>
            <Text
              variant="caption-02"
              color="text"
              data-testid="telefoneResidencialDadosSegurado"
            >
              (
              {tryGetValueOrDefault(
                [
                  UTILS.obterDadosTelefonePorTipo(
                    CONSTS.TIPO_TELEFONE.RESIDENCIAL.ID,
                    listaTelefones,
                  )?.numeroDdd?.toString(),
                ],
                '-',
              )}
              ){' '}
              {tryGetValueOrDefault(
                [
                  masks.phoneWithoutDDD.mask(
                    UTILS.obterDadosTelefonePorTipo(
                      CONSTS.TIPO_TELEFONE.RESIDENCIAL.ID,
                      listaTelefones,
                    )?.numeroTelefone?.toString(),
                  ),
                ],
                '',
              )}
            </Text>
          </S.ContainerData>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default TabelaDadosSegurado;
