// @ts-nocheck
import React from 'react';
import { Disclaimer } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import Icon from 'main/components/Icon';

export const DisclaimerError: React.FC<{ show: boolean }> = ({ show }) => (
  <RenderConditional condition={show}>
    <Disclaimer variant="alert" data-testid="mensagemErro">
      <Disclaimer.Content
        icon={<Icon name="warning" />}
        text="Não foi possível carregar o relatório, tente novamente mais tarde."
      />
    </Disclaimer>
  </RenderConditional>
);
