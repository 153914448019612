// @ts-nocheck
import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #ffa500;
  padding: 10px;
  width: 400px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
  padding: 5px 0;
  font-size: 14px;
  color: #808080;
  padding: 5px 0;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #add8e6;
  margin: 5px 0;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 20px;
  background-color: #add8e6;
  margin: 0 10px;
`;

export const Label = styled.span`
  color: #808080;
`;

export const Value = styled.span`
  color: #000;
  font-weight: bold;
`;
