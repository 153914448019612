// @ts-nocheck
import React, { useContext } from 'react';
import { Button, Text, Display } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import ComprovanteContribuicaoAdicional from 'previdencia/features/Aporte/components/ComprovanteAporte/ComprovanteAporteAdicional';
import ComprovanteAporteDadosSolicitacao from 'previdencia/features/Aporte/components/ComprovanteAporte/ComprovanteAporteDadosSolicitacao';
import ComprovanteParticipante from 'previdencia/features/Aporte/components/ComprovanteAporte/ComprovanteAporteParticipante';
import ComprovanteAporteTabelaFundos from 'previdencia/features/Aporte/components/ComprovanteAporte/ComprovanteAporteTabelaFundos';
import MensagemExcecaoAporte from 'previdencia/features/Aporte/components/MensagemExcecaoAporte';
import * as UTILS from 'previdencia/features/Aporte/utils/aporteUtils';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const ComprovanteAporte: React.FC<APORTE_TYPES.IComprovanteAporteProps> = ({
  aporteFormaPagamento,
  aporteOrigemRecursos,
  aporteDadosBancarios,
  fundosDistribuicao,
}) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { featureData } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  const responseEfetuarAporte = featureData?.dadosAporteAdicional?.entidade;

  const fetchingResponseEfetuarAporte =
    featureData?.isFetching?.loadingDadosAporteAdicional;

  const mensagensAviso =
    featureData?.dadosAporteAdicional?.mensagens?.[0].descricao;

  const imprimirPagina = (): void => {
    UTILS.imprimirPaginaCustomizada(CONSTS.TAMANHO_PERCENTUAL_PAGINA);
  };

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.COMPROVANTE_APORTE_PREVIDENCIA,
    parametrosEnvio: {
      cpfCnpj,
      numeroCertificado: numCertificado,
      codigoRequisicao: CONSTS.CODIGO_REQUISICAO_EMAIL,
      TipoPagamento: aporteFormaPagamento.get().value.formaPagamento,
      NumeroSolicitacao: tryGetValueOrDefault(
        [responseEfetuarAporte?.numeroSolicitacao],
        '',
      ),
      NumeroCobranca: tryGetValueOrDefault(
        [responseEfetuarAporte?.trabalhoId],
        '',
      ),
    },
  };

  if (fetchingResponseEfetuarAporte) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <>
      <RenderConditional condition={!!responseEfetuarAporte}>
        <Text variant="body02-lg" color="primary">
          {CONSTS.COMPROVANTE_APORTE_LABELS.OPERACAO_REALIZADA}
        </Text>

        <ComprovanteParticipante />

        <ComprovanteContribuicaoAdicional
          aporteOrigemRecursos={aporteOrigemRecursos}
        />

        <ComprovanteAporteDadosSolicitacao
          aporteFormaPagamento={aporteFormaPagamento}
          aporteDadosBancarios={aporteDadosBancarios}
        />

        <ComprovanteAporteTabelaFundos
          fundosDistribuicao={fundosDistribuicao}
        />
      </RenderConditional>

      <RenderConditional condition={!responseEfetuarAporte}>
        <MensagemExcecaoAporte
          icon="warning"
          mensagem={tryGetValueOrDefault(
            [mensagensAviso],
            CONSTS.COMPROVANTE_APORTE_LABELS.MENSAGEM_EXCECAO,
          )}
        />
      </RenderConditional>

      <S.ContainerAporte>
        <Display justify="center" className="hide-print">
          <Button
            variant="outlined"
            onClick={featureData?.iniciarProcessoAporte}
          >
            Novo Aporte
          </Button>
          <RenderConditional condition={!!responseEfetuarAporte}>
            <Button
              data-testid="button-print"
              variant="secondary"
              onClick={imprimirPagina}
            >
              Imprimir
            </Button>
            <EmailSenderModal objetoEmail={objetoEmail} />
          </RenderConditional>
        </Display>
      </S.ContainerAporte>
    </>
  );
};

export default ComprovanteAporte;
