// @ts-nocheck
import React, { useState, useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { Card, Display, Grid, Text } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import useObterDadosCpfPorCertificado from 'previdencia/hooks/useObterDadosCpfPorCertificado';
import ConfirmarAlteracaoVencimento from '../components/ConfirmarAlteracaoVencimento';
import DadosVencimento from '../components/DadosVencimento';
import { filtrarDadosPorCertificado } from '../utils/AlteracaoDiaVencimento';

const AlteracaoDiaVencimento: React.FC = () => {
  const { cliente } = useContext(AppContext);
  const {
    data: dadosDiaVencimento,
    isLoading: loadingDiaVencimento,
    refetch: refetchDadosCertificado,
  } = useObterDadosCpfPorCertificado();
  const [telaCorrente, setTelaCorrente] = useState(0);
  const [diaAlterado, setDiaAlterado] = useState('');

  const dadosCertificado = filtrarDadosPorCertificado(
    dadosDiaVencimento,
    cliente.numCertificado,
  );

  const proximaTela = (dia: string) => {
    setDiaAlterado(dia);
    setTelaCorrente(1);
  };

  const voltarTela = () => {
    setTelaCorrente(0);
  };

  const fluxoTela = [
    <DadosVencimento
      diaPagamento={dadosCertificado?.diaPagamento}
      proximaTela={proximaTela}
    />,
    <ConfirmarAlteracaoVencimento
      diaAntigo={dadosCertificado?.diaPagamento}
      diaNovo={diaAlterado}
      voltar={voltarTela}
      refetchDadosCertificado={refetchDadosCertificado}
    />,
  ];

  if (loadingDiaVencimento) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO]}
        requiredPermissions={[PREV_PERMISSIONS.DIA_DE_VENCIMENTO_DO_PLANO]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  Alteração de Dia de Vencimento
                </Text>
                {fluxoTela[telaCorrente]}
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default AlteracaoDiaVencimento;
