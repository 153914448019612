// @ts-nocheck
import React from 'react';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { useTheme } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { THEME_NAME } from 'main/components/ThemeProvider';

const FabButton: React.FunctionComponent<{
  iconName: string;
  children: React.ReactNode;
}> = ({ children, iconName }) => {
  const theme = useTheme(THEME_NAME);
  return (
    <Fab
      mainButtonStyles={{
        background: theme.color.brandSecondary.gradient,
      }}
      style={{ bottom: 40, right: 0 }}
      icon={<Icon name={iconName} />}
    >
      {children}
    </Fab>
  );
};

export default FabButton;
