// @ts-nocheck
/* eslint-disable react/require-default-props */
import { maskDate } from 'main/utils';
import React, { forwardRef } from 'react';

import * as S from './styles';

type CalendarInputProps = {
  value?: string;
  onChange?: (data: any) => void;
  error?: boolean;
};

const CalendarInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  CalendarInputProps
> = ({ value, onChange, error, ...props }, ref) => {
  return (
    <S.CalendarInput
      ref={ref}
      value={maskDate(value)}
      onChange={onChange}
      maxLength={10}
      {...props}
      error={error}
    />
  );
};

export default forwardRef(CalendarInput);
