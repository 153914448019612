// @ts-nocheck
import { useContext, useState } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { download } from 'main/utils/download';
import { usePeco } from 'main/hooks/usePeco';
import {
  PayloadSegundaViaBoleto,
  ResponseSegundaViaBoleto,
} from '../types/ResponseSegundaViaBoleto';
import { PECOS } from 'previdencia/config/endpoints';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

const useObterSegundaViaBoleto = () => {
  const [error, setError] = useState<string>();

  const { cliente } = useContext(AppContext);

  const { cpfCnpj } = cliente;

  const { fetchData, loading } = usePeco<
    PayloadSegundaViaBoleto,
    ResponseSegundaViaBoleto
  >({
    api: { operationPath: PECOS.ObterSegundaVia },
    autoFetch: false,
  });

  const obterSegundaVia = async (numeroCobranca: string): Promise<void> => {
    const dados = await fetchData({ Cpf: cpfCnpj, numeroCobranca });

    const errorMsg = dados?.mensagens?.[0].descricao;

    if (!dados?.sucessoGI) {
      setError(errorMsg);
    }
    if (
      checkIfAllItemsAreTrue([
        !!dados,
        !!dados?.sucessoGI,
        !!dados?.entidade?.return,
      ])
    ) {
      return download(dados?.entidade?.return);
    }

    return undefined;
  };

  return {
    loading,
    error,
    obterSegundaVia,
  };
};

export default useObterSegundaViaBoleto;
