// @ts-nocheck
import { useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import * as TiposSolicitacoesApi from 'previdencia/features/HistoricoSolicitacoes/services/tiposSolicitacoes.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseTiposSolicitacoes } from '../types/HistoricoSolicitacoes';

const useConsultarTiposSolicitacoes = (): UseQueryResult<
  ResponseTiposSolicitacoes[] | undefined
> => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-tipos-solicitacoes-', cpfCnpj],
    () => TiposSolicitacoesApi.obterTiposSolicitacoes(cpfCnpj, numCertificado),
    {
      cacheTime: reactQueryCacheDuration(),
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};
export default useConsultarTiposSolicitacoes;
