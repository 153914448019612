// @ts-nocheck
import { useToast } from 'main/hooks/useToast';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';
import { IProduto } from 'main/features/prospeccao/types/Produto';
import { useEffect, useState } from 'react';
import { obterListaPorCpfCpnj, obterProdutos } from '../services/api';

export const useObterProdutos = () => {
  const [produtos, setProdutos] = useState<IProduto[]>([] as IProduto[]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { toastError } = useToast();

  const getProdutos = async () => {
    try {
      setLoading(true);
      const result = await obterProdutos();
      if (result.dados.sucesso && result.dados.entidade) {
        setProdutos(result.dados.entidade);
        return;
      }
      const mensagemServico = (result.dados.mensagens ?? [])[0].descricao;
      toastError(mensagemServico);
    } catch (exception: unknown) {
      setError(error);
      toastError();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProdutos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { produtos, loading, error };
};

export const useConsultaProspeccao = () => {
  const [prospeccoes, setProspeccoes] = useState<IProspeccao[]>(
    [] as IProspeccao[],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { toastError } = useToast();

  const getProspeccpesPorCpfCnpj = async (cpfCnpj: string) => {
    try {
      setLoading(true);
      const result = await obterListaPorCpfCpnj(cpfCnpj);
      if (result.dados.sucesso && result.dados.entidade) {
        setProspeccoes(result.dados.entidade);
        return;
      }
      const mensagemServico = (result.dados.mensagens ?? [])[0].descricao;
      toastError(mensagemServico);
    } catch (exception: unknown) {
      setError(error);
      toastError();
    } finally {
      setLoading(false);
    }
  };

  return { prospeccoes, loading, error, getProspeccpesPorCpfCnpj };
};
