// @ts-nocheck
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { IDadosCertificadoSeguroResponse } from 'seguros/types/IResponseCertificadosResumo';

export type DadosBeneficiariosProps = {
  data: IHandleReponseResult<IDadosCertificadoSeguroResponse> | undefined;
  loading: boolean;
};

export enum TipoContrato {
  INDIVIDUAL = 'Individual',
  EMPRESARIAL = 'Empresarial',
  BILHETE = 'Bilhete',
}

export type CommonTablePropsFactory = {
  pagination: boolean;
  noHeader?: boolean;
  responsive?: boolean;
  striped?: boolean;
  highlightOnHover?: boolean;
  paginationPerPage?: number;
  noDataComponent?: string;
};
