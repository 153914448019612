// @ts-nocheck
import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import * as Api from '../services/imprimirPosicaoConsolidada.api';

export const useImprimirPosicaoConsolidada = () => {
  const { toastError } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<string>();
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  const gerarArquivoImprimir = async (data: string) => {
    try {
      setLoading(true);
      const result = await Api.obterArquivoImprimir(cpfCnpj, data);

      setResponse(result);
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    gerarArquivoImprimir,
  };
};
