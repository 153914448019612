// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import ValidateResult from 'main/features/Validation/types/ValidateResult';
import { minValue, required } from 'main/features/Validation/validations';
import useFieldLink from 'main/hooks/useFieldLink';
import masks from 'main/utils/masks';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';

import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
} from 'react';
import { ValidaInputMinValue } from 'previdencia/types/Forms';
import * as S from './styles';

type InputRedistribuirValorProps = {
  valorInicialInput: number;
  valorMinimo: number;
  hidden: boolean;
  isResgate: boolean | undefined;
  maxLength?: string;
};

const InputRedistribuirValor: ForwardRefRenderFunction<
  { validate: () => ValidateResult; value: number },
  InputRedistribuirValorProps
> = (
  { valorInicialInput, hidden, valorMinimo, isResgate, maxLength = '17' },
  ref,
) => {
  const [valorLink, validarValor] = useFieldLink(valorInicialInput.toString());

  let valor = '0';
  if (hidden && isResgate) {
    valor = valorInicialInput.toString();
  } else {
    valor = valorLink.get().value;
  }

  useEffect(() => {
    valorLink.set({
      value: (valorInicialInput * 100).toString(),
      isValid: true,
    });
  }, [valorInicialInput]);

  useImperativeHandle(
    ref,
    () => ({
      validate: validarValor,
      value: parseFloat(valor) / 100,
    }),
    [valor],
  );
  const validacaoInput: ValidaInputMinValue = {
    min: valorMinimo,
    select: hidden,
  };

  return (
    <>
      <S.InputFundo
        link={valorLink}
        validationRules={[required(), minValue(() => '', validacaoInput)]}
        hidden={hidden}
        inputMask={masks.currencyInput}
        legend={
          valorMinimo === 0
            ? undefined
            : `Valor Mínimo ${formatarValorPadraoBrasileiro(valorMinimo)}`
        }
        maxLength={maxLength}
      />
      {hidden && isResgate && (
        <>{formatarValorPadraoBrasileiro(parseFloat(valor) / 100)}</>
      )}
    </>
  );
};

export default forwardRef(InputRedistribuirValor);
