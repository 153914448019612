// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';

import * as S from './styles';
import CalendarInput from './CalendarInput';

registerLocale('ptBR', ptBR);

type CalendarProps = {
  id: string;
  placeholder: string;
  value: Date | null;
  endDate?: Date | null;
  onChange: (initialDate: Date | null, finalDate: Date | null) => void;
  range?: boolean;
  maxDate?: Date | null;
  minDate?: Date | null;
  className?: string;
  error?: boolean;
};

const Calendar: React.FunctionComponent<CalendarProps> = ({
  placeholder,
  value,
  endDate,
  onChange,
  className,
  range = false,
  maxDate,
  minDate,
  error,
  ...props
}) => {
  return (
    <S.CalendarContainer {...props}>
      <S.CalendarInputContainer>
        {range && <Text variant="body01-md">De:</Text>}
        <DatePicker
          selected={value}
          maxDate={maxDate}
          minDate={minDate}
          placeholderText={placeholder}
          onChange={(date: Date) => onChange(date, endDate || null)}
          locale="ptBR"
          dateFormat="dd/MM/yyyy"
          dayClassName={() => 'calendar-day'}
          startDate={value}
          endDate={endDate}
          className={className}
          selectsStart
          customInput={
            <CalendarInput error={error} data-testid="calendar-input-initial" />
          }
        />
      </S.CalendarInputContainer>
      {range && (
        <S.CalendarInputContainer>
          <Text variant="body01-md">Até:</Text>
          <DatePicker
            selected={endDate}
            placeholderText={placeholder}
            onChange={(date: Date) => onChange(value || null, date)}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            dayClassName={() => 'calendar-day'}
            startDate={value}
            endDate={endDate}
            maxDate={maxDate}
            minDate={value}
            selectsEnd
            customInput={
              <CalendarInput error={error} data-testid="calendar-input-final" />
            }
          />
        </S.CalendarInputContainer>
      )}
    </S.CalendarContainer>
  );
};

Calendar.defaultProps = {
  endDate: null,
  range: false,
};

export default Calendar;
