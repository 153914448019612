// @ts-nocheck
export function lastYears(anosDisponiveis: string[]): string[] {
  const primeiroAno = anosDisponiveis.length - 1;
  const ultimosAnos: string[] = [];
  let index = 0;

  for (let i = primeiroAno; i >= 0; i -= 1) {
    if (index < 5) {
      ultimosAnos.push(anosDisponiveis[i]);
      index += 1;
    }
  }

  return ultimosAnos;
}
