// @ts-nocheck
import { useState, useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseConfirmarTransferencia } from '../types/ConfirmarTransferencia.types';
import * as ConfirmarTransferenciaApi from '../services/confirmarTransferencia.api';
import {
  ISolicitarAssinaturaRequest,
  ISolicitarAssinaturaResponse,
} from '../types/ISolicitarAssinaturaRequest';

const useConfirmarTransferencia = () => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const [
    confirmarTransferenciaEntreFundos,
    setConfirmarTransferenciaEntreFundos,
  ] = useState<ResponseConfirmarTransferencia | undefined>();

  const [
    loadingConfirmarTransferenciaEntreFundos,
    setLoadingConfirmarTransferenciaEntreFundos,
  ] = useState<boolean>();
  const [resultadoAssinatura, setResultadoAssinatura] = useState(
    {} as ISolicitarAssinaturaResponse | undefined,
  );
  const [loadingSolicitarAssinatura, setLoadingSolicitarAssinatura] =
    useState<boolean>(false);
  const obterConfirmarTransferencia = async (
    internalTransferId: string | undefined,
  ) => {
    try {
      setLoadingConfirmarTransferenciaEntreFundos(true);
      const result = await ConfirmarTransferenciaApi.confirmarTransferencia(
        cpfCnpj,
        internalTransferId,
        numCertificado,
      );
      setConfirmarTransferenciaEntreFundos(result);
      return result;
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setLoadingConfirmarTransferenciaEntreFundos(false);
    }
    return undefined;
  };

  const solicitarAssinatura = async (data: ISolicitarAssinaturaRequest) => {
    try {
      setLoadingSolicitarAssinatura(true);
      const result = await ConfirmarTransferenciaApi.solicitarAssinatura(data);
      setResultadoAssinatura(result);
      return result;
    } catch (error) {
      toastError();
    } finally {
      setLoadingSolicitarAssinatura(false);
    }
    return undefined;
  };
  return {
    loadingConfirmarTransferenciaEntreFundos,
    confirmarTransferenciaEntreFundos,
    loadingSolicitarAssinatura,
    resultadoAssinatura,
    obterConfirmarTransferencia,
    solicitarAssinatura,
  };
};

export default useConfirmarTransferencia;
