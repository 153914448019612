// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { DefaultParamRequest } from 'previdencia/types/enum';
import {
  IPayloadResgatarBeneficioProtecao,
  IResponseResgatarBeneficioProtecao,
} from '../types/BeneficioProtecaoTypes';

const useResgatarBeneficioProtecao = () => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);
  const { response, loading, fetchData } = usePeco<
    IPayloadResgatarBeneficioProtecao,
    IResponseResgatarBeneficioProtecao
  >({
    api: {
      operationPath: PECOS.BeneficioProtecao,
    },
    autoFetch: true,
    payload: {
      codigoEmpresa: DefaultParamRequest.CODIGO_EMPRESA,
      codigoCertificado: numCertificado,
    },
  });
  const erro: string | undefined = response?.mensagens?.[0].descricao;

  return { response, loading, fetchData, erro };
};

export default useResgatarBeneficioProtecao;
