// @ts-nocheck
import { Text } from '@cvp/design-system/react';

import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { formatarPlural } from 'main/utils/string';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

export const CERTIFICADO_INVALIDO =
  'O plano selecionado para transferência entre certificados não é apto a este tipo de transação. Por favor, valide a sua escolha e tente novamente. ';

export const COBERTURAS_ANTIGAS = {
  INICIO:
    'Os benefícios de proteção do plano de origem que não são mais comercializados ',
  MEIO: 'serão cancelados caso siga com a transferência TOTAL. ',
  FIM: 'Deseja continuar?',
};

export const COBERTURAS_NAO_COMERCIALIZADAS = {
  TITULO: 'Benefícios de Proteção que não são mais comercializados:',
  LISTA: [
    'Renda de aposentadoria.',
    'Pensão ao cônjuge ou companheiro.',
    'Pensão aos Filhos e/ou dependentes menores.',
  ],
};

export const NUM_ETAPAS = {
  PLANO_DESTINO: 1,
  TIPO_TRANSFERENCIA: 2,
  DISTRIBUICAO_FUNDOS: 3,
  BENEFICIO_PROTECAO: 4,
  DEFINIR_CONTRIBUICAO_REGULAR: 5,
  EFETUAR_ASSINATURA: 6,
};

export const ETAPAS = [
  'Selecionar Plano de Destino',
  'Selecionar Tipo de Transferência',
  'Distribuição entre fundos',
  'Benefício de Proteção',
  'Contribuição Regular',
  'Efetuar Assinatura',
];

export const URL_TRANSFERENCIA = {
  BASE: 'transferencia-certificados-existentes',
  TIPO_TRANSFERENCIA: '/tipo-transferencia',
  DISTRIBUICAO_FUNDOS: '/distribuicao-fundos',
  BENEFICIO_PROTECAO: '/beneficio-protecao',
  EFETUAR_ASSINATURA: '/efetuar-assinatura',
  ASSINATURA_MANUAL: '/assinatura-manual',
  COMPROVANTE: '/comprovante',
};

export const CARD_COBERTURA = {
  PECULIO: '+ Pecúlio',
  SEM_COBERTURA: '- Não há cobertura de risco',
};

export const VALOR_MINIMO_PERMANENCIA = (
  vlrMinPermancenciaFundo: string | undefined,
): React.ReactNode => (
  <>
    Caso a opção seja parcial, um valor mínimo de{' '}
    {tryGetMonetaryValueOrDefault(vlrMinPermancenciaFundo)} deverá permanecer no
    fundo.
  </>
);

export const DISTRIBUICAO_ENTRE_FUNDOS = ({
  fundosDestino,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IDistribuicaoEntreFundos) => ({
  DESCRICAO: (
    <>
      <Text variant="body01-sm" margin>
        Selecione como o cliente deseja distribuir o valor de reserva entre o(s)
        fundo(s) de investimento. Você pode ajustar o percentual ou valor em
        cada fundo como preferir, de acordo com a regra do produto, em até{' '}
        {fundosDestino.numMaxFundos} fundos.
      </Text>
      <Text variant="body01-sm" margin>
        <b>{fundosDestino.qndDispFundoExistente}</b>{' '}
        {formatarPlural({
          palavra: 'fundo',
          quantidade: fundosDestino.qndDispFundoExistente,
        })}{' '}
        {formatarPlural({
          palavra: 'existente',
          quantidade: fundosDestino.qndDispFundoExistente,
        })}{' '}
        / <b>{fundosDestino.qndDispNovoFundo}</b>{' '}
        {formatarPlural({
          palavra: 'novo',
          quantidade: fundosDestino.qndDispNovoFundo,
        })}{' '}
        {formatarPlural({
          palavra: 'fundo',
          quantidade: fundosDestino.qndDispNovoFundo,
        })}
        .
      </Text>
    </>
  ),
  LABEL_TOTAL_TRANSFERENCIA: 'Valor total a transferir:',
  MSG_AVISO:
    'Essa não é uma simulação e após confirmada não poderá ser cancelada.',
});

export const MSG_ERRO_API = {
  LISTA_INFO_CERTIFICADO:
    'O Certificado de origem não possui outros certificados semelhantes que permitam a transferência ',
  ERRO_CONFIRMAR: 'Ocorreu um erro ao confirmar a transferência,',
};

export const TOTAL_PERCENTUAL = 100;

export const PROPRIEDADES_OBJETO = {
  MANTER_BENEFICIO: 'manterBeneficio',
};

export const VALIDAR_TYPES = {
  BOOLEAN: 'boolean',
};

export const OPCOES_PROTECAO_MANTER: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IOpcaoBeneficioProtecao[] =
  [
    {
      id: 'C',
      value: 'Contribuição',
    },
    {
      id: 'B',
      value: 'Importância Segurada',
    },
  ];

export const TEXTOS_BENEFICIO_PROTECAO = {
  DESCRICAO:
    'Caso a transferência seja total, os benefícios de proteção que não são mais comercializados, serão cancelados ao final desta transação.',
  TIPO_BENEFICIO: {
    PECULIO: 'Pecúlio por Morte',
  },
  MSG_ALERTA:
    'Caso o cliente possua benefício de proteção em ambos certificados, ele deverá optar pela contribuição ou Importância Segurada.',
  INFO_TABUA_ATUARIAL:
    'A alteração da tábua atuarial nos processos de transferência interna, implicará automaticamente na mudança do valor da Importância Segurada.',
};

export const MODAL_VALIDAR_VALORES = (
  vlrMinPermancenciaFundo: string | undefined,
) => ({
  TITULO: 'Atenção',
  TRANSFERENCIA_PARCIAL_SALDO_MINIMO: (
    <>
      Deve ser deixado em transferência parcial o saldo mínimo de{' '}
      {tryGetMonetaryValueOrDefault(vlrMinPermancenciaFundo)} nos seguintes
      fundos:
    </>
  ),
  VALOR_MINIMO_TRANSFERENCIA:
    'Valor mínimo de transferência não respeitado para os seguintes fundos:',
});
