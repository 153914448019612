// @ts-nocheck
import { Button, Display } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import ModalTipoDocuSign from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import useAssinaturaTransferencia from 'previdencia/features/TransferenciaEntreCertificados/hooks/useAssinaturaTransferencia';
import TransferenciaWrapper from '../components/TransferenciaWrapper/TransferenciaWrapper';
import CardCertificados from '../components/CardCertificados/CardCertificados';
import GridCardSkeleton from 'main/components/GridCardSkeleton';
import DisclaimerMsgError from '../components/DisclaimerMsgError';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';

const EfetuarAssinaturaTransferencia = () => {
  const {
    openModalAviso,
    dadosCliente,
    urlAssinatura,
    openModalDocusign,
    exibirBotaoFecharModal,
    loading,
    loadingConfirmar,
    transferenciaConfirmada,
    reiniciarTransferencia,
    handleTipoAssinatura,
    handleAssinaturaManual,
    handleAbirModalAviso,
    handleCloseModalAviso,
    handleCloseModal,
  } = useAssinaturaTransferencia();

  return (
    <TransferenciaWrapper>
      <CardCertificados />
      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !loadingConfirmar,
          transferenciaConfirmada,
        ])}
      >
        <Display style={{ margin: '50px 0 0 0' }}>
          <Button variant="outlined" onClick={handleAssinaturaManual}>
            Assinatura manual
          </Button>

          <Button variant="outlined" onClick={handleAbirModalAviso}>
            Assinatura Eletrônica
          </Button>
        </Display>

        <RenderConditional condition={openModalAviso}>
          <ModalTipoDocuSign
            open={openModalAviso}
            executarAssinatura={handleTipoAssinatura}
            handleClose={handleCloseModalAviso}
            tituloModal="FOP - Transferência entre Fundos"
            loading={loading}
            dadosCliente={dadosCliente}
          />
        </RenderConditional>
        <RenderConditional
          condition={checkIfAllItemsAreTrue([
            openModalDocusign,
            !!urlAssinatura,
          ])}
        >
          <ModalTokenDocuSign
            open={openModalDocusign}
            urlTokenPage={urlAssinatura}
            handleClose={handleCloseModal}
            exibirBotaoFecharModal={exibirBotaoFecharModal}
          />
        </RenderConditional>
      </RenderConditional>
      <RenderConditional condition={loadingConfirmar}>
        <Display type="display-block" style={{ margin: '50px 0' }}>
          <GridCardSkeleton quantidadeColunas={4} />
        </Display>
      </RenderConditional>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !loadingConfirmar,
          !transferenciaConfirmada,
        ])}
      >
        <Display style={{ margin: '20px 0' }}>
          <DisclaimerMsgError
            mensagem={CONSTS_TEXT.MSG_ERRO_API.ERRO_CONFIRMAR}
          />
        </Display>
        <Display>
          <Button onClick={() => reiniciarTransferencia()}>Voltar</Button>
        </Display>
      </RenderConditional>
    </TransferenciaWrapper>
  );
};

export default EfetuarAssinaturaTransferencia;
