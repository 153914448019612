// @ts-nocheck
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as FACTORY from 'previdencia/features/TransferenciaEntreCertificados/factories/obterCoberturaValidaFactory';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const useCoberturasPorCertificado =
  (): TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ICoberturasPorCertificado[] => {
    const { featureData } =
      usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

    const coberturasCertificadoOrigem = FACTORY.obterCoberturaValidaFactory({
      idTipoCertificado: CONSTS.ID_TIPO_CERTIFICADO.ORIGEM,
      tipoCertificado: featureData?.certificadoOrigem,
    });

    const coberturasCertificadoDestino = FACTORY.obterCoberturaValidaFactory({
      idTipoCertificado: CONSTS.ID_TIPO_CERTIFICADO.DESTINO,
      tipoCertificado: featureData?.certificadoDestinoSelecionado,
    });

    const coberturasPorCertificado: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ICoberturasPorCertificado[] =
      [];

    coberturasCertificadoOrigem?.forEach(certificadoOrigem => {
      coberturasCertificadoDestino?.forEach(certificadoDestino => {
        if (certificadoOrigem?.cobertura === certificadoDestino?.cobertura) {
          coberturasPorCertificado.push({
            certificadoOrigem,
            certificadoDestino,
          });
        }
      });

      const isNotExistsCertificadoDestino: boolean = checkIfAllItemsAreTrue([
        !!certificadoOrigem?.cobertura,
        !coberturasCertificadoDestino?.some(
          certificadoDestino =>
            certificadoDestino?.cobertura === certificadoOrigem?.cobertura,
        ),
      ]);

      if (isNotExistsCertificadoDestino) {
        coberturasPorCertificado.push({
          certificadoOrigem,
          certificadoDestino: {
            ...certificadoOrigem,
            id: CONSTS.ID_TIPO_CERTIFICADO.DESTINO,
            isSelectDisabled: true,
          },
        });
      }
    });

    return coberturasPorCertificado.filter(item =>
      checkIfSomeItemsAreTrue([
        !!Object.keys(item.certificadoOrigem).length,
        !!Object.keys(item.certificadoDestino).length,
      ]),
    );
  };

export default useCoberturasPorCertificado;
