// @ts-nocheck
export enum EnumTipo {
  VIDA = 'vida',
  PREVIDENCIA = 'previdência',
  PREST = 'prest',
  PREV_SIMULADOR = 'prev_simulador',
  PREV_ADESAO = 'prev_adesao',
  PREV_MANUTENCAO = 'prev_manutencao',
  CORP = 'corp_outros',
  PREV_PJ = 'prev_pj',
  PREV_SAIDA = 'prev_saida',
  PREV_OUTROS = 'prev_outros',
  PREST_VIDA = 'prest_vida',
}

export enum EnumTipoLabel {
  VIDA = 'Vida',
  PREVIDENCIA = 'Previdência',
  PREST = 'Prestamista',
  PREV_SIMULADOR = 'Simuladores de Previdência',
  PREV_ADESAO = 'Adesão de Previdência',
  PREV_MANUTENCAO = 'Manutenção de Previdência',
  CORP = 'Outros',
  PREV_PJ = 'Previdência Empresarial',
  PREV_SAIDA = 'Previdência - Saída',
  PREV_OUTROS = 'Previdência - Outros',
  PREST_VIDA = 'Prestamista/Vida',
}

export enum EnumIndicadorUso {
  DOWNLOAD = 'D',
  EMAIL = 'E',
  ANEXO = 'A',
}
