// @ts-nocheck
import { Checkbox } from '@cvp/design-system/react';

import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const ColunaSelecionarFundoDistribuicaoEntreFundos = ({
  row,
  dadosResumoFundosSelecionados,
  handleFundoSelecionado,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunaSelecionarFundoDistribuicaoEntreFundosProps) => {
  return (
    <Checkbox
      id={row.codFundo}
      disabled={checkIfAllItemsAreTrue([
        !row.selecionado,
        dadosResumoFundosSelecionados.isDisabledFundos,
      ])}
      checked={row.selecionado}
      onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
        handleFundoSelecionado(e.target.id);
      }}
    />
  );
};

export default ColunaSelecionarFundoDistribuicaoEntreFundos;
