// @ts-nocheck
import { registerAppVersion } from 'config/app';
import { registrarInterceptors } from 'config/http/interceptors';
import {
  menuAtendimento,
  menuConsulta,
  menuExternoPrevidencia,
  menuExternoVida,
  menuPosicaoConsolidada,
  menuContratosPrestamista,
  menuPrevidencia,
  menuVida,
  menuRelatoriosInadimplencia,
  menuPrestamista,
  menuEvolucaoPatrimonial,
  menuAdministracao,
  menuDPSEletronica,
} from 'config/menus';
import ModalNewFeatureByPeriod from 'main/components/ModalNewFeature/ModalNewFeatureByPeriod';
import ThemeProvider from 'main/components/ThemeProvider';
import AppContextProvider from 'main/contexts/AppContext';
import { AuthProvider } from 'main/features/Auth/contexts/AuthContext';
import { ToastContainer } from 'main/styles/GlobalStyle';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'routes';

registrarInterceptors();
registerAppVersion();

const App: React.FunctionComponent = () => (
  <QueryClientProvider client={new QueryClient()}>
    <ThemeProvider>
      <AppContextProvider
        menus={[
          menuConsulta,
          menuExternoPrevidencia,
          menuContratosPrestamista,
          menuExternoVida,
          menuAtendimento,
          menuPosicaoConsolidada,
          menuRelatoriosInadimplencia,
          menuEvolucaoPatrimonial,
          menuAdministracao,
          menuDPSEletronica,
        ]}
        contextualMenus={[menuPrevidencia, menuVida, menuPrestamista]}
      >
        <AuthProvider>
          <Router>
            <Routes>
              <ModalNewFeatureByPeriod />
            </Routes>
            <ToastContainer />
          </Router>
        </AuthProvider>
      </AppContextProvider>
    </ThemeProvider>
    <ReactQueryDevtools
      initialIsOpen={process.env.NODE_ENV === 'development'}
    />
  </QueryClientProvider>
);

export default App;
