// @ts-nocheck
import { FormikProps } from 'formik';

import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  tipoContasBancariasCefMain,
  tipoContasBancariasNaoCefMain,
} from 'main/constants/tipoContasBancariasMain';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { CODIGO_BANCO_CAIXA } from 'main/utils/formatarNumeroContaCaixa';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { PREV_REDIRECTS_SCREENS } from 'previdencia/config/redirects';
import { ALIQUOTA } from 'previdencia/constants/constants';
import { IListarFundosParaResgateFundosDisponiveis } from 'previdencia/types/ConsultaListaFundosParaResgate';
import { TAlicotasAgrupadasFundosAliquotaFactory } from 'previdencia/types/AlicotasAgrupadas';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

/**
 * Valida se um valor solicitado atende ao valor mínimo exigido para resgate.
 *
 * @param {string | undefined} value - O valor solicitado para resgate, que pode ser uma string numérica ou indefinido.
 * @param {number} valorMinimoResgate - O valor mínimo exigido para permitir o resgate.
 *
 * @returns {boolean} Retorna `true` se o valor solicitado for válido e maior ou igual ao valor mínimo de resgate. Caso contrário, retorna `false`.
 */
export const validarValorMinimoSolicitado = (
  value: string | undefined,
  valorMinimoResgate: number,
): boolean => {
  if (!value) return false;
  if (Number(value) < valorMinimoResgate) return false;
  return true;
};

/**
 * Valida se um valor solicitado não excede o valor máximo permitido para resgate.
 *
 * @param {string | undefined} value - O valor solicitado para resgate, que pode ser uma string numérica ou indefinido.
 * @param {number} maximoResgate - O valor máximo permitido para o resgate.
 *
 * @returns {boolean} Retorna `true` se o valor solicitado for válido e menor ou igual ao valor máximo de resgate. Caso contrário, retorna `false`.
 */
export const validarValorMaximoSolicitado = (
  value: string | undefined,
  maximoResgate: number,
): boolean => {
  if (!value) return false;
  if (Number(value) > maximoResgate) return false;
  return true;
};

/**
 * Calcula o valor restante e o valor total a ser retirado com base nos valores inseridos no formulário de resgate.
 *
 * @param {FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesSimulacaoResgate>} formik
 *
 * @returns {{ calculoRestante: number, calculoTotal: number }}
 *  - `calculoRestante`: O valor restante após somar as retiradas dos fundos selecionados.
 *  - `calculoTotal`: O valor total das retiradas dos fundos selecionados.
 */
export const calcularValorRestante = (
  formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesSimulacaoResgate>,
) => {
  const valorRetirarSomado = formik.values.listaFundosParaResgate
    .filter(fundo =>
      checkIfAllItemsAreTrue([!!fundo.selecionado, !!fundo.valorRetirar]),
    )
    .reduce((acumulador, current) => {
      return acumulador + Number(current.valorRetirar);
    }, 0);

  const calculoTotal = +valorRetirarSomado.toFixed(2);

  const calculoRestante =
    Number(formik.values.valorSolicitado) -
    tryGetValueOrDefault([calculoTotal], 0);

  return {
    calculoRestante,
    calculoTotal,
  };
};

/**
 * Valida se a simulação de resgate pode continuar, verificando os fundos selecionados, os valores de retirada e o valor restante.
 *
 * @param {FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesSimulacaoResgate>} formik
 *
 * @returns {boolean} Retorna `true` se a simulação estiver válida para continuar. Caso contrário, retorna `false`.
 */
export const continuarSimulacaoResgate = (
  formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesSimulacaoResgate>,
): boolean => {
  const hasFundoPreenchido = formik.values.listaFundosParaResgate.some(
    fundo => {
      return checkIfAllItemsAreTrue([
        !!fundo.selecionado,
        !checkIfSomeItemsAreTrue([
          !fundo.valorRetirar,
          Number(fundo.valorRetirar) <= 0,
        ]),
      ]);
    },
  );

  const allSelectedHaveValidValue = formik.values.listaFundosParaResgate.every(
    fundo =>
      checkIfSomeItemsAreTrue([
        !fundo.selecionado,
        checkIfAllItemsAreTrue([
          !!fundo.valorRetirar,
          Number(fundo.valorRetirar) > 0,
        ]),
      ]),
  );

  const isValid = checkIfAllItemsAreTrue([
    hasFundoPreenchido,
    allSelectedHaveValidValue,
  ]);

  return checkIfAllItemsAreTrue([
    calcularValorRestante(formik).calculoRestante === 0,
    isValid,
  ]);
};

/**
 * Valida os dados de resgate em uma tabela, verificando se os valores respeitam os limites mínimos e máximos de retirada.
 *
 * @param {IListarFundosParaResgateFundosDisponiveis} row - Objeto representando um fundo disponível para resgate, contendo informações como valor a retirar e saldo total.
 * @param {number} valorMinimoResgate - O valor mínimo permitido para retirada.
 * @param {boolean} isTipoResgateParcial - Indica se o tipo de resgate é parcial.
 * @param {string} [valor] - Valor opcional inserido pelo usuário, que pode ser usado na validação.
 *
 * @returns {{ error: boolean, errorMessage: string }} Um objeto contendo:
 *  - `error`: `true` se houver erro na validação, `false` caso contrário.
 *  - `errorMessage`: A mensagem de erro correspondente, ou uma string vazia se não houver erro.
 */
export const errorValidationFormDataTable = (
  row: IListarFundosParaResgateFundosDisponiveis,
  valorMinimoResgate: number,
  isTipoResgateParcial: boolean,
  valor?: string,
) => {
  if (
    checkIfAllItemsAreTrue([
      isTipoResgateParcial,
      !!row.selecionado,
      Number(tryGetValueOrDefault([row.valorRetirar], valor)) <
        valorMinimoResgate,
    ])
  ) {
    return {
      error: true,
      errorMessage: `Valor mínimo de retirada ${tryGetMonetaryValueOrDefault(
        valorMinimoResgate,
      )}`,
    };
  }

  if (
    checkIfAllItemsAreTrue([
      isTipoResgateParcial,
      !!row.selecionado,
      Number(row.valorRetirar) > row.saldoTotal,
    ])
  ) {
    return {
      error: true,
      errorMessage: `Valor máximo de retirada ${tryGetMonetaryValueOrDefault(
        row.saldoTotal - row.valorMinimoPermanencia,
      )}`,
    };
  }

  return { error: false, errorMessage: '' };
};

/**
 * Retorna o valor máximo de caracteres permitido para o campo de conta com base nas regras do banco CAIXA e no tipo de conta.
 *
 * @param {SOLICITACAO_RESGATE_TYPES.IFormikValuesEfetuarResgateNovaConta} novaConta - Objeto representando os dados da nova conta do formulário de resgate.
 *
 * @returns {string} O valor máximo de caracteres permitido para o campo de conta, variando conforme o tipo de operação e o banco Caixa.
 *  - Retorna o valor máximo para contas do tipo NSGD ou SIDEC.
 *  - Caso nenhuma dessas condições seja atendida, retorna o valor máximo padrão.
 */
export const obterMaxlengthContaCaixa = (
  novaConta: SOLICITACAO_RESGATE_TYPES.IFormikValuesEfetuarResgateNovaConta,
): string => {
  if (
    checkIfAllItemsAreTrue([
      novaConta.banco.value === CONSTS.DADOS_BANCO_CONTA.NUM_BANCO_CAIXA,
      novaConta.tipoConta.codigo.length ===
        CONSTS.DADOS_BANCO_CONTA.QTD_CHAR_TIPO_CONTA_OPERACAO.NSGD,
    ])
  ) {
    return CONSTS.DADOS_BANCO_CONTA.QTD_CHAR_INPUT.NSGD;
  }

  if (
    checkIfAllItemsAreTrue([
      novaConta.banco.value === CONSTS.DADOS_BANCO_CONTA.NUM_BANCO_CAIXA,
      novaConta.tipoConta.codigo.length ===
        CONSTS.DADOS_BANCO_CONTA.QTD_CHAR_TIPO_CONTA_OPERACAO.SIDEC,
    ])
  ) {
    return CONSTS.DADOS_BANCO_CONTA.QTD_CHAR_INPUT.SIDEC;
  }

  return CONSTS.DADOS_BANCO_CONTA.QTD_CHAR_INPUT.MAXIMA;
};

/**
 * Calcula o valor restante e o valor total para a contribuição regular com base nos fundos selecionados e seus respectivos valores de contribuição.
 *
 * @param {FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesDefinicaoContribuicaoRegular>} formik
 * @param {number} valorContribuicaoRegularlAtual
 *
 * @returns {{ calculoRestante: number, calculoTotal: number }} Objeto contendo:
 *  - `calculoRestante`: O valor restante após subtrair a soma dos valores de contribuição dos fundos selecionados.
 *  - `calculoTotal`: O valor total retirado a partir dos fundos selecionados.
 */
export const calcularValorRestanteContribuicaoRegular = (
  formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesDefinicaoContribuicaoRegular>,
  valorContribuicaoRegularlAtual: number,
) => {
  const valorRetirarSomado = formik.values.listaFundosParaContribuicaoRegular
    .filter(fundo =>
      checkIfAllItemsAreTrue([!!fundo.selecionado, !!fundo.valorContribuicao]),
    )
    .reduce((acumulador, current) => {
      return acumulador + Number(current.valorContribuicao);
    }, 0);

  const calculoRestante = valorContribuicaoRegularlAtual - valorRetirarSomado;

  return {
    calculoRestante,
    calculoTotal: valorRetirarSomado,
  };
};

/**
 * Retorna os tipos de contas bancárias disponíveis com base no número do banco fornecido.
 *
 * @param {string | number} numeroBanco - O código do banco, que pode ser uma string ou um número.
 *
 * @returns {Array} Uma lista de tipos de contas bancárias:
 *  - Se o banco for a CAIXA (código específico), retorna os tipos de conta da CEF.
 *  - Caso contrário, retorna os tipos de conta de outros bancos.
 */
const obterTiposContasBancarias = (numeroBanco: string | number) => {
  if (String(numeroBanco) === CODIGO_BANCO_CAIXA)
    return tipoContasBancariasCefMain;

  return tipoContasBancariasNaoCefMain;
};

/**
 * Obtém o tipo de conta bancária correspondente ao código do banco e ao número do tipo de conta fornecidos.
 *
 * @param {string} codigoBanco - O código do banco para identificar os tipos de conta disponíveis.
 * @param {string} numeroTipoConta - O número do tipo de conta que se deseja obter.
 *
 * @returns {{ codigo: string, descricao: string }} Um objeto representando o tipo de conta bancária:
 *  - `codigo`: O código do tipo de conta. Retorna '-' se não encontrado.
 *  - `descricao`: A descrição do tipo de conta. Retorna '-' se não encontrado.
 */
const obterTipoContaBancariaPorCodigo = (
  codigoBanco: string,
  numeroTipoConta: string,
) => {
  let retorno = {
    codigo: '-',
    descricao: '-',
  };

  obterTiposContasBancarias(codigoBanco).forEach(tipoContaBancaria => {
    if (tipoContaBancaria.codigo === numeroTipoConta) {
      retorno = tipoContaBancaria;
    }
  });

  return retorno;
};

/**
 * Configura e retorna a descrição do tipo de conta bancária com base no código do banco e no código do tipo de conta.
 *
 * @param {string} codigoBanco - O código do banco para identificar o tipo de conta a ser configurado.
 * @param {string} codigoTipoContaBancaria - O código do tipo de conta bancária (por exemplo, conta corrente ou poupança).
 *
 * @returns {string} A descrição do tipo de conta bancária correspondente. Retorna '-' se os códigos fornecidos forem inválidos ou não configurados.
 */
export const configurarTipoConta = (
  codigoBanco: string,
  codigoTipoContaBancaria: string,
) => {
  if (checkIfSomeItemsAreTrue([!codigoBanco, !codigoTipoContaBancaria])) {
    return '-';
  }

  let numeroTipoConta = '';

  if (codigoTipoContaBancaria === CONSTS.CONTA_BANCARIA.SIGLA_CONTA_CORRENTE) {
    numeroTipoConta = CONSTS.CONTA_BANCARIA.CODIGO_CONTA_CORRENTE;
  }

  if (codigoTipoContaBancaria === CONSTS.CONTA_BANCARIA.SIGLA_CONTA_POUPANCA) {
    numeroTipoConta = CONSTS.CONTA_BANCARIA.CODIGO_CONTA_POUPANCA;
  }

  const tipoConta = obterTipoContaBancariaPorCodigo(
    codigoBanco,
    numeroTipoConta,
  );

  return tipoConta?.descricao;
};

/**
 * Configura e retorna o código da conta bancária formatado com o dígito verificador.
 *
 * @param {string} codigoContaBancaria - O código da conta bancária a ser formatado.
 * @param {string} digitoConta - O dígito verificador da conta bancária.
 *
 * @returns {string} O código da conta bancária formatado como "codigoContaBancaria-digitoConta".
 *  Retorna '-' se os parâmetros fornecidos forem inválidos ou não configurados.
 */
export const configurarCodigoContaBancaria = (
  codigoContaBancaria: string,
  digitoConta: string,
) => {
  if (checkIfSomeItemsAreTrue([!codigoContaBancaria, !digitoConta])) return '-';

  return `${codigoContaBancaria}-${digitoConta}`;
};

/**
 * Retorna uma mensagem de fluxo de solicitação com base nos dados do serviço e configura uma mensagem temporária se necessário.
 *
 * @template T
 * @param {IHandleReponseResult<T> | undefined} dadosServico - Os dados do serviço contendo informações sobre a solicitação, incluindo mensagens e indicadores de sucesso.
 * @param {function(string=): void} configurarMensagemTemporaria - Função para configurar uma mensagem temporária, que pode ser chamada com uma mensagem específica.
 *
 * @returns {boolean} Retorna `true` se a solicitação foi bem-sucedida e não há mensagens de erro a serem exibidas.
 *  Retorna `false` e configura a mensagem temporária se houver um erro a ser exibido.
 */
export const retornarMensagemFluxoSolicitacao = <T>(
  dadosServico: IHandleReponseResult<T> | undefined,
  configurarMensagemTemporaria: (mensagem?: string) => void,
  mensagemCustom?: string,
) => {
  const listaMensagens = dadosServico?.mensagens;

  const mensagem = tryGetValueOrDefault([listaMensagens?.[0]?.descricao], '');

  const isSucesso: boolean = checkIfAllItemsAreTrue([
    !!dadosServico?.sucessoBFF,
    !!dadosServico?.sucessoGI,
  ]);

  const isExibirMensagemErro: boolean = checkIfAllItemsAreTrue([
    !isSucesso,
    !!listaMensagens?.length,
  ]);

  if (isExibirMensagemErro) {
    configurarMensagemTemporaria(
      tryGetValueOrDefault([mensagemCustom, mensagem], ''),
    );

    return false;
  }

  return true;
};

/**
 * Verifica se a origem do redirecionamento é uma das telas de histórico específicas.
 *
 * @param {PREV_REDIRECTS_SCREENS} location - A localização atual, representando a tela de redirecionamento.
 *
 * @returns {boolean} Retorna `true` se a localização corresponde a "HISTORICO_SOLICITACOES" ou "HISTORICO_CANCELAMENTO_RESGATE".
 *  Retorna `false` caso contrário.
 */
export const verificarOrigemRedirectHistorico = (
  location: PREV_REDIRECTS_SCREENS,
): boolean => {
  return checkIfSomeItemsAreTrue([
    location === PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES,
    location === PREV_REDIRECTS_SCREENS.HISTORICO_CANCELAMENTO_RESGATE,
  ]);
};

/**
 * Obtém o resumo da alíquota selecionada com base no tipo de regime tributário e nos dados fornecidos.
 *
 * @param {IObterResumoAliquotaSelecionada} params - Objeto contendo os parâmetros necessários para calcular o resumo da alíquota.
 * @param {string} params.aliquotaAtual - O tipo de alíquota atual (pode ser progressivo ou regressivo).
 * @param {IObterResumoAliquotaReturnFactory[]} params.resumoAliquotaProgressiva - Resumo da alíquota progressiva, representado como um array de objetos.
 * @param {IObterResumoAliquotaReturnFactory[]} params.resumoAliquotaRegressiva - Resumo da alíquota regressiva, representado como um array de objetos.
 * @param {IObterResumoAliquotaReturnFactory[]} params.dadosResumoSelecionado - Dados do resumo selecionado quando não há alíquota atual, representado como um array de objetos.
 * @param {string} params.opcaoRegimeTributario - A opção do regime tributário a ser utilizada quando a alíquota atual está vazia.
 *
 * @returns {{ resumo: IObterResumoAliquotaReturnFactory[], tipoAliquota: string }} Um objeto contendo o resumo da alíquota e o tipo de alíquota.
 */
export const obterResumoAliquotaSelecionada = ({
  indicadorPermiteEditarAliquota,
  aliquotaAtual,
  resumoAliquotaProgressiva,
  resumoAliquotaRegressiva,
  dadosResumoSelecionado,
  opcaoRegimeTributario,
}: SOLICITACAO_RESGATE_TYPES.IObterResumoAliquotaSelecionada) => {
  let resumo;
  let tipoAliquota;

  if (aliquotaAtual === ALIQUOTA.TIPO_REGIME_PROGRESSIVO) {
    resumo = resumoAliquotaProgressiva;
    tipoAliquota = aliquotaAtual;
  }

  if (aliquotaAtual === ALIQUOTA.TIPO_REGIME_REGRESSIVO) {
    resumo = resumoAliquotaRegressiva;
    tipoAliquota = aliquotaAtual;
  }

  if (indicadorPermiteEditarAliquota) {
    resumo = dadosResumoSelecionado;
    tipoAliquota = opcaoRegimeTributario;
  }

  return {
    resumo,
    tipoAliquota,
  };
};

/**
 * Obtém o número de resgate consolidado com base no tipo de alíquota e nos dados da etapa de seleção da alíquota.
 *
 * @param {SOLICITACAO_RESGATE_TYPES.IDadosEtapaSelecaoAliquota | undefined} dadosSelecaoAliquota - Os dados da etapa de seleção da alíquota, contendo informações sobre os cálculos das alíquotas progressiva e regressiva.
 * @param {string | undefined} tipoAliquota - O tipo de alíquota selecionado, podendo ser 'progressivo' ou 'regressivo'.
 *
 * @returns {string} O número de resgate consolidado ou uma string vazia, caso não esteja disponível.
 */
export const obterNumeroResgateConsolidado = (
  dadosSelecaoAliquota:
    | SOLICITACAO_RESGATE_TYPES.IDadosEtapaSelecaoAliquota
    | undefined,
  tipoAliquota: string | undefined,
): string => {
  const numeroResgateConsolidado = getTernaryResult(
    tipoAliquota === ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
    dadosSelecaoAliquota?.calculoAliquotaProgressiva?.numeroResgate,
    dadosSelecaoAliquota?.calculoAliquotaRegressiva?.numeroResgate,
  );

  return tryGetValueOrDefault([numeroResgateConsolidado], '');
};

export const desabilitarBotaoRealizarResgate = ({
  indicadorPermiteEditarAliquota,
  dadosResumoSelecionado,
  opcaoRegimeTributario,
}: SOLICITACAO_RESGATE_TYPES.IDesabilitarBotaoRealizarResgateParams): boolean => {
  const isTipoAliquotaSelecionado: boolean = checkIfAllItemsAreTrue([
    indicadorPermiteEditarAliquota,
    !!dadosResumoSelecionado.length,
  ]);

  const isTipoAliquotaPreviamenteSelecionado: boolean = checkIfAllItemsAreTrue([
    !indicadorPermiteEditarAliquota,
    !!opcaoRegimeTributario,
  ]);

  if (isTipoAliquotaSelecionado) return false;

  if (isTipoAliquotaPreviamenteSelecionado) return false;

  return true;
};

export const ordenarListaFundos = (
  lista: Partial<TAlicotasAgrupadasFundosAliquotaFactory>[],
) => {
  return lista.sort((a, b) => Number(b.aliquota) - Number(a.aliquota));
};
