// @ts-nocheck
import React from 'react';
import { TextField, Text, Display } from '@cvp/design-system/react';

import masks from 'main/utils/masks';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import * as S from './styles';

const ColunaValorTransferenciaTipoTransferencia: React.FC<
  TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunaValorTransferenciaTipoTransferenciaProps
> = ({
  row,
  selectedTableInputFocus,
  atribuirParametroInputFocus,
  handleValorTransferencia,
}) => {
  return (
    <Display>
      <S.ContainerInputValorMonetario>
        <TextField
          onFocus={() => {
            atribuirParametroInputFocus(row.codFundo);
          }}
          autoFocus={row.codFundo === selectedTableInputFocus}
          disabled={checkIfSomeItemsAreTrue([
            !row.selecionado,
            !row.tipoTransferenciaFundo,
            row.tipoTransferenciaFundo ===
              CONSTS.TIPOS_TRANSFERENCIA.TOTAL.charAt(0).toUpperCase(),
          ])}
          name={row.codFundo}
          placeholder="R$ 0,00"
          value={masks.currencyInput.mask(row.valorTransferencia)}
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            handleValorTransferencia(
              row.codFundo,
              (Number(masks.currencyInput.unmask(value)) / 100).toFixed(2),
            );
          }}
          helpText={
            <Text variant="caption-02">
              Valor mínimo {tryGetMonetaryValueOrDefault(row.valorMinimo)}
            </Text>
          }
        />
      </S.ContainerInputValorMonetario>
    </Display>
  );
};

export default ColunaValorTransferenciaTipoTransferencia;
