// @ts-nocheck
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { CODIGO_BANCO_CAIXA } from 'main/utils/formatarNumeroContaCaixa';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { usePecoValidarConta } from 'previdencia/hooks/usePecoValidarConta';
import useMensagemTemporaria from 'previdencia/hooks/useMensagemTemporaria';
import {
  usePecoConfirmarResgate,
  usePecoConsultarContribuicaoRegular,
  usePecoCriarMotivoResgate,
  usePecoSalvarDadosPagamento,
} from 'previdencia/features/SolicitacaoResgate/hooks/usePecoSolicitacaoResgate';
import { useHandleReponseResgate } from 'previdencia/features/SolicitacaoResgate/hooks/useHandleResponseResgate';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useHandleEfetuarSolicitacaoResgate = ({
  novaConta,
  formik,
}: SOLICITACAO_RESGATE_TYPES.IUseHandleEfetuarSolicitacaoResgateParams) => {
  const { navigateTo } = usePrevNavigation();

  const { featureData, setFeatureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const isNovaContaCaixa: boolean = checkIfAllItemsAreTrue([
    novaConta.banco.value === CODIGO_BANCO_CAIXA,
    formik.values.isNovaConta,
  ]);

  const { loadingDadosValidacaoConta, validarConta } = usePecoValidarConta(
    FACTORY.payloadValidarNovaContaFactory(novaConta),
  );

  const { salvarDadosPagamento, loadingDadosSalvarDadosPagamento } =
    usePecoSalvarDadosPagamento();

  const { criarMotivoResgate, loadingDadosCriarMotivoResgate } =
    usePecoCriarMotivoResgate();

  const {
    consultarContribuicaoRegular,
    loadingDadosConsultaContribuicaoRegular,
  } = usePecoConsultarContribuicaoRegular();

  const { confirmarResgate, loadingDadosConfirmarResgate } =
    usePecoConfirmarResgate();

  const { handleResponseResgate, exibirMensagensFeedbackResgate } =
    useHandleReponseResgate();

  const { configurarMensagemTemporaria, mensagemTemporaria } =
    useMensagemTemporaria();

  const isLoadingEfetuarResgate = checkIfSomeItemsAreTrue([
    loadingDadosValidacaoConta,
    loadingDadosSalvarDadosPagamento,
    loadingDadosCriarMotivoResgate,
    loadingDadosConsultaContribuicaoRegular,
    loadingDadosConfirmarResgate,
  ]);

  const payloadPagamento = FACTORY.criarPayloadDadosPagamentoFactory(
    featureData,
    formik,
  );

  const validarNovaContaCaixa = async (): Promise<boolean> => {
    if (isNovaContaCaixa) {
      const dadosValidacaoConta = await validarConta();

      if (
        dadosValidacaoConta?.entidade?.codigoRetorno !==
        CONSTS.VALIDACAO_CONTA_CAIXA.CODIGO_VALIDO
      ) {
        configurarMensagemTemporaria(
          CONSTS.VALIDACAO_CONTA_CAIXA.MSG_TEMPORARIA_ERRO,
        );
        return false;
      }
    }

    return true;
  };

  const obterConfirmacaoDadosPagamentos = async () => {
    const dadosPagamento = await salvarDadosPagamento(payloadPagamento);

    return UTILS.retornarMensagemFluxoSolicitacao(
      dadosPagamento,
      configurarMensagemTemporaria,
    );
  };

  const obterConfirmacaoCriacaoMotivoResgate = async () => {
    const dadosCriarMotivoResgate = await criarMotivoResgate({
      codigoDoMotivo: formik.values.motivoResgate,
      numeroDoResgate: featureData?.numeroResgateConsolidado,
    });

    return UTILS.retornarMensagemFluxoSolicitacao(
      dadosCriarMotivoResgate,
      configurarMensagemTemporaria,
    );
  };

  const obterPermissaoContribuicaoRegular = async (): Promise<boolean> => {
    const dadosConsultaContribuicaoRegular = await consultarContribuicaoRegular(
      {
        numeroResgate: featureData?.numeroResgateConsolidado,
      },
    );

    const isPermitidoDefinirContribRegular =
      dadosConsultaContribuicaoRegular?.entidade?.permiteDefinirContribRegular;

    if (isPermitidoDefinirContribRegular) {
      setFeatureData({
        ...featureData,
        dadosConsultaContribuicaoRegular:
          dadosConsultaContribuicaoRegular?.entidade,
      });

      navigateTo('definir-contribuicao');
    }

    return !!isPermitidoDefinirContribRegular;
  };

  const obterConfirmacaoResgate = async (
    isPermitidoDefinirContribRegular: boolean,
  ): Promise<void> => {
    if (!isPermitidoDefinirContribRegular) {
      const dadosConfirmarResgate = await confirmarResgate({
        numeroResgate: featureData?.numeroResgateConsolidado,
      });

      const { sucesso, status, mensagemSucesso } = handleResponseResgate(
        dadosConfirmarResgate,
      );

      const mensagensResgate = dadosConfirmarResgate?.mensagens;

      const mensagem = tryGetValueOrDefault(
        [mensagensResgate?.[0]?.descricao],
        '',
      );

      if (checkIfAllItemsAreTrue([!sucesso, !!mensagensResgate?.length])) {
        configurarMensagemTemporaria(mensagem);

        return;
      }

      exibirMensagensFeedbackResgate(sucesso, status, mensagemSucesso);
    }
  };

  const efetuarSolicitacaoResgate = async (): Promise<void> => {
    const isFalhaValidacaoConta = !(await validarNovaContaCaixa());

    if (isFalhaValidacaoConta) return;

    const isFalhaValidacaoNaConfirmacaoPagamento =
      !(await obterConfirmacaoDadosPagamentos());

    if (isFalhaValidacaoNaConfirmacaoPagamento) return;

    const isFalhaValidacaoNaCriacaoMotivoResgate =
      !(await obterConfirmacaoCriacaoMotivoResgate());

    if (isFalhaValidacaoNaCriacaoMotivoResgate) return;

    const isPermitidoDefinirContribRegular =
      await obterPermissaoContribuicaoRegular();

    obterConfirmacaoResgate(isPermitidoDefinirContribRegular);
  };

  return {
    efetuarSolicitacaoResgate,
    isLoadingEfetuarResgate,
    mensagemTemporaria,
  };
};

export default useHandleEfetuarSolicitacaoResgate;
