// @ts-nocheck
import { Display, Card, Text, Button } from '@cvp/design-system/react';

import For from 'main/components/For';
import Icon from 'main/components/Icon';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import useBeneficioProtecao from 'previdencia/features/TransferenciaEntreCertificados/hooks/useBeneficioProtecao';
import useCoberturasPorCertificado from 'previdencia/features/TransferenciaEntreCertificados/hooks/useCoberturasPorCertificado';
import CardFluxoCobertura from 'previdencia/features/TransferenciaEntreCertificados/components/CardFluxoCobertura';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import TransferenciaWrapper from '../components/TransferenciaWrapper/TransferenciaWrapper';

const BeneficioProtecao = () => {
  const coberturasPorCertificado = useCoberturasPorCertificado();

  const {
    isLoadingSimulacaoRisco,
    isDisableNextStep,
    simulacaoRiscoPorBeneficio,
    opcaoEscolhida,
    handleOpcaoBeneficio,
    definirProximaTela,
  } = useBeneficioProtecao(coberturasPorCertificado);

  return (
    <TransferenciaWrapper>
      <Card style={{ margin: '0px 0px 30px 0px' }}>
        <Card.Content padding={[4, 4, 4]}>
          <Text variant="body01-sm" margin>
            {CONSTS_TEXT.TEXTOS_BENEFICIO_PROTECAO.DESCRICAO}
          </Text>

          <Text variant="body01-sm" color="error">
            <Icon name="warning" />{' '}
            {CONSTS_TEXT.TEXTOS_BENEFICIO_PROTECAO.MSG_ALERTA}
          </Text>
        </Card.Content>
      </Card>

      <For each={tryGetValueOrDefault([coberturasPorCertificado], [])}>
        {cobertura => (
          <CardFluxoCobertura
            cobertura={cobertura}
            simulacaoRiscoPorBeneficio={simulacaoRiscoPorBeneficio}
            opcaoEscolhida={opcaoEscolhida}
            handleOpcaoBeneficio={handleOpcaoBeneficio}
          />
        )}
      </For>

      <Display>
        <Button
          onClick={definirProximaTela}
          disabled={isDisableNextStep}
          loading={isLoadingSimulacaoRisco}
        >
          Avançar
        </Button>
      </Display>
    </TransferenciaWrapper>
  );
};

export default BeneficioProtecao;
