// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import {
  IObterContatosDefaultPrevidencia,
  useObterContatosDefaultPrevidencia,
} from 'main/hooks/useObterContatosDefaultPrevidencia';
import { IMessageEvent } from 'main/types/IMessageEvent';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';

import { Beneficios } from 'previdencia/types/Beneficiarios';
import { useLocation, useHistory } from 'react-router';
import {
  PREV_REDIRECTS_SCREENS,
  PrevRedirectConfig,
} from 'previdencia/config/redirects';
import { Assinante } from 'previdencia/features/AlteracaoBeneficiarios/types/Beneficiarios';
import { AppContext } from 'main/contexts/AppContext';
import { Beneficiarios } from 'previdencia/features/AlteracaoBeneficiarios/pages/index';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import useBeneficiosBeneficiarios from 'previdencia/features/AlteracaoBeneficiarios/hooks/useBeneficiosBeneficiarios';
import useConsultarPermissaoAssinaturaBeneficiario from 'previdencia/features/AlteracaoBeneficiarios/hooks/useConsultarPermissaoAssinaturaBeneficiario';
import useModificarBeneficiarios from 'previdencia/features/AlteracaoBeneficiarios/hooks/useModificarBeneficiario';
import useSolicitarAssinaturaBeneficiarios from 'previdencia/features/AlteracaoBeneficiarios/hooks/useSolicitarAssinaturaBeneficiarios';
import TextoJuridicoAssinatura from 'previdencia/components/TextoJuridicoAssinatura';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import ModalAviso from 'main/components/AssinaturaDocuSign/ModalAviso';
import ModalTipoDocuSign from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import TabelaImpressao from 'previdencia/features/AlteracaoBeneficiarios/components/TabelaImpressao/TabelaImpressao';
import * as CONSTS from 'previdencia/features/AlteracaoBeneficiarios/constants/constants';

const AlteracaoBeneficiarios: React.FC = () => {
  const { state } = useLocation<{
    from: PREV_REDIRECTS_SCREENS;
    data: { idRequisicao: string };
  }>();
  const hasPrevRedirect =
    state?.from === PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES;

  const [dataBeneficiarios, setDataBeneficiarios] = useState<
    Beneficios[] | undefined
  >([]);
  const [imprimirComprovante, setImprimirComprovante] = useState(false);
  const [idRequisicao, setIdRequisicao] = useState('');
  const [exibirModalContatos, setExibirModalContatos] = useState(false);
  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] = useState(true);
  const [openModalDocuSign, setOpenModalDocuSign] = useState<boolean>(false);
  const [openModalAviso, setOpenModalAviso] = useState<boolean>(false);
  const [openModalTipo, setModalTipo] = useState<boolean>(false);
  const [eventSucess, setEventSucess] = useState<boolean>(false);

  const { subscribe, unsubscribe } = useMessageEvent();
  const history = useHistory();

  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  const {
    response: { entidade: benefitsAndBeneficiaries } = {},
    loading: isLoading,
  } = useBeneficiosBeneficiarios();

  const {
    loading: loadingConsultaPermissao,
    response: { entidade: permissaoAssinaturaDigital } = {},
  } = useConsultarPermissaoAssinaturaBeneficiario();

  const {
    response: retornoModificacaoBeneficiarios,
    loading: loadingModificacaoBeneficiarios,
    fetchData: modificarBeneficiarios,
  } = useModificarBeneficiarios();

  const responseModificacaoBeneficiarios =
    retornoModificacaoBeneficiarios?.entidade;

  const {
    data: reponseObterContatosDefault,
    isFetching: loadingObterContatos,
  } = useObterContatosDefaultPrevidencia();

  const { emailDefault, numerosTelefone, numeroDDD, numeroTelefoneSemDDD } =
    tryGetValueOrDefault(
      [reponseObterContatosDefault],
      {} as IObterContatosDefaultPrevidencia,
    );

  const hasDigitalSignaturePermission =
    permissaoAssinaturaDigital === CONSTS.EXISTE_PERMISSAO_ASSINATURA.Sim;

  const construirPayload = () => {
    const assinantes: Assinante[] = [];
    assinantes.push({
      emailAssinante: emailDefault,
      telefones: [
        {
          ddd: numeroDDD,
          telefoneAssinante: numeroTelefoneSemDDD,
        },
      ],
    });
    return assinantes;
  };

  const {
    response: { entidade: responseAssinatura } = {},
    loading: loadingAssinatura,
    fetchData: obterSolicitarAssinaturaBeneficiarios,
  } = useSolicitarAssinaturaBeneficiarios(idRequisicao, construirPayload());

  const handleSolicitarAssinatura = async () => {
    await obterSolicitarAssinaturaBeneficiarios();
    setOpenModalDocuSign(true);
  };

  const handleCloseModal = () => {
    setOpenModalDocuSign(false);
    setModalTipo(false);

    if (!eventSucess) {
      history.push(
        PrevRedirectConfig[PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES],
      );
    }
  };

  const definirAssinaturaPendente = async () => {
    if (checkIfAllItemsAreTrue([hasPrevRedirect, !!state?.data.idRequisicao])) {
      setIdRequisicao(state.data.idRequisicao);

      setOpenModalAviso(true);
    }
  };

  const checkIsLoadingSetId = () => {
    if (
      checkIfAllItemsAreTrue([
        !loadingModificacaoBeneficiarios,
        !!responseModificacaoBeneficiarios,
      ])
    ) {
      setIdRequisicao(
        tryGetValueOrDefault(
          [responseModificacaoBeneficiarios?.codigoRetornoModificacao],
          '',
        ),
      );
      if (
        checkIfAllItemsAreTrue([
          !!responseModificacaoBeneficiarios,
          hasDigitalSignaturePermission,
        ])
      ) {
        setOpenModalAviso(true);
      }
    }
  };

  useEffect(() => {
    if (!benefitsAndBeneficiaries) {
      return;
    }
    setDataBeneficiarios(benefitsAndBeneficiaries.retorno?.beneficios);
  }, [benefitsAndBeneficiaries]);

  useEffect(() => {
    if (hasDigitalSignaturePermission) {
      setExibirModalContatos(true);
    }
  }, [permissaoAssinaturaDigital]);

  useEffect(() => {
    definirAssinaturaPendente();
  }, [state?.from, state?.data?.idRequisicao]);

  useEffect(() => {
    if (checkIfAllItemsAreTrue([!openModalTipo, !eventSucess])) {
      checkIsLoadingSetId();
    }
  }, [modificarBeneficiarios, retornoModificacaoBeneficiarios]);

  useEffect(() => {
    subscribe(item => {
      const {
        data: { eventName, eventSuccess },
      } = item as MessageEvent<IMessageEvent>;

      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setEventSucess(true);
        setImprimirComprovante(true);
        setExibirBotaoFecharModal(true);
        setModalTipo(false);
      }
    });
    return () => {
      unsubscribe(() => {
        setExibirBotaoFecharModal(true);
      });
    };
  }, []);

  return (
    <>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          isLoading,
          loadingConsultaPermissao,
          loadingObterContatos,
          loadingAssinatura,
          !!state?.data?.idRequisicao,
        ])}
      >
        <SkeletonLoading blocks={2} />;
      </RenderConditional>

      <Beneficiarios.Root>
        <Beneficiarios.Card
          imprimirComprovante={imprimirComprovante}
          benefitsAndBeneficiaries={benefitsAndBeneficiaries}
          dataBeneficiarios={dataBeneficiarios}
          permissaoAssinaturaDigital={permissaoAssinaturaDigital}
          loadingAssinatura={loadingAssinatura}
          loadingModificacaoBeneficiarios={loadingModificacaoBeneficiarios}
          modificarBeneficiarios={modificarBeneficiarios}
          hasDigitalSignaturePermission={hasDigitalSignaturePermission}
          idRequisicao={idRequisicao}
        />
        <TabelaImpressao
          benefitsAndBeneficiaries={benefitsAndBeneficiaries}
          dataBeneficiarios={dataBeneficiarios}
        />
        <RenderConditional condition={!state?.data?.idRequisicao}>
          <ModalAlertaContatos
            open={exibirModalContatos}
            onClose={() => setExibirModalContatos(false)}
            redirect={() =>
              history.push('/cliente/produtos/previdencia/dados-participante')
            }
            dados={{
              email: emailDefault,
              telefone: numerosTelefone,
            }}
          />
        </RenderConditional>
        <RenderConditional condition={!!permissaoAssinaturaDigital}>
          <ModalAviso
            titulo="Alteração de Beneficiários"
            openModal={openModalAviso}
            setOpenModal={setOpenModalAviso}
            setModalAssinatura={setModalTipo}
          >
            <TextoJuridicoAssinatura />
          </ModalAviso>
          <ModalTipoDocuSign
            open={openModalTipo}
            executarAssinatura={handleSolicitarAssinatura}
            handleClose={() => handleCloseModal()}
            tituloModal="Alteração de Beneficiários"
            loading={loadingAssinatura}
            dadosCliente={{
              email: emailDefault,
              cpfCnpj,
              mobileNumber: numerosTelefone,
            }}
          />

          <ModalTokenDocuSign
            open={openModalDocuSign}
            urlTokenPage={responseAssinatura?.urlAssinatura}
            handleClose={() => handleCloseModal()}
            exibirBotaoFecharModal={exibirBotaoFecharModal}
          />
        </RenderConditional>
      </Beneficiarios.Root>
    </>
  );
};

export default AlteracaoBeneficiarios;
