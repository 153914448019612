// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import {
  IMFTransferirProdutoRequest,
  IMFTransferirProdutoResponse,
} from '../types/IMFTransferirProduto';
import { PECOS } from 'previdencia/config/endpoints';
import { gerarRequestMFTransferirProduto } from '../factories/gerarRequestMFTransferirProduto';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaAlteracaoTaxaContext } from '../types/ITransferenciaAlteracaoTaxaContext';
import { useAuth } from 'main/features/Auth/hooks';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const usePecoTransferirProduto = () => {
  const { user } = useAuth();
  const { featureData, setFeatureData } =
    usePrevidenciaContext<ITransferenciaAlteracaoTaxaContext>();
  const { fetchData, loading, response } = usePeco<
    IMFTransferirProdutoRequest,
    IMFTransferirProdutoResponse
  >({
    api: { operationPath: PECOS.TransferirProdutoVgbl },
  });

  const transferirProduto = async () => {
    if (featureData) {
      const payload = gerarRequestMFTransferirProduto(featureData, user);
      const responseService = await fetchData(payload);
      const numTransferencia = tryGetValueOrDefault(
        [responseService?.entidade?.dados?.numeroSolicitacao?.toString()],
        '',
      );
      setFeatureData({
        ...featureData,
        numTransferencia,
      });
      return numTransferencia;
    }
    return '';
  };

  return {
    loading,
    transferirProduto,
    response,
  };
};
