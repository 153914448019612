// @ts-nocheck
import { Display, Radio } from '@cvp/design-system/react';

import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const SelecionarTipoTransferencia = ({
  selecionarTipoTransferenciaContexto,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ISelecionarTipoTransferenciaProps) => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  return (
    <Display>
      <Display alignItems="center" style={{ margin: 0 }}>
        <Radio
          name="tipoTransferencia"
          onClick={() => {
            selecionarTipoTransferenciaContexto(
              CONSTS.TIPOS_TRANSFERENCIA.TOTAL,
            );
          }}
          checked={
            featureData?.tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.TOTAL
          }
          value={CONSTS.TIPOS_TRANSFERENCIA.TOTAL}
        />
        Total
      </Display>

      <Display alignItems="center" style={{ margin: 0 }}>
        <Radio
          name="tipoTransferencia"
          onClick={() => {
            selecionarTipoTransferenciaContexto(
              CONSTS.TIPOS_TRANSFERENCIA.PARCIAL,
            );
          }}
          checked={
            featureData?.tipoTransferencia ===
            CONSTS.TIPOS_TRANSFERENCIA.PARCIAL
          }
          value={CONSTS.TIPOS_TRANSFERENCIA.PARCIAL}
        />
        Parcial
      </Display>
    </Display>
  );
};

export default SelecionarTipoTransferencia;
