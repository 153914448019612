// @ts-nocheck
import styled from 'styled-components/macro';
import Calendar from 'main/components/form/Calendar';

export const WrapperSelect = styled.div({
  alignItems: 'left',
  maxWidth: 300,
  marginBottom: 10,
});

export const DisplayCalendar = styled(Calendar)({
  display: 'flex',
  alignItems: 'center',
});
