// @ts-nocheck
import React, { useContext } from 'react';
import { Modal, Text, Display, Button } from '@cvp/design-system/react';
import { TModalConfirmacaoTransferencia } from '../../types/TModalConfirmacaoTransferencia';
import { AppContext } from 'main/contexts/AppContext';

const ModalConfirmacaoTransferencia: React.FC<
  TModalConfirmacaoTransferencia
> = ({ show, onClose, nextStep }) => {
  const { cliente } = useContext(AppContext);
  return (
    <Modal show={show} onClose={onClose}>
      <Text variant="headline-05" color="primary" margin>
        Confirmação
      </Text>
      <Text variant="caption" margin>
        Você está transferindo o(s) recurso(s) do(s) certificado(s) de origem{' '}
        <strong>{cliente?.numCertificado}</strong> para um novo certificado.
        Prazo: até 2 dias úteis. Deseja concluir a transferência?
      </Text>
      <br />

      <Display justify="center">
        <Button variant="secondary" small onClick={onClose}>
          Cancelar
        </Button>
        <Button small onClick={nextStep}>
          Sim
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalConfirmacaoTransferencia;
