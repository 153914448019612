// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { useContext } from 'react';
import {
  ResponseCoberturas,
  IRequestCoberturas,
} from 'previdencia/types/Beneficiarios';

const useBeneficiosBeneficiarios = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IRequestCoberturas,
    ResponseCoberturas
  >({
    api: { operationPath: PECOS.ObterCoberturas },
    autoFetch: true,
    payload: {
      numeroCertificado: numCertificado,
      cpf: cpfCnpj,
    },
  });

  return { loading, response, fetchData };
};

export default useBeneficiosBeneficiarios;
