// @ts-nocheck
import React from 'react';
import BottomNav from 'main/components/Menu/BottomNav';
import BottomNavTab from 'main/components/Menu/BottomNav/BottomNavTab';
import BottomNavRetencao from './BottomNavRetencao';
import { IMenu } from 'main/components/Menu/Sidebar/types';

const CustomBottomNav = () => {
  const profileMenu: IMenu = {
    name: 'Profile',
    type: 'user-profile',
    icon: 'icon-user',
    items: [
      {
        label: 'Meu perfil',
        path: '/perfil',
        alt: 'perfil',
      },
      {
        label: 'Sair',
        path: '/login',
        alt: 'sair',
      },
    ],
  };

  return (
    <BottomNav menuMapProfile={profileMenu}>
      <BottomNavTab icon="menu" label="Menu" slideFrom="left">
        <BottomNavRetencao visibility />
      </BottomNavTab>
    </BottomNav>
  );
};

export default CustomBottomNav;
