// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { useContext } from 'react';
import { tipoEmailConstants } from 'main/constants/tipoEmail';

const useMontarDadosEnviarEmailHistoricoPagamentos = () => {
  const { clienteVida } = useContext(AppContext);
  const montarObjetoEmail = () => {
    return {
      tipoEmail: tipoEmailConstants.HISTORICO_PAGAMENTOS,
      parametrosEnvio: {
        codigoProduto: clienteVida.codigoProduto,
        NumeroCertificadoBilhete: clienteVida.numCertificado,
      },
    };
  };
  return {
    montarObjetoEmail,
  };
};

export default useMontarDadosEnviarEmailHistoricoPagamentos;
