// @ts-nocheck
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useToast } from 'main/hooks/useToast';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { useDadosSegurado } from 'seguros/hooks/useDadosSegurado';
import { AppContext } from 'main/contexts/AppContext';
import { useCertificadoSeguroResumo } from 'seguros/hooks/useCertificadoSeguroResumo';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import CardDadosSegurado from './CardDadosSegurado';

const DadosSegurado = () => {
  const { state } = useLocation<{
    msgAtualizacaoDados: string;
  }>();

  const { toastSuccess } = useToast();

  const { clienteVida } = useContext(AppContext);

  const {
    certificadoSeguros: { response, fetchData: obterDadosCertificadoSeguro },
  } = useCertificadoSeguroResumo({
    numeroContrato: tryGetValueOrDefault([clienteVida?.numCertificado], '0'),
    codigoProduto: tryGetValueOrDefault([clienteVida?.codigoProduto], '0'),
  });

  const { dadosSegurado, loadingDadosSegurado, cpfCnpj } = useDadosSegurado();

  const informacoesSegurado = dadosSegurado?.entidade;

  const dadosCertificadoSeguro = response?.entidade;

  const exibirMsgDadosAtualizadosAposRedirect = () => {
    if (state?.msgAtualizacaoDados) {
      toastSuccess(state?.msgAtualizacaoDados);
    }
  };

  useEffect(() => {
    exibirMsgDadosAtualizadosAposRedirect();
    obterDadosCertificadoSeguro();
  }, []);

  if (loadingDadosSegurado) {
    return <SkeletonLoading blocks={1} />;
  }
  return (
    <CardDadosSegurado
      cpfCnpj={cpfCnpj}
      informacoesSegurado={informacoesSegurado}
      loadingDadosSegurado={loadingDadosSegurado}
      dadosCertificadoSeguro={dadosCertificadoSeguro}
    />
  );
};

export default DadosSegurado;
