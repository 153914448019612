// @ts-nocheck
import { Text } from '@cvp/design-system/react';
import VoltarDadosPlano from 'main/components/Button/voltarDadosPlano/VoltarDadosPlano';
import { SimulacaoRendaNaoPermitidaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import * as S from 'previdencia/features/SimulacaoRenda/components/SimulacaoRendaNaoPermitida/styles';

const SimulacaoRendaNaoPermitida = ({
  mensagemErro,
}: SimulacaoRendaNaoPermitidaProps): React.ReactElement => {
  const [mensagem, telefone] = mensagemErro.split(':');

  return (
    <>
      <S.Container>
        <Text variant="body02-md" margin>
          {mensagem}:
        </Text>
        <Text variant="body02-md" margin>
          {telefone}
        </Text>
      </S.Container>

      <VoltarDadosPlano />
    </>
  );
};

export default SimulacaoRendaNaoPermitida;
