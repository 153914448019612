// @ts-nocheck
import { Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';

export const TEXTO_CODIGO_CIVIL =
  'A validade da indicação de beneficiário realizada nesta manutenção fica condicionada à confirmação de que o telefone e e-mail utilizados na assinatura digital sejam de titularidade do próprio segurado, o que será apurado em caso de sinistro. Caso o segurado não seja o titular, o capital segurado será pago por metade ao cônjuge e o restante aos herdeiros legais, conforme artigo 792 do Código Civil. A efetivação da transação ocorrerá somente após clicar em “Concluir” ao final das etapas da assinatura eletrônica.';

export const TEXTO_PRPOCURADOR =
  'Caso a manutenção da previdência seja feita por procurador ou curador, é imprescindível que o mandato ou termo de curatela contenham poderes específicos para indicar beneficiários. Do contrário, serão considerados beneficiários as pessoas indicadas no artigo 792 do Código Civil.';

export const TEXTO_STATUS_T =
  'Seu certificado está em manutenção e alguns serviços ficarão indisponíveis até a conclusão da transferência. Aguarde. Em até 2 dias úteis será possível realizar as alterações no seu plano de previdência.';

export const TEXTO_TOOLTIP_RESERVA = (
  <>
    <Text variant="body02-sm" align="left" margin>
      <b>A Reserva</b>
      <Icon name="warning" />
    </Text>
    <Text variant="body02-sm" align="left" margin>
      <b>Regular</b> - Se refere à conta Regular Participante Mensal, na qual as
      contribuições são custeadas pelos participantes/segurados de acordo com as
      regras estabelecidas no contrato entre a Caixa Vida e Previdência e a
      Instituidora (Empresa).
    </Text>
    <Text variant="body02-sm" align="left" margin>
      <b>Adicional</b> - Se refere à conta Adicional Participante Mensal, na
      qual as contribuições são custeadas pelos participantes/segurados, podendo
      ser realizadas de forma regular (mensal) ou através de aportes
      esporádicos.
    </Text>
    <Text variant="body02-sm" align="left" margin>
      <b>Migrada</b> - Se refere aos recursos transferidos da conta coletiva
      para a conta individual.
    </Text>
  </>
);
