// @ts-nocheck
import { useState } from 'react';
import {
  tryGetValueOrDefault,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { IResponseConsultaDps } from '../interface/IResponseDps';
import { mockResponseConsultaDps } from '../mock/mockResponseConsultaDps';

export const useFiltrosDps = (obterConsultaVida: IResponseConsultaDps[]) => {
  const [dpsEletronicaData, setDpsEletronicaData] = useState<
    IResponseConsultaDps[]
  >([]);

  const atualizaConsulta = () => {
    setDpsEletronicaData(obterConsultaVida);
  };

  const aplicarFiltros = (cpfCnpj: string) => {
    const listaDpsVida = tryGetValueOrDefault(
      [obterConsultaVida],
      mockResponseConsultaDps,
    );

    const listaFiltroRetorno = listaDpsVida.filter(dps =>
      checkIfSomeItemsAreTrue([dps.cpfCnpj.includes(cpfCnpj)]),
    );

    setDpsEletronicaData(listaFiltroRetorno);
  };

  return {
    dpsEletronicaData,
    setDpsEletronicaData,
    atualizaConsulta,
    aplicarFiltros,
  };
};
