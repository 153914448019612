// @ts-nocheck
import { IFilterOption } from 'main/components/Table/TableFilter/IFilterOption';
import masks from 'main/utils/masks';

export const ROTA_RETENCAO_PRESTAMISTA = '/retencao-prestamista';
export const TITULO_RETENCAO_PRESTAMISTA = 'Retenção de Prestamista';

export const DETALHES_DO_CONTRATO = '';

export const OPCOES_DE_FILTRO_AGENCIAS: IFilterOption[] = [
  {
    key: 'cpfCnpjSegurado',
    value: 'CPF',
    type: 'text',
    unmask: masks.cpf.unmask,
  },
  { key: 'nomeSegurado', value: 'Nome do Segurado', type: 'text' },
  { key: 'numeroContrato', value: 'Contrato', type: 'text' },
  { key: 'dataHoraLimiteReativacao', value: 'Data Limite', type: 'date' },
];

export const TITULO_DE_ATUALIZACAO = 'ATENÇÃO';

export const INFORMATIVO_DE_ATUALIZACAO = [
  'A informação deste relatório corresponde a ontem',
  'As mudanças realizadas hoje serão atualizadas no próximo dia',
];
export const INFORMATIVO_AVISO = [' * São considerados dias úteis'];

export const FEEDBACK_SUCESSO =
  'Retenção realizada com sucesso aguarde até o próximo dia útil para a atualização do painel!';
