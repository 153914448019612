// @ts-nocheck
import { Text } from '@cvp/design-system/react';

import Icon from 'main/components/Icon';
import For from 'main/components/For';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from 'previdencia/features/SolicitacaoResgate/pages/styles';

const ObservacoesCertificado = ({
  mensagensCertificado,
}: SOLICITACAO_RESGATE_TYPES.IObservacoesCertificadoProps) => {
  return (
    <S.ContainerWarning>
      <div>
        <Icon name="warning" />
        <Text color="text-dark" variant="body02-sm">
          {CONSTS.TEXTOS_OBSERVACAO_CERTIFICADO.TITULO_OBS}
        </Text>
      </div>

      <For each={tryGetValueOrDefault([mensagensCertificado], [])}>
        {mensagem => (
          <Text
            id={mensagem.codigo}
            variant="body02-sm"
            data-testid={mensagem.codigo}
          >
            {mensagem.descricao}
          </Text>
        )}
      </For>
    </S.ContainerWarning>
  );
};

export default ObservacoesCertificado;
