// @ts-nocheck
import { Select } from '@cvp/design-system/react';
import {
  familiaFundosRendaFixa,
  familiaFundosRendaVariavel,
} from 'extranet/features/fops/constants/constantsFop223';
import { DynamicSelectFop223Props } from 'extranet/types/DynamicSelectFop223Props';

export const DynamicSelectFop223 = ({
  setFieldValue,
  getDados,
  values,
}: DynamicSelectFop223Props) => {
  let familiaFundosArray: Array<string> = [];

  if (getDados.includes('dadosTransferenciaRendaVarivavel')) {
    familiaFundosArray = familiaFundosRendaVariavel;
  }

  if (getDados.includes('dadosTransferenciaRendaFixa')) {
    familiaFundosArray = familiaFundosRendaFixa;
  }

  if (getDados.includes('fundoContribuicaoRegularRendaFixa')) {
    familiaFundosArray = familiaFundosRendaFixa;
  }

  if (getDados.includes('fundoContribuicaoRegularRendaVariavel')) {
    familiaFundosArray = familiaFundosRendaVariavel;
  }

  return (
    <Select
      label="Fundos"
      placeholder="Escolha a opção"
      onChange={({
        target: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(getDados, value);
      }}
    >
      {familiaFundosArray.map(item => (
        <Select.Item
          key={item}
          value={item}
          text={item}
          selected={values === item}
        />
      ))}
    </Select>
  );
};
