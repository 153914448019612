// @ts-nocheck
import { Radio } from '@cvp/design-system/react';
import { IDadosTabelaContribuicaoRegularProps } from '../../types/TableColumns';
import React from 'react';

const ColunaSelecionarFundo: React.FC<IDadosTabelaContribuicaoRegularProps> = ({
  row,
  handlers,
}) => {
  return (
    <Radio
      id={row?.codigoFundo}
      value={row?.codigoFundo}
      name="fundo"
      checked={row?.indicadorContribuicaoFundo}
      onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (row) handlers.handleSelecionarFundo(row);
      }}
    />
  );
};

export default ColunaSelecionarFundo;
