// @ts-nocheck
import React from 'react';
import { Card, Display, Text, Button } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';

const CardFeatureDescription: React.FC<{
  loadingObterDadosCliente?: boolean;
}> = ({ loadingObterDadosCliente }) => (
  <Display type="display-block">
    <Card>
      <Card.Content>
        <Text variant="headline-04" color="primary" margin={10}>
          Portal do Cliente/App Caixa Vida e Previdência - Status do Cadastro
        </Text>
        <Text variant="caption-02" margin>
          Consulte o status de cadastro do Portal do Cliente/App Caixa Vida e
          Previdência.
        </Text>
        <RenderConditional condition={!!loadingObterDadosCliente}>
          <Button variant="text" loading={loadingObterDadosCliente} />
        </RenderConditional>
      </Card.Content>
    </Card>
  </Display>
);
export default CardFeatureDescription;
