// @ts-nocheck
export const reactQueryCacheDuration = (duration = 60): number =>
  Number(process.env.REACT_APP_CACHE_DURATION) * duration;

export const obterChaveArmazenamentoTokenExpiresIn = () => 'token';

export const obterChaveArmazenamentoMetadadosUsuario = () => 'metadata';

export const obterChaveArmazenamentoNomeAcesso = () => 'nomeAcesso';

export const obterChaveArmazenamentoPermissoesUsuario = () => 'userPermissions';

export const obterChaveArmazenamentoTokenOperador = () => 'tokenOperador';

export const obterChaveArmazenamentoTokenCliente = () => 'tokenCliente';

export const obterChaveArmazenamentoCliente = () => 'cliente';

export const obterChaveArmazenamentoSessionId = () => 'sessionId';

export const obterChaveArmazenamentoModalNovaFeature = () =>
  'AvisoNovaFeatureVisualizado';

export const obterChaveArmazenamentoModalNovaFeaturePorPeriodo = () =>
  'AvisoNovaFeaturePorPeriodoVisualizado';

export const obterChaveArmazenamentoDadosFuncionalidades = () => 'featureData';

export const obterVersaoAplicacao = () => 'appVersion';

export const obterAgenciaDigital = () => 'DigitalAgency';
