// @ts-nocheck
import React from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { Grid, Skeleton } from '@cvp/design-system/react';

type TableSkeletonProps = {
  colunas: IDataTableColumn[];
};

export const TableSkeleton: React.FunctionComponent<TableSkeletonProps> = ({
  colunas,
}) => {
  return (
    <Grid>
      <Grid.Item xs={1}>
        <Grid>
          {colunas.map(col => (
            <Grid.Item xs={1 / colunas.length} key={col.name}>
              <Skeleton lines={10} />
            </Grid.Item>
          ))}
        </Grid>
      </Grid.Item>
    </Grid>
  );
};
