// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarDataHora } from 'main/utils';
import RenderConditional from 'main/components/RenderConditional';
import usePrevSummary from 'previdencia/hooks/usePrevSummary';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const ComprovanteAporteAdicional: React.FC<
  APORTE_TYPES.IComprovanteAporteAdicionalProps
> = ({ aporteOrigemRecursos }) => {
  const { featureData } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  const dataAtual = new Date();

  const { informacaoPorCertificado } = usePrevSummary();

  return (
    <>
      <S.ContainerListaAporte>
        <Text variant="body02-lg">
          Dados da Contribuição Adicional / Aporte
        </Text>
        <S.ContainerCinzaAporte>
          <Text variant="body02-sm">Produto</Text>
          <Text variant="body02-sm">
            {informacaoPorCertificado?.produto.descricaoProduto}
          </Text>
        </S.ContainerCinzaAporte>
        <S.ContainerBrancoAporte>
          <Text variant="body02-sm">Certificado</Text>
          <Text variant="body02-sm">
            {informacaoPorCertificado?.certificadoNumero}
          </Text>
        </S.ContainerBrancoAporte>
        <S.ContainerCinzaAporte>
          <Text variant="body02-sm">Solicitação</Text>
          <Text variant="body02-sm">Contribuição Adicional / Aporte</Text>
        </S.ContainerCinzaAporte>
        <S.ContainerBrancoAporte>
          <Text variant="body02-sm">Data</Text>
          <Text variant="body02-sm">
            {formatarDataHora(dataAtual.toString())}
          </Text>
        </S.ContainerBrancoAporte>

        <S.ContainerCinzaAporte>
          <Text variant="body02-sm">Modalidade</Text>
          <Text variant="body02-sm">
            {informacaoPorCertificado?.produto?.modalidade}
          </Text>
        </S.ContainerCinzaAporte>

        <S.ContainerBrancoAporte>
          <Text variant="body02-sm">Regime Tributário</Text>
          <Text variant="body02-sm">
            {informacaoPorCertificado?.regimeTributario}
          </Text>
        </S.ContainerBrancoAporte>

        <RenderConditional condition={!!featureData?.pepPositivo}>
          <S.ContainerBrancoAporte>
            <Text variant="body02-sm">Pessoa Exposta Politicamente</Text>
            <Text variant="body02-sm">
              {featureData?.pepPositivo
                ? CONSTS.PEP_POSITIVO.SIM
                : CONSTS.PEP_POSITIVO.NAO}
            </Text>
          </S.ContainerBrancoAporte>
          <S.ContainerCinzaAporte>
            <Text variant="body02-sm">Origem dos Recursos</Text>
            <Text variant="body02-sm">
              {aporteOrigemRecursos.get().value.descricaoOrigemPagamento}
            </Text>
          </S.ContainerCinzaAporte>
        </RenderConditional>
      </S.ContainerListaAporte>
    </>
  );
};
export default ComprovanteAporteAdicional;
