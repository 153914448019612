// @ts-nocheck
import { useLocation } from 'react-router';

const useURLQueryParam = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const getParam = (name: string) => query.get(name);

  return {
    getParam,
  };
};

export default useURLQueryParam;
