// @ts-nocheck
import { IParametrosConfiguracaoDps } from 'contratosPrestamista/types/IParametrosConfiguracaoDps';

export const PARAMETROS_DPS = {
  ID_PRODUTO: 7,
  CODIGO_PRODUTO_ORIGEM_PF: '9821',
  CODIGO_PRODUTO_ORIGEM_PJ: '9811',
  CODIGO_SEGMENTO_PF: 'PRESTAMISTA_PF',
  CODIGO_SEGMENTO_PJ: 'PRESTAMISTA_PJ',
  CODIGO_SISTEMA_ORIGEM: 'PECO',
  CODIGO_COBERTURA_ORIGEM: 1,
};

export const SISTEMAS_PRESTAMISTA = {
  SIFEC: 'SIFEC',
  SIAPX: 'SIAPX',
  SIAPI: 'SIAPI',
};

export const PARAMETROS_DPS_SEGMENTO: Record<
  string,
  IParametrosConfiguracaoDps
> = {
  PJ: {
    codigoSegmento: PARAMETROS_DPS.CODIGO_SEGMENTO_PJ,
    codigoProdutoOrigem: PARAMETROS_DPS.CODIGO_PRODUTO_ORIGEM_PJ,
    codigoSistemaOrigem: PARAMETROS_DPS.CODIGO_SISTEMA_ORIGEM,
    codigoCoberturaOrigem: PARAMETROS_DPS.CODIGO_COBERTURA_ORIGEM,
  },
  PF: {
    codigoSegmento: PARAMETROS_DPS.CODIGO_SEGMENTO_PF,
    codigoProdutoOrigem: PARAMETROS_DPS.CODIGO_PRODUTO_ORIGEM_PF,
    codigoSistemaOrigem: PARAMETROS_DPS.CODIGO_SISTEMA_ORIGEM,
    codigoCoberturaOrigem: PARAMETROS_DPS.CODIGO_COBERTURA_ORIGEM,
  },
};

export const PARAMETROS_DPS_VERIFICAR_NECESSIDADE: Record<
  string,
  { codigoSegmento: string; codigoProdutoOrigem: string }
> = {
  J: {
    codigoSegmento: PARAMETROS_DPS.CODIGO_SEGMENTO_PJ,
    codigoProdutoOrigem: PARAMETROS_DPS.CODIGO_PRODUTO_ORIGEM_PJ,
  },
  F: {
    codigoSegmento: PARAMETROS_DPS.CODIGO_SEGMENTO_PF,
    codigoProdutoOrigem: PARAMETROS_DPS.CODIGO_PRODUTO_ORIGEM_PF,
  },
};

export const MENSAGEM_TABELA_DADOS = 'Não há dados para exibir.';

export const getDadosTransferenciaRendaVarivavelFieldName = (index: number) =>
  `dadosTransferenciaRendaVarivavel[${index}].familiaFundoDestino`;
