// @ts-nocheck
import React from 'react';
import { Modal, Text, Display, Button } from '@cvp/design-system/react';
import { TModalResumoTransferenciaModalConfirmacao } from '../../types/TModalConfirmacaoTransferencia';
import * as S from '../styles';
import For from 'main/components/For';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IDadosCobertura } from '../../types/TransferenciaConsultar';

const ModalResumoTransferenciaModalConfirmacao: React.FC<
  TModalResumoTransferenciaModalConfirmacao
> = ({ show, onClose, coberturasNaoComerciaizadas, onContinue }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <Text variant="headline-05" color="primary" margin>
        Atenção!
      </Text>
      <Text variant="caption" margin>
        Os benefícios de proteção que não são mais comercializados,{' '}
        <strong>serão cancelados.</strong> Deseja continuar?
      </Text>
      <br />
      <Text variant="caption" color="primary" margin>
        Benefícios de proteção que não são mais comercializados:
      </Text>
      <br />
      <S.ListBeneficios>
        <ul>
          <For
            each={tryGetValueOrDefault(
              [coberturasNaoComerciaizadas],
              [] as IDadosCobertura[],
            )}
          >
            {item => <li>{item.cobertura}</li>}
          </For>
        </ul>
      </S.ListBeneficios>
      <br />
      <Display justify="center">
        <Button variant="secondary" small onClick={onClose}>
          Não
        </Button>
        <Button small onClick={onContinue}>
          Sim
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalResumoTransferenciaModalConfirmacao;
