// @ts-nocheck
import { IDadosBasicosCertificadoPrevidencia } from 'main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';
import { ordenarItens } from 'main/utils/ordenar';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { SITUACAO } from '../types/EnumEmitirExtrato';

export const formatarPorSituacao = (
  item: IDadosBasicosCertificadoPrevidencia,
): IDadosBasicosCertificadoPrevidencia => {
  switch (item.situacao) {
    case SITUACAO.ATIVA:
      return { ...item, situacao: 'Ativa', ordem: 1 };
    case SITUACAO.COBRANCA_SUSPENSA:
      return { ...item, situacao: 'Ativa com cobrança suspensa', ordem: 2 };
    case SITUACAO.BENEFICIO:
      return { ...item, situacao: 'Em Benefício', ordem: 3 };
    case SITUACAO.SINISTRO:
      return { ...item, situacao: 'Sinistro', ordem: 4 };
    case SITUACAO.CANCELADA:
      return { ...item, situacao: 'Cancelada', ordem: 5 };
    case SITUACAO.ENCERRADA:
      return { ...item, situacao: 'Encerrada', ordem: 6 };
    case SITUACAO.DESLIGADA:
      return { ...item, situacao: 'Desligada', ordem: 7 };
    default:
      return item;
  }
};

export const formatarProdutos = (
  produtos: IDadosBasicosCertificadoPrevidencia[] | undefined,
): IDadosBasicosCertificadoPrevidencia[] | undefined => {
  const filter = produtos
    ?.filter(item =>
      checkIfAllItemsAreTrue([
        item.situacao !== 'N',
        item.situacao !== 'P',
        item.situacao !== 'R',
      ]),
    )
    .map(item => formatarPorSituacao(item));
  return filter?.sort(ordenarItens);
};
