// @ts-nocheck
import React from 'react';
import { Switch } from 'react-router-dom';
import { IRouteProps } from 'main/components/Route';
import PrivateRoute from 'main/components/Route/PrivateRoute';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import AtualizacaoDadosSegurado from 'seguros/components/AtualizacaoDadosSegurado';
import HomePageVida from 'vida/features/ApresentacaoVida/pages/HomePageVida';
import InformacoesBeneficiarios from 'vida/features/InformacoesBeneficiarios/pages/InformacoesBeneficiarios';
import HistoricoPagamentos from 'vida/features/Pagamentos/pages/Pagamentos';
import DeclaracaoDeQuitacaoAnualDebitos from 'vida/features/DeclaracaoDeQuitacaoAnual/pages/DeclaracaoDeQuitacaoAnualDebitos';
import DadosApolice from 'vida/features/DadosApolice/pages/DadosApoliceVida';
import DadosApoliceInformcoesIRPFVida from 'vida/features/InformacoesSobreIRPFVida/pages/DadosApoliceInformcoesIRPFVida';
import AlteracaoDiaVencimento from 'vida/features/AlterarDiaVencimento/pages/AlterarDiaVencimento';
import AlterarValorCapitalVida from 'vida/features/AlterarValorCapital/pages/AlterarValorCapitalVida';
import SegundaViaApoliceVida from 'vida/features/SegundaViaApoliceVida/pages/SegundaViaApoliceVida';
import DadosDoSegurado from 'vida/features/DadosDoSegurado/DadosDoSegurado';
import SegurosContextProvider from 'seguros/contexts/SegurosContextProvider';
import ConsultaDeSinistro from 'vida/features/ConsultaSinistro/ConsultaDeSinistro';
import Certificado from 'vida/features/CertificadoVida/pages/Certificado';

const routes: IRouteProps[] = [
  {
    path: '/cliente/produtos/vida',
    component: HomePageVida,
    key: 'vida-home',
    authenticated: true,
    breadcrumb: 'Painel',
    exact: true,
  },
  {
    path: '/cliente/produtos/vida/dados-segurado',
    exact: true,
    component: DadosDoSegurado,
    key: 'vida-dados-segurado',
    authenticated: true,
    breadcrumb: 'Dados do Segurado',
  },
  {
    path: '/cliente/produtos/vida/dados-segurado/atualizacao',
    exact: true,
    component: AtualizacaoDadosSegurado,
    key: 'vida-dados-segurado-atualizacao',
    authenticated: true,
    breadcrumb: 'Atualizar dados segurado',
  },
  {
    path: '/cliente/produtos/vida/pagamentos',
    component: HistoricoPagamentos,
    key: 'vida-historico-pagamentos',
    authenticated: true,
    breadcrumb: 'Pagamentos',
    exact: true,
  },
  {
    path: '/cliente/produtos/vida/beneficiarios',
    component: InformacoesBeneficiarios,
    key: 'vida-beneficiarios',
    authenticated: true,
    breadcrumb: 'Beneficiários',
  },
  {
    path: '/cliente/produtos/vida/dados-apolice',
    component: DadosApolice,
    key: 'vida-dados-apolice',
    authenticated: true,
    breadcrumb: 'Dados Apolice / Certificado / Nº Sorteio',
    exact: true,
  },
  {
    path: '/cliente/produtos/vida/dados-irpf',
    component: DadosApoliceInformcoesIRPFVida,
    key: 'vida-dados-irpf',
    authenticated: true,
    breadcrumb: 'Informações Sobre IRPF',
    exact: true,
  },
  {
    path: '/cliente/produtos/vida/consulta-sinistro',
    component: ConsultaDeSinistro,
    key: 'consulta-sinistro',
    authenticated: true,
    breadcrumb: 'Consulta de Sinistro',
    exact: true,
  },
  {
    path: '/cliente/produtos/vida/declaracao-anual-debitos',
    component: DeclaracaoDeQuitacaoAnualDebitos,
    key: 'declaracao-anual-debitos-vida',
    authenticated: true,
    breadcrumb: 'Declaração Anual de Quitação de Débitos',
  },
  {
    path: '/cliente/produtos/vida/dia-vencimento',
    component: AlteracaoDiaVencimento,
    key: 'alteracao-dia-vencimento',
    authenticated: true,
    breadcrumb: 'Alterar Dia de Vencimento',
  },
  {
    path: '/cliente/produtos/vida/valor-segurado',
    exact: true,
    component: AlterarValorCapitalVida,
    key: 'valor-segurado',
    authenticated: true,
    breadcrumb: 'Alterar Valor Capital Segurado',
  },
  {
    path: '/cliente/produtos/vida/emissao-certificado',
    component: Certificado,
    key: 'vida-emissao-certificado',
    authenticated: true,
    breadcrumb: 'Emissão de Certificado',
  },
  {
    path: '/cliente/produtos/vida/2a-via-certificado',
    component: SegundaViaApoliceVida,
    key: 'vida-2a-via-certificado',
    authenticated: true,
    breadcrumb: '2a Via Apolice',
    exact: true,
  },
  {
    path: '/cliente/produtos/vida/certificado-vida',
    component: Certificado,
    key: 'certificado-online-vida',
    authenticated: true,
    breadcrumb: 'Emissão de Certificado',
  },
];

export const vidaBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/cliente/produtos/vida': null,
};

const RotasVida = (): React.ReactElement => (
  <Switch>
    <SegurosContextProvider>
      {routes.map(route => (
        <PrivateRoute
          key={route.key}
          path={route.path}
          exact={route.exact}
          component={route.component}
          authenticated={route.authenticated}
        />
      ))}
    </SegurosContextProvider>
  </Switch>
);

export default RotasVida;
