// @ts-nocheck
import { useHistory } from 'react-router';

const useVidaNavigation = () => {
  const history = useHistory();

  return {
    goHomeVida: () => history.push(`/cliente/produtos/vida`),
    goDadosSeguradoVida: () =>
      history.push(`/cliente/produtos/vida/dados-segurado`),
    goHome: () => history.push(`/cliente/produtos/`),
  };
};

export default useVidaNavigation;
