// @ts-nocheck
import { obterEndpointConsultarHistoricoSimulacaoRenda } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseHistoricoSimulacaoRenda } from 'previdencia/features/SimulacaoRenda/types/historicoSimulacaoRenda';

export const consultarHistoricoSimulacaoRenda = async (
  Cpf: string,
  NumeroCertificado: string,
): Promise<ResponseHistoricoSimulacaoRenda | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponseHistoricoSimulacaoRenda>
  >(obterEndpointConsultarHistoricoSimulacaoRenda(), {
    Cpf,
    NumeroCertificado,
  });

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
