// @ts-nocheck
import { createContext } from 'react';
import { IRequestSendCode } from '../types/IRequestSendCode';

interface IReversaoCancelamentoContext {
  formSendCode: IRequestSendCode;
  idSolicitacaoToken: string;
}

const ReversaoCancelamentoContext = createContext<IReversaoCancelamentoContext>(
  {} as IReversaoCancelamentoContext,
);

export default ReversaoCancelamentoContext;
