// @ts-nocheck
import { Card, Display, Grid, Text, Button } from '@cvp/design-system/react';

import ModalTipoDocuSign from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import useAlteracaoRegimeTributario from 'previdencia/features/AlteracaoRegimeTributario/hooks/useAlteracaoRegimeTributario';
import useHandleDadosAliquota from 'previdencia/features/AlteracaoRegimeTributario/hooks/useHandleDadosAliquota';
import MensagemSemPermissaoAlteracao from 'previdencia/features/AlteracaoRegimeTributario/components/MensagemSemPermissaoAlteracao';
import MensagemDeclaracaoRegimeTributario from 'previdencia/features/AlteracaoRegimeTributario/components/MensagemDeclaracaoRegimeTributario';
import MensagemSucessoAlteracao from 'previdencia/features/AlteracaoRegimeTributario/components/MensagemSucessoAlteracao';
import ModalAlertaAlteracaoExpirada from 'previdencia/features/AlteracaoRegimeTributario/components/ModalAlertaAlteracaoExpirada';
import TabelasAliquotas from 'previdencia/features/AlteracaoRegimeTributario/components/TabelasAliquotas';
import * as CONSTS from 'previdencia/features/AlteracaoRegimeTributario/constants/constants';

const AlteracaoRegimeTributario = () => {
  const { goDadosPlano, navigateTo } = usePrevNavigation();

  const {
    loadingDadosAliquota,
    dadosConsultaParametrosRegimeTributario,
    opcaoRegimeTributario,
    dadosTabela,
    selecionarOpcaoRegimeTributario,
  } = useHandleDadosAliquota();

  const {
    eventSucess,
    checkDeclaracao,
    open,
    dadosContatos,
    loadingDadosPerfilTributario,
    dadosCliente,
    urlAssinatura,
    exibirBotaoFecharModal,
    msgAlteracaoExpirada,
    checarDeclaracao,
    confirmarDeclaracao,
    fecharModal,
    handleAssinar,
  } = useAlteracaoRegimeTributario(opcaoRegimeTributario);

  return (
    <>
      <Display type="display-block">
        <PrevidenciaResumo />

        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text
                  variant="headline-05"
                  color="primary"
                  data-testId="tituloRegimeTributario"
                >
                  {CONSTS.TEXTOS_REGIME_TRIBUTARIO.TITULO}
                </Text>
              </Grid.Item>

              <RenderConditional
                condition={
                  dadosConsultaParametrosRegimeTributario?.podeAlterarRegimeTributario ===
                  CONSTS.ALTERACAO_REGIME_TRIBUTARIO.NAO_PODE_ALTERAR
                }
              >
                <MensagemSemPermissaoAlteracao
                  opcaoTributacaoIrrfAtual={
                    dadosConsultaParametrosRegimeTributario?.opcaoTributacaoIrrfAtual
                  }
                />
              </RenderConditional>

              <RenderConditional
                condition={checkIfAllItemsAreTrue([
                  !eventSucess,
                  dadosConsultaParametrosRegimeTributario?.podeAlterarRegimeTributario ===
                    CONSTS.ALTERACAO_REGIME_TRIBUTARIO.PODE_ALTERAR,
                ])}
              >
                <Grid.Item xs={1}>
                  <Text variant="body01-md" color="text">
                    {CONSTS.TEXTOS_REGIME_TRIBUTARIO.DESCRICAO}
                  </Text>
                </Grid.Item>

                <TabelasAliquotas
                  opcoesTributacaoIrrfDisponiveis={
                    dadosConsultaParametrosRegimeTributario?.opcoesTributacaoIrrfDisponiveis
                  }
                  loadingDadosAliquota={loadingDadosAliquota}
                  opcaoRegimeTributario={opcaoRegimeTributario}
                  dadosTabela={dadosTabela}
                  selecionarOpcaoRegimeTributario={
                    selecionarOpcaoRegimeTributario
                  }
                />

                <MensagemDeclaracaoRegimeTributario
                  checarDeclaracao={checarDeclaracao}
                />

                <Grid.Item xs={1}>
                  <Display>
                    <Button
                      variant="outlined"
                      onClick={goDadosPlano}
                      data-testId="voltarDadosPlano"
                    >
                      Voltar
                    </Button>
                    <Button
                      onClick={confirmarDeclaracao}
                      disabled={checkIfSomeItemsAreTrue([
                        loadingDadosAliquota,
                        loadingDadosPerfilTributario,
                        !checkDeclaracao,
                        !opcaoRegimeTributario,
                      ])}
                      loading={checkIfSomeItemsAreTrue([
                        loadingDadosAliquota,
                        loadingDadosPerfilTributario,
                      ])}
                      data-testId="confirmarDeclaracao"
                    >
                      Confirmar
                    </Button>
                  </Display>
                </Grid.Item>
              </RenderConditional>

              <RenderConditional condition={eventSucess}>
                <MensagemSucessoAlteracao />
              </RenderConditional>
            </Grid>
          </Card.Content>
        </Card>
      </Display>

      <ModalAlertaContatos
        open={open.modalAlertaContatos}
        onClose={fecharModal}
        redirect={() => navigateTo('dados-participante')}
        dados={dadosContatos}
      />

      <ModalTipoDocuSign
        tituloModal="Alteração de Regime Tributário"
        open={open.modalTipo}
        handleClose={fecharModal}
        executarAssinatura={handleAssinar}
        loading={loadingDadosPerfilTributario}
        dadosCliente={dadosCliente}
      />

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          open.modalDocusign,
          !!urlAssinatura,
        ])}
      >
        <ModalTokenDocuSign
          open={open.modalDocusign}
          handleClose={fecharModal}
          urlTokenPage={tryGetValueOrDefault([urlAssinatura], '')}
          exibirBotaoFecharModal={exibirBotaoFecharModal}
        />
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          open.modalAlertaAlteracaoExpirada,
          !loadingDadosPerfilTributario,
          !urlAssinatura,
        ])}
      >
        <ModalAlertaAlteracaoExpirada
          open={open.modalAlertaAlteracaoExpirada}
          onClose={fecharModal}
          mensagem={msgAlteracaoExpirada}
        />
      </RenderConditional>
    </>
  );
};

export default AlteracaoRegimeTributario;
