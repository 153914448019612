// @ts-nocheck
export const SITE_RECEITA = 'www.receita.fazenda.gov.br';
export const PARAGRAFO_ALIQUOTA_PROGRESSIVA = (
  <p>
    No caso de resgate, será cobrada alíquota de 15% na fonte, indicado para
    quem é isento de IR ou imagina precisar dos recursos em curto período de
    tempo. No caso de recebimento de renda, o valorestará sujeito a IR de acordo
    com a tabela vigente à época, disponível no{' '}
    <a href={`https://${SITE_RECEITA}`} target="_blank" rel="noreferrer">
      link
    </a>
    .
  </p>
);

export const PARAGRAFO_ALIQUOTA_REGRESSIVA =
  'No caso de resgate, as alíquotas diminuem a cada dois anos, conforme tabela abaixo. Quanto maior o tempo de permanência, menor será o Imposto de Renda. Indicado para quem pretende manter os recursos no plano por um prazo rnaior. No pagamento da renda a alíquota de IR é calculada com base no tempo médio ponderado de aportes de recursos no plano e ternpo de pagamento de benefícios. A partir do início de recebimento da renda, o prazo de acumulação continua sendo contabilizado, assim, caso haja uma mudança de faixa da Tabela Decrescente, a alíquota de tributação também muda automaticamente.';
