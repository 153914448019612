// @ts-nocheck
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { Fundo } from 'previdencia/types/Fundo.type';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

export const obterDadosValidacaoTabelaTipoTransferenciaFactory = (
  fundosOrigem: Fundo[],
): TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IValidaPreenchimentoTransferencia => {
  const listaFundosPreenchidos = fundosOrigem.filter(fundo =>
    checkIfAllItemsAreTrue([
      fundo.selecionado,
      !!fundo.tipoTransferenciaFundo,
      !!fundo.valorTransferencia,
    ]),
  );

  const listaFundosNaoRespeitamValorMinimo = listaFundosPreenchidos.filter(
    fundoPreenchido =>
      checkIfAllItemsAreTrue([
        fundoPreenchido.valorMinimo >
          Number(fundoPreenchido.valorTransferencia),
        fundoPreenchido.tipoTransferenciaFundo ===
          CONSTS.TIPOS_TRANSFERENCIA.PARCIAL.charAt(0).toUpperCase(),
      ]),
  );

  const listaFundosAcimaValorMaximoSaldo = listaFundosPreenchidos.filter(
    fundoPreenchido =>
      checkIfAllItemsAreTrue([
        Number(fundoPreenchido.valorTransferencia) > fundoPreenchido.saldo,
        fundoPreenchido.tipoTransferenciaFundo ===
          CONSTS.TIPOS_TRANSFERENCIA.PARCIAL.charAt(0).toUpperCase(),
      ]),
  );

  return {
    listaFundosNaoRespeitamValorMinimo,
    listaFundosAcimaValorMaximoSaldo,
    isValorInvalido: checkIfSomeItemsAreTrue([
      !!listaFundosNaoRespeitamValorMinimo.length,
      !!listaFundosAcimaValorMaximoSaldo.length,
    ]),
  };
};
