// @ts-nocheck
import { obterEndpointDefinirFundoContribuicaoRegular } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { RequestDefinirFundoContribuicaoRegular } from '../../../types/ContribuicaoRegular.types';

export const definirFundoContribuicaoRegular = async (
  cpf: string,
  numTransferencia: string | undefined,
  request: RequestDefinirFundoContribuicaoRegular | undefined,
): Promise<boolean> => {
  if (numTransferencia && request?.codFundo && request?.vlrRequisitado) {
    const fundos = [request];
    await api.post<IApiResponse<void>>(
      obterEndpointDefinirFundoContribuicaoRegular(),
      {
        cpf,
        numTransferencia,
        fundos,
      },
    );

    return true;
  }

  return false;
};
