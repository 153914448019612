// @ts-nocheck
import styled from 'styled-components';

export const Container = styled.div(({ theme: { color } }) => ({
  border: `1px solid ${color.neutral['03']}`,
  background: `${color.neutral['03']}0d`,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,
  marginBottom: 20,
}));
