// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { formatarDataHora } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { PECOS_RETENCAO_PORTABILIDADE } from 'portabilidade/config/endpoints';
import {
  CHAVE_ID_DETALHE_PORTABILIDADE_ATUAL,
  STATUS_PORTABILIDADE,
} from '../constants';
import { DetalhesPortabilidade } from '../types/DetalhesPortabilidade';
import { IResponsePortabilidade } from '../types/IResponsePortabilidade';
import { ResumoPortabilidade } from '../types/ResumoPortabilidade';

export const useListaPortabilidade = (unidadeEntradaPainel: string) => {
  const { response, loading } = usePeco<
    Record<string, unknown>,
    ResumoPortabilidade[]
  >({
    api: { operationPath: PECOS_RETENCAO_PORTABILIDADE.listarPortabilidades },
    payload: {
      numeroAgencia: tryGetValueOrDefault([unidadeEntradaPainel], ''),
      codigoTipoSituacaoRetencao: '',
      codigoUsuario: '',
    },
    autoFetch: true,
    cacheKey: tryGetValueOrDefault([unidadeEntradaPainel], ''),
    cache: true,
  });
  let result: IResponsePortabilidade = {} as IResponsePortabilidade;

  if (response?.sucessoBFF) {
    result = {
      portabilidades: tryGetValueOrDefault([response.entidade], []).map(
        (portabilidade: ResumoPortabilidade) => ({
          ...portabilidade,
          dataVencimento: formatarDataHora(portabilidade.dataVencimento),
          valorPortabilidade: formatarValorPadraoBrasileiro(
            portabilidade.valorPortabilidade,
          ),
        }),
      ),
      qtdRecebidas: tryGetValueOrDefault([response.entidade], []).filter(
        (portabilidade: ResumoPortabilidade) =>
          portabilidade.codigoSituacaoRetencao ===
          STATUS_PORTABILIDADE.RECEBIDA,
        0,
      ).length,
      qtdProrrogada: tryGetValueOrDefault([response.entidade], []).filter(
        (portabilidade: ResumoPortabilidade) =>
          portabilidade.codigoSituacaoRetencao ===
          STATUS_PORTABILIDADE.PRORROGADA,
        0,
      ).length,
      qtdAndamento: tryGetValueOrDefault([response.entidade], []).filter(
        (portabilidade: ResumoPortabilidade) =>
          portabilidade.codigoSituacaoRetencao ===
          STATUS_PORTABILIDADE.ANDAMENTO,
        0,
      ).length,
    };
  }

  return { response: result, loading };
};

export const useDetalhePortabilidade = (id: string) => {
  sessionStorage.setItem(CHAVE_ID_DETALHE_PORTABILIDADE_ATUAL, id);
  const { response, loading, fetchData, invalidateCache } = usePeco<
    { id: string },
    DetalhesPortabilidade
  >({
    api: { operationPath: PECOS_RETENCAO_PORTABILIDADE.detalhesPortabilidade },
    payload: {
      id,
    },
    autoFetch: true,
    cache: true,
    cacheKey: `${id}`,
  });

  return {
    response,
    loading,
    fetchData,
    invalidateCache,
  };
};
