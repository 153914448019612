// @ts-nocheck
import { formatarData, formatarDataHora } from 'main/utils';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import {
  DadosSimulacao,
  IRequestSimularBeneficioApi,
  ResponseSimularBeneficios,
} from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';
import { validarGenero } from 'previdencia/features/SimulacaoRenda/utils/validarGenero';

export const simulacaoRendaFactory = (
  simulacaoProgressivo: ResponseSimularBeneficios | undefined,
  simulacaoRegressivo: ResponseSimularBeneficios | undefined,
  payload: IRequestSimularBeneficioApi | undefined,
): DadosSimulacao[] => {
  const simulacaoDisponivel = tryGetValueOrDefault(
    [simulacaoProgressivo],
    simulacaoRegressivo,
  );

  const dadosTabela: DadosSimulacao[] = [];
  const VAZIO = '--';
  dadosTabela.push({
    comparacao: 'Data da simulação',
    estimativaRenda: tryGetValueOrDefault(
      [formatarData(simulacaoDisponivel?.dthInicioGozoOriginal)],
      VAZIO,
    ),
    simulacaoProgressivo: formatarDataHora(new Date().toString()),
    simulacaoRegressivo: formatarDataHora(new Date().toString()),
  });

  dadosTabela.push({
    comparacao: 'Reserva considerada',
    estimativaRenda: tryGetMonetaryValueOrDefault(
      simulacaoDisponivel?.vlrReservaOriginal,
    ),
    simulacaoProgressivo: tryGetMonetaryValueOrDefault(
      simulacaoProgressivo?.vlrReserva,
    ),
    simulacaoRegressivo: tryGetMonetaryValueOrDefault(
      simulacaoRegressivo?.vlrReserva,
    ),
  });

  dadosTabela.push({
    comparacao: 'Renda líquida',
    estimativaRenda: tryGetMonetaryValueOrDefault(
      simulacaoDisponivel?.vlrBeneficioLiquidoOriginal,
    ),
    simulacaoProgressivo: tryGetMonetaryValueOrDefault(
      simulacaoProgressivo?.vlrBeneficioLiquido,
    ),
    simulacaoRegressivo: tryGetMonetaryValueOrDefault(
      simulacaoRegressivo?.vlrBeneficioLiquido,
    ),
  });

  dadosTabela.push({
    comparacao: 'Renda bruta',
    estimativaRenda: tryGetMonetaryValueOrDefault(
      simulacaoDisponivel?.vlrBeneficioOriginal,
    ),
    simulacaoProgressivo: tryGetMonetaryValueOrDefault(
      simulacaoProgressivo?.vlrBeneficio,
    ),
    simulacaoRegressivo: tryGetMonetaryValueOrDefault(
      simulacaoRegressivo?.vlrBeneficio,
    ),
  });

  dadosTabela.push({
    comparacao: 'Desconto do IR',
    estimativaRenda: tryGetMonetaryValueOrDefault(
      simulacaoDisponivel?.vlrIRRFOriginal,
    ),
    simulacaoProgressivo: tryGetMonetaryValueOrDefault(
      simulacaoProgressivo?.vlrIRRF,
    ),
    simulacaoRegressivo: tryGetMonetaryValueOrDefault(
      simulacaoRegressivo?.vlrIRRF,
    ),
  });

  dadosTabela.push({
    comparacao: 'Tempo para receber a renda',
    estimativaRenda: tryGetValueOrDefault(
      [simulacaoDisponivel?.descPeridoBeneficiarioRecebeOriginal],
      VAZIO,
    ),
    simulacaoProgressivo: tryGetValueOrDefault(
      [simulacaoProgressivo?.descPeridoBeneficiarioRecebe],
      VAZIO,
    ),
    simulacaoRegressivo: tryGetValueOrDefault(
      [simulacaoRegressivo?.descPeridoBeneficiarioRecebe],
      VAZIO,
    ),
  });

  dadosTabela.push({
    comparacao: 'Beneficiários receberão renda em caso do seu falecimento?',
    estimativaRenda: tryGetValueOrDefault(
      [simulacaoDisponivel?.descBeneficiarioRecebeOriginal],
      VAZIO,
    ),
    simulacaoProgressivo: tryGetValueOrDefault(
      [simulacaoProgressivo?.descBeneficiarioRecebe],
      VAZIO,
    ),
    simulacaoRegressivo: tryGetValueOrDefault(
      [simulacaoRegressivo?.descBeneficiarioRecebe],
      VAZIO,
    ),
  });

  const exibirVigenciaProgressivo = checkIfAllItemsAreTrue([
    simulacaoProgressivo?.codTipoPagamento !== EnumTipoRendaCodigo.Vitalicia,
    simulacaoProgressivo?.codTipoPagamento !== EnumTipoRendaCodigo.Temporaria,
  ]);

  const exibirVigenciaRegrassivo = checkIfAllItemsAreTrue([
    simulacaoProgressivo?.codTipoPagamento !== EnumTipoRendaCodigo.Vitalicia,
    simulacaoProgressivo?.codTipoPagamento !== EnumTipoRendaCodigo.Temporaria,
  ]);

  if (
    checkIfSomeItemsAreTrue([
      exibirVigenciaProgressivo,
      exibirVigenciaRegrassivo,
    ])
  ) {
    dadosTabela.push({
      comparacao: 'Por quanto tempo?',
      estimativaRenda: tryGetValueOrDefault(
        [simulacaoDisponivel?.descPeriodoAnosBeneficiarioRecebeOriginal],
        VAZIO,
      ),
      simulacaoProgressivo: tryGetValueOrDefault(
        [simulacaoProgressivo?.descPeriodoAnosBeneficiarioRecebe],
        VAZIO,
      ),
      simulacaoRegressivo: tryGetValueOrDefault(
        [simulacaoRegressivo?.descPeriodoAnosBeneficiarioRecebe],
        VAZIO,
      ),
    });

    dadosTabela.push({
      comparacao: 'Quanto da renda?',
      estimativaRenda: tryGetValueOrDefault(
        [simulacaoDisponivel?.descPctBeneficiarioRecebeOriginal],
        VAZIO,
      ),
      simulacaoProgressivo: tryGetValueOrDefault(
        [simulacaoProgressivo?.descPctBeneficiarioRecebe],
        VAZIO,
      ),
      simulacaoRegressivo: tryGetValueOrDefault(
        [simulacaoRegressivo?.descPctBeneficiarioRecebe],
        VAZIO,
      ),
    });
  }

  if (
    checkIfSomeItemsAreTrue([
      simulacaoProgressivo?.codTipoPagamento ===
        EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
      simulacaoRegressivo?.codTipoPagamento ===
        EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
    ])
  ) {
    dadosTabela.push({
      comparacao: 'Data de Nascimento do Beneficiário',
      estimativaRenda: VAZIO,
      simulacaoProgressivo: tryGetValueOrDefault(
        [formatarData(payload?.dthNascimentoConjuge)],
        VAZIO,
      ),
      simulacaoRegressivo: tryGetValueOrDefault(
        [formatarData(payload?.dthNascimentoConjuge)],
        VAZIO,
      ),
    });

    dadosTabela.push({
      comparacao: 'Sexo',
      estimativaRenda: VAZIO,
      simulacaoProgressivo: tryGetValueOrDefault(
        [validarGenero(payload?.staGeneroConjuge)],
        VAZIO,
      ),
      simulacaoRegressivo: tryGetValueOrDefault(
        [validarGenero(payload?.staGeneroConjuge)],
        VAZIO,
      ),
    });
  }

  dadosTabela.push({
    comparacao: 'Tipo de renda',
    estimativaRenda: tryGetValueOrDefault(
      [simulacaoDisponivel?.nomTipoPagamentoOriginal],
      VAZIO,
    ),
    simulacaoProgressivo: tryGetValueOrDefault(
      [simulacaoProgressivo?.nomTipoPagamento],
      VAZIO,
    ),
    simulacaoRegressivo: tryGetValueOrDefault(
      [simulacaoRegressivo?.nomTipoPagamento],
      VAZIO,
    ),
  });

  return dadosTabela;
};
