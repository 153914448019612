// @ts-nocheck
import {
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { DadosParticipante } from 'main/types/Participante';
import {
  ESTADO_CIVIL,
  ESTADO_CIVIL_LABEL,
  TipoTelefone,
  ValidaCamposObrigatoriosProps,
} from '../types/DadosParticipante';

export const compararTelefone = (
  telefoneNovo: string | undefined,
  telefoneAntigo: string,
): boolean => {
  return telefoneNovo !== telefoneAntigo;
};

export const procurarPorTipoTelefone = (
  dados: DadosParticipante | undefined,
  tipoTelefone: TipoTelefone,
) => {
  return dados?.telefones.find(
    e =>
      e.tipoTelefone === tipoTelefone.tipoUm ||
      e.tipoTelefone === tipoTelefone.tipoDois,
  );
};

export const compararAtualizacaoEndereco = (
  dadosCepCep: string | undefined,
  dadosCepEndereco: string | undefined,
  dadosParticipanteCep: string | undefined,
  dadosParticipanteEndereco: string | undefined,
): string | undefined => {
  if (dadosCepCep !== dadosParticipanteCep) {
    return dadosCepEndereco;
  }
  return dadosParticipanteEndereco;
};

export const compararAtualizacaoNumero = (
  dadosCepCep: string | undefined,
  dadosParticipanteCep: string | undefined,
  dadosParticipanteNumero: string | undefined,
): string | undefined => {
  if (dadosCepCep !== dadosParticipanteCep) {
    return '';
  }
  return dadosParticipanteNumero;
};

export const compararAtualizacaoBairro = (
  dadosCepCep: string | undefined,
  dadosCepBairro: string | undefined,
  dadosParticipanteCep: string | undefined,
  dadosParticipanteBairro: string | undefined,
): string | undefined => {
  if (dadosCepCep !== dadosParticipanteCep) {
    return dadosCepBairro;
  }
  return dadosParticipanteBairro;
};

export const compararAtualizacaoComplemento = (
  dadosCepCep: string | undefined,
  dadosParticipanteCep: string | undefined,
  dadosParticipanteComplemento: string | undefined,
): string | undefined => {
  if (dadosCepCep !== dadosParticipanteCep) {
    return '';
  }
  return dadosParticipanteComplemento;
};

export const tratamentoCodigoAreaTelefone = (
  CodigoAreaMemoria: string | undefined,
  telefoneResidencial: string | undefined,
): string | undefined => {
  if (CodigoAreaMemoria === '') {
    return telefoneResidencial?.substr(0, 2);
  }
  return '';
};

export const tratamentoNumeroTelefone = (
  NumeroTelefoneMemoria: string | undefined,
  telefoneResidencial: string | undefined,
): string | undefined => {
  if (NumeroTelefoneMemoria === '') {
    return telefoneResidencial?.substr(2);
  }
  return '';
};

export const tratamentoExcecaoTelefoneVazioGet = (
  telefoneResidencialCodigoArea: string | undefined,
  telefoneResidencialNumeroTelefone: string | undefined,
): string => {
  if (telefoneResidencialNumeroTelefone === '11111111') {
    return '';
  }
  return (
    tryGetValueOrDefault([telefoneResidencialCodigoArea], '') +
    tryGetValueOrDefault([telefoneResidencialNumeroTelefone], '')
  );
};

export const tratamentoExcecaoNumeroTelefoneVazioUpdate = (
  telefoneResidencialNumeroTelefone: string | undefined,
): string | undefined => {
  if (telefoneResidencialNumeroTelefone === '') {
    return '11111111';
  }
  return telefoneResidencialNumeroTelefone?.substr(2);
};

export const tratamentoExcecaoCodigoAreaVazioUpdate = (
  telefoneResidencialNumeroTelefone: string | undefined,
): string | undefined => {
  if (telefoneResidencialNumeroTelefone === '') {
    return '00';
  }
  return telefoneResidencialNumeroTelefone?.substr(0, 2);
};

export const obterTextoEstadoCivil = (sigla?: string) => {
  switch (sigla) {
    case ESTADO_CIVIL.SOLTEIRO:
      return ESTADO_CIVIL_LABEL.SOLTEIRO;
    case ESTADO_CIVIL.CASADO:
      return ESTADO_CIVIL_LABEL.CASADO;
    case ESTADO_CIVIL.VIUVO:
      return ESTADO_CIVIL_LABEL.VIUVO;
    default:
      return '';
  }
};

export const validarCamposObrigatorios = ({
  cepLink,
  numeroLink,
  enderecoLink,
  bairroLink,
  telefoneComercialLink,
  emailLink,
}: ValidaCamposObrigatoriosProps) => {
  return checkIfSomeItemsAreTrue([
    !cepLink.get().isValid,
    !numeroLink.get().isValid,
    !enderecoLink.get().isValid,
    !bairroLink.get().isValid,
    !telefoneComercialLink.get().isValid,
    !emailLink.get().isValid,
  ]);
};

export const identificarCamposNaoPreenchidos = ({
  cepLink,
  numeroLink,
  enderecoLink,
  bairroLink,
  telefoneComercialLink,
  emailLink,
}: ValidaCamposObrigatoriosProps) => {
  const camposObrigatorios = [
    { name: 'CEP', value: cepLink.get().isValid },
    { name: 'Número', value: numeroLink.get().isValid },
    { name: 'Endereço', value: enderecoLink.get().isValid },
    { name: 'Bairro', value: bairroLink.get().isValid },
    { name: 'Telefone Celular', value: telefoneComercialLink.get().isValid },
    { name: 'E-mail', value: emailLink.get().isValid },
  ];

  const camposNaoPreenchidos = camposObrigatorios.filter(campo => !campo.value);

  return camposNaoPreenchidos.map(campo => campo.name);
};

export const verificarFormulario = (
  camposObrigatorios: ValidaCamposObrigatoriosProps,
) => {
  return getTernaryResult(
    validarCamposObrigatorios(camposObrigatorios),
    `Preencha o campo de ${identificarCamposNaoPreenchidos(
      camposObrigatorios,
    ).join(' e ')}`,
    'Confirmar alterações dos dados do participante',
  );
};
