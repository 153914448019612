// @ts-nocheck
import { useContext, useEffect } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { EtapasFluxoSimulacaoRenda } from 'previdencia/features/SimulacaoRenda/types/enum';
import { ISimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/types/ISimulacaoRendaContextData';
import { IRequestSimularBeneficioApi } from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';
import { IUseSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/types/IUseSimulacaoRendaContextData';

export const useSimulacaoRendaContextData =
  (): IUseSimulacaoRendaContextData => {
    const {
      cliente: { cpfCnpj, numCertificado },
    } = useContext(AppContext);

    const {
      featureData: featureDataNullable,
      setFeatureData,
      clearFeatureData: clearContext,
    } = usePrevidenciaContext<ISimulacaoRendaContextData>();

    const featureData = featureDataNullable as ISimulacaoRendaContextData;

    const setEtapaAtual = (
      etapa: EtapasFluxoSimulacaoRenda,
      request?: Partial<IRequestSimularBeneficioApi>,
    ) => {
      setFeatureData({
        ...featureData,
        etapaAtual: etapa,
        requestSimularRenda: {
          ...featureData?.requestSimularRenda,
          ...request,
        },
      });
    };

    const exibirModal = (exibir: boolean) => {
      setFeatureData({
        ...featureData,
        exibirModalConsulta: exibir,
      });
    };

    const clearFeatureData = () => {
      setFeatureData(null);
      clearContext();
    };

    useEffect(() => {
      setFeatureData({
        ...featureData,
        requestSimularRenda: {
          ...featureData?.requestSimularRenda,
          cpf: cpfCnpj,
          numeroCertificado: numCertificado,
        },
      });
    }, [cpfCnpj, numCertificado]);

    return {
      setEtapaAtual,
      featureData,
      exibirModal,
      setFeatureData,
      clearFeatureData,
    };
  };
