// @ts-nocheck
import styled from 'styled-components/macro';

export const WrapperItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 46,
}));

export const TextItem = styled.p<{ isMarginRight?: boolean }>(
  ({ isMarginRight }) => ({
    color: '#414042',
    fontSize: 20,
    marginRight: isMarginRight ? 12 : 0,
  }),
);

export const Input = styled.input<{ largura?: string }>(({ largura }) => ({
  border: '1px solid #A4A5A9',
  borderRadius: 4,
  height: 56,
  color: '#414042',
  paddingLeft: 12,
  paddingRight: 12,
  outline: 0,
  background: '#fff',
  fontSize: 18,
  maxWidth: largura,
}));

export const InputDisabled = styled.input(() => ({
  border: '1px solid #A4A5A9',
  borderRadius: 4,
  height: 56,
  color: '#414042',
  paddingLeft: 12,
  paddingRight: 12,
  outline: 0,
  background: '#fff',
  fontSize: 18,
  textalign: 'center',
  backgroundColor: '#DBDCDE',
}));
