// @ts-nocheck
import { Grid } from '@cvp/design-system/react';
import Accordion from 'main/components/PEComponents/Accordion/Accordion';
import React from 'react';
import { DadosFinanciamentoCampoSkeleton } from './DadosFinanciamentoCampoSkeleton';

export const DadosFinanciamentoSkeleton: React.FC = () => {
  return (
    <Accordion open>
      <Accordion.Item
        title="Financiamento"
        key="Financiamento"
        data-testid="skeleton-dadosFinanciamento"
      >
        <Grid>
          <Grid>
            <DadosFinanciamentoCampoSkeleton label="Bem segurado" />
            <DadosFinanciamentoCampoSkeleton label="Início contrato" />
            <DadosFinanciamentoCampoSkeleton label="Fim contrato" />
            <DadosFinanciamentoCampoSkeleton label="Meses de contrato" />
            <DadosFinanciamentoCampoSkeleton label="Data do vencimento" />
            <DadosFinanciamentoCampoSkeleton label="Valor da parcela" />
            <DadosFinanciamentoCampoSkeleton label="Periodicidade de pagamento" />
            <DadosFinanciamentoCampoSkeleton label="Tipo de pagamento" />
            <DadosFinanciamentoCampoSkeleton label="Valor capital segurado" />
          </Grid>
        </Grid>
      </Accordion.Item>
    </Accordion>
  );
};
