// @ts-nocheck
import React, { useState } from 'react';
import { Card, Text, Display, Button, Select } from '@cvp/design-system/react';
import { ISelecItem } from 'main/types/DesignSystem/ISelectItem';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { useConsultarMetodoPagamento } from 'seguros/hooks';
import { AlteracaoDiaVencimentoProps } from 'seguros/types/DadosVencimentoSeguros';
import { DEBITO_CONTA } from 'seguros/constants/alterarDiaVencimentoSeguros';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import * as S from 'seguros/components/AlterarDiaVencimentoSeguros/styles';

export const DadosVencimentoSeguros: React.FC<AlteracaoDiaVencimentoProps> = ({
  onUpdateDay,
  data,
}) => {
  const { goDadosSeguradoVida } = useVidaNavigation();

  const [select, setSelect] = useState<ISelecItem>({} as ISelecItem);

  const selecaoValida = !!select.value;
  const { dadosConsultarMetodoPagamento, loadingConsultarMetodoPagamento } =
    useConsultarMetodoPagamento();
  const diaVencimentoAtual =
    dadosConsultarMetodoPagamento?.entidade?.dadosGerais?.diaDebitoNumero;

  const conditionRender = checkIfAllItemsAreTrue([
    dadosConsultarMetodoPagamento?.entidade?.dadosGerais
      ?.opcaoPagamentoCodigo !== DEBITO_CONTA,
    !loadingConsultarMetodoPagamento,
  ]);
  return (
    <>
      <RenderConditional condition={conditionRender}>
        <Display type="display-block">
          <Card>
            <Card.Content padding={[6, 8, 6, 8]}>
              <Text variant="headline-05" color="primary" margin>
                Alteração de Dia de Vencimento
              </Text>

              <Text variant="body02-sm" align="center" margin>
                Não é possível alterar dia de vencimento para este certificado.
              </Text>

              <Display>
                <Button
                  variant="outlined"
                  onClick={goDadosSeguradoVida}
                  data-testid="btnVoltar"
                >
                  Voltar
                </Button>
              </Display>
            </Card.Content>
          </Card>
        </Display>
      </RenderConditional>

      <RenderConditional condition={!conditionRender}>
        <Card>
          <RenderConditional condition={loadingConsultarMetodoPagamento}>
            <SkeletonLoading lines={4} />
          </RenderConditional>

          <Card.Content padding={[6, 8, 6, 8]}>
            <Text variant="headline-05" color="primary" margin>
              Alteração de Dia de Vencimento
            </Text>
            <Display>
              <Text
                variant="body05-sm"
                color="text"
                data-testid="textoDiaVencimentoAtual"
              >
                Dia de vencimento atual: <b>{diaVencimentoAtual}</b>
              </Text>
            </Display>

            <S.ContainerSelect>
              <Text
                variant="body05-sm"
                color="text"
                data-testid="textoNovoDiaVencimento"
              >
                Novo dia de vencimento:
              </Text>
              <Select
                placeholder="Escolha uma opção"
                data-testid="selectNovoDiaVencimento"
                onChange={({
                  target: { text, value },
                }: React.ChangeEvent<ISelecItem>) =>
                  setSelect(() => ({ text, value }))
                }
              >
                {data.listaNovosDiasVencimento.map(item => (
                  <Select.Item
                    key={item}
                    value={item}
                    text={item}
                    selected={item === select.value}
                  />
                ))}
              </Select>
            </S.ContainerSelect>

            <Text
              variant="caption-02"
              color="text-light"
              data-testid="textoLegendaNovoDiaVencimento"
            >
              Você conseguirá alterar o dia de vencimento uma vez a cada 24h
            </Text>

            <S.ContainerButtons>
              <Button variant="outlined" onClick={goDadosSeguradoVida}>
                Voltar
              </Button>
              <Button
                data-testid="alterar"
                disabled={!selecaoValida}
                variant="primary"
                onClick={() => {
                  onUpdateDay(select);
                }}
              >
                Alterar
              </Button>
            </S.ContainerButtons>
          </Card.Content>
        </Card>
      </RenderConditional>
    </>
  );
};
