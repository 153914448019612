// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Display, Text, Button } from '@cvp/design-system/react';
import * as S from '../components/styles';
import Icon from 'main/components/Icon';
import Table from 'main/components/Table';
import { colunasTabelaDistribuicaoFundo } from '../factories/colunasTabelaDistribuicaoFundos';
import { useTabelaDistribuicaoFundos } from '../hooks/useTabelaDistribuicaoFundos';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { useHistory } from 'react-router';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import ModalAvisoTipoFundo from '../components/Modais/ModalAvisoTipoFundo';
import { DEFAULT_NO_DATA_TABLE } from 'main/constants/messages';
import { gerarTextoAvisoDistribuicaoFundos } from '../constants/textos';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import Modal from 'main/components/Modal';

const DistribuicaoFundos: React.FC = () => {
  const [showModalAvisoTipoFundo, setShowModalAvisoTipoFundo] = useState(false);
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [showModalError, setShowModalError] = useState(false);

  const history = useHistory();
  const services = useTabelaDistribuicaoFundos();

  const {
    loadingFundosDisponiveis,
    fundosSelecionados,
    quantidadeMaximaFundos,
    valorMinimoDiversificacaoFundo,
    desabilitaPorFaltaSaldo,
    disableButton,
    percentualAtingido,
  } = services;

  const saldoParaDistribuir = tryGetValueOrDefault(
    [services?.saldoRestante],
    0,
  );

  const colunas = useMemo(
    () =>
      colunasTabelaDistribuicaoFundo({
        handlers: services,
        requisitosDiversificacao: {
          valorMinimoDiversificacao: valorMinimoDiversificacaoFundo,
          quantidadeMaximaFundos,
          quantidadeFundosSelecionados: fundosSelecionados.filter(
            fundo => fundo.selecionado,
          ).length,
          valorTotalDistribuir: saldoParaDistribuir,
          desabilitaPorFaltaSaldo,
        },
      }),
    [
      services,
      valorMinimoDiversificacaoFundo,
      fundosSelecionados,
      saldoParaDistribuir,
    ],
  );

  const restante = tryGetMonetaryValueOrDefault([saldoParaDistribuir], 0);

  useEffect(() => {
    if (services?.saldoRestante < 0) setShowModalError(!showModalError);
  }, [services?.saldoRestante]);
  if (loadingFundosDisponiveis) return <CardSkeleton />;

  return (
    <Display type="block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content>
          <Text variant="headline-05" color="primary" margin>
            Distribuição entre fundos
          </Text>
          <S.SectionTitle>
            {gerarTextoAvisoDistribuicaoFundos(quantidadeMaximaFundos)}
          </S.SectionTitle>
          <S.SectionTitlePrimary style={{ textAlign: 'center' }}>
            <Text variant="caption">
              <strong>Valor total a distribuir: {restante}</strong>
            </Text>
          </S.SectionTitlePrimary>
          <br />
          <Text variant="caption" color="error">
            <strong>
              <Icon name="warning" /> Essa ação não é uma simulação e após
              confirmada não poderá ser cancelada.
            </strong>
          </Text>
          <br />
          <Card>
            <S.CardContent>
              <Table
                columns={colunas}
                data={tryGetValueOrDefault([fundosSelecionados], [])}
                noDataComponent={DEFAULT_NO_DATA_TABLE}
                noHeader
              />
              <Text>Total: {services.saldoReserva} </Text>
              <Text>Restante: {restante}</Text>
              <Display alignItems="flex-end" justify="flex-end">
                <Button variant="outlined" onClick={() => history.goBack()}>
                  Voltar
                </Button>
                <Button
                  variant="primary"
                  disabled={checkIfSomeItemsAreTrue([
                    disableButton(),
                    !percentualAtingido(),
                    services?.saldoRestante < 0,
                  ])}
                  onClick={() => {
                    setMensagemAviso(services.compararPerfisFundo());
                    setShowModalAvisoTipoFundo(!showModalAvisoTipoFundo);
                  }}
                >
                  Avançar
                </Button>
              </Display>
            </S.CardContent>
          </Card>
        </Card.Content>
      </Card>
      <ModalAvisoTipoFundo
        show={showModalAvisoTipoFundo}
        onClose={() => setShowModalAvisoTipoFundo(!showModalAvisoTipoFundo)}
        nextStep={services.handleNextStep}
        message={mensagemAviso}
      />
      <Modal
        open={showModalError}
        onClose={() => setShowModalError(!showModalError)}
      >
        <Text>Atenção!</Text>
        <br />
        <Text variant="caption" color="error">
          O valor informado ultrapassa o valor de reserva disponível!
        </Text>
      </Modal>
    </Display>
  );
};

export default DistribuicaoFundos;
