// @ts-nocheck
import { useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { AppContext } from 'main/contexts/AppContext';
import * as DadosEnderecoCEPApi from 'previdencia/features/DadosParticipante/services/enderecoCEP.api';
import { DadosCEP } from '../types/DadosParticipante';

const useObterEnderecoCEP = (
  cep: string,
): UseQueryResult<DadosCEP | undefined> => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  return useQuery(
    ['prev-dados-cep', cpfCnpj],
    () => DadosEnderecoCEPApi.obterDadosCEP(cpfCnpj, cep),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      enabled: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};

export default useObterEnderecoCEP;
