// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import Modal from 'main/components/Modal';

type ModalAlertProps = {
  openModal: boolean;
  onClose: () => void;
};

export const ModalAlert: React.FC<ModalAlertProps> = ({
  openModal,
  onClose,
}) => {
  return (
    <>
      <Modal open={openModal} onClose={onClose}>
        <Text variant="body02-lg">
          O total de porcentagens de distribuição deve ser igual a 100%
        </Text>
      </Modal>
    </>
  );
};

export default ModalAlert;
