// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { useToast } from 'main/hooks/useToast';
import * as Api from 'previdencia/services/dadosClientePep.api';
import * as Interface from 'previdencia/types/IDadosPepGeral';
import { useContext, useState } from 'react';

export const useObterDadosClientePep = () => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataPep, setDataPep] = useState<
    Interface.IReponseDadosPep | undefined
  >();

  const buscaDadosClientePep = async () => {
    try {
      setLoading(true);
      const result = await Api.obterDadosClientePep(cpfCnpj);
      setDataPep(result);
    } catch (requestError: any) {
      toastError(requestError);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    dataPep,
    buscaDadosClientePep,
  };
};
