// @ts-nocheck
import { Grid } from '@cvp/design-system/react';
import React from 'react';
import * as S from 'sinistro/features/statusSinistro/pages/styles';
import CardProdutoSkeleton from 'sinistro/features/statusSinistro/components/CardProdutoSkeleton';

const ResultadoSinistroProdutosSkeleton: React.FC = () => {
  return (
    <Grid>
      <Grid.Item xs={1}>
        <S.TextResultado variant="body01-sm" color="primary-dark">
          Produtos:
        </S.TextResultado>
      </Grid.Item>
      <CardProdutoSkeleton />
      <CardProdutoSkeleton />
    </Grid>
  );
};

export default ResultadoSinistroProdutosSkeleton;
