// @ts-nocheck
import styled from 'styled-components/macro';
import { Tabs as TabsDS } from '@cvp/design-system/react';

export const Tabs = styled(TabsDS)(({ ...rest }) => ({
  ...rest,
}));

Tabs.Item = styled(TabsDS.Item)(({ ...rest }) => ({
  ...rest,
}));

export default Tabs;
