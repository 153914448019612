// @ts-nocheck
import * as Yup from 'yup';

const validationMessage = 'Campo obrigatório.';

export const vidatDatavalidationSchema = Yup.object({
  cep: Yup.string().min(8, 'Cep inválido').required(validationMessage),
  endereco: Yup.string().required(validationMessage),
  bairro: Yup.string().required(validationMessage),
  cidade: Yup.string().required(validationMessage),
  numCelular: Yup.string().required(validationMessage),
  email: Yup.string().required(validationMessage).email('E-mail inválido'),
});
