// @ts-nocheck
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const isInRange = (
  valor: number,
  rangeMin?: number,
  rangeMax?: number,
) => {
  if (!rangeMin && !rangeMax) return false;

  if (
    valor >= tryGetValueOrDefault([rangeMin], 0) &&
    valor <= tryGetValueOrDefault([rangeMax], 0)
  ) {
    return true;
  }

  return false;
};
