// @ts-nocheck
import React from 'react';
import { Button, Display } from '@cvp/design-system/react';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { payloadSendEmailSimularRendaFactory } from 'previdencia/features/SimulacaoRenda/factories/payloadSendEmailSimularRendaFactory';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';
import { EtapasFluxoSimulacaoRenda } from 'previdencia/features/SimulacaoRenda/types/enum';
import { DADOS_INICIAIS_SIMULACAO_RENDA } from 'previdencia/features/SimulacaoRenda/constants/constants';

const RedirecionamentoUltimasSimulacoes: React.FC = () => {
  const {
    setEtapaAtual,
    setFeatureData,
    featureData: { requestSimularRenda, podeAlterarRegimeTributario },
  } = useSimulacaoRendaContextData();

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.SIMULACAO_RENDA_PREVIDENCIA,
    parametrosEnvio: {
      cpfCnpj: requestSimularRenda?.cpf,
      numeroCertificado: requestSimularRenda?.numeroCertificado,
      tipoRenda: requestSimularRenda?.tipoRenda,
      ...payloadSendEmailSimularRendaFactory(requestSimularRenda),
    },
  };

  const inicio = (): void => {
    setFeatureData({
      ...DADOS_INICIAIS_SIMULACAO_RENDA,
      podeAlterarRegimeTributario,
    });
  };

  return (
    <Display>
      <Button variant="outlined" onClick={inicio}>
        Nova Simulação
      </Button>

      <Button
        variant="secondary"
        onClick={() =>
          setEtapaAtual(EtapasFluxoSimulacaoRenda.HISTORICO_SIMULACAO)
        }
      >
        Ver as Últimas Simulações
      </Button>

      <Button variant="secondary" onClick={() => window.print()}>
        Imprimir
      </Button>

      <EmailSenderModal objetoEmail={objetoEmail} />
    </Display>
  );
};

export default RedirecionamentoUltimasSimulacoes;
