// @ts-nocheck
import { Disclaimer } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const DisclaimerMsgError = ({
  mensagem,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IDisclaimerMsgErrorProps) => {
  return (
    <RenderConditional condition={!!mensagem}>
      <Disclaimer variant="error">
        <Disclaimer.Content text={mensagem} />
      </Disclaimer>
    </RenderConditional>
  );
};

export default DisclaimerMsgError;
