// @ts-nocheck
import styled from 'styled-components/macro';
import { Grid as GridDS } from '@cvp/design-system/react';

export const Grid = styled(GridDS)(({ ...rest }) => ({
  ...rest,
}));

Grid.Item = styled(GridDS.Item)(({ ...rest }) => ({
  ...rest,
}));

export default Grid;
