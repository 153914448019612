// @ts-nocheck
import { IMask, MaskMethods } from 'main/types/IMask';
import masks from 'main/utils/masks';

interface UploadpropostaMask extends IMask {
  SIFEC: MaskMethods;
  SIAPI: MaskMethods;
  SIAPX: MaskMethods;
}

const maskSiapiSiapx = (value: string) =>
  value.replace(/(\d{2})(\d{4})(\d{3})(\d{7})(\d{2})/g, '$1.$2.$3.$4-$5');

const UploadpropostaMasks: UploadpropostaMask = {
  SIFEC: {
    mask: (text: string) => masks.numberOnly.mask(text),
    unmask: (text: string) => masks.numberOnly.mask(text),
  },
  SIAPI: {
    mask: maskSiapiSiapx,
    unmask: (text: string) => masks.numberOnly.mask(text),
  },
  SIAPX: {
    mask: maskSiapiSiapx,
    unmask: (text: string) => masks.numberOnly.mask(text),
  },
};

export default UploadpropostaMasks;
