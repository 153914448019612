// @ts-nocheck
import { useToast } from 'main/hooks/useToast';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { useObterComprovanteOperacao } from 'previdencia/hooks/useObterComprovanteOperacao';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { StatusResgate } from 'previdencia/types/StatusResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as RESPONSE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateResponse';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

export const useHandleReponseResgate = () => {
  const { setFeatureData, featureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const { toastError, toastSuccess } = useToast();

  const { navigateTo } = usePrevNavigation();

  const { gerarComprovante } = useObterComprovanteOperacao();

  const handleResponseResgate = (
    dadosConfirmarResgate:
      | IHandleReponseResult<RESPONSE_TYPES.IConfirmarResgateResponse>
      | undefined,
  ) => {
    const dadosConfirmacaoResgate = dadosConfirmarResgate?.entidade;

    setFeatureData({
      ...featureData,
      dadosRetornoConfirmacaoResgate:
        FACTORY.obterDadosRetornoConfirmacaoResgateFactory(
          dadosConfirmacaoResgate,
          featureData?.numeroResgateConsolidado,
        ),
    });

    const [mensagemSucesso] = tryGetValueOrDefault(
      [dadosConfirmarResgate?.mensagens],
      [],
    );

    return {
      sucesso: checkIfAllItemsAreTrue([
        !!dadosConfirmarResgate?.sucessoBFF,
        !!dadosConfirmarResgate?.sucessoGI,
      ]),
      status: tryGetValueOrDefault([dadosConfirmacaoResgate?.status], ''),
      mensagemSucesso: tryGetValueOrDefault([mensagemSucesso?.descricao], ''),
    };
  };

  const exibirMensagensFeedbackResgate = async (
    sucesso: boolean,
    status: string,
    mensagemSucesso?: string,
  ) => {
    const isStatusValidoParaResgate = checkIfSomeItemsAreTrue([
      status === StatusResgate.SOLICITADO,
      status === StatusResgate.VALIDADO,
    ]);

    navigateTo('efetuar-resgate/sucesso');

    if (isStatusValidoParaResgate) {
      await gerarComprovante(
        tryGetValueOrDefault([featureData?.numeroResgateConsolidado], ''),
        CONSTS.TIPO_OPERACAO.RESGATE,
      );
    }

    if (checkIfAllItemsAreTrue([sucesso, isStatusValidoParaResgate])) {
      toastSuccess(mensagemSucesso);
    }

    if (
      checkIfAllItemsAreTrue([
        sucesso,
        status === CONSTS.STATUS_RESGATE.AGUARDANDO_ASSINATURA,
      ])
    ) {
      toastSuccess(CONSTS.RETORNO_SUCESSO_RESGATE);
    }

    if (!sucesso) toastError();
  };

  return {
    handleResponseResgate,
    exibirMensagensFeedbackResgate,
  };
};
