// @ts-nocheck
import { useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import * as HistoricoSolicitacoesApi from 'previdencia/features/HistoricoSolicitacoes/services/historicoSolicitacoes.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseHistoricoSolicitacoes } from '../types/HistoricoSolicitacoes';

const useConsultarHistoricoSolicitacoes = (
  dataInicial: string,
  dataFinal: string,
): UseQueryResult<ResponseHistoricoSolicitacoes[] | undefined> => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-historico-solicitacoes-', cpfCnpj],
    () =>
      HistoricoSolicitacoesApi.obterHistoricoSolicitacoes(
        cpfCnpj,
        numCertificado,
        dataInicial,
        dataFinal,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};
export default useConsultarHistoricoSolicitacoes;
