// @ts-nocheck
import React from 'react';
import { StatusCircle } from './styles';
import { TCircleStatusProps } from './CircleStatus.types';

const CircleStatus: React.FC<TCircleStatusProps> = ({ variant }) => {
  return <StatusCircle variant={variant} />;
};

export default CircleStatus;
