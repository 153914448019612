// @ts-nocheck
import { Text } from '@cvp/design-system/react';
import CardSkeleton from 'main/components/Card/CardSkeleton';

import styled from 'styled-components/macro';

export const CardContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Title = styled(Text)({
  textTransform: 'capitalize',
});

export const CardLoading = styled(CardSkeleton)({
  width: 300,
});
