// @ts-nocheck
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { Fundo } from 'previdencia/types/Fundo.type';
import { IResponseTransferenciaEntreFundos } from 'previdencia/types/ITransferirEntreFundos';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

export const converterFundo = (
  featureData:
    | TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData
    | null
    | undefined,
): Fundo[] => {
  const fundos: Fundo[] = [];

  if (!featureData?.certificadoOrigem?.fundos) return [];

  const isTransferenciaTotal: boolean =
    featureData?.tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.TOTAL;

  featureData?.certificadoOrigem?.fundos.forEach((fundo, index) => {
    fundos[index] = {
      codFundo: fundo.codFundo,
      descFundo: fundo.desFundo,
      rentabilidade: fundo.pctRentabUltimoAno,
      perfilRisco: fundo?.desPerfilFundo,
      saldo: fundo.vlrSaldo,
      contribuicaoAtual: 0,
      valor: 0,
      selecionado: isTransferenciaTotal,
      tipoTransferenciaFundo: getTernaryResult(
        isTransferenciaTotal,
        CONSTS.TIPOS_TRANSFERENCIA.TOTAL.charAt(0).toUpperCase(),
        '',
      ),
      valorTransferencia: getTernaryResult(
        isTransferenciaTotal,
        fundo.vlrSaldo.toString(),
        '0',
      ),
      valorMinimo: parseInt(
        tryGetValueOrDefault(
          [featureData?.certificadoOrigem?.vlrMinTransferencia],
          '',
        ),
        10,
      ),
    };
  });

  return fundos;
};
export const converterFundoDestino = (
  data: IResponseTransferenciaEntreFundos | undefined,
): Fundo[] => {
  const fundos: Fundo[] = [];
  if (!data?.reservasDestino) {
    return [];
  }
  data?.reservasDestino.forEach((fundo, index) => {
    fundos[index] = {
      codFundo: fundo.codFundo,
      descFundo: fundo.desFundo,
      rentabilidade: fundo.pctRentabUltimoAno,
      perfilRisco: fundo?.desPerfilFundo,
      contribuicaoAtual: 0,
      selecionado: false,
      valor: 0,
      valorMinimo: 0,
      saldo: 0,
    };
  });

  return fundos;
};

export const formatarFundoOrigem = (
  fundos: Fundo[],
  tipoTransferencia: string,
): Fundo[] => {
  return fundos.map((fundo: Fundo) => {
    return {
      ...fundo,
      fundoId: fundo.codFundo,
      percentual: getTernaryResult(
        tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.TOTAL,
        CONSTS_TEXT.TOTAL_PERCENTUAL.toString(),
        fundo.percentual,
      ),
      valorTransferencia: getTernaryResult(
        tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.TOTAL,
        fundo.saldo.toString(),
        tryGetValueOrDefault([fundo.valorTransferencia], '0'),
      ),
      staTipRetirada: fundo.tipoTransferenciaFundo,
    };
  });
};

const createDataCustomContribuicaoFactory = (
  valorContribuicao: string | undefined,
): TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IDataCustomContribuicaoRegular => {
  return {
    fundoComContribuicaoValida: {
      valorTransferencia: tryGetValueOrDefault([valorContribuicao], ''),
    },
    fundoSemContribuicaoValida: {
      selecionado: false,
      valorTransferencia: '0',
    },
  };
};

export const modificarFundoSelecionadoFactory = ({
  fundo,
  codigoFundo,
  origemFluxo,
  valorContribuicaoRegularlAtual,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IModificarFundoSelecionadoFactoryParams<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.TRetornoDinamicoFundos>): TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.TRetornoDinamicoFundos => {
  let dataCustomContribuicaoRegular: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IDataCustomContribuicaoRegular =
    {};

  if (origemFluxo === CONSTS.ORIGEM_FLUXO.CONTRIBUICAO_REGULAR) {
    dataCustomContribuicaoRegular = createDataCustomContribuicaoFactory(
      valorContribuicaoRegularlAtual,
    );
  }

  return getTernaryResult(
    fundo.codFundo === codigoFundo,
    {
      ...fundo,
      selecionado: !fundo.selecionado,
      valorTransferencia: '0',
      ...dataCustomContribuicaoRegular.fundoComContribuicaoValida,
    },
    { ...fundo, ...dataCustomContribuicaoRegular.fundoSemContribuicaoValida },
  );
};

export const distribuirValorTransferenciaFactory = ({
  fundo,
  codigoFundo,
  valorTransferencia,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IDistribuirValorTransferenciaFactoryParams<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.TRetornoDinamicoFundos>): TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.TRetornoDinamicoFundos => {
  return getTernaryResult(
    fundo.codFundo === codigoFundo,
    { ...fundo, valorTransferencia },
    { ...fundo },
  );
};

export const selecionarTipoTransferenciaFundoFactory = ({
  fundo,
  codigoFundo,
  tipoTransferenciaFundo,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ISelecionarTipoTransferenciaFundoFactoryParams): Fundo => {
  return getTernaryResult(
    fundo.codFundo === codigoFundo,
    {
      ...fundo,
      tipoTransferenciaFundo,
      valorTransferencia: getTernaryResult(
        tipoTransferenciaFundo ===
          CONSTS.TIPOS_TRANSFERENCIA.PARCIAL.charAt(0).toUpperCase(),
        '',
        fundo.saldo.toString(),
      ),
    },
    { ...fundo },
  );
};

export const obterFundosDestinoConsolidadoFactory = (
  featureData:
    | TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData
    | null
    | undefined,
): Partial<IResponseTransferenciaEntreFundos> => {
  const listaReservasExistentes = tryGetValueOrDefault(
    [featureData?.fundosDestinoDisponiveis?.reservasExistentes],
    [],
  );

  const listaReservasDestino = tryGetValueOrDefault(
    [featureData?.fundosDestinoDisponiveis?.reservasDestino],
    [],
  );

  return {
    ...featureData?.fundosDestinoDisponiveis,
    fundosDestinoConsolidado: [
      ...listaReservasExistentes,
      ...listaReservasDestino,
    ],
  };
};
