// @ts-nocheck
import { Card, Display, Text } from '@cvp/design-system/react';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { ITransferenciaMultifundosPjFeatureData } from 'previdencia/components/TabelaMultifundosPJ/types/ITransferenciaMultifundosPjFeatureData';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import AssinaturaTransferenciaFundos from 'previdencia/features/TransferenciaMultifundos/components/AssinaturaTransferenciaFundos';
import ConclusaoTransferenciaFundos from 'previdencia/features/TransferenciaFundoInvestimento/components/ConclusaoTransferenciaFundos';
import { EnumEtapas } from 'previdencia/types/Fundo.type';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ConfirmarTransferencia from '../components/ConfirmarTransferencia/ConfirmarTransferencia';
import Redistribuir from '../components/Redistribuir/Redistribuir';
import SelecionarFundosDestino from '../components/SelecionarFundosDestino/SelecionarFundosDestino';
import SelecionarFundosOrigem from '../components/SelecionarFundosOrigem/SelecionarFundosOrigem';
import { somaValorReserva } from '../utils/TransferenciaMultifundos';

const TransferenciaMultifundos: React.FC = () => {
  const { setFeatureData, featureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();
  const {
    data: responseObterContatosDefault,
    isFetched: recuperacaoEmailExecutada,
    isLoading: loadingObterContatos,
  } = useObterContatosDefaultPrevidencia();
  const { emailDefault, numerosTelefone } = responseObterContatosDefault ?? {};

  const history = useHistory();

  const [openModalAlerta, setOpenModalAlerta] = useState<boolean>(false);

  const data = [
    {
      resumo: 'Resumo',
      valor: 'Valor',
    },
    {
      resumo: 'Regular',
      valor: somaValorReserva(featureData?.fundosSelecionados, 'regular'),
    },
    {
      resumo: 'Adicional',
      valor: somaValorReserva(featureData?.fundosSelecionados, 'adicional'),
    },
    {
      resumo: 'Migrada',
      valor: somaValorReserva(featureData?.fundosSelecionados, 'migrada'),
    },
  ];

  const total = data.reduce((accumulator, item) => {
    const soma = accumulator + Number(item.valor);
    if (Number.isNaN(soma)) {
      return 0;
    }
    return soma;
  }, 0);

  data.push({
    resumo: 'Total',
    valor: total,
  });

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([recuperacaoEmailExecutada, !loadingObterContatos])
    ) {
      setOpenModalAlerta(true);
    }
  }, [recuperacaoEmailExecutada, loadingObterContatos]);

  useEffect(() => {
    setFeatureData({ ...featureData, dataResumo: data });
  }, [total]);

  useEffect(() => {
    setFeatureData({
      dataResumo: data,
      etapa: EnumEtapas.selecionarFundosOrigem,
    });
  }, []);

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content padding={[8, 8, 8]}>
          <Text variant="headline-05" color="primary" margin>
            Transferência de Fundos de Investimento
          </Text>
          <Text variant="body02-md" color="primary">
            Previdência Empresarial
          </Text>
          <RenderConditional
            condition={featureData?.etapa === EnumEtapas.selecionarFundosOrigem}
          >
            <SelecionarFundosOrigem />
          </RenderConditional>
          <RenderConditional
            condition={
              featureData?.etapa === EnumEtapas.selecionarFundosDestino
            }
          >
            <SelecionarFundosDestino total={total} />
          </RenderConditional>
          <RenderConditional
            condition={featureData?.etapa === EnumEtapas.confirmarTransferencia}
          >
            <ConfirmarTransferencia />
          </RenderConditional>
          <RenderConditional
            condition={featureData?.etapa === EnumEtapas.redistribuirValores}
          >
            <Redistribuir />
          </RenderConditional>
          <RenderConditional
            condition={featureData?.etapa === EnumEtapas.assinatura}
          >
            <AssinaturaTransferenciaFundos />
          </RenderConditional>
          <RenderConditional
            condition={featureData?.etapa === EnumEtapas.concluirComprovante}
          >
            <ConclusaoTransferenciaFundos />
          </RenderConditional>
        </Card.Content>
      </Card>
      <ModalAlertaContatos
        open={openModalAlerta}
        onClose={() => setOpenModalAlerta(false)}
        redirect={() =>
          history.push('/cliente/produtos/previdencia/dados-participante')
        }
        dados={{
          email: emailDefault,
          telefone: numerosTelefone,
        }}
      />
    </Display>
  );
};

export default TransferenciaMultifundos;
