// @ts-nocheck
import SkeletonLoading from 'main/components/SkeletonLoading/SkeletonLoading';
import { Display } from 'main/features/Auth/components';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { useState } from 'react';
import {
  CardResumoProduto,
  DadosVencimentoSeguros,
  ModalConfirmacaoSeguros,
  ResultadoAlteracaoDiaVencimentoSeguros,
} from 'seguros/components';
import {
  useAtualizarDiaVencimentoSeguros,
  useConsultarMetodoPagamento,
} from 'seguros/hooks';
import { listarNovosDiasVencimento } from 'seguros/utils/alterarDiaVencimento';

const AlterarDiaVencimento: React.FC<React.ReactElement> = () => {
  const [diaNovo, setDiaNovo] = useState<string>('');

  const [abrirModal, setAbrirModal] = useState<boolean>(false);
  const [registroAlterado, setRegistroAlterado] = useState<boolean>(false);

  const { dadosConsultarMetodoPagamento, loadingConsultarMetodoPagamento } =
    useConsultarMetodoPagamento();
  const dadosSegurado = dadosConsultarMetodoPagamento?.entidade;

  const {
    response,
    loading: isLoadingAlteracao,
    fetchData: alterarDiaVencimento,
  } = useAtualizarDiaVencimentoSeguros({
    dadosVencimento: dadosSegurado,
    diaNovoVencimento: diaNovo,
  });

  const solicitarConfirmacao = (dia: string) => {
    setDiaNovo(dia);
    setAbrirModal(true);
  };

  const diaVencimentoAtual = tryGetValueOrDefault(
    [dadosSegurado?.dadosGerais?.diaDebitoNumero],
    '',
  );
  const listaNovosDiasVencimento = listarNovosDiasVencimento().filter(
    dia => dia !== diaVencimentoAtual,
  );

  const sucesso = checkIfAllItemsAreTrue([
    registroAlterado,
    !!response?.sucessoGI,
    !!response?.sucessoBFF,
  ]);

  if (loadingConsultarMetodoPagamento) return <SkeletonLoading lines={4} />;

  if (registroAlterado)
    return (
      <ResultadoAlteracaoDiaVencimentoSeguros
        sucesso={sucesso}
        setRegistroAlterado={setRegistroAlterado}
      />
    );

  return (
    <Display type="display-block">
      <CardResumoProduto />
      <DadosVencimentoSeguros
        onUpdateDay={x => solicitarConfirmacao(x.value)}
        data={{
          diaVencimentoAtual,
          listaNovosDiasVencimento,
        }}
      />
      <ModalConfirmacaoSeguros
        open={abrirModal}
        handleClose={() => setAbrirModal(false)}
        handleConfirm={async () => {
          await alterarDiaVencimento();
          setRegistroAlterado(true);
          setAbrirModal(false);
        }}
        diaAntigo={diaVencimentoAtual}
        diaNovo={diaNovo}
        isLoading={isLoadingAlteracao}
      />
    </Display>
  );
};

export default AlterarDiaVencimento;
