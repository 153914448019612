// @ts-nocheck
export const obterEndpointTokenGateWay = (): string =>
  '/Autenticar/ObterTokenInfo';

export const obterEndpointAutenticacao = (): string =>
  '/PortalEconomiario/PECO_AutenticarUsuario';

export const obterEndpointAtualizarAcesso = (): string =>
  '/PortalEconomiario/PECO_AtualizarDados';

export const obterEndpointRecuperarUsuario = (): string =>
  '/PortalEconomiario/PECO_RecuperarAcesso';

export const obterEndpointConsultarFuncionarioCef = (): string =>
  '/PortalEconomiario/PECO_IdentificarAcesso';

export const obterEndpointCadastrarFuncionarioCef = (): string =>
  '/PortalEconomiario/PECO_CadastrarAcesso';

export const URLS_SSO_CAIXA = {
  development: 'https://ssopedev.caixavidaeprevidencia.com.br/auth',
  homologation: 'https://ssopehm.caixavidaeprevidencia.com.br/auth',
  production: 'https://ssope.caixavidaeprevidencia.com.br/auth',
};

export const PECOS_AUTH = {
  validarAcessoSso: 'PECO_ValidarAcessoSSO',
};
