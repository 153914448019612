// @ts-nocheck
import React from 'react';
import Icon from 'main/components/Icon';
import { BotaoLaminaProps } from 'previdencia/features/PosicaoConsolidada/types/BotaoLaminaProps';
import { TipoLamina } from 'previdencia/features/PosicaoConsolidada/utils/tipoLamina';
import { LoadingDots } from './styles';

const BotaoLamina: React.FC<BotaoLaminaProps> = ({
  doc,
  item,
  dados,
  isFetch,
}) => {
  return item.contaId === dados.contaId && isFetch ? (
    <LoadingDots data-testid="botaoLoading">
      <span />
    </LoadingDots>
  ) : (
    <Icon name={TipoLamina(doc)} data-testid="botaoIcone" />
  );
};

export default BotaoLamina;
