// @ts-nocheck
import styled from 'styled-components/macro';
import { Display } from '@cvp/design-system/react';

export const ContainerDeParaOpcoes = styled(Display)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  background: theme.color.background.light,
  borderRadius: 16,
  padding: '0 10px',
  margin: '20px 0',

  'p#tipoBeneficio': {
    fontStyle: 'italic',
  },

  'div:first-child': {
    borderRight: `1px solid ${theme.color.line.primary}`,
  },

  div: {
    display: 'flex',

    div: {
      display: 'flex',
      flexDirection: 'column',

      'p:first-child': {
        borderBottom: `1px solid ${theme.color.line.primary}`,
      },

      p: {
        padding: '10px',
        width: '250px',
        textAlign: 'center',
      },
    },
  },

  '@media (max-width: 768px)': {
    alignItems: 'center',

    'div:first-child': {
      borderRight: 'none',
    },

    div: {
      display: 'block',

      div: {
        display: 'flex',
        flexDirection: 'row',

        'p:first-child': {
          borderBottom: 'none',
        },

        p: {
          padding: '5px 5px 5px 0',
          width: 'auto',
          textAlign: 'left',
        },
      },
    },
  },
}));
