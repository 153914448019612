// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { useState } from 'react';
import { useAuth } from 'main/features/Auth/hooks/useAuth';
import { useToast } from 'main/hooks/useToast';
import * as PendenteDevolucao from 'reter/features/retencao/services/pendenteDevolucao.api';
import { ResponsePendenteDevolucao } from 'reter/features/retencao/types/ResponsePendenteDevolucao';

export const usePendenteDevolucao = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setResponse] = useState<
    ResponsePendenteDevolucao[] | undefined
  >();

  const obterListaPendenteDevolucao = async (): Promise<void> => {
    try {
      setLoading(true);
      const result = await PendenteDevolucao.obterListaPendenteDevolucao(
        user?.agenciaVinculada.toString(),
        user?.nomeAcesso,
      );

      setResponse(result);
    } catch (requestError: any) {
      toastError(requestError);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    obterListaPendenteDevolucao,
  };
};
