// @ts-nocheck
import { Button } from 'main/components/Header/styles';
import Icon from 'main/components/Icon';
import {
  formatDecimalValue,
  tryGetMonetaryValueOrDefault,
} from 'main/utils/money';
import { PropsTable } from 'painelPortabilidade/types/ConsultarPortabilidadeConstants';
import { Tooltip } from '@cvp/design-system/react';
import { ordenaValor, ordenaValorMonetario } from 'main/utils/ordenar';

export const buildColumnsHierarquia = (
  onclick: (unidade: string) => void,
  tipoFilhos?: string,
) => [
  {
    name: `Unidade ${tipoFilhos}`,
    width: '300px',
    sortable: true,
    sortFunction: ordenaValor('unidade'),
    cell: ({ unidade, nomeUnidade }: PropsTable) => {
      return (
        <Tooltip text={nomeUnidade} position="right" variant="secondary">
          <Button
            onClick={() => {
              if (unidade) {
                onclick(unidade);
              }
            }}
            variant="text"
            style={{ minWidth: '50px', padding: '0px' }}
          >
            {unidade} <Icon name="arrowRight" />
          </Button>
        </Tooltip>
      );
    },
    selector: ({ unidade }: PropsTable) => {
      return unidade;
    },
  },
  {
    name: 'Quantidade Total',
    minWidth: '100px',
    sortable: true,
    sortFunction: ordenaValor('quantidadeTotal'),
    selector: ({ quantidadeTotal }: PropsTable) => {
      return formatDecimalValue(quantidadeTotal);
    },
  },

  {
    name: 'Total',
    minWidth: '100px',
    sortable: true,
    sortFunction: ordenaValorMonetario('valorTotal'),
    selector: ({ valorTotal }: PropsTable) => {
      return tryGetMonetaryValueOrDefault(valorTotal, '-');
    },
  },

  {
    name: 'Qtde Acima R$50 mil',
    minWidth: '100px',
    sortable: true,
    sortFunction: ordenaValor('quantidadeAcimaValor'),
    selector: ({ quantidadeAcimaValor }: PropsTable) => {
      return formatDecimalValue(quantidadeAcimaValor);
    },
    center: true,
  },
  {
    name: 'Total Acima de R$50 mil',
    sortable: true,
    sortFunction: ordenaValorMonetario('valorTotalAcimaValor'),
    selector: ({ valorTotalAcimaValor }: PropsTable) => {
      return tryGetMonetaryValueOrDefault(valorTotalAcimaValor, '-');
    },
    center: true,
    minWidth: '180px',
  },
];

export const TiposVisao = {
  VP: 'VP',
  SN: 'SN',
  SR: 'SR',
  SEV: 'SEV',
  AG: 'AGENCIA',
};
