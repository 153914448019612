// @ts-nocheck
import { Text, Card, Button, Display } from '@cvp/design-system/react';
import React from 'react';
import { BOTOES_ATENDIMENTO } from '../constants';
import {
  BEM_VINDO,
  ESCOLHER_PRODUTO,
  HORARIO_ATENDIMENTO,
} from '../constants/texts';

const Webchat: React.FC = () => {
  const { REACT_APP_WEBCHAT_BASE_URL } = process.env;
  const abrirAtendimento = (id: string) => {
    window.open(`${REACT_APP_WEBCHAT_BASE_URL}/LiveAgentChatRouting/?${id}`);
  };
  return (
    <Card style={{ minHeight: 'auto' }}>
      <Card.Content>
        <Text color="primary" variant="headline-08">
          {BEM_VINDO}
        </Text>
        <Text variant="body02-md" color="text-light" margin>
          {HORARIO_ATENDIMENTO}
        </Text>
        <Text variant="body02-md" marign>
          {ESCOLHER_PRODUTO}
        </Text>
        <Display>
          {BOTOES_ATENDIMENTO.map(botao => (
            <Button onClick={() => abrirAtendimento(botao.id)}>
              {botao.label}
            </Button>
          ))}
        </Display>
      </Card.Content>
    </Card>
  );
};
export default Webchat;
