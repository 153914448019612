// @ts-nocheck
/* eslint-disable no-param-reassign */
import { AxiosInstance } from 'axios';
import getAuthData from 'main/features/Auth/utils/auth';

export const registerMarcadorControleInterceptors = (
  api: AxiosInstance,
): void => {
  api.interceptors.request.use(async config => {
    const {
      user: { marcadorControle },
    } = getAuthData();

    if (
      config.url?.includes('/PortalEconomiario') &&
      !config.url?.includes('PECO_AutenticarUsuario')
    ) {
      if (config.data instanceof FormData) {
        config.data.append('marcadorControle', marcadorControle);
      }

      if (!(config.data instanceof FormData)) {
        config.data = {
          ...config.data,
          marcadorControle,
        };
      }
    }
    return config;
  });
};
