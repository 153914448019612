// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { TEXTO_INFORMATIVO_RESGATE } from 'previdencia/features/TransferenciaFundoInvestimento/constants/constants';

type TextoInformativoResgateProps = {
  valorMinimo: number | undefined;
};

const TextoInformativoResgate: React.FC<TextoInformativoResgateProps> = ({
  valorMinimo,
}) => {
  return (
    <>
      <Text variant="body02-sm" margin>
        {TEXTO_INFORMATIVO_RESGATE.descricao}
      </Text>
      <Text variant="body02-sm" margin>
        Caso a opção seja parcial, um valor mínimo de{' '}
        <strong>{formatarValorPadraoBrasileiro(valorMinimo)} </strong>
        deverá permanecer no fundo.
      </Text>
    </>
  );
};

export default TextoInformativoResgate;
