// @ts-nocheck
import { obterEndpointImprimirDadosExtratoCotasPrevidencia } from 'previdencia/config/endpoints';
import { api } from 'main/services';
import RangeDate from 'previdencia/types/RangeDate';

export const imprimirDadosExtratoCotas = async (
  numeroCertificado: string,
  cpfCnpj: string,
  dataBusca?: RangeDate,
): Promise<string> => {
  const { data } = await api.post(
    obterEndpointImprimirDadosExtratoCotasPrevidencia(),
    {
      numeroCertificado,
      cpfCnpj,
      dataInicial: dataBusca?.dataInicial,
      dataFinal: dataBusca?.dataFinal,
    },
    { responseType: 'text' },
  );

  return data;
};
