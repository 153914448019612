// @ts-nocheck
import React, { useContext } from 'react';
import { Text } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import RenderConditional from 'main/components/RenderConditional';
import { isCpf } from 'main/utils/cpf_cnpj';

const TextoInformativoSemProdutosPrevidencia: React.FunctionComponent = () => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  return (
    <>
      <RenderConditional condition={isCpf(cpfCnpj)}>
        <Text>Nenhum produto ativo localizado para este cliente.</Text>
        <Text>
          Informamos que se o produto foi adquirido recentemente ele será
          disponibilizado em breve.
        </Text>
      </RenderConditional>
      <RenderConditional condition={!isCpf(cpfCnpj)}>
        <Text>Este produto não permite consulta para planos empresariais!</Text>
      </RenderConditional>
    </>
  );
};

export default TextoInformativoSemProdutosPrevidencia;
