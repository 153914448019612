// @ts-nocheck
import React from 'react';
import { useHistory } from 'react-router';
import {
  Text,
  Card,
  Accordion,
  Display,
  Button,
} from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';
import * as OCORRENCIA_STYLES from 'registroOcorrenciaASC/styles/styles';

const CardConclusao: React.FC<CONSULTA_OCORRENCIA_TYPES.CardConclusaoProps> = ({
  loadingDadosDetalhesOcorrencia,
  dadosDetalhesOcorrencia,
}) => {
  const history = useHistory();

  return (
    <OCORRENCIA_STYLES.CardContainer>
      <Card.Content padding={[6, 8, 6, 8]}>
        <Accordion data-testid="accordionConclusaoOcorrencia">
          <Accordion.Item title="Conclusão">
            <RenderConditional condition={loadingDadosDetalhesOcorrencia}>
              <SkeletonLoading lines={4} />
            </RenderConditional>

            <Text variant="body02-sm">
              <strong>Data da conclusão</strong>
            </Text>
            <Text variant="body02-sm" margin>
              {tryGetValueOrDefault(
                [dadosDetalhesOcorrencia?.entidade?.dataResolucao],
                '-',
              )}
            </Text>

            <Text variant="body02-sm">
              <strong>Resposta</strong>
            </Text>
            <Text variant="body02-sm" margin>
              {tryGetValueOrDefault(
                [dadosDetalhesOcorrencia?.entidade?.respostaSolicitacao],
                '-',
              )}
            </Text>

            <Display justify="center">
              <Button variant="outlined" onClick={history.goBack}>
                Voltar
              </Button>
            </Display>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </OCORRENCIA_STYLES.CardContainer>
  );
};

export default CardConclusao;
