// @ts-nocheck
import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import * as Factory from '../factories';
import * as TypesDPS from '../types';
import { useContratosPrestamistaContext } from 'contratosPrestamista/contexts/ContratosPrestamistaContext';
import { useAuth } from 'main/features/Auth/hooks';
import {
  IConsultarSubscricaoRequest,
  IConsultarSubscricaoResponse,
} from 'contratosPrestamista/types';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const usePecoFluxoPF = () => {
  const { user } = useAuth();
  const { featureData } =
    useContratosPrestamistaContext<TypesDPS.IDpsEletronicaFeatureDataFluxoPF>();

  const {
    loading: loadingVerificarNecessidadeDps,
    response: responseVerificarNecessidadeDps,
    fetchData: fetchVerificarNecessidadeDps,
  } = usePeco<
    TypesDPS.IVerificarExigenciaDpsRequest,
    TypesDPS.IResponseVerificarExigenciaDps
  >({
    api: {
      operationPath: PECOS_PRESTAMISTA.VerificarNecessidadeDps,
    },
    payload: Factory.construirPayloadVerificarNecessidadeDpsPF({
      capitalSegurado: featureData?.dadosContrato?.capitalSegurado,
      numeroAgencia: user.agenciaVinculada,
      valorAcumulo: featureData?.dadosContrato?.valorAcumulo,
      cpf: tryGetValueOrDefault([featureData?.dadosContrato?.cpfCnpj], ''),
      numeroProposta: featureData?.dadosContrato?.codContrato,
    }),
  });

  const {
    fetchData: fetchDataConsultarSubscricao,
    loading: loadingConsultarSubscricao,
  } = usePeco<IConsultarSubscricaoRequest, IConsultarSubscricaoResponse>({
    api: {
      operationPath: PECOS_PRESTAMISTA.ConsultarSubscricao,
    },
  });

  const {
    fetchData: fecthSolicitarDps,
    response: responseSolicitarDps,
    loading: loadingSolicitarDps,
  } = usePeco<TypesDPS.ISolicitarDpsRequest, TypesDPS.ISolicitarDpsResponse>({
    api: {
      operationPath: PECOS_PRESTAMISTA.SolicitarDps,
    },
    handleResponse: { throwToastErrorBFF: false },
  });

  return {
    loadingVerificarNecessidadeDps,
    responseVerificarNecessidadeDps,
    loadingConsultarSubscricao,
    fetchDataConsultarSubscricao,
    fecthSolicitarDps,
    fetchVerificarNecessidadeDps,
    responseSolicitarDps,
    loadingSolicitarDps,
  };
};
