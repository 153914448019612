// @ts-nocheck
import { Card, Grid, Text } from '@cvp/design-system/react';

import For from 'main/components/For';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ALIQUOTA } from 'previdencia/constants/constants';
import { IListarFundosParaResgateAliquota } from 'previdencia/types/ConsultaListaFundosParaResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import { Table } from 'previdencia/features/SolicitacaoResgate/pages/styles';
import * as S from './styles';

const TabelasAliquotasAgrupadasResgate = ({
  loading,
  dadosTabelaFundosAliquota,
  exibirAliquotas,
}: SOLICITACAO_RESGATE_TYPES.ITabelasAliquotasAgrupadasResgateProps) => {
  const { featureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const { opcoesAliquotas } = tryGetValueOrDefault(
    [featureData?.dadosListarFundosParaResgate?.aliquota],
    {} as IListarFundosParaResgateAliquota,
  );

  return (
    <For each={tryGetValueOrDefault([opcoesAliquotas], [])}>
      {tipoAliquota => (
        <>
          <RenderConditional condition={loading}>
            <Grid.Item {...exibirAliquotas(tipoAliquota).gridTabelas}>
              <SkeletonLoading lines={4} />
            </Grid.Item>
          </RenderConditional>

          <RenderConditional
            condition={exibirAliquotas(tipoAliquota).condicionalTabelas}
          >
            <Grid.Item {...exibirAliquotas(tipoAliquota).gridTabelas}>
              <Card>
                <Card.Content padding={[0, 0, 5, 0]}>
                  <S.ContainerTituloAliquota>
                    <Text variant="body01-md" color="white">
                      {tipoAliquota?.descricaoAliquota}
                    </Text>
                  </S.ContainerTituloAliquota>

                  <Table
                    noHeader
                    responsive
                    highlightOnHover
                    striped
                    data={tryGetValueOrDefault(
                      [
                        getTernaryResult(
                          tipoAliquota.codigoAliquota ===
                            ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
                          dadosTabelaFundosAliquota?.fundosAliquotaProgressivo,
                          dadosTabelaFundosAliquota?.fundosAliquotaRegressivo,
                        ),
                      ],
                      [],
                    )}
                    columns={CONSTS.COLUNAS_ALIQUOTA}
                    noDataComponent={CONSTS.NO_DATA_TABLE}
                    conditionalRowStyles={CONSTS.conditionalRowStyles}
                  />
                </Card.Content>
              </Card>
            </Grid.Item>
          </RenderConditional>
        </>
      )}
    </For>
  );
};

export default TabelasAliquotasAgrupadasResgate;
