// @ts-nocheck
export const CONCLUSAO_TRANSFERENCIA_FUNDOS = {
  titulo: 'Transferência solicitada com sucesso.',
  descricao: `A solicitação de transferência interna foi concluída com sucesso! O
  saldo permanecerá zerado até a efetivação da transferência, qual possui o prazo
  máximo de até 2 dias úteis (conforme legislação vigente).`,
  importante: `Durante esse período, poderão ocorrer variações de cota e no valor
  transferido devido o tipo de fundo escolhido (renda fixa ou variável). Caso exista
  uma parcela de cobrança futura gerada, esta será automaticamente direcionada para o
  novo fundo de investimento.`,
};

export const TEXTO_INFORMATIVO_RESGATE = {
  descricao: `Confira como está a distribuição dos valores em cada um dos fundos de
        investimento. É possível ajustar o percentual e o valor em cada fundo,
        de acordo com a regra do produto.`,
};
