// @ts-nocheck
export const USER_PROFILES = {
  MEDICO: 'MEDICO',
  ECONOMIARIO: 'ECONOMIARIO',
  ANALISTA_PRODUTO: 'ANALISTA_PRODUTO',
  ADMINISTRADOR: 'ADMINISTRADOR',
  OPERADOR: 'OPERADOR',
  ANALISTA_CVP: 'ANALISTA_CVP',
  SAC_CAIXA_TERCEIROS: 'SAC_CAIXA_TERCEIROS',
  ANALISTA_MANUTENCAO: 'ANALISTA_MANUTENCAO',
  ANALISTA_SAIDA: 'ANALISTA_SAIDA',
  ANALISTA_ENTRADA: 'ANALISTA_ENTRADA',
  ANALISTA_TI: 'ANALISTA_TI',
  ANALISTA_CONSULTA: 'ANALISTA_CONSULTA',
  ANALISTA_POS_VENDA: 'ANALISTA_POS_VENDA',
  ATUALIZADOR: 'ATUALIZADOR',
};

export const PRINCIPAL_USERS = [
  USER_PROFILES.ECONOMIARIO,
  USER_PROFILES.ANALISTA_POS_VENDA,
  USER_PROFILES.ANALISTA_TI,
];

export const TODOS_USUARIOS = [
  ...PRINCIPAL_USERS,
  USER_PROFILES.ANALISTA_PRODUTO,
  USER_PROFILES.OPERADOR,
  USER_PROFILES.MEDICO,
];

export const PREV_PERMISSIONS = {
  ALTERAR_DADOS_DE_PAGAMENTO: 'ALTERAR_DADOS_DE_PAGAMENTO',
  ALTERAR_VALOR_DA_CONTRIBUICAO: 'ALTERAR_VALOR_DA_CONTRIBUICAO',
  ATIVACAO_E_SUSPENSAO_DE_CONTRIBUICOES:
    'ATIVACAO_E_SUSPENSAO_DE_CONTRIBUICOES',
  BENEFICIARIOS_CADASTRADOS_EDITAR: 'BENEFICIARIOS_CADASTRADOS_EDITAR',
  CARTEIRA_DE_INVESTIMENTOS: 'CARTEIRA_DE_INVESTIMENTOS',
  CONTRIBUICAO_ADICIONAL: 'CONTRIBUICAO_ADICIONAL',
  CONTRIBUICOES_EM_ABERTO: 'CONTRIBUICOES_EM_ABERTO',
  CONTRIBUICOES_PAGAS: 'CONTRIBUICOES_PAGAS',
  DADOS_DE_PAGAMENTO: 'DADOS_DE_PAGAMENTO',
  DADOS_DE_PAGAMENTO_CONSULTA: 'DADOS_DE_PAGAMENTO_CONSULTA',
  DIA_DE_VENCIMENTO_DO_PLANO: 'DIA_DE_VENCIMENTO_DO_PLANO',
  EMITIR_CERTIFICADO: 'EMITIR_CERTIFICADO',
  ENDERECO_DE_CORRESPONDENCIA__CONSULTA:
    'ENDERECO_DE_CORRESPONDENCIA__CONSULTA',
  ENDERECO_DE_CORRESPONDENCIA__EDITAR: 'ENDERECO_DE_CORRESPONDENCIA__EDITAR',
  EXTRATO_DE_BENEFICIO: 'EXTRATO_DE_BENEFICIO',
  EXTRATO_DETALHADO: 'EXTRATO_DETALHADO',
  HISTORICO_DE_PAGAMENTO: 'HISTORICO_DE_PAGAMENTO',
  HISTORICO_DE_SOLICITACOES: 'HISTORICO_DE_SOLICITACOES',
  HISTORICO_E_CANCELAMENTO_DE_RESGATE: 'HISTORICO_E_CANCELAMENTO_DE_RESGATE',
  IMPOSTO_DE_RENDA: 'IMPOSTO_DE_RENDA',
  INFORMACOES_DO_CONTATO: 'INFORMACOES_DO_CONTATO',
  INFORMACOES_DO_PARTICIPANTE: 'INFORMACOES_DO_PARTICIPANTE',
  INFORMACOES_DO_PLANO: 'INFORMACOES_DO_PLANO',
  INFORMACOES_DOS_BENEFICIARIOS_CONSULTA:
    'INFORMACOES_DOS_BENEFICIARIOS_CONSULTA',
  PRAZO_DE_TERMINO_DO_PLANO: 'PRAZO_DE_TERMINO_DO_PLANO',
  QUITACAO_ANUAL: 'QUITACAO_ANUAL',
  REAJUSTE_E_REENQUADRAMENTO: 'REAJUSTE_E_REENQUADRAMENTO',
  REGISTRO_DE_OCORRENCIAS: 'REGISTRO_DE_OCORRENCIAS',
  RENTABILIDADE_E_COTAS: 'RENTABILIDADE_E_COTAS',
  SIMULADOR_DE_RENDA: 'SIMULADOR_DE_RENDA',
  SOLICITACAO_DE_RESGATE: 'SOLICITACAO_DE_RESGATE',
  TRANSFERENCIA_DE_FUNDOS: 'TRANSFERENCIA_DE_FUNDOS',
  ULTIMAS_PARCELAS: 'ULTIMAS_PARCELAS',
  CANCELAR_CERTIFICADO: 'CANCELAR_CERTIFICADO',
  INCLUIR_PROTECAO: 'INCLUIR_PROTECAO',
  ALTERAR_REGIME_TRIBUTARIO: 'ALTERAR_REGIME_TRIBUTARIO',
  POSICAO_CONSOLIDADA: 'POSICAO_CONSOLIDADA',
  EVOLUCAO_PATRIMONIAL: 'EVOLUCAO_PATRIMONIAL',
  AGENCIA_DIGITAL: 'AGENCIA_DIGITAL',
  TRANSFERENCIA_REST: 'PECO_REST',
  TRANSFERENCIA_CERTIFICADO: 'TRANSFERENCIA_CERTIFICADO',
  TRANSFERENCIA_EMISSAO: 'TRANSFERENCIA_EMISSAO',
};

export const VIDA_PERMISSIONS = {
  CERTIFICADO_PF: 'Individual',
  CERTIFICADO_PJ: 'Empresarial',
  BILHETE_PF: 'Bilhete',
};

export const VIDA_PERMISSIONS_STRING = {
  CERTIFICADO_PF: 'Individual',
  CERTIFICADO_PJ: 'Empresarial',
  BILHETE_PF: 'Bilhete',
};
