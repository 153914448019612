// @ts-nocheck
import React, { useMemo } from 'react';
import { Button, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import DadosRendaVitaliciaReversivel from 'previdencia/features/SimulacaoRenda/components/DadosRendaVitaliciaReversivel';
import * as S from 'previdencia/features/SimulacaoRenda/components/InformacoesAdicionaisTipoRenda/styles';
import PrazoRecebimentoRenda from 'previdencia/features/SimulacaoRenda/components/PrazoRecebimentoRenda';
import { TIPOS_RENDA_INFORMACAOA_ADICIONAL } from 'previdencia/features/SimulacaoRenda/constants/constants';
import {
  LEGENDA,
  SUB_TITULO,
  TITULO,
} from 'previdencia/features/SimulacaoRenda/constants/informacoesAdicionaisTipoRenda';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';
import {
  EnumTipoRendaCodigo,
  EtapasFluxoSimulacaoRenda,
} from 'previdencia/features/SimulacaoRenda/types/enum';
import { retornarTipoRenda } from 'previdencia/features/SimulacaoRenda/utils/simulacaoRenda';

const InformacoesAdicionaisTipoRenda: React.FC = () => {
  const { setEtapaAtual, featureData } = useSimulacaoRendaContextData();
  const tipoRenda = featureData?.requestSimularRenda?.tipoRenda;

  const retornoTipoRenda = retornarTipoRenda(
    TIPOS_RENDA_INFORMACAOA_ADICIONAL,
    featureData?.requestSimularRenda?.tipoRenda,
  );

  const exibirPrazoRecebimentoRenda = useMemo(
    (): boolean =>
      checkIfSomeItemsAreTrue([
        tipoRenda === EnumTipoRendaCodigo.Temporaria,
        tipoRenda === EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
        tipoRenda === EnumTipoRendaCodigo.PrazoCerto,
      ]),
    [tipoRenda],
  );

  return (
    <>
      <Text variant="body02-lg" margin>
        {TITULO} <strong>{retornoTipoRenda?.tipo}</strong>
      </Text>

      <S.ContainerInfo>
        <Text variant="body02-sm">
          <strong>{SUB_TITULO}</strong>
        </Text>
        <Text variant="body02-sm">{retornoTipoRenda?.descricao}</Text>
      </S.ContainerInfo>

      <S.Text variant="caption-02" margin>
        {LEGENDA}
      </S.Text>

      <RenderConditional condition={exibirPrazoRecebimentoRenda}>
        <PrazoRecebimentoRenda />
      </RenderConditional>

      <RenderConditional
        condition={
          tipoRenda === EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario
        }
      >
        <DadosRendaVitaliciaReversivel />
      </RenderConditional>

      <RenderConditional
        condition={tipoRenda === EnumTipoRendaCodigo.Vitalicia}
      >
        <S.ContainerButtons>
          <Button
            variant="outlined"
            onClick={() =>
              setEtapaAtual(EtapasFluxoSimulacaoRenda.INICIO_SIMULACAO)
            }
          >
            Voltar
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              setEtapaAtual(EtapasFluxoSimulacaoRenda.RESULTADO_SIMULACAO)
            }
          >
            Simular
          </Button>
        </S.ContainerButtons>
      </RenderConditional>
    </>
  );
};

export default InformacoesAdicionaisTipoRenda;
