// @ts-nocheck
import { AxiosRequestConfig } from 'axios';
import { api } from 'main/services';
import { URLS_MATRIZ_INTERCEPTOR } from 'previdencia/config/endpoints';
import { obterTokenOperador } from 'previdencia/services/matrizAcesso.api';

const mapInterceptor = async (request: AxiosRequestConfig) => {
  const match = URLS_MATRIZ_INTERCEPTOR.some(url =>
    url.includes(request.url ?? ''),
  );

  if (match) {
    const token = await obterTokenOperador();
    if (!(request.data instanceof FormData)) {
      request.data = {
        ...request.data,
        xToken: token,
      };
    }
    if (request.data instanceof FormData) {
      request.data.append('xToken', token);
    }
  }
  return request;
};

export const registerMatrizAcessoInterceptor = () => {
  api.interceptors.request.use(async request => {
    await mapInterceptor(request);
    return request;
  });
};
