// @ts-nocheck
import { Display, Grid, Text } from '@cvp/design-system/react';
import { DadosFinanciamentoCampoProps } from 'prestamista/features/dadosSeguro/types/IDadosFinanciamento';

export const DadosFinanciamentoCampo = ({
  label,
  value,
}: DadosFinanciamentoCampoProps) => (
  <Grid.Item xs={1} md={1 / 2} lg={1 / 3}>
    <Display>
      <div>
        <Text variant="caption-02" color="text-light">
          {label}
        </Text>
        <Text variant="caption-02" color="text">
          {value}
        </Text>
      </div>
    </Display>
  </Grid.Item>
);
