// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IFundosOrigem,
  IPayloadDefinirSaida,
  IResponseDefinirSaida,
} from 'previdencia/features/TransferenciaMultifundos/types/IFundos';
import { useContext } from 'react';

const useDefinirSaida = (fundosOrigem: IFundosOrigem[] | undefined) => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const { response, loading, fetchData } = usePeco<
    IPayloadDefinirSaida,
    IResponseDefinirSaida
  >({
    api: {
      operationPath: PECOS.DefinirSaidaMultifundos,
    },
    autoFetch: false,
    payload: {
      certificado: numCertificado,
      fundosOrigem,
    },
  });

  return { response: response?.entidade, loading, fetchData };
};

export default useDefinirSaida;
