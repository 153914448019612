// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Display, Modal, Button, Text } from '@cvp/design-system/react';
import { required, email } from 'main/features/Validation/validations';
import { validate } from 'main/features/Validation/utils/validateRules';
import { Input } from 'main/components/form';
import useFieldLink from 'main/hooks/useFieldLink';
import LinhaDigitavelBoleto from 'previdencia/components/LinhaDigitavelBoleto';
import { enviarEmail } from 'main/services/emailService';
import RenderConditional from 'main/components/RenderConditional';
import { AppContext } from 'main/contexts/AppContext';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { useToast } from 'main/hooks/useToast';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import * as S from './styles';
import useObterSegundaViaBoleto from '../../hooks/useObterSegundaViaBoleto';

type ModalEnviarSegundaViaProps = {
  open: boolean;
  handleClose(close: boolean): void;
  numeroCobranca: string;
  linhaDigitavel: string;
};

const ModalEnviarSegundaVia: React.FunctionComponent<
  ModalEnviarSegundaViaProps
> = ({ open, handleClose, numeroCobranca, linhaDigitavel }) => {
  const { cliente } = useContext(AppContext);
  const [emailLink, validateEmail] = useFieldLink('');
  const [loadingEmail, setLoadingEmail] = useState(false);
  const { loading, error, obterSegundaVia } = useObterSegundaViaBoleto();
  const [erroExibido, setErroExibido] = useState(error);
  const { toastError, toastSuccess } = useToast();

  const { data: responseObterContatosDefault } =
    useObterContatosDefaultPrevidencia();
  const { emailDefault } = responseObterContatosDefault ?? {};

  const imprimirSegundaViaBoleto = () => {
    obterSegundaVia(numeroCobranca);
  };

  const onClose = () => {
    handleClose(true);
    setErroExibido('');
  };

  const enviarEmailSegundaViaBoleto = async () => {
    try {
      setLoadingEmail(true);
      const sendResult = await enviarEmail({
        parametrosEnvio: {
          cpfCnpj: cliente.cpfCnpj,
          numeroCertificado: cliente.numCertificado,
          numeroCobranca,
        },
        tipoEmail: tipoEmailConstants.SEGUNDA_VIA_BOLETO_CONTRIBUICOES_PAGAS,
        enderecoEmail: emailLink.get().value,
      });
      setLoadingEmail(false);
      if (sendResult.sucess) {
        onClose();
        toastSuccess('E-mail enviado com sucesso!');
      } else {
        const message = `${sendResult?.resultCode}: ${sendResult.resultMessage}`;
        setErroExibido(message);
        toastError(message);
      }
    } catch (erroEmail) {
      if (erroEmail instanceof Error) toastError(erroEmail.message);
    }
  };

  const setLinkValue = (value: string) => {
    emailLink.set({
      value,
      isValid: true,
      errorMsg: '',
    });
  };

  useEffect(() => {
    if (emailDefault) {
      setLinkValue(emailDefault);
    }
  }, [emailDefault]);

  return (
    <Modal show={open} onClose={onClose}>
      <Text variant="body02-md" margin>
        Caso deseje enviar este documento por e-mail, preencha o campo abaixo e
        selecione &quot;Enviar&quot;:
      </Text>
      <RenderConditional
        condition={erroExibido !== undefined && erroExibido !== ''}
      >
        <Display margin>
          <Text variant="lead" color="error">
            {' '}
            {erroExibido}
          </Text>
        </Display>
      </RenderConditional>
      <S.LabelErrorInput>
        <Input
          link={emailLink}
          validationRules={[required(), email()]}
          label="E-mail"
          data-testid="input-print"
        />
      </S.LabelErrorInput>

      <LinhaDigitavelBoleto linhaDigitavel={linhaDigitavel} />

      <Display justify="center">
        <Button
          variant="secondary"
          loading={loading || loadingEmail}
          disabled={loading || loadingEmail}
          onClick={() => {
            if (validate([validateEmail])) {
              enviarEmailSegundaViaBoleto();
            }
          }}
          data-testid="submit"
        >
          Enviar E-mail
        </Button>
        <Button
          variant="primary"
          onClick={() => imprimirSegundaViaBoleto()}
          loading={loading || loadingEmail}
          disabled={loading}
        >
          Imprimir Boleto
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalEnviarSegundaVia;
