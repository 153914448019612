// @ts-nocheck
import { api, IApiResponse } from 'main/services';
import { obterEndPointDadosPep } from 'previdencia/config/endpoints';
import * as Interface from 'previdencia/types/IDadosPepGeral';

export const obterDadosClientePep = async (
  cpfCnpj: string,
): Promise<Interface.IReponseDadosPep | undefined> => {
  const { data } = await api.post<IApiResponse<Interface.IReponseDadosPep>>(
    obterEndPointDadosPep(),
    {
      cpfCnpj,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
