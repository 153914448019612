// @ts-nocheck
import React, { useMemo } from 'react';
import { Table } from 'portabilidade/components/Table';
import { formatarDataHora } from 'main/utils';
import {
  DetalhesPortabilidade,
  getDefaultInstance,
} from '../../types/DetalhesPortabilidade';

type HistoricoSituacaoPortabilidadeProps = {
  dadosPortabilidade?: DetalhesPortabilidade;
};

const HistoricoSituacaoPortabilidade: React.FunctionComponent<
  HistoricoSituacaoPortabilidadeProps
> = ({ dadosPortabilidade }) => {
  const colunas = useMemo(
    () => [
      {
        name: 'Situação',
        selector: 'situacao',
        sortable: false,
      },
      {
        name: 'Data e hora do registro',
        selector: 'dataHorarioRegistro',
        cell: ({ dataHorarioRegistro }: any) =>
          formatarDataHora(dataHorarioRegistro),
        sortable: true,
      },
    ],
    [],
  );
  return (
    <Table
      columns={colunas}
      noHeader
      title="Histórico da Situação"
      data={dadosPortabilidade?.historicoSituacaoRetencao ?? []}
      noDataComponent="Não há dados para exibir."
    />
  );
};

HistoricoSituacaoPortabilidade.defaultProps = {
  dadosPortabilidade: getDefaultInstance(),
};

export default HistoricoSituacaoPortabilidade;
