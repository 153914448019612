// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import React from 'react';
import { Grid } from '@cvp/design-system/react';
import * as S from 'previdencia/features/DadosParticipante/components/MensagensAlerta/styles';
import * as CONSTS from 'previdencia/features/DadosParticipante/constants/constants';

const MensagensAlerta = () => {
  return (
    <Grid.Item xs={1}>
      <S.ErrorMessage variant="caption-02" color="error">
        {CONSTS.MENSGENS_ALERTA.OBSERVACAO}
      </S.ErrorMessage>

      <S.AlertMessage variant="caption-02" color="text">
        {CONSTS.MENSGENS_ALERTA.VERIFIQUE_DADOS}
      </S.AlertMessage>
    </Grid.Item>
  );
};

export default MensagensAlerta;
