// @ts-nocheck
import { ResponseTiposSolicitacoes } from '../types/HistoricoSolicitacoes';

export const ordenarTipos = (a: any, b: any): any => {
  if (a.nomeParametro === null) {
    return 1;
  }
  if (b.nomeParametro === null) {
    return -1;
  }
  const conversaoA = a.nomeParametro.toLocaleLowerCase();
  const conversaoB = b.nomeParametro.toLocaleLowerCase();
  if (conversaoA === conversaoB) {
    return 0;
  }
  return conversaoA > conversaoB ? 1 : -1;
};

export const adicionaOpcaoTodos = (
  tiposSolicitacoes: ResponseTiposSolicitacoes[] | undefined,
): ResponseTiposSolicitacoes[] | undefined => {
  const todos: ResponseTiposSolicitacoes = {
    parametro: '',
    nomeParametro: 'Todos',
    valor: '',
  };
  return [todos, ...(tiposSolicitacoes?.sort(ordenarTipos) || [])];
};
