// @ts-nocheck
export const RENTABILIDADE_FUNDOS_INVESTIMENTOS =
  '* A rentabilidade dos fundos de investimentos é calculada somente nos dias úteis, devido ao funcionamento do mercado financeiro. Dessa forma, utilizamos o valor da cota do último dia útil para finais de semana e feriados . A cota utilizada para cálculo das movimentações será sempre a do primeiro dia útil subsequente à data da movimentação.';

export const IMPORTANTE = 'IMPORTANTE';

export const PRODUTOS_PGBL_VGBL =
  'Para produtos PGBL e VGBL, a cota utilizada para cálculo nas operações de saída, não será a do dia da solicitação do cliente.';

export const TRANSFERENCIA_MUDANCA_FUNDO =
  'No caso de transferência interna, com mudança de fundo, utilizamos o valor do dia útil subsequente para vendas das cotas do fundo/certificado atual, e a do dia útil posterior à venda, para compra de cotas no certificado/fundo novo.';

export const TRANSFERENCIA_QUE_NAO_ALTERA_FUNDO =
  'Já para as transferências que não alteram fundo de investimento, somente certificado, toda transação acorre com a cota do 1° dia útil subsequente à data da solicitação.';

export const RESGATE_COTA_UTILIZADA_CALCULO_VALOR =
  'Para o resgate, a cota utilizada no cálculo do valor é a do 2° dia útil do mês após a data de solicitação. Por esta razão, as solicitações de resgate total podem sofrer variações, positiva ou negativa do valor, entre a data de solicitação e a data do efetivo pagamento.';

export const ATENCAO_COTAS_ABERTURA_APURACAO_FUNDOS =
  'Atenção! Utilizamos as cotas de abertura para apuração dos fundos. O cálculo da rentabilidade é realizado somente nos dias úteis devido ao funcionamento do mercado financeiro.';

export const FUNDOS = '** Fundo ';

export const CONTRIBUICAO_PLANO_APLICACAO_FUNDOS =
  'Você sabia que toda contribuição que você faz no seu plano é aplicada em fundos de investimentos? Confira o quanto o seu dinheiro rende por dia.';

export const FUNDOS_INVESTIMENTOS_COTAS_PREVIDENCIA =
  'O que são fundos de investimentos e cotas na previdência?';

export const FUNDOS_INVESTIMENTOS = 'Fundos de investimentos';

export const PREVIDENCIA_PRIVADA_APLICACAO_DINHEIRO =
  'Parecido com outros fundos de investimentos, na previdência privada seu dinheiro é aplicado junto com o de outros clientes, com o objetivo de ganhar escala e reduzir custos de operação. Logo, você é cotista de um fundo e a Caixa Vida e Previdencia, a gestora de seus recursos.';

export const COTAS = 'Cotas';

export const CONVERSAO_CONVERTE_FUNDO_ESCOLHIDO =
  'Toda contribuição que você faz é convertida em costa do fundo escolhido. Este fundo sofre variação que influenciam no valor das suas cotas e determinam quanto o seu dinheiro vai render.';
