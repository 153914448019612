// @ts-nocheck
import styled from 'styled-components/macro';

export const Container = styled.div({
  padding: '10px 0 10px 0',
});

export const WrapperWarning = styled.div(({ theme: { color } }) => ({
  border: `1px solid ${color.brandSecondary.light}`,
  background: `${color.brandSecondary.light}0d`,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,
  marginBottom: 20,

  '& p:first-child': {
    color: color.brandSecondary.dark,
    fontWeight: 'bold',
  },
}));
