// @ts-nocheck
import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import * as AtualizarConsentimentoApi from 'previdencia/features/DadosParticipante/services/atualizarConsentimento.api';
import { IApiResponse } from 'main/services';

const useAtualizarConsentimento = (
  cpf: string | undefined,
  produtosCaixaVidaPrevidencia: boolean,
  consentimento: boolean | undefined,
  onCancelar: any,
): UseQueryResult<IApiResponse<undefined> | undefined> => {
  const { toastError, toastSuccess } = useToast();

  return useQuery(
    ['prev-atualizar-consentimento', cpf],
    () =>
      AtualizarConsentimentoApi.atualizarConsentimento(
        cpf,
        produtosCaixaVidaPrevidencia,
        consentimento,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      enabled: false,
      onError: (erro: Error) => toastError(erro.message),
      onSuccess: data => {
        if (data?.dados?.mensagens && !produtosCaixaVidaPrevidencia) {
          onCancelar();
          toastSuccess(String(data.dados.mensagens[0].descricao));
        }
      },
    },
  );
};

export default useAtualizarConsentimento;
