// @ts-nocheck
import styled from 'styled-components/macro';
import { Link as LinkBase } from 'react-router-dom';
import { Display as DisplayBase } from '@cvp/design-system/react';
import {
  DropdownItem as DropdownItemBase,
  DropdownMenu as DropdownMenuBase,
} from 'styled-dropdown-component';

export const Content = styled.nav(({ theme: { color } }) => ({
  color: color.neutral['08'],
  boxShadow: 'rgb(45 41 38 / 5%) 0px -2px 2px, rgb(45 41 38 / 16%) 0px 6px 8px',
}));

export const Display = styled(DisplayBase)(() => ({
  '> * ': {
    margin: 0,
  },
}));

export const Link = styled(LinkBase)(({ theme: { color } }) => ({
  color: color.brandPrimary.medium,
  transition: '0.1s',
  background: 'transparent',
  display: 'inline-block',
  border: 'none',
  width: 'auto',
  cursor: 'pointer',
  outline: 'none',
  padding: '12px 24px',
  fontFamily: 'CAIXA Std',
  textAlign: 'center',
  textDecoration: 'none',
  position: 'relative',
  pointerEvents: 'auto',
  minWidth: '148px',
  '-webkit-transition': '0.1s',
  '-webkit-text-decoration': 'none',

  svg: {
    fill: 'currentColor',
    width: '1.25em',
    height: '1.25em',
    top: '.125em',
    position: 'relative',
    marginRight: '4px',
  },
  '&:hover': {
    borderBottom: `1px solid ${color.brandPrimary.medium}`,
  },
}));

export const DropdownMenu = styled(DropdownMenuBase)(() => ({
  border: 'none',
  boxShadow: 'rgb(45 41 38 / 5%) 0px -2px 2px, rgb(45 41 38 / 16%) 0px 6px 8px',
}));

export const DropdownItem = styled(DropdownItemBase)(() => ({
  cursor: 'pointer',
}));
