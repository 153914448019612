// @ts-nocheck
/* eslint-disable react-hooks/rules-of-hooks */
import { api, IApiResponse } from 'main/services';
import { DadosInstituicaoBancaria } from 'main/features/ValidacaoContaCaixa/types/DadosInstituicaoBancaria';
import { obterEndpointRecuperarListaBancos } from 'main/features/ValidacaoContaCaixa/config/endpoints';
import { useToast } from 'main/hooks/useToast';
import { preencherValorPadrao } from 'reter/features/retencao/utils/preencherValorPadrao';

export const obterListaBancos = async (): Promise<
  DadosInstituicaoBancaria[] | undefined
> => {
  const { toastError } = useToast();

  const { data } = await api.post<IApiResponse<DadosInstituicaoBancaria[]>>(
    obterEndpointRecuperarListaBancos(),
    { cpfCnpj: '0' },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) {
    toastError('Erro ao carregar a listagem de bancos.');
    throw new Error(messageError);
  }

  return data?.dados?.entidade?.map(dado => ({
    ...dado,
    codigoBanco: preencherValorPadrao('00', dado.codigoBanco),
  }));
};
