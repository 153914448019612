// @ts-nocheck
import styled from 'styled-components/macro';
import { Display } from '@cvp/design-system/react';

export const ContainerButtons = styled(Display)`
  margin-top: 30px;
`;

export const ContainerSelect = styled(Display)(() => ({
  alignItems: 'center',
}));
