// @ts-nocheck
import React from 'react';
import { Text, Button, Divider } from '@cvp/design-system/react';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';

const SemDadosRetornados: React.FC = () => {
  const { goHome } = usePrevNavigation();

  return (
    <>
      <Text variant="body01-sm" margin>
        * Em caso de morte do titular, caso não haja beneficiário indicado, a
        Caixa Vida e Previdência seguirá a ordem de vocação hereditária{' '}
        <a
          href="https://www.jusbrasil.com.br/busca?q=Art.+792+do+C%C3%B3digo+Civil"
          rel="noopener noreferrer"
          target="_blank"
        >
          artigo 792 do Código Civil de 2002.
        </a>
      </Text>
      <Divider />
      <br />
      <Button variant="outlined" onClick={goHome}>
        Voltar
      </Button>
    </>
  );
};

export default SemDadosRetornados;
