// @ts-nocheck
import React from 'react';
import { Button } from '@cvp/design-system/react';
import * as S from './styles';

type ButtonsMudarEtapaProps = {
  disabled: boolean;
  buttonLabel?: string;
  loading?: boolean;
  proximaEtapa: () => void;
  etapaAnterior: () => void;
};

const ButtonsMudarEtapa: React.FC<ButtonsMudarEtapaProps> = ({
  disabled,
  buttonLabel,
  loading,
  proximaEtapa,
  etapaAnterior,
}) => {
  return (
    <S.Container>
      <Button
        variant="outlined"
        disabled={loading}
        onClick={() => etapaAnterior()}
      >
        Voltar
      </Button>
      <Button
        variant="primary"
        onClick={() => proximaEtapa()}
        disabled={disabled}
        loading={loading}
      >
        {buttonLabel}
      </Button>
    </S.Container>
  );
};

export default ButtonsMudarEtapa;
