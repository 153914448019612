// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Text, Checkbox, Divider } from '@cvp/design-system/react';
import masks from 'main/utils/masks';
import {
  ContainerLista,
  ContainerBranco,
  ContainerCheckbox,
} from 'main/styles/GlobalStyle';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import ButtonsMudarEtapa from 'previdencia/components/ButtonsMudarEtapa';
import useObterMesesCarenciaBeneficioProtecao from 'previdencia/hooks/useObterMesesCarenciaBeneficioProtecao';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  INFORME_ASSINATURA,
  INFORME_COBERTURA,
  TEXTOS_CARENCIA,
} from '../../constants';
import { PlanoSelecionado } from '../../types/BeneficioProtecaoTypes';
import { obterNomeBeneficio } from '../../utils/beneficioDeProtecao';
import { EnumCobertura, EnumPeriodicidade } from '../../types/enum';

type ConfirmarAquisicaoProps = {
  planoSelecionado: PlanoSelecionado | undefined;
  prazo: number | undefined;
  loading: boolean;
  concluirFluxo: () => void;
  etapaAnterior: () => void;
};
const ConfirmarAquisicao: React.FC<ConfirmarAquisicaoProps> = ({
  prazo,
  planoSelecionado,
  loading,
  concluirFluxo,

  etapaAnterior,
}) => {
  const [statusChecked, setStatusChecked] = useState(false);

  const {
    loading: loadingCarencia,
    response: responseCarencia,
    obterCarenciaBeneficioProtecao,
  } = useObterMesesCarenciaBeneficioProtecao();

  const handleConfirmarAquisicao = () => {
    concluirFluxo();
  };

  useEffect(() => {
    obterCarenciaBeneficioProtecao(
      planoSelecionado?.codigoBeneficio,
      planoSelecionado?.codigoPlano,
    );
  }, []);

  if (loadingCarencia) {
    return <SkeletonLoading lines={6} />;
  }

  return (
    <>
      <Text variant="body01-lg" color="text" margin>
        Confirmar a aquisição
      </Text>
      <Text variant="body01-lg" color="primary" margin>
        Importante
      </Text>
      <Text variant="body02-sm" color="text" margin>
        {INFORME_COBERTURA}
      </Text>
      <Text variant="body02-sm" color="text" margin>
        {INFORME_ASSINATURA}
      </Text>
      <ContainerLista>
        <ContainerBranco>
          <Text variant="body02-sm">Cobertura :</Text>
          <Text variant="body02-sm">
            {obterNomeBeneficio(planoSelecionado?.codigoBeneficio)}
          </Text>
        </ContainerBranco>

        <ContainerBranco>
          <Text variant="body02-sm">Prazo de recebimento :</Text>
          <Text variant="body02-sm">
            {prazo ? `${prazo} anos` : 'De uma única vez'}{' '}
          </Text>
        </ContainerBranco>

        <ContainerBranco>
          <Text variant="body02-sm">Vou contribuir com mais : </Text>
          <Text variant="body02-sm">
            <b>
              {formatarValorPadraoBrasileiro(
                planoSelecionado?.valorContribuicao,
              )}
            </b>{' '}
            {planoSelecionado?.periodicidade === EnumPeriodicidade.anual
              ? 'por ano'
              : 'por mês'}
          </Text>
        </ContainerBranco>
        <ContainerBranco>
          <Text variant="body02-sm">
            {planoSelecionado?.codigoBeneficio === EnumCobertura.pensao
              ? 'Valor da renda mensal'
              : 'Valor da indenização é de'}{' '}
            :
          </Text>
          <Text variant="body02-sm">
            {masks.currency.mask(planoSelecionado?.valorBeneficio)}
          </Text>
        </ContainerBranco>
      </ContainerLista>
      <Text variant="body02-sm">
        Total da contribuição <b>{planoSelecionado?.periodicidade}</b>, com a
        inclusão da cobertura
        <b> {obterNomeBeneficio(planoSelecionado?.codigoBeneficio)} </b>
        de{' '}
        <b>
          {formatarValorPadraoBrasileiro(planoSelecionado?.valorContribuicao)}
        </b>
      </Text>
      <Divider />
      <ContainerCheckbox>
        <Checkbox
          checked={statusChecked}
          onClick={() => {
            setStatusChecked(!statusChecked);
          }}
        />
        <Text variant="body02-sm">
          {TEXTOS_CARENCIA.INICIO}
          {tryGetValueOrDefault([responseCarencia?.toString()], '18')} meses
          {TEXTOS_CARENCIA.FIM}
        </Text>
      </ContainerCheckbox>
      <ButtonsMudarEtapa
        etapaAnterior={etapaAnterior}
        proximaEtapa={handleConfirmarAquisicao}
        loading={loading}
        disabled={!statusChecked}
        buttonLabel="Avançar"
      />
    </>
  );
};
export default ConfirmarAquisicao;
