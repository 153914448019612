// @ts-nocheck
import { useHistory } from 'react-router';
import {
  Card,
  Text,
  Grid,
  TextField,
  Checkbox,
  Button,
  Display,
} from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { TooltipWrapper } from 'main/components/Wrappers';
import * as UTILS from 'seguros/utils/DadosSeguradoUtils';
import * as CONSTS from 'seguros/constants/DadosSegurado';
import * as SEGURADO_TYPES from 'seguros/types/DadosSegurado';
import * as S from 'seguros/components/AtualizacaoDadosSegurado/styles';
import { AppContext } from 'main/contexts/AppContext';
import { useContext } from 'react';
import { TOOLTIP_NOME_SOCIAL } from 'main/constants/nomeSocialConstants';

const DadosSeguradoForm = ({
  formik,
  handleCepChange,
  masks,
  loadingDadosSeguradoAtualizados,
  loadingDadosConsentimentoAtualizadosProdutosCaixa,
  loadingDadosConsentimentoAtualizadosParceiros,
  cpfCnpj,
  nomeSegurado,
  dataNascimentoPorTipoCertificado,
}: SEGURADO_TYPES.DadosSeguradoFormProps) => {
  const history = useHistory();
  const { nomeSocial } = useContext(AppContext);

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[6, 8, 6, 8]}>
          <S.TitlePage
            variant="headline-05"
            color="primary"
            margin
            data-testid="tituloAtualizacaoDadosSegurado"
          >
            {UTILS.dynamicInfoInsured({
              cpfCnpj,
              seguradoPF: CONSTS.INFO_DINAMICA_SEGURADO.TITULO_PF,
              seguradoPJ: CONSTS.INFO_DINAMICA_SEGURADO.TITULO_PJ,
            })}
          </S.TitlePage>
          <Text variant="body02-md" margin>
            {CONSTS.ATUALIZACAO_DADOS_SEGURADO.DESCRICAO}
          </Text>

          <RenderConditional condition={!!dataNascimentoPorTipoCertificado}>
            <Text variant="body02-md">
              Nome: {tryGetValueOrDefault([nomeSocial], nomeSegurado)}
            </Text>

            <Text variant="body02-md" margin>
              Data de Nascimento: {dataNascimentoPorTipoCertificado}
            </Text>
          </RenderConditional>
          <S.GridContainerForm>
            <Grid.Item xs={1} md={1}>
              <Display alignItems="center">
                <Checkbox
                  onChange={() =>
                    formik.setFieldValue(
                      'utilizarNomeSocial',
                      !formik.values.utilizarNomeSocial,
                    )
                  }
                  aria-label="utilizar nome social"
                  name="utilizarNomeSocial"
                  checked={formik.values.utilizarNomeSocial}
                />
                <div>
                  <span>Utilizar Nome Social</span>
                  <TooltipWrapper
                    tooltip={TOOLTIP_NOME_SOCIAL}
                    left={100}
                    top={-25}
                  />
                </div>
              </Display>
            </Grid.Item>
          </S.GridContainerForm>
          <S.GridContainerForm>
            <Grid.Item xs={1} md={1 / 2}>
              <TextField
                disabled={!formik.values.utilizarNomeSocial}
                value={formik.values.nomeSocial}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                {...UTILS.showErrorInput(formik, 'nomeSocial')}
                label="Nome Social"
                data-testid="nomeSocialTest"
                name="nomeSocial"
              />
            </Grid.Item>
          </S.GridContainerForm>
          <S.GridContainerForm>
            <Grid.Item xs={1} lg={2 / 10}>
              <TextField
                maxLength={9}
                label="CEP"
                data-testid="inputDadosSeguradoCep"
                name="cep"
                value={masks.cep.mask(formik.values.cep)}
                onBlur={formik.handleBlur}
                onChange={handleCepChange}
                {...UTILS.showErrorInput(formik, 'cep')}
              />
            </Grid.Item>
          </S.GridContainerForm>
          <S.GridContainerForm marginTop={20}>
            <Grid.Item xs={1}>
              <TextField
                maxLength={72}
                label="Endereço"
                data-testid="inputDadosSeguradoEndereco"
                name="endereco"
                value={formik.values.endereco}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...UTILS.showErrorInput(formik, 'endereco')}
              />
            </Grid.Item>
          </S.GridContainerForm>
          <S.GridContainerForm>
            <Grid.Item xs={1} md={8 / 10} lg={5 / 10}>
              <TextField
                label="Bairro"
                data-testid="inputDadosSeguradoBairro"
                name="bairro"
                value={formik.values.bairro}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...UTILS.showErrorInput(formik, 'bairro')}
              />
            </Grid.Item>
            <Grid.Item xs={1} md={2 / 10} lg={1 / 10}>
              <TextField
                label="UF"
                data-testid="inputDadosSeguradoUF"
                name="uf"
                value={formik.values.uf}
                disabled
              />
            </Grid.Item>
            <Grid.Item xs={1} lg={4 / 10}>
              <TextField
                label="Cidade"
                data-testid="inputDadosSeguradoCidade"
                name="cidade"
                value={formik.values.cidade}
                disabled
              />
            </Grid.Item>
          </S.GridContainerForm>

          <S.GridContainerForm>
            <Grid.Item xs={1} lg={1 / 10} xl={1 / 10}>
              <TooltipWrapper
                left={50}
                top={0}
                tooltip="Ambos os números usam o mesmo DDD"
              />

              <TextField
                maxLength={2}
                label="DDD"
                data-testid="inputDadosSeguradoDDD"
                name="ddd"
                value={tryGetValueOrDefault([formik.values.ddd], '')}
                onBlur={formik.handleBlur}
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue('ddd', value);
                }}
                {...UTILS.showErrorInput(formik, 'ddd')}
              />
            </Grid.Item>
            <Grid.Item xs={1} lg={5 / 10} xl={2 / 10}>
              <TextField
                maxLength={13}
                label="Telefone"
                data-testid="inputDadosSeguradoTelefone"
                name="telefone"
                value={masks.homePhoneNoPrefix.mask(
                  UTILS.checkIfOptionIsDefaultEmpty(formik.values.telefone),
                )}
                onBlur={formik.handleBlur}
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(
                    'telefone',
                    masks.homePhoneNoPrefix.unmask(value),
                  );
                }}
                {...UTILS.showErrorInput(formik, 'telefone')}
              />
            </Grid.Item>
            <Grid.Item xs={1} lg={4 / 10} xl={2 / 10}>
              <TextField
                maxLength={14}
                label="Celular"
                data-testid="inputDadosSeguradoCelular"
                name="celular"
                value={masks.movelPhoneNoPrefix.mask(
                  UTILS.checkIfOptionIsDefaultEmpty(formik.values.celular),
                )}
                onBlur={formik.handleBlur}
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(
                    'celular',
                    masks.movelPhoneNoPrefix.unmask(value),
                  );
                }}
                {...UTILS.showErrorInput(formik, 'celular')}
              />
            </Grid.Item>
            <Grid.Item xs={1} xl={5 / 10}>
              <TextField
                label="E-mail"
                data-testid="inputDadosSeguradoEmail"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...UTILS.showErrorInput(formik, 'email')}
              />
            </Grid.Item>
          </S.GridContainerForm>
          <S.GridContainerForm marginTop={20}>
            <Grid.Item xs={1}>
              <S.ContainerCheckOption
                variant="body02-sm"
                data-testid="checkboxTextoDadosOfertaProdutos"
              >
                <Checkbox
                  checked={formik.values.dadosPessoaisOutrosProdutos}
                  onChange={({
                    target,
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      'dadosPessoaisOutrosProdutos',
                      target.checked,
                    );
                  }}
                  name="dadosPessoaisOutrosProdutos"
                />
                {CONSTS.ATUALIZACAO_DADOS_SEGURADO.FORM.LGPD_CVP}
              </S.ContainerCheckOption>
            </Grid.Item>
            <Grid.Item xs={1}>
              <S.ContainerCheckOption
                variant="body02-sm"
                data-testid="checkboxTextoDadosCompartilhadosParceiros"
              >
                <Checkbox
                  checked={formik.values.dadosPessoaisParceiros}
                  onChange={({
                    target,
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      'dadosPessoaisParceiros',
                      target.checked,
                    );
                  }}
                  name="dadosPessoaisParceiros"
                />
                {CONSTS.ATUALIZACAO_DADOS_SEGURADO.FORM.LGPD_PARCEIROS}
              </S.ContainerCheckOption>
            </Grid.Item>
            <Grid.Item xs={1}>
              <Text
                variant="caption-02"
                color="error"
                data-testid="obsCheckLGPD"
              >
                {CONSTS.ATUALIZACAO_DADOS_SEGURADO.FORM.LGPD_OBS}
              </Text>
            </Grid.Item>
            <Grid.Item xs={1}>
              <Text
                variant="caption-02"
                color="text"
                data-testid="infoDadosCorretos"
              >
                {CONSTS.ATUALIZACAO_DADOS_SEGURADO.FORM.INFO_CONFIRMACAO}
              </Text>
            </Grid.Item>
          </S.GridContainerForm>
          <S.GridContainerForm marginTop={20}>
            <Grid.Item xs={1}>
              <Display>
                <Button
                  data-testid="btnVoltarDadosSegurado"
                  variant="outlined"
                  onClick={history.goBack}
                >
                  Voltar
                </Button>
                <Button
                  data-testid="btnAtualizarDadosSegurado"
                  onClick={formik.submitForm}
                  loading={checkIfAllItemsAreTrue([
                    loadingDadosSeguradoAtualizados,
                    loadingDadosConsentimentoAtualizadosProdutosCaixa,
                    loadingDadosConsentimentoAtualizadosParceiros,
                  ])}
                  disabled={!formik.isValid}
                >
                  Confirmar
                </Button>
              </Display>
            </Grid.Item>
          </S.GridContainerForm>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default DadosSeguradoForm;
