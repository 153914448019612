// @ts-nocheck
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import { formatarData } from 'main/utils';
import {
  FilterTypes,
  IFilterOption,
} from 'main/components/Table/TableFilter/IFilterOption';
import masks from 'main/utils/masks';
import { TextWithEllipsis } from 'main/styles/GlobalStyle';

export const FiltrosTabelaStatusProposta: IFilterOption[] = [
  {
    key: 'cpfCnpj',
    value: 'CPF/CNPJ',
    type: 'text',
    unmask: masks.cpf.unmask,
  },
  { key: 'sequencialProposta', value: 'Proposta', type: 'text' },
  {
    key: 'agencia',
    value: 'Agência',
    type: 'text',
  },
  {
    key: 'numeroContrato',
    value: 'Contrato',
    type: 'text',
  },
  {
    key: 'statusPropostaDescricao',
    value: 'Status Proposta',
    type: FilterTypes.TAG,
    checkboxConfig: {
      options: [
        { id: 0, description: 'EMITIDA' },
        { id: 1, description: 'EM ANALISE' },
      ],
      helpText: 'Você também tem a opção de filtrar por status da proposta',
    },
  },
  {
    key: 'statusUpload',
    value: 'Status Upload',
    type: FilterTypes.TAG,
    checkboxConfig: {
      options: [
        { id: 2, description: 'REALIZACAO DE UPLOAD NAO NECESSARIA' },
        { id: 3, description: 'UPLOAD NAO REALIZADO' },
      ],
      helpText:
        'ou pelo status do upload, escolha a opção e clique em filtrar.',
    },
  },
];

export const ColunasTabelaStatusProposta = [
  {
    name: 'CPF/CNPJ',
    selector: 'cpfCnpj',
    sortable: true,
    width: '200px',
    cell: ({ cpfCnpj }: any) => <span>{maskCpfCnpj(cpfCnpj)}</span>,
  },
  {
    name: 'Contrato',
    selector: 'numeroContrato',
    sortable: true,
  },
  {
    name: 'Proposta',
    selector: 'sequencialProposta',
    sortable: true,
    width: '200px',
  },
  {
    name: 'Data da proposta',
    sortable: true,
    selector: 'dataProposta',
    cell: ({ dataProposta }: any) => <span>{formatarData(dataProposta)}</span>,
    width: '150px',
  },
  {
    name: 'Agência',
    selector: 'agencia',
    sortable: true,
    width: '100px',
  },
  {
    name: 'Valor Prêmio',
    selector: 'valorPremio',
    sortable: true,
    cell: ({ valorPremio }: any) => (
      <span>{masks.currency.mask(valorPremio)}</span>
    ),
  },
  {
    name: 'Status proposta',
    selector: 'statusPropostaDescricao',
    sortable: true,
    cell: ({ statusPropostaDescricao }: any) => (
      <TextWithEllipsis title={statusPropostaDescricao}>
        {statusPropostaDescricao}
      </TextWithEllipsis>
    ),
  },
  {
    name: 'Status upload',
    selector: 'statusUpload',
    sortable: true,
    cell: ({ statusUpload }: any) => (
      <TextWithEllipsis title={statusUpload}>{statusUpload}</TextWithEllipsis>
    ),
  },
];
