// @ts-nocheck
export const fundo = [
  {
    codigoFundo: '2001',
    descricaoFundo: '2001 - CAIXA FIC PREV 250 RF CRED PRIV',
    percentualRentabilidadeUltimoAno: 8.81,
    descricaoPerfilFundo: 'Conservador',
    valorSaldo: 10000.0,
    contribuicaoAtual: 0,
    selecionado: false,
    valor: 0,
    valorMinimo: 50,
    reservas: [
      {
        descricaoReserva: 'Regular',
        saldo: 5000,
      },
      {
        descricaoReserva: 'Adicional',
        saldo: 3000,
      },
      {
        descricaoReserva: 'Migrada',
        saldo: 2000,
      },
    ],
  },
  {
    codigoFundo: '2002',
    descricaoFundo: '2002 - CAIXA FIC PREV 240 RF CRED PRIV',
    percentualRentabilidadeUltimoAno: 8.81,
    descricaoPerfilFundo: 'Conservador',
    valorSaldo: 3500999900.0,
    contribuicaoAtual: 0,
    selecionado: false,
    valor: 0,
    valorMinimo: 50,
    reservas: [
      {
        descricaoReserva: 'Regular',
        saldo: 10000,
      },
      {
        descricaoReserva: 'Adicional',
        saldo: 25000,
      },
    ],
  },
  {
    codigoFundo: '2003',
    descricaoFundo: '2003 - CAIXA FIC PREV 230 RF CRED PRIV',
    percentualRentabilidadeUltimoAno: 11.91,
    descricaoPerfilFundo: 'Conservador',
    valorSaldo: 20000.0,
    contribuicaoAtual: 0,
    selecionado: false,
    valor: 0,
    valorMinimo: 50,
    reservas: [
      {
        descricaoReserva: 'Regular',
        saldo: 50000,
      },
      {
        descricaoReserva: 'Migrada',
        saldo: 15000,
      },
    ],
  },
];

export const fundoDestino = [
  {
    codigoFundo: '20010',
    descricaoFundo: '20010 - CAIXA FIC DEST PREV 250 RF CRED PRIV',
    percentualRentabilidadeUltimoAno: 8.82,
    valorSaldo: 11000.0,
    descricaoPerfilFundo: 'Conservador',
    contribuicaoAtual: 0,
    selecionado: false,
    valorMinimo: 51,
    valor: 0,
    reservas: [
      {
        saldo: 5200,
        descricaoReserva: 'Regular',
      },
      {
        saldo: 3300,
        descricaoReserva: 'Adicional',
      },
      {
        saldo: 2200,
        descricaoReserva: 'Migrada',
      },
    ],
  },
  {
    codigoFundo: '20020',
    descricaoFundo: '20020 - CAIXA FIC DEST PREV 240 RF CRED PRIV',
    percentualRentabilidadeUltimoAno: 8.81,
    descricaoPerfilFundo: 'Conservador',
    valorSaldo: 35000.0,
    contribuicaoAtual: 0,
    selecionado: false,
    valor: 0,
    valorMinimo: 50,
    reservas: [
      {
        descricaoReserva: 'Regular',
        saldo: 10000,
      },
      {
        descricaoReserva: 'Adicional',
        saldo: 25000,
      },
    ],
  },
  {
    descricaoFundo: '20030 - CAIXA FIC DEST PREV 230 RF CRED PRIV',
    codigoFundo: '20030',
    percentualRentabilidadeUltimoAno: 8.81,
    descricaoPerfilFundo: 'Conservador',
    valorSaldo: 20000.0,
    contribuicaoAtual: 0,
    selecionado: false,
    valorMinimo: 50,
    valor: 0,
    reservas: [
      {
        descricaoReserva: 'Regular',
        saldo: 50000,
      },
      {
        saldo: 15000,
        descricaoReserva: 'Migrada',
      },
    ],
  },
];

export const ordemPadraoDeReservas = ['REGULAR', 'ADICIONAL', 'MIGRADA'];

export const statusAssinaturaEletronica = 'Aguardando Assinatura Eletronica';
