// @ts-nocheck
import React, { useMemo } from 'react';
import { Card, Display, Text, Button } from '@cvp/design-system/react';
import * as S from '../components/styles';
import Icon from 'main/components/Icon';
import Table from 'main/components/Table';
import { colunasTabelaContribuicaoRegular } from '../factories/colunasTabelaContribuicaoRegular';
import { useTabelaContribuicaoRegular } from '../hooks/useTabelaContribuicaoRegular';
import { useHistory } from 'react-router';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { DEFAULT_NO_DATA_TABLE } from 'main/constants/messages';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';

const ContribuicaoRegular: React.FC = () => {
  const history = useHistory();
  const services = useTabelaContribuicaoRegular();

  const { fundos, featureData } = services;

  const colunas = useMemo(
    () =>
      colunasTabelaContribuicaoRegular({
        handlers: services,
      }),
    [services],
  );

  const disableButton = () =>
    fundos.filter(fundo => fundo.indicadorContribuicaoFundo).length === 0;

  return (
    <Display type="block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content>
          <Text variant="headline-05" color="primary" margin>
            Contribuição Regular
          </Text>
          <S.SectionTitle>
            Valor de contribuição atual é de:{' '}
            {tryGetMonetaryValueOrDefault([featureData?.valorContribuicao], 0)}.
            Indique o fundo que irá receber o valor dessa contribuição mensal.
          </S.SectionTitle>

          <Text variant="caption" color="error">
            <strong>
              <Icon name="warning" /> Essa ação não é uma simulação e após
              confirmada não poderá ser cancelada.
            </strong>
          </Text>
          <Card>
            <S.CardContent>
              <Table
                columns={colunas}
                data={tryGetValueOrDefault([fundos], [])}
                noHeader
                noDataComponent={DEFAULT_NO_DATA_TABLE}
              />

              <Display alignItems="flex-end" justify="flex-end">
                <Button variant="outlined" onClick={() => history.goBack()}>
                  Voltar
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    history.push(
                      '/cliente/produtos/previdencia/transferencia-alteracao-taxa/beneficios-protecao',
                    )
                  }
                  disabled={disableButton()}
                >
                  Avançar
                </Button>
              </Display>
            </S.CardContent>
          </Card>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default ContribuicaoRegular;
