// @ts-nocheck
export const PECOS_ASC = {
  RegistroOcorrenciaHistoricoSolicitacao:
    'PECO_RegistroOcorrenciaHistoricoSolicitacao',
  RegistroOcorrenciaDetalharSolicitacao:
    'PECO_RegistroOcorrenciaDetalharSolicitacao',
  RegistroOcorrenciaBuscarAnexos: 'PECO_RegistroOcorrenciaBuscarAnexos',
  RegistroOcorrenciaComentarioCliente:
    'PECO_RegistroOcorrenciaComentarioCliente',
  BuscarEnderecoCep: 'PECO_BuscarEnderecoCep',
  RegistroOcorrenciaClienteConsultar: 'PECO_RegistroOcorrenciaClienteConsultar',
  RegistroOcorrenciaClienteCriar: 'PECO_RegistroOcorrenciaClienteCriar',
  RegistroOcorrenciaClienteAtualizar: 'PECO_RegistroOcorrenciaClienteAtualizar',
  RegistroOcorrenciaDuplicidadeSolicitacaoConsultar:
    'PECO_RegistroOcorrenciaDuplicidadeSolicitacaoConsultar',
  RegistroOcorrenciaAssuntoConsultar: 'PECO_RegistroOcorrenciaAssuntoConsultar',
  RegistroOcorrenciaSubAssuntoConsultar:
    'PECO_RegistroOcorrenciaSubAssuntoConsultar',
  RegistroOcorrenciaCamposDinamicosConsultar:
    'PECO_RegistroOcorrenciaCamposDinamicosConsultar',
  RegistroOcorrenciaCamposDinamicosCriar:
    'PECO_RegistroOcorrenciaCamposDinamicosCriar',
  RegistroOcorrenciaCriarProtocolo: 'PECO_RegistroOcorrenciaCriarProtocolo',
  ConsultarDadosBICliente: 'PECO_ConsultarDadosBICliente',
  EnviarAnexoRegistroOcorrencia: 'PECO_EnviarAnexoRegistroOcorrencia',
};
