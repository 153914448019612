// @ts-nocheck
import React from 'react';
import { useHistory } from 'react-router';
import { FallbackProps } from 'react-error-boundary';
import serviceErrorSVG from 'assets/img/service_error.svg';
import logo from 'assets/img/caixa_vida_previdencia_2d_vertical.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import * as S from './styles';

const ErrorBoundary: React.FunctionComponent<FallbackProps> = ({
  resetErrorBoundary,
}: FallbackProps) => {
  const history = useHistory();
  const goBackAndReset = () => {
    resetErrorBoundary();
    history.push('/');
  };
  return (
    <S.Container>
      <S.Main>
        <S.Logo src={logo} alt="" />

        <S.Wrapper>
          <S.TextWrapper>
            <S.Title>
              Ops, algo deu errado{' '}
              <span role="img" aria-label="sad-emoji">
                🙁
              </span>
            </S.Title>

            <S.Text>Tente novamente mais tarde.</S.Text>

            <S.GoBackButton type="button" onClick={() => goBackAndReset()}>
              <ArrowLeft /> &nbsp; Ir para a home
            </S.GoBackButton>
          </S.TextWrapper>

          <S.ServiceErrorSVG src={serviceErrorSVG} alt="" />
        </S.Wrapper>
      </S.Main>
    </S.Container>
  );
};

export default ErrorBoundary;
