// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IPayloadConfirmarAssinatura,
  IResponseConfirmarAssinatura,
} from 'previdencia/types/ITransferirEntreFundos';

export const useConfirmarTransferencia = (numeroRequisicao: string) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IPayloadConfirmarAssinatura,
    IResponseConfirmarAssinatura
  >({
    api: {
      operationPath: PECOS.ConfirmarTransferencia,
    },
    payload: {
      cpf: cpfCnpj,
      idTransferencia: numeroRequisicao,
      numeroCertificado: numCertificado,
    },
    autoFetch: true,
  });

  return {
    loading,
    response,
    fetchData,
  };
};
