// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import FabButton, { Action } from 'portabilidade/components/FabButton';
import {
  DadosCliente,
  DadosDaNegociacao,
  DadosDaPortabilidade,
  DadosDoCertificado,
} from 'portabilidade/features/retencao/components/DetalheTarefaCards';
import {
  useDetalhePortabilidade,
  useValidarRegrasPortabilidade,
} from 'portabilidade/features/retencao/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Content, LoadingBottom } from '../components';
import ModalConfirmarAcaoRetencao from '../components/ModalConfirmacao/ModalConfirmarAcaoRetencao';
import ModalConfirmarTipoEnvio from '../components/ModalConfirmacao/ModalConfirmarTipoEnvio';
import ModalConfirmarTokenAssinatura from '../components/ModalConfirmacao/ModalConfirmarTokenAssinatura';
import { ACAO_RETENCAO } from '../constants';
import { useDefinirAcaoRetencao } from '../hooks/useDefinirAcaoRetencao';
import {
  extractEmailFromResponse,
  extractPhoneNumberFromReponse,
} from '../utils/dadosClienteUtils';
import RenderConditional from 'main/components/RenderConditional';

type AcaoRetencao = {
  acao: string;
  mensagem: string;
};

const acoesDeRetencaoSMS = [
  ACAO_RETENCAO.SOLICITAR_PRORROGACAO_VIA_SMS.ID_ACAO,
  ACAO_RETENCAO.SOLICITAR_CANCELAMENTO_VIA_SMS.ID_ACAO,
];

const DetalhePortabilidade: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { defirnirAcaoRetencao, loading: loadingRetencao } =
    useDefinirAcaoRetencao();
  const { loading, response, fetchData, invalidateCache } =
    useDetalhePortabilidade(id);
  const dadosPortabilidade = response?.entidade;
  const {
    deveExibirBotaoAtender,
    deveExibirLiberarAtendimento,
    deveExibirAcoesRetencao,
    deveExibirNaoRetido,
  } = useValidarRegrasPortabilidade(dadosPortabilidade);
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [
    exibirModalEnvioAssinaturaEmailSms,
    setExibirModalEnvioAssinaturaEmailSms,
  ] = useState<boolean>(false);
  const [exibirModalAssinaturaDocuSign, setExibirModalAssinaturaDocuSign] =
    useState<boolean>(false);
  const [urlCallBackAssinatura, setUrlCallBackAssinatura] =
    useState<string>('');
  const [acaoRetencao, setAcaoRetencao] = useState<AcaoRetencao>(
    {} as AcaoRetencao,
  );
  const [acaoConfirmada, setAcaoConfirmada] = useState<boolean>(false);
  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] =
    useState<boolean>(false);
  const { subscribe, unsubscribe } = useMessageEvent();

  const atualizarPortabilidade = useMutation(
    async (acao: string) => {
      return defirnirAcaoRetencao(id, acao);
    },
    {
      onSuccess: data => {
        if (data) {
          queryClient.invalidateQueries('portabilidades');
          const retornoServico = data.entidade;
          if (
            retornoServico &&
            acoesDeRetencaoSMS.includes(acaoRetencao.acao)
          ) {
            const urlAssinatura = retornoServico?.urlCallBackAssinatura;
            setUrlCallBackAssinatura(urlAssinatura);
            setExibirModalAssinaturaDocuSign(!!urlAssinatura);
          }
        }
      },
    },
  );

  const definirAcao = useCallback(
    (acao: string, mensagem: string) => {
      switch (acao) {
        case ACAO_RETENCAO.SOLICITAR_CANCELAMENTO.ID_ACAO:
        case ACAO_RETENCAO.SOLICITAR_PRORROGACAO.ID_ACAO:
        case ACAO_RETENCAO.SOLICITAR_PRORROGACAO_VIA_EMAIL.ID_ACAO:
        case ACAO_RETENCAO.SOLICITAR_PRORROGACAO_VIA_SMS.ID_ACAO:
        case ACAO_RETENCAO.SOLICITAR_CANCELAMENTO_VIA_EMAIL.ID_ACAO:
        case ACAO_RETENCAO.SOLICITAR_CANCELAMENTO_VIA_SMS.ID_ACAO:
          setExibirModalEnvioAssinaturaEmailSms(true);
          break;
        default:
          setExibirModal(true);
      }
      setAcaoRetencao(prevState => ({
        ...prevState,
        acao,
        mensagem,
      }));
    },
    [acaoRetencao],
  );

  const handleDefinirAcaoRetencao = async () => {
    await atualizarPortabilidade.mutateAsync(acaoRetencao.acao);
    setExibirModal(false);
    setExibirModalEnvioAssinaturaEmailSms(false);
    setAcaoRetencao({} as AcaoRetencao);
    setAcaoConfirmada(false);
    await Promise.all([
      invalidateCache(),
      invalidateCache('PECO_ListarPortabilidade'),
    ]);
    fetchData();
  };

  useEffect(() => {
    if (acaoConfirmada) {
      handleDefinirAcaoRetencao();
    }
  }, [acaoConfirmada]);

  useEffect(() => {
    subscribe(event => {
      const eventData = event as MessageEvent<unknown>;
      if (eventData.data === 'retornoAcaoConcluirAssinatura')
        setExibirBotaoFecharModal(true);
    });
    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(false));
    };
  }, []);

  return (
    <Content>
      <DadosCliente
        isLoading={loading}
        dadosPortabilidade={dadosPortabilidade}
      />
      <DadosDoCertificado
        isLoading={loading}
        dadosPortabilidade={dadosPortabilidade}
      />
      <DadosDaPortabilidade
        isLoading={loading}
        dadosPortabilidade={dadosPortabilidade}
      />
      <DadosDaNegociacao
        isLoading={loading}
        dadosPortabilidade={dadosPortabilidade}
      />
      <RenderConditional condition={loadingRetencao}>
        <LoadingBottom>
          <Button variant="text" loading />
        </LoadingBottom>
      </RenderConditional>
      <RenderConditional condition={!loadingRetencao}>
        <>
          <FabButton iconName="floatMenu">
            {deveExibirBotaoAtender() && (
              <Action
                text="Atender"
                onClick={() =>
                  definirAcao(
                    ACAO_RETENCAO.ATRIBUIR_USUARIO.ID_ACAO,
                    ACAO_RETENCAO.ATRIBUIR_USUARIO.MENSAGEM_CONFIRMACAO,
                  )
                }
              >
                <Icon name="edit" />
              </Action>
            )}
            {deveExibirLiberarAtendimento() && (
              <Action
                text="Liberar"
                onClick={() =>
                  definirAcao(
                    ACAO_RETENCAO.LIBERAR_ATENDIMENTO.ID_ACAO,
                    ACAO_RETENCAO.LIBERAR_ATENDIMENTO.MENSAGEM_CONFIRMACAO,
                  )
                }
              >
                <Icon name="externalLink" />
              </Action>
            )}

            <Action
              text="Prorrogar"
              disabled={!deveExibirAcoesRetencao()}
              onClick={() =>
                definirAcao(
                  ACAO_RETENCAO.SOLICITAR_PRORROGACAO.ID_ACAO,
                  ACAO_RETENCAO.SOLICITAR_PRORROGACAO.MENSAGEM_CONFIRMACAO,
                )
              }
            >
              <Icon name="calendar" />
            </Action>

            <Action
              text="Reter"
              disabled={!deveExibirAcoesRetencao()}
              onClick={() =>
                definirAcao(
                  ACAO_RETENCAO.SOLICITAR_CANCELAMENTO.ID_ACAO,
                  ACAO_RETENCAO.SOLICITAR_CANCELAMENTO.MENSAGEM_CONFIRMACAO,
                )
              }
            >
              <Icon name="successCheck" />
            </Action>

            <Action
              text="Não retido"
              disabled={!deveExibirNaoRetido()}
              onClick={() =>
                definirAcao(
                  ACAO_RETENCAO.RETENCAO_NAO_ACEITA_PELO_CLIENTE.ID_ACAO,
                  ACAO_RETENCAO.RETENCAO_NAO_ACEITA_PELO_CLIENTE
                    .MENSAGEM_CONFIRMACAO,
                )
              }
            >
              <Icon name="close" />
            </Action>
          </FabButton>
          <ModalConfirmarAcaoRetencao
            open={exibirModal}
            action={setAcaoConfirmada}
            message={acaoRetencao.mensagem}
            handleClose={() => setExibirModal(false)}
          />
          <ModalConfirmarTipoEnvio
            dadosPortabilidade={dadosPortabilidade}
            open={exibirModalEnvioAssinaturaEmailSms}
            definirAcao={definirAcao}
            executarAcaoRetencao={setAcaoConfirmada}
            handleClose={() => setExibirModalEnvioAssinaturaEmailSms(false)}
            email={extractEmailFromResponse(dadosPortabilidade)}
            mobileNumber={extractPhoneNumberFromReponse(dadosPortabilidade)}
            tipoRetencao={acaoRetencao.acao}
            mensagemConfirmacaoRetencao={acaoRetencao.mensagem}
            tituloModal="Para finalizar, selecione a forma que o cliente deseja assinar a proposta."
          />
          <ModalConfirmarTokenAssinatura
            open={exibirModalAssinaturaDocuSign}
            handleClose={() => setExibirModalAssinaturaDocuSign(false)}
            urlTokenPage={urlCallBackAssinatura}
            exibirBotaoFecharModal={exibirBotaoFecharModal}
          />
        </>
      </RenderConditional>
    </Content>
  );
};

export default DetalhePortabilidade;
