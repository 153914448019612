// @ts-nocheck
import { Button, Grid } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import useSimularResgate from 'previdencia/features/SolicitacaoResgate/hooks/useSimularResgate';
import useHandleConfirmarSimulacao from 'previdencia/features/SolicitacaoResgate/hooks/useHandleConfirmarSimulacao';
import FiltroSimulacaoResgate from 'previdencia/features/SolicitacaoResgate/components/FiltroSimulacaoResgate';
import CardResumoDistribuicaoFundosResgate from 'previdencia/features/SolicitacaoResgate/components/CardResumoDistribuicaoFundosResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as S from 'previdencia/features/SolicitacaoResgate/pages/styles';

const SimularResgate = () => {
  const {
    formik,
    valorMinimoResgate,
    valorMaximoResgate,
    valorMinimoPermanencia,
    colunas,
    continuarFluxo,
    listaTiposResgateFiltro,
    isInputValorSolicitadoDesabilitado,
    isTipoResgateParcial,
    isDisabledConfirmarSimulacao,
    isExibindoDetalhesSimulacao,
    alterarValorSolicitadoTotal,
    resetarInputFocus,
    reiniciarSimulacaoResgate,
    confirmarFiltroDesejadoParaSimulacao,
  } = useSimularResgate();

  const { confirmarSimulacao, loadingConfirmacaoSimulacao } =
    useHandleConfirmarSimulacao(formik);

  return (
    <>
      <FiltroSimulacaoResgate
        formik={formik}
        valorMinimoResgate={valorMinimoResgate}
        valorMaximoResgate={valorMaximoResgate}
        valorMinimoPermanencia={valorMinimoPermanencia}
        listaTiposResgateFiltro={listaTiposResgateFiltro}
        isInputValorSolicitadoDesabilitado={isInputValorSolicitadoDesabilitado}
        isTipoResgateParcial={isTipoResgateParcial}
        isDisabledConfirmarSimulacao={isDisabledConfirmarSimulacao}
        alterarValorSolicitadoTotal={alterarValorSolicitadoTotal}
        confirmarFiltroDesejadoParaSimulacao={
          confirmarFiltroDesejadoParaSimulacao
        }
        resetarInputFocus={resetarInputFocus}
      />

      <RenderConditional condition={isExibindoDetalhesSimulacao}>
        <Grid.Item xs={1}>
          <S.Table
            noHeader
            responsive
            highlightOnHover
            striped
            data={formik.values.listaFundosParaResgate}
            columns={colunas}
            noDataComponent={CONSTS.NO_DATA_TABLE}
          />
        </Grid.Item>

        <CardResumoDistribuicaoFundosResgate formik={formik} />

        <Grid.Item xs={1}>
          <S.ContainerNav>
            <Button variant="outlined" onClick={reiniciarSimulacaoResgate}>
              Novo valor de resgate
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                resetarInputFocus();
                confirmarSimulacao();
              }}
              disabled={checkIfSomeItemsAreTrue([
                continuarFluxo,
                loadingConfirmacaoSimulacao,
              ])}
              loading={loadingConfirmacaoSimulacao}
            >
              Simular
            </Button>
          </S.ContainerNav>
        </Grid.Item>
      </RenderConditional>
    </>
  );
};

export default SimularResgate;
