// @ts-nocheck
import { useQuery } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { obterProdutosPrevidencia } from '../service/consultarProdutosApi';

const useObterCertificadosPrevidencia = (codCliente?: string) => {
  return useQuery(
    ['produtos-previdencia', codCliente],
    () => obterProdutosPrevidencia(codCliente),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
    },
  );
};

export default useObterCertificadosPrevidencia;
