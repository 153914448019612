// @ts-nocheck
import { FormikProps } from 'formik';

import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { Fundo } from 'previdencia/types/Fundo.type';
import {
  IResponseTransferenciaEntreFundos,
  ResponseFundosDestino,
} from 'previdencia/types/ITransferirEntreFundos';
import { IPendencias } from 'previdencia/types/IRevalidarTransferencia';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

/**
 * Filtra e retorna a lista de fundos selecionados a partir da lista fornecida.
 *
 * @param {Fundo[]} listaFundosSelecionados - Array de objetos do tipo Fundo.
 * @returns {Fundo[]} - Retorna um array contendo apenas os fundos selecionados.
 */
export const obterFundosOrigemSelecionados = (
  listaFundosSelecionados: Fundo[],
) => {
  return tryGetValueOrDefault(
    [
      listaFundosSelecionados.filter(item =>
        checkIfAllItemsAreTrue([
          item.selecionado,
          !!item.tipoTransferenciaFundo,
          !!Number(item?.valorTransferencia),
        ]),
      ),
    ],
    [],
  );
};

/**
 * Calcula o valor restante e o valor total a ser retirado com base nos fundos selecionados para transferência e nos valores de retirada.
 *
 * @param {FormikProps<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IFormikValuesDistribuicaoEntreFundos>} formik
 * @param {TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData | null | undefined} featureData
 * @returns {{ calculoRestante: number, calculoTotal: number, valorRetirada: number }}
 */
export const calcularValores = (
  formik: FormikProps<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IFormikValuesDistribuicaoEntreFundos>,
  featureData:
    | TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData
    | null
    | undefined,
) => {
  let valorRetirada = Number(formik.values.fundosDestino.valorRetirada);

  if (featureData?.tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.TOTAL) {
    valorRetirada = Number(
      featureData?.certificadoOrigem?.valorTotalBeneficios,
    );
  }

  const valorRetirarSomado =
    formik.values.fundosDestino?.fundosDestinoConsolidado
      ?.filter(fundo =>
        checkIfAllItemsAreTrue([
          !!fundo.selecionado,
          !!fundo.valorTransferencia,
        ]),
      )
      .reduce((acumulador, current) => {
        return acumulador + Number(current.valorTransferencia);
      }, 0);

  const calculoTotal = tryGetValueOrDefault(
    [Number(valorRetirarSomado?.toFixed(2))],
    0,
  );

  const calculoRestante =
    valorRetirada - tryGetValueOrDefault([calculoTotal], 0);

  return {
    calculoRestante,
    calculoTotal,
    valorRetirada,
  };
};

/**
 * Verifica se a próxima etapa de transferência não está disponível com base em critérios de tipo de transferência,
 * existência de benefício e seleção de fundos com valores válidos.
 *
 * @param featureData - Dados do contexto de transferência entre certificados, contendo informações como tipo de transferência e benefício.
 * @param fundosOrigem - Lista de fundos que contêm as informações de seleção e valores de transferência.
 * @returns Retorna `true` se a próxima etapa não está disponível, `false` caso contrário.
 */
export const isNotAvailableNextStepTipoTransferencia = (
  featureData:
    | TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData
    | null
    | undefined,
  fundosOrigem: Fundo[],
  possuiBeneficio: boolean,
): boolean => {
  const isTipoTransferenciaSelecionado = !!featureData?.tipoTransferencia;

  const isPropriedadeManterBeneficioEmFeatureData: boolean = Object.hasOwn(
    featureData as Record<string, unknown>,
    CONSTS_TEXT.PROPRIEDADES_OBJETO.MANTER_BENEFICIO,
  );

  const isManterBeneficioPreenchido: boolean = checkIfAllItemsAreTrue([
    isPropriedadeManterBeneficioEmFeatureData,
    typeof featureData?.manterBeneficio === CONSTS_TEXT.VALIDAR_TYPES.BOOLEAN,
  ]);

  const isSomeFundoSelecionadoComValorTransferenciaValido: boolean =
    fundosOrigem.some(fundo => {
      return checkIfAllItemsAreTrue([
        fundo.selecionado,
        !!Number(fundo.valorTransferencia),
      ]);
    });

  if (!possuiBeneficio) {
    return !checkIfAllItemsAreTrue([
      isTipoTransferenciaSelecionado,
      isSomeFundoSelecionadoComValorTransferenciaValido,
    ]);
  }

  return !checkIfAllItemsAreTrue([
    isTipoTransferenciaSelecionado,
    isSomeFundoSelecionadoComValorTransferenciaValido,
    isManterBeneficioPreenchido,
  ]);
};

/**
 * Obtém o resumo dos fundos selecionados, calculando a quantidade de fundos selecionados,
 * a quantidade total de fundos possíveis e se os fundos estão desabilitados.
 *
 * @param {Partial<IResponseTransferenciaEntreFundos>} fundosDestino - Objeto parcial que contém os fundos de destino e suas propriedades.
 * @returns {TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IObterResumoFundosSelecionadosReturn} - Objeto contendo a lista de fundos selecionados,
 * a quantidade de fundos possíveis e uma flag indicando se os fundos estão desabilitados.
 */
export const obterResumoFundosSelecionados = (
  fundosDestino: Partial<IResponseTransferenciaEntreFundos>,
): TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IObterResumoFundosSelecionadosReturn => {
  const listaFundosSelecionados: number = tryGetValueOrDefault(
    [
      fundosDestino?.fundosDestinoConsolidado?.filter(
        fundoDestino => !!fundoDestino.selecionado,
      ).length,
    ],
    0,
  );

  const qtdFundosPossiveis: number =
    Number(fundosDestino.qndDispFundoExistente) +
    Number(fundosDestino.qndDispNovoFundo);

  const isDisabledFundos: boolean =
    listaFundosSelecionados === qtdFundosPossiveis;

  return {
    listaFundosSelecionados,
    qtdFundosPossiveis,
    isDisabledFundos,
  };
};

export const obterFundosDestinoSelecionados = (
  fundosDestino: ResponseFundosDestino[] | undefined,
) => {
  const fundosSelecionados = tryGetValueOrDefault(
    [fundosDestino?.filter(fundoDestino => !!fundoDestino.selecionado)],
    [],
  );
  return fundosSelecionados.map(item => ({
    codFundo: item.codFundo,
    codObjetivo: item.codObjetivo,
    codReserva: item.codReserva,
    vlrSaldo: Number(item.valorTransferencia),
  }));
};

export const obterAlertasRevalidar = (pendencias: IPendencias[]) => {
  return pendencias.filter(item => item.codigoErro === 'ALERTA');
};

/**
 * Extracts the first word from a given phrase.
 * If the input is not a string or is undefined, returns an empty string.
 *
 * @param {string | undefined} phrase - The input phrase from which to extract the first word. Can be undefined.
 * @returns {string} The first word of the phrase, or an empty string if the input is not valid or empty.
 */
export const getFirstWord = (phrase?: string): string => {
  if (typeof phrase !== 'string') {
    return '';
  }

  const words = phrase.trim().split(/\s+/);

  return words.length > 0 ? words[0] : '';
};

export const isCoberturaValida = (coberturas: Cobertura[] | undefined) => {
  return coberturas?.some(cobertura =>
    checkIfAllItemsAreTrue([
      cobertura.risco === CONSTS.COBERTURA_VALIDA.SIM,
      cobertura.comercializado === CONSTS.COBERTURA_VALIDA.SIM,
    ]),
  );
};
