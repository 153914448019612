// @ts-nocheck
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AppLayout from 'main/components/Layout';
import PrivateRoute, {
  IPrivateRoute,
} from 'main/components/Route/PrivateRoute';
import ListagemPortabilidades from 'portabilidade/features/retencao/pages/ListagemPortabilidades';
import DetalhePortabilidade from 'portabilidade/features/retencao/pages/DetalhePortabilidade';
import FeedbackAssinaturaDocUsign from 'portabilidade/features/retencao/pages/FeedbackAssinaturaDocUsign';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import DetalhesPortabilidadeEntrada from 'portabilidade/features/entrada/pages/DetalhesPortabilidadeEntrada';
import EdicaoContatos from '../features/retencao/pages/EdicaoContatos';
import ListagemPortabilidadeEntrada from '../features/entrada/pages/ListagemPortabilidadeEntrada';

const routes: IPrivateRoute[] = [
  {
    path: '/portabilidades',
    exact: true,
    component: ListagemPortabilidades,
    key: 'lista-tarefas',
    authenticated: true,
    breadcrumb: 'Portabilidades',
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
  },
  {
    path: '/portabilidades/:id',
    exact: true,
    component: DetalhePortabilidade,
    key: 'detalhe-tarefas',
    authenticated: true,
    breadcrumb: 'Detalhes',
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
  },
  {
    path: '/portabilidades/:id/contatos',
    exact: true,
    component: EdicaoContatos,
    key: 'contatos-tarefa',
    authenticated: true,
    breadcrumb: 'Contatos',
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
  },
  {
    path: '/portabilidades-entrada',
    exact: true,
    component: ListagemPortabilidadeEntrada,
    key: 'portabilidades-entrada',
    authenticated: true,
    breadcrumb: 'Portabilidades de Entrada',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
  {
    path: '/portabilidades-entrada/:id/detalhes',
    component: DetalhesPortabilidadeEntrada,
    key: 'portabilidades-entrada-detalhes',
    authenticated: true,
    breadcrumb: 'Detalhes',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
];

export const portabilidadeBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/feedback-assinatura-docusign': null,
  '/portabilidades-entrada/:id': null,
};

const RotasPortabilidade = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
    <Route
      exact
      path="/feedback-assinatura-docusign"
      render={() => (
        <AppLayout>
          <FeedbackAssinaturaDocUsign />
        </AppLayout>
      )}
    />
  </Switch>
);

export default RotasPortabilidade;
