// @ts-nocheck
import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';
import * as ValidarAssinaturaApi from '../services/validarAssinaturaTransferencia.api';

const useValidarAssinaturaTransferencia = () => {
  const { toastError } = useToast();
  const [fetchingValidarAssinatura, setFetchingValidarAssinatura] =
    useState<boolean>(false);
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);
  const [permissaoAssinatura, setFundosDestino] = useState<
    boolean | undefined
  >();
  const validarAssinatura = async () => {
    try {
      setFetchingValidarAssinatura(true);
      const result = await ValidarAssinaturaApi.validarAssinaturaTransferencia(
        DefaultValue.CODIGO_EMPRESA,
        numCertificado,
      );
      setFundosDestino(result);
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setFetchingValidarAssinatura(false);
    }
  };
  return {
    fetchingValidarAssinatura,
    permissaoAssinatura,
    validarAssinatura,
  };
};
export default useValidarAssinaturaTransferencia;
