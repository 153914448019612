// @ts-nocheck
import {
  TEXTO_CODIGO_CIVIL,
  TEXTO_PRPOCURADOR,
} from 'previdencia/constants/texts';
import { Text } from '@cvp/design-system/react';
import React from 'react';

const TextoJuridicoAssinatura: React.FC = () => {
  return (
    <>
      <Text variant="body02-sm" color="primary" align="left">
        {TEXTO_CODIGO_CIVIL}
      </Text>
      <Text variant="body02-sm" color="primary" align="left">
        {TEXTO_PRPOCURADOR}
      </Text>
    </>
  );
};

export default TextoJuridicoAssinatura;
