// @ts-nocheck
import React, { useEffect, useState } from 'react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { Text, Button, Divider } from '@cvp/design-system/react';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import TextoInformativo from '../TextoInformativo';
import useObterCoberturasContratadas from '../../hooks/useObterCoberturasContratadas';
import CardStatusContribuicao from '../CardStatusContribuicao';
import { CoberturaContratada } from '../../types/CoberturasContratadas.types';
import * as S from './styles';
import { converterResponseCoberturasContratadasParaCoberturaContratada } from '../../factories/suspensaoContribuicoesFactory';
import { EnumTipoBeneficios } from '../../types/EnumsStatusAtivacaoSuspensao.types';

type DadosContribuicoesProps = {
  mudarEtapa: (dados: CoberturaContratada | undefined) => void;
};

const DadosContribuicoes: React.FC<DadosContribuicoesProps> = ({
  mudarEtapa,
}) => {
  const { response, loading } = useObterCoberturasContratadas();
  const data = response?.entidade;

  const [coberturasReserva, setCoberturasReserva] = useState<
    CoberturaContratada[] | undefined
  >();
  const [coberturasCuidadoExtra, setCoberturasCuidadoExtra] = useState<
    CoberturaContratada[] | undefined
  >();
  const { goDadosPlano } = usePrevNavigation();

  useEffect(() => {
    setCoberturasReserva(
      converterResponseCoberturasContratadasParaCoberturaContratada(
        data,
        EnumTipoBeneficios.PREV,
      ),
    );
    setCoberturasCuidadoExtra(
      converterResponseCoberturasContratadasParaCoberturaContratada(
        data,
        EnumTipoBeneficios.RISCO,
      ),
    );
  }, [data, loading]);

  if (loading) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <>
      <RenderConditional condition={!!data}>
        <TextoInformativo />
        <br />
      </RenderConditional>

      <RenderConditional condition={!data}>
        <Text variant="body02-sm" color="text" margin>
          Não existem contribuições regulares para este certificado.
        </Text>
        <br />
      </RenderConditional>

      <RenderConditional
        condition={tryGetValueOrDefault([coberturasReserva], []).length > 0}
      >
        <Text variant="body01-lg" color="primary">
          Reserva
        </Text>

        <S.Container>
          {coberturasReserva?.map(e => {
            return (
              <CardStatusContribuicao
                mudarEtapa={mudarEtapa}
                dados={e}
                tipo="reserva"
              />
            );
          })}
        </S.Container>
        <Divider />
      </RenderConditional>
      <RenderConditional
        condition={
          tryGetValueOrDefault([coberturasCuidadoExtra], []).length > 0
        }
      >
        <Text variant="body01-lg" color="primary">
          Cuidado Extra
        </Text>
        <S.Container>
          {coberturasCuidadoExtra?.map(e => {
            return (
              <CardStatusContribuicao
                mudarEtapa={mudarEtapa}
                dados={e}
                tipo="cuidadoExtra"
              />
            );
          })}
        </S.Container>
      </RenderConditional>

      <Button variant="outlined" onClick={goDadosPlano}>
        Voltar
      </Button>
    </>
  );
};

export default DadosContribuicoes;
