// @ts-nocheck
import { api, IApiResponse } from 'main/services';
import { obterEndpointMesesCarenciaBeneficioProtecao } from 'previdencia/config/endpoints';

interface IObterMesesCarenciaBeneficioProtecaoProps {
  numeroCertificado: string;
  codigoPlano?: number;
  codigoBeneficio?: number;
}

export const obterMesesCarenciaBeneficioProtecao = async ({
  numeroCertificado,
  codigoBeneficio,
  codigoPlano,
}: IObterMesesCarenciaBeneficioProtecaoProps): Promise<number | undefined> => {
  const { data } = await api.post<IApiResponse<{ parametroCarencia: number }>>(
    obterEndpointMesesCarenciaBeneficioProtecao(),
    {
      codigoEmpresa: '500',
      codigoCertificado: numeroCertificado,
      codigoPlano,
      codigoBeneficio,
    },
  );

  const mensagemErro = data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade?.parametroCarencia;
};
