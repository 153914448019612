// @ts-nocheck
import styled from 'styled-components/macro';
import {
  Modal as ModalDS,
  ModalSlider as ModalSliderDS,
} from '@cvp/design-system/react';

export const Modal = styled(ModalDS)(({ ...rest }) => ({
  ...rest,
}));

Modal.Slider = styled(ModalSliderDS)(({ ...rest }) => ({
  ...rest,
}));

export default Modal;
