// @ts-nocheck
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { capitalize } from 'main/utils/string';
import { ICertificadoPrevidenciaDetalharResponse } from '../interfaces/ICertificadoPrevidenciaDetalharResponse';
import { IDadosBasicosCertificadoPrevidencia } from '../../../../main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';

export function preencherClassificacaoDoPlano(
  listaCertificados: IDadosBasicosCertificadoPrevidencia[] | undefined,
  detalhesCertificados: ICertificadoPrevidenciaDetalharResponse[] | undefined,
): IDadosBasicosCertificadoPrevidencia[] {
  return tryGetValueOrDefault([listaCertificados], []).map(item => {
    const detalheCorrespondente = tryGetValueOrDefault(
      [detalhesCertificados],
      [],
    ).find(detalhe => detalhe.codConta === item.certificadoNumero);

    return {
      ...item,
      fundo: detalheCorrespondente?.fundo,
      fundos: detalheCorrespondente?.fundos,
      produto: {
        ...item.produto,
        classificacaoDoPlano: capitalize(
          tryGetValueOrDefault(
            [detalheCorrespondente?.produto.classificacaoDoPlano],
            '',
          ),
        ),
      },
    } as IDadosBasicosCertificadoPrevidencia;
  });
}
