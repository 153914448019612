// @ts-nocheck
import { status } from 'consultaCliente/features/listaCardProduto/consts/constsListaCardProduto';
import { IDadosBasicosCertificadoPrevidencia } from 'main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { ICardProdutoVida } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoVida';
import { ICardProdutoPrestamista } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoPrestamista';

export function checkPrevAtivo(
  dadosCardsPrevidencia: IDadosBasicosCertificadoPrevidencia[] | undefined,
) {
  const possuiPrev =
    tryGetValueOrDefault([dadosCardsPrevidencia?.length], 0) > 0;

  const possuiPrevArivo = !!dadosCardsPrevidencia?.some(item =>
    checkIfSomeItemsAreTrue([
      item?.situacao === status.PREV_ATIVO,
      item?.situacao === status.PREV_ATIVO_COBRANCA_SUSPENSA,
    ]),
  );

  return checkIfAllItemsAreTrue([possuiPrev, possuiPrevArivo]);
}

export function checkVidaAtivo(dadosCardsVida: ICardProdutoVida[] | undefined) {
  const possuiVida = tryGetValueOrDefault([dadosCardsVida?.length], 0) > 0;

  const possuiVidaAtivo = !!dadosCardsVida?.some(
    item => item?.situacaoRegistro === status.VIDA_ATIVO,
  );

  return checkIfAllItemsAreTrue([possuiVida, possuiVidaAtivo]);
}

export function checkPrestAtivo(
  dadosCardsPrestamista: ICardProdutoPrestamista[] | undefined,
) {
  const possuiPrest =
    tryGetValueOrDefault([dadosCardsPrestamista?.length], 0) > 0;

  const possuiPrestAtivo = !!dadosCardsPrestamista?.some(
    item => item?.dadosContrato?.situacaoContrato === status.PREST_ATIVO,
  );

  return checkIfAllItemsAreTrue([possuiPrest, possuiPrestAtivo]);
}
