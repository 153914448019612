// @ts-nocheck
import { Text } from '@cvp/design-system/react';

import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import For from 'main/components/For';
import Print from 'previdencia/components/Print';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import {
  Table,
  ModalValoresDetalhadosAliquota as Modal,
  ContainerAviso,
} from 'previdencia/features/SolicitacaoResgate/pages/styles';
import * as S from './styles';

const ModalValoresDetalhadosAliquota = ({
  open,
  handleClose,
  detalhadoAliquota,
  dadosTabelaDetalheCalculo,
  objetoEmail,
}: SOLICITACAO_RESGATE_TYPES.IModalValoresDetalhadosAliquotaProps) => {
  return (
    <Modal show={open} onClose={handleClose}>
      <S.ContainerResumoCliente>
        <For each={detalhadoAliquota}>
          {item => (
            <S.ItemResumoCliente key={item.label}>
              <Text variant="body01-sm" color="text">
                <strong>{item.label}</strong>
              </Text>
              <Text variant="body01-sm" color="text">
                {getTernaryResult(
                  !!item.mask,
                  tryGetMonetaryValueOrDefault([item.value]),
                  item.value,
                )}
              </Text>
            </S.ItemResumoCliente>
          )}
        </For>
      </S.ContainerResumoCliente>

      <Text variant="body01-lg" color="text" align="left" margin>
        {CONSTS.MODAL_DETALHES_ALIQUOTA.LABEL_TABELA}
      </Text>

      <Table
        noHeader
        highlightOnHover
        striped
        data={tryGetValueOrDefault([dadosTabelaDetalheCalculo], [])}
        columns={CONSTS.COLUNAS_VALORES_DETALHADOS_ALIQUOTA}
        noDataComponent={CONSTS.NO_DATA_TABLE}
        pagination
        paginationPerPage={10}
        paginationComponentOptions={{
          rowsPerPageText: 'Items por página',
          rangeSeparatorText: 'de',
        }}
        conditionalRowStyles={CONSTS.conditionalRowStyles}
      />

      <ContainerAviso>
        <Text variant="headline-08" color="white" margin>
          {CONSTS.MODAL_DETALHES_ALIQUOTA.AVISO_TITULO}
        </Text>

        <Text variant="body03-md" color="white" margin>
          {CONSTS.MODAL_DETALHES_ALIQUOTA.AVISO_1}
        </Text>

        <Text variant="body03-md" color="white">
          {CONSTS.MODAL_DETALHES_ALIQUOTA.AVISO_2}
        </Text>
      </ContainerAviso>

      <Print objetoEmail={objetoEmail} />
    </Modal>
  );
};

export default ModalValoresDetalhadosAliquota;
