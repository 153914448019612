// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import * as S from '../styles';
import { EnumTipoBeneficios } from '../../types/EnumsStatusAtivacaoSuspensao.types';

type TextoAvisoProps = {
  suspensao?: boolean;
  cancelamento?: boolean;
  tipo?: string;
};

const TextoAviso: React.FC<TextoAvisoProps> = ({
  suspensao,
  cancelamento,
  tipo,
}) => {
  const cuidadoExtra = (
    <>
      <Text variant="body02-sm" color="text" margin>
        Para contratar um <strong>Cuidado Extra</strong> clique na opção Incluir
        Benefício de Proteção do Menu Serviços:
      </Text>
    </>
  );
  return (
    <>
      <S.WrapperWarning>
        <Text variant="body01-lg" color="primary" margin>
          Importante
        </Text>
        <RenderConditional condition={!!suspensao}>
          {cuidadoExtra}
        </RenderConditional>
        <RenderConditional condition={!suspensao}>
          <RenderConditional condition={tipo === EnumTipoBeneficios.RISCO}>
            <Text variant="body02-sm" color="text" margin>
              <RenderConditional condition={!!cancelamento}>
                O cliente concordou com as condições e cancelou o Cuidado Extra.
              </RenderConditional>
              <RenderConditional condition={!cancelamento}>
                Este valor é destinado a cuidar do cliente ou de quem ele ama.
                Já pensou em como um apoio financeiro pode ser muito importante
                no caso de seu falecimento?
              </RenderConditional>
            </Text>
            <RenderConditional condition={!!cancelamento}>
              {cuidadoExtra}
            </RenderConditional>
            <RenderConditional condition={!cancelamento}>
              <Text variant="body02-sm" color="text" margin>
                Ao cancelar o Cuidado Extra, o cliente e sua família deixam de
                estar bem cuidados.
              </Text>
            </RenderConditional>
          </RenderConditional>
          <RenderConditional condition={tipo !== EnumTipoBeneficios.RISCO}>
            <Text variant="body02-sm" color="text" margin>
              As contribuições de renda podem ser reativadas a qualquer momento.
              <br />E mesmo com a contribuição suspensa, o cliente poderá
              realizar contribuições adicionais.
            </Text>
          </RenderConditional>

          <Text variant="body02-sm" color="text" margin>
            <strong>Atenção: </strong> Caso o pagamento seja por débito em conta
            e o cliente possua uma parcela ainda em procedimento de cobrança, o
            valor será debitado da sua conta normalmente.
          </Text>
        </RenderConditional>
      </S.WrapperWarning>
    </>
  );
};

export default TextoAviso;
