// @ts-nocheck
import { Checkbox } from '@cvp/design-system/react';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as TableColumns from '../../types/TableColumns';
import React from 'react';

const ColunaSelecionarFundo: React.FC<
  TableColumns.IDadosTabelaDistribuicaoProps
> = ({ row, handlers, requisitosDiversificacao }) => {
  const atingiuLimiteMaximo = () =>
    !row?.selecionado &&
    tryGetValueOrDefault(
      [requisitosDiversificacao?.quantidadeFundosSelecionados],
      0,
    ) ===
      tryGetValueOrDefault(
        [requisitosDiversificacao?.quantidadeMaximaFundos],
        0,
      );

  const desabilitarBotao = () => {
    const faltaSaldo = checkIfAllItemsAreTrue([
      !row?.selecionado,
      !!requisitosDiversificacao?.desabilitaPorFaltaSaldo,
    ]);
    const percentualAtingido = checkIfAllItemsAreTrue([
      !row?.selecionado,
      handlers.percentualAtingido(),
    ]);
    return checkIfSomeItemsAreTrue([
      atingiuLimiteMaximo(),
      faltaSaldo,
      percentualAtingido,
    ]);
  };

  return (
    <Checkbox
      id={row?.codigoFundo}
      checked={row?.selecionado}
      onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (!atingiuLimiteMaximo() && row) handlers.handleSelecionarFundo(row);
      }}
      disabled={desabilitarBotao()}
    />
  );
};

export default ColunaSelecionarFundo;
