// @ts-nocheck
import {
  ICriacaoProtocoloResponse,
  IUseSolicitarAberturaProtocoloFop223,
} from 'extranet/types/IUseSolicitarAberturaProtocoloFop223';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';

export const useSolicitarAberturaProtocoloFop223 =
  (): IUseSolicitarAberturaProtocoloFop223 => {
    const {
      response: dadosEnvioAnexo,
      loading: loadingDadosEnvioAnexo,
      fetchData: solicitarCriacaoProtocoloFop223,
    } = usePeco<FormData, ICriacaoProtocoloResponse>({
      api: {
        operationPath: PECOS.SolicitarCriacaoProtocoloRegistroOcorrenciaFop223,
      },
      requestConfig: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });

    return {
      dadosEnvioAnexo,
      loadingDadosEnvioAnexo,
      solicitarCriacaoProtocoloFop223,
    };
  };
