// @ts-nocheck
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import {
  Modal as ModalBase,
  Button as ButtonBase,
} from '@cvp/design-system/react';

export const Linked = styled(Link)(
  ({ theme: { breakpoint, color, font } }) => ({
    '&&': {
      cursor: 'pointer',
      color: color.brandPrimary.dark,
      fontSize: font.size.md,
      [breakpoint.lg()]: {
        maxWidth: '180px',
      },
    },
  }),
);

export const LoadingBottom = styled.div({
  position: 'fixed',
  boxSizing: 'border-box',
  margin: '32px -25px',
  paddingLeft: '0',
  zIndex: 9998,
  right: 0,
  bottom: 40,
});

export const Modal = styled(ModalBase)(({ theme: { breakpoint } }) => ({
  '&&': {
    [breakpoint.lg()]: {
      minWidth: '800px',
      minHeight: '700px',
    },
  },
}));

export const ModalDocUsign = styled(Modal)<{ showCloseButton: boolean }>(
  ({ showCloseButton }) => ({
    '&&': {
      '> button': {
        display: showCloseButton ? 'inline-block' : 'none',
      },
    },
  }),
);

export const Content = styled.div({
  paddingBottom: '3.5rem',
});

export const StatusRetencao = styled.div<{ esperandoAcaoRetencao: boolean }>(
  ({ theme: { color }, esperandoAcaoRetencao }) => ({
    color: esperandoAcaoRetencao && color.feedback.error,
  }),
);

export const TextHeader = styled.div<{ statusLength: number }>(
  ({ theme: { color, font, line } }) => ({
    color: color.line.primary,
    fontSize: font.size.xl,
    fontWeight: font.weight.md,
    lineHeight: line.height.lg,
  }),
);

export const TextNotes = styled.div<{ statusLength: number }>(
  ({ theme: { color, font, line }, statusLength }) => ({
    color: color.line.primary,
    fontSize: font.size.md,
    fontWeight: font.weight.md,
    lineHeight: line.height.lg,
  }),
);

export const CustomTextField = styled.div(
  ({ theme: { breakpoint, color, font, line } }) => ({
    background: '#F0F0F1',
    marginBottom: '16px',
    fontSize: font.size.md,
    lineHeight: line.height.lg,
    fontWeight: font.weight.md,
    color: color.line.primary,
    [breakpoint.lg()]: {
      fontSize: font.size.md,
      lineHeight: line.height.lg,
    },
  }),
);

export const LoadignModalAssinatura = styled.div({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '100%',
  left: '0',
});

export const ButtonLink = styled(ButtonBase)({
  minWidth: '40px',
  padding: 0,
});

export const MensagemObrigatoriaDestaque = styled.span({
  fontWeight: 'bold',
});

export const MensagemObrigatoriaDestaqueConcluir = styled.span({
  fontStyle: 'italic',
});
