// @ts-nocheck
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useContext, PropsWithChildren } from 'react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';
import * as CONTEXT_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblContext';

const TransferenciaInternaContext =
  React.createContext<CONTEXT_TYPES.TransferenciaInterna>(
    {} as CONTEXT_TYPES.TransferenciaInterna,
  );

TransferenciaInternaContext.displayName =
  'TransferenciaInternaEmissaoConjugado';

const TransferenciaInternaProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [transferenciaInternaData, setTransferenciaInternaData] =
    useState<CONTEXT_TYPES.TransferenciaInternaData>(
      CONSTS.initialTransferenciaInternaDataState,
    );

  const iniciarTransferenciaInterna = (): void => {
    setTransferenciaInternaData(prevState => ({
      ...prevState,
      etapa: ENUM_TYPES.EtapaTransferenciaInterna.PLANOS,
    }));
  };

  const salvarDadosOrigem = (
    dadosOrigem: RESPONSE_TYPES.IDadosOrigemResponse | undefined,
  ): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        dadosOrigem,
      }),
    );
  };

  const salvarSaldoTotalFundosAtuais = (
    saldoTotalFundosAtuais: number,
  ): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        saldoTotalFundosAtuais: tryGetValueOrDefault(
          [saldoTotalFundosAtuais],
          0,
        ),
      }),
    );
  };

  const definirContribuicaoRendaMensal = (): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        etapa: ENUM_TYPES.EtapaTransferenciaInterna.CONTRIBUICAO,
      }),
    );
  };

  const salvarContribuicaoRendaMensal = (
    contribuicaoRendaMensal: string,
  ): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        contribuicaoRendaMensal,
      }),
    );
  };

  const escolherFundoInvestimentoDestino = (): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        etapa: ENUM_TYPES.EtapaTransferenciaInterna.FUNDO_INVESTIMENTO,
      }),
    );
  };

  const atualizarListaFundosInvestimento = (
    tabelaFundos:
      | RESPONSE_TYPES.IObtencaoListaOfertasVgblConjugadoProdutosFundos[]
      | undefined,
  ): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        dadosOfertasVgblConjugado: {
          ...prevState.dadosOfertasVgblConjugado,
          produtos: [
            {
              ...prevState.dadosOfertasVgblConjugado?.produtos?.[0],
              fundos: tryGetValueOrDefault([tabelaFundos], []),
            },
          ],
        } as RESPONSE_TYPES.IListaOfertasVgblConjugadoResponse,
      }),
    );
  };

  const salvarListaOfertasVgblConjugado = (
    dadosOfertasVgblConjugado:
      | RESPONSE_TYPES.IListaOfertasVgblConjugadoResponse
      | undefined,
  ): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        dadosOfertasVgblConjugado,
      }),
    );
  };

  const incluirCuidadoGarantido = (): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        etapa: ENUM_TYPES.EtapaTransferenciaInterna.CUIDADO_GARANTIDO,
      }),
    );
  };

  const salvarCuidadoGarantido = (
    coberturasCuidadoGarantido: VGBL_TYPES.CoberturasCuidadoGarantido,
  ): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        coberturasCuidadoGarantido,
      }),
    );
  };

  const selecionarFormaPagamento = (): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        etapa: ENUM_TYPES.EtapaTransferenciaInterna.FORMA_PAGAMENTO,
      }),
    );
  };

  const salvarContaCadastradaSelecionada = (
    contaCadastradaSelecionada: VGBL_TYPES.ContaCadastradaSelecionada,
  ): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        contaCadastradaSelecionada,
      }),
    );
  };

  const confirmarAquisicao = (): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        etapa: ENUM_TYPES.EtapaTransferenciaInterna.AQUISICAO,
      }),
    );
  };

  const salvarNumeroSolicitacao = (numeroSolicitacao: string): void => {
    setTransferenciaInternaData(
      (
        prevState: CONTEXT_TYPES.TransferenciaInternaData,
      ): CONTEXT_TYPES.TransferenciaInternaData => ({
        ...prevState,
        numeroSolicitacao,
      }),
    );
  };

  return (
    <TransferenciaInternaContext.Provider
      value={{
        ...transferenciaInternaData,
        iniciarTransferenciaInterna,
        salvarDadosOrigem,
        salvarSaldoTotalFundosAtuais,
        definirContribuicaoRendaMensal,
        salvarContribuicaoRendaMensal,
        escolherFundoInvestimentoDestino,
        atualizarListaFundosInvestimento,
        salvarListaOfertasVgblConjugado,
        incluirCuidadoGarantido,
        salvarCuidadoGarantido,
        selecionarFormaPagamento,
        salvarContaCadastradaSelecionada,
        confirmarAquisicao,
        salvarNumeroSolicitacao,
      }}
    >
      {children}
    </TransferenciaInternaContext.Provider>
  );
};

export const useTransferenciaInternaContext = () => {
  return useContext(TransferenciaInternaContext);
};

export default TransferenciaInternaProvider;
