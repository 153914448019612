// @ts-nocheck
import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { obterProdutosPrevidencia } from 'consultaCliente/features/listaCardProduto/service/consultarProdutosApi';
import { ICertificadoBeneficioPrevidencia } from 'main/types/CardPrevidencia/ICertificadoBeneficioPrevidencia';
import * as ValoresContribuicao from 'previdencia/features/AlterarValorContribuicao/services/valoresContribuicao.api';
import {
  ResponseValoresContribuicao,
  ReturnResponseMultiFundo,
} from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';

const useObterValoresContribuicao = () => {
  const { toastError } = useToast();
  const [contribuicaoValor, setContribuicaoValor] = useState('0');
  const [loading, setLoading] = useState<boolean>(false);
  const [responseMultiFundo, setResponseMultiFundo] =
    useState<ReturnResponseMultiFundo>();
  const [responseValoresContribuicao, setResponseValoresContribuicao] =
    useState<ResponseValoresContribuicao[]>([]);
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const buscarValoresContribuicao = async () => {
    try {
      setLoading(true);
      const [dados, produtosPrevidencia] = await Promise.all([
        ValoresContribuicao.valoresContribuicao(
          cpfCnpj,
          numCertificado,
          contribuicaoValor,
        ),
        obterProdutosPrevidencia(cpfCnpj),
      ]);

      const certificadosBeneficio =
        (produtosPrevidencia?.find(
          produtoPrev => produtoPrev.certificadoNumero === numCertificado,
        )?.certificadoBeneficios as ICertificadoBeneficioPrevidencia[]) ?? [];

      const array: ResponseValoresContribuicao[] = [];

      certificadosBeneficio.forEach(element => {
        array.push({
          benefitId: element.beneficioId,
          benefitType: element.beneficioTipo,
          contractDate: element.contratacao,
          incomeDescription: element.rendaDescricao,
          incomeType: Number(element.rendaTipo) ?? 0,
          initialContributionValue: Number(element.valorEsperado) ?? 0,
          planId: element.planoId,
          status: element.situacao,
        });
      });
      setResponseValoresContribuicao(array);

      setResponseMultiFundo(dados?.return);
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    responseMultiFundo,
    responseValoresContribuicao,
    contribuicaoValor,
    setContribuicaoValor,
    buscarValoresContribuicao,
  };
};

export default useObterValoresContribuicao;
