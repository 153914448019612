// @ts-nocheck
import React from 'react';
import { Display, Button } from '@cvp/design-system/react';
import EmailSenderModal from 'main/components/EmailSenderModal';
import usePrint from 'previdencia/hooks/usePrint';

type PrintProps = {
  objetoEmail: any;
};

const Print: React.FC<PrintProps> = ({ objetoEmail }) => {
  const { print } = usePrint();
  return (
    <Display type="display-block">
      <Display justify="center" className="hide-print">
        <EmailSenderModal objetoEmail={objetoEmail} />
        <Button
          data-testid="button-print"
          variant="secondary"
          onClick={() => print()}
        >
          Imprimir
        </Button>
      </Display>
    </Display>
  );
};

export default Print;
