// @ts-nocheck
export const tipoOperacaoContaCaixa = [
  { id: '001', valor: '001 - Conta Corrente de Pessoa Física' },
  { id: '003', valor: '003 - Conta Corrente de Pessoa Jurídica' },
  { id: '013', valor: '013 - Poupança de Pessoa Física' },
  { id: '022', valor: '022 - Poupança de Pessoa Jurídica' },
  { id: '023', valor: '023 - Conta Caixa Fácil' },
  { id: '1288', valor: '1288 - Poupança PF / Caixa Fácil' },
  { id: '1292', valor: '1292 - Canta Corrente PJ' },
  { id: '3701', valor: '3701 - Conta Corrente PF' },
  { id: '3702', valor: '3702 - Poupança PJ' },
];
