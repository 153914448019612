// @ts-nocheck
import React from 'react';
import { Accordion, Divider } from '@cvp/design-system/react';
import GridCardSkeleton from 'main/components/GridCardSkeleton';
import { IDadosBasicosCertificadoPrevidencia } from 'main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import {
  EnumStatusCard,
  EnumStatusCardPrevidencia,
} from 'consultaCliente/types/ICardsApresentacao';
import RenderConditional from 'main/components/RenderConditional';
import ListaCertificados from './ListaCertificados';
import TextoInformativoSemProdutosPrevidencia from './TextoInformativoSemProdutosPrevidencia';

type SectionGridCardPrevProps = {
  loadingPrev: boolean;
  produtosPrev?: IDadosBasicosCertificadoPrevidencia[];
  codCliente: string;
};

const SectionGridCardPrevidencia: React.FunctionComponent<
  SectionGridCardPrevProps
> = ({ loadingPrev, codCliente, produtosPrev = [] }) => {
  const getCertificados = (status: string) => {
    if (status === EnumStatusCard.ativos) {
      return produtosPrev?.filter(item =>
        checkIfSomeItemsAreTrue([
          item.situacao === EnumStatusCardPrevidencia.Ativo,
          item.situacao === EnumStatusCardPrevidencia.Transferencia,
          item.situacao === EnumStatusCardPrevidencia.Beneficio,
          item.situacao === EnumStatusCardPrevidencia.Suspenso,
        ]),
      );
    }
    return produtosPrev?.filter(item =>
      checkIfAllItemsAreTrue([
        item.situacao !== EnumStatusCardPrevidencia.Ativo,
        item.situacao !== EnumStatusCardPrevidencia.Transferencia,
        item.situacao !== EnumStatusCardPrevidencia.Beneficio,
        item.situacao !== EnumStatusCardPrevidencia.Suspenso,
      ]),
    );
  };

  return (
    <>
      <RenderConditional condition={loadingPrev}>
        <GridCardSkeleton quantidadeColunas={4} />
      </RenderConditional>
      <RenderConditional
        condition={!loadingPrev && (produtosPrev || []).length === 0}
      >
        <TextoInformativoSemProdutosPrevidencia />
      </RenderConditional>
      <RenderConditional
        condition={!loadingPrev && (produtosPrev || []).length > 0}
      >
        <>
          <ListaCertificados
            codCliente={codCliente}
            certificados={getCertificados(EnumStatusCard.ativos)}
            itensPorPagina={12}
          />
          <Divider />
          <RenderConditional
            condition={getCertificados(EnumStatusCard.inativos).length > 0}
          >
            <Accordion
              open={(getCertificados(EnumStatusCard.ativos) || []).length === 0}
            >
              <Accordion.Item title="Inativos" key="inativos">
                <ListaCertificados
                  codCliente={codCliente}
                  certificados={getCertificados(EnumStatusCard.inativos)}
                  itensPorPagina={12}
                />
              </Accordion.Item>
            </Accordion>
          </RenderConditional>
        </>
      </RenderConditional>
    </>
  );
};

SectionGridCardPrevidencia.defaultProps = {
  produtosPrev: [],
};
export default SectionGridCardPrevidencia;
