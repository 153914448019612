// @ts-nocheck
import React from 'react';
import { Modal } from '@cvp/design-system/react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import For from 'main/components/For';
import { IPendencias } from 'previdencia/types/IRevalidarTransferencia';
import { IModalPendenciasProps } from '../../types/TransferenciaEntreCertificados';

const ModalPendencias: React.FC<IModalPendenciasProps> = ({
  openModal,
  handleCloseModal,
  pendenciasRevalidar,
  erroDefinirReservaDestino,
}) => {
  return (
    <Modal show={openModal} onClose={handleCloseModal}>
      {erroDefinirReservaDestino}
      <For each={tryGetValueOrDefault([pendenciasRevalidar], [])}>
        {(item: IPendencias, index: number) => {
          return <p key={index}>{item.descricaoPendencia}</p>;
        }}
      </For>
    </Modal>
  );
};

export default ModalPendencias;
