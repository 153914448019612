// @ts-nocheck
import React from 'react';
import { Accordion, Divider } from '@cvp/design-system/react';
import {
  EnumStatusCard,
  EnumStatusCardVida,
} from 'consultaCliente/types/ICardsApresentacao';
import RenderConditional from 'main/components/RenderConditional';
import GridCardSkeleton from '../../../../../../main/components/GridCardSkeleton';
import ListaPrestamista from './ListaPrestamista';
import TextoInformativoSemProdutos from '../../TextoInformativoSemProdutos/TextoInformativoSemProdutos';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { ISectionGridPrestamistaProps } from 'consultaCliente/features/listaCardProduto/types/typesPrestamista';

const SectionGridPrestamista: React.FunctionComponent<
  ISectionGridPrestamistaProps
> = ({ loadingPrestamista, produtosPrestamista, cpfCnpj }) => {
  const getSeguros = (status: string) => {
    if (status === EnumStatusCard.ativos) {
      return produtosPrestamista?.filter(
        item =>
          item?.dadosContrato?.situacaoContrato?.toLowerCase() ===
          EnumStatusCardVida.ativo,
      );
    }
    return produtosPrestamista?.filter(
      item =>
        item?.dadosContrato?.situacaoContrato?.toLowerCase() !==
        EnumStatusCardVida.ativo,
    );
  };

  if (loadingPrestamista) {
    return <GridCardSkeleton quantidadeColunas={4} />;
  }
  return (
    <>
      <RenderConditional
        condition={
          !loadingPrestamista && (produtosPrestamista || []).length === 0
        }
      >
        <TextoInformativoSemProdutos />
      </RenderConditional>

      <RenderConditional
        condition={
          !loadingPrestamista &&
          tryGetValueOrDefault([produtosPrestamista], []).length > 0
        }
      >
        <>
          <ListaPrestamista
            cpfCnpj={cpfCnpj}
            dadosPrestamista={getSeguros(EnumStatusCard.ativos)}
            itensPorPagina={12}
          />
          <Divider />
          <RenderConditional
            condition={
              tryGetValueOrDefault([getSeguros(EnumStatusCard.inativos)], [])
                .length > 0
            }
          >
            <Accordion
              open={
                tryGetValueOrDefault([getSeguros(EnumStatusCard.ativos)], [])
                  .length === 0
              }
            >
              <Accordion.Item title="Inativos" key="inativos">
                <ListaPrestamista
                  cpfCnpj={cpfCnpj}
                  dadosPrestamista={getSeguros(EnumStatusCard.inativos)}
                  itensPorPagina={12}
                />
              </Accordion.Item>
            </Accordion>
          </RenderConditional>
        </>
      </RenderConditional>
    </>
  );
};

export default SectionGridPrestamista;
