// @ts-nocheck
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { obterCodigoOperacaoENumeroConta } from 'previdencia/utils/previdencia';
import * as FACTORY from 'previdencia/features/AlteracaoFormaDadosPagamento/factories/AlteracaoFormaDadosPagamentoFactory';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

const useComprovanteAlteracao = (
  params: Partial<ALTERACAO_CONTA_TYPES.IComprovanteAlteracaoProps>,
) => {
  const sexo = getTernaryResult(
    params.dadosParticipante?.sexoPessoa === CONSTS.GENERO.SIGLA_FEMINIMO,
    CONSTS.GENERO.FEMINIMO,
    CONSTS.GENERO.MASCULINO,
  );

  const formaPagamentoAnterior = getTernaryResult(
    params.dadosParticipante?.metodoPagamento ===
      CONSTS.TIPO_CONTA.DEBITO_CONTA_SIGLA,
    CONSTS.TIPO_CONTA.DEBITO_CONTA,
    params.dadosParticipante?.metodoPagamento,
  );

  const dadosContaBancaria = `Banco: ${
    params.dadosParticipante?.numeroBanco
  } - Agência: ${params.dadosParticipante?.numeroAgencia} - Conta: ${
    obterCodigoOperacaoENumeroConta(
      tryGetValueOrDefault([params.dadosParticipante?.numeroConta], ''),
    ).numeroConta
  }-${tryGetValueOrDefault([params.dadosParticipante?.digitoConta], '')}`;

  const dadosContaBancariaAntiga = (
    e: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias,
  ) =>
    `Banco: ${e.numeroBanco} - Agência: ${e.numeroAgencia} - Conta: ${
      obterCodigoOperacaoENumeroConta(e.numeroConta).numeroConta
    }-${e.digitoConta}`;

  let contaAntiga;

  if (params.canalId) {
    contaAntiga = FACTORY.buscarContaPorCanalId(
      params.dadosListaBancos,
      params.canalId,
    );
  }

  return {
    sexo,
    formaPagamentoAnterior,
    dadosContaBancaria,
    contaAntiga,
    dadosContaBancariaAntiga,
  };
};

export default useComprovanteAlteracao;
