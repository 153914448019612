// @ts-nocheck
import { useState, useEffect } from 'react';
import { IUser } from 'main/features/Auth/interfaces';
import { api, IApiResponse } from 'main/services';
import { useToast } from 'main/hooks/useToast';
import useURLQueryParam from 'main/hooks/useURLQueryParam';
import { setUserName as setUsername } from 'main/features/Auth/utils/auth';
import { obterEndpointAtualizarAcesso } from '../config/endpoints';

export const useAtualizarAcesso = () => {
  const { toastError, toastSuccess } = useToast();
  const { getParam } = useURLQueryParam();
  const [loading, setLoading] = useState<boolean>(false);
  const [chaveVerificacao] = useState(getParam('token') ?? '');
  const [nomeAcesso, setNomeAcesso] = useState<string>('');

  const atualizar = async (senha: string): Promise<boolean> => {
    try {
      setLoading(true);
      setUsername(nomeAcesso);
      const response = await api.post<IApiResponse<IUser>>(
        obterEndpointAtualizarAcesso(),
        {
          chaveVerificacao,
          senhaAcesso: senha,
          nomeAcesso,
        },
      );
      const [serviceResponseMessage] = response.data.dados.mensagens ?? [];

      if (response.data.dados.sucesso) {
        toastSuccess(serviceResponseMessage.descricao);
        return true;
      }
      toastError(serviceResponseMessage.descricao);
      return false;
    } catch (error: any) {
      toastError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chaveVerificacao) {
      setNomeAcesso(atob(chaveVerificacao).split(':')[0]);
    }
  }, [chaveVerificacao]);

  return {
    nomeAcesso,
    loading,
    atualizar,
  };
};
