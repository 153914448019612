// @ts-nocheck
import React from 'react';
import { Text, Grid } from '@cvp/design-system/react';
import CircleStatus from 'main/components/CircleStatus';
import { TCircleStatusProps } from 'main/components/CircleStatus/CircleStatus.types';
import { STATUS_DPS } from '../constants/texts';
import IStatusDpsCircleProps from '../interface/IStatusDpsCircleProps';
import { getTernaryResult } from 'main/utils/conditional';

const statusMap: {
  [key in keyof typeof STATUS_DPS]: TCircleStatusProps['variant'];
} = {
  PendenteDeEnvio: 'warning',
  PendenteDePreenchimento: 'warning',
  Aceito: 'success',
  Recusado: 'error',
  EmAnalise: 'warning',
  NaoNecessario: 'warning',
};

const getStatusVariant = (status: string): TCircleStatusProps['variant'] =>
  Object.entries(STATUS_DPS).reduce<TCircleStatusProps['variant']>(
    (acc, [key, value]) =>
      getTernaryResult(
        status === value,
        statusMap[key as keyof typeof STATUS_DPS],
        acc,
      ),
    'warning',
  );

const StatusDpsCircle: React.FC<IStatusDpsCircleProps> = ({
  statusEnvioDPS,
}) => {
  const variant = getStatusVariant(statusEnvioDPS);

  return (
    <Grid
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid.Item
        style={{ flexGrow: 1, textAlign: 'center', marginTop: '-10px' }}
      >
        <Text variant="body02-md">{statusEnvioDPS}</Text>
      </Grid.Item>
      <Grid.Item style={{ position: 'absolute', right: 0 }}>
        <CircleStatus variant={variant} />
      </Grid.Item>
    </Grid>
  );
};

export default StatusDpsCircle;
