// @ts-nocheck
import styled from 'styled-components/macro';
import { Accordion as AccordionDS } from '@cvp/design-system/react';

export const Accordion = styled(AccordionDS)(({ ...rest }) => ({
  ...rest,
}));

Accordion.Item = styled(AccordionDS.Item)(({ ...rest }) => ({
  ...rest,
}));

export default Accordion;
