// @ts-nocheck
import React, { useState, useContext, useMemo, PropsWithChildren } from 'react';
import { Fundo } from 'previdencia/types/Fundo.type';
import {
  getSessionItem,
  setSessionItem,
  removeSessionItem,
} from 'main/utils/storage';
import { PREV_REDIRECTS_SCREENS } from 'previdencia/config/redirects';

import {
  EtapaTransferencia,
  ResponseDefinirReservaDestino,
} from '../types/DefinirReservaDestino.types';
import { IResponseTransferenciaEntreFundos } from 'previdencia/types/ITransferirEntreFundos';

type TransferenciaFundosData = {
  etapa: EtapaTransferencia;
  contribuicaoRegularFeita?: boolean;
  fundosOrigem?: Fundo[];
  fundosDestino?: Fundo[];
  dadosContribuicaoRegular?: ResponseDefinirReservaDestino;
  responseRedistribuirFundos?: IResponseTransferenciaEntreFundos;
  mensagemErro?: string[];
  numTransferencia?: string;
  redirecionadoDe?: PREV_REDIRECTS_SCREENS;
  permissaoAssinatura?: boolean;
};

const ID_CURRENT_TRANSFUND_STORAGE_KEY = 'transfundId';

type TransferenciaActions = {
  dadosInvalidos: (mensagem: string[]) => void;
  escolherFundosOrigem: (fundosOrigem: Fundo[]) => void;
  escolherFundosDestino: (responseRedistribuirFundos: Fundo[]) => void;
  escolherFundosContribuicaoRegular: (
    dadosContribuicaoRegular: ResponseDefinirReservaDestino,
  ) => void;
  direcionarNovaTela: (
    telaDestino: EtapaTransferencia,
    from?: PREV_REDIRECTS_SCREENS,
  ) => void;
  fundosDestinoPossiveis: (
    responseRedistribuirFundos: IResponseTransferenciaEntreFundos | undefined,
  ) => void;
  definirNumeroTransferencia: (numeroTransferencia?: string) => void;
  redefinirVariaveisSessao: () => void;
  definirPermissaoAssinatura: (permissaoAssinaura: boolean | undefined) => void;
};

const Context = React.createContext<
  TransferenciaFundosData & TransferenciaActions
>({} as TransferenciaFundosData & TransferenciaActions);

const TransferenciaFundosProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [transferenciaFundosData, setTransferenciaFundosData] =
    useState<TransferenciaFundosData>(() => {
      const numTransferenciaSession = getSessionItem<string>(
        ID_CURRENT_TRANSFUND_STORAGE_KEY,
      );
      if (numTransferenciaSession) {
        return {
          etapa: 'definirFundosOrigem',
          numTransferencia: numTransferenciaSession,
        } as TransferenciaFundosData;
      }
      return { etapa: 'definirFundosOrigem' } as TransferenciaFundosData;
    });

  const escolherFundosOrigem = useMemo(
    () => (fundosOrigem: Fundo[]) =>
      setTransferenciaFundosData(prev => ({
        ...prev,
        etapa: 'definirFundosOrigem',
        fundosOrigem,
      })),
    [],
  );

  const definirPermissaoAssinatura = useMemo(
    () => (permissaoAssinatura: boolean | undefined) =>
      setTransferenciaFundosData(prev => ({
        ...prev,
        permissaoAssinatura,
      })),
    [],
  );

  const definirNumeroTransferencia = useMemo(
    () => (numTransferencia?: string) => {
      if (numTransferencia) {
        setTransferenciaFundosData(prev => ({
          ...prev,
          numTransferencia,
        }));
        setSessionItem(ID_CURRENT_TRANSFUND_STORAGE_KEY, numTransferencia);
      }
    },
    [],
  );

  const redefinirVariaveisSessao = useMemo(
    () => () => {
      removeSessionItem(ID_CURRENT_TRANSFUND_STORAGE_KEY);
    },
    [],
  );

  const fundosDestinoPossiveis = useMemo(
    () =>
      (
        responseRedistribuirFundos:
          | IResponseTransferenciaEntreFundos
          | undefined,
      ) =>
        setTransferenciaFundosData(prev => ({
          ...prev,
          etapa: 'definirFundosDestino',
          responseRedistribuirFundos,
        })),
    [],
  );

  const escolherFundosDestino = useMemo(
    () => (fundosDestino: Fundo[]) =>
      setTransferenciaFundosData(prev => ({
        ...prev,
        etapa: 'definirReservaDestino',
        fundosDestino,
      })),
    [],
  );

  const escolherFundosContribuicaoRegular = (
    dadosContribuicaoRegular: ResponseDefinirReservaDestino,
  ) =>
    setTransferenciaFundosData(prev => ({
      ...prev,
      etapa: 'redistribuirContribuicaoRegular',
      dadosContribuicaoRegular,
    }));

  const dadosInvalidos = useMemo(
    () => (mensagem: string[]) =>
      setTransferenciaFundosData(prev => ({
        ...prev,
        etapa: 'definirFundosOrigem',
        mensagemErro: mensagem,
      })),
    [],
  );

  const direcionarNovaTela = useMemo(
    () => (telaDestino: EtapaTransferencia, from?: PREV_REDIRECTS_SCREENS) =>
      setTransferenciaFundosData(prev => ({
        ...prev,
        etapa: telaDestino,
        redirecionadoDe: from,
      })),
    [],
  );

  return (
    <Context.Provider
      value={useMemo(
        () => ({
          ...transferenciaFundosData,
          escolherFundosOrigem,
          escolherFundosDestino,
          fundosDestinoPossiveis,
          escolherFundosContribuicaoRegular,
          dadosInvalidos,
          direcionarNovaTela,
          definirNumeroTransferencia,
          redefinirVariaveisSessao,
          definirPermissaoAssinatura,
        }),
        [
          transferenciaFundosData,
          escolherFundosOrigem,
          escolherFundosDestino,
          fundosDestinoPossiveis,
          dadosInvalidos,
          direcionarNovaTela,
          definirNumeroTransferencia,
          redefinirVariaveisSessao,
          definirPermissaoAssinatura,
        ],
      )}
    >
      {children}
    </Context.Provider>
  );
};

export const useTransferenciaFundosContext = () => {
  return useContext(Context);
};

export default TransferenciaFundosProvider;
