// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useContext } from 'react';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import * as REQUEST_TYPES from 'seguros/types/ApoliceAssistenciasRequest';
import * as RESPONSE_TYPES from 'seguros/types/ApoliceAssistenciasResponse';
import * as PECO_TYPES from 'seguros/types/ApoliceAssistenciasUsePeco';

export const useTabelaApoliceAssistencias =
  (): PECO_TYPES.IUseTabelaApoliceAssistencias => {
    const { clienteVida } = useContext(AppContext);

    const { response, loading } = usePeco<
      REQUEST_TYPES.ITabelaApoliceAssitenciasPayload,
      RESPONSE_TYPES.ITabelaApoliceAssitenciasResponse[]
    >({
      api: { operationPath: PECOS_VIDA.AssistenciasSeguro },
      autoFetch: true,
      payload: {
        numeroContrato: clienteVida?.numCertificado,
      },
    });

    return {
      contratos: tryGetValueOrDefault([response?.entidade], []),
      loading,
    };
  };
