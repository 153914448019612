// @ts-nocheck
import {
  Text,
  Display,
  Tag,
  Button,
  Grid,
  Card,
} from '@cvp/design-system/react';

import Icon from 'main/components/Icon';
import Table from 'main/components/Table';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { DEFAULT_NO_DATA_TABLE } from 'main/constants/messages';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import usePrevSummary from 'previdencia/hooks/usePrevSummary';
import useTipoTransferencia from 'previdencia/features/TransferenciaEntreCertificados/hooks/useTipoTransferencia';
import useTabelaFundos from 'previdencia/features/TransferenciaEntreCertificados/hooks/useTabelaFundos';
import SelecionarTipoTransferencia from 'previdencia/features/TransferenciaEntreCertificados/components/SelecionarTipoTransferencia';
import SelecionarManterBeneficioProtecao from 'previdencia/features/TransferenciaEntreCertificados/components/SelecionarManterBeneficioProtecao';
import ModalValidarValoresTipoTransferencia from 'previdencia/features/TransferenciaEntreCertificados/components/ModalValidarValoresTipoTransferencia';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import TransferenciaWrapper from '../components/TransferenciaWrapper/TransferenciaWrapper';
import ModalErro from '../components/ModalErro/ModalErro';

const TipoTransferencia = () => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const { informacaoPorCertificado } = usePrevSummary();

  const {
    formik,
    colunasTabelaFundos,
    isDisableNextStep,
    contextoAtualizado,
    toggleModalValidacaoTransferencia,
    validaPreenchimentoTransferencia,
    isExibePerguntaBeneficioProtecao,
    handleModalValidacaoValoresTipoTransferencia,
    definirFundoOrigemContexto,
    atualizarContexto,
  } = useTabelaFundos();

  const {
    loadingTransferir,
    valorMinimoPermanencia,
    mensagemErro,
    modalErro,
    handleCloseModal,
    selecionarTipoTransferenciaContexto,
    manterBeneficioProtecaoContexto,
  } = useTipoTransferencia({ contextoAtualizado, atualizarContexto });

  return (
    <TransferenciaWrapper>
      <Text color="text-light" margin>
        Plano de Origem:
      </Text>

      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Display alignItems="center">
            <Text variant="body-02-md" margin>
              <b>{informacaoPorCertificado?.produto.modalidade}</b>
            </Text>
            <Tag
              variant="primary"
              value={informacaoPorCertificado?.regimeTributario}
            />
          </Display>

          <Text variant="body-02-md">
            <b>{informacaoPorCertificado?.descricaoProduto}</b>
          </Text>

          <Grid style={{ marginTop: '20px' }}>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 4}>
              <Text variant="body01-sm">
                <b>Certificado:</b>
              </Text>

              <Text variant="body01-sm">
                {informacaoPorCertificado?.certificadoNumero}
              </Text>
            </Grid.Item>

            <Grid.Item xs={1} md={1 / 2} lg={1 / 4}>
              <Text variant="body01-sm">
                <b>Saldo do Certificado</b>
              </Text>

              <Text variant="body01-sm" margin>
                {tryGetMonetaryValueOrDefault(
                  featureData?.certificadoOrigem?.valorTotalBeneficios,
                )}
              </Text>
            </Grid.Item>

            <Grid.Item xs={1} md={1 / 2} lg={1 / 4}>
              <Text variant="body01-sm">
                <b>Como será a transferência?</b>
              </Text>

              <SelecionarTipoTransferencia
                selecionarTipoTransferenciaContexto={
                  selecionarTipoTransferenciaContexto
                }
              />
            </Grid.Item>

            <RenderConditional condition={isExibePerguntaBeneficioProtecao}>
              <Grid.Item xs={1} md={1 / 2} lg={1 / 4}>
                <Text variant="body01-sm">
                  <b>Manter o Benefício de Proteção? </b>
                </Text>

                <SelecionarManterBeneficioProtecao
                  manterBeneficioProtecaoContexto={
                    manterBeneficioProtecaoContexto
                  }
                />
              </Grid.Item>
            </RenderConditional>
          </Grid>

          <RenderConditional
            condition={
              featureData?.tipoTransferencia ===
              CONSTS.TIPOS_TRANSFERENCIA.PARCIAL
            }
          >
            <Text
              variant="body01-sm"
              color="error"
              style={{ margin: '20px 0' }}
            >
              <Icon name="warning" /> {valorMinimoPermanencia}
            </Text>
          </RenderConditional>
        </Card.Content>
      </Card>

      <RenderConditional condition={!!featureData?.tipoTransferencia}>
        <Table
          noHeader
          responsive
          highlightOnHover
          data={formik.values.fundosOrigem}
          columns={colunasTabelaFundos}
          noDataComponent={DEFAULT_NO_DATA_TABLE}
          style={{ margin: '50px 0' }}
        />

        <Display>
          <Button
            disabled={checkIfSomeItemsAreTrue([
              isDisableNextStep,
              loadingTransferir,
            ])}
            loading={loadingTransferir}
            onClick={definirFundoOrigemContexto}
          >
            Avançar
          </Button>
        </Display>
      </RenderConditional>

      <ModalValidarValoresTipoTransferencia
        open={toggleModalValidacaoTransferencia}
        dadosPreenchimento={validaPreenchimentoTransferencia}
        handleClose={handleModalValidacaoValoresTipoTransferencia}
      />

      <ModalErro
        openModal={modalErro}
        mensagem={mensagemErro}
        handleCloseModal={handleCloseModal}
      />
    </TransferenciaWrapper>
  );
};

export default TipoTransferencia;
