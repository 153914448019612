// @ts-nocheck
import styled from 'styled-components/macro';

export const Actions = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  button: {
    width: '100%',
    marginBottom: '10px',
  },

  [theme.breakpoint.xs() && theme.breakpoint.sm()]: {
    flexDirection: 'row',
    button: {
      width: '100%',
      marginRight: '10px',
    },
  },
}));
