// @ts-nocheck
export enum EModalWorkflowStep {
  CONFIRM,
  SEND,
  VALIDATE,
  FEEDBACK,
}
export interface IModalWorkflow {
  step: EModalWorkflowStep;
  mensagem?: string | null;
}
