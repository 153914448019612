// @ts-nocheck
import React from 'react';
import { uuid } from 'uuidv4';
import { Container, Rect } from './styles';

const BottomNavLoader: React.FunctionComponent<{ amount?: number }> = ({
  amount,
}) => (
  <Container>
    {[...Array(amount)].map(() => (
      <Rect key={uuid()} />
    ))}
  </Container>
);

BottomNavLoader.defaultProps = {
  amount: 3,
};

export default BottomNavLoader;
