// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { useState } from 'react';
import { useAuth } from 'main/features/Auth/hooks/useAuth';
import { useToast } from 'main/hooks/useToast';
import * as AlterarDadosBancarios from 'reter/features/retencao/services/alterarDadosBancarios.api';

export const useAlterarDadosBancarios = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setResponse] = useState<string | undefined>();

  const obterAtualizacaoDadosBancarios = async (params: any): Promise<void> => {
    try {
      setLoading(true);
      const result = await AlterarDadosBancarios.obterAtualizacaoDadosBancarios(
        { ...params, UserName: user?.nomeAcesso },
      );

      setResponse(result);
    } catch (requestError: any) {
      toastError(requestError);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    obterAtualizacaoDadosBancarios,
  };
};
