// @ts-nocheck
import React from 'react';
import For from 'main/components/For';
import { DadosArquivo } from 'main/features/Administracao/types/IFops';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import Arquivo from 'painelAdministracao/components/Arquivo/Arquivo';
import { IListaArquivoProps } from 'painelAdministracao/types/ListagemTypes';

const dadosArquivoVazio: DadosArquivo[] = [];

export const ListaArquivo: React.FC<IListaArquivoProps> = ({ fop }) => {
  return (
    <For each={tryGetValueOrDefault([fop.dadosArquivos], dadosArquivoVazio)}>
      {(arquivo: DadosArquivo, index: number) => {
        return <Arquivo key={index} fop={fop} arquivo={arquivo} />;
      }}
    </For>
  );
};
