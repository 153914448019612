// @ts-nocheck
import React from 'react';
import { Action as ActionBase } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { useTheme } from '@cvp/design-system/react';
import { THEME_NAME } from 'main/components/ThemeProvider';

type ABProps = {
  text?: string;
  onClick?: (e: React.FormEvent) => void;
  'data-testid'?: string;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const actionDefaultProps = {
  text: '',
  disabled: false,
  'data-testid': '',
  onClick: () => undefined,
};

const Action: React.FunctionComponent<ABProps> = ({
  children,
  text,
  onClick,
  disabled,
  ...rest
}) => {
  const theme = useTheme(THEME_NAME);

  return (
    <ActionBase
      style={{
        backgroundColor: disabled
          ? theme.color.neutral['04']
          : theme.color.neutral['08'],
        color: disabled
          ? theme.color.neutral['02']
          : theme.color.brandPrimary.dark,
      }}
      text={text}
      onClick={e => (!disabled ? onClick && onClick(e) : undefined)}
      {...rest}
    >
      {children}
    </ActionBase>
  );
};

Action.defaultProps = actionDefaultProps;

export { Action };
