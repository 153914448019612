// @ts-nocheck
export const DETALHES_SIMULACAO = {
  ALERT_VALOR_ABAIXO:
    'O valor da renda simulada está abaixo do previsto no regulamento do seu plano',
  ALERT_RESERVA_MINIMA: 'A reserva mínima é de',
};

export const RENDA_VITALICIA = {
  DESCRICAO: 'Você receberá uma renda mensal enquanto viver.',
  BENEFICIO: 'Esse benefício só termina no caso de seu falecimento.',
  EXEMPLO:
    'Carla quer transformar sua reserva em renda mensal, pra receber enquanto viver. Ela escolheu não deixar sua renda para seus beneficiários. Portanto, caso Carla venha a falecer, seus beneficiários não terão acesso a essa renda.',
};

export const RENDA_COM_PRAZO = {
  DESCRICAO: 'Você receberá uma renda mensal enquanto viver.',
  BENEFICIO: 'Esse benefício só termina no caso de seu falecimento.',
  EXEMPLO_INICIO:
    ' Carla transformou sua reserva em renda e escolheu 10 anos como prazo para suas duas filhas receberem sua renda no caso do seu falecimento. Ela recebeu essa renda por 3 anos, e veio a falecer. Logo, suas filhas passarão a receber sua renda por 7 anos.',
  EXEMPLO_FIM:
    'E se Carla tivesse falecido após os 10 anos que definiu? Suas filhas não teriam mais acesso a essa renda, pois o prazo já teria passado.',
};

export const RENDA_REVERSIVEL = {
  DESCRICAO: 'Você receberá uma renda mensal enquanto viver.',
  BENEFICIO: 'Esse benefício só termina no caso de seu falecimento.',
  EXEMPLO:
    'Carla tem duas filhas, e decidiu que quer transformar sua reserva em renda. Ela escolheu sua filha mais nova como beneficiária, e que ela receberia metade da sua renda. Logo, no caso do seu falecimento, sua filha mais nova será a única a ter 50% da sua renda.',
};

export const RENDA_TEMPORARIA = {
  DESCRICAO: 'Você receberá uma renda mensal enquanto viver.',
  BENEFICIO: 'Esse benefício só termina no caso de seu falecimento.',
  EXEMPLO:
    ' Carla quer transformar sua reserva em renda mensal, pra receber tudo em 10 anos. Ela escolheu não deixar sua renda para seus beneficiários. Portanto, caso Carla venha a falecer, seus beneficiários não terão acesso a essa renda.',
};

export const RENDA_PRAZO_CERTO = {
  DESCRICAO: 'Você receberá uma renda mensal enquanto viver.',
  BENEFICIO: 'Esse benefício só termina no caso de seu falecimento.',
  EXEMPLO_INICIO:
    'Carla quer transformar sua reserva em renda mensal, pra receber tudo em 10 anos. Ela tem duas filhas, e faleceu depois de ter recebido essa renda por 4 anos. Logo, suas filhas receberão essa renda por 6 anos.',
  EXEMPLO_FIM:
    ' E se Carla tivesse falecido após os 10 anos que definiu? Suas filhas não teriam mais acesso a essa renda, pois o prazo já teria passado.',
};
