// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import { AVISO_PARCELA } from '../../constants';

const TextoAviso: React.FC = () => {
  return (
    <>
      <Text variant="body01-lg" color="primary" margin>
        Importante
      </Text>
      <Text variant="body02-sm" color="text" margin>
        {AVISO_PARCELA}
      </Text>
    </>
  );
};

export default TextoAviso;
