// @ts-nocheck
import { useState, useEffect } from 'react';
import useFieldLink from 'main/hooks/useFieldLink';
import { IFilterableEntity } from 'main/types/IFilterableEntity';
import { validate } from 'main/features/Validation/utils/validateRules';
import {
  FilterType,
  ISearchTerms,
  IFilterOption,
  ITagFilterOption,
  FilterTypes,
} from 'main/components/Table/TableFilter/IFilterOption';
import * as Filters from 'main/components/Table/TableFilter/filtersHandle';

interface IUseTableFilter {
  initialData: IFilterableEntity[];
  filterOptions: IFilterOption[];
  filterTextPartial?: boolean;
  defaultFilter?: ISearchTerms;
  onSubmitCallback?: (searchTerms: ISearchTerms | undefined) => void;
}

export function useTableFilters({
  initialData = [],
  filterOptions = [],
  filterTextPartial = false,
  defaultFilter = {} as ISearchTerms,
  onSubmitCallback,
}: IUseTableFilter) {
  const [filteredData, setFilteredData] =
    useState<IFilterableEntity[]>(initialData);
  const [searchText, validateSearchText] = useFieldLink<string>('');
  const [selectFilter, validateSelectFilter] = useFieldLink<string>(
    defaultFilter?.filter ?? '',
  );
  const [searchFilterTypeSelected, setSearchFilterTypeSelected] =
    useState<FilterType>(defaultFilter?.filterType ?? 'text');
  const [initialDate, setInitialDate] = useState<Date | null>(
    defaultFilter?.initialDate ?? null,
  );
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    defaultFilter?.endDate ?? null,
  );
  const [showClearFilters, setShowClearFilters] = useState<boolean>(
    !!defaultFilter?.initialDate ?? false,
  );
  const [tagsSelecteds, setTagsSelecteds] = useState<ITagFilterOption[]>(
    [] as ITagFilterOption[],
  );
  const hasCheckBoxOption = filterOptions.find(x => x.type === FilterTypes.TAG);

  const handleSearchFilterSelected = () => {
    const filterSelectedValue = selectFilter.get().value;
    const filterTypeSelectedValue =
      filterOptions?.find(opt => opt.key === filterSelectedValue)?.type ??
      'text';

    setSearchFilterTypeSelected(filterTypeSelectedValue);
  };

  const filterByCheckbox = (dataToFilter: IFilterableEntity[]) => {
    setFilteredData(
      Filters.filterByTags(dataToFilter, hasCheckBoxOption?.key, tagsSelecteds),
    );
    setShowClearFilters(true);
  };
  const handleTagsFilter = (option: ITagFilterOption) => {
    if (tagsSelecteds.find(x => x.id === option.id)) {
      const newFilters = tagsSelecteds.filter(x => x.id !== option.id);
      if (newFilters?.length === 0) {
        setTagsSelecteds([]);
        setFilteredData(initialData);
      } else {
        setTagsSelecteds(newFilters);
      }
    } else {
      const newFilters = [...tagsSelecteds, option];
      setTagsSelecteds(newFilters);
    }
  };

  const filterByText = (search: ISearchTerms) => {
    const hasUnmask = filterOptions?.find(
      x => x.key === selectFilter.get().value,
    )?.unmask;

    const inputIsValid = validate([validateSelectFilter, validateSearchText]);
    if (inputIsValid) {
      const filterColumn = search.filter;
      const filteredItems = Filters.filterByText(
        search.text,
        filterColumn,
        initialData,
        hasUnmask,
        filterTextPartial,
      );

      if (tagsSelecteds.length > 0) {
        filterByCheckbox(filteredItems);
      } else {
        setFilteredData(filteredItems);
      }
      setShowClearFilters(true);
    }
  };

  const filterByDate = (search: ISearchTerms) => {
    const filteredItems = Filters.filterByDate(search, initialData);
    if (tagsSelecteds.length > 0) {
      filterByCheckbox(filteredItems);
    } else {
      setFilteredData(filteredItems);
    }
    setShowClearFilters(true);
  };

  const buildFilteredData = (search: ISearchTerms) => {
    if (search.filterType === 'date') return filterByDate(search);

    return filterByText(search);
  };

  const buildSearchTerms = (): ISearchTerms => ({
    text: searchText.get().value,
    filter: selectFilter.get().value,
    filterType: searchFilterTypeSelected,
    initialDate,
    endDate,
  });

  const onSubmitSearch = () => {
    if (!(searchText.get().value || endDate) && tagsSelecteds.length > 0) {
      if (onSubmitCallback) onSubmitCallback(undefined);
      filterByCheckbox(initialData);
    } else {
      const searchTerms = buildSearchTerms();
      if (initialData) {
        if (onSubmitCallback) onSubmitCallback(searchTerms);
        buildFilteredData(searchTerms);
      }
    }
  };

  const clearFilters = () => {
    searchText.set({ value: '', isValid: true, errorMsg: '' });
    selectFilter.set({ value: '', isValid: true, errorMsg: '' });
    setInitialDate(null);
    setEndDate(null);
    setFilteredData(initialData);
    setShowClearFilters(false);
    setTagsSelecteds([]);
  };

  useEffect(() => {
    handleSearchFilterSelected();
  }, [selectFilter]);

  useEffect(() => {
    if (defaultFilter?.filter) buildFilteredData(defaultFilter);
  }, [defaultFilter?.filter]);

  const inputsAreValids = () =>
    [selectFilter.get().isValid, searchText.get().isValid].every(
      x => x === true,
    );

  return {
    dataFiltered: filteredData ?? initialData ?? [],
    selectFilter,
    searchText,
    searchFilterTypeSelected,
    initialDate,
    endDate,
    showClearFilters,
    tagsSelecteds,
    setInitialDate,
    setEndDate,
    inputsAreValids,
    onSubmitSearch,
    clearFilters,
    handleTagsFilter,
    setSearchFilterTypeSelected,
  };
}
