// @ts-nocheck
import React from 'react';
import { Select, Grid, Button, Display, Text } from '@cvp/design-system/react';
import Calendar from 'main/components/Calendar/Calendar';
import Input from 'main/components/form/Input';
import RenderConditional from 'main/components/RenderConditional';
import InputSelect from 'main/components/form/InputSelect';
import { required } from 'main/features/Validation/validations';
import {
  FilterTypes,
  TableFilterProps,
} from 'main/components/Table/TableFilter/IFilterOption';
import {
  Container,
  GridItem,
  Label,
} from 'main/components/Table/TableFilter/styles';
import { useTableFilters } from 'main/components/Table/TableFilter/useTableFilters';
import { TagsFilter } from 'main/components/Table/TableFilter/TagsFilter';

const TableFilter: React.FunctionComponent<TableFilterProps> = ({
  children,
  filterOptions = [],
  dataToFilter = [],
  filterTextPartial = false,
  customButton,
  defaultFilter,
  tagsFilterDescription,
  dataProps,
  onSubmitCallback,
}) => {
  const {
    selectFilter,
    searchText,
    searchFilterTypeSelected,
    initialDate,
    endDate,
    showClearFilters,
    dataFiltered,
    tagsSelecteds,
    inputsAreValids,
    setInitialDate,
    setEndDate,
    onSubmitSearch,
    clearFilters,
    handleTagsFilter,
  } = useTableFilters({
    initialData: dataToFilter ?? [],
    filterOptions,
    filterTextPartial,
    defaultFilter,
    onSubmitCallback,
  });
  return (
    <>
      <Container>
        <Grid>
          <GridItem xs={1} md={1 / 4}>
            <RenderConditional
              condition={filterOptions?.length > 0}
              component={
                <InputSelect
                  label="Selecione o filtro"
                  placeholder="Escolha a opção"
                  link={selectFilter}
                  validationRules={[required()]}
                  data-testid="select-options"
                >
                  {filterOptions
                    .filter(x => x.type !== FilterTypes.TAG)
                    .map(item => {
                      return (
                        <Select.Item
                          selected={selectFilter.get().value === item.key}
                          key={item.key}
                          value={item.key}
                          text={item.value}
                          data-testid={`option-${item.key}`}
                        />
                      );
                    })}
                </InputSelect>
              }
            />
          </GridItem>
          <RenderConditional
            condition={searchFilterTypeSelected === 'text'}
            component={
              <GridItem xs={1} md={1 / 3}>
                <Label>Digite o termo da pesquisa</Label>
                <Input
                  type="text"
                  link={searchText}
                  validationRules={[required()]}
                  data-testid="input-pesquisa"
                />
              </GridItem>
            }
          />
          <RenderConditional
            condition={searchFilterTypeSelected === 'date'}
            component={
              <GridItem xs={1} md={1 / 3}>
                <Calendar
                  id="periodo"
                  placeholder=""
                  range
                  value={initialDate}
                  endDate={endDate}
                  onChange={(dataInicial, dataFinal) => {
                    setInitialDate(dataInicial);
                    setEndDate(dataFinal);
                  }}
                  data-testid="calendario-pesquisa"
                />
              </GridItem>
            }
          />
        </Grid>
        <div>
          <RenderConditional
            condition={
              filterOptions.filter(x => x.type === FilterTypes.TAG)?.length > 0
            }
          >
            {filterOptions
              .filter(x => x.type === FilterTypes.TAG)
              .map(item => (
                <TagsFilter
                  filterOption={item}
                  tagsSelecteds={tagsSelecteds}
                  handleTagsFilter={handleTagsFilter}
                  tagsFilterDescription={tagsFilterDescription}
                />
              ))}
          </RenderConditional>
        </div>
        <Grid>
          <GridItem
            xs={1}
            md={1 / 2}
            style={{ alignSelf: inputsAreValids() ? 'end' : 'center' }}
          >
            <Display>
              <Button
                data-testid="botao-filtrar"
                variant="secondary"
                onClick={onSubmitSearch}
              >
                Filtrar
              </Button>
              <RenderConditional
                condition={showClearFilters}
                component={
                  <Button
                    data-testid="limpar-filtros"
                    variant="outlined"
                    onClick={clearFilters}
                  >
                    Limpar filtros
                  </Button>
                }
              />
              <RenderConditional condition={!!customButton}>
                {customButton}
              </RenderConditional>
            </Display>
          </GridItem>
        </Grid>
        <RenderConditional condition={dataProps?.exibir ?? false}>
          <Text variant="caption-02">
            Período de busca:{' '}
            <strong>
              {dataProps?.dataInicio} - {dataProps?.dataFim}
            </strong>
          </Text>
        </RenderConditional>
      </Container>
      {typeof children === 'function' ? children(dataFiltered) : children}
    </>
  );
};

export default TableFilter;
