// @ts-nocheck
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';
import * as FUNC_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblFuncParams';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';

export const criarListaCoberturasFactory = ({
  coberturasCuidadoGarantido,
}: FUNC_TYPES.CriarListaCoberturasFactoryParams): VGBL_TYPES.ListaCoberturas[] => {
  let listaCoberturas: VGBL_TYPES.ListaCoberturas[] = [];

  if (coberturasCuidadoGarantido.pensao?.valorContribuicao) {
    const coberturaPensao = {
      cobertura: ENUM_TYPES.DefaultValue.COLUNA_COBERTURA_PENSAO,
      prazo: coberturasCuidadoGarantido.pensao.prazo,
      valorContribuicao: tryGetMonetaryValueOrDefault(
        coberturasCuidadoGarantido.pensao.valorContribuicao,
      ),
      valorIndenizacao: tryGetMonetaryValueOrDefault(
        coberturasCuidadoGarantido.pensao.calculoIndenizacao,
      ),
    };

    listaCoberturas = [...listaCoberturas, coberturaPensao];
  }

  if (coberturasCuidadoGarantido.peculio?.valorContribuicao) {
    const coberturaPeculio = {
      cobertura: ENUM_TYPES.DefaultValue.COLUNA_COBERTURA_PECULIO,
      prazo: ENUM_TYPES.DefaultValue.COLUNA_PRAZO_PECULIO,
      valorContribuicao: tryGetMonetaryValueOrDefault(
        coberturasCuidadoGarantido.peculio.valorContribuicao,
      ),
      valorIndenizacao: tryGetMonetaryValueOrDefault(
        coberturasCuidadoGarantido.peculio.calculoIndenizacao,
      ),
    };

    listaCoberturas = [...listaCoberturas, coberturaPeculio];
  }

  return listaCoberturas;
};
