// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useState } from 'react';
import { PECOS_SINISTRO } from 'sinistro/config/endpoints';
import * as StatusSinistroPrestamistaApi from 'sinistro/features/statusSinistro/services/statusSinistroPrestamista.api';
import { IFormStatusPrestamistaParams } from 'sinistro/features/statusSinistro/types/FormularioSinistroParams';
import { IStatusSinistroPrestamista } from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { StatusSinistroResponse } from 'sinistro/features/statusSinistro/types/enum';

const carregarTimeline = async (
  cpf: string,
  resultadoPrestamista: IStatusSinistroPrestamista[],
): Promise<IStatusSinistroPrestamista[]> => {
  const resultado: IStatusSinistroPrestamista[] = [];
  await Promise.all(
    resultadoPrestamista.map(async produto => {
      resultado.push({
        ...produto,
        timeline: await StatusSinistroPrestamistaApi.obterTimelinePrestamista({
          cpf,
          numeroSinistro: tryGetValueOrDefault(
            [produto.sinistro?.toString()],
            '',
          ),
        }),
      });
    }),
  );

  return resultado;
};

export const useStatusSinistroPrestamista = () => {
  const { fetchData } = usePeco<
    IFormStatusPrestamistaParams,
    IStatusSinistroPrestamista[]
  >({
    api: { operationPath: PECOS_SINISTRO.ListaDadosSinistroPrestamista },
    autoFetch: false,
    handleResponse: {
      throwToastErrorGI: false,
      throwToastErrorBFF: false,
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<
    IStatusSinistroPrestamista[] | undefined
  >();

  const obterStatusSinistroPrestamista = async (
    params: IFormStatusPrestamistaParams,
  ): Promise<void> => {
    setLoading(true);

    const payload = {
      ...params,
      numeroSinistro: 0,
      dataInicial: StatusSinistroResponse.DATA_INICIAL,
      dataFinal: StatusSinistroResponse.DATA_FINAL,
    };

    const resultadoPrestamista = tryGetValueOrDefault(
      [(await fetchData(payload))?.entidade],
      [],
    );

    try {
      const resultadoPrestamistaComTimeline = await carregarTimeline(
        params.cpfCnpj,
        resultadoPrestamista,
      );
      setResponse(resultadoPrestamistaComTimeline);
    } catch (requestError: unknown) {
      setResponse(resultadoPrestamista);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    setResponse,
    obterStatusSinistroPrestamista,
  };
};
