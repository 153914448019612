// @ts-nocheck
import { DadosClienteModalTipoDocuSingProps } from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import { DadosResponseAlterarValorContribuicaoBeneficioProtecao } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoBeneficioProtecao';
import { SolicitarAssinaturaBeneficioProtecaoResponse } from 'previdencia/types/SolicitarAssinaturaBeneficioProtecao';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';

export async function deveEfetuarAssinaturaDocuSign(
  solicitarAssinaturaAlteracaoValorBeneficioProtecao: (
    dadosParticipante: DadosClienteModalTipoDocuSingProps,
    numeroDocumento: string,
    url: string,
  ) => Promise<void>,
  dadosClienteModalTipoDocuSing?: DadosClienteModalTipoDocuSingProps,
  resultAlterarValorContribuicaoBeneficioProtecao?: DadosResponseAlterarValorContribuicaoBeneficioProtecao,
  url?: string,
) {
  if (
    dadosClienteModalTipoDocuSing &&
    resultAlterarValorContribuicaoBeneficioProtecao
  ) {
    await solicitarAssinaturaAlteracaoValorBeneficioProtecao(
      dadosClienteModalTipoDocuSing,
      resultAlterarValorContribuicaoBeneficioProtecao.codigoManutencao.toString(),
      url || DefaultValue.URL_CAIXA,
    );
  }
}

export function deveExibirModalTokenDocuSign(
  setConsentimentoValue: (value: React.SetStateAction<boolean>) => void,
  setConfirmarClienteCienteValue: (
    value: React.SetStateAction<boolean>,
  ) => void,
  setExibirModalTokenDocuSign: (value: React.SetStateAction<boolean>) => void,
  dadosSolicitarAssinaturaAlteracaoValorBeneficioProtecao?: SolicitarAssinaturaBeneficioProtecaoResponse,
) {
  if (dadosSolicitarAssinaturaAlteracaoValorBeneficioProtecao) {
    setConsentimentoValue(false);
    setConfirmarClienteCienteValue(false);
    setExibirModalTokenDocuSign(true);
  }
}
