// @ts-nocheck
import { IDadosBasicosCertificadoPrevidencia } from 'main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { Cobertura, PlanoSelecionado } from '../types/BeneficioProtecaoTypes';
import {
  EnumCobertura,
  EnumPeriodicidade,
  EnumTipoContribuicaoPeculio,
  EnumTipoContribuicaoPensao,
} from '../types/enum';
import { ValoresContribuicaoAtual } from '../types/CalcularBaneficioProtecaoTypes';
import {
  IPayloadIncluir,
  IPayloadReativarBeneficio,
} from '../types/ReativarIncluirBeneficioProtecaoTypes';

export const montarListaPrazos = (tempoMinimo: number, tempoMaximo: number) => {
  const listaPrazo: {
    cod: string;
    label: string;
  }[] = [];

  for (let i = tempoMinimo; i <= tempoMaximo; i += 1) {
    listaPrazo.push({
      cod: String(i),
      label: `${i} ${i > 1 ? 'anos' : 'ano'}`,
    });
  }
  return listaPrazo;
};

export const definirPlano = (
  coberturas: Cobertura[] | undefined,
  valorContribuicaoAtual: ValoresContribuicaoAtual | undefined,
  periodicidade: string,
  cobertura: number | undefined,
  valorContribuicao: number | undefined,
  beneficio: number | undefined,
) => {
  const planoSelecionado = coberturas?.find(
    item =>
      item.codBeneficio === cobertura &&
      item.periodicidade.toLocaleLowerCase() === periodicidade.substring(0, 1),
  );
  const valorContribuicaoAtualSelecionado =
    periodicidade === EnumPeriodicidade.mensal
      ? tryGetValueOrDefault(
          [valorContribuicaoAtual?.valorContribuicaoMensal],
          0,
        )
      : tryGetValueOrDefault(
          [valorContribuicaoAtual?.valorContribuicaoAnual],
          0,
        );
  return {
    codigoPlano: planoSelecionado?.codPlan,
    codigoBeneficio: planoSelecionado?.codBeneficio,
    periodicidade,
    tipoPagamento: planoSelecionado?.tipPag.toString(),
    codigoContribuicao: planoSelecionado?.codContrib,
    valorContribuicao:
      tryGetValueOrDefault([valorContribuicao], 0) +
      valorContribuicaoAtualSelecionado,
    valorBeneficio: beneficio,
  };
};

export const definirCodigoManutencao = (
  reativacao: boolean,
  codigoManutencaoReativar: number | undefined,
  codigoManutencaoIncluir: number | undefined,
): number | undefined => {
  return reativacao ? codigoManutencaoReativar : codigoManutencaoIncluir;
};

export const construirPayloadIncluir = (
  informacaoPorCertificado: IDadosBasicosCertificadoPrevidencia | undefined,
  planoSelecionado: PlanoSelecionado | undefined,
): IPayloadIncluir => {
  if (informacaoPorCertificado && planoSelecionado) {
    return {
      codigoPlano: planoSelecionado.codigoPlano,
      codigoBeneficio: planoSelecionado.codigoBeneficio,
      tipoPagamento: planoSelecionado.tipoPagamento,
      valorContribuicao: planoSelecionado.valorContribuicao,
      codigoContribuicao: planoSelecionado.codigoContribuicao,
      valorBeneficio: planoSelecionado.valorBeneficio,
    };
  }
  return {
    codigoPlano: 0,
    codigoBeneficio: 0,
    tipoPagamento: '',
    valorContribuicao: 0,
    codigoContribuicao: '',
    valorBeneficio: 0,
  };
};

export const obterTipoContribuicao = (
  periodicidade: string | undefined,
  codigoContribuicao: number | undefined,
): string => {
  if (codigoContribuicao === EnumCobertura.peculio) {
    return periodicidade === EnumPeriodicidade.anual
      ? EnumTipoContribuicaoPeculio.anual
      : EnumTipoContribuicaoPeculio.mensal;
  }
  return periodicidade === EnumPeriodicidade.anual
    ? EnumTipoContribuicaoPensao.anual
    : EnumTipoContribuicaoPensao.mensal;
};

export const construirPayloadReativar = (
  informacaoPorCertificado: IDadosBasicosCertificadoPrevidencia | undefined,
  planoSelecionado: PlanoSelecionado | undefined,
): IPayloadReativarBeneficio => {
  if (informacaoPorCertificado && planoSelecionado) {
    const tipoContribuicao = obterTipoContribuicao(
      planoSelecionado.periodicidade,
      planoSelecionado.codigoBeneficio,
    );
    return {
      codigoPlano: planoSelecionado.codigoPlano,
      codigoBeneficio: planoSelecionado.codigoBeneficio,
      tipoContribuicao,
    };
  }
  return {
    codigoPlano: 0,
    codigoBeneficio: 0,
    tipoContribuicao: '',
  };
};
