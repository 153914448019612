// @ts-nocheck
import React from 'react';
import {
  Card,
  Display,
  Text,
  TextField,
  Grid,
  Button,
} from '@cvp/design-system/react';
import masks from 'main/utils/masks';
import { Field, FieldProps, Formik } from 'formik';
import { validaCpfCnpj } from 'main/utils/cpf_cnpj';
import { useHistory } from 'react-router';
import CardFeatureDescription from '../components/CardFeatureDescription';

const ConsultarCliente: React.FC = () => {
  const history = useHistory();

  const handleSearchSubmit = async (value: string) => {
    const unmaskedValue = masks.numberOnly.unmask(value);
    history.push('/atendimento/area-logada/detalhes', {
      numCpf: unmaskedValue,
    });
  };

  return (
    <>
      <CardFeatureDescription />
      <Display type="display-block">
        <Formik
          initialValues={{ search: '' }}
          onSubmit={values => handleSearchSubmit(values.search)}
        >
          {({ handleSubmit, handleBlur, values, setFieldValue, errors }) => (
            <form onSubmit={handleSubmit} style={{ height: '100%' }}>
              <Card>
                <Card.Content>
                  <Text variant="body02-sm" margin>
                    Para começar, informe o CPF do cliente:
                  </Text>
                  <Grid>
                    <Grid.Item md={1 / 3}>
                      <Field name="search" validate={validaCpfCnpj}>
                        {({ field, meta }: FieldProps) => {
                          return (
                            <TextField
                              {...field}
                              error={meta.touched && meta.error}
                              errorMessage={meta.touched && meta.error}
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(
                                  'search',
                                  masks.cpfCnpj.unmask(value),
                                );
                              }}
                              value={masks.cpfCnpj.mask(values.search)}
                              onBlur={handleBlur}
                            />
                          );
                        }}
                      </Field>
                    </Grid.Item>
                    <Grid.Item md={1 / 3}>
                      <Button
                        disabled={Object.keys(errors).length > 0}
                        type="submit"
                      >
                        Consultar
                      </Button>
                    </Grid.Item>
                  </Grid>
                </Card.Content>
              </Card>
            </form>
          )}
        </Formik>
      </Display>
    </>
  );
};

export default ConsultarCliente;
