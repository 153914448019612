// @ts-nocheck
import styled from 'styled-components/macro';
import { Text } from '@cvp/design-system/react';

export const FeatureList = styled.ul({
  textAlign: 'left',
  marginTop: '1rem',
  marginBottom: '5px',
  color: '#414042',
  li: { marginLeft: '1.5rem' },
});

export const TextAlign = styled(Text)({
  margin: '5px 0',
  textAlign: 'left',
});
