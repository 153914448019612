// @ts-nocheck
import { Tooltip } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import {
  TooltipLinhaDoTempo,
  TooltipLinhaDoTempoIcom,
  TooltipLinhaDoTempoWrapper,
} from 'sinistro/features/statusSinistro/pages/styles';
import { LinhaTempoSinistroDados } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';
import { ColunasLinhaTempoSinistroFactory } from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import RenderConditional from 'main/components/RenderConditional';

export const colunasLinhaTempoSinistroFactory: ColunasLinhaTempoSinistroFactory =
  () => [
    {
      name: 'Data',
      selector: 'data',
      minWidth: '150px',
      maxWidth: '200px',
    },
    {
      name: 'Histórico',
      selector: 'descricaoStatus',
      minWidth: '250px',
      wrap: true,
      cell: ({
        descricaoStatus,
        textoAuxiliarStatus,
      }: LinhaTempoSinistroDados) => {
        return (
          <>
            <RenderConditional condition={!!textoAuxiliarStatus}>
              <TooltipLinhaDoTempoWrapper>
                {descricaoStatus}
                <TooltipLinhaDoTempoIcom>
                  <Icon name="questionHelp" />
                </TooltipLinhaDoTempoIcom>
                <TooltipLinhaDoTempo>
                  <Tooltip
                    variant="gray"
                    text={textoAuxiliarStatus}
                    position="top"
                  >
                    &nbsp; &nbsp; &nbsp;
                  </Tooltip>
                </TooltipLinhaDoTempo>
              </TooltipLinhaDoTempoWrapper>
            </RenderConditional>
            <RenderConditional condition={!textoAuxiliarStatus}>
              {descricaoStatus}
            </RenderConditional>
          </>
        );
      },
    },
  ];
