// @ts-nocheck
import {
  obterEndPointAssinaturaTransferenciaFundos,
  obterEndpointConfirmarTransferencia,
} from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseConfirmarTransferencia } from '../types/ConfirmarTransferencia.types';
import {
  ISolicitarAssinaturaRequest,
  ISolicitarAssinaturaResponse,
} from '../types/ISolicitarAssinaturaRequest';

export const confirmarTransferencia = async (
  cpf: string,
  idTransferencia: string | undefined,
  numeroCertificado: string,
): Promise<ResponseConfirmarTransferencia | undefined> => {
  if (idTransferencia) {
    const { data } = await api.post<
      IApiResponse<ResponseConfirmarTransferencia | undefined>
    >(obterEndpointConfirmarTransferencia(), {
      cpf,
      idTransferencia,
      numeroCertificado,
    });

    if (data?.dados?.sucesso === false)
      throw new Error(
        data?.dados?.mensagens && data?.dados?.mensagens[0]?.descricao,
      );

    return data.dados.entidade;
  }
  return undefined;
};

export const solicitarAssinatura = async (
  payload: ISolicitarAssinaturaRequest,
): Promise<ISolicitarAssinaturaResponse | undefined> => {
  const { data } = await api.post<
    IApiResponse<ISolicitarAssinaturaResponse | undefined>
  >(obterEndPointAssinaturaTransferenciaFundos(), payload);

  if (data?.dados?.sucesso === false)
    throw new Error(
      data?.dados?.mensagens && data?.dados?.mensagens[0]?.descricao,
    );

  return data.dados.entidade;
};
