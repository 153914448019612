// @ts-nocheck
import { toast } from 'react-toastify';

const DEFAULT_SUCCESS_MESSAGE = 'Operação realizada com sucesso.';
const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro ao realizar a operação!';

export const toastSuccess = (
  message?: string,
  onClose?: (props: any) => void,
) =>
  toast.success(message ?? DEFAULT_SUCCESS_MESSAGE, {
    position: toast.POSITION.TOP_RIGHT,
    onClose,
  });
export const toastError = (message?: string, onClose?: (props: any) => void) =>
  toast.error(message ?? DEFAULT_ERROR_MESSAGE, {
    position: toast.POSITION.TOP_RIGHT,
    onClose,
  });

export const toastWarnin = (message?: string, onClose?: (props: any) => void) =>
  toast.warning(message ?? DEFAULT_ERROR_MESSAGE, {
    position: toast.POSITION.TOP_RIGHT,
    onClose,
  });

export const useToast = () => {
  return {
    toastSuccess,
    toastError,
    toastWarnin,
  };
};
