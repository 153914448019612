// @ts-nocheck
import React, { useEffect } from 'react';
import { Display, Text, Button } from '@cvp/design-system/react';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import TabelaMultifundosPJ from 'previdencia/components/TabelaMultifundosPJ/TabelaMultifundosPJ';
import { MultifundoPjData } from 'previdencia/types/MultifundosPJ';
import {
  formatarValorPadraoBrasileiro,
  removerMascaraValorMonetario,
} from 'main/utils/money';
import { EnumEtapas } from 'previdencia/types/Fundo.type';
import { FUNDOS_DESTINO_TEXTS, PAGE_TEXTS } from '../../constants/texts';
import TabelaResumo from '../TabelaResumo/TabelaResumo';
import ResumoOrigem from '../ResumoOrigem/ResumoOrigem';
import { SelecionarFundosDestinoProps } from '../../types/SelecionarFundos.types';
import { ITransferenciaMultifundosPjFeatureData } from 'previdencia/components/TabelaMultifundosPJ/types/ITransferenciaMultifundosPjFeatureData';
import useDefinirDestino from '../../hooks/useDefinirDestino';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  formatarFundosPayload,
  obterTipoTransferencia,
} from '../../factories/fundosFactory';
import { removeDuplicates } from '../../utils/TransferenciaMultifundos';
import { ENUM_TIPO_FUNDO } from '../../types/Enum';

const SelecionarFundosDestino: React.FC<SelecionarFundosDestinoProps> = ({
  total,
}) => {
  const { setFeatureData, featureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();
  const {
    response: responseDefinirDestino,
    loading: loadingDefinirDestino,
    fetchData: definirFundosDestino,
  } = useDefinirDestino(
    formatarFundosPayload(
      featureData?.fundosDestinoSelecionados,
      ENUM_TIPO_FUNDO.DESTINO,
      obterTipoTransferencia(
        tryGetValueOrDefault([featureData?.fundosSelecionados], []),
      ),
    ),
    featureData?.idRequisicao,
  );

  const desabilitarBotao = () => {
    const totalRestante = tryGetValueOrDefault(
      [featureData?.resumoDestino],
      [],
    )[4]?.restante;

    return checkIfSomeItemsAreTrue([
      tryGetValueOrDefault([featureData?.fundosDestinoSelecionados], [])
        .length < 1,
      Number(
        removerMascaraValorMonetario(
          tryGetValueOrDefault([totalRestante], '0'),
        ),
      ) !== 0,
    ]);
  };

  useEffect(() => {
    if (responseDefinirDestino?.numeroTransferencia) {
      setFeatureData({
        ...featureData,
        etapa: EnumEtapas.confirmarTransferencia,
      });
    }
  }, [responseDefinirDestino]);

  const fundosDisponiveis = featureData?.fundosDestinoDisponiveis;
  const fundosDestino: MultifundoPjData[] | undefined =
    fundosDisponiveis?.fundosOrigem.concat(fundosDisponiveis.fundosNovos);
  return (
    <>
      <Text variant="body02-sm" margin>
        {FUNDOS_DESTINO_TEXTS.VALOR_TRANSFERENCIA}
        <b>{formatarValorPadraoBrasileiro(total)}</b>
      </Text>
      <ResumoOrigem />
      <Text variant="body02-sm" margin>
        {FUNDOS_DESTINO_TEXTS.QUANTIDADE_FUNDOS_DESTINO}{' '}
        <b>{fundosDisponiveis?.quantidadeMaximaFundos} fundo(s)</b>
      </Text>
      <Text variant="body02-sm" margin>
        {FUNDOS_DESTINO_TEXTS.AQUISICAO_NOVOS_FUNDOS}{' '}
        <b>
          {formatarValorPadraoBrasileiro(
            fundosDisponiveis?.valorMinimoNovoFundo,
          )}
        </b>
      </Text>
      <Text variant="body02-md" color="primary">
        Destino
      </Text>
      <TabelaMultifundosPJ
        fundos={removeDuplicates(fundosDestino)}
        valorMinimo={tryGetValueOrDefault(
          [featureData?.valorMinimoTransferencia],
          0,
        )}
        quantidadeMaximaFundos={tryGetValueOrDefault(
          [fundosDisponiveis?.quantidadeMaximaFundos],
          0,
        )}
        isLoading={loadingDefinirDestino}
        setFundosSelecionados={(fundos: MultifundoPjData[]) => {
          setFeatureData({
            ...featureData,
            fundosDestinoSelecionados: fundos,
          });
        }}
      />
      <Text variant="body02-sm">
        <b>Importante</b>
      </Text>
      <Text variant="body02-sm" margin>
        {PAGE_TEXTS.IMPORTANTE}
      </Text>
      <TabelaResumo />
      <Display justify="space-between">
        <Button
          variant="outlined"
          onClick={() =>
            setFeatureData({
              ...featureData,
              etapa: EnumEtapas.selecionarFundosOrigem,
            })
          }
        >
          Voltar
        </Button>
        <Button
          loading={loadingDefinirDestino}
          disabled={desabilitarBotao()}
          onClick={() => definirFundosDestino()}
        >
          Continuar
        </Button>
      </Display>
    </>
  );
};

export default SelecionarFundosDestino;
