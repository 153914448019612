// @ts-nocheck
import { STORAGE_BASE_KEY } from 'main/constants/app.config';

export function setSessionItem(key: string, data: unknown) {
  window.sessionStorage.setItem(
    `${STORAGE_BASE_KEY}:${key}`,
    JSON.stringify(data),
  );
}

export function getSessionItem<T>(key: string) {
  const itemRecovered = window.sessionStorage.getItem(
    `${STORAGE_BASE_KEY}:${key}`,
  );

  if (itemRecovered) return JSON.parse(itemRecovered) as T;

  return {} as T;
}

export const removeSessionItem = (key: string) => {
  window.sessionStorage.removeItem(`${STORAGE_BASE_KEY}:${key}`);
};

export function setLocalItem(key: string, data: unknown) {
  window.localStorage.setItem(
    `${STORAGE_BASE_KEY}:${key}`,
    JSON.stringify(data),
  );
}

export function getLocalItem<T>(key: string) {
  const itemRecovered = window.localStorage.getItem(
    `${STORAGE_BASE_KEY}:${key}`,
  );

  if (itemRecovered) return JSON.parse(itemRecovered) as T;

  return null;
}

export const removeLocalItem = (key: string) => {
  window.localStorage.removeItem(`${STORAGE_BASE_KEY}:${key}`);
};
