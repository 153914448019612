// @ts-nocheck
import { Text } from '@cvp/design-system/react';
import Modal from 'main/components/Modal';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const ModalPendenciaSolicitacaoResgate = ({
  open,
  handleClose,
  motivoPendenciaResgate,
}: SOLICITACAO_RESGATE_TYPES.IModalPendenciaSolicitacaoResgateProps) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Text variant="caption-02">
          <strong>{CONSTS.TEXTOS_MODAL_PENDENCIA_RESGATE.MSG_PENDENCIA}</strong>
        </Text>

        <br />
        <Text variant="caption-02" color="error">
          <strong>{motivoPendenciaResgate}</strong>
        </Text>
      </>
    </Modal>
  );
};

export default ModalPendenciaSolicitacaoResgate;
