// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IPayloadSimularRisco,
  IResponseSimularRisco,
} from 'previdencia/types/SimularRisco';

export const usePecoSimularTransferenciaRiscoPrevidencia = (
  numeroTransferencia: string,
) => {
  const {
    loading: isLoadingSimulacaoRisco,
    response: dadosSimulacaoRisco,
    fetchData: simularRisco,
  } = usePeco<Partial<IPayloadSimularRisco>, IResponseSimularRisco>({
    api: {
      operationPath: PECOS.SimularTransferenciaRiscoPrevidencia,
    },
    payload: {
      numeroTransferencia,
    },
  });

  return {
    isLoadingSimulacaoRisco,
    dadosSimulacaoRisco: dadosSimulacaoRisco?.entidade,
    simularRisco,
  };
};
