// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';

import { PECOS } from 'previdencia/config/endpoints';
import { IResponseListartMotivoCancelamento } from '../types/ListarMotivoCancelamento';

export const usePecoListarMotivoCancelamento = () => {
  const { response, loading: loadingListarMotivoCancelamento } = usePeco<
    unknown,
    IResponseListartMotivoCancelamento[]
  >({
    api: {
      operationPath: PECOS.ListarMotivosCancelamentoPrevidencia,
    },
    handleResponse: { throwToastErrorBFF: false },
    autoFetch: true,
  });

  return {
    ListarMotivoCancelamento: response?.entidade,
    loadingListarMotivoCancelamento,
  };
};
