// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react';
import { retornaFundosSelecionados } from 'previdencia/utils/tabelaFundos';
import ModalTipoDocuSign from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import * as Factory from 'previdencia/features/TransferenciaFundoInvestimento/factories/assinaturaTransferenciaFundosFactory';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import { AppContext } from 'main/contexts/AppContext';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import RenderConditional from 'main/components/RenderConditional';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import {
  PrevRedirectConfig,
  PREV_REDIRECTS_SCREENS,
} from 'previdencia/config/redirects';
import { useHistory } from 'react-router';
import { IMessageEvent } from 'main/types/IMessageEvent';
import { useTransferenciaFundosContext } from '../../contexts/ContextTransferenciaFundos';
import TabelasDadosSolicitacao from '../TabelasDadosSolicitacao';
import useConfirmarTransferencia from '../../hooks/useConfirmarTransferencia';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

const ComprovanteTransferenciaFundos: React.FC = () => {
  const { subscribe, unsubscribe } = useMessageEvent();
  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] = useState(false);
  const [openModalTipo, setModalTipo] = useState<boolean>(true);
  const {
    fundosOrigem,
    fundosDestino,
    numTransferencia,
    redirecionadoDe,
    redefinirVariaveisSessao,
    direcionarNovaTela,
  } = useTransferenciaFundosContext();
  const fundosOrigemSelecionados = retornaFundosSelecionados(fundosOrigem);
  const fundosDestinoSelecionados = retornaFundosSelecionados(fundosDestino);
  const [urlAssinatura, setUrlAssinatura] = useState<string>('');
  const { data: responseObterContatosDefault } =
    useObterContatosDefaultPrevidencia();
  const {
    numeroDDD,
    numeroTelefoneSemDDD,
    nome,
    emailDefault,
    numerosTelefone,
  } = responseObterContatosDefault ?? {};

  const { solicitarAssinatura, loadingSolicitarAssinatura } =
    useConfirmarTransferencia();
  const [openModalDocUsign, setOpenModalDocUsign] = useState<boolean>(false);
  const [eventSucess, setEventSucess] = useState<boolean>(false);
  const { cliente } = useContext(AppContext);
  const history = useHistory();

  const construirPayload = () =>
    Factory.construirPayloadAssinaturaRequest({
      numeroDocumento: numTransferencia,
      telefone: numeroTelefoneSemDDD,
      cpf: cliente.cpfCnpj,
      nome,
      email: emailDefault,
      codigoCertificado: cliente.numCertificado,
      codigoTelefone: numeroDDD,
    });

  const handleAssinar = async () => {
    const result = await solicitarAssinatura(construirPayload());
    if (result && result.urlAssinatura) {
      setUrlAssinatura(result.urlAssinatura);
      setOpenModalDocUsign(true);
    }

    return result;
  };

  useEffect(() => {
    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;
      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setEventSucess(true);
        setModalTipo(false);
        setExibirBotaoFecharModal(true);
        direcionarNovaTela('conclusaoTransferencia');
      }
      redefinirVariaveisSessao();
    });
    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(true));
    };
  }, []);

  const handleCloseModalAssinatura = () => {
    if (redirecionadoDe) {
      history.push(PrevRedirectConfig[redirecionadoDe]);
    }
    if (!eventSucess) {
      setOpenModalDocUsign(false);
      setModalTipo(false);
      history.push(
        PrevRedirectConfig[PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES],
      );
    }
  };

  return (
    <>
      <RenderConditional
        condition={
          fundosOrigemSelecionados?.length > 0 &&
          fundosDestinoSelecionados?.length > 0
        }
      >
        <TabelasDadosSolicitacao
          fundosAlterados={fundosOrigemSelecionados}
          fundosRedistribuidos={fundosDestinoSelecionados}
        />
      </RenderConditional>
      <RenderConditional condition={openModalTipo}>
        <ModalTipoDocuSign
          open={openModalTipo}
          executarAssinatura={() => handleAssinar()}
          handleClose={handleCloseModalAssinatura}
          tituloModal="Transferência de Fundos de Investimentos"
          loading={loadingSolicitarAssinatura}
          dadosCliente={{
            email: emailDefault,
            cpfCnpj: cliente?.cpfCnpj,
            mobileNumber: numerosTelefone,
          }}
        />
      </RenderConditional>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([openModalDocUsign, !!urlAssinatura])}
      >
        <ModalTokenDocuSign
          open={openModalDocUsign}
          urlTokenPage={urlAssinatura}
          handleClose={handleCloseModalAssinatura}
          exibirBotaoFecharModal={exibirBotaoFecharModal}
        />
      </RenderConditional>
    </>
  );
};

export default ComprovanteTransferenciaFundos;
