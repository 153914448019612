// @ts-nocheck
import UploadPropostaFormValidationSchema from 'contratosPrestamista/features/uploadproposta/config/UploadPropostaFormValidationSchema';
import * as Yup from 'yup';

export const UploadPropostaDpsValidationSchema =
  UploadPropostaFormValidationSchema.concat(
    Yup.object().shape({
      capitalSegurado: Yup.string().required('Campo obrigatório'),
      valorCredito: Yup.string().required('Campo obrigatório'),
    }),
  );
