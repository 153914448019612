// @ts-nocheck
import { useEffect, useRef, useState } from 'react';

const DEFAULT_TIME = 120;

const formatTime = (num: number) => String(num).padStart(2, '0');
type CountDownStatus = 'STARTING' | 'RUNNING' | 'STOPPED';

export const useCountDown = (time: number = DEFAULT_TIME) => {
  const [timer, setTimer] = useState(time);
  const [restartTimer, setRestartTimer] = useState<boolean>(false);
  const [timerStatus, setTimerStatus] = useState<CountDownStatus>('STARTING');
  const idTimer = useRef<number>(0);
  const seconds = timer % 60;
  const minutes = (timer - seconds) / 60;

  const clear = () => {
    window.clearInterval(idTimer.current);
    setTimerStatus('STOPPED');
  };
  const runTimer = () => {
    setTimerStatus('RUNNING');
    idTimer.current = window.setInterval(() => {
      setTimer(counter => counter - 1);
    }, 1000);
  };

  useEffect(() => {
    runTimer();
    return () => clear();
  }, []);
  useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  useEffect(() => {
    if (restartTimer) {
      setTimer(time);
      runTimer();
      setTimerStatus('STARTING');
    }
    return () => clear();
  }, [restartTimer]);

  return {
    secondsRemaining: formatTime(seconds),
    minutesRemaining: formatTime(minutes % 60),
    restartTimer: setRestartTimer,
    timerStatus,
  };
};
