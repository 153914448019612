// @ts-nocheck
import { obterEndpointAtualizarValorContribuicao } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { SolicitacaoAlteracaoContribuicaoRenda } from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';
import { SolicitarAlteracaoContribuicaoRendaPayload } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoBeneficioProtecao';

export const obterSolicitacaoAlteracaoContribuicaoRenda = async (
  payload: SolicitarAlteracaoContribuicaoRendaPayload,
): Promise<SolicitacaoAlteracaoContribuicaoRenda | undefined> => {
  if (payload.reserves) {
    const { data } = await api.post<
      IApiResponse<SolicitacaoAlteracaoContribuicaoRenda>
    >(obterEndpointAtualizarValorContribuicao(), payload);

    const mensagemErro: string | undefined =
      data?.dados?.mensagens?.[0].descricao;

    if (data?.dados?.sucesso === false || data.sucesso === false)
      throw new Error(mensagemErro);

    return data?.dados?.entidade;
  }

  return undefined;
};
