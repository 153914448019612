// @ts-nocheck
import { useHistory } from 'react-router';

const usePrestNavigation = () => {
  const history = useHistory();

  return {
    goHomePrest: () => history.push(`/cliente/produtos/prestamista`),
    goDadosSeguradoPrest: () =>
      history.push(`/cliente/produtos/prestamista/dados-segurado`),
    goHome: () => history.push(`/cliente/produtos/`),
  };
};

export default usePrestNavigation;
