// @ts-nocheck
import styled from 'styled-components/macro';

export const FormCalendarContainer = styled.div(
  ({ theme: { breakpoint } }) => ({
    display: 'inline-flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',

    [breakpoint.md()]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
);

export const FormCalendarInputContainer = styled.div(
  ({ theme: { color } }) => ({
    display: 'inline-flex',
    flexDirection: 'column',
    flexGrow: 1,

    '.calendar-day': {
      '&.react-datepicker__day--selected, &.react-datepicker__day--keyboard-selected':
        {
          backgroundColor: color.brandPrimary.light,
          color: color.neutral['08'],
        },
      '&.react-datepicker__day--disabled': {
        backgroundColor: 'transparent',
      },

      '&.react-datepicker__day--in-selecting-range': {
        color: color.neutral['08'],
      },
    },

    '> p': {
      marginBottom: '8px',
      fontSize: '16px',
      fontWeight: 400,
    },
  }),
);

export const FormCalendarInput = styled.input<{ hasError?: boolean }>(
  ({ theme: { color }, hasError = false }) => ({
    width: '100%',
    height: '56px',
    fontSize: '16px',
    border: `1px solid ${color.neutral['04']}`,
    color: color.neutral['02'],
    borderRadius: '4px',
    padding: '0 12px',

    '&:focus': {
      borderColor: color.brandPrimary.light,
      color: color.brandPrimary.light,
      outline: 'none',
    },
    ...(hasError && {
      borderColor: color.feedback.error,
      color: color.feedback.error,
    }),
  }),
);

export const ErrorMsg = styled.p(({ theme: { color, font } }) => ({
  marginBottom: 0,
  marginTop: 8,
  color: color.feedback.error,
  fontSize: font.size.xs,
  svg: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    top: '0.125em',
    position: 'relative',
  },
}));
