// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { popularTabelaAnoHistorico } from 'previdencia/features/ReajusteAnualPlano/factories/reajusteAnualFactory';
import {
  PayloadAnoHistorico,
  ResponseAnoHistorico,
} from 'previdencia/features/ReajusteAnualPlano/types/ResponseAnosHistorico';

const useObterAnoHistorico = (
  cpf: string,
  numeroCertificado: string,
  ano: string,
) => {
  const { loading, response, fetchData } = usePeco<
    PayloadAnoHistorico,
    ResponseAnoHistorico
  >({
    api: { operationPath: PECOS.ObterAnoHistorico },
    payload: {
      cpf,
      numeroCertificado,
      ano,
    },
    cache: true,
    cacheKey: `${cpf}-${numeroCertificado}-${ano}`,
  });

  const { dadosTabelaCuidadoExtra, dadosTabelaReserva } =
    popularTabelaAnoHistorico(response?.entidade);

  return {
    dadosTabelaCuidadoExtra,
    dadosTabelaReserva,
    isLoading: loading,
    refetch: fetchData,
    isFetching: loading,
  };
};

export default useObterAnoHistorico;
