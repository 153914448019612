// @ts-nocheck
import { toastSuccess, useToast } from 'main/hooks/useToast';
import * as atualizarDataAposentadoriaApi from 'previdencia/features/AlteracaoPrazoDiferimento/services/atualizarDataAposentadoria.api';
import { useState } from 'react';

export const useAtualizarDataAposentadoria = () => {
  const { toastError } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [mensagemSucesso, setMensagemSucesso] = useState('');
  const atualizarDataAposentadoria = async (
    userName: string,
    numCertificado: string,
    dataAposentadoria: string,
  ) => {
    try {
      setLoading(true);
      const result =
        await atualizarDataAposentadoriaApi.atualizarDataAposentadoria(
          userName,
          numCertificado,
          dataAposentadoria,
        );
      const message: string | undefined =
        result?.dados?.mensagens?.[0].descricao;
      if (message) {
        setMensagemSucesso(message);
        toastSuccess(message);
      }
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    mensagemSucesso,
    loading,
    atualizarDataAposentadoria,
  };
};
export default useAtualizarDataAposentadoria;
