// @ts-nocheck
import styled from 'styled-components/macro';

export const Container = styled.div(({ theme: { breakpoint } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  width: '90%',
  flexDirection: 'column',
  marginTop: '35px',
  marginBottom: '60px',
  gap: '40px',
  [breakpoint.lg()]: {
    flexDirection: 'row',
    gridTemplateColumns: '1fr 1fr',
  },
}));
