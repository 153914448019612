// @ts-nocheck
import { startOfMonth } from 'date-fns';
import RangeDate from 'previdencia/types/RangeDate';

export const calcularPeriodo = (periodo: string): RangeDate => {
  const now = new Date();
  switch (periodo) {
    case '2':
      return {
        dataInicial: new Date(now.getFullYear(), now.getMonth() - 1, 1),
        dataFinal: new Date(now.getFullYear(), now.getMonth(), 0),
      } as RangeDate;
    case '3':
      return {
        dataInicial: new Date(now.getFullYear(), 0, 1),
        dataFinal: now,
      } as RangeDate;
    case '4':
      return {
        dataInicial: new Date(now.getFullYear() - 1, 0, 1),
        dataFinal: new Date(now.getFullYear() - 1, 11, 31),
      } as RangeDate;
    default:
      return {
        dataInicial: startOfMonth(now),
        dataFinal: now,
      } as RangeDate;
  }
};

export const getOptions = () => {
  return [
    {
      value: '1',
      text: 'Mês atual',
    },
    {
      value: '2',
      text: 'Mês anterior',
    },
    {
      value: '3',
      text: 'Ano atual',
    },
    {
      value: '4',
      text: 'Ano anterior',
    },
    {
      value: 'custom',
      text: 'Período personalizado',
    },
  ];
};
