// @ts-nocheck
import { Button } from '@cvp/design-system/react';

import { IFilterOption } from 'main/components/Table/TableFilter/IFilterOption';
import masks from 'main/utils/masks';
import { formatarData } from 'main/utils';
import * as RESPONSE_TYPES from 'contratosPrestamista/features/reenvioDpsEletronica/types/ConsultaDpsResponse';
import * as CONSULTAR_DPS_TYPES from 'contratosPrestamista/features/reenvioDpsEletronica/types/ConsultarDps';
import * as S from 'contratosPrestamista/features/dpsEletronicaPostecipado/components/styles';

export const INITIAL_FORMIK_STATE: CONSULTAR_DPS_TYPES.IFormikValuesAtualizacaoDadosCliente =
  {
    nome: '',
    cpf: '',
    dataNascimento: '',
    sexo: 'M',
    telefone: '',
    email: '',
    numeroProposta: '',
  };

export const FILTER_OPTIONS: IFilterOption[] = [
  {
    key: 'codigoCpf',
    value: 'CPF',
    type: 'text',
    unmask: masks.numberOnly.mask,
  },
  {
    key: 'dataHoraSolicitacao',
    value: 'Data Solicitação DPS',
    type: 'date',
  },
];

export const COLUNAS = ({
  handleModalAcaoDps,
}: CONSULTAR_DPS_TYPES.IColunasProps) => [
  {
    name: 'CPF',
    selector: 'codigoCpf',
    center: true,
    sortable: true,
    minWidth: '200px',
    cell: (row: RESPONSE_TYPES.IResponseRecuperarListaDpsSolicitacoesDps) => (
      <span>{masks.cpf.mask(row.dadoCadastralCliente.cpf)}</span>
    ),
  },
  {
    name: 'Identificador DPS',
    selector: 'codigoIdentificadorDps',
    center: true,
    sortable: true,
    minWidth: '400px',
    cell: (row: RESPONSE_TYPES.IResponseRecuperarListaDpsSolicitacoesDps) => (
      <S.TextWithEllipsis title={row.codigoIdentificadorDps}>
        {row.codigoIdentificadorDps}
      </S.TextWithEllipsis>
    ),
  },
  {
    name: 'Data de Solicitação DPS',
    selector: 'dataHoraSolicitacao',
    center: true,
    sortable: true,
    minWidth: '200px',
    cell: (row: RESPONSE_TYPES.IResponseRecuperarListaDpsSolicitacoesDps) => (
      <span>{formatarData(row.dataHoraSolicitacao)}</span>
    ),
  },

  {
    name: 'Origem',
    selector: 'codigoSistemaOrigem',
    center: true,
    sortable: true,
    minWidth: '50px',
  },
  {
    name: 'Ação',
    selector: '',
    center: true,
    sortable: true,
    minWidth: '200px',
    cell: (row: RESPONSE_TYPES.IResponseRecuperarListaDpsSolicitacoesDps) => (
      <Button small onClick={() => handleModalAcaoDps(row)}>
        Reenviar Link DPS
      </Button>
    ),
  },
];

export const FORMIK_VALIDATION_MESSAGES = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório',
  EMAIL_INVALIDO: 'Informe um e-mail válido',
};

export const TIPOS_SEXO = [
  {
    id: 'F',
    sexo: 'Feminino',
  },
  {
    id: 'M',
    sexo: 'Masculino',
  },
];
