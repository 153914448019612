// @ts-nocheck
import React from 'react';
import { Button, Display, Grid, Select } from '@cvp/design-system/react';

import { showErrorInput } from 'main/utils/formik';
import RenderConditional from 'main/components/RenderConditional';
import Calendar from 'main/components/form/Calendar';
import * as CONSTS from 'evolucaoPatrimonial/constants/constants';
import * as EVOLUCAO_PATRIMONIAL_TYPES from 'evolucaoPatrimonial/types/EvolucaoPatrimonial';
import * as S from './styles';

const FiltroGrafico = ({
  formik,
  inputCalendar,
  filtrarEvolucaoPatrimonial,
  loadingConsultaEvolucaoPatrimonial,
}: EVOLUCAO_PATRIMONIAL_TYPES.IFiltroGraficoProps) => {
  return (
    <>
      <Grid>
        <Grid.Item xs={1} md={2 / 4} lg={1 / 4}>
          <Select
            {...formik.getFieldProps(formik.values.periodo)}
            label="Período:"
            placeholder="Escolha uma opção"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('periodo', value);
              formik.setFieldValue(
                'periodoPersonalizado',
                value === CONSTS.FILTRO_PERIODOS.PERIODO_PERSONALIZADO,
              );
            }}
            {...showErrorInput(formik, 'periodo')}
          >
            {CONSTS.PERIODOS.map(item => (
              <Select.Item
                key={item.id}
                value={item.id}
                text={item.label}
                selected={formik.values.periodo === item.id}
              />
            ))}
          </Select>
        </Grid.Item>

        <RenderConditional condition={formik.values.periodoPersonalizado}>
          <Grid.Item xs={1} lg={2 / 4}>
            <div>
              <Calendar
                placeholder="Informe o período"
                link={inputCalendar}
                validationRules={[]}
                maxDateRange={new Date()}
                range
              />
            </div>
          </Grid.Item>
        </RenderConditional>

        <S.GridItem xs={1} md={2 / 4} lg={1 / 4}>
          <Display>
            <Button
              data-testid="botao-filtrar"
              variant="secondary"
              onClick={filtrarEvolucaoPatrimonial}
              loading={loadingConsultaEvolucaoPatrimonial}
              disabled={loadingConsultaEvolucaoPatrimonial}
            >
              Filtrar por período
            </Button>
          </Display>
        </S.GridItem>
      </Grid>

      <Grid>
        <Grid.Item xs={1} md={2 / 4} lg={1 / 4}>
          <Select
            {...formik.getFieldProps(formik.values.tipoEvolucao)}
            label="Evolução:"
            placeholder="Escolha uma opção"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('tipoEvolucao', value);
            }}
            {...showErrorInput(formik, 'tipoEvolucao')}
          >
            {CONSTS.TIPOS_EVOLUCAO.map(item => (
              <Select.Item
                key={item.id}
                value={item.id}
                text={item.tipoEvolucao}
                selected={formik.values.tipoEvolucao === item.id}
              />
            ))}
          </Select>
        </Grid.Item>

        <Grid.Item xs={1} md={2 / 4} lg={1 / 4}>
          <Select
            {...formik.getFieldProps(formik.values.tipoModalidade)}
            label="Classificar por:"
            placeholder="Escolha uma opção"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('tipoModalidade', value);
            }}
            {...showErrorInput(formik, 'tipoModalidade')}
          >
            {CONSTS.TIPOS_MODALIDADE.map(item => (
              <Select.Item
                key={item.id}
                value={item.id}
                text={item.tipoModalidade}
                selected={formik.values.tipoModalidade === item.id}
              />
            ))}
          </Select>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default FiltroGrafico;
