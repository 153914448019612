// @ts-nocheck
import { useRef, useState } from 'react';

import { converterBase64 } from 'main/utils/converterBase64';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { IResponseComprovanteOperacao } from 'previdencia/types/ResponseComprovante';
import { obterComprovanteOperacao } from 'previdencia/services/comprovante.api';
import useMensagemTemporaria from 'previdencia/hooks/useMensagemTemporaria';

export const useObterComprovanteOperacao = () => {
  const { cpfCnpj, certificado } = usePrevidenciaContext();
  const [loading, setLoading] = useState<boolean>(false);
  const clearTimeRef = useRef<NodeJS.Timeout | null>(null);

  const { configurarMensagemTemporaria, mensagemTemporaria } =
    useMensagemTemporaria();

  const renderizarComprovanteOperacao = (
    dadosComprovanteResgate: IResponseComprovanteOperacao,
  ): boolean => {
    try {
      const blob = converterBase64(dadosComprovanteResgate.comprovante);
      const blobUrl = URL.createObjectURL(blob);
      clearTimeRef.current = setTimeout(() => {
        window.open(blobUrl);
      }, 3500);
      return true;
    } catch (exception: unknown) {
      if (exception instanceof Error)
        configurarMensagemTemporaria(exception.message);
      if (typeof exception === 'string')
        configurarMensagemTemporaria(exception);
      return false;
    }
  };

  const gerarComprovante = async (
    idRequisicao: string,
    tipoOperacao: string,
  ): Promise<boolean> => {
    try {
      setLoading(true);
      const dadosComprovanteOperacao = await obterComprovanteOperacao(
        cpfCnpj,
        certificado,
        idRequisicao,
        tipoOperacao,
      );

      if (dadosComprovanteOperacao?.dados?.entidade)
        return renderizarComprovanteOperacao(
          dadosComprovanteOperacao.dados.entidade,
        );
      return false;
    } catch (exception: unknown) {
      if (exception instanceof Error)
        configurarMensagemTemporaria(exception.message);
      if (typeof exception === 'string')
        configurarMensagemTemporaria(exception);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    gerarComprovante,
    mensagemErro: mensagemTemporaria,
    loading,
  };
};
