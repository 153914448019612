// @ts-nocheck
import styled from 'styled-components/macro';

export const LinhaDigitavel = styled.div({
  marginTop: '25px',
  marginBottom: '25px',
  border: '1px solid black',
  borderRadius: '7px',
  padding: '7px',
  ':hover': {
    cursor: 'pointer',
  },
});

export const LabelCopy = styled.label({
  position: 'absolute',
  fontSize: '10px',
  right: '40px',
  bottom: '105px',
  color: '#008000',
});

export const LabelNotCopied = styled.label({
  position: 'absolute',
  fontSize: '10px',
  right: '40px',
  bottom: '105px',
  color: '#8B0000',
});
