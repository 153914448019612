// @ts-nocheck
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  AndamentoSinistroPrevidencia,
  ICertificadoProdutoSelecionado,
  IStatusSinistroPrevidenciaDados,
} from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { LinhaTempoSinistroDados } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';

const obterDescricaoHistorico = (
  evento: AndamentoSinistroPrevidencia,
): string => {
  const beneficiario = evento.dadosBeneficiario?.nome;

  if (beneficiario)
    return `${evento.descricaoStatus} - Beneficiário: ${beneficiario}`;

  return evento.descricaoStatus;
};

export function timelinePrevidenciaFactory(
  responseStatusSinistroPrevidencia:
    | IStatusSinistroPrevidenciaDados
    | undefined,
  produtoSelecionado: ICertificadoProdutoSelecionado,
): LinhaTempoSinistroDados[] {
  const eventosTimeline = tryGetValueOrDefault(
    [
      responseStatusSinistroPrevidencia?.andamentosCertificados?.find(produto =>
        checkIfAllItemsAreTrue([
          produto.codigoCertificado === produtoSelecionado.certificado,
          String(produto.numeroAvisoSinistro) ===
            produtoSelecionado.numeroAvisoSinistro,
        ]),
      )?.andamentos,
    ],
    [],
  );
  return eventosTimeline.map(evento => {
    return {
      data: evento.data,
      descricaoStatus: obterDescricaoHistorico(evento),
      descricaoCobertura: evento.descricaoCobertura,
      textoAuxiliarStatus: evento.textoAuxiliarStatus,
      observacoes: [],
    };
  });
}
