// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import ValidableProps from 'main/features/Validation/types/ValidableProps';
import withValidation from 'main/features/Validation/hocs/withValidation';
import Icon from 'main/components/Icon';
import CalendarInput from './CalendarInput';

import { CalendarData } from './Calendar.type';

import * as S from './styles';

registerLocale('ptBR', ptBR);
interface CalendarProps extends ValidableProps<CalendarData> {
  placeholder?: string;
  range?: boolean;
  maxDate?: Date;
  maxDateRange?: Date;
  requiredInitialDate?: boolean;
  requiredFinalDate?: boolean;
}

const Calendar: React.FC<CalendarProps> = ({
  link,
  placeholder,
  maxDate,
  maxDateRange,
  range = false,
  requiredInitialDate = false,
  requiredFinalDate = false,
  ...props
}) => {
  const { initialDate, finalDate } = link.get().value;

  const startInput = (
    <CalendarInput
      data-testid="calendar-input-initial"
      hasError={!link.get().isValid}
    />
  );

  const endInput = (
    <CalendarInput
      data-testid="calendar-input-final"
      hasError={!link.get().isValid}
    />
  );

  return (
    <>
      <S.FormCalendarContainer {...props}>
        <S.FormCalendarInputContainer>
          {range && (
            <Text
              variant="body01-md"
              color={!link.get().isValid ? 'error' : 'inherit'}
            >
              De{requiredInitialDate && '*'}:
            </Text>
          )}
          <DatePicker
            selected={initialDate}
            placeholderText={placeholder}
            onChange={(date: Date) =>
              link.set({
                value: { initialDate: date, finalDate },
                isValid: true,
                errorMsg: '',
              })
            }
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            dayClassName={() => 'calendar-day'}
            startDate={initialDate}
            endDate={finalDate}
            selectsStart
            customInput={startInput}
            maxDate={maxDate}
          />
        </S.FormCalendarInputContainer>
        {range && (
          <S.FormCalendarInputContainer>
            <Text
              variant="body01-md"
              color={!link.get().isValid ? 'error' : 'inherit'}
            >
              Até{requiredFinalDate && '*'}:
            </Text>
            <DatePicker
              selected={finalDate}
              placeholderText={placeholder}
              onChange={(date: Date) =>
                link.set({
                  value: { initialDate, finalDate: date },
                  isValid: true,
                  errorMsg: '',
                })
              }
              locale="ptBR"
              dateFormat="dd/MM/yyyy"
              dayClassName={() => 'calendar-day'}
              startDate={initialDate}
              endDate={finalDate}
              minDate={initialDate}
              maxDate={maxDateRange}
              selectsEnd
              customInput={endInput}
            />
          </S.FormCalendarInputContainer>
        )}
      </S.FormCalendarContainer>
      {!link.get().isValid && (
        <S.ErrorMsg color="error">
          <Icon name="information" />
          &nbsp;
          {link.get().errorMsg}
        </S.ErrorMsg>
      )}
    </>
  );
};

Calendar.defaultProps = {
  range: false,
  placeholder: '',
};

export default withValidation<CalendarProps, CalendarData>(Calendar, {
  skipBlur: true,
});
