// @ts-nocheck
import { Text, Display, Grid, Button, Card } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import GridCardSkeleton from 'main/components/GridCardSkeleton';
import For from 'main/components/For';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { DEFAULT_NO_DATA_TABLE } from 'main/constants/messages';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import useTransferenciaEntreCertificados from 'previdencia/features/TransferenciaEntreCertificados/hooks/useTransferenciaEntreCertificados';
import CardCobertura from 'previdencia/features/TransferenciaEntreCertificados/components/CardCobertura';
import DisclaimerMsgError from 'previdencia/features/TransferenciaEntreCertificados/components/DisclaimerMsgError';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import * as S from './styles';

import ModalCertificadoInvalido from '../components/ModalCertificadoInvalido';
import TransferenciaWrapper from '../components/TransferenciaWrapper/TransferenciaWrapper';
import ModalAvisoCoberturas from '../components/ModalAvisoCoberturas';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';

const TransferenciaEntreCertificados = () => {
  const { goDadosPlano } = usePrevNavigation();

  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const {
    openModalSimulacao,
    resumoPlanoOrigemSelecionado,
    listaInfoCertificado,
    isLoadingGridCards,
    openModalAvisoCobertura,
    tamanhoLista,
    openModalAlerta,
    emailDefault,
    numerosTelefone,
    validarCoberturas,
    handleConfirmarCobertura,
    handleCloseCoberturas,
    handleCloseModalSimulacao,
    handleCloseModalAlerta,
    redirecionarDadosParticipante,
    selecionarCertificadoDestinoContexto,
  } = useTransferenciaEntreCertificados();

  return (
    <TransferenciaWrapper>
      <Text color="text-light" margin>
        Resumo do plano de origem selecionado:
      </Text>
      <Card>
        <Card.Content padding={[0, 0, 0, 0]}>
          <S.Table
            noHeader
            responsive
            highlightOnHover
            striped
            data={resumoPlanoOrigemSelecionado}
            columns={CONSTS.COLUNAS_RESUMO_PLANO_ORIGEM}
            noDataComponent={DEFAULT_NO_DATA_TABLE}
            style={{
              borderRadius: '10px',
            }}
          />
        </Card.Content>
      </Card>

      <RenderConditional condition={isLoadingGridCards}>
        <Display type="display-block" style={{ margin: '50px 0' }}>
          <GridCardSkeleton quantidadeColunas={4} />
        </Display>
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !isLoadingGridCards,
          !!listaInfoCertificado,
          tamanhoLista > 0,
        ])}
      >
        <Text color="text-light" style={{ margin: '50px 0 20px 0' }}>
          Selecione para qual certificado o cliente deseja transferir:
        </Text>

        <Grid>
          <For each={tryGetValueOrDefault([listaInfoCertificado], [])}>
            {item => (
              <Grid.Item xs={1} lg={1 / 4} key={item?.certificadoNumero}>
                <CardCobertura
                  key={item?.certificadoNumero}
                  data={item}
                  selecionarCertificadoDestinoContexto={
                    selecionarCertificadoDestinoContexto
                  }
                />
              </Grid.Item>
            )}
          </For>
        </Grid>

        <Display style={{ margin: '50px 0 0 0' }}>
          <Button variant="outlined" onClick={goDadosPlano}>
            Voltar
          </Button>

          <Button
            disabled={!featureData?.certificadoDestinoSelecionado}
            onClick={() => validarCoberturas()}
          >
            Avançar
          </Button>
        </Display>
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !isLoadingGridCards,
          tamanhoLista < 1,
        ])}
      >
        <Display style={{ margin: '20px 0' }}>
          <DisclaimerMsgError
            mensagem={CONSTS_TEXT.MSG_ERRO_API.LISTA_INFO_CERTIFICADO}
          />
        </Display>
      </RenderConditional>
      <ModalAlertaContatos
        open={openModalAlerta}
        onClose={handleCloseModalAlerta}
        redirect={() => redirecionarDadosParticipante()}
        dados={{
          email: emailDefault,
          telefone: numerosTelefone,
        }}
      />
      <ModalAvisoCoberturas
        openAvisoCoberturas={openModalAvisoCobertura}
        handleCloseCoberturas={handleCloseCoberturas}
        handleConfirmarCoberturas={handleConfirmarCobertura}
      />
      <ModalCertificadoInvalido
        openModal={openModalSimulacao}
        handleCloseModal={handleCloseModalSimulacao}
      />
    </TransferenciaWrapper>
  );
};

export default TransferenciaEntreCertificados;
