// @ts-nocheck
import { api, IApiResponse } from 'main/services';
import { obterEndpointCalcularBeneficioProtecao } from 'previdencia/config/endpoints';
import {
  ObterCalculoBeneficioPayload,
  ObterCalculoBeneficioResponse,
} from 'previdencia/features/AlterarValorContribuicao/types/CalcularBeneficio';

export const obterCalculoBeneficio = async (
  payload: ObterCalculoBeneficioPayload,
): Promise<ObterCalculoBeneficioResponse | undefined> => {
  const { data } = await api.post<IApiResponse<ObterCalculoBeneficioResponse>>(
    obterEndpointCalcularBeneficioProtecao(),
    payload,
  );
  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false || data?.dados?.entidade === null)
    throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
