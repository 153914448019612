// @ts-nocheck
import React from 'react';
import { Text, Grid } from '@cvp/design-system/react';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaMultifundosPjFeatureData } from 'previdencia/components/TabelaMultifundosPJ/types/ITransferenciaMultifundosPjFeatureData';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';

const ResumoOrigem: React.FC = () => {
  const { featureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();
  const data = tryGetValueOrDefault([featureData?.dataResumo], []);
  return (
    <Grid>
      <Grid.Item>
        <Text variant="body02-sm" margin>
          Sendo:
        </Text>
      </Grid.Item>
      <Grid.Item>
        <Text variant="body02-sm" margin>
          Regular
        </Text>
        <Text variant="body02-sm" margin>
          Adicional
        </Text>
        <Text variant="body02-sm" margin>
          Migrada
        </Text>
      </Grid.Item>
      <Grid.Item>
        <Text variant="body02-sm" margin>
          {tryGetMonetaryValueOrDefault(data[1]?.valor)}
        </Text>
        <Text variant="body02-sm" margin>
          {tryGetMonetaryValueOrDefault(data[2]?.valor)}
        </Text>
        <Text variant="body02-sm" margin>
          {tryGetMonetaryValueOrDefault(data[3]?.valor)}
        </Text>
      </Grid.Item>
    </Grid>
  );
};

export default ResumoOrigem;
