// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { Fundo } from 'previdencia/types/Fundo.type';
import {
  IPayloadTransferirEntreFundos,
  IResponseTransferenciaEntreFundos,
  TDataCard,
} from 'previdencia/types/ITransferirEntreFundos';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';

export const useTransferirEntreFundos = (reservasOrigem: Partial<Fundo>[]) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const { featureData } = usePrevidenciaContext<{
    certificadoDestinoSelecionado: TDataCard;
    manterBeneficio: boolean;
  }>();
  const { loading, response, fetchData } = usePeco<
    IPayloadTransferirEntreFundos,
    IResponseTransferenciaEntreFundos
  >({
    api: {
      operationPath: PECOS.TransferirEntreFundos,
    },
    payload: {
      cpf: cpfCnpj,
      codContaOrigem: numCertificado,
      codContaDestino: tryGetValueOrDefault(
        [featureData?.certificadoDestinoSelecionado.codConta],
        '',
      ),
      indicadorMigraRisco: getTernaryResult(
        !!featureData?.manterBeneficio,
        'S',
        'N',
      ),
      reservasOrigem,
    },
  });

  return {
    loading,
    response: response?.entidade,
    fetchData,
  };
};
