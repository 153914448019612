// @ts-nocheck
import { useMemo, useState } from 'react';
import { Card, Disclaimer } from '@cvp/design-system/react';
import * as S from 'main/components/Table/customStyles';
import RenderConditional from 'main/components/RenderConditional';
import * as P from 'contratosPrestamista/components/prestamistaStyles';
import Icon from 'main/components/Icon';
import { Skeleton as TableSkeleton } from 'main/components/Table';
import masks from 'main/utils/masks';
import TableFilters from 'main/components/Table/TableFilter/TableFilter';
import { IFilterOption } from 'main/components/Table/TableFilter/IFilterOption';
import { useObterSegurosCancelados } from '../hooks/useObterSegurosCancelados';
import { IResponseListaSegurosCancelados } from '../types/IResponseListaSegurosCancelados';
import ModalSendAndValidateCode from '../components/ModalSendAndValidateCode';
import { gerarColunasReversao } from '../components/ColunasListaRetencao';
import * as MSG from '../consts';

const ListagemSegurosCancelados = () => {
  const { data, isLoading } = useObterSegurosCancelados();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [propostaSelecionada, setPropostaSelecionada] =
    useState<IResponseListaSegurosCancelados>(
      {} as IResponseListaSegurosCancelados,
    );

  const filterOptions: IFilterOption[] = [
    {
      key: 'cpfCnpj',
      value: 'CPF/CNPJ',
      type: 'text',
      unmask: masks.cpf.unmask,
    },
    { key: 'numeroPropostaEstipulante', value: 'Proposta', type: 'text' },
    { key: 'nomeSegurado', value: 'Nome', type: 'text' },
  ];

  const colunas = useMemo(() => {
    const handleCancelamentoSelecionado = (
      proposta: IResponseListaSegurosCancelados,
    ) => {
      setPropostaSelecionada(proposta);
      setOpenModal(!openModal);
    };
    return gerarColunasReversao(handleCancelamentoSelecionado);
  }, [openModal]);

  return (
    <>
      <Card style={{ minHeight: 'auto' }}>
        <Card.Content data-testid="tabelaListaPropostas">
          {isLoading ? (
            <TableSkeleton colunas={colunas} />
          ) : (
            <>
              <TableFilters
                filterTextPartial
                dataToFilter={data}
                filterOptions={filterOptions}
              >
                {dataFiltered => (
                  <S.Table
                    noHeader
                    responsive
                    data={dataFiltered}
                    columns={colunas}
                    noDataComponent={
                      data !== undefined ? MSG.FILTRO_SEM_DADOS : ''
                    }
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={{
                      rowsPerPageText: 'Items por página',
                      rangeSeparatorText: 'de',
                    }}
                  />
                )}
              </TableFilters>
              <RenderConditional condition={data === undefined}>
                <P.Disclaimer variant="error">
                  <Disclaimer.Content
                    text={MSG.SEM_DADOS_RETORNO_ENDPOINT}
                    icon={<Icon name="information" />}
                  />
                </P.Disclaimer>
              </RenderConditional>
            </>
          )}
        </Card.Content>
      </Card>
      <ModalSendAndValidateCode
        open={openModal}
        numContrato={propostaSelecionada.numContrato}
        handleClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default ListagemSegurosCancelados;
