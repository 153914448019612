// @ts-nocheck
import React from 'react';
import { Select } from '@cvp/design-system/react';
import ISelectFieldDpsProps from '../interface/ISelectFieldDpsProps';

export const SelectFieldDps: React.FC<ISelectFieldDpsProps> = ({
  label,
  name,
  value,
  options,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <Select
      name={name}
      label={label}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
        setFieldValue(name, e.target.value);
      }}
      onBlur={handleBlur}
      value={value}
      style={{ textAlign: 'left' }}
    >
      {options.map(option => (
        <Select.Item
          key={option.value}
          selected={option.value === value}
          value={option.value}
          text={option.text}
        />
      ))}
    </Select>
  );
};
