// @ts-nocheck
import React from 'react';
import Table from 'main/components/Table';
import { Divider, Text } from '@cvp/design-system/react';
import { Fundo } from 'previdencia/types/Fundo.type';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { levelRisk } from 'previdencia/utils/riskStatus';
import { RiskBarStyle } from 'previdencia/components/stylesPrevidencia';

type TabelasDadosSolicitacaoProps = {
  fundosAlterados: Fundo[] | undefined;
  fundosRedistribuidos: Fundo[] | undefined;
};

const columnsOrigem = [
  {
    name: 'Código do Fundo',
    selector: 'codFundo',
    minWidth: '140px',
  },
  {
    name: 'Fundo de Origem',
    selector: 'descFundo',
    minWidth: '140px',
  },
  {
    name: 'Perfil de Risco',
    selector: 'perfil',
    minWidth: '140px',
  },
  {
    name: 'Valor da Retirada',
    selector: 'valor',
    minWidth: '140px',
  },
];

const columnsDestino = [
  {
    name: 'Código do Fundo',
    selector: 'codFundo',
    minWidth: '140px',
  },
  {
    name: 'Fundo de Destino',
    selector: 'descFundo',
    minWidth: '140px',
  },
  {
    name: 'Perfil de Risco',
    selector: 'perfilRedistribuido',
    minWidth: '140px',
  },
  {
    name: 'Valor da Distribuição',
    selector: 'valorRedistribuido',
    minWidth: '140px',
  },
];

const TabelasDadosSolicitacao: React.FC<TabelasDadosSolicitacaoProps> = ({
  fundosAlterados,
  fundosRedistribuidos,
}) => {
  return (
    <>
      <Text variant="body02-sm" color="primary">
        Fundos de Origem:
      </Text>
      <Table
        data={
          fundosAlterados?.map(({ valor, perfilRisco, ...rest }) => ({
            valor: formatarValorPadraoBrasileiro(valor),
            perfil: <RiskBarStyle risk={levelRisk(perfilRisco)} />,
            ...rest,
          })) ?? []
        }
        columns={columnsOrigem}
        responsive
        noDataComponent="Não há dados para exibir."
      />
      <br />
      <Divider />
      <br />
      <Text variant="body02-sm" color="primary">
        Novos fundos:
      </Text>
      <Table
        data={
          fundosRedistribuidos?.map(({ valor, perfilRisco, ...rest }) => ({
            valorRedistribuido: formatarValorPadraoBrasileiro(valor),
            perfilRedistribuido: <RiskBarStyle risk={levelRisk(perfilRisco)} />,
            ...rest,
          })) ?? []
        }
        columns={columnsDestino}
        responsive
        noDataComponent="Não há dados para exibir."
      />
      <br />
    </>
  );
};

export default TabelasDadosSolicitacao;
