// @ts-nocheck
import { useEffect, useState } from 'react';

import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  IObterContatosDefaultPrevidencia,
  useObterContatosDefaultPrevidencia,
} from 'main/hooks/useObterContatosDefaultPrevidencia';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { usePecoConsultarListaFundosParaResgate } from 'previdencia/hooks/usePecoConsultarListaFundosParaResgate';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { IListarFundosParaResgateResponse } from 'previdencia/types/ConsultaListaFundosParaResgate';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useSolicitacaoResgate = () => {
  const { navigateTo } = usePrevNavigation();

  const { featureData, setFeatureData, clearFeatureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const {
    data: responseObterContatosDefault,
    isFetched: recuperacaoEmailExecutada,
    isFetching: loadingObterContatos,
  } = useObterContatosDefaultPrevidencia();

  const {
    dadosListarFundosParaResgate,
    loadingDadosListarFundosParaResgate,
    listarFundosParaResgate,
  } = usePecoConsultarListaFundosParaResgate();

  const [openModalAlertaContatos, setOpenModalAlertaContatos] = useState(false);

  const { emailDefault, numerosTelefone } = tryGetValueOrDefault(
    [responseObterContatosDefault],
    {} as IObterContatosDefaultPrevidencia,
  );

  const dadosContatos = {
    email: emailDefault,
    telefone: numerosTelefone,
  };

  const isLoadingDados = checkIfSomeItemsAreTrue([
    loadingObterContatos,
    loadingDadosListarFundosParaResgate,
  ]);

  const redirectPrazoDiferimento = (): void =>
    navigateTo('alteracao-prazo-diferimento');

  const fecharAlertaModalContatos = (): void =>
    setOpenModalAlertaContatos(false);

  const abrirModalAlertaContatos = (): void => {
    const permitirAssinatura = checkIfAllItemsAreTrue([
      !loadingObterContatos,
      recuperacaoEmailExecutada,
      !!dadosListarFundosParaResgate?.indicadorPermiteAssinatura,
    ]);

    if (permitirAssinatura) {
      setOpenModalAlertaContatos(true);
    }
  };

  const mensagensCertificado = FACTORY.mensagensCertificadoFactory({
    indicadorPrazoDeDiferimentoExpirado:
      dadosListarFundosParaResgate?.indicadorPrazoDeDiferimentoExpirado,
    mensagensCertificado: dadosListarFundosParaResgate?.mensagensCertificado,
    redirectPrazoDiferimento,
  });

  const isExisteBloqueioSaldo: boolean = checkIfAllItemsAreTrue([
    !!featureData?.dadosListarFundosParaResgate?.saldo?.saldoTotalBloqueado,
    !!mensagensCertificado?.length,
  ]);

  const isResgatePermitido: boolean = checkIfAllItemsAreTrue([
    !dadosListarFundosParaResgate?.indicadorPermiteResgate,
    !!mensagensCertificado?.length,
  ]);

  const isExibirObsCertificado: boolean = checkIfSomeItemsAreTrue([
    isExisteBloqueioSaldo,
    isResgatePermitido,
  ]);

  const omitirColunaMensagens =
    dadosListarFundosParaResgate?.fundosDisponiveis.some(
      fundo => fundo.exibeMensagem,
    );

  const colunas = CONSTS.COLUNAS_SOLICITACAO_RESGATE(!omitirColunaMensagens);

  useEffect(() => {
    abrirModalAlertaContatos();
    setFeatureData({
      ...featureData,
      dadosListarFundosParaResgate: tryGetValueOrDefault(
        [dadosListarFundosParaResgate],
        {} as IListarFundosParaResgateResponse,
      ),
    });
  }, [
    loadingObterContatos,
    recuperacaoEmailExecutada,
    dadosListarFundosParaResgate?.indicadorPermiteAssinatura,
  ]);

  useEffect(() => {
    listarFundosParaResgate();
    clearFeatureData();
  }, []);

  return {
    dadosListarFundosParaResgate,
    isLoadingDados,
    colunas,
    isExibirObsCertificado,
    mensagensCertificado,
    openModalAlertaContatos,
    dadosContatos,
    fecharAlertaModalContatos,
  };
};

export default useSolicitacaoResgate;
