// @ts-nocheck
import { formatarData } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { IDadosFinanciamento } from 'prestamista/features/dadosSeguro/types/IDadosFinanciamento';
import { ResponseObterDadosCertificadoPrestamista } from 'prestamista/types/ICertifucatiPrestamista';

export const dadosFinanciamentoFactory = (
  retornoCertificadoPrestamista:
    | ResponseObterDadosCertificadoPrestamista[]
    | undefined,
): IDadosFinanciamento => {
  const vazio = '-';
  if (
    !retornoCertificadoPrestamista ||
    retornoCertificadoPrestamista.length < 1
  )
    return {
      bemSegurado: vazio,
      dataIniVigencia: vazio,
      dataFimVigencia: vazio,
      quantidadeMesesContrato: vazio,
      dataVencimentoPremioEndosso: vazio,
      valorParcelaPremioEndosso: vazio,
      descricaoPeriodicidadeCobr: vazio,
      descricaoTipoPagamento: vazio,
      valorImpostoSeguroMip: vazio,
    };

  const item = retornoCertificadoPrestamista[0];

  return {
    bemSegurado: tryGetValueOrDefault([item?.bemSegurado], vazio),
    dataIniVigencia: tryGetValueOrDefault(
      [formatarData(item?.dataIniVigencia)],
      vazio,
    ),
    dataFimVigencia: tryGetValueOrDefault(
      [formatarData(item?.dataFimVigencia)],
      vazio,
    ),
    quantidadeMesesContrato: tryGetValueOrDefault<string>(
      [item?.quantidadeMesesContrato?.toString()],
      vazio,
    ),
    dataVencimentoPremioEndosso: tryGetValueOrDefault(
      [item?.numeroDiaVencimento?.toString()],
      vazio,
    ),
    valorParcelaPremioEndosso: tryGetMonetaryValueOrDefault(
      [item?.premioTotal],
      vazio,
    ),
    descricaoPeriodicidadeCobr: tryGetValueOrDefault(
      [item?.descricaoPeriodicidadeCobr],
      vazio,
    ),
    descricaoTipoPagamento: tryGetValueOrDefault(
      [item?.descricaoTipoPagamento],
      vazio,
    ),
    valorImpostoSeguroMip: tryGetMonetaryValueOrDefault(
      item?.valorImpostoSeguroMip,
      vazio,
    ),
  };
};
