// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import * as REQUEST_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblRequest';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as PECO_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblUsePeco';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';

const useFundoInvestimentoDestino =
  (): PECO_TYPES.UseFundoInvestimentoDestino => {
    const { cliente } = useContext(AppContext);

    const { contribuicaoRendaMensal } = useTransferenciaInternaContext();

    const {
      response: responseQuantidadeMaximaFundos,
      loading: loadingQuantidadeMaximaFundos,
    } = usePeco<
      REQUEST_TYPES.IValidarValoresMinimosPayload,
      RESPONSE_TYPES.IValidacaoValoresMinimosResponse
    >({
      api: { operationPath: PECOS.ValidarValoresMinimosTransferenciaVgbl },
      autoFetch: true,
      payload: {
        numeroCertificado: cliente?.numCertificado,
        cpf: cliente?.cpfCnpj,
        valorContribuicao: contribuicaoRendaMensal,
      },
    });

    const quantidadeMaximaFundos =
      responseQuantidadeMaximaFundos?.entidade?.fundosDestinoMaximoPermitido;

    const {
      response: responseListaOfertasVgblConjugado,
      loading: loadingListaOfertasVgblConjugado,
    } = usePeco<
      REQUEST_TYPES.IListaOfertasVgblConjugadoPayload,
      RESPONSE_TYPES.IListaOfertasVgblConjugadoResponse
    >({
      api: { operationPath: PECOS.ListarOfertasVgblConjugado },
      autoFetch: true,
      payload: {
        certificado: cliente?.numCertificado,
        codigoEmpresa: ENUM_TYPES.DefaultValue.CODIGO_EMPRESA,
      },
    });

    return {
      loadingQuantidadeMaximaFundos,
      quantidadeMaximaFundos,
      responseListaOfertasVgblConjugado,
      loadingListaOfertasVgblConjugado,
    };
  };

export default useFundoInvestimentoDestino;
