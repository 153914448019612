// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import { required } from 'main/features/Validation/validations';
import { Input } from 'main/components/form';
import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { TIPO_TELEFONE } from 'main/types/Participante';
import {
  CODIGO_CONSENTIMENTO,
  DadosAlteracao,
  FormAtualizarDadosParticipanteProps,
} from 'previdencia/features/DadosParticipante/types/DadosParticipante';
import * as S from 'previdencia/features/DadosParticipante/components/FormAtualizarDadosParticipante/styles';
import CheckBoxConsentimentos from 'previdencia/features/DadosParticipante/components/CheckBoxConsentimentos/index';
import MensagensAlerta from 'previdencia/features/DadosParticipante/components/MensagensAlerta/index';
import {
  compararAtualizacaoBairro,
  compararAtualizacaoComplemento,
  compararAtualizacaoEndereco,
  compararAtualizacaoNumero,
  procurarPorTipoTelefone,
  tratamentoExcecaoTelefoneVazioGet,
  validarCamposObrigatorios,
  verificarFormulario,
} from 'previdencia/features/DadosParticipante/utils/dadosParticipante';
import useAtualizarConsentimento from 'previdencia/features/DadosParticipante/hooks/useAtualizarConsentimento';
import {
  preencherDadosEmail,
  preencherDadosEndereco,
  preencherDadosTelefone,
  procurarPorSubprocessoMostrarConsentimento,
} from 'previdencia/features/DadosParticipante/factories/dadosDoParticipanteFactory';

const FormAtualizarDadosParticipante: React.FC<
  FormAtualizarDadosParticipanteProps
> = ({
  dadosParticipante,
  dadosCep,
  dadosConsentimentos,
  onCancelar,
  onAtualizar,
  fetchingDados,
  cepLink,
  disabled,
}) => {
  const tipoTelefoneComercial = {
    tipoUm: TIPO_TELEFONE.CELULAR,
    tipoDois: TIPO_TELEFONE.OUTRO,
  };

  const tipoTelefoneResidencial = {
    tipoUm: TIPO_TELEFONE.TELEFONE_DIRETO,
    tipoDois: TIPO_TELEFONE.TELEX,
  };

  const telefoneComercial = procurarPorTipoTelefone(
    dadosParticipante,
    tipoTelefoneComercial,
  );

  const telefoneResidencial = procurarPorTipoTelefone(
    dadosParticipante,
    tipoTelefoneResidencial,
  );

  const [enderecoLink, validateEndereco] = useFieldLink('');
  const [numeroLink, validateNumero] = useFieldLink('');
  const [complementoLink] = useFieldLink('');
  const [bairroLink, validateBairro] = useFieldLink('');

  const [telefoneResidencialLink] = useFieldLink(
    tratamentoExcecaoTelefoneVazioGet(
      telefoneResidencial?.codigoArea,
      telefoneResidencial?.numeroTelefone,
    ),
  );

  const [telefoneComercialLink] = useFieldLink(
    tryGetValueOrDefault([telefoneComercial?.codigoArea], '') +
      tryGetValueOrDefault([telefoneComercial?.numeroTelefone], ''),
  );
  const [emailLink, validateEmail] = useFieldLink(
    tryGetValueOrDefault([dadosParticipante?.email], ''),
  );

  const [
    statusDadosPessoaisOutrosProdutos,
    setStatusDadosPessoaisOutrosProdutos,
  ] = useState(
    procurarPorSubprocessoMostrarConsentimento(
      dadosConsentimentos,
      CODIGO_CONSENTIMENTO.parceiros,
    ),
  );
  const [statusDadosPessoaisParceiros, setStatusDadosPessoaisParceiros] =
    useState(
      procurarPorSubprocessoMostrarConsentimento(
        dadosConsentimentos,
        CODIGO_CONSENTIMENTO.caixa,
      ),
    );
  const [atualizarParceiros, setAtualizarParceiros] = useState(false);

  const {
    data: dadosConsentimentoProdutosCaixa,
    isFetching: fetchingConsentimentoProdutosCaixa,
    refetch: refetchConsentimentoProdutosCaixa,
  } = useAtualizarConsentimento(
    dadosParticipante?.numCpf,
    true,
    statusDadosPessoaisOutrosProdutos,
    onCancelar,
  );

  const camposObrigatorios = {
    cepLink,
    numeroLink,
    enderecoLink,
    bairroLink,
    telefoneComercialLink,
    emailLink,
  };

  const handleUltimoValidarParaSalvar = () => {
    if (
      checkIfAllItemsAreTrue([
        validateEndereco().isValid,
        validateNumero().isValid,
        validateBairro().isValid,
        validateEmail().isValid,
      ])
    ) {
      const dadosEndereco = preencherDadosEndereco(
        dadosParticipante,
        enderecoLink.get().value,
        numeroLink.get().value,
        complementoLink.get().value,
        bairroLink.get().value,
        tryGetValueOrDefault([dadosCep?.cidade, dadosParticipante?.cidade], ''),
        dadosCep,
      );

      const dadosTelefone = preencherDadosTelefone(
        dadosParticipante,
        telefoneResidencialLink.get()?.value,
        telefoneComercialLink.get()?.value,
        tryGetValueOrDefault([telefoneResidencial?.codigoArea], '') +
          tryGetValueOrDefault([telefoneResidencial?.numeroTelefone], ''),
        tryGetValueOrDefault([telefoneComercial?.codigoArea], '') +
          tryGetValueOrDefault([telefoneComercial?.numeroTelefone], ''),
      );

      const dadosEmail = preencherDadosEmail(
        dadosParticipante,
        masks.emailInput.mask(emailLink.get().value),
      );

      const dados: DadosAlteracao = {
        dadosEndereco,
        dadosTelefone,
        dadosEmail,
      };

      if (
        checkIfAllItemsAreTrue([
          statusDadosPessoaisOutrosProdutos !== undefined,
          statusDadosPessoaisOutrosProdutos !==
            procurarPorSubprocessoMostrarConsentimento(
              dadosConsentimentos,
              CODIGO_CONSENTIMENTO.parceiros,
            ),
        ])
      ) {
        refetchConsentimentoProdutosCaixa();
      }

      if (
        checkIfAllItemsAreTrue([
          statusDadosPessoaisParceiros !== undefined,
          statusDadosPessoaisParceiros !==
            procurarPorSubprocessoMostrarConsentimento(
              dadosConsentimentos,
              CODIGO_CONSENTIMENTO.caixa,
            ),
        ])
      ) {
        setAtualizarParceiros(true);
      }

      onAtualizar(dados);
    }
  };

  const {
    isFetching: fetchingConsetimentoParceiros,
    refetch: refetchConsetimentoParceiros,
  } = useAtualizarConsentimento(
    dadosParticipante?.numCpf,
    false,
    statusDadosPessoaisParceiros,
    onCancelar,
  );

  useEffect(() => {
    if (atualizarParceiros) {
      refetchConsetimentoParceiros();
    }
  }, [atualizarParceiros, dadosConsentimentoProdutosCaixa]);

  const handleSalvarDados = () => {
    const dadosEndereco = compararAtualizacaoEndereco(
      dadosCep?.cep,
      dadosCep?.endereco,
      dadosParticipante?.cep,
      dadosParticipante?.endereco,
    );

    const endereco = dadosEndereco;

    const dadosNumero = compararAtualizacaoNumero(
      dadosCep?.cep,
      dadosParticipante?.cep,
      dadosParticipante?.numero,
    );

    const numero = dadosNumero;

    const dadosBairro = compararAtualizacaoBairro(
      dadosCep?.cep,
      dadosCep?.bairro,
      dadosParticipante?.cep,
      dadosParticipante?.bairro,
    );

    const bairro = dadosBairro;

    const dadosComplemento = compararAtualizacaoComplemento(
      dadosCep?.cep,
      dadosParticipante?.cep,
      dadosParticipante?.complemento,
    );

    const complemento = dadosComplemento;

    numeroLink.set({
      value: tryGetValueOrDefault([numero], ''),
      isValid: true,
    });
    bairroLink.set({
      value: tryGetValueOrDefault([bairro], ''),
      isValid: true,
    });
    complementoLink.set({
      value: tryGetValueOrDefault([complemento], ''),
      isValid: true,
    });
    enderecoLink.set({
      value: tryGetValueOrDefault([endereco], ''),
      isValid: true,
    });
  };

  const handleKeyPress = (e: {
    charCode: number;
    preventDefault: () => void;
  }) => {
    if (e.charCode === 32) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    handleSalvarDados();
  }, [dadosCep]);

  return (
    <>
      <>
        <Grid.Item xs={1} md={2 / 3}>
          <Input
            label="Endereço"
            aria-required
            link={enderecoLink}
            validationRules={[required()]}
            placeholder="Digite seu endereço..."
          />
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 3} xl={1 / 3}>
          <Input
            label="Número"
            aria-required
            link={numeroLink}
            validationRules={[required()]}
            placeholder="Digite o número..."
            inputMask={masks.numberOnly}
          />
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 3} xl={1 / 3}>
          <Input
            label="Complemento"
            link={complementoLink}
            placeholder="Digite o complemento..."
          />
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 3} xl={1 / 3}>
          <Input
            label="Bairro"
            aria-required
            link={bairroLink}
            validationRules={[required()]}
            placeholder="Digite seu bairro..."
          />
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 3} xl={1 / 3}>
          <TextField
            label="Cidade"
            placeholder="Digite sua cidade..."
            value={tryGetValueOrDefault(
              [dadosCep?.cidade, dadosParticipante?.cidade],
              '',
            )}
            readOnly
          />
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 9} xl={1 / 9}>
          <TextField
            label="UF"
            placeholder="Digite seu estado..."
            value={tryGetValueOrDefault(
              [dadosCep?.idEstado, dadosParticipante?.uf],
              '',
            )}
            readOnly
          />
        </Grid.Item>

        <Grid.Item xs={1} md={3 / 9} xl={3 / 9}>
          <Input
            label="Telefone Celular"
            aria-required
            placeholder="Digite o número de celular..."
            link={telefoneComercialLink}
            validationRules={[required()]}
            inputMask={masks.commercialPhone}
          />
        </Grid.Item>

        <Grid.Item xs={1} md={2 / 9} xl={2 / 9}>
          <Input
            label="Outro Telefone"
            aria-label="Digite outro número de telefone..."
            placeholder="Digite seu telefone..."
            link={telefoneResidencialLink}
            inputMask={masks.phone}
          />
        </Grid.Item>

        <Grid.Item xs={1} md={3 / 9} xl={3 / 9}>
          <Input
            label="E-mail"
            aria-required
            placeholder="Digite seu e-mail..."
            link={emailLink}
            inputMask={masks.emailInput}
            handleKeyPress={handleKeyPress}
            validationRules={[required()]}
          />
        </Grid.Item>
        <CheckBoxConsentimentos
          statusDadosPessoaisOutrosProdutos={statusDadosPessoaisOutrosProdutos}
          setStatusDadosPessoaisOutrosProdutos={
            setStatusDadosPessoaisOutrosProdutos
          }
          statusDadosPessoaisParceiros={statusDadosPessoaisParceiros}
          setStatusDadosPessoaisParceiros={setStatusDadosPessoaisParceiros}
        />
        <MensagensAlerta />
      </>

      <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
        <S.Actions>
          <>
            <Button
              aria-label="Voltar para consulta dos dados do participante"
              variant="outlined"
              onClick={() => {
                onCancelar();
              }}
            >
              Voltar
            </Button>
            <Button
              aria-label={verificarFormulario(camposObrigatorios)}
              onClick={handleUltimoValidarParaSalvar}
              aria-disabled={validarCamposObrigatorios(camposObrigatorios)}
              disabled={
                validarCamposObrigatorios(camposObrigatorios) || !!disabled
              }
              loading={tryGetValueOrDefault(
                [
                  fetchingDados,
                  fetchingConsentimentoProdutosCaixa,
                  fetchingConsetimentoParceiros,
                ],
                false,
              )}
            >
              Confirmar
            </Button>
          </>
        </S.Actions>
      </Grid.Item>
    </>
  );
};

export default FormAtualizarDadosParticipante;
