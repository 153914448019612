// @ts-nocheck
import { api } from 'main/services';

export const obterArquivoCorp = async (
  codigoIdentificadorUnico?: string,
  nomeArquivo?: string,
) => {
  const { data } = await api.post(
    '/PortalEconomiario/PECO_ObterArquivoCorp',
    {
      codigoIdentificadorUnico,
      nomeArquivo,
    },
    { responseType: 'blob' },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data;
};
export const enviarFormulario = async (
  codigoFop: number,
  numeroVersaoFop: number,
  nomeSolicitante: string,
  emailSolicitante: string,
  metadadosSolicitacao: any,
) => {
  const { data } = await api.post(
    '/PortalEconomiario/PECO_RegistrarSolicitacaoFOP',
    {
      codigoFop,
      numeroVersaoFop,
      nomeSolicitante,
      emailSolicitante,
      metadadosSolicitacao,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data;
};
