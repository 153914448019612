// @ts-nocheck
import { Text, Radio } from '@cvp/design-system/react';

import { FlexContainer } from 'main/styles/GlobalStyle';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const ColunaDescricaoFundo = ({
  row,
  handleFundoSelecionado,
}: SOLICITACAO_RESGATE_TYPES.IColunaDescricaoFundoContribuicaoProps) => {
  return (
    <FlexContainer alignItems="center">
      <Radio
        id={row.codigoFundo}
        checked={row.selecionado}
        onChange={() => {
          handleFundoSelecionado(row.codigoFundo);
        }}
      />
      <Text variant="body02-md" style={{ marginLeft: 12 }}>
        {row.descricaoFundo}
      </Text>
    </FlexContainer>
  );
};

export default ColunaDescricaoFundo;
