// @ts-nocheck
import { Grid } from '@cvp/design-system/react';
import React from 'react';
import * as S from 'sinistro/features/statusSinistro/pages/styles';

const TextoResultado: React.FC = () => {
  return (
    <Grid.Item xs={1}>
      <S.TextResultado variant="body01-sm" color="primary-dark">
        Resultado:
      </S.TextResultado>
    </Grid.Item>
  );
};

export default TextoResultado;
