// @ts-nocheck
import React, { PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import GlobalStyle from 'main/styles/GlobalStyle';
import {
  ThemeProvider as DSThemeProvider,
  useTheme,
} from '@cvp/design-system/react';

export const THEME_NAME = 'jv-caixa';

const ThemeProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <DSThemeProvider theme={THEME_NAME}>
    <StyledThemeProvider theme={useTheme(THEME_NAME)}>
      {children}
    </StyledThemeProvider>
    <GlobalStyle />
  </DSThemeProvider>
);

export default ThemeProvider;
