// @ts-nocheck
import { ICertificadoBeneficioPrevidencia } from 'main/types/CardPrevidencia/ICertificadoBeneficioPrevidencia';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { PREV_REDIRECTS_SCREENS } from 'previdencia/config/redirects';
import { IDadosParticipanteTelefone } from 'main/types/Participante';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import {
  IResponseResgatarBeneficioProtecao,
  LocationType,
} from '../types/BeneficioProtecaoTypes';
import {
  EnumTipoCalculo,
  EnumCobertura,
  EnumPeriodicidade,
  EnumPeriodicidadeAbreviada,
  EnumSituacaoBeneficio,
  EnumIncluirReativar,
} from '../types/enum';
import { MENSAGEM_NAO_SE_APLICA } from '../constants';
import { IResponseCalcularBeneficioProtecao } from '../types/CalcularBaneficioProtecaoTypes';
import { IResponseIncluirReativarBeneficioProtecao } from '../types/ReativarIncluirBeneficioProtecaoTypes';

export const validaFaixaRenda = (
  valor: number | undefined,
  valorMinimo: number | undefined,
  valorMaximo: number | undefined,
) => {
  return !checkIfAllItemsAreTrue([
    Number(valor) / 100 >= tryGetValueOrDefault([valorMinimo], 0),
    Number(valor) / 100 <= tryGetValueOrDefault([valorMaximo], 0),
    Number(valor) / 100 > 0,
  ]);
};

export const mapperTelefone = (
  telefones: IDadosParticipanteTelefone[] | undefined,
  tipoTelefone: string,
) => {
  const telefonePorTipo = telefones?.find(
    telefone => telefone.tipoTelefone === tipoTelefone,
  );
  return `${telefonePorTipo?.codigoArea}${telefonePorTipo?.numeroTelefone}`;
};

export const calcularContribuicoesAtivas = (
  coberturas: ICertificadoBeneficioPrevidencia[] | undefined,
) => {
  const coberturasAtivas = tryGetValueOrDefault([coberturas], []).filter(
    beneficio =>
      beneficio?.situacao.toLocaleLowerCase() === EnumSituacaoBeneficio.ativo,
  );

  const somaValorValorEsperado = coberturasAtivas?.reduce(
    (accumulator, object) => {
      return (
        accumulator +
        parseFloat(tryGetValueOrDefault([object?.valorEsperado], '0'))
      );
    },
    0,
  );
  return somaValorValorEsperado.toString();
};
export const obterValorMinimoAnual = (
  tipoCalculo: string,
  cobertura: number | undefined,
  response: IResponseCalcularBeneficioProtecao | undefined,
) => {
  if (tipoCalculo === EnumTipoCalculo.contribuicao) {
    return cobertura === EnumCobertura.peculio
      ? response?.valoresAnual.vlrMinContribuicaoPeculio
      : response?.valoresAnual.vlrMinContribuicaoPensao;
  }
  return cobertura === EnumCobertura.peculio
    ? response?.valoresAnual.vlrMinBeneficioPeculio
    : response?.valoresAnual.vlrMinBeneficioPensao;
};
export const obterValorMinimo = (
  plano: string,
  tipoCalculo: string,
  cobertura: number | undefined,
  response: IResponseCalcularBeneficioProtecao | undefined,
) => {
  if (plano === EnumPeriodicidade.mensal) {
    if (tipoCalculo === EnumTipoCalculo.contribuicao) {
      return cobertura === EnumCobertura.peculio
        ? response?.valoresMensal.vlrMinContribuicaoPeculio
        : response?.valoresMensal.vlrMinContribuicaoPensao;
    }
    if (tipoCalculo === EnumTipoCalculo.beneficio) {
      return cobertura === EnumCobertura.peculio
        ? response?.valoresMensal.vlrMinBeneficioPeculio
        : response?.valoresMensal.vlrMinBeneficioPensao;
    }
  }
  if (plano === EnumPeriodicidade.anual) {
    return obterValorMinimoAnual(tipoCalculo, cobertura, response);
  }
  return 0;
};

export const obterValorMaximoMensal = (
  tipoCalculo: string,
  cobertura: number | undefined,
  response: IResponseCalcularBeneficioProtecao | undefined,
) => {
  if (tipoCalculo === EnumTipoCalculo.contribuicao) {
    return cobertura === EnumCobertura.peculio
      ? response?.valoresMensal.vlrMaxContribuicaoPeculio
      : response?.valoresMensal.vlrMaxContribuicaoPensao;
  }

  return cobertura === EnumCobertura.peculio
    ? response?.valoresMensal.vlrMaxBeneficioPeculio
    : response?.valoresMensal.vlrMaxBeneficioPensao;
};

export const obterValorMaximo = (
  plano: string,
  tipoCalculo: string,
  cobertura: number | undefined,
  response: IResponseCalcularBeneficioProtecao | undefined,
) => {
  if (plano === EnumPeriodicidade.mensal) {
    return obterValorMaximoMensal(tipoCalculo, cobertura, response);
  }

  if (tipoCalculo === EnumTipoCalculo.contribuicao) {
    return cobertura === EnumCobertura.peculio
      ? response?.valoresAnual.vlrMaxContribuicaoPeculio
      : response?.valoresAnual.vlrMaxContribuicaoPensao;
  }
  if (tipoCalculo === EnumTipoCalculo.beneficio) {
    return cobertura === EnumCobertura.peculio
      ? response?.valoresAnual.vlrMaxBeneficioPeculio
      : response?.valoresAnual.vlrMaxBeneficioPensao;
  }
  return 0;
};

export const obterValorCustomizado = (
  plano: string,
  cobertura: number | undefined,
  response: IResponseCalcularBeneficioProtecao | undefined,
) => {
  if (plano === EnumPeriodicidade.anual) {
    return cobertura === EnumCobertura.pensao
      ? response?.valoresAnual?.vlrCustomizacaoPensao
      : response?.valoresAnual?.vlrCustomizacaoPeculio;
  }

  return cobertura === EnumCobertura.pensao
    ? response?.valoresMensal?.vlrCustomizacaoPensao
    : response?.valoresMensal?.vlrCustomizacaoPeculio;
};

export const obterNomeBeneficio = (codigoBeneficio: number | undefined) => {
  return codigoBeneficio === EnumCobertura.pensao
    ? 'Pensão por prazo certo'
    : 'Pecúlio';
};

export const obterCodigoPeriodicidade = (codigoPeriodicidade: string) => {
  return codigoPeriodicidade.toLocaleLowerCase() === EnumPeriodicidade.mensal
    ? EnumPeriodicidadeAbreviada.mensal
    : EnumPeriodicidadeAbreviada.anual;
};

export const validarAssinaturaDigital = (
  dadosBeneficios: IResponseResgatarBeneficioProtecao | undefined,
) => {
  return checkIfSomeItemsAreTrue([
    dadosBeneficios?.peculio.incluir === EnumIncluirReativar.sim,
    dadosBeneficios?.peculio.reativar === EnumIncluirReativar.sim,
    dadosBeneficios?.pensao.incluir === EnumIncluirReativar.sim,
    dadosBeneficios?.pensao.reativar === EnumIncluirReativar.sim,
  ]);
};

export const validarReativacao = (
  beneficios: IResponseResgatarBeneficioProtecao | undefined,
  cobertura: number | undefined,
): boolean => {
  if (cobertura === EnumCobertura.pensao) {
    return beneficios?.pensao.reativar === EnumIncluirReativar.sim;
  }

  return beneficios?.peculio.reativar === EnumIncluirReativar.sim;
};

export const verificaAssinaturaDigital = (
  reativacao: boolean,
  loadingIncluirBeneficio: boolean,
  responseIncluirBeneficio:
    | IResponseIncluirReativarBeneficioProtecao
    | undefined,
  loadingReativarBeneficio: boolean,
  responseReativarBeneficio:
    | IResponseIncluirReativarBeneficioProtecao
    | undefined,
): boolean => {
  if (reativacao) {
    return !loadingReativarBeneficio && !!responseReativarBeneficio;
  }
  return !loadingIncluirBeneficio && !!responseIncluirBeneficio;
};

export const validaProximaEtapa = (
  loadingCalculo: boolean,
  responseCalculo: IResponseCalcularBeneficioProtecao | undefined,
  flagPersonalizar: boolean,
): boolean => {
  return !loadingCalculo && responseCalculo !== undefined && !flagPersonalizar;
};

export const verificarPermissaoIncluirPeculio = (
  beneficios: IResponseResgatarBeneficioProtecao | undefined,
): boolean => {
  return checkIfSomeItemsAreTrue([
    beneficios?.peculio.incluir === EnumIncluirReativar.sim,
    beneficios?.peculio.reativar === EnumIncluirReativar.sim,
  ]);
};

export const verificarPermissaoIncluirPensao = (
  beneficios: IResponseResgatarBeneficioProtecao | undefined,
): boolean => {
  return checkIfSomeItemsAreTrue([
    beneficios?.pensao.incluir === EnumIncluirReativar.sim,
    beneficios?.pensao.reativar === EnumIncluirReativar.sim,
  ]);
};

export const selecionarCoberturaAvancar = (
  coberturaSelecionada: number | undefined,
  prazo: number | undefined,
): boolean => {
  return checkIfSomeItemsAreTrue([
    coberturaSelecionada === undefined,
    checkIfAllItemsAreTrue([
      coberturaSelecionada === EnumCobertura.pensao,
      !prazo,
    ]),
  ]);
};

export const validaAssinaturaPendente = (state: LocationType) => {
  if (
    checkIfAllItemsAreTrue([
      state?.from === PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES,
      !!state?.data.idRequisicao,
    ])
  ) {
    return state.data.idRequisicao;
  }
  return '';
};

export const mensagemErroResgatarBeneficio = (erro: string | undefined) => {
  return erro?.includes('Manutenção não permitida nesse tipo de produto')
    ? MENSAGEM_NAO_SE_APLICA
    : erro?.replace('FO.000060: ', '');
};

export const validarDisponibilidade = (
  dadosBeneficiosRetorno:
    | IHandleReponseResult<IResponseResgatarBeneficioProtecao>
    | undefined,
  erroDadosBeneficio: string | undefined,
) => {
  if (
    checkIfSomeItemsAreTrue([
      dadosBeneficiosRetorno?.sucessoBFF === false,
      dadosBeneficiosRetorno?.entidade === null,
    ])
  ) {
    if (erroDadosBeneficio?.includes('FO.000060')) {
      return mensagemErroResgatarBeneficio(erroDadosBeneficio);
    }
  }
  return '';
};
