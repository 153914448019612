// @ts-nocheck
import * as Yup from 'yup';
import * as CONSTS from 'seguros/constants/DadosSegurado';
import { TIPO_CERTIFICADO } from 'seguros/constants/Seguros';

export const formikAtualizacaoDadosSeguradoSchema = (
  tipoCertificado: string | undefined,
) => {
  return Yup.object().shape({
    endereco: Yup.string().required(CONSTS.VALIDACAO.CAMPO_OBRIGATORIO),
    bairro: Yup.string().required(CONSTS.VALIDACAO.CAMPO_OBRIGATORIO),
    celular: Yup.string().required(CONSTS.VALIDACAO.CAMPO_OBRIGATORIO),
    telefone: Yup.string().required(CONSTS.VALIDACAO.CAMPO_OBRIGATORIO),
    email:
      tipoCertificado === TIPO_CERTIFICADO.BILHETE
        ? Yup.string()
            .email(CONSTS.VALIDACAO.EMAIL_INVALIDO)
            .max(80, CONSTS.VALIDACAO.EMAIL_MAX_CHAR_BILHETE)
            .required(CONSTS.VALIDACAO.CAMPO_OBRIGATORIO)
        : Yup.string()
            .email(CONSTS.VALIDACAO.EMAIL_INVALIDO)
            .max(40, CONSTS.VALIDACAO.EMAIL_MAX_CHAR_INDIVIDUAL_EMPRESARIAL)
            .required(CONSTS.VALIDACAO.CAMPO_OBRIGATORIO),
    utilizarNomeSocial: Yup.boolean(),
    nomeSocial: Yup.string().when('utilizarNomeSocial', {
      is: true,
      then: Yup.string()
        .required(CONSTS.VALIDACAO.CAMPO_OBRIGATORIO)
        .min(3, 'Informe um nome válido'),
    }),
  });
};
