// @ts-nocheck
import { useContext } from 'react';

import { usePeco } from 'main/hooks/usePeco';
import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS } from 'previdencia/config/endpoints';
import {
  ICalcularResgatePayload,
  ICalcularResgateResponse,
} from 'previdencia/types/CalculoResgate';

export const usePecoCalcularResgate = () => {
  const { cliente } = useContext(AppContext);

  const {
    response: dadosCalcularResgate,
    loading: loadingDadosCalcularResgate,
    fetchData: calcularResgate,
  } = usePeco<Partial<ICalcularResgatePayload>, ICalcularResgateResponse>({
    api: { operationPath: PECOS.CalcularResgate },
    payload: {
      codigoCertificado: tryGetValueOrDefault([cliente.numCertificado], ''),
    },
  });

  return {
    dadosCalcularResgate: dadosCalcularResgate?.entidade,
    loadingDadosCalcularResgate,
    calcularResgate,
  };
};
