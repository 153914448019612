// @ts-nocheck
import { useState } from 'react';
import { useLocation } from 'react-router';
import { Card, Grid, Text, Display, Button } from '@cvp/design-system/react';
import { TextLabel } from 'main/styles/GlobalStyle';
import masks from 'main/utils/masks';
import { formatarData } from 'main/utils';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import { IStatusPropostaDpsAgrupado } from '../types/IResponseListarStatusPropostaDps';
import { usePecoDetalharPropostas } from 'contratosPrestamista/hooks/usePecoDetalharPropostas';
import ModalUploadArquivo from 'contratosPrestamista/features/proposta/components/ModalUploadArquivo';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import TabelaSocios from 'contratosPrestamista/features/dpsEletronicaPostecipado/components/TabelaSocios';
import { DescriptionFormatsFilesAllowed } from 'main/components/form/InputFile';
import * as S from 'contratosPrestamista/features/uploadproposta/components/styles';

const DetalhesStatusPropostaDps = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { state } = useLocation<IStatusPropostaDpsAgrupado>();
  const { responseDetalharProposta, loadingDetalharProposta } =
    usePecoDetalharPropostas({
      codigoDoEstipulante: tryGetValueOrDefault([state?.pesOperador], 0),
      numeroLinhaDoProduto: tryGetValueOrDefault([state?.linhaProduto], 0),
      seqProposta: tryGetValueOrDefault([state?.seqProposta?.toString()], '0'),
      numeroProposta: 0,
    });
  const model = responseDetalharProposta?.entidade;
  return (
    <Display type="display-block">
      {loadingDetalharProposta ? (
        <CardSkeleton />
      ) : (
        <>
          <Card>
            <Card.Content>
              <Grid>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Nome do Segurado</TextLabel>
                    <Text variant="body02-sm" data-testid="nomeSegurado">
                      {model?.nomeSegurado}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      CPF/CNPJ do Segurado
                    </TextLabel>
                    <Text variant="body02-sm" data-testid="cpfCnpjSegurado">
                      {maskCpfCnpj(
                        tryGetValueOrDefault([model?.cpfCnpjSegurado], ''),
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Nº do Certificado</TextLabel>
                    <Text variant="body02-sm" data-testid="numeroCertificado">
                      {model?.numeroCertificado}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Nº da Proposta</TextLabel>
                    <Text variant="body02-sm" data-testid="numeroProposta">
                      {model?.numeroProposta}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Status da Proposta
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="descricaoStatusProposta"
                    >
                      {model?.descricaoStatusProposta}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Agência</TextLabel>
                    <Text variant="body02-sm" data-testid="codigoAgenciaVenda">
                      {model?.codigoAgenciaVenda}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Data Início Vigência
                    </TextLabel>
                    <Text variant="body02-sm" data-testid="dataInicioVigencia">
                      {formatarData(model?.dataInicioVigencia ?? '')}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Data Fim Vigência</TextLabel>
                    <Text variant="body02-sm" data-testid="dataFimVigencia">
                      {formatarData(model?.dataFimVigencia ?? '')}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Valor Prêmio Líquido
                    </TextLabel>
                    <Text variant="body02-sm" data-testid="valorPremioLiquido">
                      {masks.currency.mask(model?.valorPremioLiquido)}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Valor da Importância Segurada
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="valorImportanciaSegurada"
                    >
                      {masks.currency.mask(model?.valorImportanciaSegurada)}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Descrição Periodicidade da Cobrança
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="descricaoPeriodicidadeCobranca"
                    >
                      {model?.descricaoPeriodicidadeCobranca}
                    </Text>
                  </div>
                </Grid.Item>
              </Grid>
            </Card.Content>
          </Card>

          <TabelaSocios
            capitalSegurado={model?.valorImportanciaSegurada}
            dataInicioVigencia={model?.dataInicioVigencia}
            numeroProposta={state?.nossoNumero?.toString()}
            socios={state?.socios as IStatusPropostaDpsAgrupado[]}
            dadosProposta={state}
          />
          <Card>
            <Card.Content>
              <Grid>
                <Grid.Item xs={1 / 3}>
                  <S.InputLabel>*Upload de proposta</S.InputLabel>
                  <DescriptionFormatsFilesAllowed fileSize="5" />
                  <Button onClick={() => setOpenModal(true)}>Upload</Button>
                </Grid.Item>
              </Grid>
              {openModal && (
                <ModalUploadArquivo
                  open={openModal}
                  dpsFlow
                  proposta={{
                    codigoAgenciaVenda: Number(model?.codigoAgenciaVenda),
                    numeroLinhaDoProduto: state.linhaProduto.toString(),
                    codigoDoEstipulante: state.pesOperador.toString(),
                    numeroProposta: state.propostaEstip?.toString(),
                    valorPremioLiquido: state.valorPremio,
                    cpfCnpj: model?.cpfCnpjSegurado ?? '',
                    dataHoraEmissaoDaProposta: state.dataProposta,
                  }}
                  handleClose={setOpenModal}
                />
              )}
            </Card.Content>
          </Card>
        </>
      )}
    </Display>
  );
};

export default DetalhesStatusPropostaDps;
