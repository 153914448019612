// @ts-nocheck
import React, { useEffect } from 'react';
import { Display, Text, Button } from '@cvp/design-system/react';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaMultifundosPjFeatureData } from 'previdencia/components/TabelaMultifundosPJ/types/ITransferenciaMultifundosPjFeatureData';
import { EnumEtapas } from 'previdencia/types/Fundo.type';
import { CONFIRMAR_TRANSFERENCIA } from '../../constants/texts';
import TabelaResumoFundos from '../TabelaResumoFundos/TabelaResumoFundos';
import useConsultarContribuicaoRegular from '../../hooks/useConsultarContribuicaoRegular';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { formatarReservasDestino } from '../../factories/fundosFactory';
import useValidarTransferencia from '../../hooks/useValidarTransferencia';
import getAuthData from 'main/features/Auth/utils/auth';
import { DefaultParamRequest } from 'previdencia/types/enum';
import useConfirmarTransferencia from '../../hooks/useConfirmarTransferencia';
import { ENUM_STATUS_VALIDAR } from '../../types/Enum';
import { confirmarAssinaturaEletronica } from '../../utils/TransferenciaMultifundos';

const ConfirmarTransferencia: React.FC = () => {
  const { featureData, setFeatureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();
  const { user } = getAuthData();

  const { response: responseValidar } = useValidarTransferencia(
    featureData?.idRequisicao,
    user?.agenciaVinculada,
  );

  const {
    response: responseConsultaRegular,
    loading: loadingConsultaRegular,
    fetchData: consultaContribuicaoRegular,
  } = useConsultarContribuicaoRegular(
    tryGetValueOrDefault([featureData?.idRequisicao], ''),
    formatarReservasDestino(featureData?.fundosDestinoSelecionados),
  );
  const {
    response: responseConfirmar,
    loading: loadingConfirmar,
    fetchData: confirmarTransferencia,
  } = useConfirmarTransferencia(featureData?.idRequisicao);

  const proximaEtapa = () => {
    if (
      responseConsultaRegular?.codigoMensagem === DefaultParamRequest.DEFINIR
    ) {
      setFeatureData({
        ...featureData,
        etapa: EnumEtapas.redistribuirValores,
        contribuicaoRegular: responseConsultaRegular,
      });
      return;
    }
    confirmarTransferencia();
  };

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        !!responseConsultaRegular,
        responseConsultaRegular?.codigoMensagem !==
          DefaultParamRequest.CODIGO_ERRO,
      ])
    ) {
      proximaEtapa();
    }
  }, [responseConsultaRegular]);

  useEffect(() => {
    if (confirmarAssinaturaEletronica(responseConfirmar)) {
      setFeatureData({
        ...featureData,
        etapa: EnumEtapas.assinatura,
      });
    }
  }, [responseConfirmar]);
  return (
    <>
      <Text variant="body02-sm" margin>
        {CONFIRMAR_TRANSFERENCIA.INFORMACOES_SALDO}
      </Text>
      <Text variant="body02-sm" margin>
        {CONFIRMAR_TRANSFERENCIA.INFORMACOES_COBRANCA}
      </Text>
      <Text variant="body02-md" color="primary">
        Origem
      </Text>
      <TabelaResumoFundos fundos={featureData?.fundosSelecionados} />
      <Text variant="body02-md" color="primary">
        Destino
      </Text>
      <TabelaResumoFundos fundos={featureData?.fundosDestinoSelecionados} />
      <Display justify="space-between">
        <Button
          variant="outlined"
          onClick={() =>
            setFeatureData({
              ...featureData,
              etapa: EnumEtapas.selecionarFundosDestino,
            })
          }
        >
          Voltar
        </Button>
        <Button
          onClick={() => consultaContribuicaoRegular()}
          loading={checkIfSomeItemsAreTrue([
            loadingConsultaRegular,
            loadingConfirmar,
          ])}
          disabled={checkIfSomeItemsAreTrue([
            !responseValidar?.sucessoBFF,
            !responseValidar?.sucessoGI,
            responseValidar?.entidade?.status?.toLocaleUpperCase() !==
              ENUM_STATUS_VALIDAR.VALIDADO,
          ])}
        >
          Continuar
        </Button>
      </Display>
    </>
  );
};

export default ConfirmarTransferencia;
