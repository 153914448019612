// @ts-nocheck
import { useEffect } from 'react';
import { Text } from '@cvp/design-system/react';
import { SelectItem } from 'main/components/form/Select';
import useFieldLink from 'main/hooks/useFieldLink';
import RenderConditional from 'main/components/RenderConditional';
import Input from 'main/components/form/Input';
import { required } from 'main/features/Validation/validations';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import * as CALC_UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblCalculos';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as VALIDATION_UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblValidacoes';
import * as UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblUtils';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as S from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/CardCoberturaIdeal/styles';

const CardCoberturaIdeal = ({
  subTitulo,
  tipoCobertura,
  prazo = false,
  combinacao = false,
  valorMinimoContribuicao = 0,
  valorMaximoContribuicao = 0,
  fatorCobertura = 0,
  setCoberturasCuidadoGarantido,
  desabilitaInclusaoCobertura = false,
  cuidadosExtrasExistentes,
}: PROPS_TYPES.CardCoberturaIdealProps): React.ReactElement => {
  const coberturaExistente = cuidadosExtrasExistentes(tipoCobertura);

  const [contribuicaoLink] = useFieldLink<string>(
    tryGetValueOrDefault(
      [coberturaExistente?.valorContribuicao.toString()],
      '',
    ),
  );
  const [prazoLink] = useFieldLink<string>(
    tryGetValueOrDefault(
      [coberturaExistente?.temporariedade.toString()],
      ENUM_TYPES.DefaultValue.PRAZO_PENSAO,
    ),
  );

  const valorContribuicao = Number(contribuicaoLink.get().value) / 100;
  const valorPrazo = prazoLink.get().value;

  const legendaContribuicao = `*Valor mínimo ${tryGetMonetaryValueOrDefault(
    valorMinimoContribuicao,
  )} - Valor máximo ${tryGetMonetaryValueOrDefault(valorMaximoContribuicao)}`;

  const validacaoInputContribuicao = {
    min: valorMinimoContribuicao,
    max: valorMaximoContribuicao,
  };

  const resultadoCalculoRendaMensalOuIndenizacao: number =
    CALC_UTILS.calcularRendaMensalOuIndenizacao(
      fatorCobertura,
      valorContribuicao,
    );

  const utilizarBeneficioExistenteOuCalcularIndenizacao = tryGetValueOrDefault(
    [
      coberturaExistente?.valorBeneficio,
      resultadoCalculoRendaMensalOuIndenizacao,
    ],
    0,
  );

  useEffect(() => {
    setCoberturasCuidadoGarantido(prevState => ({
      ...prevState,
      [tipoCobertura]: {
        valorContribuicao: tryGetValueOrDefault(
          [coberturaExistente?.valorContribuicao],
          valorContribuicao,
        ),
        prazo: Number(valorPrazo),
        calculoIndenizacao: utilizarBeneficioExistenteOuCalcularIndenizacao,
      },
    }));
  }, [valorContribuicao, valorPrazo]);

  return (
    <S.Card combinacao={combinacao}>
      <S.BadgeTitle variant="caption-01">Cobertura Ideal</S.BadgeTitle>
      <S.Title variant="body02-md" color="primary">
        Pagamento Mensal
      </S.Title>
      <S.Divider />
      <S.SubTitle variant="body02-md">{subTitulo}</S.SubTitle>
      <S.ContainerInputs combinacao={combinacao}>
        <S.ContainerContribuicaoInput combinacao={combinacao}>
          <Text variant="caption-01">Contribuindo com:</Text>
          <Input
            link={contribuicaoLink}
            inputMask={masks.currencyInput}
            placeholder="R$ 0,00"
            validationRules={[
              required(),
              VALIDATION_UTILS.validateIntervalValue(
                () => '',
                validacaoInputContribuicao,
              ),
            ]}
            legend={legendaContribuicao}
            disabled={desabilitaInclusaoCobertura}
          />
        </S.ContainerContribuicaoInput>
        <RenderConditional condition={!!prazo}>
          <S.ContainerContribuicaoInput combinacao={combinacao}>
            <Text variant="caption-01">Prazo:</Text>

            <S.Select link={prazoLink} placeholder="Selecionar" isDisabled>
              {CONSTS.PRAZO_CUIDADO_GARANTIDO.map(opcaoAno => (
                <SelectItem
                  selected={valorPrazo === opcaoAno.prazo}
                  key={opcaoAno.id}
                  value={opcaoAno.prazo}
                  text={opcaoAno.descricaoPrazo}
                />
              ))}
            </S.Select>
          </S.ContainerContribuicaoInput>
        </RenderConditional>
      </S.ContainerInputs>
      <S.BadgeValor combinacao={combinacao}>
        <Text variant="caption-01">
          {UTILS.exibirDescricaoPorTipoCobertura(tipoCobertura)}
        </Text>
        <Text variant="body02-md">
          <strong>
            {tryGetMonetaryValueOrDefault(
              utilizarBeneficioExistenteOuCalcularIndenizacao,
            )}
          </strong>
        </Text>
        <RenderConditional condition={!!prazo}>
          <Text variant="caption-01">
            por <strong>{Number(valorPrazo)} anos</strong>
          </Text>
        </RenderConditional>
      </S.BadgeValor>
    </S.Card>
  );
};

export default CardCoberturaIdeal;
