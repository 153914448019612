// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  TabContainer,
  TabButton,
  TabContent,
} from 'main/components/Menu/BottomNav/BottomNavTab/styles';
import Icon from 'main/components/Icon';

type BottomNavTabProps = {
  icon?: string;
  label?: string;
  linkTo?: string;
  slideFrom?: string;
  children?: React.ReactNode;

  onChange?(data: any): void;
} & RouteComponentProps;

const defaultProps = {
  icon: '',
  label: '',
  linkTo: '',
  slideFrom: '',
  onChange: () => undefined,
};

const BottomNavTab: React.FunctionComponent<BottomNavTabProps> = ({
  icon,
  label,
  linkTo,
  slideFrom,
  onChange,
  children,
  history,
}) => {
  const [visibility, setvisibility] = useState(false);
  const handleVisibility = (value = true) => {
    setvisibility(value);
    if (onChange) onChange({ visibility: value });
  };
  const contentRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: any) => {
    // Fecha menu se clicar fora do conteúdo
    const current =
      contentRef &&
      contentRef.current &&
      !contentRef.current.contains(event.target);

    if (visibility && current) {
      handleVisibility(false);
    }

    // Fechar menu se clicar em algum link
    if (event.target.href) {
      handleVisibility(false);
    }
  };

  const handleClick = () => {
    if (linkTo) {
      history.push(linkTo);
    } else {
      handleVisibility(true);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <TabContainer className={visibility ? 'visible' : ''}>
      <TabButton onClick={() => handleClick()}>
        <>{visibility ? icon && <Icon name="close" /> : <Icon name={icon} />}</>
        {label}
      </TabButton>
      <div ref={contentRef}>
        <TabContent
          className={`slide-from-${slideFrom} ${visibility && 'show'}`}
        >
          {children}
        </TabContent>
      </div>
    </TabContainer>
  );
};

BottomNavTab.defaultProps = defaultProps;

export default withRouter(BottomNavTab);
