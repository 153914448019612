// @ts-nocheck
import { formatarData } from 'main/utils';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  ICertificadoProdutoSelecionado,
  IStatusSinistroPrestamista,
} from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { LinhaTempoSinistroDados } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';

export function timelinePrestamistaFactory(
  responseStatusSinistroPrestamista: IStatusSinistroPrestamista[] | undefined,
  produtoSelecionado: ICertificadoProdutoSelecionado,
): LinhaTempoSinistroDados[] {
  const eventosTimeline = tryGetValueOrDefault(
    [
      responseStatusSinistroPrestamista?.find(produto =>
        checkIfAllItemsAreTrue([
          produto?.numeroContratoTerceiro?.toString() ===
            produtoSelecionado.certificado,
          String(produto.sinistro) === produtoSelecionado.numeroAvisoSinistro,
        ]),
      )?.timeline?.listaEventosTimelimeSinistro,
    ],
    [],
  );

  return eventosTimeline.map(evento => {
    return {
      data: formatarData(evento.dataHora),
      descricaoStatus: evento.descricao,
      descricaoCobertura: '',
      textoAuxiliarStatus: evento.explicacao,
      observacoes: [],
    } as LinhaTempoSinistroDados;
  });
}
