// @ts-nocheck
export const obterEndPointCertificadosSeguros = (): string =>
  '/PortalEconomiario/PECO_ConsultarSegurosPorCpf';

export const PECOS_VIDA = {
  ConsultarDadosSeguradoBilheteVida: 'PECO_ConsultarDadosSeguradoBilheteVida',
  ConsultarPortabilidades: 'PECO_ConsultarPortabilidades',
  ConsultarPortabilidadeHierarquia: 'PECO_BuscarPortabilidadeHierarquia',
  ConsultarPortabilidadeHierarquiaAgencia: 'PECO_RecuperarHierarquiaAgencia',
  BuscarEnderecoCep: 'PECO_BuscarEnderecoCep',
  ConsultarConsentimento: 'PECO_ConsultarConsentimento',
  AtualizarConsentimento: 'PECO_AtualizarConsentimento',
  ObterInformacoesPagamentoSeguroVida:
    'PECO_ObterInformacoesPagamentoSeguroVida',
  ConsultaSinistro: 'PECO_ConsultaSinistro',
  ConsultarInadimplencia: 'PECO_BuscarInadimplencia',
  ConsultarBeneficiariosSeguros: 'PECO_BeneficiariosSeguro',
  ConsultaCertificadosSegurosVida: 'PECO_ConsultarCertificadoSegurosVida',
  AtualizarDataVencimento: 'PECO_AlterarMetodoPagamento',
  AtualizarDadosSeguradoVIda: 'PECO_AtualizarDadosSeguradoVida',
  ConsultarDadosSeguradoVida: 'PECO_ConsultarDadosSeguradoVida',
  AlterarMetodoPagamento: 'PECO_AlterarMetodoPagamento',
  ConsultarMetodoPagamento: 'PECO_ConsultarMetodoPagamento',
  ConsultarDadosCertificadoSeguro: 'PECO_DadosCertificadoSeguro',
  AssistenciasSeguro: 'PECO_AssistenciasSeguro',
  EmissaoCertificadoVida: 'PECO_EmissaoCertificadoVida',
  SegundaViaApoliceVida: 'PECO_SegundaViaApoliceVida',
  SegundaViaCertificadosSegurosPdf: 'PECO_SegundaViaCertificadosSegurosPdf',
  ValorCapitalQuestionario: 'PECO_ValorCapitalQuestionario',
  EmitirDeclaracaoQuitacaoAnualVida: 'PECO_EmitirDeclaracaoQuitacaoAnualVida',
  EmitirArquivoDeclaracaoQuitacaoAnualVida:
    'PECO_EmitirArquivoDeclaracaoQuitacaoAnualVida',
  ObterArquivoBoletoSeguros: 'PECO_ObterArquivoBoletoPagamentoSeguros',
};
