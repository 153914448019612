// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Text, Display, Radio, Select } from '@cvp/design-system/react';
import ButtonsMudarEtapa from 'previdencia/components/ButtonsMudarEtapa';
import guid from 'main/utils/guid';
import masks from 'main/utils/masks';
import { DynamicFormSelectOption } from 'main/features/prospeccao/types/DesignSystemSelectProps';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { Divider, SelectContainer } from '../styles';
import { TEXTO_PECULIO, TEXTO_PENSAO } from '../../constants';
import { IResponseResgatarBeneficioProtecao } from '../../types/BeneficioProtecaoTypes';
import {
  selecionarCoberturaAvancar,
  verificarPermissaoIncluirPeculio,
  verificarPermissaoIncluirPensao,
} from '../../utils/beneficioDeProtecao';
import usePrazosBeneficioProtecao from '../../hooks/usePrazosBeneficioProtecao';
import { montarListaPrazos } from '../../factories/beneficioDeProtecaoFactory';
import { EnumCobertura, EnumIncluirReativar } from '../../types/enum';

type SelecionarCoberturaProps = {
  faixaRenda: string;
  beneficios: IResponseResgatarBeneficioProtecao | undefined;
  coberturaSelecionada: number | undefined;
  prazo: number | undefined;
  loading: boolean;
  setPrazo: (value: number | undefined) => void;
  setCobertura: (value: number) => void;
  proximaEtapa: () => void;
  etapaAnterior: () => void;
};
const SelecionarCobertura: React.FC<SelecionarCoberturaProps> = ({
  faixaRenda,
  beneficios,
  coberturaSelecionada,
  prazo,
  loading,
  setPrazo,
  setCobertura,
  proximaEtapa,
  etapaAnterior,
}) => {
  const [listaPrazos, setListaPrazos] = useState<
    {
      cod: string;
      label: string;
    }[]
  >([]);
  const incluirPensao = verificarPermissaoIncluirPensao(beneficios);
  const incluirPeculio = verificarPermissaoIncluirPeculio(beneficios);

  const { response, loading: loadingPrazos } = usePrazosBeneficioProtecao();
  const responsePrazos = response?.entidade;

  useEffect(() => {
    if (responsePrazos) {
      const tempoMinimo =
        beneficios?.pensao.reativar === EnumIncluirReativar.sim
          ? tryGetValueOrDefault([beneficios?.pensao.prazo], 0)
          : responsePrazos.tempoMinimo;

      const tempoMaximo =
        beneficios?.pensao.reativar === EnumIncluirReativar.sim
          ? tryGetValueOrDefault([beneficios?.pensao.prazo], 0)
          : responsePrazos.tempoMaximo;

      setListaPrazos(montarListaPrazos(tempoMinimo, tempoMaximo));
    }
  }, [responsePrazos, loadingPrazos]);

  const disableAvancar = selecionarCoberturaAvancar(
    coberturaSelecionada,
    prazo,
  );

  if (checkIfSomeItemsAreTrue([loading, loadingPrazos])) {
    return <SkeletonLoading lines={6} />;
  }
  return (
    <>
      <Text variant="body01-lg" color="text" margin>
        Selecionar Cobertura
      </Text>
      <Text variant="body02-sm" color="text" margin>
        Selecione a cobertura que o cliente deseja incluir.
      </Text>
      <Display justify="center">
        <Text variant="body02-sm" color="text" margin>
          Faixa de Renda: <b>{masks.currency.mask(faixaRenda)}</b>
        </Text>
      </Display>
      <RenderConditional condition={incluirPensao}>
        <Display alignItems="center">
          <Radio
            name="cobertura"
            checked={coberturaSelecionada === EnumCobertura.pensao}
            onChange={() => setCobertura(EnumCobertura.pensao)}
          />
          <Text variant="body02-sm" color="text" margin>
            <b>Pensão por prazo certo</b>
          </Text>
        </Display>
        <Text variant="body02-sm" color="text" margin>
          {TEXTO_PENSAO}
        </Text>
        <RenderConditional
          condition={coberturaSelecionada === EnumCobertura.pensao}
        >
          <Text variant="body02-sm" color="text" margin>
            <b>
              Por quanto tempo os beneficiários do titular receberão a pensão
            </b>
          </Text>
          <SelectContainer>
            <Select
              placeholder="Escolha uma opção"
              link={prazo}
              onChange={({
                target: { value },
              }: React.ChangeEvent<DynamicFormSelectOption>) => {
                setPrazo(parseInt(value, 10));
              }}
            >
              {listaPrazos?.map((item: { cod: string; label: string }) => (
                <Select.Item
                  key={guid()}
                  value={item.cod}
                  text={item.label}
                  selected={prazo?.toString() === item.cod}
                />
              ))}
            </Select>
          </SelectContainer>
        </RenderConditional>
        <RenderConditional condition={incluirPensao && !incluirPeculio}>
          <Divider />
        </RenderConditional>
      </RenderConditional>

      <RenderConditional condition={incluirPeculio}>
        <Display alignItems="center">
          <Radio
            name="cobertura"
            checked={coberturaSelecionada === EnumCobertura.peculio}
            onChange={() => setCobertura(EnumCobertura.peculio)}
          />
          <Text variant="body02-sm" color="text" margin>
            <b>Pecúlio</b>
          </Text>
        </Display>
        <Text variant="body02-sm" color="text" margin>
          {TEXTO_PECULIO}
        </Text>
      </RenderConditional>

      <ButtonsMudarEtapa
        etapaAnterior={etapaAnterior}
        proximaEtapa={proximaEtapa}
        disabled={disableAvancar}
        buttonLabel="Avançar"
      />
    </>
  );
};
export default SelecionarCobertura;
