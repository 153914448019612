// @ts-nocheck
import { FormikProps } from 'formik';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { removeEmptySpaceAndSpecialCharacters } from 'main/utils/string';

/**
 * Retorna um objeto contendo informações de erro para um campo específico em um objeto Formik.
 *
 * @template T - O tipo do objeto Formik.
 * @param {FormikProps<T>} formik - O objeto Formik.
 * @param {string} field - O nome do campo para o qual deseja verificar os erros.
 * @returns {{ error: FormikErrors<T>[keyof T], errorMessage: FormikErrors<T>[keyof T] }} - Um objeto contendo informações de erro para o campo especificado.
 */
export function showErrorInput<T>(formik: FormikProps<T>, field: string) {
  const formikErrorField = formik.errors[field as keyof T];

  if (
    checkIfAllItemsAreTrue([
      !!formik.touched[field as keyof T],
      !!formikErrorField,
    ])
  ) {
    return {
      error: formikErrorField,
      errorMessage: formikErrorField,
    };
  }

  return {};
}

/**
 * Formata o nome de um arquivo removendo espaços vazios e caracteres especiais,
 * mantendo apenas o último ponto (.) e a extensão do arquivo.
 *
 * @param {string} nomeArquivo - O nome do arquivo a ser formatado.
 * @returns {string} - O nome do arquivo formatado com apenas o último ponto (.) e a extensão.
 */
export const formatarNomeArquivo = (nomeArquivo: string): string => {
  const lastDotIndex = nomeArquivo.lastIndexOf('.');

  const nomeArquivoFormatado = removeEmptySpaceAndSpecialCharacters(
    nomeArquivo.substring(0, lastDotIndex),
  );

  const extensao = nomeArquivo.substring(lastDotIndex + 1);

  return `${nomeArquivoFormatado}.${extensao}`;
};
