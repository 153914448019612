// @ts-nocheck
import styled from 'styled-components/macro';

export const Ul = styled.ul({
  marginBottom: '25px',
});

export const Li = styled.li({
  textAlign: 'left',
});
