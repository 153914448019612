// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { FormikProps, useFormik } from 'formik';
import { AppContext } from 'main/contexts/AppContext';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { CardResumoProduto } from 'seguros/components';
import * as API from 'seguros/hooks/useDadosSegurado';
import * as UTILS from 'seguros/utils/DadosSeguradoUtils';
import * as CONSTS from 'seguros/constants/DadosSegurado';
import * as FACTORY from 'seguros/factories/DadosSeguradoFactory';
import * as RESPONSE_TYPES from 'seguros/types/DadosSeguradoResponse';
import * as SEGURADO_TYPES from 'seguros/types/DadosSegurado';
import { formikAtualizacaoDadosSeguradoSchema } from 'seguros/factories/formikAtualizacaoDadosSeguradoSchema';
import DadosSeguradoForm from './DadosSeguradoForm';
import { useManterNomeSocial } from 'consultaCliente/features/listaCardProduto/hooks/useManterNomeSocial';

const AtualizacaoDadosSegurado = () => {
  const history = useHistory();

  const {
    nomeSocial,
    cliente: { cpfCnpj },
    clienteVida: { tipoCertificado, nomeSegurado },
  } = useContext(AppContext);

  const {
    incluir,
    excluir,
    alteracao,
    loading: loadingManterNomeSocial,
  } = useManterNomeSocial();

  const {
    state: { informacoesSegurado, dataNascimentoPorTipoCertificado },
  } = useLocation<{
    informacoesSegurado: RESPONSE_TYPES.IDadosSeguradoResponse;
    dataNascimentoPorTipoCertificado: string;
  }>();

  const numeroOcorrencia =
    informacoesSegurado?.dadosEnderecoSegurado.ocorrenciaEndereco;

  const { dadosConsentimento } = API.useConsultarConsentimento(
    CONSTS.DEFAULT_VALUE.SUB_PROCESSO_CAIXA,
  );

  const { atualizarDadosSegurado, loadingDadosSeguradoAtualizados } =
    API.useAtualizarDadosSegurado();

  const initialStateFormik = FACTORY.initialFormikStateFactory({
    ...informacoesSegurado,
    nomeSocial,
    utilizarNomeSocial: !!nomeSocial,
  });

  const [valoresFormulario, setValoresFormulario] =
    useState<SEGURADO_TYPES.IFormikValues>(initialStateFormik);

  const { fetchData: atualizarConsentimentoProdutosCaixa } =
    API.useAtualizarConsentimento(
      valoresFormulario.dadosPessoaisOutrosProdutos,
    );

  const { fetchData: atualizarConsentimentoParceiros } =
    API.useAtualizarConsentimento(valoresFormulario.dadosPessoaisParceiros);

  const dadosPessoaisOutrosProdutos = UTILS.permitirConsentimento(
    dadosConsentimento?.entidade,
    CONSTS.DEFAULT_VALUE.SUB_PROCESSO_PARCEIROS,
  );

  const dadosPessoaisParceiros = UTILS.permitirConsentimento(
    dadosConsentimento?.entidade,
    CONSTS.DEFAULT_VALUE.SUB_PROCESSO_CAIXA,
  );

  const [cidadeValida, setCidadeValida] = useState<boolean>(false);

  const isFirstRender = useRef(true);
  const [cepChanged, setCepChanged] = useState(false);

  const handleManterNomeSocial = async (
    formValues: SEGURADO_TYPES.IFormikValues,
  ) => {
    const nomeSocialAtual = formValues.nomeSocial;

    const shouldAlterar = checkIfAllItemsAreTrue([
      formValues.utilizarNomeSocial,
      nomeSocial !== nomeSocialAtual,
    ]);
    const shouldIncluir = checkIfAllItemsAreTrue([
      formValues.utilizarNomeSocial,
      !nomeSocial,
      !!nomeSocialAtual,
    ]);

    const shouldExcluir = checkIfAllItemsAreTrue([
      !formValues.utilizarNomeSocial,
      !!nomeSocialAtual,
    ]);

    if (shouldIncluir) {
      await incluir(cpfCnpj, nomeSocialAtual);
    } else if (shouldAlterar) {
      await alteracao(cpfCnpj, nomeSocialAtual);
    } else if (shouldExcluir) {
      await excluir(cpfCnpj, nomeSocialAtual);
    }
  };

  const atualizarDados = async (
    values: SEGURADO_TYPES.IFormikValues,
    formikProps: FormikProps<SEGURADO_TYPES.IFormikValues>,
  ): Promise<void> => {
    if (
      formikProps.values.dadosPessoaisOutrosProdutos !==
      dadosPessoaisOutrosProdutos
    ) {
      atualizarConsentimentoProdutosCaixa();
    }

    if (formikProps.values.dadosPessoaisParceiros !== dadosPessoaisParceiros) {
      atualizarConsentimentoParceiros();
    }

    const retornoAtualizacaoDados = await atualizarDadosSegurado({
      ...values,
      ocorrencia: numeroOcorrencia,
    });

    await handleManterNomeSocial(values);
    setValoresFormulario(values);

    if (retornoAtualizacaoDados?.entidade) {
      history.push({
        pathname: `/cliente/produtos/vida/dados-segurado`,
        state: {
          msgAtualizacaoDados:
            retornoAtualizacaoDados?.mensagens?.[0]?.descricao,
        },
      });
    }
  };

  const formik: FormikProps<SEGURADO_TYPES.IFormikValues> =
    useFormik<SEGURADO_TYPES.IFormikValues>({
      initialValues: initialStateFormik,
      onSubmit: values => {
        atualizarDados(values, formik);
      },
      validationSchema: formikAtualizacaoDadosSeguradoSchema(tipoCertificado),
      validateOnMount: true,
      validate(values) {
        if (!cepChanged) return {};
        const msgError = {} as SEGURADO_TYPES.MsgInputFormikError;

        const cep = UTILS.customValidationCepFormik(values, cidadeValida);

        if (cep) msgError.cep = cep;

        return msgError;
      },
    });

  const { dadosEnderecoPeloCep, obterEnderecoPeloCep } = API.useEnderecoPeloCep(
    {
      cep: formik.values.cep,
    },
  );

  const obterNovoEndereco = async (): Promise<void> => {
    if (
      formik.values.cep.length === CONSTS.DEFAULT_VALUE.QTD_MAXIMA_DIGITOS_CEP
    ) {
      const novoEnderecoPeloCepPesquisado = await obterEnderecoPeloCep();

      setCidadeValida(!!novoEnderecoPeloCepPesquisado?.entidade?.cidade);
    }
  };

  const definirNovosValoresEnderecoPeloCepPesquisado = (): void => {
    if (cidadeValida) {
      formik.setValues({
        ...formik.values,
        endereco: tryGetValueOrDefault(
          [dadosEnderecoPeloCep?.entidade?.logradouro],
          '',
        ),
        bairro: tryGetValueOrDefault(
          [dadosEnderecoPeloCep?.entidade?.bairro],
          '',
        ),
        cidade: tryGetValueOrDefault(
          [dadosEnderecoPeloCep?.entidade?.cidade],
          '',
        ),
        uf: tryGetValueOrDefault([dadosEnderecoPeloCep?.entidade?.estado], ''),
      });
    }
  };

  const definirConsentimentoPadrao = (): void => {
    if (dadosConsentimento) {
      formik.setValues({
        ...formik.values,
        dadosPessoaisOutrosProdutos,
        dadosPessoaisParceiros,
      });
    }
  };

  const limparValoresEnderecoSeCepInvalido = (): void => {
    if (
      checkIfAllItemsAreTrue([
        !cidadeValida,
        formik.errors.cep === CONSTS.VALIDACAO.CEP_INVALIDO,
      ])
    ) {
      formik.setValues({
        ...formik.values,
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
      });
    }
  };

  const lidarComMudancaDoCep = () => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (formik.values.cep.length === 8) {
      obterNovoEndereco();
    }
  };
  const handleCepChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCepChanged(true);
    formik.setFieldValue('cep', masks.cep.unmask(value));
  };

  useEffect(definirConsentimentoPadrao, [dadosConsentimento]);

  useEffect(() => {
    lidarComMudancaDoCep();
  }, [formik.values.cep]);

  useEffect(() => {
    definirNovosValoresEnderecoPeloCepPesquisado();
    limparValoresEnderecoSeCepInvalido();
  }, [cidadeValida, dadosEnderecoPeloCep?.entidade?.endereco]);

  return (
    <>
      <CardResumoProduto />

      <DadosSeguradoForm
        formik={formik}
        handleCepChange={handleCepChange}
        masks={masks}
        loadingDadosSeguradoAtualizados={checkIfSomeItemsAreTrue([
          loadingDadosSeguradoAtualizados,
          loadingManterNomeSocial,
        ])}
        cpfCnpj={cpfCnpj}
        loadingDadosConsentimentoAtualizadosProdutosCaixa={false}
        loadingDadosConsentimentoAtualizadosParceiros={false}
        nomeSegurado={nomeSegurado}
        dataNascimentoPorTipoCertificado={dataNascimentoPorTipoCertificado}
      />
    </>
  );
};

export default AtualizacaoDadosSegurado;
