// @ts-nocheck
import styled from 'styled-components/macro';

import {
  IconButton as IconButtonBase,
  Disclaimer as DisclaimerBase,
} from '@cvp/design-system/react';

export const IconButton = styled(IconButtonBase)({
  margin: '3px 0',
});

export const ActionsButton = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

export const Disclaimer = styled(DisclaimerBase)({
  padding: '12px 0 12px 16px',
  button: {
    opacity: 0,
  },
  svg: {
    width: '22px',
    height: '22px',
  },
});
