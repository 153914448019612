// @ts-nocheck
import masks from 'main/utils/masks';
import { PARAMETROS_DPS } from '../../../constants/parametrosDps';
import { IDadosBasicoProposta } from 'contratosPrestamista/types/IDadosBasicoProposta';

export const construirPayloadVerificarNecessidadeDpsPF = ({
  capitalSegurado,
  cpf,
  numeroAgencia,
  numeroProposta,
  valorAcumulo,
}: IDadosBasicoProposta) => ({
  codigoSegmento: PARAMETROS_DPS.CODIGO_SEGMENTO_PF,
  codigoProdutoOrigem: PARAMETROS_DPS.CODIGO_PRODUTO_ORIGEM_PF,
  numeroProposta: masks.numberOnly.mask(numeroProposta),
  cpf,
  dataHoraValidadeProposta: new Date().toISOString(),
  numeroAgencia: Number(numeroAgencia),
  dadosValidacao: {
    valorAcumulo,
    importanciaSegurada: Number(capitalSegurado),
  },
});

export const construirPayloadVerificarNecessidadeDpsPJ = ({
  capitalSegurado,
  cpf,
  numeroAgencia,
  numeroProposta,
  valorAcumulo,
}: IDadosBasicoProposta) => ({
  codigoSegmento: PARAMETROS_DPS.CODIGO_SEGMENTO_PJ,
  codigoProdutoOrigem: PARAMETROS_DPS.CODIGO_PRODUTO_ORIGEM_PJ,
  numeroProposta: masks.numberOnly.mask(numeroProposta),
  cpf,
  dataHoraValidadeProposta: new Date().toISOString(),
  numeroAgencia: Number(numeroAgencia),
  dadosValidacao: {
    valorAcumulo,
    importanciaSegurada: Number(capitalSegurado),
  },
});
