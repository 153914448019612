// @ts-nocheck
import React from 'react';
import { Display, Text, Button } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const ModalInformeCliente: React.FC<
  PROPS_TYPES.ModalTransferenciaVgblProps
> = ({ toggleModal, handleModal }) => {
  return (
    <TRANSF_VGBL_STYLES.ModalTransferenciaInterna
      show={toggleModal}
      handleClose={handleModal}
      size={ENUM_TYPES.ModalInfoPredefinitionSize.MD}
      data-testid="modalAvisoQuantidadeMinima"
    >
      <TRANSF_VGBL_STYLES.Text margin color="secondary" fontWeight="bold">
        <Icon name="warning" /> Importante!
      </TRANSF_VGBL_STYLES.Text>
      <Text variant="body02-sm" margin>
        Informe ao cliente o valor da cobertura selecionada e o novo valor da
        parcela.
      </Text>
      <Display justify="center">
        <Button variant="secondary" onClick={handleModal}>
          Certo, entendi!
        </Button>
      </Display>
    </TRANSF_VGBL_STYLES.ModalTransferenciaInterna>
  );
};

export default ModalInformeCliente;
