// @ts-nocheck
import React from 'react';
import { Accordion, Divider } from '@cvp/design-system/react';
import { ICardProdutoVida } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoVida';
import {
  EnumStatusCard,
  EnumStatusCardVida,
} from 'consultaCliente/types/ICardsApresentacao';
import RenderConditional from 'main/components/RenderConditional';
import GridCardSkeleton from '../../../../../../main/components/GridCardSkeleton';
import ListaSeguros from './ListaSeguros';
import TextoInformativoSemProdutos from '../../TextoInformativoSemProdutos/TextoInformativoSemProdutos';

type SectionGridCardVidaProps = {
  loadingVida: boolean;
  produtosVida?: ICardProdutoVida[];
  cpfCnpj: string;
};

const SectionGridCardVida: React.FunctionComponent<
  SectionGridCardVidaProps
> = ({ loadingVida, produtosVida, cpfCnpj }) => {
  const getSeguros = (status: string) => {
    if (status === EnumStatusCard.ativos) {
      return produtosVida?.filter(
        item =>
          item.situacaoRegistro?.toLowerCase() === EnumStatusCardVida.ativo,
      );
    }
    return produtosVida?.filter(
      item => item.situacaoRegistro?.toLowerCase() !== EnumStatusCardVida.ativo,
    );
  };

  if (loadingVida) {
    return <GridCardSkeleton quantidadeColunas={4} />;
  }
  return (
    <>
      {!loadingVida && (produtosVida || []).length === 0 && (
        <TextoInformativoSemProdutos />
      )}
      {!loadingVida && (produtosVida || []).length > 0 && (
        <>
          <ListaSeguros
            cpfCnpj={cpfCnpj}
            seguros={getSeguros(EnumStatusCard.ativos)}
            itensPorPagina={12}
          />
          <Divider />
          <RenderConditional
            condition={(getSeguros(EnumStatusCard.inativos) || []).length > 0}
          >
            <Accordion
              open={(getSeguros(EnumStatusCard.ativos) || []).length === 0}
            >
              <Accordion.Item title="Inativos" key="inativos">
                <ListaSeguros
                  cpfCnpj={cpfCnpj}
                  seguros={getSeguros(EnumStatusCard.inativos)}
                  itensPorPagina={12}
                />
              </Accordion.Item>
            </Accordion>
          </RenderConditional>
        </>
      )}
    </>
  );
};

SectionGridCardVida.defaultProps = {
  produtosVida: [],
};

export default SectionGridCardVida;
