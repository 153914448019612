// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';

import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import {
  IRequestVGBLListarOfertas,
  IResponseVGBLListarOfertas,
} from '../types/VGBLListarOfertas';

export const usePecoVgblListarOfertas = (familia = 0) => {
  const { cliente } = useContext(AppContext);
  const { response, loading, invalidateCache } = usePeco<
    IRequestVGBLListarOfertas,
    IResponseVGBLListarOfertas
  >({
    api: { operationPath: PECOS.ListarOfertasVgblConjugado },
    payload: {
      certificado: cliente?.numCertificado,
      familia,
    },
    autoFetch: true,
  });

  return {
    loading,
    response: response?.entidade,
    invalidateCacheVgblListarOfertas: () =>
      invalidateCache(PECOS.ListarOfertasVgblConjugado),
  };
};
