// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { AlertProps } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as S from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Alert/styles';

const Alert = ({ icon, message }: AlertProps): React.ReactElement => {
  return (
    <S.ContainerAlert>
      <Icon name={icon} />
      <Text variant="body02-sm" color="text">
        {message}
      </Text>
    </S.ContainerAlert>
  );
};

export default Alert;
