// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  TabelaSemDados,
  CarteiraUsuario,
} from 'previdencia/features/PosicaoConsolidada/types/enum';
import { columns } from 'previdencia/features/PosicaoConsolidada/utils/tabelaColunas';
import {
  extratoCarteira,
  totalCarteira,
} from 'previdencia/features/PosicaoConsolidada/factories/extratoUnificadoFactory';
import BotaoLamina from 'previdencia/features/PosicaoConsolidada/components/BotaoLamina';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { TabelaCarteiraInvestimentoProps } from 'previdencia/features/PosicaoConsolidada/types/TabelaCarteiraInvestimentoProps';
import * as S from 'previdencia/features/PosicaoConsolidada/components/TabelaCarteiraInvestimento/styles';
import { ordenarItens } from 'main/utils/ordenar';
import { DocumentoFundos } from 'previdencia/features/PosicaoConsolidada/types/ResponseExtratoUnificado';

const TabelaCarteiraInvestimento: React.FC<TabelaCarteiraInvestimentoProps> = ({
  dadosLamina,
  setDadosLamina,
  extratoUnificado,
  isFetching,
  refetch,
}) => {
  const ordenarDocumentos = (
    documentos: DocumentoFundos[],
  ): DocumentoFundos[] => {
    const documentosOrdenados = documentos.map(doc => ({
      ...doc,
      ordem: doc.tipoArquivoFundo.toLocaleLowerCase() === 'lamina' ? 1 : 2,
    }));
    return documentosOrdenados.sort(ordenarItens);
  };

  const dadosCarteiraInvestimentos = () => {
    const carteiraInvestimentosUsuario = extratoCarteira(
      extratoUnificado,
    )?.concat(totalCarteira(extratoUnificado));

    return carteiraInvestimentosUsuario?.map(item => ({
      ...item,
      contaId:
        item.contaId === CarteiraUsuario.CARTEIRA ? (
          item.contaId
        ) : (
          <S.ContainerCertificado>
            <S.IndicativoCor corFundo={item.corFundo} />
            <div>{item.contaId}</div>
          </S.ContainerCertificado>
        ),
      saldoTotal: formatarValorPadraoBrasileiro(item.saldoTotal),
      modalidadeTributacao: item.modalidadeTributacao.map(modalidade => (
        <S.WrapperModalidade key={item.contaId}>
          {modalidade}
        </S.WrapperModalidade>
      )),
      documentos: ordenarDocumentos(item.documentos).map(doc => (
        <>
          <RenderConditional condition={!doc.tipoArquivoFundo}>
            <Text variant="body02-sm">Indisponível</Text>
          </RenderConditional>

          <RenderConditional condition={!!doc.tipoArquivoFundo}>
            <S.ButtonAction
              key={doc.arquivoId}
              title={doc.tipoArquivoFundo}
              onClick={() => {
                setDadosLamina(item);
                refetch({
                  NumeroCertificado: tryGetValueOrDefault([item.contaId], ''),
                  TipoArquivo: doc.tipoArquivoFundo.toLowerCase(),
                });
              }}
            >
              <BotaoLamina
                doc={doc}
                item={item}
                dados={dadosLamina}
                isFetch={isFetching}
              />
            </S.ButtonAction>
          </RenderConditional>
        </>
      )),
    }));
  };

  const colunas = columns.map(col => ({
    ...col,
    cell: (row: any) => (
      <S.CelulaCarteiraUsuario
        contaId={row.contaId === CarteiraUsuario.CARTEIRA}
      >
        {row[col.selector]}
      </S.CelulaCarteiraUsuario>
    ),
  }));

  return (
    <S.Table
      data={tryGetValueOrDefault([dadosCarteiraInvestimentos()], [])}
      columns={colunas}
      responsive
      noDataComponent={TabelaSemDados.NO_DATA_COMPONENT}
      striped
      noHeader
      data-testid="tabela"
    />
  );
};

export default TabelaCarteiraInvestimento;
