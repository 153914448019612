// @ts-nocheck
export const initialValues = {
  nomeParticipante: '',
  cpf: '',
  endereco: '',
  telefones: '',
  pgbl: '',
  vgbl: '',
  email: '',
  deAcordoDadosAtualizados: '',
  certificadoOrigem: '',
  dadosTransferenciaRendaFixa: [
    {
      familiaFundoOrigem: '',
      familiaFundoDestino: '',
      total: '',
      parcial: '',
      valor: '',
    },
  ],
  dadosTransferenciaRendaVarivavel: [
    {
      familiaFundoOrigem: '',
      familiaFundoDestino: '',
      total: '',
      parcial: '',
      valor: '',
    },
  ],
  desejoManterContribuicaoFundoOrigem: '',
  desejoManterValorContribuicao: '',
  desejoManterValorImportanciaSegurada: '',
  desejoContribuirMensalFundoDiversificado: '',
  desejoContribuirMensalFundoNovo: '',
  fundoContribuicaoRegularRendaFixa: [
    {
      familiaFundoOrigem: '',
      familiaFundoDestino: '',
    },
  ],
  fundoContribuicaoRegularRendaVariavel: [
    {
      familiaFundoOrigem: '',
      familiaFundoDestino: '',
    },
  ],
  informacoesComplementares: '',
  local: '',
  data: '',
};
export const familiaFundosRendaFixa = [
  'CAIXA FIC PREV 30 RF',
  'CAIXA FIC PREV 30 PÓS FIXADO',
  'CAIXA FIC PREV 40 RF',
  'CAIXA FIC PREV 40 PÓS FIXADO',
  'CAIXA FIC PREV 50 RF',
  'CAIXA FIC PREV 50 PÓS FIXADO',
  'CAIXA FIC PREV 60 RF',
  'CAIXA FIC PREV 70 RF',
  'CAIXA FIC PREV 100 RF',
  'CAIXA FIC PREV 150 RF',
  'CAIXA FIC PREV 200 RF',
  'CAIXA FIC PREV 250 RF',
  'CAIXA FIC PREV 60 RF Cred Priv',
  'CAIXA FIC PREV 80 RF Cred Priv',
  'CAIXA FIC PREV 125 RF Cred Priv',
  'CAIXA FIC PREV 150 RF Cred Priv',
  'CAIXA FIC PREV 200 RF Cred Priv',
  'CAIXA FIC PREV 250 RF Cred Priv',
  'CAIXA FIC PREV 80 RF Índice de Preços',
  'CAIXA FIC PREV 125 RF Índice de Preços',
  'CAIXA FIC PREV 150 RF Índice de Preços',
  'CAIXA FIC PREV 200 RF Índice de Preços',
  'CAIXA FIC PREV 250 RF Índice de Preços',
  'CAIXA FIC PREV 130 RF',
  'CAIXA FIC PREV 180 RF',
  'CAIXA FIC PREV 125 MODERADO',
  'CAIXA FIC PREV 150 MODERADO',
  'CAIXA FIC PREV 200 MODERADO',
  'CAIXA FIC PREV 250 MODERADO',
  'CAIXA FIC PREV 40 MODERADO',
  'CAIXA FIC PREV 50 MODERADO',
  'CAIXA FIC PREV 60 MODERADO',
  'CAIXA FIC PREV 70 MODERADO',
  'CAIXA FIC PREV 80 MODERADO',
  'CAIXA FIC PREV 100 PÓS FIXADO',
  'CAIXA FIC PREV 130 PÓS FIXADO',
  'CAIXA FIC PREV 150 PÓS FIXADO',
  'CAIXA FIC PREV 180 PÓS FIXADO',
  'CAIXA FIC PREV 200 PÓS FIXADO',
  'CAIXA FIC PREV 250 PÓS FIXADO',
  'CAIXA FIC PREV 60 PÓS FIXADO',
  'CAIXA FIC PREV 70 PÓS FIXADO',
  'CAIXA FIC PREV 80 RF INFLAÇÃO ATIVA',
  'CAIXA FIC PREV 125 RF INFLAÇÃO ATIVA',
  'CAIXA FIC PREV 150 RF INFLAÇÃO ATIVA',
  'CAIXA FIC PREV 250 RF INFLAÇÃO ATIVA',
  'CAIXA FIC PREV PRIVATE 80 RF CRÉDITO PRIVADO PLUS',
  'CAIXA FIC PREV 60 MULTIGESTOR CRÉDITO PRIVADO PREMIUM',
];

export const familiaFundosRendaVariavel = [
  'CAIXA FIC PREV 125 Multi RV 15',
  'CAIXA FIC PREV 150 Multi RV 15',
  'CAIXA FIC PREV 200 Multi RV 15',
  'CAIXA FIC PREV 250 Multi RV 15',
  'CAIXA FIC PREV 125 Multi RV 30',
  'CAIXA FIC PREV 150 Multi RV 30',
  'CAIXA FIC PREV 200 Multi RV 30',
  'CAIXA FIC PREV 250 Multi RV 30',
  'CAIXA FIC PREV 125 Multi RV 49',
  'CAIXA FIC PREV 150 Multi RV 49',
  'CAIXA FIC PREV 200 Multi RV 49',
  'CAIXA FIC PREV 250 Multi RV 49',
  'CAIXA FIC PREV 125 MULTI RV 70',
  'CAIXA FIC PREV 150 MULTI RV 70',
  'CAIXA FIC PREV 200 MULTI RV 70',
  'CAIXA FIC PREV 250 MULTI RV 70',
  'CAIXA FIC PREV 100 MM ESTRATEGIA LIVRE MODERADO',
  'CAIXA FIC PREV 80 MM ESTRATEGIA LIVRE MODERADO',
  'CAIXA FIC PREV 115 MM ESTRATEGIA LIVRE MODERADO',
  'CAIXA FIC PREV 145 MM ESTRATEGIA LIVRE MODERADO',
  'CAIXA FIC PREV 195 MM ESTRATEGIA LIVRE MODERADO',
  'CAIXA FIC PREV 250 MM ESTRATEGIA LIVRE MODERADO',
  'CAIXA FIC PREV 100 MM ESTRATEGIA LIVRE CONSERVADOR',
  'CAIXA FIC PREV 115 MM ESTRATEGIA LIVRE CONSERVADOR',
  'CAIXA FIC PREV 145 MM ESTRATEGIA LIVRE CONSERVADOR',
  'CAIXA FIC PREV 195 MM ESTRATEGIA LIVRE CONSERVADOR',
  'CAIXA FIC PREV 250 MM ESTRATEGIA LIVRE CONSERVADOR',
  'CAIXA FIC PREV 80 MM ESTRATEGIA LIVRE CONSERVADOR',
  'CAIXA FIC PREV 100 MM ESTRATEGIA LIVRE ARROJADO',
  'CAIXA FIC PREV 115 MM ESTRATEGIA LIVRE ARROJADO',
  'CAIXA FIC PREV 145 MM ESTRATEGIA LIVRE ARROJADO',
  'CAIXA FIC PREV 250 MM ESTRATEGIA LIVRE ARROJADO',
  'CAIXA FIC PREV 80 MM ESTRATEGIA LIVRE ARROJADO',
  'CAIXA FIC PREV 125 MULTI RV 70 LIVRE',
  'CAIXA FIC PREV 150 MULTI RV 70 LIVRE',
  'CAIXA FIC PREV 200 MULTI RV 70 LIVRE',
  'CAIXA FIC PREV 250 MULTI RV 70 LIVRE',
  'CAIXA FIC PREV 100 MM MULTIGESTOR MULTIESTRATÉGIA',
  'CAIXA FIC PREV 125 MM AGENDA FUTURO',
];

export const TEXTOS_FOP223 = {
  tituloFop: 'FOP 223 - Solicitação de Transferência com Alteração de Taxa',
  tituloSecaoDadoCliente: '1. Dados do Cliente',
  avisoImportante: (
    <>
      <strong>Importante:</strong> caso as informações acima estejam divergentes
      dos dados cadastrais identificados na CVP, estes serão utilizados para
      atualização. Esta atualização fica autorizada pela assinatura deste
      documento.
    </>
  ),
  textoDeAcordo:
    'Estou ciente da obrigatoriedade em manter meus dados cadastrais atualizados e em concordância com os critérios de boa fé e veracidade nas declarações e circunstâncias atreladas à contratação do seguro conforme artigo 765 da lei nº 10.406/2022 do Código Civil.',
  tituloSecaoDadosTransferencia: '2. Dados da Transferência',
  subTituloSecaoDadosTransferencia:
    '2.1. Transferência com alteração de Taxa de Administração',
  textoExplicativoSecaoDadosTransferencia:
    'Nesta opção o cliente autoriza a transferência entre certificados com redução de taxa de administração. O cliente poderá optar pela transferência do valor total de um certificado, com nova emissão (certificado monofundo)  por transferências parciais com a diversificação de fundos, com novas emissões (certificados multifundos).',
  PGBL: 'PGBL',
  VGBL: 'VGBL',
  fundoOrigem: (
    <>
      <strong>Fundo de Origem</strong>
    </>
  ),
  fundoDestino: (
    <>
      <strong> Fundo de Destino</strong>
    </>
  ),
  botaoRendaFixa: '+ Renda Fixa',
  botaoRendaVariavel: ' + Renda Variável',
  avisoUpload: (
    <>
      <strong>Importante:</strong> É obrigatório o upload de um documento ou
      extrato que comprove o volume de recursos citados com a CEF de acordo com
      a CE086.
    </>
  ),
  subTituloSecaoDadosTransferenciaBeneficioProtecao:
    '2.2. Transferência do Benefícios de Proteção',
  avisosBeneficioProtecao: {
    autorizacaoBeneficioProtecao: (
      <>
        Nesta opção o cliente autoriza a transferência da cobertura de proteção
        que estiver ativa no certificado de origem. <strong>IMPORTANTE:</strong>
      </>
    ),
    benficioProtecaoAtivo:
      ' a) O benefício de proteção ativo no certificado de origem poderá ser transferido para o certificado de destino caso a transferência dos recursos seja TOTAL, e desde que o dia de vencimento e canal de pagamento sejam os mesmos;',
    periodoDeCarencia:
      ' b) O período de carência do benefício de proteção cumprido no certificado de origem será considerado no certificado de destino;',
    contribuicaoCertificadoOrigem:
      ' c) Os valores das contribuições do certificado de origem e do certificado de destino, se houver, serão somados e, caso não haja, será mantido o valor do certificado de origem. Em função da tábua atuarial poderão ocorrer alterações nos valores de Importância Segurada;',
    beneficioProtecaoNaoMaisComercializado:
      ' d) Benefício de proteção que não é mais comercializado, não será transferido para o certificado de destino;',
    coberturaRisco:
      ' e) A cobertura do risco não poderá ser excluída, sendo passível apenas de manutenção ou majoração; e',
    manutencaoRisco:
      ' f) A manutenção do risco poderá ser realizada na Central de Atendimento, Área Logada, Portal Economiário e através do FOP 318.',
    opcaoBeneficioProtecao:
      ' OPÇÃO PARA O BENEFÍCIO DE PROTEÇÃO – Escolha apenas uma das opções abaixo:',
  },
  manterValorContribuicao: 'Desejo manter o valor da Contribuição',
  manterImportanciaSegurada: 'Desejo manter o valor da Importância Segurada',
  tituloSecaoDadosContribuicoes: '3. Dados das Contribuições',
  avisoContribuicoes: (
    <>
      {' '}
      Em caso de diversificação de fundos, escolher abaixo o fundo que deverá
      receber a contribuição regular do certificado de origem OU se deseja criar
      um fundo novo para receber as contribuições mensais. OBSERVAÇÕES:{' '}
      <strong>
        Diante de uma diversificação de fundos em que, no certificado de origem,
        conste uma contribuição suspensa, esta será ativada automaticamente no
        fundo de destino, sendo necessário seguir a instrução acima
      </strong>
    </>
  ),
  contribuirMensalmente:
    'Desejo continuar contribuindo mensalmente no fundo de origem (Apenas transferências parciais)',
  opcoesContribuicaoMensal:
    'OPÇÃO DE FUNDO PARA CONTRIBUIÇÃO REGULAR MENSAL - Escolha apenas uma das opções abaixo:',
  contribuirFundoDiversificado: (
    <>
      {' '}
      Desejo contribuir mensalmente no seguinte fundo diversificado: <br />{' '}
      <br />
    </>
  ),
  criarNovoFundo: (
    <>
      Desejo a criação de um novo fundo para receber as contribuições mensais:{' '}
      <br />
      <br />
    </>
  ),
  informacoesComplementares: '4. Informações Complementares',
  avisoInformacoesComplementares: (
    <>
      Este campo poderá ser utilizado para quaisquer informações complementares
      para indicação de Grupo Familiar (Segmento Exclusivo) – Informe o(s)
      CPF(s) do grupo e grau de parentesco; Convênio – Informe o tipo de
      convênio, matrícula do funcionário CAIXA e valor da contribuição da
      cobertura de risco (pecúlio e/ou pensão).
    </>
  ),
  dadosIndicador: '5. Dados do indicador',
  avisoVendaDigital:
    ' *No caso de venda por indicador de agência digital, a agência física de vinculação deverá ser informada.',
  tituloDeclaracao: '5. Declaração',
  declaracaoRegulamento:
    'Declaro conhecer o regulamento do plano de destino e estou ciente de que irei cumprir novo prazo de carência para resgates (60 dias) e transferências (30 dias) a contar da emissão do novo certificado.',
};

export const TIPOS_TRANSFERENCIA = {
  TOTAL: 'total',
  PARCIAL: 'parcial',
};
export const TIPOS_TRANSFERENCIA_TEXT = {
  TOTAL: 'Total',
  PARCIAL: 'Parcial',
};
