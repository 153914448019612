// @ts-nocheck
import styled from 'styled-components/macro';

export const ContainerTableAliquota = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `2px solid ${theme.color.line.medium}`,
  padding: '20px',
  transition: '200ms',

  '&:nth-child(even)': {
    backgroundColor: theme.color.neutral['07'],
  },

  '&:last-child': {
    borderBottom: 'none',

    p: {
      fontWeight: 'bold',
    },
  },

  '&:hover': {
    backgroundColor: theme.color.neutral['06'],
    transition: '200ms',
  },
}));
