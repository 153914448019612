// @ts-nocheck
import React, { useContext } from 'react';
import { Text, Grid, Divider } from '@cvp/design-system/react';

import { formatarCpf } from 'main/utils/cpf_cnpj';
import { formatarData } from 'main/utils';
import { AppContext } from 'main/contexts/AppContext';
import useComprovanteAlteracao from 'previdencia/features/AlteracaoFormaDadosPagamento/hooks/useComprovanteAlteracao';
import DadosSolicitacao from 'previdencia/features/AlteracaoFormaDadosPagamento/components/DadosSolicitacao';
import ComprovanteNovaConta from 'previdencia/features/AlteracaoFormaDadosPagamento/components/ComprovanteNovaConta';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

export const ComprovanteAlteracao: React.FC<
  ALTERACAO_CONTA_TYPES.IComprovanteAlteracaoProps
> = ({
  dadosParticipante,
  dadosListaBancos,
  canalId,
  dadosNovaConta,
  voltar,
}) => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  const {
    sexo,
    formaPagamentoAnterior,
    dadosContaBancaria,
    contaAntiga,
    dadosContaBancariaAntiga,
  } = useComprovanteAlteracao({ dadosListaBancos, dadosParticipante, canalId });

  if (!canalId) {
    return (
      <ComprovanteNovaConta
        dadoscontaAntiga={dadosParticipante}
        dadosNovaConta={dadosNovaConta}
        cpfcnpj={cpfCnpj}
        voltar={voltar}
      />
    );
  }

  return (
    <>
      <Grid.Item xs={1}>
        <Text variant="body01-md" color="text">
          Sua alteração foi realizada com sucesso.
        </Text>

        <Text variant="body01-md" color="text" margin>
          {CONSTS.COBRANCA_GERADA}
        </Text>
      </Grid.Item>
      <Grid.Item xs={1}>
        <Text variant="headline-08" color="primary" margin>
          Dados do participante
        </Text>

        <Text variant="body01-md" color="text">
          Cliente: {dadosParticipante?.nomePessoa}
        </Text>

        <Text variant="body01-md" color="text">
          Data de nascimento:{' '}
          {formatarData(dadosParticipante?.dataNascimentoPessoa)}
        </Text>

        <Text variant="body01-md" color="text">
          CPF: {formatarCpf(cpfCnpj)}
        </Text>

        <Text variant="body01-md" color="text">
          Sexo: {sexo}
        </Text>
      </Grid.Item>
      <Divider />
      <Grid.Item xs={1}>
        <Text variant="headline-08" color="primary" margin>
          Dados da alteração da forma de pagamento
        </Text>

        <Text variant="body01-md" color="text">
          Forma de pagamento anterior: {formaPagamentoAnterior}
        </Text>

        <Text variant="body01-md" color="text" margin>
          {dadosContaBancaria}
        </Text>

        <Text variant="body01-md" color="text">
          Forma de pagamento atual: Débito em conta
        </Text>

        {contaAntiga?.map(e => (
          <Text variant="body01-md" color="text">
            {dadosContaBancariaAntiga(e)}
          </Text>
        ))}
        <DadosSolicitacao voltar={voltar} />
      </Grid.Item>
    </>
  );
};

export default ComprovanteAlteracao;
