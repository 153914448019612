// @ts-nocheck
import React from 'react';
import RenderConditional from 'main/components/RenderConditional';
import TransferenciaInternaProvider, {
  useTransferenciaInternaContext,
} from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import PlanosTransferenciaInterna from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/PlanosTransferenciaInterna';
import ContribuicaoRendaMensal from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/ContribuicaoRendaMensal';
import FundoInvestimentoDestino from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/FundoInvestimentoDestino';
import CuidadoGarantido from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/CuidadoGarantido';
import FormaPagamento from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/FormaPagamento';
import Aquisicao from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Aquisicao';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';

const TransferenciaInterna = (): React.ReactElement => {
  const { etapa } = useTransferenciaInternaContext();

  return (
    <>
      <RenderConditional
        condition={etapa === ENUM_TYPES.EtapaTransferenciaInterna.PLANOS}
      >
        <PlanosTransferenciaInterna />
      </RenderConditional>

      <RenderConditional
        condition={etapa === ENUM_TYPES.EtapaTransferenciaInterna.CONTRIBUICAO}
      >
        <ContribuicaoRendaMensal />
      </RenderConditional>

      <RenderConditional
        condition={
          etapa === ENUM_TYPES.EtapaTransferenciaInterna.FUNDO_INVESTIMENTO
        }
      >
        <FundoInvestimentoDestino />
      </RenderConditional>

      <RenderConditional
        condition={
          etapa === ENUM_TYPES.EtapaTransferenciaInterna.CUIDADO_GARANTIDO
        }
      >
        <CuidadoGarantido />
      </RenderConditional>

      <RenderConditional
        condition={
          etapa === ENUM_TYPES.EtapaTransferenciaInterna.FORMA_PAGAMENTO
        }
      >
        <FormaPagamento />
      </RenderConditional>

      <RenderConditional
        condition={etapa === ENUM_TYPES.EtapaTransferenciaInterna.AQUISICAO}
      >
        <Aquisicao />
      </RenderConditional>
    </>
  );
};

const TransferenciaInternaEmissaoConjugado: React.FC = () => {
  return (
    <TransferenciaInternaProvider>
      <TransferenciaInterna />
    </TransferenciaInternaProvider>
  );
};

export default TransferenciaInternaEmissaoConjugado;
