// @ts-nocheck
import { Button } from 'main/components/Header/styles';
import Icon from 'main/components/Icon';
import {
  formatDecimalValue,
  tryGetMonetaryValueOrDefault,
} from 'main/utils/money';
import { DadosCliente } from 'painelInadimplencia/types/ConsultarInadimplenciaResponse';
import { PropsTable } from 'painelInadimplencia/types/ConsultarInadimplenciaConstants';
import { Tooltip } from '@cvp/design-system/react';
import { ordenaValorMonetario, ordenaValor } from 'main/utils/ordenar';
import { tryGetValueOrDefault } from 'main/utils/conditional';

function formataValorInadimplencia() {
  return ({ valorInadimplencia }: PropsTable) => {
    return tryGetMonetaryValueOrDefault(valorInadimplencia, '-');
  };
}

export const buildColumnsAgencia = (onclick: (value: DadosCliente) => void) => [
  {
    name: 'Certificado',
    selector: 'contrato',
    minWidth: '100px',
  },
  {
    name: 'Quantidade Parcela',
    selector: ({ quantidadeParcelas }: PropsTable) => {
      return formatDecimalValue(quantidadeParcelas);
    },
    minWidth: '80px',
    sortable: true,
    sortFunction: ordenaValor('quantidadeParcelas'),
  },
  {
    name: 'Total',
    selector: ({ totalInadimplencia }: PropsTable) => {
      return tryGetMonetaryValueOrDefault(totalInadimplencia, '-');
    },
    minWidth: '150px',
    sortable: true,
    sortFunction: ordenaValorMonetario('totalInadimplencia'),
  },
  {
    maxWidth: '250px',
    cell: (row: DadosCliente) => {
      return (
        <Button onClick={() => onclick(row)} variant="primary">
          Detalhes
        </Button>
      );
    },
  },
];

export const buildColumnsHierarquiaSEV = (
  onclick: (unidade: string) => void,
  tipoFilhos?: string,
) => [
  {
    name: `Unidade ${tryGetValueOrDefault([tipoFilhos], '')}`,
    sortable: true,
    sortFunction: ordenaValor('codigoAgenciaHierarquia'),
    cell: ({ codigoAgenciaHierarquia, nomeAgenciaHierarquia }: PropsTable) => {
      return (
        <Tooltip
          text={nomeAgenciaHierarquia}
          position="right"
          variant="secondary"
        >
          <Button
            onClick={() => {
              if (codigoAgenciaHierarquia) {
                onclick(codigoAgenciaHierarquia);
              }
            }}
            variant="text"
            style={{ minWidth: '50px', padding: '0px' }}
          >
            {codigoAgenciaHierarquia} <Icon name="arrowRight" />
          </Button>
        </Tooltip>
      );
    },
    selector: ({ codigoAgenciaHierarquia }: PropsTable) => {
      return codigoAgenciaHierarquia;
    },
    minWidth: '100px',
  },
  {
    name: 'Quantidade Total',
    selector: ({ quantidadeInadimplencia }: PropsTable) => {
      return formatDecimalValue(quantidadeInadimplencia);
    },
    minWidth: '100px',
    sortable: true,
    sortFunction: ordenaValor('quantidadeInadimplencia'),
  },
  {
    name: 'Total',
    selector: formataValorInadimplencia(),
    minWidth: '150px',
    sortable: true,
    sortFunction: ordenaValorMonetario('valorInadimplencia'),
  },
];

export const buildColumnsHierarquiaSN = (
  onclick: (unidade: string) => void,
  tipoFilhos?: string,
) => [
  {
    name: `Unidade ${tryGetValueOrDefault([tipoFilhos], '')}`,
    sortable: true,
    sortFunction: ordenaValor('codigoSrHierarquia'),
    cell: ({ codigoSrHierarquia, nomeSrHierarquia }: PropsTable) => {
      return (
        <Tooltip text={nomeSrHierarquia} position="right" variant="secondary">
          <Button
            onClick={() => {
              if (codigoSrHierarquia) {
                onclick(codigoSrHierarquia);
              }
            }}
            variant="text"
            style={{ minWidth: '50px', padding: '0px' }}
          >
            {codigoSrHierarquia} <Icon name="arrowRight" />
          </Button>
        </Tooltip>
      );
    },
    selector: ({ codigoSrHierarquia }: PropsTable) => {
      return codigoSrHierarquia;
    },
    minWidth: '100px',
  },
  {
    name: 'Quantidade Total',
    selector: ({ quantidadeInadimplencia }: PropsTable) => {
      return formatDecimalValue(quantidadeInadimplencia);
    },
    minWidth: '100px',
    sortable: true,
    sortFunction: ordenaValor('quantidadeInadimplencia'),
  },
  {
    name: 'Total',
    selector: formataValorInadimplencia(),
    minWidth: '150px',
    sortable: true,
    sortFunction: ordenaValorMonetario('valorInadimplencia'),
  },
];

export const buildColumnsHierarquiaSR = (
  onclick: (unidade: string) => void,
  tipoFilhos?: string,
) => [
  {
    name: `Unidade ${tryGetValueOrDefault([tipoFilhos], '')}`,
    sortable: true,
    sortFunction: ordenaValor('codigoSev'),
    cell: ({ codigoSev, nomeSev }: PropsTable) => {
      return (
        <Tooltip text={nomeSev} position="right" variant="secondary">
          <Button
            onClick={() => {
              if (codigoSev) {
                onclick(codigoSev);
              }
            }}
            variant="text"
            style={{ minWidth: '50px', padding: '0px' }}
          >
            {codigoSev} <Icon name="arrowRight" />
          </Button>
        </Tooltip>
      );
    },
    selector: ({ codigoSev }: PropsTable) => {
      return codigoSev;
    },
    minWidth: '100px',
  },
  {
    name: 'Quantidade Total',
    selector: ({ quantidadeInadimplencia }: PropsTable) => {
      return formatDecimalValue(quantidadeInadimplencia);
    },

    minWidth: '100px',
    sortable: true,
    sortFunction: ordenaValor('quantidadeInadimplencia'),
  },
  {
    name: 'Total',
    selector: formataValorInadimplencia(),
    minWidth: '150px',
    sortable: true,
    sortFunction: ordenaValorMonetario('valorInadimplencia'),
  },
];

export const buildColumnsHierarquiaVP = (
  onclick: (unidade: string) => void,
  tipoFilhos?: string,
) => [
  {
    name: `Unidade ${tryGetValueOrDefault([tipoFilhos], '')}`,
    sortable: true,
    sortFunction: ordenaValor('codigoSnHierarquia'),
    cell: ({ codigoSnHierarquia, nomeSnHierarquia }: PropsTable) => {
      return (
        <Tooltip text={nomeSnHierarquia} position="right" variant="secondary">
          <Button
            onClick={() => {
              if (codigoSnHierarquia) {
                onclick(codigoSnHierarquia);
              }
            }}
            variant="text"
            style={{ minWidth: '50px', padding: '0px' }}
          >
            {codigoSnHierarquia} <Icon name="arrowRight" />
          </Button>
        </Tooltip>
      );
    },
    selector: ({ codigoSnHierarquia }: PropsTable) => {
      return codigoSnHierarquia;
    },
    minWidth: '100px',
  },
  {
    name: 'Quantidade Total',
    selector: ({ quantidadeInadimplencia }: PropsTable) => {
      return formatDecimalValue(quantidadeInadimplencia);
    },
    minWidth: '100px',
    sortable: true,
    sortFunction: ordenaValor('quantidadeInadimplencia'),
  },

  {
    name: 'Total',
    selector: formataValorInadimplencia(),
    minWidth: '150px',
    sortable: true,
    sortFunction: ordenaValorMonetario('valorInadimplencia'),
  },
];

export const TiposVisao = {
  VP: 'VP',
  SN: 'SN',
  SR: 'SR',
  SEV: 'SEV',
  AG: 'AG',
};

export const SegmentosFilter = [
  {
    value: 'PREVIDENCIA',
    key: 'PREVIDENCIA',
  },
  {
    value: 'VIDA',
    key: 'VIDA',
  },
];

export const PREV = 'PREVIDENCIA';
export const VIDA = 'VIDA';

export const FormaPagamentoFilterPREV = [
  {
    value: 'Debito em Conta',
    key: 'FormaDePagamento',
  },
  {
    value: 'Boleto',
    key: 'FormaDePagamento',
  },
];

export const FormaPagamentoFilterVIDA = [
  {
    value: 'Debito em Conta',
    key: 'FormaDePagamento',
  },

  {
    value: 'Cartão de Crédito',
    key: 'FormaDePagamento',
  },
  {
    value: 'Boleto',
    key: 'FormaDePagamento',
  },
];

export const PeriodicidadePagamentoFilter = [
  {
    label: 'Pagamento Anual',
    value: 'PA',
    key: 'PU',
  },
  {
    label: 'Pagamento Mensal',
    value: 'PM',
    key: 'PM',
  },
  {
    label: 'Pagamento Unico',
    value: 'PU',
    key: 'PU',
  },
  {
    label: 'Pagamento Trimestral',
    value: 'PT',
    key: 'PT',
  },
  {
    label: 'Pagamento Semestral',
    value: 'PS',
    key: 'PS',
  },
];

export const SEGMENTO = {
  VIDA: 'vida',
  PREV: 'previdencia',
};
