// @ts-nocheck
export const INFORMACOES_TELEFONE_UTEIS = [
  {
    canal: 'Central de atendimento clientes e Relacionamento CVP',
    telefone: '0800 702 4000',
    horario: 'Atendimento das 8h às 21h de segunda a sexta-feira.',
  },
  {
    canal: 'SAC Seguridade',
    telefone: '0800 702 4280',
    horario: 'Atendimento 24h todos os dias da semana.',
  },
  {
    canal: 'Assistência Dia & Noite e Sinistros',
    telefone: '0800 722 2492',
    horario:
      'Atendimento 24h todos os dias da semana. (Acionar ou acompanhar um sinistro das 8h às 21h de segunda a sexta-feira).',
  },
  {
    canal: 'Ouvidoria',
    telefone: '0800 722 2492',
    horario:
      'Antes de começar, tenha em mãos o protocolo anterior fornecido em um dos nossos canais de atendimento.',
  },
  {
    canal:
      'Central de Atendimento ao Surdo (pós-venda - Informações Gerais, 2ª via de docs, endossos e pagamentos)',
    telefone: '0800 702 4260',
    horario:
      'Exclusivo para pessoas com deficiência auditiva ou de fala, que buscam por informações ou desejam fazer sugestões, reclamações, cancelamento ou suspensão de serviços e produtos. O atendimento funciona 24h, todos os dias. Como Ouvidoria, funciona das 8h às 18h, de segunda a sexta-feira, exceto em feriados nacionais.',
  },
];

export const COLUNA_ATENDIMENTO_TELEFONE_UTEIS = [
  {
    name: 'Canal',
    selector: 'canal',
    wrap: true,
  },
  {
    name: 'Telefone',
    selector: 'telefone',
  },
  {
    name: 'Horário',
    selector: 'horario',
    wrap: true,
  },
];

export const TITULO_TELEFONE_UTEIS = 'Telefones Úteis';
