// @ts-nocheck
import { useState } from 'react';
import { useFormik } from 'formik';
import useTableInputFocus from 'main/hooks/useTableInputFocus';
import {
  getTernaryResult,
  tryGetValueOrDefault,
  checkIfAllItemsAreTrue,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { Fundo } from 'previdencia/types/Fundo.type';
import * as FACTORY from 'previdencia/features/TransferenciaEntreCertificados/factories';
import * as UTILS from 'previdencia/features/TransferenciaEntreCertificados/utils/TransferenciaEntreCertificados';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const useTabelaFundos = () => {
  const { featureData, setFeatureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const tipoTransferencia = tryGetValueOrDefault(
    [featureData?.tipoTransferencia],
    '',
  );

  const { selectedTableInputFocus, atribuirParametroInputFocus } =
    useTableInputFocus();

  const [contextoAtualizado, setContextoAtualizado] = useState(false);

  const [
    toggleModalValidacaoTransferencia,
    setToggleModalValidacaoTransferencia,
  ] = useState(false);

  const [
    validaPreenchimentoTransferencia,
    setValidaPreenchimentoTransferencia,
  ] = useState(
    {} as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IValidaPreenchimentoTransferencia,
  );

  const quantidadeMaximaFundos = tryGetValueOrDefault(
    [Number(featureData?.certificadoOrigem?.qtdMaximaFundos)],
    0,
  );

  const initialStateFormik = {
    fundosOrigem: FACTORY.converterFundo(featureData),
  };

  const formik =
    useFormik<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IFormikValuesTipoTransferencia>(
      {
        enableReinitialize: true,
        initialValues: initialStateFormik,
        validateOnMount: true,
        onSubmit: () => undefined,
      },
    );

  const isParcial: boolean =
    featureData?.tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.PARCIAL;

  const possuiBeneficio = UTILS.isCoberturaValida(
    featureData?.certificadoOrigem?.coberturas?.coberturas,
  );

  const isExibePerguntaBeneficioProtecao: boolean = checkIfAllItemsAreTrue([
    !!featureData?.tipoTransferencia,
    !isParcial,
    possuiBeneficio,
  ]);

  const isDisableNextStep: boolean =
    UTILS.isNotAvailableNextStepTipoTransferencia(
      featureData,
      formik.values.fundosOrigem,
      possuiBeneficio,
    );

  const atualizarContexto = (isContextoAtualizado: boolean): void => {
    setContextoAtualizado(isContextoAtualizado);
  };

  const handleFundoSelecionado = (codigoFundo: string): void => {
    atribuirParametroInputFocus(codigoFundo);

    const listaFundosOrigem = formik.values.fundosOrigem?.map(fundo =>
      FACTORY.modificarFundoSelecionadoFactory({
        fundo,
        codigoFundo,
      }),
    ) as Fundo[];

    formik.setValues({
      ...formik.values,
      fundosOrigem: listaFundosOrigem,
    });
  };

  const handleValorTransferencia = (
    codigoFundo: string,
    valorTransferencia: string,
  ): void => {
    const listaFundosOrigem = formik.values.fundosOrigem?.map(fundo =>
      FACTORY.distribuirValorTransferenciaFactory({
        fundo,
        codigoFundo,
        valorTransferencia,
      }),
    ) as Fundo[];

    formik.setValues({
      ...formik.values,
      fundosOrigem: listaFundosOrigem,
    });
  };

  const handleTipoTransferenciaFundo = (
    codigoFundo: string,
    tipoTransferenciaFundo: string,
  ): void => {
    const listaFundosOrigem = formik.values.fundosOrigem?.map(fundo =>
      FACTORY.selecionarTipoTransferenciaFundoFactory({
        fundo,
        codigoFundo,
        tipoTransferenciaFundo,
      }),
    );

    formik.setValues({
      ...formik.values,
      fundosOrigem: listaFundosOrigem,
    });
  };

  const handleModalValidacaoValoresTipoTransferencia = (): void => {
    setToggleModalValidacaoTransferencia(prevState => !prevState);
  };

  const COLUNAS_TOTAL = CONSTS.COLUNAS_TABELA_FUNDOS_TOTAL({
    fundos: formik.values.fundosOrigem,
    quantidadeMaximaFundos,
    tipoTransferencia: featureData?.tipoTransferencia,
    handleFundoSelecionado,
  });

  const COLUNAS_PARCIAL = CONSTS.COLUNAS_TABELA_FUNDOS_PARCIAL({
    COLUNAS_TOTAL,
    selectedTableInputFocus,
    atribuirParametroInputFocus,
    handleValorTransferencia,
    handleTipoTransferenciaFundo,
  });

  const colunasTabelaFundos = getTernaryResult(
    featureData?.tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.TOTAL,
    COLUNAS_TOTAL,
    COLUNAS_PARCIAL,
  );

  const validarPreenchimentoTabelaTransferencia = (): boolean => {
    const dadosValidados =
      FACTORY.obterDadosValidacaoTabelaTipoTransferenciaFactory(
        formik.values.fundosOrigem,
      );

    setValidaPreenchimentoTransferencia(dadosValidados);

    if (dadosValidados.isValorInvalido) {
      handleModalValidacaoValoresTipoTransferencia();
      return true;
    }

    return false;
  };

  const definirFundoOrigemContexto = (): void => {
    if (validarPreenchimentoTabelaTransferencia()) return;

    setFeatureData({
      ...featureData,
      fundosOrigem: FACTORY.formatarFundoOrigem(
        UTILS.obterFundosOrigemSelecionados(formik.values.fundosOrigem),
        tipoTransferencia,
      ),
    });

    atualizarContexto(true);
  };

  return {
    formik,
    colunasTabelaFundos,
    isDisableNextStep,
    contextoAtualizado,
    toggleModalValidacaoTransferencia,
    validaPreenchimentoTransferencia,
    isExibePerguntaBeneficioProtecao,
    handleModalValidacaoValoresTipoTransferencia,
    definirFundoOrigemContexto,
    atualizarContexto,
  };
};

export default useTabelaFundos;
