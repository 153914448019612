// @ts-nocheck
import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseConsultarDadosBasicos } from 'previdencia/features/SimulacaoRenda/types/dadosBasicos';
import * as ConsultarDadosBasicosApi from 'previdencia/features/SimulacaoRenda/services/consultarDadosBasicos.api';

const useConsultarDadosBasicos = (): UseQueryResult<
  ResponseConsultarDadosBasicos | undefined
> => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-consultar-dados-basico', numCertificado],
    () =>
      ConsultarDadosBasicosApi.consultarDadosBasicos(cpfCnpj, numCertificado),
    {
      cacheTime: reactQueryCacheDuration(),
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
};

export default useConsultarDadosBasicos;
