// @ts-nocheck
import styled from 'styled-components/macro';

export const Coberturas = styled.div(({ theme }) => ({
  borderBottom: `2px solid #DBDCDE`,
  marginBottom: '20px',
  paddingBottom: '5px',

  [theme.breakpoint.md()]: {
    paddingBottom: '0',
  },
}));

export const Title = styled.div(() => ({
  marginTop: '20px',
  borderBottom: `2px solid #DBDCDE`,
}));
