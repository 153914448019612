// @ts-nocheck
import { TIPO_FORMA_PAGAMENTO } from 'seguros/constants/ApoliceDadosBancarios';

export const dadosBancariosFormaPagamento = (
  isPagamentoDebito: boolean,
): string => {
  if (isPagamentoDebito) return TIPO_FORMA_PAGAMENTO.DEBITO_CONTA;

  return TIPO_FORMA_PAGAMENTO.BOLETO;
};
