// @ts-nocheck
import styled from 'styled-components/macro';
import { Display } from '@cvp/design-system/react';

export const ContainerSelecionarCuidado = styled(Display)`
  display: block;
`;

export const ContainerSelecionar = styled(Display)(() => ({
  margin: '5px 8px',
  '& p': {
    lineHeight: '16px',
  },
}));

export const ContainerCombinacaoCobertura = styled(Display)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

export const CombinacaoCobertura = styled(Display)(({ theme: { color } }) => ({
  borderRadius: '100%',
  border: `1px solid ${color.brandPrimary.light}`,
  padding: '5px',
  display: 'inline-flex',
  transition: '350ms all ease',

  '&:hover': {
    background: color.neutral['07'],
    transition: '350ms all ease',
  },

  '& svg': {
    margin: '3px',
    fill: color.brandPrimary.light,
  },
}));
