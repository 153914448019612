// @ts-nocheck
export const SUBTITULO_PAGINA_TEXTO =
  'Aqui é possível ver o resumo dos planos individuais e acessar a lâmina e o regulamento dos fundos.';

export const COMPOSICAO_CARTEIRA_TEXTO =
  'Confira abaixo a composição da carteira e como a reserva está distribúida por fundo de investimento.';

export const RELACAO_RISCO_RETORNO_TEXTO =
  'Veja aqui a relação risco/retorno dos fundos de investimento que compõem a carteira.';

export const NIVEL_RISCO_TEXTO =
  '* A volatilidade mostra o nível de risco de mercado (quanto maior, mais arriscado é o fundo). A volatilidade da carteira não leva em conta a correlação entre os fundos. Produtos FederalPrev não são demonstrados neste gráfico.';

export const TAMANHO_PERCENTUAL_PAGINA = '0.66';

export const DOCUMENTOS_FUNDOS_INITIAL_STATE = {
  fundoId: '',
  arquivoId: '',
  tipoArquivoFundo: '',
};
