// @ts-nocheck
import { ITagFilterOption } from 'main/components/Table/TableFilter/IFilterOption';
import { IRelatorioResgateResponse } from 'previdencia/features/Resgate/types/RelatorioResgateResponse';

export const removerTiposDeResgatesDuplicados = (
  data: IRelatorioResgateResponse,
  index: number,
  array: IRelatorioResgateResponse[],
) => index === array.findIndex(t => t.tipoResgate === data.tipoResgate);

export const mapIRelatorioResgateResponseParaITagFilterOption = ({
  numeroProposta,
  tipoResgate,
}: IRelatorioResgateResponse): ITagFilterOption => ({
  id: parseInt(numeroProposta, 10),
  description: tipoResgate,
  column: 'tipoResgate',
});
