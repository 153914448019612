// @ts-nocheck
/* eslint-disable react/jsx-no-constructed-context-values */

import { obterChaveArmazenamentoDadosFuncionalidades } from 'config/storage_keys';
import { AppContext } from 'main/contexts/AppContext';
import { AuthContext } from 'main/features/Auth/contexts/AuthContext';
import { getPermissionsInSession } from 'main/features/Auth/utils/auth';
import { useFeatureData } from 'main/hooks/useFeatureData';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { removeSessionItem } from 'main/utils/storage';
import { IPrestamistaContext } from 'prestamista/types/IPrestamistaContext';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useLayoutEffect,
} from 'react';

export function createPrestamistaContex<T = unknown>() {
  return createContext<IPrestamistaContext<T> | null>(null);
}

const PrestamistaContext = createPrestamistaContex<unknown>();

PrestamistaContext.displayName = 'PrestamistaContextFeatureData';

export function usePrestamistaContext<T>() {
  const context = useContext(PrestamistaContext);
  if (!context) {
    throw new Error(
      'usePrestamistaContext deve ser usado com o PrestamistaContex',
    );
  }
  return context as IPrestamistaContext<T>;
}

const PrestamistaContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    clientePrestamista,
    setContextualMenu,
    contextualMenu,
    restoreDefaultMenuPrestamista,
  } = useContext(AppContext);
  const { setUserPermissions } = useContext(AuthContext);
  const { clearFeatureData, setFeatureData, featureData } = useFeatureData();
  const featureDataKey = obterChaveArmazenamentoDadosFuncionalidades();

  useLayoutEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        !!clientePrestamista?.cpfCnpj,
        !!clientePrestamista?.numeroApolice,
        contextualMenu === 'default',
      ])
    ) {
      setContextualMenu('prestamista');
    }

    setUserPermissions(getPermissionsInSession());

    return () => {
      if (contextualMenu === 'prestamista') {
        restoreDefaultMenuPrestamista(clientePrestamista?.cpfCnpj);
        removeSessionItem(featureDataKey);
      }
    };
  }, [clientePrestamista?.cpfCnpj, clientePrestamista?.numeroApolice]);

  return (
    <PrestamistaContext.Provider
      value={{
        numeroContrato: tryGetValueOrDefault(
          [clientePrestamista?.numeroContrato],
          '',
        ),
        numeroCertificado: tryGetValueOrDefault(
          [clientePrestamista?.numeroCertificado],
          0,
        ),
        numeroApolice: tryGetValueOrDefault(
          [clientePrestamista?.numeroApolice],
          0,
        ),
        cpfCnpj: tryGetValueOrDefault([clientePrestamista?.cpfCnpj], ''),

        nomeTitular: tryGetValueOrDefault(
          [clientePrestamista?.nomeTitular],
          '',
        ),
        situacaoContrato: tryGetValueOrDefault(
          [clientePrestamista?.situacaoContrato],
          '',
        ),
        featureData,
        setFeatureData,
        clearFeatureData,
      }}
    >
      {children}
    </PrestamistaContext.Provider>
  );
};

export default PrestamistaContextProvider;
