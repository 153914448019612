// @ts-nocheck
import styled from 'styled-components/macro';
import { DropdownMenu as DropdownMenuBase } from 'main/components/Menu/Horizontal/styles';

export const FilterComponent = styled.div({});

export const DropdownMenu = styled(DropdownMenuBase)(() => ({
  left: 'auto',
  right: '0',
}));
