// @ts-nocheck
import { useEffect, useState } from 'react';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
  getTernaryResult,
} from 'main/utils/conditional';
import { useEnviarDps } from './useEnviarDps';
import { TEXT_MODAL } from '../constants/texts';
import { IFormValues } from '../interface/IFormFieldsProps';
import {
  IResponseDps,
  IFetchDataConsultaVidaDps,
  ISetOpenModalDetalhes,
} from '../interface/ISolicitaDps';

export const useSolicitaDps = (
  fetchDataConsultaVidaDps: IFetchDataConsultaVidaDps,
  setOpenModalDetalhes: ISetOpenModalDetalhes,
) => {
  const {
    response: responseSolicitaDPS,
    loading: isLoading,
    fetchData: fetchDataSolicitaDPS,
  } = useEnviarDps();

  const [localResponse, setLocalResponse] = useState<IResponseDps | null>(null);

  useEffect(() => {
    if (responseSolicitaDPS) {
      setLocalResponse(responseSolicitaDPS);
    }

    if (checkIfAllItemsAreTrue([!!localResponse?.sucessoBFF])) {
      fetchDataConsultaVidaDps();
    }

    setOpenModalDetalhes(false);
  }, [responseSolicitaDPS, localResponse]);

  const handleSubmitForm = async (values: IFormValues) => {
    const {
      nomeCliente,
      cpfCnpj,
      email,
      dataNascimento,
      telefone,
      sexo,
      numeroProposta,
    } = values;

    try {
      await fetchDataSolicitaDPS({
        nome: tryGetValueOrDefault([nomeCliente], ''),
        cpf: tryGetValueOrDefault([cpfCnpj], ''),
        email: tryGetValueOrDefault([email], ''),
        dataNascimento: tryGetValueOrDefault([dataNascimento], ''),
        telefone: tryGetValueOrDefault([telefone], ''),
        sexo: tryGetValueOrDefault([sexo], ''),
        numeroProposta: tryGetValueOrDefault([numeroProposta], ''),
      });

      const success = tryGetValueOrDefault([localResponse?.sucessoBFF], false);
      return {
        success,
        message: getTernaryResult(
          success,
          TEXT_MODAL.MENSAGEM_SUCESSO_DPS,
          TEXT_MODAL.MENSAGEM_ERRO_DPS,
        ),
      };
    } catch (error) {
      return { success: false, message: TEXT_MODAL.MENSAGEM_ERRO_DPS };
    }
  };

  return { handleSubmitForm, isLoading };
};
