// @ts-nocheck
import React from 'react';
import Table from 'main/components/Table';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { levelRisk } from 'previdencia/utils/riskStatus';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as FACTORY from 'previdencia/features/Aporte/factories/aporteFactory';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const GerarAporteDadosDistribuicao: React.FC<
  APORTE_TYPES.IAporteDadosDistribuicaoProps
> = ({ fundosDistribuicao }) => {
  const fundosPrevia = FACTORY.recuperarFundosComprovante(
    fundosDistribuicao.get().value,
  );
  const resultFundosGerarAporte = fundosPrevia.map(
    ({ descPerfilFundo, valorContribuicao, ...rest }) => ({
      perfil: <S.RiskBarAporteStyleAporte risk={levelRisk(descPerfilFundo)} />,
      valorContribuicao: formatarValorPadraoBrasileiro(
        valorContribuicao === '-' ? 0 : valorContribuicao,
      ),
      ...rest,
    }),
  );

  return (
    <Table
      noHeader
      responsive
      striped
      highlightOnHover
      data={tryGetValueOrDefault([resultFundosGerarAporte], [])}
      columns={CONSTS.COLUNAS_TABELA_DISTRIBUIR_APORTE}
      noDataComponent={CONSTS.DADOS_DISTRIBUICAO_APORTE_NAO_ENCONTADOS}
    />
  );
};

export default GerarAporteDadosDistribuicao;
