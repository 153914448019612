// @ts-nocheck
import { useState } from 'react';
import * as Yup from 'yup';
import { Email, Telefone } from '../types/Cliente';
import { DetalhesPortabilidade } from '../types/DetalhesPortabilidade';
import { getYupExceptionMessage } from '../utils/edicaoContatosUtils';

const validationSchema = {
  email: Yup.string().required('Informe o e-mail.').email('E-mail inválido.'),
  telefone: Yup.string()
    .min(9, 'Número de telefone inválido.')
    .required('Informe o telefone.'),
};

export const useEdicaoContatos = (portabilidade?: DetalhesPortabilidade) => {
  const [emails] = useState<Email[]>(() => {
    return portabilidade?.cliente.emails ?? ([] as Email[]);
  });
  const [telefones] = useState<Telefone[]>(() => {
    return portabilidade?.cliente.telefones ?? ([] as Telefone[]);
  });

  const validatePhone = (value: string) => {
    try {
      validationSchema.telefone.validateSync(value);
      return undefined;
    } catch (error: any) {
      return getYupExceptionMessage(error);
    }
  };

  const validateEmail = (value: string) => {
    try {
      validationSchema.email.validateSync(value);
      return undefined;
    } catch (error: any) {
      return getYupExceptionMessage(error);
    }
  };

  const validateTipoTelefone = (value: string) => {
    if (!value) return 'Escolha uma opção';

    return undefined;
  };

  return {
    emails,
    telefones,
    validatePhone,
    validateEmail,
    validateTipoTelefone,
  };
};
