// @ts-nocheck
import { STATUS_DPS } from '../constants/texts';
import { getTernaryResult } from 'main/utils/conditional';

export const useObterStatusDPS = (statusDps: string): boolean => {
  const statusMap = [
    { status: STATUS_DPS.PendenteDeEnvio, result: true },
    { status: STATUS_DPS.PendenteDePreenchimento, result: true },
    { status: STATUS_DPS.Aceito, result: false },
    { status: STATUS_DPS.Recusado, result: false },
    { status: STATUS_DPS.EmAnalise, result: true },
    { status: STATUS_DPS.NaoNecessario, result: true },
  ];

  return statusMap.reduce((acc, curr) => {
    return getTernaryResult(
      curr.status.toLocaleLowerCase() === statusDps.toLocaleLowerCase(),
      curr.result,
      acc,
    );
  }, false);
};
