// @ts-nocheck
import styled from 'styled-components/macro';

export const ReactDataTable = styled.div(({ theme }) => ({
  maxWidth: '100%',
  flexGrow: 1,
  '.rdt_TableHeader': {
    display: 'none',
  },

  '.rdt_TableCol': {
    fontSize: '16px',
    padding: '12px',

    [theme.breakpoint.md()]: {
      backgroundColor: theme.color.neutral['06'],
    },
  },

  '.rdt_TableCell': {
    fontSize: '16px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },

  '.cQxDXr': {
    padding: '10px',
  },
}));
