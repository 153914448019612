// @ts-nocheck
import React, { useState } from 'react';
import { Select } from '@cvp/design-system/react';
import { DynamicFormSelectOption } from 'main/features/prospeccao/types/DesignSystemSelectProps';
import * as S from './styles';
import { SelectTipoTransferenciaProps } from './types/TabelaMultifundos.types';
import { TipoTransferencia } from 'previdencia/types/MultifundosPJ';

export const SelectTipoTransferencia: React.FunctionComponent<
  SelectTipoTransferenciaProps
> = ({ onSelect }) => {
  const [tipoTransferenciaSelecionado, setTipoTransferenciaSelecionado] =
    useState('');
  const isSelected = (tipoTransf: string) => {
    return tipoTransferenciaSelecionado === tipoTransf;
  };
  const handleChange = (value: string) => {
    setTipoTransferenciaSelecionado(value);
    onSelect(value);
  };
  return (
    <S.ContainerSelectTipoTransferencia>
      <Select
        placeholder="Escolha a opção"
        onChange={({
          target: { value },
        }: React.ChangeEvent<DynamicFormSelectOption>) => {
          handleChange(value);
        }}
        dettach
      >
        <Select.Item
          value="Total"
          text="Total"
          selected={isSelected(TipoTransferencia.TOTAL)}
        />
        <Select.Item
          value="Parcial"
          text="Parcial"
          selected={isSelected(TipoTransferencia.PARCIAL)}
        />
      </Select>
    </S.ContainerSelectTipoTransferencia>
  );
};
