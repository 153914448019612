// @ts-nocheck
import {
  COLUNAS_TABELA_ALTERACAO_BENEFICIARIOS,
  COLUNAS_TABELA_ALTERACAO_BENEFICIARIOS_IMPRIMIR,
  COLUNAS_TABELA_RESERVA,
  COLUNAS_TABELA_RESERVA_IMPRIMIR,
} from 'previdencia/constants/constants';

export const definirColunasTabelaAlteração = (print?: boolean) => {
  return print
    ? COLUNAS_TABELA_ALTERACAO_BENEFICIARIOS_IMPRIMIR
    : COLUNAS_TABELA_ALTERACAO_BENEFICIARIOS;
};

export const definirColunasTabelaReserva = (print?: boolean) => {
  return print ? COLUNAS_TABELA_RESERVA_IMPRIMIR : COLUNAS_TABELA_RESERVA;
};

export const definirOverflow = (print?: boolean) => {
  return print ? 'hidden' : 'auto';
};
