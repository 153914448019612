// @ts-nocheck
import { Button, Card, Display, Grid, Text } from '@cvp/design-system/react';

import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import useDefinirContribuicao from 'previdencia/features/SolicitacaoResgate/hooks/useDefinirContribuicao';
import useHandleDefinirContribuicao from 'previdencia/features/SolicitacaoResgate/hooks/useHandleDefinirContribuicao';
import DisclaimerMsgErroResgate from 'previdencia/features/SolicitacaoResgate/components/DisclaimerMsgErroResgate';
import CardResumoDistribuicaoContribuicaoRegular from 'previdencia/features/SolicitacaoResgate/components/CardResumoDistribuicaoContribuicaoRegular';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as RESPONSE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateResponse';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from './styles';

const DefinirContribuicao = () => {
  const { navigateTo } = usePrevNavigation();

  const { featureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const { valorContribuicaoRegularlAtual } = tryGetValueOrDefault(
    [featureData?.dadosConsultaContribuicaoRegular],
    {} as RESPONSE_TYPES.IConsultarContribuicaoRegularResponse,
  );

  const { formik, colunas } = useDefinirContribuicao();

  const {
    isDefinicaoContribuicaoDesabilitado,
    isLoadingDefinicaoContribuicao,
    mensagemTemporaria,
    efetuarDefinicaoContribuicao,
  } = useHandleDefinirContribuicao(formik);

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-05" color="primary">
                Escolha um novo fundo
              </Text>
            </Grid.Item>
            <Grid.Item xs={1}>
              <Text variant="body01-md" color="text-light" margin>
                {`Vimos que você investe o valor de ${tryGetMonetaryValueOrDefault(
                  valorContribuicaoRegularlAtual,
                )} por mês. Em qual fundo você quer continuar contribuindo?`}
              </Text>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item xs={1}>
              <S.Table
                noHeader
                responsive
                highlightOnHover
                striped
                data={tryGetValueOrDefault(
                  [formik.values.listaFundosParaContribuicaoRegular],
                  [],
                )}
                columns={colunas}
                noDataComponent={CONSTS.NO_DATA_TABLE}
              />
            </Grid.Item>

            <CardResumoDistribuicaoContribuicaoRegular
              formik={formik}
              valorContribuicaoRegularlAtual={valorContribuicaoRegularlAtual}
            />

            <DisclaimerMsgErroResgate mensagem={mensagemTemporaria} />

            <Grid.Item xs={1}>
              <S.ContainerNav>
                <Button
                  variant="outlined"
                  onClick={() => navigateTo('simular-resgate')}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  onClick={efetuarDefinicaoContribuicao}
                  disabled={isDefinicaoContribuicaoDesabilitado}
                  loading={isLoadingDefinicaoContribuicao}
                >
                  Confirmar
                </Button>
              </S.ContainerNav>
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default DefinirContribuicao;
