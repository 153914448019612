// @ts-nocheck
import { AtualizacaoPortabilidade } from '../types/AtualizacaoPortabilidade';

export const getAtualizacaoPortabilidadePayload =
  (): AtualizacaoPortabilidade => ({
    numPortabilidade: '',
    acao: '',
    dadosCliente: {
      id: '',
      cpfCnpj: '',
      primeiroNome: '',
      sobrenome: '',
      telefones: [
        {
          numeroTelefone: '',
          ativo: false,
          dataHoraAtualizacao: new Date().toISOString(),
          tipoTelefone: '',
          principal: false,
        },
      ],
      emails: [
        {
          dataHoraAtualizacao: new Date().toISOString(),
          ativo: false,
          enderecoEmail: '',
          principal: false,
        },
      ],
    },
  });
