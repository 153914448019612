// @ts-nocheck
import { Select } from '@cvp/design-system/react';

import { capitalize } from 'main/utils/string';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';

const ColunaSelecionarTipoTransferencia = ({
  row,
  handleTipoTransferenciaFundo,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunaSelecionarTipoTransferenciaProps) => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();
  const tiposTransferencia = Object.values(CONSTS.TIPOS_TRANSFERENCIA);
  const fundos = tryGetValueOrDefault(
    [featureData?.certificadoOrigem?.fundos],
    [],
  );
  const tiposDisponiveis = getTernaryResult(
    fundos?.length > 1,
    tiposTransferencia,
    tiposTransferencia.slice(0, -1),
  );
  return (
    <Select
      placeholder="Selecionar..."
      disabled={!row.selecionado}
      onChange={({
        target: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        handleTipoTransferenciaFundo(
          row.codFundo,
          value.charAt(0).toUpperCase(),
        );
      }}
    >
      {tiposDisponiveis.map(item => (
        <Select.Item
          key={item}
          value={item}
          text={capitalize(item)}
          selected={row.tipoTransferenciaFundo === item.charAt(0).toUpperCase()}
        />
      ))}
    </Select>
  );
};

export default ColunaSelecionarTipoTransferencia;
