// @ts-nocheck
export const obterEndpointStatusSinistroPrevidencia = (): string =>
  '/PortalEconomiario/PECO_StatusSinistroPrevidencia';

export const obterEndpointStatusSinistroVida = (): string =>
  '/PortalEconomiario/PECO_ConsultarTimelineSinistroVida';

export const obterEndpointTimelinePrestamista = (): string =>
  '/PortalEconomiario/PECO_ConsultarTimelineSinistroPrestamista';

export const obterEndpointStatusSinistroPrestamista = (): string =>
  '/PortalEconomiario/PECO_ListaDadosSinistroPrestamista';

export const obterEndpointInfoAdicionalStatusSinistroVida = (): string =>
  '/PortalEconomiario/PECO_ListarTimelineSinistroVida';

export const PECOS_SINISTRO = {
  StatusSinistroPrevidencia: 'PECO_StatusSinistroPrevidencia',
  ListaDadosSinistroPrestamista: 'PECO_ListaDadosSinistroPrestamista',
  ConsultarTimelineSinistroCpfCnpj: 'PECO_ConsultarSinistroVidaCpfCnpj',
};
