// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Display, Modal, Button, Text } from '@cvp/design-system/react';
import { ModalInfoEnvioDPS } from './ModalInfoEnvioDPS';
import { IModalConfirmacaoProps } from '../interface/IModalDpsProps';
import RenderConditional from 'main/components/RenderConditional';

export const ModalConfirmacao: React.FC<IModalConfirmacaoProps> = ({
  open,
  handleClose,
  handleConfirm,
  loading,
}) => {
  const [showModalInfoEnvioDPS, setShowModalInfoEnvioDPS] = useState(false);
  const [statusEnvio, setStatusEnvio] = useState<{
    success: boolean;
    message: string;
  } | void>();

  const handleConfirmAndOpenDetalhes = async () => {
    const response = await handleConfirm();
    setStatusEnvio(response);
    handleClose();
  };

  useEffect(() => {
    if (statusEnvio) {
      setShowModalInfoEnvioDPS(true);
    }
  }, [statusEnvio]);

  return (
    <>
      <Modal show={open} onClose={handleClose}>
        <Text variant="body01-lg" align="center" margin>
          Todos os dados preenchidos estão corretos?
        </Text>
        <RenderConditional condition={loading}>
          <Button variant="text" loading />
        </RenderConditional>
        <RenderConditional condition={!loading}>
          <Text variant="body-medium3" align="center">
            Deseja confirmar o envio da DPS Eletrônica?
          </Text>
          <Display justify="center" style={{ marginTop: 20 }}>
            <Button
              variant="secondary"
              onClick={handleClose}
              data-testid="cancelar"
            >
              Voltar
            </Button>
            <Button
              variant="primary"
              onClick={handleConfirmAndOpenDetalhes}
              data-testid="confirmar"
            >
              Confirmar
            </Button>
          </Display>
        </RenderConditional>
      </Modal>

      <ModalInfoEnvioDPS
        open={showModalInfoEnvioDPS}
        handleClose={() => setShowModalInfoEnvioDPS(false)}
        loading={loading}
        statusEnvio={statusEnvio}
      />
    </>
  );
};

export default ModalConfirmacao;
