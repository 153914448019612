// @ts-nocheck
import styled from 'styled-components/macro';
import { Display } from '@cvp/design-system/react';
import Calendar from 'main/components/form/Calendar';
import Select from 'main/components/form/Select';

export const InputDataNascimento = styled(Calendar)({
  input: {
    marginBottom: '5px',
  },
});

export const InputSelectSimulacao = styled(Select)({
  marginBottom: '5px',
});

export const ContainerButtons = styled(Display)({
  marginTop: '30px',
});
