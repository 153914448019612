// @ts-nocheck
import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IRouteProps } from 'main/components/Route/IRouteProps';
import AppLayout from 'main/components/Layout';
import { useAuth } from 'main/features/Auth/hooks/useAuth';
import RouteContext from 'main/contexts/RouteContext';
import AppBreadcrumb from 'main/components/Layout/AppBreadcrumb';

interface IPublicRoute extends IRouteProps {
  forceLogout?: boolean;
}

export const PublicRoute: React.FunctionComponent<IPublicRoute> = ({
  component: Component,
  forceLogout = true,
  ...rest
}) => {
  const { logout } = useAuth();
  useEffect(() => {
    if (forceLogout) logout();
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <AppLayout>
            <Component {...rest} {...props} />
          </AppLayout>
        );
      }}
    />
  );
};

export const PrivateRouteWithouSidebar: React.FunctionComponent<
  IRouteProps
> = ({ component: Component, ...rest }) => {
  const { user } = useAuth();
  const { breadcrumbs } = useContext(RouteContext);

  return (
    <Route
      {...rest}
      render={props => {
        return user ? (
          <AppLayout>
            <AppBreadcrumb routes={breadcrumbs} />
            <Component {...props} {...rest} />
          </AppLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
