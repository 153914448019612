// @ts-nocheck
import { useState } from 'react';

import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { usePecoCalcularResgate } from 'previdencia/hooks/usePecoCalcularResgate';
import { usePecoAlicotasAgrupadas } from 'previdencia/hooks/usePecoAlicotasAgrupadas';
import {
  IListarFundosParaResgateAliquota,
  IListarFundosParaResgateAliquotaOpcoes,
  IListarFundosParaResgateResponse,
} from 'previdencia/types/ConsultaListaFundosParaResgate';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useDetalhesAliquota = () => {
  const { featureData, setFeatureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const [dadosTabelaFundosAliquota, setDadosTabelaFundosAliquota] =
    useState<SOLICITACAO_RESGATE_TYPES.IDadosTabelaFundosAliquota>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleToggleModal = (): void => {
    setIsModalOpen(prevState => !prevState);
  };

  const dadosFundosParaResgate = tryGetValueOrDefault(
    [featureData?.dadosListarFundosParaResgate],
    {} as IListarFundosParaResgateResponse,
  );

  const { aliquotaAtual, indicadorPermiteEditarAliquota } =
    tryGetValueOrDefault(
      [dadosFundosParaResgate?.aliquota],
      {} as IListarFundosParaResgateAliquota,
    );

  const { calcularResgate, loadingDadosCalcularResgate } =
    usePecoCalcularResgate();

  const { obterAlicotasAgrupadas, loadingDadosAlicotasAgrupadas } =
    usePecoAlicotasAgrupadas();

  const isLoadingDetalhesAliquota = checkIfSomeItemsAreTrue([
    loadingDadosCalcularResgate,
    loadingDadosAlicotasAgrupadas,
  ]);

  const exibirAliquotas = (
    tipoAliquota: IListarFundosParaResgateAliquotaOpcoes,
  ): SOLICITACAO_RESGATE_TYPES.IExibirAliquotasReturn => {
    const condicionalTabelas = getTernaryResult(
      isLoadingDetalhesAliquota,
      checkIfAllItemsAreTrue([
        !isLoadingDetalhesAliquota,
        tipoAliquota.codigoAliquota === aliquotaAtual,
      ]),
      !isLoadingDetalhesAliquota,
    );

    const gridTabelas = getTernaryResult(
      !indicadorPermiteEditarAliquota,
      { xs: 1 },
      { xs: 1, xl: 1 / 2 },
    );

    return { condicionalTabelas, gridTabelas };
  };

  const handleDadosTabelaAliquota = async (): Promise<void> => {
    const defaultFactoryParams = {
      calcularResgate,
      obterAlicotasAgrupadas,
      dadosFundosParaResgate,
    };

    const { resultadoAliquotaRegressivo, resultadoAliquotaProgressivo } =
      await FACTORY.criarContextoDetalhesAliquotaFactory(
        aliquotaAtual,
        indicadorPermiteEditarAliquota,
        defaultFactoryParams,
      );

    setDadosTabelaFundosAliquota({
      fundosAliquotaRegressivo: tryGetValueOrDefault(
        [resultadoAliquotaRegressivo.fundos],
        [],
      ),
      fundosAliquotaProgressivo: tryGetValueOrDefault(
        [resultadoAliquotaProgressivo.fundos],
        [],
      ),
    });

    setFeatureData({
      ...featureData,
      calculoResgateEtapaDetalheAliquota: {
        progressivo: resultadoAliquotaProgressivo.calculo,
        regressivo: resultadoAliquotaRegressivo.calculo,
      },
    });
  };

  const handleGetDataModalDetalhesAliquota = (): void => {
    const isFundosAliquotaExistente = checkIfSomeItemsAreTrue([
      !!dadosTabelaFundosAliquota?.fundosAliquotaProgressivo.length,
      !!dadosTabelaFundosAliquota?.fundosAliquotaRegressivo.length,
    ]);

    handleToggleModal();

    if (!isFundosAliquotaExistente) {
      handleDadosTabelaAliquota();
    }
  };

  return {
    dadosTabelaFundosAliquota,
    isLoadingDetalhesAliquota,
    isModalOpen,
    exibirAliquotas,
    handleToggleModal,
    handleGetDataModalDetalhesAliquota,
  };
};

export default useDetalhesAliquota;
