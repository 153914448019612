// @ts-nocheck
import { Display, Grid, Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import { TooltipWrapper } from 'main/components/Wrappers';
import * as S from './styles';

type InfoGridItemProps = {
  icon: string;
  label: string;
  value: string | React.ReactElement | null;
  whithTooltip?: {
    message: string;
    left?: number;
    top?: number;
  };
};

const renderChildren = ({ icon, label, value }: InfoGridItemProps) => (
  <div>
    <Display alignItems="center">
      <Icon name={icon} />
      <Text variant="caption-02" color="text-light">
        {label}
      </Text>
    </Display>

    <S.Text
      variant="caption-02"
      color="text"
      title={value}
      data-testid={`info-grid-${label}`}
    >
      {value}
    </S.Text>
  </div>
);
export const InfoGridItem: React.FC<InfoGridItemProps> = ({
  icon,
  label,
  value,
  whithTooltip,
}: InfoGridItemProps) => (
  <Grid.Item xs={1} md={1} lg={1 / 2} xl={1 / 3}>
    <RenderConditional condition={!whithTooltip}>
      {renderChildren({ icon, label, value })}
    </RenderConditional>
    <RenderConditional condition={!!whithTooltip}>
      <TooltipWrapper
        tooltip={whithTooltip?.message}
        left={whithTooltip?.left}
        top={whithTooltip?.top}
      >
        {renderChildren({ icon, label, value })}
      </TooltipWrapper>
    </RenderConditional>
  </Grid.Item>
);
