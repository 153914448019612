// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import {
  PayloadObterExtratoRentabilidade,
  ResponseExtratoRentabilidadeCotas,
} from 'previdencia/features/RentabilidadeCotas/types/ResponseExtratoRentabilidadeCotas';
import RangeDate from 'previdencia/types/RangeDate';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';

const useObterExtratoRentabilidadeCotas = (rangeDate: RangeDate) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { fetchData, loading, response } = usePeco<
    PayloadObterExtratoRentabilidade,
    ResponseExtratoRentabilidadeCotas
  >({
    api: {
      operationPath: PECOS.ConsultarDadosExtratoCotas,
    },
    cache: true,
    cacheKey: `${cpfCnpj}  ${rangeDate.dataFinal}`,
    autoFetch: false,
    payload: {
      numeroCertificado: numCertificado,
      cpfCnpj,
      dataInicial: rangeDate.dataInicial,
      dataFinal: rangeDate.dataFinal,
    },
  });
  return {
    fetchData,
    response,
    loading,
  };
};
export default useObterExtratoRentabilidadeCotas;
