// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { useToast } from 'main/hooks/useToast';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import * as DadosPlanoApi from 'previdencia/features/DadosPlano/services/dadosPlano.api';
import { useQuery, UseQueryResult } from 'react-query';
import { IResponseDadosPlano } from '../types/IResponseDadosPlano';

const useObterDadosPlano = (): UseQueryResult<
  IResponseDadosPlano | undefined
> => {
  const { toastError } = useToast();
  const { cliente } = useContext(AppContext);
  return useQuery(
    ['previdencia-dados-do-plano', cliente.numCertificado],
    () =>
      DadosPlanoApi.obterDadosPlano(cliente.cpfCnpj, cliente.numCertificado),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};

export default useObterDadosPlano;
