// @ts-nocheck
import React, { useMemo, useEffect } from 'react';
import { Button, Display, Divider, Grid, Text } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import { formatarData } from 'main/utils/date';
import { formatarValorPadraoDigitoBrasileiro } from 'main/utils/money';
import ExtratoRentabilidadeCotasFooter from 'previdencia/features/RentabilidadeCotas/components/ExtratoRentabilidadeCotasFooter';
import {
  ResponseExtratoRentabilidadeCotas,
  ExtratoRentabilidade,
  ExtratoRentabilidadeCotasProps,
} from 'previdencia/features/RentabilidadeCotas/types/ResponseExtratoRentabilidadeCotas';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { COLUNAS_TABELA_EXTRATO_RENTABILIDADE_COTAS } from 'previdencia/constants/constants';
import RenderConditional from 'main/components/RenderConditional';
import EmailSenderModal from 'main/components/EmailSenderModal';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as S from 'previdencia/features/RentabilidadeCotas/components/ExtratoRentabilidadeCotas/styles';
import useImprimirExtratoRentabilidadeCotas from 'previdencia/features/RentabilidadeCotas/hooks/useImprimirExtratoRentabilidadeCotas';
import {
  checkIfAllItemsAreTrue,
  getTernaryResult,
} from 'main/utils/conditional';

const readequarDados = (dados: string) => {
  return getTernaryResult(dados === '', '-', dados);
};

export const formatarDados = (
  data?: ResponseExtratoRentabilidadeCotas,
): ExtratoRentabilidade[] => {
  if (data) {
    return data.dadosExtrato.map<ExtratoRentabilidade>(x => ({
      codigoFundo: x.codigoFundo,
      valorMotivo: formatarValorPadraoDigitoBrasileiro(x.valorMotivo, 2, true),
      valorCota: getTernaryResult(
        x.valorCota !== '0',
        formatarValorPadraoDigitoBrasileiro(x.valorCota, 8),
        formatarValorPadraoDigitoBrasileiro(x.valorCota, 2),
      ),
      valorTotalCota: getTernaryResult(
        x.valorTotalCota !== '0',
        formatarValorPadraoDigitoBrasileiro(x.valorTotalCota, 8, true),
        formatarValorPadraoDigitoBrasileiro(x.valorTotalCota, 2, true),
      ),
      valorSaldo: formatarValorPadraoDigitoBrasileiro(x.valorSaldo),
      valorRentabilidade: getTernaryResult(
        x.valorRentabilidade !== '0',
        formatarValorPadraoDigitoBrasileiro(x.valorRentabilidade, 6),
        formatarValorPadraoDigitoBrasileiro(x.valorRentabilidade, 2),
      ),
      dataExtrato: readequarDados(formatarData(x.dataExtrato)),
      rentabilidade: formatarValorPadraoDigitoBrasileiro(x.rentabilidade, 6),
      dataMotivo: readequarDados(formatarData(x.dataMotivo)),
      nomeMotivo: readequarDados(x.nomeMotivo),
      valorMovimentacao: formatarValorPadraoDigitoBrasileiro(
        x.valorMovimentacao,
      ),
      dataCota: readequarDados(formatarData(x.dataCota)),
    }));
  }

  return [];
};

const ExtratoRentabilidadeCotas: React.FC<ExtratoRentabilidadeCotasProps> = ({
  data,
  rangeDate,
}) => {
  const { goDadosPlano } = usePrevNavigation();

  const {
    gerarArquivoImprimir,
    loading: loadingImprimir,
    response: responseImprimir,
  } = useImprimirExtratoRentabilidadeCotas();

  const columns = useMemo(
    () => [...COLUNAS_TABELA_EXTRATO_RENTABILIDADE_COTAS],
    [],
  );

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.EXTRATO_COTAS_RENTABILIDADE,
    parametrosEnvio: {
      DataInicial: data?.dataInicio,
      DataFinal: data?.dataFim,
      NumeroCertificado: data?.numeroCertificado,
      CpfCnpj: data?.numeroCPF,
    },
  };

  useEffect(() => {
    if (checkIfAllItemsAreTrue([!!responseImprimir, !loadingImprimir])) {
      const mywindow = window.open('', 'response', 'resizable=yes');
      mywindow?.document?.write(responseImprimir as string);
      setTimeout(() => {
        mywindow?.print();
      }, 100);
    }
  }, [responseImprimir, loadingImprimir]);

  return (
    <>
      <S.ExtratoRentabilidadeCotasContainer>
        <Grid>
          <Grid.Item xs={1}>
            <Divider />
            <Display alignItems="center">
              <Text variant="body01-lg" color="primary" margin>
                Exibindo as cotas no período de:
              </Text>
              <Display alignItems="center">
                <Text variant="body01-md" color="text">
                  {formatarData(data?.dataInicio)}
                </Text>
                <Text variant="body01-md" color="text">
                  a
                </Text>
                <Text variant="body01-md" color="text">
                  {formatarData(data?.dataFim)}
                </Text>
              </Display>
            </Display>

            <Table
              data={formatarDados(data)}
              columns={columns}
              responsive
              noDataComponent="Não há dados para exibir."
              striped
              style={{ marginBottom: 30 }}
              pagination
              paginationPerPage={10}
              paginationComponentOptions={{
                rowsPerPageText: 'Items por página',
                rangeSeparatorText: 'de',
              }}
            />
          </Grid.Item>
          <Grid.Item xs={1}>
            <RenderConditional condition={data?.nomeFundos.length !== 0}>
              <ExtratoRentabilidadeCotasFooter nomeFundos={data?.nomeFundos} />
              <Display marginTop={50}>
                <Button variant="outlined" onClick={goDadosPlano}>
                  Voltar
                </Button>
                <Button
                  variant="secondary"
                  loading={loadingImprimir}
                  disabled={loadingImprimir}
                  onClick={() => gerarArquivoImprimir(rangeDate)}
                >
                  Imprimir
                </Button>
                <S.Button>
                  <EmailSenderModal objetoEmail={objetoEmail} />
                </S.Button>
              </Display>
            </RenderConditional>
          </Grid.Item>
          <Grid.Item>
            <S.Separator />
          </Grid.Item>
        </Grid>
      </S.ExtratoRentabilidadeCotasContainer>
    </>
  );
};

export default ExtratoRentabilidadeCotas;
