// @ts-nocheck
import styled from 'styled-components/macro';
import { Text as TextBase } from '@cvp/design-system/react';

export const Text = styled(TextBase)({
  maxWidth: '75%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  overflow: 'hidden',
});
