// @ts-nocheck
export const tipoContasBancariasCefMain = [
  {
    codigo: '001',
    descricao: '001 - Conta Corrente de Pessoa Física',
  },
  {
    codigo: '003',
    descricao: '003 - Conta Corrente de Pessoa Jurídica',
  },
  {
    codigo: '013',
    descricao: '013 - Poupança de Pessoa Física',
  },
  {
    codigo: '022',
    descricao: '022 - Poupança de Pessoa Jurídica',
  },
  {
    codigo: '023',
    descricao: '023 - Conta Caixa Fácil',
  },
  {
    codigo: '1288',
    descricao: '1288 - Poupança PF / Caixa Fácil',
  },
  {
    codigo: '1292',
    descricao: '1292 - Conta Corrente PJ',
  },
  {
    codigo: '3701',
    descricao: '3701 - Conta Corrente PF',
  },
  {
    codigo: '3702',
    descricao: '3702 - Poupança PJ',
  },
];

export const tipoContasBancariasNaoCefMain = [
  {
    codigo: '001',
    descricao: 'Conta Corrente',
  },
  {
    codigo: '013',
    descricao: 'Conta Poupança',
  },
];
