// @ts-nocheck
import styled from 'styled-components/macro';
import {
  Modal as ModalDS,
  TextField as TextFieldDS,
  Divider as DividerDS,
  Select as SelectDS,
} from '@cvp/design-system/react';

export const Modal = styled(ModalDS)(() => ({
  maxWidth: 'initial',
}));

export const TextField = styled(TextFieldDS)`
  & input {
    height: 45px;
  }

  & label {
    margin-bottom: 0.2rem;
  }
`;

export const ContainerRadioButtons = styled.div`
  text-align: left;

  & > p {
    margin-bottom: 5px;
  }

  & div {
    display: flex;
  }

  & div:first-child {
    margin-right: 20px;
  }
`;

export const Divider = styled(DividerDS)`
  margin: 30px 0 30px 0;
`;

export const DangerMessage = styled.span`
  color: red;
  font-size: smaller;
`;

export const Select = styled(SelectDS)`
  text-align: left;

  & input,
  & div {
    height: 45px;
  }

  & label ~ div {
    line-height: 45px;
  }

  & > div ~ div {
    height: 0px;
  }

  & label {
    margin-bottom: 0.2rem;
  }
`;

export const ContainerTextArea = styled.div`
  text-align: left;

  & > p {
    margin-bottom: 5px;
  }
`;
