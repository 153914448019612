// @ts-nocheck
import styled from 'styled-components/macro';

export const Wrapper = styled.div({
  paddingLeft: '20px',
});

export const Container = styled.div({
  position: 'relative',
  '.rdt_Table': {
    width: '100%',
    overflow: 'auto',
  },
  '.rdt_TableBody': {
    overflowY: 'visible',
  },
});

export const FixedColumnCell = styled.div`
  position: fixed;
  right: 0;
  z-index: 1;
`;
