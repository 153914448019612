// @ts-nocheck
import { Text, Card, Display, Button } from '@cvp/design-system/react';
import { CardResumoPrestamista } from 'prestamista/components/CardResumoPrestamista';
import { Coberturas } from 'prestamista/features/dadosSeguro/components/Coberturas';
import { useDadosSeguroPrestamista } from 'prestamista/features/dadosSeguro/hooks/useDadosSeguroPrestamista';
import React from 'react';
import { DadosFinanciamento } from 'prestamista/features/dadosSeguro/components/DadosFinanciamento';
import usePrestNavigation from 'prestamista/hooks/usePrestNavigation';

const DadosSeguro: React.FC = () => {
  const { goHomePrest } = usePrestNavigation();
  const { dadosFinanciamento, dadosCoberturas, loading } =
    useDadosSeguroPrestamista();

  return (
    <>
      <CardResumoPrestamista />
      <Card>
        <Card.Content>
          <Text variant="headline-05" color="primary" margin>
            Dados do Seguro
          </Text>
          <DadosFinanciamento
            data={{ ...dadosFinanciamento }}
            loading={loading}
          />
          <Coberturas data={dadosCoberturas} loading={loading} />
          <Display>
            <Button variant="outlined" onClick={goHomePrest}>
              Voltar
            </Button>
          </Display>
        </Card.Content>
      </Card>
    </>
  );
};

export default DadosSeguro;
