// @ts-nocheck
import styled from 'styled-components/macro';
import { Disclaimer as DisclaimerDS } from '@cvp/design-system/react';

export const Disclaimer = styled(DisclaimerDS)(({ ...rest }) => ({
  ...rest,
}));

Disclaimer.Content = styled(DisclaimerDS.Content)(({ ...rest }) => ({
  ...rest,
}));

Disclaimer.Actions = styled(DisclaimerDS.Actions)(({ ...rest }) => ({
  ...rest,
}));

export default Disclaimer;
