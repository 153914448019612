// @ts-nocheck
import { obterEndpointFundosPorCertificado } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseFundosPorCertificado } from '../types/TiposTransferenciaFundoInvestimento.type';

export const fundosPorCertificado = async (
  cpf: string,
  CodContaOrigem: string,
): Promise<ResponseFundosPorCertificado | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseFundosPorCertificado>>(
    obterEndpointFundosPorCertificado(),
    {
      cpf,
      CodContaOrigem,
    },
  );
  if (
    data?.dados?.sucesso === false ||
    data?.dados?.entidade?.retornoCertificado === null
  )
    throw new Error(
      data?.dados?.mensagens && data?.dados?.mensagens[0]?.descricao,
    );

  return data.dados.entidade;
};
