// @ts-nocheck
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Card } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import { TableSkeleton } from 'portabilidade/components/Table';
import { Linked } from 'portabilidade/features/retencao/components';
import TableFilter from 'main/components/Table/TableFilter/TableFilter';
import {
  FilterTypes,
  IFilterOption,
} from 'main/components/Table/TableFilter/IFilterOption';
import { HorizontalProgressBar } from 'main/components/ProgressBar/HorizontalProgressBar/HorizontalProgressBar';
import masks from 'main/utils/masks';
import { useListarPortabilidadesEntrada } from '../hooks/usePortabilidadeEntrada';
import { converterRetornoParaListagem } from '../factories/listagemPortabilidadeEntradaFactory';
import RenderConditional from 'main/components/RenderConditional';

const ListagemPortabilidadeEntrada: React.FunctionComponent = () => {
  const { response, loading } = useListarPortabilidadesEntrada();

  const filterOptions: IFilterOption[] = [
    {
      key: 'numeroCpf',
      value: 'CPF/CNPJ',
      type: FilterTypes.TEXT,
      unmask: masks.cpf.unmask,
    },
    {
      key: 'numeroPortabilidade',
      value: 'Portabilidade',
      type: FilterTypes.TEXT,
    },
    { key: 'indicador', value: 'Indicador', type: FilterTypes.TEXT },
  ];
  const retornoConvertido = converterRetornoParaListagem(response);

  const colunas = useMemo(
    () => [
      {
        name: 'Portabilidade',
        selector: 'numeroPortabilidade',
        cell: ({ numeroPortabilidade }: any) => (
          <Linked
            to={`/portabilidades-entrada/${numeroPortabilidade}/detalhes`}
          >
            {numeroPortabilidade}
          </Linked>
        ),
        width: '200px',
      },
      {
        name: 'Nome do cliente',
        selector: 'nomeCliente',
        width: '190px',
      },
      {
        name: 'CPF do Cliente',
        selector: 'numeroCpf',
        width: '150px',
      },
      {
        name: 'Valor',
        selector: 'valorPortabilidadeFormatado',
        width: '150px',
      },
      {
        name: 'Agência',
        selector: 'agencia',
        width: '100px',
      },
      {
        name: 'Indicador',
        selector: 'indicador',
      },
      {
        name: 'Status',
        selector: 'status',
        width: '120px',
      },
      {
        name: '% de Conclusão',
        cell: ({ statusConclusao }: any) => (
          <HorizontalProgressBar width={statusConclusao} />
        ),
        width: '180px',
      },
    ],
    [],
  );

  return (
    <Card style={{ minHeight: 'auto' }}>
      <Card.Content>
        <RenderConditional condition={loading}>
          <TableSkeleton colunas={colunas} />
        </RenderConditional>

        <RenderConditional condition={!loading}>
          <TableFilter
            dataToFilter={retornoConvertido ?? []}
            filterTextPartial
            filterOptions={filterOptions}
          >
            {filteredData => (
              <Table
                noHeader
                responsive
                data={filteredData}
                columns={colunas}
                noDataComponent="Não há dados para exibir."
                pagination
                paginationPerPage={10}
                paginationComponentOptions={{
                  rowsPerPageText: 'Items por página',
                  rangeSeparatorText: 'de',
                }}
              />
            )}
          </TableFilter>
        </RenderConditional>
      </Card.Content>
    </Card>
  );
};

export default ListagemPortabilidadeEntrada;
