// @ts-nocheck
/* eslint-disable react/jsx-no-constructed-context-values */
import {
  ICriacaoProtocoloResponse,
  ISolicitacaoAberturaProtocolo,
} from 'extranet/types/IUseSolicitarAberturaProtocoloFop223';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

interface IFopContextProps {
  protocoloFop223: ICriacaoProtocoloResponse | undefined;
  payloadProtocolo: ISolicitacaoAberturaProtocolo | undefined;
  salvarProtocolo: (data: ICriacaoProtocoloResponse | undefined) => void;
  salvarPayloadProtocolo: (data: ISolicitacaoAberturaProtocolo) => void;
  isDigitalAgency: boolean;
  setIsDigitalAgency: (data: boolean) => void;
}
const inicialState = {
  protocoloFop223: undefined,
  payloadProtocolo: undefined,
};
export const Fop223Context = createContext<IFopContextProps>(
  {} as IFopContextProps,
);
Fop223Context.displayName = 'Fop223 Context';

const Fop223ContextProvider: React.FC<PropsWithChildren> = ({
  children,
}: any) => {
  const [isDigitalAgency, setIsDigitalAgency] = useState(false);
  const [protocoloFop223, setProtocoloFop223] = useState<
    ICriacaoProtocoloResponse | undefined
  >(inicialState.protocoloFop223);

  const [payloadProtocolo, setPayloadProtocolo] = useState<
    ISolicitacaoAberturaProtocolo | undefined
  >(inicialState.payloadProtocolo);

  const salvarPayloadProtocolo = (
    novopayload: ISolicitacaoAberturaProtocolo,
  ): void =>
    setPayloadProtocolo(
      (prevState: ISolicitacaoAberturaProtocolo | undefined) => ({
        ...prevState,
        ...novopayload,
      }),
    );

  const salvarProtocolo = (
    novoProtocolo: ICriacaoProtocoloResponse | undefined,
  ) => {
    setProtocoloFop223((prevState: ICriacaoProtocoloResponse | undefined) => {
      if (novoProtocolo) {
        return {
          ...prevState,
          ...novoProtocolo,
        };
      }
      return undefined;
    });
  };

  return (
    <Fop223Context.Provider
      value={{
        payloadProtocolo,
        protocoloFop223,
        isDigitalAgency,
        setIsDigitalAgency,
        salvarPayloadProtocolo,
        salvarProtocolo,
      }}
    >
      {children}
    </Fop223Context.Provider>
  );
};
export const useFop223Context = () => {
  return useContext(Fop223Context);
};
export default Fop223ContextProvider;
