// @ts-nocheck
import { useEffect, useState } from 'react';

import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { useTransferirEntreFundos } from 'previdencia/hooks/Transferencia/useTransferirEntreFundos';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { IResponseTransferenciaEntreFundos } from 'previdencia/types/ITransferirEntreFundos';
import * as FACTORY from 'previdencia/features/TransferenciaEntreCertificados/factories';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const useTipoTransferencia = ({
  contextoAtualizado,
  atualizarContexto,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IUseTipoTransferencia) => {
  const { navigateTo } = usePrevNavigation();

  const { featureData, setFeatureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const reservasOrigem = FACTORY.tratarReservasOrigemFactory(
    featureData?.fundosOrigem,
  );

  const { loading: loadingTransferir, fetchData: fetchTransferir } =
    useTransferirEntreFundos(reservasOrigem);

  const [modalErro, setModalErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');

  const valorMinimoPermanencia = CONSTS_TEXT.VALOR_MINIMO_PERMANENCIA(
    featureData?.certificadoOrigem?.vlrMinPermancenciaFundo,
  );

  const manterBeneficioProtecaoContexto = (
    isMantemBeneficio: boolean,
  ): void => {
    setFeatureData({
      ...featureData,
      manterBeneficio: isMantemBeneficio,
    });
  };

  const selecionarTipoTransferenciaContexto = (
    tipoTransferencia: string,
  ): void => {
    setFeatureData({
      ...featureData,
      tipoTransferencia,
      fundosOrigem: [],
      manterBeneficio: getTernaryResult(
        tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.PARCIAL,
        false,
        featureData?.manterBeneficio,
      ),
    });
  };

  const handleCloseModal = () => {
    setModalErro(false);
    setMensagemErro('');
  };

  const avancarEtapaDistribuicaoEntreFundos = async (): Promise<void> => {
    handleCloseModal();
    const dadosTransferenciaEntreFundos = await fetchTransferir();

    if (dadosTransferenciaEntreFundos?.entidade?.reservasDestino) {
      setFeatureData({
        ...featureData,
        numTransferencia:
          dadosTransferenciaEntreFundos.entidade.numTransferencia,
        fundosDestinoDisponiveis: tryGetValueOrDefault(
          [dadosTransferenciaEntreFundos?.entidade],
          {} as IResponseTransferenciaEntreFundos,
        ),
      });

      if (dadosTransferenciaEntreFundos.entidade.sta !== 'ERR') {
        navigateTo(
          `${CONSTS_TEXT.URL_TRANSFERENCIA.BASE}${CONSTS_TEXT.URL_TRANSFERENCIA.DISTRIBUICAO_FUNDOS}`,
        );
      }

      if (dadosTransferenciaEntreFundos.entidade.sta === 'ERR') {
        setModalErro(true);
        setMensagemErro(dadosTransferenciaEntreFundos.entidade.desSta);
      }
    }
  };

  const salvarEtapasContexto = (): void => {
    setFeatureData({
      ...featureData,
      step: CONSTS_TEXT.NUM_ETAPAS.TIPO_TRANSFERENCIA,
    });
  };

  useEffect(() => {
    if (contextoAtualizado) {
      avancarEtapaDistribuicaoEntreFundos();
      atualizarContexto(false);
    }
  }, [contextoAtualizado]);

  useEffect(salvarEtapasContexto, []);

  return {
    modalErro,
    mensagemErro,
    loadingTransferir,
    valorMinimoPermanencia,
    handleCloseModal,
    selecionarTipoTransferenciaContexto,
    manterBeneficioProtecaoContexto,
    avancarEtapaDistribuicaoEntreFundos,
  };
};

export default useTipoTransferencia;
