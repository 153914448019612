// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { formatarDataHoraAmigavel } from 'main/utils';
import * as S from 'main/styles/GlobalStyle';
import { IResponseAcaoCoberturas } from '../../types/AcaoCoberturas.types';
import { Container } from '../styles';
import { EnumStatusAtivacaosuspensao } from '../../types/EnumsStatusAtivacaoSuspensao.types';

type ListaComprovanteReservaProps = {
  dadosComprovante: IResponseAcaoCoberturas | undefined;
  status: string;
};

const ListaComprovanteReserva: React.FC<ListaComprovanteReservaProps> = ({
  dadosComprovante,
  status,
}) => {
  return (
    <Container>
      <S.ContainerBranco>
        <Text variant="body02-sm">Data :</Text>
        <Text variant="body02-sm">
          {formatarDataHoraAmigavel(new Date().toString())}
        </Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Valor da Contribuição :</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(dadosComprovante?.valorPagamento)}
        </Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Dia de Vencimento :</Text>
        <Text variant="body02-sm">{dadosComprovante?.diaPagamento}</Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Status da Contribuição :</Text>
        <Text variant="body02-sm">
          {status === EnumStatusAtivacaosuspensao.Ativo ? 'Ativo' : 'Suspenso'}
        </Text>
      </S.ContainerCinza>
    </Container>
  );
};

export default ListaComprovanteReserva;
