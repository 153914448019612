// @ts-nocheck
import styled from 'styled-components/macro';
import { Input } from 'main/components/form';

export const InputFundo = styled(Input)(({ theme: { color } }) => ({
  errorMessage: { fontSize: '9px' },
  label: {
    marginBottom: '10px',
  },
  input: {
    background: color.neutral['08'],
    height: '40px',
    marginTop: '10px',
    fontSize: '13px',
    ':disabled': {
      cursor: 'not-allowed',
      background: color.neutral['07'],
      color: color.neutral['03'],
    },
  },
}));
