// @ts-nocheck
import { useQuery } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import * as Cancelamento from 'contratosPrestamista/features/cancelamento/services/cancelamento';
import { useToast } from 'main/hooks/useToast';

export const useObterSegurosCancelados = () => {
  return useQuery('cancelamentos', Cancelamento.obterListaSegurosCancelados, {
    staleTime: reactQueryCacheDuration(),
    retry: false,
  });
};

export const useObterDetalhesSeguroCancelado = (
  id: string,
  numeroLinhaDoProduto: string,
  codigoDoEstipulante: string,
) => {
  const { toastError } = useToast();
  return useQuery(
    ['cancelamentos', id],
    () =>
      Cancelamento.obterDetalhesSeguroCancelado(
        id,
        numeroLinhaDoProduto,
        codigoDoEstipulante,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};
