// @ts-nocheck
import React, { useState } from 'react';
import { Accordion, Divider, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as S from 'previdencia/features/SimulacaoRenda/components/InformacoesRenda/styles';
import { PrazoAcumulacao } from 'previdencia/features/SimulacaoRenda/components/PrazoAcumulacao';
import {
  PARAGRAFO_ALIQUOTA_PROGRESSIVA,
  PARAGRAFO_ALIQUOTA_REGRESSIVA,
} from 'previdencia/features/SimulacaoRenda/constants/informacoesRenda';
import { InformacoesRendaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import { validarPrimeiraStr } from 'previdencia/features/SimulacaoRenda/utils/validarPrimeiraStr';

const InformacoesRenda: React.FC<InformacoesRendaProps> = ({
  simulacaoProgressivo,
  simulacaoRegressivo,
}) => {
  const [abrirProgressivo, setAbrirProgressivo] = useState(false);
  const abreProgressivo = (): void => {
    setAbrirProgressivo(!abrirProgressivo);
  };
  const [abrirRegressivo, setAbrirRegressivo] = useState(false);
  const abreRegressivo = (): void => {
    setAbrirRegressivo(!abrirRegressivo);
  };

  return (
    <S.Resultado>
      <RenderConditional condition={Boolean(simulacaoProgressivo?.nomCliente)}>
        <div style={{ flex: '1' }}>
          <S.AccordionStyled>
            <Accordion.Item
              title="Alíquota Progressiva"
              key="1"
              open={abrirProgressivo}
              onClick={abreProgressivo}
            >
              {PARAGRAFO_ALIQUOTA_PROGRESSIVA}
            </Accordion.Item>
          </S.AccordionStyled>
          <S.ContainerInformacoesRenda>
            <S.RendaLiquida>
              <Text variant="body02-sm">Renda mensal líquida de:</Text>
              <Text variant="headline-08">
                <strong>
                  {tryGetMonetaryValueOrDefault(
                    simulacaoProgressivo?.vlrBeneficioLiquido,
                  )}
                </strong>
              </Text>
              <Text variant="caption-01" margin>
                {validarPrimeiraStr(
                  simulacaoProgressivo?.descPeridoBeneficiarioRecebe,
                  'por ',
                )}
              </Text>
            </S.RendaLiquida>

            <div>
              <Text variant="caption-01">Renda mensal bruta de:</Text>
              <Text variant="caption-01">
                <strong>
                  {tryGetMonetaryValueOrDefault(
                    simulacaoProgressivo?.vlrBeneficio,
                  )}
                </strong>
              </Text>
              <Divider />
              <Text variant="caption-01">Desconto do Imposto de Renda:</Text>
              <Text variant="caption-01">
                <strong>
                  {tryGetMonetaryValueOrDefault(simulacaoProgressivo?.vlrIRRF)}
                </strong>
              </Text>
            </div>
          </S.ContainerInformacoesRenda>
        </div>
      </RenderConditional>

      <RenderConditional condition={Boolean(simulacaoRegressivo?.nomCliente)}>
        <div style={{ flex: '1' }}>
          <S.AccordionStyled>
            <Accordion.Item
              title="Alíquota Regressiva"
              key="2"
              open={abrirRegressivo}
              onClick={abreRegressivo}
            >
              <p>{PARAGRAFO_ALIQUOTA_REGRESSIVA}</p>
              <S.Tabela>
                <PrazoAcumulacao />
              </S.Tabela>
            </Accordion.Item>
          </S.AccordionStyled>
          <S.ContainerInformacoesRenda>
            <S.RendaLiquida>
              <Text variant="body02-sm">Renda mensal líquida de:</Text>
              <Text variant="headline-08">
                <strong>
                  {tryGetMonetaryValueOrDefault(
                    simulacaoRegressivo?.vlrBeneficioLiquido,
                  )}
                </strong>
              </Text>
              <Text variant="caption-01" margin>
                {validarPrimeiraStr(
                  simulacaoRegressivo?.descPeridoBeneficiarioRecebe,
                  'por ',
                )}
              </Text>
            </S.RendaLiquida>

            <div>
              <Text variant="caption-01">Renda mensal bruta de:</Text>
              <Text variant="caption-01">
                <strong>
                  {tryGetMonetaryValueOrDefault(
                    simulacaoRegressivo?.vlrBeneficio,
                  )}
                </strong>
              </Text>
              <Divider />
              <Text variant="caption-01">Desconto do Imposto de Renda:</Text>
              <Text variant="caption-01">
                <strong>
                  {tryGetMonetaryValueOrDefault(simulacaoRegressivo?.vlrIRRF)}
                </strong>
              </Text>
            </div>
          </S.ContainerInformacoesRenda>
        </div>
      </RenderConditional>
    </S.Resultado>
  );
};

export default InformacoesRenda;
