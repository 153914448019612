// @ts-nocheck
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import { ResponseSimularBeneficios } from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';

export const exibirTextoRendaMensalLiquida = (
  simulacao: ResponseSimularBeneficios | undefined,
): boolean => {
  const codigoTipoPagamento = simulacao?.codTipoPagamento;

  return checkIfSomeItemsAreTrue([
    codigoTipoPagamento ===
      EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
    codigoTipoPagamento === EnumTipoRendaCodigo.PrazoCerto,
    codigoTipoPagamento ===
      EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
  ]);
};
