// @ts-nocheck
import React from 'react';
import { Button } from '@cvp/design-system/react';
import * as S from './styles';
import { EnumTipoBeneficios } from '../../types/EnumsStatusAtivacaoSuspensao.types';

type ButtonsMudarEtapaProps = {
  disabled: boolean;
  proximaEtapa: () => void;
  etapaAnterior: () => void;
  suspensao?: boolean;
  tipo?: string;
  loading: boolean;
};

const ButtonsMudarEtapa: React.FC<ButtonsMudarEtapaProps> = ({
  disabled,
  proximaEtapa,
  etapaAnterior,
  suspensao,
  tipo,
  loading,
}) => {
  const buttonText =
    tipo === EnumTipoBeneficios.RISCO
      ? (suspensao && <>Ativar contribuição</>) || <>Cancelar Cuidado Extra </>
      : (suspensao && <>Ativar contribuição</>) || <>Suspender contribuição </>;
  return (
    <S.Container>
      <Button variant="outlined" onClick={() => etapaAnterior()}>
        Voltar
      </Button>
      <Button
        variant="primary"
        onClick={() => proximaEtapa()}
        disabled={disabled}
        loading={loading}
      >
        {buttonText}
      </Button>
    </S.Container>
  );
};

ButtonsMudarEtapa.defaultProps = {
  suspensao: false,
};

export default ButtonsMudarEtapa;
