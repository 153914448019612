// @ts-nocheck
import { usePecoInserirAtualizarPropostaDps } from 'contratosPrestamista/hooks/usePecoAtualizarStatusPropostaDps';
import { useVerificarNecessidadeDps } from 'contratosPrestamista/hooks/useVerificarNecessidadeDps';
import { IDadosBasicoProposta } from 'contratosPrestamista/types/IDadosBasicoProposta';
import masks from 'main/utils/masks';
import {
  NAO_NECESSITA_DPS,
  NECESSITA_DPS,
} from 'contratosPrestamista/features/dpsEletronicaPostecipado/constants/MapeamentoStatus';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { IRequesInserirPropostaDps } from 'contratosPrestamista/features/dpsEletronicaPostecipado/types/IRequestAtualizarStatusPropostaDps';
import { converterFormatoData } from 'main/utils';
import {
  TipoPessoaEnum,
  TipoPortalEconomiario,
} from 'contratosPrestamista/features/dpsEletronicaPostecipado/constants/EnumTipoPessoa';

export const useAdicionarSocio = () => {
  const serviceAtualizarDps = usePecoInserirAtualizarPropostaDps();
  const serviceVerificarNecessidadeDps = useVerificarNecessidadeDps();
  const validarDataNascimento = (entrada: string | undefined) => {
    if (entrada !== undefined) return converterFormatoData(entrada);
    return entrada;
  };
  const incluirSocioSmart = async (
    entrada: IDadosBasicoProposta,
    necessitaDps: boolean,
    correspondenteDps = '1',
    portalEconomiario = getTernaryResult(
      entrada.tipoPessoa === TipoPessoaEnum.juridica,
      TipoPortalEconomiario.juridica,
      TipoPortalEconomiario.fisica,
    ),
  ) =>
    serviceAtualizarDps.fetchInserirPropostaDps({
      cpfCnpjSocio: Number(masks.numberOnly.mask(entrada.cpf)),
      tipoPessoa: entrada.tipoPessoa,
      correspondenteDps,
      numeroPropostaEstipulante: '0',
      numeroPropostaSequencial: tryGetValueOrDefault([entrada.seqProposta], 0),
      cpfCnpj: Number(masks.numberOnly.mask(entrada.cnpj)),
      numeroContrato: masks.numberOnly.mask(entrada?.numeroProposta),
      statusDps: getTernaryResult(
        necessitaDps,
        NECESSITA_DPS,
        NAO_NECESSITA_DPS,
      ),
      portalEconomiario,
      dataNascimento: validarDataNascimento(entrada.dataNascimento),
      email: entrada.email,
      nomeSocio: entrada.nomeSocio,
      nomeEmpresa: entrada.nomeEmpresa,
    } as IRequesInserirPropostaDps);

  const verificarNecessiadeDps = async (entrada: IDadosBasicoProposta) => {
    const dadosDps = await serviceVerificarNecessidadeDps.verificarExigenciaDps(
      {
        cpf: entrada.cpf,
        numeroProposta: entrada.numeroProposta,
        valorAcumulo: entrada.valorAcumulo,
        capitalSegurado: entrada.capitalSegurado,
        cnpj: entrada.cnpj,
      },
    );
    await incluirSocioSmart(entrada, dadosDps.necessitaDps);
    return dadosDps.necessitaDps;
  };

  return {
    verificarNecessiadeDps,
    incluirSocioSmart,
    loadingVerificarNecessidadeDps:
      serviceVerificarNecessidadeDps.loadingVerificarNecessidadeDps,
    responseVerificarNecessidadeDps:
      serviceVerificarNecessidadeDps.responseVerificarNecessidadeDps,
    loadingIncluirSocioSmart: serviceAtualizarDps.loadingInserirPropostaDps,
  };
};
