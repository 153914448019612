// @ts-nocheck
import { basePath } from 'config/httpRequest';
import { api } from 'main/services';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import { IEmitirDeclaracaoQuitacaoAnualVidaPayload } from 'seguros/types/EmitirDeclaracaoQuitacaoAnualTypes';

export const obterArquivoQuitacaoAnualDebitos = async (
  payload: IEmitirDeclaracaoQuitacaoAnualVidaPayload,
): Promise<BlobPart> => {
  const { data } = await api.post(
    `${basePath}${PECOS_VIDA.EmitirArquivoDeclaracaoQuitacaoAnualVida}`,
    payload,
    { responseType: 'blob' },
  );

  if (data?.dados?.sucesso === false) {
    throw new Error(data?.dados?.mensagens?.[0].descricao);
  }

  return data;
};
