// @ts-nocheck
import { TextosInstrucoesRegerarPdf } from 'main/constants/TextosInstrucoesRegerarPdf';
import React from 'react';

const InstrucoesRegerarPdf: React.FC = () => {
  return (
    <>
      {TextosInstrucoesRegerarPdf.POSSIVEL_CAUSA_PROBLEMA}
      <br />
      <br />
      {TextosInstrucoesRegerarPdf.ACOES_SENDO_TOMADAS}
      <br />
      <br />
      {TextosInstrucoesRegerarPdf.SOLUCAO_ALTERNATIVA}
      <br />
      <br />
      {TextosInstrucoesRegerarPdf.SOLUCAO_ALTERNATIVA_PASSO_1}
      <br />
      {TextosInstrucoesRegerarPdf.SOLUCAO_ALTERNATIVA_PASSO_2}
      <br />
      {TextosInstrucoesRegerarPdf.SOLUCAO_ALTERNATIVA_PASSO_3}
      <br />
      {TextosInstrucoesRegerarPdf.SOLUCAO_ALTERNATIVA_PASSO_4}
      <br />
      {TextosInstrucoesRegerarPdf.SOLUCAO_ALTERNATIVA_PASSO_5}
      <br />
      <br />
      {TextosInstrucoesRegerarPdf.ORIENTACAO_CANAIS_ATENDIMENTO}
    </>
  );
};

export default InstrucoesRegerarPdf;
