// @ts-nocheck
import { useContext } from 'react';

import { useAuth } from 'main/features/Auth/hooks/useAuth';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import * as PECO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributarioUsePeco';
import * as REQUEST_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributarioRequest';
import * as RESPONSE_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributarioResponse';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributario';

export const usePecoAlterarPerfilTributario =
  (): PECO_TYPES.IUseAlterarPerfilTributario => {
    const { user } = useAuth();
    const { cliente } = useContext(AppContext);

    const {
      response: dadosPerfilTributario,
      loading: loadingDadosPerfilTributario,
      fetchData: obterAtualizacaoPerfilTributario,
    } = usePeco<
      Partial<REQUEST_TYPES.IAlterarPerfilTributarioPayload>,
      RESPONSE_TYPES.IAlterarPerfilTributarioResponse
    >({
      api: { operationPath: PECOS.SolicitarAlteracaoPerfilTributario },
      payload: {
        UserName: user?.nomeAcesso,
        numeroCertificado: cliente?.numCertificado,
        agencia: REGIME_TRIBUTARIO_TYPES.ResponsePerfilTributario.AGENCIA,
      },
    });

    return {
      dadosPerfilTributario,
      loadingDadosPerfilTributario,
      obterAtualizacaoPerfilTributario,
    };
  };

export const usePecoConsultarParametrosRegimeTributario = () => {
  const { cliente } = useContext(AppContext);

  const {
    response: dadosConsultaParametrosRegimeTributario,
    loading: loadingDadosConsultaParametrosRegimeTributario,
    fetchData: consultarParametrosRegimeTributario,
  } = usePeco<
    REQUEST_TYPES.IConsultarParametrosRegimeTributarioPayload,
    RESPONSE_TYPES.IConsultarParametrosRegimeTributarioResponse
  >({
    api: { operationPath: PECOS.ConsultarParametrosRegimeTributario },
    payload: {
      numeroConta: cliente?.numCertificado,
    },
    autoFetch: true,
  });

  return {
    dadosConsultaParametrosRegimeTributario:
      dadosConsultaParametrosRegimeTributario?.entidade,
    loadingDadosConsultaParametrosRegimeTributario,
    consultarParametrosRegimeTributario,
  };
};
