// @ts-nocheck
import React from 'react';
import { TextField } from '@cvp/design-system/react';
import * as TableColumns from '../../types/TableColumns';
import masks from 'main/utils/masks';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';

const ColunaValorTotal: React.FC<
  TableColumns.IDadosTabelaDistribuicaoProps
> = ({ row, requisitosDiversificacao }) => {
  return (
    <TextField
      placeholder="Valor total"
      value={masks.currency.mask(row?.saldo)}
      disabled
      helpText={`Valor mínimo: ${tryGetMonetaryValueOrDefault(
        [requisitosDiversificacao?.valorMinimoDiversificacao],
        0,
      )}`}
    />
  );
};

export default ColunaValorTotal;
