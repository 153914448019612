// @ts-nocheck
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';
import { NovoValorBeneficioProtecao } from 'previdencia/features/AlterarValorContribuicao/types/FactoryTypes';

export const montarPayloadCalcularBeneficioFactory = (
  novoValorBeneficioProtecao: NovoValorBeneficioProtecao,
) => {
  if (
    novoValorBeneficioProtecao.tipoBeneficio ===
    DefaultValue.TIPO_BENEFICIO_PECULIO
  ) {
    return { valorCustomizacaoPeculio: novoValorBeneficioProtecao.novoValor };
  }

  if (
    novoValorBeneficioProtecao.tipoBeneficio ===
    DefaultValue.TIPO_BENEFICIO_PENSAO
  ) {
    return {
      valorCustomizacaoPensao: novoValorBeneficioProtecao.novoValor,
      prazo: novoValorBeneficioProtecao.prazo,
    };
  }

  return {};
};
