// @ts-nocheck
import { Text } from '@cvp/design-system/react';

import { tryGetValueOrDefault } from 'main/utils/conditional';
import Table from 'main/components/Table';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const InformacoesBancarias = ({
  contasBancariasValidasParaResgate,
  colunas,
}: SOLICITACAO_RESGATE_TYPES.IInformacoesBancariasProps) => {
  return (
    <>
      <Text variant="body01-md" color="primary" margin>
        {CONSTS.TEXTOS_INFO_BANCARIAS.TITULO}
      </Text>
      <Text variant="body01-md" margin>
        {CONSTS.TEXTOS_INFO_BANCARIAS.DESCRICAO}
      </Text>

      <Table
        noHeader
        highlightOnHover
        striped
        data={tryGetValueOrDefault([contasBancariasValidasParaResgate], [])}
        columns={colunas}
        responsive
        pagination
        paginationPerPage={10}
        paginationComponentOptions={{
          rowsPerPageText: 'Items por página',
          rangeSeparatorText: 'de',
        }}
        noDataComponent={CONSTS.NO_DATA_TABLE}
      />
    </>
  );
};

export default InformacoesBancarias;
