// @ts-nocheck
import styled from 'styled-components/macro';

export const Contribuicoes = styled.div(({ theme }) => ({
  marginBottom: '20px',

  [theme.breakpoint.md()]: {
    paddingBottom: '0',
  },
}));

export const Title = styled.div(({ theme }) => ({
  marginTop: '20px',
  borderBottom: `2px solid ${theme.color.neutral['02']}`,
}));
