// @ts-nocheck
import { useContext, useEffect, useState } from 'react';

import { AppContext } from 'main/contexts/AppContext';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { IMessageEvent } from 'main/types/IMessageEvent';
import {
  IObterContatosDefaultPrevidencia,
  useObterContatosDefaultPrevidencia,
} from 'main/hooks/useObterContatosDefaultPrevidencia';
import * as API from 'previdencia/features/AlteracaoRegimeTributario/hooks/usePecoAlteracaoRegimeTributario';
import * as CONSTS from 'previdencia/features/AlteracaoRegimeTributario/constants/constants';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributario';

const useAlteracaoRegimeTributario = (opcaoRegimeTributario: string) => {
  const { subscribe, unsubscribe } = useMessageEvent();

  const { cliente } = useContext(AppContext);

  const [checkDeclaracao, setCheckDeclaracao] = useState<boolean>(false);

  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] =
    useState<boolean>(false);

  const [eventSucess, setEventSucess] = useState<boolean>(false);

  const [open, setOpen] =
    useState<REGIME_TRIBUTARIO_TYPES.IModaisRegimeTributario>(
      CONSTS.MODAIS_REGIME_TRIBUTARIO,
    );

  const {
    data: responseObterContatosDefault,
    isFetching: loadingObterContatos,
    isFetched: recuperacaoEmailExecutada,
  } = useObterContatosDefaultPrevidencia();

  const { emailDefault, numerosTelefone } = tryGetValueOrDefault(
    [responseObterContatosDefault],
    {} as IObterContatosDefaultPrevidencia,
  );

  const dadosCliente = {
    email: emailDefault,
    mobileNumber: numerosTelefone,
    cpfCnpj: cliente?.cpfCnpj,
    numeroCertificado: cliente?.numCertificado,
    tipoProduto: undefined,
  };

  const dadosContatos = {
    email: emailDefault,
    telefone: numerosTelefone,
  };

  const {
    dadosPerfilTributario,
    loadingDadosPerfilTributario,
    obterAtualizacaoPerfilTributario,
  } = API.usePecoAlterarPerfilTributario();

  const urlAssinatura =
    dadosPerfilTributario?.entidade?.assinaturas?.[0].urlAssinatura;

  const msgAlteracaoExpirada = tryGetValueOrDefault(
    [dadosPerfilTributario?.mensagens?.[0].descricao],
    '',
  );

  const checarDeclaracao = (): void => {
    setCheckDeclaracao(prevState => !prevState);
  };

  const confirmarDeclaracao = (): void => {
    if (checkDeclaracao) {
      setOpen(prev => ({ ...prev, modalTipo: true }));
    }
  };

  const handleAssinar = (): void => {
    obterAtualizacaoPerfilTributario({
      opcaoTributacaoIrrf: opcaoRegimeTributario,
    });

    setOpen(prev => ({
      ...prev,
      modalDocusign: true,
      modalAlertaAlteracaoExpirada: true,
    }));
  };

  const fecharModal = (): void => {
    setOpen(CONSTS.MODAIS_REGIME_TRIBUTARIO);
  };

  const executarAcoesAposAssinaturaDocusign = (): void => {
    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;
      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setEventSucess(true);
        setExibirBotaoFecharModal(true);
      }
    });
  };

  const abrirModalAlertaContatos = (): void => {
    if (
      checkIfAllItemsAreTrue([!loadingObterContatos, recuperacaoEmailExecutada])
    ) {
      setOpen(prev => ({ ...prev, modalAlertaContatos: true }));
    }
  };

  useEffect(abrirModalAlertaContatos, [
    loadingObterContatos,
    recuperacaoEmailExecutada,
  ]);

  useEffect(() => {
    executarAcoesAposAssinaturaDocusign();

    return () => unsubscribe(() => setExibirBotaoFecharModal(true));
  }, []);

  return {
    eventSucess,
    checkDeclaracao,
    open,
    dadosContatos,
    loadingDadosPerfilTributario,
    dadosCliente,
    urlAssinatura,
    exibirBotaoFecharModal,
    msgAlteracaoExpirada,
    checarDeclaracao,
    confirmarDeclaracao,
    fecharModal,
    handleAssinar,
  };
};

export default useAlteracaoRegimeTributario;
