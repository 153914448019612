// @ts-nocheck
import { IMenuItem } from 'main/components/Menu/Sidebar/types';
import {
  PREV_PERMISSIONS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';

export const menuEvolucaoPatrimonial: IMenuItem = {
  label: 'Evolução Patrimonial',
  alt: 'previdencia-evolucao-patrimonial',
  path: '/cliente/produtos/previdencia/evolucao-patrimonial',
  icon: 'chart',
  roles: [
    USER_PROFILES.ANALISTA_TI,
    USER_PROFILES.ANALISTA_MANUTENCAO,
    USER_PROFILES.ECONOMIARIO,
    USER_PROFILES.ANALISTA_CONSULTA,
    USER_PROFILES.ANALISTA_POS_VENDA,
    USER_PROFILES.ANALISTA_SAIDA,
    USER_PROFILES.ANALISTA_ENTRADA,
  ],
  permissions: [PREV_PERMISSIONS.POSICAO_CONSOLIDADA],
};
