// @ts-nocheck
import SkeletonLoading from 'main/components/SkeletonLoading';
import Header from 'main/components/Header';

const Loader = (): React.ReactElement => {
  return (
    <>
      <Header showUserData={false} />
      <SkeletonLoading lines={20} />
    </>
  );
};

export default Loader;
