// @ts-nocheck
import styled from 'styled-components/macro';
import {
  Button,
  Accordion as AccordionBase,
  Display,
} from '@cvp/design-system/react';

export const DownloadButton = styled(Button)({
  textAlign: 'left',
  padding: '12px 0',
});

export const DisplayTooltip = styled(Display)({
  '> *': {
    margin: '0 8px',
  },
});

export const TooltipStatus = styled.div({
  marginTop: '-3px',
});

const AccordionItemStyle = AccordionBase.Item;

export const AccordionItem = styled(AccordionItemStyle)(({ open }) => ({
  'div:nth-child(2)': {
    overflow: open === true ? 'unset' : 'hidden',
  },
}));
