// @ts-nocheck
import React from 'react';
import { Select } from '@cvp/design-system/react';
import InputSelect from 'main/components/form/InputSelect';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';

const DadosBancariosOperacao: React.FC<
  APORTE_TYPES.IDadosBancariosOpracaoProps
> = ({ operacaoLink }) => {
  function isSelected(value: string): boolean {
    return operacaoLink.get().value === value;
  }

  return (
    <InputSelect
      link={operacaoLink}
      label="Tipo de Operação:"
      placeholder="Escolha a opção"
      dettach
    >
      <Select.Item
        key="001"
        value="001"
        text="001 - Conta Corrente de PF"
        selected={isSelected('001')}
      />
      <Select.Item
        key="003"
        value="003"
        text="003 - Conta Corrente de PJ"
        selected={isSelected('003')}
      />
      <Select.Item
        value="013"
        key="013"
        text="013 - Conta Poupança"
        selected={isSelected('013')}
      />
      <Select.Item
        key="022"
        text="022 - Conta Poupança de PJ"
        value="022"
        selected={isSelected('022')}
      />
      <Select.Item
        key="023"
        value="023"
        selected={isSelected('023')}
        text="023 - Conta Caixa Fácil"
      />
      <Select.Item
        selected={isSelected('1288')}
        key="1288"
        value="1288"
        text="1288 - Poupança Caixa Fácil"
      />
      <Select.Item
        key="1292"
        selected={isSelected('1292')}
        value="1292"
        text="1292 - Conta Corrente de PJ"
      />
      <Select.Item
        key="3701"
        value="3701"
        selected={isSelected('3701')}
        text="3701 - Conta Corrente de PF"
      />
      <Select.Item
        text="3702 - Poupança de PJ"
        key="3702"
        value="3702"
        selected={isSelected('3702')}
      />
    </InputSelect>
  );
};

export default DadosBancariosOperacao;
