// @ts-nocheck
import React from 'react';
import { Display, Modal, Button, Text } from '@cvp/design-system/react';

type ModalConfirmarAcaoProps = {
  message: string;
  open: boolean;
  action(confirm: boolean): void;
  handleClose(close: boolean): void;
};

const ModalConfirmarAcaoRetencao: React.FunctionComponent<
  ModalConfirmarAcaoProps
> = ({ open, message, action, handleClose }) => (
  <Modal show={open} onClose={handleClose}>
    <Text variant="headline-04" margin>
      Confirmar ação
    </Text>
    <Text variant="body02-sm" margin>
      {message}
    </Text>
    <Display justify="center">
      <Button
        variant="primary"
        onClick={() => action(true)}
        data-testid="confirmar"
      >
        Confirmar
      </Button>
      <Button variant="secondary" onClick={handleClose} data-testid="cancelar">
        Cancelar
      </Button>
    </Display>
  </Modal>
);

export default ModalConfirmarAcaoRetencao;
