// @ts-nocheck
import { useState, useEffect } from 'react';
import { Text, Checkbox } from '@cvp/design-system/react';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { levelRisk } from 'previdencia/utils/riskStatus';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import InputDistribuicaoValorAplicacao from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/InputDistribuicaoValorAplicacao';
import InputValorReserva from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/InputValorReserva';
import * as FACTORY from 'previdencia/features/TransferenciaInternaEmissaoConjugado/factories/tabelaFundosInvestimentoDestinoFactory';
import * as CALC_UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblCalculos';
import * as UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblUtils';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as FUNC_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblFuncParams';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as S from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/TabelaFundosInvestimentoDestino/styles';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const TabelaFundosInvestimentoDestino = ({
  qtdFundosInvestimentoPermitidos,
  setContinuarTransfVgbl,
}: PROPS_TYPES.TabelaFundosInvestimentoDestinoProps): React.ReactElement => {
  const {
    atualizarListaFundosInvestimento,
    saldoTotalFundosAtuais,
    dadosOfertasVgblConjugado,
  } = useTransferenciaInternaContext();

  const [tabelaFundos, setTabelaFundos] = useState<
    | RESPONSE_TYPES.IObtencaoListaOfertasVgblConjugadoProdutosFundos[]
    | undefined
  >(dadosOfertasVgblConjugado?.produtos?.[0]?.fundos);

  const resumoFundosInvestimentoDestino =
    CALC_UTILS.calcularResumoFundosInvestimentoDestino({
      tabelaFundos,
      saldoTotalFundosAtuais,
    });

  const somaPercentualAplicacaoFundos: number =
    CALC_UTILS.obterPercentualAplicado(tabelaFundos);

  const isPercentualMaximo: boolean =
    somaPercentualAplicacaoFundos === CONSTS.VALOR_PORCENTAGEM.MAXIMO;

  const handleFundoSelecionado = ({
    codigoFundo,
  }: FUNC_TYPES.HandleFundoSelecionadoParams): void => {
    setTabelaFundos(prevFundos =>
      prevFundos?.map(fundo =>
        FACTORY.modificarFundoSelecionadoFactory({ fundo, codigoFundo }),
      ),
    );
  };

  const handleDistribuicaoValorAplicacao = ({
    codigoFundo,
    valorInput,
  }: FUNC_TYPES.HandleDistribuicaoValorAplicacaoParams): void => {
    setTabelaFundos(prevFundos =>
      prevFundos?.map(fundo =>
        FACTORY.distribuirValorAplicacaoFactory({
          fundo,
          codigoFundo,
          valorInput,
          isPercentualMaximo,
          valorTotalReserva: saldoTotalFundosAtuais,
          valorTotalDistribuido: CALC_UTILS.obterValorReserva(tabelaFundos),
          restoCalculoSaldoReservaRestante:
            resumoFundosInvestimentoDestino.restoCalculoSaldoReservaRestante,
        }),
      ),
    );
  };

  const transformarDadosTabelaFundosInvestimentoDestino = () => {
    return tabelaFundos?.map(item => ({
      ...item,
      descricaoFundo: (
        <>
          <Text variant="body02-sm">{item.descricaoFundo}</Text>
          <TRANSF_VGBL_STYLES.Text variant="body02-sm" fontWeight="bold">
            Tx {item.taxaAdministracao.replace('.', ',')}%
          </TRANSF_VGBL_STYLES.Text>
        </>
      ),
      check: (
        <Checkbox
          id={item.codigoFundo}
          disabled={checkIfAllItemsAreTrue([
            !item.selecionado,
            UTILS.obterFundosAtivos(tabelaFundos) ===
              Number(qtdFundosInvestimentoPermitidos),
          ])}
          checked={item.selecionado}
          onChange={() =>
            handleFundoSelecionado({ codigoFundo: item.codigoFundo })
          }
        />
      ),
      perfilRisco: <S.RiskBarFundos risk={levelRisk(item.perfilRiscoNivel)} />,
      aplicacao: (
        <InputDistribuicaoValorAplicacao
          inputItem={item}
          handleInput={handleDistribuicaoValorAplicacao}
          somaPercentualAplicacaoFundos={somaPercentualAplicacaoFundos}
        />
      ),
      valorReserva: <InputValorReserva inputItem={item} />,
    }));
  };

  const atualizarContextoFundosInvestimento = (): void => {
    setContinuarTransfVgbl(isPercentualMaximo);

    if (isPercentualMaximo) {
      atualizarListaFundosInvestimento(tabelaFundos);
    }
  };

  useEffect(atualizarContextoFundosInvestimento, [tabelaFundos]);

  return (
    <>
      <S.TabelaFundosInvestimento
        noHeader
        responsive
        striped
        highlightOnHover
        data={tryGetValueOrDefault(
          [transformarDadosTabelaFundosInvestimentoDestino()],
          [],
        )}
        columns={CONSTS.COLUNAS_TABELA_FUNDOS_INVESTIMENTO_DESTINO}
        noDataComponent={CONSTS.NAO_HA_DADOS_TABELA}
      />
      <TRANSF_VGBL_STYLES.Display
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
      >
        <S.TextoResumoTabela variant="body02-md" marginBottom={5}>
          Total aplicado{' '}
          <strong>
            {resumoFundosInvestimentoDestino.percentualTotalAplicado}%
          </strong>
          , corresponde a{' '}
          <strong>
            {resumoFundosInvestimentoDestino.saldoReservaAplicada}
          </strong>
        </S.TextoResumoTabela>

        <S.TextoResumoTabela variant="body02-md" marginTop={0}>
          Restante{' '}
          <strong>
            {resumoFundosInvestimentoDestino.percentualRestanteAplicado}%
          </strong>
          , corresponde a{' '}
          <strong>
            {resumoFundosInvestimentoDestino.saldoReservaRestante}
          </strong>
        </S.TextoResumoTabela>
      </TRANSF_VGBL_STYLES.Display>
    </>
  );
};

export default TabelaFundosInvestimentoDestino;
