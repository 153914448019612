// @ts-nocheck
import {
  Card,
  Disclaimer as DisclaimerBase,
  Display,
  Text,
  Accordion,
} from '@cvp/design-system/react';
import DataTable from 'react-data-table-component';
import { LinhaTempoSinistroDados } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';
import styled from 'styled-components/macro';

export const TextAttention = styled(Text)({
  marginBottom: '1.5rem',
});

export const TextResultado = styled(Text)({
  marginTop: '1.5rem',
});

export const CardFitContent = styled(Card)({
  width: 'fit-content',
});

export const TableSinistro = styled(DataTable<LinhaTempoSinistroDados>)(
  ({ theme: { color, font } }) => ({
    backgroundColor: 'transparent',
    overflow: 'visible',
    '.rdt_TableCol, .rdt_TableCell': {
      fontSize: font.size.sm,
      fontFamily: font.family.base,
    },
    '.rdt_TableCol': {
      fontWeight: font.weight.lg,
      '&:hover': {
        color: color.brandPrimary.light,
      },
    },
  }),
);

export const ContainerCardResultado = styled(Display)({
  display: 'block',
  minWidth: 400,
});

const AccordionItem = Accordion.Item;
export const AccordionItemSinistro = styled(AccordionItem)`
  & [class^='Accordionstyle__Body'] {
    background-color: transparent;
    overflow: ${({ open }: { open: boolean }) => (open ? 'visible' : 'hidden')};
  }
`;

export const Disclaimer = styled(DisclaimerBase)({
  padding: '12px 0 12px 16px',
  marginTop: '10px',
  button: {
    opacity: 0,
  },
  svg: {
    width: '22px',
    height: '22px',
  },
});

export const ContainerCertificados = styled.div`
  div {
    margin: 4px 2px;
  }
  span {
    margin: 4px 2px;
  }
  button {
    margin: 4px 2px;
  }
  hr {
    margin: 4px;
  }
`;

export const CertificadosText = styled.div(
  ({ theme: { color, font, line } }) => ({
    minWidth: 150,
    maxWidth: '52%', // '85%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    overflow: 'hidden',
    marginBottom: '10px',
    color: color.line.primary,
    fontSize: font.size.md,
    fontWeight: font.weight.md,
    lineHeight: line.height.lg,
  }),
);
export const IconContainer = styled.div`
  margin: 0 !important;
  svg {
    height: 20px;
  }
`;

export const TooltipLinhaDoTempoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
`;

export const TooltipLinhaDoTempo = styled.div`
  position: absolute;
  right: -5px;
  top: 0px;
  z-index: 1000;
`;

export const TooltipLinhaDoTempoIcom = styled.div`
  margin-left: 3px;
  padding-top: 6px;
`;
