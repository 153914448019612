// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as PECO_TYPES from 'previdencia/features/Aporte/types/AporteUsePeco';
import * as RESPONSE_TYPES from 'previdencia/features/Aporte/types/AporteResponse';
import * as REQUEST_TYPES from 'previdencia/features/Aporte/types/AporteRequest';

export const usePecoEfetuarAporte = (): PECO_TYPES.IUseEfetuarAporte => {
  const {
    cliente: { numCertificado, cpfCnpj },
  } = useContext(AppContext);

  const {
    response: dadosAporteAdicional,
    loading: loadingDadosAporteAdicional,
    fetchData: efetuarAporteAdicional,
  } = usePeco<
    Partial<REQUEST_TYPES.IEfetuarAportePayload>,
    RESPONSE_TYPES.IEfetuarAporteResponse
  >({
    api: { operationPath: PECOS.EfetuarAporte },
    payload: {
      CpfCnpj: cpfCnpj,
      NumeroCertificado: numCertificado,
    },
    handleResponse: { throwToastErrorBFF: false },
  });

  return {
    dadosAporteAdicional,
    loadingDadosAporteAdicional,
    efetuarAporteAdicional,
  };
};

export const usePecoDatasPagamento = (): PECO_TYPES.IUseDatasPagamento => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const {
    response: dadosDatasPagamento,
    loading: loadingDadosDatasPagamento,
    fetchData: buscarDatasPagamento,
  } = usePeco<Partial<REQUEST_TYPES.IDatasPagamentoPayload>, string[]>({
    api: { operationPath: PECOS.ObterDatas },
    autoFetch: true,
    cache: true,
    cacheKey: numCertificado,
    payload: {
      cpfCnpj,
      canalId: CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.PARAMETRO_DATA_PAGAMENTO,
    },
  });

  return {
    dadosDatasPagamento,
    loadingDadosDatasPagamento,
    buscarDatasPagamento,
  };
};

export const usePecoDadosBancarios = (): PECO_TYPES.IUseDadosBancarios => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const {
    response: dadosDatasBancarios,
    loading: loadingDadosDatasBancarios,
    fetchData: buscarDadosBancarios,
  } = usePeco<
    REQUEST_TYPES.IDadosBancariosPayload,
    RESPONSE_TYPES.IDadosBancariosResponse
  >({
    api: { operationPath: PECOS.ObterDadosBancarios },
    autoFetch: true,
    payload: {
      cpfCnpj,
      numeroCertificado: numCertificado,
    },
  });

  return {
    dadosDatasBancarios,
    loadingDadosDatasBancarios,
    buscarDadosBancarios,
  };
};

export const usePecoFundosDistribuicaoAporte =
  (): PECO_TYPES.IUseFundosDistribuicaoAporte => {
    const {
      cliente: { cpfCnpj, numCertificado },
    } = useContext(AppContext);

    const {
      response: dadosFundosDistribuicaoAporte,
      loading: loadingDadosFundosDistribuicaoAporte,
      fetchData: buscarFundosDistribuicaoAporte,
    } = usePeco<
      Partial<REQUEST_TYPES.IFundosDistribuicaoAportePayload>,
      RESPONSE_TYPES.IFundosDistribuicaoAporteResponse
    >({
      api: { operationPath: PECOS.ObterFundos },
      payload: {
        cpfCnpj,
        numeroCertificado: numCertificado,
      },
      handleResponse: { throwToastErrorBFF: false },
    });

    return {
      dadosFundosDistribuicaoAporte,
      loadingDadosFundosDistribuicaoAporte,
      buscarFundosDistribuicaoAporte,
    };
  };

export const usePecoValidarAporte = ({
  primeiroNome,
  finalCPF,
}: {
  primeiroNome: string;
  finalCPF: string;
}) => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const {
    response: validaAporteData,
    loading: validaAporteLoading,
    fetchData: validarAporteFetch,
  } = usePeco<
    REQUEST_TYPES.IVerificaCamposAportePayload,
    RESPONSE_TYPES.IVerificaCamposAporteResponse
  >({
    api: { operationPath: PECOS.ValidarAporte },
    payload: {
      codigoCertificado: numCertificado,
      cpfParticipante: finalCPF,
      nomeParticipante: primeiroNome,
    },
    autoFetch: false,
  });

  return {
    validaAporteData,
    validaAporteLoading,
    validarAporteFetch,
  };
};
