// @ts-nocheck
import React, { useState } from 'react';
import { Display, Modal, Button, Text } from '@cvp/design-system/react';
import InputFile, {
  DescriptionFormatsFilesAllowed,
} from 'main/components/form/InputFile';
import useFieldLink from 'main/hooks/useFieldLink';
import {
  filesFormatsAllowed,
  maxFileSizeAllowed,
  requiredFile,
} from 'main/features/Validation/validations';
import { validate } from 'main/features/Validation/utils/validateRules';
import { useHistory } from 'react-router';
import InstrucoesRegerarPdf from 'main/components/InstrucoesRegerarPdf';
import RenderConditional from 'main/components/RenderConditional';
import { IDetalhesProspeccao } from '../types/IDetalhesProspeccao';
import { useProspeccao } from '../hooks/useProspeccao';

type ModalUploadDpsProps = {
  open: boolean;
  handleClose(close: boolean): void;
  prospeccao: IDetalhesProspeccao;
};

const ModalUploadDps: React.FunctionComponent<ModalUploadDpsProps> = ({
  open,
  handleClose,
  prospeccao,
}) => {
  const history = useHistory();
  const [arquivoDps, validateArquivoDps] = useFieldLink<FileList>(
    {} as FileList,
  );
  const { loadingUploadProspeccao, realizarUploadDps } = useProspeccao();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const handleCloseModal = () => {
    arquivoDps.set({ value: {} as FileList, isValid: false });
    history.push('/prestamista/preanalises');
    handleClose(false);
    setShowErrorModal(false);
  };

  const handleUpload = async () => {
    const arquivo = arquivoDps.get().value;
    if (validate([validateArquivoDps])) {
      const result = await realizarUploadDps(
        arquivo,
        prospeccao.id,
        prospeccao.cpfCnpjCliente,
      );
      if (result) {
        handleCloseModal();
        setShowErrorModal(false);
      } else {
        setShowErrorModal(true);
      }
    }
  };

  const conteudoUpload = (
    <>
      <Text variant="body02-sm" margin>
        Arquivo da Declaração Pessoal de Saúde (DPS)
      </Text>
      <DescriptionFormatsFilesAllowed fileSize="5" />
      {loadingUploadProspeccao ? (
        <Button variant="text" loading />
      ) : (
        <Display justify="center">
          <div>
            <InputFile
              link={arquivoDps}
              validationRules={[
                requiredFile(),
                filesFormatsAllowed(),
                maxFileSizeAllowed(),
              ]}
            />
            <Display style={{ marginTop: '2rem ' }}>
              <Button
                type="click"
                variant="primary"
                onClick={handleUpload}
                data-testid="confirmar"
              >
                Enviar
              </Button>
              <Button
                variant="secondary"
                onClick={handleCloseModal}
                data-testid="cancelar"
              >
                Voltar
              </Button>
            </Display>
          </div>
        </Display>
      )}
    </>
  );

  const conteudoErroUpload = (
    <>
      <Text variant="body02-md" margin>
        FALHA AO INCLUIR A
        <br />
        DECLARAÇÃO PESSOAL DE SAÚDE (DPS)
      </Text>
      <Text variant="body02-sm" margin align="left">
        <InstrucoesRegerarPdf />
      </Text>
    </>
  );

  return (
    <Modal
      show={open}
      onClose={handleCloseModal}
      data-testid="modalUploadArquivo"
    >
      <RenderConditional condition={!showErrorModal}>
        {conteudoUpload}
      </RenderConditional>
      <RenderConditional condition={showErrorModal}>
        {conteudoErroUpload}
      </RenderConditional>
    </Modal>
  );
};

export default ModalUploadDps;
