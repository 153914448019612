// @ts-nocheck
import React, { useState } from 'react';
import {
  Display,
  Card,
  Text,
  Grid,
  Divider,
  TextField,
  Button,
  Modal,
  Accordion,
  Select,
} from '@cvp/design-system/react';
import { TitleSection, TextLabel } from 'main/styles/GlobalStyle';
import { Field, Formik } from 'formik';
import masks from 'main/utils/masks';
import { TextArea } from 'main/components/form';
import { useToast } from 'main/hooks/useToast';
import {
  selectOptionsFilial,
  selectOptionsRegra,
  selectOptionsBeneficio,
  selectOptionsPrazoBeneficio,
  selectOptionsFaturamento,
  selectOptionsReversao,
  selectOptionsPagamento,
  selectOptionsValoresParticipantes,
  selectOptionsValorContribuicao,
  selectOptionsCuidadoExtra,
  selectOptionsRegraCuidado63,
  selectOptionsRegraCuidadoPensao,
  selectOptionsTipoFundo,
  selectOptionsConcessaoBeneficio,
  selectOptionsPagamentoFatura,
  selectOptionsOperacao,
  selectOptionsDadosCobranca,
  selectOptionsVencimentoFatura,
  selectOptionsModalidade,
  selectOptionsModalidadePGBL,
  selectOptionsModalidadeVGBL,
  selectOptionsUF,
  selectOptionsRegraAporte,
  selectOptLiberacaoDaReserva,
} from 'extranet/types/enum';
import { useFormFops } from 'extranet/hooks/useFormFops';
import fopsValidation63, {
  validarArquivoDownload,
} from 'extranet/utils/fopsValidation63';
import { api } from 'main/services';
import InputFile, {
  DescriptionFormatsFilesAllowed,
} from 'main/components/form/InputFile';
import { enviarFormulario } from 'extranet/services/obterEndpointArquivo';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { DownloadButton } from 'main/features/prospeccao/components/styles';
import Icon from 'main/components/Icon';
import { useHistory, useLocation } from 'react-router-dom';
import { ParamsLocationData } from 'extranet/types/IResponseObterListaFopsAtivos';
import * as S from './styles';
import tabelaVesting from '../../../img/Tabela_de_Vesting.png';

const Fop063: React.FC = () => {
  const {
    openSelectTipoBenficio,
    openSelectReversivel,
    openSelectPlanoInstituido,
    openSelectValoresParticipantesCuidado,
    openSelectValorContribuicao,
    openSelectValorContribuicaoCuidadoExtra,
    openSelectValorFixo,
    openSelectValorFixoCuidadoExtra,
    openSelectValorFixoEmpresa,
    openSelectValorFixoEmpresaCuidadoExtra,
    openSelectValorFixoFuncionario,
    openSelectValorPercentualCuidadoExtra,
    openSelectValorFixoFuncionarioCuidadoExtra,
    openSelectValorPercentual,
    openSelectValorPercentualEmpresa,
    openSelectValorPercentualEmpresaCuidadoExtra,
    openSelectValorPercentualFuncionario,
    openSelectValorPercentualFuncionarioCuidadoExtra,
    openSelectOutraFormaPagamento,
    openSelectPlanoInstituidoCuidadoExtra,
    openSelectPrazoBenficio,
    openSelectOutraFormaPagamentoCuidadoExtra,
    openSelectValoresParticipantes,
    openSelectPeculio,
    openSelectPensao,
    openSelectFormaPagamentoCuidado,
    openSelectOutraFormaPagamentoEmpresa,
    openSelectOutraFormaPagamentoEmpresaCuidadoExtra,
    openSelectOutraFormaPagamentoFuncionario,
    openSelectOutraFormaPagamentoFuncionarioCuidadoExtra,
    openSelectTipoModalidade,
    openSelectTipoModalidadePGBL,
    openSelectTipoModalidadeVGBL,
    openSelectLiberacaoReserva,
    openSelectSugestaoLiberacao,
    openSelectOutraFormaLiberacao,
    openSelectTipoAporte,
    openSelectPortabilidade,
    openSelectComAporte,
    openSelectIdadeAposentadoria,
    openSelectPrazoContribuicao,
    openSelectAporteUnico,
    openSelectDiaVencimento,
    openSelectDebitoEmConta,
    textOutraFormaPagamento,
    textOutraFormaPgEmpresa,
    textOutraFormaPagamentoEmpresaCuidadoExtra,
    textOutraFormaPagamentoFuncionario,
    textOutraRegraLiberacaoDaReserva,
    textOutraFormaPagamentoFuncionarioCuidadoExtra,
    textOutraFormaPagamentoCuidadoExtra,
    textInformacoesComplementares,
    openModalFormulario,
    loadingDownload,
    arquivoAnexoFop63,
    regraFiles,
    baixarArquivoFop,
    openModal,
    fecharModal,
    setTextInformacoesComplementares,
    setTextOutraFormaPagamentoCuidadoExtra,
    setTextOutraFormaPgEmpresa,
    setTextOutraFormaPagamentoFuncionarioCuidadoExtra,
    setTextOutraFormaPagamentoFuncionario,
    setTextOutraFormaPagamentoEmpresaCuidadoExtra,
    setTextOutraFormaPagamento,
    setTextOutraRegraLiberacaoDaReserva,
    selectRegraCalculo,
    selectFormaPagamentoCuidado,
    selectBeneficio,
    selectValoresParticipantes,
    selectValoresParticipantesCuidado,
    selectValorContribuicao,
    selectValorContribuicaoCuidadoExtra,
    selectCuidadoExtra,
    selectValorContribuicaoEmpresa,
    selectValorContribuicaoEmpresaCuidadoExtra,
    selectValorContribuicaoFuncionario,
    selectValorContribuicaoFuncionarioCuidadoExtra,
    selectFormaPagamentoFop63,
    selecLiberacaoReserva,
    selectRegraAporte,
    selectTipoConcessao,
    selecTipoPagamentoFatura,
    selectDadosCobranca,
  } = useFormFops();
  const location = useLocation<ParamsLocationData>();
  const [loading, setLoading] = useState<boolean>(false);
  const { dataLocation } = location.state;
  const { toastError, toastSuccess } = useToast();
  const history = useHistory();

  const enviarFop = async (
    codigoFop: number,
    numeroVersaoFop: number,
    nomeSolicitante: string,
    emailSolicitante: string,
    metadados: any,
  ) => {
    const metadadosSolicitacao = JSON.stringify(metadados);
    const data = await enviarFormulario(
      codigoFop,
      numeroVersaoFop,
      nomeSolicitante,
      emailSolicitante,
      metadadosSolicitacao,
    );
    setLoading(true);
    if (data?.dados?.mensagens) {
      if (data?.dados?.sucesso) {
        toastSuccess((data?.dados?.mensagens[0] || []).descricao);
        setTimeout(() => {
          history.push({
            pathname: `/extranet/fop-previdencia`,
          });
        }, 0);
      }
      setLoading(false);
    } else {
      toastError('Erro ao registrar FOP.');
      setLoading(false);
    }
  };

  const handleFormSubmit = async (values: any) => {
    const arquivo = arquivoAnexoFop63.get().value;

    const data = new FormData();
    const file = arquivo[0];
    data.append('Arquivo', file);
    data.append('codigoFop', '63');
    data.append('numeroVersaoFop', '11');

    const response: any = await api.post(
      '/PortalEconomiario/PECO_EnviarArquivoSolicitacaoFOP',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    const solicitacao = {
      ...values,
      idunicosolicitacaofop: response?.data?.dados?.entidade
        ?.idUnicoSolicitacaoFop
        ? response?.data?.dados?.entidade?.idUnicoSolicitacaoFop
        : '',
      nomeEmpresa: values.nomeDaEmpresa,
      cnpj: values.cnpjEmpresa,
      faturamento: selectOptionsFaturamento.find(
        x => x.key === values.faturamento,
      )?.value,
      uf: selectOptionsUF.find(x => x.key === values.uf)?.value,
      agenciaFilial: selectOptionsFilial.find(
        x => x.key === values.agenciaFilial,
      )?.value,
      aporteInicial: selectOptionsRegraAporte.find(
        x => x.key === values.aporteInicial,
      )?.value,
      formaDePagamento: selectOptionsPagamento.find(
        x => x.key === values.formaDePagamento,
      )?.value,
      linkSelectModalidade: selectOptionsModalidade.find(
        x => x.key === values.linkSelectModalidade,
      )?.value,
      linkSelectModalidadePGBL: selectOptionsModalidadePGBL.find(
        x => x.key === values.linkSelectModalidadePGBL,
      )?.value,
      linkSelectModalidadeVGBL: selectOptionsModalidadeVGBL.find(
        x => x.key === values.linkSelectModalidadeVGBL,
      )?.value,
      liberacaoDaReserva: selectOptLiberacaoDaReserva.find(
        x => x.key === values.liberacaoDaReserva,
      )?.value,
      regraCalculo: selectOptionsRegra.find(x => x.key === values.regraCalculo)
        ?.value,
      tipoBeneficioBasico: selectOptionsBeneficio.find(
        x => x.key === values.tipoBeneficioBasico,
      )?.value,
      prazoBeneficio: selectOptionsPrazoBeneficio.find(
        x => x.key === values.prazoBeneficio,
      )?.value,
      reversao: selectOptionsReversao.find(x => x.key === values.reversao)
        ?.value,
      optionsValoresParticipante: selectOptionsValoresParticipantes.find(
        x => x.key === values.optionsValoresParticipante,
      )?.value,
      linkValorContribuicao: selectOptionsValorContribuicao.find(
        x => x.key === values.linkValorContribuicao,
      )?.value,
      linkValorContribuicaoEmpresa: selectOptionsValorContribuicao.find(
        x => x.key === values.linkValorContribuicaoEmpresa,
      )?.value,
      linkValorContribuicaoFuncionario: selectOptionsValorContribuicao.find(
        x => x.key === values.linkValorContribuicaoFuncionario,
      )?.value,
      linkSelectCuidadoExtra: selectOptionsCuidadoExtra.find(
        x => x.key === values.linkSelectCuidadoExtra,
      )?.value,
      linkSelectRegraCuidadoExtraPensao: selectOptionsRegraCuidadoPensao.find(
        x => x.key === values.linkSelectRegraCuidadoExtraPensao,
      )?.value,
      formaDePagamentoCuidado: selectOptionsPagamento.find(
        x => x.key === values.formaDePagamentoCuidado,
      )?.value,
      linkSelectRegraCuidadoExtraPeculio: selectOptionsRegraCuidado63.find(
        x => x.key === values.linkSelectRegraCuidadoExtraPeculio,
      )?.value,
      linkValorContribuicaoCuidadoExtra: selectOptionsValorContribuicao.find(
        x => x.key === values.linkValorContribuicaoCuidadoExtra,
      )?.value,
      linkValorContribuicaoEmpresaCuidadoExtra:
        selectOptionsValorContribuicao.find(
          x => x.key === values.linkValorContribuicaoEmpresaCuidadoExtra,
        )?.value,
      linkValorContribuicaoFuncionarioCuidadoExtra:
        selectOptionsValorContribuicao.find(
          x => x.key === values.linkValorContribuicaoFuncionarioCuidadoExtra,
        )?.value,
      linkSelectTipoFundo: selectOptionsTipoFundo.find(
        x => x.key === values.linkSelectTipoFundo,
      )?.value,
      linkSelectTipoConcessao: selectOptionsConcessaoBeneficio.find(
        x => x.key === values.linkSelectTipoConcessao,
      )?.value,
      linkSelectTipoPagamentoFatura: selectOptionsPagamentoFatura.find(
        x => x.key === values.linkSelectTipoPagamentoFatura,
      )?.value,
      linkSelectVencimentoFatura: selectOptionsVencimentoFatura.find(
        x => x.key === values.linkSelectVencimentoFatura,
      )?.value,
      linkSelectDadosCobranca: selectOptionsDadosCobranca.find(
        x => x.key === values.linkSelectDadosCobranca,
      )?.value,
      linkSelectDadosOperacao: selectOptionsOperacao.find(
        x => x.key === values.linkSelectDadosOperacao,
      )?.value,
      textOutraRegraLiberacaoDaReserva,
      textOutraFormaPagamento,
      textOutraFormaPgEmpresa,
      textOutraFormaPagamentoEmpresaCuidadoExtra,
      textOutraFormaPagamentoFuncionario,
      textOutraFormaPagamentoFuncionarioCuidadoExtra,
      textOutraFormaPagamentoCuidadoExtra,
      textInformacoesComplementares,
    };
    enviarFop(
      63,
      11,
      values.nomeCompletoResponsavel,
      values.emailResponsavel,
      solicitacao,
    );
  };

  return (
    <Display type="display-block">
      <Formik
        initialValues={{
          nomeDaEmpresa: '',
          atividadePrincipalEmpresa: '',
          cnpjEmpresa: '',
          faturamento: '',
          emailInstitucional: '',
          logradouro: '',
          bairro: '',
          cidade: '',
          cep: '',
          uf: '',
          agenciaFilial: '',
          agenciaSr: '',
          numeroDaAgencia: '',
          nomeDaAgencia: '',
          matriculaDoIndicador: '',
          nomeDoIndicador: '',
          nomeCompletoResponsavel: '',
          emailResponsavel: '',
          telefoneResponsavel: '',
          numeroDeParticipantes: '',
          regraCalculo: '',
          formaDePagamento: '',
          formaDePagamentoCuidado: '',
          linkSelectRegraCuidadoExtraPeculio: '',
          tipoBeneficioBasico: '',
          prazoBeneficio: '',
          reversao: '',
          optionsValoresParticipantes: '',
          valoresParticipantesCuidado: '',
          valorFixoContribuicao: '',
          valorPercentualContribuicao: '',
          valorFixoEmpresaContribuicao: '',
          linkValorContribuicao: '',
          linkSelectTipoConcessao: '',
          linkSelectTipoPagamentoFatura: '',
          linkSelectDadosCobranca: '',
          linkSelectCuidadoExtra: '',
          linkValorContribuicaoCuidadoExtra: '',
          linkValorContribuicaoEmpresa: '',
          linkValorContribuicaoEmpresaCuidadoExtra: '',
          linkValorContribuicaoFuncionario: '',
          linkValorContribuicaoFuncionarioCuidadoExtra: '',
          linkSelectTipoFundo: '',
          linkSelectVencimentoFatura: '',
          linkSelectDadosOperacao: '',
          linkSelectModalidade: '',
          linkSelectModalidadePGBL: '',
          linkSelectModalidadeVGBL: '',
          linkSelectRegraCuidadoExtraPensao: '',
          linkSelectAnosPensao: '',
          valorFixoEmpresaCuidadoExtraContribuicao: '',
          valorFixoFuncionarioContribuicao: '',
          valorFixoFuncionarioCuidadoExtraContribuicao: '',
          valorPercentualEmpresaContribuicao: '',
          valorPercentualEmpresaCuidadoExtraContribuicao: '',
          valorPercentualFuncionarioContribuicao: '',
          valorPercentualFuncionarioCuidadoExtraContribuicao: '',
          valorFixoCuidadoExtraContribuicao: '',
          valorPercentualCuidadoExtraContribuicao: '',
          nomeRepresentante: '',
          emailRepresentante: '',
          cargoRepresentante: '',
          aporteInicial: '',
          valorAporteInicial: '',
          liberacaoDaReserva: '',
          valorPortabilidade: '',
          prazoContribuicao: '',
          idadeAposentadoria: '',
          aporteUnico: '',
          agencia: '',
          conta: '',
        }}
        validationSchema={fopsValidation63}
        onSubmit={values => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          handleBlur,
          values,
          errors,
          setFieldValue,
          resetForm,
        }) => (
          <Card>
            <Card.Content padding={[4, 4, 4]}>
              <Text variant="body-medium3" color="text-light">
                *Atenção: Todos os campos são obrigatórios!
              </Text>
              <br />
              <Grid>
                <Grid.Item xs={1}>
                  <Text
                    variant="headline-05"
                    color="primary"
                    key="formulario-titulo"
                  >
                    FOP 063 - Solicitação de Contrato de Prev Empresarial
                  </Text>
                </Grid.Item>
              </Grid>
              <Divider />
              <TitleSection> 1. Informações Gerais </TitleSection>
              <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                <>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="nomeDaEmpresa"
                      label="*Nome da empresa"
                      component={TextField}
                      value={values.nomeDaEmpresa}
                      error={errors.nomeDaEmpresa}
                      errorMessage={errors.nomeDaEmpresa}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('nomeDaEmpresa', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="atividadePrincipalEmpresa"
                      label="*Atividade principal"
                      component={TextField}
                      value={values.atividadePrincipalEmpresa}
                      error={errors.atividadePrincipalEmpresa}
                      errorMessage={errors.atividadePrincipalEmpresa}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('atividadePrincipalEmpresa', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="cnpjEmpresa"
                      label="*CNPJ"
                      component={TextField}
                      value={masks.cnpj.mask(values.cnpjEmpresa)}
                      error={errors.cnpjEmpresa}
                      errorMessage={errors.cnpjEmpresa}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('cnpjEmpresa', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="faturamento"
                      label="*Faturamento"
                      component={Select}
                      placeholder="Selecione Faturamento"
                      value={values.faturamento}
                      error={errors.faturamento}
                      errorMessage={errors.faturamento}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('faturamento', value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsFaturamento.map(optFaturamento => (
                        <Select.Item
                          key={optFaturamento.key}
                          value={optFaturamento.key}
                          text={optFaturamento.value}
                          selected={optFaturamento.key === values.faturamento}
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="emailInstitucional"
                      label="*E-mail institucional"
                      component={TextField}
                      value={values.emailInstitucional}
                      error={errors.emailInstitucional}
                      errorMessage={errors.emailInstitucional}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('emailInstitucional', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="logradouro"
                      label="*Logradouro"
                      component={TextField}
                      value={values.logradouro}
                      error={errors.logradouro}
                      errorMessage={errors.logradouro}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('logradouro', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="bairro"
                      label="*Bairro"
                      component={TextField}
                      value={values.bairro}
                      error={errors.bairro}
                      errorMessage={errors.bairro}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('bairro', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1 / 3}>
                    <Field
                      name="cidade"
                      label="*Cidade"
                      component={TextField}
                      value={values.cidade}
                      error={errors.cidade}
                      errorMessage={errors.cidade}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('cidade', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="uf"
                      label="*UF"
                      component={Select}
                      placeholder="Selecione UF"
                      value={values.uf}
                      error={errors.uf}
                      errorMessage={errors.uf}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('uf', value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsUF.map(optUF => (
                        <Select.Item
                          key={optUF.key}
                          value={optUF.key}
                          text={optUF.value}
                          selected={optUF.key === values.uf}
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <Grid.Item xs={1 / 3}>
                    <Field
                      name="cep"
                      label="*CEP"
                      component={TextField}
                      value={masks.cep.mask(values.cep)}
                      error={errors.cep}
                      errorMessage={errors.cep}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('cep', value);
                      }}
                      onBlur={handleBlur}
                    />
                    <br />
                    <TitleSection>Dados do Representante</TitleSection>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="nomeRepresentante"
                      label="*Nome Representante"
                      component={TextField}
                      value={values.nomeRepresentante}
                      error={errors.nomeRepresentante}
                      errorMessage={errors.nomeRepresentante}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('nomeRepresentante', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="emailRepresentante"
                      label="*E-mail Representante"
                      component={TextField}
                      value={values.emailRepresentante}
                      error={errors.emailRepresentante}
                      errorMessage={errors.emailRepresentante}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('emailRepresentante', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="cargoRepresentante"
                      label="*Cargo Representante"
                      component={TextField}
                      value={values.cargoRepresentante}
                      error={errors.cargoRepresentante}
                      errorMessage={errors.cargoRepresentante}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('cargoRepresentante', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>

                  <Divider />
                  <TitleSection>2.Dados da Agência</TitleSection>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="agenciaFilial"
                      label="*Filial"
                      component={Select}
                      placeholder="Selecione"
                      value={values.agenciaFilial}
                      error={errors.agenciaFilial}
                      errorMessage={errors.agenciaFilial}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('agenciaFilial', value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsFilial.map(optFilial => (
                        <Select.Item
                          key={optFilial.key}
                          value={optFilial.key}
                          text={optFilial.value}
                          selected={optFilial.key === values.agenciaFilial}
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <Grid.Item xs={1 / 2} lg={1 / 4}>
                    <Field
                      name="agenciaSr"
                      label="*Superintendência regional"
                      component={TextField}
                      value={values.agenciaSr}
                      error={errors.agenciaSr}
                      maxLength={4}
                      errorMessage={errors.agenciaSr}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('agenciaSr', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 4}>
                    <Field
                      name="numeroDaAgencia"
                      label="*Número da agência"
                      component={TextField}
                      maxLength={4}
                      value={values.numeroDaAgencia}
                      error={errors.numeroDaAgencia}
                      errorMessage={errors.numeroDaAgencia}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('numeroDaAgencia', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="nomeDaAgencia"
                      label="*Nome da agência"
                      component={TextField}
                      value={values.nomeDaAgencia}
                      error={errors.nomeDaAgencia}
                      errorMessage={errors.nomeDaAgencia}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('nomeDaAgencia', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1 / 2} lg={1 / 3}>
                    <Field
                      name="matriculaDoIndicador"
                      label="*Matrícula do Indicador"
                      component={TextField}
                      value={values.matriculaDoIndicador}
                      error={errors.matriculaDoIndicador}
                      errorMessage={errors.matriculaDoIndicador}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('matriculaDoIndicador', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1 / 2}>
                    <Field
                      name="nomeDoIndicador"
                      label="*Nome do Indicador"
                      component={TextField}
                      value={values.nomeDoIndicador}
                      error={errors.nomeDoIndicador}
                      errorMessage={errors.nomeDoIndicador}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('nomeDoIndicador', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Divider />
                  <TitleSection>3.Benefício Basico</TitleSection>
                  <Grid.Item xs={1} lg={1 / 4}>
                    <Field
                      name="numeroDeParticipantes"
                      label="*Número de participantes"
                      component={TextField}
                      value={values.numeroDeParticipantes}
                      error={errors.numeroDeParticipantes}
                      errorMessage={errors.numeroDeParticipantes}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('numeroDeParticipantes', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="aporteInicial"
                      label="*Aporte Inicial?"
                      component={Select}
                      placeholder="Selecione"
                      value={values.aporteInicial}
                      error={errors.aporteInicial}
                      errorMessage={errors.aporteInicial}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('aporteInicial', value);
                        selectRegraAporte(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsRegraAporte.map(optRegraAporte => (
                        <Select.Item
                          key={optRegraAporte.key}
                          value={optRegraAporte.key}
                          text={optRegraAporte.value}
                          selected={optRegraAporte.key === values.aporteInicial}
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectTipoAporte}>
                    <>
                      <RenderConditional condition={openSelectComAporte}>
                        <Grid.Item xs={1 / 2}>
                          <Field
                            name="valorAporteInicial"
                            label="*Valor do Aporte Inicial"
                            component={TextField}
                            value={values.valorAporteInicial}
                            error={errors.valorAporteInicial}
                            errorMessage={errors.valorAporteInicial}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(
                                'valorAporteInicial',
                                masks.currencyInput.mask(value),
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                      </RenderConditional>
                      <RenderConditional condition={openSelectPortabilidade}>
                        <Grid.Item xs={1 / 2}>
                          <Field
                            name="valorPortabilidade"
                            label="*Valor da Portabilidade"
                            component={TextField}
                            value={values.valorPortabilidade}
                            error={errors.valorPortabilidade}
                            errorMessage={errors.valorPortabilidade}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(
                                'valorPortabilidade',
                                masks.currencyInput.mask(value),
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                      </RenderConditional>
                    </>
                  </RenderConditional>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="formaDePagamento"
                      label="*Forma de pagamento"
                      component={Select}
                      placeholder="Selecione"
                      value={values.formaDePagamento}
                      error={errors.formaDePagamento}
                      errorMessage={errors.formaDePagamento}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('formaDePagamento', value);
                        selectFormaPagamentoFop63(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsPagamento.map(optPagamento => (
                        <Select.Item
                          key={optPagamento.key}
                          value={optPagamento.key}
                          text={optPagamento.value}
                          selected={
                            optPagamento.key === values.formaDePagamento
                          }
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectTipoModalidade}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="linkSelectModalidade"
                        label="*Modalidade do Plano"
                        component={Select}
                        placeholder="Selecione"
                        value={values.linkSelectModalidade}
                        error={errors.linkSelectModalidade}
                        errorMessage={errors.linkSelectModalidade}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue('linkSelectModalidade', value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptionsModalidade.map(optModalidade => (
                          <Select.Item
                            key={optModalidade.key}
                            value={optModalidade.key}
                            text={optModalidade.value}
                            selected={
                              optModalidade.key === values.linkSelectModalidade
                            }
                          />
                        ))}
                      </Field>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectTipoModalidadePGBL}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="linkSelectModalidadePGBL"
                        label="*Modalidade do Plano"
                        component={Select}
                        placeholder="Selecione"
                        value={values.linkSelectModalidadePGBL}
                        error={errors.linkSelectModalidadePGBL}
                        errorMessage={errors.linkSelectModalidadePGBL}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue('linkSelectModalidadePGBL', value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptionsModalidadePGBL.map(optModalidadePGBL => (
                          <Select.Item
                            key={optModalidadePGBL.key}
                            value={optModalidadePGBL.key}
                            text={optModalidadePGBL.value}
                            selected={
                              optModalidadePGBL.key ===
                              values.linkSelectModalidadePGBL
                            }
                          />
                        ))}
                      </Field>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectTipoModalidadeVGBL}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="linkSelectModalidadeVGBL"
                        label="*Modalidade do Plano"
                        component={Select}
                        placeholder="Selecione"
                        value={values.linkSelectModalidadeVGBL}
                        error={errors.linkSelectModalidadeVGBL}
                        errorMessage={errors.linkSelectModalidadeVGBL}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue('linkSelectModalidadeVGBL', value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptionsModalidadeVGBL.map(optModalidadeVGBL => (
                          <Select.Item
                            key={optModalidadeVGBL.key}
                            value={optModalidadeVGBL.key}
                            text={optModalidadeVGBL.value}
                            selected={
                              optModalidadeVGBL.key ===
                              values.linkSelectModalidadeVGBL
                            }
                          />
                        ))}
                      </Field>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectLiberacaoReserva}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="liberacaoDaReserva"
                        label="*Liberação da reserva"
                        component={Select}
                        placeholder="Selecione"
                        value={values.liberacaoDaReserva}
                        error={errors.liberacaoDaReserva}
                        errorMessage={errors.liberacaoDaReserva}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue('liberacaoDaReserva', value);
                          selecLiberacaoReserva(value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptLiberacaoDaReserva.map(
                          optLiberacaoDaReserva => (
                            <Select.Item
                              key={optLiberacaoDaReserva.key}
                              value={optLiberacaoDaReserva.key}
                              text={optLiberacaoDaReserva.value}
                              selected={
                                optLiberacaoDaReserva.key ===
                                values.liberacaoDaReserva
                              }
                            />
                          ),
                        )}
                      </Field>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectSugestaoLiberacao}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <img
                        src={tabelaVesting}
                        data-testid="InvestidorPF-cvp"
                        alt="InvestidorPF"
                        width="400"
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectOutraFormaLiberacao}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <p>
                        Descreva neste espaço como será a outra forma de
                        liberação da reserva
                      </p>
                      <TextArea
                        spellCheck
                        value={textOutraRegraLiberacaoDaReserva}
                        placeholder=""
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{ value: string }>) =>
                          setTextOutraRegraLiberacaoDaReserva(value)
                        }
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="regraCalculo"
                      label="*Selecione a regra para o cálculo"
                      component={Select}
                      placeholder="Selecione"
                      value={values.regraCalculo}
                      error={errors.regraCalculo}
                      errorMessage={errors.regraCalculo}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('regraCalculo', value);
                        selectRegraCalculo(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsRegra.map(optRegra => (
                        <Select.Item
                          key={optRegra.key}
                          value={optRegra.key}
                          text={optRegra.value}
                          selected={optRegra.key === values.regraCalculo}
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectTipoBenficio}>
                    <>
                      <Grid.Item xs={1} lg={1 / 3}>
                        <Field
                          name="tipoBeneficioBasico"
                          label="*Tipo de Benefício Básico"
                          component={Select}
                          placeholder="Selecione"
                          value={values.tipoBeneficioBasico}
                          error={errors.tipoBeneficioBasico}
                          errorMessage={errors.tipoBeneficioBasico}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{
                            text: string;
                            value: string;
                          }>) => {
                            setFieldValue('tipoBeneficioBasico', value);
                            selectBeneficio(value);
                          }}
                          onBlur={handleBlur}
                        >
                          {selectOptionsBeneficio.map(optBeneficio => (
                            <Select.Item
                              key={optBeneficio.key}
                              value={optBeneficio.key}
                              text={optBeneficio.value}
                              selected={
                                optBeneficio.key === values.tipoBeneficioBasico
                              }
                            />
                          ))}
                        </Field>
                      </Grid.Item>
                      <RenderConditional condition={openSelectPrazoBenficio}>
                        <Grid.Item xs={1} lg={1 / 3}>
                          <Field
                            name="prazoBeneficio"
                            label="*Prazo de Benefício Básico"
                            component={Select}
                            placeholder="Selecione"
                            value={values.prazoBeneficio}
                            error={errors.prazoBeneficio}
                            errorMessage={errors.prazoBeneficio}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<{
                              text: string;
                              value: string;
                            }>) => {
                              setFieldValue('prazoBeneficio', value);
                            }}
                            onBlur={handleBlur}
                          >
                            {selectOptionsPrazoBeneficio.map(
                              optPrazoBeneficio => (
                                <Select.Item
                                  key={optPrazoBeneficio.key}
                                  value={optPrazoBeneficio.key}
                                  text={optPrazoBeneficio.value}
                                  selected={
                                    optPrazoBeneficio.key ===
                                    values.prazoBeneficio
                                  }
                                />
                              ),
                            )}
                          </Field>
                        </Grid.Item>
                      </RenderConditional>
                      <RenderConditional condition={openSelectReversivel}>
                        <Grid.Item xs={1} lg={1 / 3}>
                          <Field
                            name="reversao"
                            label="% Reversão"
                            component={Select}
                            placeholder="Selecione"
                            value={values.reversao}
                            error={errors.reversao}
                            errorMessage={errors.reversao}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<{
                              text: string;
                              value: string;
                            }>) => {
                              setFieldValue('reversao', value);
                            }}
                            onBlur={handleBlur}
                          >
                            {selectOptionsReversao.map(optReversao => (
                              <Select.Item
                                key={optReversao.key}
                                value={optReversao.key}
                                text={optReversao.value}
                                selected={optReversao.key === values.reversao}
                              />
                            ))}
                          </Field>
                        </Grid.Item>
                      </RenderConditional>
                    </>
                  </RenderConditional>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="optionsValoresParticipantes"
                      label="*Os valores são iguais para todos os participantes?"
                      component={Select}
                      placeholder="Selecione"
                      value={values.optionsValoresParticipantes}
                      error={errors.optionsValoresParticipantes}
                      errorMessage={errors.optionsValoresParticipantes}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('optionsValoresParticipantes', value);
                        selectValoresParticipantes(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsValoresParticipantes.map(
                        optValoresParticipantes => (
                          <Select.Item
                            key={optValoresParticipantes.key}
                            value={optValoresParticipantes.key}
                            text={optValoresParticipantes.value}
                            selected={
                              optValoresParticipantes.key ===
                              values.optionsValoresParticipantes
                            }
                          />
                        ),
                      )}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectValorContribuicao}>
                    <>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="linkValorContribuicao"
                          label="*Valor da contribuição"
                          component={Select}
                          placeholder="Selecione"
                          value={values.linkValorContribuicao}
                          error={errors.linkValorContribuicao}
                          errorMessage={errors.linkValorContribuicao}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{
                            text: string;
                            value: string;
                          }>) => {
                            setFieldValue('linkValorContribuicao', value);
                            selectValorContribuicao(value);
                          }}
                          onBlur={handleBlur}
                        >
                          {selectOptionsValorContribuicao.map(
                            optValorContribuicao => (
                              <Select.Item
                                key={optValorContribuicao.key}
                                value={optValorContribuicao.key}
                                text={optValorContribuicao.value}
                                selected={
                                  optValorContribuicao.key ===
                                  values.linkValorContribuicao
                                }
                              />
                            ),
                          )}
                        </Field>
                      </Grid.Item>
                      <RenderConditional condition={openSelectValorFixo}>
                        <Grid.Item xs={1} lg={1 / 2}>
                          <Field
                            name="valorFixoContribuicao"
                            label="*Valor Fixo"
                            component={TextField}
                            value={values.valorFixoContribuicao}
                            error={errors.valorFixoContribuicao}
                            errorMessage={errors.valorFixoContribuicao}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(
                                'valorFixoContribuicao',
                                masks.currencyInput.mask(value),
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                      </RenderConditional>

                      <RenderConditional condition={openSelectValorPercentual}>
                        <Grid.Item xs={1} lg={1 / 2}>
                          <Field
                            name="valorPercentualContribuicao"
                            label="*Definir % do valor com base do salário"
                            component={TextField}
                            value={masks.percentage.mask(
                              values.valorPercentualContribuicao,
                            )}
                            error={errors.valorPercentualContribuicao}
                            errorMessage={errors.valorPercentualContribuicao}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(
                                'valorPercentualContribuicao',
                                masks.percentage.mask(value),
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                      </RenderConditional>
                      <RenderConditional
                        condition={openSelectOutraFormaPagamento}
                      >
                        <Grid.Item xs={1} lg={1 / 2}>
                          <p>
                            *Descreva neste espaço como será o custeio do
                            pagamento
                          </p>
                          <TextArea
                            spellCheck
                            value={textOutraFormaPagamento}
                            placeholder=""
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<{ value: string }>) =>
                              setTextOutraFormaPagamento(value)
                            }
                          />
                        </Grid.Item>
                      </RenderConditional>
                    </>
                  </RenderConditional>
                  <RenderConditional condition={openSelectValoresParticipantes}>
                    <p style={{ color: 'red' }}>
                      Será necessário preencher os campos específicos do FOP 064
                      disponível para download no final deste formulário.
                    </p>
                  </RenderConditional>
                  <RenderConditional condition={openSelectPlanoInstituido}>
                    <>
                      <TitleSection>Empresa</TitleSection>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="linkValorContribuicaoEmpresa"
                          label="*Valor da contribuição"
                          component={Select}
                          placeholder="Selecione"
                          value={values.linkValorContribuicaoEmpresa}
                          error={errors.linkValorContribuicaoEmpresa}
                          errorMessage={errors.linkValorContribuicaoEmpresa}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{
                            text: string;
                            value: string;
                          }>) => {
                            setFieldValue(
                              'linkValorContribuicaoEmpresa',
                              value,
                            );
                            selectValorContribuicaoEmpresa(value);
                          }}
                          onBlur={handleBlur}
                        >
                          {selectOptionsValorContribuicao.map(
                            optValorContribuicaoEmpresa => (
                              <Select.Item
                                key={optValorContribuicaoEmpresa.key}
                                value={optValorContribuicaoEmpresa.key}
                                text={optValorContribuicaoEmpresa.value}
                                selected={
                                  optValorContribuicaoEmpresa.key ===
                                  values.linkValorContribuicaoEmpresa
                                }
                              />
                            ),
                          )}
                        </Field>
                      </Grid.Item>
                      <RenderConditional condition={openSelectValorFixoEmpresa}>
                        <Grid.Item xs={1} lg={1 / 2}>
                          <Field
                            name="valorFixoEmpresaContribuicao"
                            label="*Valor Fixo"
                            component={TextField}
                            value={values.valorFixoEmpresaContribuicao}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(
                                'valorFixoEmpresaContribuicao',
                                masks.currencyInput.mask(value),
                              );
                            }}
                            error={errors.valorFixoEmpresaContribuicao}
                            errorMessage={errors.valorFixoEmpresaContribuicao}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                      </RenderConditional>
                      <RenderConditional
                        condition={openSelectValorPercentualEmpresa}
                      >
                        <Grid.Item xs={1} lg={1 / 2}>
                          <Field
                            name="valorPercentualEmpresaContribuicao"
                            label="*Definir % do valor com base do salário"
                            component={TextField}
                            value={values.valorPercentualEmpresaContribuicao}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(
                                'valorPercentualEmpresaContribuicao',
                                masks.percentage.mask(value),
                              );
                            }}
                            onBlur={handleBlur}
                            error={errors.valorPercentualEmpresaContribuicao}
                            errorMessage={
                              errors.valorPercentualEmpresaContribuicao
                            }
                          />

                          <p style={{ color: 'red' }}>
                            Informe o valor do salário para cada participante no
                            no FOP 064
                          </p>
                        </Grid.Item>
                      </RenderConditional>
                      <RenderConditional
                        condition={openSelectOutraFormaPagamentoEmpresa}
                      >
                        <Grid.Item xs={1} lg={1 / 2}>
                          <p>
                            *Descreva neste espaço como será o custeio do
                            pagamento
                          </p>
                          <TextArea
                            spellCheck
                            value={textOutraFormaPgEmpresa}
                            placeholder=""
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<{ value: string }>) =>
                              setTextOutraFormaPgEmpresa(value)
                            }
                          />
                        </Grid.Item>
                      </RenderConditional>
                      <Divider />
                      <TitleSection>Funcionário</TitleSection>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="linkValorContribuicaoFuncionario"
                          label="*Valor da contribuição"
                          component={Select}
                          placeholder="Selecione"
                          value={values.linkValorContribuicaoFuncionario}
                          error={errors.linkValorContribuicaoFuncionario}
                          errorMessage={errors.linkValorContribuicaoFuncionario}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{
                            text: string;
                            value: string;
                          }>) => {
                            setFieldValue(
                              'linkValorContribuicaoFuncionario',
                              value,
                            );
                            selectValorContribuicaoFuncionario(value);
                          }}
                          onBlur={handleBlur}
                        >
                          {selectOptionsValorContribuicao.map(
                            optContribuicaoFuncionario => (
                              <Select.Item
                                key={optContribuicaoFuncionario.key}
                                value={optContribuicaoFuncionario.key}
                                text={optContribuicaoFuncionario.value}
                                selected={
                                  optContribuicaoFuncionario.key ===
                                  values.linkValorContribuicaoFuncionario
                                }
                              />
                            ),
                          )}
                        </Field>
                      </Grid.Item>
                      <RenderConditional
                        condition={openSelectValorFixoFuncionario}
                      >
                        <Grid.Item xs={1} lg={1 / 2}>
                          <Field
                            name="valorFixoFuncionarioContribuicao"
                            label="*Valor Fixo"
                            component={TextField}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(
                                'valorFixoFuncionarioContribuicao',
                                masks.currencyInput.mask(value),
                              );
                            }}
                            onBlur={handleBlur}
                            value={values.valorFixoFuncionarioContribuicao}
                            error={errors.valorFixoFuncionarioContribuicao}
                            errorMessage={
                              errors.valorFixoFuncionarioContribuicao
                            }
                          />
                        </Grid.Item>
                      </RenderConditional>
                      <></>
                      <RenderConditional
                        condition={openSelectValorPercentualFuncionario}
                      >
                        <Grid.Item xs={1} lg={1 / 2}>
                          <Field
                            name="valorPercentualFuncionarioContribuicao"
                            label="*Definir % do valor com base do salário"
                            component={TextField}
                            value={masks.percentage.mask(
                              values.valorPercentualFuncionarioContribuicao,
                            )}
                            error={
                              errors.valorPercentualFuncionarioContribuicao
                            }
                            errorMessage={
                              errors.valorPercentualFuncionarioContribuicao
                            }
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(
                                'valorPercentualFuncionarioContribuicao',
                                masks.percentage.mask(value),
                              );
                            }}
                            onBlur={handleBlur}
                          />
                          <p style={{ color: 'red' }}>
                            Informe o valor do salário para cada participante no
                            no FOP 064
                          </p>
                        </Grid.Item>
                      </RenderConditional>
                      <RenderConditional
                        condition={openSelectOutraFormaPagamentoFuncionario}
                      >
                        <Grid.Item xs={1} lg={1 / 2}>
                          <p>
                            *Descreva neste espaço como será o custeio do
                            pagamento
                          </p>
                          <TextArea
                            spellCheck
                            value={textOutraFormaPagamentoFuncionario}
                            placeholder=""
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<{ value: string }>) =>
                              setTextOutraFormaPagamentoFuncionario(value)
                            }
                          />
                        </Grid.Item>
                      </RenderConditional>
                    </>
                  </RenderConditional>
                  <Divider />
                  <TitleSection>4.Cuidado Extra</TitleSection>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="linkSelectCuidadoExtra"
                      label="*Tipo de cuidado extra"
                      component={Select}
                      placeholder="Selecione"
                      value={values.linkSelectCuidadoExtra}
                      error={errors.linkSelectCuidadoExtra}
                      errorMessage={errors.linkSelectCuidadoExtra}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('linkSelectCuidadoExtra', value);
                        selectCuidadoExtra(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsCuidadoExtra.map(optsTipoCuidadoExtra => (
                        <Select.Item
                          key={optsTipoCuidadoExtra.key}
                          value={optsTipoCuidadoExtra.key}
                          text={optsTipoCuidadoExtra.value}
                          selected={
                            optsTipoCuidadoExtra.key ===
                            values.linkSelectCuidadoExtra
                          }
                        />
                      ))}
                    </Field>
                  </Grid.Item>

                  <RenderConditional condition={openSelectPensao}>
                    <>
                      <Grid.Item xs={1} lg={1 / 3}>
                        <Field
                          name="linkSelectRegraCuidadoExtraPensao"
                          label="*Selecione a regra para o cálculo"
                          component={Select}
                          placeholder="Selecione"
                          value={values.linkSelectRegraCuidadoExtraPensao}
                          error={errors.linkSelectRegraCuidadoExtraPensao}
                          errorMessage={
                            errors.linkSelectRegraCuidadoExtraPensao
                          }
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{
                            text: string;
                            value: string;
                          }>) => {
                            setFieldValue(
                              'linkSelectRegraCuidadoExtraPensao',
                              value,
                            );
                          }}
                          onBlur={handleBlur}
                        >
                          {selectOptionsRegraCuidadoPensao.map(
                            optRegraCuidadoPensao => (
                              <Select.Item
                                key={optRegraCuidadoPensao.key}
                                value={optRegraCuidadoPensao.key}
                                text={optRegraCuidadoPensao.value}
                                selected={
                                  optRegraCuidadoPensao.key ===
                                  values.linkSelectRegraCuidadoExtraPensao
                                }
                              />
                            ),
                          )}
                        </Field>
                      </Grid.Item>
                    </>
                  </RenderConditional>
                  <RenderConditional
                    condition={openSelectFormaPagamentoCuidado}
                  >
                    <>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="formaDePagamentoCuidado"
                          label="*Forma de pagamento"
                          component={Select}
                          placeholder="Selecione"
                          value={values.formaDePagamentoCuidado}
                          error={errors.formaDePagamentoCuidado}
                          errorMessage={errors.formaDePagamentoCuidado}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{
                            text: string;
                            value: string;
                          }>) => {
                            setFieldValue('formaDePagamentoCuidado', value);
                            selectFormaPagamentoCuidado(value);
                          }}
                          onBlur={handleBlur}
                        >
                          {selectOptionsPagamento.map(
                            optFormaDePagamentoCuidado => (
                              <Select.Item
                                key={optFormaDePagamentoCuidado.key}
                                value={optFormaDePagamentoCuidado.key}
                                text={optFormaDePagamentoCuidado.value}
                                selected={
                                  optFormaDePagamentoCuidado.key ===
                                  values.formaDePagamentoCuidado
                                }
                              />
                            ),
                          )}
                        </Field>
                      </Grid.Item>
                      <RenderConditional condition={openSelectPeculio}>
                        <>
                          <Grid.Item xs={1} lg={1 / 3}>
                            <Field
                              name="linkSelectRegraCuidadoExtraPeculio"
                              label="*Selecione a regra para o cálculo"
                              component={Select}
                              placeholder="Selecione"
                              value={values.linkSelectRegraCuidadoExtraPeculio}
                              error={errors.linkSelectRegraCuidadoExtraPeculio}
                              errorMessage={
                                errors.linkSelectRegraCuidadoExtraPeculio
                              }
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<{
                                text: string;
                                value: string;
                              }>) => {
                                setFieldValue(
                                  'linkSelectRegraCuidadoExtraPeculio',
                                  value,
                                );
                              }}
                              onBlur={handleBlur}
                            >
                              {selectOptionsRegraCuidado63.map(
                                optRegraCuidado63 => (
                                  <Select.Item
                                    key={optRegraCuidado63.key}
                                    value={optRegraCuidado63.key}
                                    text={optRegraCuidado63.value}
                                    selected={
                                      optRegraCuidado63.key ===
                                      values.linkSelectRegraCuidadoExtraPeculio
                                    }
                                  />
                                ),
                              )}
                            </Field>
                          </Grid.Item>
                        </>
                      </RenderConditional>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="valoresParticipantesCuidado"
                          label="*Os valores são iguais para todos os participantes?"
                          component={Select}
                          placeholder="Selecione"
                          value={values.valoresParticipantesCuidado}
                          error={errors.valoresParticipantesCuidado}
                          errorMessage={errors.valoresParticipantesCuidado}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{
                            text: string;
                            value: string;
                          }>) => {
                            setFieldValue('valoresParticipantesCuidado', value);
                            selectValoresParticipantesCuidado(value);
                          }}
                          onBlur={handleBlur}
                        >
                          {selectOptionsValoresParticipantes.map(
                            optParticipantesCuidado => (
                              <Select.Item
                                key={optParticipantesCuidado.key}
                                value={optParticipantesCuidado.key}
                                text={optParticipantesCuidado.value}
                                selected={
                                  optParticipantesCuidado.key ===
                                  values.valoresParticipantesCuidado
                                }
                              />
                            ),
                          )}
                        </Field>
                      </Grid.Item>
                      <RenderConditional
                        condition={openSelectValoresParticipantesCuidado}
                      >
                        <p style={{ color: 'red' }}>
                          Será necessário preencher os campos específicos do FOP
                          063 disponível para download no final deste
                          formulário.
                        </p>
                      </RenderConditional>
                      <RenderConditional
                        condition={openSelectValorContribuicaoCuidadoExtra}
                      >
                        <>
                          <RenderConditional condition={openSelectPensao}>
                            <Grid.Item xs={1} lg={1 / 2}>
                              <Field
                                name="linkSelectAnosPensao"
                                label="*Período escolhido para o recebimento da Pensão Por Prazo Certo"
                                component={TextField}
                                value={values.linkSelectAnosPensao}
                                error={errors.linkSelectAnosPensao}
                                errorMessage={errors.linkSelectAnosPensao}
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue('linkSelectAnosPensao', value);
                                }}
                                onBlur={handleBlur}
                              />
                            </Grid.Item>
                          </RenderConditional>
                          <Grid.Item xs={1} lg={1 / 2}>
                            <Field
                              name="linkValorContribuicaoCuidadoExtra"
                              label="*Valor da contribuição"
                              component={Select}
                              placeholder="Selecione"
                              value={values.linkValorContribuicaoCuidadoExtra}
                              error={errors.linkValorContribuicaoCuidadoExtra}
                              errorMessage={
                                errors.linkValorContribuicaoCuidadoExtra
                              }
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<{
                                text: string;
                                value: string;
                              }>) => {
                                setFieldValue(
                                  'linkValorContribuicaoCuidadoExtra',
                                  value,
                                );
                                selectValorContribuicaoCuidadoExtra(value);
                              }}
                              onBlur={handleBlur}
                            >
                              {selectOptionsValorContribuicao.map(
                                optContribuicaoCuidadoExtra => (
                                  <Select.Item
                                    key={optContribuicaoCuidadoExtra.key}
                                    value={optContribuicaoCuidadoExtra.key}
                                    text={optContribuicaoCuidadoExtra.value}
                                    selected={
                                      optContribuicaoCuidadoExtra.key ===
                                      values.linkValorContribuicaoCuidadoExtra
                                    }
                                  />
                                ),
                              )}
                            </Field>
                          </Grid.Item>
                          <RenderConditional
                            condition={openSelectValorFixoCuidadoExtra}
                          >
                            <Grid.Item xs={1} lg={1 / 2}>
                              <Field
                                name="valorFixoCuidadoExtraContribuicao"
                                label="*Valor Fixo"
                                component={TextField}
                                value={values.valorFixoCuidadoExtraContribuicao}
                                error={errors.valorFixoCuidadoExtraContribuicao}
                                errorMessage={
                                  errors.valorFixoCuidadoExtraContribuicao
                                }
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue(
                                    'valorFixoCuidadoExtraContribuicao',
                                    masks.currencyInput.mask(value),
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Grid.Item>
                          </RenderConditional>
                          <RenderConditional
                            condition={openSelectValorPercentualCuidadoExtra}
                          >
                            <Grid.Item xs={1} lg={1 / 2}>
                              <Field
                                name="valorPercentualCuidadoExtraContribuicao"
                                label="*Definir % do valor com base do salário"
                                component={TextField}
                                value={masks.percentage.mask(
                                  values.valorPercentualCuidadoExtraContribuicao,
                                )}
                                error={
                                  errors.valorPercentualCuidadoExtraContribuicao
                                }
                                errorMessage={
                                  errors.valorPercentualCuidadoExtraContribuicao
                                }
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue(
                                    'valorPercentualCuidadoExtraContribuicao',
                                    masks.percentage.mask(value),
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Grid.Item>
                          </RenderConditional>
                          <RenderConditional
                            condition={
                              openSelectOutraFormaPagamentoCuidadoExtra
                            }
                          >
                            <Grid.Item xs={1} lg={1 / 2}>
                              <p>
                                *Descreva neste espaço como será o custeio do
                                pagamento
                              </p>
                              <TextArea
                                spellCheck
                                value={textOutraFormaPagamentoCuidadoExtra}
                                placeholder=""
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<{ value: string }>) =>
                                  setTextOutraFormaPagamentoCuidadoExtra(value)
                                }
                              />
                            </Grid.Item>
                          </RenderConditional>
                        </>
                      </RenderConditional>

                      <RenderConditional
                        condition={openSelectPlanoInstituidoCuidadoExtra}
                      >
                        <>
                          <TitleSection>Empresa</TitleSection>
                          <Grid.Item xs={1} lg={1 / 2}>
                            <Field
                              name="linkValorContribuicaoEmpresaCuidadoExtra"
                              label="*Valor da contribuição"
                              component={Select}
                              placeholder="Selecione"
                              value={
                                values.linkValorContribuicaoEmpresaCuidadoExtra
                              }
                              error={
                                errors.linkValorContribuicaoEmpresaCuidadoExtra
                              }
                              errorMessage={
                                errors.linkValorContribuicaoEmpresaCuidadoExtra
                              }
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<{
                                text: string;
                                value: string;
                              }>) => {
                                setFieldValue(
                                  'linkValorContribuicaoEmpresaCuidadoExtra',
                                  value,
                                );
                                selectValorContribuicaoEmpresaCuidadoExtra(
                                  value,
                                );
                              }}
                              onBlur={handleBlur}
                            >
                              {selectOptionsValorContribuicao.map(
                                optValorContribuicaoEmpresaCuidadoExtra => (
                                  <Select.Item
                                    key={
                                      optValorContribuicaoEmpresaCuidadoExtra.key
                                    }
                                    value={
                                      optValorContribuicaoEmpresaCuidadoExtra.key
                                    }
                                    text={
                                      optValorContribuicaoEmpresaCuidadoExtra.value
                                    }
                                    selected={
                                      optValorContribuicaoEmpresaCuidadoExtra.key ===
                                      values.linkValorContribuicaoEmpresaCuidadoExtra
                                    }
                                  />
                                ),
                              )}
                            </Field>
                          </Grid.Item>
                          <RenderConditional
                            condition={openSelectValorFixoEmpresaCuidadoExtra}
                          >
                            <Grid.Item xs={1} lg={1 / 2}>
                              <Field
                                name="valorFixoEmpresaCuidadoExtraContribuicao"
                                label="*Valor Fixo"
                                component={TextField}
                                value={
                                  values.valorFixoEmpresaCuidadoExtraContribuicao
                                }
                                error={
                                  errors.valorFixoEmpresaCuidadoExtraContribuicao
                                }
                                errorMessage={
                                  errors.valorFixoEmpresaCuidadoExtraContribuicao
                                }
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue(
                                    'valorFixoEmpresaCuidadoExtraContribuicao',
                                    masks.currencyInput.mask(value),
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Grid.Item>
                          </RenderConditional>
                          <RenderConditional
                            condition={
                              openSelectValorPercentualEmpresaCuidadoExtra
                            }
                          >
                            <Grid.Item xs={1} lg={1 / 2}>
                              <Field
                                name="valorPercentualEmpresaCuidadoExtraContribuicao"
                                label="*Definir % do valor com base do salário"
                                component={TextField}
                                value={masks.percentage.mask(
                                  values.valorPercentualEmpresaCuidadoExtraContribuicao,
                                )}
                                error={
                                  errors.valorPercentualEmpresaCuidadoExtraContribuicao
                                }
                                errorMessage={
                                  errors.valorPercentualEmpresaCuidadoExtraContribuicao
                                }
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue(
                                    'valorPercentualEmpresaCuidadoExtraContribuicao',
                                    masks.percentage.mask(value),
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                              <p style={{ color: 'red' }}>
                                Informe o valor do salário para cada
                                participante no no FOP 064
                              </p>
                            </Grid.Item>
                          </RenderConditional>
                          <RenderConditional
                            condition={
                              openSelectOutraFormaPagamentoEmpresaCuidadoExtra
                            }
                          >
                            <Grid.Item xs={1} lg={1 / 2}>
                              <p>
                                *Descreva neste espaço como será o custeio do
                                pagamento
                              </p>
                              <TextArea
                                spellCheck
                                value={
                                  textOutraFormaPagamentoEmpresaCuidadoExtra
                                }
                                placeholder=""
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<{ value: string }>) =>
                                  setTextOutraFormaPagamentoEmpresaCuidadoExtra(
                                    value,
                                  )
                                }
                              />
                            </Grid.Item>
                          </RenderConditional>
                          <Divider />
                          <TitleSection>Funcionário</TitleSection>
                          <Grid.Item xs={1} lg={1 / 2}>
                            <Field
                              name="linkValorContribuicaoFuncionarioCuidadoExtra"
                              label="*Valor da contribuição"
                              component={Select}
                              placeholder="Selecione"
                              value={
                                values.linkValorContribuicaoFuncionarioCuidadoExtra
                              }
                              error={
                                errors.linkValorContribuicaoFuncionarioCuidadoExtra
                              }
                              errorMessage={
                                errors.linkValorContribuicaoFuncionarioCuidadoExtra
                              }
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<{
                                text: string;
                                value: string;
                              }>) => {
                                setFieldValue(
                                  'linkValorContribuicaoFuncionarioCuidadoExtra',
                                  value,
                                );
                                selectValorContribuicaoFuncionarioCuidadoExtra(
                                  value,
                                );
                              }}
                              onBlur={handleBlur}
                            >
                              {selectOptionsValorContribuicao.map(
                                optValorContribuicaoFuncionarioCuidadoExtra => (
                                  <Select.Item
                                    key={
                                      optValorContribuicaoFuncionarioCuidadoExtra.key
                                    }
                                    value={
                                      optValorContribuicaoFuncionarioCuidadoExtra.key
                                    }
                                    text={
                                      optValorContribuicaoFuncionarioCuidadoExtra.value
                                    }
                                    selected={
                                      optValorContribuicaoFuncionarioCuidadoExtra.key ===
                                      values.linkValorContribuicaoFuncionarioCuidadoExtra
                                    }
                                  />
                                ),
                              )}
                            </Field>
                          </Grid.Item>
                          <RenderConditional
                            condition={
                              openSelectValorFixoFuncionarioCuidadoExtra
                            }
                          >
                            <Grid.Item xs={1} lg={1 / 2}>
                              <Field
                                name="valorFixoFuncionarioCuidadoExtraContribuicao"
                                label="*Valor Fixo"
                                component={TextField}
                                value={
                                  values.valorFixoFuncionarioCuidadoExtraContribuicao
                                }
                                error={
                                  errors.valorFixoFuncionarioCuidadoExtraContribuicao
                                }
                                errorMessage={
                                  errors.valorFixoFuncionarioCuidadoExtraContribuicao
                                }
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue(
                                    'valorFixoFuncionarioCuidadoExtraContribuicao',
                                    masks.currencyInput.mask(value),
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Grid.Item>
                          </RenderConditional>
                          <RenderConditional
                            condition={
                              openSelectValorPercentualFuncionarioCuidadoExtra
                            }
                          >
                            <Grid.Item xs={1} lg={1 / 2}>
                              <Field
                                name="valorPercentualFuncionarioCuidadoExtraContribuicao"
                                label="*Definir % do valor com base do salário"
                                component={TextField}
                                value={masks.percentage.mask(
                                  values.valorPercentualFuncionarioCuidadoExtraContribuicao,
                                )}
                                error={
                                  errors.valorPercentualFuncionarioCuidadoExtraContribuicao
                                }
                                errorMessage={
                                  errors.valorPercentualFuncionarioCuidadoExtraContribuicao
                                }
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue(
                                    'valorPercentualFuncionarioCuidadoExtraContribuicao',
                                    masks.percentage.mask(value),
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                              <p style={{ color: 'red' }}>
                                Informe o valor do salário para cada
                                participante no no FOP 064
                              </p>
                            </Grid.Item>
                          </RenderConditional>
                          <RenderConditional
                            condition={
                              openSelectOutraFormaPagamentoFuncionarioCuidadoExtra
                            }
                          >
                            <Grid.Item xs={1} lg={1 / 2}>
                              <p>
                                *Descreva neste espaço como será o custeio do
                                pagamento
                              </p>
                              <TextArea
                                spellCheck
                                value={
                                  textOutraFormaPagamentoFuncionarioCuidadoExtra
                                }
                                placeholder=""
                                onChange={({
                                  target: { value },
                                }: React.ChangeEvent<{ value: string }>) =>
                                  setTextOutraFormaPagamentoFuncionarioCuidadoExtra(
                                    value,
                                  )
                                }
                              />
                            </Grid.Item>
                          </RenderConditional>
                        </>
                      </RenderConditional>
                    </>
                  </RenderConditional>
                  <Divider />
                  <TitleSection>5.Dados do Plano</TitleSection>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="linkSelectTipoFundo"
                      label="*Tipo de Fundo"
                      component={Select}
                      placeholder="Selecione"
                      value={values.linkSelectTipoFundo}
                      error={errors.linkSelectTipoFundo}
                      errorMessage={errors.linkSelectTipoFundo}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('linkSelectTipoFundo', value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsTipoFundo.map(optTipoFundo => (
                        <Select.Item
                          key={optTipoFundo.key}
                          value={optTipoFundo.key}
                          text={optTipoFundo.value}
                          selected={
                            optTipoFundo.key === values.linkSelectTipoFundo
                          }
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="linkSelectTipoConcessao"
                      label="*Concessão de benefício"
                      component={Select}
                      placeholder="Selecione"
                      value={values.linkSelectTipoConcessao}
                      error={errors.linkSelectTipoConcessao}
                      errorMessage={errors.linkSelectTipoConcessao}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('linkSelectTipoConcessao', value);
                        selectTipoConcessao(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsConcessaoBeneficio.map(
                        optConcessaoBeneficio => (
                          <Select.Item
                            key={optConcessaoBeneficio.key}
                            value={optConcessaoBeneficio.key}
                            text={optConcessaoBeneficio.value}
                            selected={
                              optConcessaoBeneficio.key ===
                              values.linkSelectTipoConcessao
                            }
                          />
                        ),
                      )}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectPrazoContribuicao}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="prazoContribuicao"
                        label="*Prazo Contribuicao"
                        component={TextField}
                        value={values.prazoContribuicao}
                        error={errors.prazoContribuicao}
                        errorMessage={errors.prazoContribuicao}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('prazoContribuicao', value);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectIdadeAposentadoria}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="idadeAposentadoria"
                        label="*Idade de aposentadoria"
                        component={TextField}
                        value={values.idadeAposentadoria}
                        error={errors.idadeAposentadoria}
                        errorMessage={errors.idadeAposentadoria}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('idadeAposentadoria', value);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="linkSelectTipoPagamentoFatura"
                      label="*Forma de pagamento de fatura"
                      component={Select}
                      placeholder="Selecione"
                      value={values.linkSelectTipoPagamentoFatura}
                      error={errors.linkSelectTipoPagamentoFatura}
                      errorMessage={errors.linkSelectTipoPagamentoFatura}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('linkSelectTipoPagamentoFatura', value);
                        selecTipoPagamentoFatura(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsPagamentoFatura.map(
                        optTipoPagamentoFatura => (
                          <Select.Item
                            key={optTipoPagamentoFatura.key}
                            value={optTipoPagamentoFatura.key}
                            text={optTipoPagamentoFatura.value}
                            selected={
                              optTipoPagamentoFatura.key ===
                              values.linkSelectTipoPagamentoFatura
                            }
                          />
                        ),
                      )}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectDiaVencimento}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="linkSelectVencimentoFatura"
                        label="*Dia de vencimento da fatura"
                        component={Select}
                        placeholder="Selecione"
                        value={values.linkSelectVencimentoFatura}
                        error={errors.linkSelectVencimentoFatura}
                        errorMessage={errors.linkSelectVencimentoFatura}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue('linkSelectVencimentoFatura', value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptionsVencimentoFatura.map(
                          optVencimentoFatura => (
                            <Select.Item
                              key={optVencimentoFatura.key}
                              value={optVencimentoFatura.key}
                              text={optVencimentoFatura.value}
                              selected={
                                optVencimentoFatura.key ===
                                values.linkSelectVencimentoFatura
                              }
                            />
                          ),
                        )}
                      </Field>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectAporteUnico}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="aporteUnico"
                        label="*Aporte único"
                        component={TextField}
                        value={values.aporteUnico}
                        error={errors.aporteUnico}
                        errorMessage={errors.aporteUnico}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            'aporteUnico',
                            masks.currencyInput.mask(value),
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <Grid.Item xs={1} lg={1 / 3}>
                    {openSelectAporteUnico}
                    <Field
                      name="linkSelectDadosCobranca"
                      label="*Dados para Cobrança"
                      component={Select}
                      placeholder="Selecione"
                      value={values.linkSelectDadosCobranca}
                      error={errors.linkSelectDadosCobranca}
                      errorMessage={errors.linkSelectDadosCobranca}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('linkSelectDadosCobranca', value);
                        selectDadosCobranca(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsDadosCobranca.map(optDadosCobranca => (
                        <Select.Item
                          key={optDadosCobranca.key}
                          value={optDadosCobranca.key}
                          text={optDadosCobranca.value}
                          selected={
                            optDadosCobranca.key ===
                            values.linkSelectDadosCobranca
                          }
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectDebitoEmConta}>
                    <Grid.Item xs={1}>
                      <Grid.Item xs={1} lg={1 / 3}>
                        <Field
                          name="agencia"
                          label="*Agência"
                          component={TextField}
                          value={values.agencia}
                          error={errors.agencia}
                          errorMessage={errors.agencia}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('agencia', value);
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1 / 3}>
                        <Field
                          name="linkSelectDadosOperacao"
                          label="*Operação"
                          component={Select}
                          placeholder="Selecione"
                          value={values.linkSelectDadosOperacao}
                          error={errors.linkSelectDadosOperacao}
                          errorMessage={errors.linkSelectDadosOperacao}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{
                            text: string;
                            value: string;
                          }>) => {
                            setFieldValue('linkSelectDadosOperacao', value);
                          }}
                          onBlur={handleBlur}
                        >
                          {selectOptionsOperacao.map(optiOperacao => (
                            <Select.Item
                              key={optiOperacao.key}
                              value={optiOperacao.key}
                              text={optiOperacao.value}
                              selected={
                                optiOperacao.key ===
                                values.linkSelectDadosOperacao
                              }
                            />
                          ))}
                        </Field>
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1 / 3}>
                        <Field
                          name="conta"
                          label="*Conta"
                          component={TextField}
                          value={values.conta}
                          error={errors.conta}
                          errorMessage={errors.conta}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('conta', value);
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </Grid.Item>
                  </RenderConditional>
                  <Divider />
                  <TitleSection>6.Informações Complementares</TitleSection>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <p>Informar a família de taxas concedida.</p>
                    <TextArea
                      spellCheck
                      value={textInformacoesComplementares}
                      placeholder=""
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{ value: string }>) =>
                        setTextInformacoesComplementares(value)
                      }
                    />
                  </Grid.Item>
                  <Divider />
                  <TitleSection>
                    7.Dados do responsável pelo preenchimento
                  </TitleSection>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="nomeCompletoResponsavel"
                      label="*Nome completo"
                      component={TextField}
                      value={values.nomeCompletoResponsavel}
                      error={errors.nomeCompletoResponsavel}
                      errorMessage={errors.nomeCompletoResponsavel}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('nomeCompletoResponsavel', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="telefoneResponsavel"
                      label="*Telefone"
                      component={TextField}
                      value={masks.phone.mask(values.telefoneResponsavel)}
                      error={errors.telefoneResponsavel}
                      errorMessage={errors.telefoneResponsavel}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('telefoneResponsavel', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="emailResponsavel"
                      label="*E-mail (Atenção: esse será o e-mail para o recebimento do estudo)"
                      component={TextField}
                      value={values.emailResponsavel}
                      error={errors.emailResponsavel}
                      errorMessage={errors.emailResponsavel}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('emailResponsavel', value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Divider />
                  <Text variant="body01-sm" color="primary" margin>
                    <DownloadButton
                      variant="text"
                      type="button"
                      onClick={() =>
                        baixarArquivoFop(
                          validarArquivoDownload(dataLocation),
                          dataLocation[0]?.dadosArquivos
                            ? dataLocation[0]?.dadosArquivos[0]?.nomeArquivo
                            : 'Planilha_teste.html',
                        )
                      }
                    >
                      {loadingDownload && <Button variant="text" loading />}
                      {!loadingDownload && (
                        <>
                          Faça o download do FOP 064 aqui &nbsp;
                          <Icon name="download" />
                        </>
                      )}
                    </DownloadButton>
                  </Text>
                  <Grid.Item xs={1 / 3}>
                    <>
                      <S.InputLabel>
                        *Anexe aqui o FOP 064 ou documentos importantes para
                        elaboração do estudo
                      </S.InputLabel>
                      <DescriptionFormatsFilesAllowed fileSize="3" />
                      <InputFile
                        link={arquivoAnexoFop63}
                        validationRules={regraFiles}
                      />
                    </>
                  </Grid.Item>
                  <Modal
                    show={openModalFormulario}
                    onClose={fecharModal}
                    style={{ maxWidth: `90%` }}
                  >
                    <Display justify="center">
                      <Text variant="body02-md" margin>
                        <strong>Atenção! Confira seus dados</strong>
                      </Text>
                      <br />
                      <Card.Content>
                        <Accordion open>
                          <Accordion.Item title="1. Informações Gerais">
                            <Grid>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Nome da Empresa
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="nomeDaEmpresa"
                                  >
                                    {values.nomeDaEmpresa}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Atividade Principal
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="atividadePrincipalEmpresa"
                                  >
                                    {values.atividadePrincipalEmpresa}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    CNPJ
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="cnpjEmpresa"
                                  >
                                    {masks.cnpj.mask(values.cnpjEmpresa)}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Faturamento
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="faturamento"
                                  >
                                    {
                                      selectOptionsFaturamento.find(
                                        x => x.key === values.faturamento,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    E-mail institucional
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="emailInstitucional"
                                  >
                                    {values.emailInstitucional}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Logradouro
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="logradouro"
                                  >
                                    {values.logradouro}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Bairro
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="bairro"
                                  >
                                    {values.bairro}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Cidade
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="cidade"
                                  >
                                    {values.cidade}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">UF</TextLabel>
                                  <Text variant="body02-sm" data-testid="uf">
                                    {values.uf}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 4}>
                                <div>
                                  <TextLabel variant="body02-sm">CEP</TextLabel>
                                  <Text variant="body02-sm" data-testid="cep">
                                    {masks.cep.mask(values.cep)}
                                  </Text>
                                </div>
                              </Grid.Item>
                            </Grid>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion open>
                          <Accordion.Item title="Dados do Representante">
                            <Grid>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Nome Representante (obrigatório)
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="AgenciaFilial"
                                  >
                                    {values.nomeRepresentante}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    E-mail Representante (obrigatório)
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="agenciaSr"
                                  >
                                    {values.emailRepresentante}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Cargo Representante (obrigatório)
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="numeroDaAgencia"
                                  >
                                    {values.cargoRepresentante}
                                  </Text>
                                </div>
                              </Grid.Item>
                            </Grid>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion open>
                          <Accordion.Item title="2. Dados da Agência">
                            <Grid>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Filial
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="AgenciaFilial"
                                  >
                                    {
                                      selectOptionsFilial.find(
                                        x => x.key === values.agenciaFilial,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Superintendência regional
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="agenciaSr"
                                  >
                                    {values.agenciaSr}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Número da agência
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="numeroDaAgencia"
                                  >
                                    {values.numeroDaAgencia}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Nome da agência
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="nomeDaAgencia"
                                  >
                                    {values.nomeDaAgencia}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Matrícula do Indicador
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="matriculaDoIndicador"
                                  >
                                    {values.matriculaDoIndicador}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Nome do Indicador
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="nomeDoIndicador"
                                  >
                                    {values.nomeDoIndicador}
                                  </Text>
                                </div>
                              </Grid.Item>
                            </Grid>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion open>
                          <Accordion.Item title="3.Benefício Básico">
                            <Grid>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Número de participantes
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="numeroDeParticipantes"
                                  >
                                    {values.numeroDeParticipantes}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Aporte Inicial
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="regraCalculo"
                                  >
                                    {
                                      selectOptionsRegraAporte.find(
                                        x => x.key === values.aporteInicial,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              {values.valorAporteInicial && (
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Valor do aporte inicial
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorAporteInicial"
                                    >
                                      {values.valorAporteInicial}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              )}
                              {values.valorPortabilidade && (
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Valor da portabilidade
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorPortabilidade"
                                    >
                                      {values.valorPortabilidade}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              )}
                              <RenderConditional
                                condition={openSelectTipoBenficio}
                              >
                                <>
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Tipo de Benefício Básico
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="tipoBeneficioBasico"
                                      >
                                        {
                                          selectOptionsBeneficio.find(
                                            x =>
                                              x.key ===
                                              values.tipoBeneficioBasico,
                                          )?.value
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                  <RenderConditional
                                    condition={openSelectReversivel}
                                  >
                                    <>
                                      <Grid.Item xs={1} lg={1 / 3}>
                                        <div>
                                          <TextLabel variant="body02-sm">
                                            % Reversão
                                          </TextLabel>
                                          <Text
                                            variant="body02-sm"
                                            data-testid="reversao"
                                          >
                                            {values.reversao}
                                          </Text>
                                        </div>
                                      </Grid.Item>
                                    </>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={openSelectPrazoBenficio}
                                  >
                                    {values.prazoBeneficio && (
                                      <Grid.Item xs={1} lg={1 / 3}>
                                        <div>
                                          <TextLabel variant="body02-sm">
                                            Prazo de Benefício Básico
                                          </TextLabel>
                                          <Text
                                            variant="body02-sm"
                                            data-testid="prazoBeneficio"
                                          >
                                            {values.prazoBeneficio}
                                          </Text>
                                        </div>
                                      </Grid.Item>
                                    )}
                                  </RenderConditional>
                                </>
                              </RenderConditional>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Forma de pagamento
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="formaDePagamento"
                                  >
                                    {
                                      selectOptionsPagamento.find(
                                        x => x.key === values.formaDePagamento,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Modalidade do Plano
                                  </TextLabel>
                                  {values.linkSelectModalidade && (
                                    <Text
                                      variant="body02-sm"
                                      data-testid="linkSelectModalidade"
                                    >
                                      {
                                        selectOptionsModalidade.find(
                                          x =>
                                            x.key ===
                                            values.linkSelectModalidade,
                                        )?.value
                                      }
                                    </Text>
                                  )}
                                  {values.linkSelectModalidadePGBL && (
                                    <Text
                                      variant="body02-sm"
                                      data-testid="linkSelectModalidadePGBL"
                                    >
                                      {
                                        selectOptionsModalidadePGBL.find(
                                          x =>
                                            x.key ===
                                            values.linkSelectModalidadePGBL,
                                        )?.value
                                      }
                                    </Text>
                                  )}
                                  {values.linkSelectModalidadeVGBL && (
                                    <Text
                                      variant="body02-sm"
                                      data-testid="linkSelectModalidadeVGBL"
                                    >
                                      {
                                        selectOptionsModalidadeVGBL.find(
                                          x =>
                                            x.key ===
                                            values.linkSelectModalidadeVGBL,
                                        )?.value
                                      }
                                    </Text>
                                  )}
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Liberação da reserva
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="liberacaoDaReserva"
                                  >
                                    {
                                      selectOptLiberacaoDaReserva.find(
                                        x =>
                                          x.key === values.liberacaoDaReserva,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Outra forma de liberação de reserva.
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="textOutraRegraLiberacaoDaReserva"
                                  >
                                    {textOutraRegraLiberacaoDaReserva}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Regra para o cálculo
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="regraCalculo"
                                  >
                                    {
                                      selectOptionsRegra.find(
                                        x => x.key === values.regraCalculo,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Os valores são iguais para todos os
                                    participantes
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="optionsValoresParticipantes"
                                  >
                                    {values.optionsValoresParticipantes}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <RenderConditional
                                condition={openSelectValoresParticipantes}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <p style={{ color: 'red' }}>
                                    Será necessário preencher os campos
                                    específicos do FOP 064 disponível para
                                    download no final deste formulário.
                                  </p>
                                </Grid.Item>
                              </RenderConditional>
                              <RenderConditional
                                condition={openSelectValorContribuicao}
                              >
                                <>
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor da contribuição
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="linkValorContribuicao"
                                      >
                                        {
                                          selectOptionsValorContribuicao.find(
                                            x =>
                                              x.key ===
                                              values.linkValorContribuicao,
                                          )?.value
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                  <RenderConditional
                                    condition={openSelectValorFixo}
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Valor fixo
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorFixoContribuicao"
                                        >
                                          {values.valorFixoContribuicao}
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={openSelectValorPercentual}
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Definir % do valor com base do salário
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="nomeIndicador"
                                        >
                                          {values.valorPercentualContribuicao}
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={openSelectOutraFormaPagamento}
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Descreva neste espaço como será o
                                          custeio do pagamento
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="nomeIndicador"
                                        >
                                          {textOutraFormaPagamento}
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                </>
                              </RenderConditional>
                              <RenderConditional
                                condition={openSelectPlanoInstituido}
                              >
                                <>
                                  <Divider />
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <TitleSection>Empresa</TitleSection>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor da contribuição
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="linkValorContribuicaoEmpresa"
                                      >
                                        {
                                          selectOptionsValorContribuicao.find(
                                            x =>
                                              x.key ===
                                              values.linkValorContribuicaoEmpresa,
                                          )?.value
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>

                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <br />
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor fixo
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="valorFixoEmpresaContribuicao"
                                      >
                                        {values.valorFixoEmpresaContribuicao}
                                      </Text>
                                    </div>
                                  </Grid.Item>

                                  <RenderConditional
                                    condition={openSelectValorPercentualEmpresa}
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Definir % do valor com base do salário
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorPercentualEmpresaContribuicao"
                                        >
                                          {
                                            values.valorPercentualEmpresaContribuicao
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={
                                      openSelectOutraFormaPagamentoEmpresa
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Descreva neste espaço como será o
                                          custeio do pagamento
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="textOutraFormaPgEmpresa"
                                        >
                                          {textOutraFormaPgEmpresa}
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>

                                  <Divider />
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <TitleSection>Funcionário</TitleSection>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor da contribuição
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="linkValorContribuicaoFuncionario"
                                      >
                                        {
                                          selectOptionsValorContribuicao.find(
                                            x =>
                                              x.key ===
                                              values.linkValorContribuicaoFuncionario,
                                          )?.value
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                  <RenderConditional
                                    condition={openSelectValorFixoFuncionario}
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Valor fixo
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorFixoFuncionarioContribuicao"
                                        >
                                          {
                                            values.valorFixoFuncionarioContribuicao
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={
                                      openSelectValorPercentualFuncionario
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Definir % do valor com base do salário
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorPercentualFuncionarioContribuicao"
                                        >
                                          {
                                            values.valorPercentualFuncionarioContribuicao
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={
                                      openSelectOutraFormaPagamentoFuncionario
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Descreva neste espaço como será o
                                          custeio do pagamento
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="textOutraFormaPagamentoFuncionario"
                                        >
                                          {textOutraFormaPagamentoFuncionario}
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                </>
                              </RenderConditional>
                            </Grid>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion open>
                          <Accordion.Item title="4. Cuidado Extra">
                            <Grid>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Tipo de Cuidado Extra
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="linkSelectCuidadoExtra"
                                  >
                                    {
                                      selectOptionsCuidadoExtra.find(
                                        x =>
                                          x.key ===
                                          values.linkSelectCuidadoExtra,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <RenderConditional condition={openSelectPeculio}>
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Regra para o cálculo
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="linkSelectRegraCuidadoExtraPeculio"
                                    >
                                      {
                                        selectOptionsRegraCuidado63.find(
                                          x =>
                                            x.key ===
                                            values.linkSelectRegraCuidadoExtraPeculio,
                                        )?.value
                                      }
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                              <RenderConditional condition={openSelectPensao}>
                                {values.linkSelectAnosPensao && (
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Período escolhido para o recebimento da
                                        Pensão Por Prazo Certo
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="linkSelectAnosPensao"
                                      >
                                        {values.linkSelectAnosPensao}
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                )}
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Regra para o cálculo
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="linkSelectRegraCuidadoExtraPensao"
                                    >
                                      {
                                        selectOptionsRegraCuidadoPensao.find(
                                          x =>
                                            x.key ===
                                            values.linkSelectRegraCuidadoExtraPensao,
                                        )?.value
                                      }
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                              <RenderConditional
                                condition={openSelectFormaPagamentoCuidado}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Forma de pagamento
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="formaDePagamentoCuidado"
                                    >
                                      {
                                        selectOptionsPagamento.find(
                                          x =>
                                            x.key ===
                                            values.formaDePagamentoCuidado,
                                        )?.value
                                      }
                                    </Text>
                                  </div>
                                </Grid.Item>
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Os valores são iguais para todos os
                                      participantes
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valoresParticipantesCuidado"
                                    >
                                      {values.valoresParticipantesCuidado}
                                    </Text>
                                  </div>
                                </Grid.Item>
                                <RenderConditional
                                  condition={
                                    openSelectValorContribuicaoCuidadoExtra
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor da contribuição
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="linkValorContribuicaoCuidadoExtra"
                                      >
                                        {
                                          selectOptionsValorContribuicao.find(
                                            x =>
                                              x.key ===
                                              values.linkValorContribuicaoCuidadoExtra,
                                          )?.value
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                  <RenderConditional
                                    condition={openSelectValorFixoCuidadoExtra}
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Valor fixo
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorFixoCuidadoExtraContribuicao"
                                        >
                                          {
                                            values.valorFixoCuidadoExtraContribuicao
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={
                                      openSelectValorPercentualCuidadoExtra
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Definir % do valor com base do salário
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorPercentualCuidadoExtraContribuicao"
                                        >
                                          {
                                            values.valorPercentualCuidadoExtraContribuicao
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={
                                      openSelectOutraFormaPagamentoCuidadoExtra
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Descreva neste espaço como será o
                                          custeio do pagamento
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="textOutraFormaPagamentoCuidadoExtra"
                                        >
                                          {textOutraFormaPagamentoCuidadoExtra}
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                </RenderConditional>
                                <RenderConditional
                                  condition={
                                    openSelectValoresParticipantesCuidado
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <p style={{ color: 'red' }}>
                                      Será necessário preencher os campos do FOP
                                      063 disponível para download no final
                                      deste formulário.
                                    </p>
                                  </Grid.Item>
                                </RenderConditional>
                                <RenderConditional
                                  condition={
                                    openSelectPlanoInstituidoCuidadoExtra
                                  }
                                >
                                  <Divider />
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <TitleSection>Empresa</TitleSection>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor da contribuição
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="valorContribuicaoEmpresaCuidadoExtra"
                                      >
                                        {
                                          selectOptionsValorContribuicao.find(
                                            x =>
                                              x.key ===
                                              values.linkValorContribuicaoEmpresaCuidadoExtra,
                                          )?.value
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                  <RenderConditional
                                    condition={
                                      openSelectValorFixoEmpresaCuidadoExtra
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Valor fixo
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorFixoEmpresaCuidadoExtraContribuicao"
                                        >
                                          {
                                            values.valorFixoEmpresaCuidadoExtraContribuicao
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={
                                      openSelectValorPercentualEmpresaCuidadoExtra
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Definir % do valor com base do salário
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorPercentualEmpresaCuidadoExtraContribuicao"
                                        >
                                          {
                                            values.valorPercentualEmpresaCuidadoExtraContribuicao
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={
                                      openSelectOutraFormaPagamentoEmpresaCuidadoExtra
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Descreva neste espaço como será o
                                          custeio do pagamento
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="textOutraFormaPagamentoEmpresaCuidadoExtra"
                                        >
                                          {
                                            textOutraFormaPagamentoEmpresaCuidadoExtra
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <Divider />
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <TitleSection>Funcionário</TitleSection>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor da contribuição
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="linkValorContribuicaoFuncionarioCuidadoExtra"
                                      >
                                        {
                                          selectOptionsValorContribuicao.find(
                                            x =>
                                              x.key ===
                                              values.linkValorContribuicaoFuncionarioCuidadoExtra,
                                          )?.value
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                  <RenderConditional
                                    condition={
                                      openSelectValorFixoFuncionarioCuidadoExtra
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Valor fixo
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorFixoFuncionarioCuidadoExtra"
                                        >
                                          {
                                            values.valorFixoFuncionarioCuidadoExtraContribuicao
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={
                                      openSelectValorPercentualFuncionarioCuidadoExtra
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Definir % do valor com base do salário
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="valorPercentualFuncionarioCuidadoExtraContribuicao"
                                        >
                                          {
                                            values.valorPercentualFuncionarioCuidadoExtraContribuicao
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                  <RenderConditional
                                    condition={
                                      openSelectOutraFormaPagamentoFuncionarioCuidadoExtra
                                    }
                                  >
                                    <Grid.Item xs={1} lg={1 / 3}>
                                      <br />
                                      <div>
                                        <TextLabel variant="body02-sm">
                                          Descreva neste espaço como será o
                                          custeio do pagamento
                                        </TextLabel>
                                        <Text
                                          variant="body02-sm"
                                          data-testid="textOutraFormaPagamentoFuncionarioCuidadoExtra"
                                        >
                                          {
                                            textOutraFormaPagamentoFuncionarioCuidadoExtra
                                          }
                                        </Text>
                                      </div>
                                    </Grid.Item>
                                  </RenderConditional>
                                </RenderConditional>
                              </RenderConditional>
                            </Grid>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion open>
                          <Accordion.Item title="5. Dados do Plano">
                            <Grid>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Tipo de Fundo
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="linkSelectTipoFundo"
                                  >
                                    {
                                      selectOptionsTipoFundo.find(
                                        x =>
                                          x.key === values.linkSelectTipoFundo,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Concessão de benefício
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="linkSelectTipoConcessao"
                                  >
                                    {
                                      selectOptionsConcessaoBeneficio.find(
                                        x =>
                                          x.key ===
                                          values.linkSelectTipoConcessao,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              {values.idadeAposentadoria && (
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Idade de aposentadoria
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="idadeAposentadoria"
                                    >
                                      {values.idadeAposentadoria}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              )}
                              {values.prazoContribuicao && (
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Prazo de contribuição
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="prazoContribuicao"
                                    >
                                      {values.prazoContribuicao}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              )}
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Forma de pagamento de fatura
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="linkSelectTipoPagamentoFatura"
                                  >
                                    {
                                      selectOptionsPagamentoFatura.find(
                                        x =>
                                          x.key ===
                                          values.linkSelectTipoPagamentoFatura,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              {values.linkSelectVencimentoFatura && (
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Dia de vencimento da fatura
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="linkSelectVencimentoFatura"
                                    >
                                      {
                                        selectOptionsVencimentoFatura.find(
                                          x =>
                                            x.key ===
                                            values.linkSelectVencimentoFatura,
                                        )?.value
                                      }
                                    </Text>
                                  </div>
                                </Grid.Item>
                              )}
                              {values.aporteUnico && (
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Aporte Único
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="aporteUnico"
                                    >
                                      {values.aporteUnico}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              )}
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Dados para Cobrança
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="linkSelectDadosCobranca"
                                  >
                                    {
                                      selectOptionsDadosCobranca.find(
                                        x =>
                                          x.key ===
                                          values.linkSelectDadosCobranca,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <RenderConditional
                                condition={openSelectDebitoEmConta}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Agência
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="agencia"
                                    >
                                      {values.agencia}
                                    </Text>
                                  </div>
                                </Grid.Item>
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Operação
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="agencia"
                                    >
                                      {values.linkSelectDadosOperacao}
                                    </Text>
                                  </div>
                                </Grid.Item>
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Conta
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="agencia"
                                    >
                                      {values.conta}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                            </Grid>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion open>
                          <Accordion.Item title="6. Informações Complementares">
                            <Grid>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Informar a família de taxas concedida.
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="textInformacoesComplementares"
                                  >
                                    {textInformacoesComplementares}
                                  </Text>
                                </div>
                              </Grid.Item>
                            </Grid>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion open>
                          <Accordion.Item title="7. Dados do responsável pelo preenchimento">
                            <Grid>
                              <Grid.Item xs={1} lg={1 / 2}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Nome completo
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="nomeCompletoResponsavel"
                                  >
                                    {values.nomeCompletoResponsavel}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Telefone
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="telefoneResponsavel"
                                  >
                                    {values.telefoneResponsavel}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 2}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    E-mail
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="emailResponsavel"
                                  >
                                    {values.emailResponsavel}
                                  </Text>
                                </div>
                              </Grid.Item>
                            </Grid>
                          </Accordion.Item>
                        </Accordion>
                      </Card.Content>
                      <Display justify="center" style={{ marginTop: 20 }}>
                        <Button
                          variant="secondary"
                          onClick={fecharModal}
                          data-testid="voltar-ok"
                        >
                          OK
                        </Button>
                      </Display>
                    </Display>
                  </Modal>
                  <Grid>
                    <Grid.Item xs={1 / 3}>
                      <Display>
                        <Button
                          variant="outlined"
                          type="button"
                          onClick={() => resetForm()}
                        >
                          Limpar
                        </Button>
                        <Button
                          data-testid="visualizar-fop63"
                          type="button"
                          onClick={openModal}
                        >
                          Visualizar
                        </Button>
                        <Button
                          loading={loading}
                          data-testid="form-enviar-fop63"
                          type="submit"
                        >
                          Enviar
                        </Button>
                      </Display>
                    </Grid.Item>
                  </Grid>
                </>
              </form>
            </Card.Content>
          </Card>
        )}
      </Formik>
    </Display>
  );
};

export default Fop063;
