// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Text, Checkbox } from '@cvp/design-system/react';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { CoberturaContratada } from '../../types/CoberturasContratadas.types';
import ButtonsMudarEtapa from '../ButtonsMudarEtapa';
import useAtivarContribuicao from '../../hooks/useAtivarContribuicao';
import {
  IPayloadAcaoCoberturas,
  IResponseAcaoCoberturas,
} from '../../types/AcaoCoberturas.types';
import * as S from './styles';
import TextoAviso from '../TextoAviso';
import { verificarStatusComprovante } from '../../utils/suspensaoContribuicoes';
import ListaInformacoesPlanoReserva from '../ListaInformacoesPlanoReserva';
import ListaInformacoesPlanoCuidadoExtra from '../ListaInformacoesPlanoCuidadoExtra';
import { useSuspenderContribuicao } from '../../hooks/useSuspenderContribuicao';
import { checkLabel, tipoServicoLabel } from '../../utils/texts';
import {
  EnumTipoBeneficios,
  StatusCoberturas,
} from '../../types/EnumsStatusAtivacaoSuspensao.types';

type InformacoesPlanoProps = {
  proximaEtapa: (dados: IResponseAcaoCoberturas | undefined) => void;
  etapaAnterior: () => void;
  dadosPlano: CoberturaContratada | undefined;
};

const InformacoesPlano: React.FC<InformacoesPlanoProps> = ({
  proximaEtapa,
  etapaAnterior,
  dadosPlano,
}) => {
  const [statusChecked, setStatusChecked] = useState(false);
  const [verComprovante, setVerComprovante] = useState(false);
  const servicoAtivacao =
    dadosPlano?.status === StatusCoberturas.CoberturaSuspensa;
  const servicoSuspensao =
    dadosPlano?.status === StatusCoberturas.CoberturaAtiva;

  const request: IPayloadAcaoCoberturas = {
    NumeroBeneficio: dadosPlano?.numeroBeneficio,
    NumeroPlano: dadosPlano?.numeroPlano,
    TipoContribuicao: dadosPlano?.tipoContribuicao,
  };

  const {
    response: responseAtivar,
    loading: fetchingAtivarContribuicao,
    fetchData: refetchAtivarContribuicao,
  } = useAtivarContribuicao(request);
  const ativarContribuicao = responseAtivar?.entidade;
  const {
    response: responseSuspender,
    loading: fetchingSuspenderContribuicao,
    fetchData: suspenderContribuicao,
  } = useSuspenderContribuicao(request);
  const responseSuspenderContribuicao = responseSuspender?.entidade;

  if (checkIfAllItemsAreTrue([!!ativarContribuicao, servicoAtivacao])) {
    proximaEtapa(ativarContribuicao);
  }

  if (
    checkIfAllItemsAreTrue([!!responseSuspenderContribuicao, servicoSuspensao])
  ) {
    proximaEtapa(responseSuspenderContribuicao);
  }

  const chamarServico = () => {
    setVerComprovante(true);
  };

  useEffect(() => {
    if (
      verificarStatusComprovante(
        StatusCoberturas.CoberturaAtiva,
        verComprovante,
        dadosPlano?.status,
      )
    ) {
      suspenderContribuicao();
    }
    if (
      verificarStatusComprovante(
        StatusCoberturas.CoberturaSuspensa,
        verComprovante,
        dadosPlano?.status,
      )
    ) {
      refetchAtivarContribuicao();
    }
  }, [dadosPlano?.status, verComprovante]);

  return (
    <>
      <Text variant="body01-lg" color="primary" margin>
        Confirmar{' '}
        {tipoServicoLabel(
          tryGetValueOrDefault([dadosPlano?.tipo], ''),
          servicoAtivacao,
        )}{' '}
        de{' '}
        <RenderConditional
          condition={dadosPlano?.tipo === EnumTipoBeneficios.RISCO}
        >
          Cuidado Extra
        </RenderConditional>
        <RenderConditional
          condition={dadosPlano?.tipo !== EnumTipoBeneficios.RISCO}
        >
          Contribuição de Renda
        </RenderConditional>
      </Text>
      <Text variant="body02-sm" color="text">
        Antes de prosseguir, confira abaixo as informações do plano:
      </Text>
      <RenderConditional
        condition={dadosPlano?.tipo === EnumTipoBeneficios.RISCO}
      >
        <ListaInformacoesPlanoCuidadoExtra dados={dadosPlano} />
      </RenderConditional>
      <RenderConditional
        condition={dadosPlano?.tipo === EnumTipoBeneficios.PREV}
      >
        <ListaInformacoesPlanoReserva dados={dadosPlano} />
      </RenderConditional>

      <TextoAviso suspensao={servicoAtivacao} tipo={dadosPlano?.tipo} />
      <br />
      <S.ContainerCheckobox>
        <Checkbox
          checked={statusChecked}
          onClick={() => {
            setStatusChecked(!statusChecked);
          }}
        />
        <Text variant="body02-sm">
          {checkLabel(
            tryGetValueOrDefault([dadosPlano?.tipo], ''),
            servicoAtivacao,
          )}
        </Text>
      </S.ContainerCheckobox>
      <ButtonsMudarEtapa
        etapaAnterior={etapaAnterior}
        proximaEtapa={chamarServico}
        suspensao={servicoAtivacao}
        disabled={!statusChecked}
        tipo={dadosPlano?.tipo}
        loading={checkIfSomeItemsAreTrue([
          fetchingSuspenderContribuicao,
          fetchingAtivarContribuicao,
        ])}
      />
    </>
  );
};

export default InformacoesPlano;
