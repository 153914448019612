// @ts-nocheck
import React, { useState } from 'react';
import { Button, Grid, Display } from '@cvp/design-system/react';
import { IResponseConsultaDps, IModalProps } from '../interface/IResponseDps';
import { TEXT_MODAL, DADOS_INICIAL } from '../constants/texts';
import { Formik } from 'formik';
import * as S from './styles';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { ModalConfirmacao } from './ModalConfirmacao';
import { useFormValidation } from '../hooks/useFormValidation';
import { FormFields } from './FormFieldsDetalhes';
import RenderConditional from 'main/components/RenderConditional';

const ModalDetalhes: React.FC<IModalProps> = ({
  open,
  onClose,
  handleSubmitForm,
  dadosForm,
  loading,
}) => {
  const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);
  const [formValues, setFormValues] = useState<IResponseConsultaDps | null>(
    null,
  );

  const handleConfirmSubmit = async () => {
    if (formValues) {
      try {
        return handleSubmitForm(formValues);
      } catch {
        return { success: false, message: TEXT_MODAL.MENSAGEM_ERRO_DPS };
      }
    }
    return { success: false, message: TEXT_MODAL.MENSAGEM_ERRO_FORMULARIO };
  };

  const { validateForm } = useFormValidation();

  return (
    <>
      <S.Modal show={open} onClose={onClose}>
        <RenderConditional condition={loading}>
          <Display justify="center" alignItems="center">
            <Button variant="text" loading />
          </Display>
        </RenderConditional>
        <RenderConditional condition={!loading}>
          <S.Text color="text" margin={10}>
            {TEXT_MODAL.TITULO}
          </S.Text>
          <Formik
            initialValues={tryGetValueOrDefault([dadosForm], DADOS_INICIAL)}
            onSubmit={values => {
              setFormValues(values);
              setShowModalConfirmacao(true);
            }}
            validate={validateForm}
          >
            {({
              handleSubmit,
              handleBlur,
              setFieldValue,
              values,
              touched,
              errors,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <FormFields
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Display>
                  <Button variant="primary" disabled={loading}>
                    Prosseguir
                  </Button>
                </Display>
              </form>
            )}
          </Formik>
        </RenderConditional>
      </S.Modal>

      <ModalConfirmacao
        open={showModalConfirmacao}
        handleClose={() => setShowModalConfirmacao(false)}
        handleConfirm={handleConfirmSubmit}
        loading={loading}
      />
    </>
  );
};

export default ModalDetalhes;
