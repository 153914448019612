// @ts-nocheck
import {
  Card,
  Display,
  Text,
  Tag,
  Select,
  Disclaimer,
  Grid,
  Button,
} from '@cvp/design-system/react';
import * as S from '../components/styles';
import For from 'main/components/For';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import { useEffect, useState } from 'react';
import ModalResumoTransferenciaModalConfirmacao from '../components/Modais/ModalResumoTransferenciaModalConfirmacao';
import Icon from 'main/components/Icon';
import { useResumoTransferencia } from '../hooks/useResumoTransferencia';
import RenderConditional from 'main/components/RenderConditional';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import { useHistory } from 'react-router';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';

export const ResumoTransferencia = () => {
  const [showModal, setShowModal] = useState(false);
  const [openModalAlerta, setOpenModalAlerta] = useState<boolean>(true);

  const history = useHistory();
  const {
    loading,
    loadingConsultarSaldo,
    existeCoberturasNaoComercializas,
    coberturas,
    saldoTotal,
    responseConsultarSaldo,
    loadingListarMelhoresTaxas,
    responseListarMelhoresTaxas,
    fundos,
    mensagemError,
    coberturasNaoComerciaizadas,
    handleNextStep,
    setFamiliaFundoEscolhida,
    clearFeatureData,
    isDisabledButton,
  } = useResumoTransferencia();

  const {
    data: responseObterContatosDefault,
    isLoading: loadingObterContatos,
  } = useObterContatosDefaultPrevidencia();
  const { emailDefault, numerosTelefone } = responseObterContatosDefault ?? {};

  const handleCloseModalAlerta = () => {
    setOpenModalAlerta(!openModalAlerta);
    if (existeCoberturasNaoComercializas) setShowModal(!showModal);
  };

  useEffect(() => {
    clearFeatureData();
  }, []);

  if (
    checkIfSomeItemsAreTrue([
      loading,
      loadingConsultarSaldo,
      loadingListarMelhoresTaxas,
      loadingObterContatos,
    ])
  )
    return <CardSkeleton />;

  return (
    <Display type="block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content>
          <Text variant="headline-05" color="primary" margin>
            Transferência com alteração de taxa
          </Text>
          <RenderConditional condition={!mensagemError}>
            <S.Disclaimer>
              <Disclaimer.Content
                icon={<Icon name="warning" />}
                text="O certificado selecionado na origem será utilizado como parâmetro
            para emissão do novo certificado."
              />
            </S.Disclaimer>
          </RenderConditional>
          <RenderConditional condition={!!mensagemError}>
            <S.Disclaimer variant="error">
              <Disclaimer.Content
                icon={<Icon name="warning" />}
                text={mensagemError}
              />
            </S.Disclaimer>
          </RenderConditional>
          <br />
          <RenderConditional condition={!mensagemError}>
            <Card>
              <S.CardContent>
                <Grid>
                  <Grid.Item md={1 / 2}>
                    <Text color="primary">
                      <strong>Certificado de origem</strong>
                    </Text>
                    <br />
                    <Text variant="caption">Coberturas:</Text>
                    <For
                      each={tryGetValueOrDefault(
                        [coberturas?.dadosCoberturas],
                        [],
                      )}
                    >
                      {item => (
                        <Text variant="caption">
                          <strong>{item.cobertura}</strong>
                        </Text>
                      )}
                    </For>
                    <br />
                    <Text variant="caption">Fundos:</Text>
                    <For each={tryGetValueOrDefault([fundos], [])}>
                      {item => (
                        <Text variant="caption">
                          <strong>{item.descricaoFundo}</strong>
                        </Text>
                      )}
                    </For>
                    <Display>
                      <Text variant="caption">Saldo:</Text>
                      <Tag
                        variant="secondary"
                        value={tryGetMonetaryValueOrDefault([saldoTotal], 0)}
                      />
                    </Display>
                    <Text variant="caption">
                      <strong>
                        Faixa de saldo atual total:{' '}
                        {responseListarMelhoresTaxas?.entidade?.taxaAtual?.replace(
                          '.',
                          ',',
                        )}
                      </strong>
                    </Text>
                    <S.AlertContainer>
                      A faixa de saída se refere ao montante total que o cliente
                      possui atualmente neste CPF.
                    </S.AlertContainer>
                  </Grid.Item>
                  <Grid.Item md={1 / 2}>
                    <Text color="primary">
                      <strong>Taxa de administração de fundo</strong>
                    </Text>
                    <Text variant="caption">
                      Selecione a família de fundos a ser considerada para a
                      transferência
                    </Text>
                    <S.SectionTitlePrimary>
                      <Text variant="caption">
                        Saldo total de reserva no CPF:{' '}
                        {tryGetMonetaryValueOrDefault(
                          [responseConsultarSaldo?.dados.saldo],
                          0,
                        )}
                      </Text>
                    </S.SectionTitlePrimary>
                    <Display alignItems="center">
                      <div>Faixas de reserva disponível</div>
                      <div style={{ flex: 1 }}>
                        <Select
                          placeholder="Escolha a opção"
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) =>
                            setFamiliaFundoEscolhida(Number(value))
                          }
                        >
                          {responseListarMelhoresTaxas?.entidade?.familias.map(
                            item => (
                              <Select.Item
                                key={item.familia}
                                value={item.familia}
                                text={item.descricao}
                              />
                            ),
                          )}
                        </Select>
                      </div>
                    </Display>
                  </Grid.Item>
                </Grid>
                <Display alignItems="flex-end" justify="flex-end">
                  <Button
                    variant="primary"
                    onClick={handleNextStep}
                    disabled={isDisabledButton()}
                  >
                    Avançar
                  </Button>
                </Display>
              </S.CardContent>
            </Card>
          </RenderConditional>
        </Card.Content>
      </Card>
      <ModalResumoTransferenciaModalConfirmacao
        show={showModal}
        onClose={() => history.push('/cliente/produtos')}
        coberturasNaoComerciaizadas={tryGetValueOrDefault(
          [coberturasNaoComerciaizadas],
          [],
        )}
        onContinue={() => setShowModal(!showModal)}
      />

      <ModalAlertaContatos
        open={openModalAlerta}
        onClose={handleCloseModalAlerta}
        redirect={() =>
          history.push('/cliente/produtos/previdencia/dados-participante')
        }
        dados={{
          email: emailDefault,
          telefone: numerosTelefone,
        }}
      />
    </Display>
  );
};
