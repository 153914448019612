// @ts-nocheck
import { Button, Display, Text } from '@cvp/design-system/react';
import { IModalEditarConfirmacaoProps } from 'painelAdministracao/types/IEditarFop';
import { Modal } from 'painelAdministracao/components/ModalEditar/styles';
import { MENSAGEM_CONFIRMACAO_EXCLUSAO } from 'painelAdministracao/constants/constants';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const ModalEditarConfirmacao = ({
  fopEditar,
  handleClose,
  handleConfirm,
}: IModalEditarConfirmacaoProps) => {
  return (
    <Modal show onClose={() => handleClose()}>
      <Text variant="body02-md" align="left" margin>
        {MENSAGEM_CONFIRMACAO_EXCLUSAO.replace(
          '{nomeFop}',
          tryGetValueOrDefault([fopEditar?.nome], ''),
        )}
      </Text>
      <Display justify="center" style={{ marginTop: 20 }}>
        <Button
          variant="secondary"
          onClick={handleClose}
          data-testid="cancelar"
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => handleConfirm()}
          data-testid="confirmar"
        >
          Confirmar
        </Button>
      </Display>
    </Modal>
  );
};
