// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS_PROPOSTA_VIDA } from 'propostasVida/config/endpoints';
import { ISolicitacaoDps } from '../interface/ISolicitaDps';

export const useEnviarDps = () => {
  const { response, loading, fetchData } = usePeco<
    ISolicitacaoDps,
    ISolicitacaoDps
  >({
    api: {
      operationPath: PECOS_PROPOSTA_VIDA.SolicitarReenvioLinkPorEmailDps,
    },
    handleResponse: {
      throwToastErrorGI: false,
      throwToastErrorBFF: false,
    },
    cache: false,
  });
  return { response, loading, fetchData };
};
