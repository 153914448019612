// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Text } from '@cvp/design-system/react';
import TabelaFundos from 'previdencia/components/TabelaFundos';
import Modal from 'main/components/Modal';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { retornaTipoResgate } from 'previdencia/utils/tabelaFundos';
import ButtonsValidaDadosTabelaFundos from 'previdencia/components/ButtonsValidaDadosTabelaFundos';
import { Fundo, TipoResgate } from 'previdencia/types/Fundo.type';
import useObterCertificadosPrevidencia from 'consultaCliente/features/listaCardProduto/hooks/useObterCertificadosPrevidencia';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { TabelaFundosHandles } from 'previdencia/components/TabelaFundos/TabelaFundos';
import Icon from 'main/components/Icon';
import { AppContext } from 'main/contexts/AppContext';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import RenderConditional from 'main/components/RenderConditional';
import {
  converterValorMinimo,
  verificarExistenciaRequestEFundosDestino,
  verificarFundosPreenchidos,
  verificarValorMaximo,
} from '../../utils/transferenciaFundosInvestimento';
import TextoInformativoResgate from '../TextoInformativoResgate';
import useObterFundosPorCertificado from '../../hooks/useObterFundosPorCertificado';
import useObterFundosDestino from '../../hooks/useObterFundosDestino';
import { useTransferenciaFundosContext } from '../../contexts/ContextTransferenciaFundos';
import {
  converterFundoParaRequestTransferenciaEntreFundos,
  converterResponseFundosPorCertificadoParaFundo,
} from '../../factories/transferenciaFundoInvestimentoFactory';
import { ITransferenciaFundosFeatureData } from '../../types/ITransferenciaFundosFeatureData';
import { IPayloadReservasOrigem } from 'previdencia/types/ITransferirEntreFundos';

const DadosResgate: React.FC = () => {
  const tabelaFundosRef = useRef<TabelaFundosHandles>(null);
  const { response, loading, buscarFundosPorCertificado } =
    useObterFundosPorCertificado();
  const { fundosDestino, buscarFundosDestino, fetchingFundosDestino } =
    useObterFundosDestino();
  const {
    escolherFundosOrigem,
    fundosDestinoPossiveis,
    definirNumeroTransferencia,
    redefinirVariaveisSessao,
  } = useTransferenciaFundosContext();
  const [openModal, setOpenModal] = useState(false);
  const [tipoResgate, setTipoResgate] = useState<TipoResgate>();
  const [mensagemModal, setMensagemModal] = useState<string[]>([]);
  const { cliente } = useContext(AppContext);
  const { isLoading: loadingCertificadosPrevidencia } =
    useObterCertificadosPrevidencia(cliente?.cpfCnpj);
  const fundo = converterResponseFundosPorCertificadoParaFundo(response);
  const [request, setRequest] = useState<IPayloadReservasOrigem[] | undefined>(
    undefined,
  );
  const [
    openModalQtdeMaximaTipoTransferencia,
    setOpenModalQtdeMaximaTipoTransferencia,
  ] = useState(false);
  const [desabilitarBotaoContinuar, setDesabilitarBotaoContinuar] =
    useState<boolean>(false);
  const { setFeatureData, featureData } =
    usePrevidenciaContext<ITransferenciaFundosFeatureData>();

  const verificarQuantidadeMaximaFundosComMesmoTipoTransferencia = () => {
    if (
      checkIfAllItemsAreTrue([
        (featureData?.fundosSelecionados ?? []).length > 0,
        !!response,
      ])
    ) {
      const resultParcial = tryGetValueOrDefault(
        [featureData?.fundosSelecionados],
        [],
      ).filter(x =>
        checkIfAllItemsAreTrue([x.selecionado, !!x.isParcial]),
      )?.length;

      const qtdMaximaFundos = Number(
        response?.retornoCertificado?.qtdMaximaFundos,
      );

      const quantidadeFundosSelecionadoParcial = checkIfAllItemsAreTrue([
        tryGetValueOrDefault([resultParcial], 0) === fundo.length,
        qtdMaximaFundos === fundo.length,
      ]);

      setOpenModalQtdeMaximaTipoTransferencia(
        quantidadeFundosSelecionadoParcial,
      );

      if (quantidadeFundosSelecionadoParcial)
        setDesabilitarBotaoContinuar(true);
      else setDesabilitarBotaoContinuar(false);
    }
  };

  const atualizarFundosParaResgate = () => {
    const tabelaAlterada =
      tabelaFundosRef.current?.validateFundos()?.tabelaAlterada;
    const mensagemErro = verificarValorMaximo(
      tabelaAlterada,
      response?.retornoCertificado?.vlrMinPermancenciaFundo,
    );

    const dadosInvalidos = verificarFundosPreenchidos(tabelaAlterada);

    if (dadosInvalidos) {
      return undefined;
    }

    if (mensagemErro) {
      setMensagemModal(mensagemErro);
      setOpenModal(true);
      return undefined;
    }

    if (tabelaAlterada) {
      escolherFundosOrigem(tabelaAlterada);
      setRequest(
        converterFundoParaRequestTransferenciaEntreFundos(tabelaAlterada),
      );
    }
    return undefined;
  };

  useEffect(() => {
    if (response) {
      setTipoResgate(retornaTipoResgate(response));
    } else buscarFundosPorCertificado();
  }, [response]);

  useEffect(() => {
    if (request) {
      buscarFundosDestino(request);
    }
  }, [request]);

  useEffect(() => {
    if (verificarExistenciaRequestEFundosDestino(fundosDestino, request)) {
      setRequest(undefined);
      fundosDestinoPossiveis(fundosDestino);
      definirNumeroTransferencia(fundosDestino?.numTransferencia);
    }
  }, [fundosDestino, request]);

  useEffect(() => {
    redefinirVariaveisSessao();
  }, []);

  useEffect(() => {
    verificarQuantidadeMaximaFundosComMesmoTipoTransferencia();
  }, [featureData]);

  const showLoading = checkIfSomeItemsAreTrue([
    loadingCertificadosPrevidencia,
    loading,
    fetchingFundosDestino,
  ]);

  const isTransferenciaTotal = (fundosToCheck: Fundo[]) => {
    return checkIfAllItemsAreTrue([
      fundosToCheck.length === 1,
      !fundosToCheck[0].isParcial,
    ]);
  };
  return (
    <>
      <RenderConditional condition={showLoading}>
        <SkeletonLoading blocks={2} />
      </RenderConditional>
      <RenderConditional condition={!showLoading}>
        <TextoInformativoResgate
          valorMinimo={converterValorMinimo(
            response?.retornoCertificado?.vlrMinPermancenciaFundo,
          )}
        />

        <Text color="error" margin>
          <Icon name="warning" /> Essa ação não é uma simulação e após
          confirmada não poderá ser cancelada.
        </Text>

        <TabelaFundos
          fundos={fundo}
          quantidadeMaximaFundos={fundo.length}
          isLoading={loading}
          ref={tabelaFundosRef}
          tipoResgate={tipoResgate}
          resgate
          valorTotal={undefined}
          setFundosSelecionados={(fundos: Fundo[]) => {
            setFeatureData({
              ...featureData,
              fundosSelecionados: fundos,
              transferenciaTotal: isTransferenciaTotal(fundos),
            });
          }}
        />

        <ButtonsValidaDadosTabelaFundos
          disabled={desabilitarBotaoContinuar || (fundo ?? []).length === 0}
          onClick={() => atualizarFundosParaResgate()}
          labelButtonSubmit="Continuar"
        />
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <>
            <strong>
              Deve ser deixado em transferência parcial o saldo mínimo de{' '}
              {formatarValorPadraoBrasileiro(
                response?.retornoCertificado?.vlrMinPermancenciaFundo,
              )}{' '}
              nos seguintes fundos:
            </strong>
            <br />
            {mensagemModal?.map(e => (
              <li>{e}</li>
            ))}
          </>
        </Modal>
        <Modal
          open={openModalQtdeMaximaTipoTransferencia}
          onClose={() => setOpenModalQtdeMaximaTipoTransferencia(false)}
        >
          <>
            <Text variant="caption-02">
              <strong>
                A quantidade máxima de fundos permitida para o certificado é de{' '}
                {fundo.length} fundos.
              </strong>
            </Text>

            <br />
            <Text variant="caption-02" color="error">
              <strong>
                Para prosseguir, é necessário indicar pelo menos um fundo como
                transferência total.
              </strong>
            </Text>
          </>
        </Modal>
      </RenderConditional>
    </>
  );
};

export default DadosResgate;
