// @ts-nocheck
import { ReactElement } from 'react';

export type Reserva = {
  codigoReserva: string;
  descricaoReserva?: string;
  saldo?: number;
  codigoObjetivoTransferencia?: string;
  tipoTransferencia?: string;
  valorTransferencia?: number;
  isParcial?: boolean;
};

export type MultifundoPjTable = {
  check: React.ReactElement;
  descricaoPerfilFundo: string;
  perfil: React.ReactElement;
  reservas: Reserva[];
  valorSaldo: number;
  valorMinimo: number;
  saldo: string;
  percentualRentabilidadeUltimoAno: string;
  codigoFundo: string;
};

export interface MultifundoPj {
  codigoFundo: string;
  descricaoFundo: string;
  valorSaldo: number;
  percentualRentabilidadeUltimoAno?: number;
  reservas: Reserva[];
  descricaoPerfilFundo?: string;
  numeroCnpj?: string;
  codigoPerfilFundo?: string;
}

export type FundosContribuicao = {
  codFundo: string;
  desFundo: string;
  valorSaldo: number;
  codObjetivo: string;
  codReserva: string;
};

export interface MultifundoPjData extends MultifundoPj {
  check?: ReactElement;
  selecionado?: boolean;
  perfil?: ReactElement;
  valor?: number;
  valorContribuicao?: number;
}

export interface SelecionarTransferenciaProps {
  id: string;
  reserva?: string;
  tipoTransferencia?: string;
  saldo: number;
  valorTransferencia?: number;
}

export interface SelecionarTransferenciaUtils
  extends SelecionarTransferenciaProps {
  fundos: MultifundoPjData[];
}

export interface IResponseConsultaFundo {
  codigoMensagem: string;
  descricaoMensagem: string;
  valorContribuicaoRegular: number;
  valorMinimoContribuicao: number;
  valorMinimoDiversificacaoRegular: number;
  fundosContribuicao?: FundosContribuicao[];
}

export enum TipoFundo {
  EXISTENTE = 'existente',
  NOVO = 'novo',
}

export enum TipoTransferencia {
  PARCIAL = 'Parcial',
  TOTAL = 'Total',
}

export type DataType = {
  resumo: any;
  restante?: any;
  valor: any;
};
