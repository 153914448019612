// @ts-nocheck
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { MultifundoPjData, Reserva } from 'previdencia/types/MultifundosPJ';
import {
  ordemPadraoDeReservas,
  statusAssinaturaEletronica,
} from 'previdencia/features/TransferenciaMultifundos/constants/tabelaFundos';
import { IResponseConfirmarAssinatura } from '../types/IContribuicaoRegular';

export const somaValorReserva = (
  fundos: MultifundoPjData[] | undefined,
  reserva: string,
): number => {
  const soma = fundos?.reduce((accumulator, fundo) => {
    const indexReserva = fundo.reservas.findIndex(item => {
      return item.descricaoReserva?.toLocaleLowerCase() === reserva;
    });
    return (
      accumulator +
      tryGetValueOrDefault(
        [fundo.reservas[indexReserva]?.valorTransferencia],
        0,
      )
    );
  }, 0);
  return tryGetValueOrDefault([soma], 0);
};

export const somaTransferenciaPorFundo = (reservas: Reserva[]) => {
  return reservas?.reduce((accumulator, reserva) => {
    return accumulator + tryGetValueOrDefault([reserva?.valorTransferencia], 0);
  }, 0);
};

export const removeDuplicates = (fundos: MultifundoPjData[] | undefined) => {
  const uniqueItems: { [key: string]: MultifundoPjData } = {};

  return fundos?.filter(item => {
    const { codigoFundo } = item;
    if (!uniqueItems[codigoFundo]) {
      uniqueItems[codigoFundo] = item;
      return true;
    }
    return false;
  });
};

export const ordenarReservas = (reservas: Reserva[]): Reserva[] => {
  const resultado: Reserva[] = [];
  ordemPadraoDeReservas.forEach(descricaoReserva => {
    const reserva = reservas.find(
      item =>
        item?.descricaoReserva?.toUpperCase() ===
        descricaoReserva.toUpperCase(),
    );
    if (reserva) resultado.push(reserva);
  });

  return resultado;
};

export const ordenarReservasMultifundoPjData = (
  tabelaFundos: MultifundoPjData[],
): MultifundoPjData[] => {
  return tabelaFundos.map(tabela => {
    return {
      ...tabela,
      reservas: ordenarReservas(tabela.reservas),
    };
  });
};

export const confirmarAssinaturaEletronica = (
  responseConfirmar: IResponseConfirmarAssinatura | undefined,
) => responseConfirmar?.status === statusAssinaturaEletronica;
