// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Card, Button, Grid } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import TableFilter from 'main/components/Table/TableFilter/TableFilter';
import masks from 'main/utils/masks';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { IFilterableEntity } from 'main/types/IFilterableEntity';
import { usePendenteDevolucao } from 'reter/features/retencao/hooks/usePendenteDevolucao';
import ModalMotivo from 'reter/features/retencao/components/ModalMotivo';
import { COLUNAS_TABELA_RECURSOS_PENDENTES_DEVOLUCAO } from 'reter/features/retencao/constants/constants';
import { ModalFlag } from 'reter/features/retencao/types/ModalMotivo';
import { filterOptions } from 'reter/features/retencao/factories/filterOptionsFactory';
import * as S from 'reter/features/retencao/pages/ListaRetencoes/styles';

const ListaRetencoes: React.FC = () => {
  const {
    loading,
    response: listaPendenteDevolucao,
    obterListaPendenteDevolucao,
  } = usePendenteDevolucao();

  const history = useHistory();

  const [openModalMotivo, setOpenModalMotivo] = useState<ModalFlag>({
    open: false,
  });

  useEffect(() => {
    obterListaPendenteDevolucao();
  }, []);

  const handleEditarContaBancaria = (numeroResgate: string): void => {
    history.push({
      pathname: '/reter/recursos-pendentes-recebimento/dados-bancario',
      state: { numeroResgate },
    });
  };

  const dadosTabelaRecursosPendentesDevolucao = ():
    | IFilterableEntity[]
    | undefined => {
    return listaPendenteDevolucao?.map(item => ({
      ...item,
      dadosPessoa: [
        {
          ...item.dadosPessoa[0],
          cpf: masks.cpf.mask(item.dadosPessoa[0].cpf),
        },
      ],
      nome: item.dadosPessoa[0].nome,
      cpf: item.dadosPessoa[0].cpf,
      valorReceber: formatarValorPadraoBrasileiro(item.valorReceber),
      motivo: (
        <S.BotaoVisualizar
          variant="text"
          onClick={() =>
            setOpenModalMotivo({ open: true, message: item.motivo })
          }
        >
          Visualizar
        </S.BotaoVisualizar>
      ),
      id: (
        <Button onClick={() => handleEditarContaBancaria(item.numeroResgate)}>
          Atualizar
        </Button>
      ),
    }));
  };

  if (loading) {
    return <SkeletonLoading lines={4} />;
  }

  return (
    <>
      <S.CardContainer>
        <Card.Content>
          <S.DuvidasContainer alignItems="center">
            <Grid.Item xs={1} style={{ display: 'flex' }}>
              <S.TextoDuvidas variant="body02-ms" color="text">
                Está com dúvidas?
              </S.TextoDuvidas>
              <Link to="/reter/recursos-pendentes-recebimento/faq-retencao">
                Clique aqui e saiba mais!
              </Link>
            </Grid.Item>
          </S.DuvidasContainer>
          <TableFilter
            dataToFilter={tryGetValueOrDefault(
              [dadosTabelaRecursosPendentesDevolucao()],
              [],
            )}
            filterTextPartial
            filterOptions={filterOptions}
          >
            {filteredData => (
              <Table
                noHeader
                columns={COLUNAS_TABELA_RECURSOS_PENDENTES_DEVOLUCAO}
                data={filteredData}
                responsive
                pagination
                paginationPerPage={10}
                noDataComponent="Não há dados para serem exibidos."
                paginationComponentOptions={{
                  rowsPerPageText: 'Items por página',
                  rangeSeparatorText: 'de',
                }}
              />
            )}
          </TableFilter>
        </Card.Content>
      </S.CardContainer>
      <ModalMotivo
        data={openModalMotivo}
        handleClose={() => setOpenModalMotivo({ open: false })}
      />
    </>
  );
};

export default ListaRetencoes;
