// @ts-nocheck
import styled from 'styled-components';
import { Container as ContainerDS } from '@cvp/design-system/react';

export const Container = styled(ContainerDS)(({ theme: { color } }) => ({
  padding: '20px 0',
  borderRadius: 8,
  marginTop: 20,
  marginBottom: 20,

  '& p': {
    textAlign: 'center',
  },

  '& p:last-child': {
    fontWeight: 'bold',
  },
}));
