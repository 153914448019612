// @ts-nocheck
export const obterEndpointListarPortabilidadeEntrada = (): string =>
  '/PortalEconomiario/PECO_ListarPortabilidadesEntrada';

export const PECOS_RETENCAO_PORTABILIDADE = {
  registrarPortabilidade: 'PECO_RegistrarRetencaoPortabilidade',
  listarPortabilidades: 'PECO_ListarPortabilidade',
  detalhesPortabilidade: 'PECO_ObterPortabilidade',
  listarPortabilidadesEntrada: 'PECO_ListarPortabilidadesEntrada',
};
