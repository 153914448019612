// @ts-nocheck
import ValidableElement from 'main/features/Validation/types/ValidableElement';
import ValidableState from 'main/features/Validation/types/ValidableState';
import { useRef } from 'react';

function useValidation<T>(): readonly [
  React.RefObject<ValidableElement<T>>,
  () => ValidableState,
  () => void,
] {
  const ref = useRef<ValidableElement<T>>(null);
  const validate = () =>
    ref.current?.validate() || { isValid: false, errorMsg: '' };

  const scrollIntoView = () => ref.current?.scrollIntoView();

  return [ref, validate, scrollIntoView] as const;
}

export default useValidation;
