// @ts-nocheck
import styled, { css } from 'styled-components/macro';
import { Tag, Display } from '@cvp/design-system/react';

export const InputFileContainer = styled.div`
  input[type='file'] {
    display: none;
  }
  width: 100%;
  max-width: 500px;
`;

const inputTagCss = css`
  border: none;
  background: none;
  padding: 0 4px;
  cursor: pointer;
  transition: color;
  display: flex;
  align-items: center;
  font-size: 12px;

  &:hover {
    filter: brightness(0.86);
  }

  & > svg {
    height: 18px;
  }
`;

export const InputRenameFile = styled.input`
  background: none;
  border: none;
  width: 100%;
  padding: 1px 4px;
`;

export const ContainerMultipleFiles = styled(Display)`
  margin-top: 12px;
`;

export const TagMultipleFileInput = styled(Tag)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 14px;
  text-align: left;
  margin: 4px 8px;
  border-radius: 2px;
  font-size: 12px;
`;

export const InputTagRename = styled.button`
  color: #202020;
  ${inputTagCss}
`;

export const InputTagRemove = styled.button`
  color: #fa1a1a;
  ${inputTagCss}
`;

export const UploadButton = styled.label({
  transition: '0.1s',
  background: 'transparent',
  display: 'inline-block',
  border: '1px dotted #005FC8',
  width: '100%',
  cursor: 'pointer',
  outline: 'none',
  padding: '12px 24px',
  fontFamily: 'CAIXA Std',
  fontSize: '14px',
  textAlign: 'center',
  textDecoration: 'none',
  position: 'relative',
  pointerEvents: 'auto',
  minWidth: '148px',
  color: '#005FC8',
});

export const Input = styled.input.attrs({ type: 'file' })(() => ({
  display: 'none',
}));
