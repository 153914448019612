// @ts-nocheck
import { useContext, useState } from 'react';

import { AppContext } from 'main/contexts/AppContext';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import useObterDadosCpfPorCertificado from 'previdencia/hooks/useObterDadosCpfPorCertificado';
import { filtrarDadosPorCertificado } from 'previdencia/utils/previdencia';
import { chatAtendimentoSalesForce } from 'previdencia/utils/chatAtendimentoSalesForce';
import AlteracaoContaDebito from 'previdencia/features/AlteracaoFormaDadosPagamento/components/AlteracaoContaDebito';
import ComprovanteAlteracao from 'previdencia/features/AlteracaoFormaDadosPagamento/components/ComprovanteAlteracao';
import ConfirmarAlteracao from 'previdencia/features/AlteracaoFormaDadosPagamento/components/ConfirmarAlteracao';
import DadosPagamentoAtual from 'previdencia/features/AlteracaoFormaDadosPagamento/components/DadosPagamentoAtual';
import { DadosPagamento } from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';
import { buscarTipoContribuicaoPorTipoBeneficio } from 'previdencia/features/AlteracaoFormaDadosPagamento/factories/AlteracaoFormaDadosPagamentoFactory';
import { DefaultValue } from 'previdencia/features/AlteracaoFormaDadosPagamento/types/enum';
import * as PECO from 'previdencia/features/AlteracaoFormaDadosPagamento/hooks/usePecoAlteracaoFormaDadosPagamento';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';

const useAlteracaoFormaDadosPagamento = () => {
  const {
    cliente: { numCertificado: certificado },
  } = useContext(AppContext);

  const {
    dadosListaContasBancarias,
    loadingDadosListaContasBancarias,
    obterListaContasBancarias,
  } = PECO.usePecoListarContasBancarias();

  const { data: dadosCpf, isLoading: loadingCpf } =
    useObterDadosCpfPorCertificado();

  const {
    dadosRecuperacaoContribuicoesCertificado,
    loadingDadosRecuperacaoContribuicoesCertificado,
  } = PECO.usePecoRecuperarContribuicoesCertificado();

  const { dadosResponsavelFinanceiro, loadingDadosResponsavelFinanceiro } =
    PECO.usePecoResponsavelFinanceiroFormaPagamento();

  const [etapaAtual, setEtapaAtual] = useState<number>(0);
  const [canal, setCanal] = useState<string>('');
  const [dadosPagamento, setDadosPagamento] = useState<
    DadosPagamento | undefined
  >();

  const proximo = (): void => {
    setEtapaAtual(etapaAtual + 1);
  };

  const enviarDados = (
    canalId: string,
    dadosNovaConta?: DadosPagamento,
  ): void => {
    setCanal(canalId);
    setDadosPagamento({
      ...dadosNovaConta,
      digitoAgencia: CONSTS.DIGITO_AGENCIA,
    } as DadosPagamento);

    proximo();
  };

  const voltar = (): void => {
    setEtapaAtual(etapaAtual - 1);
  };

  const voltarInicio = (): void => {
    setEtapaAtual(0);
    setCanal('');
    setDadosPagamento(undefined);
    obterListaContasBancarias();
  };

  const dadosResponsavel = {
    nome: dadosResponsavelFinanceiro?.pessoaNome,
    cpf: dadosResponsavelFinanceiro?.cpf,
  };

  const dadosCertificadoFiltrado = filtrarDadosPorCertificado(
    dadosCpf,
    certificado,
  );

  const handleAbreChat = (): string => {
    return chatAtendimentoSalesForce();
  };

  const obterValorContribuicao = (): string => {
    const beneficioPR =
      dadosRecuperacaoContribuicoesCertificado?.beneficioContribuicaoCertificado?.find(
        beneficio => beneficio.tipoBeneficio === DefaultValue.TIPO_BENEFICIO_PR,
      );

    return tryGetValueOrDefault([beneficioPR?.valorContribuicaoEsperado], '');
  };

  const skeletonLoading: boolean = checkIfSomeItemsAreTrue([
    loadingDadosListaContasBancarias,
    loadingCpf,
    loadingDadosResponsavelFinanceiro,
    loadingDadosRecuperacaoContribuicoesCertificado,
  ]);

  const fluxosTela = [
    <DadosPagamentoAtual
      dadosCertificado={dadosCertificadoFiltrado}
      dadosResponsavel={dadosResponsavel}
      valor={obterValorContribuicao()}
      alterarConta={proximo}
    />,
    <AlteracaoContaDebito
      voltar={voltar}
      dadosBancos={dadosListaContasBancarias}
      dadosResponsavel={dadosResponsavel}
      alterar={enviarDados}
    />,
    <ConfirmarAlteracao
      proximo={proximo}
      voltar={voltar}
      dadosListaBancos={dadosListaContasBancarias}
      canalId={canal}
      idPessoa={dadosResponsavelFinanceiro?.idPessoa}
      dadosNovaConta={dadosPagamento}
      dadosCertificado={dadosCertificadoFiltrado}
      tipoBeneficio={buscarTipoContribuicaoPorTipoBeneficio(
        dadosRecuperacaoContribuicoesCertificado,
      )}
    />,
    <ComprovanteAlteracao
      dadosParticipante={dadosCertificadoFiltrado}
      dadosListaBancos={dadosListaContasBancarias}
      canalId={canal}
      dadosNovaConta={dadosPagamento}
      voltar={voltarInicio}
    />,
  ];

  const etapaAtualFluxo = fluxosTela[etapaAtual];

  return {
    skeletonLoading,
    dadosResponsavel,
    handleAbreChat,
    etapaAtualFluxo,
  };
};

export default useAlteracaoFormaDadosPagamento;
