// @ts-nocheck
/* eslint-disable react-hooks/rules-of-hooks */
import { obterEndpointValidarConta } from 'main/features/ValidacaoContaCaixa/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseValidarConta } from 'main/features/ValidacaoContaCaixa/types/DadosContaBancaria';
import { IDadosBancariosContaCaixa } from 'main/features/ValidacaoContaCaixa/types/IDadosBancariosContaCaixa';
import { useToast } from 'main/hooks/useToast';

export const validarConta = async (
  dadosBancarios: IDadosBancariosContaCaixa,
): Promise<ResponseValidarConta | undefined> => {
  const { toastError } = useToast();

  const { data } = await api.post<IApiResponse<undefined>>(
    obterEndpointValidarConta(),
    {
      cpfCnpj: dadosBancarios.cpf,
      codigoAgencia: dadosBancarios.agencia,
      codigoOperacao: dadosBancarios.operacao,
      digitoVerificador: dadosBancarios.digito,
      numeroBanco: dadosBancarios.codigoInstituicao,
      numeroConta: dadosBancarios.conta,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) {
    toastError('Erro ao validar conta Caixa.');
    throw new Error(mensagemErro);
  }

  return data?.dados?.entidade;
};
