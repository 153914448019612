// @ts-nocheck
import { STRING_SEM_CARACTERES_ESPECIAIS_E_ESPACOS } from './regex';

export const capitalize = (text: string) =>
  text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

export const withPlaceholder = (text?: string) => text || '--';

export const isNegativeCurrent = (value: string) => {
  const newValue = `${value}`.replace(/[^0-9.-]+/g, '');
  const newNum = newValue.replace(',', '').split('.').join('');
  if (newNum === '') {
    return false;
  }

  const valueParsed = parseFloat(newNum);

  if (Math.sign(valueParsed) === -1) {
    return true;
  }

  return false;
};

export const removeEmptySpace = (value = '') => value?.replace(/\s/g, '');

export const removeEmptySpaceAndSpecialCharacters = (value = '') =>
  value?.replace(STRING_SEM_CARACTERES_ESPECIAIS_E_ESPACOS, '');

export const removeEmptySpaceAndForceUpperCase = (value = '') =>
  removeEmptySpace(value).toUpperCase();

export /**
 * Converte uma string em capitalize, exceto para as preposições fornecidas.
 * @param {string} str - A string a ser convertida.
 * @returns {string} A string convertida em capitalize.
 */
function capitalizeExceptPrepositions(str: string): string {
  const prepositions = [
    'a',
    'ante',
    'após',
    'até',
    'com',
    'contra',
    'de',
    'desde',
    'em',
    'entre',
    'para',
    'per',
    'perante',
    'por',
    'sem',
    'sob',
    'sobre',
    'trás',
    'ou',
  ];

  return str
    .split(' ')
    .map(word =>
      prepositions.includes(word.toLowerCase())
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(' ');
}

/**
 * Formata uma palavra com base em um número, adicionando plural ou forma personalizada se necessário.
 *
 * @param {IFormatarPluralParams} params - Objeto contendo os parâmetros para formatar a palavra.
 * @param {string} params.palavra - A palavra base a ser formatada.
 * @param {string | number} params.quantidade - A quantidade que determina se a palavra deve ser singular ou plural.
 * @param {string} [params.sufixoPlural='s'] - Um sufixo opcional para formar o plural (padrão: "s").
 * @param {string} [params.palavraPlural] - Uma forma específica da palavra no plural. Se fornecida, será usada no lugar da palavra com o sufixo.
 * @returns {string} - A palavra formatada de acordo com a quantidade, incluindo plural personalizado se necessário.
 */

export function formatarPlural({
  palavra,
  quantidade,
  sufixoPlural = 's',
  palavraPlural,
}: IFormatarPluralParams): string {
  if (Number(quantidade) === 1) {
    return palavra;
  }

  const palavraPluralCustom = tryGetValueOrDefault([palavraPlural], '');

  return getTernaryResult(
    !!palavraPluralCustom,
    palavraPluralCustom,
    palavra + sufixoPlural,
  );
}
