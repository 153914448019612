// @ts-nocheck
import { textosAtivarSuspender } from '../constants/AtivarSuspenderContribuicaoConstants';
import { CoberturaContratada } from '../types/CoberturasContratadas.types';
import {
  EnumTipoBeneficios,
  StatusCoberturas,
} from '../types/EnumsStatusAtivacaoSuspensao.types';

export function verificarDadoAtivo(status: string | undefined): number {
  if (status === StatusCoberturas.CoberturaAtiva) {
    return 1;
  }
  return 0;
}

export const verificarStatusComprovante = (
  status: string,
  verComprovante: boolean,
  statusRecebidoDadosPlano: string | undefined,
): boolean => {
  return verComprovante && statusRecebidoDadosPlano === status;
};

export const validarCodigoRequisicao = (
  dadosContribuicao: CoberturaContratada | undefined,
): string => {
  if (dadosContribuicao?.tipo === EnumTipoBeneficios.RISCO) {
    return textosAtivarSuspender.comprovanteCancelamentoRI;
  }

  if (
    dadosContribuicao?.status === StatusCoberturas.CoberturaSuspensa &&
    dadosContribuicao?.tipo === EnumTipoBeneficios.PREV
  ) {
    return textosAtivarSuspender.comprovanteAtivacao;
  }

  return textosAtivarSuspender.comprovanteSuspensao;
};
