// @ts-nocheck
import { useContext, useEffect, useState } from 'react';

import useCardsProduto from 'main/hooks/useCardsProduto';
import { AppContext } from 'main/contexts/AppContext';
import {
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import {
  IClientePrevidencia,
  IDadosClientePrev,
} from 'previdencia/types/IClientePrevidencia';
import { usePecoObterInformacoesCertificado } from 'previdencia/features/TransferenciaEntreCertificados/hooks/usePecoObterInformacoesCertificado';
import * as FACTORY from 'previdencia/features/TransferenciaEntreCertificados/factories';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import { TDataCard } from 'previdencia/types/ITransferirEntreFundos';
import {
  IObterContatosDefaultPrevidencia,
  useObterContatosDefaultPrevidencia,
} from 'main/hooks/useObterContatosDefaultPrevidencia';

const useTransferenciaEntreCertificados = () => {
  const { navigateTo } = usePrevNavigation();
  const { goDadosPlano } = usePrevNavigation();

  const [openModalAvisoCobertura, setOpenModalAvisoCobertura] = useState(false);
  const [openModalSimulacao, setOpenModalSimulacao] = useState(false);
  const [openModalAlerta, setOpenModalAlerta] = useState(true);
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  const { cliente }: { cliente: IClientePrevidencia<IDadosClientePrev> } =
    useContext(AppContext);

  const { featureData, setFeatureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const {
    dadosCardsPrevidencia,
    isLoadingPrevidencia,
    fetchDadosCardsPrevidencia,
  } = useCardsProduto();

  const { responseInfoCertificado, certificadoOrigem, loadingInfoCertificado } =
    usePecoObterInformacoesCertificado();

  const resumoPlanoOrigemSelecionado =
    FACTORY.obterResumoPlanoOrigemSelecionadoFactory(cliente);

  const listaInfoCertificado = FACTORY.filtrarCertificadosFactory(
    responseInfoCertificado?.retornoCertificado?.certificadosDestino,
    dadosCardsPrevidencia,
  ) as TDataCard[];
  const { data: responseObterContatosDefault } =
    useObterContatosDefaultPrevidencia();

  const { emailDefault, numerosTelefone } =
    tryGetValueOrDefault<IObterContatosDefaultPrevidencia>(
      [responseObterContatosDefault],
      {} as IObterContatosDefaultPrevidencia,
    );

  const tamanhoLista = tryGetValueOrDefault([listaInfoCertificado], []).length;
  const coberturasRunOff =
    responseInfoCertificado?.retornoCertificado?.certificadoOrigem?.coberturas;

  const simulacaoValida =
    responseInfoCertificado?.retornoCertificado?.indSimulacaoValida;
  const isLoadingGridCards: boolean = checkIfSomeItemsAreTrue([
    isLoadingPrevidencia,
    loadingInfoCertificado,
  ]);

  const salvarCertificadoOrigemContexto = (): void => {
    if (certificadoOrigem) {
      setFeatureData({
        ...featureData,
        certificadoOrigem: tryGetValueOrDefault([certificadoOrigem], {}),
      });
    }
  };
  const handleCloseCoberturas = () => {
    setOpenModalAvisoCobertura(false);
  };

  const handleCloseModalSimulacao = () => {
    setOpenModalSimulacao(false);
    goDadosPlano();
  };

  const handleCloseModalAlerta = () => {
    setOpenModalAlerta(false);
  };
  const selecionarCertificadoDestinoContexto = (
    checked: boolean,
    data: TDataCard | undefined,
  ): void => {
    setFeatureData({
      ...featureData,
      certificadoDestinoSelecionado: getTernaryResult(checked, data, undefined),
    });
  };

  const salvarEtapasContexto = (): void => {
    setFeatureData({
      step: CONSTS_TEXT.NUM_ETAPAS.PLANO_DESTINO,
      steps: CONSTS_TEXT.ETAPAS,
    });
  };

  const redirecionarTipoTransferencia = () => {
    navigateTo(
      `${CONSTS_TEXT.URL_TRANSFERENCIA.BASE}${CONSTS_TEXT.URL_TRANSFERENCIA.TIPO_TRANSFERENCIA}`,
    );
  };
  const redirecionarDadosParticipante = () => {
    navigateTo('dados-participante');
  };
  const validarCoberturas = () => {
    if (coberturasRunOff?.existeCoberturasNaoComercializadas === 'false') {
      redirecionarTipoTransferencia();
    }
    setOpenModalAvisoCobertura(true);
  };
  const handleConfirmarCobertura = () => {
    setOpenModalAvisoCobertura(false);
    redirecionarTipoTransferencia();
  };

  useEffect(() => {
    if (cpfCnpj) {
      fetchDadosCardsPrevidencia(cpfCnpj);
    }
  }, [cpfCnpj]);

  useEffect(() => {
    if (simulacaoValida) {
      setOpenModalSimulacao(simulacaoValida === 'false');
    }
  }, [simulacaoValida]);

  useEffect(salvarCertificadoOrigemContexto, [responseInfoCertificado]);
  useEffect(salvarEtapasContexto, []);

  return {
    openModalAvisoCobertura,
    openModalSimulacao,
    resumoPlanoOrigemSelecionado,
    listaInfoCertificado,
    tamanhoLista,
    simulacaoValida,
    coberturasRunOff,
    step: featureData?.step,
    steps: featureData?.steps,
    isLoadingGridCards,
    openModalAlerta,
    emailDefault,
    numerosTelefone,
    validarCoberturas,
    handleConfirmarCobertura,
    handleCloseCoberturas,
    handleCloseModalSimulacao,
    handleCloseModalAlerta,
    redirecionarDadosParticipante,
    selecionarCertificadoDestinoContexto,
  };
};

export default useTransferenciaEntreCertificados;
