// @ts-nocheck
import { Card, Grid, Text } from '@cvp/design-system/react';

import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const CardResumoDistribuicaoContribuicaoRegular = ({
  formik,
  valorContribuicaoRegularlAtual,
}: SOLICITACAO_RESGATE_TYPES.ICardResumoDistribuicaoContribuicaoRegularProps) => {
  return (
    <Grid.Item xs={1} lg={2 / 5}>
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <RenderConditional
            condition={
              UTILS.calcularValorRestanteContribuicaoRegular(
                formik,
                valorContribuicaoRegularlAtual,
              ).calculoRestante >= 0
            }
          >
            <Text variant="body02-md">
              Restante:{' '}
              <strong>
                {tryGetMonetaryValueOrDefault(
                  UTILS.calcularValorRestanteContribuicaoRegular(
                    formik,
                    valorContribuicaoRegularlAtual,
                  ).calculoRestante,
                )}
              </strong>
            </Text>
          </RenderConditional>
          <RenderConditional
            condition={
              UTILS.calcularValorRestanteContribuicaoRegular(
                formik,
                valorContribuicaoRegularlAtual,
              ).calculoRestante < 0
            }
          >
            <Text variant="body02-md">
              {`O valor preenchido é maior que a contribuição atual de ${tryGetMonetaryValueOrDefault(
                valorContribuicaoRegularlAtual,
              )}.`}
            </Text>
          </RenderConditional>
          <Text variant="body02-md">
            Total:{' '}
            <strong>
              {tryGetMonetaryValueOrDefault(
                UTILS.calcularValorRestanteContribuicaoRegular(
                  formik,
                  valorContribuicaoRegularlAtual,
                ).calculoTotal,
              )}
            </strong>
          </Text>
        </Card.Content>
      </Card>
    </Grid.Item>
  );
};

export default CardResumoDistribuicaoContribuicaoRegular;
