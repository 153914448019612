// @ts-nocheck
import { Card, Grid, Text } from '@cvp/design-system/react';
import React from 'react';
import * as S from 'sinistro/features/statusSinistro/pages/styles';
import { ResultadoSinistroProps } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';
import TextoResultado from 'sinistro/features/statusSinistro/components/TextoResultado';
import TextoDadosCliente from 'sinistro/features/statusSinistro/components/TextoDadosCliente';

const ResultadoSinistro: React.FC<ResultadoSinistroProps> = ({
  dados: { cpfTitular, nomeTitular },
}) => {
  return (
    <Grid>
      <TextoResultado />
      <Grid.Item xs={1} md={1 / 2} xl={1 / 2}>
        <S.CardFitContent>
          <Card.Content>
            <S.ContainerCardResultado>
              <TextoDadosCliente />
              <Text variant="body01-sm" data-testid="dadosClienteSinistroCPF">
                <b>CPF</b>: {cpfTitular}
              </Text>

              <Text variant="body01-sm" data-testid="dadosClienteSinistroNome">
                <b>Nome do Segurado</b>: {nomeTitular}
              </Text>
            </S.ContainerCardResultado>
          </Card.Content>
        </S.CardFitContent>
      </Grid.Item>
    </Grid>
  );
};

export default ResultadoSinistro;
