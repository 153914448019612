// @ts-nocheck
import React, { useContext } from 'react';
import {
  Display,
  Card,
  Text,
  Grid,
  Divider,
  Button,
} from '@cvp/design-system/react';
import { useDadosSegurado } from 'seguros/hooks';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { AppContext } from 'main/contexts/AppContext';
import * as CONST from '../constants/InformacoesIRPF';

export const InformacoesIRPF: React.FC = () => {
  const { clienteVida } = useContext(AppContext);
  const { dadosSegurado } = useDadosSegurado();
  const emailDefault = dadosSegurado?.entidade?.email;

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.DADOS_SEGURO_IRPF,
    parametrosEnvio: {
      numeroCertificadoBilhete: String(clienteVida.numCertificado),
      codigoProduto: clienteVida.codigoProduto,
    },
  };

  const { goDadosSeguradoVida } = useVidaNavigation();
  return (
    <Display type="display-inline">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-06" color="primary" margin>
                {CONST.TITULO_IRPF}
              </Text>
            </Grid.Item>
          </Grid>
          <Text variant="body01-md" color="text-light" margin>
            {CONST.PARA_FINS.INICIO} <u>{CONST.PARA_FINS.MORTE}</u>{' '}
            {CONST.PARA_FINS.OU} <u>{CONST.PARA_FINS.INVALIDEZ}</u>{' '}
            {CONST.PARA_FINS.PERMANENTE} <u>{CONST.PARA_FINS.NAO_PRECISAM}</u>{' '}
            {CONST.PARA_FINS.FIM}
            <br />
            <br />
            {CONST.PARA_AQUELES.INICIO} <u>{CONST.PARA_AQUELES.DESPESAS}</u>{' '}
            {CONST.PARA_AQUELES.MEIO} <u>{CONST.PARA_AQUELES.MORTE}</u> ou{' '}
            <u>{CONST.PARA_AQUELES.INVALIDEZ}</u> {CONST.PARA_AQUELES.FIM}{' '}
            <b>
              <a href={CONST.PARA_AQUELES.SITE_DA_RECEITA_LINK}>
                ({CONST.PARA_AQUELES.SITE_DA_RECEITA})
              </a>
            </b>
            .
          </Text>

          <Divider />

          <Display>
            <Button variant="outlined" onClick={goDadosSeguradoVida}>
              Voltar
            </Button>
            <EmailSenderModal
              objetoEmail={objetoEmail}
              defaultEmail={emailDefault}
              seguro
            />
            <Button variant="secondary" onClick={window.print}>
              Imprimir
            </Button>
          </Display>
        </Card.Content>
      </Card>
    </Display>
  );
};
