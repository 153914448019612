// @ts-nocheck
import {
  DadosArquivo,
  IResponseObterListaFopsAtivos,
} from 'main/features/Administracao/types/IFops';
import {
  IAdmContextProviderProps,
  IAdmProps,
} from 'painelAdministracao/types/IAdministracaoContext';
import { TListaAgrupadaPorTipo } from 'painelAdministracao/types/ListagemTypes';
import { createContext, useContext, useMemo, useState } from 'react';

export const AdmContext = createContext<IAdmProps>({} as IAdmProps);
AdmContext.displayName = 'Administracao Context';

const AdmContextProvider = ({ children }: IAdmContextProviderProps) => {
  const [modalEditar, setModalEditar] = useState<boolean>(false);
  const [dadosAgrupadosPorTipo, setDadosAgrupadosPorTipo] = useState<
    TListaAgrupadaPorTipo[]
  >([]);
  const [fopEditar, setFopEditar] = useState<IResponseObterListaFopsAtivos>();
  const [documentoEditar, setDocumentoEditar] = useState<
    DadosArquivo | undefined
  >();

  const handleEditarFop = (
    fop: IResponseObterListaFopsAtivos,
    documento?: DadosArquivo,
  ) => {
    setModalEditar(true);
    setFopEditar(fop);
    setDocumentoEditar(documento);
  };

  const handleAdicionarFop = (fop: IResponseObterListaFopsAtivos) => {
    setModalEditar(true);
    const novoArquivo = {
      nome: fop.nome,
      codigo: fop.codigo,
      tipo: fop.tipo,
    };
    setFopEditar(novoArquivo);
  };

  const handleFecharModal = () => {
    setModalEditar(false);
    setFopEditar(undefined);
  };

  const contextValue: IAdmProps = useMemo((): IAdmProps => {
    return {
      modalEditar,
      setModalEditar,
      handleFecharModal,
      dadosAgrupadosPorTipo,
      setDadosAgrupadosPorTipo,
      fopEditar,
      setFopEditar,
      documentoEditar,
      handleEditarFop,
      handleAdicionarFop,
    };
  }, [modalEditar, dadosAgrupadosPorTipo, fopEditar, documentoEditar]);

  return (
    <AdmContext.Provider value={contextValue}>{children}</AdmContext.Provider>
  );
};
export const useAdmContext = () => {
  return useContext(AdmContext);
};

export default AdmContextProvider;
