// @ts-nocheck
import React, { useState } from 'react';
import { Button } from '@cvp/design-system/react';
import IframeResizer from 'iframe-resizer-react';
import * as S from './styles';
import RenderConditional from '../RenderConditional';

type ModalTokenDocuSignProps = {
  open: boolean;
  handleClose(close: boolean): void;
  urlTokenPage: string | undefined;
  exibirBotaoFecharModal?: boolean;
};

export const ModalTokenDocuSign: React.FunctionComponent<
  ModalTokenDocuSignProps
> = ({ open, handleClose, urlTokenPage, exibirBotaoFecharModal = false }) => {
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <S.ModalDocuSign
      showCloseButton={exibirBotaoFecharModal}
      show={open}
      onClose={handleClose}
    >
      <RenderConditional condition={loading}>
        <S.LoadignModalAssinaturaDocuSign>
          <Button variant="text" loading />
        </S.LoadignModalAssinaturaDocuSign>
      </RenderConditional>

      <IframeResizer
        log
        src={urlTokenPage}
        style={{
          width: '100%',
          minWidth: '100%',
          height: '100%',
          minHeight: '700px',
          border: 0,
        }}
        onLoad={() => setLoading(false)}
        checkOrigin={false}
      />
    </S.ModalDocuSign>
  );
};

export default ModalTokenDocuSign;
