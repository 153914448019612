// @ts-nocheck
import React, { useContext, useEffect } from 'react';
import { ListaPreAnalise } from 'main/features/prospeccao/components/ListaPreAnalise';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';
import { useListarProspeccoes } from '../../../hooks/useListarProspeccoes';

const ListaProspeccoesAvaliadas: React.FC = () => {
  const { getOperadorRouteDetails } = useContext(ProspeccaoContext);
  const { prospeccoesPerfilOperacao, obterListaProspeccoesPerfilOperacao } =
    useListarProspeccoes();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    obterListaProspeccoesPerfilOperacao();
  }, []);

  if (prospeccoesPerfilOperacao.loading) return <SkeletonLoading lines={4} />;

  return (
    <div>
      <ListaPreAnalise
        profile={USER_PROFILES.OPERADOR}
        onClickDetails={(prospeccao: IProspeccao) =>
          getOperadorRouteDetails(prospeccao)
        }
        data={prospeccoesPerfilOperacao.items}
        title="Acompanhamento GEFEA"
        noDataMessage="Não há análises finalizadas pelos times médico e técnico"
      />
    </div>
  );
};

export default ListaProspeccoesAvaliadas;
