// @ts-nocheck
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import ValidateResult from 'main/features/Validation/types/ValidateResult';
import { useState, useCallback } from 'react';
import useValidation from './useValidation';

/**
 * @deprecated
 * @description Use Formik
 */

function useFieldLink<T>(
  initialValue: T,
): readonly [LinkedValue<T>, () => ValidateResult] {
  const [inputState, set] = useState({
    value: initialValue,
    isValid: true,
    errorMsg: '',
  });

  const get = useCallback(() => inputState, [inputState]);

  const [ref, validate, scrollIntoView] = useValidation<T>();

  const validateField = (): ValidateResult => {
    const { isValid } = validate();
    return { isValid, scrollIntoView };
  };

  const link = { get, set, ref } as LinkedValue<T>;

  return [link, validateField] as const;
}

export default useFieldLink;
