// @ts-nocheck
import * as Yup from 'yup';
import { parseDateStringToDate } from 'main/utils';
import {
  tryGetValueOrDefault,
  checkIfSomeItemsAreTrue,
  checkIfAllItemsAreTrue,
} from 'main/utils/conditional';
import * as UTILS from 'prestamista/features/dadosSegurado/utils/DadosSegurado';
import * as CONSTS from 'prestamista/features/dadosSegurado/constants/constants';
import * as DADOS_SEGURADO_TYPES from 'prestamista/features/dadosSegurado/types/DadosSegurado';
import { ResponseObterDadosCertificadoPrestamista } from 'prestamista/types/ICertifucatiPrestamista';

export const initialFormikStateFactory = (
  dadosCertificadoPrestamista:
    | ResponseObterDadosCertificadoPrestamista
    | undefined,
) => {
  const listaTelefones = dadosCertificadoPrestamista?.segurados?.[0]?.telefones;

  const email = dadosCertificadoPrestamista?.segurados?.[0]?.emails?.[0];

  return {
    cep: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.codigoCepSegurado],
      '',
    ),
    endereco: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.nomeLogradouroSegurado],
      '',
    ),
    numeroEndereco: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.numeroLogradouroSegurado],
      '',
    ),
    complementoEndereco: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.descricaoComplementoEnderecoSegurado],
      '',
    ),
    bairro: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.nomeBairroSegurado],
      '',
    ),
    cidade: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.nomeCidadeSegurado],
      '',
    ),

    uf: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.codigoUfSegurado],
      '',
    ),

    ddd1: tryGetValueOrDefault(
      [
        UTILS.obterDadosTelefonePorTipo(
          CONSTS.TIPO_TELEFONE.CELULAR.ID,
          listaTelefones,
        )?.numeroDdd?.toString(),
      ],
      '',
    ),
    telefone1: tryGetValueOrDefault(
      [
        UTILS.obterDadosTelefonePorTipo(
          CONSTS.TIPO_TELEFONE.CELULAR.ID,
          listaTelefones,
        )?.numeroTelefone?.toString(),
      ],
      '',
    ),

    ddd2: tryGetValueOrDefault(
      [
        UTILS.obterDadosTelefonePorTipo(
          CONSTS.TIPO_TELEFONE.RESIDENCIAL.ID,
          listaTelefones,
        )?.numeroDdd?.toString(),
      ],
      '',
    ),
    telefone2: tryGetValueOrDefault(
      [
        UTILS.obterDadosTelefonePorTipo(
          CONSTS.TIPO_TELEFONE.RESIDENCIAL.ID,
          listaTelefones,
        )?.numeroTelefone?.toString(),
      ],
      '',
    ),

    email: tryGetValueOrDefault([email?.uri], ''),
    nomeSocial: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.nomeSocial],
      '',
    ),
    utilizarNomeSocial: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.utilizarNomeSocial],
      false,
    ),
    nome: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.segurados[0]?.nomeRazaoSocial],
      '',
    ),
  };
};

export const formikAtualizacaoDadosSeguradoSchemaFactory = () => {
  return Yup.object().shape({
    endereco: Yup.string().required(
      CONSTS.MSG_VALIDACAO_ERRO.CAMPO_OBRIGATORIO,
    ),
    numeroEndereco: Yup.string().required(
      CONSTS.MSG_VALIDACAO_ERRO.CAMPO_OBRIGATORIO,
    ),
    bairro: Yup.string().required(CONSTS.MSG_VALIDACAO_ERRO.CAMPO_OBRIGATORIO),
    utilizarNomeSocial: Yup.boolean(),
    nomeSocial: Yup.string().when('utilizarNomeSocial', {
      is: true,
      then: Yup.string()
        .required(CONSTS.MSG_VALIDACAO_ERRO.CAMPO_OBRIGATORIO)
        .min(3, 'Informe um nome válido'),
    }),
  });
};

const dynamicPayloadAtualizacaoTelefone = (
  values: DADOS_SEGURADO_TYPES.IFormikValues,
  dadosCertificadoPrestamista:
    | ResponseObterDadosCertificadoPrestamista
    | undefined,
) => {
  const listaTelefones = dadosCertificadoPrestamista?.segurados?.[0]?.telefones;

  const incrementoSequencial =
    listaTelefones?.length && listaTelefones.length + 1;

  const isTelefone1Preenchido: boolean = checkIfAllItemsAreTrue([
    !!values.ddd1,
    !!values.telefone1,
  ]);

  const isTelefone2Preenchido: boolean = checkIfAllItemsAreTrue([
    !!values.ddd2,
    !!values.telefone2,
  ]);

  if (checkIfSomeItemsAreTrue([isTelefone1Preenchido, isTelefone2Preenchido])) {
    return [
      isTelefone1Preenchido && {
        seqTelefone: tryGetValueOrDefault(
          [
            UTILS.obterDadosTelefonePorTipo(
              CONSTS.TIPO_TELEFONE.CELULAR.ID,
              listaTelefones,
            )?.seqTelefone,
          ],
          incrementoSequencial,
        ),
        numeroDdd: Number(values.ddd1),
        numeroTelefone: Number(values.telefone1),
        codigoTipoTelefone: CONSTS.TIPO_TELEFONE.CELULAR.ID,
        descricaoTipoTelefone: CONSTS.TIPO_TELEFONE.CELULAR.DESCRICAO,
        numeroCanalServico: CONSTS.CANAL_SERVICO.CODIGO_PECO.ID,
      },
      isTelefone2Preenchido && {
        seqTelefone: tryGetValueOrDefault(
          [
            UTILS.obterDadosTelefonePorTipo(
              CONSTS.TIPO_TELEFONE.RESIDENCIAL.ID,
              listaTelefones,
            )?.seqTelefone,
          ],
          incrementoSequencial,
        ),
        numeroDdd: Number(values.ddd2),
        numeroTelefone: Number(values.telefone2),
        codigoTipoTelefone: CONSTS.TIPO_TELEFONE.RESIDENCIAL.ID,
        descricaoTipoTelefone: CONSTS.TIPO_TELEFONE.RESIDENCIAL.DESCRICAO,
        numeroCanalServico: CONSTS.CANAL_SERVICO.CODIGO_PECO.ID,
      },
    ];
  }

  return [];
};

const dynamicPayloadAtualizacaoEmail = (
  values: DADOS_SEGURADO_TYPES.IFormikValues,
  dadosCertificadoPrestamista:
    | ResponseObterDadosCertificadoPrestamista
    | undefined,
) => {
  const email = dadosCertificadoPrestamista?.segurados?.[0]?.emails?.[0];

  if (values.email) {
    return [
      {
        numeroPessoa: tryGetValueOrDefault([Number(email?.numeroPessoa)], 0),
        seqEnderecoEletronico: tryGetValueOrDefault(
          [Number(email?.seqEnderecoEletronico)],
          1,
        ),
        codigoEnderecoEletronico: CONSTS.ENDERECO_ELETRONICO.EMAIL.ID,
        email: values.email,
        dataCadastro: parseDateStringToDate(new Date()),
        numeroCanalServico: CONSTS.CANAL_SERVICO.CODIGO_PECO.ID,
      },
    ];
  }

  return [];
};

export const payloadAtualizacaoDadosSeguradoFactory = (
  values: DADOS_SEGURADO_TYPES.IFormikValues,
  dadosCertificadoPrestamista:
    | ResponseObterDadosCertificadoPrestamista
    | undefined,
  cpfCnpj: string,
) => ({
  telefones: dynamicPayloadAtualizacaoTelefone(
    values,
    dadosCertificadoPrestamista,
  ),
  emails: dynamicPayloadAtualizacaoEmail(values, dadosCertificadoPrestamista),
  enderecos: [
    {
      nomeLogradouroEstipulante: values.endereco,
      numeroLogradouroEstipulante: values.numeroEndereco,
      nomeBairroEstipulante: values.bairro,
      nomeCidadeEstipulante: values.cidade,
      codigoCepEstipulante: values.cep,
      numeroEnderecoId: tryGetValueOrDefault(
        [dadosCertificadoPrestamista?.numeroEnderecoSegurado],
        0,
      ),
      descricaoComplementoEndereco: values.complementoEndereco,
      uf: {
        id: values.uf,
        nome: '',
      },
    },
  ],
  numeroPessoa: tryGetValueOrDefault(
    [Number(dadosCertificadoPrestamista?.segurados[0].numeroPessoa)],
    0,
  ),
  numeroContrato: tryGetValueOrDefault(
    [Number(dadosCertificadoPrestamista?.numeroContrato)],
    0,
  ),
  codigoUfEstipulante: tryGetValueOrDefault(
    [dadosCertificadoPrestamista?.codigoUfEstipulante],
    '',
  ),
  existeCelular: true,
  cpf: cpfCnpj,
  numeroCanalServico: CONSTS.CANAL_SERVICO.CODIGO_PECO.ID,
});
