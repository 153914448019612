// @ts-nocheck
import { ISinistroProdutosPropsFactory } from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { ResultadoSinistroProdutoProps } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';
import { produtosPrestamistaFactory } from 'sinistro/features/statusSinistro/factories/produtosPrestamistaFactory';
import { produtosPrevidenciaFactory } from 'sinistro/features/statusSinistro/factories/produtosPrevidenciaFactory';
import { produtosVidaFactory } from 'sinistro/features/statusSinistro/factories/produtosVidaFactory';

export const sinistroProdutosPropsFactory = ({
  sinistroPrevidencia,
  sinistroPrestamista,
  sinistroVida,
}: ISinistroProdutosPropsFactory): ResultadoSinistroProdutoProps[] => {
  const produtosPrestamista = produtosPrestamistaFactory(sinistroPrestamista);

  const produtodsPrevdencia = produtosPrevidenciaFactory(sinistroPrevidencia);

  const produtosVida = produtosVidaFactory(sinistroVida);

  return produtosPrestamista.concat(produtodsPrevdencia).concat(produtosVida);
};
