// @ts-nocheck
import React, { useContext } from 'react';
import BottomNavMenu from 'main/components/Menu/BottomNav/BottomNavMenu';
import { AppContext } from 'main/contexts/AppContext';
import { MenuContainer } from './styles';

type BottomNaveRetencaoProps = {
  visibility: boolean;
};

const BottomNavRetencao: React.FunctionComponent<BottomNaveRetencaoProps> = ({
  visibility,
}) => {
  const { menu } = useContext(AppContext);

  return (
    <>
      {menu && (
        <MenuContainer className="show">
          <BottomNavMenu
            items={menu.items ?? []}
            type={menu.type || menu.name}
            visibility={visibility}
          />
        </MenuContainer>
      )}
    </>
  );
};

export default BottomNavRetencao;
