// @ts-nocheck
import { ChangeEvent, useCallback, useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useToast } from 'main/hooks/useToast';
import { formatarData } from 'main/utils';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import {
  usePecoConsultarDadosBICliente,
  usePecoConsultarCamposDinamicos,
  usePecoConsultarDuplicidadeSolicitacao,
} from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks/usePecoRegistrarOcorrencia';
import * as FACTORY from 'registroOcorrenciaASC/features/registrarOcorrencia/factories/RegistrarOcorrenciaFactory';
import * as UTILS from 'registroOcorrenciaASC/features/registrarOcorrencia/utils/RegistrarOcorrenciasUtils';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';

export const useRenderizarFormOcorrencia = () => {
  const { toastSuccess } = useToast();

  const {
    cpfCnpjCliente,
    formDadosOcorrencia,
    salvarDadosOcorrencia,
    salvarCamposComunicadoSinistoPrestamista,
    formFop,
  } = useRegistrarOcorrenciaContext();

  const { loadingDadosContratos, obterContratos } =
    usePecoConsultarDadosBICliente();

  const {
    dadosConsultaCamposDinamicos,
    loadingDadosConsultaCamposDinamicos,
    obterCamposDinamicos,
  } = usePecoConsultarCamposDinamicos();

  const { loadingDadosSolicitacoesDuplicadas, obterSolicitacoesDuplicadas } =
    usePecoConsultarDuplicidadeSolicitacao();

  const initialStateFormik = FACTORY.initialFormikStateDadosOcorrenciaFactory(
    CONSTS.INITIAL_FORMIK_STATE_DADOS_OCORRENCIA,
    formFop,
  );

  const formik: FormikProps<REGISTRO_OCORRENCIA_TYPES.IFormikValuesDadosOcorrencia> =
    useFormik<REGISTRO_OCORRENCIA_TYPES.IFormikValuesDadosOcorrencia>({
      enableReinitialize: true,
      initialValues: initialStateFormik,
      validationSchema: UTILS.FormikDadosOcorrenciaSchema(
        formFop.comunicadoSinistroPrestamista,
      ),
      validateOnMount: true,
      onSubmit: () => undefined,
    });

  const toggleModalDuplicidadeSolicitacao = (): void => {
    formik.setValues({
      ...formik.values,
      toggleModalMsgDuplicidadeSolicitacao:
        !formik.values.toggleModalMsgDuplicidadeSolicitacao,
    });
  };

  const toggleModalComunicadoSinistroPrestamista = (): void => {
    formik.setValues({
      ...formik.values,
      toggleModalComunicadoSinistroPrestamista:
        !formik.values.toggleModalComunicadoSinistroPrestamista,
    });
  };

  const limparFormComunicadoSinistroPrestamista = (): void => {
    formik.setValues({
      ...formik.values,
      ...CONSTS.INITIAL_FORMIK_STATE_FORM_COMUNICADO,
    });
  };

  const desabilitarBotaoFormComunicadoSinistroPrestamista = (
    values: REGISTRO_OCORRENCIA_TYPES.IFormikValuesDadosOcorrencia,
  ): boolean => {
    const requiredFields = Object.keys(
      CONSTS.INITIAL_FORMIK_STATE_FORM_COMUNICADO_CAMPOS_OBRIGATORIOS,
    );

    return requiredFields.every(
      field => !!values[field as keyof typeof formik.values],
    );
  };

  const fetchContratos = async (): Promise<
    REGISTRO_OCORRENCIA_TYPES.IFormDadosOcorrenciaContrato[]
  > => {
    const responseContratos = await obterContratos({
      cpf: cpfCnpjCliente.valor,
      statusContrato: ENUM_TYPES.PayloadFixo.STATUS_CONTRATO_ATIVO,
    });

    const preencherTabelaContratos = responseContratos?.entidade?.map(item => ({
      id: item.numModPlano.toString(),
      produto: item.desProduto,
      contrato: item.numContrato.toString(),
      vigencia: `${formatarData(item.dtaInicioVigencia)} a ${formatarData(
        item.dtaFimVigencia,
      )}`,
    }));

    return tryGetValueOrDefault([preencherTabelaContratos], []);
  };

  const fetchCamposDinamicos = async (): Promise<
    REGISTRO_OCORRENCIA_TYPES.IListaCamposDinamicos[]
  > => {
    const responseCamposDinamicos = await obterCamposDinamicos({
      externalIdAssunto: formDadosOcorrencia.assunto,
      segmento: formDadosOcorrencia.produto,
    });

    return tryGetValueOrDefault([responseCamposDinamicos?.entidade?.local], []);
  };

  const salvarContratosVigentesECamposDinamicosContexto =
    async (): Promise<void> => {
      if (formDadosOcorrencia.assunto) {
        const listaContratos = await fetchContratos();

        const listaCamposDinamicos = await fetchCamposDinamicos();

        salvarDadosOcorrencia({
          ...formDadosOcorrencia,
          listaContratos: [
            CONSTS.OPCAO_PADRAO_TABELA_DADOS_OCORRENCIA,
            ...listaContratos,
          ],
          listaCamposDinamicos,
        });
      }
    };

  const validarSolicitacoesDuplicadas = async (
    itemSelecionado: REGISTRO_OCORRENCIA_TYPES.IFormDadosOcorrenciaContrato,
  ): Promise<boolean> => {
    const response = await obterSolicitacoesDuplicadas({
      contrato: itemSelecionado.contrato,
    });

    const solicitacaoDuplicada = response?.entidade?.listaSolicitacao?.some(
      solicitacao =>
        solicitacao.externalIdAssunto === formDadosOcorrencia.assunto,
    );

    return tryGetValueOrDefault([solicitacaoDuplicada], false);
  };

  const salvarContratoSelecionadoContexto = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const itemSelecionado = formDadosOcorrencia.listaContratos.find(
      selecionado => selecionado.id === e.target.value,
    );

    if (itemSelecionado) {
      const solicitacaoDuplicada = await validarSolicitacoesDuplicadas(
        itemSelecionado,
      );

      if (solicitacaoDuplicada) {
        formik.setValues({
          ...formik.values,
          toggleModalMsgDuplicidadeSolicitacao: true,
        });
      } else {
        salvarDadosOcorrencia({
          ...formDadosOcorrencia,
          contratoSelecionado: { ...itemSelecionado },
        });
      }
    }
  };

  const salvarAnexoContexto = useCallback((): void => {
    if (formik.values.arquivoAnexo === null) {
      salvarDadosOcorrencia({
        ...formDadosOcorrencia,
        arquivoAnexo: {} as FileList,
      });
      return;
    }

    if (formik.values.arquivoAnexo.length) {
      salvarDadosOcorrencia({
        ...formDadosOcorrencia,
        arquivoAnexo: formik.values.arquivoAnexo,
      });
    }
  }, [formik.values.arquivoAnexo, formDadosOcorrencia, salvarDadosOcorrencia]);

  const salvarMensagemOcorrenciaContexto = (
    e: ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    formik.setValues({
      ...formik.values,
      conteudoTextarea: e.target.value,
      quantidadeCaracteresTextarea:
        ENUM_TYPES.DefaultValue
          .QTD_MAX_CARACTERES_TEXTAREA_REGISTRAR_OCORRENCIA -
        e.target.value.length,
    });

    salvarDadosOcorrencia({
      ...formDadosOcorrencia,
      mensagem: e.target.value.trim(),
    });
  };

  const salvarCamposComunicadoSinistoPrestamistaContexto = (): void => {
    salvarCamposComunicadoSinistoPrestamista({
      NomeSegurado: formik.values.nomeDadosSegurado,
      CPFSegurado: formik.values.cpfDadosSegurado,
      DthNascimento: formik.values.dataNascDadosSegurado,
      Sexo: formik.values.sexoDadosSegurado,
      NomeReclamante: formik.values.nomeDadosReclamante,
      CPFReclamante: formik.values.cpfDadosReclamante,
      GrauParentesco: formik.values.grauParentescoDadosReclamante,
      Email1: formik.values.email1DadosReclamante,
      Email2: formik.values.email2DadosReclamante,
      Endereco: formik.values.enderecoDadosReclamante,
      Cidade: formik.values.cidadeDadosReclamante,
      Bairro: formik.values.bairroDadosReclamante,
      Uf: formik.values.uFDadosReclamante,
      DDDResidencial: formik.values.dDDTelResidencialDadosReclamante,
      TelefoneResidencial: formik.values.telResidencialDadosReclamante,
      DDDComercial: formik.values.dDDTelComercialDadosReclamante,
      TelefoneComercial: formik.values.telComercialDadosReclamante,
      DDDCelular: formik.values.dDDTelCelularDadosReclamante,
      TelefoneCelular: formik.values.telCelularDadosReclamante,
      Cobertura: formik.values.coberturaPleiteadaDadosSinistro,
      DthSinistro: formik.values.dataOcorrenciaDadosSinistro,
      HistoricoSinistro: formik.values.historicoDadosSinistro,
    });

    toastSuccess(CONSTS.MSG_TOAST_FOP_364_02);

    toggleModalComunicadoSinistroPrestamista();
  };

  const colunasTabelaContratos = CONSTS.COLUNAS_TABELA_DADOS_OCORRENCIA({
    formDadosOcorrencia,
    salvarContratoSelecionadoContexto,
  });

  useEffect(() => {
    salvarContratosVigentesECamposDinamicosContexto();
  }, [formDadosOcorrencia.assunto]);

  useEffect(() => {
    salvarAnexoContexto();
  }, [formik.values.arquivoAnexo]);

  return {
    formik,
    toggleModalComunicadoSinistroPrestamista,
    toggleModalDuplicidadeSolicitacao,
    limparFormComunicadoSinistroPrestamista,
    desabilitarBotaoFormComunicadoSinistroPrestamista,
    loadingDadosContratos,
    loadingDadosSolicitacoesDuplicadas,
    loadingDadosConsultaCamposDinamicos,
    colunasTabelaContratos,
    dadosConsultaCamposDinamicos,
    salvarMensagemOcorrenciaContexto,
    salvarCamposComunicadoSinistoPrestamistaContexto,
  };
};
