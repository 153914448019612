// @ts-nocheck
export const habilitarAtualizacaoForm = (
  values: any,
  ...removerCamposNaoObrigatorios: string[]
): boolean => {
  const valuesTemp = { ...values };

  removerCamposNaoObrigatorios.forEach(
    campoNaoObrigatorio => delete valuesTemp[campoNaoObrigatorio],
  );

  return !Object.values(valuesTemp).every(value => value);
};
