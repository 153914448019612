// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import {
  IObterContatosDefaultPrevidencia,
  useObterContatosDefaultPrevidencia,
} from 'main/hooks/useObterContatosDefaultPrevidencia';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  TIPO_ASSINATURA,
  TIPO_ENVIO_ASSINATURA,
} from 'previdencia/constants/constants';
import { useTransfenciaFundos } from 'previdencia/hooks/Transferencia/useTransferenciaFundosPrevidencia';
import { IPayloadAssinatura } from 'previdencia/types/ITransferirEntreFundos';
import { IUseAssinaturaManualProps } from '../types/IUseAssinaturaManualProps';
import { useLocation } from 'react-router';
import { IRedirecionamentoHistoricoSolicitacaoLocation } from 'previdencia/types/IRedirecionamentoHistoricoSolicitacaoLocation';
import { ITransferenciaAlteracaoTaxaContext } from '../types/ITransferenciaAlteracaoTaxaContext';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';

export const useAssinatura = (
  props: IUseAssinaturaManualProps = { assinaturaManual: false },
) => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  const location = useLocation<IRedirecionamentoHistoricoSolicitacaoLocation>();
  const { featureData, setFeatureData } =
    usePrevidenciaContext<ITransferenciaAlteracaoTaxaContext>();
  const { data: responseObterContatosDefault, isLoading } =
    useObterContatosDefaultPrevidencia();

  const { data: locationHistoricoSolicitacoes } = tryGetValueOrDefault(
    [location.state],
    {} as IRedirecionamentoHistoricoSolicitacaoLocation,
  );
  const numeroRequisicao = tryGetValueOrDefault(
    [
      locationHistoricoSolicitacoes?.idRequisicao,
      featureData?.numTransferencia,
    ],
    '',
  );

  const {
    nome,
    emailDefault,
    numerosTelefone,
    numeroTelefoneSemDDD,
    numeroDDD,
  } = tryGetValueOrDefault<IObterContatosDefaultPrevidencia>(
    [responseObterContatosDefault],
    {} as IObterContatosDefaultPrevidencia,
  );

  const obterPayloadAssinatura = (): Partial<IPayloadAssinatura> => {
    return {
      nome,
      codigoTelefone: numeroDDD,
      telefone: numeroTelefoneSemDDD,
      email: emailDefault,
      tipoEnvio: TIPO_ENVIO_ASSINATURA.SMS,
      tipoAssinatura: TIPO_ASSINATURA.TRANSFERENCIA_ADESAO,
      assinaturaManual: props?.assinaturaManual,
    };
  };

  const dadosCliente = {
    email: emailDefault,
    cpfCnpj,
    mobileNumber: numerosTelefone,
  };

  const { response, loading, fetchData } = useTransfenciaFundos(
    obterPayloadAssinatura(),
    numeroRequisicao,
  );

  const solicitarAssinatura = () => {
    setFeatureData({ ...featureData, numTransferencia: numeroRequisicao });

    return fetchData({ ...obterPayloadAssinatura() });
  };

  return {
    loadingDadosAssinatura: isLoading,
    loadingAssinatura: loading,
    arquivoBase64: response?.arquivoBase64,
    fetchData,
    response,
    dadosCliente,
    idRequisicaoHistorico: locationHistoricoSolicitacoes?.idRequisicao,
    solicitarAssinatura,
  };
};
