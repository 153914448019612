// @ts-nocheck
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';

export function periodicidadePorExtenso(periodicidade?: string): string {
  if (!periodicidade) return DefaultValue.PERIODICIDADE_ANO_EXTENSO;

  switch (periodicidade.toUpperCase()) {
    case DefaultValue.PERIODICIDADE_MES:
      return DefaultValue.PERIODICIDADE_MES_EXTENSO;
    case DefaultValue.PERIODICIDADE_ANO:
    default:
      return DefaultValue.PERIODICIDADE_ANO_EXTENSO;
  }
}
