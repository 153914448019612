// @ts-nocheck
import React from 'react';
import { useHistory } from 'react-router';
import { Button } from '@cvp/design-system/react';

import { removeEmptySpaceAndForceUpperCase } from 'main/utils/string';
import { IRenderBotaoProps } from 'previdencia/features/HistoricoSolicitacoes/types/BotaoAcoes';
import { PrevRedirectByStatus } from 'previdencia/features/HistoricoSolicitacoes/types/enum';

export const ButtonReassinar: React.FC<Partial<IRenderBotaoProps>> = ({
  idRequisicao,
  tipoRequisicao,
}) => {
  const history = useHistory();
  return (
    <Button
      variant="primary"
      style={{ margin: '5px 0', boxShadow: 'none' }}
      onClick={() => {
        history.push({
          pathname:
            PrevRedirectByStatus[
              removeEmptySpaceAndForceUpperCase(tipoRequisicao)
            ],
          state: { from: 'HISTORICO_SOLICITACOES', data: { idRequisicao } },
        });
      }}
    >
      Assinar
    </Button>
  );
};
