// @ts-nocheck
import { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { usePrestamistaContext } from 'prestamista/contexts/PrestamistaContextProvider';
import {
  usePecoAtualizarDadosSegurado,
  usePecoEnderecoPeloCep,
} from 'prestamista/features/dadosSegurado/hooks/usePecoDadosSeguradoPrestamista';
import * as FACTORY from 'prestamista/features/dadosSegurado/factories/DadosSeguradoFactory';
import * as UTILS from 'prestamista/features/dadosSegurado/utils/DadosSegurado';
import * as CONSTS from 'prestamista/features/dadosSegurado/constants/constants';
import * as DADOS_SEGURADO_TYPES from 'prestamista/features/dadosSegurado/types/DadosSegurado';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseObterDadosCertificadoPrestamista } from 'prestamista/types/ICertifucatiPrestamista';
import { useManterNomeSocial } from 'consultaCliente/features/listaCardProduto/hooks/useManterNomeSocial';

export const useAtualizacaoDadosSeguradoPrestamista = () => {
  const history = useHistory();
  const { nomeSocial } = useContext(AppContext);
  const {
    excluir,
    incluir,
    alteracao,
    loading: loadingManterNomeSocial,
  } = useManterNomeSocial();
  const { featureData, cpfCnpj } =
    usePrestamistaContext<DADOS_SEGURADO_TYPES.IDadosSeguradoContextData>();

  const { atualizarDadosSegurado, loadingDadosSeguradoAtualizados } =
    usePecoAtualizarDadosSegurado();

  const initialStateFormik = FACTORY.initialFormikStateFactory({
    ...tryGetValueOrDefault(
      [featureData?.dadosCertificadoPrestamista],
      {} as ResponseObterDadosCertificadoPrestamista,
    ),
    nomeSocial,
    utilizarNomeSocial: !!nomeSocial,
  });

  const [cidadeValida, setCidadeValida] = useState<boolean>(false);

  const isFirstRender = useRef(true);

  const [cepAlterado, setCepAlterado] = useState(false);

  const handleManterNomeSocial = async (
    formValues: DADOS_SEGURADO_TYPES.IFormikValues,
  ) => {
    const nomeSocialAtual = formValues.nomeSocial;

    const shouldIncluir = checkIfAllItemsAreTrue([
      formValues.utilizarNomeSocial,
      !nomeSocial,
      !!nomeSocialAtual,
    ]);
    const shouldAlterar = checkIfAllItemsAreTrue([
      formValues.utilizarNomeSocial,
      nomeSocial !== nomeSocialAtual,
    ]);
    const shouldExcluir = checkIfAllItemsAreTrue([
      !formValues.utilizarNomeSocial,
      !!nomeSocialAtual,
    ]);

    if (shouldIncluir) {
      await incluir(cpfCnpj, nomeSocialAtual);
    } else if (shouldAlterar) {
      await alteracao(cpfCnpj, nomeSocialAtual);
    } else if (shouldExcluir) {
      await excluir(cpfCnpj, nomeSocialAtual);
    }
  };

  const atualizarDados = async (
    values: DADOS_SEGURADO_TYPES.IFormikValues,
  ): Promise<void> => {
    const retornoAtualizacaoDados = await atualizarDadosSegurado({
      ...FACTORY.payloadAtualizacaoDadosSeguradoFactory(
        values,
        featureData?.dadosCertificadoPrestamista,
        cpfCnpj,
      ),
    });

    await handleManterNomeSocial(values);

    if (retornoAtualizacaoDados?.entidade) {
      history.push({
        pathname: `/cliente/produtos/prestamista/dados-segurado`,
        state: {
          msgAtualizacaoDados:
            retornoAtualizacaoDados?.mensagens?.[0]?.descricao,
        },
      });
    }
  };

  const formik = useFormik<DADOS_SEGURADO_TYPES.IFormikValues>({
    initialValues: initialStateFormik,
    enableReinitialize: true,
    onSubmit: values => atualizarDados(values),
    validationSchema: FACTORY.formikAtualizacaoDadosSeguradoSchemaFactory(),
    validateOnMount: true,
    validate(values) {
      if (!cepAlterado) return {};

      const msgError = {} as DADOS_SEGURADO_TYPES.MsgInputFormikError;

      const cep = UTILS.customValidationCepFormik(values, cidadeValida);

      if (cep) msgError.cep = cep;

      return msgError;
    },
  });

  const { dadosEnderecoPeloCep, obterEnderecoPeloCep } = usePecoEnderecoPeloCep(
    {
      cep: formik.values.cep,
    },
  );

  const obterNovoEndereco = async (): Promise<void> => {
    if (formik.values.cep.length === CONSTS.QTD_MAXIMA_DIGITOS_CEP) {
      const novoEnderecoPeloCepPesquisado = await obterEnderecoPeloCep();

      setCidadeValida(!!novoEnderecoPeloCepPesquisado?.entidade?.cidade);
    }
  };

  const definirNovosValoresEnderecoPeloCepPesquisado = (): void => {
    if (cidadeValida) {
      formik.setValues({
        ...formik.values,
        endereco: tryGetValueOrDefault(
          [dadosEnderecoPeloCep?.entidade?.logradouro],
          '',
        ),
        numeroEndereco: tryGetValueOrDefault(
          [dadosEnderecoPeloCep?.entidade?.numero],
          '',
        ),
        complementoEndereco: tryGetValueOrDefault(
          [dadosEnderecoPeloCep?.entidade?.complemento],
          '',
        ),
        bairro: tryGetValueOrDefault(
          [dadosEnderecoPeloCep?.entidade?.bairro],
          '',
        ),
        cidade: tryGetValueOrDefault(
          [dadosEnderecoPeloCep?.entidade?.cidade],
          '',
        ),
        uf: tryGetValueOrDefault([dadosEnderecoPeloCep?.entidade?.estado], ''),
      });
    }
  };

  const limparValoresEnderecoSeCepInvalido = (): void => {
    if (
      checkIfAllItemsAreTrue([
        !cidadeValida,
        formik.errors.cep === CONSTS.MSG_VALIDACAO_ERRO.CEP_INVALIDO,
      ])
    ) {
      formik.setValues({
        ...formik.values,
        endereco: '',
        numeroEndereco: '',
        complementoEndereco: '',
        bairro: '',
        cidade: '',
        uf: '',
      });
    }
  };

  const controlarMudancaCep = (): void => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (formik.values.cep.length === 8) {
      obterNovoEndereco();
    }
  };

  const handleCepChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    setCepAlterado(true);
    formik.setFieldValue('cep', masks.cep.unmask(value));
  };

  useEffect(controlarMudancaCep, [formik.values.cep]);

  useEffect(() => {
    definirNovosValoresEnderecoPeloCepPesquisado();
    limparValoresEnderecoSeCepInvalido();
  }, [cidadeValida, dadosEnderecoPeloCep?.entidade?.endereco]);

  return {
    formik,
    handleCepChange,
    loadingDadosSeguradoAtualizados,
    loadingManterNomeSocial,
  };
};
