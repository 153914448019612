// @ts-nocheck
import { api } from 'main/services/api';
import { registerGatewayInterceptor } from 'config/http/interceptors/gatewayInterceptor';
import { registerMarcadorControleInterceptors } from 'config/http/interceptors/marcadorControleInterceptors';
import { registerMatrizAcessoInterceptor } from 'previdencia/config/http/interceptors/MatrizAcessoInterceptor';
import { registerTokenOperadorInteceptor } from 'previdencia/config/http/interceptors/TokenOperadorInteceptor';

export const registrarInterceptors = (): void => {
  registerGatewayInterceptor(api);
  registerMarcadorControleInterceptors(api);
  registerTokenOperadorInteceptor();
  registerMatrizAcessoInterceptor();
};
