// @ts-nocheck
import { FormikProps } from 'formik';

import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import useMensagemTemporaria from 'previdencia/hooks/useMensagemTemporaria';
import {
  usePecoConfirmarResgate,
  usePecoDefinirContribuicaoRegular,
} from 'previdencia/features/SolicitacaoResgate/hooks/usePecoSolicitacaoResgate';
import { useHandleReponseResgate } from 'previdencia/features/SolicitacaoResgate/hooks/useHandleResponseResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as RESPONSE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateResponse';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useHandleDefinirContribuicao = (
  formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesDefinicaoContribuicaoRegular>,
) => {
  const { featureData, setFeatureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const { valorContribuicaoRegularlAtual } = tryGetValueOrDefault(
    [featureData?.dadosConsultaContribuicaoRegular],
    {} as RESPONSE_TYPES.IConsultarContribuicaoRegularResponse,
  );

  const { definirContribuicaoRegular, loadingDadosDefinirContribuicaoRegular } =
    usePecoDefinirContribuicaoRegular();

  const { confirmarResgate, loadingDadosConfirmarResgate } =
    usePecoConfirmarResgate();

  const { handleResponseResgate, exibirMensagensFeedbackResgate } =
    useHandleReponseResgate();

  const { configurarMensagemTemporaria, mensagemTemporaria } =
    useMensagemTemporaria();

  const isDefinicaoContribuicaoDesabilitado: boolean = checkIfSomeItemsAreTrue([
    loadingDadosDefinirContribuicaoRegular,
    loadingDadosConfirmarResgate,
    UTILS.calcularValorRestanteContribuicaoRegular(
      formik,
      valorContribuicaoRegularlAtual,
    ).calculoTotal !== valorContribuicaoRegularlAtual,
  ]);

  const isLoadingDefinicaoContribuicao: boolean = checkIfSomeItemsAreTrue([
    loadingDadosDefinirContribuicaoRegular,
    loadingDadosConfirmarResgate,
  ]);

  const payloadParaContribuicaoRegular =
    FACTORY.criarPayloadParaContribuicaoRegular(
      formik.values.listaFundosParaContribuicaoRegular,
      featureData?.numeroResgateConsolidado,
    );

  const obterDefinicaoContribuicaoRegular = async () => {
    const dadosDefinicaoContribuicaoRegular = await definirContribuicaoRegular(
      payloadParaContribuicaoRegular,
    );

    if (!dadosDefinicaoContribuicaoRegular?.entidade) {
      return UTILS.retornarMensagemFluxoSolicitacao(
        dadosDefinicaoContribuicaoRegular,
        configurarMensagemTemporaria,
        CONSTS.VALIDACAO_DEFINICAO_CONTRIBUICAO_REGULAR.MSG_TEMPORARIA_ERRO,
      );
    }

    return true;
  };

  const obterConfirmacaoResgate = async () => {
    const dadosConfirmarResgate = await confirmarResgate({
      numeroResgate: tryGetValueOrDefault(
        [featureData?.numeroResgateConsolidado],
        '',
      ),
    });

    const { sucesso, status, mensagemSucesso } = handleResponseResgate(
      dadosConfirmarResgate,
    );

    const mensagensResgate = dadosConfirmarResgate?.mensagens;

    const mensagem = tryGetValueOrDefault(
      [mensagensResgate?.[0]?.descricao],
      '',
    );

    if (checkIfAllItemsAreTrue([!sucesso, !!mensagensResgate?.length])) {
      configurarMensagemTemporaria(mensagem);

      return;
    }

    exibirMensagensFeedbackResgate(sucesso, status, mensagemSucesso);
  };

  const efetuarDefinicaoContribuicao = async () => {
    const isFalhaDefinicaoContribuicaoRegular =
      !(await obterDefinicaoContribuicaoRegular());

    if (isFalhaDefinicaoContribuicaoRegular) return;

    obterConfirmacaoResgate();

    setFeatureData({
      ...featureData,
      listaFundosContribuicaoRegular:
        formik.values.listaFundosParaContribuicaoRegular,
    });
  };

  return {
    isDefinicaoContribuicaoDesabilitado,
    isLoadingDefinicaoContribuicao,
    mensagemTemporaria,
    efetuarDefinicaoContribuicao,
  };
};

export default useHandleDefinirContribuicao;
