// @ts-nocheck
import React, { useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';
import * as UTILS from 'registroOcorrenciaASC/features/registrarOcorrencia/utils/RegistrarOcorrenciasUtils';
import * as FACTORY from 'registroOcorrenciaASC/features/registrarOcorrencia/factories/RegistrarOcorrenciaFactory';

export const useRenderizarCamposDinamicos = () => {
  const { formDadosOcorrencia, salvarDadosOcorrencia } =
    useRegistrarOcorrenciaContext();

  const initialStateFormik = FACTORY.initialFormikStateCamposDinamicosFactory(
    formDadosOcorrencia.listaCamposDinamicos,
  );

  const formik: FormikProps<REGISTRO_OCORRENCIA_TYPES.IDynamicFields> =
    useFormik<REGISTRO_OCORRENCIA_TYPES.IDynamicFields>({
      enableReinitialize: true,
      initialValues: initialStateFormik,
      validateOnMount: true,
      validate: values => UTILS.customFormikValidationDynamicFields(values),
      onSubmit: () => undefined,
    });

  const handleChangeCampos = (
    valorCampo: string,
    campo: REGISTRO_OCORRENCIA_TYPES.IListaCamposDinamicos,
  ): void => {
    formik.setValues({
      ...formik.values,
      [campo.nomeCampo]: {
        nomeCampo: campo.nomeCampo,
        obrigatorio: campo.obrigatorio,
        idCampoDinamico: campo.idCampoDinamico,
        valorCampo,
      },
    });
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    campo: REGISTRO_OCORRENCIA_TYPES.IListaCamposDinamicos,
    removeMask: (value: string) => string,
  ): void => {
    formik.setFieldValue(
      formik.values[campo.nomeCampo]?.nomeCampo,
      removeMask(formik.values[campo.nomeCampo]?.valorCampo),
    );

    handleChangeCampos(removeMask(e.target.value), campo);
  };

  const salvarCamposPreenchidosContexto = (): void => {
    if (formDadosOcorrencia.listaCamposDinamicos.length) {
      salvarDadosOcorrencia({
        ...formDadosOcorrencia,
        camposDinamicosPreenchidos: Object.values(formik.values),
      });
    }
  };

  useEffect(salvarCamposPreenchidosContexto, [formik.values]);

  return {
    formik,
    onChange,
  };
};
