// @ts-nocheck
import { Checkbox } from '@cvp/design-system/react';

import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { fundosAtivos } from 'previdencia/utils/tabelaFundos';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const ColunaSelecionarFundoTipoTransferencia = ({
  row,
  fundos,
  quantidadeMaximaFundos,
  tipoTransferencia,
  handleFundoSelecionado,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunaSelecionarFundoTipoTransferenciaProps) => {
  return (
    <Checkbox
      id={row.codFundo}
      disabled={checkIfSomeItemsAreTrue([
        checkIfAllItemsAreTrue([
          !row.selecionado,
          fundosAtivos(fundos) === quantidadeMaximaFundos,
        ]),
        !!row.desabilitado,
        tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.TOTAL,
      ])}
      checked={row.selecionado}
      onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleFundoSelecionado(e.target.id)
      }
    />
  );
};

export default ColunaSelecionarFundoTipoTransferencia;
