// @ts-nocheck
import { useContext } from 'react';

import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IRequestValidarConta,
  ResponseValidarConta,
} from 'previdencia/types/DadosContaBancaria';

export const usePecoValidarConta = (params: Partial<IRequestValidarConta>) => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  const {
    response: dadosValidacaoConta,
    loading: loadingDadosValidacaoConta,
    fetchData: validarConta,
  } = usePeco<Partial<IRequestValidarConta>, ResponseValidarConta>({
    api: { operationPath: PECOS.ValidarConta },
    payload: {
      cpfCnpj,
      ...params,
    },
    handleResponse: { throwToastErrorBFF: false },
  });
  return {
    dadosValidacaoConta: dadosValidacaoConta?.entidade,
    loadingDadosValidacaoConta,
    validarConta,
  };
};
