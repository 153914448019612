// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import * as CONSTS from 'seguros/constants/DadosSegurado';
import * as PECO_TYPES from 'seguros/types/DadosSeguradoUsePeco';
import * as REQUEST_TYPES from 'seguros/types/DadosSeguradoRequest';
import * as RESPONSE_TYPES from 'seguros/types/DadosSeguradoResponse';

export const useDadosSegurado = (): PECO_TYPES.IUseDadosSegurado => {
  const { clienteVida } = useContext(AppContext);

  const {
    response: dadosSegurado,
    loading: loadingDadosSegurado,
    fetchData,
  } = usePeco<
    REQUEST_TYPES.IConsultarDadosSeguradoVida,
    RESPONSE_TYPES.IDadosSeguradoResponse
  >({
    api: { operationPath: PECOS_VIDA.ConsultarDadosSeguradoVida },
    autoFetch: true,
    payload: {
      numeroCertificadoBilhete: clienteVida?.numCertificado,
    },
  });

  return {
    dadosSegurado,
    loadingDadosSegurado,
    cpfCnpj: clienteVida.cpfCnpj,
    getDadosCertificadoVida: fetchData,
  };
};

export const useEnderecoPeloCep = ({
  cep,
}: PECO_TYPES.IUseEnderecoPeloCepParams): PECO_TYPES.IUseEnderecoPeloCep => {
  const { clienteVida } = useContext(AppContext);

  const {
    response: dadosEnderecoPeloCep,
    loading: loadingDadosEnderecoPeloCep,
    fetchData: obterEnderecoPeloCep,
  } = usePeco<
    REQUEST_TYPES.IEnderecoPeloCepPayload,
    RESPONSE_TYPES.IEnderecoPeloCepResponse
  >({
    api: { operationPath: PECOS_VIDA.BuscarEnderecoCep },
    payload: {
      cpfCnpj: clienteVida.cpfCnpj,
      cep,
    },
  });

  return {
    dadosEnderecoPeloCep,
    loadingDadosEnderecoPeloCep,
    obterEnderecoPeloCep,
  };
};

export const useConsultarConsentimento = (
  subProcesso: string,
): PECO_TYPES.IUseConsultarConsentimento => {
  const { clienteVida } = useContext(AppContext);

  const { response: dadosConsentimento, loading: loadingDadosConsentimento } =
    usePeco<
      REQUEST_TYPES.IConsultarConsentimentoPayload,
      RESPONSE_TYPES.IConsultarConsentimentoResponse
    >({
      api: { operationPath: PECOS_VIDA.ConsultarConsentimento },
      autoFetch: true,
      payload: {
        cpf: clienteVida.cpfCnpj,
        numeroCertificado: clienteVida.numCertificado,
        numConsentimentoNegocio: CONSTS.DEFAULT_VALUE.NUM_CONSENTIMENTO_NEGOCIO,
        macroProcesso: CONSTS.DEFAULT_VALUE.MACRO_PROCESSO,
        subProcesso,
      },
    });

  return {
    dadosConsentimento,
    loadingDadosConsentimento,
  };
};

export const useAtualizarConsentimento = (
  consentimento: boolean,
  produtosCVP = false,
): PECO_TYPES.IUseAtualizarConsentimento => {
  const { clienteVida } = useContext(AppContext);

  const { response, loading, fetchData } = usePeco<
    REQUEST_TYPES.IAtualizarConsentimentoPayload,
    RESPONSE_TYPES.IAtualizarConsentimentoResponse
  >({
    api: { operationPath: PECOS_VIDA.AtualizarConsentimento },
    payload: {
      cpf: clienteVida.cpfCnpj,
      sistema: {
        codigo: CONSTS.DEFAULT_VALUE.CODIGO_CONSENTIMENTO_SISTEMA,
      },
      unidadeNegocio: {
        codigo: CONSTS.DEFAULT_VALUE.CODIGO_CONSENTIMENTO_UNIDADE_NEGOCIO,
      },
      processoNegocio: {
        subProcesso: {
          codigo: produtosCVP
            ? CONSTS.DEFAULT_VALUE.CODIGO_CONSENTIMENTO_SUB_PROCESSO_1
            : CONSTS.DEFAULT_VALUE.CODIGO_CONSENTIMENTO_SUB_PROCESSO_2,
        },
        macroProcesso: {
          codigo: CONSTS.DEFAULT_VALUE.MACRO_PROCESSO,
        },
      },
      evidencia: {
        codigo: [
          `sistema: ${CONSTS.DEFAULT_VALUE.CODIGO_CONSENTIMENTO_SISTEMA}, cpf:`,
          clienteVida.cpfCnpj,
        ].join(' '),
      },
      consentimento: {
        tipo: consentimento
          ? CONSTS.DEFAULT_VALUE.TIPO_CONSENTIMENTO_1
          : CONSTS.DEFAULT_VALUE.TIPO_CONSENTIMENTO_0,
        numConsentimentoNegocio: CONSTS.DEFAULT_VALUE.NUM_CONSENTIMENTO_NEGOCIO,
      },
      cliente: {
        codigo: clienteVida.cpfCnpj,
      },
    },
  });

  return {
    response,
    loading,
    fetchData,
  };
};

export const useAtualizarDadosSegurado =
  (): PECO_TYPES.IUseAtualizarDadosSegurado => {
    const { clienteVida } = useContext(AppContext);

    const {
      response: dadosSeguradoAtualizados,
      loading: loadingDadosSeguradoAtualizados,
      fetchData: atualizarDadosSegurado,
    } = usePeco<
      REQUEST_TYPES.IAtualizarDadosSeguradoPayload,
      RESPONSE_TYPES.IAtualizarDadosSeguradoResponse
    >({
      api: { operationPath: PECOS_VIDA.AtualizarDadosSeguradoVIda },
      payload: {
        numeroCertificadoBilhete: tryGetValueOrDefault(
          [clienteVida?.numCertificado],
          '',
        ),
        codigoCliente: tryGetValueOrDefault([clienteVida?.codigoCliente], ''),
      },
    });
    return {
      dadosSeguradoAtualizados,
      loadingDadosSeguradoAtualizados,
      atualizarDadosSegurado,
    };
  };
