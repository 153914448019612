// @ts-nocheck
import React from 'react';
import { Display, Button, Grid, Tag } from '@cvp/design-system/react';
import { ITagFilterOption, IFilterOption } from './IFilterOption';
import { Label } from './styles';

export const TagsFilter: React.FC<{
  filterOption?: IFilterOption;
  tagsSelecteds: ITagFilterOption[];
  handleTagsFilter: (item: ITagFilterOption) => void;
  tagsFilterDescription?: string;
}> = ({
  filterOption,
  tagsSelecteds = [],
  handleTagsFilter,
  tagsFilterDescription = 'Você também tem a opção de filtrar por status, escolha abaixo e clique em filtrar.',
}) => {
  const isChecked = (option: ITagFilterOption) => {
    const found = tagsSelecteds.find(x => x.id === option.id);
    if (found) return true;
    return false;
  };

  return (
    <Grid.Item>
      <Label style={{ marginBottom: 10 }}>
        {filterOption?.checkboxConfig?.helpText ?? tagsFilterDescription}
      </Label>
      <Display>
        {filterOption?.checkboxConfig?.options.map(item => (
          <Display style={{ margin: 1 }} key={item.description}>
            <Button
              style={{ margin: 0, padding: 12 }}
              variant="text"
              onClick={() =>
                handleTagsFilter({ column: filterOption?.key, ...item })
              }
            >
              <Tag
                variant={isChecked(item) ? 'primary' : 'inactive'}
                value={item.description}
                style={{ cursor: 'pointer', padding: 10 }}
              />
            </Button>
          </Display>
        ))}
      </Display>
    </Grid.Item>
  );
};
