// @ts-nocheck
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { useEffect } from 'react';
import { ITransferenciaEntreCertificadosContextData } from '../types/TransferenciaEntreCertificados';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import useObterComprovante from 'previdencia/hooks/Transferencia/useObterComprovante';
import { TIPO_ASSINATURA } from 'previdencia/constants/constants';
import { getBlobUrl } from 'main/utils/blob';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

const useComprovante = () => {
  const { featureData, setFeatureData } =
    usePrevidenciaContext<ITransferenciaEntreCertificadosContextData>();

  const { loading, response, fetchData } = useObterComprovante(
    TIPO_ASSINATURA.ENTRE_CERTIFICADOS,
  );
  const salvarEtapasContexto = (): void => {
    setFeatureData({
      ...featureData,
      step: CONSTS_TEXT.NUM_ETAPAS.EFETUAR_ASSINATURA,
    });
  };

  useEffect(() => {
    if (checkIfAllItemsAreTrue([!!response, !!response?.comprovante])) {
      getBlobUrl(response?.comprovante);
    }
  }, [response]);

  useEffect(salvarEtapasContexto, []);

  return {
    fetchData,
    loading,
    response,
  };
};

export default useComprovante;
