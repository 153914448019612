// @ts-nocheck
import styled from 'styled-components';

export const WrapperWarning = styled.div(({ theme: { color } }) => ({
  border: `1px solid ${color.brandSecondary.light}`,
  background: `${color.brandSecondary.light}0d`,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,
  marginBottom: 20,

  '& > p:first-child': {
    fontWeight: 'bold',
  },

  p: {
    color: color.brandSecondary.dark,
    fontStyle: 'italic',
  },
}));
