// @ts-nocheck
import styled from 'styled-components/macro';
import { Text } from '@cvp/design-system/react';

export const ContainerInformacoesAdicionaisCuidadoExtra = styled.div<{
  prazoGrid: boolean;
}>(({ prazoGrid }) => ({
  display: 'grid',
  gridTemplateColumns: prazoGrid ? '1fr 1fr 1fr' : '1fr 1fr',
  textAlign: 'center',
  alignItems: 'center',
  position: 'relative',
}));

export const TextDescricaoItemContribuicao = styled(Text)({
  fontSize: '11px',
  margin: '5px 0 5px 0',
});

export const DescricaoContribuicao = styled.div({
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  margin: 0,
  height: '60px',
});

export const PrimeiroElementoDescricaoContribuicao = styled.div({
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px',
  padding: 0,
  margin: 0,
});
