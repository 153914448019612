// @ts-nocheck
import { formatarData } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  IStatusSinistroVida,
  IProdutoSinistroVida,
  TimelineVida,
} from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { ResultadoSinistroProdutoProps } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';
import { TipoProduto } from 'sinistro/features/statusSinistro/types/enum';
import { ultimoItemArray } from 'sinistro/features/statusSinistro/utils/ultimoItemArray';

const obterUltimoStatus = (
  statusSinistroVida: IStatusSinistroVida | undefined,
): string => {
  const ultimoEventoTimeline = ultimoItemArray<TimelineVida>(
    tryGetValueOrDefault([statusSinistroVida?.timeline], []),
  );

  return tryGetValueOrDefault([ultimoEventoTimeline?.descricaoStatus], ' - ');
};

export function produtosVidaFactory(
  responseStatusSinistroVida?: IProdutoSinistroVida[],
) {
  const listaVida = tryGetValueOrDefault([responseStatusSinistroVida], []);
  return listaVida.map(produto => {
    const codigoFonte = `${produto.codigoFonte}`.padStart(3, '0');
    const numeroAvisoSinistro = `${codigoFonte}${produto.numeroProtocoloSinistro}`;
    return {
      tipoProduto: TipoProduto.VIDA,
      descricaoProduto: 'Vida',
      codigoCertificado: String(produto.numeroCertificado),
      numeroAvisoSinistro,
      dataSinistro: formatarData(
        produto.timeline?.informacoesAdicionais.dataOcorrencia,
      ),
      status: obterUltimoStatus(produto.timeline),
    } as ResultadoSinistroProdutoProps;
  });
}
