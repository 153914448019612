// @ts-nocheck
import { isCpf } from 'main/utils/cpf_cnpj';
import { ModalProps } from '../types';

type validaPrazoIdadeProps = {
  dataNascimento: string | number | boolean;
  prazo: string;
  setModal: (params: ModalProps) => void;
  setBloqueioEnvio: (bloqueioEnvio: boolean) => void;
};

const validaPrazo = (dataNascimento: Date, prazo: number): boolean => {
  const localeDate = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }),
  );
  const dataFinal = new Date(
    localeDate.setMonth(new Date().getMonth() + prazo),
  );
  const dataNascimentoAos70 = new Date(
    dataNascimento.setFullYear(dataNascimento.getFullYear() + 70),
  );
  const dataFinalPermitida = new Date(
    dataNascimentoAos70.setDate(dataNascimentoAos70.getDate() - 1),
  );
  const dataFinalPermitidaSemHora = new Date(
    dataFinalPermitida.getFullYear(),
    dataFinalPermitida.getMonth(),
    dataFinalPermitida.getDate(),
  );
  const dataFinalSemHora = new Date(
    dataFinal.getFullYear(),
    dataFinal.getMonth(),
    dataFinal.getDate(),
  );
  return dataFinalPermitidaSemHora >= dataFinalSemHora;
};

export const validaPrazoIdade = ({
  dataNascimento,
  prazo,
  setModal,
  setBloqueioEnvio,
}: validaPrazoIdadeProps): void => {
  const dataFormat = dataNascimento
    .toString()
    .replace(/(\d+[/])(\d+[/])/, '$2$1');

  const prazoValido = validaPrazo(new Date(dataFormat), parseInt(prazo, 10));

  if (!prazoValido) {
    setModal({
      open: true,
      mensagem:
        'A idade do cliente somado ao prazo de financiamento não poderá ultrapassar 70 anos.',
    });
    setBloqueioEnvio(true);
    return;
  }
  setBloqueioEnvio(false);
  setModal({
    open: false,
    mensagem: '',
  });
};

interface Validator {
  [index: string]: (value: any) => boolean | void;
}
export const validators: Validator = {
  cpf: isCpf,
  validaPrazoIdade,
};

export const showErrorIfFieldInvalid = (touched: boolean, error?: string) =>
  Boolean(touched && error);

export const renderLabelWithRequiredCheck = (
  required?: boolean,
  label?: string,
) => (required ? `*${label}` : label);
