// @ts-nocheck
import styled from 'styled-components/macro';

export const ExtratoRentabilidadeCotasContainer = styled.div(
  ({ theme: { breakpoint } }) => ({
    padding: '8px',

    [breakpoint.md()]: {
      padding: 0,
    },
  }),
);

export const WrapperButton = styled.div({
  display: 'flex',
});

export const ButtonDivisor = styled.div({
  width: '10px',
});

export const Separator = styled.div({
  height: '10px',
});

export const Button = styled.div({
  marginRight: '10px',
  display: 'flex',
  marginBottom: '10px',
});
