// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Grid, Card, Display, Text, Button } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import { minValue, required } from 'main/features/Validation/validations';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import Input from 'main/components/form/Input';
import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import useContribuicaoRendaMensal from 'previdencia/features/TransferenciaInternaEmissaoConjugado/hooks/useContribuicaoRendaMensal';
import ModalValorMinimoContribuicao from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Modal/ModalValorMinimoContribuicao';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as S from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/ContribuicaoRendaMensal/styles';

const ContribuicaoRendaMensal: React.FC = () => {
  const {
    contribuicaoRendaMensal,
    iniciarTransferenciaInterna,
    escolherFundoInvestimentoDestino,
    salvarContribuicaoRendaMensal,
  } = useTransferenciaInternaContext();

  const { loadingValorMinimoContribuicao, valorMinimoContribuicao } =
    useContribuicaoRendaMensal();

  const [
    modalAtencaoValorMinimoContribuicao,
    setModalAtencaoValorMinimoContribuicao,
  ] = useState<boolean>(false);

  const [contribuicaoLink] = useFieldLink('');
  const valorContribuicao = contribuicaoLink.get().value;
  const valorValidoContribuicao = contribuicaoLink.get().isValid;

  const legendaValorMinimoContribuicao = `*O valor mínimo deve ser de ${tryGetMonetaryValueOrDefault(
    valorMinimoContribuicao,
  )}`;

  const handleModalValorMinimoContribuicao = (): void => {
    setModalAtencaoValorMinimoContribuicao(
      !modalAtencaoValorMinimoContribuicao,
    );
  };

  const avancarEscolhaFundoInvestimentoDestino = (): void => {
    const isValidContribuicao: boolean = checkIfAllItemsAreTrue([
      valorValidoContribuicao,
      Number(valorContribuicao) >= Number(valorMinimoContribuicao),
    ]);

    if (isValidContribuicao) {
      salvarContribuicaoRendaMensal(
        (Number(valorContribuicao) / 100).toFixed(2),
      );
    } else {
      handleModalValorMinimoContribuicao();
      return;
    }

    escolherFundoInvestimentoDestino();
  };

  useEffect(() => {
    contribuicaoLink.set({
      value: tryGetValueOrDefault(
        [(Number(contribuicaoRendaMensal) * 100).toFixed(0)],
        '',
      ),
      isValid: true,
    });
  }, []);

  return (
    <>
      <Display type="display-block" data-testid="ContribuicaoRendaMensal">
        <Card>
          <Card.Content padding={[6, 8, 6, 8]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  {CONSTS.TEXTOS_CONTRIBUICAO_RENDA_MENSAL.TITULO}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Text variant="body02-md" margin>
                  {CONSTS.TEXTOS_CONTRIBUICAO_RENDA_MENSAL.DESCRICAO}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1} lg={1 / 5}>
                <S.Display>
                  <Text variant="body02-md">
                    {CONSTS.TEXTOS_CONTRIBUICAO_RENDA_MENSAL.INPUT.SUBTITULO}
                  </Text>
                </S.Display>
              </Grid.Item>
              <Grid.Item xs={1} lg={1 / 4}>
                <RenderConditional condition={loadingValorMinimoContribuicao}>
                  <SkeletonLoading
                    blocks={1}
                    data-testid="skeletonExibirInputValorMinimoContribuicao"
                  />
                </RenderConditional>
                <RenderConditional condition={!loadingValorMinimoContribuicao}>
                  <Input
                    link={contribuicaoLink}
                    inputMask={masks.currencyInput}
                    placeholder={
                      CONSTS.TEXTOS_CONTRIBUICAO_RENDA_MENSAL.INPUT.PLACEHOLDER
                    }
                    validationRules={[
                      required(),
                      minValue(() => '', {
                        min: Number(valorMinimoContribuicao),
                      }),
                    ]}
                    label={CONSTS.TEXTOS_CONTRIBUICAO_RENDA_MENSAL.INPUT.LABEL}
                    legend={legendaValorMinimoContribuicao}
                    data-testid="inputValorContribuicaoRendaMensal"
                  />
                </RenderConditional>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Display>
                  <Button
                    variant="outlined"
                    onClick={iniciarTransferenciaInterna}
                    data-testid="botaoVoltarEtapaCuidadoExtra"
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={avancarEscolhaFundoInvestimentoDestino}
                    loading={loadingValorMinimoContribuicao}
                    disabled={loadingValorMinimoContribuicao}
                    data-testid="botaoContinuarEscolhaFundo"
                  >
                    Continuar
                  </Button>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>

      <ModalValorMinimoContribuicao
        handleModal={handleModalValorMinimoContribuicao}
        toggleModal={modalAtencaoValorMinimoContribuicao}
        valorMinimoContribuicao={valorMinimoContribuicao}
      />
    </>
  );
};

export default ContribuicaoRendaMensal;
