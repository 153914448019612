// @ts-nocheck
import { Card, Display, Text } from '@cvp/design-system/react';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import EtapasAporte from 'previdencia/features/Aporte/components/EtapasAporte';

const Aporte = () => {
  return (
    <Display type="display-block" alignItems="center">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO]}
        requiredPermissions={[PREV_PERMISSIONS.CONTRIBUICAO_ADICIONAL]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Text variant="headline-05" color="primary" margin>
              Aporte Adicional
            </Text>
            <EtapasAporte />
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default Aporte;
