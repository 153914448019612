// @ts-nocheck
import { FC } from 'react';
import { Display } from '@cvp/design-system/react';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { FluxoSimulacaoRenda } from 'previdencia/features/SimulacaoRenda/components/FluxoSimulacaoRenda/FluxoSimulacaoRenda';

const SimulacaoRenda: FC = () => {
  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FluxoSimulacaoRenda />
    </Display>
  );
};

export default SimulacaoRenda;
