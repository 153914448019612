// @ts-nocheck
import React from 'react';
import { PE } from 'main/components/PEComponents';
import {
  ATENCAO_COTAS_ABERTURA_APURACAO_FUNDOS,
  FUNDOS,
  IMPORTANTE,
  PRODUTOS_PGBL_VGBL,
  RENTABILIDADE_FUNDOS_INVESTIMENTOS,
  RESGATE_COTA_UTILIZADA_CALCULO_VALOR,
  TRANSFERENCIA_MUDANCA_FUNDO,
  TRANSFERENCIA_QUE_NAO_ALTERA_FUNDO,
} from '../../constants';

export interface IExtratoRentabilidadeCotasFooter {
  nomeFundos: string[] | undefined;
}

const ExtratoRentabilidadeCotasFooter: React.FC<
  IExtratoRentabilidadeCotasFooter
> = ({ nomeFundos }) => {
  return (
    <PE.Display type="block">
      <PE.Text variant="body02-sm" color="text-light" margin>
        {RENTABILIDADE_FUNDOS_INVESTIMENTOS}
      </PE.Text>
      <PE.Text variant="body01-md" color="primary" margin>
        {IMPORTANTE}
      </PE.Text>
      <PE.Text variant="body02-sm" color="text-light" margin>
        {PRODUTOS_PGBL_VGBL}
      </PE.Text>
      <PE.Text variant="body02-sm" color="text-light" margin>
        {TRANSFERENCIA_MUDANCA_FUNDO}
      </PE.Text>
      <PE.Text variant="body02-sm" color="text-light" margin>
        {TRANSFERENCIA_QUE_NAO_ALTERA_FUNDO}
      </PE.Text>
      <PE.Text variant="body02-sm" color="text-light" margin>
        {RESGATE_COTA_UTILIZADA_CALCULO_VALOR}
      </PE.Text>
      <PE.Text variant="body02-sm" color="text-light" margin>
        {ATENCAO_COTAS_ABERTURA_APURACAO_FUNDOS}
      </PE.Text>
      <PE.Text variant="body02-sm" color="text-light" margin>
        {FUNDOS}
      </PE.Text>
      {nomeFundos?.map(Fundos => {
        return (
          <PE.Text variant="body02-sm" color="text-light" margin>
            {Fundos}
          </PE.Text>
        );
      })}
    </PE.Display>
  );
};

export default ExtratoRentabilidadeCotasFooter;
