// @ts-nocheck
import styled from 'styled-components';

export const Container = styled.div`
  @media print {
    & > div:first-child {
      display: none;
    }
  }

  & > div:first-child {
    & > div:nth-child(3) {
      & > div {
        & > div {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
      }
    }
  }
`;
