// @ts-nocheck
import React from 'react';
import { Card, Display, Grid, Text } from '@cvp/design-system/react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import TagStatusSeguro from 'seguros/components/TagStatusSeguro';
import { InfoGridItemImpressao } from 'seguros/components/CardResumoProduto/InfoGridItemImpressao/InfoGridItemImpressao';
import * as S from 'seguros/components/CardResumoProduto/styles';
import { CardResumoProdutoImpressaoProps } from 'seguros/types/CardResumoProduto';

export const CardResumoProdutoImpressao: React.FC<
  CardResumoProdutoImpressaoProps
> = ({
  status,
  obterVigenciaValor,
  obterVigenciaLabel,
  tipoContrato,
  dadosGerais,
}) => {
  return (
    <>
      <Display className="show-print" type="display-block">
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <S.GridItem>
                <Display alignItems="center">
                  <Text variant="headline-05" color="primary">
                    {dadosGerais?.nomeProduto}
                  </Text>
                  <TagStatusSeguro status={status} />
                </Display>
              </S.GridItem>

              <Grid.Item lg={9 / 10} md={1}>
                <Display alignItems="center">
                  <Grid>
                    <InfoGridItemImpressao
                      icon="user"
                      label="Nome do Cliente"
                      value={
                        <S.TextClientName>
                          {tryGetValueOrDefault(
                            [dadosGerais?.nomeSegurado],
                            '',
                          )}
                        </S.TextClientName>
                      }
                    />
                    <InfoGridItemImpressao
                      icon="documentFront"
                      label="Documento do Cliente"
                      value={masks.cpfCnpj.mask(
                        tryGetValueOrDefault([dadosGerais?.cpfSegurado], ''),
                      )}
                    />
                    <InfoGridItemImpressao
                      icon="documentPaper"
                      label="Número da Apólice"
                      value={tryGetValueOrDefault(
                        [dadosGerais?.numeroApolice],
                        '',
                      )}
                    />
                    <InfoGridItemImpressao
                      icon="documentBack"
                      label="Certificado/Bilhete"
                      value={`${tryGetValueOrDefault(
                        [dadosGerais?.numeroCertificado],
                        '',
                      )} (${tryGetValueOrDefault([tipoContrato], '')})`}
                    />
                    <InfoGridItemImpressao
                      icon="calendar"
                      label={obterVigenciaLabel()}
                      value={obterVigenciaValor()}
                    />
                    <InfoGridItemImpressao
                      icon="information"
                      label="Situação"
                      value={tryGetValueOrDefault(
                        [dadosGerais?.statusContrato],
                        '',
                      )}
                    />
                  </Grid>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};
