// @ts-nocheck
import { Text, Card, Grid } from '@cvp/design-system/react';

import useAssinaturaTransferencia from 'previdencia/features/TransferenciaEntreCertificados/hooks/useAssinaturaTransferencia';

const CardCertificados = () => {
  const { certificadoDestino, numCertificado } = useAssinaturaTransferencia();

  return (
    <Card>
      <Card.Content padding={[4, 4, 4]}>
        <Text color="text-light" margin>
          Resumo certificados
        </Text>
        <Grid style={{ margin: '20px 0' }}>
          <Grid.Item xs={1} lg={1 / 4}>
            <Text variant="body01-sm" margin>
              <b>Certificado Origem</b>
            </Text>
            <Text variant="body01-sm">{numCertificado}</Text>
          </Grid.Item>

          <Grid.Item xs={1} lg={1 / 4}>
            <Text variant="body01-sm" margin>
              <b>Certificado Destino</b>
            </Text>
            <Text variant="body01-sm">{certificadoDestino}</Text>
          </Grid.Item>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default CardCertificados;
