// @ts-nocheck
import { useState } from 'react';
import { IFormProspeccao } from 'main/features/prospeccao/types/IFormProspeccao';
import { useToast } from 'main/hooks/useToast';
import { IImportanciaSegurada } from 'main/features/prospeccao/types/IImportanciaSegurada';
import * as ApiProspeccao from '../services/prospeccao.api';

export const useFormularioProspeccao = () => {
  const { toastError } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCadastroProspeccao, setLoadingCadastroProspeccao] =
    useState<boolean>(false);
  const [loadingImportanciaSegurada, setLoadingImportanciaSegurada] =
    useState<boolean>(false);
  useState<boolean>(false);
  const [resultadoCadastroProspeccao, setResultadoCadastroProspeccao] =
    useState<number>(0);

  const [importanciaSegurada, setImportanciaSegurada] =
    useState<IImportanciaSegurada>({} as IImportanciaSegurada);

  const [formulario, setFormulario] = useState<IFormProspeccao>(
    {} as IFormProspeccao,
  );
  const [error, setError] = useState<unknown>();

  const getFormulario = async (codigoProduto: string) => {
    try {
      setLoading(true);
      const {
        dados: { entidade, sucesso },
      } = await ApiProspeccao.obterFormulario(codigoProduto);
      if (sucesso && entidade) {
        setFormulario(entidade);
        return;
      }
      if (!sucesso)
        toastError('Não foi possível recuperar o formulário de Pré-Análise.');
    } catch (exception: unknown) {
      setError(exception);
    } finally {
      setLoading(false);
    }
  };

  const cadastrarProspeccao = async (form: IFormProspeccao) => {
    try {
      setLoadingCadastroProspeccao(true);
      const {
        dados: { entidade, sucesso },
      } = await ApiProspeccao.cadastrarProspeeccao(form);
      if (sucesso && entidade) {
        setResultadoCadastroProspeccao(entidade);
        return entidade;
      }
      if (!sucesso) toastError('Não foi possível cadastrar a Pré-Análise');
    } catch (exception: unknown) {
      setError(exception);
    } finally {
      setLoadingCadastroProspeccao(false);
    }
    return 0;
  };

  const obterValorImportanciaSegurada = async (cpfCnpjCliente: string) => {
    try {
      setLoadingImportanciaSegurada(true);
      const {
        dados: { entidade, sucesso },
      } = await ApiProspeccao.obterImportanciaSegurada(cpfCnpjCliente);
      if (sucesso && entidade) {
        setImportanciaSegurada(entidade);
        const { valorTotalContratos } = entidade;
        return valorTotalContratos ?? 0;
      }
      if (!sucesso)
        toastError('Ocorreu um erro ao obter a Importância Segurada');
    } catch (exception: unknown) {
      setError(exception);
    } finally {
      setLoadingImportanciaSegurada(false);
    }
    return 0;
  };

  return {
    loading,
    formulario,
    error,
    loadingCadastroProspeccao,
    loadingImportanciaSegurada,
    resultadoCadastroProspeccao,
    importanciaSegurada,
    getFormulario,
    cadastrarProspeccao,
    obterValorImportanciaSegurada,
  };
};
