// @ts-nocheck
import React from 'react';
import { Display, Modal, Button, Text, Grid } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { IModalInfoEnvioDPSProps } from '../interface/IModalDpsProps';
import RenderConditional from 'main/components/RenderConditional';

export const ModalInfoEnvioDPS: React.FC<IModalInfoEnvioDPSProps> = ({
  open,
  handleClose,
  loading,
  statusEnvio,
}) => {
  return (
    <Modal show={open} onClose={handleClose}>
      <Text variant="headline-05" margin>
        DPS Eletrônica
      </Text>

      <RenderConditional condition={loading}>
        <Button variant="text" loading />
      </RenderConditional>

      <Grid justify="center">
        <Grid.Item>
          <RenderConditional condition={!!statusEnvio?.success}>
            <Text variant="body-medium3" align="center" color="success">
              <Icon name="successCheck" /> {statusEnvio?.message}
            </Text>
          </RenderConditional>
          <RenderConditional condition={!statusEnvio?.success}>
            <Text variant="body-medium3" align="center" color="error">
              <Icon name="warning" /> {statusEnvio?.message}
            </Text>
          </RenderConditional>
        </Grid.Item>
      </Grid>

      <Display justify="center" style={{ marginTop: 20 }}>
        <Button variant="primary" onClick={handleClose} data-testid="cancelar">
          Fechar
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalInfoEnvioDPS;
