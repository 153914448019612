// @ts-nocheck
import React from 'react';
import { Display, Card, Text, Grid, Divider } from '@cvp/design-system/react';
import ListarFops from 'extranet/components/ListarFops/ListarFops';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { useFormFops } from 'extranet/hooks/useFormFops';

const FopPrestamista: React.FC = () => {
  const { loadingFops, responseFops } = useFormFops();

  if (loadingFops) {
    return <SkeletonLoading blocks={3} />;
  }

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text
                variant="headline-05"
                color="primary"
                key="formulario-titulo"
              >
                Formulários (FOPs) de Prestamista
              </Text>
            </Grid.Item>
          </Grid>
          <Divider />
          <ListarFops tipoFop="prest" dataToList={responseFops?.entidade} />
          <ListarFops
            tipoFop="prest_vida"
            dataToList={responseFops?.entidade}
          />
          <ListarFops
            tipoFop="corp_outros"
            dataToList={responseFops?.entidade}
          />
          <Divider />
        </Card.Content>
      </Card>
    </Display>
  );
};
export default FopPrestamista;
