// @ts-nocheck
import styled from 'styled-components/macro';

export const Container = styled.div`
  padding-right: 10px;
  padding-left: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Description = styled.div(({ theme: { breakpoint } }) => ({
  [breakpoint.md()]: {
    fonrSize: 13,
  },
}));

export const WrapperColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const WrapperIcon = styled.div<{ margin?: number }>(({ margin }) => ({
  marginRight: margin || 56,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));
