// @ts-nocheck
import React, { useMemo } from 'react';

import { Select, Button, Display, Text } from '@cvp/design-system/react';
import Modal from 'main/components/Modal';
import { usePecoListarMotivoCancelamento } from 'previdencia/features/HistoricoSolicitacoes/hooks/usePecoListarMotivoCancelamento';
import { required } from 'main/features/Validation/validations';
import { useCancelarTransferencia } from 'previdencia/features/HistoricoSolicitacoes/hooks/useCancelarTransferencia';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import RenderConditional from 'main/components/RenderConditional';
import { IModalCancelarTransferenciaProps } from 'previdencia/features/HistoricoSolicitacoes/components/ModalCancelarTransferencia/types/ModalCancelarTransferencia';

export const ModalCancelarTransferencia: React.FC<
  IModalCancelarTransferenciaProps
> = ({ onClose, isOpen = false, idRequisicao }) => {
  const { ListarMotivoCancelamento } = usePecoListarMotivoCancelamento();

  const {
    codigoMotivo,
    handleSelecionarMotivo,
    handleCancelar,
    loadingCancelarTransferencia,
    mensagem,
    sucesso,
  } = useCancelarTransferencia(idRequisicao);

  const motivosCancelamentoOptions = useMemo(() => {
    return ListarMotivoCancelamento?.map(item => (
      <Select.Item
        key={item.codigoMotivo}
        value={item.codigoMotivo}
        text={item.descricaoMotivo}
        selected={item.codigoMotivo === codigoMotivo}
      />
    ));
  }, [ListarMotivoCancelamento, codigoMotivo]);

  const renderMessage = useMemo(() => {
    if (sucesso) {
      return (
        <Display alignItems="flex-start">
          <Text variant="body01-md" color="success">
            {tryGetValueOrDefault([mensagem], '')}
          </Text>
        </Display>
      );
    }
    return null;
  }, [sucesso, mensagem]);

  return (
    <Modal onClose={onClose} open={isOpen}>
      {renderMessage}

      <RenderConditional condition={!sucesso}>
        <Display alignItems="flex-start">
          <Text variant="body01-md" color="primary">
            Motivo do cancelamento
          </Text>
          <Text variant="body01-md">Selecione o motivo do cancelamento:</Text>
        </Display>
        <Display>
          <Select
            placeholder="Motivo do cancelamento"
            validationRules={[required()]}
            onChange={handleSelecionarMotivo}
            value={codigoMotivo}
            disabled={sucesso}
          >
            {motivosCancelamentoOptions}
          </Select>
        </Display>
        <Display justify="center">
          <Button
            variant="primary"
            onClick={handleCancelar}
            disabled={checkIfSomeItemsAreTrue([
              !codigoMotivo,
              loadingCancelarTransferencia,
            ])}
            loading={loadingCancelarTransferencia}
          >
            Confirmar
          </Button>
        </Display>
      </RenderConditional>
    </Modal>
  );
};
