// @ts-nocheck
import { formatarData } from 'main/utils';
import {
  checkIfSomeItemsAreTrue,
  getTernaryResult,
} from 'main/utils/conditional';

export const formatarDataUpload = (dataUpload: string) =>
  getTernaryResult(
    checkIfSomeItemsAreTrue([!dataUpload, !!dataUpload?.match(/1900/)]),
    '-',
    formatarData(dataUpload),
  );
