// @ts-nocheck
import React from 'react';
import { Grid, Text, Display, Divider } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import Icon from 'main/components/Icon';
import { differenceInYears } from 'date-fns';
import { replaceDate } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IPagamentoRegular, IResponseDadosPlano } from '../../types';
import * as S from './styles';

type DetalhesPlanoProps = {
  dados: IResponseDadosPlano | undefined;
  pagamentoRegular: IPagamentoRegular;
};

const calcularDuracao = (dataFinal: any, dataInicioVigencia: any): string => {
  const finalVigencia = replaceDate(dataFinal);
  const inicioVigencia = replaceDate(dataInicioVigencia);

  const duracao = differenceInYears(finalVigencia, inicioVigencia);
  return `${duracao} ${duracao > 1 ? 'anos' : 'ano'}`;
};

const DetalhesPlano: React.FC<DetalhesPlanoProps> = ({
  dados,
  pagamentoRegular,
}) => {
  return (
    <S.DetalhesPlano>
      <Grid>
        <Grid.Item xs={1}>
          <Text variant="body02-md" color="text">
            Saldo
          </Text>
          <Divider />
          <Grid.Item xs={1}>
            <Display className="saldo">
              <Icon name="extrato" />
              <div>
                <Text
                  variant="body02-sm"
                  color="text"
                  data-testid="saldo-plano"
                >
                  {formatarValorPadraoBrasileiro(dados?.return?.vlrSaldoAtual)}
                </Text>
              </div>
            </Display>
          </Grid.Item>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item xs={1}>
          <Text variant="body02-md" color="text">
            Plano
          </Text>
          <Divider />
        </Grid.Item>
        <Grid.Item xs={1} sm={1 / 2} md={1 / 3}>
          <div>
            <Text variant="body02-md" color="text">
              Duração do Plano
            </Text>
            <Text variant="body02-sm" color="text">
              {calcularDuracao(
                dados?.return?.dhtFimPlano,
                dados?.return?.dhtIniVigencia,
              )}
            </Text>
          </div>
        </Grid.Item>
        <Grid.Item xs={1} sm={1 / 2} md={1 / 3}>
          <div>
            <Text variant="body02-md" color="text">
              Dia do Vencimento
            </Text>
            <Text variant="body02-sm" color="text">
              {tryGetValueOrDefault([pagamentoRegular?.diaVencimento], ' - ')}
            </Text>
          </div>
        </Grid.Item>
        <Grid.Item xs={1} sm={1 / 2} md={1 / 3}>
          <div>
            <Text variant="body02-md" color="text">
              Forma de Pagamento
            </Text>
            <Text
              variant="body02-sm"
              color="text"
              data-testid="forma-pagamento-plano"
            >
              {tryGetValueOrDefault([pagamentoRegular?.formaPagamento], ' - ')}
            </Text>
          </div>
        </Grid.Item>
      </Grid>
    </S.DetalhesPlano>
  );
};

export default DetalhesPlano;
