// @ts-nocheck
import React, { useState } from 'react';
import { Button } from '@cvp/design-system/react';
import IframeResizer from 'iframe-resizer-react';
import * as S from '../styles';

type ModalConfirmarTokenAssinaturaProps = {
  open: boolean;
  handleClose(close: boolean): void;
  urlTokenPage: string;
  exibirBotaoFecharModal?: boolean;
};

export const ModalConfirmarTokenAssinatura: React.FunctionComponent<
  ModalConfirmarTokenAssinaturaProps
> = ({ open, handleClose, urlTokenPage, exibirBotaoFecharModal = false }) => {
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <S.ModalDocUsign
      showCloseButton={exibirBotaoFecharModal}
      show={open}
      onClose={handleClose}
    >
      {loading && (
        <S.LoadignModalAssinatura>
          <Button variant="text" loading />
        </S.LoadignModalAssinatura>
      )}

      <IframeResizer
        log
        src={urlTokenPage}
        style={{
          width: '100%',
          minWidth: '100%',
          height: '100%',
          minHeight: '700px',
          border: 0,
        }}
        onLoad={() => setLoading(false)}
      />
    </S.ModalDocUsign>
  );
};

export default ModalConfirmarTokenAssinatura;
