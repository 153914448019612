// @ts-nocheck
import usePecoConsultarPortabilidade from 'painelPortabilidade/hooks/usePecoConsultarPortabilidade';
import usePecoConsultarPortabilidadeAgencia from 'painelPortabilidade/hooks/usePecoConsultarPortabilidadeAgencia';

import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'main/features/Auth/hooks';
import { IDadosPortabilidadePayload as payloadRequest } from 'painelPortabilidade/types/ConsultarPortabilidadePayload';

const useHierarquiaPortabilidade = () => {
  const { user } = useAuth();
  const [tipoVisao, setTipoVisao] = useState<string>('');
  const [unidade, setUnidade] = useState<number | string>();
  const [step, setStep] = useState([user?.agenciaVinculada]);
  const ultimoCodRef = useRef<any>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [payloadConsulta, setPayloadConsulta] = useState<payloadRequest>({
    codigoTipoVisao: '',
    tipoVisao: '',
  });

  const {
    dadosHierarquiaPortabilidade,
    loadingHierarquiaPortabilidade: loading,
    fetchDataHierarquia,
  } = usePecoConsultarPortabilidade(payloadConsulta);

  const {
    dadosHierarquiaPortabilidadeAgencia,
    loadingHierarquiaPortabilidadeAgencia,
    fetchDataAgencia,
  } = usePecoConsultarPortabilidadeAgencia();

  const handleConsulta = (cod?: string | number) => {
    if (cod) {
      fetchDataAgencia({ codigo: cod });
      if (ultimoCodRef.current) setStep([...step, ultimoCodRef.current]);
      setIsDisabled(false);
      ultimoCodRef.current = cod;
      setPayloadConsulta({ ...payloadConsulta, codigoTipoVisao: cod });
    } else {
      setPayloadConsulta({
        ...payloadConsulta,
        codigoTipoVisao: unidade,
      });
    }
  };

  const handleBackStep = () => {
    const ultimoCodigo = step[step.length - 1];
    if (step.length > 0) {
      setStep(step.slice(0, -1));
      ultimoCodRef.current = '';
      setPayloadConsulta({ codigoTipoVisao: ultimoCodigo });
      if (ultimoCodigo === user?.agenciaVinculada) {
        setIsDisabled(true);
      }
    } else {
      setStep([]);
      setPayloadConsulta({
        codigoTipoVisao: user?.agenciaVinculada,
      });
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    if (dadosHierarquiaPortabilidade?.entidade?.tipoVisao)
      setTipoVisao(dadosHierarquiaPortabilidade?.entidade?.tipoVisao);
    if (dadosHierarquiaPortabilidade?.entidade?.unidadeInicial)
      setUnidade(dadosHierarquiaPortabilidade?.entidade?.unidadeInicial);
  }, [dadosHierarquiaPortabilidade]);

  useEffect(() => {
    fetchDataHierarquia();
  }, [payloadConsulta]);
  return {
    tipoVisao,
    unidade,
    setPayloadConsulta,
    payloadConsulta,
    loading,
    handleConsulta,
    fetchDataAgencia,
    dadosHierarquiaPortabilidadeAgencia,
    isDisabled,
    handleBackStep,
    dadosHierarquiaPortabilidade,
    loadingHierarquiaPortabilidadeAgencia,
  };
};

export default useHierarquiaPortabilidade;
