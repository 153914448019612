// @ts-nocheck
import styled from 'styled-components';

import { Grid } from '@cvp/design-system/react';

export const GridItem = styled(Grid.Item)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
}));
