// @ts-nocheck
import { PARAMETROS_DPS_SEGMENTO } from 'contratosPrestamista/constants/parametrosDps';
import { IDadosBasePessoa } from 'contratosPrestamista/types';
import { formatarDataAdicionandoSeparador } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { removeEmptySpace } from 'main/utils/string';

type ParametrosSolicitarDps = {
  numeroProposta?: string | number | null;
  valorAcumulo: number;
} & IDadosBasePessoa;

export const construirPayloadSolicitarDps = (
  data: ParametrosSolicitarDps,
  tipo: 'PF' | 'PJ' = 'PJ',
) => ({
  ...PARAMETROS_DPS_SEGMENTO[tipo],
  numeroProposta: masks.numberOnly.mask(data?.numeroProposta),
  cpf: data.cpf,
  codigoUsuario: data?.codigoUsuario,
  dataHoraValidadeProposta: new Date().toISOString(),
  valorGarantia: +tryGetValueOrDefault([data?.valorAcumulo], 0).toFixed(2),
  dadosCadastrais: {
    nome: data.nome,
    dataNascimento: formatarDataAdicionandoSeparador(data.dataNascimento),
    sexo: data.sexo,
    contato: {
      email: removeEmptySpace(data.email),
      telefone: removeEmptySpace(data.telefone),
    },
    endereco: {
      logradouro: removeEmptySpace(data.endereco),
      complemento: '',
      bairro: removeEmptySpace(data.bairro),
      cidade: removeEmptySpace(data.cidade),
      uf: removeEmptySpace(data.uf),
      cep: removeEmptySpace(data.cep),
    },
  },
});
