// @ts-nocheck
import styled from 'styled-components/macro';
import { Display as DisplayDS } from '@cvp/design-system/react';

export const ContainerAttachments = styled(DisplayDS)(props => ({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fit, minmax(250px, ${
    props.itemCount > 2 ? '1fr' : '350px'
  }))`,
}));

export const ContainerAttachDownload = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',

  '&:hover': {
    cursor: 'pointer',
  },
}));

export const ContainerAttach = styled(DisplayDS)(({ theme: { color } }) => ({
  background: `${color.brandPrimary.light}26`,
  transition: 'ease all 250ms',
  borderRadius: '8px',

  '&:hover': {
    background: `${color.brandPrimary.light}4d`,
  },

  '& div div': {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
  },

  '& p, & h6': {
    margin: 0,
    zIndex: 1,
    opacity: 0.7,
    transition: 'ease all 250ms',
  },

  '&:hover p, &:hover h6': {
    opacity: 1,
    transition: 'ease all 250ms',
  },

  '& span': {
    fontWeight: 'bold',
  },

  '& div svg': {
    display: 'flex',
    flex: 1,
    margin: 0,
    width: '45px',
    height: '45px',
    fill: color.brandPrimary.light,
    opacity: 0.7,
    zIndex: 0,
  },

  '&:hover svg': {
    opacity: 1,
    transition: 'ease all 250ms',
  },
}));
