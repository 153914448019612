// @ts-nocheck
import { Card, Display, Text, Button } from '@cvp/design-system/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { TransferenciaOrigemDestino } from '../components/TransferenciaOrigemDestino/TransferenciaOrigemDestino';
import { useAssinatura } from '../hooks/useAssinatura';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import { IMessageEvent } from 'main/types/IMessageEvent';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaAlteracaoTaxaContext } from '../types/ITransferenciaAlteracaoTaxaContext';
import RenderConditional from 'main/components/RenderConditional';
import Icon from 'main/components/Icon';
import { MENSAGENS_ERRO } from '../constants/tratamentoErros';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import ModalTipoDocuSing from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';

export const TipoAssinatura = () => {
  const history = useHistory();
  const { featureData } =
    usePrevidenciaContext<ITransferenciaAlteracaoTaxaContext>();
  const { subscribe, unsubscribe } = useMessageEvent();
  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] =
    useState<boolean>(false);
  const [showModalTipoEnvio, setShowModalTipoEnvio] = useState(false);
  const [showModalTokenDocUsign, setShowModalTokenDocUsign] = useState(false);
  const [sucessoAssinatura, setSucessoAssinatura] = useState(false);

  const handleCloseModalDocUsign = () => {
    setShowModalTokenDocUsign(!showModalTokenDocUsign);
    if (sucessoAssinatura) {
      history.push(
        '/cliente/produtos/previdencia/transferencia-alteracao-taxa/comprovante',
      );
    }
  };
  const {
    loadingAssinatura,
    loadingDadosAssinatura,
    response,
    dadosCliente,
    idRequisicaoHistorico,
    solicitarAssinatura,
  } = useAssinatura();

  useEffect(() => {
    if (response?.urlAssinatura)
      setShowModalTokenDocUsign(!showModalTokenDocUsign);
  }, [response]);

  useEffect(() => {
    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;
      if (eventSuccess) setSucessoAssinatura(true);
      if (
        checkIfAllItemsAreTrue([eventName === 'retornoAcaoConcluirAssinatura'])
      ) {
        setExibirBotaoFecharModal(true);
      }
    });

    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(false));
    };
  }, []);

  useEffect(() => {
    if (idRequisicaoHistorico) setShowModalTipoEnvio(!showModalTipoEnvio);
  }, [idRequisicaoHistorico]);

  if (checkIfSomeItemsAreTrue([loadingAssinatura, loadingDadosAssinatura]))
    return <CardSkeleton />;

  return (
    <Display type="block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content>
          <TransferenciaOrigemDestino erro={!featureData?.numTransferencia} />
          <RenderConditional condition={!!featureData?.numTransferencia}>
            <Text>
              Para <strong>concluir a transferência</strong>, escolha como
              deseja assinar:
            </Text>
            <Display alignItems="center" style={{ marginTop: 20 }}>
              <RenderConditional condition={!idRequisicaoHistorico}>
                <Button
                  variant="outlined"
                  disabled={!featureData?.numTransferencia}
                  onClick={() =>
                    history.push(
                      '/cliente/produtos/previdencia/transferencia-alteracao-taxa/assinatura-manual',
                    )
                  }
                >
                  Assinatura física
                </Button>
              </RenderConditional>

              <Button
                variant="outlined"
                disabled={!featureData?.numTransferencia}
                outlined
                onClick={() => setShowModalTipoEnvio(!showModalTipoEnvio)}
              >
                Assinatura eletrônica
              </Button>
            </Display>
          </RenderConditional>
          <RenderConditional condition={!featureData?.numTransferencia}>
            <Text color="error" variant="caption">
              <Icon name="warning" /> {MENSAGENS_ERRO.ERRO_GENERICO_SERVICO}
            </Text>
          </RenderConditional>
        </Card.Content>
      </Card>
      <ModalTipoDocuSing
        dadosCliente={dadosCliente}
        open={showModalTipoEnvio}
        handleClose={() => setShowModalTipoEnvio(!showModalTipoEnvio)}
        metodoAssinaturaEmail={false}
        executarAssinatura={() => {
          solicitarAssinatura();
        }}
      />
      <ModalTokenDocuSign
        exibirBotaoFecharModal={exibirBotaoFecharModal}
        open={showModalTokenDocUsign}
        handleClose={handleCloseModalDocUsign}
        urlTokenPage={response?.urlAssinatura}
      />
    </Display>
  );
};
