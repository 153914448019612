// @ts-nocheck
import styled from 'styled-components';

import {
  Disclaimer as DisclaimerDS,
  Display as DisplayDS,
} from '@cvp/design-system/react';

export const Disclaimer = styled(DisclaimerDS)({
  padding: '12px 0 12px 16px',
  button: {
    opacity: 0,
  },
  svg: {
    width: '22px',
    height: '22px',
  },
});

export const Display = styled(DisplayDS)({
  margin: '40px 0 0px 0',
});
