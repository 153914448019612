// @ts-nocheck
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { IMenuItem } from 'main/components/Menu/Sidebar/types';

export const menuExternoPrevidencia: IMenuItem = {
  label: 'Previdência',
  alt: 'previdencia',
  path: '/previdencia',
  icon: 'capitalizacao',
  roles: [
    ...PRINCIPAL_USERS,
    USER_PROFILES.ANALISTA_MANUTENCAO,
    USER_PROFILES.ANALISTA_SAIDA,
    USER_PROFILES.ANALISTA_ENTRADA,
    USER_PROFILES.ANALISTA_CONSULTA,
  ],
  subItems: [
    {
      label: 'Fundos de investimento',
      path: '/extranet/fundos-investimentos',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
      ],
    },
    {
      label: 'Consultar Portabilidade de Entrada',
      path: '/portabilidades-entrada',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
      ],
    },
    {
      label: 'Recursos pendentes de recebimento',
      path: '/reter/recursos-pendentes-recebimento',
      roles: PRINCIPAL_USERS,
    },
    {
      label: 'Retenção',
      path: '/retencao',
      roles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
      subItems: [
        {
          label: 'Portabilidade de Saída',
          path: '/hierarquia-portabilidades',
          roles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
        },
        {
          label: 'Resgate',
          path: '/painel-resgate',
          roles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
        },
      ],
    },
    {
      label: 'Relatório de Resgates',
      path: '/previdencia/resgates',
      roles: [USER_PROFILES.ANALISTA_TI],
    },
    {
      label: 'Formulários (FOPs)',
      path: '/extranet/fop-previdencia',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
      ],
    },
    {
      label: 'Simuladores',
      path: '/extranet/simuladores',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
      ],
    },
  ],
};
