// @ts-nocheck
import {
  Button,
  Card,
  Display,
  Grid,
  Text,
  Radio,
} from '@cvp/design-system/react';

import For from 'main/components/For';
import RenderConditional from 'main/components/RenderConditional';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { IListarFundosParaResgateAliquota } from 'previdencia/types/ConsultaListaFundosParaResgate';
import useConfirmarValoresSimulacao from 'previdencia/features/SolicitacaoResgate/hooks/useConfirmarValoresSimulacao';
import TabelaResumoSimulacao from 'previdencia/features/SolicitacaoResgate/components/TabelaResumoSimulacao';
import ModalValoresDetalhadosAliquota from 'previdencia/features/SolicitacaoResgate/components/ModalValoresDetalhadosAliquota';
import ModalMensagensResgate from 'previdencia/features/SolicitacaoResgate/components/ModalMensagensResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from './styles';

const ConfirmarValoresSimulacao = () => {
  const { navigateTo } = usePrevNavigation();

  const { featureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const { opcoesAliquotas, indicadorPermiteEditarAliquota } =
    tryGetValueOrDefault(
      [featureData?.dadosListarFundosParaResgate?.aliquota],
      {} as IListarFundosParaResgateAliquota,
    );

  const {
    objetoEmail,
    opcaoRegimeTributario,
    openModalAtencaoResgate,
    open,
    isLoadingEfetuarResgate,
    isDesabilitadoBotaoRealizarResgate,
    obterDadosPorAliquota,
    selecionarOpcaoRegimeTributario,
    handleCloseModalValoresDetalhados,
    handleCloseModalAtencaoResgate,
    efetuarResgate,
  } = useConfirmarValoresSimulacao();

  return (
    <>
      <Display type="display-block">
        <PrevidenciaResumo />
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  Simular Resgate
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Text variant="body01-md" margin>
                  {getTernaryResult(
                    indicadorPermiteEditarAliquota,
                    CONSTS.DESCRICAO_SELECAO_ALIQUOTA.COM_OPCAO_SELECAO,
                    CONSTS.DESCRICAO_SELECAO_ALIQUOTA.SEM_OPCAO_SELECAO,
                  )}
                </Text>
              </Grid.Item>
            </Grid>
            <Grid>
              <For each={tryGetValueOrDefault([opcoesAliquotas], [])}>
                {tipoAliquota => (
                  <RenderConditional
                    condition={
                      obterDadosPorAliquota(tipoAliquota.codigoAliquota)
                        .isExibeTabelaAliquota
                    }
                  >
                    <Grid.Item xs={1} xl={1 / 2}>
                      <Card>
                        <Card.Content
                          padding={[4, 4, 4]}
                          style={{ padding: 0 }}
                        >
                          <S.ContainerCardAliquota>
                            <S.ContainerSelecaoAliquota>
                              <RenderConditional
                                condition={indicadorPermiteEditarAliquota}
                              >
                                <Radio
                                  name="tipoAliquota"
                                  onChange={() => {
                                    selecionarOpcaoRegimeTributario(
                                      tipoAliquota.codigoAliquota,
                                    );
                                  }}
                                  value={tipoAliquota.codigoAliquota}
                                  checked={
                                    tipoAliquota.codigoAliquota ===
                                    opcaoRegimeTributario
                                  }
                                  aria-label={`Selecionar alíquota ${tipoAliquota.descricaoAliquota}`}
                                />
                              </RenderConditional>

                              <Text variant="body01-md" color="white">
                                Alíquota {tipoAliquota.descricaoAliquota}
                              </Text>
                            </S.ContainerSelecaoAliquota>

                            <div>
                              <TabelaResumoSimulacao
                                dadosResumo={
                                  obterDadosPorAliquota(
                                    tipoAliquota.codigoAliquota,
                                  ).resumo
                                }
                              />
                            </div>

                            <Button
                              variant="secondary"
                              id="valoresDetalhados"
                              onClick={() =>
                                handleCloseModalValoresDetalhados(
                                  tipoAliquota.codigoAliquota,
                                )
                              }
                            >
                              Valores detalhados
                            </Button>
                          </S.ContainerCardAliquota>
                        </Card.Content>
                      </Card>
                    </Grid.Item>
                  </RenderConditional>
                )}
              </For>

              <Grid.Item xs={1}>
                <S.ContainerNav>
                  <Button
                    variant="outlined"
                    onClick={() => navigateTo('solicitacao-resgate')}
                  >
                    Nova simulação
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleCloseModalAtencaoResgate}
                    disabled={isDesabilitadoBotaoRealizarResgate}
                  >
                    Realizar resgate
                  </Button>
                </S.ContainerNav>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>

      <ModalValoresDetalhadosAliquota
        handleClose={handleCloseModalValoresDetalhados}
        open={open}
        detalhadoAliquota={obterDadosPorAliquota().resumoEstendido}
        dadosTabelaDetalheCalculo={
          obterDadosPorAliquota().tabelaHistoricoContribuicao
        }
        objetoEmail={objetoEmail}
      />

      <ModalMensagensResgate
        openModalAtencaoResgate={openModalAtencaoResgate}
        handleCloseModalAtencaoResgate={handleCloseModalAtencaoResgate}
        efetuarResgate={efetuarResgate}
        isLoadingEfetuarResgate={isLoadingEfetuarResgate}
      />
    </>
  );
};

export default ConfirmarValoresSimulacao;
