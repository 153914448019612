// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { getUserName } from 'main/features/Auth/utils/auth';
import { EnumCanalOrigem } from 'previdencia/types/DefaultValues';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { DefaultParamRequest } from 'previdencia/types/enum';
import {
  IPayloadIncluir,
  IPayloadIncluirPeco,
  IResponseIncluirReativarBeneficioProtecao,
} from '../types/ReativarIncluirBeneficioProtecaoTypes';

const useIncluirBeneficioProtecao = (payloadReativar: IPayloadIncluir) => {
  const username = getUserName();

  const {
    cliente: { numCertificado },
  } = useContext(AppContext);
  const { response, loading, fetchData } = usePeco<
    IPayloadIncluirPeco,
    IResponseIncluirReativarBeneficioProtecao
  >({
    api: {
      operationPath: PECOS.IncluirBeneficioProtecao,
    },
    autoFetch: false,
    payload: {
      ...payloadReativar,
      codigoEmpresa: DefaultParamRequest.CODIGO_EMPRESA,
      codigoCertificado: numCertificado,
      matriculaEconomiario: username,
      canalOrigem: EnumCanalOrigem.portalEconimiario,
    },
  });

  return { response, loading, fetchData };
};

export default useIncluirBeneficioProtecao;
