// @ts-nocheck
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { IStatusPropostaDpsAgrupado } from '../types/IResponseListarStatusPropostaDps';

export const obterTituloTabelaSocios = (isPj: boolean) =>
  getTernaryResult<string>(isPj, 'Sócios', 'Declaração Pessoal de Saúde');
export const formatarCpfCnpjComZerosEsquerda = (
  tipoPessoa: string,
  numCpfSocio: number,
) => {
  return tipoPessoa === 'F'
    ? numCpfSocio?.toString().padStart(11, '0')
    : numCpfSocio?.toString().padStart(14, '0');
};

export const obterRetornoAdicionarSocio = (
  socio: unknown,
  listaSocios: IStatusPropostaDpsAgrupado[],
): IStatusPropostaDpsAgrupado[] => {
  const socioCast = socio as IStatusPropostaDpsAgrupado;

  if (socioCast?.nome && listaSocios?.length > 0) {
    const [itemCopy] = listaSocios;
    return [
      ...tryGetValueOrDefault([listaSocios], []),
      {
        ...itemCopy,
        ...socioCast,
        capitalSegurado: 0,
        linhaProduto: 0,
        pesOperador: 0,
        valorAcumulo: 0,
        numCpfSocio: socioCast.cpf,
        numCpfSocioFormatado: socioCast.cpf,
        novoSocio: true,
      },
    ].map(item => ({ ...item, dpsVerificada: false }));
  }

  return [] as IStatusPropostaDpsAgrupado[];
};

/**
 * Adiciona um número específico de dias à data fornecida e valida se a nova data está no futuro.
 *
 * @param {string | null} data - A data a ser processada.
 * @param {number} [dias=5] - O número de dias a serem adicionados à data. Padrão é 5.
 * @returns {boolean} Retorna true se a nova data (adicionando os dias especificados) estiver no futuro em relação à data atual; caso contrário, retorna false.
 */
export const validarDataVigenteDps = (
  data: string | null,
  dias = 6,
): boolean => {
  const newData = tryGetValueOrDefault([data], '') as string;

  const dataAtual = new Date();

  const dataParametro = new Date(newData);
  dataParametro.setDate(dataParametro.getDate() + dias);
  dataParametro.setHours(23, 59, 59, 999);

  return dataParametro > dataAtual;
};
