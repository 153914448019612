// @ts-nocheck
import React, { useCallback, useContext, useState } from 'react';
import { Grid, Display, Text, Button, Card } from '@cvp/design-system/react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import ButtonsEtapasAporte from 'previdencia/features/Aporte/components/ButtonsEtapasAporte';
import GerarAporteDadosDistribuicao from 'previdencia/features/Aporte/components/GerarAporte/GerarAporteDadosDistribuicao';
import {
  usePecoEfetuarAporte,
  usePecoValidarAporte,
} from 'previdencia/features/Aporte/hooks/usePecoAporte';
import * as FACTORY from 'previdencia/features/Aporte/factories/aporteFactory';
import * as CONST from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';

import usePrevSummary from 'previdencia/hooks/usePrevSummary';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import ModalBlur from 'main/components/ModalBlur/ModalBlur';
import RenderConditional from 'main/components/RenderConditional';
import { InfoGridItemInline } from 'previdencia/components/PrevidenciaResumo/InfoGridItemInline/InfoGridItemInline';
import { useFormik } from 'formik';
import InputGridItemInline from '../InputGrid';
import ModalConfirmaCertificado from './ConfirmaCertificado';
import { validarVerificacaoCamposAporte } from '../../utils/aporteUtils';

const GerarAporte: React.FC<APORTE_TYPES.IGerarAporteProps> = ({
  fundosDistribuicao,
  aporteFormaPagamento,
  aporteDadosBancarios,
  aporteOrigemRecursos,
}) => {
  const { toastSuccess } = useToast();

  const { setFeatureData, featureData } =
    usePrevidenciaContext<Partial<APORTE_TYPES.IAporteContextData>>();
  const [confirmarAporte, setConfirmarAporte] = useState(false);
  const [confirmarCertificado, setConfirmarCertificado] = useState(false);

  const { informacaoPorCertificado } = usePrevSummary();

  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const formik = useFormik({
    initialValues: {
      primeiroNome: '',
      finalCPF: '',
    },
    validate: values =>
      validarVerificacaoCamposAporte(values, {
        nome: tryGetValueOrDefault(
          [informacaoPorCertificado?.pessoa?.pessoaFisica?.nome],
          '',
        ),
        cpfCnpj: tryGetValueOrDefault([cpfCnpj], ''),
      }),
    onSubmit: () => undefined,
  });

  const { efetuarAporteAdicional, loadingDadosAporteAdicional } =
    usePecoEfetuarAporte();

  const { validarAporteFetch, validaAporteLoading } = usePecoValidarAporte({
    primeiroNome: formik.values.primeiroNome,
    finalCPF: formik.values.finalCPF,
  });

  const requestAporte: APORTE_TYPES.RequestEfetuarAporte =
    FACTORY.requisicaoEfetuarAporte({
      cpfCnpj,
      numCertificado,
      aporteFormaPagamento: aporteFormaPagamento.get().value,
      aporteDadosBancarios: aporteDadosBancarios.get().value,
      fundosDistribuicao: fundosDistribuicao.get().value,
      pepPositivo: featureData?.pepPositivo,
      aporteOrigemRecursos: aporteOrigemRecursos.get().value,
    });

  const abrirSolicitaAporte = () => {
    setConfirmarAporte(true);
  };

  const verificarCamposAporte = async () => {
    const resposta = await validarAporteFetch();

    if (resposta?.entidade?.camposValidos) {
      setConfirmarAporte(false);
      setConfirmarCertificado(true);
    } else {
      featureData?.etapaAnterior?.();
    }
  };

  const concluirSolicitacaoAporte = async (): Promise<void> => {
    setConfirmarCertificado(false);
    setConfirmarAporte(true);

    const result = await efetuarAporteAdicional(requestAporte);

    if (result?.entidade) {
      setFeatureData({
        ...featureData,
        dadosAporteAdicional: result,
        isFetching: {
          loadingDadosAporteAdicional,
        },
      });
      featureData?.proximaEtapa?.();
      toastSuccess(CONST.COMPROVANTE_APORTE_LABELS.OPERACAO_REALIZADA);
    } else {
      featureData?.proximaEtapa?.();
    }
  };

  const dadosPagamento = FACTORY.dadosGerarAporte(
    numCertificado,
    aporteFormaPagamento.get().value,
    aporteDadosBancarios.get().value,
  );

  const validationErrors = checkIfSomeItemsAreTrue([
    !!formik.errors.finalCPF,
    !!formik.errors.primeiroNome,
    !formik.values.finalCPF,
    !formik.values.primeiroNome,
  ]);

  const fecharSolicitacaoAporte = useCallback(() => {
    formik.resetForm();
    setConfirmarCertificado(false);
    setConfirmarAporte(false);
  }, []);

  return (
    <>
      <RenderConditional condition={confirmarAporte}>
        <ModalBlur open={confirmarAporte} onClose={fecharSolicitacaoAporte}>
          <>
            <Display>
              <Text variant="headline-06" color="primary">
                Deseja realizar o aporte?
              </Text>
            </Display>

            <Card>
              <Card.Content>
                <InfoGridItemInline
                  icon="user"
                  label="Titular"
                  value={tryGetValueOrDefault(
                    [informacaoPorCertificado?.pessoa?.pessoaFisica?.nome],
                    '',
                  )}
                />
                <Display style={{ margin: 0 }}>
                  <InfoGridItemInline
                    icon="documentPaper"
                    label="Certificado"
                    value={tryGetValueOrDefault(
                      [informacaoPorCertificado?.certificadoNumero],
                      '',
                    )}
                    width="135px"
                  />
                  <InfoGridItemInline
                    icon="documentBack"
                    label="Modalidade / Regime Tributário"
                    value={`${informacaoPorCertificado?.produto?.modalidade} / ${informacaoPorCertificado?.regimeTributario} `}
                  />
                </Display>
                <Display gap="100px" style={{ margin: 0 }}>
                  <InfoGridItemInline
                    icon="financialMoneyBag"
                    label="Valor"
                    value={tryGetValueOrDefault(
                      [dadosPagamento[0].valorAporte],
                      '',
                    )}
                    width="135px"
                  />
                  <InfoGridItemInline
                    icon="financialWallet"
                    label="Forma de Pagamento"
                    value={tryGetValueOrDefault(
                      [requestAporte?.Pagamento?.DescricaoPagamento],
                      '',
                    )}
                  />
                </Display>
                <InputGridItemInline
                  name="primeiroNome"
                  label="Informe o primeiro nome do cliente"
                  placeholder="Primeiro nome"
                  icon="personTicked"
                  value={formik.values.primeiroNome}
                  error={formik.errors.primeiroNome}
                  disabled={checkIfSomeItemsAreTrue([
                    loadingDadosAporteAdicional,
                    validaAporteLoading,
                  ])}
                  handleChange={formik.handleChange}
                />
                <InputGridItemInline
                  name="finalCPF"
                  label="Informe os 4 últimos dígitos do CPF"
                  placeholder="Últimos dígitos"
                  icon="documentTicked"
                  value={formik.values.finalCPF}
                  error={formik.errors.finalCPF}
                  disabled={checkIfSomeItemsAreTrue([
                    loadingDadosAporteAdicional,
                    validaAporteLoading,
                  ])}
                  handleChange={formik.handleChange}
                />
              </Card.Content>
            </Card>
            <Grid>
              <Grid.Item xs={1}>
                <ButtonsEtapasAporte
                  proximaEtapa={verificarCamposAporte}
                  buttonLabel="Confirmar Aporte"
                  loading={loadingDadosAporteAdicional}
                  disabled={checkIfSomeItemsAreTrue([
                    loadingDadosAporteAdicional,
                    validaAporteLoading,
                    validationErrors,
                  ])}
                />
              </Grid.Item>
            </Grid>
          </>
        </ModalBlur>
      </RenderConditional>

      <Text variant="body02-md" color="primary" style={{ marginTop: 20 }}>
        Distribuição:
      </Text>

      <GerarAporteDadosDistribuicao fundosDistribuicao={fundosDistribuicao} />

      <Button variant="primary" onClick={abrirSolicitaAporte}>
        Solicitar Aporte
      </Button>

      <ModalConfirmaCertificado
        descricaoProduto={informacaoPorCertificado?.produto?.descricaoProduto}
        confirmarCertificado={confirmarCertificado}
        cancelConfirmarCertificado={fecharSolicitacaoAporte}
        validaConfirmarCertificado={concluirSolicitacaoAporte}
      />
    </>
  );
};

export default GerarAporte;
