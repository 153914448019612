// @ts-nocheck
import styled from 'styled-components/macro';
import {
  Select as SelectDS,
  Accordion as AccordionDS,
} from '@cvp/design-system/react';

export const Select = styled(SelectDS)(() => ({
  '& input, & div': {
    height: '45px',
  },

  '& label ~ div': {
    lineHeight: '45px',
  },

  '& label': {
    marginBottom: '0.2rem',
  },
}));

export const AccordionItem = styled(AccordionDS.Item)(({ spacingFooter }) => ({
  paddingBottom: spacingFooter ? '0px' : '100px',
}));
