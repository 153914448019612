// @ts-nocheck
import { Button, Text } from '@cvp/design-system/react';

import For from 'main/components/For';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from 'previdencia/features/SolicitacaoResgate/pages/styles';

const ModalMensagensResgate = ({
  openModalAtencaoResgate,
  handleCloseModalAtencaoResgate,
  efetuarResgate,
  isLoadingEfetuarResgate,
}: SOLICITACAO_RESGATE_TYPES.IModalMensagensResgate) => {
  return (
    <S.ModalValoresDetalhadosAliquota
      show={openModalAtencaoResgate}
      onClose={handleCloseModalAtencaoResgate}
    >
      <Text variant="headline-08" color="primary" margin>
        IMPORTANTE
      </Text>

      <For each={CONSTS.MENSAGENS_ATENCAO_RESGATE}>
        {mensagem => (
          <Text
            key={mensagem}
            variant="body03-md"
            color="text"
            align="left"
            style={{ marginBottom: 10 }}
          >
            {mensagem}
          </Text>
        )}
      </For>

      <S.ContainerNav>
        <Button variant="secondary" onClick={handleCloseModalAtencaoResgate}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={efetuarResgate}
          loading={isLoadingEfetuarResgate}
          disabled={isLoadingEfetuarResgate}
        >
          Continuar
        </Button>
      </S.ContainerNav>
    </S.ModalValoresDetalhadosAliquota>
  );
};

export default ModalMensagensResgate;
