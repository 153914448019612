// @ts-nocheck
import React from 'react';
import { Button } from '@cvp/design-system/react';

import { getTernaryResult } from 'main/utils/conditional';
import { FLAGS_FOPS } from 'previdencia/constants/constants';
import { TBotaoAcaoProps } from 'previdencia/features/HistoricoSolicitacoes/types/BotaoAcoes';
import {
  verificarBotaoClicado,
  verificarBotaoDesabilitado,
} from 'previdencia/features/HistoricoSolicitacoes/utils/historicoSolicitacoes';

export const ButtonBaixar: React.FC<TBotaoAcaoProps> = ({
  idRequisicao,
  tipoRequisicao,
  isFetching,
  idRequisicaoRequest,
  setIdRequisicaoRequest,
  setCodRequisicao,
}) => (
  <Button
    variant="primary"
    style={{ margin: '5px 0', boxShadow: 'none' }}
    onClick={() => {
      setIdRequisicaoRequest(idRequisicao);
      setCodRequisicao(
        getTernaryResult(
          tipoRequisicao.includes(FLAGS_FOPS.FOP223),
          tipoRequisicao.replace(FLAGS_FOPS.FOP223, ''),
          tipoRequisicao,
        ),
      );
    }}
    loading={verificarBotaoClicado(
      idRequisicaoRequest,
      idRequisicao,
      isFetching,
    )}
    disabled={verificarBotaoDesabilitado(
      idRequisicaoRequest,
      idRequisicao,
      isFetching,
    )}
  >
    Baixar
  </Button>
);
