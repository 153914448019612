// @ts-nocheck
export const ATUALIZACAO_DADOS_SEGURADO = {
  TITULO: 'Dados do Segurado',
  DESCRICAO: `Altere os dados que estão disponíveis. Caso queira alterar outra
  informação, procure sua Agência da CAIXA.`,
  INFO_CONFIRMACAO:
    'Verifique se os dados estão corretos e clique em CONFIRMAR.',
  INFO_OBRIGATORIO: 'Campos Obrigatórios*',
};

export const CANAL_SERVICO = {
  CODIGO_SOLN: { ID: 1, DESCRICAO: 'AREA_LOGADA' },
  CODIGO_AUTO: { ID: 2, DESCRICAO: 'AUTO_COMPRA' },
  CODIGO_BNEF: {
    ID: 3,
    DESCRICAO: 'COMUNICADO SINISTRO',
  },
  CODIGO_MCVP: {
    ID: 4,
    DESCRICAO: 'MOBILE VIDA E PREVIDENCIA',
  },
  CODIGO_PECO: {
    ID: 5,
    DESCRICAO: 'NOVO PORTAL ECONOMIARIO',
  },
  CODIGO_POPJ: { ID: 6, DESCRICAO: 'PORTAL_PJ' },
  CODIGO_SLCX: {
    ID: 7,
    DESCRICAO: 'SALES CAIXA - PORTAL PJ',
  },
  CODIGO_SALE: { ID: 8, DESCRICAO: 'SALES_FORCE' },
  CODIGO_ISIG: {
    ID: 9,
    DESCRICAO: 'Security Identity Governance Intelligence ( GIA )',
  },
  CODIGO_SIAA: {
    ID: 1,
    DESCRICAO: 'SISTEMA DE AUTORIZACAO E AUTENTICACAO DA GESEG',
  },
};

export const ENDERECO_ELETRONICO = {
  OUTROS: { ID: 6, DESCRICAO: 'OUTROS' },
  WEBSITE: { ID: 5, DESCRICAO: 'WEBSITE' },
  SMS: { ID: 4, DESCRICAO: 'SMS' },
  TWITTER: { ID: 3, DESCRICAO: 'TWITTER' },
  FACEBOOK: { ID: 2, DESCRICAO: 'FACEBOOK' },
  EMAIL: { ID: 1, DESCRICAO: 'EMAIL' },
};

export const TIPO_TELEFONE = {
  CELULAR: { ID: 1, DESCRICAO: 'CELULAR' },
  FAX: { ID: 2, DESCRICAO: 'FAX' },
  COMERCIAL: { ID: 3, DESCRICAO: 'COMERCIAL' },
  RESIDENCIAL: { ID: 4, DESCRICAO: 'RESIDENCIAL' },
};

export const MSG_VALIDACAO_ERRO = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório',
  QTD_MINIMA_CEP: 'Mínimo de 8 dígitos',
  CEP_INVALIDO: 'CEP inválido',
  EMAIL_INVALIDO: 'E-MAIL inválido',
  EMAIL_MAX_CHAR_BILHETE: 'O e-mail deve ter no máximo 80 caracteres',
  EMAIL_MAX_CHAR_INDIVIDUAL_EMPRESARIAL:
    'O e-mail deve ter no máximo 40 caracteres',
};

export const QTD_MAXIMA_DIGITOS_CEP = 8;
