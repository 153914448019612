// @ts-nocheck
export const MENSAGEM_ALTERACAO_COM_SUCESSO =
  'A Data de Vencimento foi atualizada com sucesso. Caso seu certificado já possua parcela gerada, a alteração do dia de vencimento será efetivada em cobrança posterior.';

export const MENSAGEM_ALTERACAO_NAO_SE_APLICA =
  ' Em caso de dúvida, fale agora com um dos nossos Especialistas Online pelo  ';

export const RANGE_DIAS_PERMITIDO = 28;

export const DEBITO_CONTA = 'DEB';
