// @ts-nocheck
import { IFormikValuesAlteracaoValorCapital } from 'seguros/types/AlteracaoValorCapital';

export const TEXTOS_ALTERACAO_VALOR_CAPITAL = {
  TITULO: `Alteração de Capital Segurado`,
  CABECALHO: {
    PARAGRAFO_01: `Aqui você pode solicitar alteração do Capital Segurado do seu seguro.`,
    PARAGRAFO_02: `Sua solicitação será avaliada em até 7 dias úteis pela Seguradora de acordo com as regras (Condições Gerais) do seu seguro. Ao final desta análise você receberá um e-mail informando se a solicitação foi aceita ou recusada. Portanto não deixe de atualizar seus dados cadastrais.`,
    PARAGRAFO_03: `É só optar por um novo valor de Capital Segurado.`,
  },
  FAQ: {
    QUESTAO_01: {
      PERGUNTA: `* Como saber o valor de capital segurado desejado para a cobertura de morte?`,
      PARAGRAFO_01: `Multiplicar a quantidade atual de funcionários pelo capital individual desejado por funcionário.`,
      PARAGRAFO_02: `O capital individual, ou seja, por empregado é de no máximo R$ 100 mil e por empresa (CNPJ) é no máximo de R$ 50 milhões. Se o valor ficar maior que R$ 100 mil/empregado, a indenização, se couber, é limitada a R$ 100 mil conforme, Condições Gerais do produto.`,
    },
    QUESTAO_02: {
      PERGUNTA: `* Em caso de sinistro como saber o valor da indenização?`,
      PARAGRAFO_01: `Com a base de funcionários ativos (documento GFIP e SEFIP), dividir o valor do Capital Total contratado pela quantidade de funcionários ativos na data do evento do sinistro do empregado.`,
    },
  },
  TITULO_SOLICITACAO: `Solicitação da Empresa para Alteração do Capital Segurado Global`,
  TITULO_OPCAO: {
    REDUCAO: `Redução do Capital Segurado`,
    AUMENTO: `Aumento do Capital Segurado`,
  },
  CALCULO_CAPITAL: `* Para calcular o Capital desejado, multiplicar: Quantidade atual de funcionários x Capital Segurado desejado por funcionário (Capital Individual).`,
  INFORMACOES: {
    SUBTITULO: `Informações Importantes:`,
    PARAGRAFO_01: `- Apenas o responsável legal da Empresa pode solicitar a Alteração do Capital Segurado.`,
    PARAGRAFO_02: `- O valor máximo de Capital Individual permitido é de R$ 100.000,00`,
  },
  CAMPOS_OBRIGATORIOS: `* É obrigatório o preenchimento de todos os campos.`,
};

export const INITIAL_STATE_FORMIK: IFormikValuesAlteracaoValorCapital = {
  isReducao: false,
  isAumento: false,
  valorCapitalSeguradoCoberturaMorte: '',
  emailCliente: '',
  localData: '',
  nomeCompletoRepresentanteLegal: '',
  cpfRepresentanteLegal: '',
  emailRepresentanteLegal: '',
};

export const VALIDACAO_CAMPOS = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório',
  EMAIL_INVALIDO: 'E-MAIL inválido',
};
