// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import * as REQUEST_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblRequest';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as PECO_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblUsePeco';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';

const usePlanosTransferenciaInterna =
  (): PECO_TYPES.UsePlanosTransferenciaInterna => {
    const { cliente } = useContext(AppContext);

    const {
      response: dadosOrigem,
      loading: loadingDadosOrigem,
      fetchData: obterDadosOrigem,
    } = usePeco<
      REQUEST_TYPES.IDadosOrigemPayload,
      RESPONSE_TYPES.IDadosOrigemResponse
    >({
      api: { operationPath: PECOS.ObterDadosOrigemTransferenciaVgbl },
      payload: {
        certificado: cliente?.numCertificado,
        codigoEmpresa: ENUM_TYPES.DefaultValue.CODIGO_EMPRESA,
      },
      handleResponse: { throwToastErrorBFF: false },
    });

    return { dadosOrigem, loadingDadosOrigem, obterDadosOrigem };
  };

export default usePlanosTransferenciaInterna;
