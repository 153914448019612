// @ts-nocheck
import { useContext } from 'react';

import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IPayloadObterInformacoes,
  IResponseObterInformacoes,
} from 'previdencia/types/ICertificadoInformacoes';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

export const usePecoObterInformacoesCertificado = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const {
    loading: loadingInfoCertificado,
    response,
    fetchData: fetchInfoCertificado,
  } = usePeco<IPayloadObterInformacoes, IResponseObterInformacoes>({
    api: {
      operationPath: PECOS.ObterInformacoesCertificado,
    },
    payload: {
      cpf: cpfCnpj,
      CodContaOrigem: numCertificado,
    },
    autoFetch: true,
  });

  const retorno = response?.entidade?.retornoCertificado;

  const certificadoOrigem: Partial<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ICertificadoOrigem> =
    {
      ...retorno?.certificadoOrigem,
      qtdMaximaFundos: retorno?.qtdMaximaFundos,
      vlrMinNovoFundo: retorno?.vlrMinNovoFundo,
      vlrMinPermancenciaFundo: retorno?.vlrMinPermancenciaFundo,
      vlrMinTransferencia: retorno?.vlrMinTransferencia,
    };

  return {
    loadingInfoCertificado,
    responseInfoCertificado: response?.entidade,
    certificadoOrigem,
    fetchInfoCertificado,
  };
};
