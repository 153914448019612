// @ts-nocheck
export const COLUNAS_TABELA_COBERTURAS = [
  {
    name: '',
    selector: 'descricao',
  },
  {
    name: '',
    selector: 'valor',
    maxWidth: '200px',
    center: true,
  },
];

export const SEM_DADOS_COBERTURAS =
  'Não há dados de cobertura a serem exibidos.';
