// @ts-nocheck
import { Display, Card, Text, Select, Grid } from '@cvp/design-system/react';

import Icon from 'main/components/Icon';
import CardResumoDeParaBeneficioProtecao from 'previdencia/features/TransferenciaEntreCertificados/components/CardResumoDeParaBeneficioProtecao';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import * as S from './styles';

const CardFluxoCobertura = ({
  cobertura,
  handleOpcaoBeneficio,
  opcaoEscolhida,
  simulacaoRiscoPorBeneficio,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ICardFluxoCoberturaProps) => {
  return (
    <Card style={{ margin: '0px 0px 30px 0px' }}>
      <Card.Content padding={[4, 4, 4]}>
        <S.ContainerPeculio>
          <div>
            <Icon name="peculio" />{' '}
            <span>{cobertura.certificadoOrigem.cobertura}</span>
          </div>

          <Text variant="caption-02" color="secondary" margin>
            {cobertura.certificadoOrigem.status}
          </Text>
        </S.ContainerPeculio>

        <CardResumoDeParaBeneficioProtecao
          dePara="Origem"
          valorContribuicao={cobertura.certificadoOrigem.valorContribuicao}
          valorImportanciaSegurada={cobertura.certificadoOrigem.valorBeneficio}
        />

        <CardResumoDeParaBeneficioProtecao
          dePara="Destino"
          valorContribuicao={cobertura.certificadoDestino.valorContribuicao}
          valorImportanciaSegurada={cobertura.certificadoDestino.valorBeneficio}
        />

        <Grid>
          <Grid.Item xs={1} lg={1 / 3}>
            <Display>
              <Select
                name="beneficioProtecao"
                label="Selecione a opção que deseja manter:"
                placeholder="Escolha uma opção"
                disabled={!!cobertura?.certificadoDestino?.isSelectDisabled}
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) => {
                  const opcao = JSON.parse(
                    value,
                  ) as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IOpcaoBeneficioProtecao;

                  handleOpcaoBeneficio(
                    opcao,
                    cobertura.certificadoOrigem.cobertura,
                  );
                }}
              >
                {CONSTS_TEXT.OPCOES_PROTECAO_MANTER.map(item => (
                  <Select.Item
                    key={item.id}
                    value={JSON.stringify(item)}
                    text={item.value}
                    selected={opcaoEscolhida.opcao?.id === item.id}
                  />
                ))}
              </Select>
            </Display>
          </Grid.Item>
        </Grid>

        <Text variant="body01-sm">
          {CONSTS_TEXT.TEXTOS_BENEFICIO_PROTECAO.INFO_TABUA_ATUARIAL}
        </Text>

        <CardResumoDeParaBeneficioProtecao
          dePara="Demonstrativo Destino"
          tipoBeneficio={
            simulacaoRiscoPorBeneficio?.[cobertura.certificadoOrigem.cobertura]
              ?.desBeneficio
          }
          valorContribuicao={
            simulacaoRiscoPorBeneficio?.[cobertura.certificadoOrigem.cobertura]
              ?.vlrEsperadoContribuicao
          }
          valorImportanciaSegurada={
            simulacaoRiscoPorBeneficio?.[cobertura.certificadoOrigem.cobertura]
              ?.vlrBeneficio
          }
        />
      </Card.Content>
    </Card>
  );
};

export default CardFluxoCobertura;
