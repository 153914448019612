// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS_ASC } from 'registroOcorrenciaASC/config/endpoints';
import * as PECO_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrenciasUsePeco';
import * as REQUEST_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrenciasRequest';
import * as RESPONSE_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrenciasResponse';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';

export const usePecoBuscarEnderecoCep =
  (): PECO_TYPES.IUseBuscarEnderecoCep => {
    const {
      response: dadosEnderecoPeloCep,
      loading: loadingDadosEnderecoPeloCep,
      fetchData: obterEnderecoPeloCep,
    } = usePeco<
      REQUEST_TYPES.IBuscarEnderecoCepPayload,
      RESPONSE_TYPES.IBuscarEnderecoCepResponse
    >({
      api: { operationPath: PECOS_ASC.BuscarEnderecoCep },
    });

    return {
      dadosEnderecoPeloCep,
      loadingDadosEnderecoPeloCep,
      obterEnderecoPeloCep,
    };
  };

export const usePecoConsultarCliente = (): PECO_TYPES.IUseConsultarCliente => {
  const {
    response: dadosConsultaCliente,
    loading: loadingDadosConsultaCliente,
    fetchData: obterDadosConsultaCliente,
  } = usePeco<
    REQUEST_TYPES.IConsultarClientePayload,
    RESPONSE_TYPES.IConsultarClienteResponse
  >({
    api: { operationPath: PECOS_ASC.RegistroOcorrenciaClienteConsultar },
    handleResponse: { throwToastErrorBFF: false },
  });

  return {
    dadosConsultaCliente,
    loadingDadosConsultaCliente,
    obterDadosConsultaCliente,
  };
};

export const usePecoCriarCliente = (): PECO_TYPES.IUseCriarCliente => {
  const {
    response: dadosCriacaoCliente,
    loading: loadingDadosCriacaoCliente,
    fetchData: criarCliente,
  } = usePeco<
    REQUEST_TYPES.ICriarClientePayload,
    RESPONSE_TYPES.ICriarClienteResponse
  >({
    api: { operationPath: PECOS_ASC.RegistroOcorrenciaClienteCriar },
  });

  return {
    dadosCriacaoCliente,
    loadingDadosCriacaoCliente,
    criarCliente,
  };
};

export const usePecoAtualizarCliente = (): PECO_TYPES.IUseAtualizarCliente => {
  const {
    response: dadosAtualizacaoCliente,
    loading: loadingDadosAtualizacaoCliente,
    fetchData: atualizarCliente,
  } = usePeco<
    REQUEST_TYPES.IAtualizarClientePayload,
    RESPONSE_TYPES.IAtualizarClienteResponse
  >({
    api: { operationPath: PECOS_ASC.RegistroOcorrenciaClienteAtualizar },
  });

  return {
    dadosAtualizacaoCliente,
    loadingDadosAtualizacaoCliente,
    atualizarCliente,
  };
};

export const usePecoConsultarDuplicidadeSolicitacao =
  (): PECO_TYPES.IUseConsultarDuplicidadeSolicitacao => {
    const {
      response: dadosSolicitacoesDuplicadas,
      loading: loadingDadosSolicitacoesDuplicadas,
      fetchData: obterSolicitacoesDuplicadas,
    } = usePeco<
      REQUEST_TYPES.IConsultarDuplicidadeSolicitacaoPayload,
      RESPONSE_TYPES.IConsultarDuplicidadeSolicitacaoResponse
    >({
      api: {
        operationPath:
          PECOS_ASC.RegistroOcorrenciaDuplicidadeSolicitacaoConsultar,
      },
      handleResponse: { throwToastErrorBFF: false },
    });

    return {
      dadosSolicitacoesDuplicadas,
      loadingDadosSolicitacoesDuplicadas,
      obterSolicitacoesDuplicadas,
    };
  };

export const usePecoConsultarAssunto = (): PECO_TYPES.IUseConsultarAssunto => {
  const {
    response: dadosConsultaAssunto,
    loading: loadingDadosConsultaAssunto,
    fetchData: obterDadosAssunto,
    setResponse: setDadosConsultaAssunto,
  } = usePeco<
    REQUEST_TYPES.IConsultarAssuntoPayload,
    RESPONSE_TYPES.IConsultarAssuntoResponse
  >({
    api: {
      operationPath: PECOS_ASC.RegistroOcorrenciaAssuntoConsultar,
    },
    payload: {
      origem: ENUM_TYPES.PayloadFixo.ORIGEM,
    },
  });

  return {
    dadosConsultaAssunto,
    loadingDadosConsultaAssunto,
    obterDadosAssunto,
    setDadosConsultaAssunto,
  };
};

export const usePecoConsultarSubAssunto =
  (): PECO_TYPES.IUseConsultarSubAssunto => {
    const {
      response: dadosConsultaSubAssunto,
      loading: loadingDadosConsultaSubAssunto,
      fetchData: obterDadosSubAssunto,
      setResponse: setDadosConsultaSubAssunto,
    } = usePeco<
      REQUEST_TYPES.IConsultarSubAssuntoPayload,
      RESPONSE_TYPES.IConsultarSubAssuntoResponse
    >({
      api: {
        operationPath: PECOS_ASC.RegistroOcorrenciaSubAssuntoConsultar,
      },
      payload: {
        origem: ENUM_TYPES.PayloadFixo.ORIGEM,
      },
    });

    return {
      dadosConsultaSubAssunto,
      loadingDadosConsultaSubAssunto,
      obterDadosSubAssunto,
      setDadosConsultaSubAssunto,
    };
  };

export const usePecoConsultarCamposDinamicos =
  (): PECO_TYPES.IUseConsultarCamposDinamicos => {
    const {
      response: dadosConsultaCamposDinamicos,
      loading: loadingDadosConsultaCamposDinamicos,
      fetchData: obterCamposDinamicos,
    } = usePeco<
      REQUEST_TYPES.IConsultarCamposDinamicosPayload,
      RESPONSE_TYPES.IConsultarCamposDinamicosResponse
    >({
      api: {
        operationPath: PECOS_ASC.RegistroOcorrenciaCamposDinamicosConsultar,
      },
      handleResponse: { throwToastErrorBFF: false },
    });

    return {
      dadosConsultaCamposDinamicos,
      loadingDadosConsultaCamposDinamicos,
      obterCamposDinamicos,
    };
  };

export const usePecoCriarCamposDinamicos =
  (): PECO_TYPES.IUseCriarCamposDinamicos => {
    const {
      response: dadosCriacaoCamposDinamicos,
      loading: loadingDadosCriacaoCamposDinamicos,
      fetchData: criarCamposDinamicos,
    } = usePeco<
      REQUEST_TYPES.ICriarCamposDinamicosPayload,
      RESPONSE_TYPES.ICriarCamposDinamicosResponse
    >({
      api: {
        operationPath: PECOS_ASC.RegistroOcorrenciaCamposDinamicosCriar,
      },
    });

    return {
      dadosCriacaoCamposDinamicos,
      loadingDadosCriacaoCamposDinamicos,
      criarCamposDinamicos,
    };
  };

export const usePecoCriarProtocolo = (): PECO_TYPES.IUseCriarProtocolo => {
  const {
    response: dadosProtocolo,
    loading: loadingDadosProtocolo,
    fetchData: obterProtocolo,
  } = usePeco<
    REQUEST_TYPES.ICriarProtocoloPayload,
    RESPONSE_TYPES.ICriarProtocoloResponse
  >({
    api: {
      operationPath: PECOS_ASC.RegistroOcorrenciaCriarProtocolo,
    },
  });

  return {
    dadosProtocolo,
    loadingDadosProtocolo,
    obterProtocolo,
  };
};

export const usePecoConsultarDadosBICliente =
  (): PECO_TYPES.IUseConsultarDadosBICliente => {
    const {
      response: dadosContratos,
      loading: loadingDadosContratos,
      fetchData: obterContratos,
    } = usePeco<
      REQUEST_TYPES.IConsultarDadosBIClientePayload,
      RESPONSE_TYPES.IConsultarDadosBIClienteResponse[]
    >({
      api: {
        operationPath: PECOS_ASC.ConsultarDadosBICliente,
      },
      handleResponse: { throwToastErrorBFF: false },
    });

    return {
      dadosContratos,
      loadingDadosContratos,
      obterContratos,
    };
  };
