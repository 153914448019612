// @ts-nocheck
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';

export const exibirDescricaoPorTipoCobertura = (
  tipoCobertura: string,
): string => {
  if (tipoCobertura === ENUM_TYPES.DefaultValue.TIPO_COBERTURA_PENSAO) {
    return CONSTS.DESCRICAO_TIPO_COBERTURA.PENSAO;
  }

  if (tipoCobertura === ENUM_TYPES.DefaultValue.TIPO_COBERTURA_PECULIO) {
    return CONSTS.DESCRICAO_TIPO_COBERTURA.PECULIO;
  }

  return '';
};

export const obterFundosAtivos = (
  listaFundos: RESPONSE_TYPES.IObtencaoListaOfertasVgblConjugadoProdutosFundos[],
): number => {
  return listaFundos.reduce(
    (total, fundo) => (fundo.selecionado ? total + 1 : total),
    0,
  );
};

export const habilitarAtualizacaoForm = (
  values: any,
  ...removerCamposNaoObrigatorios: string[]
): boolean => {
  const valuesTemp = { ...values };

  removerCamposNaoObrigatorios.forEach(
    campoNaoObrigatorio => delete valuesTemp[campoNaoObrigatorio],
  );

  return !Object.values(valuesTemp).every(value => value);
};
