// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import * as S from './styles';
import {
  REAJUSTE_ANUAL,
  REAJUSTE_REENQUADRAMENTO,
} from '../../constants/constants';

const CardReajusteEReenquadramento: React.FC = () => {
  return (
    <>
      <S.WrapperInfo>
        <S.ResgateInfoContent>
          <Text variant="headline-08" color="primary">
            O que é Reajuste e Reenquadramento?
          </Text>
          <S.WrapperSection>
            <S.Section>
              <Text variant="body01-lg" color="text" margin>
                <strong>Reajuste</strong>
              </Text>
              <S.WrapperContentInfo>
                <S.TextInfo>Acontece em:</S.TextInfo>
                <Text variant="body02-sm" color="text-light">
                  Todos os planos.
                </Text>
              </S.WrapperContentInfo>

              <S.WrapperContentInfo>
                <S.TextInfo>Quando:</S.TextInfo>
                <Text variant="body02-sm" color="text-light">
                  {REAJUSTE_ANUAL.QUANDO}
                </Text>
              </S.WrapperContentInfo>

              <S.WrapperContentInfo>
                <S.TextInfo>O que atualiza:</S.TextInfo>
                <Text variant="body02-sm" color="text-light">
                  {REAJUSTE_REENQUADRAMENTO.O_QUE_ATUALIZA}
                </Text>
              </S.WrapperContentInfo>

              <S.WrapperContentInfo>
                <S.TextInfo>Como atualiza:</S.TextInfo>
                <Text variant="body02-sm" color="text-light">
                  {REAJUSTE_REENQUADRAMENTO.COMO_ATUALIZA}
                </Text>
              </S.WrapperContentInfo>
            </S.Section>

            <S.Section isBorderLeft>
              <Text variant="body01-lg" color="text" margin>
                <strong>Reenquadramento</strong>
              </Text>
              <S.WrapperContentInfo>
                <S.TextInfo>Acontece em:</S.TextInfo>
                <Text variant="body02-sm" color="text-light">
                  Planos com Cuidado Extra.
                </Text>
              </S.WrapperContentInfo>

              <S.WrapperContentInfo>
                <S.TextInfo maxWidth={100}>Quando:</S.TextInfo>
                <Text variant="body02-sm" color="text-light">
                  {REAJUSTE_REENQUADRAMENTO.QUANDO_CUIDADO_EXTRA}
                </Text>
              </S.WrapperContentInfo>

              <S.WrapperContentInfo>
                <S.TextInfo maxWidth={80} marginRight={1}>
                  O que atualiza:
                </S.TextInfo>
                <Text variant="body02-sm" color="text-light">
                  O valor que você paga para o Cuidado Extra.
                </Text>
              </S.WrapperContentInfo>

              <S.WrapperContentInfo>
                <S.TextInfo marginRight={1}>Como atualiza:</S.TextInfo>
                <Text variant="body02-sm" color="text-light">
                  {REAJUSTE_REENQUADRAMENTO.COMO_ATUALIZA_CUIDADO_EXTRA}
                </Text>
              </S.WrapperContentInfo>
            </S.Section>
          </S.WrapperSection>
        </S.ResgateInfoContent>
      </S.WrapperInfo>
    </>
  );
};

export default CardReajusteEReenquadramento;
