// @ts-nocheck
import { obterEndpointConsultarHistoricoSolicitacoes } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseHistoricoSolicitacoes } from '../types/HistoricoSolicitacoes';

export const obterHistoricoSolicitacoes = async (
  cpfCnpj: string,
  numeroCertificado: string,
  dataInicial: string,
  dataFinal: string,
): Promise<ResponseHistoricoSolicitacoes[] | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponseHistoricoSolicitacoes[] | undefined>
  >(obterEndpointConsultarHistoricoSolicitacoes(), {
    cpfCnpj,
    numeroCertificado,
    dataInicial,
    dataFinal,
  });
  return data?.dados?.entidade;
};
