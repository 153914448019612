// @ts-nocheck
import React from 'react';
import {
  Button,
  Display,
  Grid,
  TextField,
  Select,
} from '@cvp/design-system/react';

import masks from 'main/utils/masks';
import { showErrorInput } from 'main/utils/formik';
import * as CONSULTAR_DPS_TYPES from 'contratosPrestamista/features/reenvioDpsEletronica/types/ConsultarDps';
import * as CONSTS from 'contratosPrestamista/features/reenvioDpsEletronica/constants/constants';

const FormAtualizacaoDados = ({
  formik,
  handleSubmitReenviarLink,
  serviceReenviarLinkDps,
}: CONSULTAR_DPS_TYPES.IFormAtualizacaoDadosProps) => {
  return (
    <>
      <Grid>
        <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
          <TextField
            {...formik.getFieldProps(formik.values.cpf)}
            label="CPF*"
            name="cpf"
            value={masks.cpf.mask(formik.values.cpf)}
            {...showErrorInput(formik, 'cpf')}
          />
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
          <TextField
            {...formik.getFieldProps(formik.values.nome)}
            label="Nome*"
            name="nome"
            value={formik.values.nome}
            {...showErrorInput(formik, 'nome')}
          />
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
          <TextField
            {...formik.getFieldProps(formik.values.dataNascimento)}
            label="Data de Nascimento*"
            name="dataNascimento"
            value={masks.date.mask(formik.values.dataNascimento)}
            {...showErrorInput(formik, 'dataNascimento')}
          />
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
          <Select
            style={{ textAlign: 'left' }}
            {...formik.getFieldProps(formik.values.sexo)}
            label="Sexo*"
            placeholder="Escolha uma opção"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('sexo', value);
            }}
            {...showErrorInput(formik, 'sexo')}
          >
            {CONSTS.TIPOS_SEXO.map(item => (
              <Select.Item
                key={item.id}
                value={item.id}
                text={item.sexo}
                selected={formik.values.sexo === item.id}
              />
            ))}
          </Select>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
          <TextField
            {...formik.getFieldProps(formik.values.telefone)}
            label="Telefone*"
            name="telefone"
            value={masks.phone.mask(formik.values.telefone)}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('telefone', masks.phone.unmask(value));
            }}
            {...showErrorInput(formik, 'telefone')}
          />
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
          <TextField
            {...formik.getFieldProps(formik.values.email)}
            label="E-mail*"
            name="email"
            value={formik.values.email}
            {...showErrorInput(formik, 'email')}
          />
        </Grid.Item>
      </Grid>

      <Display>
        <Button
          variant="primary"
          onClick={handleSubmitReenviarLink}
          disabled={Object.keys(formik.errors).length > 0}
          loading={serviceReenviarLinkDps.loadingReenviarLink}
        >
          Solicitar DPS
        </Button>
      </Display>
    </>
  );
};

export default FormAtualizacaoDados;
