// @ts-nocheck
const defaultMinWidth = {
  minWidth: '120px',
};

export const columns = [
  {
    id: '1',
    name: 'Certificado',
    selector: 'contaId',
    minWidth: '125px',
  },
  {
    id: '2',
    name: 'Reserva',
    selector: 'saldoTotal',
    minWidth: '160px',
  },
  {
    id: '3',
    name: 'Fundo',
    selector: 'descricaoCompletaFundo',
    minWidth: '150px',
  },
  {
    id: '4',
    name: '% da Carteira',
    selector: 'percentualSaldo',
    minWidth: '85px',
  },
  {
    id: '5',
    name: 'Rentabilidade 12 meses',
    selector: 'lucratividadeUltimos12Meses',
    ...defaultMinWidth,
  },
  {
    id: '6',
    name: 'Volatilidade 12 meses',
    selector: 'flutuacaoUltimos12Meses',
    ...defaultMinWidth,
  },
  {
    id: '7',
    name: 'Tx. de Adm.',
    selector: 'taxaAdministrativa',
    minWidth: '50px',
  },
  {
    id: '8',
    name: 'Modalidade e Tributação',
    selector: 'modalidadeTributacao',
    minWidth: '115px',
  },
  {
    id: '9',
    name: 'Regulamento e Lâmina',
    selector: 'documentos',
    minWidth: '150px',
    center: true,
  },
];
