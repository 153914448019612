// @ts-nocheck
export enum StateError {
  ERRO_GI = 1,
  ERRO_BFF = 2,
  ERRO_BFF_COM_MSG_ERRO_NEGOCIO = 3,
}

export interface INotificationErrorConfig {
  key: StateError;
  conditions: boolean[];
  throwNotification: () => void;
  enable: boolean;
}

export interface INotificationErrorResult {
  hasError: boolean;
  errorResult?: INotificationErrorConfig;
}
