// @ts-nocheck
import {
  Card,
  Display,
  Divider,
  Grid,
  Skeleton,
  Tabs,
} from '@cvp/design-system/react';
import * as S from 'consultaCliente/features/listaCardProduto/components/ConsultaResultadoSkeleton/styles';

export const ConsultaResultadoSkeleton = () => {
  return (
    <Display
      justify="center"
      alignItems="center"
      type="display-block"
      style={{ marginBottom: '3.5rem' }}
    >
      <Tabs activeTab={0} aria-label="abas de produtos">
        {['Previdência', 'Seguros', 'Prestamista'].map(tabItem => (
          <Tabs.Item title={tabItem} key={tabItem}>
            <Grid>
              {[1, 2, 3].map(cardItem => (
                <Grid.Item key={cardItem} xs={1} lg={1 / 4}>
                  <Card>
                    <S.ContainerSkeleton>
                      <Card.Content padding={[1, 2, 0]}>
                        <Display alignItems="center">
                          <S.TituloSkeleton>
                            <Skeleton lines={1} />
                          </S.TituloSkeleton>
                        </Display>
                        <Display justify="end">
                          <S.StatusContatoSkeleton>
                            <Skeleton lines={1} />
                          </S.StatusContatoSkeleton>
                        </Display>
                      </Card.Content>
                      <Divider />
                      <Card.Content padding={[1, 2, 0]}>
                        {[1, 2, 3, 4].map(linha => (
                          <Display alignItems="center" key={linha}>
                            <S.ConteudoSkeleton>
                              <Skeleton lines={1} />
                            </S.ConteudoSkeleton>
                          </Display>
                        ))}
                      </Card.Content>
                      <Card.Content padding={[1, 4, 0]}>
                        <Display justify="space-between">
                          <S.ButtonSkeleton
                            fullWidth
                            small
                            disabled
                            variant="text"
                          />
                        </Display>
                      </Card.Content>
                    </S.ContainerSkeleton>
                  </Card>
                </Grid.Item>
              ))}
            </Grid>
          </Tabs.Item>
        ))}
      </Tabs>
    </Display>
  );
};
