// @ts-nocheck
import { useEffect } from 'react';
import { format } from 'date-fns';
import { useToast } from 'main/hooks/useToast';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as CONSTS from 'painelAdministracao/constants/constants';
import { EnumIndicadorUso } from 'painelAdministracao/constants/enum';
import { useSalvarArquivo } from 'painelAdministracao/hooks/useSalvarArquivo';
import { IUseHandleAtualizarFop } from 'painelAdministracao/types/IEditarFop';

export const useHandleAtualizarFop: IUseHandleAtualizarFop = (
  formik,
  fopEditar,
  documentoEditar,
  handleFecharModal,
) => {
  const { toastError, toastSuccess } = useToast();
  const { response, loading, fetchData } = useSalvarArquivo();

  const atualizarFop = () => {
    if (
      checkIfAllItemsAreTrue([
        !formik.values?.novoArquivo?.size,
        formik.values.ativo,
      ])
    ) {
      toastError('Selecione o arquivo');
      return;
    }
    const obterCodigo = (): string => {
      if (fopEditar?.codigo) {
        return String(fopEditar.codigo);
      }

      return formik.values.codigo;
    };
    const formData = new FormData();
    formData.append('Arquivo', formik.values.novoArquivo as Blob);
    formData.append('Tipo', tryGetValueOrDefault([fopEditar?.tipo], ''));
    formData.append('Codigo', obterCodigo());
    formData.append(
      'IndicadorDeUsoDoArquivo',
      tryGetValueOrDefault(
        [documentoEditar?.indicadorUsoDoArquivo],
        EnumIndicadorUso.DOWNLOAD,
      ),
    );
    formData.append('NumeroVersao', String(formik.values.versao));
    formData.append(
      'NomeFop',
      tryGetValueOrDefault([fopEditar?.nome], formik.values.nome),
    );
    formData.append('Ativo', String(formik.values.ativo));
    if (formik.values.dataVersao instanceof Date)
      formData.append(
        'DataVersao',
        format(formik.values?.dataVersao, 'dd-MM-yyyy'),
      );
    fetchData(formData);
  };

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([!!response?.sucessoGI, !!response?.sucessoBFF])
    ) {
      toastSuccess('Arquivo salvo com sucesso!');
      handleFecharModal();
      formik.setValues(CONSTS.INITIAL_FORMIK_STATE);
    }
  }, [response]);

  return {
    loading,
    atualizarFop,
  };
};
