// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { IResponseComprovanteOperacao } from 'previdencia/types/ResponseComprovante';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { IPayloadComprovante } from 'previdencia/types/ITransferirEntreFundos';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';

const useObterComprovante = (codigoRequisicao: string) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const { featureData } = usePrevidenciaContext<{ numTransferencia: string }>();

  const { loading, response, fetchData } = usePeco<
    IPayloadComprovante,
    IResponseComprovanteOperacao
  >({
    api: { operationPath: PECOS.ObterComprovanteResgate },
    autoFetch: false,
    payload: {
      cpfCnpj,
      NumeroCertificado: numCertificado,
      idRequisicao: featureData?.numTransferencia,
      codigoRequisicao,
    },
  });

  return { loading, response: response?.entidade, fetchData };
};
export default useObterComprovante;
