// @ts-nocheck
import { FormikProps, useFormik } from 'formik';

import { parseDateStringToDate } from 'main/utils';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import useFieldLink from 'main/hooks/useFieldLink';
import { usePecoConsultarEvolucaoPatrimonial } from 'evolucaoPatrimonial/hooks/usePecoEvolucaoPatrimonial';
import * as FACTORY from 'evolucaoPatrimonial/factories/EvolucaoPatrimonialFactory';
import * as UTILS from 'evolucaoPatrimonial/utils/EvolucaoPatrimonial';
import * as CONSTS from 'evolucaoPatrimonial/constants/constants';
import * as EVOLUCAO_PATRIMONIAL_TYPES from 'evolucaoPatrimonial/types/EvolucaoPatrimonial';

const useEvolucaoPatrimonial = () => {
  const defaultDateCalendar = new Date();
  defaultDateCalendar.setDate(
    defaultDateCalendar.getDate() -
      Number(CONSTS.FILTRO_PERIODOS.ULTIMOS_12_MESES),
  );

  const [inputCalendar] = useFieldLink({
    finalDate: new Date(),
    initialDate: defaultDateCalendar,
  } as CalendarData);

  const {
    dadosConsultaEvolucaoPatrimonial,
    fetchDataConsultarEvolucaoPatrimonial,
    loadingConsultaEvolucaoPatrimonial,
  } = usePecoConsultarEvolucaoPatrimonial({
    dataInicial: inputCalendar.get().value.initialDate,
    dataFinal: inputCalendar.get().value.finalDate,
  });

  const dadosConsulta = dadosConsultaEvolucaoPatrimonial?.entidade;

  const formik: FormikProps<EVOLUCAO_PATRIMONIAL_TYPES.IFormikValuesEvolucaoPatrimonial> =
    useFormik<EVOLUCAO_PATRIMONIAL_TYPES.IFormikValuesEvolucaoPatrimonial>({
      enableReinitialize: true,
      initialValues: CONSTS.INITIAL_FORMIK_STATE,
      validateOnMount: true,
      onSubmit: () => undefined,
    });

  const chartData = FACTORY.chartDataFactory({ formik, dadosConsulta });

  const series = FACTORY.seriesChartDataFactory({ chartData });

  const options = FACTORY.optionsChartDataFactory({ formik, chartData });

  const filtrarPeriodoPersonalizado = (): void => {
    fetchDataConsultarEvolucaoPatrimonial({
      dataInicial: parseDateStringToDate(inputCalendar.get().value.initialDate),
      dataFinal: parseDateStringToDate(inputCalendar.get().value.finalDate),
    });
  };

  const filtrarPeriodosPreDefinidos = (): void => {
    const { dataHoje, dataInicial } =
      UTILS.obterRangeDataBaseadoNoPeriodoPreDefinido(formik.values.periodo);

    fetchDataConsultarEvolucaoPatrimonial({
      dataInicial: parseDateStringToDate(dataInicial),
      dataFinal: parseDateStringToDate(dataHoje),
    });
  };

  const filtrarPeriodoAnoAnterior = (): void => {
    const { diaMesInicioAnoAnterior, diaMesFimAnoAnterior } =
      UTILS.obterRangeDataAnoAnterior();

    fetchDataConsultarEvolucaoPatrimonial({
      dataInicial: parseDateStringToDate(diaMesInicioAnoAnterior),
      dataFinal: parseDateStringToDate(diaMesFimAnoAnterior),
    });
  };

  const filtrarPeriodoAnoAtual = (): void => {
    const { diaMesInicioAnoAtual, diaMesFimAnoAtual } =
      UTILS.obterRangeDataAnoAtual();

    fetchDataConsultarEvolucaoPatrimonial({
      dataInicial: parseDateStringToDate(diaMesInicioAnoAtual),
      dataFinal: parseDateStringToDate(diaMesFimAnoAtual),
    });
  };

  const filtrarEvolucaoPatrimonial = (): void => {
    switch (formik.values.periodo) {
      case CONSTS.FILTRO_PERIODOS.PERIODO_PERSONALIZADO:
        filtrarPeriodoPersonalizado();
        break;
      case CONSTS.FILTRO_PERIODOS.ANO_ANTERIOR:
        filtrarPeriodoAnoAnterior();
        break;
      case CONSTS.FILTRO_PERIODOS.ANO_ATUAL:
        filtrarPeriodoAnoAtual();
        break;
      default:
        filtrarPeriodosPreDefinidos();
    }
  };

  return {
    formik,
    inputCalendar,
    filtrarEvolucaoPatrimonial,
    loadingConsultaEvolucaoPatrimonial,
    dadosConsulta,
    options,
    series,
  };
};

export default useEvolucaoPatrimonial;
