// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { useContext } from 'react';
import {
  ResponsePermissaoAssinaturaBeneficiarios,
  IRequestPermissaoAssinaturaBeneficiarios,
} from 'previdencia/features/AlteracaoBeneficiarios/types/PermissaoAssinaturaBeneficiarios';

const useConsultarPermissaoAssinaturaBeneficiario = () => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IRequestPermissaoAssinaturaBeneficiarios,
    ResponsePermissaoAssinaturaBeneficiarios
  >({
    api: { operationPath: PECOS.ConsultarPermissaoAssinaturaBeneficiario },
    autoFetch: true,
    payload: {
      NumeroCertificado: numCertificado,
    },
  });

  return { loading, response, fetchData };
};

export default useConsultarPermissaoAssinaturaBeneficiario;
