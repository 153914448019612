// @ts-nocheck
import { useContext, useEffect, useState } from 'react';

import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  COLUNAS_TABELA_DADOS_ANTIGO_BANCARIOS_CONFIRMACAO,
  COLUNAS_TABELA_DADOS_BANCARIOS_CONFIRMACAO,
} from 'previdencia/constants/constants';
import * as PECO from 'previdencia/features/AlteracaoFormaDadosPagamento/hooks/usePecoAlteracaoFormaDadosPagamento';
import * as FACTORY from 'previdencia/features/AlteracaoFormaDadosPagamento/factories/AlteracaoFormaDadosPagamentoFactory';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

const useConfirmarAlteracao = (
  params: Partial<ALTERACAO_CONTA_TYPES.IConfirmarAlteracaoProps>,
) => {
  const { cliente } = useContext(AppContext);
  const [
    confirmacaoDadosAtualizarFormaPagamento,
    setConfirmacaoDadosAtualizarFormaPagamento,
  ] = useState(false);
  const [confirmacaoDadosNovaConta, setConfirmacaoDadosNovaConta] =
    useState(false);
  const [novosDadosConta, setNovosDadosConta] = useState<
    ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias[] | undefined
  >();
  const [isAtualizacaoDados, setIsAtualizacaoDados] = useState(false);
  const {
    loadingDadosCanalPagamento,
    criarCanalPagamento,
    isSuccessCriarCanalPagamento,
    canalPagamentoId,
  } = PECO.usePecoCriarCanalPagamento({
    idPessoa: tryGetValueOrDefault([params.idPessoa], ''),
    metodoPagamento: tryGetValueOrDefault(
      [params.tipoBeneficio],
      CONSTS.TIPO_CONTRIBUICAO_CREG1,
    ),
    dadosPagamento: params.dadosNovaConta,
    canalId: params.canalId,
  });
  const {
    loadingDadosAtualizacaoFormaPagamento,
    atualizarFormaPagamento,
    isSuccessAtualizarFormaPagamento,
  } = PECO.usePecoAtualizarFormaPagamento({
    tipoContribuicao: CONSTS.TIPO_CONTRIBUICAO_CREG1,
    canalId: tryGetValueOrDefault([params.canalId], canalPagamentoId),
  });

  if (isSuccessAtualizarFormaPagamento) {
    params?.proximo?.();
  }

  const dadosBancarios = FACTORY.dadosBancariosFactory({
    dadosCertificado: params.dadosCertificado,
    cliente,
  });

  const dadosDaConta = FACTORY.dadosContaFactory({
    cliente,
    novosDadosConta,
  });

  const colunas = COLUNAS_TABELA_DADOS_BANCARIOS_CONFIRMACAO(
    cliente?.numCertificado,
  );

  const colunasDadosAntigos = COLUNAS_TABELA_DADOS_ANTIGO_BANCARIOS_CONFIRMACAO(
    cliente?.numCertificado,
  );

  const alterarFormaPagamentoListaBanco = (): void => {
    if (params.canalId) {
      setNovosDadosConta(
        FACTORY.buscarContaPorCanalId(params.dadosListaBancos, params.canalId),
      );
      setIsAtualizacaoDados(true);
    }
  };

  const alterarFormaPagamentoNovaConta = (): void => {
    if (!params.canalId) {
      setNovosDadosConta(
        FACTORY.converterDadosNovaContaParaDadosContasBancarias(
          params.dadosNovaConta,
        ),
      );
      setIsAtualizacaoDados(false);
    }
  };

  useEffect(() => {
    alterarFormaPagamentoListaBanco();

    alterarFormaPagamentoNovaConta();
  }, [params.canalId, params.dadosListaBancos, params.dadosNovaConta]);

  useEffect(() => {
    if (confirmacaoDadosAtualizarFormaPagamento) {
      atualizarFormaPagamento();
    }
  }, [confirmacaoDadosAtualizarFormaPagamento]);

  useEffect(() => {
    if (confirmacaoDadosNovaConta) {
      criarCanalPagamento();
    }
  }, [confirmacaoDadosNovaConta]);

  useEffect(() => {
    if (isSuccessCriarCanalPagamento) {
      atualizarFormaPagamento();
    }
  }, [isSuccessCriarCanalPagamento]);

  return {
    isAtualizacaoDados,
    setConfirmacaoDadosNovaConta,
    novosDadosConta,
    loadingDadosCanalPagamento,
    dadosBancarios,
    dadosDaConta,
    setConfirmacaoDadosAtualizarFormaPagamento,
    loadingDadosAtualizacaoFormaPagamento,
    colunas,
    colunasDadosAntigos,
  };
};

export default useConfirmarAlteracao;
