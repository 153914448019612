// @ts-nocheck
import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';

type TextoInformativoRedistribuirValorProps = {
  valorTransferencia: number;
};

const TextoInformativoRedistribuirValor: React.FC<
  TextoInformativoRedistribuirValorProps
> = ({ valorTransferencia }) => {
  return (
    <>
      <Text variant="body02-sm" margin>
        Você escolheu fazer uma transferência entre fundos de{' '}
        <strong>{formatarValorPadraoBrasileiro(valorTransferencia)} </strong>.
        Distribua seu dinheiro como quiser, de acordo com a regra do produto.
      </Text>
    </>
  );
};

export default TextoInformativoRedistribuirValor;
