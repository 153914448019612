// @ts-nocheck
import React from 'react';
import { Text, Display, Modal, Button } from '@cvp/design-system/react';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';

const ModalDuplicidadeSolicitacao: React.FC<
  REGISTRO_OCORRENCIA_TYPES.ModalDuplicidadeSolicitacaoProps
> = ({ formik, toggleModal }) => {
  return (
    <Modal
      show={formik.values.toggleModalMsgDuplicidadeSolicitacao}
      onClose={toggleModal}
    >
      <Text variant="body02-sm" margin>
        {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.MODAL_MSG_DUPLICIDADE}
      </Text>
      <Display justify="center">
        <Button variant="secondary" onClick={toggleModal}>
          Certo, entendi!
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalDuplicidadeSolicitacao;
