// @ts-nocheck
import React, { useEffect } from 'react';
import { Display, Modal, Button, Text } from '@cvp/design-system/react';

type ModalConfirmarCadastroProspeccaoProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  loadingCadastroProspeccao: boolean;
  loadingUploadProspeccao: boolean;
};
export const ModalConfirmarCadastroProspeccao: React.FC<
  ModalConfirmarCadastroProspeccaoProps
> = ({
  open,
  handleClose,
  handleConfirm,
  loadingCadastroProspeccao,
  loadingUploadProspeccao,
}) => {
  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Modal show={open} onClose={() => handleClose()}>
      {loadingCadastroProspeccao || loadingUploadProspeccao ? (
        <Button variant="text" loading />
      ) : (
        <>
          <Text variant="body02-md" margin>
            Todos os dados preenchidos estão corretos?
          </Text>
          <Text variant="body-medium3">
            Deseja confirmar o cadastro da Pré-Análise?
          </Text>
          <Display justify="center" style={{ marginTop: 20 }}>
            <Button
              variant="secondary"
              onClick={handleClose}
              data-testid="cancelar"
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleConfirm()}
              data-testid="confirmar"
            >
              Confirmar
            </Button>
          </Display>
        </>
      )}
    </Modal>
  );
};
