// @ts-nocheck
import styled from 'styled-components/macro';

export const TextArea = styled.textarea(({ theme }) => ({
  '-webkit-transition': '0.3s',
  transition: ' 0.3s',
  boxSizing: 'border-box',
  border: '1px solid #A4A5A9',
  borderRadius: '4px',
  background: theme.color.neutral['08'],
  height: 'auto',
  outline: 'none',
  width: '100%',
  maxWidth: '100%',
  color: '#414042',
  paddingLeft: '12px',
  paddingRight: '12px',
  fontFamily: 'CAIXA Std',
  fontWeight: ' 400',
  lineHeight: '150%',
  '-webkit-letter-spacing': 'auto',
  '-moz-letter-spacing': 'auto',
  '-ms-letter-spacing': ' auto',
  'letter-spacing': 'auto',
  fontSize: 16,
  minHeight: 56,
}));
