// @ts-nocheck
import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import {
  IPayloadReservasOrigem,
  IResponseTransferenciaEntreFundos,
} from 'previdencia/types/ITransferirEntreFundos';
import * as FundosDestinoApi from '../services/fundosDestino.api';

const useObterFundosDestino = () => {
  const { toastError } = useToast();
  const [fetchingFundosDestino, setFetchingFundosDestino] =
    useState<boolean>(false);
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const [fundosDestino, setFundosDestino] = useState<
    IResponseTransferenciaEntreFundos | undefined
  >();
  const buscarFundosDestino = async (
    fundosRequest: IPayloadReservasOrigem[] | undefined,
  ) => {
    try {
      setFetchingFundosDestino(true);
      const result = await FundosDestinoApi.fundosDestino(
        cpfCnpj,
        numCertificado,
        fundosRequest,
      );
      setFundosDestino(result);
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setFetchingFundosDestino(false);
    }
  };
  return {
    fetchingFundosDestino,
    fundosDestino,
    buscarFundosDestino,
  };
};
export default useObterFundosDestino;
