// @ts-nocheck
export const TITULO = 'Pagamentos';

export const SUB_TEXTO =
  'Veja abaixo a situação dos pagamentos registrados no nosso sistema.';

export const MENSAGEM_LISTA_VAZIA =
  'Não foi encontrada nenhuma parcela para o seguro consultado.';

export const MENSAGEM_IOF = '* No valor do seguro já está incluído o IOF.';

export const PRAZO_MAXIMO_PARA_BOLETOS_ATRASADOS = 10;
