// @ts-nocheck
export enum ENUM_TIPO_FUNDO {
  ORIGEM = 'origem',
  DESTINO = 'destino',
}

export enum ENUM_STATUS_VALIDAR {
  VALIDADO = 'VAL',
  PENDENTE = 'PND',
}
