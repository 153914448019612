// @ts-nocheck
import React from 'react';
import { useHistory } from 'react-router';
import {
  Text,
  Card,
  Accordion,
  Grid,
  Button,
  Display,
} from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { TextArea } from 'main/components/form';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import * as CONSTS from 'registroOcorrenciaASC/features/consultarOcorrencia/constants/constants';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';
import * as OCORRENCIA_UTILS from 'registroOcorrenciaASC/utils/RegistroOcorrenciaASCUtils';
import * as OCORRENCIA_STYLES from 'registroOcorrenciaASC/styles/styles';
import { TEXTOS_UPLOAD } from 'registroOcorrenciaASC/constants/constants';
import { InputFileMultipleFormik } from 'main/components/form/InputFile/InputFIleMultipleFormik';

const CardAdicionarComplemento: React.FC<
  CONSULTA_OCORRENCIA_TYPES.CardAdicionarComplementoProps
> = ({
  formikDetalhesOcorrencia,
  handleDataTextarea,
  adicionarComplemento,
  habilitarBtnAddComplemento,
  exibirBotaoComplementar,
}) => {
  const history = useHistory();

  return (
    <OCORRENCIA_STYLES.CardContainer>
      <Card.Content padding={[6, 8, 6, 8]}>
        <Accordion data-testid="accordionAdicionarComplementoOcorrencia">
          <Accordion.Item title="Adicionar Complemento">
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="body02-sm" data-testid="descricaoAddComplemento">
                  {CONSTS.TEXTOS_ADICIONAR_COMPLEMENTO.DESCRICAO}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Text variant="body02-sm">
                  {CONSTS.TEXTOS_ADICIONAR_COMPLEMENTO.LABEL_TEXTAREA}
                </Text>
                <TextArea
                  {...formikDetalhesOcorrencia.getFieldProps(
                    formikDetalhesOcorrencia.values.conteudoTextarea,
                  )}
                  rows={5}
                  spellCheck
                  maxLength={
                    ENUM_TYPES.DefaultValue
                      .QTD_MAX_CARACTERES_TEXTAREA_REGISTRAR_OCORRENCIA
                  }
                  value={formikDetalhesOcorrencia.values.conteudoTextarea}
                  onChange={handleDataTextarea}
                  {...OCORRENCIA_UTILS.showErrorInput(
                    formikDetalhesOcorrencia,
                    formikDetalhesOcorrencia.values.conteudoTextarea,
                  )}
                />
                <Text variant="body02-sm">
                  Caracteres restantes:{' '}
                  <strong>
                    {
                      formikDetalhesOcorrencia.values
                        .quantidadeCaracteresTextarea
                    }
                  </strong>
                </Text>
              </Grid.Item>

              <Grid.Item xs={1} md={1 / 3}>
                <Text variant="caption-02">
                  {TEXTOS_UPLOAD.EXTENSOES_PERMITIDAS}
                </Text>
                <Text variant="caption-02" margin>
                  Tamanho máximo do arquivo:{' '}
                  {ENUM_TYPES.DefaultValue.TAMANHO_ARQUIVO_UPLOAD.toString()}MB
                </Text>
                <InputFileMultipleFormik
                  formikValue={formikDetalhesOcorrencia.values.arquivoAnexo}
                  setFieldValue={formikDetalhesOcorrencia.setFieldValue}
                  maxSize={7}
                />
              </Grid.Item>

              <Grid.Item xs={1}>
                <Display justify="center">
                  <Button variant="outlined" onClick={history.goBack}>
                    Voltar
                  </Button>

                  <RenderConditional condition={!exibirBotaoComplementar()}>
                    <Button
                      variant="primary"
                      onClick={adicionarComplemento}
                      data-testid="enviarNovoComplemento"
                      disabled={checkIfSomeItemsAreTrue([
                        formikDetalhesOcorrencia.values
                          .quantidadeCaracteresTextarea ===
                          ENUM_TYPES.DefaultValue
                            .QTD_MAX_CARACTERES_TEXTAREA_REGISTRAR_COMPLEMENTO,
                        habilitarBtnAddComplemento,
                      ])}
                      loading={habilitarBtnAddComplemento}
                    >
                      Complementar
                    </Button>
                  </RenderConditional>
                </Display>
              </Grid.Item>
            </Grid>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </OCORRENCIA_STYLES.CardContainer>
  );
};

export default CardAdicionarComplemento;
