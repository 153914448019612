// @ts-nocheck
import styled from 'styled-components/macro';
import { Text } from '@cvp/design-system/react';

export const ContainerInformacoesAdicionaisReserva = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  textAlign: 'center',
  alignItems: 'center',
  position: 'relative',
});

export const TextDescricaoItemContribuicaoReserva = styled(Text)({
  fontSize: '11px',
  margin: '5px 0 5px 0',
});

export const DescricaoContribuicaoReserva = styled.div({
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  margin: 0,
  height: '60px',
});

export const PrimeiroElementoDescricaoContribuicaoReserva = styled.div({
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px',
  padding: 0,
  margin: 0,
});
