// @ts-nocheck
import React from 'react';
import { Grid } from '@cvp/design-system/react';
import * as S from 'previdencia/features/DadosParticipante/components/CheckBoxConsentimentos/styles';
import * as CONSTS from 'previdencia/features/DadosParticipante/constants/constants';
import { CheckBoxConsentimentosProps } from 'previdencia/features/DadosParticipante/types/DadosParticipante';

const CheckBoxConsentimentos: React.FC<CheckBoxConsentimentosProps> = ({
  statusDadosPessoaisOutrosProdutos,
  setStatusDadosPessoaisOutrosProdutos,
  statusDadosPessoaisParceiros,
  setStatusDadosPessoaisParceiros,
}) => {
  return (
    <>
      <Grid.Item xs={1}>
        <S.TextCheckbox
          aria-label={CONSTS.OUTROS_PRODUTOS_CAIXA}
          checked={statusDadosPessoaisOutrosProdutos}
          onChange={() =>
            setStatusDadosPessoaisOutrosProdutos(
              !statusDadosPessoaisOutrosProdutos,
            )
          }
        />
        {CONSTS.OUTROS_PRODUTOS_CAIXA}
      </Grid.Item>
      <Grid.Item xs={1}>
        <S.TextCheckbox
          aria-label={CONSTS.PARCEIROS_CAIXA}
          checked={statusDadosPessoaisParceiros}
          onChange={() =>
            setStatusDadosPessoaisParceiros(!statusDadosPessoaisParceiros)
          }
        />
        {CONSTS.PARCEIROS_CAIXA}
      </Grid.Item>
    </>
  );
};

export default CheckBoxConsentimentos;
