// @ts-nocheck
import { useHistory } from 'react-router-dom';
import * as S from 'seguros/components/DadosSegurado/styles';
import { Card, Text, Grid, Button, Display } from '@cvp/design-system/react';
import * as UTILS from 'seguros/utils/DadosSeguradoUtils';
import * as CONSTS from 'seguros/constants/DadosSegurado';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { TooltipWrapper } from 'main/components/Wrappers';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import { DadosSeguradoDisplay } from 'seguros/types/DadosSeguradoResponse';
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { TIPO_CERTIFICADO } from 'seguros/constants/Seguros';
import RenderConditional from 'main/components/RenderConditional';
import { TOOLTIP_NOME_SOCIAL } from 'main/constants/nomeSocialConstants';
import { useDadosSegurado } from 'seguros/hooks';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { tipoEmailConstants } from 'main/constants/tipoEmail';

const CardDadosSegurado = ({
  cpfCnpj,
  informacoesSegurado,
  loadingDadosSegurado,
  dadosCertificadoSeguro,
}: DadosSeguradoDisplay) => {
  const history = useHistory();

  const { goHomeVida } = useVidaNavigation();

  const { clienteVida, nomeSocial } = useContext(AppContext);

  const obterDataNascimentoPorTipoCertificado = (): string => {
    if (clienteVida.tipoCertificado === TIPO_CERTIFICADO.INDIVIDUAL) {
      return tryGetValueOrDefault(
        [
          dadosCertificadoSeguro?.individual?.proposta?.parametros
            ?.dataNascimento,
        ],
        '',
      );
    }

    if (clienteVida.tipoCertificado === TIPO_CERTIFICADO.BILHETE) {
      return tryGetValueOrDefault(
        [dadosCertificadoSeguro?.bilhete?.contratanteBilhete?.dataNascimento],
        '',
      );
    }

    return '';
  };

  const handleUpdateDadosSegurado = () => {
    history.push({
      pathname: `/cliente/produtos/vida/dados-segurado/atualizacao`,
      state: {
        informacoesSegurado,
        dataNascimentoPorTipoCertificado:
          obterDataNascimentoPorTipoCertificado(),
      },
    });
  };

  const { dadosSegurado } = useDadosSegurado();
  const emailDefault = dadosSegurado?.entidade?.email;
  const objetoEmail = {
    tipoEmail: tipoEmailConstants.DADOS_SEGURADO,
    parametrosEnvio: {
      numeroCertificadoBilhete: String(clienteVida.numCertificado),
      codigoProduto: String(clienteVida.codigoProduto),
    },
  };

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[6, 8, 6, 8]}>
          <S.TitlePage
            variant="headline-05"
            color="primary"
            data-testid="tituloDadosSegurado"
          >
            {UTILS.dynamicInfoInsured({
              cpfCnpj,
              seguradoPF: CONSTS.INFO_DINAMICA_SEGURADO.TITULO_PF,
              seguradoPJ: CONSTS.INFO_DINAMICA_SEGURADO.TITULO_PJ,
            })}
          </S.TitlePage>

          <Grid>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labelCepDadosSegurado"
                >
                  Nome Civil
                </Text>
                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="cepDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      dadosCertificadoSeguro?.dadosGeraisCertificado
                        .nomeSegurado,
                    ],
                    '-',
                  )}
                </Text>
              </S.ContainerData>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <TooltipWrapper
                  left={25}
                  top={-3}
                  tooltip={TOOLTIP_NOME_SOCIAL}
                >
                  <Text
                    variant="caption-02"
                    color="text-light"
                    data-testid="labelCepDadosSegurado"
                  >
                    Nome Social
                  </Text>
                </TooltipWrapper>

                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="cepDadosSegurado"
                >
                  {tryGetValueOrDefault([nomeSocial], '-')}
                </Text>
              </S.ContainerData>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labelCepDadosSegurado"
                >
                  CEP
                </Text>
                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="cepDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      masks.cep.mask(
                        UTILS.checkIfOptionIsDefaultEmpty(
                          informacoesSegurado?.dadosEnderecoSegurado.cep,
                        ),
                      ),
                    ],
                    '-',
                  )}
                  {}
                </Text>
              </S.ContainerData>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labelEnderecoDadosSegurado"
                >
                  Endereço
                </Text>
                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="enderecoDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      UTILS.checkIfOptionIsDefaultEmpty(
                        informacoesSegurado?.dadosEnderecoSegurado.endereco,
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </S.ContainerData>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labelBairroDadosSegurado"
                >
                  Bairro
                </Text>
                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="bairroDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      UTILS.checkIfOptionIsDefaultEmpty(
                        informacoesSegurado?.dadosEnderecoSegurado.bairro,
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </S.ContainerData>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labelCidadeDadosSegurado"
                >
                  Cidade
                </Text>
                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="cidadeDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      UTILS.checkIfOptionIsDefaultEmpty(
                        informacoesSegurado?.dadosEnderecoSegurado.cidade,
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </S.ContainerData>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labelUfDadosSegurado"
                >
                  UF
                </Text>
                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="ufDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      UTILS.checkIfOptionIsDefaultEmpty(
                        informacoesSegurado?.dadosEnderecoSegurado.uf,
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </S.ContainerData>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labelEmailDadosSegurado"
                >
                  E-mail
                </Text>
                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="emailDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      UTILS.checkIfOptionIsDefaultEmpty(
                        informacoesSegurado?.email,
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </S.ContainerData>
            </Grid.Item>
            <RenderConditional
              condition={!!obterDataNascimentoPorTipoCertificado()}
            >
              <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
                <S.ContainerData>
                  <Text
                    variant="caption-02"
                    color="text-light"
                    data-testid="labelDataNascimentoDadosSegurado"
                  >
                    Data de Nascimento
                  </Text>
                  <Text
                    variant="caption-02"
                    color="text"
                    data-testid="dataNascimentoDadosSegurado"
                  >
                    {obterDataNascimentoPorTipoCertificado()}
                  </Text>
                </S.ContainerData>
              </Grid.Item>
            </RenderConditional>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labeldddDadosSegurado"
                >
                  DDD
                  <TooltipWrapper
                    left={10}
                    tooltip="Ambos os números usam o mesmo DDD"
                  />
                </Text>

                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="dddDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      masks.homePhoneNoPrefix.mask(
                        UTILS.checkIfOptionIsDefaultEmpty(
                          informacoesSegurado?.dadosTelefoneSegurado
                            .prefixoTelefone,
                        ),
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </S.ContainerData>
            </Grid.Item>

            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labelTelefoneDadosSegurado"
                >
                  Telefone
                </Text>
                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="telefoneDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      masks.homePhoneNoPrefix.mask(
                        UTILS.checkIfOptionIsDefaultEmpty(
                          informacoesSegurado?.dadosTelefoneSegurado
                            .numeroTelefone,
                        ),
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </S.ContainerData>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.ContainerData>
                <Text
                  variant="caption-02"
                  color="text-light"
                  data-testid="labelCelularDadosSegurado"
                >
                  Celular
                </Text>
                <Text
                  variant="caption-02"
                  color="text"
                  data-testid="celularDadosSegurado"
                >
                  {tryGetValueOrDefault(
                    [
                      masks.movelPhoneNoPrefix.mask(
                        UTILS.checkIfOptionIsDefaultEmpty(
                          informacoesSegurado?.dadosTelefoneSegurado
                            .numeroCelular,
                        ),
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </S.ContainerData>
            </Grid.Item>
          </Grid>

          <S.ContainerButton>
            <Button
              variant="outlined"
              onClick={goHomeVida}
              data-testid="btnVoltarTelaInicialVida"
            >
              Voltar
            </Button>
            <Button
              data-testid="btnAtualizarDadosSegurado"
              onClick={handleUpdateDadosSegurado}
              variant="primary"
              loading={loadingDadosSegurado}
            >
              Atualizar Dados
            </Button>
            <Button variant="secondary" onClick={() => window.print()}>
              Imprimir
            </Button>
            <EmailSenderModal
              objetoEmail={objetoEmail}
              defaultEmail={emailDefault}
              seguro
            />
          </S.ContainerButton>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default CardDadosSegurado;
