// @ts-nocheck
import { riskStatus } from 'previdencia/utils/riskStatus';
import React from 'react';
import * as S from './styles';

type RiskBarProps = {
  risk: number;
};

const RiskBar: React.FC<RiskBarProps> = ({ risk, ...props }) => {
  const { id, title } = riskStatus(risk);
  return (
    <S.RiskBarContainer {...props}>
      <S.RiskProfileLabel>Perfil de risco</S.RiskProfileLabel>
      <S.Bar>
        <S.StatusIndicatorSection risk={risk}>
          <S.TriangleIcon />
        </S.StatusIndicatorSection>
        <S.InvestorTypeLabel level={id}>{title}</S.InvestorTypeLabel>
      </S.Bar>
    </S.RiskBarContainer>
  );
};

export default RiskBar;
