// @ts-nocheck
import React from 'react';
import { Text, Modal, Button, Display } from '@cvp/design-system/react';
import useDistribuicaoEntreFundos from '../../hooks/useDistribuicaoEntreFundos';
import For from 'main/components/For';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IPendencias } from 'previdencia/types/IRevalidarTransferencia';
import { IModalAvisoProps } from '../../types/TransferenciaEntreCertificados';

const ModalAvisoRevalidar: React.FC<IModalAvisoProps> = ({
  openModal,
  handleCloseModal,
  pendenciasRevalidar,
}) => {
  const { definirProximaTela } = useDistribuicaoEntreFundos();
  return (
    <Modal show={openModal} onClose={handleCloseModal}>
      <Text variant="headline-05" color="primary" margin>
        Atenção
      </Text>

      <For each={tryGetValueOrDefault([pendenciasRevalidar], [])}>
        {(item: IPendencias, index: number) => {
          return <p key={index}>{item.descricaoPendencia}</p>;
        }}
      </For>

      <Display justify="space-evenly">
        <Button onClick={handleCloseModal} variant="secondary">
          Cancelar
        </Button>
        <Button onClick={() => definirProximaTela()} variant="primary">
          Confirmar
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalAvisoRevalidar;
