// @ts-nocheck
import React, { useState } from 'react';
import { Grid, Text, Button } from '@cvp/design-system/react';
import { FlexContainer } from 'main/styles/GlobalStyle';
import { Input } from 'main/components/form';
import useFieldLink from 'main/hooks/useFieldLink';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import masks from 'main/utils/masks';
import Select, { SelectItem } from 'main/components/form/Select';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import { validate } from 'main/features/Validation/utils/validateRules';
import Calendar from 'main/components/form/Calendar';
import {
  required,
  validCpf,
  validDate,
} from 'main/features/Validation/validations';
import { AdicionarBeneficiarioProps } from 'previdencia/features/AlteracaoBeneficiarios/types/AlteracaoBeneficiariosProps';
import Modal from 'main/components/Modal';
import { TipoParentesco } from 'previdencia/features/AlteracaoBeneficiarios/types/TipoParentesco';
import * as S from './styles';

const AdicionarBeneficiario: React.FC<AdicionarBeneficiarioProps> = ({
  onChangeAddBeneficiario,
  onHiddenForm,
  beneficiariosAtuais,
}) => {
  const [nomeLink, validateNome] = useFieldLink('');
  const [cpfLink, validateCpf] = useFieldLink('');
  const [inputDateLink, validateInputDate] = useFieldLink({
    initialDate: null,
    finalDate: null,
  } as CalendarData);
  const [sexoLink, validateSexo] = useFieldLink('');
  const [parentescoLink, validateParentesco] = useFieldLink('');
  const [openModalAvisoCPFIguais, setopenModalAvisoCPFIguais] = useState(false);

  const isSelected = (value: string, link: LinkedValue<string>) => {
    return value === link.get().value;
  };

  const onSubmit = () => {
    const isCpfCadastrado = beneficiariosAtuais.find(
      e => e.pessoaFisica.cpfCnpj === cpfLink.get().value,
    );

    if (isCpfCadastrado) {
      setopenModalAvisoCPFIguais(true);

      return;
    }
    onChangeAddBeneficiario({
      pessoaFisica: {
        nome: nomeLink.get().value.toUpperCase(),
        cpfCnpj: cpfLink.get().value,
        dataNascimento: inputDateLink.get().value.initialDate || new Date(),
        genero: sexoLink.get().value,
      },
      percentualDistribuicao: '',
      grauParentescoId: parentescoLink.get().value,
      situacao: 'A',
      descricaoGrauParentesco: parentescoLink.get().value,
      isRemove: false,
      isUpdate: false,
    });

    onHiddenForm();
  };

  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <S.Content>
        <Text variant="headline-06" color="primary" margin>
          Cadastre um novo beneficiário
        </Text>
        <Text variant="body01-md" margin>
          Cadastre um novo beneficiário, preenchendo os campos abaixo:
        </Text>
      </S.Content>

      <S.ContainerForm>
        <Grid alignItems="center">
          <Grid.Item xs={1}>
            <S.WraperLabel>
              <Input
                link={nomeLink}
                validationRules={[required()]}
                label="Nome completo"
              />
            </S.WraperLabel>

            <S.WraperLabel>
              <Input
                link={cpfLink}
                validationRules={[required(), validCpf()]}
                label="CPF"
                inputMask={{
                  mask: value => masks.cpf.mask(value),
                  unmask: value => masks.cpf.unmask(value),
                }}
              />
            </S.WraperLabel>

            <S.WraperLabel>
              <S.Label>Grau de parentesco</S.Label>
              <Select
                link={parentescoLink}
                placeholder="Selecione o grau de parentesco"
                validationRules={[required()]}
              >
                {TipoParentesco.map(item => (
                  <SelectItem
                    key={item.valor}
                    value={item.valor}
                    text={item.label}
                    selected={isSelected(String(item.valor), parentescoLink)}
                  />
                ))}
              </Select>
            </S.WraperLabel>

            <S.WraperLabel>
              <S.Label>Data de Nascimento</S.Label>
              <Calendar
                placeholder="Data de nascimento"
                link={inputDateLink}
                validationRules={[validDate()]}
                maxDate={new Date()}
              />
            </S.WraperLabel>

            <S.WraperLabel>
              <S.Label>Sexo</S.Label>
              <Select
                link={sexoLink}
                placeholder="Selecione o sexo"
                validationRules={[required()]}
              >
                <SelectItem
                  value="M"
                  text="Masculino"
                  selected={isSelected('M', sexoLink)}
                />

                <SelectItem
                  value="F"
                  text="Feminino"
                  selected={isSelected('F', sexoLink)}
                />
              </Select>
            </S.WraperLabel>
            <S.ContainerButtonsForm>
              <Button variant="outlined" onClick={() => onHiddenForm()}>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  const validations = [
                    validateNome,
                    validateCpf,
                    validateInputDate,
                    validateSexo,
                    validateParentesco,
                  ];
                  if (validate(validations)) {
                    onSubmit();
                  }
                }}
              >
                Incluir
              </Button>
            </S.ContainerButtonsForm>
            <Modal
              open={openModalAvisoCPFIguais}
              onClose={() => setopenModalAvisoCPFIguais(false)}
            >
              <Text variant="body02-sm">
                O CPF deste beneficiário já existe para essa cobertura!
              </Text>
            </Modal>
          </Grid.Item>
        </Grid>
      </S.ContainerForm>
    </FlexContainer>
  );
};

export default AdicionarBeneficiario;
