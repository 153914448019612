// @ts-nocheck
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
import ValidateResult from 'main/features/Validation/types/ValidateResult';
import { validate } from 'main/features/Validation/utils/validateRules';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { ResponseFundosPorCertificado } from 'previdencia/features/TransferenciaFundoInvestimento/types/TiposTransferenciaFundoInvestimento.type';
import {
  Fundo,
  QuantidadeMaximaFundosPorTipos,
  TipoInputRefs,
  TipoResgate,
  TipoRetornoValidaFundos,
  valorTotalTabelaFundos,
} from 'previdencia/types/Fundo.type';
import { IPayloadReservasOrigem } from 'previdencia/types/ITransferirEntreFundos';
import {
  SelecionarTransferenciaUtils,
  TipoTransferencia,
  TipoFundo,
  MultifundoPjData,
} from 'previdencia/types/MultifundosPJ';

export const somaFundosSelecionados = (data: Fundo[]): number => {
  return Object.values(data).reduce<number>((acc, current) => {
    if (current.selecionado) {
      return acc + current.valor;
    }
    return acc;
  }, 0);
};

export const validaFundos = (
  inputRedistribuirValorRefs: React.MutableRefObject<
    Record<string, TipoInputRefs>
  >,
  tabelaFundos: Fundo[],
  valorTotal: number | undefined,
  resgate: boolean | undefined,
): TipoRetornoValidaFundos => {
  const validations = Object.values(inputRedistribuirValorRefs.current).map(
    inputRedistribuir => inputRedistribuir?.validate,
  ) as Array<() => ValidateResult>;

  const validationsValid = validations.filter(
    validation => validation !== undefined,
  );

  let fundosTabelaValidada: Fundo[] = [];
  let total = 0;

  if (!validate(validationsValid)) {
    return {
      tabelaAlterada: undefined,
      valoresDiferentes: false,
      mensagemValoresDiferentes: undefined,
    };
  }

  fundosTabelaValidada = tabelaFundos?.map(fundo => {
    if (!fundo?.selecionado) {
      return {
        ...fundo,
        valor: 0,
      };
    }
    return {
      ...fundo,
      valor: inputRedistribuirValorRefs.current[fundo?.codFundo]?.value || 0,
    };
  });

  total = somaFundosSelecionados(fundosTabelaValidada);
  const mensagemErro = `A soma de valores dos fundos devem ser iguais a ${formatarValorPadraoBrasileiro(
    valorTotal,
  )} e você possui ${formatarValorPadraoBrasileiro(total)}`;

  const calculoFinal = +(total - (valorTotal ?? 0)).toFixed(2);

  if (Math.abs(calculoFinal) > 0 && !resgate) {
    return {
      tabelaAlterada: undefined,
      valoresDiferentes: true,
      mensagemValoresDiferentes: mensagemErro,
    };
  }

  return {
    tabelaAlterada: fundosTabelaValidada,
    valoresDiferentes: false,
    mensagemValoresDiferentes: undefined,
  };
};

export const retornaFundosSelecionados = (
  data: Fundo[] | undefined,
): Fundo[] => {
  const resul: Fundo[] = [];

  data?.forEach(e => {
    if (e.selecionado && e.valor !== 0) {
      resul.push(e);
    }
  });

  return resul;
};

export const retornaTipoResgate = (
  data: ResponseFundosPorCertificado,
): TipoResgate => {
  let retornoTipoResgate: TipoResgate = 'somenteTotal';

  const qtdMaximaFundos = Number(data?.retornoCertificado?.qtdMaximaFundos);

  const isMultifundo = qtdMaximaFundos > 1;

  if (isMultifundo) {
    retornoTipoResgate = 'totalEParcial';
  }

  return retornoTipoResgate;
};

export const somarFundosSelecionados = (
  data: valorTotalTabelaFundos[],
): number => {
  let sum = 0;
  data.forEach(e => {
    if (e.selecionado) {
      sum += e.value;
    }
  });
  return sum;
};

export const possuiTipoFundoTotal = (data: Fundo[]): boolean => {
  let retorno = false;

  data.forEach(e => {
    if (e.selecionado) {
      retorno = true;
    }
  });

  return retorno;
};

export const verificarInclusaoFundosTotais = (
  data: Fundo[],
  codFundo: string,
  tipoResgate: TipoResgate | undefined,
): boolean | undefined => {
  let tipoFundoTotal;

  if (tipoResgate === 'somenteUmTotal') {
    data.forEach(e => {
      if (
        e.codFundo === codFundo &&
        possuiTipoFundoTotal(data) &&
        !e.selecionado
      ) {
        tipoFundoTotal = true;
      }
      if (e.codFundo === codFundo && e.isParcial === false && e.selecionado) {
        tipoFundoTotal = false;
      }
    });
  }

  return tipoFundoTotal;
};

export const modificarFundoSelecionado = (fundo: Fundo, id: string): Fundo => {
  if (fundo.codFundo !== id) {
    return { ...fundo };
  }
  if (fundo.selecionado) {
    return { ...fundo, selecionado: !fundo.selecionado, valor: 0 };
  }
  return { ...fundo, selecionado: !fundo.selecionado };
};

export const modificarMultifundoPjSelecionado = (
  fundo: MultifundoPjData,
  id: string,
  selecionar?: boolean,
): MultifundoPjData => {
  if (fundo.codigoFundo !== id) {
    return { ...fundo };
  }

  if (fundo.selecionado) {
    return {
      ...fundo,
      selecionado: !!selecionar,
      valor: 0,
    };
  }

  const resetReservas = fundo?.reservas.map(item => ({
    ...item,
    tipoTransferencia: '',
    valorTransferencia: 0,
    isParcial: false,
  }));

  return { ...fundo, selecionado: !!selecionar, reservas: resetReservas };
};

export const modificarFundoPorTipoTransferencia = (
  fundos: Fundo[],
  tipoTransferencia: string,
  id: string,
  saldo: number,
): Fundo[] => {
  return fundos.map(fundo => {
    if (
      fundo.codFundo === id &&
      tipoTransferencia === TipoTransferencia.TOTAL
    ) {
      return { ...fundo, valor: saldo * 100, isParcial: false };
    }
    if (
      fundo.codFundo === id &&
      tipoTransferencia === TipoTransferencia.PARCIAL
    ) {
      return { ...fundo, valor: 0, isParcial: true };
    }

    return { ...fundo };
  });
};

export const obterIndex = (
  fundos: MultifundoPjData[],
  id: string,
  reserva?: string,
) => {
  const indexFundo = fundos.findIndex(fundo => {
    return fundo.codigoFundo === id;
  });

  const indexReserva = fundos[indexFundo].reservas.findIndex(item => {
    return (
      item.descricaoReserva?.toLocaleLowerCase() ===
      reserva?.toLocaleLowerCase()
    );
  });

  return { indexFundo, indexReserva };
};

export const modificarMultifundoPjPorTipoTransferencia = (
  props: SelecionarTransferenciaUtils,
): MultifundoPjData[] => {
  const { fundos, id, tipoTransferencia, reserva, saldo, valorTransferencia } =
    props;

  const { indexFundo, indexReserva } = obterIndex(fundos, id, reserva);

  if (tipoTransferencia === TipoTransferencia.TOTAL) {
    fundos[indexFundo].reservas[indexReserva] = {
      ...fundos[indexFundo].reservas[indexReserva],
      tipoTransferencia: tryGetValueOrDefault(
        [tipoTransferencia.toString()],
        '',
      ),
      valorTransferencia: saldo,
      isParcial: false,
    };
  }
  if (tipoTransferencia === TipoTransferencia.PARCIAL) {
    fundos[indexFundo].reservas[indexReserva] = {
      ...fundos[indexFundo].reservas[indexReserva],
      tipoTransferencia: tryGetValueOrDefault(
        [tipoTransferencia.toString()],
        '',
      ),
      valorTransferencia,
      isParcial: true,
    };
  }

  return fundos;
};

export const modificarMultifundoPjValorTransferencia = (
  props: SelecionarTransferenciaUtils,
): MultifundoPjData[] => {
  const { fundos, id, reserva, valorTransferencia } = props;

  const { indexFundo, indexReserva } = obterIndex(fundos, id, reserva);

  fundos[indexFundo].reservas[indexReserva] = {
    ...fundos[indexFundo].reservas[indexReserva],
    valorTransferencia,
  };

  return fundos;
};

export const desabilitarFundoEntreTiposDiferentes = (
  fundosSelecionados: Fundo[],
  quantidadeMaximaFundosPorTipos: QuantidadeMaximaFundosPorTipos,
) => {
  const fundosExistenteSelecionados = fundosSelecionados?.filter(
    fundoSelecionado =>
      checkIfAllItemsAreTrue([
        fundoSelecionado?.tipoFundo === TipoFundo.EXISTENTE,
        fundoSelecionado?.selecionado,
      ]),
  ).length;

  const fundosNovosSelecionados = fundosSelecionados?.filter(fundoSelecionado =>
    checkIfAllItemsAreTrue([
      fundoSelecionado?.tipoFundo === TipoFundo.NOVO,
      fundoSelecionado?.selecionado,
    ]),
  ).length;

  const desabilitarFundos = (fund: Fundo) => {
    if (fund.tipoFundo === TipoFundo.EXISTENTE) {
      return checkIfAllItemsAreTrue([
        fundosExistenteSelecionados ===
          quantidadeMaximaFundosPorTipos.existente.quantidade,
        !fund.selecionado,
        fund.tipoFundo === TipoFundo.EXISTENTE,
        true,
      ]);
    }

    if (fund.tipoFundo === TipoFundo.NOVO) {
      return checkIfAllItemsAreTrue([
        fundosNovosSelecionados ===
          quantidadeMaximaFundosPorTipos.novo.quantidade,
        !fund.selecionado,
        fund.tipoFundo === TipoFundo.NOVO,
        true,
      ]);
    }

    return false;
  };

  return fundosSelecionados.map(fundoSelecionado => ({
    ...fundoSelecionado,
    desabilitado: desabilitarFundos(fundoSelecionado),
  }));
};

export const fundosAtivos = (data?: Fundo[]): number => {
  let sum = 0;

  data?.forEach(fundo => {
    if (fundo.selecionado === true) sum += 1;
  });

  return sum;
};

export const obterValorTotalFundosRequest = (
  data: IPayloadReservasOrigem[],
): number => {
  return data?.reduce<number>((acc, current) => {
    return acc + current.valorSolicitado;
  }, 0);
};
