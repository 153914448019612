// @ts-nocheck
import React from 'react';
import { Text, Display } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import * as S from './styles';

const ReajusteIndisponivel: React.FC = () => {
  return (
    <Display type="display-block">
      <S.WrapperMessage>
        <S.WrapperIcom>
          <Icon name="information" />
        </S.WrapperIcom>
        <Text variant="body02-md" color="text">
          Não identificamos reajuste no plano.
        </Text>
      </S.WrapperMessage>
      <Text variant="body02-sm" color="text">
        Caso tenha feito ele aparecerá aqui assim que o sistema identificar as
        alterações.
      </Text>
    </Display>
  );
};

export default ReajusteIndisponivel;
