// @ts-nocheck
import { ValidationError } from 'yup';

export function disableAllContacts<Type>(contacts?: Type[]) {
  if (!contacts?.length) return [] as Type[];
  return contacts.map(item => ({ ...item, principal: false })) as Type[];
}

export const getYupExceptionMessage = (exception: any): string => {
  if (exception instanceof ValidationError) {
    const validationError = exception;
    return validationError.message;
  }
  return 'Campo inválido.';
};
