// @ts-nocheck
import { Button, Card, Display, Grid, Text } from '@cvp/design-system/react';

import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import Table from 'main/components/Table';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import useSolicitacaoResgate from 'previdencia/features/SolicitacaoResgate/hooks/useSolicitacaoResgate';
import useDetalhesAliquota from 'previdencia/features/SolicitacaoResgate/hooks/useDetalhesAliquota';
import ObservacoesCertificado from 'previdencia/features/SolicitacaoResgate/components/ObservacoesCertificado';
import SaldoTotalCertificado from 'previdencia/features/SolicitacaoResgate/components/SaldoTotalCertificado';
import ModalDetalhesAliquota from 'previdencia/features/SolicitacaoResgate/components/ModalDetalhesAliquota/ModalDetalhesAliquota';
import SimularResgate from 'previdencia/features/SolicitacaoResgate/components/SimularResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as S from './styles';

const SolicitacaoResgate = () => {
  const { navigateTo } = usePrevNavigation();

  const {
    dadosListarFundosParaResgate,
    isLoadingDados,
    colunas,
    isExibirObsCertificado,
    mensagensCertificado,
    openModalAlertaContatos,
    dadosContatos,
    fecharAlertaModalContatos,
  } = useSolicitacaoResgate();

  const {
    dadosTabelaFundosAliquota,
    isLoadingDetalhesAliquota,
    isModalOpen,
    exibirAliquotas,
    handleToggleModal,
    handleGetDataModalDetalhesAliquota,
  } = useDetalhesAliquota();

  return (
    <>
      <Display type="display-block">
        <PrevidenciaResumo />

        <FeatureAuthorizer
          requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA]}
          requiredPermissions={[PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE]}
        >
          <Card>
            <Card.Content padding={[4, 4, 4]}>
              <Grid>
                <Grid.Item xs={1}>
                  <Text variant="headline-05" color="primary" margin>
                    Simulação/Solicitação de Resgate
                  </Text>

                  <RenderConditional condition={isLoadingDados}>
                    <SkeletonLoading lines={1} />
                  </RenderConditional>

                  <RenderConditional condition={!isLoadingDados}>
                    <SaldoTotalCertificado />
                  </RenderConditional>
                </Grid.Item>

                <Grid.Item xs={1}>
                  <RenderConditional condition={isLoadingDados}>
                    <SkeletonLoading lines={4} />
                  </RenderConditional>

                  <RenderConditional condition={!isLoadingDados}>
                    <Table
                      noHeader
                      responsive
                      highlightOnHover
                      striped
                      data={tryGetValueOrDefault(
                        [dadosListarFundosParaResgate?.fundosDisponiveis],
                        [],
                      )}
                      columns={colunas}
                      noDataComponent={CONSTS.NO_DATA_TABLE}
                    />
                  </RenderConditional>
                </Grid.Item>

                <Grid.Item xs={1}>
                  <S.ContainerBotaoDetalhesAliquota>
                    <Button
                      variant="secondary"
                      onClick={handleGetDataModalDetalhesAliquota}
                      loading={isLoadingDados}
                      disabled={checkIfSomeItemsAreTrue([
                        isLoadingDados,
                        !dadosListarFundosParaResgate,
                      ])}
                    >
                      Detalhes por alíquota
                    </Button>
                  </S.ContainerBotaoDetalhesAliquota>
                </Grid.Item>

                <RenderConditional condition={isExibirObsCertificado}>
                  <Grid.Item xs={1}>
                    <RenderConditional condition={isLoadingDados}>
                      <SkeletonLoading lines={4} />
                    </RenderConditional>

                    <RenderConditional condition={!isLoadingDados}>
                      <ObservacoesCertificado
                        mensagensCertificado={mensagensCertificado}
                      />
                    </RenderConditional>
                  </Grid.Item>
                </RenderConditional>

                <SimularResgate />
              </Grid>
            </Card.Content>
          </Card>
        </FeatureAuthorizer>
      </Display>

      <ModalAlertaContatos
        open={openModalAlertaContatos}
        onClose={fecharAlertaModalContatos}
        redirect={() => navigateTo('dados-participante')}
        dados={dadosContatos}
      />

      <ModalDetalhesAliquota
        open={isModalOpen}
        handleClose={handleToggleModal}
        isLoadingDetalhesAliquota={isLoadingDetalhesAliquota}
        dadosTabelaFundosAliquota={dadosTabelaFundosAliquota}
        exibirAliquotas={exibirAliquotas}
      />
    </>
  );
};

export default SolicitacaoResgate;
