// @ts-nocheck
import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import * as BeneficioProtecaoApi from '../services/obterMesesCarenciaBeneficioProtecao.api';

const useObterMesesCarenciaBeneficioProtecao = () => {
  const { toastError } = useToast();
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<number>();
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const obterCarenciaBeneficioProtecao = async (
    codigoBeneficio?: number,
    codigoPlano?: number,
  ): Promise<void> => {
    try {
      setLoading(true);
      const dados =
        await BeneficioProtecaoApi.obterMesesCarenciaBeneficioProtecao({
          numeroCertificado: numCertificado,
          codigoBeneficio,
          codigoPlano,
        });

      if (!dados) throw new Error();

      setResponse(dados);
    } catch (error: any) {
      toastError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    obterCarenciaBeneficioProtecao,
  };
};

export default useObterMesesCarenciaBeneficioProtecao;
