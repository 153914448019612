// @ts-nocheck
import { Card, Display, Text, Button } from '@cvp/design-system/react';
import * as S from '../components/styles';
import Icon from 'main/components/Icon';
import useComprovante from '../hooks/useComprovante';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';

export const ComprovanteTransferencia = () => {
  const { fetchData, loading } = useComprovante();

  if (loading) return <CardSkeleton />;
  return (
    <Display type="block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content>
          <Text variant="headline-05" color="primary" margin>
            A tansferência total foi realizada com sucesso!
          </Text>
          <Text variant="caption">
            Caso necessário, consulte no Histórico de Solicitações.
          </Text>

          <S.ComprovanteSection>
            <div>
              <Text variant="caption">
                <Icon name="documentPaper" /> <strong>Comprovante</strong>
              </Text>
            </div>
            <Display alignItems="center" style={{ marginTop: 20 }}>
              <Button onClick={() => fetchData()} small variant="outlined">
                <Icon name="print" />
                Vizualizar ou imprimir
              </Button>
            </Display>
          </S.ComprovanteSection>
        </Card.Content>
      </Card>
    </Display>
  );
};
