// @ts-nocheck
import React, { useEffect } from 'react';
import { Field, FieldProps, Formik } from 'formik';
import { Button, Display, Grid, Select, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { useToast } from 'main/hooks/useToast';
import Alert from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Alert';
import { defaultFormFieldPropsFactory } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/factories/defaultFormFieldPropsFactory';
import { initialFormStateFactory } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/factories/initialStateFactory';
import * as VALIDATION_UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblValidacoes';
import * as UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblUtils';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as S from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/FormCadastrarNovaConta/styles';

const FormCadastrarNovaConta: React.FC<
  PROPS_TYPES.FormCadastrarNovaContaProps
> = ({
  exibirForm,
  loadingInserirDadosBancariosTransfVgbl,
  listaTiposOperacoesBancarias,
  listarDadosBancariosVgbl,
  listaDiasUteis,
  inserirDadosBancarios,
  formularioDadosBancarios,
  setFormularioDadosBancarios,
}) => {
  const { toastSuccess } = useToast();

  const handleFormSubmit = (
    values: VGBL_TYPES.FormCadastrarNovaContaInitialState,
  ): void => {
    setFormularioDadosBancarios(values);
  };

  const handleSubmitDadosBancarios = async (): Promise<void> => {
    if (formularioDadosBancarios) {
      const retornoInsercaoNovosDadosBancarios = await inserirDadosBancarios();
      if (retornoInsercaoNovosDadosBancarios?.entidade) {
        toastSuccess(
          retornoInsercaoNovosDadosBancarios?.mensagens?.[0].descricao,
        );
        listarDadosBancariosVgbl();
        exibirForm(prev => !prev);
      }
    }
  };

  useEffect(() => {
    handleSubmitDadosBancarios();
  }, [formularioDadosBancarios]);

  return (
    <Display type="block">
      <Formik
        initialValues={initialFormStateFactory}
        onSubmit={(values, { resetForm }) => {
          handleFormSubmit(values);
          resetForm();
          setFormularioDadosBancarios(null);
        }}
        validationSchema={
          VALIDATION_UTILS.validationFormCadastrarNovaContaSchema
        }
      >
        {({ handleSubmit, handleBlur, values, setFieldValue }) => (
          <>
            <Text variant="body02-md" margin>
              {CONSTS.TEXTOS_CADASTRAR_CONTA.TITULO}
            </Text>
            <Alert
              icon="warning"
              message={CONSTS.TEXTOS_CADASTRAR_CONTA.MENSAGEM_ALERTA}
            />
            <form onSubmit={handleSubmit}>
              <Grid>
                <Grid.Item xs={1}>
                  <Text variant="body02-md" color="text-light">
                    {CONSTS.TEXTOS_CADASTRAR_CONTA.DADOS_CONTA_CAIXA}
                  </Text>
                </Grid.Item>

                <RenderConditional condition={!!listaTiposOperacoesBancarias}>
                  <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
                    <Field name={CONSTS.TEXTOS_CADASTRAR_CONTA.OPERACAO.NAME}>
                      {({ field, meta }: FieldProps) => (
                        <S.Select
                          margin="0px 8px"
                          {...field}
                          {...defaultFormFieldPropsFactory(
                            meta,
                            CONSTS.TEXTOS_CADASTRAR_CONTA.OPERACAO.LABEL,
                            setFieldValue,
                            CONSTS.TEXTOS_CADASTRAR_CONTA.OPERACAO.NAME,
                            CONSTS.TEXTOS_CADASTRAR_CONTA.OPERACAO.PLACEHOLDER,
                            CONSTS.TEXTOS_CADASTRAR_CONTA.OPERACAO.TEST_ID,
                          )}
                        >
                          {listaTiposOperacoesBancarias?.map(item => (
                            <Select.Item
                              key={item.tipoOperacao}
                              value={item.tipoOperacao}
                              text={item.descricao}
                              selected={
                                values.tipoOperacao === item.tipoOperacao
                              }
                            />
                          ))}
                        </S.Select>
                      )}
                    </Field>
                  </Grid.Item>
                </RenderConditional>

                <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
                  <Field name={CONSTS.TEXTOS_CADASTRAR_CONTA.AGENCIA.NAME}>
                    {({ field, meta }: FieldProps) => (
                      <S.TextField
                        {...field}
                        {...defaultFormFieldPropsFactory(
                          meta,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.AGENCIA.LABEL,
                          setFieldValue,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.AGENCIA.NAME,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.AGENCIA.PLACEHOLDER,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.AGENCIA.TEST_ID,
                          values.agencia,
                        )}
                        onBlur={handleBlur}
                        maxLength={4}
                      />
                    )}
                  </Field>
                </Grid.Item>

                <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
                  <Field name={CONSTS.TEXTOS_CADASTRAR_CONTA.CONTA.NAME}>
                    {({ field, meta }: FieldProps) => (
                      <S.TextField
                        {...field}
                        {...defaultFormFieldPropsFactory(
                          meta,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.CONTA.LABEL,
                          setFieldValue,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.CONTA.NAME,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.CONTA.PLACEHOLDER,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.CONTA.TEST_ID,
                          values.conta,
                        )}
                        onBlur={handleBlur}
                        maxLength={12}
                      />
                    )}
                  </Field>
                </Grid.Item>

                <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
                  <Field name={CONSTS.TEXTOS_CADASTRAR_CONTA.DIGITO.NAME}>
                    {({ field, meta }: FieldProps) => (
                      <S.TextField
                        {...field}
                        {...defaultFormFieldPropsFactory(
                          meta,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.DIGITO.LABEL,
                          setFieldValue,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.DIGITO.NAME,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.DIGITO.PLACEHOLDER,
                          CONSTS.TEXTOS_CADASTRAR_CONTA.DIGITO.TEST_ID,
                          values.digito,
                        )}
                        onBlur={handleBlur}
                        maxLength={1}
                      />
                    )}
                  </Field>
                </Grid.Item>

                <RenderConditional condition={!!listaDiasUteis}>
                  <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
                    <Field
                      name={CONSTS.TEXTOS_CADASTRAR_CONTA.DATA_DEBITO.NAME}
                    >
                      {({ field, meta }: FieldProps) => (
                        <S.Select
                          margin="0px 8px"
                          {...field}
                          {...defaultFormFieldPropsFactory(
                            meta,
                            CONSTS.TEXTOS_CADASTRAR_CONTA.DATA_DEBITO.LABEL,
                            setFieldValue,
                            CONSTS.TEXTOS_CADASTRAR_CONTA.DATA_DEBITO.NAME,
                            CONSTS.TEXTOS_CADASTRAR_CONTA.DATA_DEBITO
                              .PLACEHOLDER,
                            CONSTS.TEXTOS_CADASTRAR_CONTA.DATA_DEBITO.TEST_ID,
                          )}
                          error={meta.error}
                          errorMessage={meta.error}
                        >
                          {listaDiasUteis?.map(item => (
                            <Select.Item
                              key={`diaVencimento${item.diaVencimento}`}
                              value={item.diaVencimento}
                              text={`Dia ${item.diaVencimento}`}
                              selected={
                                values.dataDebito === item.diaVencimento
                              }
                            />
                          ))}
                        </S.Select>
                      )}
                    </Field>
                  </Grid.Item>
                </RenderConditional>
              </Grid>

              <Grid>
                <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
                  <Display>
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={UTILS.habilitarAtualizacaoForm(values)}
                      loading={loadingInserirDadosBancariosTransfVgbl}
                      data-testid="adicionarNovaConta"
                    >
                      Adicionar
                    </Button>
                  </Display>
                </Grid.Item>
              </Grid>
            </form>
          </>
        )}
      </Formik>
    </Display>
  );
};

export default FormCadastrarNovaConta;
