// @ts-nocheck
import { setLocalItem } from 'main/utils/storage';
import { obterVersaoAplicacao } from './storage_keys';

const versionManager = [1, 0, 6];
export const APP_CURRENT_VERSION = versionManager.join('.');

export const registerAppVersion = (version = APP_CURRENT_VERSION) => {
  setLocalItem(obterVersaoAplicacao(), version);
};
