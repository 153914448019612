// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatarData } from 'main/utils';

export const COLUNAS_TABELA_CONSULTA_SINISTRO = [
  {
    name: 'Contrato',
    selector: 'numeroContrato',
    minWidth: '100px',
  },
  {
    name: 'Cobertura',
    selector: 'coberturas',
    minWidth: '280px',
    wrap: true,
  },
  {
    name: 'Causa',
    selector: 'causas',
    minWidth: '280px',
    wrap: true,
  },
  {
    name: 'Data do Sinistro',
    selector: 'dataHoraSinistro',
    cell: ({ dataHoraSinistro }: any) => formatarData(dataHoraSinistro),
    minWidth: '100px',
  },
  {
    name: 'Data da Atualização',
    selector: 'dataHoraAtualizacao',
    cell: ({ dataHoraAtualizacao }: any) => formatarData(dataHoraAtualizacao),
    minWidth: '100px',
  },
];

export const TEXTOS_CONSULTA_SINISTRO = {
  TITULO: 'Consulta de Sinistro',
  TABELA_SEM_DADOS:
    'Não identificamos solicitação de abertura de sinistro neste certificado. Favor, verificar junto ao cliente.',
  OBSERVACAO: `A Caixa Seguros tem um prazo de 30 dias para a conclusão do processo
de análise de documentação complementar ou para pedidos de
reanálise.`,
};
