// @ts-nocheck
import React from 'react';
import { Button, Display, Modal, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import * as UTILS from 'previdencia/features/Aporte/utils/aporteUtils';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const ModalNotificacao: React.FC<APORTE_TYPES.IModalNotificacaoProps> = ({
  tipo,
  titulo,
  open,
  textoAdicional,
  onClose,
}) => {
  return (
    <Modal show={open} onClose={onClose}>
      <RenderConditional condition={titulo !== ''}>
        <Text color="primary" margin>
          {titulo}
        </Text>
      </RenderConditional>
      <Text variant="body02-md" margin>
        {UTILS.mensagemlAlerta(tipo)}
      </Text>
      <RenderConditional condition={textoAdicional !== undefined}>
        <Text variant="body02-md" margin>
          {textoAdicional}
        </Text>
      </RenderConditional>
      <S.ContainerModalAporte>
        <RenderConditional
          condition={tipo === CONSTS.enumTipoMensagemAporte.ORIGEM_DOS_RECURSOS}
        >
          <Display alignItems="center">
            <Button variant="secondary" onClick={onClose}>
              Cancelar
            </Button>

            <Button variant="primary" onClick={onClose}>
              Confirmar
            </Button>
          </Display>
        </RenderConditional>
        <RenderConditional
          condition={checkIfSomeItemsAreTrue([
            tipo === CONSTS.enumTipoMensagemAporte.CONTA_BANCARIA_EXISTENTE,
            tipo === CONSTS.enumTipoMensagemAporte.CONTA_BANCARIA_NOVA,
          ])}
        >
          <Display>
            <Button variant="secondary" onClick={onClose}>
              Ok, entendi.
            </Button>
          </Display>
        </RenderConditional>
      </S.ContainerModalAporte>
    </Modal>
  );
};

export default ModalNotificacao;
