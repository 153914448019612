// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS_SINISTRO } from 'sinistro/config/endpoints';
import { IFormPrevidenciaParams } from 'sinistro/features/statusSinistro/types/FormularioSinistroParams';
import {
  IStatusSinistroPrevidencia,
  IUseStatusSinistro,
} from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { StatusSinistroResponse } from 'sinistro/features/statusSinistro/types/enum';
import { formatarStatusSinistroPrevidenciaDados } from 'sinistro/features/statusSinistro/utils/formatarStatusSinistroPrevidenciaDados';

export const useStatusSinistro = (): IUseStatusSinistro => {
  const {
    response: handleResponseResult,
    loading,
    setResponse,
    fetchData,
  } = usePeco<IFormPrevidenciaParams, IStatusSinistroPrevidencia>({
    api: { operationPath: PECOS_SINISTRO.StatusSinistroPrevidencia },
    handleResponse: {
      throwToastErrorGI: false,
      throwToastErrorBFF: false,
    },
  });

  const response = formatarStatusSinistroPrevidenciaDados(
    handleResponseResult?.entidade?.dados,
  );

  const obterStatusSinistroPrevidencia = (params: IFormPrevidenciaParams) =>
    fetchData({
      ...params,
      codigoEmpresa: StatusSinistroResponse.CODIGO_EMPRESA,
    });

  return {
    loading,
    response,
    setResponse,
    obterStatusSinistroPrevidencia,
  };
};
