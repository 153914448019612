// @ts-nocheck
/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  PropsWithChildren,
} from 'react';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';

const RegistrarOcorrenciaContext =
  React.createContext<REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrencia>(
    {} as REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrencia,
  );

RegistrarOcorrenciaContext.displayName = 'Registrar Ocorrência';

const RegistrarOcorrenciaProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [registrarOcorrenciaData, setRegistrarOcorrenciaData] = useState(
    CONSTS.initialRegistrarOcorrenciaDataState,
  );

  const salvarConsultaCpfCnpj = (
    cpfCnpjCliente: REGISTRO_OCORRENCIA_TYPES.ConsultaCpfCnpjCliente,
  ): void =>
    setRegistrarOcorrenciaData(
      (
        prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
      ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
        ...prevState,
        cpfCnpjCliente,
      }),
    );

  const limparConsultaCpfCnpj = (): void =>
    setRegistrarOcorrenciaData(
      (
        prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
      ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
        ...prevState,
        cpfCnpjCliente:
          CONSTS.initialRegistrarOcorrenciaDataState.cpfCnpjCliente,
      }),
    );

  const salvarDadosCliente = (
    formDadosCliente: REGISTRO_OCORRENCIA_TYPES.FormDadosCliente,
  ): void =>
    setRegistrarOcorrenciaData(
      (
        prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
      ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
        ...prevState,
        formDadosCliente,
      }),
    );

  const limparDadosCliente = (): void =>
    setRegistrarOcorrenciaData(
      (
        prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
      ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
        ...prevState,
        formDadosCliente:
          CONSTS.initialRegistrarOcorrenciaDataState.formDadosCliente,
      }),
    );

  const salvarDadosOcorrencia = (
    formDadosOcorrencia: REGISTRO_OCORRENCIA_TYPES.FormDadosOcorrencia,
  ): void =>
    setRegistrarOcorrenciaData(
      (
        prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
      ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
        ...prevState,
        formDadosOcorrencia,
      }),
    );

  const limparDadosOcorrencia = (): void =>
    setRegistrarOcorrenciaData(
      (
        prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
      ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
        ...prevState,
        formDadosOcorrencia:
          CONSTS.initialRegistrarOcorrenciaDataState.formDadosOcorrencia,
      }),
    );

  const habilitarBotaoEnviarOcorrencia = useCallback((): void => {
    const { formDadosOcorrencia } = registrarOcorrenciaData;

    const qtdTotalCamposDinamicosObrigatorios: number =
      formDadosOcorrencia.listaCamposDinamicos.filter(
        item => item.obrigatorio,
      ).length;

    const qtdTotalCamposDinamicosObrigatoriosPreenchidos: number =
      formDadosOcorrencia.camposDinamicosPreenchidos.filter(
        (item: REGISTRO_OCORRENCIA_TYPES.IDynamicFields) =>
          checkIfAllItemsAreTrue([item.obrigatorio, !!item.valorCampo]),
      ).length;

    if (
      checkIfAllItemsAreTrue([
        !!registrarOcorrenciaData.formDadosOcorrencia.assunto,
        !!registrarOcorrenciaData.formDadosOcorrencia.mensagem,
      ])
    ) {
      setRegistrarOcorrenciaData(
        (
          prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
        ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
          ...prevState,
          formDadosOcorrencia: {
            ...prevState.formDadosOcorrencia,
            botaoRegistroOcorrenciaHabilitado: !checkIfAllItemsAreTrue([
              qtdTotalCamposDinamicosObrigatorios ===
                qtdTotalCamposDinamicosObrigatoriosPreenchidos,
            ]),
          },
        }),
      );
    }
  }, [
    registrarOcorrenciaData.formDadosOcorrencia.assunto,
    registrarOcorrenciaData.formDadosOcorrencia.mensagem,
    registrarOcorrenciaData.formDadosOcorrencia.contratoSelecionado,
    registrarOcorrenciaData.formDadosOcorrencia.camposDinamicosPreenchidos,
    registrarOcorrenciaData.formDadosOcorrencia
      .botaoRegistroOcorrenciaHabilitado,
  ]);

  const salvarDadosAberturaOcorrencia = (
    aberturaOcorrencia: REGISTRO_OCORRENCIA_TYPES.RetornoAberturaOcorrencia,
  ): void =>
    setRegistrarOcorrenciaData(
      (
        prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
      ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
        ...prevState,
        aberturaOcorrencia,
      }),
    );

  const limparOcorrenciaAberta = (): void => {
    setRegistrarOcorrenciaData(CONSTS.initialRegistrarOcorrenciaDataState);
  };

  const toggleFormularioFop = (comunicadoSinistroPrestamista: boolean): void =>
    setRegistrarOcorrenciaData(
      (
        prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
      ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
        ...prevState,
        formFop: {
          ...prevState.formFop,
          comunicadoSinistroPrestamista,
        },
      }),
    );

  const salvarCamposComunicadoSinistoPrestamista = (
    camposComunicadoSinistoPrestamista: REGISTRO_OCORRENCIA_TYPES.CamposComunicadoSinistoPrestamista,
  ): void =>
    setRegistrarOcorrenciaData(
      (
        prevState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData,
      ): REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData => ({
        ...prevState,
        formFop: {
          ...prevState.formFop,
          camposComunicadoSinistoPrestamista,
        },
      }),
    );

  useEffect(habilitarBotaoEnviarOcorrencia, [habilitarBotaoEnviarOcorrencia]);

  return (
    <RegistrarOcorrenciaContext.Provider
      value={{
        ...registrarOcorrenciaData,
        salvarConsultaCpfCnpj,
        limparConsultaCpfCnpj,
        salvarDadosCliente,
        limparDadosCliente,
        salvarDadosOcorrencia,
        limparDadosOcorrencia,
        habilitarBotaoEnviarOcorrencia,
        salvarDadosAberturaOcorrencia,
        limparOcorrenciaAberta,
        toggleFormularioFop,
        salvarCamposComunicadoSinistoPrestamista,
      }}
    >
      {children}
    </RegistrarOcorrenciaContext.Provider>
  );
};

export const useRegistrarOcorrenciaContext = () => {
  return useContext(RegistrarOcorrenciaContext);
};

export default RegistrarOcorrenciaProvider;
