// @ts-nocheck
import { Button, Display, Modal, Text } from '@cvp/design-system/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TextAlign } from '../styles';

type ModalDisponibilidadeProps = {
  open: boolean;
  handleClose: () => void;
};
const ModalDisponibilidade: React.FC<ModalDisponibilidadeProps> = ({
  open,
  handleClose,
}) => {
  const history = useHistory();
  return (
    <Modal show={open} onClose={handleClose}>
      <Text variant="headline-04" margin>
        BOA NOTÍCIA!
      </Text>
      <TextAlign variant="body03-sm">Prezado(a) colega CAIXA,</TextAlign>
      <TextAlign variant="body03-sm">
        A consulta de Status de Sinistro está novamente disponível neste canal.
        <br />
      </TextAlign>
      <Display justify="center">
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
            history.push('/sinistro/status');
          }}
        >
          Consultar Sinistro
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalDisponibilidade;
