// @ts-nocheck
import styled, { createGlobalStyle } from 'styled-components/macro';
import { Container, Button, Text as TextBase } from '@cvp/design-system/react';
import { Link } from 'react-router-dom';
import { ToastContainer as ToastWrapper } from 'react-toastify';

export default createGlobalStyle`
 * {
     margin: 0;
     padding: 0;
     font-family: 'CAIXA Std';
     box-sizing: border-box;
 }


html, body{
    height: 100%;
}

body{
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    box-sizing: border-box;
 }

 #root{
    height: 100%;
}

ul[class^="Selectstyle__List"] {
  max-height: 200px;
  overflow-y: auto;
}

.report-style-class {
	height: 100%;
	margin: 1% auto;
	width: 100%;
}
@media print {
  body {
    padding: 0;
    -webkit-print-color-adjust: exact;
    max-width: 100%;
  }
   @page {
    size: 66% auto;
    margin: 0;
    transform-origin: top left;
    left:0;
    toolbar:0;
    scrollbars:0;
    status:0;
  }
  .rdt_TableCell {
    div {
      white-space: normal !important;
      overflow: auto !important;
      text-overflow: unset !important;
    }
  }
    .rdt_TableCell {
    div {
      white-space: normal !important;
      overflow: auto !important;
      text-overflow: unset !important;
    }
  }
  .hide-print {
    display: none;
  }
  .flex-print {
    flex-direction: column;
  }

}


.show-print {
  display: none;
  @media print {
    display: block;
  }
}
.disableReactDataTableOverflow {
  overflow: initial !important;
}
`;

export const ToastContainer = styled(ToastWrapper)`
  &&&.Toastify__toast-container--top-right {
    top: 6em;
  }
`;

export const PageContainer = styled(Container)(() => ({
  minHeight: '70vh',
  marginBottom: '5vh',
  marginTop: '5vh',
}));

export const TextLabel = styled(TextBase)(({ theme: { font } }) => ({
  '&&': {
    fontWeight: font.weight.lg,
  },
}));

interface DisplayFlex {
  flexDirection?: 'row' | 'column';
  alignItems?: string;
  justifyContent?: string;
}

export const FlexContainer = styled.div<DisplayFlex>(
  ({ flexDirection, alignItems, justifyContent }) => ({
    display: 'flex',
    flexDirection,
    alignItems,
    justifyContent,
  }),
);

export const Linked = styled(Link)(
  ({ theme: { breakpoint, color, font } }) => ({
    '&&': {
      cursor: 'pointer',
      color: color.brandPrimary.dark,
      fontSize: font.size.md,
      [breakpoint.lg()]: {
        maxWidth: '150px',
      },
    },
  }),
);

export const LinkFop = styled(Button)(({ theme: { color } }) => ({
  '&&': {
    color: color.brandPrimary.dark,
    textDecoration: 'underline',
    padding: '10px 0px',
  },
}));

export const TitleSection = styled.h3(({ theme: { color } }) => ({
  '&&': {
    color: color.brandPrimary.dark,
  },
  '&&::after': {
    width: '23px',
    height: '4px',
    content: '',
    position: 'absolute',
    left: 0,
    bottom: '-12px',
    background: '#1c60ab',
  },
}));

export const TextWithEllipsis = styled.div({
  maxWidth: '90%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  overflow: 'hidden',
});

export const TextWarningWithEllipsis = styled(TextWithEllipsis)<{
  warning?: boolean;
}>(({ warning, theme: { color } }) => ({
  maxWidth: '90%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  overflow: 'hidden',
  color: warning ? color.brandSecondary.light : color.line.dark,
}));

export const ContainerGeral = styled.div({
  padding: '10px 0 10px 0',
});

export const ContainerBranco = styled.div(({ theme: { color } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: '5px',
  borderBottom: `1px solid ${color.neutral['04']}`,
  background: '#fff',
}));

export const ContainerCinza = styled.div(({ theme: { color } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: '5px',
  background: `${color.neutral['07']}`,
  borderBottom: `1px solid ${color.neutral['04']}`,
}));

export const ContainerLista = styled.div({
  padding: '30px 0 30px 0',
});

export const ContainerCheckbox = styled.div({
  display: 'flex',
  gap: '7px',
});

export const ButtonLink = styled(Button)`
  min-width: 50px;
  padding: 0px;
`;
