// @ts-nocheck
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import useTableInputFocus from 'main/hooks/useTableInputFocus';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import {
  ResponseFundosDestino,
  TFundosDestinoConsolidado,
} from 'previdencia/types/ITransferirEntreFundos';
import { IPendencias } from 'previdencia/types/IRevalidarTransferencia';
import { useDefinirReservasDestino } from 'previdencia/hooks/Transferencia/useDefinirReservasDestino';
import { useRevalidarTransferencia } from 'previdencia/hooks/Transferencia/useRevalidarTransferencia';
import * as FACTORY from 'previdencia/features/TransferenciaEntreCertificados/factories';
import * as UTILS from 'previdencia/features/TransferenciaEntreCertificados/utils/TransferenciaEntreCertificados';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const useDistribuicaoEntreFundos = () => {
  const { navigateTo } = usePrevNavigation();
  const [erroDefinirReservaDestino, setErroDefinirReservaDestino] = useState<
    string | undefined
  >('');
  const [pendenciasRevalidar, setPendenciasRevalidar] =
    useState<IPendencias[]>();
  const [modalErro, setModalErro] = useState(false);
  const [modalAlerta, setModalAlerta] = useState(false);

  const { featureData, setFeatureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const initialStateFormik = {
    fundosDestino: FACTORY.obterFundosDestinoConsolidadoFactory(featureData),
  };

  const formik =
    useFormik<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IFormikValuesDistribuicaoEntreFundos>(
      {
        enableReinitialize: true,
        initialValues: initialStateFormik,
        validateOnMount: true,
        onSubmit: () => undefined,
      },
    );

  const {
    response: dataDefinirReservaDestino,
    loading,
    fetchData,
  } = useDefinirReservasDestino(
    UTILS.obterFundosDestinoSelecionados(
      formik.values.fundosDestino
        .fundosDestinoConsolidado as ResponseFundosDestino[],
    ),
  );

  const {
    response: dataRevalidarTransferencia,
    loading: loadingRevalidarTransferencia,
    fetchData: revalidarTransferencia,
  } = useRevalidarTransferencia();

  const { selectedTableInputFocus, atribuirParametroInputFocus } =
    useTableInputFocus();
  const isLoading = checkIfSomeItemsAreTrue([
    loading,
    loadingRevalidarTransferencia,
  ]);
  const handleFundoSelecionado = (codigoFundo: string): void => {
    atribuirParametroInputFocus(codigoFundo);

    const listaReservasDestino =
      formik.values.fundosDestino?.fundosDestinoConsolidado?.map(fundo =>
        FACTORY.modificarFundoSelecionadoFactory({
          fundo,
          codigoFundo,
        }),
      ) as TFundosDestinoConsolidado[];

    formik.setValues({
      ...formik.values,
      fundosDestino: {
        ...formik.values.fundosDestino,
        fundosDestinoConsolidado: listaReservasDestino,
      },
    });
  };

  const handleValorTransferencia = (
    codigoFundo: string,
    valorTransferencia: string,
  ): void => {
    const listaReservasDestino =
      formik.values.fundosDestino?.fundosDestinoConsolidado?.map(fundo =>
        FACTORY.distribuirValorTransferenciaFactory({
          fundo,
          codigoFundo,
          valorTransferencia,
        }),
      ) as TFundosDestinoConsolidado[];

    formik.setValues({
      ...formik.values,
      fundosDestino: {
        ...formik.values.fundosDestino,
        fundosDestinoConsolidado: listaReservasDestino,
      },
    });
  };
  const handleCloseModalAlerta = () => {
    setModalAlerta(false);
  };

  const handleCloseModalErro = () => {
    setModalErro(false);
  };
  const textosDistribuicao = CONSTS_TEXT.DISTRIBUICAO_ENTRE_FUNDOS({
    fundosDestino: formik.values.fundosDestino,
  });

  const dadosResumoFundosSelecionados = UTILS.obterResumoFundosSelecionados(
    formik.values.fundosDestino,
  );

  const colunasFundos = CONSTS.COLUNAS_DISTRIBUICAO_ENTRE_FUNDOS({
    selectedTableInputFocus,
    dadosResumoFundosSelecionados,
    handleFundoSelecionado,
    handleValorTransferencia,
    atribuirParametroInputFocus,
  });

  const isFundosSelecionadosSemValorTransferencia =
    formik.values.fundosDestino.fundosDestinoConsolidado?.some(fundo =>
      checkIfAllItemsAreTrue([
        !Number(fundo.valorTransferencia),
        !!fundo.selecionado,
      ]),
    );

  const resultadoCalculo = UTILS.calcularValores(formik, featureData);

  const isNotAvailableNextStepDistribuicaoFundos = checkIfSomeItemsAreTrue([
    resultadoCalculo.calculoTotal !== resultadoCalculo.valorRetirada,
    !!isFundosSelecionadosSemValorTransferencia,
  ]);
  const pendenciasRevalidarTransferencia = tryGetValueOrDefault(
    [dataRevalidarTransferencia?.pendenciasRevalidarTransferencia],
    [],
  );
  const alertasRevalidar = UTILS.obterAlertasRevalidar(
    pendenciasRevalidarTransferencia,
  );

  const manterBeneficioProtecao = checkIfAllItemsAreTrue([
    featureData?.tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.TOTAL,
    featureData?.manterBeneficio === CONSTS.MANTER_BENEFICIO.SIM,
    !!UTILS.isCoberturaValida(
      featureData?.certificadoOrigem?.coberturas?.coberturas,
    ),
  ]);

  const avancarEtapa = (): void => {
    fetchData();
  };

  const salvarEtapasContexto = (): void => {
    setFeatureData({
      ...featureData,
      step: CONSTS_TEXT.NUM_ETAPAS.DISTRIBUICAO_FUNDOS,
    });
  };

  const redirecionarBeneficioProtecao = () => {
    navigateTo(
      `${CONSTS_TEXT.URL_TRANSFERENCIA.BASE}${CONSTS_TEXT.URL_TRANSFERENCIA.BENEFICIO_PROTECAO}`,
    );
  };

  const redirecionarAssinatura = () => {
    navigateTo(
      `${CONSTS_TEXT.URL_TRANSFERENCIA.BASE}${CONSTS_TEXT.URL_TRANSFERENCIA.EFETUAR_ASSINATURA}`,
    );
  };

  const definirProximaTela = () => {
    if (manterBeneficioProtecao) {
      redirecionarBeneficioProtecao();
      return;
    }
    redirecionarAssinatura();
  };
  const validarRedirecionamento = () => {
    if (
      checkIfAllItemsAreTrue([
        !!dataDefinirReservaDestino,
        !!pendenciasRevalidarTransferencia,
        !dataDefinirReservaDestino?.msgErroExcessao,
        pendenciasRevalidarTransferencia.length === 0,
      ])
    ) {
      definirProximaTela();
    }
  };
  useEffect(salvarEtapasContexto, []);

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        !!dataDefinirReservaDestino,
        !!dataDefinirReservaDestino?.msgErroExcessao,
      ])
    ) {
      setErroDefinirReservaDestino(dataDefinirReservaDestino?.msgErroExcessao);
      setModalErro(true);
    }
    if (dataDefinirReservaDestino) {
      revalidarTransferencia();
    }
  }, [dataDefinirReservaDestino]);

  useEffect(salvarEtapasContexto, []);
  useEffect(() => {
    if (alertasRevalidar.length > 0) {
      setModalAlerta(true);
      setPendenciasRevalidar(alertasRevalidar);
    }
    if (pendenciasRevalidarTransferencia.length > 0) {
      setErroDefinirReservaDestino('');
      setModalErro(true);
      setPendenciasRevalidar(pendenciasRevalidarTransferencia);
    }
    validarRedirecionamento();
  }, [dataRevalidarTransferencia]);

  return {
    isLoading,
    erroDefinirReservaDestino,
    pendenciasRevalidar,
    modalErro,
    setModalErro,
    modalAlerta,
    handleCloseModalAlerta,
    handleCloseModalErro,
    definirProximaTela,
    textosDistribuicao,
    formik,
    colunasFundos,
    dadosResumoFundosSelecionados,
    isNotAvailableNextStepDistribuicaoFundos,
    avancarEtapa,
  };
};

export default useDistribuicaoEntreFundos;
