// @ts-nocheck
import React from 'react';
import { Text, Display } from '@cvp/design-system/react';

import {
  buildColumnsHierarquiaVP,
  buildColumnsHierarquiaSN,
  buildColumnsHierarquiaSR,
  buildColumnsHierarquiaSEV,
  TiposVisao,
} from 'painelInadimplencia/constants/constants';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import { RelatorioInadimplenciaProps } from 'painelInadimplencia/types/ConsultaInadimplenciaUsePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TableInadimplencia } from './TableInadimplencia';

const RelatorioHierarquia: React.FC<RelatorioInadimplenciaProps> = ({
  loading,
  data,
  handleConsultar,
}) => {
  const ColumnsTable = (unidade: (unidade: string) => void) => {
    switch (data?.tipoHierarquia) {
      case TiposVisao.SN:
        return buildColumnsHierarquiaSN(
          unidade,
          tryGetValueOrDefault([data?.tipoFilhos], ''),
        );
      case TiposVisao.SR:
        return buildColumnsHierarquiaSR(
          unidade,
          tryGetValueOrDefault([data?.tipoFilhos], ''),
        );
      case TiposVisao.VP:
        return buildColumnsHierarquiaVP(
          unidade,
          tryGetValueOrDefault([data?.tipoFilhos], ''),
        );
      case TiposVisao.SEV:
        return buildColumnsHierarquiaSEV(
          unidade,
          tryGetValueOrDefault([data?.tipoFilhos], ''),
        );
      default:
        return [];
    }
  };

  return (
    <RenderConditional condition={!loading}>
      <Display>
        <TableInadimplencia
          noHeader
          responsive
          striped
          highlightOnHover
          data={tryGetValueOrDefault([data?.dados], [])}
          columns={ColumnsTable((unidade: string) => {
            handleConsultar(unidade);
          })}
          noDataComponent="Não há dados para exibir."
          pagination
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText: 'Items por página',
            rangeSeparatorText: 'de',
          }}
        />
      </Display>
      <RenderConditional condition={!!data?.valorTotalInadimplencia}>
        <Text variant="body02-sm" color="text" margin>
          Total: {formatarValorPadraoBrasileiro(data?.valorTotalInadimplencia)}
        </Text>
      </RenderConditional>
    </RenderConditional>
  );
};

export default RelatorioHierarquia;
