// @ts-nocheck
import { obterEndpointGraficoRentabilidadeCarteira } from 'previdencia/config/endpoints';
import { api } from 'main/services';
import { byteArrayToBase64 } from 'main/utils/converterByteArray';

export const obterGraficoRentabilidade = async (
  cpfCnpj: string,
  tipoGrafico: string,
): Promise<string> => {
  const { data } = await api.post(
    obterEndpointGraficoRentabilidadeCarteira(),
    {
      cpfCnpj,
      tipoGrafico,
    },
    { responseType: 'arraybuffer' },
  );

  const dataChar = String.fromCharCode.apply(
    null,
    Array.from(new Uint8Array(data)),
  );

  if (dataChar.substring(1, 4) !== 'PNG') {
    const dataToJson = JSON.parse(dataChar || '');
    if (!dataToJson?.dados && !dataToJson?.sucesso) {
      throw new Error(dataToJson.mensagem);
    }
    if (
      dataChar.substring(1, 4) !== 'PNG' &&
      dataToJson?.dados?.sucesso === false
    ) {
      throw new Error(
        dataToJson?.dados?.mensagens &&
          dataToJson?.dados?.mensagens[0]?.descricao,
      );
    }
  }

  const byteArray = new Uint8Array(data);
  const base64String = byteArrayToBase64(byteArray);
  return `data:image/jpeg;base64,${base64String}`;
};
