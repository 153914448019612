// @ts-nocheck
import { Button, Card, Display, Divider, Text } from '@cvp/design-system/react';
import EmailSenderModal from 'main/components/EmailSenderModal';
import RenderConditional from 'main/components/RenderConditional';
import Table from 'main/components/Table';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import React from 'react';
import {
  MENSAGEM_IOF,
  MENSAGEM_LISTA_VAZIA,
  SUB_TEXTO,
  TITULO,
} from 'seguros/constants/HistoricoPagamentos';
import { colunasTabelaHistoricoPagamentosFactory } from 'seguros/factories/colunasTabelaHistoricoPagamentosFactory';
import { useImprimirBoletoHistoricoPagamentos } from 'seguros/hooks/useImprimirBoletoHistoricoPagamentos';
import useMontarDadosEnviarEmailHistoricoPagamentos from 'seguros/hooks/useMontarDadosEnviarEmailHistoricoPagamentos';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import { HistoricoPagamentosProps } from 'vida/features/Pagamentos/types/HistoricoPagamentosTypes';

export const HistoricoPagamentos: React.FC<HistoricoPagamentosProps> = ({
  data,
  columns,
}) => {
  const { goDadosSeguradoVida } = useVidaNavigation();
  const { montarObjetoEmail } = useMontarDadosEnviarEmailHistoricoPagamentos();
  const objetoEmail = montarObjetoEmail();

  const { imprimirBoleto, loading } = useImprimirBoletoHistoricoPagamentos();

  const temDados = !!data.length;

  return (
    <Display type="display-block">
      <Card>
        <Card.Content>
          <Text variant="headline-05" color="primary" margin>
            {TITULO}
          </Text>
          <RenderConditional condition={temDados}>
            <Text variant="caption-02" color="text-light" margin>
              {SUB_TEXTO}
            </Text>
            <Table
              noHeader
              responsive
              striped
              columns={tryGetValueOrDefault(
                [columns],
                colunasTabelaHistoricoPagamentosFactory(
                  imprimirBoleto,
                  loading,
                ),
              )}
              data={tryGetValueOrDefault([data], [])}
              noDataComponent={MENSAGEM_LISTA_VAZIA}
              highlightOnHover
              pagination
              paginationPerPage={10}
              paginationComponentOptions={{
                rowsPerPageText: 'Items por página',
                rangeSeparatorText: 'de',
              }}
            />
            <Text variant="caption-02" color="text-light" margin>
              {MENSAGEM_IOF}
            </Text>
          </RenderConditional>
          <RenderConditional condition={!temDados}>
            <Text variant="caption-02" color="text-light" margin>
              {MENSAGEM_LISTA_VAZIA}
            </Text>
          </RenderConditional>
          <Divider />
          <Display>
            <Button
              data-testid="botao-voltar"
              className="hide-print"
              variant="outlined"
              onClick={goDadosSeguradoVida}
            >
              Voltar
            </Button>
            <RenderConditional condition={temDados}>
              <Button
                data-testid="botao-imprimir-paigna"
                className="hide-print"
                variant="secondary"
                onClick={() => window.print()}
              >
                Imprimir
              </Button>
              <EmailSenderModal objetoEmail={objetoEmail} seguro />
            </RenderConditional>
          </Display>
        </Card.Content>
      </Card>
    </Display>
  );
};
