// @ts-nocheck
import { levelRisk } from 'previdencia/utils/riskStatus';
import ColunaSelecionarFundo from '../components/TabelaContribuicaoRegular/ColunaSelecionarFundo';
import {
  TTabelaContribuicaoRegular,
  IDadosTabelaContribuicaoRegularProps,
} from '../types/TableColumns';
import RiskBar from 'previdencia/components/RiskBar';
import { IFundo } from '../types/VGBLListarOfertas';

export const colunasTabelaContribuicaoRegular: TTabelaContribuicaoRegular = ({
  handlers,
}: IDadosTabelaContribuicaoRegularProps) => [
  {
    name: '',
    selector: 'check',
    width: '20px',
    cell: (row: IFundo) => (
      <ColunaSelecionarFundo row={row} handlers={handlers} />
    ),
  },
  {
    name: 'Fundos',
    selector: 'descricaoFundo',
    wrap: true,
  },
  {
    name: 'Perfil de Risco',
    selector: 'perfilRiscoNivel',
    center: true,
    cell: (row: IFundo) => (
      <div style={{ minHeight: 50, minWidth: 150 }}>
        <RiskBar risk={levelRisk(row.perfilRiscoNivel)} />
      </div>
    ),
  },
  {
    name: 'Rentabilidade (Últimos 12 Meses)',
    selector: 'percentualRentabilidadeUltimoAno',
    center: true,
  },
];
