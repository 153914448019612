// @ts-nocheck
import styled from 'styled-components/macro';
import DataTable from 'main/components/Table';
import { Link } from 'react-router-dom';

export const Table = styled(DataTable)(({ theme: { color, font } }) => ({
  '.rdt_TableCol, .rdt_TableCell': {
    fontSize: font.size.sm,
    fontFamily: font.family.base,
  },
  '.rdt_TableCol': {
    fontWeight: font.weight.lg,
    backgroundColor: color.neutral['08'],
    textAlign: 'center',
    '&:hover': {
      color: color.brandPrimary.light,
    },
  },
}));

export const LinkedTable = styled(Link)(({ theme: { color } }) => ({
  '&&': {
    cursor: 'pointer',
    color: color.brandPrimary.dark,
  },
}));

export const TextWithEllipsis = styled.div<{ warning?: boolean }>(
  ({ warning, theme: { color } }) => ({
    maxWidth: '90%',
    whiteSpace: 'break-spaces',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    overflow: 'hidden',
    color: warning ? color.brandSecondary.light : color.line.dark,
  }),
);
