// @ts-nocheck
import React from 'react';
import * as S from 'main/components/Table/styles';
import ReactDataTable, { IDataTableProps } from 'react-data-table-component';
import { IDadosPortabilidadeResponse } from 'painelPortabilidade/types/ConsultarPortabilidadeResponse';

export const TablePortabilidade: React.FC<IDataTableProps> = props => {
  return (
    <S.ReactDataTable>
      <ReactDataTable<IDadosPortabilidadeResponse> {...props} />
    </S.ReactDataTable>
  );
};
