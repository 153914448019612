// @ts-nocheck
import { useContext, useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as FACTORY from 'painelAdministracao/factories/formatarDadosFactory';
import * as CONSTS from 'painelAdministracao/constants/constants';
import * as EDITAR_PROPS from 'painelAdministracao/types/IEditarFop';
import { AdmContext } from 'painelAdministracao/context/AdministracaoContext';
import { validarDataVersao } from 'painelAdministracao/utils/utils';
import { getTernaryResult } from 'main/utils/conditional';

const useEditarFop: EDITAR_PROPS.IUseEditarFop = () => {
  const { fopEditar } = useContext(AdmContext);

  const formik: FormikProps<EDITAR_PROPS.IFormikValuesEditarFop> =
    useFormik<EDITAR_PROPS.IFormikValuesEditarFop>({
      enableReinitialize: true,
      initialValues: CONSTS.INITIAL_FORMIK_STATE,
      validationSchema: FACTORY.FORMIK_VALIDATION,
      validateOnMount: true,
      onSubmit: () => undefined,
    });

  const carregarValores = (): void => {
    formik.setValues({
      ...formik.values,
      dataVersao: validarDataVersao(fopEditar?.dataVersao),
      versao: getTernaryResult(
        !!fopEditar?.numeroVersao,
        String(fopEditar?.numeroVersao),
        '',
      ),
    });
  };

  const handleChangeDataVersao = (data: Date | null): void => {
    formik.setValues({
      ...formik.values,
      dataVersao: data,
    });
  };

  const handleChangeAtivo = (value: boolean): void => {
    formik.setValues({
      ...formik.values,
      ativo: value,
    });
  };

  useEffect(() => {
    carregarValores();
  }, [fopEditar]);

  return {
    formik,
    handleChangeDataVersao,
    handleChangeAtivo,
  };
};

export default useEditarFop;
