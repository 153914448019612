// @ts-nocheck
import React from 'react';
import {
  Display,
  Card,
  Text,
  Grid,
  Divider,
  TextField,
  Button,
  Modal,
  Accordion,
  Select,
} from '@cvp/design-system/react';
import { useToast } from 'main/hooks/useToast';
import { TitleSection, TextLabel } from 'main/styles/GlobalStyle';
import { Field, Formik } from 'formik';
import masks from 'main/utils/masks';
import { useHistory, useLocation } from 'react-router-dom';
import { TextArea } from 'main/components/form';
import {
  selectOptionsFilial,
  selectOptionsRegra,
  selectOptionsBeneficio,
  selectOptionsPrazoBeneficio,
  selectOptionsReversao,
  selectOptionsPagamento,
  selectOptionsValoresParticipantes,
  selectOptionsValorContribuicao,
  selectOptionsCuidadoExtra,
  selectOptionsRegraCuidado,
  selectOptionsRegraCuidadoPensao,
  selectOptionsAnosPensao,
} from 'extranet/types/enum';
import { api } from 'main/services';
import { useFormFops } from 'extranet/hooks/useFormFops';
import FormFopsValidationSchema from 'extranet/utils/FormFopsValidationSchema';
import InputFile, {
  DescriptionFormatsFilesAllowed,
} from 'main/components/form/InputFile';
import { usePeco } from 'main/hooks/usePeco';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { DownloadButton } from 'main/features/prospeccao/components/styles';
import Icon from 'main/components/Icon';
import { ParamsLocationData } from 'extranet/types/IResponseObterListaFopsAtivos';
import * as S from './styles';

const Fop062: React.FC = () => {
  const {
    openSelectTipoBenficio,
    openSelectReversivel,
    openSelectPlanoInstituido,
    openSelectValoresParticipantesCuidado,
    openSelectValorContribuicao,
    openSelectValorContribuicaoCuidadoExtra,
    openSelectValorFixo,
    openSelectValorFixoCuidadoExtra,
    openSelectValorFixoEmpresa,
    openSelectValorFixoEmpresaCuidadoExtra,
    openSelectValorFixoFuncionario,
    openSelectValorPercentualCuidadoExtra,
    openSelectValorFixoFuncionarioCuidadoExtra,
    openSelectValorPercentual,
    openSelectValorPercentualEmpresa,
    openSelectValorPercentualEmpresaCuidadoExtra,
    openSelectValorPercentualFuncionario,
    openSelectValorPercentualFuncionarioCuidadoExtra,
    openSelectOutraFormaPagamento,
    openSelectPlanoInstituidoCuidadoExtra,
    openSelectPrazoBenficio,
    openSelectOutraFormaPagamentoCuidadoExtra,
    openSelectValoresParticipantes,
    openSelectPeculio,
    openSelectPensao,
    openSelectFormaPagamentoCuidado,
    openSelectOutraFormaPagamentoEmpresa,
    openSelectOutraFormaPagamentoEmpresaCuidadoExtra,
    openSelectOutraFormaPagamentoFuncionario,
    openSelectOutraFormaPagamentoFuncionarioCuidadoExtra,
    loadingDownload,
    textOutraFormaPagamento,
    textOutraFormaPagamentoEmpresa,
    textOutraFormaPagamentoEmpresaCuidadoExtra,
    textOutraFormaPagamentoFuncionario,
    textOutraFormaPagamentoFuncionarioCuidadoExtra,
    textOutraFormaPagamentoCuidadoExtra,
    textInformacoesComplementares,
    openModalFormulario,
    arquivoAnexoFop,
    regraFiles,
    baixarArquivoFop,
    openModal,
    fecharModal,
    setTextInformacoesComplementares,
    setTextOutraFormaPagamentoEmpresa,
    setTextOutraFormaPagamentoCuidadoExtra,
    setTextOutraFormaPagamentoFuncionarioCuidadoExtra,
    setTextOutraFormaPagamentoFuncionario,
    setTextOutraFormaPagamentoEmpresaCuidadoExtra,
    setTextOutraFormaPagamento,
    selectRegraCalculo,
    selectFormaPagamento,
    selectFormaPagamentoCuidado,
    selectBeneficio,
    selectValoresParticipantes,
    selectValoresParticipantesCuidado,
    selectValorContribuicao,
    selectValorContribuicaoCuidadoExtra,
    selectCuidadoExtra,
    selectValorContribuicaoEmpresa,
    selectValorContribuicaoEmpresaCuidadoExtra,
    selectValorContribuicaoFuncionario,
    selectValorContribuicaoFuncionarioCuidadoExtra,
  } = useFormFops();
  const location = useLocation<ParamsLocationData>();
  const { dataLocation } = location.state;
  const { toastError, toastSuccess } = useToast();
  const { fetchData, loading } = usePeco({
    api: {
      operationPath: 'PECO_RegistrarSolicitacaoFOP',
    },
    autoFetch: false,
  });
  const history = useHistory();
  const enviarFop = async (
    codigoFop: number,
    numeroVersaoFop: number,
    nomeSolicitante: string,
    emailSolicitante: string,
    metadados: any,
  ) => {
    const metadadosSolicitacao = JSON.stringify(metadados);
    const result = await fetchData({
      codigoFop,
      numeroVersaoFop,
      nomeSolicitante,
      emailSolicitante,
      metadadosSolicitacao,
    });

    if (result?.mensagens) {
      if (result?.sucessoBFF) {
        toastSuccess((result?.mensagens[0] || []).descricao);
        setTimeout(() => {
          history.push({
            pathname: `/extranet/fop-previdencia`,
          });
        }, 0);
      }
    } else {
      toastError('Erro ao registrar FOP.');
    }
  };

  const handleFormSubmit = async (values: any) => {
    const arquivo = arquivoAnexoFop.get().value;

    const data = new FormData();
    const file = arquivo[0];
    data.append('Arquivo', file);
    data.append('codigoFop', '62');
    data.append('numeroVersaoFop', '7');

    const response: any = await api.post(
      '/PortalEconomiario/PECO_EnviarArquivoSolicitacaoFOP',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    const form = {
      ...values,
      idunicosolicitacaofop: response?.data?.dados?.entidade
        ?.idUnicoSolicitacaoFop
        ? response?.data?.dados?.entidade?.idUnicoSolicitacaoFop
        : '',
      nomeEmpresa: values.nomeEmpresa,
      cnpj: values.cnpj,
      filial: selectOptionsFilial.find(x => x.key === values.filial)?.value,
      regraParaCalculo: selectOptionsRegra.find(
        x => x.key === values.regraParaCalculo,
      )?.value,
      tipoDeBeneficioBasico: selectOptionsBeneficio.find(
        x => x.key === values.tipoDeBeneficioBasico,
      )?.value,
      prazoDeBeneficio: selectOptionsPrazoBeneficio.find(
        x => x.key === values.prazoDeBeneficio,
      )?.value,
      selectReversao: selectOptionsReversao.find(
        x => x.key === values.selectReversao,
      )?.value,
      formaPagamento: selectOptionsPagamento.find(
        x => x.key === values.formaPagamento,
      )?.value,
      valoresParticipantes: selectOptionsValoresParticipantes.find(
        x => x.key === values.valoresParticipantes,
      )?.value,
      valorContribuicao: selectOptionsValorContribuicao.find(
        x => x.key === values.valorContribuicao,
      )?.value,
      valorContribuicaoEmpresa: selectOptionsValorContribuicao.find(
        x => x.key === values.valorContribuicaoEmpresa,
      )?.value,
      valorContribuicaoFuncionario: selectOptionsValorContribuicao.find(
        x => x.key === values.valorContribuicaoFuncionario,
      )?.value,
      tipoCuidadoExtra: selectOptionsCuidadoExtra.find(
        x => x.key === values.tipoCuidadoExtra,
      )?.value,
      regraCuidadoExtra: selectOptionsRegraCuidado.find(
        x => x.key === values.regraCuidadoExtra,
      )?.value,
      anosPensao: selectOptionsAnosPensao.find(x => x.key === values.anosPensao)
        ?.value,
      regraCuidadoExtraPensao: selectOptionsRegraCuidadoPensao.find(
        x => x.key === values.regraCuidadoExtraPensao,
      )?.value,
      formaPagamentoCuidado: selectOptionsPagamento.find(
        x => x.key === values.formaPagamentoCuidado,
      )?.value,
      valoresParticipanteCuidado: selectOptionsValoresParticipantes.find(
        x => x.key === values.valoresParticipanteCuidado,
      )?.value,
      valorContribuicaoCuidadoExtra: selectOptionsValorContribuicao.find(
        x => x.key === values.valorContribuicaoCuidadoExtra,
      )?.value,
      valorContribuicaoEmpresaCuidadoExtra: selectOptionsValorContribuicao.find(
        x => x.key === values.valorContribuicaoEmpresaCuidadoExtra,
      )?.value,
      valorContribuicaoFuncionarioCuidadoExtra:
        selectOptionsValorContribuicao.find(
          x => x.key === values.valorContribuicaoFuncionarioCuidadoExtra,
        )?.value,
      textOutraFormaPagamento,
      textOutraFormaPagamentoEmpresa,
      textOutraFormaPagamentoEmpresaCuidadoExtra,
      textOutraFormaPagamentoFuncionario,
      textOutraFormaPagamentoFuncionarioCuidadoExtra,
      textOutraFormaPagamentoCuidadoExtra,
      textInformacoesComplementares,
    };
    enviarFop(62, 7, values.nomeCompleto, values.email, form);
  };

  return (
    <Display type="display-block" key="form-fop062">
      <Formik
        key="form-fop-prev-empresarial"
        initialValues={{
          nomeEmpresa: '',
          nomeCompleto: '',
          telefone: '',
          email: '',
          atividadePrincipal: '',
          cnpj: '',
          superintendenciaRegional: '',
          numeroAgencia: '',
          nomeAgencia: '',
          filial: '',
          matriculaIndicador: '',
          nomeIndicador: '',
          numeroParticipantes: '',
          regraParaCalculo: '',
          formaPagamento: '',
          formaPagamentoCuidado: '',
          valoresParticipantes: '',
          valoresParticipanteCuidado: '',
          valorContribuicaoCuidadoExtra: '',
          valorContribuicaoFuncionario: '',
          valorContribuicaoFuncionarioCuidadoExtra: '',
          tipoDeBeneficioBasico: '',
          valorContribuicao: '',
          tipoCuidadoExtra: '',
          valorFixo: '',
          valorFixoEmpresa: '',
          valorContribuicaoEmpresa: '',
          valorFixoEmpresaCuidadoExtra: '',
          valorContribuicaoEmpresaCuidadoExtra: '',
          valorFixoFuncionario: '',
          valorFixoFuncionarioCuidadoExtra: '',
          anosPensao: '',
          regraCuidadoExtra: '',
          valorPercentual: '',
          valorPercentualEmpresa: '',
          valorPercentualEmpresaCuidadoExtra: '',
          valorPercentualFuncionario: '',
          valorPercentualFuncionarioCuidadoExtra: '',
          valorFixoCuidadoExtra: '',
          valorPercentualCuidadoExtra: '',
          prazoDeBeneficio: '',
          selectReversao: '',
          regraCuidadoExtraPensao: '',
        }}
        validationSchema={FormFopsValidationSchema}
        onSubmit={values => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <Card key="form-header">
              <Card.Content padding={[4, 4, 4]}>
                <Text
                  variant="body-medium3"
                  color="text-light"
                  key="form-atencao"
                >
                  *Atenção: Todos os campos são obrigatórios!
                </Text>
                <br />
                <Grid>
                  <Grid.Item xs={1}>
                    <Text
                      variant="headline-05"
                      color="primary"
                      key="formulario-titulo"
                    >
                      FOP 062 - Solicitação de Estudo de Prev Empresarial
                    </Text>
                  </Grid.Item>
                </Grid>
              </Card.Content>
            </Card>
            <br />
            <Card key="form-informacoes-gerais">
              <Card.Content padding={[4, 4, 4]}>
                <TitleSection> 1. Informações Gerais </TitleSection>
                <Grid.Item xs={1} lg={1 / 2}>
                  <Field
                    name="nomeEmpresa"
                    label="Nome da Empresa"
                    id="nomeEmpresa"
                    component={TextField}
                    value={values.nomeEmpresa}
                    error={errors.nomeEmpresa && touched.nomeEmpresa}
                    errorMessage={errors.nomeEmpresa}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('nomeEmpresa', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 2}>
                  <Field
                    name="atividadePrincipal"
                    label="*Atividade principal"
                    component={TextField}
                    value={values.atividadePrincipal}
                    error={errors.atividadePrincipal}
                    errorMessage={errors.atividadePrincipal}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('atividadePrincipal', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 2}>
                  <Field
                    name="cnpj"
                    label="*CNPJ"
                    component={TextField}
                    value={masks.cnpj.mask(values.cnpj)}
                    error={errors.cnpj}
                    errorMessage={errors.cnpj}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('cnpj', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1 / 2} lg={1 / 4}>
                  <Field
                    name="superintendenciaRegional"
                    label="*Superintendência regional"
                    component={TextField}
                    value={values.superintendenciaRegional}
                    error={errors.superintendenciaRegional}
                    errorMessage={errors.superintendenciaRegional}
                    maxLength={4}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('superintendenciaRegional', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 4}>
                  <Field
                    name="numeroAgencia"
                    label="*Número da agência"
                    component={TextField}
                    value={values.numeroAgencia}
                    error={errors.numeroAgencia}
                    errorMessage={errors.numeroAgencia}
                    maxLength={4}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('numeroAgencia', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 2}>
                  <Field
                    name="nomeAgencia"
                    label="*Nome da agência"
                    component={TextField}
                    value={values.nomeAgencia}
                    error={errors.nomeAgencia}
                    errorMessage={errors.nomeAgencia}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('nomeAgencia', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <Field
                    name="filial"
                    label="*Filial"
                    component={Select}
                    placeholder="Selecione"
                    value={values.filial}
                    error={errors.filial}
                    errorMessage={errors.filial}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<{
                      text: string;
                      value: string;
                    }>) => {
                      setFieldValue('filial', value);
                    }}
                    onBlur={handleBlur}
                  >
                    {selectOptionsFilial.map(optionsFilial => (
                      <Select.Item
                        key={optionsFilial.key}
                        value={optionsFilial.key}
                        text={optionsFilial.value}
                        selected={optionsFilial.key === values.filial}
                      />
                    ))}
                  </Field>
                </Grid.Item>
                <Grid.Item xs={1 / 2} lg={1 / 3}>
                  <Field
                    name="matriculaIndicador"
                    label="*Matrícula do Indicador"
                    component={TextField}
                    value={values.matriculaIndicador}
                    error={errors.matriculaIndicador}
                    errorMessage={errors.matriculaIndicador}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('matriculaIndicador', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1 / 2}>
                  <Field
                    name="nomeIndicador"
                    label="*Nome do Indicador"
                    component={TextField}
                    value={values.nomeIndicador}
                    error={errors.nomeIndicador}
                    errorMessage={errors.nomeIndicador}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('nomeIndicador', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
              </Card.Content>
            </Card>
            <br />
            <Card>
              <Card.Content padding={[4, 4, 4]}>
                <TitleSection>2.Dados do Plano - Beneficio Básico</TitleSection>
                <Grid.Item xs={1} lg={1 / 4}>
                  <Field
                    name="numeroParticipantes"
                    label="*Número de participantes"
                    component={TextField}
                    value={values.numeroParticipantes}
                    error={errors.numeroParticipantes}
                    errorMessage={errors.numeroParticipantes}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('numeroParticipantes', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <Field
                    name="regraParaCalculo"
                    label="*Selecione a regra para o cálculo"
                    component={Select}
                    placeholder="Selecione"
                    value={values.regraParaCalculo}
                    error={errors.regraParaCalculo}
                    errorMessage={errors.regraParaCalculo}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<{
                      text: string;
                      value: string;
                    }>) => {
                      setFieldValue('regraParaCalculo', value);
                      selectRegraCalculo(value);
                    }}
                    onBlur={handleBlur}
                  >
                    {selectOptionsRegra.map(OptionsRegra => (
                      <Select.Item
                        key={OptionsRegra.key}
                        value={OptionsRegra.key}
                        text={OptionsRegra.value}
                        selected={OptionsRegra.key === values.regraParaCalculo}
                      />
                    ))}
                  </Field>
                </Grid.Item>
                <RenderConditional condition={openSelectTipoBenficio}>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="tipoDeBeneficioBasico"
                      label="*Tipo de benefício básico"
                      component={Select}
                      placeholder="Selecione"
                      value={values.tipoDeBeneficioBasico}
                      error={errors.tipoDeBeneficioBasico}
                      errorMessage={errors.tipoDeBeneficioBasico}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('tipoDeBeneficioBasico', value);
                        selectBeneficio(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsBeneficio.map(optionsBeneficio => (
                        <Select.Item
                          key={optionsBeneficio.key}
                          value={optionsBeneficio.key}
                          text={optionsBeneficio.value}
                          selected={
                            optionsBeneficio.key ===
                            values.tipoDeBeneficioBasico
                          }
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectPrazoBenficio}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="prazoDeBeneficio"
                        label="*Prazo de Benefício Básico"
                        component={Select}
                        placeholder="Selecione"
                        value={values.prazoDeBeneficio}
                        error={errors.prazoDeBeneficio}
                        errorMessage={errors.prazoDeBeneficio}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue('prazoDeBeneficio', value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptionsPrazoBeneficio.map(
                          optionsPrazoBeneficio => (
                            <Select.Item
                              key={optionsPrazoBeneficio.key}
                              value={optionsPrazoBeneficio.key}
                              text={optionsPrazoBeneficio.value}
                              selected={
                                optionsPrazoBeneficio.key ===
                                values.prazoDeBeneficio
                              }
                            />
                          ),
                        )}
                      </Field>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectReversivel}>
                    <Grid.Item xs={1} lg={1 / 3}>
                      <Field
                        name="selectReversao"
                        label="% Reversão"
                        component={Select}
                        placeholder="Selecione"
                        value={values.selectReversao}
                        error={errors.selectReversao}
                        errorMessage={errors.selectReversao}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue('selectReversao', value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptionsReversao.map(optSelectReversao => (
                          <Select.Item
                            key={optSelectReversao.key}
                            value={optSelectReversao.key}
                            text={optSelectReversao.value}
                            selected={
                              optSelectReversao.key === values.selectReversao
                            }
                          />
                        ))}
                      </Field>
                    </Grid.Item>
                  </RenderConditional>
                </RenderConditional>
                <Grid.Item xs={1} lg={1 / 2}>
                  <Field
                    name="formaPagamento"
                    label="*Forma de pagamento"
                    component={Select}
                    placeholder="Selecione"
                    value={values.formaPagamento}
                    error={errors.formaPagamento}
                    errorMessage={errors.formaPagamento}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<{
                      text: string;
                      value: string;
                    }>) => {
                      setFieldValue('formaPagamento', value);
                      selectFormaPagamento(value);
                    }}
                    onBlur={handleBlur}
                  >
                    {selectOptionsPagamento.map(optRegraParaCalculo => (
                      <Select.Item
                        key={optRegraParaCalculo.key}
                        value={optRegraParaCalculo.key}
                        text={optRegraParaCalculo.value}
                        selected={
                          optRegraParaCalculo.key === values.formaPagamento
                        }
                      />
                    ))}
                  </Field>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 2}>
                  <Field
                    name="valoresParticipantes"
                    label="*Os valores são iguais para todos os participantes?"
                    component={Select}
                    placeholder="Selecione"
                    value={values.valoresParticipantes}
                    error={errors.valoresParticipantes}
                    errorMessage={errors.valoresParticipantes}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<{
                      text: string;
                      value: string;
                    }>) => {
                      setFieldValue('valoresParticipantes', value);
                      selectValoresParticipantes(value);
                    }}
                    onBlur={handleBlur}
                  >
                    {selectOptionsValoresParticipantes.map(
                      optValoresParticipantes => (
                        <Select.Item
                          key={optValoresParticipantes.key}
                          value={optValoresParticipantes.key}
                          text={optValoresParticipantes.value}
                          selected={
                            optValoresParticipantes.key ===
                            values.valoresParticipantes
                          }
                        />
                      ),
                    )}
                  </Field>
                </Grid.Item>
                <RenderConditional condition={openSelectValorContribuicao}>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="valorContribuicao"
                      label="*Valor da contribuição"
                      component={Select}
                      placeholder="Selecione"
                      value={values.valorContribuicao}
                      error={errors.valorContribuicao}
                      errorMessage={errors.valorContribuicao}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('valorContribuicao', value);
                        selectValorContribuicao(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsValorContribuicao.map(
                        optValorContribuicao => (
                          <Select.Item
                            key={optValorContribuicao.key}
                            value={optValorContribuicao.key}
                            text={optValorContribuicao.value}
                            selected={
                              optValorContribuicao.key ===
                              values.valorContribuicao
                            }
                          />
                        ),
                      )}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectValorFixo}>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <Field
                        name="valorFixo"
                        label="*Valor Fixo"
                        component={TextField}
                        value={values.valorFixo}
                        error={errors.valorFixo}
                        errorMessage={errors.valorFixo}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            'valorFixo',
                            masks.currencyInput.mask(value),
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectValorPercentual}>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <Field
                        name="valorPercentual"
                        label="*Definir % do valor com base do salário"
                        component={TextField}
                        value={masks.percentage.mask(values.valorPercentual)}
                        error={errors.valorPercentual}
                        errorMessage={errors.valorPercentual}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('valorPercentual', value);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={openSelectOutraFormaPagamento}>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <p>
                        *Descreva neste espaço como será o custeio do pagamento
                      </p>
                      <TextArea
                        spellCheck
                        value={textOutraFormaPagamento}
                        placeholder=""
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{ value: string }>) =>
                          setTextOutraFormaPagamento(value)
                        }
                      />
                    </Grid.Item>
                  </RenderConditional>
                </RenderConditional>
                <RenderConditional condition={openSelectValoresParticipantes}>
                  <p style={{ color: 'red' }}>
                    Será necessário preencher os campos específicos do FOP 064
                    disponível para download no final deste formulário.
                  </p>
                </RenderConditional>
                <RenderConditional condition={openSelectPlanoInstituido}>
                  <TitleSection>Empresa</TitleSection>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="valorContribuicaoEmpresa"
                      label="*Valor da contribuição"
                      component={Select}
                      placeholder="Selecione"
                      value={values.valorContribuicaoEmpresa}
                      error={errors.valorContribuicaoEmpresa}
                      errorMessage={errors.valorContribuicaoEmpresa}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('valorContribuicaoEmpresa', value);
                        selectValorContribuicaoEmpresa(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsValorContribuicao.map(
                        optValorContribuicaoEmpresa => (
                          <Select.Item
                            key={optValorContribuicaoEmpresa.key}
                            value={optValorContribuicaoEmpresa.key}
                            text={optValorContribuicaoEmpresa.value}
                            selected={
                              optValorContribuicaoEmpresa.key ===
                              values.valorContribuicaoEmpresa
                            }
                          />
                        ),
                      )}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectValorFixoEmpresa}>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <Field
                        name="valorFixoEmpresa"
                        label="*Valor Fixo"
                        component={TextField}
                        value={values.valorFixoEmpresa}
                        error={errors.valorFixoEmpresa}
                        errorMessage={errors.valorFixoEmpresa}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            'valorFixoEmpresa',
                            masks.currencyInput.mask(value),
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional
                    condition={openSelectValorPercentualEmpresa}
                  >
                    <Grid.Item xs={1} lg={1 / 2}>
                      <Field
                        name="valorPercentualEmpresa"
                        label="*Definir % do valor com base do salário"
                        component={TextField}
                        value={masks.percentage.mask(
                          values.valorPercentualEmpresa,
                        )}
                        error={errors.valorPercentualEmpresa}
                        errorMessage={errors.valorPercentualEmpresa}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('valorPercentualEmpresa', value);
                        }}
                        onBlur={handleBlur}
                      />
                      <p style={{ color: 'red' }}>
                        Informe o valor do salário para cada participante no no
                        FOP 064
                      </p>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional
                    condition={openSelectOutraFormaPagamentoEmpresa}
                  >
                    <Grid.Item xs={1} lg={1 / 2}>
                      <p>
                        *Descreva neste espaço como será o custeio do pagamento
                      </p>
                      <TextArea
                        spellCheck
                        value={textOutraFormaPagamentoEmpresa}
                        placeholder=""
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{ value: string }>) =>
                          setTextOutraFormaPagamentoEmpresa(value)
                        }
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <Divider />
                  <TitleSection>Funcionario</TitleSection>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="valorContribuicaoFuncionario"
                      label="*Valor da contribuição"
                      component={Select}
                      placeholder="Selecione"
                      value={values.valorContribuicaoFuncionario}
                      error={errors.valorContribuicaoFuncionario}
                      errorMessage={errors.valorContribuicaoFuncionario}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('valorContribuicaoFuncionario', value);
                        selectValorContribuicaoFuncionario(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsValorContribuicao.map(
                        optValorContribuicaoFuncionario => (
                          <Select.Item
                            key={optValorContribuicaoFuncionario.key}
                            value={optValorContribuicaoFuncionario.key}
                            text={optValorContribuicaoFuncionario.value}
                            selected={
                              optValorContribuicaoFuncionario.key ===
                              values.valorContribuicaoFuncionario
                            }
                          />
                        ),
                      )}
                    </Field>
                  </Grid.Item>
                  <RenderConditional condition={openSelectValorFixoFuncionario}>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <Field
                        name="valorFixoFuncionario"
                        label="*Valor Fixo"
                        component={TextField}
                        value={values.valorFixoFuncionario}
                        error={errors.valorFixoFuncionario}
                        errorMessage={errors.valorFixoFuncionario}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            'valorFixoFuncionario',
                            masks.currencyInput.mask(value),
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional
                    condition={openSelectValorPercentualFuncionario}
                  >
                    <Grid.Item xs={1} lg={1 / 2}>
                      <Field
                        name="valorPercentualFuncionario"
                        label="*Definir % do valor com base do salário"
                        component={TextField}
                        value={masks.percentage.mask(
                          values.valorPercentualFuncionario,
                        )}
                        error={errors.valorPercentualFuncionario}
                        errorMessage={errors.valorPercentualFuncionario}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('valorPercentualFuncionario', value);
                        }}
                        onBlur={handleBlur}
                      />
                      <p style={{ color: 'red' }}>
                        Informe o valor do salário para cada participante no FOP
                        064
                      </p>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional
                    condition={openSelectOutraFormaPagamentoFuncionario}
                  >
                    <Grid.Item xs={1} lg={1 / 2}>
                      <p>
                        *Descreva neste espaço como será o custeio do pagamento
                      </p>
                      <TextArea
                        spellCheck
                        value={textOutraFormaPagamentoFuncionario}
                        placeholder=""
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{ value: string }>) =>
                          setTextOutraFormaPagamentoFuncionario(value)
                        }
                      />
                    </Grid.Item>
                  </RenderConditional>
                </RenderConditional>
              </Card.Content>
            </Card>
            <br />
            <Card>
              <Card.Content padding={[4, 4, 4]}>
                <TitleSection>3.Cuidado Extra</TitleSection>
                <Grid.Item xs={1} lg={1 / 3}>
                  <Field
                    name="tipoCuidadoExtra"
                    label="*Tipo de cuidado extra"
                    component={Select}
                    placeholder="Selecione"
                    value={values.tipoCuidadoExtra}
                    error={errors.tipoCuidadoExtra}
                    errorMessage={errors.tipoCuidadoExtra}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<{
                      text: string;
                      value: string;
                    }>) => {
                      setFieldValue('tipoCuidadoExtra', value);
                      selectCuidadoExtra(value);
                    }}
                    onBlur={handleBlur}
                  >
                    {selectOptionsCuidadoExtra.map(optTipoCuidadoExtra => (
                      <Select.Item
                        key={optTipoCuidadoExtra.key}
                        value={optTipoCuidadoExtra.key}
                        text={optTipoCuidadoExtra.value}
                        selected={
                          optTipoCuidadoExtra.key === values.tipoCuidadoExtra
                        }
                      />
                    ))}
                  </Field>
                </Grid.Item>
                <RenderConditional condition={openSelectPeculio}>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="regraCuidadoExtra"
                      label="*Selecione a regra para o cálculo"
                      component={Select}
                      placeholder="Selecione"
                      value={values.regraCuidadoExtra}
                      error={errors.regraCuidadoExtra}
                      errorMessage={errors.regraCuidadoExtra}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('regraCuidadoExtra', value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsRegraCuidado.map(optRegraCuidadoExtra => (
                        <Select.Item
                          key={optRegraCuidadoExtra.key}
                          value={optRegraCuidadoExtra.key}
                          text={optRegraCuidadoExtra.value}
                          selected={
                            optRegraCuidadoExtra.key ===
                            values.regraCuidadoExtra
                          }
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                </RenderConditional>
                <RenderConditional condition={openSelectPensao}>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="anosPensao"
                      label="*Por quantos anos a pensão será paga aos beneficiários?"
                      component={Select}
                      placeholder="Selecione"
                      value={values.anosPensao}
                      error={errors.anosPensao}
                      errorMessage={errors.anosPensao}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('anosPensao', value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsAnosPensao.map(optAnosPensao => (
                        <Select.Item
                          key={optAnosPensao.key}
                          value={optAnosPensao.key}
                          text={optAnosPensao.value}
                          selected={optAnosPensao.key === values.anosPensao}
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <Field
                      name="regraCuidadoExtraPensao"
                      label="*Selecione a regra para o cálculo"
                      component={Select}
                      placeholder="Selecione"
                      value={values.regraCuidadoExtraPensao}
                      error={errors.regraCuidadoExtraPensao}
                      errorMessage={errors.regraCuidadoExtraPensao}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('regraCuidadoExtraPensao', value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsRegraCuidadoPensao.map(
                        optRegraCuidadoExtraPensao => (
                          <Select.Item
                            key={optRegraCuidadoExtraPensao.key}
                            value={optRegraCuidadoExtraPensao.key}
                            text={optRegraCuidadoExtraPensao.value}
                            selected={
                              optRegraCuidadoExtraPensao.key ===
                              values.regraCuidadoExtraPensao
                            }
                          />
                        ),
                      )}
                    </Field>
                  </Grid.Item>
                </RenderConditional>
                <RenderConditional condition={openSelectFormaPagamentoCuidado}>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="formaPagamentoCuidado"
                      label="*Forma de pagamento"
                      component={Select}
                      placeholder="Selecione"
                      value={values.formaPagamentoCuidado}
                      error={errors.formaPagamentoCuidado}
                      errorMessage={errors.formaPagamentoCuidado}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('formaPagamentoCuidado', value);
                        selectFormaPagamentoCuidado(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsPagamento.map(optFormaPagamentoCuidado => (
                        <Select.Item
                          key={optFormaPagamentoCuidado.key}
                          value={optFormaPagamentoCuidado.key}
                          text={optFormaPagamentoCuidado.value}
                          selected={
                            optFormaPagamentoCuidado.key ===
                            values.formaPagamentoCuidado
                          }
                        />
                      ))}
                    </Field>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <Field
                      name="valoresParticipanteCuidado"
                      label="*Os valores são iguais para todos os participantes?"
                      component={Select}
                      placeholder="Selecione"
                      value={values.valoresParticipanteCuidado}
                      error={errors.valoresParticipanteCuidado}
                      errorMessage={errors.valoresParticipanteCuidado}
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setFieldValue('valoresParticipanteCuidado', value);
                        selectValoresParticipantesCuidado(value);
                      }}
                      onBlur={handleBlur}
                    >
                      {selectOptionsValoresParticipantes.map(
                        optValoresParticipantesCuidado => (
                          <Select.Item
                            key={optValoresParticipantesCuidado.key}
                            value={optValoresParticipantesCuidado.key}
                            text={optValoresParticipantesCuidado.value}
                            selected={
                              optValoresParticipantesCuidado.key ===
                              values.valoresParticipanteCuidado
                            }
                          />
                        ),
                      )}
                    </Field>
                  </Grid.Item>
                  <RenderConditional
                    condition={openSelectValoresParticipantesCuidado}
                  >
                    <p style={{ color: 'red' }}>
                      Será necessário preencher os campos específicos do FOP 064
                      disponível para download no final deste formulário.
                    </p>
                  </RenderConditional>
                  <RenderConditional
                    condition={openSelectValorContribuicaoCuidadoExtra}
                  >
                    <Grid.Item xs={1} lg={1 / 2}>
                      <Field
                        name="valorContribuicaoCuidadoExtra"
                        label="*Valor da contribuição"
                        component={Select}
                        placeholder="Selecione"
                        value={values.valorContribuicaoCuidadoExtra}
                        error={errors.valorContribuicaoCuidadoExtra}
                        errorMessage={errors.valorContribuicaoCuidadoExtra}
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue('valorContribuicaoCuidadoExtra', value);
                          selectValorContribuicaoCuidadoExtra(value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptionsValorContribuicao.map(
                          optValorContribuicaoCuidadoExtra => (
                            <Select.Item
                              key={optValorContribuicaoCuidadoExtra.key}
                              value={optValorContribuicaoCuidadoExtra.key}
                              text={optValorContribuicaoCuidadoExtra.value}
                              selected={
                                optValorContribuicaoCuidadoExtra.key ===
                                values.valorContribuicaoCuidadoExtra
                              }
                            />
                          ),
                        )}
                      </Field>
                    </Grid.Item>
                    <RenderConditional
                      condition={openSelectValorFixoCuidadoExtra}
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="valorFixoCuidadoExtra"
                          label="*Valor Fixo"
                          component={TextField}
                          value={values.valorFixoCuidadoExtra}
                          error={errors.valorFixoCuidadoExtra}
                          errorMessage={errors.valorFixoCuidadoExtra}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              'valorFixoCuidadoExtra',
                              masks.currencyInput.mask(value),
                            );
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </RenderConditional>
                    <RenderConditional
                      condition={openSelectValorPercentualCuidadoExtra}
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="valorPercentualCuidadoExtra"
                          label="*Definir % do valor com base do salário"
                          component={TextField}
                          value={values.valorPercentualCuidadoExtra}
                          error={errors.valorPercentualCuidadoExtra}
                          errorMessage={errors.valorPercentualCuidadoExtra}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              'valorPercentualCuidadoExtra',
                              masks.percentage.mask(values),
                            );
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </RenderConditional>
                    <RenderConditional
                      condition={openSelectOutraFormaPagamentoCuidadoExtra}
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <p>
                          *Descreva neste espaço como será o custeio do
                          pagamento
                        </p>
                        <TextArea
                          spellCheck
                          value={textOutraFormaPagamentoCuidadoExtra}
                          placeholder=""
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{ value: string }>) =>
                            setTextOutraFormaPagamentoCuidadoExtra(value)
                          }
                        />
                      </Grid.Item>
                    </RenderConditional>
                  </RenderConditional>
                  <RenderConditional
                    condition={openSelectPlanoInstituidoCuidadoExtra}
                  >
                    <TitleSection>Empresa</TitleSection>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <Field
                        name="valorContribuicaoEmpresaCuidadoExtra"
                        label="*Valor da contribuição"
                        component={Select}
                        placeholder="Selecione"
                        value={values.valorContribuicaoEmpresaCuidadoExtra}
                        error={errors.valorContribuicaoEmpresaCuidadoExtra}
                        errorMessage={
                          errors.valorContribuicaoEmpresaCuidadoExtra
                        }
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue(
                            'valorContribuicaoEmpresaCuidadoExtra',
                            value,
                          );
                          selectValorContribuicaoEmpresaCuidadoExtra(value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptionsValorContribuicao.map(
                          optValorContribuicaoEmpresaCuidadoExtra => (
                            <Select.Item
                              key={optValorContribuicaoEmpresaCuidadoExtra.key}
                              value={
                                optValorContribuicaoEmpresaCuidadoExtra.key
                              }
                              text={
                                optValorContribuicaoEmpresaCuidadoExtra.value
                              }
                              selected={
                                optValorContribuicaoEmpresaCuidadoExtra.key ===
                                values.valorContribuicaoEmpresaCuidadoExtra
                              }
                            />
                          ),
                        )}
                      </Field>
                    </Grid.Item>
                    <RenderConditional
                      condition={openSelectValorFixoEmpresaCuidadoExtra}
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="valorFixoEmpresaCuidadoExtra"
                          label="*Valor Fixo"
                          component={TextField}
                          value={values.valorFixoEmpresaCuidadoExtra}
                          error={errors.valorFixoEmpresaCuidadoExtra}
                          errorMessage={errors.valorFixoEmpresaCuidadoExtra}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              'valorFixoEmpresaCuidadoExtra',
                              masks.currencyInput.mask(value),
                            );
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </RenderConditional>
                    <RenderConditional
                      condition={openSelectValorPercentualEmpresaCuidadoExtra}
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="valorPercentualEmpresaCuidadoExtra"
                          label="*Definir % do valor com base do salário"
                          component={TextField}
                          value={masks.percentage.mask(
                            values.valorPercentualEmpresaCuidadoExtra,
                          )}
                          error={errors.valorPercentualEmpresaCuidadoExtra}
                          errorMessage={
                            errors.valorPercentualEmpresaCuidadoExtra
                          }
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              'valorPercentualEmpresaCuidadoExtra',
                              value,
                            );
                          }}
                          onBlur={handleBlur}
                        />

                        <p style={{ color: 'red' }}>
                          Informe o valor do salário para cada participante no
                          no FOP 064
                        </p>
                      </Grid.Item>
                    </RenderConditional>
                    <RenderConditional
                      condition={
                        openSelectOutraFormaPagamentoEmpresaCuidadoExtra
                      }
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <p>
                          *Descreva neste espaço como será o custeio do
                          pagamento
                        </p>
                        <TextArea
                          spellCheck
                          value={textOutraFormaPagamentoEmpresaCuidadoExtra}
                          placeholder=""
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{ value: string }>) =>
                            setTextOutraFormaPagamentoEmpresaCuidadoExtra(value)
                          }
                        />
                      </Grid.Item>
                    </RenderConditional>
                    <Divider />
                    <TitleSection>Funcionario</TitleSection>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <Field
                        name="valorContribuicaoFuncionarioCuidadoExtra"
                        label="*Valor da contribuição"
                        component={Select}
                        placeholder="Selecione"
                        value={values.valorContribuicaoFuncionarioCuidadoExtra}
                        error={errors.valorContribuicaoFuncionarioCuidadoExtra}
                        errorMessage={
                          errors.valorContribuicaoFuncionarioCuidadoExtra
                        }
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<{
                          text: string;
                          value: string;
                        }>) => {
                          setFieldValue(
                            'valorContribuicaoFuncionarioCuidadoExtra',
                            value,
                          );
                          selectValorContribuicaoFuncionarioCuidadoExtra(value);
                        }}
                        onBlur={handleBlur}
                      >
                        {selectOptionsValorContribuicao.map(
                          optValorContribuicaoFuncionarioCuidadoExtra => (
                            <Select.Item
                              key={
                                optValorContribuicaoFuncionarioCuidadoExtra.key
                              }
                              value={
                                optValorContribuicaoFuncionarioCuidadoExtra.key
                              }
                              text={
                                optValorContribuicaoFuncionarioCuidadoExtra.value
                              }
                              selected={
                                optValorContribuicaoFuncionarioCuidadoExtra.key ===
                                values.valorContribuicaoFuncionarioCuidadoExtra
                              }
                            />
                          ),
                        )}
                      </Field>
                    </Grid.Item>
                    <RenderConditional
                      condition={openSelectValorFixoFuncionarioCuidadoExtra}
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          name="valorFixoFuncionarioCuidadoExtra"
                          label="*Valor Fixo"
                          component={TextField}
                          value={values.valorFixoFuncionarioCuidadoExtra}
                          error={errors.valorFixoFuncionarioCuidadoExtra}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              'valorFixoFuncionarioCuidadoExtra',
                              masks.currencyInput.mask(value),
                            );
                          }}
                          errorMessage={errors.valorFixoFuncionarioCuidadoExtra}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </RenderConditional>
                    <></>
                    <RenderConditional
                      condition={
                        openSelectValorPercentualFuncionarioCuidadoExtra
                      }
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Field
                          label="*Definir % do valor com base do salário"
                          name="valorPercentualFuncionarioCuidadoExtra"
                          value={masks.percentage.mask(
                            values.valorPercentualFuncionarioCuidadoExtra,
                          )}
                          component={TextField}
                          error={errors.valorPercentualFuncionarioCuidadoExtra}
                          errorMessage={
                            errors.valorPercentualFuncionarioCuidadoExtra
                          }
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              'valorPercentualFuncionarioCuidadoExtra',
                              value,
                            );
                          }}
                          onBlur={handleBlur}
                        />

                        <p style={{ color: 'red' }}>
                          Informe o valor do salário para cada participante no
                          no FOP 064
                        </p>
                      </Grid.Item>
                    </RenderConditional>
                    <RenderConditional
                      condition={
                        openSelectOutraFormaPagamentoFuncionarioCuidadoExtra
                      }
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <p>
                          *Descreva neste espaço como será o custeio do
                          pagamento
                        </p>
                        <TextArea
                          spellCheck
                          value={textOutraFormaPagamentoFuncionarioCuidadoExtra}
                          placeholder=""
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{ value: string }>) =>
                            setTextOutraFormaPagamentoFuncionarioCuidadoExtra(
                              value,
                            )
                          }
                        />
                      </Grid.Item>
                    </RenderConditional>
                  </RenderConditional>
                </RenderConditional>
              </Card.Content>
            </Card>
            <br />
            <Card>
              <Card.Content padding={[4, 4, 4]}>
                <TitleSection>4.Informações Complementares</TitleSection>
                <Grid.Item xs={1} lg={1 / 2}>
                  <p>Informações Complementares (Opcional)</p>
                  <TextArea
                    spellCheck
                    value={textInformacoesComplementares}
                    placeholder=""
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<{ value: string }>) =>
                      setTextInformacoesComplementares(value)
                    }
                  />
                </Grid.Item>
              </Card.Content>
            </Card>
            <br />
            <Card>
              <Card.Content padding={[4, 4, 4]}>
                <TitleSection>
                  5.Dados do responsável pelo preenchimento
                </TitleSection>
                <Grid.Item xs={1} lg={1 / 2}>
                  <Field
                    name="nomeCompleto"
                    label="*Nome completo"
                    component={TextField}
                    value={values.nomeCompleto}
                    error={errors.nomeCompleto}
                    errorMessage={errors.nomeCompleto}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('nomeCompleto', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <Field
                    name="telefone"
                    label="*Telefone"
                    component={TextField}
                    value={masks.phone.mask(values.telefone)}
                    error={errors.telefone}
                    errorMessage={errors.telefone}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('telefone', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 2}>
                  <Field
                    name="email"
                    label="*E-mail (Atenção: esse será o e-mail para o recebimento do estudo)"
                    component={TextField}
                    value={values.email}
                    error={errors.email}
                    errorMessage={errors.email}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('email', value);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid.Item>
                <Divider />
                <Text variant="body01-sm" color="primary" margin>
                  <DownloadButton
                    variant="text"
                    type="button"
                    onClick={() =>
                      baixarArquivoFop(
                        dataLocation[0]?.dadosArquivos
                          ? dataLocation[0]?.dadosArquivos[0]
                              ?.codigoIdentificadorUnico
                          : '00000000',
                        dataLocation[0]?.dadosArquivos
                          ? dataLocation[0]?.dadosArquivos[0]?.nomeArquivo
                          : 'Planilha_teste.html',
                      )
                    }
                  >
                    {loadingDownload && <Button variant="text" loading />}
                    {!loadingDownload && (
                      <>
                        Faça o download do FOP 064 aqui &nbsp;
                        <Icon name="download" />
                      </>
                    )}
                  </DownloadButton>
                </Text>
                <Grid.Item xs={1 / 3}>
                  <S.InputLabel>
                    *Anexe aqui o FOP 064 ou documentos importantes para
                    elaboração do estudo
                  </S.InputLabel>
                  <DescriptionFormatsFilesAllowed fileSize="3" />
                  <InputFile
                    link={arquivoAnexoFop}
                    validationRules={regraFiles}
                  />
                </Grid.Item>
                <Modal
                  show={openModalFormulario}
                  onClose={fecharModal}
                  style={{ maxWidth: `90%` }}
                >
                  <Display justify="center">
                    <Text variant="body02-md" margin>
                      <strong>Atenção! Confira seus dados</strong>
                    </Text>
                    <br />
                    <Card.Content>
                      <Accordion open>
                        <Accordion.Item title="1. Informações Gerais">
                          <Grid>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Nome da Empresa
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="nomeEmpresa"
                                >
                                  {values.nomeEmpresa}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Atividade Principal
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="atividadePrincipal"
                                >
                                  {values.atividadePrincipal}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">CNPJ</TextLabel>
                                <Text variant="body02-sm" data-testid="cnpj">
                                  {values.cnpj}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Superintendência Regional
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="superintendenciaRegional"
                                >
                                  {values.superintendenciaRegional}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Número da Agência
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="numeroAgencia"
                                >
                                  {values.numeroAgencia}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Nome da Agência
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="nomeAgencia"
                                >
                                  {values.nomeAgencia}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Filial
                                </TextLabel>
                                <Text variant="body02-sm" data-testid="filial">
                                  {
                                    selectOptionsFilial.find(
                                      x => x.key === values.filial,
                                    )?.value
                                  }
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Matrícula do Indicador
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="matriculaIndicador"
                                >
                                  {values.matriculaIndicador}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Nome do Indicador
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="nomeIndicador"
                                >
                                  {values.nomeIndicador}
                                </Text>
                              </div>
                            </Grid.Item>
                          </Grid>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion open>
                        <Accordion.Item title="2. Dados do Plano - Beneficio Básico">
                          <Grid>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Número de participantes
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="numeroParticipantes"
                                >
                                  {values.numeroParticipantes}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Regra para o cálculo
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="regraParaCalculo"
                                >
                                  {
                                    selectOptionsRegra.find(
                                      x => x.key === values.regraParaCalculo,
                                    )?.value
                                  }
                                </Text>
                              </div>
                            </Grid.Item>
                            <RenderConditional
                              condition={openSelectTipoBenficio}
                            >
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Tipo de Benefício Básico
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="tipoDeBeneficioBasico"
                                  >
                                    {
                                      selectOptionsBeneficio.find(
                                        x =>
                                          x.key ===
                                          values.tipoDeBeneficioBasico,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <RenderConditional
                                condition={openSelectReversivel}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      % Reversão
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="selectReversao"
                                    >
                                      {values.selectReversao}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                              <RenderConditional
                                condition={openSelectPrazoBenficio}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Prazo de Benefício Básico
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="prazoDeBeneficio"
                                    >
                                      {values.prazoDeBeneficio}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                            </RenderConditional>

                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Forma de pagamento
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="formaPagamento"
                                >
                                  {
                                    selectOptionsPagamento.find(
                                      x => x.key === values.formaPagamento,
                                    )?.value
                                  }
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Os valores são iguais para todos os
                                  participantes
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="valoresParticipantes"
                                >
                                  {values.valoresParticipantes}
                                </Text>
                              </div>
                            </Grid.Item>

                            <RenderConditional
                              condition={openSelectValoresParticipantes}
                            >
                              <Grid.Item xs={1} lg={1 / 3}>
                                <p style={{ color: 'red' }}>
                                  Será necessário preencher os campos
                                  específicos do FOP 064 disponível para
                                  download no final deste formulário.
                                </p>
                              </Grid.Item>
                            </RenderConditional>

                            <RenderConditional
                              condition={openSelectValorContribuicao}
                            >
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Valor da contribuição
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="valorContribuicao"
                                  >
                                    {
                                      selectOptionsValorContribuicao.find(
                                        x => x.key === values.valorContribuicao,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>

                              <RenderConditional
                                condition={openSelectValorFixo}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Valor fixo
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorFixo"
                                    >
                                      {values.valorFixo}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>

                              <RenderConditional
                                condition={openSelectValorPercentual}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Definir % do valor com base do salário
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="nomeIndicador"
                                    >
                                      {values.valorPercentual}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>

                              <RenderConditional
                                condition={openSelectOutraFormaPagamento}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Descreva neste espaço como será o custeio
                                      do pagamento
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="nomeIndicador"
                                    >
                                      {textOutraFormaPagamento}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                            </RenderConditional>
                            <RenderConditional
                              condition={openSelectPlanoInstituido}
                            >
                              <Divider />
                              <Grid.Item xs={1} lg={1 / 3}>
                                <TitleSection>Empresa</TitleSection>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Valor da contribuição
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="valorContribuicaoEmpresa"
                                  >
                                    {
                                      selectOptionsValorContribuicao.find(
                                        x =>
                                          x.key ===
                                          values.valorContribuicaoEmpresa,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <RenderConditional
                                condition={openSelectValorFixoEmpresa}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <br />
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Valor fixo
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorFixoEmpresa"
                                    >
                                      {values.valorFixoEmpresa}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                              <RenderConditional
                                condition={openSelectValorPercentualEmpresa}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <br />
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Definir % do valor com base do salário
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorPercentualEmpresa"
                                    >
                                      {values.valorPercentualEmpresa}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                              <RenderConditional
                                condition={openSelectOutraFormaPagamentoEmpresa}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <br />
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Descreva neste espaço como será o custeio
                                      do pagamento
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="textOutraFormaPagamentoEmpresa"
                                    >
                                      {textOutraFormaPagamentoEmpresa}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>

                              <Divider />
                              <Grid.Item xs={1} lg={1 / 3}>
                                <TitleSection>Funcionário</TitleSection>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Valor da contribuição
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="valorContribuicaoFuncionario"
                                  >
                                    {
                                      selectOptionsValorContribuicao.find(
                                        x =>
                                          x.key ===
                                          values.valorContribuicaoFuncionario,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <RenderConditional
                                condition={openSelectValorFixoFuncionario}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <br />
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Valor fixo
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorFixoFuncionario"
                                    >
                                      {values.valorFixoFuncionario}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                              <RenderConditional
                                condition={openSelectValorPercentualFuncionario}
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <br />
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Definir % do valor com base do salário
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorPercentualFuncionario"
                                    >
                                      {values.valorPercentualFuncionario}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                              <RenderConditional
                                condition={
                                  openSelectOutraFormaPagamentoFuncionario
                                }
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <br />
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Descreva neste espaço como será o custeio
                                      do pagamento
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="textOutraFormaPagamentoFuncionario"
                                    >
                                      {textOutraFormaPagamentoFuncionario}
                                    </Text>
                                  </div>
                                </Grid.Item>
                              </RenderConditional>
                            </RenderConditional>
                          </Grid>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion open>
                        <Accordion.Item title="3. Cuidado Extra">
                          <Grid>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Tipo de Cuidado Extra
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="tipoCuidadoExtra"
                                >
                                  {
                                    selectOptionsCuidadoExtra.find(
                                      x => x.key === values.tipoCuidadoExtra,
                                    )?.value
                                  }
                                </Text>
                              </div>
                            </Grid.Item>

                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Regra para o cálculo
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="regraCuidadoExtra"
                                >
                                  {
                                    selectOptionsRegraCuidado.find(
                                      x => x.key === values.regraCuidadoExtra,
                                    )?.value
                                  }
                                </Text>
                              </div>
                            </Grid.Item>

                            <RenderConditional condition={openSelectPensao}>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Por quantos anos a pensão será paga aos
                                    beneficiários?
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="anosPensao"
                                  >
                                    {values.anosPensao}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Regra para o cálculo
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="regraCuidadoExtraPensao"
                                  >
                                    {
                                      selectOptionsRegraCuidadoPensao.find(
                                        x =>
                                          x.key ===
                                          values.regraCuidadoExtraPensao,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                            </RenderConditional>
                            <RenderConditional
                              condition={openSelectFormaPagamentoCuidado}
                            >
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Forma de pagamento
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="formaPagamentoCuidado"
                                  >
                                    {
                                      selectOptionsPagamento.find(
                                        x =>
                                          x.key ===
                                          values.formaPagamentoCuidado,
                                      )?.value
                                    }
                                  </Text>
                                </div>
                              </Grid.Item>
                              <Grid.Item xs={1} lg={1 / 3}>
                                <div>
                                  <TextLabel variant="body02-sm">
                                    Os valores são iguais para todos os
                                    participantes
                                  </TextLabel>
                                  <Text
                                    variant="body02-sm"
                                    data-testid="valoresParticipanteCuidado"
                                  >
                                    {values.valoresParticipanteCuidado}
                                  </Text>
                                </div>
                              </Grid.Item>
                              <RenderConditional
                                condition={
                                  openSelectValorContribuicaoCuidadoExtra
                                }
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Valor da contribuição
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorContribuicaoCuidadoExtra"
                                    >
                                      {
                                        selectOptionsValorContribuicao.find(
                                          x =>
                                            x.key ===
                                            values.valorContribuicaoCuidadoExtra,
                                        )?.value
                                      }
                                    </Text>
                                  </div>
                                </Grid.Item>
                                <RenderConditional
                                  condition={openSelectValorFixoCuidadoExtra}
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor fixo
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="valorFixoCuidadoExtra"
                                      >
                                        {masks.currencyInput.mask(
                                          values.valorFixoCuidadoExtra,
                                        )}
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                </RenderConditional>
                                <RenderConditional
                                  condition={
                                    openSelectValorPercentualCuidadoExtra
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Definir % do valor com base do salário
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="valorPercentualCuidadoExtra"
                                      >
                                        {values.valorPercentualCuidadoExtra}
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                </RenderConditional>
                                <RenderConditional
                                  condition={
                                    openSelectOutraFormaPagamentoCuidadoExtra
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Descreva neste espaço como será o
                                        custeio do pagamento
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="textOutraFormaPagamentoCuidadoExtra"
                                      >
                                        {textOutraFormaPagamentoCuidadoExtra}
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                </RenderConditional>
                              </RenderConditional>
                              <RenderConditional
                                condition={
                                  openSelectValoresParticipantesCuidado
                                }
                              >
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <p style={{ color: 'red' }}>
                                    Será necessário preencher os campos do FOP
                                    062 disponível para download no final deste
                                    formulário.
                                  </p>
                                </Grid.Item>
                              </RenderConditional>
                              <RenderConditional
                                condition={
                                  openSelectPlanoInstituidoCuidadoExtra
                                }
                              >
                                <Divider />
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <TitleSection>Empresa</TitleSection>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Valor da contribuição
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorContribuicaoEmpresaCuidadoExtra"
                                    >
                                      {
                                        selectOptionsValorContribuicao.find(
                                          x =>
                                            x.key ===
                                            values.valorContribuicaoEmpresaCuidadoExtra,
                                        )?.value
                                      }
                                    </Text>
                                  </div>
                                </Grid.Item>
                                <RenderConditional
                                  condition={
                                    openSelectValorFixoEmpresaCuidadoExtra
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <br />
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor fixo
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="valorFixoEmpresaCuidadoExtra"
                                      >
                                        {values.valorFixoEmpresaCuidadoExtra}
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                </RenderConditional>
                                <RenderConditional
                                  condition={
                                    openSelectValorPercentualEmpresaCuidadoExtra
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <br />
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Definir % do valor com base do salário
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="valorPercentualEmpresaCuidadoExtra"
                                      >
                                        {
                                          values.valorPercentualEmpresaCuidadoExtra
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                </RenderConditional>
                                <RenderConditional
                                  condition={
                                    openSelectOutraFormaPagamentoEmpresaCuidadoExtra
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <br />
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Descreva neste espaço como será o
                                        custeio do pagamento
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="textOutraFormaPagamentoEmpresaCuidadoExtra"
                                      >
                                        {
                                          textOutraFormaPagamentoEmpresaCuidadoExtra
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                </RenderConditional>
                                <Divider />
                                <Grid.Item xs={1} lg={1 / 3}>
                                  <TitleSection>Funcionário</TitleSection>
                                  <div>
                                    <TextLabel variant="body02-sm">
                                      Valor da contribuição
                                    </TextLabel>
                                    <Text
                                      variant="body02-sm"
                                      data-testid="valorContribuicaoFuncionarioCuidadoExtra"
                                    >
                                      {
                                        selectOptionsValorContribuicao.find(
                                          x =>
                                            x.key ===
                                            values.valorContribuicaoFuncionarioCuidadoExtra,
                                        )?.value
                                      }
                                    </Text>
                                  </div>
                                </Grid.Item>
                                <RenderConditional
                                  condition={
                                    openSelectValorFixoFuncionarioCuidadoExtra
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <br />
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Valor fixo
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="valorFixoFuncionarioCuidadoExtra"
                                      >
                                        {
                                          values.valorFixoFuncionarioCuidadoExtra
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                </RenderConditional>
                                <RenderConditional
                                  condition={
                                    openSelectValorPercentualFuncionarioCuidadoExtra
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <br />
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Definir % do valor com base do salário
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="valorPercentualFuncionarioCuidadoExtra"
                                      >
                                        {
                                          values.valorPercentualFuncionarioCuidadoExtra
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                </RenderConditional>
                                <RenderConditional
                                  condition={
                                    openSelectOutraFormaPagamentoFuncionarioCuidadoExtra
                                  }
                                >
                                  <Grid.Item xs={1} lg={1 / 3}>
                                    <br />
                                    <div>
                                      <TextLabel variant="body02-sm">
                                        Descreva neste espaço como será o
                                        custeio do pagamento
                                      </TextLabel>
                                      <Text
                                        variant="body02-sm"
                                        data-testid="textOutraFormaPagamentoFuncionarioCuidadoExtra"
                                      >
                                        {
                                          textOutraFormaPagamentoFuncionarioCuidadoExtra
                                        }
                                      </Text>
                                    </div>
                                  </Grid.Item>
                                </RenderConditional>
                              </RenderConditional>
                            </RenderConditional>
                          </Grid>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion open>
                        <Accordion.Item title="4. Informações Complementares">
                          <Grid>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Informações Complementares (Opcional)
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="textInformacoesComplementares"
                                >
                                  {textInformacoesComplementares}
                                </Text>
                              </div>
                            </Grid.Item>
                          </Grid>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion open>
                        <Accordion.Item title="5. Dados do responsável pelo preenchimento">
                          <Grid>
                            <Grid.Item xs={1} lg={1 / 2}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Nome completo
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="nomeCompleto"
                                >
                                  {values.nomeCompleto}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 3}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  Telefone
                                </TextLabel>
                                <Text
                                  variant="body02-sm"
                                  data-testid="telefone"
                                >
                                  {values.telefone}
                                </Text>
                              </div>
                            </Grid.Item>
                            <Grid.Item xs={1} lg={1 / 2}>
                              <div>
                                <TextLabel variant="body02-sm">
                                  E-mail
                                </TextLabel>
                                <Text variant="body02-sm" data-testid="email">
                                  {values.email}
                                </Text>
                              </div>
                            </Grid.Item>
                          </Grid>
                        </Accordion.Item>
                      </Accordion>
                    </Card.Content>
                    <Display justify="center" style={{ marginTop: 20 }}>
                      <Button
                        variant="secondary"
                        onClick={fecharModal}
                        data-testid="ok"
                      >
                        OK
                      </Button>
                    </Display>
                  </Display>
                </Modal>
                <Grid>
                  <Grid.Item xs={1 / 3}>
                    <Display>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={() => resetForm()}
                      >
                        Limpar
                      </Button>
                      <Button
                        data-testid="fop-visualizar"
                        type="button"
                        onClick={openModal}
                      >
                        Visualizar
                      </Button>
                      <Button
                        data-testid="enviar-fop62"
                        type="submit"
                        loading={loading}
                      >
                        Enviar
                      </Button>
                    </Display>
                  </Grid.Item>
                </Grid>
              </Card.Content>
            </Card>
          </form>
        )}
      </Formik>
    </Display>
  );
};

export default Fop062;
