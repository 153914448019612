// @ts-nocheck
import React from 'react';
import { Radio, Grid, Text, Select, TextField } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import SelectSearch from 'main/components/SelectSearch';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import { ContainerWarning } from 'previdencia/features/SolicitacaoResgate/pages/styles';
import * as S from './styles';

const FormNovaContaBancaria = ({
  formik,
  listaTiposConta,
  dadosRecuperarBancos,
  quantidadeCharInput,
  novaConta,
}: SOLICITACAO_RESGATE_TYPES.IFormNovaContaBancariaProps) => {
  return (
    <>
      <S.ContainerSelecaoNovaConta>
        <Radio
          name="isNovaConta"
          onChange={() => {
            formik.setValues({
              ...formik.values,
              contaExistente:
                CONSTS.INITIAL_STATE_EFETUAR_RESGATE.contaExistente,
              isNovaConta: true,
            });
          }}
          value="novaConta"
          checked={formik.values.isNovaConta}
        />
        <Text variant="body01-md" id="novaConta">
          {CONSTS.TEXTOS_FORM_NOVA_CONTA_BANCARIA.INDICAR_NOVA_CONTA}
        </Text>
      </S.ContainerSelecaoNovaConta>

      <RenderConditional condition={formik.values.isNovaConta}>
        <ContainerWarning>
          <Text variant="body01-md" style={{ marginBottom: '16px' }}>
            {CONSTS.TEXTOS_FORM_NOVA_CONTA_BANCARIA.AVISO_PARAGRAFO_1}
          </Text>
          <Text variant="body01-md" style={{ marginBottom: '16px' }}>
            {CONSTS.TEXTOS_FORM_NOVA_CONTA_BANCARIA.AVISO_PARAGRAFO_2}
          </Text>
          <Text variant="body01-md" style={{ marginBottom: '16px' }}>
            {CONSTS.TEXTOS_FORM_NOVA_CONTA_BANCARIA.AVISO_PARAGRAFO_3}
          </Text>
        </ContainerWarning>

        <Grid style={{ marginTop: 20, marginBottom: 20 }}>
          <Grid.Item xs={1} xl={2 / 5}>
            <Text variant="body01-md">Banco:</Text>
            <SelectSearch
              options={dadosRecuperarBancos?.map(banco => ({
                value: banco?.codigoBanco?.toString(),
                label: banco?.nomeBanco,
              }))}
              selectedItem={novaConta.banco}
              setSelectedItem={banco =>
                formik.setValues({
                  ...formik.values,
                  novaConta: {
                    ...novaConta,
                    banco,
                  },
                })
              }
            />
          </Grid.Item>

          <RenderConditional condition={!!novaConta.banco.value}>
            <Grid.Item xs={1} xl={2 / 5}>
              <Text variant="body01-md">Tipo Conta:</Text>
              <Select
                placeholder="Selecione uma opção"
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) => {
                  const tipoConta = value as unknown as {
                    codigo: string;
                    descricao: string;
                  };

                  formik.setValues({
                    ...formik.values,
                    novaConta: {
                      ...novaConta,
                      tipoConta: {
                        codigo: tipoConta.codigo,
                        descricao: tipoConta.descricao,
                      },
                    },
                  });
                }}
              >
                {listaTiposConta.map(item => (
                  <Select.Item
                    key={item.codigo}
                    value={{
                      codigo: item.codigo,
                      descricao: item.descricao,
                    }}
                    text={item.descricao}
                    selected={novaConta.tipoConta.codigo === item.codigo}
                  />
                ))}
              </Select>
            </Grid.Item>
          </RenderConditional>
        </Grid>

        <Grid style={{ marginTop: 20, marginBottom: 20 }}>
          <Grid.Item xs={1} xl={2 / 10}>
            <Text variant="body01-md">Agência:</Text>
            <TextField
              maxLength="4"
              value={novaConta.agencia}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                formik.setValues({
                  ...formik.values,
                  novaConta: {
                    ...novaConta,
                    agencia: value,
                  },
                });
              }}
            />
          </Grid.Item>

          <Grid.Item xs={1} xl={2 / 10}>
            <Text variant="body01-md">Conta:</Text>
            <TextField
              maxLength={quantidadeCharInput}
              value={novaConta.conta}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                formik.setValues({
                  ...formik.values,
                  novaConta: {
                    ...novaConta,
                    conta: value,
                  },
                });
              }}
            />
          </Grid.Item>

          <Grid.Item xs={1} xl={1 / 10}>
            <Text variant="body01-md">DV:</Text>
            <TextField
              maxLength="1"
              value={novaConta.digitoConta}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                formik.setValues({
                  ...formik.values,
                  novaConta: {
                    ...novaConta,
                    digitoConta: value,
                  },
                });
              }}
            />
          </Grid.Item>
        </Grid>
      </RenderConditional>
    </>
  );
};

export default FormNovaContaBancaria;
