// @ts-nocheck
import React from 'react';
import { Grid, Select } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import masks from 'main/utils/masks';
import { FieldType } from 'registroOcorrenciaASC/types/enum';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import { useRenderizarCamposDinamicos } from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks';
import * as UTILS from 'registroOcorrenciaASC/features/registrarOcorrencia/utils/RegistrarOcorrenciasUtils';
import * as S from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CamposDinamicos/styles';
import * as OCORRENCIA_UTILS from 'registroOcorrenciaASC/utils/RegistroOcorrenciaASCUtils';

const CamposDinamicos = (): React.ReactElement => {
  const { formDadosOcorrencia } = useRegistrarOcorrenciaContext();

  const { formik, onChange } = useRenderizarCamposDinamicos();

  return (
    <>
      {UTILS.camposDinamicosOrdenados(
        formDadosOcorrencia.listaCamposDinamicos,
      ).map(campo => (
        <>
          <RenderConditional
            condition={campo.tipoCampoApi === FieldType.NUMBER}
          >
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.TextField
                {...formik.getFieldProps(
                  formik.values[campo.nomeCampo]?.nomeCampo,
                )}
                label={`${campo.nomeCampo}${campo.obrigatorio ? '*' : ''}`}
                maxLength={campo.tamanho.toString()}
                value={formik.values[campo.nomeCampo]?.valorCampo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e, campo, masks.none.unmask);
                }}
                {...OCORRENCIA_UTILS.showErrorInput(
                  formik,
                  formik.values[campo.nomeCampo]?.nomeCampo,
                )}
              />
            </Grid.Item>
          </RenderConditional>

          <RenderConditional condition={campo.tipoCampoApi === FieldType.TEXT}>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.TextField
                {...formik.getFieldProps(
                  formik.values[campo.nomeCampo]?.nomeCampo,
                )}
                value={formik.values[campo.nomeCampo]?.valorCampo}
                maxLength={campo.tamanho.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e, campo, masks.none.unmask);
                }}
                label={`${campo.nomeCampo}${campo.obrigatorio ? '*' : ''}`}
                {...OCORRENCIA_UTILS.showErrorInput(
                  formik,
                  formik.values[campo.nomeCampo]?.nomeCampo,
                )}
              />
            </Grid.Item>
          </RenderConditional>

          <RenderConditional condition={campo.tipoCampoApi === FieldType.DATE}>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.TextField
                {...formik.getFieldProps(
                  formik.values[campo.nomeCampo]?.nomeCampo,
                )}
                label={`${campo.nomeCampo}${campo.obrigatorio ? '*' : ''}`}
                maxLength={campo.tamanho.toString()}
                value={masks.date.mask(
                  formik.values[campo.nomeCampo]?.valorCampo,
                )}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e, campo, masks.date.unmask);
                }}
                {...OCORRENCIA_UTILS.showErrorInput(
                  formik,
                  formik.values[campo.nomeCampo]?.nomeCampo,
                )}
              />
            </Grid.Item>
          </RenderConditional>

          <RenderConditional
            condition={campo.tipoCampoApi === FieldType.SELECT}
          >
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <S.Select
                {...formik.getFieldProps(
                  formik.values[campo.nomeCampo]?.nomeCampo,
                )}
                label={`${campo.nomeCampo}${campo.obrigatorio ? '*' : ''}`}
                placeholder="Escolha uma opção"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e, campo, masks.none.unmask);
                }}
                {...OCORRENCIA_UTILS.showErrorInput(
                  formik,
                  formik.values[campo.nomeCampo]?.nomeCampo,
                )}
              >
                {campo.dominios.map(dominio => (
                  <Select.Item
                    key={dominio.externalId}
                    value={dominio.externalId}
                    text={dominio.nomeDominio}
                    selected={
                      formik.values[campo.nomeCampo]?.valorCampo ===
                      dominio.externalId
                    }
                  />
                ))}
              </S.Select>
            </Grid.Item>
          </RenderConditional>
        </>
      ))}
    </>
  );
};

export default CamposDinamicos;
