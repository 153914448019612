// @ts-nocheck
import { format, addYears, differenceInYears } from 'date-fns';

export const calcularAnoAposentadoria = (
  nascimento: string | undefined,
  vigencia: string | undefined,
) => {
  return differenceInYears(
    new Date(vigencia || ''),
    new Date(nascimento || ''),
  );
};

export const calcularAnoVigencia = (
  anoNascimento: string | undefined,
  saidaPlano: number,
) => {
  const formatToDate = Date.parse(anoNascimento || '');
  const addYear = addYears(formatToDate, saidaPlano);
  return format(addYear, 'yyyy');
};

export const calcularIdadeSaida = (
  anoNascimento: string | undefined,
  vigenciaSaida: string | undefined,
): number | undefined => {
  return differenceInYears(
    new Date(vigenciaSaida?.replace(/(\d+[/])(\d+[/])/, '$2$1') || ''),
    new Date(anoNascimento || ''),
  );
};

export const formatedDateFimVigenciaAno = (
  anoAposentadoria: string | undefined,
) => {
  return `${format(Date.parse(anoAposentadoria ?? ''), 'dd/MM')}/`;
};

export const formatarVigenciaSaida = (
  vigenciaSaida: string,
  aposentadoria: string,
): string => {
  return new Date(
    Date.parse(
      `
        ${vigenciaSaida.substring(6, 11)},
        ${format(Date.parse(aposentadoria), 'MM')},
        ${format(Date.parse(aposentadoria), 'dd')},
      `,
    ),
  ).toISOString();
};
