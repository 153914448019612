// @ts-nocheck
import { DadosClienteModalTipoDocuSingProps } from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { Fundo } from 'previdencia/types/Fundo.type';
import { DadosParticipante } from 'main/types/Participante';
import {
  DadosBeneficioProtecao,
  RequestReserve,
} from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';
import { obterTelefone } from 'previdencia/features/AlterarValorContribuicao/utils/dadosParticipanteUtils';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';
import { ConverterListaFundosProps } from 'previdencia/features/AlterarValorContribuicao/types/FactoryTypes';

export const converterListaFundos = ({
  data,
  valorMinimoContribuicao,
  tipoFundo,
  exibirFundosNovos = true,
}: ConverterListaFundosProps): Fundo[] => {
  const fundos: Fundo[] = [];

  if (exibirFundosNovos) {
    data?.forEach((fundo, idx) => {
      fundos[idx] = {
        codFundo: fundo.codFundo,
        codReserva: fundo.codReserva,
        selecionado: fundo.selecionado,
        valor: 0,
        valorMinimo: Number(
          tipoFundo === DefaultValue.TIPO_FUNDO_EXISTENTE
            ? valorMinimoContribuicao
            : fundo.vlMinEntradaFamilia,
        ),
        contribuicaoAtual: Number(fundo.vlContribuicaoAtual),
        rentabilidade: Number(fundo.rentabilidadeDozeMeses),
        perfilRisco: fundo.perfilFundo,
        saldo: Number(fundo.saldoAtual),
        descFundo: fundo.nomeFundo,
        tipoFundo: tipoFundo as string,
      };
    });
  }

  return fundos;
};

export const converterFundosRequest = (
  data: Fundo[] | undefined,
): RequestReserve[] => {
  const dadosRequest: RequestReserve[] = [];

  data?.forEach((e, current) => {
    if (e.selecionado) {
      dadosRequest[current] = {
        reserveId: tryGetValueOrDefault([e.codReserva], ''),
        funds: [
          {
            fundId: e.codFundo,
            netValue: e.valor.toString(),
          },
        ],
      };
    }
  });

  return dadosRequest.filter(dado => dado !== null);
};

export const encontrarCobertura = (
  coberturas: DadosBeneficioProtecao[],
  descricaoPagamento: string,
  periodicidade = DefaultValue.PERIODICIDADE_MES,
): DadosBeneficioProtecao => {
  const selecionado = coberturas.find(
    cobertura =>
      cobertura.periodicidade === periodicidade &&
      cobertura.descricaoPagamento
        .toLowerCase()
        .includes(descricaoPagamento.toLowerCase()),
  );

  if (!selecionado)
    throw new Error('Cobertura do benefício proteção não encontrado.');

  return selecionado;
};

export function retornarDadosClienteModalTipoDocuSingProps(
  response: DadosParticipante,
): DadosClienteModalTipoDocuSingProps {
  return {
    nome: response.nome,
    cpfCnpj: response.numCpf,
    email: response.email,
    mobileNumber: obterTelefone(response.telefones),
  };
}
