// @ts-nocheck
import React from 'react';
import { Modal, Text, Display, Button } from '@cvp/design-system/react';
import { TModalAvisoTipoFundo } from '../../types/TModalConfirmacaoTransferencia';

const ModalAvisoTipoFundo: React.FC<TModalAvisoTipoFundo> = ({
  show,
  onClose,
  nextStep,
  message,
}) => {
  return (
    <Modal show={show} onClose={onClose}>
      <Text variant="headline-05" color="primary" margin>
        Confirmação
      </Text>
      <Text variant="caption" margin>
        O perfil do fundo de destino <strong>{message}</strong> que o atual.
        Deseja continuar?
      </Text>
      <br />

      <Display justify="center">
        <Button variant="secondary" small onClick={onClose}>
          Cancelar
        </Button>
        <Button small onClick={nextStep}>
          Sim
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalAvisoTipoFundo;
