// @ts-nocheck
import * as Yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import masks from 'main/utils/masks';
import {
  validarNumeroContrato,
  validarNumeroContratoSIFEC,
} from 'contratosPrestamista/utils/validadorNumeroContrato';

const UploadPropostaFormValidationSchema = Yup.object().shape({
  cpfCnpj: Yup.string()
    .required('Campo obrigatório')
    .test('cpfCnpj', 'CPF/CNPJ inválido', value => {
      const valueUnmasked = masks.numberOnly.mask(value);
      if (valueUnmasked.length === 11) return cpf.isValid(valueUnmasked);
      if (valueUnmasked.length === 14) return cnpj.isValid(valueUnmasked);
      return false;
    }),
  dtaVenda: Yup.date().required('Campo obrigatório'),
  desSistemaOrigem: Yup.string().required('Campo obrigatório'),
  codContrato: Yup.string()
    .required('Campo obrigatório')
    .when('desSistemaOrigem', {
      is: 'SIAPI',
      then: Yup.string().test(
        'Número do Contrato',
        'Número do contrato inválido',
        value => validarNumeroContrato(value),
      ),
    })
    .when('desSistemaOrigem', {
      is: 'SIAPX',
      then: Yup.string().test(
        'Número do Contrato',
        'Número do contrato inválido',
        value => validarNumeroContrato(value),
      ),
    })
    .when('desSistemaOrigem', {
      is: 'SIFEC',
      then: Yup.string().test(
        'Número do Contrato',
        'Número do contrato inválido',
        value => validarNumeroContratoSIFEC(value),
      ),
    }),
  capitalSegurado: Yup.string().required('Campo obrigatório'),
  valorCredito: Yup.string().required('Campo obrigatório'),
});

export default UploadPropostaFormValidationSchema;
