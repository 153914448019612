// @ts-nocheck
import { formatarData } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { ResponseObterDadosCertificadoPrestamista } from 'prestamista/types/ICertifucatiPrestamista';

export function dadosResumoPrestamistaFactory(
  dadosCertificadoPrestamista:
    | ResponseObterDadosCertificadoPrestamista
    | undefined,
) {
  return {
    descricaoProduto: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.descricaoProduto],
      '',
    ),
    descricaoStatusCertificado: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.descricaoStatusCertificado],
      '',
    ),
    nomeRazaoSocial: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.segurados[0]?.nomeRazaoSocial],
      '',
    ),
    cpfCnpjSegurado: masks.cpfCnpj.mask(
      tryGetValueOrDefault(
        [
          dadosCertificadoPrestamista?.segurados[0]?.cpfCnpjSeguradoFormatado.toString(),
        ],
        '',
      ),
    ),
    numeroApolice: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.numeroApolice?.toString()],
      '',
    ),
    numeroContrato: tryGetValueOrDefault(
      [dadosCertificadoPrestamista?.numeroContrato?.toString()],
      '',
    ),
    dataIniVigencia: formatarData(
      tryGetValueOrDefault([dadosCertificadoPrestamista?.dataIniVigencia], ''),
    ),
    dataFimVigencia: formatarData(
      tryGetValueOrDefault([dadosCertificadoPrestamista?.dataFimVigencia], ''),
    ),
  };
}
