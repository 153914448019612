// @ts-nocheck
import styled from 'styled-components/macro';
import { Text, Display, TextField } from '@cvp/design-system/react';
import SearchIcon from 'assets/icons/search.svg';

export const TituloPagina = styled(Text)`
  letter-spacing: 5px;
  font-weight: bold;
`;

export const SubtituloPerguntasFrequentes = styled(Display)(
  ({ theme: { color } }) => ({
    background: color.brandPrimary.gradient,
    padding: '5px',
    borderRadius: '5px',
  }),
);

export const PalavraChavePesquisada = styled.span(({ theme: { color } }) => ({
  color: color.brandSecondary.medium,
  fontWeight: 'bold',
}));

export const InputPesquisaContainer = styled.div`
  width: 40%;
`;

export const InputPesquisa = styled(TextField)`
  position: relative;

  &:after {
    content: '';
    background: url(${SearchIcon}) no-repeat;
    position: absolute;
    height: 28px;
    width: 28px;
    right: 5%;
    top: 50%;
    transform: translate(0px, -50%);
    font-size: 1.5rem;
  }
`;
