// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { useContext } from 'react';
import {
  ResponseDeclaracaoQuitacaoAnual,
  IRequestAtualizarDiaVencimento,
  UseDeclaracaoQuitacaoAnualType,
} from 'previdencia/features/DeclaracaoQuitacaoAnual/types/ResponseDeclaracaoQuitacaoAnual';

const useDeclaracaoQuitacaoAnual: UseDeclaracaoQuitacaoAnualType = (
  anoBase: string,
) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IRequestAtualizarDiaVencimento,
    ResponseDeclaracaoQuitacaoAnual
  >({
    api: { operationPath: PECOS.GerarQuitacaoAnual },
    autoFetch: false,
    payload: {
      numeroCertificado: numCertificado,
      cpfCnpj,
      ano: anoBase,
    },
  });
  const success = Boolean(response?.sucessoBFF && response?.sucessoGI);
  return {
    data: response?.entidade,
    isFetching: loading,
    isSuccess: success,
    response,
    refetch: fetchData,
    isRefetchError: !success,
  };
};

export default useDeclaracaoQuitacaoAnual;
