// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { IResponseAssinaturaManual } from '../types/TransferenciaEntreCertificados';

export const useUploadAssinatura = () => {
  const { loading, response, fetchData } = usePeco<
    FormData,
    IResponseAssinaturaManual
  >({
    api: {
      operationPath: PECOS.SolicitarFop473AssinaturaManual,
    },
    requestConfig: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    autoFetch: false,
  });

  return {
    loading,
    response: response?.entidade,
    fetchData,
  };
};
