// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS_ASC } from 'registroOcorrenciaASC/config/endpoints';
import {
  IEnviarAnexoRegistroOcorrenciaResponse,
  IUseEnviarAnexoRegistroOcorrencia,
} from 'registroOcorrenciaASC/types/AnexoRegistroOcorrencia';

export const useEnviarAnexoRegistroOcorrencia =
  (): IUseEnviarAnexoRegistroOcorrencia => {
    const {
      response: dadosEnvioAnexo,
      loading: loadingDadosEnvioAnexo,
      fetchData: enviarAnexo,
    } = usePeco<FormData, IEnviarAnexoRegistroOcorrenciaResponse>({
      api: {
        operationPath: PECOS_ASC.EnviarAnexoRegistroOcorrencia,
      },
      requestConfig: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });

    return {
      dadosEnvioAnexo,
      loadingDadosEnvioAnexo,
      enviarAnexo,
    };
  };
