// @ts-nocheck
export const PAGE_TEXTS = {
  SELECIONE_FUNDO:
    'Selecione os fundos que deseja transferir e escolha o valor total ou parcial.',
  VALOR_MINIMO_PARCIAL:
    'Se optar pela transferência parcial o valor mínimo é de ',
  IMPORTANTE:
    'Nas operações de transferência entre fundos, a reserva de origem deverá ser obrigatoriamente correspondente à reserva de destino, ou seja, não é permitido a transferência entre reservas diferentes.',
};

export const FUNDOS_DESTINO_TEXTS = {
  VALOR_TRANSFERENCIA:
    'Você escolheu realizar uma transferência entre fundos no valor de ',
  QUANTIDADE_FUNDOS_DESTINO:
    'Para prosseguir com a transferência, selecione até ',
  AQUISICAO_NOVOS_FUNDOS: 'Para aquisição de novos fundos o valor mínimo é de ',
};

export const CONFIRMAR_TRANSFERENCIA = {
  INFORMACOES_SALDO:
    'O saldo do seu certificado permanecerá zerado até a efetivação da transferência, dentro do prazo máximo de até 2 dias úteis, de acordo com a legislação vigente. Durante esse período, podem ocorrer variações na cota e no valor transferido, dependendo do tipo de fundo (renda fixa ou variável) e de sua performance no mercado financeiro.',
  INFORMACOES_COBRANCA:
    'Caso o seu certificado já tenha uma parcela de cobrança futura gerada, no momento do pagamento, ela será automaticamente direcionada para o novo fundo de investimento.',
};

export const TROCA_FUNDO = {
  FUNDO_REMOVIDO_INICIO: 'Você contribui mensalmente com  ',
  FUNDO_REMOVIDO_FIM:
    ' no entanto o fundo programado para receber este valor foi removido.',
  REDEFINIR:
    'Defina na sua carteira o(s) fundo(s) que deseja endereçar esse valor mensal.',
  VALOR_MINIMO: 'Lembrando que o valor mínimo é de ',
};
