// @ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import { IUseTabelaDistribuicaoFundos } from '../types/IUseTabelaDistribuicaoFundos';
import { usePecoVgblListarOfertas } from './usePecoVgblListarOfertas';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaAlteracaoTaxaContext } from '../types/ITransferenciaAlteracaoTaxaContext';
import useTableInputFocus from 'main/hooks/useTableInputFocus';
import { useHistory } from 'react-router';
import { IFundo } from '../types/VGBLListarOfertas';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';

export const useTabelaDistribuicaoFundos = (): IUseTabelaDistribuicaoFundos => {
  const history = useHistory();
  const { featureData, setFeatureData } =
    usePrevidenciaContext<ITransferenciaAlteracaoTaxaContext>();
  const { loading, response } = usePecoVgblListarOfertas(
    featureData?.familiaFundoEscolhida,
  );
  const [codigoProduto, setCodigoProduto] = useState('');
  const [fundosSelecionados, setFundosSelecionados] = useState<IFundo[]>([]);
  const { atribuirParametroInputFocus, selectedTableInputFocus } =
    useTableInputFocus();
  const [saldoRestante, setSaldoRestante] = useState(
    tryGetValueOrDefault([featureData?.saldoReserva], 0),
  );
  const [desabilitaPorFaltaSaldo, setDesabilitaPorFaltaSaldo] = useState(false);

  const obterPerfilFundoDestino = () =>
    fundosSelecionados
      .filter(fundo => fundo.selecionado)
      .reduce((maior, atual) => {
        return getTernaryResult(
          atual.nivelPerfilFundo > maior.nivelPerfilFundo,
          atual,
          maior,
        );
      });

  const compararPerfisFundo = () => {
    const nivelNovo = obterPerfilFundoDestino().nivelPerfilFundo;
    const nivelAtual = tryGetValueOrDefault(
      [featureData?.perfilFundoAtual?.nivel],
      0,
    );

    if (nivelNovo === nivelAtual) {
      return 'tem o mesmo perfil';
    }

    if (nivelNovo > nivelAtual) {
      return 'é mais agressivo';
    }
    return ' é mais conservador';
  };

  const obterSomaSaldoAlocado = () => {
    const fundosEscolhidos = fundosSelecionados.filter(fundo =>
      checkIfAllItemsAreTrue([
        !!fundo.selecionado,
        fundo.percentualDistribuicao > 0,
        fundo.saldo > 0,
      ]),
    );
    if (fundosEscolhidos?.length) {
      const somaValores =
        fundosEscolhidos
          .map(fundo => fundo.saldo)
          .reduce((prev, cur) => prev + cur) ?? 0;
      return +somaValores.toFixed(2);
    }
    return 0;
  };

  const desabilitarPorFaltaSaldo = () => {
    const valorMinimoDiversificacaoFundo = tryGetValueOrDefault(
      [response?.valorMinimoDiversificacaoFundo],
      0,
    );
    const saldoReserva = tryGetValueOrDefault([featureData?.saldoReserva], 0);
    if (
      saldoReserva < valorMinimoDiversificacaoFundo &&
      fundosSelecionados.filter(fundo => fundo.selecionado).length === 1
    )
      return true;
    return false;
  };

  const somaPercentual = () => {
    let totalPercentual = 0;
    const percentuais = fundosSelecionados
      .filter(fundo => fundo.selecionado)
      .map(item => Number(item.percentualDistribuicao));

    if (percentuais?.length > 0) {
      totalPercentual = percentuais.reduce((prev, cur) => prev + cur);
    }
    return totalPercentual;
  };
  const percentualAtingido = useCallback(
    () => somaPercentual() === 100,
    [fundosSelecionados],
  );

  const percentualADistribuir = useCallback(() => {
    const percentualRestanteCalculo = 100 - somaPercentual();

    return (percentualRestanteCalculo * 100) / 100;
  }, [fundosSelecionados]);

  const calcularSaldoRestante = () => {
    const saldo = tryGetValueOrDefault([featureData?.saldoReserva], 0);
    const soma = obterSomaSaldoAlocado();
    const diferenca = saldo - soma;
    if (percentualAtingido()) setSaldoRestante(0);
    else setSaldoRestante(diferenca);
  };

  const handleSelecionarFundo = useCallback(
    (fundo: IFundo) => {
      const fundosMapeados = fundosSelecionados.map(item => {
        const itemToUpdate = item;
        const itemParaSelecionar =
          itemToUpdate.codigoFundo === fundo.codigoFundo;
        if (itemParaSelecionar)
          itemToUpdate.selecionado = !itemToUpdate.selecionado;

        if (!item.selecionado) {
          itemToUpdate.percentualDistribuicao = undefined;
          itemToUpdate.saldo = undefined;
        }

        return itemToUpdate;
      });

      setFundosSelecionados([...fundosMapeados]);
      setDesabilitaPorFaltaSaldo(desabilitarPorFaltaSaldo());
    },
    [
      fundosSelecionados,
      setFundosSelecionados,
      calcularSaldoRestante,
      setDesabilitaPorFaltaSaldo,
      desabilitarPorFaltaSaldo,
    ],
  );

  const obterSaldoPorPercentual = (percentual: string) => {
    const novoPercentual = percentual?.replace(',', '.');
    if (novoPercentual) {
      const saldo = Number(featureData?.saldoReserva);
      const saldoPorcentagem = +(
        (parseFloat(novoPercentual) * saldo) /
        100
      ).toFixed(2);
      return { novoPercentual, saldoPorcentagem };
    }
    return { novoPercentual: '', saldoPorcentagem: 0 };
  };

  const handleValorTotal = (fundo: IFundo) => {
    const fundoParaSelecionarIndex = fundosSelecionados?.findIndex(
      fundoSelecionado => fundoSelecionado.codigoFundo === fundo.codigoFundo,
    );

    if (fundoParaSelecionarIndex >= 0) {
      const fundoSelecionado = fundosSelecionados[fundoParaSelecionarIndex];
      const { saldoPorcentagem } = obterSaldoPorPercentual(
        fundoSelecionado.percentualDistribuicao?.toString(),
      );

      fundosSelecionados[fundoParaSelecionarIndex].saldo = saldoPorcentagem;
      calcularSaldoRestante();
      setFundosSelecionados([...fundosSelecionados]);
      atribuirParametroInputFocus('');
    }
  };

  const formatarPercentual = (percentual: string) => {
    const hasFractions = checkIfSomeItemsAreTrue([
      percentual.includes(','),
      percentual.includes('.'),
    ]);
    if (
      checkIfAllItemsAreTrue([
        !!percentual,
        hasFractions,
        percentual?.length === 2,
      ])
    ) {
      return percentual.padEnd(1, '0');
    }
    return percentual;
  };
  const handlePercentual = (fundo: IFundo, percentual: string) => {
    const fundoParaSelecionarIndex = fundosSelecionados?.findIndex(
      fundoSelecionado => fundoSelecionado.codigoFundo === fundo.codigoFundo,
    );

    if (fundoParaSelecionarIndex >= 0) {
      const { novoPercentual } = obterSaldoPorPercentual(percentual);

      fundosSelecionados[fundoParaSelecionarIndex].percentualDistribuicao =
        formatarPercentual(novoPercentual);
      setFundosSelecionados([...fundosSelecionados]);
    }
  };

  const handleNextStep = () => {
    setFeatureData({
      ...featureData,
      fundosSelecionados: fundosSelecionados.filter(fundo => fundo.selecionado),
      codigoProduto,
    });
    history.push(
      '/cliente/produtos/previdencia/transferencia-alteracao-taxa/contribuicao',
    );
  };

  const disableButton = useCallback(
    () => fundosSelecionados.filter(fundo => fundo.selecionado).length === 0,
    [fundosSelecionados],
  );

  useEffect(() => {
    if (response) {
      const retorno = response?.produtos.find(
        fundo => fundo.familia === String(featureData?.familiaFundoEscolhida),
      );

      if (retorno) {
        setCodigoProduto(retorno.codigoProduto);
        if (retorno?.fundos.length) setFundosSelecionados(retorno?.fundos);
      }
    }
  }, [response?.produtos]);

  useEffect(() => {
    calcularSaldoRestante();
  }, [fundosSelecionados]);

  return {
    fundosDisponiveis: response,
    loadingFundosDisponiveis: loading,
    fundosSelecionados,
    featureData,
    handleSelecionarFundo,
    handlePercentual,
    handleValorTotal,
    atribuirParametroInputFocus,
    handleNextStep,
    disableButton,
    percentualAtingido,
    compararPerfisFundo,
    percentualADistribuir,
    selectedTableInputFocus,
    saldoRestante,
    quantidadeMaximaFundos: tryGetValueOrDefault(
      [response?.quantidadeMaximaDeFundos],
      0,
    ),
    valorMinimoDiversificacaoFundo: tryGetValueOrDefault(
      [response?.valorMinimoDiversificacaoFundo],
      0,
    ),
    desabilitaPorFaltaSaldo,
    saldoReserva: tryGetMonetaryValueOrDefault([featureData?.saldoReserva], 0),
  };
};
