// @ts-nocheck
import styled from 'styled-components/macro';

export const MenuContainer = styled.nav`
  transition: 0.3s transform;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &.show {
    transform: translateX(0%);
  }
  &.hide {
    transform: translateX(-100%);
  }
`;

export const BackButton = styled.button`
  background: ${props => props.theme.color.support['01']};
  border: 0;
  font-family: inherit;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  text-align: left;
  height: 47px;
  color: ${props => props.theme.color.line['08']};
  line-height: 2.9;
  padding: 4px 20px;
  width: 100%;

  .icon {
    top: 4px;
    margin-right: 10px;
  }

  img {
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
`;
