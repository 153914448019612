// @ts-nocheck
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useToast } from 'main/hooks/useToast';
import { usePecoCertificadoPrestamista } from 'prestamista/hooks/usePecoCertificadoPrestamista';
import { usePrestamistaContext } from 'prestamista/contexts/PrestamistaContextProvider';
import * as DADOS_SEGURADO_TYPES from 'prestamista/features/dadosSegurado/types/DadosSegurado';

export const useDadosSeguradoPrestamista = () => {
  const history = useHistory();

  const { state } = useLocation<{
    msgAtualizacaoDados: string;
  }>();

  const { setFeatureData } =
    usePrestamistaContext<DADOS_SEGURADO_TYPES.IDadosSeguradoContextData>();

  const { toastSuccess } = useToast();

  const { dadosCertificadoPrestamista, certificadoPrestamista } =
    usePecoCertificadoPrestamista(state?.msgAtualizacaoDados);

  const handleUpdateDadosSegurado = (): void => {
    history.push({
      pathname: `/cliente/produtos/prestamista/dados-segurado/atualizacao-dados-segurado`,
    });
  };

  const exibirMsgDadosAtualizadosAposRedirect = () => {
    if (state?.msgAtualizacaoDados) {
      toastSuccess(state?.msgAtualizacaoDados, () => {
        history.replace({
          state: {
            ...state,
            msgAtualizacaoDados: '',
          },
        });
      });
    }
  };

  useEffect(() => {
    setFeatureData({
      dadosCertificadoPrestamista,
      loadingDadosSegurado: certificadoPrestamista.loading,
    });
  }, [dadosCertificadoPrestamista, certificadoPrestamista.loading]);

  useEffect(() => {
    exibirMsgDadosAtualizadosAposRedirect();
    certificadoPrestamista.fetchData();
  }, []);

  useEffect(() => {
    if (state?.msgAtualizacaoDados) {
      certificadoPrestamista.fetchData();
    }
  }, [state?.msgAtualizacaoDados]);

  return {
    handleUpdateDadosSegurado,
    loadingDadosSegurado: certificadoPrestamista.loading,
  };
};
