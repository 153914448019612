// @ts-nocheck
import getAuthData from 'main/features/Auth/utils/auth';
import { api, IApiResponse } from 'main/services';
import { formatarData } from 'main/utils';
import {
  obterEndpointListarCancelamentos,
  obterEndpointDetalharCancelamento,
  obterEndpointEnviarToken,
  obterEndpointConfirmarToken,
} from 'contratosPrestamista/config/endpoints';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { IResponseListaSegurosCancelados } from '../types/IResponseListaSegurosCancelados';
import { IResponseDetalhesSeguroCancelado } from '../types/IResponseDetalhesSeguroCancelado';
import { IRequestSendCode, IResponseSendCode } from '../types/IRequestSendCode';
import { IRequestConfirmCode } from '../types/IRequestConfirmCode';
import * as Helpers from '../utils/helpers';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
export const obterListaSegurosCancelados = async () => {
  const {
    user: { agenciaVinculada },
  } = getAuthData();

  const resultado = await api.post<
    IApiResponse<IResponseListaSegurosCancelados[]>
  >(obterEndpointListarCancelamentos(), {
    numeroAgencia: agenciaVinculada,
    numeroCpfCnpj: '',
    dataInicial: '2021-12-29T17:55:09.163Z',
    dataFinal: '2021-12-29T17:55:09.163Z',
  });

  return resultado.data.dados.entidade?.map(item => ({
    ...item,
    dataHoraEmissaoDaProposta: formatarData(item.dataHoraEmissaoDaProposta),
    dtaFimReversao: formatarData(item.dtaFimReversao),
    reversaoPermitida: Helpers.statusAtualPermiteReversao(item.dtaFimReversao),
    valorPremio: formatarValorPadraoBrasileiro(item.valorPremio),
    valorImportanciaSegurada: formatarValorPadraoBrasileiro(
      item.valorImportanciaSegurada,
    ),
    valorPremioLiquido: formatarValorPadraoBrasileiro(item.valorPremioLiquido),
  }));
};

export const obterDetalhesSeguroCancelado = async (
  numeroProposta: string,
  numeroLinhaDoProduto: string,
  codigoDoEstipulante: string,
) => {
  const resultado = await api.post<
    IApiResponse<IResponseDetalhesSeguroCancelado>
  >(obterEndpointDetalharCancelamento(), {
    numeroProposta,
    numeroLinhaDoProduto,
    codigoDoEstipulante,
  });
  return resultado.data.dados.entidade;
};

export const enviarCodigo = async (data: IRequestSendCode) => {
  const resultado = await api.post<IApiResponse<IResponseSendCode>>(
    obterEndpointEnviarToken(),
    data,
  );
  return resultado.data;
};

export const confirmarCodigo = async (data: IRequestConfirmCode) => {
  const resultado = await api.post<IApiResponse<unknown>>(
    obterEndpointConfirmarToken(),
    data,
  );
  return resultado.data;
};
