// @ts-nocheck
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import * as CALC_UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblCalculos';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as FUNC_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblFuncParams';

export const modificarFundoSelecionadoFactory = ({
  fundo,
  codigoFundo,
}: FUNC_TYPES.ModificarFundoSelecionadoFactoryParams): RESPONSE_TYPES.IObtencaoListaOfertasVgblConjugadoProdutosFundos => {
  return fundo.codigoFundo !== codigoFundo
    ? { ...fundo }
    : { ...fundo, selecionado: !fundo.selecionado };
};

export const distribuirValorAplicacaoFactory = ({
  fundo,
  codigoFundo,
  valorInput,
  valorTotalReserva,
  valorTotalDistribuido,
  isPercentualMaximo,
  restoCalculoSaldoReservaRestante,
}: FUNC_TYPES.DistribuirValorAplicacaoFactoryParams): RESPONSE_TYPES.IObtencaoListaOfertasVgblConjugadoProdutosFundos => {
  const percentualDistribuicao: string = (Number(valorInput) / 100).toFixed(2);

  return fundo.codigoFundo !== codigoFundo
    ? { ...fundo }
    : {
        ...fundo,
        percentualDistribuicao,
        saldo: CALC_UTILS.calcularValorSaldoReserva({
          percentualDistribuicao,
          valorTotalReserva,
          sobrouResto: checkIfAllItemsAreTrue([
            valorTotalReserva !== valorTotalDistribuido,
            isPercentualMaximo,
          ]),
          restoCalculoSaldoReservaRestante,
        }),
      };
};
