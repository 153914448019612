// @ts-nocheck
import { getFieldErrorMessage } from 'main/features/prospeccao/features/questionario/utils';
import masks from 'main/utils/masks';
import React from 'react';
import { TextFieldProps } from 'sinistro/features/statusSinistro/types/TextFieldProps';

export const textFieldFormSinistroPropsFactory: TextFieldProps = (
  meta,
  label,
  setFieldValue,
  field,
  valueInput,
  onBlur,
) => {
  return {
    error: meta.touched && meta.error,
    errorMessage: getFieldErrorMessage(meta.touched, meta.error),
    label,
    onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(
        field,
        value === 'cpf' ? masks.cpf.unmask(value) : value.trim(),
      );
    },
    valueInput,
    onBlur,
  };
};
