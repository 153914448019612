// @ts-nocheck
import { ChangeEvent } from 'react';
import {
  Grid,
  Accordion,
  Card,
  Select,
  Skeleton,
} from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import FormDadosOcorrencia from 'registroOcorrenciaASC/features/registrarOcorrencia/components/FormDadosOcorrencia';
import { useRenderizarCardOcorrencia } from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as S from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CardDadosOcorrencia/styles';
import * as OCORRENCIA_STYLES from 'registroOcorrenciaASC/styles/styles';

const CardDadosOcorrencia = (): React.ReactElement => {
  const { formDadosCliente } = useRegistrarOcorrenciaContext();

  const {
    assuntoOcorrencia,
    setAssuntoOcorrencia,
    setMacroassuntoOcorrencia,
    setProdutoOcorrencia,
    dadosConsultaAssunto,
    loadingDadosConsultaAssunto,
    produtoOcorrencia,
    macroassuntoOcorrencia,
    dadosConsultaSubAssunto,
    loadingDadosConsultaSubAssunto,
    validaOpcoesOcorrencia,
  } = useRenderizarCardOcorrencia();

  return (
    <RenderConditional
      condition={checkIfSomeItemsAreTrue([
        formDadosCliente.camposObrigatorios.pessoaFisica,
        formDadosCliente.camposObrigatorios.pessoaJuridica,
      ])}
    >
      <OCORRENCIA_STYLES.CardContainer>
        <Card.Content>
          <Accordion open data-testid="accordionDadosOcorrencia">
            <S.AccordionItem
              title="Dados da ocorrência"
              spacingFooter={validaOpcoesOcorrencia}
            >
              <Grid>
                <Grid.Item xs={1} lg={5 / 10} xl={3 / 10}>
                  <Select
                    label="Produto"
                    placeholder="Escolha uma opção"
                    data-testid="inputSelectProdutoOcorrencia"
                    onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      setProdutoOcorrencia(e.target.value)
                    }
                  >
                    {CONSTS.LISTA_PRODUTO.map(item => (
                      <Select.Item
                        key={item.id}
                        value={item.id}
                        text={item.produto}
                        selected={produtoOcorrencia === item.id}
                      />
                    ))}
                  </Select>
                </Grid.Item>

                <RenderConditional condition={loadingDadosConsultaAssunto}>
                  <Grid.Item xs={1} lg={5 / 10} xl={4 / 10}>
                    <div style={{ maxWidth: '100px', marginTop: '-8px' }}>
                      <Skeleton lines={1} />
                    </div>
                    <SkeletonLoading blocks={1} lines={1} />
                  </Grid.Item>
                </RenderConditional>
                <RenderConditional
                  condition={checkIfAllItemsAreTrue([
                    !loadingDadosConsultaAssunto,
                    !!dadosConsultaAssunto?.entidade,
                  ])}
                >
                  <Grid.Item xs={1} lg={5 / 10} xl={4 / 10}>
                    <Select
                      label="Macroassunto"
                      placeholder="Escolha uma opção"
                      data-testid="inputSelectMacroassuntoOcorrencia"
                      onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        setMacroassuntoOcorrencia(e.target.value)
                      }
                    >
                      {dadosConsultaAssunto?.entidade?.assuntos?.map(item => (
                        <Select.Item
                          key={item.codigoAssunto}
                          value={item.codigoAssunto}
                          text={item.rotulo}
                          selected={
                            macroassuntoOcorrencia === item.codigoAssunto
                          }
                        />
                      ))}
                    </Select>
                  </Grid.Item>
                </RenderConditional>

                <RenderConditional condition={loadingDadosConsultaSubAssunto}>
                  <Grid.Item xs={1} lg={5 / 10} xl={3 / 10}>
                    <div style={{ maxWidth: '100px', marginTop: '-8px' }}>
                      <Skeleton lines={1} />
                    </div>
                    <SkeletonLoading blocks={1} lines={1} />
                  </Grid.Item>
                </RenderConditional>
                <RenderConditional
                  condition={checkIfAllItemsAreTrue([
                    !loadingDadosConsultaSubAssunto,
                    !!dadosConsultaSubAssunto?.entidade,
                  ])}
                >
                  <Grid.Item xs={1} lg={5 / 10} xl={3 / 10}>
                    <Select
                      label="Assunto"
                      placeholder="Escolha uma opção"
                      data-testid="inputSelectAssuntoOcorrencia"
                      dettach
                      onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        setAssuntoOcorrencia(e.target.value)
                      }
                    >
                      {dadosConsultaSubAssunto?.entidade?.assuntos?.map(
                        item => (
                          <Select.Item
                            key={item.codigoAdminAssunto}
                            value={item.codigoAdminAssunto}
                            text={item.rotulo}
                            selected={
                              assuntoOcorrencia === item.codigoAdminAssunto
                            }
                          />
                        ),
                      )}
                    </Select>
                  </Grid.Item>
                </RenderConditional>
              </Grid>
              <RenderConditional
                condition={checkIfAllItemsAreTrue([
                  !!dadosConsultaSubAssunto?.entidade,
                  !!assuntoOcorrencia,
                ])}
              >
                <FormDadosOcorrencia />
              </RenderConditional>
            </S.AccordionItem>
          </Accordion>
        </Card.Content>
      </OCORRENCIA_STYLES.CardContainer>
    </RenderConditional>
  );
};

export default CardDadosOcorrencia;
