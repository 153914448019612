// @ts-nocheck
import { useCallback, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import useTipoPessoa from 'main/hooks/useTipoPessoa';
import { converterBase64 } from 'main/utils/converterBase64';
import { ResponseInformeIR } from '../types/InformeIR';
import * as InformeIRApi from '../services/obterInformeIR.api';

export const useObterInformeIR = (cpfCnpj: string, anoBase: string) => {
  const { toastError } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const tipoPessoa = useTipoPessoa(cpfCnpj);
  const [responseIR, setResponseIR] = useState<ResponseInformeIR | undefined>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const isSucessInformeIR = useCallback(() => {
    if (responseIR?.informe) setIsSuccess(true);
  }, [responseIR]);

  const obterIR = async (): Promise<void> => {
    try {
      setLoading(true);
      const result = await InformeIRApi.obterInformeIR(
        cpfCnpj,
        anoBase,
        tipoPessoa,
      );
      const blob = converterBase64(result?.informe);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      isSucessInformeIR();
      setResponseIR(result);
    } catch (error: any) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    isSuccess,
    responseIR,
    obterIR,
  };
};
