// @ts-nocheck
import React, { useContext, useEffect } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { PE } from 'main/components/PEComponents';
import { download } from 'main/utils/download';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import useObterComprovanteResgate from 'previdencia/hooks/useObterComprovanteResgate';
import { ITransferenciaFundosFeatureData } from '../../types/ITransferenciaFundosFeatureData';
import * as CONSTS from '../../constants/constants';

const ConclusaoTransferenciaFundos = () => {
  const { cliente } = useContext(AppContext);
  const { goDadosPlano } = usePrevNavigation();

  const { featureData } =
    usePrevidenciaContext<ITransferenciaFundosFeatureData>();

  const {
    data: comprovanteResgate,
    refetch,
    isFetching,
  } = useObterComprovanteResgate(
    cliente.cpfCnpj,
    cliente.numCertificado,
    tryGetValueOrDefault([featureData?.idRequisicao], ''),
    true,
    'TRANSFUND',
  );

  const gerarComprovante = () => {
    if (comprovanteResgate?.dados.entidade?.comprovante) {
      download(comprovanteResgate?.dados.entidade?.comprovante);
    }
  };

  const imprimir = () => {
    if (!comprovanteResgate?.dados.entidade?.comprovante) {
      refetch();
    }
    gerarComprovante();
  };

  useEffect(() => {
    gerarComprovante();
  }, [comprovanteResgate]);

  return (
    <>
      <PE.Text variant="body02-md" marginBottom={15} fontWeight="bold">
        {CONSTS.CONCLUSAO_TRANSFERENCIA_FUNDOS.titulo}
      </PE.Text>
      <PE.Text variant="body02-md" marginBottom={10}>
        {CONSTS.CONCLUSAO_TRANSFERENCIA_FUNDOS.descricao}
      </PE.Text>
      <PE.Text variant="body02-md">
        <b>
          <u>Importante:</u>{' '}
        </b>
        {CONSTS.CONCLUSAO_TRANSFERENCIA_FUNDOS.importante}
      </PE.Text>
      <PE.Display marginTop={30}>
        <PE.Button variant="outlined" onClick={goDadosPlano}>
          Voltar
        </PE.Button>
        <PE.Button variant="primary" onClick={imprimir} loading={isFetching}>
          Imprimir
        </PE.Button>
      </PE.Display>
    </>
  );
};

export default ConclusaoTransferenciaFundos;
