// @ts-nocheck
import React from 'react';
import { Display, Text, Button, Radio } from '@cvp/design-system/react';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const ModalContribuicaoRegular: React.FC<
  PROPS_TYPES.ModalContribuicaoRegularProps
> = ({
  toggleModal,
  handleModal,
  handleFundoSelecionadoParaContribuicaoRegular,
}) => {
  const { incluirCuidadoGarantido, dadosOfertasVgblConjugado } =
    useTransferenciaInternaContext();

  const tabelaFundos = dadosOfertasVgblConjugado?.produtos?.[0]?.fundos;

  const tabelaFundosSelecionados = tabelaFundos?.filter(
    fundoSelecionado => fundoSelecionado.selecionado,
  );

  const contribuicaoRegularSelecionada = tabelaFundosSelecionados?.some(
    fundo => fundo.indicadorContribuicaoFundo,
  );

  return (
    <TRANSF_VGBL_STYLES.ModalTransferenciaInterna
      show={toggleModal}
      handleClose={handleModal}
      size={ENUM_TYPES.ModalInfoPredefinitionSize.MD}
      data-testid="modalContribuicaoRegular"
    >
      <TRANSF_VGBL_STYLES.Text
        align="left"
        fontWeight="bold"
        margin
        color="primary"
        variant="headline-05"
        data-testid="modalContribuicaoRegularTitulo"
      >
        {CONSTS.TEXTOS_MODAL_CONTRIBUICAO_REGULAR.TITULO}
      </TRANSF_VGBL_STYLES.Text>

      <Text
        align="left"
        variant="body02-md"
        margin
        data-testid="modalContribuicaoRegularDescricao"
      >
        {CONSTS.TEXTOS_MODAL_CONTRIBUICAO_REGULAR.DESCRICAO}
      </Text>

      {tabelaFundosSelecionados?.map(fundo => (
        <Display key={fundo.codigoFundo}>
          <Radio
            onChange={handleFundoSelecionadoParaContribuicaoRegular}
            value={fundo.codigoFundo}
            checked={fundo.indicadorContribuicaoFundo}
            name="contribuicaoRegular"
            data-testid="modalContribuicaoRegularInputSelecionarFundo"
          />
          <Text
            variant="body02-sm"
            margin
            data-testid="modalContribuicaoRegularInputDescricaoFundo"
          >
            {fundo.descricaoFundo} -{' '}
            <strong>Tx {fundo.taxaAdministracao.replace('.', ',')}%</strong>
          </Text>
        </Display>
      ))}

      <TRANSF_VGBL_STYLES.Display marginTop={20}>
        <Button
          variant="outlined"
          type="button"
          onClick={handleModal}
          data-testid="modalContribuicaoRegularFecharModal"
        >
          Voltar
        </Button>
        <Button
          variant="primary"
          onClick={incluirCuidadoGarantido}
          disabled={!contribuicaoRegularSelecionada}
          data-testid="modalContribuicaoRegularAvancarInclusaoCuidadoGarantido"
        >
          Continuar
        </Button>
      </TRANSF_VGBL_STYLES.Display>
    </TRANSF_VGBL_STYLES.ModalTransferenciaInterna>
  );
};

export default ModalContribuicaoRegular;
