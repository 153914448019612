// @ts-nocheck
import { Button, Display } from '@cvp/design-system/react';

import { InputFileFormik } from 'main/components/form/InputFile/InputFileFormik';
import useAssinaturaTransferencia from 'previdencia/features/TransferenciaEntreCertificados/hooks/useAssinaturaTransferencia';
import TransferenciaWrapper from '../components/TransferenciaWrapper/TransferenciaWrapper';
import CardCertificados from '../components/CardCertificados/CardCertificados';
import useAssinaturaManual from '../hooks/useAssinaturaManual';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';

const AssinaturaManual473 = () => {
  const { downloadArquivo, loading } = useAssinaturaTransferencia();
  const {
    isDisabled,
    loadingUpload,
    handleDefinirArquivoUpload,
    enviarArquivo,
  } = useAssinaturaManual();
  return (
    <TransferenciaWrapper>
      <CardCertificados />

      <Display
        justify="center"
        alignItems="center"
        style={{ margin: '50px 0 0 0' }}
      >
        <div style={{ margin: '0 50px 0 0' }}>
          <Button
            variant="outlined"
            loading={loading}
            disabled={loading}
            onClick={downloadArquivo}
          >
            Download
          </Button>
        </div>
        <Display>
          <InputFileFormik
            onChange={event => {
              if (event?.currentTarget?.files) {
                handleDefinirArquivoUpload(event?.currentTarget?.files[0]);
              }
            }}
          />
          <div>
            <Button
              variant="primary"
              onClick={enviarArquivo}
              disabled={checkIfSomeItemsAreTrue([loadingUpload, isDisabled])}
              loading={loadingUpload}
            >
              Enviar FOP
            </Button>
          </div>
        </Display>
      </Display>
    </TransferenciaWrapper>
  );
};

export default AssinaturaManual473;
