// @ts-nocheck
import { Grid } from '@cvp/design-system/react';
import { TextFieldDps } from './TextFieldDps';
import { SelectFieldDps } from './SelectFieldDps';
import {
  tryGetValueOrDefault,
  getTernaryResult,
  checkIfAllItemsAreTrue,
} from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { IFormFieldsProps } from '../interface/IFormFieldsProps';
import ITextFieldDpsProps from '../interface/ITextFieldDpsProps';
import { SEXO } from '../constants/texts';

export const FormFields = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleBlur,
}: IFormFieldsProps) => {
  const renderField = (fieldProps: ITextFieldDpsProps) => (
    <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
      <TextFieldDps {...fieldProps} />
    </Grid.Item>
  );

  return (
    <>
      {renderField({
        label: 'CPF/CNPJ*',
        name: 'cpfCnpj',
        placeholder: 'CPF/CNPJ',
        value: values.cpfCnpj,
        handleBlur,
        setFieldValue,
        mask: masks.cpfCnpj.mask,
        unmask: masks.cpfCnpj.unmask,
        error: tryGetValueOrDefault(
          [checkIfAllItemsAreTrue([!!touched.cpfCnpj, !!errors.cpfCnpj])],
          false,
        ),
        errorMessage: getTernaryResult(
          !!touched.cpfCnpj,
          tryGetValueOrDefault([errors.cpfCnpj], ''),
          '',
        ),
      })}
      {renderField({
        label: 'Nome*',
        name: 'nomeCliente',
        placeholder: 'Nome',
        value: values.nomeCliente,
        handleBlur,
        setFieldValue,
        error: tryGetValueOrDefault(
          [
            checkIfAllItemsAreTrue([
              !!touched.nomeCliente,
              !!errors.nomeCliente,
            ]),
          ],
          false,
        ),
        errorMessage: getTernaryResult(
          !!touched.nomeCliente,
          tryGetValueOrDefault([errors.nomeCliente], ''),
          '',
        ),
      })}
      <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
        <SelectFieldDps
          label="Sexo*"
          name="sexo"
          value={values.sexo}
          options={SEXO}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      </Grid.Item>
      {renderField({
        label: 'Data Nascimento*',
        name: 'dataNascimento',
        placeholder: 'Data Nascimento',
        value: values.dataNascimento,
        setFieldValue,
        handleBlur,
        mask: masks.date.mask,
        error: tryGetValueOrDefault(
          [
            checkIfAllItemsAreTrue([
              !!touched.dataNascimento,
              !!errors.dataNascimento,
            ]),
          ],
          false,
        ),
        errorMessage: getTernaryResult(
          !!touched.dataNascimento,
          errors.dataNascimento,
          '',
        ),
      })}
      {renderField({
        label: 'E-mail*',
        name: 'email',
        placeholder: 'E-mail',
        value: values.email,
        handleBlur,
        setFieldValue,
        error: tryGetValueOrDefault(
          [checkIfAllItemsAreTrue([!!touched.email, !!errors.email])],
          false,
        ),
        errorMessage: getTernaryResult(!!touched.email, errors.email, ''),
      })}
      {renderField({
        label: 'Telefone*',
        name: 'telefone',
        placeholder: 'Telefone',
        value: values.telefone,
        handleBlur,
        mask: masks.phone.mask,
        setFieldValue,
        error: tryGetValueOrDefault(
          [checkIfAllItemsAreTrue([!!touched.telefone, !!errors.telefone])],
          false,
        ),
        errorMessage: getTernaryResult(
          !!touched.telefone,
          tryGetValueOrDefault([errors.telefone], ''),
          '',
        ),
      })}
    </>
  );
};
