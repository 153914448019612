// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import {
  IReponseExerciciosDisponiveisIR,
  IRequestExerciciosDisponiveisIR,
} from 'previdencia/features/ServicosIR/types/IReponseExerciciosDisponiveisIR';
import { PECOS } from 'previdencia/config/endpoints';

const useObterExerciciosDisponiveisIR = (cpf: string) => {
  const { loading, response } = usePeco<
    IRequestExerciciosDisponiveisIR,
    IReponseExerciciosDisponiveisIR
  >({
    api: { operationPath: PECOS.ExerciciosDisponivesIR },
    autoFetch: true,
    payload: {
      cpf,
    },
  });
  return {
    isLoadingExerciciosDisponiveisIR: loading,
    responseExerciciosDisponiveisIR: response?.entidade,
  };
};
export default useObterExerciciosDisponiveisIR;
