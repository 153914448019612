// @ts-nocheck
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
];

export const MAX_FILE_SIZE = 5;

export const TEXTOS_UPLOAD_SALES_FORCE = {
  EXTENSOES_PERMITIDAS: `Extensões permitidas: pdf, mp3, wma, aac, ogg, wav, bmp, gif, jpg,
  png, doc, docx, xls, xlsx, ppt, pptx.`,
  FORMATOS_PERMITIDOS_UPLOAD: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'audio/mpeg',
    'audio/x-ms-wma',
    'audio/aac',
    'audio/ogg',
    'video/ogg',
    'application/ogg',
    'audio/wav',
    'image/bmp',
    'image/gif',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  MSG_FORMATO_NAO_SUPORTADO: 'Formato de arquivo não suportado.',
  TAMANHO_ARQUIVO_UPLOAD: 7,
  TAMANHO_MAX_ANEXO: 'Tamanho máximo do arquivo excedido.',
};
