// @ts-nocheck
import React from 'react';
import { Button, Grid, Select, Text } from '@cvp/design-system/react';
import { Input } from 'main/components/form';
import InputSelect from 'main/components/form/InputSelect';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { required } from 'main/features/Validation/validations';
import useFieldLink from 'main/hooks/useFieldLink';
import { formatarData } from 'main/utils/date';
import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import VoltarDadosPlano from 'main/components/Button/voltarDadosPlano/VoltarDadosPlano';
import { usePecoDatasPagamento } from 'previdencia/features/Aporte/hooks/usePecoAporte';
import * as UTILS from 'previdencia/features/Aporte/utils/aporteUtils';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const FormaPagamentoAporte: React.FC<
  APORTE_TYPES.IFormaPagamentoAporteProps
> = ({ aporteFormaPagamento }) => {
  const { loadingDadosDatasPagamento, dadosDatasPagamento } =
    usePecoDatasPagamento();

  const { featureData } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  const [tipoPagamento] = useFieldLink('');
  const valorTipoPagamento = tipoPagamento.get().value;

  const [dataDebito] = useFieldLink('');
  const valorDataDebito = dataDebito.get().value;

  const [valorContribuicao] = useFieldLink('');
  const valorValorContribuicao = valorContribuicao.get().value;

  const datasDeDebito = dadosDatasPagamento?.entidade?.map(date => (
    <Select.Item
      selected={valorDataDebito === date}
      key={date}
      value={date}
      text={formatarData(date)}
    />
  ));

  const dataPagamentoViaBoleto = dadosDatasPagamento?.entidade?.slice(-1)[0];

  const handleProximaEtapa = (): void => {
    aporteFormaPagamento.set({
      value: {
        formaPagamento: valorTipoPagamento,
        dataDebito: tryGetValueOrDefault(
          [
            UTILS.dataPagamentoRegistro(
              valorDataDebito,
              valorTipoPagamento,
              dataPagamentoViaBoleto,
            ),
          ],
          '',
        ),
        valorContribuicao: valorValorContribuicao,
      },
      isValid: true,
      errorMsg: '',
    });

    featureData?.proximaEtapa();
  };

  const habilitarProximaEtapa: boolean = checkIfSomeItemsAreTrue([
    valorTipoPagamento.length === 0,
    valorValorContribuicao.length === 0,
    Number(valorValorContribuicao) / 100 < CONSTS.VALOR_MINIMO_CONTRIBUICAO,
    valorTipoPagamento === APORTE_TYPES.EnumFormaPagamento.DEBITO
      ? valorDataDebito.length === 0
      : false,
  ]);

  if (loadingDadosDatasPagamento) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <>
      <Grid>
        <Grid.Item xs={1}>
          <Text variant="body02-sm">
            {CONSTS.FORMA_PAGAMENTO_APORTE_ADICIONAL}
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} lg={1 / 2} xl={1 / 3}>
          <InputSelect
            link={tipoPagamento}
            label={CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.INDIQUE_FORMA_PAGAMENTO}
            placeholder={
              CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.ESCOLHA_OPCAO_PAGAMENTO
            }
          >
            <Select.Item
              selected={
                valorTipoPagamento === APORTE_TYPES.EnumFormaPagamento.BOLETO
              }
              key={APORTE_TYPES.EnumFormaPagamento.BOLETO}
              value={APORTE_TYPES.EnumFormaPagamento.BOLETO}
              text={CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.BOLETO_BANCARIO}
            />
            <Select.Item
              selected={
                valorTipoPagamento === APORTE_TYPES.EnumFormaPagamento.DEBITO
              }
              key={APORTE_TYPES.EnumFormaPagamento.DEBITO}
              value={APORTE_TYPES.EnumFormaPagamento.DEBITO}
              text={CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.DEBITO_EM_CONTA}
            />
          </InputSelect>
        </Grid.Item>
        <RenderConditional
          condition={
            valorTipoPagamento === APORTE_TYPES.EnumFormaPagamento.DEBITO
          }
        >
          <Grid.Item xs={1} lg={1 / 2} xl={1 / 3}>
            <InputSelect
              link={dataDebito}
              label={
                CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.SELECIONE_DEBITO_CONTA
              }
              placeholder={
                CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.ESCOLHA_OPCAO_PAGAMENTO
              }
            >
              {datasDeDebito}
            </InputSelect>
          </Grid.Item>
        </RenderConditional>
        <RenderConditional condition={valorTipoPagamento !== ''}>
          <Grid.Item xs={1} lg={1 / 2} xl={1 / 3}>
            <Input
              label={CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.VALOR_CONTRIBUICAO}
              link={valorContribuicao}
              placeholder={
                CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.VALOR_CONTRIBUICAO_INICIAL
              }
              validationRules={[required()]}
              inputMask={masks.currency}
              legend={`Valor Mínimo ${formatarValorPadraoBrasileiro(50)}`}
              maxLength="15"
            />
          </Grid.Item>
        </RenderConditional>
      </Grid>

      <RenderConditional
        condition={
          valorTipoPagamento === APORTE_TYPES.EnumFormaPagamento.BOLETO
        }
      >
        <S.WrapperAporteWarning>
          <Text variant="body02-sm" color="warning">
            {CONSTS.INFORMACAO_PAGAMENTO_VIA_BOLETO.COMECO}
            <strong>{CONSTS.INFORMACAO_PAGAMENTO_VIA_BOLETO.MEIO}</strong>
            {CONSTS.INFORMACAO_PAGAMENTO_VIA_BOLETO.FIM}
          </Text>
        </S.WrapperAporteWarning>
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          valorTipoPagamento === APORTE_TYPES.EnumFormaPagamento.DEBITO,
          valorDataDebito !== '',
        ])}
      >
        <S.WrapperAporteWarning>
          <Text variant="body02-sm" color="warning">
            {CONSTS.FORMA_PAGAMENTO_DEBITO.DATA_SELECIONADA}
            <strong>{CONSTS.FORMA_PAGAMENTO_DEBITO.FERIADO}</strong>
            {CONSTS.FORMA_PAGAMENTO_DEBITO.DEBITO_SERA}
            <strong>{CONSTS.FORMA_PAGAMENTO_DEBITO.PROXIMO_DIA_UTIL}</strong>
          </Text>
        </S.WrapperAporteWarning>
      </RenderConditional>

      <S.ContainerAporte>
        <S.ActionsAporte>
          <>
            <VoltarDadosPlano />
            <Button
              variant="primary"
              disabled={habilitarProximaEtapa}
              onClick={handleProximaEtapa}
            >
              Avançar
            </Button>
          </>
        </S.ActionsAporte>
      </S.ContainerAporte>
    </>
  );
};

export default FormaPagamentoAporte;
