// @ts-nocheck
import { obterEndpointDadosParticipante } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { DadosParticipante } from '../types/Participante';

export const obterDadosParticipante = async (
  cpfCnpj?: string,
  numeroCertificado?: string,
): Promise<DadosParticipante | undefined> => {
  if (!cpfCnpj && !numeroCertificado) return undefined;
  const { data } = await api.post<IApiResponse<DadosParticipante | undefined>>(
    obterEndpointDadosParticipante(),
    {
      cpfCnpj,
      numeroCertificado,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
