// @ts-nocheck
import React from 'react';
import { Tooltip } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';

type TooltipWrapperProps = {
  tooltip?: string;
  left?: number;
  top?: number;
  iconName?: string;
  position?: string;
  variant?: string;
  width?: string;
  children?: React.ReactNode;
};

export const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  children,
  tooltip = '',
  left = 91,
  top = -8,
  iconName = 'warning',
  position = 'top',
  variant = 'gray',
  width = 'inherit',
}) => {
  return (
    <div style={{ position: 'relative', width }}>
      {children}
      <RenderConditional condition={!!tooltip}>
        <div
          style={{
            position: 'absolute',
            left: `${left}%`,
            top: `${top}px`,
            zIndex: '1000',
          }}
        >
          <Tooltip text={tooltip} position={position} variant={variant}>
            <Icon name={iconName} />
          </Tooltip>
        </div>
      </RenderConditional>
    </div>
  );
};
