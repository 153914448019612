// @ts-nocheck
import styled from 'styled-components/macro';
import { Modal, Text } from '@cvp/design-system/react';

export const ModalObservacoesAndamento = styled(Modal)<{
  showCloseButton: boolean;
}>(({ showCloseButton }) => ({
  '&&': {
    '& > button': {
      display: showCloseButton ? 'inline-block' : 'none',
    },
  },
}));

export const TextWithMargin = styled(Text)`
  margin: 0.2rem 0 1.2rem 0;
`;
