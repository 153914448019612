// @ts-nocheck
import styled from 'styled-components/macro';
import { Display as DisplayDS } from '@cvp/design-system/react';

export const Display = styled(DisplayDS)`
  height: 100%;
  display: flex;
  align-items: center;

  p {
    font-weight: bold;
    text-align: center;
  }
`;
