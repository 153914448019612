// @ts-nocheck
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaAlteracaoTaxaContext } from '../types/ITransferenciaAlteracaoTaxaContext';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { IUseTabelaContribuicaoRegular } from '../types/IUseTabelaContribuicaoRegular';
import { useEffect } from 'react';
import { IFundo } from '../types/VGBLListarOfertas';

const ARRAY_VAZIO: IFundo[] = [];

export const useTabelaContribuicaoRegular =
  (): IUseTabelaContribuicaoRegular => {
    const { featureData, setFeatureData } =
      usePrevidenciaContext<ITransferenciaAlteracaoTaxaContext>();

    const handleSelecionarFundo = (fundo: IFundo) => {
      const fundos = tryGetValueOrDefault(
        [featureData?.fundosSelecionados],
        ARRAY_VAZIO,
      ).map(item => ({ ...item, indicadorContribuicaoFundo: false }));

      const fundoEscollhidoIndex = fundos?.findIndex(
        fundoEscolhido => fundoEscolhido.codigoFundo === fundo.codigoFundo,
      );

      if (
        checkIfAllItemsAreTrue([
          fundoEscollhidoIndex >= 0,
          !!fundos?.[fundoEscollhidoIndex],
        ])
      ) {
        const temContribuicao =
          fundos[fundoEscollhidoIndex].indicadorContribuicaoFundo;
        fundos[fundoEscollhidoIndex].indicadorContribuicaoFundo =
          !temContribuicao;

        setFeatureData({ ...featureData, fundosSelecionados: fundos });
      }
    };

    useEffect(() => {
      const fundos = featureData?.fundosSelecionados;
      if (fundos?.length === 1) {
        const [fundo] = fundos;
        handleSelecionarFundo(fundo);
      }
    }, []);

    return {
      fundos: tryGetValueOrDefault(
        [featureData?.fundosSelecionados],
        ARRAY_VAZIO,
      ),
      handleSelecionarFundo,
      featureData,
    };
  };
