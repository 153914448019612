// @ts-nocheck
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import * as RESPONSE_TYPES from 'previdencia/features/Aporte/types/AporteResponse';

export interface IAporteContextData {
  etapaAtual?: number;
  dadosAporteAdicional?: IHandleReponseResult<RESPONSE_TYPES.IEfetuarAporteResponse>;
  isFetching?: {
    loadingDadosAporteAdicional: boolean;
  };
  pepPositivo?: boolean;
  proximaEtapa: () => void;
  etapaAnterior: () => void;
  iniciarProcessoAporte: () => void;
}

export interface IFormaPagamentoAporteProps {
  aporteFormaPagamento: LinkedValue<IAporteFormaPagamento>;
}

export interface IDeclaracaoOrigemRecursosProps {
  aporteOrigemRecursos: LinkedValue<IAporteOrigemRecursos>;
}

export interface IDadosBancariosProps {
  aporteDadosBancarios: LinkedValue<RESPONSE_TYPES.IDadosBancariosAporte>;
}

export interface IFundosDistribuicaoAporteProps {
  fundosDistribuicao: LinkedValue<RESPONSE_TYPES.IDadosFundosAporte[]>;
  aporteFormaPagamento: LinkedValue<IAporteFormaPagamento>;
  aporteDadosBancarios: LinkedValue<RESPONSE_TYPES.IDadosBancariosAporte>;
}

export interface IGerarAporteProps {
  fundosDistribuicao: LinkedValue<RESPONSE_TYPES.IDadosFundosAporte[]>;
  aporteFormaPagamento: LinkedValue<IAporteFormaPagamento>;
  aporteOrigemRecursos: LinkedValue<IAporteOrigemRecursos>;
  aporteDadosBancarios: LinkedValue<RESPONSE_TYPES.IDadosBancariosAporte>;
}

export interface IComprovanteAporteProps {
  aporteFormaPagamento: LinkedValue<IAporteFormaPagamento>;
  aporteOrigemRecursos: LinkedValue<IAporteOrigemRecursos>;
  aporteDadosBancarios: LinkedValue<RESPONSE_TYPES.IDadosBancariosAporte>;
  fundosDistribuicao: LinkedValue<RESPONSE_TYPES.IDadosFundosAporte[]>;
}

export interface IButtonsEtapasAporteProps {
  disabled: boolean;
  proximaEtapa: () => void;
  buttonLabel?: string;
  loading?: boolean;
}

export interface IComprovanteAporteAdicionalProps {
  aporteOrigemRecursos: LinkedValue<IAporteOrigemRecursos>;
}

export interface IComprovanteAporteDadosSolicitacaoProps {
  aporteFormaPagamento: LinkedValue<IAporteFormaPagamento>;
  aporteDadosBancarios: LinkedValue<RESPONSE_TYPES.IDadosBancariosAporte>;
}

export interface IComprovanteAporteTabelaFundosProps {
  fundosDistribuicao: LinkedValue<RESPONSE_TYPES.IDadosFundosAporte[]>;
}

export interface IComprovanteAporteViaDebitoProps {
  agenciaBancaria: string;
  contaBancaria: string;
  dataSelecionada: string;
  operacaoBancaria: string;
}

export interface IDadosBancariosEditarProps {
  numeroAgencia: LinkedValue<string>;
  operacao: LinkedValue<string>;
  numeroConta: LinkedValue<string>;
  digitoConta: LinkedValue<string>;
}

export interface IDadosBancariosOpracaoProps {
  operacaoLink: LinkedValue<string>;
}

export interface IFundosDistribuicaoAporteHeaderProps {
  aporteFormaPagamento: LinkedValue<IAporteFormaPagamento>;
  aporteDadosBancarios: LinkedValue<RESPONSE_TYPES.IDadosBancariosAporte>;
}

export interface IAporteDadosDistribuicaoProps {
  fundosDistribuicao: LinkedValue<RESPONSE_TYPES.IDadosFundosAporte[]>;
}

export interface IAporteDadosPagamentoProps {
  aporteFormaPagamento: LinkedValue<IAporteFormaPagamento>;
  aporteDadosBancarios: LinkedValue<RESPONSE_TYPES.IDadosBancariosAporte>;
}

export interface IMensagemExcecaoAporteProps {
  icon: string;
  mensagem: string | undefined;
}

export interface IModalNotificacaoProps {
  tipo: string;
  titulo: string;
  open: boolean;
  textoAdicional?: string;
  onClose: () => void;
}

export interface IStatusCOlorsProp {
  [key: string]: string;
}

export enum EnumFormaPagamento {
  BOLETO = 'BOLETO',
  DEBITO = 'DEBITO',
  CB = 'CB',
  FC = 'FC',
}

export interface IRequisicaoEfetuarAporteParams {
  cpfCnpj: string;
  numCertificado: string;
  aporteFormaPagamento: IAporteFormaPagamento;
  aporteDadosBancarios: RESPONSE_TYPES.IDadosBancariosAporte;
  fundosDistribuicao: RESPONSE_TYPES.IDadosFundosAporte[];
  pepPositivo: boolean | undefined;
  aporteOrigemRecursos: IAporteOrigemRecursos;
}

interface PagamentoAporte {
  DescricaoPagamento: string;
  MetodoPagamento: string;
  DataPagamento: string;
  CanalId: string;
  TipoContaBanco: string;
  NumeroBanco: string;
  NomeBanco: string;
  NumeroAgencia: string;
  DigitoAgencia: string;
  NumeroConta: string;
  DigitoConta: string;
}

export interface FundoAporte {
  ReservaId: string;
  FundoId: string;
  ValorContribuicao: string;
}

export type RequestEfetuarAporte = {
  CpfCnpj: string;
  NumeroCertificado: string;
  MultiFundo: boolean;
  FaturaImpressa: boolean;
  Pagamento: PagamentoAporte;
  Fundos: FundoAporte[];
  invoicePrintInd: boolean;
  IndPep: boolean;
  Origem: string;
  DescricaoOrigem: string;
  TipoPep: string;
};

export interface IAporteFormaPagamento {
  formaPagamento: string;
  valorContribuicao: string;
  dataDebito: string;
}

export interface IAporteOrigemRecursos {
  idOrigemPagamento: string;
  descricaoOrigemPagamento: string;
}

export interface IGerarDadosAporte {
  numeroCerfificado: string;
  formaPagamento: string;
  valorAporte: string;
  banco: string;
  operacao: string;
  agencia: string;
  contaBancaria: string;
  dataPgamento: string;
  digitoConta: string;
}

export interface IValorTotalFundosAporte {
  value: number;
  cod: string;
  selecionado: boolean;
}

export interface IModalConfirmaCertificado {
  descricaoProduto?: string;
  confirmarCertificado: boolean;
  cancelConfirmarCertificado: () => void;
  validaConfirmarCertificado: () => void;
}
