// @ts-nocheck
import React, { useMemo } from 'react';
import Table from 'main/components/Table';
import { TabelaDadosSimulacaoProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import { DadosSimulacao } from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';
import { colunasTabelaDadosSimulacaoFactory } from 'previdencia/features/SimulacaoRenda/factories/colunasTabelaDadosSimulacaoFactory';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';
import { simulacaoRendaFactory } from 'previdencia/features/SimulacaoRenda/factories/simulacaoRendaFactory';

const TabelaDadosSimulacao: React.FC<TabelaDadosSimulacaoProps> = ({
  simulacaoProgressivo,
  simulacaoRegressivo,
}) => {
  const {
    featureData: { requestSimularRenda },
  } = useSimulacaoRendaContextData();
  const dadosTabela: DadosSimulacao[] = useMemo(
    () =>
      simulacaoRendaFactory(
        simulacaoProgressivo,
        simulacaoRegressivo,
        requestSimularRenda,
      ),
    [simulacaoProgressivo, simulacaoRegressivo, requestSimularRenda],
  );

  const colunas = useMemo(
    () =>
      colunasTabelaDadosSimulacaoFactory(
        simulacaoProgressivo,
        simulacaoRegressivo,
      ),
    [simulacaoProgressivo, simulacaoRegressivo],
  );

  return (
    <Table
      noHeader
      responsive
      highlightOnHover
      striped
      data={dadosTabela}
      columns={colunas}
      noDataComponent="Não há dados para exibir."
      paginationComponentOptions={{
        rowsPerPageText: 'Items por página',
        rangeSeparatorText: 'de',
      }}
    />
  );
};

export default TabelaDadosSimulacao;
