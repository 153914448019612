// @ts-nocheck
import { useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import * as AtualizarEmailApi from 'previdencia/features/DadosParticipante/services/atualizarEmail.api';
import { IApiResponse } from 'main/services';
import { AppContext } from 'main/contexts/AppContext';
import { RequestAlterarEmail } from '../types/DadosParticipante';

const useAtualizarEmail = (
  request: RequestAlterarEmail | undefined,
  onCancelar: any,
): UseQueryResult<IApiResponse<undefined> | undefined> => {
  const { toastError, toastSuccess } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-atualizar-email', cpfCnpj],
    () => AtualizarEmailApi.atualizarEmail(cpfCnpj, numCertificado, request),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      enabled: false,
      onError: (erro: Error) => toastError(erro.message),
      onSuccess: data => {
        if (data?.dados?.mensagens) {
          onCancelar();
          toastSuccess(String(data.dados.mensagens[0].descricao));
        }
      },
    },
  );
};

export default useAtualizarEmail;
