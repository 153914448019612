// @ts-nocheck
export const getDadosTransferenciaRendaVariavel = (
  index: number,
  tipo: string,
) => {
  return `dadosTransferenciaRendaVarivavel[${index}].${tipo}`;
};

export const getDadosTransferenciaRendaFixa = (index: number, tipo: string) => {
  return `dadosTransferenciaRendaFixa[${index}].${tipo}`;
};

export const getDadosContribuicaoRegularRendaFixa = (
  index: number,
  tipo: string,
) => {
  return `fundoContribuicaoRegularRendaFixa[${index}].${tipo}`;
};
export const getContribuicaoRegularRendaVariavel = (
  index: number,
  tipo: string,
) => {
  return `fundoContribuicaoRegularRendaVariavel[${index}].${tipo}`;
};
