// @ts-nocheck
export const BOTOES_ATENDIMENTO = [
  {
    label: 'Seguro Vida',
    id: 'button_id=573Do0000000Al6',
  },
  {
    label: 'Seguro Prestamista',
    id: 'button_id=573Do0000000Aib',
  },
  {
    label: 'Previdência ',
    id: 'button_id=573Do0000000Aj0',
  },
];
