// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { IResponseSalvarArquivo } from 'painelAdministracao/types/SalvarArquivo';

export const useSalvarArquivo = () => {
  return usePeco<FormData, IResponseSalvarArquivo[]>({
    api: {
      operationPath: 'PECO_CadastrarAtualizarArquivoAssociacao',
    },
    requestConfig: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    autoFetch: false,
  });
};
