// @ts-nocheck
import React, { PropsWithChildren } from 'react';
import { Display, Text } from '@cvp/design-system/react';
import { MensagemObrigatoriaDestaqueDocuSing } from './styles';
import RenderConditional from '../RenderConditional';

const AvisoConclusaoAssinatura: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Display justify="center">
    {' '}
    <Text variant="body02-sm" color="primary" align="left">
      {' '}
      <MensagemObrigatoriaDestaqueDocuSing>
        {' '}
        Importante:
      </MensagemObrigatoriaDestaqueDocuSing>{' '}
      A efetivação da transação ocorrerá somente após clicar em “Concluir” ao
      final das etapas da assinatura eletrônica.
    </Text>{' '}
    <RenderConditional condition={!!children}>
      {children}
    </RenderConditional>{' '}
  </Display>
);
export default AvisoConclusaoAssinatura;
