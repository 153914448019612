// @ts-nocheck
import React from 'react';
import { Text, Modal, Button } from '@cvp/design-system/react';

import * as CONSTS_TEXT from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import { IModalProps } from '../../types/TransferenciaEntreCertificados';

const ModalCertificadoInvalido: React.FC<IModalProps> = ({
  openModal,
  handleCloseModal,
}) => {
  return (
    <Modal show={openModal} onClose={handleCloseModal}>
      <Text variant="headline-05" color="primary" margin>
        Atenção
      </Text>

      <Text color="text" margin>
        {CONSTS_TEXT.CERTIFICADO_INVALIDO}
      </Text>
      <div>
        <Button onClick={handleCloseModal} variant="primary">
          Ciente
        </Button>
      </div>
    </Modal>
  );
};

export default ModalCertificadoInvalido;
