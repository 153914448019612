// @ts-nocheck
import { useContext, useEffect } from 'react';
import { Button, Card, Display, Grid, Text } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { converterBase64 } from 'main/utils/converterBase64';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import useObterEmissaoCertificado from 'previdencia/features/EmissaoCertificado/hooks/useObterEmissaoCertificado';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import * as S from './styles';

const EmissaoCertificado = (): React.ReactElement => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const hookEmissaoCertificado = useObterEmissaoCertificado();
  const {
    data: responseObterContatosDefault,
    isFetched: recuperacaoEmailExecutada,
  } = useObterContatosDefaultPrevidencia();
  const { emailDefault } = responseObterContatosDefault ?? {};
  const { data, loading, emitirCertificado } = hookEmissaoCertificado;

  const objetoSendEmail = hookEmissaoCertificado.montarObjetoEmail();
  const { goDadosPlano } = usePrevNavigation();

  const handleEmitirCertificado = async () => {
    emitirCertificado(cpfCnpj, numCertificado);
  };

  useEffect(() => {
    if (data && !loading) {
      const blob = converterBase64(data);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  }, [data, loading]);

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredPermissions={[PREV_PERMISSIONS.EMITIR_CERTIFICADO]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  Emissão de Certificado
                </Text>
                <Text variant="body02-md" color="text" margin>
                  Você pode visualizar o certificado:
                </Text>
                <S.ButtonContainer>
                  <Button variant="outlined" onClick={goDadosPlano}>
                    Voltar
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleEmitirCertificado();
                    }}
                    loading={loading}
                    disabled={loading}
                  >
                    Visualizar
                  </Button>
                  <EmailSenderModal
                    defaultEmail={emailDefault}
                    disabled={
                      !(cpfCnpj && numCertificado && recuperacaoEmailExecutada)
                    }
                    objetoEmail={objetoSendEmail}
                  />
                </S.ButtonContainer>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default EmissaoCertificado;
