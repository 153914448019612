// @ts-nocheck
import { Card, Text, Grid } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as UTILS from 'previdencia/features/TransferenciaEntreCertificados/utils/TransferenciaEntreCertificados';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const CardResumoDistribuicao = ({
  formik,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ICardResumoDistribuicaoProps) => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const resultado = UTILS.calcularValores(formik, featureData);

  return (
    <Grid style={{ marginBottom: '20px' }}>
      <Grid.Item xs={1} lg={2 / 5}>
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <RenderConditional condition={resultado.calculoRestante >= 0}>
              <Text variant="body02-md">
                Restante:{' '}
                <b>{tryGetMonetaryValueOrDefault(resultado.calculoRestante)}</b>
              </Text>
            </RenderConditional>

            <RenderConditional condition={resultado.calculoRestante < 0}>
              <Text variant="body02-md">
                O valor preenchido é maior que o solicitado.
              </Text>
            </RenderConditional>

            <Text variant="body02-md">
              Total:{' '}
              <b>{tryGetMonetaryValueOrDefault(resultado.calculoTotal)}</b>
            </Text>
          </Card.Content>
        </Card>
      </Grid.Item>
    </Grid>
  );
};

export default CardResumoDistribuicao;
