// @ts-nocheck
import { useQuery } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { obterValorImportanciaSegurada } from '../service/valorImportanciaSeguradaApi';

const useObterValorImportanciaSegurada = (codCliente: string) => {
  return useQuery(
    ['valor-importancia-segurada', codCliente],
    () => obterValorImportanciaSegurada(codCliente),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
    },
  );
};

export default useObterValorImportanciaSegurada;
