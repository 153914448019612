// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import {
  ResponseHistoricoResgate,
  IRequestHistoricoResgate,
} from 'previdencia/features/CancelamentoSolicitacaoResgate/types/ResponseCancelamentoHistoricoResgate';

export const useCancelamentoHistoricoResgate = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IRequestHistoricoResgate,
    ResponseHistoricoResgate
  >({
    api: { operationPath: PECOS.ObterHistoricoResgate },
    autoFetch: true,
    payload: {
      userName: cpfCnpj,
      numeroCertificado: numCertificado,
      cpfCnpj,
    },
  });

  return { loading, response, fetchData };
};

export default useCancelamentoHistoricoResgate;
