// @ts-nocheck
import { validaCpfCnpj } from 'main/utils/cpf_cnpj';
import { validateEmail } from 'main/utils/emails';
import IValidation from '../interface/IValidation';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { MENSAGEM_CAMPO_OBRIGATORIO } from '../constants/texts';

const validations: Record<string, IValidation> = {
  cpfCnpj: {
    validate: validaCpfCnpj,
    message: 'CPF/CNPJ inválido',
    emptyMessage: MENSAGEM_CAMPO_OBRIGATORIO,
  },
  nomeCliente: {
    validate: (value: string) => !!value,
    message: MENSAGEM_CAMPO_OBRIGATORIO,
  },
  dataNascimento: {
    validate: (value: string) => !!value,
    message: MENSAGEM_CAMPO_OBRIGATORIO,
  },
  email: {
    validate: validateEmail,
    message: 'E-mail inválido',
    emptyMessage: MENSAGEM_CAMPO_OBRIGATORIO,
  },
  telefone: {
    validate: (value: string) => !!value,
    message: MENSAGEM_CAMPO_OBRIGATORIO,
  },
};

export const useFormValidation = () => {
  const validateForm = (values: any) => {
    const errors: any = {};
    Object.entries(validations).forEach(
      ([field, { validate, message, emptyMessage }]) => {
        if (!values[field]) {
          errors[field] = tryGetValueOrDefault([emptyMessage], message);
        } else {
          const validationResult = validate(values[field]);
          if (
            checkIfAllItemsAreTrue([
              validationResult !== undefined,
              validationResult !== true,
            ])
          ) {
            errors[field] = message;
          }
        }
      },
    );
    return errors;
  };

  return { validateForm };
};
