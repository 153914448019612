// @ts-nocheck
import React from 'react';
import { CardResumoProduto, InformacoesIRPF } from 'seguros/components';

const DadosApoliceInformcoesIRPFVida: React.FC = () => {
  return (
    <>
      <CardResumoProduto />
      <InformacoesIRPF />
    </>
  );
};

export default DadosApoliceInformcoesIRPFVida;
