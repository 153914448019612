// @ts-nocheck
import {
  FilterTypes,
  IFilterOption,
} from 'main/components/Table/TableFilter/IFilterOption';
import masks from 'main/utils/masks';

export const filterOptions: IFilterOption[] = [
  { key: 'numeroProposta', value: 'Certificado', type: FilterTypes.TEXT },
  {
    key: 'nome',
    value: 'Nome do cliente',
    type: FilterTypes.TEXT,
  },
  {
    key: 'cpf',
    value: 'CPF / CNPJ',
    type: FilterTypes.TEXT,
    unmask: masks.cpf.unmask,
  },
];
