// @ts-nocheck
import React, { useEffect } from 'react';
import { Modal, Button, Text } from '@cvp/design-system/react';
import { ModalMotivoProps } from 'reter/features/retencao/types/ModalMotivo';
import * as S from 'reter/features/retencao/components/ModalMotivo/styles';

const ModalMotivo: React.FC<ModalMotivoProps> = ({ data, handleClose }) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [data]);

  return (
    <Modal show={data.open} onClose={() => handleClose()}>
      <Text variant="body02-md" margin>
        <strong>Motivo:</strong>
      </Text>
      <br />
      <Text variant="body-medium3">{data.message}</Text>
      <br />
      <S.BotaoModalContainer justify="center">
        <Button
          variant="secondary"
          onClick={handleClose}
          data-testid="cancelar"
        >
          Certo, entendi
        </Button>
      </S.BotaoModalContainer>
    </Modal>
  );
};

export default ModalMotivo;
