// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import { Display, Text } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import For from 'main/components/For/For';
import {
  ContainerMultipleFiles,
  InputFileContainer,
  InputRenameFile,
  InputTagRemove,
  InputTagRename,
  TagMultipleFileInput,
  UploadButton,
} from './styles';
import Icon from 'main/components/Icon';
import { IInputFileMultipleFormikProps } from 'main/types/IInputFileMultipleFormikProps';
import {
  INPUT_FILE_DELETE,
  INPUT_FILE_DUPLICATE_FILE_ERROR,
  INPUT_FILE_RENAME,
} from 'main/constants/textosInputFileMultipleFormik';

export const InputFileMultipleFormik = ({
  setFieldValue,
  formikValue,
  maxSize = 7,
}: IInputFileMultipleFormikProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState('');
  const [rename, setRename] = useState<null | string>(null);

  const handleSplitFileName = (val: string): Array<string> => {
    return val.split('.');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: changeFiles } = e.target;
    const dataTransfer = new DataTransfer();
    const maxSizeInMB = maxSize * 1024 * 1024;

    if (formikValue) {
      Array.from(formikValue).forEach(file => {
        dataTransfer.items.add(file);
      });
    }

    if (changeFiles) {
      Array.from(changeFiles).forEach(file => {
        if (
          Array.from(dataTransfer.files).findIndex(
            existentFile => existentFile.name === file.name,
          ) === -1
        ) {
          if (file.size > maxSizeInMB) {
            setError(`Tamanho máximo do arquivo deve ser ${maxSize}MB`);
            return;
          }
          dataTransfer.items.add(file);
        }
      });
    }

    setFieldValue('arquivoAnexo', dataTransfer.files);
  };

  const handleRename = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dataTransfer = new DataTransfer();
    if (formikValue) {
      if (
        Array.from(formikValue).filter(
          file => handleSplitFileName(file.name)[0] === e.target.value,
        ).length > 0
      ) {
        setError(INPUT_FILE_DUPLICATE_FILE_ERROR);
        e.preventDefault();
        e.target.focus();
        return;
      }

      Array.from(formikValue).forEach(file => {
        if (file.name === rename) {
          dataTransfer.items.add(
            new File(
              [file],
              `${e.target.value}.${handleSplitFileName(file.name)[1]}`,
              {
                type: file.type,
              },
            ),
          );
          return;
        }
        dataTransfer.items.add(file);
      });
    }
    setFieldValue('arquivoAnexo', dataTransfer.files);
    setRename(null);
  };

  const handleRemoveFileFromList = (fileToRemove: File) => {
    const dataTransfer = new DataTransfer();

    if (formikValue) {
      Array.from(formikValue).forEach(file => {
        if (file !== fileToRemove) {
          dataTransfer.items.add(file);
        }
      });
    }
    setFieldValue(
      'arquivoAnexo',
      dataTransfer.files.length > 0 ? dataTransfer.files : null,
    );
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 10000);
    }
  }, [error]);

  return (
    <InputFileContainer>
      <UploadButton>
        <input
          ref={fileInputRef}
          multiple
          value=""
          onChange={handleChange}
          type="file"
          name="arquivoAnexo"
        />
        Selecione o arquivo
      </UploadButton>
      {!!formikValue && formikValue !== null && (
        <ContainerMultipleFiles justify="center">
          <For each={Array.from(formikValue)}>
            {(item: File, index: number) => (
              <TagMultipleFileInput
                variant="primary"
                key={tryGetValueOrDefault([item?.name], String(index))}
                value={
                  <>
                    {rename === item.name ? (
                      <InputRenameFile
                        placeholder={handleSplitFileName(item.name)[0]}
                        type="text"
                        onBlur={handleRename}
                        autoFocus={rename === item.name}
                      />
                    ) : (
                      item.name
                    )}{' '}
                    <div style={{ display: 'flex' }}>
                      <InputTagRename
                        type="button"
                        onClick={() => {
                          setRename(item.name);
                        }}
                      >
                        <Icon name="edit" /> {INPUT_FILE_RENAME}
                      </InputTagRename>
                      <InputTagRemove
                        type="button"
                        onClick={() => handleRemoveFileFromList(item)}
                      >
                        <Icon name="trash" /> {INPUT_FILE_DELETE}
                      </InputTagRemove>
                    </div>
                  </>
                }
              />
            )}
          </For>
        </ContainerMultipleFiles>
      )}
      <RenderConditional condition={!!error}>
        <Display justify="center">
          <Text
            variant="caption-02"
            color="error"
            data-testid="mensagemErroUploadFile"
          >
            {error}
          </Text>
        </Display>
      </RenderConditional>
    </InputFileContainer>
  );
};
