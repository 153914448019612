// @ts-nocheck
import { useState } from 'react';
import { IResponseConsultaDps } from '../interface/IResponseDps';

export const useModalDetalhesDps = () => {
  const [openModalDetalhes, setOpenModalDetalhes] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState<
    IResponseConsultaDps | undefined
  >();

  const handleSelecionarItem = (item: IResponseConsultaDps) => {
    setOpenModalDetalhes(true);
    setItemSelecionado(item);
  };

  return {
    openModalDetalhes,
    setOpenModalDetalhes,
    itemSelecionado,
    setItemSelecionado,
    handleSelecionarItem,
  };
};
