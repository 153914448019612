// @ts-nocheck
import styled from 'styled-components/macro';

export const Container = styled.div(() => ({
  display: 'flex',
}));

export const Label = styled.div(() => ({
  marginTop: '3px',
  marginRight: '10px',
}));

export const ButtonStyle = styled.button(() => ({
  background: 'none',
  display: 'flex',
  border: 'none',
  padding: '0',
  font: 'inherit',
  cursor: 'pointer',
  outline: 'inherit',
}));
