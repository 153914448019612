// @ts-nocheck
import { useContext, useEffect } from 'react';
import {
  Card,
  Display,
  IconButton,
  Text,
  Tooltip,
} from '@cvp/design-system/react';
import For from 'main/components/For';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import { Skeleton } from 'main/components/Table';
import { usePecoObterListaFopsAtivos } from 'main/features/Administracao/hooks/useObterListaFopsAtivos';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { ListaFopsAtivos } from 'painelAdministracao/components/ListaFopsAtivos/ListaFopsAtivos';
import ModalEditar from 'painelAdministracao/components/ModalEditar/ModalEditar';
import {
  BOTOES,
  COLUNAS_TABELA,
  SUBTITULO_FOP,
} from 'painelAdministracao/constants/constants';
import { AdmContext } from 'painelAdministracao/context/AdministracaoContext';
import { agruparDadosPorTipo } from 'painelAdministracao/factories/formatarDadosFactory';
import { TListaAgrupadaPorTipo } from 'painelAdministracao/types/ListagemTypes';
import { Accordion, AccordionItem, ContainerAccordion } from './styles';

const agrupamentoVazio: TListaAgrupadaPorTipo[] = [];

const ListagemFop = () => {
  const {
    dadosAgrupadosPorTipo,
    setDadosAgrupadosPorTipo,
    handleAdicionarFop,
    modalEditar,
  } = useContext(AdmContext);

  const { loading, response, fetchData, invalidateCache } =
    usePecoObterListaFopsAtivos();

  useEffect(() => {
    if (response?.entidade) {
      setDadosAgrupadosPorTipo(agruparDadosPorTipo(response.entidade));
    } else {
      setDadosAgrupadosPorTipo(agrupamentoVazio);
    }
  }, [response?.entidade]);

  useEffect(() => {
    if (!modalEditar) {
      invalidateCache();
      fetchData();
    }
  }, [modalEditar]);

  return (
    <>
      <Display type="display-block">
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Text variant="headline-04" color="primary" margin>
              Lista de FOPs ativos
            </Text>
            <Text variant="body02-md" color="text" margin>
              {SUBTITULO_FOP}
            </Text>
            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                loading,
                !dadosAgrupadosPorTipo.length,
              ])}
            >
              <Skeleton colunas={COLUNAS_TABELA} />
            </RenderConditional>
            <RenderConditional condition={!!dadosAgrupadosPorTipo.length}>
              <For each={dadosAgrupadosPorTipo}>
                {(item: TListaAgrupadaPorTipo, index: number) => {
                  return (
                    <Accordion fillOut key={index}>
                      <AccordionItem
                        title={
                          <Display alignItems="center">
                            <Tooltip
                              variant="gray"
                              text={BOTOES.ADICIONAR}
                              position="top"
                            >
                              <IconButton
                                onClick={() => handleAdicionarFop(item)}
                                small
                                variant="outlined"
                              >
                                <Icon name="plus" />
                              </IconButton>
                            </Tooltip>

                            <div>{item.labelTipo}</div>
                          </Display>
                        }
                      >
                        <ContainerAccordion>
                          <ListaFopsAtivos fopsAtivos={item.itens} />
                        </ContainerAccordion>
                      </AccordionItem>
                    </Accordion>
                  );
                }}
              </For>
            </RenderConditional>
          </Card.Content>
        </Card>
      </Display>
      <ModalEditar />
    </>
  );
};

export default ListagemFop;
