// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { ResponseLaminaRentabilidade } from 'previdencia/features/PosicaoConsolidada/types/ResponseLaminaRentabilidade';
import { PECOS } from 'previdencia/config/endpoints';
import { RequestLaminaRentabilidade } from 'previdencia/features/PosicaoConsolidada/types/RequestLaminaRentabilidade';

const useLaminaRentabilidade = (cpf: string) => {
  const { fetchData, loading, response } = usePeco<
    Partial<RequestLaminaRentabilidade>,
    ResponseLaminaRentabilidade[]
  >({
    api: { operationPath: PECOS.ObterLaminaRentabilidade },
    cacheTime: 0,
    payload: {
      CpfCnpj: cpf,
    },
  });

  return {
    data: response?.entidade,
    refetch: fetchData,
    isSuccess: response?.sucessoBFF && response.sucessoGI,
    isRefetchError: false,
    isFetching: loading,
  };
};

export default useLaminaRentabilidade;
