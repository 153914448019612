// @ts-nocheck
import { api, IApiResponse } from 'main/services';
import { obterEndpointObterComprovanteResgate } from 'previdencia/config/endpoints';
import { IResponseComprovanteOperacao } from 'previdencia/types/ResponseComprovante';

export const obterComprovanteOperacao = async (
  cpfCnpj: string,
  numeroCertificado: string,
  idRequisicao: string,
  codigoRequisicao: string,
  username?: string,
): Promise<IApiResponse<IResponseComprovanteOperacao | undefined>> => {
  const { data } = await api.post<IApiResponse<IResponseComprovanteOperacao>>(
    obterEndpointObterComprovanteResgate(),
    {
      cpfCnpj,
      numeroCertificado,
      codigoRequisicao,
      idRequisicao,
      username,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data;
};
