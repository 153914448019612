// @ts-nocheck
import { useAuth } from 'main/features/Auth/hooks';
import { useToast } from 'main/hooks/useToast';
import masks from 'main/utils/masks';
import { useState } from 'react';
import * as Service from '../services/service';
import {
  IConsultarStatusUploadForm,
  IConsultarStatusUploadRequest,
} from '../types/IConsultarStatusUploadForm';
import { IEntradaUploadDocumento } from '../../../types/IEntradaUploadDocumento';
import { IListarDocumentosIncluidosReponse } from '../types/IListarDocumentosIncluidosResponse';

export const useUploadPropostaAntecipado = () => {
  const { user } = useAuth();
  const { toastSuccess, toastError } = useToast();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState();
  const [listaStatusUpload, setListaStatusUpload] = useState<
    IListarDocumentosIncluidosReponse[]
  >([] as IListarDocumentosIncluidosReponse[]);

  const getNumOpercao = (entrada: IEntradaUploadDocumento) => {
    const numeroSemFormatacao = masks.numberOnly.mask(entrada.codContrato);
    if (
      entrada.desSistemaOrigem === 'SIAPX' ||
      entrada.desSistemaOrigem === 'SIAPI'
    )
      return numeroSemFormatacao.substring(6, 9);
    return '99';
  };

  const realizarUpload = async (
    entrada: IEntradaUploadDocumento,
    files: FileList,
  ) => {
    try {
      setLoading(true);
      const result = await Service.realizarUploadAnexo(
        {
          ...entrada,
          numOperacao: getNumOpercao(entrada),
          cpfCnpj: masks.cpf.unmask(entrada.cpfCnpj),
          codContrato: masks.numberOnly.mask(entrada.codContrato),
        },
        files,
      );
      if (
        result.dados &&
        result.dados.sucesso &&
        result.dados.mensagens &&
        result.dados.mensagens?.length > 0
      ) {
        toastSuccess(result?.dados?.mensagens[0].descricao);
      } else {
        toastError();
      }

      return result;
    } catch (exception: any) {
      setError(exception);
    } finally {
      setLoading(false);
    }
    return null;
  };

  const prepararPayloadConsulta = (data: IConsultarStatusUploadForm) => {
    const payload: IConsultarStatusUploadRequest = data;
    if (data.dataInicial && data.dataFinal) {
      payload.codContrato = '0';
      payload.cpfCnpj = '0';
      payload.numeroAgencia = user.agenciaVinculada.toString();
      return payload;
    }
    payload.dataFinal = new Date('1900-01-01');
    payload.dataInicial = new Date('1900-01-01');
    payload.numeroAgencia = '0';
    payload.codContrato = data.codContrato ?? '0';
    payload.cpfCnpj = data.cpfCnpj ?? '0';
    return payload;
  };

  const obterListaStatusUpload = async (data: IConsultarStatusUploadForm) => {
    try {
      setLoading(true);
      const result = await Service.obterListaDocumentosIncluidos(
        prepararPayloadConsulta(data),
      );
      if (
        result.dados &&
        result.dados.entidade &&
        result.dados.entidade.length > 0
      )
        setListaStatusUpload(result.dados.entidade);
      else {
        const [mensagem] = result?.dados?.mensagens ?? [];
        toastError(mensagem.descricao);
      }

      return result;
    } catch (exception: any) {
      setError(exception);
    } finally {
      setLoading(false);
    }
    return null;
  };

  return {
    loading,
    error,
    realizarUpload,
    obterListaStatusUpload,
    listaStatusUpload,
  };
};
