// @ts-nocheck
import React from 'react';
import { Tag } from '@cvp/design-system/react';
import { statusCertificadoMapper } from 'previdencia/utils/previdenciaMappers';

type statusCOlorsProp = {
  [key: string]: string;
};
const statusColors: statusCOlorsProp = {
  A: 'primary',
  E: 'inactive',
  U: 'primary',
  C: 'error',
};

const TagStatusDocumento: React.FunctionComponent<{ status: string }> = ({
  status,
}) => {
  return (
    <Tag
      variant={statusColors[status]}
      value={statusCertificadoMapper(status)}
    />
  );
};

export default TagStatusDocumento;
