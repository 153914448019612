// @ts-nocheck
import { obterEndpointAtualizarConsentimento } from 'previdencia/config/endpoints';

import { api, IApiResponse } from 'main/services';

export const atualizarConsentimento = async (
  cpf: string | undefined,
  produtosCaixaVidaPrevidencia: boolean,
  consentimento: boolean | undefined,
): Promise<IApiResponse<undefined> | undefined> => {
  const { data } = await api.post<IApiResponse<undefined>>(
    obterEndpointAtualizarConsentimento(),
    {
      cpf,
      sistema: {
        codigo: 'LS0116',
      },
      unidadeNegocio: {
        codigo: '11',
      },
      processoNegocio: {
        subProcesso: {
          codigo: produtosCaixaVidaPrevidencia ? '1' : '2',
        },
        macroProcesso: {
          codigo: '2',
        },
      },
      evidencia: {
        codigo: ['sistema: LS0116, cpf:', cpf].join(' '),
      },
      consentimento: {
        tipo: consentimento ? '1' : '0',
        numConsentimentoNegocio: '40',
      },
      cliente: {
        codigo: cpf,
      },
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);
  return data;
};
