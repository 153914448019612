// @ts-nocheck
import React from 'react';
import { Button, Card, Display, Grid, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { ISegundaViaApoliceProps } from 'seguros/types/SegundaViaApoliceVidaTypes';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';

export const SegundaViaApolice: React.FC<ISegundaViaApoliceProps> = ({
  error,
  gerarPdf,
  loading,
  messagemService,
}) => {
  const { goDadosSeguradoVida } = useVidaNavigation();

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[6, 8, 6, 8]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-05" color="primary" margin>
                Segunda Via Apolice Vida
              </Text>
            </Grid.Item>
          </Grid>

          <RenderConditional condition={!!error}>
            <Text variant="body02-sm" align="center" margin>
              {messagemService}
            </Text>
            <Display>
              <Button
                variant="outlined"
                onClick={goDadosSeguradoVida}
                data-testid="btnVoltar"
              >
                Voltar
              </Button>
            </Display>
          </RenderConditional>

          <RenderConditional condition={!error}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="body02-sm" margin>
                  Você pode visualizar o certificado:
                </Text>
              </Grid.Item>
              <Display>
                <Button
                  variant="outlined"
                  onClick={goDadosSeguradoVida}
                  data-testid="btnVoltar"
                >
                  Voltar
                </Button>
                <Button variant="primary" onClick={gerarPdf} loading={loading}>
                  Emitir
                </Button>
              </Display>
            </Grid>
          </RenderConditional>
        </Card.Content>
      </Card>
    </Display>
  );
};
