// @ts-nocheck
import styled from 'styled-components/macro';

export const BottomNavBar = styled.div`
  background-color: ${props => props.theme.color.neutral['08']};
  border-top: solid 1px ${props => props.theme.color.neutral['06']};
  height: 55px;
  display: flex;
  position: fixed;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  z-index: 2000;
`;
