// @ts-nocheck
import styled from 'styled-components/macro';

export const ParticipantEditContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

export const ConsultZipCode = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  button: {
    width: '100%',
  },

  [theme.breakpoint.xs()]: {
    flexDirection: 'row',
    button: {
      width: '100%',
      marginBottom: '15px',
    },
  },

  [theme.breakpoint.md() && theme.breakpoint.sm()]: {
    flexDirection: 'row',
    button: {
      width: 'unset',
      marginTop: '38px',
    },
  },
}));
