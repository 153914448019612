// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid, Pagination } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import { useCertificadoSeguroResumo } from 'seguros/hooks/useCertificadoSeguroResumo';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { CardPrestamista } from './CardPrestamista';
import TextoInformativoSemProdutos from '../../TextoInformativoSemProdutos/TextoInformativoSemProdutos';
import GridCardSkeleton from '../../../../../../main/components/GridCardSkeleton';
import { ICardProdutoPrestamista } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoPrestamista';
import { IListaProdutosPrestamistaProps } from 'consultaCliente/features/listaCardProduto/types/typesPrestamista';
import RenderConditional from 'main/components/RenderConditional';

const ListaPrestamista: React.FunctionComponent<
  IListaProdutosPrestamistaProps
> = ({ dadosPrestamista, cpfCnpj, itensPorPagina }) => {
  const history = useHistory();
  const [item, setItem] = useState<ICardProdutoPrestamista>();
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { setClientePrestamista } = useContext(AppContext);
  const [prestamistaPage, setPrestamistaPage] = useState<
    ICardProdutoPrestamista[] | undefined
  >();

  const {
    certificadoSeguros: { loading, response, fetchData },
  } = useCertificadoSeguroResumo({
    numeroContrato: tryGetValueOrDefault(
      [item?.dadosContrato?.numeroContrato?.toString()],
      '0',
    ),
    codigoProduto: tryGetValueOrDefault(
      [item?.unidadeNegocio?.codigoProdutoBu?.toString()],
      '0',
    ),
  });

  useEffect(() => {
    if (response) {
      setClientePrestamista({
        cpfCnpj,
        numeroApolice: item?.numeroApolice,
        numeroCertificado: item?.numeroCertificado,
        numeroContrato: item?.dadosContrato.numeroContrato.toString(),
        nomeTitular: item?.nomeTitular,
      });
      history.push(`/cliente/produtos/prestamista`);
    }
  }, [response]);

  useEffect(() => {
    if (item) {
      fetchData();
    }
  }, [item]);

  useEffect(() => {
    const tamanho = (dadosPrestamista || [])?.length;
    const paginas = tamanho / itensPorPagina;
    setTotalPages(tamanho % itensPorPagina === 0 ? paginas : paginas + 1);
    setPrestamistaPage(dadosPrestamista?.slice(0, itensPorPagina));
  }, [dadosPrestamista]);

  useEffect(() => {
    setPrestamistaPage(
      dadosPrestamista?.slice(
        (page - 1) * itensPorPagina,
        page * itensPorPagina,
      ),
    );
  }, [page]);

  if (loading) {
    return <GridCardSkeleton quantidadeColunas={4} />;
  }

  if ((dadosPrestamista || [])?.length === 0) {
    return <TextoInformativoSemProdutos />;
  }

  return (
    <>
      <Grid>
        {prestamistaPage?.map((itemHandle: ICardProdutoPrestamista) => (
          <Grid.Item xs={1} lg={1 / 4}>
            <CardPrestamista
              onClick={() => setItem(itemHandle)}
              data={itemHandle}
            />
          </Grid.Item>
        ))}
      </Grid>
      <br />
      <RenderConditional
        condition={
          tryGetValueOrDefault([dadosPrestamista], [])?.length > itensPorPagina
        }
      >
        <Pagination page={page} total={totalPages} onChange={setPage} />
      </RenderConditional>
    </>
  );
};

export default ListaPrestamista;
