// @ts-nocheck
import React, { useMemo } from 'react';
import { Text } from '@cvp/design-system/react';
import { Table } from 'portabilidade/components/Table';
import { formatarDataHora } from 'main/utils';
import { IDetalhesProspeccao } from 'main/features/prospeccao/types/IDetalhesProspeccao';

type HistoricoSituacaoProspeccaoProps = {
  dadosProspeccao?: IDetalhesProspeccao;
};

const HistoricoSituacaoProspeccao: React.FunctionComponent<
  HistoricoSituacaoProspeccaoProps
> = ({ dadosProspeccao }) => {
  const colunas = useMemo(
    () => [
      {
        name: 'Situação',
        selector: 'descricao',
        sortable: false,
        cell: ({ descricao, situacaoVigente }: any) => (
          <Text
            variant="body02-sm"
            color={situacaoVigente ? 'success' : 'text'}
          >
            {descricao}
          </Text>
        ),
      },
      {
        name: 'Data e hora do registro',
        selector: 'dataHoraOcorrencia',
        cell: ({ dataHoraOcorrencia, situacaoVigente }: any) => (
          <Text
            variant="body02-sm"
            color={situacaoVigente ? 'success' : 'text'}
          >
            {formatarDataHora(dataHoraOcorrencia)}
          </Text>
        ),

        sortable: true,
      },
      {
        name: 'Responsável',
        selector: 'descricao',
        sortable: false,
        cell: ({ usuarioResponsavel, situacaoVigente }: any) => (
          <Text
            variant="body02-sm"
            color={situacaoVigente ? 'success' : 'text'}
          >
            {usuarioResponsavel}
          </Text>
        ),
      },
    ],
    [],
  );
  return (
    <Table
      columns={colunas}
      noHeader
      title="Histórico da Situação"
      data={dadosProspeccao?.situacoes ?? []}
      noDataComponent="Não há dados para exibir."
    />
  );
};

export default HistoricoSituacaoProspeccao;
