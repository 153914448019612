// @ts-nocheck
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { Fundo } from 'previdencia/types/Fundo.type';

export const tratarReservasOrigemFactory = (
  fundosOrigem: Fundo[] | undefined,
): Partial<Fundo>[] => {
  const fundos = fundosOrigem?.map(fundo => ({
    fundoId: fundo.codFundo,
    valorSolicitado: Number(fundo.valorTransferencia),
    staTipRetirada: fundo.staTipRetirada,
  }));

  return tryGetValueOrDefault([fundos], []);
};
