// @ts-nocheck
import { useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import * as ConsultarConsentimentoApi from 'previdencia/features/DadosParticipante/services/consultarConsentimento.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseConsultarConsentimento } from '../types/DadosParticipante';

const useConsultarConsentimento = (
  subProcesso: string,
): UseQueryResult<ResponseConsultarConsentimento | undefined> => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-consultar-consentimento', cpfCnpj],
    () =>
      ConsultarConsentimentoApi.consultarConsentimento(
        cpfCnpj,
        numCertificado,
        subProcesso,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};

export default useConsultarConsentimento;
