// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { IConsultarSaldoResponse } from '../types/IConsultarSaldo';
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';

export const usePecoConsultarSaldo = () => {
  const { cliente } = useContext(AppContext);
  const { response, loading } = usePeco<
    { cpfCnpj: string },
    IConsultarSaldoResponse
  >({
    api: { operationPath: PECOS.ConsultarSaldoPrevidencia },
    autoFetch: true,
    payload: {
      cpfCnpj: cliente?.cpfCnpj,
    },
  });

  return {
    response: response?.entidade,
    loading,
  };
};
