// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Display, Text } from '@cvp/design-system/react';
import { buildColumnsAgencia } from 'painelInadimplencia/constants/constants';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import ModalDetalhesInadimplencia from 'painelInadimplencia/components/ModalDetalhes/ModalDetalhesInadimplencia';
import { RelatorioInadimplenciaProps } from 'painelInadimplencia/types/ConsultaInadimplenciaUsePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TableInadimplencia } from './TableInadimplencia';
import { DadosCliente } from 'painelInadimplencia/types/ConsultarInadimplenciaResponse';

const RelatorioAgencia: React.FC<RelatorioInadimplenciaProps> = ({
  loading,
  data,
}) => {
  const [details, setDetail] = useState<DadosCliente>();
  const [openModal, setOpenModal] = useState(false);

  const handleDetailsData = (dados: DadosCliente) => {
    setDetail(dados);
    setOpenModal(true);
  };
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [openModal]);
  return (
    <>
      <RenderConditional condition={!loading}>
        <Display>
          <TableInadimplencia
            noHeader
            responsive
            striped
            highlightOnHover
            data={tryGetValueOrDefault([data?.dados], [])}
            columns={buildColumnsAgencia(handleDetailsData)}
            noDataComponent="Não há dados para exibir."
            pagination
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: 'Items por página',
              rangeSeparatorText: 'de',
            }}
          />
        </Display>
        <Display>
          <RenderConditional condition={!!data?.valorTotalInadimplencia}>
            {' '}
            <Text variant="body02-sm" color="text" margin>
              Total:{' '}
              {tryGetMonetaryValueOrDefault(data?.valorTotalInadimplencia)}
            </Text>{' '}
          </RenderConditional>
        </Display>
      </RenderConditional>

      <ModalDetalhesInadimplencia
        dados={details}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default RelatorioAgencia;
