// @ts-nocheck
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import {
  convertMegaBytesToBytes,
  MAX_FILE_SIZE,
  SUPPORTED_FORMATS,
} from 'main/components/form/InputFile';
import { validarCpf } from 'main/utils/cpf_cnpj';
import masks from 'main/utils/masks';
import { ValidaInputIntervalValue } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import {
  ValidaInputMaxValue,
  ValidaInputMinValue,
} from 'previdencia/types/Forms';
import ValidationFunction from './types/ValidationFunction';

const createValidableState = (isValid: boolean, errorMsg: string) => ({
  isValid,
  errorMsg: isValid ? '' : errorMsg,
});

type ValidationFunctionBuilder<V, D = unknown> = (
  errorMessage?: (data?: D) => string,
  data?: D,
) => ValidationFunction<V>;

export const required =
  (errorMessage = () => 'Campo obrigatório'): ValidationFunction<string> =>
  (value: string) => {
    return createValidableState(value.length > 0, errorMessage());
  };

export const email: ValidationFunctionBuilder<string> =
  (errorMessage = () => 'Email inválido') =>
  value => {
    return createValidableState(/(^\w.*@\w+\.\w)/.test(value), errorMessage());
  };

export const minLength: ValidationFunctionBuilder<string, number> =
  (errorMessage = _min => `Tamanho mínimo é ${_min} caracteres`, min = 0) =>
  value => {
    return createValidableState(value.length >= min, errorMessage(min));
  };

export const requiredFile: ValidationFunctionBuilder<FileList> =
  (errorMessage = () => 'Por favor, anexe um arquivo.') =>
  value => {
    return createValidableState(value.length > 0, errorMessage());
  };

export const filesFormatsAllowed: ValidationFunctionBuilder<
  FileList,
  string[]
> =
  (
    errorMessage = () => `Formato de arquivo não suportado.`,
    formatsAllowed = SUPPORTED_FORMATS,
  ) =>
  value => {
    return createValidableState(
      value &&
        Array.from(value).every(item => {
          return formatsAllowed.includes(item.type);
        }),
      errorMessage(),
    );
  };

export const maxFileSizeAllowed: ValidationFunctionBuilder<FileList, number> =
  (
    errorMessage = () => `Tamanho máximo do arquivo excedido.`,
    maxSizeMb = MAX_FILE_SIZE,
  ) =>
  value => {
    return createValidableState(
      Array.from(value).every(
        item => item.size <= convertMegaBytesToBytes(maxSizeMb),
      ),
      errorMessage(),
    );
  };

export const validDateRange: ValidationFunctionBuilder<CalendarData> =
  (errorMessage = () => 'Informe um período válido.') =>
  ({ initialDate, finalDate }) => {
    return createValidableState(!!initialDate && !!finalDate, errorMessage());
  };

export const validDate: ValidationFunctionBuilder<CalendarData> =
  (errorMessage = () => 'Informe o ano de nascimento.') =>
  value => {
    return createValidableState(!!value.initialDate, errorMessage());
  };
export const minValue: ValidationFunctionBuilder<string, ValidaInputMinValue> =
  (
    errorMessage = validacaoInputMinValue =>
      `O valor deve ser maior que ${validacaoInputMinValue?.min}`,
    valoresValidacao = {
      min: 0,
      select: true,
    },
  ) =>
  value => {
    const valor = parseFloat(masks.currencyInput.unmask(value)) / 100;
    return createValidableState(
      valor >= valoresValidacao.min || valoresValidacao.select || false,
      errorMessage(valoresValidacao),
    );
  };

export const maxValue: ValidationFunctionBuilder<string, ValidaInputMaxValue> =
  (
    errorMessage = validacaoInputMinValue =>
      `O valor deve ser menor que ${validacaoInputMinValue?.max}`,
    valoresValidacao = {
      max: 0,
      select: true,
    },
  ) =>
  value => {
    const valor = parseFloat(masks.currencyInput.unmask(value)) / 100;
    return createValidableState(
      valor <= valoresValidacao.max || valoresValidacao.select || false,
      errorMessage(valoresValidacao),
    );
  };

export const validCpf: ValidationFunctionBuilder<string, string> =
  (errorMessage = () => `CPF inválido`) =>
  value => {
    return createValidableState(validarCpf(value) === true, errorMessage());
  };

export const intervalValue: ValidationFunctionBuilder<
  string,
  ValidaInputIntervalValue
> =
  (
    errorMessage = validacaoInputMinValue =>
      `O valor deve ser maior ou igual a ${validacaoInputMinValue?.min} e menor ou igual a ${validacaoInputMinValue?.max}`,
    valoresValidacao = {
      min: 0,
      max: 100,
    },
  ) =>
  value => {
    const valor = parseFloat(value);

    return createValidableState(
      valor >= valoresValidacao.min && valor <= valoresValidacao.max,
      errorMessage(valoresValidacao),
    );
  };
