// @ts-nocheck
import { useContext } from 'react';

import { usePeco } from 'main/hooks/usePeco';
import { AppContext } from 'main/contexts/AppContext';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { PECOS } from 'previdencia/config/endpoints';
import {
  ListaTiposContasBancarias,
  ResponseRecuperarContribuicoesCertificado,
  ResponseResponsavelFinanceiro,
} from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';
import { DefaultValue } from 'previdencia/features/AlteracaoFormaDadosPagamento/types/enum';
import * as REQUEST_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamentoRequest';

export const usePecoListarContasBancarias = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const {
    response: dadosListaContasBancarias,
    loading: loadingDadosListaContasBancarias,
    fetchData: obterListaContasBancarias,
  } = usePeco<
    REQUEST_TYPES.IRequestListarContasBancarias,
    ListaTiposContasBancarias[]
  >({
    api: { operationPath: PECOS.ListarContasBancarias },
    autoFetch: true,
    payload: {
      cpfCnpj,
      numeroCertificado: numCertificado,
    },
  });

  return {
    dadosListaContasBancarias: dadosListaContasBancarias?.entidade,
    loadingDadosListaContasBancarias,
    obterListaContasBancarias,
  };
};

export const usePecoResponsavelFinanceiroFormaPagamento = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const {
    response: dadosResponsavelFinanceiro,
    loading: loadingDadosResponsavelFinanceiro,
    fetchData: obterResponsavelFinanceiro,
  } = usePeco<
    REQUEST_TYPES.IRequestResponsavelFinanceiro,
    ResponseResponsavelFinanceiro
  >({
    api: { operationPath: PECOS.ResponsavelFinanceiro },
    autoFetch: true,
    payload: {
      cpfCnpj,
      numeroCertificado: numCertificado,
    },
  });

  return {
    dadosResponsavelFinanceiro: dadosResponsavelFinanceiro?.entidade,
    loadingDadosResponsavelFinanceiro,
    obterResponsavelFinanceiro,
  };
};

export const usePecoRecuperarContribuicoesCertificado = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const {
    response: dadosRecuperacaoContribuicoesCertificado,
    loading: loadingDadosRecuperacaoContribuicoesCertificado,
    fetchData: obterRecuperacaoContribuicoesCertificado,
  } = usePeco<
    REQUEST_TYPES.IRequestRecuperarContribuicoesCertificado,
    ResponseRecuperarContribuicoesCertificado
  >({
    api: { operationPath: PECOS.RecuperarContribuicoesCertificado },
    autoFetch: true,
    payload: {
      cpfCnpj,
      numeroCertificado: numCertificado,
      categoria: DefaultValue.TIPO_RECUPERACAO_CONTRIBUICOES_RE,
    },
  });

  return {
    dadosRecuperacaoContribuicoesCertificado:
      dadosRecuperacaoContribuicoesCertificado?.entidade,
    loadingDadosRecuperacaoContribuicoesCertificado,
    obterRecuperacaoContribuicoesCertificado,
  };
};

export const usePecoCriarCanalPagamento = ({
  idPessoa,
  metodoPagamento,
  dadosPagamento,
}: Partial<REQUEST_TYPES.IRequestCriarCanalPagamento>) => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  const {
    response: dadosCanalPagamento,
    loading: loadingDadosCanalPagamento,
    fetchData: criarCanalPagamento,
  } = usePeco<
    Partial<REQUEST_TYPES.IRequestCriarCanalPagamento>,
    REQUEST_TYPES.IResponseCriarCanalPagamento
  >({
    api: { operationPath: PECOS.CriarCanalPagamento },
    payload: {
      cpfCnpj,
      idPessoa,
      metodoPagamento,
      dadosPagamento,
    },
  });

  const isSuccessCriarCanalPagamento = checkIfAllItemsAreTrue([
    !!dadosCanalPagamento?.sucessoBFF,
    !!dadosCanalPagamento?.sucessoGI,
  ]);

  return {
    dadosCanalPagamento: dadosCanalPagamento?.entidade,
    loadingDadosCanalPagamento,
    criarCanalPagamento,
    isSuccessCriarCanalPagamento,
    canalPagamentoId: dadosCanalPagamento?.entidade?.canalId,
  };
};

export const usePecoAtualizarFormaPagamento = ({
  tipoContribuicao,
  canalId,
}: Partial<REQUEST_TYPES.IRequestAtualizarFormaPagamento>) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const {
    response: dadosAtualizacaoFormaPagamento,
    loading: loadingDadosAtualizacaoFormaPagamento,
    fetchData: atualizarFormaPagamento,
  } = usePeco<
    Partial<REQUEST_TYPES.IRequestAtualizarFormaPagamento>,
    undefined
  >({
    api: { operationPath: PECOS.AtualizarFormaPagamento },
    payload: {
      cpfCnpj,
      numeroCertificado: numCertificado,
      tipoContribuicao,
      canalId,
    },
  });

  const isSuccessAtualizarFormaPagamento = checkIfAllItemsAreTrue([
    !!dadosAtualizacaoFormaPagamento?.sucessoBFF,
    !!dadosAtualizacaoFormaPagamento?.sucessoGI,
  ]);

  return {
    dadosAtualizacaoFormaPagamento: dadosAtualizacaoFormaPagamento?.entidade,
    loadingDadosAtualizacaoFormaPagamento,
    atualizarFormaPagamento,
    isSuccessAtualizarFormaPagamento,
  };
};
