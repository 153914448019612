// @ts-nocheck
import { IDadosBasicosCertificadoPrevidencia } from 'main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { Fundo } from 'previdencia/types/Fundo.type';
import {
  ConfirmacaoTransferencia,
  ResponseConfirmarTransferencia,
} from '../types/ConfirmarTransferencia.types';
import {
  EtapaTransferencia,
  ResponseDefinirReservaDestino,
  ResponseFundosContrib,
} from '../types/DefinirReservaDestino.types';

import {
  IPendencias,
  IResponseRevalidarTransferencia,
} from '../../../types/IRevalidarTransferencia';
import { ValidacaoFundos } from '../types/TransferenciaEntreFundos.type';
import {
  IPayloadReservasOrigem,
  IResponseTransferenciaEntreFundos,
} from 'previdencia/types/ITransferirEntreFundos';
import { obterValorTotalFundosRequest } from 'previdencia/utils/tabelaFundos';

export const obterProdutoPeloCertificado = (
  data: IDadosBasicosCertificadoPrevidencia[] | undefined,
  numCertificado: string,
): string | null => {
  const result = data?.find(
    element => element.certificadoNumero === numCertificado,
  );

  return result?.produto.descricaoProduto || null;
};

export const validarDadosServico = (
  data: IResponseTransferenciaEntreFundos | undefined,
  request: IPayloadReservasOrigem[],
): ValidacaoFundos | undefined => {
  if (data) {
    let isError = false;
    let mensagem = '';
    if (data.sta === 'ERR') {
      isError = true;
      mensagem = data.desSta;
    }
    if (
      data.qndDispFundoExistente === 0 &&
      data.vlrMinimo > obterValorTotalFundosRequest(request || [])
    ) {
      isError = true;
      mensagem =
        'O certificado não possui fundos de destino existentes, e o valor da transferência é menor que valor mínimo para aquisição de um novo fundo';
    }
    return { isError, mensagem };
  }
  return undefined;
};

export const verificarValorMaximo = (
  data: Fundo[] | undefined,
  valorPermanencia: string | undefined,
): string[] | undefined => {
  const errorMensage: string[] = [];
  const valorConvertido = parseInt(valorPermanencia || '', 10);
  let isError = false;
  data?.forEach(e => {
    if (e.selecionado && e.isParcial) {
      if (e.saldo - e.valor < (valorConvertido || 0)) {
        isError = true;
        errorMensage.push(e.descFundo);
      }
    }
  });
  if (isError) {
    return errorMensage;
  }

  return undefined;
};

export const verificarFundosPreenchidos = (
  data: Fundo[] | undefined,
): boolean => {
  let dadosInvalidos = false;
  let sum = 0;

  data?.forEach(e => {
    if (e.selecionado) {
      sum += e.valor;
    }
  });

  if (sum === 0) {
    dadosInvalidos = true;
  }

  return dadosInvalidos;
};

export const converterValorMinimo = (
  vlrMinPermancenciaFundo: string | undefined,
): number => {
  return parseInt(vlrMinPermancenciaFundo || '', 10);
};

export const verificarMudancaPerfil = (
  pendencias: IPendencias[] | undefined,
): boolean => {
  return (
    pendencias !== undefined &&
    pendencias.length > 0 &&
    pendencias[0].codigoErro === 'ALERTA'
  );
};

export const verificaRevalidar = (
  revalidarProcesso: boolean,
  dataRevalidarTransferencia: IResponseRevalidarTransferencia | undefined,
): boolean => {
  return !!(revalidarProcesso && dataRevalidarTransferencia);
};
export const verificarErroRetornadoServico = (
  retornoRevalidar: IResponseRevalidarTransferencia | undefined,
  revalidar: boolean | undefined,
): boolean => {
  if (retornoRevalidar === undefined) {
    return false;
  }
  return (
    retornoRevalidar?.pendenciasRevalidarTransferencia.length > 0 &&
    retornoRevalidar?.pendenciasRevalidarTransferencia[0].codigoErro !==
      'ALERTA' &&
    !revalidar
  );
};

export const verificarLoadsDadosResgate = (
  loadingCertificadosPrevidencia: boolean,
  isLoading: boolean,
  fetchingFundosDestino: boolean,
): boolean => {
  return loadingCertificadosPrevidencia || isLoading || fetchingFundosDestino;
};

export const verificarExistenciaRequestEFundosDestino = (
  fundosDestino: IResponseTransferenciaEntreFundos | undefined,
  request: IPayloadReservasOrigem[] | undefined,
) => {
  return fundosDestino && request;
};

export const confirmarTransferenciaRetorno = (
  mudancaPerfil: boolean,
  realizarContribuicaoRegular: boolean,
  dataDefinirReservaDestino: ResponseDefinirReservaDestino | undefined,
  dataRevalidarTransferencia: IPendencias[] | undefined,
): ConfirmacaoTransferencia => {
  if (mudancaPerfil) {
    if (realizarContribuicaoRegular && dataDefinirReservaDestino) {
      return 'contribuicaoRegular';
    }
    return 'numTransferencia';
  }
  if (
    !mudancaPerfil &&
    dataRevalidarTransferencia &&
    dataRevalidarTransferencia.length > 0
  ) {
    return 'perfil';
  }
  return undefined;
};

export const verificarRealizacaoContribuicaoRegular = (
  fundosContrib: ResponseFundosContrib[],
  contribuicaoRegularFeita: boolean | undefined,
): boolean => {
  return fundosContrib && !contribuicaoRegularFeita;
};

export const validaLoading = (
  loadingDefinirReservaDestino: boolean,
  loadingRevalidarTransferencia: boolean,
  loadingConfirmarTransferenciaEntreFundos: boolean | undefined,
): boolean => {
  return !!(
    loadingDefinirReservaDestino ||
    loadingRevalidarTransferencia ||
    loadingConfirmarTransferenciaEntreFundos
  );
};

export const direcionarDefinirFundos = (
  status: string | undefined,
  direcionarNovaTela: (telaDestino: EtapaTransferencia) => void,
): void => {
  if (status === 'ERR') {
    direcionarNovaTela('definirFundosDestino');
  }
};

export const direcionarTelaFinalizacaoTransferencia = (
  confirmarTransferenciaEntreFundos: ResponseConfirmarTransferencia | undefined,
  permissaoAssinatura: boolean | undefined,
  direcionarNovaTela: (telaDestino: EtapaTransferencia) => void,
): void => {
  if (
    checkIfAllItemsAreTrue([
      !!confirmarTransferenciaEntreFundos?.sucessoOperacao,
      !!permissaoAssinatura,
    ])
  ) {
    direcionarNovaTela('comprovanteTransferencia');
  }
  if (
    checkIfAllItemsAreTrue([
      !!confirmarTransferenciaEntreFundos?.sucessoOperacao,
      !permissaoAssinatura,
    ])
  ) {
    direcionarNovaTela('conclusaoTransferencia');
  }
};
