// @ts-nocheck
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { IResponseConsultaDps } from '../interface/IResponseDps';
import { formatarData } from 'main/utils';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import LinkDPSButton from '../components/LinkDPSButton';
import StatusDpsCircle from '../components/StatusDpsCircle';

export const colunasTabelaDps = (
  handleSelecionarItem: (item: IResponseConsultaDps) => void,
) => [
  {
    name: 'CPF/CNPJ',
    selector: (row: IResponseConsultaDps) => row.cpfCnpj,
    sortable: true,
    minWidth: '190px',
    cell: (row: IResponseConsultaDps) => <>{masks.cpfCnpj.mask(row.cpfCnpj)}</>,
  },
  {
    name: 'Proponente',
    selector: (row: IResponseConsultaDps) => row.nomeCliente,
    sortable: true,
    left: true,
    cell: (row: IResponseConsultaDps) => (
      <>{tryGetValueOrDefault([row.nomeCliente], '-')}</>
    ),
  },
  {
    name: 'Importância segurada acumulada',
    selector: (row: IResponseConsultaDps) => row.valorAcumulo,
    sortable: true,
    minWidth: '170px',
    cell: (row: IResponseConsultaDps) => (
      <>
        {getTernaryResult(
          !row.valorAcumulo,
          '-',
          tryGetMonetaryValueOrDefault([row.valorAcumulo]),
        )}
      </>
    ),
  },
  {
    name: 'Validade DPS',
    selector: (row: IResponseConsultaDps) => row.envioDpsAte,
    sortable: true,
    center: true,
    minWidth: '100px',
    cell: (row: IResponseConsultaDps) => (
      <>
        {getTernaryResult(!row.envioDpsAte, '-', formatarData(row.envioDpsAte))}
      </>
    ),
  },
  {
    name: 'Status DPS',
    selector: (row: IResponseConsultaDps) => row.statusEnvioDPS,
    sortable: true,
    center: true,
    width: '250px',
    cell: (row: IResponseConsultaDps) => (
      <StatusDpsCircle statusEnvioDPS={row.statusEnvioDPS} />
    ),
  },
  {
    name: 'Link DPS',
    selector: 'acao',
    sortable: true,
    center: true,
    minWidth: '200px',
    allowOverflow: true,
    position: 'fixed',
    cell: (row: IResponseConsultaDps) => (
      <LinkDPSButton row={row} handleSelecionarItem={handleSelecionarItem} />
    ),
  },
];
