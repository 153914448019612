// @ts-nocheck
import styled from 'styled-components';
import { Accordion as AccordionBase } from '@cvp/design-system/react';

export const ContainerAccordion = styled.div`
  padding: 4px;
`;

const AccordionItemStyle = AccordionBase.Item;

export const Accordion = styled(AccordionBase)(() => ({
  padding: 0,
  marginBottom: '20px',
}));

export const AccordionItem = styled(AccordionItemStyle)(
  ({ theme: { color }, open }) => ({
    padding: 0,
    background: 'transparent',
    border: open
      ? `2px solid ${color.brandPrimary.light}`
      : `1px solid ${color.neutral['04']}`,
    div: {
      color: color.brandPrimary.light,
      paddingTop: open ? '1px' : 0,
      paddingBottom: open ? '1px' : 0,
    },

    p: {
      span: {
        div: {
          background: 'white',
          marginTop: open && '-5px',
        },
      },
    },

    'p:after': {
      content: 'none',
    },
    'p:before': {
      content: 'none',
    },
  }),
);
