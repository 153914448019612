// @ts-nocheck
import styled from 'styled-components/macro';
import { Grid } from '@cvp/design-system/react';

export const Label = styled.label({
  display: 'inline-block',
  marginBottom: '8px',
  fontFamily: 'CAIXA Std',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '150%',
});

export const Container = styled.div({
  marginBottom: '2rem ',
  width: '100%',
});

export const GridItem = styled(Grid.Item)({
  alignSelf: 'stretch',
});
