// @ts-nocheck
import React from 'react';
import { Modal, Text, Display, Button } from '@cvp/design-system/react';
import { TModalConfirmacaoTransferencia } from '../../types/TModalConfirmacaoTransferencia';

const ModalAssinaturaManual: React.FC<TModalConfirmacaoTransferencia> = ({
  show,
  onClose,
  nextStep,
}) => {
  return (
    <Modal show={show} onClose={onClose}>
      <Text variant="headline-05" color="primary" margin>
        Importante
      </Text>
      <Text variant="caption" margin>
        O processo de transferencia somente será concluído após o upload do
        formulário assinado pelo cliente.
      </Text>
      <br />

      <Display justify="center">
        <Button small onClick={nextStep}>
          OK
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalAssinaturaManual;
