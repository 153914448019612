// @ts-nocheck
import React, { useContext } from 'react';
import { Button, Display, Text, Card, Divider } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { formatarData } from 'main/utils';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import TagStatusApolice from 'main/components/TagStatusApolice/TagStatusApolice';
import * as S from '../styles';
import NomeCertificadoCardItem from '../NomeCertificadoCardItem';
import { ICardPrestamistaProps } from 'consultaCliente/features/listaCardProduto/types/typesPrestamista';
import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const CardPrestamista: React.FunctionComponent<
  ICardPrestamistaProps
> = ({ onClick, data }) => {
  const { nomeSocial } = useContext(AppContext);

  return (
    <Card>
      <S.Container>
        <Card.Content padding={[1, 5, 0]}>
          <Display alignItems="center">
            <S.Text
              statusLength={
                data?.dadosContrato?.situacaoContrato?.length === 26 ? 8 : 12
              }
              title={data?.produto?.nomeProduto}
              status={
                data?.dadosContrato?.situacaoContrato !== null
                  ? data?.dadosContrato?.situacaoContrato?.substr(0, 1)
                  : 'C'
              }
            >
              {data?.produto?.nomeProduto}
            </S.Text>
          </Display>
          <Display justify="end">
            <TagStatusApolice status={data?.dadosContrato.situacaoContrato} />
          </Display>
        </Card.Content>
        <Divider />
        <Card.Content padding={[1, 6, 0]}>
          <Display alignItems="center">
            <S.IconContainer>
              <Icon name="creditCardVerse" />
            </S.IconContainer>
            <span>
              <Text variant="caption-02">Apólice</Text>
              <Text variant="body01-sm">{data.numeroApolice}</Text>
            </span>
          </Display>
          <NomeCertificadoCardItem
            nomeCliente={tryGetValueOrDefault([nomeSocial], data.nomeTitular)}
            numCertificado={data.numeroCertificado?.toString()}
            tipoProduto="Prestamista"
          />
          <Display alignItems="center">
            <S.IconContainer>
              <Icon name="calendar" />
            </S.IconContainer>
            <span>
              <Text variant="caption-02">Vigência</Text>
              <Text variant="body03-md">
                {formatarData(data.dataHoraInicioVigencia)} -{' '}
                {formatarData(data.dataHoraFimVigencia)}
              </Text>
            </span>
          </Display>
        </Card.Content>
        <FeatureAuthorizer
          requiredRoles={[
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
          ]}
        >
          <Card.Content padding={[1, 4, 0]}>
            <Display justify="space-between">
              <Button onClick={onClick} fullWidth small>
                Detalhes
              </Button>
            </Display>
          </Card.Content>
        </FeatureAuthorizer>
      </S.Container>
    </Card>
  );
};
