// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { useContext } from 'react';
import { tipoEmailConstants } from 'main/constants/tipoEmail';

export const useEnviarDadosPlanoEmail = (): any => {
  const { cliente } = useContext(AppContext);

  const montarObjetoEmail = () => {
    return {
      tipoEmail: tipoEmailConstants.COBERTURA_PLANO_PREVIDENCIA,
      parametrosEnvio: {
        cpfCnpj: cliente.cpfCnpj,
        numeroCertificado: cliente.numCertificado,
      },
    };
  };
  return {
    montarObjetoEmail,
  };
};
