// @ts-nocheck
import { Grid, Text, Disclaimer, Button } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import Icon from 'main/components/Icon';
import TableFilter from 'main/components/Table/TableFilter/TableFilter';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as API from 'registroOcorrenciaASC/features/consultarOcorrencia/hooks';
import FormConsultaOcorrencia from 'registroOcorrenciaASC/features/consultarOcorrencia/components/FormConsultaOcorrencia';
import * as CONSTS from 'registroOcorrenciaASC/features/consultarOcorrencia/constants/constants';
import * as S from 'registroOcorrenciaASC/features/consultarOcorrencia/pages/ConsultarOcorrencia/styles';

const ConsultarOcorrencia = () => {
  const {
    listaHistoricoSolicitacao,
    obterHistoricoSolicitacao,
    setListaHistoricoSolicitacao,
    loadingDadosHistoricoSolicitacao,
    mensagemErro,
    colunas,
    limparEIniciarNovaConsulta,
  } = API.useConsultarDadosOcorrencia();

  return (
    <S.Card>
      <S.Card.Content padding={[6, 8, 6, 8]}>
        <Grid>
          <Grid.Item xs={1}>
            <Text
              variant="headline-05"
              color="primary"
              margin
              data-testid="tituloConsultaOcorrencia"
            >
              Consultar Ocorrência
            </Text>
          </Grid.Item>
        </Grid>

        <RenderConditional condition={!listaHistoricoSolicitacao?.length}>
          <FormConsultaOcorrencia
            obterHistoricoSolicitacao={obterHistoricoSolicitacao}
            setListaHistoricoSolicitacao={setListaHistoricoSolicitacao}
            loadingDadosHistoricoSolicitacao={loadingDadosHistoricoSolicitacao}
          />
        </RenderConditional>

        <RenderConditional
          condition={checkIfAllItemsAreTrue([
            !listaHistoricoSolicitacao?.length,
            !!mensagemErro,
          ])}
        >
          <S.Disclaimer variant="error">
            <Disclaimer.Content
              text={mensagemErro}
              icon={<Icon name="information" />}
            />
          </S.Disclaimer>
        </RenderConditional>

        <RenderConditional condition={!!listaHistoricoSolicitacao?.length}>
          <TableFilter
            filterTextPartial
            dataToFilter={tryGetValueOrDefault([listaHistoricoSolicitacao], [])}
            filterOptions={CONSTS.FILTRO_OPCOES_TABELA_HISTORICO_SOLICITACAO}
            data-testid="tableFilter"
            customButton={
              <Button onClick={limparEIniciarNovaConsulta}>
                Nova Consulta
              </Button>
            }
          >
            {dataFiltered => (
              <S.Table
                noHeader
                responsive
                data={dataFiltered}
                columns={colunas}
                noDataComponent={CONSTS.NAO_HA_DADOS_TABELA}
                highlightOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: 'Items por página',
                  rangeSeparatorText: 'de',
                }}
              />
            )}
          </TableFilter>
        </RenderConditional>
      </S.Card.Content>
    </S.Card>
  );
};

export default ConsultarOcorrencia;
