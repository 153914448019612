// @ts-nocheck
import { Text } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import * as S from './styles';

const CardResumoDeParaBeneficioProtecao = ({
  dePara,
  valorContribuicao,
  valorImportanciaSegurada,
  tipoBeneficio,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ICardResumoDeParaBeneficioProtecaoProps) => {
  return (
    <S.ContainerDeParaOpcoes>
      <Text variant="body01-sm">
        <b>{dePara.toUpperCase()}</b>
      </Text>

      <RenderConditional condition={!!tipoBeneficio}>
        <Text variant="body01-sm" id="tipoBeneficio">
          {tipoBeneficio}
        </Text>
      </RenderConditional>

      <div>
        <div>
          <Text variant="body01-sm">
            <b>Contribuição</b>
          </Text>
          <Text variant="body01-sm">
            Valor: {tryGetMonetaryValueOrDefault(valorContribuicao)}
          </Text>
        </div>

        <div>
          <Text variant="body01-sm">
            <b>Importância segurada</b>
          </Text>
          <Text variant="body01-sm">
            Valor: {tryGetMonetaryValueOrDefault(valorImportanciaSegurada)}
          </Text>
        </div>
      </div>
    </S.ContainerDeParaOpcoes>
  );
};

export default CardResumoDeParaBeneficioProtecao;
