// @ts-nocheck
import styled from 'styled-components';
import { Display, Button, Text } from '@cvp/design-system/react';
import RiskBar from 'previdencia/components/RiskBar';

export const RiskBarStyle = styled(RiskBar)(() => ({
  minHeight: '60px',
  minWidth: '100px',
}));

export const SelectContainer = styled.div({
  marginTop: 5,
});

export const TextAlign = styled(Text)({
  margin: '30px 20px 0px 20px',
});

export const DisplayContainer = styled(Display)({
  display: 'flex',
  alignItems: 'center',
});

export const ButtonStyle = styled(Button)({
  marginTop: 10,
  marginLeft: 10,
});
