// @ts-nocheck
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef } from 'react';

import Icon from 'main/components/Icon';
import { AuthContext } from 'main/features/Auth/contexts/AuthContext';
import guid from 'main/utils/guid';
import RenderConditional from 'main/components/RenderConditional';
import { useFeatureAuthorizer } from 'main/features/Auth/hooks/useFeatureAuthorizer';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  Sidebar,
  ListItems,
  Item,
  ListSubItems,
  SubItem,
  AccordionItem,
  Accordiont,
} from './styles';
import { NavLink } from 'react-router-dom';
import { IMenuItem, IMenuSubItem, SideBarProps } from './types';

export const enableMenuItem = (iMenuItemId: string, active: boolean) => {
  const element = document.getElementById(iMenuItemId);
  if (element) {
    if (active) element.style.display = 'block';
    else element.style.display = 'none';
  }
};

const SidebarMenu: React.FunctionComponent<SideBarProps> = ({ menu }) => {
  const { verifyPermissions, verifyRoles, verifySubitens } =
    useFeatureAuthorizer();
  const { userRoles, userPermissions } = useContext(AuthContext);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const removeMenuTarget = () => {
    if (sidebarRef.current) {
      const menuAberto = sidebarRef.current.querySelector('.menu-target');
      menuAberto?.classList.remove('menu-target');
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        removeMenuTarget();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef]);

  const hasActiveSubItem = (subItem: IMenuSubItem[]) => {
    const path = window.location.pathname;
    return subItem.find(item => path === item.path);
  };

  const { items } = menu;

  const verificarExibicaoItem = (item: IMenuItem) => {
    if (tryGetValueOrDefault([item?.subItems], []).length > 0) {
      return checkIfAllItemsAreTrue([
        verifyRoles(item.roles ?? [], userRoles),
        verifyPermissions(item.permissions, userPermissions),
        verifySubitens(item.subItems, userRoles, userPermissions),
      ]);
    }
    return true;
  };
  const renderIcon = ({ src, alt, icon }: IMenuItem) => {
    return (
      <div className="image-container">
        {src ? <img src={src} alt={alt} /> : icon && <Icon name={icon} />}
      </div>
    );
  };

  const handleFocus = (event: React.KeyboardEvent<HTMLLIElement>) => {
    const accordionTarget = event.currentTarget;
    const accordionOpen = event.currentTarget.querySelector(
      '.accordion-selector',
    );
    if (accordionOpen) {
      const primeiraDiv = accordionOpen.querySelector('div');
      const segundaDiv = accordionOpen.querySelector('div:nth-child(2)');
      if (primeiraDiv) {
        primeiraDiv.classList.add('accordionBorder');
      }
      accordionOpen.classList.add('accordionBorder');
      if (accordionTarget && segundaDiv) {
        accordionTarget.classList.add('accordion-target');
        segundaDiv.classList.remove('accordion-fechado');
        segundaDiv.classList.add('accordion-items');
      }
    }
  };

  const handleBlur = (event: any) => {
    const accordionTarget = event.currentTarget;
    const accordionOpen = event.currentTarget.querySelector(
      '.accordion-selector',
    );
    if (accordionOpen) {
      removeMenuTarget();
      accordionOpen.classList.remove('accordionBorder');
      const primeiraDiv = accordionOpen.querySelector('div');
      if (primeiraDiv) {
        primeiraDiv.classList.remove('accordionBorder');
      }
      const segundaDiv = accordionOpen.querySelector('div:nth-child(2)');
      if (accordionTarget && segundaDiv) {
        accordionTarget.classList.remove('accordion-target');
        segundaDiv.classList.remove('accordion-items');
      }
    }
  };

  const handleFocusItem = (event: any, item: IMenuItem) => {
    if (tryGetValueOrDefault([item.subItems], []).length > 0) {
      removeMenuTarget();
      const menuTarget = event.currentTarget;
      const submenu = event.currentTarget.querySelector('ul');

      if (submenu) {
        const firstSubmenuItem = submenu.querySelector('ul');
        if (checkIfAllItemsAreTrue([firstSubmenuItem, menuTarget])) {
          menuTarget.classList.add('menu-target');
        }
      }
    }
  };

  const RenderNavLinkWithPermissionCheck: React.FC<{ item: IMenuItem }> = ({
    item,
  }) => {
    return (
      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          verifyRoles(item.roles ?? [], userRoles),
          verifyPermissions(item.permissions, userPermissions),
        ])}
      >
        <NavLink
          exact
          activeClassName="active"
          to={item.path ?? '#'}
          id={item.id}
          style={{ display: item.initialHidden ? 'none' : 'block' }}
        >
          {renderIcon(item)}
          {item.label}
        </NavLink>
      </RenderConditional>
    );
  };

  const RenderSubItemThirdLayer: React.FC<{
    item: IMenuSubItem;
  }> = ({ item }) => {
    const showSubitem = checkIfAllItemsAreTrue([
      verifyRoles(item.roles ?? [], userRoles),
      verifyPermissions(item.permissions, userPermissions),
    ]);

    return (
      <RenderConditional condition={showSubitem}>
        <SubItem key={guid()} tabIndex={0}>
          <Accordiont onFocus={handleFocus} onBlur={handleBlur} tabIndex={0}>
            <AccordionItem
              aria-label={item.label}
              tabIndex={0}
              title={item.label}
              key={guid()}
              className="accordion-selector"
            >
              {tryGetValueOrDefault([item.subItems], []).map(subSubItem => {
                return (
                  <RenderConditional
                    condition={checkIfAllItemsAreTrue([
                      verifyRoles(subSubItem.roles ?? [], userRoles),
                      verifyPermissions(
                        subSubItem.permissions,
                        userPermissions,
                      ),
                    ])}
                  >
                    <NavLink
                      aria-label={subSubItem.label}
                      key={guid()}
                      to={subSubItem.path}
                    >
                      <div
                        style={{
                          width: '95%',
                          margin: ' 0 auto',
                          padding: 0,
                        }}
                      >
                        <span>{subSubItem.label}</span>
                      </div>
                    </NavLink>
                  </RenderConditional>
                );
              })}
            </AccordionItem>
          </Accordiont>
        </SubItem>
      </RenderConditional>
    );
  };

  const RenderSubItemSecondLayer: React.FC<{
    item: IMenuItem;
  }> = ({ item }) => {
    return (
      <>
        <ListSubItems key={guid()}>
          {tryGetValueOrDefault([item.subItems], []).map(subitem => {
            if (
              checkIfAllItemsAreTrue([
                verifyRoles(subitem.roles ?? [], userRoles),
                verifyPermissions(subitem.permissions, userPermissions),
                !!subitem.subItems,
              ])
            ) {
              return (
                <SubItem key={guid()}>
                  <Accordiont
                    tabIndex={0}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  >
                    <AccordionItem
                      title={subitem.label}
                      key={guid()}
                      className="accordion-selector"
                      aria-label={subitem.label}
                    >
                      {tryGetValueOrDefault([subitem.subItems], []).map(
                        subSubItem => {
                          return (
                            <>
                              {subSubItem.subItems ? (
                                <RenderSubItemThirdLayer item={subSubItem} />
                              ) : (
                                <RenderConditional
                                  condition={checkIfAllItemsAreTrue([
                                    verifyRoles(
                                      subSubItem.roles ?? [],
                                      userRoles,
                                    ),
                                    verifyPermissions(
                                      subSubItem.permissions,
                                      userPermissions,
                                    ),
                                  ])}
                                >
                                  <NavLink
                                    key={guid()}
                                    to={subSubItem.path}
                                    aria-label={subSubItem.label}
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                        margin: ' 0 auto',
                                        padding: 0,
                                      }}
                                    >
                                      <span>{subSubItem.label} </span>
                                    </div>
                                  </NavLink>
                                </RenderConditional>
                              )}
                            </>
                          );
                        },
                      )}
                    </AccordionItem>
                  </Accordiont>
                </SubItem>
              );
            }
            return (
              <RenderConditional
                condition={checkIfAllItemsAreTrue([
                  verifyRoles(subitem.roles ?? [], userRoles),
                  verifyPermissions(subitem.permissions, userPermissions),
                ])}
              >
                <SubItem aria-label={item.label} key={guid()} tabIndex={0}>
                  <NavLink
                    key={guid()}
                    activeClassName="active"
                    to={subitem.path}
                    tabIndex={-1}
                  >
                    {subitem.label}
                  </NavLink>
                </SubItem>
              </RenderConditional>
            );
          })}
        </ListSubItems>
        <RenderConditional condition={!!item.subItems}>
          <span
            key={guid()}
            className={
              hasActiveSubItem(tryGetValueOrDefault([item.subItems], [])) &&
              'active'
            }
          >
            {renderIcon(item)}
            {item.label}
          </span>
        </RenderConditional>
      </>
    );
  };

  const RenderMenuSubItemsWithPermissionCheck: React.FC<{
    item: IMenuItem;
  }> = ({ item }) => {
    return (
      <RenderConditional condition={verificarExibicaoItem(item)}>
        <Item
          aria-label={item.label}
          tabIndex={0}
          onFocus={e => handleFocusItem(e, item)}
          key={guid()}
          fixedColor={item.fixedColor}
          className={item.subItems && 'with-subitems'}
        >
          {tryGetValueOrDefault([item.subItems], [])?.length > 0 ? (
            <RenderSubItemSecondLayer item={item} key={guid()} />
          ) : (
            <RenderNavLinkWithPermissionCheck key={guid()} item={item} />
          )}
        </Item>
      </RenderConditional>
    );
  };

  return (
    <Sidebar ref={sidebarRef} role="navigation" aria-label="Menu Lateral">
      <ListItems data-section="menu">
        <RenderConditional condition={!!items}>
          {items?.map((item: IMenuItem) => (
            <RenderMenuSubItemsWithPermissionCheck key={guid()} item={item} />
          ))}
        </RenderConditional>
      </ListItems>
    </Sidebar>
  );
};

export default SidebarMenu;
