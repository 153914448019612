// @ts-nocheck
import React from 'react';
import { Card, Skeleton } from '@cvp/design-system/react';
import * as S from './styles';

const SkeletonLoading: React.FC<{ lines?: number; blocks?: number }> = ({
  lines,
  blocks,
}) => {
  return (
    <S.SkeletonLoading>
      {Array.from(Array(blocks).keys()).map(k => (
        <Card key={k} data-testid="card-skeleton-block">
          <Card.Content padding={[4, 4, 4]}>
            <Skeleton lines={lines} />
          </Card.Content>
        </Card>
      ))}
    </S.SkeletonLoading>
  );
};

SkeletonLoading.defaultProps = {
  lines: 4,
  blocks: 1,
};

export default SkeletonLoading;
