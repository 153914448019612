// @ts-nocheck
import styled from 'styled-components/macro';
import { Display as DisplayDS } from '@cvp/design-system/react';

export const Display = styled(DisplayDS)(
  ({ theme: { breakpoint, color } = false }) => ({
    background: color.neutral['07'],
    borderTop: `solid 1px ${color.neutral['06']}`,
    textAlign: 'center',
    alignItems: 'center',
    paddingBottom: '60px',
    justifyContent: 'center',
    flexDirection: 'column',
    h6: {
      textAlign: 'center',
    },
    [breakpoint.lg()]: {
      textAlign: 'center',
      justifyContent: 'space-between',
      padding: '10px 40px',
      flexDirection: 'row',
      h6: {
        textAlign: 'left',
      },
    },
  }),
);
