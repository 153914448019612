// @ts-nocheck
import React from 'react';
import { Card, Skeleton, Display } from '@cvp/design-system/react';

export const DetalheTarefaCardSkeleton: React.FunctionComponent = () => (
  <Display type="display-block" data-testid="skeleton">
    <Card>
      <Card.Content>
        <Skeleton lines={1} />
      </Card.Content>
    </Card>
  </Display>
);
