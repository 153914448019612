// @ts-nocheck
import { PECOS } from 'consultaCliente/config/endpoints';
import { usePeco } from '../../../../main/hooks/usePeco';
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { useToast } from 'main/hooks/useToast';
import {
  MSG_SERVICO,
  TIPO_OPERACAO,
  USAR_NOME_SOCIAL,
} from 'main/constants/nomeSocialConstants';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

export const useManterNomeSocial = () => {
  const { loading, fetchData } = usePeco({
    api: { operationPath: PECOS.ManterNomeSocial },
  });
  const { setNomeSocial } = useContext(AppContext);
  const { toastSuccess, toastError } = useToast();

  const incluir = async (cpf: string, nome: string) => {
    const response = await fetchData({
      indUsarNomeSocial: USAR_NOME_SOCIAL.SIM,
      tipoOperacao: TIPO_OPERACAO.INCLUSAO,
      cpf,
      nomeSocial: nome,
    });

    if (checkIfAllItemsAreTrue([!!response, !!response?.sucessoBFF])) {
      toastSuccess(MSG_SERVICO.MSG_INCLUSAO);
      setNomeSocial(nome);
    } else toastError(MSG_SERVICO.MSG_ERRO_INCLUSAO);

    return response;
  };

  const excluir = async (cpf: string, nome: string) => {
    const response = await fetchData({
      indUsarNomeSocial: USAR_NOME_SOCIAL.NAO,
      tipoOperacao: TIPO_OPERACAO.EXCLUSAO,
      cpf,
      nomeSocial: nome,
    });
    if (checkIfAllItemsAreTrue([!!response, !!response?.sucessoBFF])) {
      toastSuccess(MSG_SERVICO.MSG_EXCLUSAO);
      setNomeSocial('');
    } else toastError(MSG_SERVICO.MSG_ERRO_EXCLUSAO);
    return response;
  };

  const alteracao = async (cpf: string, nome: string) => {
    const response = await fetchData({
      indUsarNomeSocial: USAR_NOME_SOCIAL.SIM,
      tipoOperacao: TIPO_OPERACAO.ALTERACAO,
      cpf,
      nomeSocial: nome,
    });
    if (checkIfAllItemsAreTrue([!!response, !!response?.sucessoBFF])) {
      toastSuccess(MSG_SERVICO.MSG_ATUALIZACAO);
      setNomeSocial(nome);
    } else toastError(MSG_SERVICO.MSG_ERRO_ALTERACAO);
    return response;
  };

  return {
    loading,
    fetchData,
    alteracao,
    incluir,
    excluir,
  };
};
