// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button } from '@cvp/design-system/react';
import { retornaFundosSelecionados } from 'previdencia/utils/tabelaFundos';
import Modal from 'main/components/Modal';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { useTransferenciaFundosContext } from '../../contexts/ContextTransferenciaFundos';
import useDefinirReservaDestino from '../../hooks/useDefinirReservaDestino';
import {
  confirmarTransferenciaRetorno,
  verificarErroRetornadoServico,
  verificarMudancaPerfil,
  verificarRealizacaoContribuicaoRegular,
  validaLoading,
  verificaRevalidar,
  direcionarDefinirFundos,
  direcionarTelaFinalizacaoTransferencia,
} from '../../utils/transferenciaFundosInvestimento';
import TabelasDadosSolicitacao from '../TabelasDadosSolicitacao';
import TextoInformativoDadosSolicitacao from '../TextoInformativoDadosSolicitacao';
import useRevalidarTransferencia from '../../hooks/useRevalidarTransferencia';
import * as S from './styles';
import useConfirmarTransferencia from '../../hooks/useConfirmarTransferencia';
import { IPendencias } from '../../../../types/IRevalidarTransferencia';
import { converterFundoParaLstReservasDestinoRequest } from '../../factories/transferenciaFundoInvestimentoFactory';
import { ITransferenciaFundosFeatureData } from '../../types/ITransferenciaFundosFeatureData';

const DadosSolicitacao: React.FC = () => {
  const {
    fundosOrigem,
    fundosDestino,
    responseRedistribuirFundos,
    dadosInvalidos,
    direcionarNovaTela,
    escolherFundosContribuicaoRegular,
    contribuicaoRegularFeita,
    permissaoAssinatura,
  } = useTransferenciaFundosContext();

  const { featureData, setFeatureData } =
    usePrevidenciaContext<ITransferenciaFundosFeatureData>();

  const request = converterFundoParaLstReservasDestinoRequest(
    fundosDestino,
    responseRedistribuirFundos,
  );
  const [openModal, setOpenModal] = useState(false);
  const [mensagensModal, setMensagensModal] = useState<
    IPendencias[] | undefined
  >();
  const fundosOrigemSelecionados = retornaFundosSelecionados(fundosOrigem);
  const fundosDestinoSelecionados = retornaFundosSelecionados(fundosDestino);
  const [revalidarProcesso, setRevalidarProcesso] = useState(false);
  const [realizarContribuicaoRegular, setRealizarContribuicaoRegular] =
    useState(false);
  const [mudancaPerfil, setMudancaPerfil] = useState(false);
  const [erroDefinirReservaDestino, setErroDefinirReservaDestino] = useState<
    string | undefined
  >(undefined);
  const [
    revalidarTransferenciaPossuiMudancaPerfil,
    setRevalidarTransferenciaPossuiMudancaPerfil,
  ] = useState<boolean | undefined>(undefined);

  const {
    dataDefinirReservaDestino,
    loadingDefinirReservaDestino,
    definirReservaDestino,
  } = useDefinirReservaDestino();

  const {
    dataRevalidarTransferencia,
    loadingRevalidarTransferencia,
    revalidarTransferencia,
  } = useRevalidarTransferencia();

  const {
    loadingConfirmarTransferenciaEntreFundos,
    obterConfirmarTransferencia,
  } = useConfirmarTransferencia();

  useEffect(() => {
    setFeatureData({
      ...featureData,
      idRequisicao: responseRedistribuirFundos?.numTransferencia,
    });

    if (dataDefinirReservaDestino) {
      revalidarTransferencia(responseRedistribuirFundos?.numTransferencia);
      setErroDefinirReservaDestino(dataDefinirReservaDestino.msgErroExcessao);
      setRealizarContribuicaoRegular(
        verificarRealizacaoContribuicaoRegular(
          dataDefinirReservaDestino.fundosContrib,
          contribuicaoRegularFeita,
        ),
      );
      setRevalidarProcesso(true);
    }

    if (!dataDefinirReservaDestino) {
      definirReservaDestino(
        responseRedistribuirFundos?.numTransferencia,
        request,
      );
    }
  }, [dataDefinirReservaDestino]);

  if (erroDefinirReservaDestino) {
    const mensagem: string[] = [];
    mensagem.push(erroDefinirReservaDestino);
    dadosInvalidos(mensagem);
  }

  useEffect(() => {
    if (verificaRevalidar(revalidarProcesso, dataRevalidarTransferencia)) {
      const perfil = verificarMudancaPerfil(
        dataRevalidarTransferencia?.pendenciasRevalidarTransferencia,
      );
      if (perfil) {
        setOpenModal(true);
        setMensagensModal(
          dataRevalidarTransferencia?.pendenciasRevalidarTransferencia,
        );
        setRevalidarTransferenciaPossuiMudancaPerfil(perfil);
        setMudancaPerfil(true);
      }
    }
  }, [revalidarProcesso, dataRevalidarTransferencia]);

  useEffect(() => {
    direcionarDefinirFundos(
      dataRevalidarTransferencia?.sta,
      direcionarNovaTela,
    );
  }, [dataRevalidarTransferencia]);

  if (
    verificarErroRetornadoServico(
      dataRevalidarTransferencia,
      revalidarTransferenciaPossuiMudancaPerfil,
    )
  ) {
    const mensagensErro: string[] = [];
    dataRevalidarTransferencia?.pendenciasRevalidarTransferencia
      .filter(x => x.codigoErro?.toUpperCase() !== 'ALERTA')
      .map(e => mensagensErro.push(e.descricaoPendencia));
    dadosInvalidos(mensagensErro);
  }

  const confirmarTransferencia = async () => {
    switch (
      confirmarTransferenciaRetorno(
        mudancaPerfil,
        realizarContribuicaoRegular,
        dataDefinirReservaDestino,
        dataRevalidarTransferencia?.pendenciasRevalidarTransferencia,
      )
    ) {
      case 'contribuicaoRegular':
        escolherFundosContribuicaoRegular(dataDefinirReservaDestino!);
        break;
      case 'perfil':
        setOpenModal(true);
        setMensagensModal(
          dataRevalidarTransferencia?.pendenciasRevalidarTransferencia,
        );
        break;
      default: {
        const retornoConfirmacaoEntreFundos = await obterConfirmarTransferencia(
          responseRedistribuirFundos?.numTransferencia,
        );
        direcionarTelaFinalizacaoTransferencia(
          retornoConfirmacaoEntreFundos,
          permissaoAssinatura,
          direcionarNovaTela,
        );
      }
    }
  };

  if (
    validaLoading(
      loadingDefinirReservaDestino,
      loadingRevalidarTransferencia,
      loadingConfirmarTransferenciaEntreFundos,
    )
  ) {
    return <SkeletonLoading blocks={1} />;
  }
  return (
    <>
      <TextoInformativoDadosSolicitacao />
      <TabelasDadosSolicitacao
        fundosAlterados={fundosOrigemSelecionados}
        fundosRedistribuidos={fundosDestinoSelecionados}
      />
      <S.ContainerButtons>
        <S.ButtonRecusarStyle
          variant="outlined"
          onClick={() => direcionarNovaTela('definirFundosDestino')}
        >
          Voltar
        </S.ButtonRecusarStyle>
        <Button variant="primary" onClick={() => confirmarTransferencia()}>
          Confirmar
        </Button>
      </S.ContainerButtons>

      <Modal
        open={openModal}
        onClose={() => {
          setMudancaPerfil(false);
          setOpenModal(false);
        }}
      >
        {mensagensModal?.map(e => (
          <p>{e.descricaoPendencia}</p>
        ))}

        <S.ContainerButtonsModal>
          <S.ButtonRecusarStyle
            variant="outlined"
            onClick={() => {
              setMudancaPerfil(false);
              setOpenModal(false);
            }}
          >
            Recusar
          </S.ButtonRecusarStyle>
          <Button
            variant="primary"
            onClick={() => {
              setMudancaPerfil(true);
              setOpenModal(false);
            }}
          >
            Continuar
          </Button>
        </S.ContainerButtonsModal>
      </Modal>
    </>
  );
};

export default DadosSolicitacao;
