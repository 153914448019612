// @ts-nocheck
import SkeletonLoading from 'main/components/SkeletonLoading';
import { CardResumoProduto, HistoricoPagamentos } from 'seguros/components';
import { useHistoricoPagamentos } from 'seguros/hooks/useHistoricoPagamentos';
import { converterParaHistoricoPagamentosTableProps } from '../factories/HistoricoPagamentosFactory';

const Pagamentos = () => {
  const {
    getHistoricoPagamentos: { loading, response },
  } = useHistoricoPagamentos();

  if (loading) return <SkeletonLoading lines={5} />;

  return (
    <>
      <CardResumoProduto />
      <HistoricoPagamentos
        data={converterParaHistoricoPagamentosTableProps(response?.entidade)}
      />
    </>
  );
};

export default Pagamentos;
