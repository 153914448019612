// @ts-nocheck
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IResultadoSinistroPropsFactory } from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { ResultadoSinistroDadosProps } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';

export const resultadoSinistroPropsFactory = ({
  statusSinistroPrevidencia,
  listaStatusSinistroPrestamista,
  responseStatusSinistroVida,
}: IResultadoSinistroPropsFactory): ResultadoSinistroDadosProps => {
  const defaultValue = '-';

  const statusSinistroPrestamista = listaStatusSinistroPrestamista?.length
    ? listaStatusSinistroPrestamista[0]
    : undefined;

  const informeSinistroVida = responseStatusSinistroVida?.length
    ? responseStatusSinistroVida[0]
    : undefined;

  const cpfPrevidencia = statusSinistroPrevidencia?.cpfTitular;
  const nomePrevidencia = statusSinistroPrevidencia?.nomeTitular;
  const cpfPrestamista = statusSinistroPrestamista?.cpfCnpj?.toString();
  const nomePrestamista = statusSinistroPrestamista?.nomeSegurado;
  const cpfVida = informeSinistroVida?.cpfCnpjPessoa?.toString();
  const nomeVida = informeSinistroVida?.nomeRazao;

  return {
    cpfTitular: tryGetValueOrDefault(
      [cpfPrevidencia],
      tryGetValueOrDefault(
        [cpfPrestamista],
        tryGetValueOrDefault([cpfVida], defaultValue),
      ),
    ),
    nomeTitular: tryGetValueOrDefault(
      [nomePrevidencia],
      tryGetValueOrDefault(
        [nomePrestamista],
        tryGetValueOrDefault([nomeVida], defaultValue),
      ),
    ),
  };
};
