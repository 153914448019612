// @ts-nocheck
import { Display, Tabs } from '@cvp/design-system/react';
import { TABS_PRODUTOS } from 'consultaCliente/config/tabsEnum';
import SectionGridPrestamista from 'consultaCliente/features/listaCardProduto/components/CardsProduto/CardPrestamista/SectionGridPrestamista';
import SectionGridCardPrev from 'consultaCliente/features/listaCardProduto/components/CardsProduto/CardPrevidencia';
import SectionGridCardVida from 'consultaCliente/features/listaCardProduto/components/CardsProduto/CardVida';
import { useActiveTab } from 'consultaCliente/features/listaCardProduto/hooks/useActiveTab';
import useCardsProduto from 'main/hooks/useCardsProduto';
import { AppContext } from 'main/contexts/AppContext';
import { PREV_PERMISSIONS } from 'main/features/Auth/config/userProfiles';
import { useAuth } from 'main/features/Auth/hooks';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { scrollScreen } from 'main/utils/screen';
import React, { useContext, useEffect } from 'react';
import { ConsultaResultadoSkeleton } from 'consultaCliente/features/listaCardProduto/components/ConsultaResultadoSkeleton';

const ConsultaResultado: React.FC = () => {
  const { setCustomPermissions, removeCustomPermission } = useAuth();
  const {
    cliente: { cpfCnpj },
    restoreDefaultMenu,
    restoreDefaultMenuVida,
    restoreDefaultMenuPrestamista,
    loadingNomeSocial,
  } = useContext(AppContext);

  const {
    dadosCardsPrevidencia,
    isLoadingPrevidencia,
    fetchDadosCardsPrevidencia,
    dadosCardsVida,
    isLoadingVida,
    fetchDadosCardsVida,
    dadosCardsPrestamista,
    fetchDadosCardsPrestamista,
    isLoadingPrestamista,
  } = useCardsProduto();

  const loading = checkIfSomeItemsAreTrue([
    isLoadingPrestamista,
    isLoadingPrevidencia,
    isLoadingVida,
  ]);

  const tabActive: number | null = useActiveTab(
    loading,
    dadosCardsPrevidencia,
    dadosCardsVida,
    dadosCardsPrestamista,
  );

  useEffect(() => {
    if (cpfCnpj) {
      fetchDadosCardsPrevidencia(cpfCnpj);
      fetchDadosCardsVida(cpfCnpj);
      fetchDadosCardsPrestamista(cpfCnpj);
      restoreDefaultMenu(cpfCnpj);
      restoreDefaultMenuVida(cpfCnpj);
      restoreDefaultMenuPrestamista(cpfCnpj);
      setCustomPermissions([PREV_PERMISSIONS.POSICAO_CONSOLIDADA]);
      scrollScreen(0);
    }
  }, [cpfCnpj]);

  if (tabActive === null) return <ConsultaResultadoSkeleton />;

  return (
    <Display
      justify="center"
      alignItems="center"
      type="display-block"
      style={{ marginBottom: '3.5rem' }}
    >
      <Tabs
        activeTab={tabActive}
        aria-label="abas de produtos"
        onChange={(activeTab: number) => {
          if (activeTab === TABS_PRODUTOS.Previdencia)
            setCustomPermissions([PREV_PERMISSIONS.POSICAO_CONSOLIDADA]);
          else removeCustomPermission(PREV_PERMISSIONS.POSICAO_CONSOLIDADA);
        }}
      >
        <Tabs.Item title="Previdência" key={1}>
          <SectionGridCardPrev
            codCliente={cpfCnpj}
            loadingPrev={checkIfSomeItemsAreTrue([
              isLoadingPrevidencia,
              loadingNomeSocial,
            ])}
            produtosPrev={dadosCardsPrevidencia}
          />
        </Tabs.Item>
        <Tabs.Item title="Seguros" key={2}>
          <SectionGridCardVida
            cpfCnpj={cpfCnpj}
            loadingVida={checkIfSomeItemsAreTrue([
              isLoadingVida,
              loadingNomeSocial,
            ])}
            produtosVida={dadosCardsVida}
          />
        </Tabs.Item>
        <Tabs.Item title="Prestamista" key={3}>
          <SectionGridPrestamista
            cpfCnpj={cpfCnpj}
            loadingPrestamista={checkIfSomeItemsAreTrue([
              isLoadingPrestamista,
              loadingNomeSocial,
            ])}
            produtosPrestamista={dadosCardsPrestamista}
          />
        </Tabs.Item>
      </Tabs>
    </Display>
  );
};

export default ConsultaResultado;
