// @ts-nocheck
import React from 'react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { converterBase64 } from 'main/utils/converterBase64';
import { CardResumoProduto, SegundaViaApolice } from 'seguros/components';
import { useSegundaViaCertificadosSegurosPdf } from 'seguros/hooks/useSegundaViaCertificadosSegurosPdf';

const getBlobUrl = (base64Pdf: string | undefined): string => {
  const blob = converterBase64(base64Pdf);
  return URL.createObjectURL(blob);
};

const SegundaViaApoliceVida: React.FC = () => {
  const { dadosSegundaViaCertificado, loadingSegundaViaCertificadoo } =
    useSegundaViaCertificadosSegurosPdf();

  const gerarPdf = () => {
    const blobUrl = getBlobUrl(
      dadosSegundaViaCertificado?.entidade?.conteudoBase64,
    );
    window.open(blobUrl);
  };

  if (loadingSegundaViaCertificadoo) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <>
      <CardResumoProduto />
      <SegundaViaApolice
        messagemService={dadosSegundaViaCertificado?.mensagens?.[0]?.descricao}
        error={!dadosSegundaViaCertificado?.entidade?.conteudoBase64}
        gerarPdf={gerarPdf}
        loading={loadingSegundaViaCertificadoo}
      />
    </>
  );
};

export default SegundaViaApoliceVida;
