// @ts-nocheck
import React from 'react';

type RenderConditionalProps = {
  condition: boolean;
  component?: React.ReactNode;
  children?: React.ReactNode;
};
const RenderConditional: React.FC<RenderConditionalProps> = ({
  condition,
  component,
  children,
}) => {
  if (condition) {
    return <>{component ?? children}</>;
  }
  return <></>;
};

RenderConditional.defaultProps = {
  component: null,
};

export default RenderConditional;
