// @ts-nocheck
import { obterEndpointTransferirEntreFundos } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import {
  IPayloadReservasOrigem,
  IResponseTransferenciaEntreFundos,
} from 'previdencia/types/ITransferirEntreFundos';

export const fundosDestino = async (
  cpf: string,
  codContaOrigem: string,
  reservasOrigem: IPayloadReservasOrigem[] | undefined,
): Promise<IResponseTransferenciaEntreFundos | undefined> => {
  if (reservasOrigem) {
    const { data } = await api.post<
      IApiResponse<IResponseTransferenciaEntreFundos>
    >(obterEndpointTransferirEntreFundos(), {
      cpf,
      codContaOrigem,
      reservasOrigem,
      codContaDestino: codContaOrigem,
    });

    if (data?.dados?.sucesso === false)
      throw new Error(
        data?.dados?.mensagens && data?.dados?.mensagens[0]?.descricao,
      );

    return data.dados.entidade;
  }

  return undefined;
};
