// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import * as PECO_TYPES from 'seguros/types/ConsultarMetodoPagamentoUsePeco';
import * as REQUEST_TYPES from 'seguros/types/ConsultarMetodoPagamentoRequest';
import * as RESPONSE_TYPES from 'seguros/types/ConsultarMetodoPagamentoResponse';

export const useConsultarMetodoPagamento =
  (): PECO_TYPES.IUseConsultarMetodoPagamento => {
    const { clienteVida } = useContext(AppContext);

    const {
      response: dadosConsultarMetodoPagamento,
      loading: loadingConsultarMetodoPagamento,
    } = usePeco<
      REQUEST_TYPES.IConsultarMetodoPagamentoPayload,
      RESPONSE_TYPES.IConsultaMetodoPagamentoResponse
    >({
      api: { operationPath: PECOS_VIDA.ConsultarMetodoPagamento },
      autoFetch: true,
      handleResponse: { throwToastErrorBFF: false },
      payload: {
        numeroCertificado: clienteVida?.numCertificado,
      },
    });

    return {
      dadosConsultarMetodoPagamento,
      loadingConsultarMetodoPagamento,
    };
  };
