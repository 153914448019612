// @ts-nocheck
import {
  IDadosParticipanteTelefone,
  TIPO_TELEFONE,
} from 'main/types/Participante';

export const obterTelefoneCelularOutro = (
  telefones?: IDadosParticipanteTelefone[],
) => {
  const telefoneCelular = telefones?.find(
    (telefone: IDadosParticipanteTelefone) =>
      telefone?.tipoTelefone === TIPO_TELEFONE.CELULAR,
  );
  if (telefoneCelular) {
    return telefoneCelular;
  }

  return telefones?.find(
    (telefone: IDadosParticipanteTelefone) =>
      telefone?.tipoTelefone === TIPO_TELEFONE.OUTRO,
  );
};

export const obterTelefonePrincipal = (
  telefones?: IDadosParticipanteTelefone[],
) => {
  const telefonePadrao = obterTelefoneCelularOutro(telefones);
  return `${telefonePadrao?.codigoArea}${telefonePadrao?.numeroTelefone}`;
};

export const obterTelefonePrincipalObjeto = (
  telefones?: IDadosParticipanteTelefone[],
) => {
  const telefonePadrao = obterTelefoneCelularOutro(telefones);
  return {
    ddd: telefonePadrao?.codigoArea,
    numero: telefonePadrao?.numeroTelefone,
  };
};
