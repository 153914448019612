// @ts-nocheck
import React from 'react';
import { TextField } from '@cvp/design-system/react';
import * as TableColumns from '../../types/TableColumns';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

const ColunaPercentualDistribuicao: React.FC<
  TableColumns.IDadosTabelaDistribuicaoProps
> = ({ row, handlers, requisitosDiversificacao }) => {
  const isInvalidoPorValorMinimo = () => {
    const valorMinimo = requisitosDiversificacao?.valorMinimoDiversificacao;

    const saldo = row?.saldo;

    if (
      checkIfAllItemsAreTrue([
        !!row?.selecionado,
        row.percentualDistribuicao,
        Number(saldo) < Number(valorMinimo),
        Number(requisitosDiversificacao?.quantidadeMaximaFundos) > 1,
      ])
    ) {
      return {
        error: true,
        message: 'Percentual não corresponde ao valor mínimo em reais.',
      };
    }
    return {
      error: false,
      message: '',
    };
  };

  let status = isInvalidoPorValorMinimo();

  return (
    <TextField
      placeholder="Percentual"
      onBlur={({ target }: React.ChangeEvent<HTMLInputElement>) => {
        if (row) {
          handlers.handleValorTotal(row);
          status = isInvalidoPorValorMinimo();
        }
      }}
      onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
        if (row) {
          handlers.handlePercentual(row, target.value?.toString());
          status = isInvalidoPorValorMinimo();
        }
      }}
      value={row?.percentualDistribuicao}
      disabled={!row?.selecionado}
      onFocus={() => {
        if (row) handlers.atribuirParametroInputFocus(row?.codigoFundo);
      }}
      autoFocus={row?.codigoFundo === handlers.selectedTableInputFocus}
      error={status.error}
      errorMessage={status.message}
    />
  );
};

export default ColunaPercentualDistribuicao;
