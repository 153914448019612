// @ts-nocheck
import { useContext, useMemo } from 'react';
import useObterCertificadosPrevidencia from 'consultaCliente/features/listaCardProduto/hooks/useObterCertificadosPrevidencia';
import { AppContext } from 'main/contexts/AppContext';
import useObterCertificadosDetlhadoPrevidencia from 'consultaCliente/features/listaCardProduto/hooks/useObterCertificadosDetlhadoPrevidencia';
import { preencherClassificacaoDoPlano } from 'consultaCliente/features/listaCardProduto/utils/certificadoPrevidencia';

const usePrevSummary = () => {
  const { cliente } = useContext(AppContext);
  const { data, isLoading } = useObterCertificadosPrevidencia(cliente?.cpfCnpj);
  const { data: detalhes } = useObterCertificadosDetlhadoPrevidencia(
    cliente?.cpfCnpj,
  );

  const informacaoPorCertificado = useMemo(
    () =>
      preencherClassificacaoDoPlano(data, detalhes)?.find(
        e => e.certificadoNumero === cliente?.numCertificado,
      ),
    [data, cliente?.numCertificado, detalhes],
  );

  return { informacaoPorCertificado, isLoading, id: cliente?.cpfCnpj };
};
export default usePrevSummary;
