// @ts-nocheck
import styled from 'styled-components/macro';
import { Display } from '@cvp/design-system/react';

export const ContainerPeculio = styled(Display)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  background: theme.color.background.light,
  borderRadius: 16,
  padding: '0 10px',
  margin: '20px 0',

  div: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    span: {
      marginLeft: '10px',
    },
  },
}));
