// @ts-nocheck
import React from 'react';
import { Grid } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';

import Table from 'main/components/Table';
import { levelRisk } from 'previdencia/utils/riskStatus';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { RiskBarStyle } from 'previdencia/components/stylesPrevidencia';
import { somaTransferenciaPorFundo } from '../../utils/TransferenciaMultifundos';
import { COLUNAS_TABELA_RESUMO_FUNDOS_PJ } from 'previdencia/components/TabelaMultifundosPJ/constants/constants';
import { TabelaResumoFundosProps } from '../../types/Resumo.types';
import { TabelaFundosDefaultValue } from 'previdencia/components/TabelaMultifundosPJ/types/enum';

const TabelaResumoFundos: React.FC<TabelaResumoFundosProps> = ({ fundos }) => {
  const formatData = fundos?.map(
    ({ reservas, descricaoPerfilFundo, ...rest }) => ({
      perfil: (
        <RiskBarStyle
          risk={levelRisk(
            tryGetValueOrDefault(
              [descricaoPerfilFundo],
              TabelaFundosDefaultValue.PERFIL_RISCO_MODERADO,
            ),
          )}
        />
      ),
      valorRetirada: formatarValorPadraoBrasileiro(
        somaTransferenciaPorFundo(reservas),
      ),
      ...rest,
    }),
  );

  return (
    <Grid.Item xs={1}>
      <Table
        noHeader
        responsive
        data={tryGetValueOrDefault([formatData], [])}
        columns={COLUNAS_TABELA_RESUMO_FUNDOS_PJ}
        noDataComponent="Não há dados para exibir."
      />
    </Grid.Item>
  );
};

export default TabelaResumoFundos;
