// @ts-nocheck
import { IDadosBasicosCertificadoPrevidencia } from 'main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';
import { CertificadosDestino } from 'previdencia/types/ICertificadoInformacoes';

export const filtrarCertificadosFactory = (
  certificadosOrigem: CertificadosDestino[] | undefined,
  listaCertificados: IDadosBasicosCertificadoPrevidencia[] | undefined,
) => {
  return certificadosOrigem
    ?.map(certificadoInfoItem => {
      const itemCorrespondente = listaCertificados?.find(
        listaCertificadosItem =>
          certificadoInfoItem.codConta ===
          listaCertificadosItem.certificadoNumero,
      );

      if (itemCorrespondente) {
        return { ...certificadoInfoItem, ...itemCorrespondente };
      }

      return null;
    })
    .filter(item => item !== null);
};
