// @ts-nocheck
import React, { useState, useCallback, useEffect } from 'react';
import { Text } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import RenderConditional from 'main/components/RenderConditional';
import Modal from 'main/components/Modal';
import Calendar from 'main/components/form/Calendar';
import { verificarPeriodoMenorUmAno } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import SelectSolicitacoes from 'previdencia/features/HistoricoSolicitacoes/components/SelectSolicitacoes/index';
import SelectQuantidadeMeses from 'previdencia/features/HistoricoSolicitacoes/components/SelectQuantidadeMeses/index';
import { InputsConsultaProps } from 'previdencia/features/HistoricoSolicitacoes/types/HistoricoSolicitacoesProps';
import { FiltroPeriodo } from 'previdencia/features/HistoricoSolicitacoes/types/enum';
import * as S from 'previdencia/features/HistoricoSolicitacoes/components/InputsConsulta/styles';

const InputsConsulta: React.FC<InputsConsultaProps> = ({
  alterarPeriodo,
  tiposSolicitacoes,
  definirPeriodoDefaultHistorico,
}) => {
  const dataPadraoLink = new Date();
  dataPadraoLink.setDate(
    dataPadraoLink.getDate() - Number(FiltroPeriodo.ULTIMOS_12_MESES),
  );

  const [inputDateLink] = useFieldLink({
    finalDate: new Date(),
    initialDate: dataPadraoLink,
  } as CalendarData);
  const [openModal, setOpenModal] = useState(false);
  const [tipo, setTipo] = useState('');
  const [periodoPersonalizado, setPeriodoPersonalizado] = useState(false);

  const definirTipoSolicitado = useCallback((tipoEscolhido: string): void => {
    setTipo(tipoEscolhido);
  }, []);

  const definirPeriodoSolicitado = useCallback(
    (periodoEscolhido: string): void => {
      if (periodoEscolhido === FiltroPeriodo.PERIODO_PERSONALIZADO) {
        setPeriodoPersonalizado(true);

        inputDateLink.set({
          value: { initialDate: null, finalDate: null },
          isValid: true,
          errorMsg: '',
        });
      } else {
        setPeriodoPersonalizado(false);

        const dataFinal = new Date();
        dataFinal.setDate(dataFinal.getDate() - Number(periodoEscolhido));

        inputDateLink.set({
          value: { initialDate: dataFinal, finalDate: new Date() },
          isValid: true,
          errorMsg: '',
        });
      }
    },
    [],
  );

  const consultarSolicitacoes = (): void => {
    const dataValida = verificarPeriodoMenorUmAno(
      inputDateLink.get().value.initialDate,
      inputDateLink.get().value.finalDate,
    );

    if (
      !dataValida &&
      inputDateLink.get().value.initialDate !== null &&
      inputDateLink.get().value.finalDate !== null
    ) {
      setOpenModal(true);
    }

    if (dataValida || tipo !== '') {
      alterarPeriodo({
        dataInicialModificada: inputDateLink.get().value.initialDate,
        dataFinalModificada: inputDateLink.get().value.finalDate,
        tipo,
        dataValida,
      });
    }
  };

  useEffect(() => {
    definirPeriodoDefaultHistorico({
      dataInicialModificada: inputDateLink.get().value.initialDate,
      dataFinalModificada: inputDateLink.get().value.finalDate,
    });
  }, []);

  return (
    <>
      <S.DisplayContainer>
        <S.SelectContainer>
          <Text variant="body01-md">Tipo de Solicitação:</Text>
          <SelectSolicitacoes
            tiposSolicitacoes={tryGetValueOrDefault([tiposSolicitacoes], [])}
            definirTipoSolicitado={definirTipoSolicitado}
          />
        </S.SelectContainer>
        <S.SelectContainer>
          <Text variant="body01-md">Período:</Text>
          <SelectQuantidadeMeses
            definirPeriodoSolicitado={definirPeriodoSolicitado}
          />
        </S.SelectContainer>
        <RenderConditional
          condition={periodoPersonalizado}
          component={
            <div>
              <Calendar
                placeholder="Informe o período"
                link={inputDateLink}
                validationRules={[]}
                maxDateRange={new Date()}
                range
              />
            </div>
          }
        />
        <div>
          <S.ButtonStyle
            data-testid="botao-filtrar"
            variant="secondary"
            onClick={consultarSolicitacoes}
          >
            Consultar
          </S.ButtonStyle>
        </div>
      </S.DisplayContainer>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Text variant="body01-md" color="text">
          O período para consulta não deve ser superior a 12 meses. Favor
          informar um novo período.
        </Text>
      </Modal>
    </>
  );
};

export default InputsConsulta;
