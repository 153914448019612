// @ts-nocheck
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { PECOS } from 'previdencia/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { useToast } from 'main/hooks/useToast';
import {
  IPayloadConsultarBeneficiarios,
  IResponseConsultarBeneficiarios,
} from '../types/InformesBeneficiariosTypes';

const useObterBeneficiariosPrevidencia = (ano: string) => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  const {
    response,
    loading,
    fetchData: originalFetchData,
  } = usePeco<
    IPayloadConsultarBeneficiarios,
    IResponseConsultarBeneficiarios[]
  >({
    api: {
      operationPath: PECOS.ObterBeneficiarios,
    },
    handleResponse: {
      throwToastErrorGI: false,
      throwToastErrorBFF: false,
    },
    payload: {
      cpf: cpfCnpj,
      ano,
    },
  });

  const fetchData = async () => {
    try {
      const result = await originalFetchData();
      const mensagem = result?.mensagens?.[0]?.descricao;
      if (!result?.entidade?.length) {
        toastError(mensagem);
      }
    } catch (error: any) {
      toastError(error);
    }
  };
  return { response, loading, fetchData };
};

export default useObterBeneficiariosPrevidencia;
