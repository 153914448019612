// @ts-nocheck
import {
  FundosContribuicao,
  MultifundoPjData,
  Reserva,
} from 'previdencia/types/MultifundosPJ';
import { ENUM_TIPO_FUNDO } from '../types/Enum';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IReservasDestino } from '../types/IContribuicaoRegular';
import { DadosParticipante } from 'main/types/Participante';
import { ISolicitarAssinaturaRequest } from 'previdencia/features/TransferenciaFundoInvestimento/types/ISolicitarAssinaturaRequest';

export const formatarReservas = (
  reservas?: Reserva[],
  tipo?: string,
  tipoTransferenciaDefault?: string,
) => {
  if (tipo === ENUM_TIPO_FUNDO.ORIGEM) {
    return reservas
      ?.filter(reserva => reserva.tipoTransferencia !== '')
      .map(({ codigoReserva, tipoTransferencia, valorTransferencia }) => ({
        codigoReserva,
        tipoTransferencia: tipoTransferencia?.substring(0, 1),
        valorRetirada: valorTransferencia,
      }));
  }

  const allReservas = reservas?.map(
    ({ codigoReserva, valorTransferencia, codigoObjetivoTransferencia }) => ({
      codigoReserva,
      tipoTransferencia: tipoTransferenciaDefault?.substring(0, 1),
      valorSaldo: valorTransferencia,
      codigoObjetivoTransferencia,
    }),
  );

  return allReservas?.filter(item => item?.valorSaldo !== 0);
};

export const formatarFundosPayload = (
  fundos?: MultifundoPjData[],
  tipo?: string,
  tipoTransferencia?: string,
) => {
  if (tipo === ENUM_TIPO_FUNDO.DESTINO) {
    return fundos?.map(({ codigoFundo, reservas }) => ({
      codigoFundo,
      reserva: formatarReservas(reservas, tipo, tipoTransferencia),
    }));
  }
  return fundos?.map(({ codigoFundo, reservas }) => ({
    codigoFundo,
    reservas: formatarReservas(reservas, tipo, tipoTransferencia),
  }));
};

export const obterTipoTransferencia = (fundos: MultifundoPjData[]) => {
  const reservaComTipoTransferencia = fundos
    .flatMap(fundo => fundo.reservas ?? [])
    .find(reserva => reserva.tipoTransferencia !== '');
  if (reservaComTipoTransferencia) {
    return reservaComTipoTransferencia.tipoTransferencia;
  }
  return '';
};

export const formatarReservaRegular = (
  codigoFundo: string,
  reservas?: Reserva[],
) => {
  return reservas?.map(({ codigoReserva, saldo }) => ({
    codigoFundo,
    codigoReserva,
    valorSaldo: tryGetValueOrDefault([saldo], 0),
  }));
};

export const formatarReservasDestino = (
  fundosDestino: MultifundoPjData[] | undefined,
): IReservasDestino[] => {
  const fundosFormat = fundosDestino?.map(({ codigoFundo, reservas }) => ({
    reservasDestino: formatarReservaRegular(codigoFundo, reservas),
  }));
  const arraySimples = fundosFormat?.map(item => item.reservasDestino).flat();
  return arraySimples as any as IReservasDestino[];
};

export const formatarFundosNovos = (fundos: MultifundoPjData[] | undefined) => {
  return fundos?.map(({ codigoFundo, valorContribuicao }) => ({
    codigoFundo,
    valorRequisitado: valorContribuicao,
  }));
};

export const converterContribuicaoParaFundo = (
  fundos?: FundosContribuicao[],
) => {
  return fundos?.map(item => ({
    descricaoFundo: item.desFundo,
    codigoFundo: item.codFundo,
    selecionado: false,
    reservas: [],
    ...item,
  }));
};

export const montarPayloadAssinaturaEletronica = (
  numeroTransferencia: string | undefined,
  certificado: string,
  response: DadosParticipante | undefined,
): ISolicitarAssinaturaRequest => {
  return {
    codigoEmpresa: '500',
    codigoCertificado: certificado,
    numeroDocumento: tryGetValueOrDefault([numeroTransferencia], '0'),
    nome: response?.nome,
    codigoTelefone: response?.telefones[0].codigoArea,
    telefone: response?.telefones[0].numeroTelefone,
    email: response?.email,
    tipoEnvio: 'SMS',
    cpf: response?.numCpf,
    tipoAssinatura: 'TRANSFUND',
  };
};
