// @ts-nocheck
import React from 'react';
import { Display, Button } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const ModalInformeCuidadoGarantido: React.FC<
  PROPS_TYPES.ModalTransferenciaVgblProps
> = ({ toggleModal, handleModal }) => {
  return (
    <TRANSF_VGBL_STYLES.ModalTransferenciaInterna
      show={toggleModal}
      handleClose={handleModal}
      size={ENUM_TYPES.ModalInfoPredefinitionSize.MD}
      data-testid="modalAvisoQuantidadeMinima"
    >
      <TRANSF_VGBL_STYLES.Text
        margin
        color="secondary"
        align="center"
        fontWeight="bold"
      >
        <Icon name="warning" /> Importante!
      </TRANSF_VGBL_STYLES.Text>
      <TRANSF_VGBL_STYLES.Text variant="body02-sm" margin>
        {CONSTS.TEXTOS_COBERTURAS.MODAL_DESCRICAO}
      </TRANSF_VGBL_STYLES.Text>
      <Display justify="center">
        <Button variant="secondary" onClick={handleModal}>
          Certo, entendi!
        </Button>
      </Display>
    </TRANSF_VGBL_STYLES.ModalTransferenciaInterna>
  );
};

export default ModalInformeCuidadoGarantido;
