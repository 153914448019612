// @ts-nocheck
import {
  Display,
  Card,
  Button,
  Grid,
  Text,
  TextField,
  Disclaimer,
} from '@cvp/design-system/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Formik } from 'formik';
import Icon from 'main/components/Icon';
import SkeletonLoading from 'main/components/SkeletonLoading';
import masks from 'main/utils/masks';
import { isCpf } from 'main/utils/cpf_cnpj';
import RenderConditional from 'main/components/RenderConditional';
import * as P from 'ferramentas/components/ferramentasStyles';
import useObterValorImportanciaSegurada from '../hooks/useObterValorImportanciaSegurada';
import { ModalValorAcumuladoAcima } from '../components/ModalValorAcumuladoAcima';
import { CardResultado } from '../components/CardResultado';
import { TooltipWrapper } from 'main/components/Wrappers';

const isQuerySuccess = (success: boolean, fetching: boolean, error: boolean) =>
  success && !fetching && !error;

const VALOR_MAX_TOTAL = 5000000.0;
const VALOR_MAX_CONTRATADO = 500000.0;

const validaCpf = (value: string) => {
  return isCpf(value.substring(0, 11)) ? undefined : 'CPF inválido!';
};

const ConsultaResultado: React.FC = () => {
  const [documento, setDocumento] = useState('');
  const [valorSeguradoIs, setValorSeguradoIs] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [openModalSemValorAcumulado, setOpenModalSemValorAcumulado] =
    useState<boolean>(false);
  const [openModalValorAcumuladoAcima, setOpenModalValorAcumuladoAcima] =
    useState<boolean>(false);

  const { data, refetch, isSuccess, isRefetchError, isFetching } =
    useObterValorImportanciaSegurada(documento);

  const isSucessValorImportanciaSegurada = useCallback(() => {
    return isSuccess && !isFetching && !isRefetchError && data;
  }, [isSuccess, isFetching, isRefetchError, data]);

  const regraShowModal = (valorAcumulo: number, valorContratado: number) => {
    const valorTotal = valorAcumulo + valorContratado;
    const valorTotalValido = valorTotal >= VALOR_MAX_TOTAL;
    const valorMaximoContratadoValido = valorContratado >= VALOR_MAX_CONTRATADO;

    if (valorTotalValido || valorMaximoContratadoValido) return true;

    return false;
  };

  const formatarValue = (valor: string): number => {
    const maskedValor = masks.currency.unmask(valor);
    const len = maskedValor.length;
    const res = `${maskedValor.substring(0, len - 2)}.${maskedValor.substring(
      len - 2,
      len,
    )}`;
    return parseFloat(parseFloat(res).toFixed(2));
  };

  useEffect(() => {
    if (isSucessValorImportanciaSegurada()) {
      if (data) {
        setShowResult(true);
        if (regraShowModal(data.valorTotalContratos, valorSeguradoIs)) {
          setOpenModalValorAcumuladoAcima(true);
        }

        if (data.valorTotalContratos <= 0) {
          setOpenModalSemValorAcumulado(true);
        }
      } else {
        setOpenModalSemValorAcumulado(true);
      }
    }
  }, [isSucessValorImportanciaSegurada, data, valorSeguradoIs]);

  const resetData = (): void => {
    setValorSeguradoIs(0);
    setShowResult(false);
    setOpenModalSemValorAcumulado(false);
    setDocumento('');
  };

  const handleFormSubmit = (cpfCnpj: string, valorSegurado: string) => {
    resetData();
    const unmaskedCpfCnpj = masks.numberOnly.unmask(cpfCnpj);
    setDocumento(unmaskedCpfCnpj);

    if (valorSegurado) {
      setValorSeguradoIs(formatarValue(valorSegurado));
    }

    refetch();
  };

  const handleReset = (): void => {
    resetData();
  };

  return (
    <Display type="display-block">
      <Formik
        initialValues={{ cpf: '', valorSegurado: '' }}
        onSubmit={values => handleFormSubmit(values.cpf, values.valorSegurado)}
        onReset={handleReset}
      >
        {({ handleSubmit, handleBlur, values, setFieldValue, resetForm }) => (
          <Card>
            <Card.Content>
              <Text variant="body-medium3" color="text-light">
                Simule o valor total de acúmulo de IS para o cliente
              </Text>
              <br />
              <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                <Grid>
                  <Grid.Item xs={1 / 3}>
                    <Field name="cpf" validate={validaCpf}>
                      {({ field, meta }: FieldProps) => {
                        return (
                          <TextField
                            {...field}
                            label="*CPF"
                            error={meta.touched && meta.error}
                            errorMessage={meta.touched && meta.error}
                            onChange={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue('cpf', masks.cpf.unmask(value));
                            }}
                            value={masks.cpf.mask(values.cpf)}
                            onBlur={handleBlur}
                          />
                        );
                      }}
                    </Field>
                  </Grid.Item>
                </Grid>
                <Grid>
                  <Grid.Item xs={1 / 3}>
                    <TooltipWrapper
                      left={65}
                      top={0}
                      tooltip="IS ou Importância segurada é o valor do capital segurado contratado."
                    >
                      <TextField
                        label="Informe a Importância Segurada"
                        name="valorSegurado"
                        onChange={({
                          target: { value },
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            'valorSegurado',
                            masks.currency.unmask(value),
                          );
                        }}
                        value={masks.currency.mask(values.valorSegurado)}
                        onBlur={handleBlur}
                        placeholder=""
                      />
                    </TooltipWrapper>
                    <Display>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={resetForm}
                      >
                        Limpar
                      </Button>
                      <Button
                        data-testid="emitir-extrato"
                        disabled={values.cpf.length < 11}
                      >
                        Consultar
                      </Button>
                    </Display>
                  </Grid.Item>
                </Grid>
              </form>

              <RenderConditional condition={openModalSemValorAcumulado}>
                <P.Disclaimer variant="error">
                  <Disclaimer.Content
                    text="Não há valor acumulado para o cliente informado"
                    icon={<Icon name="information" />}
                  />
                </P.Disclaimer>
              </RenderConditional>

              {isFetching && <SkeletonLoading />}
              {isQuerySuccess(isSuccess, isFetching, isRefetchError) && (
                <CardResultado
                  dataResult={data}
                  valorSegurado={valorSeguradoIs}
                  showResult={showResult}
                />
              )}

              <ModalValorAcumuladoAcima
                open={openModalValorAcumuladoAcima}
                handleClose={() => setOpenModalValorAcumuladoAcima(false)}
              />
            </Card.Content>
          </Card>
        )}
      </Formik>
    </Display>
  );
};

export default ConsultaResultado;
